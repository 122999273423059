import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { ifEmptyFields } from "../../../../utils/UI_methods/global";
import { checkFromCache } from "../../../../utils/cache/cache";
import { applicationConfig, stringWithoutWhiteSpace } from "../../../../utils/methods";
import { getProductImageUrl } from "../../../../utils/moodboard/helper";
import { createProductForm } from "../../../../utils/products/createProductForm";
import MultipleFileUpload from "../../../common/ui-components/forms/MultipleFileUpload";
import DefaultConigForm from "./DefaultConigForm";
import ProductDetailsForm from "./ProductDetailsForm";

const ProductForm: React.FC<{
  configObj: any;
  setCurrState: (value: string) => void;
  closeModal: (value: boolean) => void;
  setProductDetails:(value:any)=>void
  productDetails:createProductForm
  isUpdateMode:boolean
}> = (props) => {  


  //In update mode
  const[selectedImages,setSelectedImages] = useState([])


  useEffect(()=>{
    if(props.isUpdateMode && props.productDetails){
      // updateMedia(props.productDetails)
    }
    
  },[props.isUpdateMode,props.productDetails])



  
  async function updateMedia(product:createProductForm){
    let productName = stringWithoutWhiteSpace(product.productName)
    let imageUrlKey = getProductImageUrl(applicationConfig?.BASE_URL_ASSETS,applicationConfig?.clientName,product.productName,product.categoryName,product.subCategory);
    let fileName = `${productName}.png`  
    const galleryImageCount = 5

    for (let i = 0; i < galleryImageCount; i++) {
      productName = `${productName}_${i+1}`
      imageUrlKey = getProductImageUrl(applicationConfig?.BASE_URL_ASSETS,applicationConfig?.clientName,productName,product.categoryName,product.subCategory);
      fileName = `${productName}.png`  
      await checkFromCache(imageUrlKey)?.then(async (url: any) => {
        await fetch(url).then(response=>(response.blob()).then(blobData=>{
          var file = new File([blobData],fileName,{ type: "image/jpeg" })
          product.galleryImages.push(file) 
          setSelectedImages(prevValues => {
            return [...prevValues,file]
          })
        }))
      }).catch((err=>[
        console.log(err)
      ]))
      
    }
  }
  
    function reviewProductDetails(){
        var isTemplateAvailable = $('input[name="isTemplateAvailable"]:checked').val();
        var isLeatherAvailable = $('input[name="isLeatherAvailable"]:checked').val();
        var canHaveCupHolder = $('input[name="canHaveCupHolder"]:checked').val();
        props.productDetails.isTemplate = Number(isTemplateAvailable || 0)
        props.productDetails.isLeatherAvailable = Number(isLeatherAvailable || 0)
        props.productDetails.canHaveCupHolder = Number(canHaveCupHolder || 0)
        if(ifEmptyFields()){
            return
        }
        props.setCurrState("Review")
    }

    function setProductModuleFiles(value:any) {
      props.productDetails['productModuleFiles'] = value
    }

    function setProduct3dModel(value:any) {
      props.productDetails.product3dModel = value
    }

    function setProductImage(file:any) {
      props.productDetails.setProductImage(file)
    }

    function setGalleryImages(value:any) {
      let galleryList = []
      for (const key in value) {
        const file = value[key]
        if(file.name && file.size){
          galleryList.push(file)
        }
      }
      props.productDetails.galleryImages = galleryList
    }

 
  function Header() {
    return (
      <div className="modal-header">
        <div className="center-aligned-row">
          <strong>Create Product</strong>
        </div>
        <span
          className="right-middle"
          onClick={() => {
            props.closeModal(false);
          }}>
          <IonIcon icon={close} ios={close} className="font-large"></IonIcon>
        </span>
      </div>
    );
  }
  

  function Body() {
    return (
      <div className="modal-body bg-color-ex-light">
        <div className="full-height-width overflow-y-scroll">

          <div className="display-flex-row">
            <div className="width70 padding5">
              <div className="border">
                <div className="heading3 bg-color-light color-black padding10">Product details</div>
                <ProductDetailsForm 
                  productDetails={props.productDetails}
                  isUpdateMode={props.isUpdateMode}
                />
              </div>

              <div className="border marginTop10    e">
                <div className="heading3 bg-color-light color-black padding10">Default Configuration</div>
                <DefaultConigForm 
                  productDetails={props.productDetails}
                />
              </div>

            </div>
            <div className="width30 padding5">

              <div className="border">
              <div className="heading3 bg-color-light color-black padding10">Product Images</div>
                <MultipleFileUpload 
                    heading=""
                    setFiles={setGalleryImages}
                    acceptExtensions = ".png,.jpg,.webp"
                    multiple = {true}
                    isSelectDefaultImage={true}
                    setDefaultImage={setProductImage}
                    selectedFiles={props.isUpdateMode?selectedImages:[]}
                />
              </div>
              <div className="border ion-margin-top">
              <div className="heading3 bg-color-light color-black padding10">Product 3d model</div>
                <MultipleFileUpload 
                    heading="Select Model"
                    setFiles={setProduct3dModel}
                    acceptExtensions = ".glb,.gltf"
                    multiple = {false}
                />
              </div>

              <div className="border ion-margin-top">
              <div className="heading3 bg-color-light color-black padding10">Product Modules</div>
                <MultipleFileUpload 
                    heading="Select Modules"
                    setFiles={setProductModuleFiles}
                    acceptExtensions = ".glb,.gltf"
                    multiple = {true}
                />
              </div>

              
            </div>
          </div>

          

          

         

          
        </div>
      </div>
    );
  }

  function Footer() {
    return (
      <div className="modal-footer">
        <div className="full-width display-flex-row space-between">
            <div><button className="op-btn op-btn-light" onClick={()=>props.setCurrState("Home")}>Back to home</button></div>
            <div><button className="op-btn op-btn-primary" onClick={reviewProductDetails}>Review & Next</button></div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Header />
      {props.productDetails?
        <Body />
      :null}
      <Footer />
    </React.Fragment>
  );
};

export default ProductForm;
