import { IonIcon } from '@ionic/react';
import { arrowBack, close } from 'ionicons/icons';
import React from 'react';
import { rippleEffectOnClick } from '../../../../../utils/UI_methods/ui_effects';

// import './Home.css';

const CloseMenu: React.FC<{
    heading:string
    action:()=>void
}> = (props) => {

    return(
      <>
      <button  className="padding10 zindex100 hide-in-mobile bottom-left pointer border-top bg-color-ternary overflow-hidden full-width text-center color-primary"
          onClick={props.action}>
          <div className="center-aligned-row full-width" onPointerDown={rippleEffectOnClick}>
            <div className="heading color-black display-flex-row pointer-none">
              {/* <i className="fas fa-chevron-left heading2"></i> */}
              <span className="center-aligned-column">
                {props.heading.toLocaleLowerCase().includes("close")?
                <IonIcon
                  icon={close}
                  className="font-medium center-aligned-column color-danger"
                />:
                <i className='fas fa-chevron-left font-medium center-aligned-column color-danger'></i>
              }
              </span>
              <span className="marginleft5 center-aligned-column heading2 color-black">
                {props.heading}
                
              </span>
            </div>
          </div>
      </button>


      <button className="close-menu zindex100 bottom-left pointer  overflow-hidden border-top bg-color-dark full-width text-center color-primary"
          onClick={props.action} onPointerDown={rippleEffectOnClick}>
          <div className="center-aligned-row pointer-none">
            <div className="heading color-black display-flex-row no-wrap">
              <span className="center-aligned-column">
                {props.heading.toLocaleLowerCase().includes("close")?
                <IonIcon
                  icon={close}
                  className="font-medium center-aligned-column color-white"
                />:
                <i className='fas fa-times font-medium center-aligned-column color-danger'></i>
              }
              </span>
              <span className="marginleft5 center-aligned-column heading2 font-normal color-white">
                Close Menu
              </span>
            </div>
          </div>
      </button>
      </>
      
        
    );
};
 
export default CloseMenu;
