import { IonModal } from "@ionic/react";
import $ from "jquery";
import React, { useRef, useState } from "react";
import { FabricateConfig } from "../../../../../utils/fabricate/fabricate";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import Summary from "./Summary";

const SummaryFooter: React.FC<{
  fabricateConfig:FabricateConfig
}> = (props) => {
   

  const productRef = useRef(null)

  const[isOpenSummary,setIsOpenSummary] = useState(false)

  function show() {
    $(productRef.current).removeClass("display-none")
  }

 

  function openSummaryAction() {
        setIsOpenSummary(true)
  }

  
  function closeSummary() {
    setIsOpenSummary(false)
  }

 
 

  return (
      <div className="full-height-width">

      <IonModal
          isOpen = {isOpenSummary}
          onDidDismiss = {()=>setIsOpenSummary(false)}
          cssClass="share-modal"
          id=""
      >
        <>
        <Summary fabricateConfig={props.fabricateConfig} isOpen={isOpenSummary} closeHandle={()=>closeSummary()}/>
        </>
      </IonModal>


        <div className="footer-summary position-relative white-card no-border-radius border-top center-aligned-column" style={{height:"4rem",zIndex:30}}>
          
          <div className="pointer full-width">
              <div className="display-flex-row no-wrap space-between">
                <div className="center-aligned-column marginleft5">
                   
                </div>
                  <div className="display-flex-row no-wrap"  onClick={openSummaryAction}>
                    <div className="display-flex-row no-wrap space-between full-width marginRight10">
                        <div className="center-aligned-column">
                            <span className="heading1 marginleft5 font-normal color-black total_price center-aligned-column">{getFormattedPrice(14574)} </span>
                        </div>
                        <div className="white-card marginleft10 shadow-light heading2 bg-color-primary color-white" style={{borderRadius:"25px",padding:"0.4rem 1.2rem"}}>
                            Summary
                        </div>
                    </div>
              </div>
          </div>
          </div>
        </div>

        <div className="hide-in-desktop bottom-right padding10">
          <Button2
              configObj={{id:""}}
              action={()=>{show()}}
              label={""}
              fontAwesomeIcon={"fas fa-plus color-white"}
              iconPos="left"
              classNames={"shadow-medium bg-color-primary"}
          />
        </div>
      </div>
    );
};

export default SummaryFooter;
