  import { IonIcon } from "@ionic/react";
import { colorPalette } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { goback, Init, releaseMemoryVirtualTour } from "../../../../../../utils/virtualtour/virtualtour";
// import Controls from "./controls/Controls"
// import MoodBoardCanvas from "./canvas/MoodBoardCanvas"
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import PageTourLink from "../../../../../common/ui-components/links/PageTourLink";
import AreaMinimap from "./AreaMinimap";
import Areas from "./Areas";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import { getFilteredArray, logger, sortAreasByPriority } from "../../../../../../utils/methods";
  
  const Canvas: React.FC<{
    configObj:any
    virtualTourConfig:any
    setCurrAreaName:(area:string)=>void
    projectId:number
  }> = (props) => {

    let history = useHistory()

    const [areas, setAreas]: any = useState([])

    const[forRendering,setForRender] = useState(String(Math.random()))

    useEffect(()=>{
      if(props.projectId){
        setForRender(String(Math.random()))
        props.configObj.setFunctionRef("virtualtour",{renderVirtualTour:setForRender})
        let areasList = getFilteredArray(props.configObj.data?.projectAreasList,"project_id",props.projectId)
        setAreas(sortAreasByPriority(areasList));
      }
    },[props.configObj,props.projectId])

    useEffect(()=>{
      if(areas.length){
        Init(props.virtualTourConfig,areas)
      }
    },[forRendering,props.configObj,areas])
 

    function customize() {
      window.location.href = '/customize'
    }      

    return (
    <div className="virtual-tour-container position-relative">
      <div className="hide-in-desktop hide-in-mobile">{forRendering}</div>


      <AreaMinimap
        configObj={props.configObj}
        currentConfigObject={props.configObj}
        areas={areas}
        module="virtualtour"
      />

        <div className="bottom-middle zindex30 ion-padding" id="virtualTourCustomizeLink" style={{bottom:"1rem"}}>
            
            <Button2
              configObj={props.configObj}
              action={customize}
              label={"Customize"}
              fontAwesomeIcon={"fas fa-cog color-danger font-medium"}
              iconPos="left"
              classNames={"button-large bg-color-primary"}
            />
            {/* <div className="vt-customize-link shadow-medium white-card click-effect background-white-fade display-flex-row no-wrap pointer" onClick={customize} style={{padding:"1rem 2rem"}}>
              <span className="center-aligned-column">
                <IonIcon icon={colorPalette} className="color-danger icon" />
              </span>
              <span className="color-black text center-aligned-column marginleft5">Customize</span>
            </div> */}
          </div>
 
          {/* <HorizontalScroll
        Content={()=>(
          <Areas
            configObj={props.configObj}
            setProducts={props.setProducts}
            currentConfigObject={props.currentConfigObject}
          />
        )}
        containerId="virtualTourAreaWrapper"
        wrapperId={"virtualTourAreaHrScroll"}
        itemclassName={"configuration-area"}
        cssClassWrapper={"virtual-tour-area-scroll-wrapper"}
      /> */}

        <Areas
            configObj={props.configObj}
            currentConfigObject={props.virtualTourConfig}
            setCurrAreaName={props.setCurrAreaName}
          />

    

        <div className="top-right zindex30" id="pageTourLink">
          <PageTourLink/>
        </div>


        <div className="top-left hide-in-mobile">
          <Button2
            configObj={props.configObj}
            action={()=>{history.push("/projects")}}
            label={"Home"}
            fontAwesomeIcon={"fas fa-home color-primary"}
            iconPos="left"
            classNames={""}
          />
        </div>

        <div className="top-left hide-in-desktop">
          <Button2
            configObj={props.configObj}
            action={goback}
            label={""}
            fontAwesomeIcon={"fas fa-home color-primary"}
            iconPos="left"
            classNames={""}
          />
        </div>

        

  


        {/* <div className="top-middle zindex30 " id="">
          <div className="display-flex-row fade-background-loader white-card"  style={{padding:"0.6rem 1rem"}}>
            <div className="center-aligned-column"> <i className="fas fa-street-view font-medium color-danger"></i> </div>
            <div className="center-aligned-column marginleft5 font-medium heading2 color-black capitalize" id="virtualTourAreaName">Foyer</div>
          </div>
        </div> */}

        {/* <div className="top-left zindex30" id="">
          <div className="display-flex-row fade-background-loader white-card" style={{padding:"0.6rem 1rem"}} onClick={()=>window.history.back()}> 
            <div className="center-aligned-column"> <i className="fas fa-chevron-left color-primary"></i> </div>
            <div className="center-aligned-column marginleft5">Back</div>
          </div>
        </div> */}

      <Loader 
        className="--is-active fade-background-loader"
        id="walkthroughLoader"
        loaderType="topProgressBar"
        data={{}}
      />
      <Loader 
        className="fade-background-loader"
        id="changeAreaLoader"
        loaderType="spinner"
        data={{}}
      />
        {/* <Areas
            configObj={props.configObj}
            updateCurrArea={updateCurrArea}
        /> */}
      <div id="progress"></div>
      <div id="virtualTourContainer" style={{width:"100%",height:"100%"}}></div>
      </div>
    );
  };
  
  export default Canvas;
  