import React, { useEffect, useState } from "react";
import { getTextures } from "../../../../services/api";
import Header from "./header/Header";
import StockListContainer from "./MaterialListContainer";
 
const StockList: React.FC<{
}> = (props) => {

 
  const[itemsList,setItemsList] = useState([])

  const[searchedItems,setSearchedLeadsList] = useState([])
  
  const[refresh,setIsReferesh] = useState(Number(new Date()))

  useEffect(()=>{
    getTextures().then(data=>{
      console.log(data)
      console.log(data)
      // if(!data){
        // let list = data.data.data
        setItemsList(data)
      // }
    }).catch(err=>{
      console.log(err)
    })
  },[refresh])

  function refreshList() {
    setIsReferesh(Number(new Date()))
  }


 
  return (
    <div className="full-height-width">

      

      <Header setSearchedLeadsList={setSearchedLeadsList} refreshList={refreshList}/>

      

      <div className="display-flex-row no-wrap full-height-width position-relative">


        <div className="full-width full-height display-flex-column">
          <StockListContainer allItems={itemsList} refreshList={refreshList} />
        </div>
      </div>


    </div>
  );
};

export default StockList;
