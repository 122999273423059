import React from "react";
import { applicationConfig } from "../../../../utils/methods";


const Button: React.FC<{
  configObj: any;
  action:()=>void
  label:string
  fontAwesomeIcon:string
  iconPos:string
  classNames:string
  isPreventDefault?:boolean
  svgUrl?:string
}> = (props) => {


  function clickHandle(event:any) {
    // rippleEffectOnClick(event)
    // shadowEffectOnClick(event)
    if(props.isPreventDefault){
      event.stopPropagation()
      event.preventDefault()
    }
    props.action()
  }

  function pointerDown(event:any) {
    if(props.isPreventDefault){
      event.stopPropagation()
      event.preventDefault()
    }
  }

  return (
    <React.Fragment>
      {props.label !== "" ?
      <div id={props.configObj?.id} className={`position-relative op-btn stop-propagation  button2 no-wrap display-flex-row ${props.classNames}`} onPointerDown={pointerDown} onClick={clickHandle}>
        {props.iconPos==="left"?
          props.svgUrl?
          <img src={applicationConfig.aws_public_url+props.svgUrl} width={18} className=" pointer-none" />
          :
          <i className={`${props.fontAwesomeIcon} icon pointer-none center-aligned-column`}></i>
          :null}
        <span className="text center-aligned-column pointer-none">{props.label}</span>  
        {props.iconPos==="right"?
          props.svgUrl?
          <img src={applicationConfig.aws_public_url+props.svgUrl} width={18} className=" pointer-none" />
          :
          <i className={`${props.fontAwesomeIcon} icon pointer-none center-aligned-column`}></i>
        :null}
        <div className="ripple-effect"></div>
        <span className={`${props.configObj.stateText ? "center-aligned-column heading4 hide-in-mobile state-text font-md-small" : ""}`} >{props.configObj?.stateText}</span> 
      </div> 
      :
      <div id={props.configObj?.id} className={`position-relative op-btn stop-propagation ripple  button2 no-wrap center-aligned-column ${props.classNames}`} onPointerDown={pointerDown} onClick={clickHandle} style={{padding:"0 12px",height:"auto",minHeight:"30px"}}>
        {props.iconPos==="left"?
          props.svgUrl?
          <img src={applicationConfig.aws_public_url+props.svgUrl} width={16} className=" pointer-none" />
          :
          <i className={`${props.fontAwesomeIcon} icon pointer-none center-aligned-column`}></i>
        :null}
        {props.iconPos==="right"?
          props.svgUrl?
          <img src={applicationConfig.aws_public_url+props.svgUrl} width={16} className=" pointer-none" />
          :
          <i className={`${props.fontAwesomeIcon} icon pointer-none center-aligned-column`}></i>
        :null}
        <div className="ripple-effect"></div>
      </div> 
      }
    </React.Fragment>
  
  );
};

export default Button;
