import { IonPage } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { addItemToLocalStorage } from "../../../../utils/cache/localstorage";
import { isTouchDevice } from "../../../../utils/customizer/helper";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getObjectArrayOfDistictValues, getObjectByParameter, getUser } from "../../../../utils/methods";
import { getWindowWidth } from "../../../../utils/UI_methods/global";
import { rippleEffectOnClick } from "../../../../utils/UI_methods/ui_effects";
import SessionExpired from "../../../common/ui-components/alerts/SessionExpired";
import SubscriptionAlert from "../../../common/ui-components/alerts/SubscriptionAlert";
import Button2 from "../../../common/ui-components/buttons/Button2";
import ShareLink from "../../../common/ui-components/links/sharelink/ShareLink";
import HorizontalScroll from "../../../common/ui-components/scrollbar/HorizontalScroll";
import MainHeader from "../../products/store/common/MainHeader";
import SubHeader from "../../products/store/common/SubHeader";
import Collections from "./Collections";
 
const KanvasCollections: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {


  const[collectionsList,setCollectionsList] = useState([])
  const [currCompany,setCurrCompany] = useState<string>();
  const [currCategory,setCurrCategory] = useState<string>();

  const [defaultProduct,setDefaultProduct] = useState<any>(null);
//   const[currSelectedSubCategory,setCurrSelectedSubCategory] = useState("")

  const [companies,setCompanies]: any = useState([]);
  const [categoryMaterials,setCategoryMaterials]: any = useState([]);
  const [loadLimit, setLoadLimit] = useState(10);

//   const [isCustomizableProducts,setIsCustomizableProduct] = useState(false);

//   const[isOpenSearch,setIsOpenSearch] = useState(false)

  const[materialTypes,setMaterialTypes] = useState([])
  const[currMaterialType,setCurrMaterialType] = useState("All")


  useEffect(()=>{
    let windowWidth = getWindowWidth()
    if(isTouchDevice() && (windowWidth >= 668 && windowWidth <= 2160)){
      setTimeout(() => {
        $("#imageMediumButton").click()
      }, 500);
    }
  },[])


  useEffect(()=>{
    if(categoryMaterials?.length && currMaterialType){
      setLoadLimit(10)
      let companiesResult = getObjectArrayOfDistictValues(categoryMaterials,"company_name") 
      if(categoryMaterials?.length && currMaterialType){
        setCompanies(companiesResult)
        setCurrCompany(companiesResult[0].company_name)
        updateCollections(categoryMaterials,currMaterialType)
      }
    }
  },[categoryMaterials,currMaterialType])

  useEffect(()=>{
    if(applicationConfig.data?.materials){
      let category = "Fabric"
      let materials = applicationConfig.data.materials
      let allProductsList = applicationConfig.data.allProductsList
      let filterProducts = [] 

      if(category === "Fabric"){
        materials = materials.filter(material => 
      
          material.material_type.includes("Fabric") || material.material_type.includes("Leather") ||
          material.material_type === "Velvet" ||
          material.material_type === "DigitalPrint" ||
          material.material_type === "Chenille"
        )
 
      }

      let userName = getUser().username
      if(userName.includes("Mittal")){
        materials = materials.filter(material => material.company_name === "Mittal")
      }
      if(userName.includes("Sarom")){
        materials = materials.filter(material => material.company_name === "Sarom")
      }
      if(userName.includes("Stanley")){
        materials = materials.filter(material => material.company_name === "Stanley")
      }
      if(userName.includes("Darpan")){
        materials = materials.filter(material => material.company_name === "Darpan")
      }
      if(applicationConfig.clientName === "KairaFabrics"){
        materials = materials.filter(material => material.company_name === "KairaFabrics")
      }
     
      setCategoryMaterials(materials)
      filterProducts = allProductsList.filter(product => product.category_name === "Sofa")

      applicationConfig.data.productsList = filterProducts
      let resultProduct = getObjectByParameter(filterProducts,"product_name","Linda") || filterProducts[0]
      if(resultProduct){
        setDefaultProduct(resultProduct)
        setCurrCategory(resultProduct.category_name)
        addItemToLocalStorage("currProduct",resultProduct)
      }else{
        clearCache()
      }
      
    }
  },[])

  function clearCache() {
    localStorage.clear();
    window.location.reload();
  }

  
  useEffect(()=>{
    let materialTypes = getArrayOfDistinctValues(categoryMaterials,"material_type") 
    setMaterialTypes(["All",...materialTypes])
    setCurrMaterialType("All")
  },[categoryMaterials])


 

  function updateCollections(categoryMaterials:any,materialType:string) {
    let collections = []
    collections = getFilteredArray(categoryMaterials,"material_type",materialType)
    if(materialType === "All"){
      collections = categoryMaterials
    }
    collections = getObjectArrayOfDistictValues(collections,"collection_name")
   
    setCollectionsList(collections)

    // setCurrCompany(company)
  }
 
 
  function SubCategoriesScroll() {
    return(
        <>
            <div className={currCompany==="All"?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrCompany("All")}>{"All"}</div>
            </div>
          {companies.map((company:any,index:number)=>{
            let companyName = company.company_name
            return(
              <div className={currCompany===companyName?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrCompany(companyName)}>{companyName}</div>
              </div>
            )
          })}
        </>
    )
  }

  function Footer(){
    return (
      <div className="center-aligned-row marginBottom5">
         <p className="color-dark font-small heading4">Powered by 
         <a href="https://omniviewlabs.com/" className="font-small" style={{textDecoration:"none"}}> omniviewlabs.com</a>
        </p>
      </div>
    )
  }
 
  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white">
        <SubscriptionAlert/>
        <MainHeader/>
        <SubHeader/>

        
        {/* <StoreProductSearch isOpen={isOpenSearch} setIsOpen={setIsOpenSearch} /> */}

        <div className="main-content-wrapper position-relative hide-scrollbar light-background padding10">
            <div className=" background-white position-relative overflow-hidden full-height-width">
              <div className="display-flex-row main-cards-filters-wrapper no-wrap ion-padding full-height-width" >
                <div className="main-sidebar-filters-wrapper hide-in-mobile position-relative shadow-medium white-card padding10 overflow-y-scroll">

                <div className="bottom-left full-width padding0">
                <img className="full-width contain" src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/clientLogos/other/${applicationConfig?.clientName}_Tag.PNG`} alt="" />
                <Footer/>
                </div>

                <div className="hide-in-mobile display overflow-y-scroll">
                  {!getUser()?.shareItemDetails?
                    <Button2
                      configObj={{}}
                      action={()=>{}}
                      label={"Material Types"}
                      fontAwesomeIcon={"fas fa-bars color-primary"}
                      iconPos="left"
                      classNames={"shadow-light margin0 font-medium full-width bg-color-black"}
                    />
                  :null}
                </div>

                    {materialTypes.map((materialType:any,index:number)=>{
                        return(
                        <div onClick={()=>{setCurrMaterialType(materialType)}} id={"subCategory"+index} onPointerDown={rippleEffectOnClick} className={`border-bottom click-effect position-relative overflow-hidden sub-category ion-padding pointer ${materialType === currMaterialType?" --is-active":" "}`} key={index} style={{paddingLeft:"2rem"}}>
                            <div className="heading4 pointer-none text">{materialType}</div>
                        </div>
                        )
                    })}

                </div>

                <div className="main-cards-wrapper padding10 position-relative display-flex-column no-wrap overflow-hidden full-height shadow-light white-card">
                
                <div className="sort-items-container position-relative">

                  


                  <div className="storefront-in-header">

                  

                    

                    <div className="display-flex-row space-between">

                     
                    </div>
                    
                  </div>

                  <div className="full-width display-flex-row position-relative space-between no-wrap">
                    
                    <div className="padding5">
                      <div className="heading2 font-large">{applicationConfig.clientName} Collections</div>
                      <div className="heading4 font-normal color-black">Showing {collectionsList?.length} collections of {collectionsList?.length} collections</div>
                    </div>



                    <div className="center-aligned-column marginRight10">

                      <ShareLink
                          shareItemId={defaultProduct?.product_id}
                          loaderId = {"loaderId"}
                          moduleName="products"
                          textClass="color-black"
                          isShareButton={true}
                          kanvasModeConfig={{
                              collectionName:"",
                              companyName:currCompany,
                              categoryName:currCategory,
                              isShareMultipleCollections:true
                          }}
                          Content={()=>{
                          return(
                            <Button2
                              configObj={{id:""}}
                              action={()=>{}}
                              label={"Share Multiple"}
                              fontAwesomeIcon={"fas fa-share color-primary"}
                              iconPos="right"
                              classNames={"shadow-medium border-primary margin0"}
                            />
                          )
                          }
                          }
                        />

                      <div className="display-flex-row no-wrap">
                        <div className="display-flex-row no-wrap">
                        {/* <div className="hide-in-mobile">
                          <Button2
                            configObj={{id:""}}
                            action={()=>{setIsOpenSearch(true)}}
                            label={"Search"}
                            fontAwesomeIcon={"fas fa-search color-black"}
                            iconPos="left"
                            classNames={"shadow-medium no-border button-small margin0"}
                          />
                        </div> */}

                        

                        
                          

                        {/* <div className="display-flex-row no-wrap pointer icon-wrapper" onClick={()=>{setIsOpenSearch(true)}}>
                          <span className="center-aligned-column pointer-none position-relative "><i className="fas fa-search color-warning font-large"></i>
                          </span>
                        </div> */}

                          {/* <div className="display-flex-row marginleft5 button-group-container no-wrap">
                            <div id="imageSmallButton" data-value="image-small" className="center-aligned-column button --is-active product-card-size-button" onClick={ChangeProductCardSize}> <i className="fas fa-th-large icon font-ex-small"></i> </div>
                            <div id="imageMediumButton" data-value="image-medium" className="center-aligned-column button   product-card-size-button" onClick={ChangeProductCardSize}> <i className="fas fa-th-large icon font-small"></i> </div>
                            <div data-value="image-large" className="center-aligned-column button product-card-size-button" onClick={ChangeProductCardSize}> <i className="fas fa-th-large icon font-normal"></i> </div>
                          </div> */}


                        </div>
                      </div>
                    
                    </div>

                  </div>
                </div>


                <div className="marginTop5 marginBottom5 sub-categories-container-potrait">
                    <HorizontalScroll
                      itemsClass="sub-categories-container"
                      Component={SubCategoriesScroll}   
                      itemsList={companies}
                      id={`subCategoryMobilesScrollBar`}     
                    />
                </div>

                {collectionsList?.length?
                  <Collections materialsList={categoryMaterials} defaultProduct={defaultProduct} currCategory={currCategory} collectionsList={collectionsList}/>
                :
                  <div className="top-left full-height-width center-aligned-column pointer-none">
                    <div className="heading4 font-normal">No products found</div>
                  </div>
                }

                </div>
              </div>
            </div>
        </div>
      </div>
      :
      <SessionExpired/>
      }
    </IonPage>
  );
};

export default KanvasCollections;
