import $ from "jquery";
import React, { useEffect, useState } from "react";
import { checkFromCache } from "../../../../../utils/cache/cache";
import { applicationConfig, getObjectByParameter, stringWithoutWhiteSpace, waitFor } from "../../../../../utils/methods";
import { getProductImageUrl } from "../../../../../utils/moodboard/helper";
import ProductImage from "../../elements/ProductImage";

const ProductInfo: React.FC<{
  productName: string;
  category: string;
  subCategory: string;
  shareItemId: any;
}> = (props) => {
  const [mode, setMode] = useState("");


  const [product,setProduct] = useState(null);

  useEffect(() => {

    if (typeof props.shareItemId === "number") {
      setMode("single")
    }else{
      setMode("Multiple")
    }
    let result = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",props.shareItemId)
    if(result){
      setProduct(result)
    }

  }, [props.productName]);


  return (
    <div className="flex1 position-relative">
      {mode === "Multiple" ? 
        <>
          {props.shareItemId?.map((itemId:number, index:number) =>{
          let currProduct = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",props.shareItemId)
          return(
            <div key={index}
            className="padding10 display-flex-row space-between shadow-light ion-margin-top"
            style={{ paddingTop: 0, paddingLeft: 0 }}>
            <div className="shadow-light center-aligned-column color-primary" style={{width:"28px",height:"28px"}}>
              {index+1}
            </div>
            <div className="heading1 font-small letter-spacing">
              {currProduct.product_name} ( {currProduct.category_name} / {currProduct.sub_category_name} )
            </div>

            <div>
              <i className="fas fa-trash-alt color-danger cursor-pointer"></i>
            </div>
            </div>
          )
           
        })}

        </>
     : 
     <>
        <div className="padding10 top-left" style={{ paddingTop: 0, paddingLeft: 0 }}>
            <span className="color-black heading1 font-normal"></span>
            <span className="heading3 color-primary">
              {props.category}
            </span> /{" "}
            <span className="heading3 color-black">{props.subCategory}</span> /{" "}
            <span className="heading3 color-dark">{props.productName}</span>{" "}
          </div>

          <div className="full-width center-aligned-column padding10" >
            <div className="" style={{height:"20rem"}}>
              <ProductImage product={product} classNames="full-height contain"/>
            </div>
          </div>

          

        </>
    }
    </div>
  );
};

export default ProductInfo;
