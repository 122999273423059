import React, { useEffect, useState } from "react";
import { getLogs } from "../../../services/api";
import LogCards from "../../common/ui-components/cards/LogCards";
import CardsLayout from "../../templates/layouts/page/CardsLayout";
import Inventory from "../inventory/Inventory";
import LogsHeader from "./LogsHeader";
import { applicationConfig } from "../../../utils/methods";


const LogsContainer: React.FC<{
  configObj:any
}> = (props) => {


 
  const[logsList,setLogsList] = useState([])
  const[filteredLogs,setFilteredLogs] = useState([])

  useEffect(()=>{
    getLogs().then(data=>{
      if(data){
        applicationConfig.data.logsList = data
        setLogsList(data)
        setFilteredLogs(data)
      }
    }).catch(err=>{
      setLogsList([])
    })
  },[props.configObj])


  const [createNewFinish, setcreateNewFinish] = useState(false);



  function AddCardForm() {
    return(
      <Inventory
      currState="Finish"
      configObj={props.configObj}
      closeModal={setcreateNewFinish}
      product={null}
      finishId={0}
    />
    )
  } 

  return (
    <React.Fragment>

        
   
      {logsList?
        <>
        <LogsHeader
         configObj={props.configObj}
         logsList={logsList}
         setFilteredLogs={setFilteredLogs}
       />
       <CardsLayout
       MainContent={()=>(
        <>
        {filteredLogs?.length?
        <LogCards
          configObj={props.configObj}
          logsList={filteredLogs}
        />
        :null}
           
        </>
      
       )}
       AddCardForm={AddCardForm}
       configObj={props.configObj}
     />
     </>
      :
      <span>Please Wait...</span>
      
      }

     
  </React.Fragment>
  );
};

export default LogsContainer;
