import { String } from 'aws-sdk/clients/cloudwatchevents';
import $ from 'jquery';
import React from 'react';

import { updateModuleFilters } from '../../../utils/cache/localstorage';
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getUserType, goback } from '../../../utils/methods';
import { addClassInElement, closeFiltersPanelMobile, focusOnInput, openFiltersWindowMobile, removeClassFromElement, toggleFiltersWindowMobile } from '../../../utils/UI_methods/global';
import Button2 from '../../common/ui-components/buttons/Button2';
import ProductsSearch from '../../common/ui-components/search/ProductsSearch';

const FilterHeaderContent: React.FC<{
  setCurrSelectedCategory:(val:string)=>void
  setCurrSelectedSubCategory:(val:string)=>void
  currSelectedSubCategory:string
  currSelectedCategory:string
  productsList:Array<any>
  setIsSearchMode:(val:boolean)=>void
  setProductsList:(val:any)=>void
  isSearchMode:boolean
}> = (props) => {

 
 
             

  function filterProducts(category:string,subCategory:String) {
    if(category && subCategory){
      if(subCategory.toLowerCase() === "all"){
        let filteredProducts = applicationConfig?.data.productsList?.filter(product=>product.category_name===category)
        props.setProductsList(filteredProducts);
        return
      }
      
      let filteredProducts = applicationConfig?.data.productsList?.filter(product=>product.category_name===category&&product.sub_category_name===subCategory)
      props.setProductsList(filteredProducts);
      props.setCurrSelectedCategory(category)
      props.setCurrSelectedSubCategory(subCategory)
    }

    closeCategories()
    closeFiltersPanelMobile()
  }


  function filterProductsWithSubCategory(cat:string,val:string){
    filterProducts(cat,val)
  }

  function filterProductsWithCategory(category:string){
    // let filteredProducts = getFilteredArray(applicationConfig?.data.productsList,"category_name",category);
    // let subCategory = filteredProducts[0].sub_category_name
    props.setCurrSelectedCategory(category)
    filterProducts(category,"All")
  }

  function activateSearchMode(products:Array<any>,searchString:string) {
    updateModuleFilters("products","isSearchMode",true)
    updateModuleFilters("products","searchedProducts",products)
    updateModuleFilters("products","searchString",searchString)
    props.setProductsList(products)
    props.setIsSearchMode(true)
  }

  function deactiveSearchModel() {
    updateModuleFilters("products","isSearchMode",false)
    props.setIsSearchMode(false)
    applicationConfig.functions.products.filterProductWithCategoryAndSubCategory(props.currSelectedCategory,"All")
  }


  function closeCategories() {
    $("#productsCategoryWrapper").removeClass("--is-active")
  }


  function toggleProducts(isCustomizable:boolean) {
    if(!isCustomizable){
      filterProducts(applicationConfig?.states.products.currSelectedCategory,"All")
    }else{
      let customizableProducts = getFilteredArray(applicationConfig?.data.productAttributes,"is_customizable","1");
      customizableProducts = getArrayOfDistinctValues(customizableProducts,"product_id")
      let filteredProducts = props.productsList?.filter(product=>customizableProducts.includes(product.product_id))
      props.setProductsList(filteredProducts);
    }
  }

  function expandSearchHandle() {
    removeClassFromElement("productSearchMobileWrapper","display-none")
    focusOnInput("productSearchMainProductsPageMobile")
  }

 

  return (
    <div className='filter-header-wrapper full-width' style={{padding:"0 5px"}}>
       <div className="display-flex-row  space-between filters-container full-width hide-in-mobile padding10">

      <div className="product-filters display-flex-row ">
        {/* <div className="display-flex-row full-width"> */}
        {/* <div className="pointer products-toggle-wrapper padding10">
          <div className="display-flex-row border padding10">
            <IonCheckbox slot="end" color="primary" mode='md' onIonChange={(e)=>(toggleProducts(e.detail.checked))}/>
            <div className="font-small  center-aligned-column products-toggle marginleft5 heading3" data-value = "customizable"   style={{padding:"10 12px"}}>Customizable</div>
          </div>
        </div> */}

        {props.isSearchMode?
          <Button2
            configObj={{}}
            action={()=>deactiveSearchModel()}
            label={"Clear search result"}
            fontAwesomeIcon={"fas fa-times color-primary"}
            iconPos="left"
            classNames={"no-shadow border"}
          />
        :
        <div className="center-aligned-column">
          <div className="center-aligned-row breadcrumb">
            <span className="inactive color-black pointer heading2" onClick={()=>{goback()}}>{props.currSelectedCategory} <span className="padding10 color-dark">/</span> </span>
            <span className="active color-black heading2"> {props.currSelectedSubCategory}</span>
          </div>
        </div>
        }
         
      </div>
      {/* <div className='heading1 center-aligned-column'>Products</div> */}
      <div className="" style={{width:"350px"}}>
     <ProductsSearch
        configObj={applicationConfig}
        currSelectedCategory={props.currSelectedCategory}
        activateSearchMode={activateSearchMode}
        filterProductsWithCategory={filterProductsWithCategory}
        filterProductsWithSubCategory={filterProductsWithSubCategory}
        idSuffix={"MainProductsPage"}
     />
     </div>
    </div>


    <div className="hide-in-desktop full-width  filters-container ">
      <div className='full-width display-flex-row no-wrap position-relative space-between'>
      {props.isSearchMode?
          <Button2
            configObj={{}}
            action={()=>deactiveSearchModel()}
            label={"Clear search"}
            fontAwesomeIcon={"fas fa-times color-primary"}
            iconPos="left"
            classNames={"no-shadow no-border"}
          />
        :
        <>

          {getUserType().toLowerCase() !== "customer"?
            <>
              <div className="">
              <Button2
                configObj={{}}
                action={goback}
                label={"Go back"}
                fontAwesomeIcon={"fas fa-chevron-left color-danger"}
                iconPos="left"
                classNames={"no-shadow margin0 bg-color-light"}
              />
            </div>

            

            <div className="middle">
              <div className='display-flex-row' style={{maxWidth:"100px",overflow:"hidden"}}>
                <Button2
                  configObj={{}}
                  action={()=>toggleFiltersWindowMobile()}
                  label={props.currSelectedSubCategory}
                  fontAwesomeIcon={"fas fa-chevron-down color-primary"}
                  iconPos="right"
                  classNames={"margin0 no-shadow bg-color-"}
                />
              </div>
            </div>
            </>
          :null}
          
        </>
        }
        <div className="center-aligned-column">
          <div className='display-flex-row'>
            <Button2
              configObj={{}}
              action={expandSearchHandle}
              label={""}
              fontAwesomeIcon={"fas fa-search color-black"}
              iconPos="right"
              classNames={"shadow-light margin0 no-shadow"}
            />
          </div>
        </div>

        <div className='full-width top-left bg-color-white display-none padding0' id="productSearchMobileWrapper" style={{zIndex:1001}}>
          <div className='display-flex-row space-between white-card no-shadow'>
            <div className='marginTop5' style={{flex:"1"}}>
              <ProductsSearch
                configObj={applicationConfig}
                currSelectedCategory={props.currSelectedCategory}
                activateSearchMode={activateSearchMode}
                filterProductsWithCategory={filterProductsWithCategory}
                filterProductsWithSubCategory={filterProductsWithSubCategory}
                idSuffix={"MainProductsPageMobile"}
              />
            </div>
            
            <Button2
              configObj={{}}
              action={()=>addClassInElement("productSearchMobileWrapper","display-none")}
              label={""}
              fontAwesomeIcon={"fas fa-times color-primary"}
              iconPos="right"
              classNames={"shadow-light marginTop5 border"}
            />
          </div>
          
        </div>

      </div>
    </div>
    </div>
  );
};

export default FilterHeaderContent;
