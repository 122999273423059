import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Instruction, SofaConfiguration } from "../../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import { getFormattedPrice } from "../../../../../../utils/products/productviewer_shopify";

const Summary: React.FC<{
  currSelectedWindowIndex:number
  customizingOptions:any
  product:any
  productConfiguration:SofaConfiguration
  setCurrSelectedWindowIndex:(id:number)=>void
  confirmHandle:()=>void
}> = (props) => {

  const[advanceOptions,setAdvanceOptions] = useState([])
  const[instructions] = useState(props.productConfiguration.instructions)

  
  useEffect(()=>{
    setAdvanceOptions(props.customizingOptions.filter(currOption => !currOption.name.includes("Instructions")))
  },[props.customizingOptions])

  function goToHandle(customizationOption:any,index:number) {
    // setCurrCustomizeOption(customizationOption)
    // setCurrWindowIndex(index)
    // setIsOpenOptions(true)
    // return
    $(`#AdvanceOptionsSummary`).fadeOut(500)
    setTimeout(() => {
      $(`#ConfigurationSelector_${index}`).fadeIn(500)
      props.setCurrSelectedWindowIndex(index)
    }, 500);
  }

  function addRemoveOptions() {
    $(`#AdvanceOptionsSummary`).fadeOut(0)
    props.setCurrSelectedWindowIndex(0)
    $("#productConfiguratorContainer").fadeOut(0)
    setTimeout(() => {
      $("#selectOptionsContainer").fadeIn(0)
    }, 500);
  }

  function confirmHandle() {
    props.productConfiguration.updatePrice()
    props.confirmHandle()

  }

  function getAddonsLength() {
    let count = 0
    for (const currAddon of props.productConfiguration.addon) {
        count = count + currAddon.quantity
    }
    return count
  }

  return (
    <div className="full-height display-flex-column no-wrap" id={`AdvanceOptionsSummary`} style={{display:"none"}}>
        
        <div className="heading1 pc-color-primary font-large text-center padding10 header-content">Summary</div>

        <div className="padding-container no-wrap overflow-y-scroll body-content">

          <div className="white-card shadow-light ion-padding position-relative">
            <div className="display-flex-row no-wrap space-between">
                <div className="heading2 pc-color-primary marginBottom5">Advance Customization</div>
                <Button2
                  configObj={{id:""}}
                  action={addRemoveOptions}
                  label={`Add / Remove Options`}
                  fontAwesomeIcon={"fas fa-edit color-warning"}
                  iconPos="left"
                  classNames={"no-shadow text-center button-ex-small bg-color-warning-outline"}
                />
            </div>
            {advanceOptions.map((currOption:any,index:number)=>{
              return(
                <div key={index} className="white-card position-relative bg-color-light shadow-light padding10 display-flex-row marginTop10">
                  <span className="heading3 accordion-badge">{index+1}</span>
                  <span className="heading3 center-aligned-column pc-color-primary ">{currOption.name}</span>
                  <div className="right-middle">
                    <div className="display-flex-row no-wrap">
                      <div className="heading marginRight5 center-aligned-column text-ellipsis-mobile color-danger">{currOption.name.toLowerCase().includes("addon")?`${getAddonsLength()} Addons selected`:currOption.currSelectedValue}</div>
                      <Button2
                        configObj={{id:""}}
                        action={()=>{goToHandle(currOption,index)}}
                        label={`Change`}
                        fontAwesomeIcon={"fas fa-arrow-right color-primary"}
                        iconPos=""
                        classNames={"no-shadow text-center button-ex-small bg-color-warning-outline"}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="white-card shadow-light ion-padding position-relative marginTop10">
            <div className="heading2 pc-color-primary marginBottom5">Instructions</div>
            {instructions.length?
              instructions.map((instruction:Instruction,j:number)=>{
                return(
                  <div key={j} className="white-card position-relative no-shadow padding10 border display-flex-row marginTop10">
                    <span className="heading3 color-dark">{j+1}. </span>
                    <span className="marginleft10 heading4 font-normal  center-aligned-column color-black">{instruction.instruction}</span>
                    <div className="right-middle">
                      <div className="display-flex-row">
                        <span className="marginRight10">{getFormattedPrice(instruction.price)}</span>
                      </div>
                    </div>
                  </div>
                )
              })
            :
              <div className="middle heading4 font-normal">
                No Instructions Added
              </div>
            }
          </div>


        </div>


        <div className="center-aligned-column footer-content" style={{bottom:"0"}}>
            <Button2
              configObj={{id:""}}
              action={confirmHandle}
              label={"Confirm"}
              fontAwesomeIcon={"fas fa-arrow-right color-primary"}
              iconPos=""
              classNames={"no-shadow button-large button-fixed-width text-center pc-bg-color-primary"}
            />
        </div>
        
      
    </div>
  );
};

export default Summary;
