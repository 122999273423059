import { IonAlert, IonIcon } from "@ionic/react";
import { trash } from "ionicons/icons";
import React, { useState } from "react";
import { getUserType } from "../../../../utils/methods";


const DeleteLink: React.FC<{
  configObj: any;
  action:()=>void
}> = (props) => {

  const[showDeleteAlert,setShowDeleteAlert] = useState(false)


 
  return (
    <React.Fragment>
            <IonAlert
        isOpen={showDeleteAlert}
        mode="ios"
        onDidDismiss={() => setShowDeleteAlert(false)}
        cssClass="my-custom-class"
        header={"Confirm!"}
        message={"Delete <strong>item</strong>!!!"}
        buttons={[{
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              props.action();
            },
          },
        ]}
      />
    {getUserType()?.toLowerCase()==="admin"?
            <IonIcon className="font-normal pointer color-danger" icon={trash} onClick={()=>setShowDeleteAlert(true)}></IonIcon>
          :null}
    </React.Fragment>
  
  );
};

export default DeleteLink;
