import React, { useEffect, useState } from "react";
import { Customer, STORE } from "../../../../../utils/store/storeConfiguration";
import Modal from "../../../../common/shared-components/Modals/Modal";
import CustomerSessionWindow from "./CustomerSessionWindow";

 

const StartCustomerSession: React.FC<{
  createCustomerCallback:(customer:Customer)=>void
  isOpen:boolean
  setIsOpen:(val:boolean)=>void
}> = (props) => {

  const[activeCustomer,setActiveCustomer] = useState(STORE.activeCustomer)

 
  function setCustomerHandle(customer:Customer) {
    setActiveCustomer(customer)
    STORE.setActiveCustomer(customer)
    props.createCustomerCallback(customer)
    props.setIsOpen(false)
  }

 
  function openAddCustomer(){
    props.setIsOpen(true)
  }

  function closeModal() {
    props.setIsOpen(false)
  }

 
  return (
    <Modal
        id=""
        classNames="large-x-modal"
        onDismiss={()=>props.setIsOpen(false)}
        isOpen={props.isOpen}
        Content={()=>(
          <CustomerSessionWindow closeModal={closeModal}  isStartSessionMode={true} createCustomerCallback={setCustomerHandle} />
        )}
        heading="Customer Session"
        FooterContent={()=>(<div></div>)}
        >
    </Modal>
  );
};

export default StartCustomerSession;
