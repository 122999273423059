import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { applicationConfig, getFilteredArray } from "../../../../../utils/methods";
import ProductsSearch from "../../../../common/ui-components/search/ProductsSearch";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import { IonIcon, IonModal } from "@ionic/react";
import { close } from "ionicons/icons";
import ProductsList from "../products/ProductsList";
import { focusOnInput } from "../../../../../utils/UI_methods/global";

 
const StoreProductSearch: React.FC<{
  isOpen:boolean
  setIsOpen:(val:boolean)=>void
}> = (props) => {


  const history = useHistory()

 
  const[searchedProducts,setProducts] = useState([])

  useEffect(()=>{
    setProducts([])
    STORE.functionRefs.closeProductSearch = closeProductSearch

    setTimeout(() => {
        focusOnInput("productSearch"+"CategoryProducts")
    }, 700);
  },[props.isOpen])
  
  function activateSearchMode(products:Array<any>,searchString:string) {
      // history.push("/search")
      // setTimeout(() => {
      //   STORE.functionRefs.setSearchedProductsList(products)
      // }, 500);
      setProducts(products)
  }

  function gotoCategory(category:string) {
    setProducts(getFilteredArray(applicationConfig?.data?.productsList,"category_name",category))
    // history.push(`/store/products/${category}`)
  }

  function gotoSubCategory(category:string,sub:string) {
    history.push(`/store/products/${category}`)
  }

  function closeProductSearch(){
    props.setIsOpen(false)
  }

  return (
    <div className="">
      <IonModal
          id=""
          cssClass="medium-x-modal"
          onDidDismiss={()=>props.setIsOpen(false)}
          isOpen={props.isOpen}
        >
        <div className="store-product-search ion-padding">
          <div className="display-flex-row full-width" style={{justifyContent:"flex-end"}}>
            <div className="" onClick={()=>{props.setIsOpen(false)}}>
              <IonIcon icon={close} class="font-ex-large color-dark"></IonIcon>
            </div>
          </div>
          <div className="body  white-card shadow-light border padding10">
            <div className="input-container">
              <ProductsSearch
                configObj={applicationConfig}
                currSelectedCategory={"Sofa"}
                activateSearchMode={activateSearchMode}
                filterProductsWithCategory={gotoCategory}
                filterProductsWithSubCategory={gotoSubCategory}
                idSuffix={"CategoryProducts"}
                hideCloseButton={true}
              />
            </div>
            <div className="results-container ion-padding ">
              <div className="center-aligned-row padding10 heading3 border-top">Search Results</div>
              <div className="overflow-y-scroll">
                <ProductsList productsList={searchedProducts} closeModal={()=>{props.setIsOpen(false)}}/>
              </div>
            </div>
          </div>
          

        </div>
        
      </IonModal>
        
    </div>
  );
};

export default StoreProductSearch;
