import React, { useEffect, useState } from 'react';
import { ProductViewer360 as ProductViewer } from '../../../../utils/products/productviewer_shopify';
// const {CloudView360} = require('../../../../utils/products/cloudViewer360.js');
import "../../../style/admin/productviewer.css";
import "../../../style/ui-components/accordion.css";
  
const ProductViewer360: React.FC<{
    id:string
    product:any
}> = (props) => {

    useEffect(()=>{
        if(props.product){
            let productViewer = new ProductViewer("product-viewer-360-wrapper","product-viewer-360-controls",props.product.product_name)
            setTimeout(() => {
                productViewer.init()
            }, 500);
        }
    },[props.product])

  
    return (
        <div className='full-height-width display-flex-row no-wrap padding10' id={props.id}>
           
            <div className='full-height product-viewer-360-wrapper position-relative' style={{width:"65%"}}>
                
            </div>


            <div className='full-height product-viewer-360-controls padding5 overflow-y-scroll' style={{width:"35%"}}>
                
            </div>
            
        </div>
    );
};

export default ProductViewer360;