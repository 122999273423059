import $ from "jquery";
import React, { useEffect, useState } from "react";
import { kitchenPlanner } from "../../../../utils/customizein3d/area3dmodel/area3dModel";
import { openGroupsListPanel } from "../../../../utils/customizein3d/area3dmodel/UI_methods";
import { addItemToGroup, createNewGroup } from "../../../../utils/kitchenplanner/groups";
import { resetObjectScale, scaleToFit } from "../../../../utils/kitchenplanner/helper";
import { applicationConfig } from "../../../../utils/methods";
import { showToast } from "../../../../utils/UI_methods/global";
import Button from "../../ui-components/buttons/Button";

const ModuleInfo: React.FC<{
}> = (props) => {

  const[refreshList,setRefreshList] =  useState(0)
  const[isShowGroupPanel,setIsShowGroupPanel] =  useState(false)
  const[currSelectedGroup,setCurrSelectedModuleGroup] =  useState(null)
  const[currSelectedGroupFromDropDown,setCurrSelectedModuleGroupFromDropDown] =  useState(null)

  const[groupsList,setGroupsList] =  useState([])

  
  useEffect(()=>{
    if(applicationConfig){
      applicationConfig.setFunctionRef("kitchenPlanner",{setCurrSelectedModuleGroup:setCurrSelectedModuleGroup})
      applicationConfig.setFunctionRef("kitchenPlanner",{setRefreshModulesList:setRefreshList})
    }
  },[applicationConfig])



  function createAndAddItemToGroupHandle() {
    let group = createNewGroup()
    group.name = String($("#groupNameInput").val())
    if(group){
      addItemToGroupHandle(group)
      setRefreshList(Number(new Date()))
      $("#newGroupNameWindow").addClass("display-none")
      showToast("New group has been created",3000)
    }
  }

  function showSetGroupNameWindow() {
    $("#newGroupNameWindow").removeClass("display-none")
  }


  function addItemToGroupHandle(group:any) {
    addItemToGroup(group)
    // setRefreshList(Number(new Date()))
    setIsShowGroupPanel(false)
  }

  function manageGroupsHadle(event:any) {
    openGroupsListPanel(event)
    applicationConfig.functions.kitchenPlanner.setRefreshGroupList(Number(new Date()))
  }

  function expandGroupsList() {
    setGroupsList(kitchenPlanner.groupsList)
    $("#groupsListInModulesInfo").toggleClass("display-none")
  }

  function groupChangeHandle(group:any) {
    $("#groupsListInModulesInfo").toggleClass("display-none")
    setCurrSelectedModuleGroupFromDropDown(group)
  }

  function confirmAddToGroup() {
    addItemToGroup(currSelectedGroupFromDropDown)
    setCurrSelectedModuleGroupFromDropDown(null)
    setIsShowGroupPanel(false)
  }

  function cancleAddToGroup() {
    setCurrSelectedModuleGroupFromDropDown(null)
    $("#newGroupNameWindow").addClass("display-none")
  }


  return (
    <>
    <div id="newGroupNameWindow" className="full-height-width top-left display-none" style={{zIndex:1002,paddingTop:"10px",background:"rgba(0,0,0,0.7)"}}>
      <div className="middle">
        <input type="text" id="groupNameInput" className="input" />
        <div className="display-flex-row no-wrap ion-margin-top">
          <Button
            configObj={{}}
            action={createAndAddItemToGroupHandle}
            label={"Confirm"}
            fontAwesomeIcon={"fas fa-check color-white"}
            iconPos="left"
            classNames={"no-shadow button-small  bg-color-primary"}
          />
          <Button
            configObj={{}}
            action={cancleAddToGroup}
            label={"Cancel"}
            fontAwesomeIcon={"fas fa-times color-danger"}
            iconPos="left"
            classNames={"no-shadow button-small bg-color-"}
          />
        </div>
      </div>
    </div>
    
    <div className="overflow-y-scroll position-relative padding10 full-height-width">

      <div className="display-flex-row no-wrap">

        <div className="image width30  padding5">
          <img src="assets/images/placeholder.png" className="full-width border" alt="" />
        </div>

        <div className="details module-details marginleft10" id="moduleDetails">
          <div className="heading3">Base Kitchen</div>
          <div className="heading4">Wall</div>
          <div className="dimesnions">
            <div className="display-flex-row">
              <div className="heading4">Width</div>
              <div className="heading4">498</div>
            </div>
            <div className="display-flex-row">
              <div className="heading4">Height</div>
              <div className="heading4">498</div>
            </div>
          </div>
        </div>
      </div>

      <div className="padding5">
        <div className="border">
          <div className="heading3 font-md-small padding5 bg-color-light">Scale</div>

          <div className="ion-padding center-aligned-row">
            <div className="display-flex-row no-wrap">
              <Button
                configObj={{}}
                action={scaleToFit}
                label={"Scale to fit"}
                fontAwesomeIcon={"fas fa-times color-danger"}
                iconPos=""
                classNames={"no-shadow button-small bg-color-primary"}
              />
              <Button
                configObj={{}}
                action={resetObjectScale}
                label={"Reset scale"}
                fontAwesomeIcon={"fas fa-times color-danger"}
                iconPos=""
                classNames={"no-shadow button-small bg-color-"}
              />
            </div>
            
          </div>
          
        </div>
      </div>

      <div className="padding5">
        <div className="border">
          <div className="heading3 font-md-small padding5 bg-color-light">Dimensions</div>

          <div className="padding5">
            <div className="display-flex-row space-around padding10">
              <div className="heading4 center-aligned-column">Length</div>
              <div className="display-flex-row no-wrap">
                <span className="padding5 border center-aligned-column"><i className="fas fa-minus font-small heading4"></i></span>
                <span className="border heading4 marginleft5 center-aligned-column font-small padding5">54</span>
                <span className="padding5 border marginleft5 center-aligned-column"><i className="fas fa-plus heading4 font-small"></i></span>
              </div>
            </div>

            <div className="display-flex-row space-around padding10">
              <div className="heading4 center-aligned-column">Breadth</div>
              <div className="display-flex-row no-wrap">
                <span className="padding5 border center-aligned-column"><i className="fas fa-minus font-small heading4"></i></span>
                <span className="border heading4 marginleft5 center-aligned-column font-small padding5">95</span>
                <span className="padding5 border marginleft5 center-aligned-column"><i className="fas fa-plus heading4 font-small"></i></span>
              </div>
            </div>
          </div>

        </div>
      </div>

      


      
      <div className="padding5">
        <div className="border">

          <div className="display-flex-row padding5 bg-color-light space-between">
            <div className="heading3 font-md-small">Group</div>
            <div className="heading4 font-small">{currSelectedGroup? currSelectedGroup.name : "Un-assigned"} </div>
            <div className="displ" onClick={()=>setIsShowGroupPanel(true)}><i className="fas fa-edit font-small color-black pointer"></i></div>
          </div>

          <div className={isShowGroupPanel?"padding5 position-relative":"display-none"} style={{height:"120px"}}>

            <div className="middle">
              {currSelectedGroup?
                <div className="heading4 color-success text-center">Module has been assigned to {currSelectedGroup.name}</div>
                :
                <>
                
                <div className="display-flex-row no-wrap">
                  <div className="position-relative">
                    <Button
                      configObj={{}}
                      action={expandGroupsList}
                      label={currSelectedGroupFromDropDown?currSelectedGroupFromDropDown.name:"Add to existing"}
                      fontAwesomeIcon={"fas fa-chevron-down color-danger"}
                      iconPos="left"
                      classNames={kitchenPlanner.groupsList.length?"no-shadow button-small bg-color-light":"display-none"}
                    />

                    <div className="top-left border bg-color-white padding0 full-width display-none marginleft5" id="groupsListInModulesInfo" style={{top:"35px"}}>
                      {groupsList.map((group:any,index:number)=>{
                        return(
                          <div key={index} onClick={()=>groupChangeHandle(group)} className="border-bottom pointer font-small padding5 heading4">{group.name}</div>
                        )
                      })}
                    </div>

                  </div>

                  {currSelectedGroupFromDropDown?
                    <div className="display-flex-row no-wrap">
                    <Button
                      configObj={{}}
                      action={confirmAddToGroup}
                      label={"Confirm"}
                      fontAwesomeIcon={"fas fa-check color-white"}
                      iconPos="left"
                      classNames={"no-shadow button-small bg-color-primary"}
                    />
                    <Button
                      configObj={{}}
                      action={cancleAddToGroup}
                      label={"Cancel"}
                      fontAwesomeIcon={"fas fa-times color-danger"}
                      iconPos="left"
                      classNames={"no-shadow button-small bg-color-"}
                    />
                    </div>
                    :
                    <Button
                      configObj={{}}
                      action={showSetGroupNameWindow}
                      label={"Add to new group"}
                      fontAwesomeIcon={"fas fa-plus color-danger"}
                      iconPos="left"
                      classNames={"no-shadow button-small bg-color-light"}
                    />
                  }
               

                
              </div>
                </>
              }
              

            </div>

            <a className="hyperlink bottom-right click-effect font-small padding5" onClick={manageGroupsHadle}>Manage groups</a>

          </div>

        </div>
      </div>

       

    </div>
    </>
  );
};

export default ModuleInfo;
