import html2canvas from "html2canvas";
import $ from "jquery";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { getModulePrice, stringWithoutWhiteSpace } from "../methods";
import { projectConfiguration } from "../customizein3d/area3dmodel/area3dModel";


let options = {
  orientation: 'p',
  unit: 'mm',
  format: 'a4',
  putOnlyUsedFonts:true,
  floatPrecision: 16 // or "smart", default is 16
 }
 var doc:any = null
 var paddingX = 8
 var paddingY = 30
 var width = 0
 var height = 0
 var imageWidth = width * 0.4
 var tableWidth = width * 0.6
 var imageHeight = 60

 var companyLogoHeight = 20
 var companyLogoWidth = companyLogoHeight * 16/9 + 10

export async function convertHtmlToPdf(moduleName:string,configObj:any,areas:any,shareItemName:string,isDownload = true) {
 
  doc = new jsPDF()
  width = doc.internal.pageSize.getWidth()
  height = doc.internal.pageSize.getHeight()
  imageWidth = 60
  tableWidth = width * 0.6
  paddingX = 8
  paddingY = 30


  doc.setFont("Poppins", "nomral", "normal")
  
  doc.setFont("Arial");

  await addHeader(shareItemName)
  addFooter()


  doc.setTextColor(0,0,0);

  //get table cell height from jspdf auto table cell 
  let tableCellHeight = 7.584

  if(moduleName.toLowerCase()==="customizer"){
    imageWidth = width * 0.25
    imageHeight = 40
    await addAreaProducts(configObj) 
  }
  else if(moduleName.toLowerCase()==="customizein3d"){
    await addModules(configObj,configObj.areaName,areas) 
  }
  else if(moduleName.toLowerCase() === "cart"){
    await addCartItem(areas)
  } 
  else if(moduleName === "storeQuotation"){
    await addStoreQuotation(areas)
  } 
  else{
    await areaAndConfig(areas,shareItemName,tableCellHeight)
  }


  let date = new Date()
  let docName = `${shareItemName}_${Number(date)}.pdf`


  if(isDownload){
    doc.save(docName)
    return
  }

  let blobUrl = null
  let blobPDF = null

  if(!isDownload){
    blobPDF =  new Blob([ doc.output("blob") ], { type : 'application/pdf'});
    blobUrl = URL.createObjectURL(blobPDF);
  }
  return blobUrl
}


async function areaAndConfig(areas:Array<any>,shareItemName:string,tableCellHeight:any,) {
  for (let i = 0; i < areas.length; i++) {
    const area = areas[i];
    let imageId = document.getElementById("shareAreaImage"+area.area_name);
    //Calculate table height
    let table = document.getElementById('table'+area.area_name)
    let totalRows = table?.getElementsByTagName("tr")
    let tableHeight = totalRows?.length * tableCellHeight

    doc.setFontSize(16);
    if(area.area_name!="Default"){
      doc.setFontSize(16);
      doc.text(area.area_name.toUpperCase(),paddingX,paddingY);
    }else{
      doc.text(shareItemName.toUpperCase(),paddingX,paddingY);
    }

    doc.setFontSize(10);
    doc.text("Total Price: 200000 INR",paddingX,paddingY+6);

    let imageData = imageId?.getAttribute("src")
    if(imageData){
      await doc.addImage(imageId.getAttribute("src"), "PNG", paddingX,paddingY+10,imageWidth,imageHeight, "", "FAST", 0);
    } 


    doc.autoTable({ html:'#table'+area.area_name,
      tableWidth:tableWidth-paddingX*2-2,startY:paddingY+10,margin:{left:imageWidth+2+paddingX},
      didDrawCell: function(data) {
        if (data.column.index === 0 && data.cell.section === 'body') {
           var td = data.cell.raw;
           var img = td.getElementsByTagName('img')[0];
           var dim = data.cell.height - data.cell.padding('horizontal');
           var textPos = data.cell;
           try {
            doc.addImage(img?.getAttribute("src"), textPos.x+5,  textPos.y+1.5, dim, dim);
          } catch (error) {
             
          }
        }
      },
    })
    //Image height plus padding
    let containerHeight = tableHeight + 20
    // if less then image height put image height 
    if(tableHeight < imageHeight){
      containerHeight = imageHeight + 20
    }

    paddingY = paddingY + containerHeight

    //add a page break after 3 items
    if((i+1)%3 === 0){
      doc.addPage()
      paddingX = 8
      paddingY = 30
    }
  }

}


async function areaViewAndConfig(configObj:any,areas:Array<any>,shareItemName:string,tableCellHeight:any,) {
  for (let i = 0; i < areas.length; i++) {
    const area = areas[i];
    let areaViews = configObj.areaViewsMapping[area.area_name]
    doc.setFontSize(16);
    if(area.area_name!="Default"){
      doc.setFontSize(16);
      doc.text(area.area_name.toUpperCase(),paddingX,paddingY);
    }else{
      doc.text(shareItemName.toUpperCase(),paddingX,paddingY);
    }

    doc.setFontSize(10);
    doc.text("Total Price: 200000 INR",paddingX,paddingY+6);

    for (let j = 0; j < areaViews.length; j++) {
      const view = areaViews[j];
      let imageId = document.getElementById("shareAreaImage"+area.area_name+view.view_name);
      //Calculate table height
      let table = document.getElementById('table'+area.area_name+view.view_name)
      let totalRows = table?.getElementsByTagName("tr")
      let tableHeight = totalRows?.length * tableCellHeight
  
      let imageData = imageId?.getAttribute("src")
      if(imageData){
        await doc.addImage(imageId.getAttribute("src"), "PNG", paddingX,paddingY+10,imageWidth,imageHeight, "", "FAST", 0);
      } 
  
  
      doc.autoTable({ html:'#table'+area.area_name+view.view_name,
        tableWidth:tableWidth-paddingX*2-2,startY:paddingY+10,margin:{left:imageWidth+2+paddingX},
        didDrawCell: function(data) {
          if (data.column.index === 0 && data.cell.section === 'body') {
             var td = data.cell.raw;
             var img = td.getElementsByTagName('img')[0];
             var dim = data.cell.height - data.cell.padding('horizontal');
             var textPos = data.cell;
             try {
              doc.addImage(img?.getAttribute("src"), textPos.x+5,  textPos.y+1.5, dim, dim);
            } catch (error) {
               
            }
          }
        },
      })
      //Image height plus padding
      let containerHeight = tableHeight + 20
      // if less then image height put image height 
      if(tableHeight < imageHeight){
        containerHeight = imageHeight + 20
      }
  
      paddingY = paddingY + containerHeight
  
      //add a page break after 3 items
      if((j+i+1)%3 === 0){
        doc.addPage()
        paddingX = 8
        paddingY = 30
      }
    }
  }

}

function getConfiguration(configObj:any,productName:string) {
  let areaConfig = configObj.getAreaModelConfiguration()
  let productConfig = areaConfig[productName]
  for (const partName in productConfig) {
    let partConfig = productConfig[partName] 
    if(partConfig?.companyName.includes("Default")){
      continue
    }
    return `${partConfig.companyName} / ${partConfig.collectionName} / ${partConfig.materialCode}`
  }
  return "Default / Default / Default"
}


async function addAreaProducts(configObj:any) {
  const areasList = projectConfiguration.areasList

  for (const area of areasList) {
    const products = projectConfiguration.projectConfiguration[area.area_name].areaConfiguration
    console.log(area,products)
    if(Object.keys(products).length){
      console.log(configObj,products,area.area_name)
      await addProducts(configObj,products,area.area_name)
    }
  }
}


async function addProducts(configObj:any,products:Array<any>,areaName:string) {
  
  
  var i = 0
  for (const key in products) {
    i = 0
    const productInstances = products[key]
    for (const currProductKey in productInstances) {

    const productInstance = productInstances[currProductKey]
    const productName = productInstances[currProductKey].productName
    // let productConfig = getConfiguration(configObj,productName)
    let productConfig = JSON.stringify(productInstance.configuration)

  
    doc.setFontSize(14);
    doc.text(productName,imageWidth + 10, paddingY)

    doc.setFontSize(10);
    doc.text(`Dimensions: Width: 950 Cm, Height: 680 Cm, Depth: 580 Cm`,imageWidth + 10, paddingY + 7)

    doc.setFontSize(10);
    doc.text(`Configuration: ${productConfig}`,imageWidth + 10, paddingY + 14)

    doc.setFontSize(12);
    doc.text(`${productInstance.categoryName} / ${productInstance?.subCategoryName}`,imageWidth + 10, paddingY + 21)
    
    doc.setFontSize(14);
    doc.text(`${productInstance.price || 0} .00 INR`,imageWidth + 110, paddingY + 10)
    
    let imageId = document.getElementById("shareAreaImage"+areaName+productName);
    //Calculate table height
    let imageData = imageId?.getAttribute("src")
    if(imageData){
      await doc.addImage(imageId.getAttribute("src"), "PNG", paddingX,paddingY,imageWidth,imageHeight, "", "FAST", 0);
    } 
    
    paddingY = paddingY + imageHeight + 15
    if((i+1)%5 === 0){
      doc.addPage()
      paddingX = 8
      paddingY = 30
    }
    i += 1
    }
  }
}

async function addModules(configObj:any,areaName:string,config:Array<any>) {

  let imageId = document.getElementById("shareProductImageNormalMode");
  let imageData = imageId?.getAttribute("src")
  let aspectRatio = imageId.clientWidth / imageId.clientHeight
  if(imageData){
    await doc.addImage(imageId.getAttribute("src"), "PNG", paddingX,paddingY,imageWidth,imageWidth / aspectRatio, "", "FAST", 0);
  } 

  doc.setFontSize(14);
  doc.text("Total Price",imageWidth + paddingX + 15 ,paddingY + 5)

  doc.setFontSize(20);
  let totalPrice:any = $("#summaryTotalPrice").text() + ".00 INR"
  doc.text(totalPrice,imageWidth + paddingX + 15 ,paddingY + 15)

  // doc.setFontSize(12);
  // let breadth:any = $("#summaryRoomBreadth").text()
  // doc.text(`Breadth : ${breadth}`,imageWidth + paddingX + 10 ,paddingY + 20)

  paddingY = paddingY + imageHeight + 20

  doc.setFontSize(14);
  doc.text("Modules List",paddingX,paddingY)

  paddingY = paddingY + 10

  for (let i = 0; i < config.length; i++) {
    const moduleConfig = config[i]

    let finish = ''
    let collectionName = ''
    for (const key in moduleConfig.configuration) {
        finish = `${moduleConfig.configuration[key].companyName} / ${moduleConfig.configuration[key].collectionName} / ${moduleConfig.configuration[key].materialCode}`
        collectionName = moduleConfig.configuration[key].collectionName
    }
    let price = getModulePrice(configObj.data?.productModulesPrice,collectionName,moduleConfig.moduleId)
   
  
    doc.setFontSize(12);
    let moduleName = i+1 + " ) " + moduleConfig.displayName
    doc.text(moduleName,paddingX,paddingY)

    doc.setFontSize(10);
    doc.text(`Dimensions: Width: 950 Cm, Height: 680 Cm, Depth: 580 Cm`,paddingX, paddingY + 7)

    doc.setFontSize(10);
    doc.text(`Configuration: ${collectionName}`,paddingX, paddingY + 14)

    doc.setFontSize(12);
    
    doc.setFontSize(14);
    doc.text(`${price} .00 INR`,imageWidth + 50, paddingY + 10)
    
    paddingY = paddingY + 25
    
  }

}



async function addCartItem(itemDetails:any) {

  let imageId = document.getElementById(itemDetails.imageId);
  let imageData = imageId?.getAttribute("src")
  imageWidth = 80
  if(imageData){
    await doc.addImage(imageId.getAttribute("src"), "PNG", paddingX,paddingY,imageWidth,imageHeight, "", "FAST", 0);
  } 

  doc.setFontSize(14);
  doc.text("Total Price",imageWidth + paddingX + 15 ,paddingY + 5)

  doc.setFontSize(20);
  doc.text(itemDetails.price + ".00 INR",imageWidth + paddingX + 15 ,paddingY + 15)

  // doc.setFontSize(12);
  // let breadth:any = $("#summaryRoomBreadth").text()
  // doc.text(`Breadth : ${breadth}`,imageWidth + paddingX + 10 ,paddingY + 20)

  paddingY = paddingY + imageHeight + 20

  doc.setFontSize(14);
  doc.text("Product info",paddingX,paddingY)

  let modules = itemDetails.modules.join(" ")

  paddingY = paddingY + 10
 
    doc.setFontSize(12);
    doc.text(itemDetails.productName,paddingX,paddingY)

    doc.setFontSize(10);
    doc.text(`Modules: ${modules}`,paddingX, paddingY + 7)

    doc.setFontSize(10);
    doc.text(`Configuration: ${itemDetails.finish}`,paddingX, paddingY + 14)

    doc.setFontSize(12);
    
    doc.setFontSize(14);
    doc.text(`${itemDetails.price} .00 INR`,imageWidth + 70, paddingY + 5)
    

}


async function addStoreQuotation(items:Array<any>) {

  console.log(items)
  return

  for (let i = 0; i < items.length; i++) {

    const currItem = items[i]

    const productName = currItem.product_name
    // let productConfig = getConfiguration(configObj,productName)

  
    doc.setFontSize(14);
    doc.text(productName,imageWidth + 10, paddingY)

    doc.setFontSize(10);
    doc.text(`Dimensions: Width: 950 Cm, Height: 680 Cm, Depth: 580 Cm`,imageWidth + 10, paddingY + 7)

    // doc.setFontSize(10);
    // doc.text(`Configuration: ${productConfig}`,imageWidth + 10, paddingY + 14)

    // doc.setFontSize(12);
    // doc.text(`${productInstance.categoryName} / ${productInstance?.subCategoryName}`,imageWidth + 10, paddingY + 21)
    
    doc.setFontSize(14);
    doc.text(`${currItem.price || 0} .00 INR`,imageWidth + 110, paddingY + 10)
    
    let imageId = document.getElementById("shareAreaImage"+productName);
    //Calculate table height
    let imageData = imageId?.getAttribute("src")
    if(imageData){
      await doc.addImage(imageId.getAttribute("src"), "PNG", paddingX,paddingY,imageWidth,imageHeight, "", "FAST", 0);
    } 
    
    paddingY = paddingY + imageHeight + 15
      if((i+1)%5 === 0){
        doc.addPage()
        paddingX = 8
        paddingY = 30
      }
      i += 1
  }

}





export function addImagesToPdf(areas:any) {
  const doc:any = new jsPDF()
  // var pdf = new jsPDF() 
  var width = doc.internal.pageSize.getWidth();
  let pos = 10
  for (let i = 0; i < areas.length; i++) {
    const area = areas[i];
    let elementId = `shareScreenshot${area.area_name}`
    convertHtmlToImage(elementId).then((blob:any)=>{
      doc.addImage(blob, "PNG", 0, pos, width,100, "", "FAST", 0);
      pos = pos + 110
      if(i===areas.length-1){
        doc.save('table.pdf')
      }
      if(i===2){
        doc.addPage()
      }
    })
  }
 
}


async function addHeader(shareItemName:string) {
  //Header text and image
  doc.setFontSize(20);
  doc.text(shareItemName,width/2-20,13);
  doc.setLineWidth(0.01);
  
  //Company logo
  let companyLogoId = document.getElementById("companyLogo");
  let companyLogoData = companyLogoId?.getAttribute("src")
  if(companyLogoData){
    await doc.addImage(companyLogoData,"PNG",paddingX,0,companyLogoWidth,companyLogoHeight,"", "FAST",0);
  } 
  
  //x0,y0,x1,y1
  doc.line(paddingX,20,width-paddingX,20);
}

function addFooter() {
  //Add footer to page
  doc.setFontSize(10);
  doc.setTextColor(161,161,161);
  doc.text("Powered by Ominiviewlabs",paddingX,height-5);
}

export function convertHtmlToImage(elementId:string) {
  return new Promise((resolve,reject)=>{
      var container:any = document.getElementById("screenshotClone")!
      const targetElement = document.getElementById(elementId)!.cloneNode(true);
      container.appendChild(targetElement);
      // const option = {allowTaint: true, useCORS: true};
      html2canvas(container).then(function(canvas) {
          try {
            let blobData = canvas.toDataURL("image/png")
            container.firstChild.remove();
            resolve(blobData)
          } catch (error) {
            reject(error)
          }
      });
  })
}



function addTable () {
  var table1 = tableToJson($("#Room1table").get(0)),
    cellWidth = 35,
    rowCount = 0,
    cellContents,
    leftMargin = 2,
    topMargin = 12,
    topMarginTable = 55,
    headerRowHeight = 13,
    rowHeight = 9,
    l = {
      orientation: "l",
      unit: "mm",
      format: "a3",
      compress: true,
      fontSize: 8,
      lineHeight: 1,
      autoSize: false,
      printHeaders: true,
    };

  var doc = new jsPDF();

  doc.setProperties({
    title: "Test PDF Document",
    subject: "This is the subject",
    author: "author",
    keywords: "generated, javascript, web 2.0, ajax",
    creator: "author",
  });

  // doc.cellInitialize();

  $.each(table1, function (i, row) {
    rowCount++;

    $.each(row, function (j, cellContent) {
      if (rowCount == 1) {
        doc.setFont("helvetica");
        doc.setFontSize(9);

        doc.cell(
          leftMargin,
          topMargin,
          cellWidth,
          headerRowHeight,
          cellContent,
          i,
          "left"
        );
      } else if (rowCount == 2) {
        doc.setFont("times ");
        doc.setFontSize(8);

        doc.cell(
          leftMargin,
          topMargin,
          cellWidth,
          rowHeight,
          cellContent,
          i,
          "left"
        );
      } else {
        doc.setFont("courier ");
        doc.setFontSize(6.5);

        doc.cell(
          leftMargin,
          topMargin,
          cellWidth,
          rowHeight,
          cellContent,
          i,
          "left"
        ); // 1st=left margin    2nd parameter=top margin,     3rd=row cell width      4th=Row height
      }
    });
  });

  doc.save("sample Report.pdf");
}

function tableToJson(table) {
  var data = [];

  // first row needs to be headers
  var headers = [];
  for (var i = 0; i < table.rows[0].cells.length; i++) {
    headers[i] = table.rows[0].cells[i].innerHTML
      .toLowerCase()
      .replace(/ /gi, "");
  }

  // go through cells
  for (var i = 1; i < table.rows.length; i++) {
    var tableRow = table.rows[i];
    var rowData = {};

    for (var j = 0; j < tableRow.cells.length; j++) {
      rowData[headers[j]] = tableRow.cells[j].innerHTML;
    }

    data.push(rowData);
  }

  return data;
}
 