import { IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getObjectArrayOfDistictValues } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import DropDown from "../../../../common/ui-components/filters/DropDown2";
import OrdersList from "./OrdersList";
import Search from "./Search";
import MainHeader from "../common/MainHeader";
import SubHeader from "./common/SubHeader";

 

const StoreOrders: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {
  const[ordersList,setOrdersList] = useState([])
  const[orderItemsList,setOrderItemsList] = useState([])


  useEffect(()=>{
    if(STORE.activeCustomer){
      fetchOrders()
    }
  },[])

  function fetchOrders() {
    STORE.storeApi.orders.getOrders(STORE.activeCustomer.id).then((data:any)=>{
      if(!data.error){
        if(data.data?.data?.length){
          const orderItems = data.data.data 
          setOrderItemsList(orderItems)
          const orders = getObjectArrayOfDistictValues(orderItems,"order_id")
          setOrdersList(orders)
        }
      }
    })
  }

  function Header() {
    return(
      <div className="full-width position-relative display-flex-row space-between border-bottom bg-color-light calendar-header" style={{padding:"10px 0",height:"fit-content"}}>
        <div className="center-aligned-column">
          <div className="date-filter">
              <DropDown
                classNames={""}
                values={["Last created"]}
                currSelectedValue={"Last created"}
                action={()=>{}}
                label="Sort by"
              />
            </div>
        </div>
        <Search/>
    </div>  
    )
  }
 
 
  return (
  //   <IonPage>
  //   {props.configObj && props.isLogin?
  //   <div className="full-height-width bg-color-white">
  //     <MainHeader/>
  //     {/* <SubHeader/> */}
  //     <MainSubHeader heading="Orders"/>
  //     <div className="full-height-width overflow-y-scroll padding-container display-flex-row no-wrap product-filters-wrapper">
  //         <OrdersList/>
  //     </div>
  //   </div>
  //   :null}
  // </IonPage>
  <IonPage>
    {props.isLogin?
      <div className="full-height-width bg-color-white orders-list-wrapper">
      <MainHeader />
      <SubHeader heading="Orders"/>
      <div className="display-flex-row no-wrap crm-main-content-wrapper padding-container bg-color-light">
      <div className="full-height-width">

          <Header/>

          <div className="display-flex-row no-wrap full-height">
            <div className="border full-height white-card shadow-light display-flex-column" style={{width:"100%"}}>
              <div className="full-width  full-height hide-scrollbar">
                
                <OrdersList/>  
          
              </div>
            </div>
          </div>

          </div>
        
      </div>
    </div>
    :null}
</IonPage>
  );
};

export default StoreOrders;
