import { projectConfiguration } from "../customizein3d/area3dmodel/area3dModel";
import { isDeviceIpadPotrait } from "../customizein3d/modules/UI_methods";
import { getWindowWidth } from "../UI_methods/global";

export function getPageTourElements(moduleName: string) {
  let tourElementsList = [];
  switch (moduleName) {
    case "Customizer":
      tourElementsList = getCustomizerElements();
      break;

    case "Customizein3D":
      tourElementsList = getCustomizein3DElements();
    break;

    case "Moodboard":
      tourElementsList = getMoodboardElements();
      break;

    case "VirtualTour":
      tourElementsList = getVirtualTourElements();
      break;

    case "CreateRender2d":
      tourElementsList = getCreateRender2dElements();
      break;

    case "CreateRender3d":
      tourElementsList = getCreateRender3dElements();
    break;

    case "VariantCreator3D":
      tourElementsList = getVariantCreator3DElements();
    break;
  

    default:
      break;
  }
  return tourElementsList || [];
}

function getCustomizerElements() {
  let windowWidth = getWindowWidth();
  if (windowWidth > 480) {
    return [
      {
        elementId: "floorplanAreasPanel",
        calloutMessage: "Set anchors and walls",
        calloutPosition: "top",
        nextButtonPosition: "topRight",
      },
      {
        elementId: "floorplanConfirmButton",
        calloutMessage: "Save progress",
        calloutPosition: "bottom",
        nextButtonPosition: "topLeft",
      },
      // {
      //   elementId: "customizerControlsTabs",
      //   calloutMessage: "Customize finish",
      //   calloutPosition: "top",
      //   nextButtonPosition: "topLeft",
      // },
      // {
      //   elementId: "customizerSaveButton",
      //   calloutMessage: "Go to next step",
      //   calloutPosition: "top",
      //   nextButtonPosition: "topRight",
      // },
    ];
  }

  return [
    {
      elementId: "multistepsWrapper",
      calloutMessage: "Track progress",
      calloutPosition: "bottom",
      nextButtonPosition: "leftBottom",
    },
    {
      elementId: "customizerControlsTabs",
      calloutMessage: "Customize finish",
      calloutPosition: "top",
      nextButtonPosition: "bottomLeft",
    },
    {
      elementId: "customizerSaveButton",
      calloutMessage: "Go to next step",
      calloutPosition: "top",
      nextButtonPosition: "topLeft",
    },
  ];
}

function getCustomizein3DElements() {
  let windowWidth = getWindowWidth();
  let commonElements = [
    {
      elementId: "customizeButton",
      calloutMessage: "Customize Product",
      calloutPosition: "top",
      nextButtonPosition: "topRight",
    },
    {
      elementId: "spacePlannerToggle",
      calloutMessage: "View in 2D",
      calloutPosition: "right",
      nextButtonPosition: "bottomRight",
    },
    {
      elementId: "arButtonProduct",
      calloutMessage: "View in Ar",
      calloutPosition: "bottom",
      nextButtonPosition: "topLeft",
    },
    {
      elementId: "addToCartMobile",
      calloutMessage: "Shortlist your configuration",
      calloutPosition: "top",
      nextButtonPosition: "topLeft",
    },
  ];
  if (windowWidth > 480) {
    return commonElements
  }
  return commonElements
}

function getVariantCreator3DElements() {
  let windowWidth = getWindowWidth();
  let commonElements = [
    {
      elementId: "elevateControlsWrapper",
      calloutMessage: "Customize Product",
      calloutPosition: "bottom",
      nextButtonPosition: "topLeft",
    },
    {
      elementId: "customizein3dRightBottom",
      calloutMessage: "Convert Dimensions",
      calloutPosition: "top",
      nextButtonPosition: "topRight",
    },
    {
      elementId: "spacePlannerToggle",
      calloutMessage: "View in 2D",
      calloutPosition: "right",
      nextButtonPosition: "bottomRight",
    },
    {
      elementId: "arButtonProduct",
      calloutMessage: "View in Ar",
      calloutPosition: "bottom",
      nextButtonPosition: "topRight",
    },
    // {
    //   elementId: "dimensionToggle",
    //   calloutMessage: "View Dimensions",
    //   calloutPosition: "top",
    //   nextButtonPosition: "topRight",
    // },
  ];
  if (windowWidth > 480) {
    return commonElements
  }
  return commonElements
}




function getMoodboardElements() {
  let windowWidth = getWindowWidth();
  if (windowWidth > 480) {
    return [
      {
        elementId:"customizein3dActionTabs",
        calloutMessage:"Add items to canvas",
        calloutPosition:"right",
        nextButtonPosition:"topRight"
      },
      {
        elementId:"moodboardAddedItemsList",
        calloutMessage:"Track added Items list",
        calloutPosition:"top",
        nextButtonPosition:"topLeft"
      },
     
      {
        elementId:"summaryButtonDesktop",
        calloutMessage:"View pricing",
        calloutPosition:"bottom",
        nextButtonPosition:"topLeft"
  
      },
      {
        elementId:"saveButtonDesktop",
        calloutMessage:"Save your progress",
        calloutPosition:"top",
        nextButtonPosition:"topLeft"
      }
    ]
  }

  return [
    {
      elementId:"addItemsButtonMobile",
      calloutMessage:"Add items to canvas",
      calloutPosition:"right",
      nextButtonPosition:"topRight"
    },
    {
      elementId:"summaryButton",
      calloutMessage:"View pricing",
      calloutPosition:"top",
      nextButtonPosition:"rightTop"
    },
    {
      elementId:"saveButtonMobile",
      calloutMessage:"Save your progress",
      calloutPosition:"left",
      nextButtonPosition:"leftBottom"
    }
  ]
}



function getVirtualTourElements() {
  let windowWidth = getWindowWidth();
  if (windowWidth > 480) {
    return [
      {
        elementId:"virtualTourAreaWrapper",
        calloutMessage:"Switch areas",
        calloutPosition:"top",
        nextButtonPosition:"topRight"
      },
      {
        elementId:"virtualTourAreaLink",
        calloutMessage:"Go to next area",
        calloutPosition:"top",
        nextButtonPosition:"topRight"
      },
      {
        elementId:"virtualTourCustomizeLink",
        calloutMessage:"Customize this room",
        calloutPosition:"top",
        nextButtonPosition:"topRight"
      },
      {
        elementId:"leftSidebarToggle",
        calloutMessage:"Track product and price",
        calloutPosition:"top",
        nextButtonPosition:"topLeft"
      },
    
    ]
  }

  return [
    {
      elementId:"openMinimap",
      calloutMessage:"Switch areas",
      calloutPosition:"top",
      nextButtonPosition:"topRight"
    },
    {
      elementId:"virtualTourAreaLink",
      calloutMessage:"Change Area",
      calloutPosition:"top",
      nextButtonPosition:"topRight"
    },
    {
      elementId:"virtualTourCustomizeLink",
      calloutMessage:"Change Finish",
      calloutPosition:"top",
      nextButtonPosition:"topRight"
    },
 
   
  ]
}



function getCreateRender2dElements() {
  let windowWidth = getWindowWidth();
  if (windowWidth > 480) {
    return [
      {
        elementId:`areaAnchor${projectConfiguration.projectName}`,
        calloutMessage:"Update camera position",
        calloutPosition:"top",
        nextButtonPosition:"topRight"
      },
      {
        elementId:`cameraSettingsWrapper`,
        calloutMessage:"Update camera setting",
        calloutPosition:"top",
        nextButtonPosition:"topLeft"
      },
      {
        elementId:`finalizeRenderButton`,
        calloutMessage:"Finalize Render",
        calloutPosition:"top",
        nextButtonPosition:"topLeft"
      },
    ]
  }

  return [
    {
      elementId:`areaAnchor${projectConfiguration.projectName}`,
      calloutMessage:"Update camera position",
      calloutPosition:"top",
      nextButtonPosition:"topRight"
    },
    {
      elementId:`finalizeRenderButton`,
      calloutMessage:"Finalize Render",
      calloutPosition:"top",
      nextButtonPosition:"topLeft"
    },
 
   
  ]
}



function getCreateRender3dElements() {
  let windowWidth = getWindowWidth();
  if (windowWidth > 480) {
    return [
      {
        elementId:`addCameraButton`,
        calloutMessage:"add Cameras",
        calloutPosition:"top",
        nextButtonPosition:"topRight"
      },
      {
        elementId:`finalizeRenderButton`,
        calloutMessage:"Update walkthrough",
        calloutPosition:"left",
        nextButtonPosition:"topRight"
      },
    ]
  }

  return [
    {
      elementId:`areaAnchor${projectConfiguration.projectName}`,
      calloutMessage:"Update camera position",
      calloutPosition:"top",
      nextButtonPosition:"topRight"
    },
    {
      elementId:`finalizeRenderButton`,
      calloutMessage:"Track the progress",
      calloutPosition:"top",
      nextButtonPosition:"topLeft"
    },
 
   
  ]
}