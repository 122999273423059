import { IonSpinner } from '@ionic/react';
import { image } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { customizerConfig, projectConfiguration } from '../../../../../../../utils/customizein3d/area3dmodel/area3dModel';
import { getProjectSavedConfigs } from '../../../../../../../utils/customizer/customizer';
import { applicationConfig, getFilteredArray } from '../../../../../../../utils/methods';
import ControlsTab from '../../../../../../common/ui-components/tabs/ControlsTab';
import Render2d from './Render2d';

const Gallery: React.FC<{
  setCurrStep:(val:string)=>void
}> = (props) => {

  let history = useHistory()

  const [currTab, setCurrTab] = useState<string>("2d");
  const[rendersList,setRendersList] = useState([])
  const[allRendersList,setAllRendersList] = useState([])
  const[isCreatingRender,setIsCreatingRender] = useState(false)
  const[refreshRenders,setRefreshRenders] = useState(Number(new Date()))
  const[isRenderComplete,setIsRenderComplete] = useState(false)



  useEffect(()=>{
    
    applicationConfig.setFunctionRef("customizer",{setIsCreatingRender:setIsCreatingRender})
    applicationConfig.setFunctionRef("customizer",{refreshGallery:refreshGallery})
    applicationConfig.setFunctionRef("customizer",{setRefreshRenders:setRefreshRenders})
    applicationConfig.setFunctionRef("customizer",{updateRenderTab:updateRenderTab})
    applicationConfig.setFunctionRef("customizer",{setIsRenderComplete:setIsRenderComplete})

   },[])
   

  

   useEffect(()=>{
    setIsCreatingRender(projectConfiguration.isCreatingRender)
    let renders3d = getProjectSavedConfigs()

    if(renders3d.length){
      renders3d.sort((a:any,b:any)=>a.updated_on>b.updated_on?-1:1)
      setRendersList(renders3d)
    }

    let renders = getFilteredArray(projectConfiguration.cameras,"renderType","2d")
    renders = renders.sort((a,b)=>a.cameraId>b.cameraId?-1:1)
    // renders = renders.splice(0,30)
    setAllRendersList(renders)
   },[refreshRenders])


     
  var tabItems = [
    {tabName:"3d",label:"Walkthrough",ionIcon:image},
    {tabName:"2d",label:"Gallery",ionIcon:image}
  ]

  function updateRenderTab(tabName: string) {
    setCurrTab(tabName)
  }

  function refreshGallery() {
    setRefreshRenders(Number(new Date()))
  }

 
  function gotoVirtualTour(configId:any) {
    // releaseMemoryCustomizer()
    applicationConfig.setCurrArea("Foyer")
    if(configId != projectConfiguration.configId){
      projectConfiguration.setConfigId(configId)
      projectConfiguration.createConfigFromConfigId(configId)
    }
    window.location.href = "/3dwalkthrough"
    // history.push("/3dwalkthrough");
  }

  function Renders3D() {
    return(
      <div  className='full-width display-flex-row'>
        {rendersList?.map((render:any,index:number)=>{
          var d = new Date(Number(render.updated_on))
          return(
            <div key={index} className='width50 area-item pointer padding10'>
              <div className='render-image center-aligned-column  position-relative padding5 border' style={{aspectRatio:"4/3"}}>
                <img src="assets/images/placeholder.png" width={70} alt="" />
                  
                  <div className={`middle ${isCreatingRender || (!projectConfiguration.isRenderCreated)?" disable":""}`}>
                  <div className='minimap-actions'>
                  <div className="customize-link bg-color-primary display-flex-row no-wrap" onClick={()=>gotoVirtualTour(render.id)}>
                    <div className="color-white center-aligned-column text">Walkthrough</div>
                  </div>
                  </div>
                </div>

              </div>
              <div className='details padding5'>
                <div className='display-flex-row no-wrap'>
                  {isCreatingRender && render.config_name === projectConfiguration.configName && currTab === customizerConfig.currentRenderingType?
                  <div className='loader'><IonSpinner name="crescent"/></div>
                  :null
                  }
                  <div className='marginleft10'>
                    <div className='heading3 font-md-small'>{render.config_name}</div>
                    {isCreatingRender && render.config_name === projectConfiguration.configName?
                    <div className='heading4 font-small'>Processing render...</div>
                    :
                    <div className='heading4 font-small'>{d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear() + " " +  d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds()}</div>
                    }
                    </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }


  return (
    <React.Fragment>
      {/* <MenuHeader
        label='Gallery'
        action={disableFinalizeMode}
      /> */}

      {/* 
      <div className='padding10 font-normal bg-color-ternary shadow-medium center-aligned-column heading1'>
        Gallery
      </div> */}
      <ControlsTab
        configObj={applicationConfig}
        tabItems={tabItems}
        currSelectedValue={currTab}
        action={updateRenderTab}
        theme="red"
      />

      {currTab === "3d" ? 
        <Renders3D/>
        :
        <Render2d
          isCreatingRender = {isCreatingRender}
          allRendersList={allRendersList}
          currTab={currTab}
        />
      }
    

      
    
    
 
    </React.Fragment>
  );
};

export default Gallery;
 
