import React from "react";
import ColorOptions from "../../../../admin/projects/projectActions/moodboard/controls/ColorOptions";
import { applicationConfig } from "../../../../../utils/methods";
import { sprites } from "../../../../../utils/customizein3d/modules/customizein3d";

const ColorSwatch: React.FC<{
}> = (props) => {



  return (
    <React.Fragment>
      <ColorOptions
          configObj={applicationConfig}
          action={(data)=>{sprites.updateColor(data)}}
      />
    </React.Fragment>
  );
};

export default ColorSwatch;
