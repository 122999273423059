import React from "react";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import { shareCanvasAsPng } from "../../../../../utils/customizein3d/modules/sceneBackground";
import ScenesList from "./ScenesList";
import { FabricateConfig } from "../../../../../utils/fabricate/fabricate";
import { goback } from "../../../../../utils/methods";
 
const Header: React.FC<{
  fabricateConfig:FabricateConfig
}> = (props) => {





  
  return (
    <div className="main-header-wrapper border-bottom customize-3d-header heading2 bg-color-white white-card position-relative" style={{borderTop:"var(--op-color-primary)"}}>
    <div className="left-middle">
        <div className="display-flex-row">
            <img src="assets/images/opus.png" alt=""  className="" style={{width:"7rem",objectFit:"contain"}} />
            
            <div className="center-aligned-column border-left hide-button-text-in-mobile  border-right padding10">
                <div className="display-flex-row">
                    <span><i className="fas fa-chevron-left color-danger"></i></span>
                    <span className="heading3 marginleft5 text">Go back</span>
                </div>
            </div>

 
           
        </div>
    </div>
    <div className="right-middle">
    <Button2
        configObj={{id:""}}
        action={goback}
        label={"Exit"}
        fontAwesomeIcon={"fas fa-times color-white"}
        iconPos="right"
        classNames={"shadow-medium bg-color-black no-border button-small"}
    />
    </div>
    
    <div className="middle font-large color-black store-color-primary heading1">
      <ScenesList fabricateConfig={props.fabricateConfig}/>
    </div>

    </div>
  );
};

export default Header;
