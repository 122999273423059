import React, { useEffect, useState } from "react";
import { hideComponentLoader, showComponentLoader } from "../../../../../../../../utils/UI_methods/global";
import { applicationConfig, getFilteredArray, stringWithoutWhiteSpace, waitFor } from "../../../../../../../../utils/methods";
import { getFilteredLeadsWithOutcome, getLeadsTabItems } from "../../../../../../../../utils/store/crm/crm";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import { STORE } from "../../../../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import HorizontalScroll from "../../../../../../../common/ui-components/scrollbar/HorizontalScroll";
import TodayTasks from "../../tasks/TodayTasks";
import LeadsCardView from "./LeadsCardView";
import LeadsListContainer from "./LeadsListContainer";
import Header from "./header/Header";
import $ from "jquery"
import { useHistory } from "react-router";
 
const LeadsList: React.FC<{
}> = (props) => {

  const[refreshLeads,setIsRefereshLeads] = useState(Number(new Date()))
  const[currLead,setCurrLead] = useState({})
  const[leadsList,setLeads] = useState([])

  const[filteredLeads,setFilteredLeads] = useState([])

  const[tabsList,setTabList] = useState(getLeadsTabItems([]))

  const[currSelectedTab,setCurrSelectedTab] = useState(tabsList[0].tabName)

  const[searchedLeadsList,setSearchedLeadsList] = useState([])

  const[isShowCardView,setIsShowCardView] = useState(false)

  const[activeCustomer,setActiveCustomer] = useState(STORE.activeCustomer)

  const [currSelectedTime,setCurrSeletedTime] = useState("All")

  const[unAssignedleads,setUnAssignedLeads] = useState([])

  const history = useHistory()


  useEffect(()=>{
    fetchData()
  },[refreshLeads])


  const fetchData = () => {
    let clientId = null
    if(applicationConfig?.user?.userType.toLowerCase() === "manager"){
      clientId = applicationConfig.clientId
    }
    let promises = [CRM.crmApi.leads.getLeads(clientId,applicationConfig?.user?.userId),CRM.crmApi.activity.getActivities()]
    Promise.all(promises).then(async (data)=>{
      const leadsData = data[0]
      const activityData = data[1]
      // console.log(leadsData)
      if(!leadsData.data.error){
        let leads =  leadsData.data.data
        leads = leads.filter(currLead => currLead.sales_stage !== "Loss")
        setLeads(leads)
        CRM.data.leadsList = leads
        // updateCounts(leads)
        onChangeHandle(currSelectedTab,leads)
      }
      if(!activityData.data.error){
        const activities =  activityData.data.data
        CRM.data.activitiesList = activities
      }
      hideComponentLoader("leadsListLoader")
    }).catch(err=>{
      console.log(err)
    })
     
  }


  useEffect(()=>{
    updateCounts(leadsList)
  },[isShowCardView,leadsList,history,searchedLeadsList])


  function updateCounts(leadsArray:any) {
    for (const tab of tabsList) {
      let currTab = tab.tabName 
      let leads = []
      if(currTab === "Visited"){
        let assignedLeads = getFilteredArray(leadsArray,"sales_stage","Visited")
        leads = [...CRM.data?.unAssignedleadsList,...assignedLeads]
      }else if(currTab === "Placed order"){
        leads = getFilteredArray(leadsArray,"sales_stage","Win")
      }else{
        let filtered = leadsArray.filter(currLead => currLead.sales_stage !== "Win" && currLead.sales_stage !== "Visited")
        leads = getFilteredLeadsWithOutcome(filtered,currTab)
      }
      $(`#Count_${stringWithoutWhiteSpace(currTab)}`).text(leads?.length)
    }
  }


  // useEffect(()=>{
  //   if(currSelectedTab === "Visited"){
  //     CRM.crmApi.leads.getUnassignedLeads().then(data=>{
  //       if(!data.data.error){
  //         setFilteredLeads(data.data.data)
  //         CRM.data.unAssignedleadsList = data.data.data
  //       }
  //     }).catch(err=>{
  //       console.log(err)
  //     })
  //   }
  // },[currSelectedTab])


  function setVisitedLeads(assignedLeads:Array<any>) {
    // CRM.crmApi.leads.getUnassignedLeads().then(data=>{
    //   if(!data.data.error){
        let result = [...CRM.data?.unAssignedleadsList,...assignedLeads]
        // CRM.data.unAssignedleadsList = result
        setFilteredLeads(result)
        // $(`#Count_Visited`).text(result?.length)
    //   }
    // }).catch(err=>{
    //   console.log(err)
    // })
  }




  function refreshList() {
    setIsRefereshLeads(Number(new Date()))
  }

  async function onChangeHandle(currTab:string,leads:Array<any> = leadsList) {
    showComponentLoader("leadsListLoader")
    setCurrSelectedTab(currTab)
    await waitFor(500)
    if(currTab === "Visited"){
      setVisitedLeads(getFilteredArray(leads,"sales_stage","Visited"))
    }else if(currTab === "Placed order"){
      setFilteredLeads(getFilteredArray(leads,"sales_stage","Win"))
    }else{
      let filtered = leads.filter(currLead => currLead.sales_stage !== "Win" && currLead.sales_stage !== "Visited")
      setFilteredLeads(getFilteredLeadsWithOutcome(filtered,currTab))
    }
    hideComponentLoader("leadsListLoader")
  }



  async function filterLeadsWithToday(currTab:any,leads:any) {
    setCurrSelectedTab(currTab)
    showComponentLoader("leadsListLoader")
    await waitFor(300)
    const list = getFilteredLeadsWithOutcome(leads,currTab)
    setFilteredLeads(list)
    await waitFor(200)
    setCurrSeletedTime("Today")
    hideComponentLoader("leadsListLoader")
  }

  function filterLeadsWithTime(currTab:any,leads:any,time:string) {
    if(time.toLowerCase() === "all"){
      setCurrSeletedTime("All")
      onChangeHandle(currSelectedTab)
      return
    }

    if(time.toLowerCase() === "today"){
      const list = getFilteredLeadsWithOutcome(leads,currTab)
      setFilteredLeads(list)
      setCurrSeletedTime("Today")
      return
    }
  }

  

  

  

  function OutcomesList() {
    return(
        <>
          {tabsList.map((currTab:any,index:number)=>{
            return(
              <div className={`marginleft10 item marginTop5 ${currTab.tabName === currSelectedTab?" --is-active":""}`} key={index}  onClick={()=>onChangeHandle(currTab.tabName)}>
                <div className='heading3 font-normal text-center text'>{currTab.label}</div>
              </div>
            )
          })}
        </>
    )
  }

 
  return (
    <div className="full-height-width">

      

      <Header setIsShowCardView={setIsShowCardView} setSearchedLeadsList={setSearchedLeadsList} refreshLeads={refreshList}/>

      <div className="hide-in-desktop" style={{padding:"10px 0"}}>
        <HorizontalScroll
          itemsClass="sub-categories-container"
          Component={OutcomesList}   
          itemsList={tabsList}
          id={`ProductSbuCategoriesScrollBar`}     
        />
      </div>

      

      <div className="display-flex-row no-wrap full-height-width position-relative">


        <div className="leads-list-wrapper full-height display-flex-column">
          {isShowCardView?
          <LeadsCardView 
            allLeads={leadsList} 
            setCurrLead={setCurrLead}
            refreshLeads={refreshList}
            activeCustomer={activeCustomer}
            setActiveCustomer={setActiveCustomer}
          />
          :
          <div className="full-width  full-height bg-color-light hide-scrollbar calendar-header">
            <LeadsListContainer
                filteredLeads={filteredLeads}
                setCurrLead={setCurrLead}
                refreshLeads={refreshList}
                setCurrTab={onChangeHandle}
                activeCustomer={activeCustomer}
                setActiveCustomer={setActiveCustomer}
                currSelectedTime={currSelectedTime}
                filterLeadsWithTime={filterLeadsWithTime}
                currTab={currSelectedTab}
                tabsList={tabsList}
                searchedLeadsList={searchedLeadsList}
                setSearchedLeadsList={setSearchedLeadsList}
              />
          </div>
          }
        </div>
        <div className="border agenda-list-wrapper  white-card marginleft10 shadow-light">
          <TodayTasks  allLeads={leadsList} refreshLeads={refreshLeads} filterLeadsWithTime={filterLeadsWithToday}/>
        </div>
      </div>


    </div>
  );
};

export default LeadsList;
