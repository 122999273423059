import React, { useEffect, useState } from "react";
import { addActiveClass, addClassInElement, focusOnInput, openFiltersWindowMobile, removeClassFromElement } from "../../../utils/UI_methods/global";
import { applicationConfig } from "../../../utils/methods";
import Button2 from "../../common/ui-components/buttons/Button2";
import MaterialSearch from "../../common/ui-components/search/MaterialSearch";
import CardsFilterHeaderLayout from "../../templates/layouts/page/CardsFilterHeaderLayout";


const FinishHeader: React.FC<{
  setSearchedMaterials:(val:any)=>void
  searchedMaterials:Array<any>
}> = (props) => {

  const[currSelectedCollectionForBreadcrumb,setCurrSelectedCollectionForBreadcrumb] = useState<string>("")

  useEffect(()=>{
    applicationConfig.setFunctionRef("finish",{setCurrSelectedCollectionForBreadcrumb:setCurrSelectedCollectionForBreadcrumb})
  },[])

  function activateSearchMode(materials:Array<any>,searchString:string) {
    props.setSearchedMaterials(materials)
    // applicationConfig.functions.finish.setMaterials(materials)
  }

  function filterMaterialsWithCollection(name:string) {
    if(props.searchedMaterials.length){
      props.setSearchedMaterials([])
    }
    applicationConfig.functions.finish.setCurrCollection(name)
  }

  function updateCollectionsWithCompany() {
  }
  function expandSearchHandle() {
    removeClassFromElement("materialSearchMobileWrapper","display-none")
    focusOnInput("materialSearchmainFinishPageMobileSearch")
  }
  return (
    <div className="filter-header-wrapper">
      <div className="display-flex-row no-wrap hide-in-mobile" style={{ justifyContent: "space-between" }}>
        {props.searchedMaterials.length?
          <Button2
            configObj={{}}
            action={()=>props.setSearchedMaterials([])}
            label={"Go back"}
            fontAwesomeIcon={"fas fa-chevron-left color-primary"}
            iconPos="left"
            classNames={"no-shadow no-border"}
          />
        :
        
          <div className="display-flex-row">
            {!currSelectedCollectionForBreadcrumb.length?
            <div className="center-aligned-row breadcrumb">
              <span className="inactive color-black heading2">{applicationConfig.states.finish.currSelectedMaterialType} <span className="padding10 color-dark">/</span> </span>
              <span  className="active color-black heading2"> {applicationConfig.states.finish.currSelectedCompany}</span>
            </div>
            :
            <div className="center-aligned-row breadcrumb">
              <span className="inactive color-black heading2">{applicationConfig.states.finish.currSelectedMaterialType} <span className="padding10 color-dark">/</span> </span>
              <span onClick={updateCollectionsWithCompany} className="inactive color-black heading2"> {applicationConfig.states.finish.currSelectedCompany} <span className="padding10 color-dark">/</span></span>
              <span className="active color-black heading2"> {currSelectedCollectionForBreadcrumb}</span>
            </div>
            }
          </div>
        
        }

        

        <MaterialSearch
          configObj={applicationConfig}
          currSelectedMaterialType={""}
          activateSearchMode={activateSearchMode}
          filterMaterialWithMaterialType={()=>{}}
          filterMaterialWithCollection={filterMaterialsWithCollection}
          idSuffix={"mainFinishContentWrapper"}
        />
        </div>

        <div className="hide-in-desktop full-width ">
        <div className='full-width display-flex-row position-relative space-between'>

          <div className="">
            <Button2
              configObj={{}}
              action={()=>applicationConfig?.functions.finish?.setCurrSelectedMaterialType("")}
              label={"Go back"}
              fontAwesomeIcon={"fas fa-chevron-left color-primary"}
              iconPos="left"
              classNames={"no-shadow margin0 font-medium full-width bg-color-light"}
            />
          </div>

          <div className="middle">
            <div className='display-flex-row'>
              <Button2
                configObj={{}}
                action={()=>openFiltersWindowMobile()}
                label={applicationConfig?.states.finish.currSelectedMaterialType}
                fontAwesomeIcon={"fas fa-chevron-down color-primary"}
                iconPos="right"
                classNames={"shadow-light margin0 no-shadow font-large full-width bg-color-"}
              />
            </div>
          </div>

          <div className="center-aligned-column">
            <div className='display-flex-row'>
              <Button2
                configObj={{}}
                action={expandSearchHandle}
                label={""}
                fontAwesomeIcon={"fas fa-search color-black"}
                iconPos="right"
                classNames={"shadow-light margin0 no-shadow font-large full-width bg-color-p"}
              />
            </div>
          </div>

          <div className='full-width top-left bg-color-white display-none padding0' id="materialSearchMobileWrapper" style={{zIndex:1001}}>
          <div className='display-flex-row space-between white-card no-shadow'>
            <div className='marginTop5' style={{flex:"1"}}>
              <MaterialSearch
                configObj={applicationConfig}
                currSelectedMaterialType={""}
                activateSearchMode={activateSearchMode}
                filterMaterialWithMaterialType={()=>{}}
                filterMaterialWithCollection={filterMaterialsWithCollection}
                idSuffix={"mainFinishPageMobileSearch"}
              />
            </div>
            
            <Button2
              configObj={{}}
              action={()=>addClassInElement("materialSearchMobileWrapper","display-none")}
              label={""}
              fontAwesomeIcon={"fas fa-times color-primary"}
              iconPos="right"
              classNames={"shadow-light marginTop5 border"}
            />
          </div>
          
        </div>

        </div>
        </div>
    </div>
 
  );
};

export default FinishHeader;
