import React, { useEffect, useState } from "react";
import { ProjectForm } from "../../../../../utils/projects/projectForm";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getObjectArrayOfDistictValues } from "../../../../../utils/methods";
import { displayImageFromCache, getAndSaveImageToCache } from "../../../../../utils/cache/cache";
import $ from "jquery"
 

const MaterialList: React.FC<{
    projectForm:ProjectForm
}> = (props) => {

  const[materialTypes,setMaterialTypes] = useState([])
  const[selectedTextures,setSelectedTextures] = useState([])

  useEffect(()=>{
    const materialIds = props.projectForm.projectMaterials.list
    const allTextures = applicationConfig?.data?.materials
    const selectedTexturesList = allTextures.filter(currTexture => materialIds.includes(currTexture.company_collection_materialtype_id))
    const materialTypesList = getArrayOfDistinctValues(selectedTexturesList,"material_type")
    setSelectedTextures(selectedTexturesList)
    setMaterialTypes(materialTypesList)
   
  },[])

  useEffect(()=>{
    if(materialTypes.length && selectedTextures.length){
      setTimeout(() => {
        loadAndDisplayImages()
      }, 200);
    }
    
  },[materialTypes,selectedTextures])

  async function loadAndDisplayImages() {
    //for each company get collection name
    for (const materialType of materialTypes) {
      const filteredMaterials = getFilteredArray(selectedTextures,"material_type",materialType)
      const collectionsList = getObjectArrayOfDistictValues(filteredMaterials,"collection_name")

      for (const material of collectionsList) {
        const companyName:string = material.company_name
        const collectionName = material.collection_name;
        const materialCode = material.material_code;
        let key = `thumbnails/OVL/${companyName}/${collectionName}/${materialCode}.png`
        await getAndSaveImageToCache(key)?.then(async (data)=>{
          displayImage(material.material_type,companyName,collectionName,materialCode,"png")
        }).catch(async (err)=>{
          let key = `thumbnails/OVL/${companyName}/${collectionName}/${materialCode}.jpg`
          await getAndSaveImageToCache(key)
          displayImage(material.material_type,companyName,collectionName,materialCode,"jpg")
        })
      }

      
    }
}


async function displayImage(materialType:string,companyName:string,collectionName:string,materialCode:string,extension:string) {
  let image = $(`#collectionImage${materialType}${companyName}${collectionName}`);
  // let filteredMaterials = getFilteredArray(applicationConfig?.data?.materials,"collection_name",collection)
  let key = `thumbnails/OVL/${companyName}/${collectionName}/${materialCode}.${extension}`
  await displayImageFromCache(image,key)
}
 
  return (
    <React.Fragment>
      <div className="project-area-product-details marginTop10 white-card no-shadow padding10 position-relative display-none">
        <div className="heading2 color-danger">Selected Finishes</div>
        <div className=" display-flex-row space-between">
          {materialTypes?.map((currMaterialType: any, index: number) => {
            const filteredMaterials = getFilteredArray(selectedTextures,"material_type",currMaterialType)
            const collectionsList = getObjectArrayOfDistictValues(filteredMaterials,"collection_name")
            return (
              <div className={"marginTop10   bg-color-light white-card shadow-light padding10"} style={{width:"49.5%"}} key={index}>
                <div  className="heading3 font-md-small marginRight10 ">{currMaterialType}</div>
                <div className="display-flex-row marginTop10">
                  {collectionsList.map((currCollection:any,index:number)=>{
                    return(
                      <div className={"position-relative  padding5 marginRight10"} key={index} >
                        <div className="center-aligned-column padding0 white-card shadow-light" style={{width:"60px",height:"60px"}}>
                            <img style={{zIndex:4}} width={100} src="" className="full-height-width white-card no-shadow collection-image pointer-none padding0 top-left" id={`collectionImage${currMaterialType}${currCollection.company_name}${currCollection.collection_name}`} alt="" />
                            <div style={{zIndex:5}} className="title heading3 font-small pointer-none" >{currCollection.collection_name}</div>
                            {/* <Loader
                              id={`${material.collection_name}Loader`}
                              data={{message:"Fetching data"}}
                              loaderType="spinner"
                              className="--is-active pointer-none collection-loader"
                            /> */}
                        </div>
                        
                      </div>
                    )
                  })}
                </div>
                {/* <CategoriesTable currArea={area.area_name} categories={categories} allCategories={props.allCategories} isShowPrice={props.isShowPrice} /> */}
            
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
        
  );
};

export default MaterialList;
