import React from 'react';

const Gallery: React.FC<{
  action:()=>void
  label:string
}> = (props) => {
 

  return (
    <React.Fragment>
       
      <div className='bottom-left center-aligned-column full-width padding10' style={{bottom:"3rem"}}>
        <div onClick={props.action} className='center-aligned-column width70 bg-color-primary pointer no-shadow white-card  click-effect padding5'>
          <div className='display-flex-row' style={{padding:"6px 0"}}>
            <span className='heading3 color-white'>{props.label}</span>
            <span className='marginleft5'><i className='fas fa-chevron-right'></i></span>
          </div>
        </div>
      </div>
          
    </React.Fragment>
  );
};

export default Gallery;
