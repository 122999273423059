import $ from "jquery";
import React, { useState } from "react";
import { updateWardrobeHandles } from "../../../../../utils/customizein3d/modules/wardrobe";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import DropDown from "../../../../common/ui-components/filters/DropDown";
 
const ConfirmWardrobeHandle: React.FC<{
}> = (props) => {
  

  const[size,setSize] = useState("3 Inch")
  const[color,setColor] = useState("Chrome")
    
  function closeWindow(){
    $("#handleConfirmWindow").fadeOut()
  }

  function confirmHandle(){
    updateWardrobeHandles(STORE.wardrobe.currSelectedHandle)
    closeWindow()
  }
  
  return (
    <div style={{display:"none",background:"rgba(0,0,0,0.7)",zIndex:1001}} id="handleConfirmWindow" className="top-left full-height-width position-fixed padding0">
    <div className="middle white-card center-aligned-column shadow-light" style={{width:"25em",height:"15rem"}}>
      <div className="">

        <div className="heading1 center-aligned-row padding10" id="confirmWindowHandleName"></div>
        <div className="display-flex-row no-wrap marginTop10">

            <DropDown
              classNames="width50"
              columns={1}
              values={["3 Inch","4 Inch","5 Inch"]}
              currSelectedValue={size}
              action={setSize}
              label="Size"
            />

            <DropDown
              classNames="width50 marginleft5"
              columns={1}
              values={["Chrome","Gold","RoseGold"]}
              currSelectedValue={color}
              action={setColor}
              label="Color"
            />

        </div>

        <div className="padding10 center-aligned-row marginTop10">
          <Button2
            configObj={{id:""}}
            action={()=>confirmHandle()}
            label={"Confirm"}
            fontAwesomeIcon={"fas fa-arrow-right color-primary"}
            iconPos=""
            classNames={"no-shadow bg-color-primary width-auto"}
          />
        </div>


      </div>
    </div>

  </div>
   
  );
};

export default ConfirmWardrobeHandle;
