import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { ProjectForm } from "../../../../utils/projects/projectForm";
import FormStatus from "./FormStatus";
import Hint from "./Hint";
import { applicationConfig, getArrayOfDistinctValues, getObjectByParameter } from "../../../../utils/methods";

const AreasFrom: React.FC<{
  updateAreasInApartment:(areaName:string)=>void
  selectedAreas: any;
  closeModal: (value: boolean) => void;
  updateCurrentState: (state: string) => void;
  otherAreas:any
  projectForm:ProjectForm
  setAreasInApartment:(array:any)=>void
}> = (props) => {

  const [bedroomCount,setBedRoomCount]:any = useState(props.projectForm.bedroomCount);
  const [bathroomCount,setBathroomCount]:any = useState(props.projectForm.bathroomCount);

  const updateSelectedAreas = (areaName: string) => {
    props.updateAreasInApartment(areaName)
  };


  function checkIfSelected(areaName: string) {
    return getObjectByParameter(props.selectedAreas,"area_name",areaName)
  }

  useEffect(()=>{
    props.projectForm.bedroomCount = bedroomCount
    props.projectForm.bathroomCount = bathroomCount
  },[bedroomCount,bathroomCount])

  const gotoNextStep = () => {
    // props.updateSelectedAreas(selectedAreas)
    // props.getSelectedAndOtherAreas(areasArray)
    let areasList:any = getArrayOfDistinctValues(props.selectedAreas,"area_name")
    areasList = areasList.filter(currArea => !currArea.includes("BedRoom") || !currArea.includes("Bathroom"))
    if(bedroomCount){
      for (let i = 1; i <= bedroomCount; i++) {
        // props.updateAreasInApartment(`BedRoom${i}`)
        areasList.push(`BedRoom${i}`)
      }
    }
    if(bathroomCount){
      for (let i = 1; i <= bathroomCount; i++) {
        // props.updateAreasInApartment(`Bathroom${i}`)
        areasList.push(`Bathroom${i}`)
      }
    }
    props.setAreasInApartment(areasList)
    props.updateCurrentState("Step3")
  }

  function increase(room:string) {
    if(room === "BedRoom"){
      if(bedroomCount === 6){
        return
      }
      setBedRoomCount(bedroomCount + 1)
    }
    if(room === "Bathroom"){
      if(bathroomCount === 3){
        return
      }
      setBathroomCount(bathroomCount + 1)
    }
  }

  function decrease(room:string) {
    if(room === "BedRoom"){
      if(bedroomCount === 0){
        return
      }
      setBedRoomCount(bedroomCount - 1)
    }
    if(room === "Bathroom"){
      if(bathroomCount === 0){
        return
      }
      setBathroomCount(bathroomCount - 1)
    }
  }

  function BedroomSelector() {
    return(
      <div className="area-item">
            <div className="area-image white-card shadow-light --is-active">
              <div className="top-left check-container">
                <div className="check"></div>
              </div>
              <div className="ion-margin-bottom">
                <img src={`${applicationConfig.aws_public_url}assets/icon/svg/areas/BedRoom1.svg`} alt="" style={{width:"2.5rem",aspectRatio:"1/1",opacity:"0.7"}} />
                <div className="heading4 color-black">{"BedRoom"}</div>
              </div>
              <div className="bottom-middle full-width center-aligned-column padding5">
                <div className="display-flex-row button-group">
                  <span onClick={(event)=>{decrease("BedRoom")}} className="border bg-color-light white-card no-shadow" style={{padding:"0.1rem 0.4rem"}}><i className="fas fa-minus color-dark font-ex-small"></i></span>
                  <span className="border white-card no-shadow heading3 font-small center-aligned-column" style={{padding:"0.1rem 0.7rem",margin:"0 3px"}}>{bedroomCount}</span>
                  <span  onClick={(event)=>{increase("BedRoom")}}  className="border bg-color-light white-card no-shadow" style={{padding:"0.1rem 0.4rem"}}><i className="fas fa-plus color-dark font-ex-small"></i></span>
                </div>
              </div>
            </div>
        </div>
      
    )
  }

  function BathRoomSelector() {
    return(
      <div className="area-item display-none">
            <div className="area-image white-card shadow-light --is-active">
              <div className="top-left check-container">
                <div className="check"></div>
              </div>
              <div className="ion-margin-bottom">
                <img src={`${applicationConfig.aws_public_url}assets/icon/svg/areas/Bathroom1.svg`} alt="" style={{width:"2.5rem",aspectRatio:"1/1",opacity:"0.7"}} />
                <div className="heading4 color-black">{"Bathroom"}</div>
              </div>
              <div className="bottom-middle full-width center-aligned-column padding5">
                <div className="display-flex-row button-group">
                  <span onClick={(event)=>{decrease("Bathroom")}} className="border bg-color-light white-card no-shadow" style={{padding:"0.1rem 0.4rem"}}><i className="fas fa-minus color-dark font-ex-small"></i></span>
                  <span className="border white-card no-shadow heading3 font-small center-aligned-column" style={{padding:"0.1rem 0.7rem",margin:"0 3px"}}>{bathroomCount}</span>
                  <span  onClick={(event)=>{increase("Bathroom")}}  className="border bg-color-light white-card no-shadow" style={{padding:"0.1rem 0.4rem"}}><i className="fas fa-plus color-dark font-ex-small"></i></span>
                </div>
              </div>
            </div>
        </div>
      
    )
  }

  const SelectedAreas = () =>{
    return(
      <div className="areas-container ion-padding" style={{height:"auto"}}>
        <BedroomSelector/>
        <BathRoomSelector/>
      {props.selectedAreas?.map((area: any, index: any) => {
        return (
          <div className={`area-item ${area.area_name.includes("BedRoom") || area.area_name.includes("Bathroom") ? " display-none":""}`} key={index}>
            <div className={`area-image white-card shadow-light ${checkIfSelected(area.area_name)?" --is-active":""}`}
              onClick={(event) => updateSelectedAreas(area.area_name)}>
              {checkIfSelected(area.area_name) ? (
                <div className="top-left check-container">
                  <div className="check"></div>
                </div>
              ) : null}
              <div className="">
                <img src={`${applicationConfig.aws_public_url}assets/icon/svg/areas/${area.area_name}.svg`} alt="" style={{width:"2.5rem",aspectRatio:"1/1",opacity:"0.7"}} />
                <div className="heading4 color-black">{area.area_name}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
    )
  }

  const OtherAreas = () =>{
    return(
      <React.Fragment>
      <div className="center-aligned-row heading3 padding5 marginTop5 bg-color-light">Other areas</div>
      <div className="areas-container ion-padding">
      {props.otherAreas?.map((area: any, index: any) => {
        return (
          <div className={`area-item ${area.area_name.includes("BedRoom") || area.area_name.includes("Bathroom") ? " display-none":""}`} key={index}>
            <div className="area-image white-card shadow-light" onClick={(event) => updateSelectedAreas(area.area_name)}>
              <div className="">
                <img src={`${applicationConfig.aws_public_url}assets/icon/svg/areas/${area.area_name}.svg`} alt="" style={{width:"2.5rem",aspectRatio:"1/1",opacity:"0.7"}} />
                <div className="heading4 color-black">{area.area_name}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
    </React.Fragment>
    )
  }


  return (
    <React.Fragment>
      <Hint
          id="areaForm"
          heading="Project details saved"
          subHeading="Select areas from the areas list, selected areas will be linked to the project"
          buttonText="Start Selecting Areas"
          closeAction={()=>{props.projectForm.isShowAreaHint = false}}
          isShow={props.projectForm.isShowAreaHint}
        />
      <div className="modal-header heading1">
        <IonIcon
          icon={close}
          ios={close}
          className="modal-close-icon"
          onClick={() => props.closeModal(false)}></IonIcon>
        Select Areas
      </div>
      <div className="modal-body">
        
        <FormStatus
            count={2}
        />
        <div className="create-project-areas-container">
          <SelectedAreas/>
          <OtherAreas/>
        </div>
      </div>
      <div className="modal-footer" style={{ justifyContent:"flex-end" }}>
        {/* <button className="op-btn op-btn-light" onClick={() => props.updateCurrentState("Step1")}>
          {" "}
          <i className="fa fa-arrow-left color-black" area-hidden="true" /> Prev
        </button> */}
        <button className="op-btn op-btn-primary" onClick={gotoNextStep}>
          Next <i className="fa fa-arrow-right" area-hidden="true" />
        </button>
      </div>
    </React.Fragment>
  );
};

export default AreasFrom;
