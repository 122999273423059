import $ from "jquery"
import AwsConfig from "../aws-sdk/AwsConfig"
import { getAddonsImagesUrl, getConvertedLengthBreadth, getModelDimensions, getObjectByName, getObjectDimensionPosition, getProductModuleUrl } from "./modules/helper"
import { checkFromCache, untarAndSaveToCache } from "../cache/cache";
import { hideComponentLoader, updateLoaderProgress } from "../UI_methods/global";
import { applicationConfig } from "../methods";
import { MathUtils } from "three";
import { getLoftsFromObject } from "./modules/wardrobe";


export function updateActionPanelInformation(modelObject:any,box:any) {
    $("#lengthInputContainer").addClass("disable")
    let category = modelObject?.userData.configuration?.categoryName

    // let data = getModelDimensions(modelObject,box)

    // let convertedUnits = getConvertedLengthBreadth(data.dimZ,data.dimX,"inch")
    // $("#lengthValue").text(convertedUnits.length)
    // $("#breadthValue").text(convertedUnits.breadth)
    let rotation = MathUtils.radToDeg(modelObject.rotation.y)
    let dim = getObjectDimensionsByUnit(modelObject,"inch")
    $("#heightValue").text(dim.height + " In")
    $("#depthValue").text(dim.depth + " In")
    $("#breadthValue").text(dim.breadth + " In")
    if(Math.abs(rotation) === 90){
      $("#breadthValue").text(dim.depth + " In")
    }
    $("#modularPanelHeading").text(category)
    if(category === "Wardrobe"){
      $("#loftResizeContainer").removeClass("display-none")
    }else{
      $("#loftResizeContainer").addClass("display-none")
    }

    updateLoftDimensionValue(modelObject)
}

export function updateLoftDimensionValue(modelObject:any) {
  let loft = getLoftsFromObject(modelObject)[0]
  if(loft){
    let dim = getObjectDimensionsByUnit(loft,"inch")
    $("#loftHeightValue").text(dim.height + " In")
  }
}

export function getObjectDimensionsByUnit(object:any,unit:string){
    let data = getObjectDimensionPosition(null,object)
    let dimensions = {
      height:0,
      depth:0,
      breadth:0
    }
    dimensions.height = data.dimensions.dimY * 100
    dimensions.depth = data.dimensions.dimZ * 100
    dimensions.breadth = data.dimensions.dimX * 100
    if(unit ==="inch"){
      dimensions.height = dimensions.height / 2.54  
      dimensions.depth =  dimensions.depth / 2.54 
      dimensions.breadth = dimensions.breadth / 2.54 
    }
    if(unit ==="ft"){
      dimensions.height /= 30.48  
      dimensions.depth /= 30.48 
      dimensions.breadth /= 30.48 
    }
    dimensions.height = Math.round(dimensions.height)
    dimensions.depth = Math.round(dimensions.depth)
    dimensions.breadth = Math.round(dimensions.breadth)
    return dimensions
}


export function updateRoomLayoutInformation(scene:any,GROUND_GROUP_NAME:string,box:any){
    let groundGroupObject = getObjectByName(scene,GROUND_GROUP_NAME)

    let data = getModelDimensions(groundGroupObject,box)
    let convertedUnits = getConvertedLengthBreadth(data.dimZ,data.dimX,"feet")

    $(".room-length-feet").find(".select-value").text(convertedUnits.length.substr(0,2))
    $(".room-length-inch").find(".select-value").text(convertedUnits.length.substr(convertedUnits.length.indexOf("-")+1,3))
    $(".room-breadth-feet").find(".select-value").text(convertedUnits.breadth.substr(0,2))
    $(".room-breadth-inch").find(".select-value").text(convertedUnits.breadth.substr(convertedUnits.length.indexOf("-")+1,3))
}


export function loadModuleImages(moduleImagesLoader:string,productModules:any,categoryName:string,parentContainerId:string) {
      let awsConfig = applicationConfig.awsConfig
      let key = awsConfig.getTarFileKey("moduleImages",{clientName:applicationConfig?.defaultClientName,categoryName:`${categoryName}2`})
      let urlprefix = `moduleImages`
      updateLoaderProgress(moduleImagesLoader,1,1,"Downloading images")
      untarAndSaveToCache(awsConfig,urlprefix,key)?.then(data=>{
        setTimeout(() => {
          displayModulesImages(categoryName,moduleImagesLoader,parentContainerId)
        }, 200);
      }).catch(err=>{
        console.log(err)
      })
    }


    export function loadAddonsImages(configObj:any,moduleImagesLoader:string,productModules:any,categoryName:string,imageId:string) {
      let awsConfig = applicationConfig.awsConfig
      let key = awsConfig.getTarFileKey("addons",{clientName:configObj?.clientName,categoryName:categoryName})
      let urlprefix = `addons/${configObj?.clientName}/${categoryName}`
      updateLoaderProgress(moduleImagesLoader,1,1,"Downloading images")
      untarAndSaveToCache(awsConfig,urlprefix,key)?.then(data=>{
        setTimeout(() => {
          displayAddonsImages(productModules,configObj,categoryName,imageId,moduleImagesLoader)
        }, 500);
      }).catch(err=>{
      })
    }

    function displayAddonsImages(productModules:any,configObj:any,categoryName:string,imageId:string,moduleImagesLoader:string) {
      for (let i = 0; i < productModules.length; i++) {
        let module = productModules[i]
        let key = getAddonsImagesUrl(configObj?.BASE_URL_ASSETS,configObj?.clientName,module.module_name,categoryName,module.product_name)
        let image = document.getElementById(imageId+key)
        checkFromCache(key)?.then((url:any)=>{
          image?.setAttribute("src",url)
          if(i===productModules.length-1){
            hideComponentLoader(moduleImagesLoader)
          }
        }).catch(err=>{
          console.log(err)
          if(i===productModules.length-1){
            hideComponentLoader(moduleImagesLoader)
          }
        })
      }
    }
  
    export async function displayModulesImages(categoryName:string,moduleImagesLoader:string,parentContainerId:string = "") {
      let productModules = document.querySelectorAll(".module-image")!
      for (let i = 0; i < productModules.length; i++) {
        let module = $(productModules[i])
        let key = getProductModuleUrl(applicationConfig?.BASE_URL_ASSETS,applicationConfig?.defaultClientName,module.attr("data-product-name"),categoryName,module.attr("data-product-subcategory"))
        await checkFromCache(key)?.then((url:any)=>{
          module?.attr("src",url)
          if(i===productModules.length-1){
            hideComponentLoader(moduleImagesLoader)
          }
        }).catch(err=>{
          if(i===productModules.length-1){
            hideComponentLoader(moduleImagesLoader)
          }
        })
      }
    }


    export function getTextureInfo(event:any){
      let textureElement = $(event.target)
      if(!textureElement.hasClass("texture-label-container")){
        textureElement = $(event.target).parents(".texture-label-container")
      }
      return {
        companyName:textureElement.attr("data-company-name"),
        collectionName:textureElement.attr("data-collection-name"),
        materialType: textureElement.attr("data-material-type"),
        materialCode: textureElement.attr("data-material-code"),
        uvScale: textureElement.attr("data-uv-scale"),
        roughness: textureElement.attr("data-roughness-value"),
        metalness: textureElement.attr("data-metalness-value"),
        specular: textureElement.attr("data-specular-value"),
        clearcoat: textureElement.attr("data-clearcoat-value"),
        sheen: textureElement.attr("data-sheen-value"),
        transmission: textureElement.attr("data-transmission-value"),
      }
    }
    
     
