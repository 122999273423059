import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getRouteFromSubDomain, getToken } from './methods';
 
// handle the private routes
const PublicRoute:React.FC<{component:any;path:any;exact:any}> = ({ component: Component, ...rest }) => {
  return (
    // <Route
    //   {...rest}
    //   render={(props:any) => !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/home' }} />}
    // />

    <Route
      {...rest}
      render={(props:any) => !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: getRouteFromSubDomain() }} />}
    />
  )
}
 
export default PublicRoute;