import * as TWEEN from "@tweenjs/tween.js";
import { copyCameraParameters } from "./controls";
import { Mesh } from "three";

export var tween = TWEEN


var animationFrameId:any = null

export function tweenModelPosition(modelObject:any,positions:any,isTween:boolean) {
    if(true){
        modelObject.position.set(positions.x,positions.y,positions.z);
        return
    }
    let tween = new TWEEN.Tween({x:500,y:20,z:500})
    // let tween = new TWEEN.Tween({x:modelObject.position.x,y:modelObject.position.y,z:modelObject.position.z})
    .to({x:positions.x,y:positions.y,z:positions.z},500)
    tween.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
        modelObject.position.set(object.x,object.y,object.z);
      // controls.target.set(width/2,0,height/2)
    })

    tween.start()
}

export function tweenCameraOld(scene:any,controls:any,boundingBox:any,target:any,camera:any,updatedCamera:any) {
    // animateTween()
    // let focusObject = updatedCamera.parent
    // boundingBox.setFromObject(updatedCamera.parent)
    // console.log(boundingBox,focusObject)

    // camera.getWorldPosition(target) 

    // camera.parent = null
    // camera.position.set(target.x,target.y,target.z)
    camera.far = updatedCamera.far
    camera.fov = updatedCamera.fov
    camera.zoom = updatedCamera.zoom
    camera.near = updatedCamera.near
    camera.parent = updatedCamera.parent
    // updatedCamera.parent.attach(camera)

    // let updatedCameraControls = new OrbitControls( updatedCamera, renderer.domElement );
    // updatedCameraControls.enabled = false
    // updatedCameraControls.autoRotate = false
    // updatedCameraControls.enableRotate = false

    // updatedCameraControls.update()
    // console.log(updatedCameraControls.target)

    // controls.target.set(updatedCameraControls.target.x,updatedCameraControls.target.y,updatedCameraControls.target.z)
    
    
    // var cameraPos = camera.position.clone();
    // var updateCameraPos = updatedCamera.position.clone();
    // cameraPos = camera.localToWorld(cameraPos) 
    // console.log(updatedCamera.localToWorld(vector),updatedCamera.getWorldPosition(target))

    // var worldRot = new Euler();
    
    // let quaternion = updatedCamera.quaternion
    // camera.quaternion.copy( quaternion )


    // camera.position.set(target.x,target.y,target.z)
    
    // var rotationTarget = new Vector3()
    // updatedCamera.getWorldQuaternion(rotationTarget)
    // camera.rotation.set(rotationTarget.x,rotationTarget.y,rotationTarget.z)
    camera.rotation.set(updatedCamera.rotation.x,updatedCamera.rotation.y,updatedCamera.rotation.z)
    camera.position.set(updatedCamera.position.x,updatedCamera.position.y,updatedCamera.position.z)

    // camera.getWorldPosition(target) 
    
      
    camera.updateProjectionMatrix()
    camera.updateWorldMatrix()

    // const quaternion = new Quaternion(updatedCamera.position.x, updatedCamera.position.y, updatedCamera.position.z, 1);
    // camera.applyQuaternion(quaternion);  
    // camera.quaternion.normalize();
 

    // camera.rotation.set(updatedCamera.rotation.x,
    // updatedCamera.rotation.y,
    // updatedCamera.rotation.z
    // );
    
    // let tweenCamera = new TWEEN.Tween({t:0})
    // .to({t:1},500).delay(500)

    // tweenCamera.onUpdate(function(tween:{t:number},elapsed:number){
    //   camera.quaternion.slerp(quaternion,tween.t)
      
    //   camera.updateProjectionMatrix()
    //   camera.updateWorldMatrix()
    //   })

        
    // let tweenCamera = new TWEEN.Tween({x:camera.position.x || 0,y:camera.position.y || 0,z:camera.position.z || 0})
    // .to({x:0,y:0,z:0},1000)
  
    
    // tweenCamera.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
    //   camera.position.set(object.x,object.y,object.y);
    //   // camera.position.set(0,0,0);
    //   // camera.lookAt(new Vector3(boundingBox.getCenter(target).x,boundingBox.getCenter(target).y,boundingBox.getCenter(target).z))
    //   // camera.lookAt(new Vector3(focusObject.position.x,focusObject.position.y,focusObject.position.z))
    //   camera.updateProjectionMatrix();
    //   camera.updateWorldMatrix()
    // })

          
    // let tweenControls = new TWEEN.Tween({x:0,y:0,z:0})
    // .to({x:boundingBox.getCenter(target).x,y:boundingBox.getCenter(target).y,z:boundingBox.getCenter(target).z},1000)

    // tweenControls.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
    //   console.log(object)
    //   controls.target.set(object.x,object.y,object.z)
    //   controls.update()
    // })



 
    // tweenCamera.onComplete(()=>{
    //   cancelAnimation()
    // })
 

    // console.log(camera,updatedCamera)

    // tweenCamera.start()
   
    return camera

}


export async function tweenCameraPosition(camera:any,controls:any,target:any,updatedCamera:any) {
  animateTween()

  let cameraPos = updatedCamera?.getWorldPosition()
  if(cameraPos){
    copyCameraParameters(camera,updatedCamera)
  }

  let tweenCamera = new TWEEN.Tween({x:camera.position.x,y:camera.position.y,z:camera.position.z})
  .to({x:cameraPos?.x ,y:cameraPos?.y ,z:cameraPos?.z},1000)

  let tweenControls = new TWEEN.Tween({x:controls.target.x,y:controls.target.y,z:controls.target.z})
  .to({x:target.x,y:target.y,z:target.z},1000)

  tweenCamera.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
    camera.position.set(object?.x,object?.y,object?.z);
    camera.lookAt(object?.x,object?.y,object?.z)
  })

  tweenControls.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
    controls.target.set(object.x,object.y,object.z)
    controls.update()
  })

  // tweenCamera.start()
  tweenControls.start()

  setTimeout(() => {
    cancelAnimation()
  }, 1000);
}

export function tweenCameraControls(camera:any,controls:any,cameraTarget:any,controlsTarget:any,tweenTime:number) {
  animateTween()
  let tweenCamera = new TWEEN.Tween({x:camera.position.x,y:camera.position.y,z:camera.position.z})
  .to({x:cameraTarget.x,y:cameraTarget.y,z:cameraTarget.z},tweenTime)

  let tweenControls = new TWEEN.Tween({x:controls.target.x,y:controls.target.y,z:controls.target.z})
  .to({x:controlsTarget.x,y:controlsTarget.y,z:controlsTarget.z},tweenTime)

  tweenCamera.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
    camera.position.set(object.x,object.y,object.z)
    camera.lookAt(object.x,object.y,object.z)
  })

  tweenControls.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
    controls.target.set(object.x,object.y,object.z)
  })
  
  tweenCamera.start()
  tweenControls.start()
  // tweenControls.chain(tweenCamera);

  setTimeout(() => {
    cancelAnimation()
  }, tweenTime)
}

export function tweenControls(controls:any,controlsTarget:any,tweenTime:number) {
  animateTween()

  let tweenControls = new TWEEN.Tween({x:controls.target.x,y:controls.target.y,z:controls.target.z})
  .to({x:controlsTarget.x,y:controlsTarget.y,z:controlsTarget.z},tweenTime)


  tweenControls.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
    controls.target.set(object.x,object.y,object.z)
  })
  tweenControls.start()

  setTimeout(() => {
    cancelAnimation()
  }, tweenTime)
}

export function tweenCamera(camera:any,target:any,tweenTime:number) {
  animateTween()

  let tweenControls = new TWEEN.Tween({x:camera.position.x,y:camera.position.y,z:camera.position.z})
  .to({x:target.x,y:target.y,z:target.z},tweenTime)


  tweenControls.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
    camera.position.set(object.x,object.y,object.z)
    camera.lookAt(object.x,object.y,object.z)
  })
  tweenControls.start()

  setTimeout(() => {
    cancelAnimation()
  }, tweenTime)
}


export function tweenScale(modelObject:any,tweenTime:number) {
  animateTween()

  let tweenScaleOut = new TWEEN.Tween({x:modelObject.scale.x,y:modelObject.scale.y,z:modelObject.scale.z})
  .to({x:1.1,y:1.1,z:1.1},tweenTime)

  let tweenScaleIn = new TWEEN.Tween({x:1.1,y:1.1,z:1.1})
  .to({x:1,y:1,z:1},tweenTime).delay(tweenTime)


  tweenScaleOut.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
    modelObject.scale.set(object.x,object.y,object.z)
  })

  tweenScaleIn.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
    modelObject.scale.set(object.x,object.y,object.z)
  })
  
  tweenScaleOut.start()
  tweenScaleIn.start()

  setTimeout(() => {
    cancelAnimation()
  }, tweenTime)
}
 
export function animateTween() {
  animationFrameId = requestAnimationFrame(animateTween);
  TWEEN.update();
}

export function cancelAnimation() {
  cancelAnimationFrame(animationFrameId)
}