import React from "react";
import { getFormatedDate } from "../../../../../../../utils/store/crm/crm";
 


 
const TicketDetails: React.FC<{
    ticket:any
}> = (props) => {



 
  function Details() {
    return(
      <div className="display-flex-row white-card no-shadow full-width border no-wrap">

      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="profile center-aligned-column">
          <div className="bg-color-primary font-small color-white center-aligned-column white-card no-shadow" style={{width:"28px",height:"28px",borderRadius:"50%"}}>
              {props.ticket?.customer_name.substring(0,2)}
          </div>
        </div>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Customer name</div>
          <div className="heading4 font-normal color-black">{props.ticket?.customer_name}</div>
        </div>
      </div>


      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Contact no.</div>
          <div className="heading4v font-normal color-black">+91-{props.ticket?.mobile}</div>
        </div>
      </div>

      {/* <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Address</div>
          <div className="heading4v font-normal color-black">{props.ticket?.address}</div>
        </div>
      </div> */}

      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Ticket Number</div>
          <div className="heading3 font-normal color-primary">{props.ticket.ticket_no}</div>
        </div>
      </div>

      


      </div>
    )
  }
  
  
 
  return (
    <div className="full-height-width ion-padding">
    <Details/>

    <div className="ion-margin-top white-card border no-shadow padding10">

      <div className="heading3 font-medium">Details</div>

      <div className="display-flex-row no-wrap ion-margin-top">
        <div className="heading4 font-md-small ticket-info-title">Sales Order Number</div>
        <div className="heading4 color-black font-md-small ion-margin-start">{props.ticket?.so_number}</div>
      </div>

      <div className="display-flex-row no-wrap ion-margin-top">
        <div className="heading4 font-md-small ticket-info-title">Issue Type</div>
        <div className="heading4 color-black font-md-small ion-margin-start">{props.ticket?.issue_type}</div>
      </div>

      <div className="display-flex-row no-wrap ion-margin-top">
        <div className="heading4 font-md-small ticket-info-title">Description</div>
        <div className="heading4 color-black font-md-small ion-margin-start">{props.ticket?.description}</div>
      </div>


      <div className="display-flex-row no-wrap ion-margin-top">
        <div className="heading4 font-md-small ticket-info-title">Created Date</div>
        <div className="heading4 color-black font-md-small ion-margin-start">{getFormatedDate(props.ticket?.created_at)}</div>
      </div>

      <div className="display-flex-row no-wrap ion-margin-top">
        <div className="heading4 font-md-small ticket-info-title">Remarks</div>
        <div className="heading4 color-black font-md-small ion-margin-start">{props.ticket?.remarks}</div>
      </div>

    </div>

    <div className="activities-list-wrapper ion-margin-top white-card border no-shadow padding10">
      <div className="heading3 font-medium">References </div>
      <div className="center-aligned-column ion-margin-top" >
        <div className="padding10">
          <a target="_blank" href={`https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/customer-tickets/${props.ticket.ticket_no}/receipt/receipt.png`}>Receipt</a>
        </div>
      </div>
    </div>


    </div>
  );
};

export default TicketDetails;
