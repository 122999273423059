import { IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { applicationConfig } from '../../../utils/methods';
import { addActiveClass, openFiltersWindowMobile } from '../../../utils/UI_methods/global';
import Button2 from '../../common/ui-components/buttons/Button2';

import DropDown2 from '../../common/ui-components/filters/DropDown2';
import Tabs from '../../common/ui-components/tabs/Tabs';
import CardsFilterHeaderLayout from '../../templates/layouts/page/CardsFilterHeaderLayout';

  
  const MoodboardHeader: React.FC<{
    configObj:any
    setMoodboardList:(data:Array<any>)=>void
    moodboardList:Array<any>
  }> = (props) => {


    const[currSelectedSagment,setCurrSelectedSagment] = useState("")


  
    useEffect(()=>{
      let filteredArray = props.moodboardList.filter((object:any)=>object.sagment===currSelectedSagment)
      filteredArray.sort((a:any,b:any)=>a.moodboard_id<b.moodboard_id?-1:1)
      props.setMoodboardList(filteredArray)
      props.configObj.setFunctionRef("moodboard",{setCurrSelectedSagment:updateSagment})
    },[currSelectedSagment,props.moodboardList])


    //Set module filter if exists 
    useEffect(()=>{
      let filters = props.configObj.getModuleFilters("moodboard")
      if(filters?.sagment){
        setCurrSelectedSagment(filters.sagment)
      }else{
        setCurrSelectedSagment("Interior")
      }

      // HightList last edited card 
      // if(filters?.lastEdited?.length){
      //   setTimeout(() => {
      //     updateCardPosition(filters.lastEdited)
      //   }, 500);
      // }
    },[])


    // function updateCardPosition(id:string) {
    //   let element = $(`#moodboardCard_${id}`)
    //   if(element && element.length){
    //     let wrapper = element.parent()
    //     let newElement = element.clone()
    //     newElement.addClass("recent-item-container")
    //     wrapper.prepend(newElement)
    //     // element.remove()
    //   }
    // }

    function updateSagment(val:string) {
      props.configObj.setModuleFilter("moodboard","sagment",val)
      setCurrSelectedSagment(val)
    }

    const tabs = [{tabName:"Interior"},{tabName:"Other"}]

    function FilterHeaderContent() {
      return(
        <>
          <div className="display-flex-row no-wrap hide-in-mobile" style={{justifyContent:"space-between"}}>
            <div className="">
              <DropDown2
              classNames=""
              values={["Name"]}
              currSelectedValue={"Name"}
              action={()=>{}}
              label="Sort By"
              />
            </div>
          
          <div className="search-container" style={{width:"300px",marginRight:"12px"}}>
            <input type="text" placeholder="Search styleboard" className="search-input"/>
            <IonIcon className="icon left-middle" icon={search}></IonIcon>
            </div>
          </div>

          <div className="hide-in-desktop full-width">
            <div className='full-width display-flex-row position-relative space-between'>

              <div className='display-flex-row'>
                <Button2
                  configObj={{}}
                  action={()=>openFiltersWindowMobile()}
                  label={applicationConfig?.states?.moodboard?.currSagement}
                  fontAwesomeIcon={"fas fa-chevron-down color-primary"}
                  iconPos="right"
                  classNames={"shadow-light margin0 no-shadow font-large full-width bg-color-black"}
                />
              </div>

              <div className="center-aligned-column">
                <div className='display-flex-row'>
                  <Button2
                    configObj={{}}
                    action={()=>addActiveClass("#productSearchWrapperMobile")}
                    label={""}
                    fontAwesomeIcon={"fas fa-search color-black"}
                    iconPos="right"
                    classNames={"shadow-light margin0 no-shadow font-large full-width bg-color-p"}
                  />
                </div>
              </div>

            </div>
          </div>
        </>
      )
    }

    return (
      <CardsFilterHeaderLayout
        FilterHeaderContent={FilterHeaderContent}
      />
   
    );
  };
  
  export default MoodboardHeader;
  