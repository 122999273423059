import React, { useEffect, useState } from "react";
import { getObjectArrayOfDistictValues } from "../../../../../../../../utils/methods";
import { getFormatedDate, outcomesList } from "../../../../../../../../utils/store/crm/crm";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import Select from "../../../../../../../common/ui-components/input/Select";
import SelectInput from "../../../../../../../common/ui-components/input/SelectInput";
import { getTodayTimestampWithDayDifference } from "../../../../../../../../utils/store/crm/calendar";
 
const ActivityLogsList: React.FC<{
  lead:any
  isShowFilters:boolean
}> = (props) => {


  const[activities,setActivities] = useState([])
  const[activitiesInRange,setActivitesInRange] = useState([])
  const[filteredActivities,setFilteredActivities] = useState([])

  const[customer,setCustomer] = useState("All")
  const[activityType,setActivityType] = useState("All")
  const[outcome,setOutcome] = useState("All")
  const[date,setDate] = useState("All")
  const[dateRange,setDateRange] = useState("Last 90 days")
  
  useEffect(()=>{
    let leadsList = []
    if(props.lead){
      leadsList = CRM.data.activitiesList?.filter(currActivity => currActivity.status === 0 && currActivity.lead_id === props.lead.lead_id)
    }else{
      leadsList = CRM.data.activitiesList?.filter(currActivity => currActivity.status === 0)
    }
    setActivities(leadsList)
    setFilteredActivities(leadsList)
  },[])


  useEffect(()=>{
    let filtered = activitiesInRange.filter(currActivity => {
      return (
      (customer === "All" || currActivity.customer_name === customer) &&
      (activityType === "All" || currActivity.activity_type === activityType) &&
      (outcome === "All" || currActivity.outcome === outcome)
      )
    })
    setFilteredActivities(filtered)
  },[activitiesInRange,customer,activityType,outcome,dateRange])

  useEffect(()=>{
    let filtered = []
    if(dateRange === "Yesterday"){
      filtered = activities.filter(currActivity => {
        return currActivity.start_date === getTodayTimestampWithDayDifference(-1)
      })
    }
    if(dateRange === "Today"){
      filtered = activities.filter(currActivity => {
        return currActivity.start_date === getTodayTimestampWithDayDifference(0)
      }) 
    }
    if(dateRange === "Last 7 days"){
      filtered = activities.filter(currActivity => {
        return currActivity.start_date >= getTodayTimestampWithDayDifference(-7) && currActivity.start_date <= getTodayTimestampWithDayDifference(0) 
      }) 
    }
    if(dateRange === "Last 90 days"){
      filtered = activities.filter(currActivity => {
        return currActivity.start_date >= getTodayTimestampWithDayDifference(-90) && currActivity.start_date <= getTodayTimestampWithDayDifference(0) 
      })  
    }
    setActivitesInRange(filtered)
  },[activities,dateRange])


  function resetFilterHandle() {
    setCustomer("All")
    setActivityType("All")
    setOutcome("All")
    // setDateRange("Last 90 days")
  }


  return (
    <div className="summary ion-padding position-relative overflow-y-scroll full-height">

      <div className={`filters-header padding10 bg-color-light ion-margin-bottom ${props.isShowFilters ? "" : "display-none"}`}>
        <div className="display-flex-row">
          {/* <InputDate
              id="logsDate"
              label="Date"
              isMandatory={false}
              onChange={()=>{}}
              value={``}
              className=""
          /> */}

          <div className="">
            <Select
                id="logsDateRange"
                label="Date range"
                isMandatory={false}
                onChange={(value)=>{setDateRange(value)}}
                options={["Yesterday","Today","Last 7 days","Last 90 days"]}
                className=""
                value={dateRange}
            />
          </div>

          <div className="ion-margin-start">
            <SelectInput
                id="logsCustomerName"
                label="Customer Name"
                isMandatory={false}
                onChange={(value)=>{setCustomer(value)}}
                options={[{"customer_name":"All"},...getObjectArrayOfDistictValues(activities,"customer_name")]}
                className=""
                value={customer}
                config={{value:"customer_name",displayName:"customer_name"}}
            />
          </div>

          <div className="ion-margin-start">
            <SelectInput
                id="logsActivityType"
                label="Activity Type"
                isMandatory={false}
                onChange={(value)=>{setActivityType(value)}}
                options={[{"activity_type":"All"},...CRM.data.activityTypes]}
                className=""
                value={activityType}
                config={{value:"activity_type",displayName:"activity_type"}}
            />
          </div>


          <div className="ion-margin-start">
            <SelectInput
                id="logsActivityOutcome"
                label="Outcome"
                isMandatory={false}
                onChange={(value)=>{setOutcome(value)}}
                options={[{outcomeName:"All"},...outcomesList]}
                className=""
                value={outcome}
                config={{value:"outcomeName",displayName:"outcomeName"}}
            />
          </div>


          <div className="ion-margin-start padding5 display-flex-row" style={{alignContent:"end"}}>
             <Button2
                configObj={{id:""}}
                action={()=>{resetFilterHandle()}}
                label={"Reset filters"}
                fontAwesomeIcon={"fas fa-arrow-right color-dark"}
                iconPos=""
                classNames={"no-shadow button-small color-white bg-color-warning-outline"}
              /> 
          </div>
          

        </div>
      </div>

      {filteredActivities.length?
        <table className="table activity-logs-table ">
              <thead className="light-background bg-color-dark">
                <tr>
                  <th>Date</th>
                  <th>Customer name</th>
                  <th>Activity Name</th>
                  <th>Outcome</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                
                {filteredActivities?.map((activity: any, index: number) => {
                  return (
                    <tr key={index} className="text-center ">
                      <td className="text-no-wrap">{getFormatedDate(activity.created_at)}</td>
                      <td className="text-no-wrap">{activity.customer_name}</td>
                      <td className="text-no-wrap">{activity.activity_type || "Lead created"}</td>
                      <td className="text-no-wrap">{activity.outcome}</td>
                      <td>{activity.comments}</td>
                    </tr>
                  );
                })}
              </tbody>
        </table>
        :
        <div className="center-aligned-column" >
          <div className="heading4 font-normal white-card no-shadow" style={{border:"1px dashed var(--op-color-dark)",padding:"25px 25px"}}>No activities found</div>
        </div>
        
        }


      </div>

    
       
  );
};

export default ActivityLogsList;
