import React, { useEffect, useState } from 'react';
import { toggleSnapping, toggleXrayOptions } from '../../../../../../utils/customizein3d/area3dmodel/UI_methods';
import {  dimensions, enableMeasurementTool } from '../../../../../../utils/customizein3d/area3dmodel/area3dModel';
import { enableMultiselectMode } from '../../../../../../utils/customizein3d/area3dmodel/multiselect';
import { autoWallsHiding, disableWallsHiding, enableWallsHiding } from '../../../../../../utils/customizein3d/area3dmodel/walls';
import DimensionToggle from '../../../../../common/modules/customizein3d/common/DimensionToggle';
import { applicationConfig } from '../../../../../../utils/methods';
import Button2 from '../../../../../common/ui-components/buttons/Button2';
import UnitSelector from '../../../../../common/modules/customizein3d/common/UnitSelector';

const BottomRightActions: React.FC<{
  isSpacePlannerMode:boolean
  isKitchenPlannerMode:boolean
  }> = (props) => {

    const[dim,setDimensionsObject] = useState(dimensions)


    useEffect(()=>{
      applicationConfig.setFunctionRef("customizer",{setDimensionsObject:setDimensionsObject})
    },[])


  return (
    <>
    {!props.isKitchenPlannerMode?
    <>
    <div className="display-flex-column hide-on-rotation zindex1 hide-on-measurement hide-in-elevation-view hide-on-configure hide-in-isolation-view ion-padding bottom-left  hide-on-multiselect hide-in-floorplan-mode hide-on-walkthrough-mode hide-on-wall-focus">

    <div>
      <UnitSelector
        action={(unit)=>dimensions?.updateUnit(unit)}
      />
    </div>

    <DimensionToggle
      dimensions={dimensions}
    />

    <button className="action-button zindex30 display-none shadow-light white-card border display-flex-row font-medium" onClick={enableMeasurementTool} id="toggleMeasurement">
        <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label" >Measurement tool</span> 
    </button>

    <button className="action-button zindex30 display-none  shadow-light white-card border display-flex-row font-medium" onClick={enableMultiselectMode} id="toggleMultiselect">
        <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label" >MultiSelect off</span> 
    </button>

    <div className='position-relative marginTop5' id="toggleSnapping">
      <Button2
        configObj={{id:""}}
        action={()=>toggleSnapping()}
        label={"Snap"}
        fontAwesomeIcon={"far fa-clipboard color-primary"}
        iconPos="left"
        classNames={"hide-on-configure button-small width-auto shadow-medium no-border --is-active"}
        svgUrl="assets/icon/svg/wall.svg"
      />
      <span className="center-aligned-column marginleft5 heading4 zindex30 font-small capitalize color-danger marginRight5 hide-in-mobile label state-text right-middle" >on</span> 
    </div>

    <div className='position-relative marginTop5' id="wallsVisibiltyToggle">
      <Button2
        configObj={{id:""}}
        action={()=>toggleXrayOptions()}
        label={"X-ray"}
        fontAwesomeIcon={"far fa-clipboard color-primary"}
        iconPos="left"
        classNames={"hide-on-configure button-small width-auto shadow-medium user-options-link no-border --is-active"}
        svgUrl="assets/icon/svg/xray.svg"
      />
      <span className="center-aligned-column pointer-none hide-in-mobile zindex30 capitalize font-small color-danger marginRight5 label heading4 state-text inner-text right-middle" >auto</span> 
    </div>
    
 


    {/* <button className="action-button zindex30   display-flex-row font-medium" onClick={rotateCamera} id="">
      <div className="shadow-light white-card display-flex-row border action-button pointer-none">
        <span className="center-aligned-column"><i className="fas fa-undo  font-"></i></span>
        <span className="center-aligned-column marginleft5 heading4 hide-in-mobile" >Rotate</span> 
      </div>
    </button> */}
 


    </div>

    <div className='header-user-options customizer-xray-options white-card shadow-medium border' >
      <div className='option  display-flex-row' onClick={autoWallsHiding}>
        <div><i className="fas fa-save   font-normal"></i></div>
        <div className='marginleft10 heading1 font-small center-aligned-column'>Auto</div>
      </div>

      <div className='option border-top display-flex-row' onClick={enableWallsHiding}>
        <div><i className="fas fa-save   font-normal"></i></div>
        <div className='marginleft10 heading1 font-small center-aligned-column'>On</div>
      </div>


      <div className='option border-top display-flex-row'   onClick={disableWallsHiding}>
        <div><i className="fas fa-save  color-primary font-normal"></i></div>
        <div className='marginleft10 heading1 font-small center-aligned-column'>Off</div>
      </div>
    </div>
    </>
    :null}
    </>
  );
};

export default BottomRightActions;
