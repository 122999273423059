import $ from "jquery";
import React, { useEffect, useState } from "react";
import { hideComponentLoader, showAlert, showComponentLoader, showGlobalToast } from "../../../../../../utils/UI_methods/global";
import { applicationConfig, getObjectByParameter, getUser, waitFor } from "../../../../../../utils/methods";
import { CRM } from "../../../../../../utils/store/crm/crmConfiguration";
import { Customer, STORE } from "../../../../../../utils/store/storeConfiguration";
import Alert from "../../../../../common/ui-components/alerts/Alert";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import CustomerDetailsForm from "./CustomerDetailsForm";
import CustomerMobileForm from "./CustomerMobileForm";
import { getTodayTimestampOnlyDate } from "../../../../../../utils/store/crm/calendar";
import { phoneValidation } from "../../../../../../utils/form/validation";

 

const CreateCustomer: React.FC<{
  setActiveCustomer:(customer:Customer)=>void
  createCustomerCallback:(customer:Customer)=>void
  isStartSessionMode:boolean
  isCustomerCheckInMode:boolean
  isCreateTicketMode:boolean
}> = (props) => {

  const[alertId] = useState("customerFormAlert")

  const[customersList,setCustomersList] = useState([])
  const[isCustomerFound,setIsCustomerFound] = useState(false)
  const[isSetMobileNumber,setIsSetMobileNumber] = useState(false)
  const[foundCustomer,setFoundCustomer] = useState(null)

  
  const [formData] = useState({
    mobile:"",
    customerName:"",
    email:""
  })

  useEffect(()=>{
    STORE.storeApi.getCustomersList().then((data:any)=>{
      if(!data.error){
        if(data.data?.data?.length){
          setCustomersList(data.data.data)
        }
      }
    })
  },[props.isStartSessionMode])

  async function activeCustomerSession(customer:Customer) {
    showComponentLoader("storeLoader","Please wait")
    await waitFor(1000)
    await STORE.storeApi.startCustomerSession(customer).then(data=>{
      if(!data.data.data.error){
        // setIsSessionActive(true)
        customer.customerSessionId = data.data.data.insertId
        STORE.isCustomerSessionActive = true
        showGlobalToast("Customer session has been activated",3000)
        // props.closeAddCustomer()
      }else{
        showAlert("customerFormAlert",data.data.message,"error")
      }
    })
    hideComponentLoader("storeLoader")
  }

  async function createVisitor(customer:Customer) {
    showComponentLoader("storeLoader","Please wait")
    await waitFor(1000)
    await CRM.crmApi.visitors.createVisitor({customerId:customer.id,checkIn:getTodayTimestampOnlyDate()}).then(data=>{
      if(!data.data?.data?.error){
        showGlobalToast("Marked as visited",3000)
      }else{
        showAlert("customerFormAlert",data.data.message,"error")
      }
    })
    hideComponentLoader("storeLoader")
  }
 
  async function createCustomer() {
 
    if(isCustomerFound){
      postCustomerCreateAction(foundCustomer)
      return
    }

   

    const customerName:any = $("#customerName").val()
    const email:any = $("#customerEmail").val()
    formData.customerName = customerName
    formData.email = email

    if(formData.customerName?.length && formData.mobile?.length){
      showComponentLoader("storeLoader")
      const customer = new Customer(formData)
    
      STORE.storeApi.createCustomer(customer).then((data:any)=>{
        if(!data.data.error){
          customer.id = data.data.data.insertId
          postCustomerCreateAction(customer)
          if(props.isStartSessionMode){
            createLead(data.data.data.insertId)
            return
          }
        }else{
          showGlobalToast(data.data.message,2000,"error")
        }
      })
    }else{
      showGlobalToast("Please enter mandatory fields",2000,"error")
    }
    await waitFor(800)
    hideComponentLoader("storeLoader")
  }


  async function postCustomerCreateAction(customer:Customer) {
    if(props.isCustomerCheckInMode){
      let salesStageId = getObjectByParameter(CRM.data.salesStages,"sales_stage","Visited")?.id
      await createVisitor(customer)
      await createLead(customer.id,salesStageId,!isCustomerFound)
      props.createCustomerCallback(customer)
      return
    }

    if(!props.isStartSessionMode){
      props.createCustomerCallback(customer)
      return
    }

    if(props.isCreateTicketMode){
      props.setActiveCustomer(customer)
      return
    }
   

    await activeCustomerSession(customer)
    STORE.setActiveCustomer(customer)
    props.setActiveCustomer(customer)
    if(applicationConfig.functions?.store_MainHeader?.refreshCartItems){
      applicationConfig.functions.store_MainHeader?.refreshCartItems()
    }
    showAlert(alertId,"Customer has been created successfully!!","success")
  }

  async function createLead(customerId:number,salesStageId:string = "1",isDirectVisit:boolean = false) {
    let userId = null
    if(props.isStartSessionMode){
      userId = getUser()?.userId
    }
    if(props.isCustomerCheckInMode){
      userId = null
    }
    const formData = {
        customerId:customerId,
        leadSource:"Online",
        salesStageId:salesStageId,
        potentialValue:"",
        sizeOfDeal:"High",
        productCategory:"",
        dateOfCreation:getTodayTimestampOnlyDate(),
        isDirectVisit:isDirectVisit
    }
    if(formData){
      showComponentLoader("storeLoader")
      CRM.crmApi.leads.createLead(formData,userId).then((data:any)=>{
        if(!data.data.error){
            showGlobalToast("Lead created",3000)
        }else{
          showGlobalToast("Internal server error",2000,"error")
        }
      })
    }else{
      showAlert(alertId,"Please enter mandatory fields","error")
    }
    await waitFor(800)
    hideComponentLoader("storeLoader")
  }

  function submitMobileHandle() {
    if(!formData.mobile){
      showAlert(alertId,"Please enter mobile number","error")
      return
    }
    if(phoneValidation(formData.mobile).length){
      showAlert(alertId,"Please enter a valid mobile number","error")
      return
    }
    if(props.isCreateTicketMode && !isCustomerFound){
      showGlobalToast("Customer not found",2000,"error")
      return
    }
    setIsSetMobileNumber(true)
  }

  function resetHandle() {
    setIsCustomerFound(false)
    setFoundCustomer(null)
    setIsSetMobileNumber(false)
    $(".input").val("")
    formData.mobile = ""
    formData.customerName = ""
  }
 
    
  return (
    <div className="create-customer-container full-height-width center-aligned-column bg-color-light overflow-y-scroll">

      <div className="form">
        <Alert
          id="customerFormAlert"
        />
        <CustomerMobileForm formData={formData} isSetMobileNumber={isSetMobileNumber} foundCustomer={foundCustomer}  setFoundCustomer={setFoundCustomer} setIsCustomerFound={setIsCustomerFound}/>
        {isSetMobileNumber || isCustomerFound?
        <CustomerDetailsForm foundCustomer={foundCustomer}/>
        :
        null
        }

        {isSetMobileNumber || isCustomerFound?
        <div className="padding5 center-aligned-column ion-margin-top">
          <div className="display-flex-row">
            <Button2
              configObj={{id:""}}
              action={createCustomer}
              label={props.isStartSessionMode?"Start Session":"Confirm"}
              fontAwesomeIcon={"fas fa-chevron-right color-white"}
              iconPos=""
              classNames={"no-shadow margin0 button-large cart-button cart-button-warning"}
            />
            {isCustomerFound?
                <Button2
                  configObj={{id:""}}
                  action={()=>{resetHandle()}}
                  label={"Reset"}
                  fontAwesomeIcon={"fas fa-chevron-right color-white"}
                  iconPos=""
                  classNames={"no-shadow width-auto button-large border"}
                />
            :null}
          </div>
          
        </div>
        :
        <div className="padding5 center-aligned-column ion-margin-top">
          <div className="display-flex-row">
            <Button2
              configObj={{id:""}}
              action={submitMobileHandle}
              label={"Continue"}
              fontAwesomeIcon={"fas fa-chevron-right color-white"}
              iconPos=""
              classNames={"no-shadow margin0 button-large cart-button cart-button-warning"}
            />
            
          </div>
          
        </div>
        }

        
        
      </div>
      
    </div>
  );
};

export default CreateCustomer;
