import React from "react";


const Compass: React.FC<{
  }> = (props) => {
 
  
  return (
      <div className="top-left compass-wrapper center-aligned-column hide-on-configure zindex30">
        <div id="compassContainer" className="compass-container center-aligned-column position-relative">
          <div className="">
            <i  className="fas fa-location-arrow font-normal color-danger compass-icon"></i>
            <div className="heading1 font-large" style={{lineHeight:"12px"}}>N</div>
          </div>
          {/* <div className="bottom-middle padding bg-color-white heading1 font-small">S</div>
          <div className="right-middle padding bg-color-white heading1 font-small">E</div>
          <div className="left-middle padding bg-color-white heading1 font-small">W</div> */}
          
        </div>
      </div>
 
  );
};

export default Compass;