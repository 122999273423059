import { arrowUndoCircleOutline, colorPalette } from "ionicons/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { getMoodboardList } from "../../../../services/api";
import { logger } from "../../../../utils/methods";
import { addNewBadgeClassToElement, hideComponentLoader, showComponentLoader, updateLoaderProgress } from "../../../../utils/UI_methods/global";
import Actions from "../../../common/ui-components/tabs/Actions";
import $ from "jquery"
const FinalActions: React.FC<{
  configObj:any
  setCurrProjectDetails:(id:number,name:string)=>void
  setCurrMoodboardId:(id:string)=>void
  refreshMoodboardList:(data:Array<any>)=>void
  formData:any
}> = (props) => {


  const [actions,setActions] = useState([
    
    {
      name: "goBakc",
      label: "Design later",
      iconClassNames: ["color-dark"],
      icon: arrowUndoCircleOutline,
      action: ()=>{gotomoodboard(false)},
    },

    {
      name: "ContinueDesigning",
      label: "Start designing",
      iconClassNames: ["color-danger"],
      icon: colorPalette,
      action: gotomoodboard,
    },
    
  ]);
  
  let history  = useHistory()

  function closeModal() {
      props.configObj.functions.setaddNewCard(false)
  }

 
  function gotomoodboard(isStartDesigning = true) {
    // let projectId = props.currMoodboardDetails?.project_id
    // let moodboardId = props.currMoodboardDetails?.moodboard_id
    // Set products for the project
    showComponentLoader("mainLoader")
    getMoodboardList().then(moodboardListData=>{
      //fetch and update data in config obj
      let configData = props.configObj.getData()
      configData['moodboardList'] = moodboardListData
      props.configObj.setData(configData)

      props.configObj.moodboardId = props.formData.moodboardId

      props.refreshMoodboardList(moodboardListData)

      setTimeout(() => {
        if(isStartDesigning){
          updateLoaderProgress("mainLoader",1,1,"Loading collection")
          openMoodboard()
        }else{
          updateLoaderProgress("mainLoader",1,1,"Please wait")
          postCreateAction()
        }
      }, 500);

    
    }).catch(err=>{
      console.log(err)
    })
  
  }

  function openMoodboard() {
    props.setCurrMoodboardId(props.formData.moodboardId)
    hideComponentLoader("mainLoader")
    logger?.info("editcollection","edit",`Start designing: ${props.formData.moodboardId}`)
    history.push('/edit-moodboard')
  }

  async function postCreateAction() {
    closeModal()
    props.configObj.functions.moodboard.setCurrSelectedSagment("Other")
    setTimeout(() => {
      addNewBadgeClassToElement("moodboardCard_"+props.formData.moodboardId)
      hideComponentLoader("mainLoader")
      // $(".main-inner-content-wrapper").scrollTop()
      $("#mainMoodboardContentWrapper").find(".main-inner-content-wrapper").animate({ scrollTop: $(".main-inner-content-wrapper").innerHeight() * 10 }, 500);
    }, 500);
  }
 
  return (
    <React.Fragment>
      <div className="modal-header capitalize">Choose option</div>
      <div className="modal-body">
        <div className="create-project-areas-container" style={{ padding: "0 0", overflowY: "hidden" }}>
          <div className="product-actions full-height-width center-aligned-column">
            <div className=""><i className="fas fa-check-circle font-ex-large color-success"></i></div>
            <div className="heading1 color-black letter-spacing">Your collection has been created successfully</div>
            <div className="ion-margin-bottom marginTop5 heading2 color-dark letter-spacing">Choose any option to continue</div>
            <Actions configObj={props.configObj} actionList={actions} />
          </div>
        </div>
      </div>
      <div className="modal-footer space-between">
        
      </div>
    </React.Fragment>
  );
};

export default FinalActions;
