import React, { useState } from "react";
import { Customer } from "../../../../../../../../utils/store/storeConfiguration";
import Modal from "../../../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import CreateCustomer from "../../../../customer/CreateCustomer/CreateCustomer";
import Calendar from "../../calender/Calendar";
import CreateTicket from "../createticket/CreateTicket";
import Search from "./Search";
 
const Header: React.FC<{
  refreshList:()=>void
  setSearchTicketsList:(values:any)=>void
  allCalendarEvents:Array<any>
}> = (props) => {

  const[customer,setCustomer] = useState(null)
  const[isOpen,setIsOpen] = useState(false)
  const[isOpenCalender,setIsOpenCalender] = useState(false)
  const[isOpenCreateTicket,setIsOpenCreateTicket] = useState(false)


  
  function LeftContent() {
    return(
      <div className="display-flex-row space-between">

      <div className="center-aligned-column">
        <Button2
          configObj={{id:""}}
          action={()=>{setIsOpen(true)}}
          label={"Create ticket"}
          fontAwesomeIcon={"fas fa-edit color-dark"}
          iconPos="left"
          classNames={"shadow margin0 button- bg-color-primary"}
        />
      </div>

      

      </div>
    )
  }



  function createCustomerCallback(customer:Customer) {
    setIsOpen(false)
    setIsOpenCreateTicket(true)
    setCustomer(customer)
    // props.refreshList()
  }

  function createTicketCallback() {
    setIsOpenCreateTicket(false)
    props.refreshList()
  }
 



  return (
    <div className="full-width position-relative display-flex-row space-between border-bottom bg-color-light calendar-header" style={{padding:"10px 0",height:"fit-content"}}>


        <Modal
          id=""
          classNames="small-y-modal"
          onDismiss={()=>setIsOpen(false)}
          isOpen={isOpen}
          Content={()=>(
            <CreateCustomer isCreateTicketMode={true} createCustomerCallback={createCustomerCallback} isCustomerCheckInMode={false} isStartSessionMode={false} setActiveCustomer={()=>{}}/>
          )}
          heading="Create new ticket"
          FooterContent={()=>(<div></div>)}
          >
        </Modal>

        <Modal
          id=""
          classNames="large-x-modal"
          onDismiss={()=>setIsOpenCalender(false)}
          isOpen={isOpenCalender}
          Content={()=>(
            <Calendar  eventsList={props.allCalendarEvents} callbackAfterDateSelect={()=>{}} lead={null} isSetEventMode={false} activityEvent={null}/>
          )}
          heading="Appointments"
          FooterContent={()=>(<div></div>)}
          >
        </Modal>

        <CreateTicket createTicketCallback={createTicketCallback} isOpen={isOpenCreateTicket} setIsOpen={setIsOpenCreateTicket} customer={customer}/>

        <LeftContent/>
     

        <div className="display-flex-row  hide-in-mobile">
          <div className="center-aligned-column marginleft5">
            <Button2
              configObj={{id:""}}
              action={()=>{setIsOpenCalender(true)}}
              label={""}
              fontAwesomeIcon={"fas fa-calendar-alt color-warning"}
              iconPos="left"
              classNames={"no-shadow bg-color-warning-outline"}
            />
          </div>
          <Search setSearchedCustomerList={props.setSearchTicketsList}/>
          
        </div>

      </div>  
  );
};

export default Header;
