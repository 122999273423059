import $ from "jquery";
import React, { useEffect, useState } from "react";

const TimeStampFilter: React.FC<{
  configObj: any;
  filterWithTimestamp:(start:number,end:number) => void
  resetLogs:()=>void
  currSelectedTimeStamp:string
  setCurrSelectedTimeStamp:(val:string)=>void
}> = (props) => {


 

  function updateTime(event:any) {
    // $(".log-timestamp-options").find(".option").removeClass("--is-active")

    let time = Number($(event.target).attr("data-time-value"))
    let date = new Date()

    props.filterWithTimestamp(date.setHours(date.getHours() - time),Number(new Date()))
    // console.log(Number(endDate),endDate.setHours(endDate.getHours() - time))

    props.setCurrSelectedTimeStamp(String(time))

  }

 
 

  // function addActiveClass() {
    
  //   let options = $(".log-timestamp-options").find(".option")
  //   console.log(options)
  //   for (let i = 0; i < options.length; i++) {
  //     const element = options[i];
  //     if(!$(element).hasClass("--is-active")){
        
  //     }
      
  //   }
    
  // }


  function toggleCustomInput() {
    $("#customTimestampinput").toggleClass("--is-active")
  }

  function customSelect() {
    let startTime:any = $("#startTimestamp").val() || ""
    let startDateString = startTime.substring(0,startTime.indexOf("T"))
    let startTimeString = startTime.substring(startTime.indexOf("T")+1)
    startDateString = startDateString.split("-")
    startTimeString = startTimeString.split(":")
    let finalStartDate = new Date(startDateString[0],startDateString[1]-1,startDateString[2],startTimeString[0],startTimeString[1],0)
    
    let endTime:any = $("#endTimestamp").val() || ""
    let endDateString = endTime.substring(0,endTime.indexOf("T"))
    let endTimeString = endTime.substring(endTime.indexOf("T")+1)
    endDateString = endDateString.split("-")
    endTimeString = endTimeString.split(":")
    let finalendDate = new Date(endDateString[0],endDateString[1]-1,endDateString[2],endTimeString[0],endTimeString[1],0)

    props.filterWithTimestamp(Number(finalStartDate),Number(finalendDate))

  }

  const values = [
    {
      label:"30m",
      value:"0.5"
    },
    {
      label:"1h",
      value:"1"
    },
    {
      label:"12h",
      value:"12"
    },
    {
      label:"24h",
      value:"24"
    }
  ]
 


  return (
  <div className="log-timestamp-options  center-aligned-column">
    <div className="display-flex-row container">
      <div className="option" onClick={props.resetLogs}>Clear</div>
      {values.map((currValue:any,index:number)=>{
        return(
          <div key={index} className={currValue.value == props.currSelectedTimeStamp?"option --is-active":"option"} data-time-value={currValue.value} onClick={updateTime}>{currValue.label}</div>
        )
      })}

      <div className="option border-left" onClick={toggleCustomInput}>
        Custom <i className="fas fa-calender color-dark"></i>
      </div>
    </div>


    <div id="customTimestampinput" className="custom-options-container white-card shadow-light "> 
      <div className="display-flex-row no-wrap">
        <div>
          <div className="label">Start time</div>
          <input type="datetime-local" id="startTimestamp" className="input"/>
        </div>

        <div className="marginleft10">
          <div className="label">End time</div>
          <input type="datetime-local" id="endTimestamp" className="input" />
        </div>

      </div>

      <div className="display-flex-row border-top ion-margin-top">
        <button className="op-btn op-btn-light marginTop10" onClick={toggleCustomInput}>Cancel</button>
        <button className="op-btn op-btn-primary marginleft10 marginTop10" onClick={customSelect}>Apply</button>
      </div>
    </div>
   
  </div>
  )
};

export default TimeStampFilter;
