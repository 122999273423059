import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { checkFromCache, getAndSaveImageToCache } from '../../../../../../../utils/cache/cache';
import { projectConfiguration } from '../../../../../../../utils/customizein3d/area3dmodel/area3dModel';
import { getFilteredArray } from '../../../../../../../utils/methods';
import $ from "jquery"

const GalleryAreasList: React.FC<{
  allRendersList:any
  setcurrSelectedArea:(areaName:string)=>void
  currTab:string
}> = (props) => {

  const[areasList,setAreasList] =  useState([])

  useEffect(()=>{
    setTimeout(() => {
      displayAreaImages()
    }, 500);
  },[props.currTab,props.allRendersList])

  function displayAreaImages() {
    let areas = [...projectConfiguration.areasList]
    // areas.pop()
    setAreasList(areas)
    for (const area of areas) {
      let renders = getFilteredArray(props.allRendersList,"areaName",area.area_name)
      displayImage(renders,0)        
      // if(renders.length){
      //   for (let i = 0; i < 1; i++) {
      //     displayImage(renders,i)        
      //   }
      // }
      
    }
  }

  function displayImage(renders:any,index:number) {
    let currcamera = renders[index]
    if(currcamera){
      displayCameraImage(currcamera,index)
    }
  }

  async function displayCameraImage(currCamera:any,index:any = null){
    let imageUrlKey = `2DImages/DemoClient2/NewDemo/${currCamera.areaName}${currCamera.cameraId}/${currCamera.areaName}0000.png`

    let element = $(`#galleryAreaImage_${currCamera.areaName}`)

    await checkFromCache(imageUrlKey)?.then((url: any) => {
      element?.attr("src", url);
    })
    .catch(async (err) => {
      await getAndSaveImageToCache(imageUrlKey).then(data=>{
        checkFromCache(imageUrlKey)?.then((url: any) => {
          element?.attr("src", url)
        })
      }).catch(err=>{
        console.log(imageUrlKey)
      })
    });
 }


  return (
    <React.Fragment>
      <div className='full-height-width display-flex-row overflow-y-scroll' style={{padding:"10px 10px 20rem 10px",alignItems:"baseline",alignContent:"baseline"}}>
        {areasList.map((area:any,index:number)=>{
        let totalCamera = projectConfiguration.cameras.filter(currCamera=>currCamera.renderType === "2d" && currCamera.areaName === area.area_name).length
        return(
        
            <div key={index} className={`${totalCamera?"width50 aspect-four-by-three position-relative padding5":"display-none"}`}  style={{alignItems:"baseline"}}>
              {/* <div className=' top-left zindex30 padding10'>
                <div className='white-card no-shadow font-small heading2 center-aligned-column' style={{padding:"3px 7px"}}>{String(totalCamera)}</div>
              </div> */}

              <div className='middle heading2 zindex100 pointer-none '>
                <div className='display-flex-row no-wrap'>
                  <div className='color-light'>{area.area_name}</div>
                  <div className='white-card no-shadow font-small heading2 marginleft5 center-aligned-column' style={{padding:"3px 7px"}}>{String(totalCamera)}</div>
                </div>
              </div>
              <div className='white-card full-height-width pointer shadow-light ' onClick={()=>props.setcurrSelectedArea(area.area_name)}>
                <img src="" className='full-height white-card no-shadow pointer-none'   alt="" id={`galleryAreaImage_${area.area_name}`}  style={{width:"100%",objectFit:"cover",filter:`brightness(0.7)`}}/>
                {/* <img src="" className='full-height'  alt="" id={`galleryAreaImage_${area.area_name}2`}  style={{width:"auto",objectFit:"contain"}}/>
                <img src="" className='full-height'  alt="" id={`galleryAreaImage_${area.area_name}3`}  style={{width:"auto",objectFit:"contain"}}/> */}
              </div>
            </div>
        )
        })}
      </div>
    </React.Fragment>
  );
};

export default GalleryAreasList;
