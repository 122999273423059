import { IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getFilteredProductForDemo } from "../../../utils/store/storeConfiguration";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, stringWithoutWhiteSpace } from "../../../utils/methods";
import Loader from "../../common/shared-components/main-ui/Loader";
import MainHeader from "./common/MainHeader";
import SubHeader from "./common/SubHeader";
import ProductsList from "./ProductsList";
import Button2 from "../../common/ui-components/buttons/Button2";
import ProductFilterHeader from "./common/ProductFilterHeader";
 

const ElevateCategoryProducts: React.FC<{
  configObj:any
  isLogin:boolean
}> = (props) => {

  const parameters:any = useParams();
  const history = useHistory()

  const[subCategories,setSubCatgories] = useState([])
  const [currSelectedCategory, setCurrSelectedCategory] = useState<string>(parameters.category);
  const[currSelectedSubCategory,setCurrSelectedSubCategory] = useState("All")

  const [allProductsList]: any = useState(getFilteredProductForDemo());
  const [productsList,setProductsList]: any = useState(allProductsList);
  const [loadLimit, setLoadLimit] = useState(10);


  useEffect(()=>{
    if(props.configObj && props.isLogin){
      const categoryName = parameters.category || "Sofa"
      setCurrSelectedCategory(categoryName)
      setCurrSelectedSubCategory("All")
      const filteredSubCat = getFilteredArray(applicationConfig?.data?.subCategories,"category_name",categoryName)
      setSubCatgories(getArrayOfDistinctValues(filteredSubCat,"sub_category_name"))
    }
  },[parameters.category,history])

  useEffect(()=>{
    if(currSelectedCategory!="" && currSelectedSubCategory!= ""){
      setLoadLimit(10)

      let filteredProductsList = allProductsList?.filter(product=>(stringWithoutWhiteSpace(product.category_name)===stringWithoutWhiteSpace(currSelectedCategory)) && product.product_type != "custom");
      filteredProductsList = filteredProductsList?.slice(0,20)
      if(currSelectedSubCategory?.toLowerCase() === "all"){
        setProductsList(filteredProductsList)
        return
      }
      filteredProductsList = allProductsList?.filter(product=>(product.category_name===currSelectedCategory&&product.sub_category_name===currSelectedSubCategory ) && product.product_type != "custom");
      setProductsList(filteredProductsList)
    }
  },[currSelectedCategory,currSelectedSubCategory,allProductsList])

 
  function ContentRight() {
    return(
      <>
        <div className="sort-items-container">
          <ProductFilterHeader heading={"Sofas & Sectional Collections"} filterOptions={["View All","In Stock"]} isHomePage={false}/>
        </div>
        <ProductsList productsList={productsList}/>
      </>
    )
  }



 
  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white elevate-main-wrapper overflow-y-scroll">
        <MainHeader/>
        <SubHeader/>

        <div className="main-content-wrapper position-relative bg-color-white">

            <div className="background-white position-relative overflow-hidden full-height-width">
              <div className="main-cards-filters-wrapper no-wrap padding-container full-height-width" >
                <ContentRight/>
              </div>
            </div>

        </div>

      </div>
      :null}
    </IonPage>
  );
};

export default ElevateCategoryProducts;
