import React from "react";
import "../../../style/admin/error.css";
import Button from "../../ui-components/buttons/Button";
    // import Controls from "./controls/Controls"
  
     
    
const Error: React.FC<{
    configObj:any
}> = (props) => {

    function HeaderLeftComponent() {
        return (
          <div className="brand">
            {/* <img src="/assets/images/opus.png" alt="Opus"/> */}
            <div className="heading font-medium">Oops</div>
          </div>
        );
      }
    
      function MainContent() {
        return (
            <div className="full-height-width center-aligned-column bg-color-ternary">
                <div className="ion-padding">
                    <div className="top-left">
                        <img src="assets/images/opus.png" alt="" width={200} />
                    </div>
                    <div className="font-ex-large color-primary">Something Went Wrong...</div>
                    <div className="font-normal marginTop5">Your query has been sent to our backend team, we will contact you soon..</div>
                    <div className="display-flex-row ion-margin-top">
                        <Button
                            configObj={props.configObj}
                            action={()=>{}}
                            label={"Contact us"}
                            fontAwesomeIcon={"fas fa-envelope color-white"}
                            iconPos="left"
                            classNames={"bg-color-white no-margin op-btn-primary shadow-light"}   
                        />

                        <Button
                            configObj={props.configObj}
                            action={()=>{window.location.href = "https://www.ovlapps.com"}}
                            label={"Back to home"}
                            fontAwesomeIcon={"fas fa-home color-primary"}
                            iconPos="left"
                            classNames={"bg-color-white marginleft5 shadow-light"}   
                        />
                    </div>
                </div>
            </div>
        );
      }
    
    
    return (
    //     <MainLayout 
    //         configObj={props.configObj}
    //         // FilterHeaderContent={FilterHeader}
    //         CardsContainer={MainContent}
    //         HeaderLeftContent={HeaderLeftComponent}
    //         HeaderMiddleContent={null}
    //         contentWrapperId="mainErrorContentWrapper"
    //   />

        <MainContent/>
    );
};

export default Error;
    