import Konva from "konva";
import { cancelAddonFill, currSelectedAddon } from "./addons";
import {
    hideProductSelectionBox
} from "./helper";
import { moodboardConfig } from "./moodboard";
import {
    hideAllProductAnnotations,
    hideItemsPanel,
    hideProductActions
} from "./UI_methods";
import $ from "jquery"

var x1, y1, x2, y2;
export var isMultipleSelectionMode = false


export function addMultipleSelect(stage:any,layer:any,canvasContainerId:string) {

      let selectionRectangle = moodboardConfig.getSelectionRectangle(canvasContainerId)

      stage.on('mousedown touchstart', (e) => {
        // do nothing if we mousedown on any shape
        if (e.target !== stage) {
          return;
        }

        e.evt.preventDefault();
        x1 = stage.getPointerPosition().x;
        y1 = stage.getPointerPosition().y;
        x2 = stage.getPointerPosition().x;
        y2 = stage.getPointerPosition().y;

        selectionRectangle.visible(true);
        selectionRectangle.width(0);
        selectionRectangle.height(0);
        layer.draw()

        hideProductSelectionBox(null,canvasContainerId);
        hideProductActions();
        hideAllProductAnnotations();
        hideItemsPanel();
        disableMultiSelectionMode(layer,canvasContainerId)
        if (!currSelectedAddon?.attrs?.isAddonSelected) {
          cancelAddonFill();
        }

      });

      stage.on('mousemove touchmove', (e) => {
        // do nothing if we didn't start selection
        if (!selectionRectangle.visible()) {
          return;
        }
        e.evt.preventDefault();
        x2 = stage.getPointerPosition().x;
        y2 = stage.getPointerPosition().y;

        selectionRectangle.setAttrs({
          x: Math.min(x1, x2),
          y: Math.min(y1, y2),
          width: Math.abs(x2 - x1),
          height: Math.abs(y2 - y1),
        });
        selectionRectangle.visible(true)
        layer.draw()
      });

      stage.on('mouseup touchend', (e) => {
        // do nothing if we didn't start selection
        if (!selectionRectangle.visible()) {
          return;
        }

        // if(e.target == stage && isMultipleSelectionMode){
        //   disableMultiSelectionMode(layer)
        //   return
        // }
        e.evt.preventDefault();
        // update visibility in timeout, so we can check it in click event
        setTimeout(() => {
          selectionRectangle.setAttrs({
            x:0,
            y:0,
            width:0,
            height:0,
          });
          selectionRectangle.visible(false)
          layer.draw()
        });
        

        // if(!Object.keys(stage.find(".itemsLayer")[0].children).length){
        //   $("#products").click()
        // }

        var shapes = stage.find('.canvasImageItemGroup');
        var box = selectionRectangle.getClientRect();
        var selected = shapes.filter((shape) =>
          Konva.Util.haveIntersection(box, shape.getClientRect())
        );

        if(selected.length){
            enableMultiSelectionMode(selected,layer,canvasContainerId)
        }else{
            disableMultiSelectionMode(layer,canvasContainerId)
        }
      
      });
      
}


function enableMultiSelectionMode(array:Array<any>,layer:any,canvasContainerId:string) {
    let transformer = moodboardConfig.getTransformer(canvasContainerId)
    isMultipleSelectionMode = true
    transformer.nodes(array)
    transformer.rotateEnabled(false)
    transformer.opacity(1)
    transformer.update()
    layer.draw()
}

export function disableMultiSelectionMode(layer:any,canvasContainerId:string) {
    let transformer = moodboardConfig.getTransformer(canvasContainerId)
    isMultipleSelectionMode = false
    transformer.rotateEnabled(true)
    transformer.opacity(0)
    transformer.nodes([])
    transformer.update()
    layer.draw()
} 