import { Camera, Object3D } from "three";
import { updateUndoVisibility } from "../customizein3d/area3dmodel/UI_methods";
import { applicationConfig, getArrayOfDistinctValuesFromObject } from "../methods";



export default class CustomizerConfig {
  projectProductsList: any[];
  materials: any[];
  functions: any = {}
  currentRenderingType:string = "3d"
  dragStartArea:string
  dragEndArea:string
  areaCameraMappping:any = {}
  isFullScreenMode:boolean = false
  isFinalizeMode:boolean = false
  isFloorplanMode:boolean = false
  isSnap:boolean = true
  isMultipleSelectionMode:boolean = false
  isChangedHeight:boolean = false
  currentPositionCameraTranslation:number = 0
  renderStartTime:number = 0
  renderEndTime:number = 0
  flatSurfaceProducts:Array<Object3D> = []
  isProductConfigurationMode:boolean = false
  offsetForFrontWall = 0.001
  isProductsLoaded = true
  isAutoRotation: boolean = true
  isHideWalls: boolean = false

  cameraMinHeight:number = 0
  cameraMaxHeight:number = 2

  dimensionUnit:string = "feet"
  isShowRoomDimensions:boolean = false

  customizableObjectsArray = []

  constructor(areaList: object) {
    // this.areaList = areaList;
    this.customizableObjectsArray = getArrayOfDistinctValuesFromObject(applicationConfig?.data?.customizableObjects,"object_name")
  }
 
 
  setMaterials(materials: Array<any>) {
    this.materials = materials;
  }

 
  setProjectProductsList(data: Array<any>) {
    this.projectProductsList = data;
  }
   
  setCurrentRenderingType(type:string){
    this.currentRenderingType = type
  }
 
  addFunctionRef(fun:any){
    this.functions = {...this.functions,fun:fun}
  }

  setIsProductsLoaded(val:boolean){
    this.isProductsLoaded = val
  }



  setDragStartArea(areaName:string){
    this.dragStartArea = areaName
  }

  setDragEndArea(areaName:string){
    this.dragEndArea = areaName
  }

  updateAreaCameraMapping(areaName:string,sceneCamera:Camera){
    this.areaCameraMappping = {...this.areaCameraMappping,[areaName]:sceneCamera}
  }

  getAreaCamera(areaName:string){
    return this.areaCameraMappping[areaName]
  }

  setIsFullScreenMode(val:boolean){
    this.isFullScreenMode = val
  }

  setIsFinalizeMode(val:boolean){
    this.isFinalizeMode = val
  }

  setIsSnap(val:boolean){
    this.isSnap = val
  }

  setIsAutoRotation(val:boolean){
    this.isAutoRotation = val
  }

  setIsHideWalls(val:boolean){
    this.isHideWalls = val
  }

  
  setIsFloorplanMode(val:boolean){
    this.isFloorplanMode = val
  }

  setIsMultipleSelectionMode(val:boolean){
    this.isMultipleSelectionMode = val
  }

  setIsChangedHeight(val:boolean){
    this.isChangedHeight = val
  }
  setCurrPositionCameraTranslation(val:number){
    this.currentPositionCameraTranslation = val
  }

  setRenderStartTime(time:number){
    this.renderStartTime = time
  }

  setRenderEndTime(time:number){
    this.renderEndTime = time
  }

  
  setIsProductConfigurationMode(val:boolean){
    this.isProductConfigurationMode = val
  }

  addFlatSurfaceProduct(object:Object3D){
    this.flatSurfaceProducts.push(object)
  }
  
}


export class Undo{
  mode:string = "3d"
  itemActionsStack:Array<any> = []
  itemsStack3D:Array<any> = []
  itemsStackRender2D:Array<any> = []
  itemsStackRender3D:Array<any> = []
  itemsStackFloorplan:Array<any> = []
  
  
  constructor(mode:string){
    this.updateMode(mode)
  }

  updateMode(mode:string){
    this.mode = mode
    switch (mode.toLowerCase()) {
      case "360":
        this.itemActionsStack = this.itemsStack3D
      break;

      case "2d":
        this.itemActionsStack = this.itemsStackRender2D
      break;

      case "3d":
        this.itemActionsStack = this.itemsStackRender3D
      break;

      case "floorplan":
        this.itemActionsStack = this.itemsStackFloorplan
      break;
    
      default:
        break;
    }
  }

  add(action:string,data:any){
    this.itemActionsStack.push(new UndoItem(action,data))
    updateUndoVisibility()
  }

  getLastItem(){
    let length = this.itemActionsStack.length 
    if(!length){
      return null
    }
    return this.itemActionsStack[length - 1] || null
  }

  removeLastItem(){
    this.itemActionsStack.pop()
  }
}

export class UndoItem{
  action:string
  data:any
  constructor(action:string,data:any){
    this.action = action
    this.data = data
  }
}
