import React from "react";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getObjectByParameter } from "../../../../../utils/methods";

 
const OtherDimensions: React.FC<{
    configuration:any
    configuration3D:any
    isReadOnly:boolean
}> = (props) => {


    function updateValue(type:string,value:number) {
        if(type === "handleHeight"){
            props.configuration.handleHeight = value
        }

        if(type === "seatHeight"){
            props.configuration.seatHeight = value
        }

        if(type === "backHeight"){
            props.configuration.backHeight = value
        }

        if(type === "legsHeight"){
            props.configuration.legsHeight = value
        }
        showGlobalToast("Updated")
    }

    function getLegsHeight(currLeg:string){
        let result = 0
        let leg = getObjectByParameter(applicationConfig.data.sofaLegs,"leg_name",currLeg)
        if(leg){
            result = leg.height
        }
        return result
    }


    function Info() {
        let configuration = props.configuration
        return(
            <div className="marginTop10">
                <div className="marginTop10">
                <div className="Pricing"></div>
                <table className="table store-quotation-table">
                <tbody>
               
                    <tr>
                        <td>ArmRest Height</td>
                        <td className="display-flex-row no-wrap">
                            {props.isReadOnly?
                            <span className="heading3 font-md-small">{configuration.handleHeight}</span>
                            :
                            <input onBlur={(event)=>updateValue("handleHeight",Number(event.target.value))} style={{maxWidth:"5rem"}} type="number" className="input heading3 marginleft5" defaultValue={configuration?.handleHeight}/>
                            }
                            <span className="heading4 font-small center-aligned-column marginleft5">In</span>    
                        </td>
                        <td>Seat Height</td>
                        <td className="display-flex-row no-wrap">
                            {props.isReadOnly?
                            <span className="heading3 font-md-small">{configuration.seatHeight}</span>
                            :
                            <input onBlur={(event)=>updateValue("seatHeight",Number(event.target.value))} style={{maxWidth:"5rem"}} type="number" className="input heading3 marginleft5" defaultValue={configuration?.seatHeight}/>
                            }
                            <span className="heading4 font-small center-aligned-column marginleft5">In</span>      
                        </td>
                    </tr>

                    <tr>
                        <td>Back Height</td>
                        <td className="display-flex-row no-wrap">
                            {props.isReadOnly?
                            <span className="heading3 font-md-small">{configuration.backHeight}</span>
                            :
                            <input onBlur={(event)=>updateValue("backHeight",Number(event.target.value))} style={{maxWidth:"5rem"}} type="number" className="input heading3 marginleft5" defaultValue={configuration?.backHeight}/>
                            }
                            <span className="heading4 font-small center-aligned-column marginleft5">In</span>    
                        </td>
                        <td>Legs Height</td>
                        <td className="display-flex-row no-wrap">
                            {props.isReadOnly?
                            <span className="heading3 font-md-small">{configuration.legsHeight}</span>
                            :
                            <input onBlur={(event)=>updateValue("legsHeight",Number(event.target.value))} style={{maxWidth:"5rem"}} type="number" className="input heading3 marginleft5" defaultValue={getLegsHeight(props.configuration3D?.currSofaLeg)}/>
                            }
                            <span className="heading4 font-small center-aligned-column marginleft5">In</span>      
                        </td>
                    </tr>

                </tbody>
                </table>
            </div>
            </div>
        )
    }
   
 
  return (
    <Info/>
  );
};

export default OtherDimensions;
