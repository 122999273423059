import React, { useEffect, useState } from 'react';
import { getSavedConfigList } from '../../../../../utils/customizein3d/modules/helper';
import { configuration, loadModulesFromSavedConfiguration, resetConfiguration } from '../../../../../utils/customizein3d/modules/customizein3d';
import { waitFor } from '../../../../../utils/methods';
import { hideComponentLoader, showComponentLoader } from '../../../../../utils/UI_methods/global';

// import './Home.css';

const ConfigList: React.FC<{
    setIsRendersOpen:(val:boolean)=>void
}> = (props) => {
 
    const[rendersList,setRendersList] = useState([])
    
    useEffect(()=>{
        let renders = getSavedConfigList()
        if(renders.length){
          renders.sort((a:any,b:any)=>a.updated_on>b.updated_on?-1:1)
          setRendersList(renders)
        }
       
    },[])

    async function loadConfiguration(config:any) {
      showComponentLoader("canvasLoader")
      props.setIsRendersOpen(false)
      resetConfiguration()
      await waitFor(500)
      await loadModulesFromSavedConfiguration(JSON.parse(config.configuration))
      configuration.updateConfigId(config.id)
      hideComponentLoader("canvasLoader")
    }
 
  return (
    <div className="full-height-width overflow-y-scroll">
    <div className="renders-list display-flex-row">
      {rendersList.length?
        <table className="table">
          <thead>
            <tr>
              <th>Config name</th>
              <th>Last updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>

            {rendersList?.map((render:any,index:number)=>{
              var d = new Date(Number(render.updated_on))
            return(
            <tr key={index} className="" style={{padding:"10px 0"}}>
                <td ><div className="center-aligned-column position-relative full-height "> 
                <span className="display-flex-row heading4">
                {configuration.configId == render.id?<span className='bg-color-success left-middle white-card' style={{width:"10px",height:"10px"}}></span>:null}
                {render.title}
                </span>  
                </div></td>
                <td ><div className="center-aligned-column full-height heading4">{d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear() + " " +  d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds()}</div></td>
                <td className="center-aligned-row"  onClick={()=>loadConfiguration(render)}>
                     <div className="render-action heading4 white-card shadow-light marginleft10 bg-color-dark color-white pointer">Load config</div>
                </td>
            </tr>
            )
            })}
      
          </tbody>
        </table>
        :
        <div className="center-aligned-column full-width">
          <div className="heading4 ion-padding text-center full-width" style={{border:"1px dashed lightgray",borderRadius:"10px"}}>No saved Configs</div>
        </div>
        }
  </div>
  </div>
  );
};

export default ConfigList;
