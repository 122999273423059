import React from "react";
import { createProductForm } from "../../../../../utils/products/createProductForm";
import MultipleFileUpload from "../../../../common/ui-components/forms/MultipleFileUpload";
 

const SelectImageForm: React.FC<{
    productDetails:createProductForm
}> = (props) => {
    
    function setProductImage(file:any) {
        props.productDetails.setProductImage(file)
    }

    async function setGalleryImages(value:any) {
        let galleryList = []
        for (const key in value) {
          const file = value[key]
          if(file.name && file.size){
            galleryList.push(file)
          }
        }
        props.productDetails.galleryImages = galleryList
    }

    return (
        <div id="selectProductImageForm" className="width70 create-custom-order-wrapper" style={{display:""}}>
            <div className="padding5">
                <div className="store-color-primary text-center heading3 padding5 font-medium">Upload Product Image</div>
                    <MultipleFileUpload 
                        heading=""
                        setFiles={setGalleryImages}
                        acceptExtensions = ".png,.jpg,.webp"
                        multiple = {true}
                        isSelectDefaultImage={true}
                        setDefaultImage={setProductImage}
                        selectedFiles={[]}
                    />
            </div>
        </div>
    );
};

export default SelectImageForm;
