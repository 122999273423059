import $ from "jquery";
import React, { useEffect, useState } from "react";
import { saveUploadedFileDetails } from "../../../../../../services/api";
import {
  hideComponentLoader,
  showComponentLoader
} from "../../../../../../utils/UI_methods/global";
import { createBounceEffectEvents } from "../../../../../../utils/UI_methods/ui_effects";
import {
  checkFromCache,
  getAndSaveImageToCache
} from "../../../../../../utils/cache/cache";
import { applicationConfig, getObjectByParameter } from "../../../../../../utils/methods";
import { addEventListenerOnImages } from "../../../../../../utils/moodboard/helper";
import { dragStart, dropImageMoodboard } from "../../../../../../utils/moodboard/moodboard";
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import Rembg from "../../../../../common/ui-components/forms/rembg/Rembg";

const MyImages: React.FC<{
  configObj: any;
}> = (props) => {
  const [myImages, setMyImages]: any = useState([]);

  const [refreshImages, setRefreshImages] = useState(false);
  const [isOpenImageUpload, setIsOpenImageUpload] = useState(false);
  const [uploadImageLoaderId] = useState("uploadImageLaoder");




  // useEffect(()=>{
  //   setTimeout(() => {
  //     if(selectedFile){
  //       displayUploadedImage(selectedFile,"collectionUploadImg")
  //     }
  //   }, 200);
   
  // },[selectedFile])

  useEffect(() => {
    let albumName = "uploads"
    var albumPhotosKey = encodeURIComponent(albumName) + '/' + applicationConfig?.clientName + "/collections";
    applicationConfig.awsConfig.viewAlbumContents(albumPhotosKey)
      .then((photos: any) => {
        setMyImages(photos);
        for (let i = 0; i < photos.length; i++) {
          if (i === 0) {
            continue;
          }
          let key = photos[i];
          getAndSaveImageToCache(key).then((data) => {
              if (i === photos.length - 1) {
                setTimeout(() => {
                  displayThumbnails();
                }, 1000);
              }
            })
            .catch((err) => {
              if (i === photos.length - 1) {
                setTimeout(() => {
                  displayThumbnails();
                }, 1000);
              }
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refreshImages, props.configObj]);

  useEffect(() => {
    if(myImages.length){
      addEventListenerOnImages(".image-drag-icon")
    }
  }, [myImages]);

  function displayThumbnails() {
    let thumbnails = document.querySelectorAll(".uploaded-image-container");
    for (let i = 0; i < thumbnails.length; i++) {
      let image = $(thumbnails[i]).find(".uploaded-image");
      let key = image.attr("id");
      checkFromCache(key)
        ?.then((url: any) => {
          image.attr("src", url);
          image.attr("data-product-subCategory", key);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // const selectFile = (event: any) => {
  //   // setSelectedFile(event.target.files[0])
  //   SELECTED_FILE = event.target.files[0];
  //   $("#inputLabelProductImage").text(event.target.files[0]?.name);
  // };

  const uploadImage = (imageDetails:any) => {
    showComponentLoader(uploadImageLoaderId);

    if (imageDetails.selectedFile) {
      let albumName = "uploads";
      var albumPhotosKey = encodeURIComponent(albumName) + "/" +  props.configObj?.clientName + "/collections/";
      applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey, imageDetails.selectedFile).then((data:any) => {
          saveUploadedFileDetails({
            clientId:props.configObj.clientId,
            name:imageDetails.name || "",
            description:imageDetails.description || "",
            awsFileKey:data.Key
          }).then(data=>{
            postUploadActions()
          }).catch(err=>{
            postUploadActions()
          })
        })
        .catch((err) => {
          console.log(err);
          // setSelectedFile(null)
          hideComponentLoader(uploadImageLoaderId);
        });
    } else {
      alert("No file selected");
      hideComponentLoader(uploadImageLoaderId);
    }
  };

  function postUploadActions() {
    hideComponentLoader(uploadImageLoaderId);
    setRefreshImages(!refreshImages);
    // setSelectedFile(null)
  }

  return (
    <React.Fragment>
      <div className="border-bottom hide-in-mobile full-width ion-margin-bottom">
        <div className="heading1 text-center font-normal full-width padding10">
          My Images
        </div>
      </div>

    
      <Loader
        className="fade-background-loader"
        id={uploadImageLoaderId}
        loaderType="svg"
        data={{}}
      />

      <Rembg
        isOpen={isOpenImageUpload}
        setIsOpen={setIsOpenImageUpload}
        uploadImage={uploadImage}
        isFloorplan={false}
      />

      {/* <div className="left-bottom display-flex-row upload-image-container" style={{width:"100%",bottom:"40px",justifyContent:"space-between"}}>
        <label className="custom-upload-input border" >  
        <input type="file" id="uploadImage" onChange={selectFile}/>
            <i className="fa fa-upload" area-hidden="true"/> <span style={{margin:"-2px 5px"}} id="inputLabelProductImage">No file choosen</span> 
          </label>
          <div className="center-aligned-column">
          <button className="op-btn op-btn-primary full-height" onClick={()=>uploadImage(1)}>Upload</button>
          </div>
        </div> */}

    
        <div className="full-width padding10">  
          <div className="center-aligned-column shadow-light bg-color-primary white-card" onClick={() => setIsOpenImageUpload(true)}>
            <div className="display-flex-row padding10">
              <i className="fas fa-upload font-medium center-aligned-column color-white"></i>
              <div className="heading2 center-aligned-column marginleft10 color-white">
                Upload image
              </div>
            </div>
          </div>
        </div>


        <div className="products-wrapper padding-bottom10 my-images-container overflow-y-scroll display-flex-row" onLoad={(e)=>createBounceEffectEvents(e)}>
        {myImages?.map((url: string, index: number) => {
          // let productName = url.substr(url.lastIndexOf("/") + 1);
          let productName = getObjectByParameter(applicationConfig?.data?.uploadedFileDetails || [],"aws_file_key",url)?.name || ""
          return (
            <div
              key={index}
              className="uploaded-image-container position-relative"
              style={{
                width: "calc(100% / 3)",
                aspectRatio: "1",
                padding: "2px",
              }}>
              <img
                data-product-name={productName}
                data-product-category="Default"
                data-product-subcategory={url}
                data-product-price={0}
                data-product-id={0}
                data-image-type="uploaded"
                src={url}
                id={url}
                onDragStart={dragStart}
                draggable="true"
                className="uploaded-image border"
                alt=""
                style={{ padding: "2px" }}
                onClick={(event:any)=>dropImageMoodboard(event)}
              />

              <div className="top-right padding10 zindex30 hide-in-desktop">
                  <div className="image-drag-icon">
                    <i className="fas fa-grip-vertical color-dark"></i>
                  </div>
                </div>
            </div>
          );
        })}
      </div>

    </React.Fragment>
  );
};

export default MyImages;
