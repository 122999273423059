import $ from "jquery";
import React, { useState } from "react";
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../../../../../utils/UI_methods/global";
import { getObjectByParameter, waitFor } from "../../../../../../../../utils/methods";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import Modal from "../../../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import SelectInput from "../../../../../../../common/ui-components/input/SelectInput";
 


 
const UpdateLead: React.FC<{
    isOpen:boolean
    setIsOpen:(val:boolean)=>void
    refreshLeads:()=>void
    lead:any
}> = (props) => {

    const[alertId] = useState("createLeadAlertId")

    function Footer() {
        return(
            <div className="display-flex-row">
                <Button2
                    configObj={{id:""}}
                    action={updateHandle}
                    label={"Update"}
                    fontAwesomeIcon={"fas fa-times color-black"}
                    iconPos=""
                    classNames={"no-shadow bg-color-primary"}
                />

                <Button2
                    configObj={{id:""}}
                    action={()=>{props.setIsOpen(false)}}
                    label={"Go back"}
                    fontAwesomeIcon={"fas fa-times color-black"}
                    iconPos=""
                    classNames={"no-shadow bg-color-light"}
                />
            </div>
        )
    }

  

    async function updateHandle() {
        // if(isCustomerFound){
        //   postCustomerCreateAction(foundCustomer)
        //   return
        // }
        const userId = Number($("#leadAssignedToValue").val())
        if(userId){
          let user = getObjectByParameter(CRM.data.users,"user_id",userId)
          let isAlreadyAssigned = CRM.data.leadsList.find(currLead => currLead.lead_id === props.lead.lead_id && currLead.user_id === userId)
          if(isAlreadyAssigned){
            showGlobalToast("Already assigned to this user",2000,"error")
            return
          }
          showComponentLoader("storeLoader")
          CRM.crmApi.leads.assignLead(userId,props.lead.lead_id).then((data:any)=>{
            if(!data.data.error){
                props.setIsOpen(false)
                props.refreshLeads()
            //   postCustomerCreateAction(customer)
                showGlobalToast("Lead assigned to " + user?.username,4000)
            }else{
              showGlobalToast("Internal server error",2000,"error")
            }
          })
        }else{
          showGlobalToast("Please select a user",2000,"error")
        }
        await waitFor(800)
        hideComponentLoader("storeLoader")
      }

  function UpdateDetailsForm() {
    return(
      <div className="padding10 full-height-width center-aligned-column">
        
        <div className="white-card no-shadow padding5">
          <div className="header- text-center ion-padding padding10 heading3 font-medium">Select User</div>
          <div className="" style={{padding:"0 5px"}}>
            <SelectInput
                id="leadAssignedToValue"
                label=""
                isMandatory={false}
                onChange={()=>{}}
                options={CRM.data.users.filter(currUser => currUser.user_type === "InsideSales" || currUser.user_type === "FieldSales")}
                className="input-large"
                value={""}
                config={{value:"user_id",displayName:"username"}}
            />
          </div>
        </div>
      </div>
    )
  }

 
  return (

    <>
    <Modal
        isOpen = {props.isOpen}
        Content = {()=>(
            <UpdateDetailsForm/>
        )}
        onDismiss = {()=>props.setIsOpen(false)}
        heading={"Assign lead"}
        classNames="small-x-modal"
        id=""
        FooterContent={Footer}
    />

    
    </>
  );
};

export default UpdateLead;
