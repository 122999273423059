import { IonIcon } from '@ionic/react';
import { arrowBack, backspace, clipboard, clipboardOutline, cog } from 'ionicons/icons';
import React from 'react';
import { enableFloorplanMode } from '../../../../../../utils/customizein3d/area3dmodel/area3dModel';
import { hideFloorplanOptionWindow } from '../../../../../../utils/customizein3d/area3dmodel/UI_methods';


const EditFloorplanOptions: React.FC<{
}> = (props) => {



    return(
      <div className="full-height-width  config-options-container" id="floorplanOptionWindow" style={{position:"fixed"}}>
      <div className="center-aligned-column full-height-width ">
          <div className="display-flex-row">
              
              <div className='ion-padding pointer center-aligned-column click-effect text-center border shadow-medium' onClick={hideFloorplanOptionWindow} style={{width:"14rem"}}>
                <div className='pointer-none'>
                  <div className='icon'>
                    <IonIcon className="color-primary marginBottom5" icon={arrowBack} style={{fontSize:"3rem"}} />
                  </div>
                  <div className='font-normal color-white marginTop5'>
                    Back to designing
                  </div>
                </div>
                
              </div>
{/* 
              {props.modulesConfigurtion.isDefaultConfigAvailable?
              <div className='ion-padding pointer marginleft10 center-aligned-column click-effect text-center border shadow-medium' onClick={loadModulsesFromDefaultConfiguration} style={{width:"14rem"}}>
                <div className='pointer-none'>
                  <div className='icon'>
                    <IonIcon className="color-primary marginBottom5" icon={save} style={{fontSize:"3rem"}} />
                  </div>
                  <div className='font-normal color-white marginTop5'>
                    Load Default
                  </div>
                </div>
              </div>
              :null} */}

              <div className='ion-padding center-aligned-column pointer click-effect marginleft10 border bg-color-primary text-center shadow-medium' onClick={()=>enableFloorplanMode()} style={{width:"14rem"}}>
                <div className='pointer-none'>
                  <div className=''>
                    <IonIcon className="color-white marginBottom5" icon={clipboardOutline} style={{fontSize:"3rem"}} />
                  </div>
                  <div className='color-white font-normal marginTop5'>
                    Edit Floorplan
                  </div>
                </div>
              </div>

              
          </div>
      </div>
  </div>
        
    );
};
 
export default EditFloorplanOptions;
