import { IonIcon, IonModal, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { fullScreenBrowserToggle, toggleUserOption } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getRandomString, getSubDomainName, getUser } from "../../../../../utils/methods";
import { openSideMenu } from "../../../../../utils/store/UI_methods";
import { showProductCart, updateCartIconCounter } from "../../../../../utils/store/product-cart";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import "../../../../style/store/header.css";
import ProductCart from "../cart/ProductCart";
import ProductCheckout from "../checkout/ProductCheckout";
import CreateCustomOrder from "../custom-order/CreateCustomOrder";
import StartCustomerSession from "../customer/StartCustomerSession";
import SideMenu from "./SideMenu";
import StoreProductSearch from "./StoreProductSearch";
import UserOptions from "./UserOptions";
import { getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";
import { expand } from "ionicons/icons";
import SubscriptionAlert from "../../../../common/ui-components/alerts/SubscriptionAlert";
import MainHeader from "./MainHeader";
import SessionExpired from "../../../../common/ui-components/alerts/SessionExpired";

 
const KairaAbout: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {

  const history = useHistory()

  
  function Footer(){
    return (
      <div className="full-width bg-color-dark center-aligned-column" style={{height:"10rem"}}>
         <p className="color-white heading4">&copy; 2024 Kaira Fabrics. All rights reserved. | Powered by <a href="https://omniviewlabs.com/" className="color-white" style={{textDecoration:"none"}}> omniviewlabs.com</a></p>
      </div>
    )
  }
 
  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white">
        <SubscriptionAlert/>
        <MainHeader/>
        <div className="main-content-wrapper overflow-y-scroll hide-scrollbar  position-relative light-background">
          <div className="configurator-link padding-container full-width" style={{margin:"5rem 0"}}>
          <div className="section position-relative padding-container">
            <div className="position-relative" style={{width:"100%",margin:"auto"}}>
              <img style={{objectFit:"cover",width:"100%",height:"32rem"}} className="" src="https://kairafabrics.in/wp-content/themes/kairafab/img/ab1.jpg" alt="" />
            </div>
            <img style={{objectFit:"cover",width:"25rem",height:"20rem",right:"5rem",bottom:"-3rem"}} className="bottom-right" src="https://kairafabrics.in/wp-content/themes/kairafab/img/ab2.jpg" alt="" />
          </div>

          <div className="section details padding-container">
              <div className="heading3 marginTop10">About us</div>
              <div className="heading1 font-ex-large ion-margin-top">Kaira : the house of sofa fabrics</div>
              <div className="heading4 color-black ion-margin-top font-normal line-height-2" style={{textAlign:"justify"}}>
              Kaira is an entity under Kurikkal group who specialize in a wide range of sofa fabrics and leathers. Kaira ensures enhanced remarkable qulaity and availability to the doorsteps. with the physical presence , Kaira focuses on best sofa fabrics and leather solutionswhich brings affordability, design and comfort. kaira born by understanding the change of the things in the furniture industry for the last few years, we are a perfect blend of form and function with an emphasis on quality and style.
              </div>
              <div className="" style={{marginTop:"2rem"}}>
                <Button2
                    configObj={{id:""}}
                    action={()=>{}}
                    label={"Know More"}
                    fontAwesomeIcon={"far fa-eye elevate-color-primary font-large"}
                    iconPos=""
                    classNames={"shadow-light button-medium margin0 text-center bg-color-primary"}
                  />
              </div>
          </div>
          </div> 
        <Footer/>
        </div>
      </div>
      :
      <SessionExpired/>
      }
    </IonPage>   
  );
};

export default KairaAbout;
