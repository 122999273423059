import React, { useEffect, useRef, useState } from "react";
import { enableHorizontalSlider, onPointerDownSlider, onPointerMoveSlider, onPointerUpSlider } from "../../../../utils/UI_methods/ui_effects";
import $ from "jquery"

const HorizontalScroll: React.FC<{
  itemsClass: string;
  Component: any;
  id:string
  itemsList: Array<any>;
  selectedElement?:any
}> = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollLength, setScrollLength] = useState(100);

  const elementRef = useRef(null)


  // const scrollToSelectedElement = () => {
  //   if (props.selectedElement) {
  //     const element = props.selectedElement
  //     const elementRect = element.getBoundingClientRect();
  //     const absoluteElementTop = elementRect.top + window.pageYOffset;
  //     const middle = absoluteElementTop - window.innerHeight / 2;

  //     let ele:any = $("#"+props.id)
  //     console.log(ele)
  //     let pos:any = ele.scrollLeft() || 0
  
      
  //     ele.animate(
  //       {scrollLeft: middle,},
  //       600
  //     );
  //   }
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //   scrollToSelectedElement();
  //   }, 1000);
  // }, [props.selectedElement]);


  // if scroll left is greater then 0 show left

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (props.itemsList.length) {
  //       enableHorizontalSlider(props.id);
  //     }
  //   }, 500);
  // }, [props.itemsList,props.id]);

  function scrollLeft(event:any) {
    let ele:any = $(event.target).siblings(".horizontal-scroll-wrapper")
    let pos:any = ele.scrollLeft() || 0

    let scrollUpto = pos - scrollLength;
    if (scrollUpto < 0) {
      scrollUpto = 0;
    }
    ele.animate(
      {
        scrollLeft: scrollUpto,
      },
      600
    );
    setScrollPosition(scrollUpto);
  }

  function scrollRight(event:any) {
    // ele.scrollTo(scroll,0)
    let ele = $(event.target).siblings(".horizontal-scroll-wrapper")
    let pos = ele.scrollLeft();

    let scrollUpto = pos + scrollLength;

    ele.animate(
      {
        scrollLeft: scrollUpto,
      },
      600
    );

    setScrollPosition(scrollUpto);

    // if(getContainerTotalWidth() - Math.ceil(getScrolledWidth(scrollUpto))  < 10){
    //     $("#"+props.wrapperId).find("#rightScrollArrow").find(".icon").removeClass("--is-active")
    //     return
    // }
  }

  return (
    <div className="display-flex-row no-wrap" style={{padding:"0px 5px"}}>
      <span
        className="prev-arrow center-aligned-column pointer padding5"
        onClick={scrollLeft}>
        <i className="fas fa-chevron-left pointer-none color-black font-normal"></i>
      </span>
      <div ref={elementRef} onPointerDown={(event:any)=>{onPointerDownSlider(event,elementRef.current)}} onPointerUp={(event:any)=>{onPointerUpSlider(event,elementRef.current)}} onPointerLeave={(event:any)=>{onPointerUpSlider(event,elementRef.current)}} onPointerMove={(event:any)=>{onPointerMoveSlider(event,elementRef.current)}} className={`${props.itemsClass} horizontal-scroll-wrapper display-flex-row no-wrap hide-scrollbar`}
        id={props.id}>
        <props.Component />
      </div>
      <span
        className="next-arrow center-aligned-column pointer padding5"
        onClick={scrollRight}>
        <i className="fas fa-chevron-right pointer-none color-black font-normal"></i>
      </span>
    </div>
  );
};

export default HorizontalScroll;
