import { IonPage } from "@ionic/react";
import $ from "jquery"
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { API_INIT, apiGatwayAuthentication, getProductsList, tokenVerification } from "../../../../../services/api";
import { showComponentLoader, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { addItemToLocalStorage } from "../../../../../utils/cache/localstorage";
import { applicationConfig, getFilteredArray, getLinkExpiryTimeLabel, getObjectByParameter, getUser, setUserSession, updateUserSession } from "../../../../../utils/methods";
import { SharedItem } from "../../../../../utils/moodboard/applicationConfig";
import Loader from "../../../../common/shared-components/main-ui/Loader";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ProductImage from "../../../../common/ui-components/elements/ProductImage";
import CollectionGallery from "../../productActions/CollectionGallery";
import PlaceOrder from "../../../Elevate/Kanvas/PlaceOrder";

 
const SharedProduct: React.FC<{
    configObj:any
    setIsLoadedPageConfiguration:(val:any)=>void
    isLoadedPageConfiguration:any
    setIsLogin:(val:boolean)=>void
}> = (props) => {

    const [productsList,setProductsList]: any = useState([]);
    const [product,setProduct]: any = useState(null);
    const [expiryTime,setExpiryTime]: any = useState(0);

    const parameters:any = useParams()
    const history:any = useHistory()
    const[logoSrc,setLogoSrc] = useState("assets/images/opus.png")

    const[kanvasModeConfig,setIsKanvasModeConfig] = useState(null)
    const [imageHeight,setImageHeight]: any = useState(300);

    const [isOrderMode,setIsOrderMode]: any = useState(window.location.pathname.includes("/order"));


    useEffect(()=>{
      if(applicationConfig.user?.isAllowedWhitelabeling){
        let clientName = applicationConfig.clientName
        setLogoSrc(applicationConfig.aws_public_url + `assets/clientLogos/${clientName}.png`)
      }
    },[])



   
 

    useEffect(()=>{

        let token = parameters.token
        tokenVerification(token).then(async (data)=>{

          console.log(data)
            await apiGatwayAuthentication()
            applicationConfig.clientId = data?.clientId
            applicationConfig.clientName = data?.clientName

            applicationConfig.share = data


            applicationConfig.isShareMode = true
            data.username = "user"
            data.userType = "user"
            API_INIT({clientId:data?.clientId,shareItemId:data?.shareItemId,moduleName:data?.moduleName})

            let promiseArray = [getProductsList(60)]

            let expiry = data.expiryTime
                
            Promise.all(promiseArray)
            .then(function (apidata) {
                applicationConfig.data.productsList = apidata[0]
                if(data.kanvasModeConfig){
                  applicationConfig.data.productsList = getFilteredArray(applicationConfig.data.productsList,"category_name",data.kanvasModeConfig?.categoryName)
                }
               
                setProductsList(apidata[0])
                let userType = getUser()?.userType

                let sharedItem = {
                  id:data.shareItemId,
                  configuration:data.configuration
                }


                applicationConfig.sharedItem = new SharedItem(sharedItem)
                setIsKanvasModeConfig(data.kanvasModeConfig)
                addItemToLocalStorage("sharedItem",sharedItem)

                if(!isOrderMode){
                  if(userType?.toLowerCase() != "admin"){
                    data.isShareMode = true
                    setUserSession(token, data);
                  }else{
                    updateUserSession(true,data.moduleName)
                  }
                }
                

                let product = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",data.shareItemId)
                setProduct(product)
                if(data.configuration.modules){
                  if(product){
                    addItemToLocalStorage("currProduct",product)
                  }
                }
                if(data.kanvasModeConfig){
                  addItemToLocalStorage("currCollection",{
                    companyName:data.kanvasModeConfig.companyName,
                    collectionName:data.kanvasModeConfig.collectionName,
                    categoryName:data.kanvasModeConfig.categoryName,
                    collectionList:data.kanvasModeConfig.collectionList
                  })
                }

                if(data.shareItemDetails){
                  let category = data.shareItemDetails.categoryName
                  gotoProducts(category)
                }
                setExpiryTime(expiry)
                props.setIsLogin(true)
                setGalleryImageHeight()
            })
            .catch(function (error) {
            console.log(error);
            });

        }).catch(err=>{
            console.log(err)
            showGlobalToast("Link Expired",2000,"error")
            // hideComponentLoader(mainLoaderId)
        })

       
    },[isOrderMode])

    function setGalleryImageHeight(){
      setTimeout(() => {
        let image = $("#shareImageGalleryContainer").height() 
        setImageHeight(image)
      }, 200);
    }

    function gotoProducts(category:string){
      history.push(`/store/products/${category}`)
    }

      function gotoCustomizeIn3D() {
        showComponentLoader("mainSpinnerLoader")
        history.push("/customizein3d")
        // window.location.href = "/customizein3d"
      }

      function Gallery(){
        let companyName = kanvasModeConfig.companyName
        let collectionName = kanvasModeConfig.collectionName
        let collections = [collectionName]
        if(kanvasModeConfig.collectionList){
          collections = kanvasModeConfig.collectionList
        }
        return(
          <div className="full-width center-aligned-column border">
              <CollectionGallery gotoCustomizeIn3D={()=>{}} imageHeight={imageHeight}
               collectionName={""} companyName={companyName} collectionsList={collections} />
          </div>
        )
      }

      function getCollectionHeading(){
        if(kanvasModeConfig.collectionList){
          return kanvasModeConfig.companyName + " Collections"
        }else{
          return  kanvasModeConfig.collectionName
        }
      }
 

      function WelcomeWindow() {
        return(
          <div className="full-height-width bg-color-white">
            <div className="padding5" style={{height:"4rem"}}>
              <img src={logoSrc} className="full-height" alt="" />
            </div>
            <div className="position-relative" style={{height:"calc(100% - 11rem)"}}>
              <div className="heading1 header-content font-ex-large ion-margin-top text-center ion-padding">Welcome to {applicationConfig.user?.isAllowedWhitelabeling? applicationConfig.clientName: " Opus Product Configurator"} </div>
              <div className="body-content-no-footer ">
                {product || kanvasModeConfig?
                <div className="full-height-width center-aligned-column">
                  <div id="shareImageGalleryContainer" className="full-width" style={{height:"80%",maxWidth:"30rem"}}>
                    {kanvasModeConfig?
                    <Gallery />
                    :
                    <ProductImage classNames="full-height contain" product={product} />
                    }
                  </div>
                  <div className="heading3 font-medium text-center  center-aligned-column" style={{height:"20%"}}>{kanvasModeConfig?getCollectionHeading():product.product_name} has been shared with you</div>
                </div>
                :
                <Loader
                  className="fade-background-loader --is-active"
                  id="Lo"
                  data={{}}
                  loaderType="dots"
                />
                }
              </div>
            </div>
            <div className="full-width center-aligned-column ion-padding ion-margin-bottom" style={{height:"7rem"}}>
              <div className="display-flex-row no-wrap">
                    {isOrderMode && kanvasModeConfig?
                        <PlaceOrder collectionName={kanvasModeConfig.collectionName} companyName={kanvasModeConfig.companyName}  />
                    :
                    <Button2
                      configObj={{}}
                      action={gotoCustomizeIn3D}
                      label={kanvasModeConfig?"Start Visualizing":"Customize"}
                      fontAwesomeIcon={"fas fa-arrow-right color-primary"}
                      iconPos="right"
                      classNames={"shadow-medium center-aligned-row button-large margin0 bg-color-primary"}
                    />
                    }
              </div>

              {!isOrderMode?
                <div className="padding10 marginBottom5 heading4 text-center color-danger">This link will get expeired in {getLinkExpiryTimeLabel(expiryTime)}</div>
              :null}
              
            </div>
          </div>
        )
      }

    return (
        <IonPage>
        {props.configObj &&  productsList.length?
        <div className="full-height-width bg-color-white">
          <WelcomeWindow/>
        </div>
        :
        <Loader
            id={""}
            data={{message:"Fetching Data..."}}
            loaderType="dotsText"
            className="--is-active fade-background-loader"
        />
        }
      </IonPage>
    );
};

export default SharedProduct;
