import { AnyAaaaRecord } from "dns";
import React, { useState } from "react";
import AreasForm from "./AreasForm";
 
const MoodboardDetailsForm: React.FC<{
  configObj:any
  setCurrStep:(val:string)=>void
  formData:any
}> = (props) => {

  const[moodboardName,setMoodboardName] = useState("")
 

  function updateMoodboardName(value:string) {
    setMoodboardName(value)
  }

  function nextStepHandle() {
    if(!moodboardName.length){
      alert("Please enter collection name")
      return
    }

    props.formData.name = moodboardName
    props.setCurrStep("step2")
  }


  function enterSubmit(event:any) {
    if (event.keyCode === 13) {
      nextStepHandle()
    }
  }
  

  return(
    <React.Fragment>
     
      <div className="modal-header">Create collection</div>
        <div className="modal-body">
          <div className="full-height-width center-aligned-column">
            <div>
              <div className="color-dark font-medium">Start creating collection with collection name</div>
              <div className="input-container ion-margin-top center-aligned-row full-width" >
                  <input
                    className="input input-mandatory text-center"
                    type="text"
                    name="collectionName"
                    id="collectionName"
                    placeholder="Enter collection name"
                    required
                    style={{width:"200px"}}
                    onChange={(e)=>updateMoodboardName(e.target.value)}
                    onKeyUp={(event)=>{enterSubmit(event)}}
                  />
                </div>
            </div>
          </div>
        </div>
        <div className="modal-footer space-between">
          <div></div>
          <div><button className="op-btn op-btn-primary" onClick={nextStepHandle}>Next <i className="fa fa-arrow-right"></i></button></div>
        </div>
    </React.Fragment>
  );
};

export default MoodboardDetailsForm;
