import $ from "jquery"
import { CURR_SELECTED_PRODUCT } from "../customizein3d/raycasting"
import { Euler, MathUtils, Quaternion } from "three"
import { groupForRotatingProduct } from "../customizein3d/area3dmodel/area3dModel"

var position = {x:0,y:0},
lastPosition = 0,
canSlide = false,
range = null,
dial = null,
input = null,
maxPoints = 0,
minPoints = 0,
prevVal = 0

var isLoaded:boolean = false

var rotationSnaps = [0,90,180,-90,-180]
const snapDelta = 35
const worldQuaternion = new Quaternion();
const eular = new Euler();
var result = 0

export function circleRangleSliderInit(onRotate:(value:number)=>void,startRotation:()=>void,endRotation:(value:number)=>void,parentContainerId:string) {
    if(!isLoaded){
        range = $("#circleRangeSliderWrapper")
        dial = $("#circleRangeSliderWrapper").find(".dial")
        dial.on("mousedown touchstart", ()=>rangeSliderInit(startRotation));
        // dial.on("mousemove touchmove", (event)=>rangeSliderUpdate(event,onRotate))
        // dial.on("mouseup touchend", ()=>rangeSliderStop(endRotation) )

        $("#"+parentContainerId).on("mousemove touchmove", (event)=>rangeSliderUpdate(event,onRotate));
        $("#"+parentContainerId).on("mouseup touchend", ()=>rangeSliderStop(endRotation) )

    
        input = range.find("input")
        maxPoints = input.attr("max")
        minPoints = input.attr("min")
        prevVal = input.attr("min")

        isLoaded = true
    }
   
}

export function pointerEvents(e) {
  var pos = { x: 0, y: 0 };
   
  if (
    e.type == "touchstart" ||
    e.type == "touchmove" ||
    e.type == "touchend" ||
    e.type == "touchcancel"
  ) {
    var touch = e.changedTouches[0];
    pos.x = touch.pageX;
    pos.y = touch.pageY;
  } else if (
    e.type == "mousedown" ||
    e.type == "mouseup" ||
    e.type == "mousemove" ||
    e.type == "mouseover" ||
    e.type == "mouseout" ||
    e.type == "mouseenter" ||
    e.type == "mouseleave"
  ) {
    pos.x = e.pageX;
    pos.y = e.pageY;
  }

  return pos;
}

function rangeSliderInit(callback:()=>void) {
  canSlide = true;
  callback()
  $(".show-on-rotate").removeClass("display-none")
  $(".hide-on-rotation").addClass("visibility-hidden")
}

function rangeSliderUpdate(e:any,callback:(val:number)=>void) {
  if (!canSlide) return;

  var position = pointerEvents(e),
    dial = range.find(".dial"),
    dialRadius = dial.width() / 2,
    coords = {
      x: position.x - range.offset().left,
      y: position.y - range.offset().top
    },
    radius = range.width() / 2,
    atan = Math.atan2(coords.x - radius, coords.y - radius),
    deg = Math.ceil(-atan / (Math.PI / 180) + 180);

  // if (Number(prevVal) <= 1 && lastPosition - position.x >= 0) deg = 0;
  // if (Number(prevVal) >= 359 && lastPosition - position.x <= 0) deg = 360;

  var x = Math.ceil((radius - 5) * Math.sin(deg * Math.PI / 180)) + radius + "px",
      y = Math.ceil((radius - 5) * -Math.cos(deg * Math.PI / 180)) + radius + "px",
      points = Math.ceil(deg * Number() / 360);

  //move dial
  dial.css({ transform: "translate(" + x + "," + y + ")" });


  //show range progress
  // if (deg <= 180) {
  //   range.find(".right .blocker").css({ transform: "rotate(" + deg + "deg)" });
  //   range.find(".left .blocker").css({ transform: "rotate(0)" });
  // } else {
  //   range.find(".right .blocker").css({ transform: "rotate(180deg" });
  //   range.find(".left .blocker").css({ transform: "rotate(" + (deg - 180) + "deg)" });
  // }
  // range.find(".svg-circle").css("stroke-dasharray",deg);

 
 
  let roundCircum = 30 * Math.PI,
  roundDraw = deg * roundCircum / 100

  range.find(".svg-circle").css('stroke-dasharray', deg /2  + ' 999')
  
  //show value

  prevVal = deg;
  lastPosition = position.x; 

  //Result will be in the range between -180 to 180
  result = deg
  if(result > 180){
    result = result - 360
  }
 
  let objectRotationValueGroup = - Math.floor(MathUtils.radToDeg(groupForRotatingProduct.rotation.y))
  updateDisplayValue(objectRotationValueGroup)
  callback(-result)
}

function updateDisplayValue(deg:number) {
  let valueToDisplay = deg
  if(deg < 0){
    valueToDisplay = valueToDisplay + 360
  }
  range.find(".count").html(valueToDisplay + "<sup class='font-ex-small super-script'>o</sup>")
}
       
function rangeSliderStop(callback:(val:number)=>void) {

    canSlide = false;
    callback(result)
    // $(".show-on-rotate").addClass("display-none")
    $(".hide-on-rotation").removeClass("visibility-hidden")

    range.find(".dial").css("transform",`translateX(${36}px) translateY(${6}px`)
    range.find(".right .blocker").css({ transform: "rotate(0deg)" });
    range.find(".left .blocker").css({ transform: "rotate(0)" });
    range.find(".count").html(0+"<sup class='font-ex-small super-script'>o</sup>")
    range.find(".svg-circle").css('stroke-dasharray', 0+ ' 999')

}

function updateRangeSliderValue(value:number) {
    input.val(value)
}

function getSnappedValue(deg:number) {
  for (const currValue of rotationSnaps) {
    let diff = Math.abs(Math.abs(currValue) - Math.abs(deg)) 
    if(diff < 10){
      console.log("here")
      deg = currValue
    }
  }
  return deg
}



// References

// https://codepen.io/square0225/pen/QdvLQg


export function setInitialRotationSliderValues(group:any = groupForRotatingProduct) {
    dial = range.find(".dial")
    const radius = range.width() / 2
    
    let deg = - Math.floor(MathUtils.radToDeg(group.rotation.y)) 

    updateDisplayValue(deg)

    const x = Math.ceil((radius - 5) * Math.sin(deg * Math.PI / 180)) + radius + "px"
    const y = Math.ceil((radius - 5) * -Math.cos(deg * Math.PI / 180)) + radius + "px"

    dial.css({ transform: "translate(" + x + "," + y + ")" });
    range.find(".svg-circle").css('stroke-dasharray', deg /2  + ' 999')
}