import React, { useEffect, useState } from "react";
import { hideComponentLoader, showComponentLoader, updateScrollPosition } from "../../../../../utils/UI_methods/global";
import AwsConfig from "../../../../../utils/aws-sdk/AwsConfig";
import { displayImageFromCache, getAndSaveImageToCache, getThumbnailsUrlKey, untarAndSaveToCache } from "../../../../../utils/cache/cache";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getObjectArrayOfDistictValues } from "../../../../../utils/methods";
import { ProjectForm } from "../../../../../utils/projects/projectForm";
import HorizontalScroll from "../../../../common/ui-components/scrollbar/HorizontalScroll";
import FormStatus from "../FormStatus";
import $ from "jquery"
import Loader from "../../../../common/shared-components/main-ui/Loader";
import Hint from "../Hint";

const MaterialsForm: React.FC<{
  projectForm:ProjectForm
  updateCurrentState:(state:string) =>void
}> = (props) => {
 
  const [scrollBarIdTypes] = useState("createProjectMaterialsScrollbar");
 
  const [materialTypes,setMaterialTypes]:any = useState([]);
  const [currMaterialType,setCurrSelectedMaterialType]:any = useState(null);

  const [companies,setCompanies]:any = useState([]);
  const [currCompany,setCurrCompany]:any = useState("All");

  const [collections,setCollections]:any = useState([]);

  const [selectedMaterialIds,setSelectedMaterialIds]:any = useState([]);



  useEffect(()=>{
    let types = getArrayOfDistinctValues(applicationConfig?.data?.materials,"material_type")
    setCurrSelectedMaterialType(types[0])
    setMaterialTypes(types) 
  },[])

  function updateMaterialsList(event:any,id:number) {
    $(event.target).find(".check-container").toggleClass("display-none")
    if(props.projectForm.projectMaterials.list.includes(id)){
      props.projectForm.projectMaterials.list = props.projectForm.projectMaterials.list.filter(currId => currId != id)
      setSelectedMaterialIds(props.projectForm.projectMaterials.list)
    }else{
      props.projectForm.projectMaterials.list.push(id)
      setSelectedMaterialIds(props.projectForm.projectMaterials.list)
    }
    // setIsRefresh(String(Number(new Date())))
  }
 
  const MaterialTypes = () => {
    return(
      <>
        {materialTypes?.map((materialType:any,index:number)=>{
            let classNames=[]
            classNames.push("area heading3   ")
            if(currMaterialType===materialType){
                classNames.push("--is-active")
            }
            return(
                <div className={classNames.join(" ")} onClick={()=>{
                  setCurrSelectedMaterialType(materialType)
                }} key={index}>{materialType}</div>
            )
        })}

      </>
    )
  }

  function CompaniesList() {
    return(
        <>
          {/* {companies.map((company:any,index:number)=>{
            
            return(
              <div className={currCompany===company?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-md-small text-center' onClick={()=>setCurrCompany(company)}>{company}</div>
              </div>
            )
          })} */}
          <div id={"subCategoryAll"} className={`border-bottom click-effect position-relative overflow-hidden sub-category ion-padding pointer ${currCompany === "All"?" --is-active":" "}`}  style={{paddingLeft:"2rem"}} onClick={()=>setCurrCompany("All")}>
            <div  className="heading4 text pointer-none" >All</div>
          </div>
          {companies.map((company:any,indexSub:number)=>{
            return(
              <div id={"subCategory"+company} className={`border-bottom click-effect position-relative overflow-hidden sub-category ion-padding pointer ${currCompany === company?" --is-active":" "}`} key={indexSub} style={{paddingLeft:"2rem"}} onClick={()=>setCurrCompany(company)}>
                <div className="heading4 pointer-none text" key={indexSub}>{company || ""}</div>
              </div>
            )
          })}
        </>
        // </div>
    )
  }

  function CollectionsList() {
    return(
      <div className="create-project-areas-container padding0 display-flex-row full-width" style={{alignContent:"baseline"}}>
        {collections?.map((material:any,index:any)=>{
          return (
            <>
              <div className={"area-item position-relative"} key={index} >
                <div className="area-image padding0 white-card shadow-light" onClick={(event)=>updateMaterialsList(event,material.company_collection_materialtype_id)} >
                    <img style={{zIndex:4}} src="" className="full-height-width padding0 top-left" id={`collectionImage${material.collection_name}`} alt="" />
                    {/* <div style={{zIndex:5}}  className={`top-left pointer-none check-container ${selectedMaterialIds.includes(material.company_collection_materialtype_id) ? "" : "  display-none" }  `}>
                        <div className="check"></div>
                    </div> */}
                    <div style={{zIndex:5}} className="title pointer-none" >{material.collection_name}</div>
                </div>
              </div>
            </>
          )
        })}
      </div>
  )
  }


 

  useEffect(()=>{
    if(currMaterialType){
      let filteredArray = getFilteredArray(applicationConfig?.data?.materials,"material_type",currMaterialType)
      let companylist = getArrayOfDistinctValues(filteredArray,"company_name")
      setCompanies(companylist)
      setCurrCompany("All")
    }

    setTimeout(() => {
      updateScrollPosition(scrollBarIdTypes)
    }, 500);
  },[currMaterialType])

  useEffect(()=>{
    if(currCompany && currMaterialType){
      if(currCompany.toLowerCase() === "all"){
        let filteredArray = applicationConfig?.data?.materials.filter(currMaterial => currMaterial.material_type === currMaterialType)
        let collections = getObjectArrayOfDistictValues(filteredArray,"collection_name") 
        setCollections(collections)
        return
      }
      let filteredArray = applicationConfig?.data?.materials.filter(currMaterial => currMaterial.company_name === currCompany && currMaterial.material_type === currMaterialType)
      let collections = getObjectArrayOfDistictValues(filteredArray,"collection_name") 
      setCollections(collections)
    }
  },[currCompany,currMaterialType])
  
  useEffect(()=>{
    if(collections){
      $(".collection-image").attr("src","")
      showComponentLoader(null,"collection-loader")
      setTimeout(() => {
        loadAndDisplayImages()
        markedCheckedCollection()
      }, 500);
    }
  },[collections])


  function markedCheckedCollection() {
    $(".check-container").addClass("display-none")
    for (const material of collections) {
      if(props.projectForm.projectMaterials.list.includes(material.company_collection_materialtype_id)){
        $(`#collectionCheckmark${material.collection_name}`).removeClass("display-none")
      }
    }
  }

  async function loadAndDisplayImages() {
    //for each company get collection name
    for (let i = 0; i < companies.length; i++) {
      let companyName:string = companies[i]
      if(companyName==="Default"){
        continue
      }
      let filteredCompanyMaterials = getFilteredArray(applicationConfig?.data?.materials,"company_name",companies[i])
      let allCollections = getObjectArrayOfDistictValues(filteredCompanyMaterials,"collection_name")

      for (let j = 0; j < allCollections.length; j++) {
        const collectionName = allCollections[j].collection_name;
        const materialCode = allCollections[j].material_code;
        if(collectionName!="Default"){
          let key = `thumbnails/OVL/${companyName}/${collectionName}/${materialCode}.png`
          await getAndSaveImageToCache(key)?.then(async (data)=>{
            displayImage(companyName,collectionName,materialCode,"png")
          }).catch(async (err)=>{
            let key = `thumbnails/OVL/${companyName}/${collectionName}/${materialCode}.jpg`
            await getAndSaveImageToCache(key)
            displayImage(companyName,collectionName,materialCode,"jpg")
          })
        }
      }
    }
}

async function displayImage(companyName:string,collectionName:string,materialCode:string,extension:string) {
    let image = $(`#collectionImage${collectionName}`);
    // let filteredMaterials = getFilteredArray(applicationConfig?.data?.materials,"collection_name",collection)
    let key = `thumbnails/OVL/${companyName}/${collectionName}/${materialCode}.${extension}`
    await displayImageFromCache(image,key)
    hideComponentLoader(`${collectionName}Loader`)
}
 
  return (
    <>
        {/* <Areas/> */}

        <Hint
          id="materialForm"
          heading="Product saved"
          subHeading="Select materials from the materials list"
          buttonText="Start Selecting Materials"
          closeAction={()=>{props.projectForm.isShowMaterialsHint = false}}
          isShow={props.projectForm.isShowMaterialsHint}
        />

        <div className="modal-header heading1">
          Select Materials
        </div>


        <div className="modal-body">
          
          <FormStatus
              count={4}
          />
          <div className="border full-width display-flex-row no-wrap">
            <div  className="heading3 border-right font-md-small width20 center-aligned-column color-danger bg-color-ternary" style={{padding:"5px 15px",width:"80%"}}>Material Types </div>
            <div className="width80" style={{width:"80%"}}>
              <HorizontalScroll
                  itemsClass="product-area-container"
                  Component={MaterialTypes}   
                  itemsList={materialTypes}
                  id={scrollBarIdTypes}     
              />
            </div>
            
          </div>
          <div className="display-flex-row fill-available-height padding0 no-wrap marginTop5 overflow-y-scroll">
            <div className="border-right full-height" style={{width:"20%"}}>
              <div className="color-dark bg-color-ternary border heading3 font-md-small text-center padding10 border-right">Manufacturer</div>
              <CompaniesList/>
              
            </div>
            <div className="full-height" style={{width:"80%"}}>
              <div className="bg-color-ternary color-dark border heading3 font-md-small text-center padding10">Select collections for - {currMaterialType} / {currCompany}</div>
             
              <div className="create-project-areas-container padding0 display-flex-row full-width" style={{alignContent:"baseline"}}>
                {collections?.map((material:any,index:any)=>{
                  return (
                    <>
                      <div className={"area-item position-relative"} key={index} >
                        <div className="area-image padding0 white-card shadow-light" onClick={(event)=>updateMaterialsList(event,material.company_collection_materialtype_id)} >
                            <img style={{zIndex:4}} src="" className="full-height-width collection-image pointer-none padding0 top-left" id={`collectionImage${material.collection_name}`} alt="" />
                            <div id={`collectionCheckmark${material.collection_name}`} style={{zIndex:5}}  className={`top-left pointer-none check-container display-none`}>
                                <div className="check"></div>
                            </div>
                            <div style={{zIndex:5}} className="title pointer-none" >{material.collection_name}</div>
                            <Loader
                              id={`${material.collection_name}Loader`}
                              data={{message:"Fetching data"}}
                              loaderType="spinner"
                              className="--is-active fade-background-loader pointer-none collection-loader"
                            />
                        </div>
                        
                      </div>
                    </>
                  )
                })}
              </div>

            </div>
          </div>
         

        </div>
       
        
        
        <div className="modal-footer" style={{justifyContent:"space-between"}}>
            <button className="op-btn op-btn-light marginRight5" onClick={()=>props.updateCurrentState("Step3")}>
             <i className="fas fa-arrow-left color-black"></i> Prev
            </button>

            <button className="op-btn op-btn-primary marginleft5" onClick={()=>props.updateCurrentState("Summary")}>
            Next <i className="fas fa-arrow-right"></i>
            </button>
          </div>


        

        {/* <HorizontalScroll
          itemsClass="sub-categories-container"
          Component={CompaniesList}   
          itemsList={companies}
          id={`${scrollBarIdCompanies}ScrollBar`}     
        /> */}
    </>
  )
};

export default MaterialsForm;