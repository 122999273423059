import React, { useEffect, useState } from "react";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import Loader from "../../../../common/shared-components/main-ui/Loader";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ShareLink from "../quotation/approval/ShareLink";
import { FabricateConfig } from "../../../../../utils/fabricate/fabricate";
import ProductFinishDetails from "./ProductFinishDetails";
import { getCanvasURI } from "../../../../../utils/customizein3d/modules/sceneBackground";
 

const Summary: React.FC<{
    closeHandle:()=>void
    fabricateConfig:FabricateConfig
    isOpen:boolean
}> = (props) => {

  const[imageUrl,setImageUrl] = useState("")
  const[productsList,setProductsList] = useState(props.fabricateConfig.configuration.productsList)

  useEffect(()=>{
    if(props.isOpen){
      setImageUrl(getCanvasURI())
    }
  },[props.isOpen])

       

 

 

function closeSummary() {
  props.closeHandle()
}

function ProductsList2() {
  return(
      <div className="pricing-details  white-card padding10 shadow-medium full-width overflow-x-scroll">
          <div className="marginTop10">
          <div className="Pricing"></div>
          <table className="table store-quotation-table">
              <thead>
                  <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th className="">Configuration</th>
                      <th className="">Price</th>
                  </tr>
              </thead>
          <tbody>
              {productsList?.map((product:any,i:number)=>{
                  return(
                      <tr key={i}>
                          <td>{i+1}</td>
                          <td className="center-aligned-column">{product.categoryName}</td>
                          <td> <ProductFinishDetails configuration={product.configuration} /> </td>
                          <td>{getFormattedPrice(0)}</td>
                      </tr>
                  )
              })}
          </tbody>
          </table>
      </div>
      </div>
  )
}


function ProductsList() {
  return(
      <div className="pricing-details  white-card padding10 shadow-medium full-width overflow-x-scroll">
          {/* <div className="heading2 font-medium padding5">Products List</div> */}
          <div className="">
            {productsList?.map((product:any,i:number)=>{
                return(
                  <div className="full-width marginTop10 white-card padding10 no-shadow border" key={i}>
                  <div className="full-width display-flex-row space-between padding5">
                    <div className="heading3 font-medium">{i+1}. {product.categoryName}</div>
                    <div className="heading4 font-normal color-black">Price - {getFormattedPrice(0)}</div>
                  </div>
                  {/* <div className="heading3 font-small padding5">Configuration - </div> */}
                  <div className="white-card padding5  no-shadow">
                  <ProductFinishDetails configuration={product.configuration} />
                  </div>
                  </div>
                )
            })}
      </div>
      </div>
  )
}

 




  return (
      <>
     <div className="full-height-width  bg-color-light">

        <div className="position-relative display-flex-row space-between padding-container" style={{height:"5rem"}}>
          <div className="heading1 middle font-large store-color-primary">
            Summary
          </div>
          <div className="right-middle padding10">
            <Button2
                  configObj={{id:""}}
                  action={closeSummary}
                  label={""}
                  fontAwesomeIcon={"fas fa-times color-black"}
                  iconPos="right"
                  classNames={"margin0 icon-button bg-color-warning-outline"}
            />
          </div>
        </div>


        <div className="overflow-y-scroll" style={{height:"calc(100% - 5rem"}}>
          <div className="summary-wrapper padding-container">

         

            <div className="summary-details-wrapper">

              {imageUrl?
                <div className="white-card shadow-light no-border-radius padding10">
                  <img src={imageUrl} className="width70" alt="" />
                </div>
              :null}

              {props.isOpen?
                  <>
                  <ProductsList/>
                  </>
              :
              <Loader
                className="fade-background-loader --is-active"
                id="summaryLoader"
                data={{}}
                loaderType="spinner"
              />
              }
            </div>

            <div className="summary-action-wrapper">
              {props.isOpen?
                <div className="full-width white-card shadow-medium marginTop10 padding10" style={{position:"sticky",top:"0"}}>
                  <div className="ion-margin-bottom">
                    <div className="font-large heading1 letter-spacing color-black marginBottom5">
                      Total Price
                      </div>
                    <div><span className="heading2 font-large marginRight5 rupee-sign">₹</span> <span className="heading1 letter-spacing rupee-sign" style={{fontSize:"3rem"}} id="summaryTotalPrice">{getFormattedPrice(0)}</span>  </div>
                  </div>

                  <div className="display-flex-row no-wrap full-width marginTop10">
                    <ShareLink cartItems={null} Content={()=>(
                      <Button2
                        configObj={{id:""}}
                        action={()=>{}}
                        label={"Share Quotation"}
                        fontAwesomeIcon={"fas fa-share color-primary"}
                        iconPos="right"
                        classNames={"no-shadow text-center button-large cart-button  bg-color-warning-outline"}
                      />
                    )}/>
        
                 
                  
                  </div>
                  
                </div>
              :null}
            </div>
          </div>
          
          
        </div>
      </div>
        
      </>
    );
};

export default Summary;
