import { getParentContainer, hideElement, showElement, showToast } from "../UI_methods/global";
import { getObjectArrayOfDistictValues, logger } from "../methods";
import $ from "jquery"
import { STORE } from "../store/storeConfiguration";

var materialTypesList = [] 
var collectionsList = []

export function searchMaterial(configObj:any,currSelectedMaterialType:string,activateSearchMode:(products:Array<any>,searchStr:string)=>void,filterMaterialWithMaterialType:(cat:string)=>void,filterMaterialWithCollection:(cat:string)=>void,idSuffix:string){
  let input:any, searchString, productsWrapper,productContainers:any, i;

  let materialsList = configObj.data.materials || STORE.data.materialList


  if(!materialTypesList.length || !collectionsList.length){
    materialTypesList = getObjectArrayOfDistictValues(configObj.data.materials,"material_type") 
    collectionsList = getObjectArrayOfDistictValues(configObj.data.materials,"collection_name") 
  }


  let parentContainer = getParentContainer()

  if(!parentContainer){
    showToast("Invalid page",2000,"error")
    logger.error("products","filter","Error in search Invalid page")
    return
  }

  input = parentContainer.querySelector("#materialSearch"+idSuffix)!;

  if(!input.value.length){
      closeSearch()
      return
  }


  $("#materialSearchCloseButton").removeClass("display-none")
  searchString = String(input.value.toLowerCase());


  // productsWrapper = document.getElementById("productsWrapper")!;
  productContainers = parentContainer.querySelectorAll(".product-image-container")!;


    hideElement("loadMoreButton")
    let materialsResultContainer = $("#resultMaterials"+idSuffix)
    let materialTypesContainer = $("#resultMaterialTypes"+idSuffix)
    let collectionsContainer = $("#resultCollections"+idSuffix)

    materialsResultContainer.empty()
    materialTypesContainer.empty()
    collectionsContainer.empty()

    let resultMaterials = []
    let materialTypes = []


    //get all products matching the result
    resultMaterials = materialsList.filter((material:any)=>{
      if (material.material_code.toLowerCase().indexOf(searchString) > -1 ) {
        return material
      }
    })

    //get all categories matching the result
    materialTypes = materialTypesList?.filter((materialType:any)=>{
      if (materialType.material_type.toLowerCase().indexOf(searchString) > -1 ) {
        return materialType
      }
    })

      //get all sub categories matching the result
      collectionsList = collectionsList?.filter((collection:any)=>{
      if (collection.collection_name.toLowerCase().indexOf(searchString) > -1 ) {
        return collection
      }
    })

    //Categories
    for (const materialType of materialTypes) {
      let item = $("<div></div>",{
        class:"category-item display-flex-row op-btn op-btn-primary-inverse-active search-item"
      }).text(`${materialType.material_type}`)
      materialTypesContainer.append(item)
      item.on("click",function() {
        $("#materialSearchResult"+idSuffix).fadeOut()
        filterMaterialWithMaterialType(materialType.material_type)
      })
    }

      //Sub Categories
    for (const collection of collectionsList) {
      let item = $("<div></div>",{
        class:"category-item display-flex-row op-btn op-btn-primary-inverse-active search-item"
      }).text(`${collection.collection_name}`)
      collectionsContainer.append(item)
      item.on("click",function() {
        $("#materialSearchResult"+idSuffix).fadeOut()
        filterMaterialWithCollection(collection.collection_name)
      })
    }

    if(resultMaterials.length){
      $("#materialSearchResult"+idSuffix).fadeIn()
    }else{
      $("#materialSearchResult"+idSuffix).fadeOut()
    }

    //Create appent elments
    for (const material of resultMaterials) {
      let item = $("<div></div>",{
        class:"display-flex-row search-item full-width"
      }).html(`${material.company_name} / ${material.collection_name} / ${material.material_code}`)
      materialsResultContainer.append(item)

      item.on("click",function() {
        $("#materialSearchResult"+idSuffix).fadeOut()
        activateSearchMode([material],material.material_code)
      })
    }


    let viewAllButton = $("<a></a>",{
      class:"center-aligned-column view-all-button bottom-middle"
    }).text("View all results")
    viewAllButton.on("click",function(){
      $("#materialSearchResult"+idSuffix).fadeOut()
      activateSearchMode(resultMaterials,searchString)
    })
    collectionsContainer.append(viewAllButton)

    highlightSearchedText(searchString)
      //Without lower case
  }



export function expandSearch() {
    $("#searchWrapper").addClass("--is-active")
    $("#searchContainer").addClass("--is-active")
    $("#searchContainer").fadeIn()
}

export function closeSearch() {
// $("#searchWrapper").removeClass("--is-active")
// $("#searchContainer").removeClass("--is-active")
  $(".search-results-container").fadeOut(0)
  $(".product-search-input").val("")
  $("#materialSearchCloseButton").addClass("display-none")

}


export function highlightSearchedText(searchString:string) {
    let searchItems = document.querySelectorAll(".search-item")
    for (let i = 0; i < searchItems.length; i++) {
      const currItem:any = searchItems[i]
      let innerHtml = String(currItem.innerHTML.toLowerCase())
      let index = innerHtml.indexOf(searchString)
      if(index >= 0){
        innerHtml = innerHtml.substring(0,index) + '<span class="heading2 font-md-small color-black center-aligned-column">' + innerHtml.substring(index,index+searchString.length) + '</span>' + innerHtml.substring(index+searchString.length);
        // currItem.innerHtml = innerHtml
        $(currItem).html(innerHtml)
      }
      
    }
}