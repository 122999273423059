import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { toggleUserOption } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getUser } from "../../../../../utils/methods";
import { openSideMenu } from "../../../../../utils/store/UI_methods";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import "../../../../style/store/header.css";
import SideMenu from "./SideMenu";
import UserOptions from "./UserOptions";

 
const KairaFabricsHeader: React.FC<{
}> = (props) => {


  const history = useHistory()
  
  // const[isOpenSearch,setIsOpenSearch] = useState(false)

  const[logoSrc,setLogoSrc] = useState("assets/images/opus.png")
  const location = useLocation();

  const[routes] = useState([
    {
      link:"/",
      label:"Home"
    },
    {
      link:"/about",
      label:"About us"
    },
    {
      link:"/products",
      label:"Products"
    },
    {
      link:"/contact",
      label:"Conatct us"
    }
  ])
 
  
  useEffect(()=>{
    if(applicationConfig?.user?.isAllowedWhitelabeling){
      let clientName = applicationConfig.clientName
      setLogoSrc(applicationConfig.aws_public_url + `assets/clientLogos/${clientName}.png`)
    }
  },[location])
 
 
  function clickHandle(route:string) {
    if(!getUser().shareItemDetails){
      history.push(route)
    }
  }
 

 
  return (
    <>
    <SideMenu/>
    <div className="main-header-wrapper padding-container position-relative display-flex-row no-wrap">

      <div className="header-left-content">
      <div className={`full-height company-logo pointer hide-in-mobile ${applicationConfig.clientName === "KairaFabrics"?"padding10":"padding5"}`} onClick={()=>{clickHandle("/")}}>
            <img src={logoSrc} alt=""  className="full-height" />
        </div>
        <div className="hide-in-desktop">
          <Button2
            configObj={{id:""}}
            action={openSideMenu}
            label={""}
            fontAwesomeIcon={"fas fa-bars color-warning"}
            iconPos="left"
            classNames={"no-shadow button-large margin0 cart-button cart-button-warning-outline"}
          />
        </div>
      </div>

      <div className="header-middle-content middle">
      </div>

      <div className="header-right-content position-relative">

        <div className="display-flex-row no-wrap space-between">
          {routes.map((currRoute:any,index:number)=>{
            return(
              <div key={index} onClick={()=>{clickHandle(currRoute.link)}} className={` pointer center-aligned-column font-normal hide-in-mobile ${location.pathname === currRoute.link?" heading1 color-primary":"heading3"}`} style={{marginRight:"3rem"}}>
                {currRoute.label}
              </div>
            )
          })}

 
        {getUser().userType === "admin"?
          <Button2
          configObj={{id:""}}
          action={()=>{history.push("/admin")}}
          label={"Admin Dashboard"}
          fontAwesomeIcon={"fas fa-user color-danger font-medium"}
          iconPos="left"
          classNames={"no-shadow button-small border-danger"}
        />
        :null}

       

        {getUser()?
            <div className="center-aligned-column user-options-link marginleft10 " onClick={toggleUserOption}>
              <div className="bg-color-warning color-white pointer-none uppercase center-aligned-column white-card no-shadow" style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%"}}>
                {getUser()?.username.substring(0,2)}
              </div>
            </div>
          :
            <div className="center-aligned-column user-options-link">
                <div className="bg-color-warning color-white pointer-none uppercase center-aligned-column white-card no-shadow" style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%"}}>
                  <i className="fas fa-user color-white"></i>
                </div>
            </div>
        }
        

        <UserOptions />



        </div>

        
      </div>

    </div>
    </>     
  );
};

export default KairaFabricsHeader;
