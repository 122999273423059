import {
  IonModal
} from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getArrayOfDistinctValues } from "../../../../../../utils/methods";
import { hideAllProductAnnotations } from "../../../../../../utils/moodboard/UI_methods";
import {
  compareConfigurations,
  removeOtherCanvases, varifyCategories
} from "../../../../../../utils/moodboard/helper";
import { resetCurrSelectedLayout } from "../../../../../../utils/moodboard/layouts/UI_methods";
import {
  InitMoodBoardCanvas,
  configuration,
  emptyCanvas,
  loadSavedConfiguration
} from "../../../../../../utils/moodboard/moodboard";
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import Toast from "../../../../../common/ui-components/notification/Toast";
import "../../../../../style/canvas.css";
import Pricing from "../common/Pricing";
import Actions from "./Actions";
import Areas from "./Areas";
import CanvasContainer from "./CanvasContainer";
import LeftBottom from "./LeftBottomActions";
import RightMiddle from "./RightMiddle";
import TopRight from "./TopRight";


const Canvas: React.FC<{
  configObj: any;
  projectAreaCategories:any
  areasList:Array<any>
  updateCategoriesstatus:(confirmed:Array<any>,remaining:Array<any>,additional:Array<any>,areaName:string)=>void
  currArea:string
  updateCurrArea:(areaName:string,areaId:number)=>void
  categoriesStatus:any
  updateProjectAreaCategories:()=>void
  currentConfigObject:any
}> = (props) => {

    const[showPricing,setShowPricing]:any = useState(false)



    const[reloadProjectSavedConfig,setReloadProjectSavedConfig]:any = useState(false)

    const[isConfigUpdated,setIsConfigUpdated] = useState<boolean>(false)

    const[areaConfirmedLength,setAreaConfirmedLength] = useState<number>(0)
    
    const[areaConfigFlagMapping,setAreaConfigFlagMapping] = useState<any>({})

    const[canvasLoaderId] = useState("moodboardLoader")
    const[projectSavedConfiguration,setProjectSavedConfiguration]:any = useState(null)


    const history = useHistory()

    useEffect(()=>{
      //If configuration exits 
        if(configuration.images.length){
          emptyCanvas(null)
          setTimeout(() => {
            loadSavedConfiguration()
          }, 300);
        }
    },[configuration])

   

    useEffect(()=>{
      if(props.currArea!="" && props.projectAreaCategories && props.configObj && areaConfigFlagMapping){
        InitMoodBoardCanvas(setIsConfigUpdated,props.updateCategoriesstatus,props.projectAreaCategories)
        varifyCategories(props.configObj?.areas,props.configObj,props.projectAreaCategories)
        checkIfAllAreasCompleted()
        compareConfigurations(false,props.configObj,setIsConfigUpdated)
        removeOtherCanvases()
        hideAllProductAnnotations()
        resetCurrSelectedLayout()
      }
    },[props.currArea,props.projectAreaCategories,props.configObj,areaConfigFlagMapping])


    useEffect(()=>{
      //Set area config flag mapping 
      if(projectSavedConfiguration){
        let array = getArrayOfDistinctValues(projectSavedConfiguration,"area_name")
        setAreaConfirmedLength(array.length)
        let mappingObj:any = {}
        for (const area of props.configObj?.areas) {
           if(array.includes(area.area_name)){
             mappingObj[area.area_name] = true
           }else{
              mappingObj[area.area_name] = false
           }
        }
        setAreaConfigFlagMapping(mappingObj)
      }
    },[projectSavedConfiguration])

    
  function checkIfAllAreasCompleted() {
    let flag = true
    for (const area of props.configObj?.areas) {
      if(!areaConfigFlagMapping[area.area_name]){
        flag = false
      }
   }
   if(flag){
    $('#summaryLink').addClass("--is-active")
    $('#statusCount').addClass("--is-active")
    $('#summaryTooltip').addClass("--is-active")
   }
  }
 

  return (
    <React.Fragment>
      <div className="moodboard-container --is-active" id="imageContainer">

      <Loader
        className="fade-background-loader"
        id={canvasLoaderId}
        data={{}}
        loaderType="svg"
      />

        {/* <div
          className="status-count-container"
          onClick={() => setShowPricing(true)}>
          <div className="status-details" id="statusCount">
            <StatusDetails />
          </div>
          <div className="update-hint-label">Confirmed</div>
        </div> */}

        <Actions
            configObj={props.configObj}
            isConfigUpdated={isConfigUpdated}
            canvasLoaderId={canvasLoaderId}
            areaConfigFlagMapping={areaConfigFlagMapping}
            reloadProjectSavedConfig={reloadProjectSavedConfig}
            setReloadProjectSavedConfig={setReloadProjectSavedConfig}
          />

      

        
        <IonModal
          cssClass="project-action-modal"
          onDidDismiss={() => setShowPricing(false)}
          isOpen={showPricing}>
          <Pricing
            configObj={props.configObj}
            currSelection="Summary"
            setShowPricing={setShowPricing}
            areasList={props.areasList}
          />
        </IonModal>
        
        {/* <div className="top-right ion-padding center-aligned-row">
          <PageTourLink/>
        </div> */}

        {/* <div className="top-left center-aligned-row hide-in-mobile" style={{padding:"0.2rem"}}>
          <Button2
            configObj={props.configObj}
            action={gotoCustomizer}
            label={"Back"}
            fontAwesomeIcon={"fas fa-chevron-left color-primary"}
            iconPos="left"
            classNames={""}
          />
        </div> */}

        {/* <div className="top-left center-aligned-row" style={{padding:"0.2rem"}}>
          <Button2
            configObj={props.configObj}
            action={goback}
            label={""}
            fontAwesomeIcon={"fas fa-chevron-left color-primary"}
            iconPos="left"
            classNames={"shadow-light"}
          />
        </div> */}
        
        <Areas
          configObj={props.configObj}
          areaConfigFlagMapping={areaConfigFlagMapping}
          updateCurrArea={props.updateCurrArea}
          areasList={props.areasList}
          currArea={props.currArea}
          categoriesStatus={props.categoriesStatus}
          updateProjectAreaCategories={props.updateProjectAreaCategories}
        />

        <RightMiddle
          configObj={props.configObj}
          currArea={props.currArea}
          projectAreaCategories={props.projectAreaCategories}
          categoriesStatus={props.categoriesStatus}
          areasList = {props.areasList}
          currentConfigObject = {props.currentConfigObject}
          updateCurrArea = {props.updateCurrArea}
          updateProjectAreaCategories={props.updateProjectAreaCategories}
        />
      
        <div className="moodboard-canvas-wrapper position-relative hide-scrollbar  overflow-y-scroll" id="moodboardCanvasWrapper">
         
        <Toast/>
       
         <CanvasContainer
          configObj={props.configObj}
          canvasLoaderId={canvasLoaderId}
          currentConfigObject={props.currentConfigObject}
          currArea={props.currArea}
         />
        </div>

     
     

        {/* <RightBottomActions
          configObj={props.configObj}
          updateProjectAreaCategories={props.updateProjectAreaCategories}
          areasList={props.areasList}
          categoriesStatus={props.categoriesStatus}
          currentConfigObject = {props.currentConfigObject}
          updateCurrArea = {props.updateCurrArea}
          currArea={props.currArea}
          projectAreaCategories={props.projectAreaCategories}
        /> */}

        
    <TopRight
          configObj={props.configObj}
          currentConfigObject={props.currentConfigObject}
          categoriesStatus={props.categoriesStatus}
          currArea={props.currArea}
          projectAreaCategories={props.projectAreaCategories}
          updateCurrArea = {props.updateCurrArea}
          areasList={props.areasList}
        />

      <LeftBottom
          configObj={props.configObj}
          currentConfigObject={props.currentConfigObject}
        />

       
      </div>
    </React.Fragment>
  );
};

export default Canvas;
