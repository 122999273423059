import { IonAlert } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { updatePriority } from "../../../../../../utils/moodboard/layouts/UI_methods";
import {
  allowDrop, 
  dropImageMoodboard, 
  emptyCanvas,
  Init
} from "../../../../../../utils/moodboard/moodboard";
import CanvasFooter from "./CanvasFooter";
import CreateTemplateLink from "./CreateTemplate";
import InitialText from "./InitialText";
import SelectionBox from "./SelectionBox";
import TextAreaActions from "./TextAreaActions";


const CanvasContainer: React.FC<{
  configObj: any;
  canvasLoaderId: string;
  currentConfigObject:any
  currArea:string
}> = (props) => {

  const [showAlert, setShowAlert] = useState(false);

  var emptyCanvasEvent = null
  var moduleRef = {
    confirmEmptyCanvas:confirmEmptyCanvas
  }
  useEffect(() => {
    Init(props.configObj,moduleRef,props.currentConfigObject,props.canvasLoaderId);
  }, [props.configObj,props.currentConfigObject]);

  function confirmEmptyCanvas(event:any) {
    setShowAlert(true)
    emptyCanvasEvent = event
  }


  function PrioritySelector() {
    return(
      <>
      <div className="full-height-width priority-selector top-left padding0" id="prioritySelectorWrapper">
        <div className="position-relative full-height-width" id="prioritySelector">
        </div>

        <div className="bottom-middle">
          <div className="display-flex-row padding10 no-wrap">
            {/* <div onClick={disablePrioritySelectionMode} className="color-white font-small pointer marginleft5 zindex30 border" style={{padding:"5px 10px"}}>Close</div>
            <div onClick={enablePrioritySelectionMode} className="color-white font-small pointer marginleft5 zindex30 border" style={{padding:"5px 10px"}}>Reset</div> */}
            <div onClick={updatePriority} className="color-white font-small pointer bg-color-primary marginleft5 zindex30 border" style={{padding:"7px 12px"}}>Update layout</div>
          </div>
        </div>

      </div>

      {/* <div className="bottom-middle zindex100" style={{padding:"0 20px",background:"#fff"}}>
        <div id="priorityFormToggle" onClick={enablePrioritySelectionMode}  className="color-warning priority-form-toggle priority-selector font-small pointer marginleft5 zindex30 border" style={{padding:"7px 12px"}}>Update main image</div>
      </div> */}


      </>
    )
  }
  
  return (
    <React.Fragment>
      
       
 
        
      <IonAlert
        isOpen={showAlert}
        mode="ios"
        onDidDismiss={() => setShowAlert(false)}
        cssClass="my-custom-class"
        header={"Confirm!"}
        message={"Reset <strong>canvas</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              emptyCanvas(emptyCanvasEvent);
            },
          },
        ]}
      />

      <div className="moodboard-canvas-container" id="moodboardCanvasContainer">
        {/* <div className="top-middle zindex100" id="titleEditor">
          <div className="display-flex-row">
            <div>
              <input type="text" className="input" id="inputTitle" />
            </div>
            <button className="op-btn op-btn-success" onClick={addTextToCanvas}><i className="fa fa-check color-white"></i></button>
            <button className="op-btn op-btn-danger" onClick={addTextToCanvas}><i className="fa fa-trash color-white"></i></button>
          </div>
        </div> */}

        <SelectionBox
            configObj={props.configObj}
          />

      <TextAreaActions
            configObj={props.configObj}
          />

      
        <CreateTemplateLink
          configObj={props.configObj}
        />

  


        <div className="position-relative full-height-width">
        <InitialText/>

        <PrioritySelector/>

        <CanvasFooter
          configObj={props.configObj}
        />

       

        <div className="center-aligned-column top-middle color-danger heading1 capitalize font-normal letter-spacing" style={{padding:"0.7rem 0"}} >
          <div className="display-flex-row color-dark">
          <span className="center-aligned-column marginRight5"><i className="fas fa-map-marker-alt color-primary"></i></span> 
            {props.currArea}
          </div>
        </div>

        

        <textarea className="moodboard-text-area top-left" id="moodboardTextArea"></textarea>
        
        {/* <div className="canvas-title top-middle center-aligned-row full-width no-wrap zindex30">
              <input placeholder={"Title"} className="text-center zindex30" style={{background:"transparent",border:"none"}} />
        </div> */}


        <div id="mainMoodboardCanvasContainer"
          onDrop={(event:any)=>dropImageMoodboard(event)}
          onDragOver={allowDrop}
          className="canvas-container full-height-width white-card shadow-medium">
        </div>

        </div>
        

        {/* <div className="moodboard-product-header">
          <div className="image">
            <img src="assets/images/placeholder.png" alt="" />
          </div>
          <div className="text">ProductName</div>
        </div> */}

       
      </div>

    </React.Fragment>
  );
};

export default CanvasContainer;
