import React, { useState } from "react";
import { Customer, STORE } from "../../../../../utils/store/storeConfiguration";
import Modal from "../../../../common/shared-components/Modals/Modal";
import CreateCustomer from "./CreateCustomer/CreateCustomer";
import CurrentCustomer from "./CurrentCustomer";

 

const CustomerSessionWindow: React.FC<{
  createCustomerCallback:(customer:Customer)=>void
  isStartSessionMode:boolean
  closeModal:()=>void
}> = (props) => {

  const[activeCustomer,setActiveCustomer] = useState(STORE.activeCustomer)

  const[isOpenAddCustomer,setIsOpenAddCustomer] = useState(false)


  function endCustomerSession() {
    setActiveCustomer(null)
    STORE.setActiveCustomer(null)
    props.createCustomerCallback(null)
  }

  function setCustomerHandle(customer:Customer) {
    setActiveCustomer(customer)
    STORE.setActiveCustomer(customer)
    props.createCustomerCallback(customer)
  }

  function closeAddCustomer() {
    setIsOpenAddCustomer(false)
  }
 
  return (
    <div className="full-height-width display-flex-column no-wrap position-relative">
      <div className="bg-color-light padding10 overflow-y-scroll full-height">
      {!activeCustomer || !props.isStartSessionMode?
        <CreateCustomer isCreateTicketMode={false} createCustomerCallback={props.createCustomerCallback} isCustomerCheckInMode={false} isStartSessionMode={props.isStartSessionMode} setActiveCustomer={setCustomerHandle}/>
      :
        <CurrentCustomer closeModal={props.closeModal} customer={activeCustomer} closeAddCustomer={closeAddCustomer} endCustomerSession={endCustomerSession}/>
      }
      </div>
    </div>
  );
};

export default CustomerSessionWindow;
