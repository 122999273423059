import React from "react";
import { toggleSpacePlannerMode } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { rotateModules } from "../../../../../../utils/customizein3d/area3dmodel/productConfigure";
import SpacePlannerToggle from "../../../../../common/modules/customizein3d/canvas/SpacePlannerToggle";


const ProductConfigure: React.FC<{
  isSpacePlannerMode:boolean
  setIsSpacePlannerMode:(val:boolean)=>void
  }> = (props) => {


  return (
    <>
    {/* <div className="bottom-middle">
      <div id="productRotationRangeSlider" className="product-rotation-slider">
        <RangeSlider
          id="rangeSlider"
          action={rotationRangeSliderHandle}
          pointerUp={endRotation}
          pointerDown={startRotation}
        />
      </div>
    </div> */}

    <div className="top-left zindex30 display-none">
      <div className="hide-on-measurement show-on-configure">
          <SpacePlannerToggle
            isSpacePlannerMode={props.isSpacePlannerMode}
            setIsSpacePlannerMode={props.setIsSpacePlannerMode}
            action={toggleSpacePlannerMode}
          />
        </div>
    </div>
   
    
    <div className="bottom-left ion-margin">
      {props.isSpacePlannerMode?
        <button id="rotateModules" onClick={rotateModules}  className="action-button dimension-icon zindex30 position-relative  shadow-light white-card border display-flex-row font-medium" style={{marginTop:"5px"}}>
          <span className="center-aligned-column"><i className="fas fa-undo icon  font-"></i></span>
          <span className="center-aligned-column marginleft5 heading4 label"  >Rotate Product</span> 
        </button>
      :
      <>
       
      </>
      }
    </div>
    </>
  );
};

export default ProductConfigure;
