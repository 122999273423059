import $ from "jquery";
import React, { useEffect, useState } from "react";
import { getArrayOfDistinctValues, getFilteredArray, getObjectByParameter } from "../../../../../../utils/methods";
import { Addon, SofaConfiguration } from "../../../../../../utils/store/storeConfiguration";
import Footer from "./Footer";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
 

const AddonsSelector: React.FC<{
  customizationOption:any
  productConfiguration:SofaConfiguration
  heading:string
  index:number
  setCurrSelectedWindowIndex:(id:number)=>void
  currSelectedWindowIndex:number
  customizingOptions:any
}> = (props) => {


  const[configuration] = useState(props.customizationOption.configuration)

  const[selectedOptions,setSelectedOptions] = useState(props.productConfiguration.addon)



  const[currConfig,setCurrConfig]:any =  useState({})

  const[options,setOptions] =  useState([])

  const[currSelectedTypeOption,setCurrSelectedTypeOption] =  useState(null)
  const[refresh,setRefresh] =  useState(Number(new Date()))

  
  const[typeOptions,setTypeOptions]:any =  useState([])


  function toggleSelection(config:any,option:any){
    const id = config.id
    setSelectedOptions(prevValue=>{
      if(isExists(id)){
        return prevValue.filter(currValue => currValue.id !== id)
      }else{
        if(config.name === "Ottoman" || config.name === "Pillows"){
          openMoreOptions(config)
        }
        let addon = new Addon(config,props.productConfiguration.quality)
        return [...prevValue,addon]
      }
    })
  }

  useEffect(()=>{
    props.productConfiguration.addon = selectedOptions.filter(currAddon => currAddon.name !== "Ottoman" && currAddon.name !== "Pillows")
  },[selectedOptions])


  function isExists(id:number) {
    return getObjectByParameter(selectedOptions,"id",id)
  }

  function openMoreOptions(config:any) {
    setCurrConfig(config)
    // const configName = config.name 
      // option.currSelectedValue = configName
    if(config.options?.length){
      $(".more-options-container").fadeOut(0)
      $(`.more-options-container`).fadeIn(200)
      let typeOptions = getArrayOfDistinctValues(config.options,"type") 
      setTypeOptions(typeOptions)
      setCurrSelectedTypeOption(typeOptions[0])
      setOptions(config.options)
    }
  }

  useEffect(()=>{
    if(currConfig && currSelectedTypeOption){
      // if(currConfig.name.toLowerCase().includes("pillow")){
        let allOptions = currConfig.options
        allOptions = getFilteredArray(allOptions,"type",currSelectedTypeOption)
        // allOptions = getArrayOfDistinctValues(allOptions,"name")
        setOptions(allOptions)
      // }
    }
  
  },[currSelectedTypeOption,currConfig])

  function onChangeMoreOption(event:any,option:string) {
    event.preventDefault()
    event.stopPropagation()
    $(".more-options-container").fadeOut(0)
  }

  function closeMoreOptions() {
    $(".more-options-container").fadeOut(500)
  }


  function toggleSizeSelection(event:any,currType:string) {
    $(".switch-selection-container").find(".option").removeClass("--is-active")
    $(event.target).addClass("--is-active")
    currConfig.type = currType
    setCurrSelectedTypeOption(currType)
  }

  function updateQuantity(event:any,config:any,diff:number) {
    event.preventDefault()
    event.stopPropagation()
    let addon = getObjectByParameter(props.productConfiguration.addon,"id",config.id)
    if(addon){
      if(addon.quantity === 1 && diff < 0){
        return
      }
      addon.quantity = addon.quantity + diff
    }
    setRefresh(Number(new Date()))
  }

  function getAddonsLength(addonsList:Array<any>) {
    let count = 0
    if(addonsList){
      let selectedAddonIds = getArrayOfDistinctValues(props.productConfiguration.addon,"id")
      for (const addon of addonsList) {
        if(selectedAddonIds.includes(addon.id)){
          let currAddon = getObjectByParameter(props.productConfiguration.addon,"id",addon.id)
          count = count + currAddon.quantity
        }
      }
    }
    return count
  }

  return (
    <div className="configuration-seletor center-aligned-column" key={props.index} id={`ConfigurationSelector_${props.index}`} style={{display:`${props.index === props.currSelectedWindowIndex?"":"none"}`}}>
        
        
        
        <div id={`moreOptionsContainer`} style={{display:"none",zIndex:100,background:"rgba(0,0,0,0.3)"}} className="middle customization-options full-height-width padding10 more-options-container  center-aligned-column">
          
          <div className="white-card position-relative" style={{width:"90%",height:"25rem"}}>
            <div className="top-right" onClick={closeMoreOptions}><i className="fas fa-times color-dark font-medium"></i></div>
            <div className="heading3 font-medium  iddle ion-padding text-center">Select Your Option</div>
            
            <div className="center-aligned-row padding5">
              <div className="switch-selection-container display-flex-row no-wrap">
                {typeOptions.map((currType:string,index:number)=>{
                  return(
                    <div key={index} onClick={(event)=>{toggleSizeSelection(event,currType)}} className={`capitalize option ${currSelectedTypeOption === currType?" --is-active":" "}`}>{currType}</div>
                  )
                })}
              </div>
            </div>
            
            <div className="center-aligned-row  no-wrap marginTop10 overflow-x-scroll">
              {options.map((config:any,index:number)=>{
                return(
                  <div key={index}  onClick={(event)=>toggleSelection(config,props.customizationOption)} className={`center-aligned-column item position-relative white-card shadow-light ${isExists(config.id)?" --is-active":""}`}>
                      <div className="midde full-width">
                        {config.svg?
                          <div className="center-aligned-row">
                            <img src={config.svg} alt="" style={{width:"5rem"}} className="icon" />
                          </div>
                        :
                        <div className="padding5 center-aligned-row"><i className={`icon fas fa-couch font-large`}/></div>
                        }
                        <div className="heading2 font-md-small padding5 pc-color-primary text text-center" style={{marginTop:"-1.5rem"}}>{config.name}</div>
                        <div className="top-right">
                          {isExists(config.id)?
                          <i className="far fa-check-circle select-icon font-large"></i>
                          :
                          <i className="far fa-circle select-icon font-large"></i>
                          }
                        </div>
                        <div className="bottom-middle full-width center-aligned-column padding5">
                          {isExists(config.id)?
                          <div data-refresh={refresh} className="display-flex-row button-group">
                            <span onClick={(event)=>{updateQuantity(event,config,-1)}} className="border bg-color-light white-card no-shadow" style={{padding:"0.1rem 0.4rem"}}><i className="fas fa-minus color-dark font-ex-small"></i></span>
                            <span className="border white-card no-shadow heading3 font-small center-aligned-column" style={{padding:"0.1rem 0.7rem",margin:"0 3px"}}>{isExists(config.id)?.quantity || 1}</span>
                            <span onClick={(event)=>{updateQuantity(event,config,1)}} className="border bg-color-light white-card no-shadow" style={{padding:"0.1rem 0.4rem"}}><i className="fas fa-plus color-dark font-ex-small"></i></span>
                          </div>
                          :null
                          }
                        </div>
                      </div>
                  </div>
                )
              })}
            </div>

            <div className="center-aligned-row padding10">
              <Button2
                configObj={{id:""}}
                action={closeMoreOptions}
                label={"Confirm"}
                fontAwesomeIcon={"fas fa-arrow-left color-black"}
                iconPos=""
                classNames={"no-shadow store-bg-color-primary"}
              />
            </div>
          </div>
        </div>

        {/* <OttomansSelector/> */}

        
        {props.heading?
          <div className="heading1 t pc-color-primary header-content font-large capitalize text-center ion-padding">{props.heading}</div>
        :null}
        
        <div className="display-flex-row ion-padding body-content customization-options advance-customization-options space-around">
          {configuration.map((config:any,index:number)=>{
            return(
              <div key={index} onClick={()=>toggleSelection(config,props.customizationOption)} className={`center-aligned-column item position-relative white-card shadow-light ${isExists(config.id)?" --is-active":""}`}>
                <div className="pointer-none midde full-width">
                  
                  {config.svg?
                  <div className="center-aligned-row">
                    <img src={config.svg} alt="" style={{width:"5rem"}} className="icon" />
                  </div>
                  :
                  <div className="padding5 center-aligned-row"><i className={`icon fas fa-couch font-large`}/></div>
                  }
                  <div className="heading2 font-md-small padding5 pc-color-primary text text-center">{config.name}</div>
                  <div className="heading4 font-small text text-center text-ellipsis-mobile ">{config.description}</div>

                </div>
                <div className="top-left">
                  {(config.name.includes("Ottoman") || config.name.includes("Pillows")) && isExists(config.id)?
                  <span className="heading3 color-success font-small">{getAddonsLength(config.options)} Selected</span>
                  :
                  null
                  }
                </div>
                <div className="top-right">
                  {isExists(config.id)?
                  <i className="far fa-check-circle select-icon font-large"></i>
                  :
                  <i className="far fa-circle select-icon font-large"></i>
                  }
                </div>
              </div>
            )
          })}
         
        </div>
        {/* If calling from customin in 3d */}
        {props.setCurrSelectedWindowIndex?
          <div className="padding10 center-aligned-column footer-content full-width">
            <Footer selectedOptionIds={selectedOptions} customizingOptions={props.customizingOptions}  index={props.index} setCurrSelectedWindowIndex={props.setCurrSelectedWindowIndex}/>
          </div>
        :null}
        
      </div>
  );
};

export default AddonsSelector;
