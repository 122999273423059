export const Reclining_Options = [
    {
        id:1,
        name:"Maunal",
        displayName:"",
        description:""
    },
    {
        id:2,
        name:"Electric",
        displayName:"",
        description:""
    },
    {
        id:3,
        name:"Rocking + Rotating",
        displayName:"",
        description:""
    }
]



export const ReclinerVariantsConfiguration = [
    {
        id:1,
        name:"1Seater",
        noOfSeats:1,
        displayName:"",
        description:""
    },
    {
        id:2,
        name:"2Seater",
        noOfSeats:2,
        displayName:"",
        description:""
    },
    {
        id:3,
        name:"3Seater",
        noOfSeats:3,
        displayName:"",
        description:""
    },
]
