import React, { useEffect, useState } from "react";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import { STORE } from "../../../../../utils/store/storeConfiguration";

 
const PriceDetails: React.FC<{
  cartItems:Array<any>
}> = (props) => {



  const[totalPrice,setTotalPrice] = useState(0)
  const[priceAfterDiscount,setPriceAfterDiscount] = useState(0)

  useEffect(()=>{
    let total = 0
    for (const item of props.cartItems) {
      total += item.price
    }
    setTotalPrice(total)
    setPriceAfterDiscount(total - STORE.currDiscount)
  },[props.cartItems])


  function setDiscount(event:any) {
    let value = event.target.value
    STORE.currDiscount = Number(value)
    // setTotalPrice(prevValue=>{
    //   return prevValue - Number(value)
    // })
    setPriceAfterDiscount(totalPrice - Number(value))
  }
  

  return (
    <div className="pricing-summary marginTop5">
    <div className="display-flex-row space-between item">
      <div className="heading4 font-md-small">Cart total</div>
      <div className="heading3 font-md-small rupee-sign">{getFormattedPrice(priceAfterDiscount)}</div>
    </div>
    <div className="display-flex-row space-between marginTop10 item">
      <div className="heading4 font-md-small">Shipping & Handeling</div>
      <div className="heading3 font-md-small color-success">FREE <span className="color-dark heading3 font-normal rupee-sign" style={{textDecoration:"line-through"}}>0</span></div>
    </div>
    <div className="display-flex-row space-between marginTop10 item">
      <div className="heading4 font-md-small">Discount</div>
      <div className="heading3 font-md-small rupee-sign"><input type="number" className="input" onChange={setDiscount} defaultValue={STORE.currDiscount} /></div>
    </div>
    <div className="display-flex-row space-between marginTop10 item no-border">
      <div className="heading4 font-md-small">Total Amount</div>
      <div className="heading1 font-medium rupee-sign">{getFormattedPrice(priceAfterDiscount)}</div>
    </div>
  </div>  
  );
};

export default PriceDetails;
