import React from "react";
import AwsConfig from "../../../../utils/aws-sdk/AwsConfig";
import Review from "../common/Review";
import MaterialDetails from "./MaterialDetails";
import MaterialsList from "./MaterialsList"
const ReviewFinish: React.FC<{
  configObj: any;
  setCurrState: (value: string) => void;
  closeModal: (value: boolean) => void;
  finishDetails:any
  setFinishDetails:(value:any)=>void
  addFinishLoader:string
}> = (props) => {



  // useEffect(()=>{
  //   if(props.finishDetails.SELECTED_FILE){
  //     displayUploadedImage(props.finishDetails)
  //   }else{
  //     //If no file selected display from cache if exists for the existed product
  //     let imageUrlKey = getThumbnailsUrlKey(props.finishDetails?.companyName,props.finishDetails?.collectionName,props.finishDetails?.materialCode)
  //     setUploadedImage(imageUrlKey)
  //   }
  // },[])





 
  function FinishDetails() {
    return (
            <div className="overflow-y-scroll full-height-width">
            <div className="heading2">Material details</div>

            <MaterialDetails
            configObj={props.configObj}
            finishDetails={props.finishDetails}
          />

            <div className="heading2 ion-margin-top ion-margin-bottom">Materials List</div>

            <MaterialsList
                configObj={props.configObj}
                materialsList={props.finishDetails?.finishImageFiles}
                deleteItem={()=>{}}
                addFinishLoader={props.addFinishLoader}
                currForm="review"
              />
            </div>
    );
  }
 

  return (
    <React.Fragment>
      <Review 
        configObj={props.configObj}
        setCurrState={props.setCurrState}
        closeModal={props.closeModal}
        save={()=>{}}
        Details={FinishDetails}
        formData={props.finishDetails}
        module="finish"
      />
    </React.Fragment>
  );
};

export default ReviewFinish;
