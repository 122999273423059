import { Layout } from "./layout"

export class Layout5 extends Layout{


    name:string = "layout5a"

    constructor(width:number,height:number){
        super(width,height)
    }

    setPadding(padding:number){
        this.padding = padding
    }

    getLayoutsList(){
        return ["a","b"]
    }

    getBlockParameters(layoutName:string,index:number){
        switch (layoutName.toLocaleLowerCase()) {
            case "a":
                return this.getBlockParametersLayoutA(index)
            
            case "b":
                return this.getBlockParametersLayoutB(index)
            default:
                break;
        }
    }

    getBlockParametersLayoutA(index:number){

        switch(index){
            case 1:
                this.block1 = {
                    width: this.stageWidth * 0.4 - this.padding,
                    height: this.stageHeight * 0.6 - this.padding,
                    x: 0 + this.padding,
                    y: this.stageHeight * 0.2 + this.padding
                }
                return this.block1
            case 2:
                this.block2 = {
                    width: this.stageWidth * 0.28 - this.padding,
                    height: this.block1.height * 0.6 - this.padding,
                    x: this.block1.x + this.block1.width + this.padding,
                    y: this.padding * 3
                }
                return this.block2

            case 3:
                this.block3 = {
                    width: this.block2.width,
                    height: this.block2.height,
                    x: this.block2.x + this.block2.width + this.padding,
                    y: this.block2.y
                }
                return this.block3

            case 4:
                this.block4 = {
                    width: this.block2.width,
                    height: this.block2.height,
                    x: this.block2.x,
                    y: this.block2.y + this.block2.height + this.padding * 3
                }
                return this.block4
            
            case 5:
                this.block5 = {
                    width: this.block2.width,
                    height: this.block2.height,
                    x: this.block4.x + this.block4.width +  this.padding ,
                    y: this.block4.y
                }
                return this.block5
            
        }

    }

    getBlockParametersLayoutB(index:number){

        switch(index){
            case 1:
                this.block1 = {
                    width: this.stageWidth * 0.35 - this.padding,
                    height: this.stageHeight * 0.48 - this.padding,
                    x: 0 + this.padding,
                    y: 0 + this.padding
                }
                return this.block1
            case 2:
                this.block2 = {
                    width: this.block1.width,
                    height: this.block1.height,
                    x: this.block1.x,
                    y: this.block1.y + this.block1.height + this.padding
                }
                return this.block2

            case 3:
                this.block3 = {
                    width: this.block2.width - this.padding,
                    height: this.block2.height - this.padding,
                    x: this.block2.x + this.block2.width + this.padding,
                    y: this.stageHeight / 2 - (this.block2.height / 2)
                }
                return this.block3

            case 4:
                this.block4 = {
                    width: this.block2.width,
                    height: this.block2.height,
                    x: this.block3.x + this.block3.width + this.padding,
                    y: this.block1.y
                }
                return this.block4
            
            case 5:
                this.block5 = {
                    width: this.block4.width,
                    height: this.block4.height,
                    x: this.block4.x,
                    y: this.block4.y + this.block4.height + this.padding
                }
                return this.block5
            
        }

    }
}
