import { briefcase, cube } from 'ionicons/icons';
import $ from "jquery";
import React, { useState } from 'react';
import MenuHeader from '../../../../../../common/ui-components/header/MenuHeader';
import DebugOptions from '../../common/DebugOptions';
import ActionButton from './ActionButton';

const Camera: React.FC<{
  setCurrStep:(val:string)=>void
}> = (props) => {

  const [currTab, setCurrTab] = useState<string>("3d");
  const[showRenderModal,setShowRenderModal] = useState(false)

  var tabItems = [
    {tabName:"3d",label:"360 Renders",ionIcon:cube},
    {tabName:"2d",label:"2D Renders",ionIcon:briefcase},
  ]

  function changeTabOption(tabName: string) {
    setCurrTab(tabName);
    // updateTabValue(tabName)
  }

  function createRender() {
    $("#generateRender").click()
  }


  return (
    <React.Fragment>
      <MenuHeader
        label='Adjust Camera'
        action={()=>props.setCurrStep("gallery")}
      />

      <div className='full-width padding10 center-aligned-row'>
        <div className='width70 aspect-four-by-three border center-aligned-column'>
          {/* <button className='op-btn op-btn-primary'>Set initial view</button> */}
          <div className='heading3 color-dark text-center padding10'>Click on the camera to set the initial view</div>
        </div>
      </div>
    
    <DebugOptions
        isOpenDebugOptions={showRenderModal}
        setIsOpenDebugOptions={setShowRenderModal}
    />

      <ActionButton
        label='Generate Render'
        action={createRender}
      />
          
    </React.Fragment>
  );
};

export default Camera;
