import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { convertHtmlToImage } from "../../../../utils/html2canvas/html2canvas";
import { convertHtmlToPdf } from "../../../../utils/html2canvas/html2pdf";
import { shareProject, waitFor } from "../../../../utils/methods";
import { hideComponentLoader, showComponentLoader } from "../../../../utils/UI_methods/global";
import "../../../style/common/share.css";
import Loader from "../main-ui/Loader";
import ConfigCustomizein3D from "./ConfigCustomizein3D";
import ConfigCustomizer from "./ConfigCustomizer";
import ConfigMoodBoard from "./ConfigMoodBoard";
import CreateProjectSummary from "./CreateProjectSummary";

const Share: React.FC<{
  areas:Array<any>;
  configObj: any;
  moduleName:string
  configuration:any
  closeModal:(value:boolean)=>void
}> = (props) => {


  const[shareLink,setSharelink]:any = useState("")
  const[shareItemName,setShareItemName]:any = useState("")


  useEffect(()=>{
    let shareItemName = props.configObj?.projectName
    if(props.moduleName==="Customizein3D"){
      shareItemName = props.configObj?.productName
    }
    setShareItemName(shareItemName)
  },[])

  function shareImage(elementId:string,areaName:string) {
   
    showComponentLoader("sharePageLoader")
    convertHtmlToImage(elementId).then(data=>{
      $("#shareLink").addClass("--is-active")
      setSharelink(data)
      hideComponentLoader("sharePageLoader")
    }).catch(err=>{
      console.log(err)
    })
  }

  async function DownloadAsPdf() {
    showComponentLoader("sharePageLoader")
    await convertHtmlToPdf(props.moduleName,props.configObj,props.areas,shareItemName)
    hideComponentLoader("sharePageLoader")
  }
  

  async function shareAPI() {
     shareProject(props.configObj,props.moduleName)
  }

 

  function Header() {
    return(
      <div className="modal-header" style={{height:"7%"}}>
      <div className="heading color-black">Share Summary</div>
      <div className="full-height center-aligned-column top-right">
      <IonIcon
            className="font-large"
            icon={close}
            ios={close}
            onClick={() => props.closeModal(false)}>
        </IonIcon>
      </div>
      
      </div>
    )
  }

  function Footer() {
    return(
      <div className="modal-footer" style={{height:"8%"}}>
        <div className=""><button className="op-btn op-btn-info" onClick={DownloadAsPdf}>Download as PDF <i className="fa fa-download" area-hidden="true"></i></button></div>
        <div className=""><button className="op-btn op-btn-primary marginleft5" onClick={shareAPI}>Share <i className="fas fa-share" area-hidden="true"></i></button></div>
      </div>
    )
  }

  return (
    <React.Fragment>
       <Loader className="fade-background-loader"
        id="sharePageLoader"
        data={{message:"Please wait"}}
        loaderType="dotsText"
      />
      <div className="" style={{display:"none"}}>
          <img src="assets/images/democlient.jpg" id="companyLogo" alt=""/>
      </div>

      {/* <Header/> */}

      <div className="modal-body padding1rem" style={{height:"85%"}}>
          <div className="final-summary-wrapper hide-scrollbar" id="shareContainer">
          
            <div id="shareLink" className="breadcrumb padding10">
              <strong>Share link</strong> <a href={shareLink}>{shareLink}</a>
            </div>
                        {props.moduleName==="Moodboard"?
                        <ConfigMoodBoard
                        configObj={props.configObj}
                        areas={props.areas}
                      />
                        :props.moduleName==="Customizein3D"?
                        <ConfigCustomizein3D
                          configObj={props.configObj}
                          configuration={props.configuration}
                        />
                        :props.moduleName==="CreateProjectSummary"?
                        <CreateProjectSummary
                            configObj={props.configObj}
                            configuration={props.configuration}
                        />:
                        <ConfigCustomizer
                        areaName={"area.area_name"}
                        configObj={props.configObj}
                        areas={props.areas}
                      />}
                  
          </div>
      </div>

      {/* <Footer/> */}

 
    </React.Fragment>
  );
};

export default Share;
