import React, { useState } from "react";
import MultipleFileUpload from "../../../common/ui-components/forms/MultipleFileUpload";
import MaterialsList from "./MaterialsList";
const CreateMaterialForm: React.FC<{
  configObj: any;
  finishDetails:any
  addFinishLoader:string
}> = (props) => {



  const[materialsList,setMaterialsList] = useState(props.finishDetails.materialCodes || [])




  const selectFile = (uploadedImages:any) => {
    if(!uploadedImages){
      setMaterialsList([])
      return
    }
    let uploadedFiles = []
    for (const currFile of uploadedImages) {
      let materialCode = currFile.name 
      materialCode = materialCode.substring(0,materialCode.indexOf(".")) 
      let currMaterialDetails = {
        SELECTED_FILE:currFile,
        materialCode:materialCode
      }
      uploadedFiles.push(currMaterialDetails)
      props.finishDetails.finishImageFiles.push(currMaterialDetails)
      props.finishDetails.materialCodes.push(materialCode)
    }
    setMaterialsList(uploadedFiles)
    // props.finishDetails.materialCodes.push()
  }




  function deleteItem(index:number) {
    setMaterialsList(prevValues=>{
      prevValues.splice(index,1)
      return [...prevValues]
    })
  }
 

  return (
    <div className="full-height display-flex-column no-wrap">
          <div className="heading2 color-danger ion-margin-top">Add materials</div>

          <div className="display-flex-row full-height">

          <div className="full-width position-relative shadow-light  custom-col-60 padding10 position-relative">

            <MultipleFileUpload
              heading=""
              acceptExtensions=".png,.jpg,.webp"
              setFiles={selectFile}
              multiple={true}
            />

          </div>



          <div className="added-materials-wrapper padding10 shadow-light custom-col-40">
            <div className="heading2 color-black ion-margin-bottom">Materials list</div>
            <div className="display-flex-column no-wrap hide-scrollbar overflow-y-scroll" style={{height:"200px"}}>
              <MaterialsList
                configObj={props.configObj}
                materialsList={materialsList}
                deleteItem={deleteItem}
                addFinishLoader={props.addFinishLoader}
                currForm={"material"}
              />
            </div>

          
        
          </div>
          </div>
    </div>
  );
};

export default CreateMaterialForm;
