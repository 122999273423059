 
  import $ from "jquery";
import React, { useState } from "react";
import { hideComponentLoader } from "../../../../utils/UI_methods/global";
import { checkFromCache } from "../../../../utils/cache/cache";
import { applicationConfig } from "../../../../utils/methods";
import Modal from "../../../common/shared-components/Modals/Modal";
import Loader from "../../../common/shared-components/main-ui/Loader";

  const MaterialDetails: React.FC<{
    configObj:any
    materials:any
  }> = (props) => {

    const[openFinishAction,setopenFinishAction] = useState(false)
    const[currFinish,setCurrFinish] = useState(null)

    async function displayThumbnails(material:any) {
      let key = `thumbnails/OVL/${material.company_name}/${material.collection_name}/${material.material_code}.png`
      let image = $("#materialViewImage")
      let url:any = await checkFromCache(key)
      image.attr("src",url)
      hideComponentLoader("viewMaterialImageLoader")
    }


    function clickMaterialHandle(material:any) {
      setCurrFinish(material)
      setopenFinishAction(true)
      setTimeout(() => {
        displayThumbnails(material)
      }, 1000);
    }

    function FinishDetails() {
      return(
        <div className="full-height-width ion-padding">
          <div className="full-height-width position-relative ">
              <img src="assets/images/placeholder.png" 
              className="border full-width"
              id="materialViewImage"
              />

              <div className="top-left ion-margin">
                <div className="color-light heading3">Company Name: {currFinish?.company_name}</div>
                <div className="color-light heading3 marginTop5">Collection Name: {currFinish?.collection_name}</div>
                <div className="color-light heading3 marginTop5">Material Code: {currFinish?.material_code}</div>
              </div>
          </div>
          
          <Loader 
            className={`--is-active`}
            id={"viewMaterialImageLoader"}
            data={{}}
            loaderType="spinner"
          />
        </div>
      )
    }

  
    return (
      <div className="">

      <Modal
        isOpen={openFinishAction} 
        classNames="vertical-modal"
        Content={FinishDetails}
        FooterContent={()=>(<div></div>)}
        id={""}
        onDismiss={setopenFinishAction}
        heading={"Finish details"}
      />
     
 
        <div className="display-flex-row space-between">
          {props.materials?.map((material: any, index: number) => {
            return (
                <div key={index} className="texture-label-container ion-margin click-effect padding10 white-card shadow-light">
                    <img src="assets/images/placeholder.png" 
                      data-company-name={material.company_name}
                      data-collection-name={material.collection_name}
                      data-material-code={material.material_code}
                      data-material-id={material.id}
                      className="slider-image"
                      onClick={()=>clickMaterialHandle(material)}
                    />

                    {/* <div className="texture">
                      <div className="texture-name-mobile bottom-middle center-aligned-column">{material.material_code}</div>
                        <img
                          className="texture-img"
                        />
                    </div> */}
{/* 
                    <div className="texture-details">
                      <div className="detail heading">{material.material_code}</div>
                      <div className="detail">Company: {material.company_name}</div>
                      <div className="detail">Collections: {material.collection_name}</div>
                    </div> */}
                    <div className="color-black ion-margin-bottom heading4 font-normal full-width fade-background-loader text-center bottom-middle">{material.material_code}</div>
                </div>
            );
          })}

          <div className="texture-label-container"></div>
          <div className="texture-label-container"></div>
          <div className="texture-label-container"></div>
          <div className="texture-label-container"></div>
          <div className="texture-label-container"></div>
          <div className="texture-label-container"></div>
          <div className="texture-label-container"></div>
        </div>
      </div>
    );
  };
  
  export default MaterialDetails;
  