import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { applicationConfig, getFilteredArray, getObjectArrayOfDistictValues, waitFor } from "../../../../../utils/methods";
import { showProductCheckout } from "../../../../../utils/store/product-cart";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Quotation from "./Quotation";
import QuotationsListContainer from "./QuotationsListContainer";
import ProductCheckout from "../checkout/ProductCheckout";
import { hideComponentLoader, showComponentLoader } from "../../../../../utils/UI_methods/global";
import ControlsTab from "../../../../common/ui-components/tabs/ControlsTab";
import $ from "jquery";
 

const QuotationsList: React.FC<{
    closeModal:()=>void
}> = (props) => {

    const [quotationsList,setQuotations] = useState([]);
    const [filteredquotations,setfilteredquotations] = useState([]);
    const [distinctQuotations,setDistintQuotations] = useState([]);
    const [currCartItems,setCurrCartItems] = useState([]);
    const[isOpenQuote,setIsOpenQuote] = useState(false)
    const[totalPrice,setTotalPrice] = useState(0)

    const[currTab,setCurrTab] = useState("WaitingForApproval")


    const[tabItems] = useState([
        {tabName:"WaitingForApproval",label:"Shared Quotation",ionIcon:null},
        {tabName:"Approved",label:"Approved",ionIcon:null},
    ])

    useEffect(()=>{
        STORE.storeApi.quotation.get(STORE?.activeCustomer?.id).then((data:any)=>{
            if(!data.error){
                if(data.data?.data?.length){
                    let quotations =  data.data.data
                    setDistintQuotations(getObjectArrayOfDistictValues(quotations,"quote_no"))
                    setQuotations(quotations)
                }
            }
        })
    },[])

    useEffect(()=>{
        if(currTab === "Approved"){
            setfilteredquotations(quotationsList.filter((currValue => currValue.is_approved)))
        }else{
            setfilteredquotations(quotationsList.filter((currValue => !currValue.is_approved)))
        }
    },[quotationsList,currTab])

    useEffect(()=>{
        let total = 0
        for (const item of currCartItems) {
          total += item.price
        }
        setTotalPrice(total)
      },[currCartItems])

    function setCartItems(quoteNo:string) {
        let items = getFilteredArray(quotationsList,"quote_no",quoteNo)
        setCurrCartItems(items)
        setIsOpenQuote(true)
    }

    async function placeOrderHandle(quoteNo:string) {
        showComponentLoader("mainLoaderSpinner")
        let items = getFilteredArray(quotationsList,"quote_no",quoteNo)
        setCurrCartItems(items)
        STORE.cartItems = items
        // setIsOpenReview(true)
        await waitFor(500)
        STORE.functionRefs.checkout.setCartItems(items)
        STORE.functionRefs.checkout.setIsCheckoutFromQuotation(true)
        await waitFor(500)
        showProductCheckout()
        props.closeModal()
        hideComponentLoader("mainLoaderSpinner")
    }

    function changeTabOption(tabName: string) {
        setCurrTab(tabName);
        updateTabValue(tabName)
    }

    function updateTabValue(val:string){
    $(".tab-content").removeClass("--is-active")
    $("#"+val).addClass("--is-active")
    }

    return (
        <div className=''>

        <IonModal
          id=""
          cssClass="vertical-modal"
          onDidDismiss={()=>setIsOpenQuote(false)}
          isOpen={isOpenQuote}
          >
          <Quotation confirmHandle={null} cartItems={currCartItems} />
        </IonModal>

        

        {/* <IonModal
          id=""
          cssClass="vertical-modal"
          onDidDismiss={()=>setIsOpenReview(false)}
          isOpen={isOpenReview}
          >
          <ProductCheckout cartItems={currCartItems}/>
        </IonModal> */}

            <div className="center-aligned-column" style={{maxWidth:"30rem"}}>
                <ControlsTab
                    configObj={applicationConfig}
                    tabItems={tabItems}
                    currSelectedValue={currTab}
                    action={changeTabOption}
                    theme="black"
                />
            </div>

            {quotationsList.length?
                <QuotationsListContainer placeOrderHandle={placeOrderHandle} setCartItems={setCartItems} quotationsList={filteredquotations} />
            :
            <div className="center-aligned-column heading4">
                <div className="heading4 border ion-padding white-card no-shadow" style={{borderStyle:"dashed"}}>No Recent Quotations</div>
            </div>
            }
        </div>
    );
};

export default QuotationsList;
