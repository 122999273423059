export const kanvasScenes = [
    {
        scene_name:"scene1",
        room_name:"Room2_View1",
        productsList: [
            {
                productName:"8159_SingleSeater",
                categoryName:"Sofa",
                parts:["Base","Wood"],
                collections:[{
                    companyName:"OVL",
                    collectionName:"Fabiano"
                }]
            },
            {
                productName:"Floor_Room2",
                categoryName:"Floor",
                parts:["Floor"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"FloorOptions"
                }]
            },
            {
                productName:"Carpet_Room2",
                categoryName:"Carpet",
                parts:["Carpet"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"CarpetOptions"
                }]
            },
            {
                productName:"Wallpaper_Room2",
                categoryName:"Wallpaper",
                parts:["Wallpaper"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"Geometric"
                },
                {
                    companyName:"Demo",
                    collectionName:"Scenery"
                }
            ]
            },
            {
                productName:"Curtain_Room2",
                categoryName:"Curtain",
                parts:["CurtainBase"],
                collections:[{
                    companyName:"DDecor",
                    collectionName:"Arlington"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Atelier"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Malabar"
                }
                ]
            }
            
        ]
    },

    // {
    //     scene_name:"scene2",
    //     room_name:"Room3_View1",
    //     productsList: [
    //         {
    //             productName:"Italia",
    //             categoryName:"Sofa",
    //             parts:["Base","Wood","BackCushion","SeatCushion","Pillow"],
    //             collections:[{
    //                 companyName:"OVL",
    //                 collectionName:"Fabiano"
    //             }]
    //         },
    //         {
    //             productName:"Floor3",
    //             categoryName:"Floor",
    //             parts:["Floor"],
    //             collections:[{
    //                 companyName:"Demo",
    //                 collectionName:"FloorOptions"
    //             }]
    //         },
    //         {
    //             productName:"Carpet3",
    //             categoryName:"Carpet",
    //             parts:["Carpet"],
    //             collections:[{
    //                 companyName:"Demo",
    //                 collectionName:"CarpetOptions"
    //             }]
    //         },
    //         {
    //             productName:"Wallpaper3",
    //             categoryName:"Wallpaper",
    //             parts:["Wallpaper","Skirting"],
    //             collections:[{
    //                 companyName:"Demo",
    //                 collectionName:"Geometric"
    //             },
    //             {
    //                 companyName:"Demo",
    //                 collectionName:"Scenery"
    //             }
    //         ]
    //         },
    //         {
    //             productName:"Curtain3",
    //             categoryName:"Curtain",
    //             parts:["CurtainBase"],
    //             collections:[{
    //                 companyName:"DDecor",
    //                 collectionName:"Arlington"
    //             },
    //             {
    //                 companyName:"DDecor",
    //                 collectionName:"Atelier"
    //             },
    //             {
    //                 companyName:"DDecor",
    //                 collectionName:"Malabar"
    //             }
    //             ]
    //         }
            
    //     ]
    // },


    {
        scene_name:"scene3",
        room_name:"Room4_View1",
        productsList: [
            {
                productName:"Linda",
                categoryName:"Sofa",
                defaultMaterial:"5012",
                parts:["Base","BackCushion","SeatCushion","Wood","Other"],
                collections:[{
                    companyName:"LWIN",
                    collectionName:"EL305"
                },
                {
                    companyName:"LWIN",
                    collectionName:"EL306"
                },
                {
                    companyName:"LWIN",
                    collectionName:"EL308"
                },
                {
                    companyName:"LWIN",
                    collectionName:"EL310"
                }]
            },
            {
                productName:"Floor",
                categoryName:"Floor",
                defaultMaterial:"1",
                parts:["Floor"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"FloorOptions"
                }]
            },
            {
                productName:"Carpet",
                categoryName:"Carpet",
                defaultMaterial:"1",
                parts:["Carpet"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"CarpetOptions"
                }]
            },
            
            {
                productName:"Curtain",
                categoryName:"Curtain",
                defaultMaterial:"3",
                parts:["CurtainBase"],
                collections:[{
                    companyName:"DDecor",
                    collectionName:"Arlington"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Atelier"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Malabar"
                }
                ]
            }
            
        ]
    },

    {
        scene_name:"scene4",
        room_name:"Room1_View1",
        productsList: [
            {
                productName:"Linda",
                categoryName:"Sofa",
                defaultMaterial:"5012",
                parts:["Base","BackCushion","SeatCushion","Wood","Other"],
                collections:[{
                    companyName:"KairaFabrics",
                    collectionName:"Adelaide"
                }]
            },
            {
                productName:"Floor_Room1",
                categoryName:"Floor",
                defaultMaterial:"1",
                parts:["Floor"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"FloorOptions"
                }]
            },
            {
                productName:"Carpet_Room1",
                categoryName:"Carpet",
                defaultMaterial:"1",
                parts:["Carpet"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"CarpetOptions"
                }]
            },
            
            {
                productName:"Curtain_Room1",
                categoryName:"Curtain",
                defaultMaterial:"3",
                parts:["CurtainBase"],
                collections:[{
                    companyName:"Ddecor",
                    collectionName:"Arlington"
                },
                {
                    companyName:"Ddecor",
                    collectionName:"Atelier"
                },
                {
                    companyName:"Ddecor",
                    collectionName:"Malabar"
                }
                ]
            },
            {
                productName:"Wallpaper_Room1",
                categoryName:"Wallpaper",
                defaultMaterial:"3",
                parts:["Wallpaper"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"Geometric"
                },
                {
                    companyName:"Demo",
                    collectionName:"Scenery"
                } 
                ]
            }
            
        ]
    },

]


export const SpritesScenes = [
    {
        scene_name:"scene1",
        room_name:"Room3_View1",
        productsList: [
            {
                productName:"8159_SingleSeater",
                categoryName:"Sofa",
                parts:["Base","Wood"],
                collections:[{
                    companyName:"OVL",
                    collectionName:"Fabiano"
                }]
            },
            {
                productName:"Floor_Room2",
                categoryName:"Floor",
                parts:["Floor"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"FloorOptions"
                }]
            },
            {
                productName:"Carpet_Room2",
                categoryName:"Carpet",
                parts:["Carpet"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"CarpetOptions"
                }]
            },
            {
                productName:"Wallpaper_Room2",
                categoryName:"Wallpaper",
                parts:["Wallpaper","Other"],
                collections:[{
                    companyName:"Demo",
                    collectionName:"Geometric"
                },
                {
                    companyName:"Demo",
                    collectionName:"Scenery"
                }
            ]
            },
            {
                productName:"Curtain_Room2",
                categoryName:"Curtain",
                parts:["CurtainBase"],
                collections:[{
                    companyName:"DDecor",
                    collectionName:"Arlington"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Atelier"
                },
                {
                    companyName:"DDecor",
                    collectionName:"Malabar"
                }
                ]
            }
            
        ]
    }

]