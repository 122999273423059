import React, { useState } from "react";
import { SofaConfiguration } from "../../../../../../utils/store/storeConfiguration";
import Modal from "../../../../../common/shared-components/Modals/Modal";
import QualityDetails from "../common/QualityDetails";
import CustomInstructions from "./CustomInstructions";
import Options from "./Options";
import Summary from "./Summary";
import MultiSelectOptions from "./AddonsSelector";
import AddonsSelector from "./AddonsSelector";
 

const ConfigurationSelector: React.FC<{
  customizingOptions:any
  product:any
  productConfiguration:SofaConfiguration
  confirmHandle:()=>void
}> = (props) => {


  const[currSelectedWindowIndex,setCurrSelectedWindowIndex] = useState(0)
  const[isOpen,setIsOpen] = useState(false)



  return (
    <div className="full-height-width" id="productConfiguratorContainer" style={{display:"none"}}>
        <Modal
          id=""
          classNames="medium-y-modal"
          onDismiss={()=>setIsOpen(false)}
          isOpen={isOpen}
          Content={()=>(
            <QualityDetails></QualityDetails>
          )}
          heading="Quality details"
          FooterContent={()=>(<></>)}
          >
        </Modal>

        <Summary confirmHandle={props.confirmHandle} setCurrSelectedWindowIndex={setCurrSelectedWindowIndex} productConfiguration={props.productConfiguration}  product={props.product} customizingOptions={props.customizingOptions} currSelectedWindowIndex={currSelectedWindowIndex}/>

        {props.customizingOptions.map((currOption:any,index:number)=>{
          return(
            <>
              { 
              currOption.name === "Custom Instructions" ?
                <CustomInstructions customizingOptions={props.customizingOptions} productConfiguration={props.productConfiguration} setCurrSelectedWindowIndex={setCurrSelectedWindowIndex} currSelectedWindowIndex={currSelectedWindowIndex} index={index}/>
              : 
                currOption.name.toLowerCase().includes("addons")?
                <AddonsSelector customizingOptions={props.customizingOptions} productConfiguration={props.productConfiguration} currSelectedWindowIndex={currSelectedWindowIndex} setCurrSelectedWindowIndex={setCurrSelectedWindowIndex} customizationOption={currOption} heading={currOption.heading} index={index}/>
                :
                <Options customizingOptions={props.customizingOptions} productConfiguration={props.productConfiguration} currSelectedWindowIndex={currSelectedWindowIndex} setCurrSelectedWindowIndex={setCurrSelectedWindowIndex} customizationOption={currOption} heading={currOption.heading} index={index}/>
              }
            </>
          )
        })}
    </div>
  );
};

export default ConfigurationSelector;
