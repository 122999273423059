import {
  IonPage
} from "@ionic/react";
import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SessionExpired from "../../../common/ui-components/alerts/SessionExpired";
import SubscriptionAlert from "../../../common/ui-components/alerts/SubscriptionAlert";
import MainHeader from "../../products/store/common/MainHeader";
import { Carousel } from "react-responsive-carousel";
import Button2 from "../../../common/ui-components/buttons/Button2";
import TopCollections from "./TopCollections";
import AccessDenied from "../../../common/modules/access-denied/AccessDenied";
import KairaAbout from "./KairaFabrics/KairaAbout";
import KairaContact from "./KairaFabrics/KairaContact";
 

const KanvasHome: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {

  const[userType] = useState(props.configObj.user.userType)


  function Banner() {
    return(
      <div className="full-width">
        <Carousel infiniteLoop={true} showThumbs={false}   autoPlay={true}>
            <div >
                <img style={{maxHeight:"500px",objectFit:"cover"}} className="contain" src="https://kairafabrics.in/wp-content/themes/kairafab/img/1.jpg" />
                <div className="middle uppercase color-white font-large" style={{padding:"1rem 1.5rem", background:"rgba(0,0,0,0.3)"}}>Elegence, Woven in every Stich</div>
            </div>
            <div>
                <div className="middle uppercase color-white font-large" style={{padding:"1rem 1.5rem",background:"rgba(0,0,0,0.3)"}}>Elegence, Woven in every Stich</div>
                <img style={{maxHeight:"500px",objectFit:"cover"}} src="https://kairafabrics.in/wp-content/themes/kairafab/img/1.jpg" />
            </div>
        </Carousel>
      </div>
    )
  }

  function Categories() {
    return(
      <div className="full-width section padding-container">
        <div className="ion-padding center-aligned-column" >
          <div className="text-center font-ex-large" style={{padding:"2.5rem 0"}}>Explore fabulous collection of<br/>
          sofa fabrics</div>
        </div>
        <TopCollections/>
      </div>
    )
  }


 

  function Footer(){
    return (
      <div className="full-width bg-color-dark center-aligned-column" style={{height:"10rem"}}>
         <p className="color-white heading4">&copy; 2024 Kaira Fabrics. All rights reserved. | Powered by <a href="https://omniviewlabs.com/" className="color-white" style={{textDecoration:"none"}}> omniviewlabs.com</a></p>
      </div>
    )
  }

  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white">
        <SubscriptionAlert/>
        <MainHeader/>
        <div className="main-content-wrapper overflow-y-scroll hide-scrollbar  position-relative light-background">
          <Banner/>
          <Categories/>
          <KairaAbout/>
          <KairaContact/>
          <Footer/>
        </div>
      </div>
      :
      <SessionExpired/>
      }
    </IonPage>
  );
};

export default KanvasHome;
