import React, { useEffect, useState } from "react";
import { getFormatedDate } from "../../../../../../../utils/store/crm/crm";
import Comments from "./Comments";
import { applicationConfig } from "../../../../../../../utils/methods";
 


 
const TicketDetails: React.FC<{
    ticket:any
}> = (props) => {



  const[allImages,setAllImages] = useState([])


  useEffect(()=>{
    if(props.ticket){
      let key = `public/estre/customer-tickets/${props.ticket.ticket_no}`
      applicationConfig.awsConfig.viewAlbumContents(key).then((data:any)=>{
        setAllImages(data)
      })
    }
  },[])

 
  function Details() {
    return(
      <div className="display-flex-row white-card no-shadow full-width border no-wrap">

      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="profile center-aligned-column">
          <div className="bg-color-primary font-small color-white center-aligned-column white-card no-shadow" style={{width:"28px",height:"28px",borderRadius:"50%"}}>
              {props.ticket?.customer_name.substring(0,2)}
          </div>
        </div>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Customer name</div>
          <div className="heading4 font-normal color-black">{props.ticket?.customer_name}</div>
        </div>
      </div>


      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Contact no.</div>
          <div className="heading4v font-normal color-black">+91-{props.ticket?.mobile}</div>
        </div>
      </div>


      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Ticket Number</div>
          <div className="heading4v font-normal color-primary">{props.ticket?.ticket_no}</div>
        </div>
      </div>

      


      </div>
    )
  }
  
 
  return (
    <div className="full-height-width ion-padding display-flex-column no-wrap overflow-y-scroll">
    <Details/>

    <div className="ion-margin-top white-card border no-shadow padding10">
      <div className="heading3 font-normal">Ticket Details</div>

      <div className="display-flex-row no-wrap ion-margin-top">
        <div className="heading4 font-md-small ticket-info-title">Sales Order Number</div>
        <div className="heading4 color-primary font-md-small ion-margin-start">{props.ticket?.so_number}</div>
      </div>

      <div className="display-flex-row no-wrap ion-margin-top">
        <div className="heading4 font-md-small ticket-info-title">Priority</div>
        <div className="heading4 color-primary font-md-small ion-margin-start">{props.ticket?.priority}</div>
      </div>


      <div className="display-flex-row no-wrap marginTop10">
        <div className="heading4 font-md-small ticket-info-title">Issue Type</div>
        <div className="heading4 color-black font-md-small ion-margin-start">{props.ticket?.issue_type}</div>
      </div>

      <div className="display-flex-row no-wrap marginTop10">
        <div className="heading4 font-md-small ticket-info-title">Description</div>
        <div className="heading4 color-black font-md-small ion-margin-start">{props.ticket?.description}</div>
      </div>

      <div className="display-flex-row no-wrap marginTop10">
        <div className="heading4 font-md-small ticket-info-title">Resolution Date</div>
        {props.ticket?.resolution_date?
        <div className="heading4 color-black font-md-small ion-margin-start">{getFormatedDate(props.ticket?.resolution_date)}</div>
        :
        <div className="heading4 color-black font-md-small ion-margin-start">Not Scheduled</div>
        }
      
        </div>
      

      

      <div className="display-flex-row no-wrap marginTop10">
        <div className="heading4 font-md-small ticket-info-title">Remarks</div>
        <div className="heading4 color-black font-md-small ion-margin-start">{props.ticket?.remarks === "undefined"?"":props.ticket?.remarks}</div>
      </div>

    </div>

    <Comments ticket={props.ticket}/>

    <div className="activities-list-wrapper ion-margin-top white-card border no-shadow padding10">
      <div className="heading3 font-medium">References </div>
      <div className="center-aligned-column ion-margin-top" >
        <div className="padding10">
          <a target="_blank" href={`https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/customer-tickets/${props.ticket.ticket_no}/receipt/receipt.png`}>Receipt</a>
        </div>
      </div>

      <div className="other-images">
        <div className="heading3 padding5">Other Images</div>
        <div className="display-flex-row space-between">
          {allImages.map((currKey:string,index:number)=>{
            let imageUrl = `https://opusassets.s3.ap-south-1.amazonaws.com/${currKey}`
            return(
              <div className="padding5 border pointer" key={index} style={{width:"50%"}}>
                <a target="_blank" href={imageUrl}>
                  <img src={imageUrl} alt="" className="full-width" />
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </div>


    </div>
  );
};

export default TicketDetails;
