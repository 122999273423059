import $ from "jquery";
import React, { useEffect, useState } from "react";
import { getProjects, saveProject, updateProject } from "../../../../services/api";
import { applicationConfig } from "../../../../utils/methods";
import { setCreateProjectFormData } from "../../../../utils/projects/createProject";
import { ProjectForm } from "../../../../utils/projects/projectForm";
import FormStatus from "./FormStatus";
import { developers } from "./data";
import UploadFloorplan from "./UploadFloorplan";
import { displayUploadedImage } from "../../../../utils/inventory/inventory";
import Button2 from "../../../common/ui-components/buttons/Button2";

const ProjectDetailsForm: React.FC<{
  updateCurrentState:(state:string) =>void
  closeModal: (value: boolean) => void;
  updateApartmentType:(apartmentType:string)=>void
  setProjectId:(id:number)=>void
  projectId:number
  projectForm:ProjectForm
  configObj:any
  selectedFile:any
  setSelectedFile:(file:any)=>void
}> = (props) => {
  

  
  useEffect(()=>{
    if(props.selectedFile){
      displayUploadedImage(props.selectedFile,"uploadedImage")
    }
  },[props.selectedFile])


  useEffect(()=>{
    let projectId = props.projectId
    if(projectId){
      //get the project details and fill the form 
      getProjects().then((data:any) =>{
        let projectDetails = data?.filter((project:any)=>String(project.project_id)===String(projectId))
        if(projectDetails){
          setCreateProjectFormData(projectDetails[0])
        }
      }).catch(error=>{
        console.log(error)
      })

      // displayFloorPlanImage(projectId)
    }
  },[props.projectId])




 


  const createProject = () => {
    if(ifEmptyFields()){
      return
    }
    updateApartmentType()
    saveProject(props.projectForm,applicationConfig.clientId)
      .then((response: any) => {
        //set project Id 
        props.setProjectId(response.data.data.id)
        props.projectForm.projectId = response.data.data.id
        uploadImage()
        //Used to display in summary page
        // props.setProjectDetails({SELECTED_FILE:props.projectDetails?.SELECTED_FILE,projectName:formData.projectName,projectLocation:formData.projectLocation,developer:formData.developer,projectType:formData.projectType})
        props.updateCurrentState("Step2");
      })
      .catch((error: any) => {
        console.log(error);
      });
  };


  function updateProjectDetails() {
    if(ifEmptyFields()){
      return
    }
    // updateApartmentType()
    updateProject(props.projectForm)
    .then((response: any) => {
      console.log(response)
      props.setProjectId(props.projectId)
      // props.setProjectDetails({SELECTED_FILE:props.projectDetails?.SELECTED_FILE,projectName:formData.projectName,projectLocation:formData.projectLocation,developer:formData.developer,projectType:formData.projectType})
      props.updateCurrentState("Step2")
    })
    .catch((error: any) => {
      console.log(error);
    });
  }

 

  function updateApartmentType() {
    let apartmentTypeInput: any = document.getElementById("apartmentType")!;
    let apartmentType:string = apartmentTypeInput.value
    props.updateApartmentType(apartmentType)
  }


  function ifEmptyFields() {
    let err = false
    let inputs = document.querySelectorAll(".input-mandatory")
    for (let i = 0; i < inputs.length; i++) {
      const inputElement:any = inputs[i];
      let value = inputElement.value
      if(value===""){
        $(inputElement).addClass("mandatory")
        err = true
      }else{
        $(inputElement).removeClass("mandatory")
      }
    }
    return err
  }


  const Form = () => {
    return(
      <div className="form-container ">
      <div className="custom-row">
        <div className="custom-col-50">
          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">Project Name <span className="mandatory">*</span></div>
            </div>
            <div className="input-container">
              <input
                className="input input-mandatory"
                type="text"
                name="projectName"
                id="projectName"
                defaultValue={"NewDemo"}
                onChange={(event)=>{props.projectForm.projectName = event.target.value}}
                required
              />
            </div>
          </div>
        </div>
        <div className="custom-col-50">
          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">Project Location <span className="mandatory">*</span></div>
            </div>
            <div className="input-container">
              <input
                className="input input-mandatory"
                type="text"
                name="location"
                id="projectLocation"
                value={"Bangalore"}
                onChange={(event)=>props.projectForm.projectLocation = event.target.value}
                required
              />
            </div>
          </div>
        </div>
      </div>


      <div className="custom-row">
        <div className="custom-col-50">
          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">Client Name <span className="mandatory">*</span></div>
            </div>
            <div className="input-container">
              <input
                className="input input-mandatory"
                type="text"
                name="clientName"
                id="clientName"
                value={"DemoClient2"}
                onChange={(event)=>props.projectForm.clientName = event.target.value}
                required
              />
            </div>
          </div>
        </div>
        <div className="custom-col-50">
          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">Mobile Number <span className="mandatory">*</span> </div>
            </div>
            <div className="input-container">
              <input
                className="input input-mandatory"
                type="number"
                name="mobile"
                id="clientMobile"
                onChange={(event)=>props.projectForm.clientMobile = event.target.value}
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div className="custom-row">
        <div className="custom-col-50">
          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">Project Type <span className="mandatory">*</span></div>
            </div>
            <div className="input-container">
              <select onChange={(event)=>props.projectForm.projectType = event.target.value} className="input" name="" id="projectType">
                <option value="Apartment">Apartment</option>
                <option value="Villa">Villa</option>
                <option value="Independent House">Independent House</option>
              </select>
            </div>
          </div>
        </div>
        {/* <div className="custom-col-50">
          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">Developer</div>
            </div>
            <div className="input-container">
              <select onChange={(event)=>props.projectForm.developer = event.target.value} className="input" name="" id="developer">
                {developers.map((developer,index)=>{
                  return(
                    <option key={index} value={developer}>{developer}</option>
                  )
                })}
              </select>
            </div>
          </div>
        </div> */}
      </div>

      <div className="form-label-input-container">
        <div className="label-container">
          <div className="label">No. of BedRooms <span className="mandatory">*</span></div>
          <div className="hint"></div>
        </div>
        <div className="input-container">
          <select onChange={(event)=>props.projectForm.apartmentType = event.target.value}  className="input" name="" id="apartmentType">
              <option value="1BHK">1BHK</option>
              <option value="2BHK">2BHK</option>
              <option value="3BHK">3BHK</option>
              <option value="4BHK">4BHK</option>
              <option value="5BHK">5BHK</option>
              <option value="6BHK">6BHK</option>
          </select>
        </div>
      </div>

      {props.selectedFile?
      <div className="padding5">
        <img src="" id="uploadedImage" width={300} alt="" />
        <Button2
          configObj={applicationConfig}
          action={()=>props.setSelectedFile(null)}
          label={"Reset"}
          fontAwesomeIcon={"fas fa-undo color-danger"}
          iconPos=""
          classNames={"bg-color-light marginTop10 button-"}
        />
      </div>
      :
      <UploadFloorplan
        setSelectedFile={props.setSelectedFile}
      />
      }
      

    </div>
    )
  }

 
  const uploadImage = () => {
    if (props.selectedFile && props.projectId) {
      const fileName = "flooplan.png"
      let albumName = "uploads";
      var albumPhotosKey = encodeURIComponent(albumName) + "/floorplan/" +  applicationConfig?.clientName + "/" + props.projectForm.projectName + "_"  + props.projectId + "/";
        applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey, props.selectedFile,fileName,true).then((data:any) => {
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
    }
  };
 
   
  function Body() {
    return(
      <div className="modal-body " style={{paddingBottom:"5rem"}}>
      <div className="full-height-width">
        <div className="full-height-width">
          <FormStatus
            count={1}
          />
          <Form />
          
        </div>
        {/* <div className="full-height">
          <FileUpload heading="Upload floor plan"
              configObj={{}}
              setFile={setProjectImage}
          />
        </div> */}
      </div>
      </div>
    )
  }
  return (
    <React.Fragment>
    <div className="modal-header heading1">
      Create New Project
    </div>


    <Body/>
  <div className="modal-footer" style={{justifyContent:"flex-end"}}>
    {!props.projectId?
        <button className="op-btn op-btn-primary" onClick={() => createProject()}>
        <i className="fa fa-save" area-hidden="true"/> Save & Proceed
      </button>
    :
        <button className="op-btn op-btn-primary marginleft5" onClick={updateProjectDetails}>
         Next <i className="fas fa-arrow-right"></i>
        </button>
    
      
    }
  </div>
  </React.Fragment>
        
  );
};

export default ProjectDetailsForm;
