import { IonIcon, IonPage } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { displayUploadedImage } from "../../../../utils/inventory/inventory";
import $ from "jquery"

const FileUpload: React.FC<{
  configObj: any;
  setFile:any
  heading:string
}> = (props) => {

  var SELECTED_FILE = null

  useEffect(()=>{
    if(SELECTED_FILE){
      displayUploadedImage(SELECTED_FILE,"uploadedImage")
    }
  },[SELECTED_FILE])

  const selectFile = (event:any) => {
    SELECTED_FILE = event.target.files[0]
    props.setFile(SELECTED_FILE)
    displayUploadedImage(SELECTED_FILE,"uploadedImage")
  }

  function uploadImage() {
    $("#uploadImage").click()
  }

 
  return (
    <div className="padding10">
      <div className="label marginBottom5">{props.heading}</div>
    <div
      className="border position-relative upload-image-input center-aligned-column"
      style={{ aspectRatio: "4/3",width:"200px" }}>
        <img className="top-left full-height-width background-white" onClick={uploadImage} id="uploadedImage" alt="" style={{display:"none"}}/>
      <div className="">
        <div className="center-aligned-row">
          <i className="fas fa-upload font-large color-dark"></i>
        </div>
        <div className="center-aligned-row color-dark ion-padding-bottom ion-padding-top heading3 font-small" id="inputLabelProductImage">
          Select a file or drag here
        </div>
        <div className="center-aligned-row">
          <label className="op-btn op-btn-primary center-aligned-row"
            style={{ width: "auto", height: "auto" }}>
            <input type="file" id="uploadImage" accept="image/*" capture="camera" onChange={selectFile} />
            <span
              className="center-aligned-row color-white heading4"
              id="inputLabelProductImage">
              Browse file
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
  );
};

export default FileUpload;
