import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import { applicationConfig, getObjectByParameter } from "../../../../../utils/methods";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ProductImage from "../../../../common/ui-components/elements/ProductImage";
import CartProductConfig from "../common/CartProductConfig";
import ProductionOrderCard from "../quotation/ProductionOrderCard";
import ItemPricing from "./ItemPricing";
import MaterialImage from "../../../../common/ui-components/elements/MaterialImage";

 

const CartItemsList: React.FC<{
  cartItems:any
  updateTotalPrice:()=>void
}> = (props) => {

  const[isOpenQuote,setIsOpenQuote] = useState(false)
  const[currCartItem,setCurrCartItem] = useState(null)
 

  function openOrderCardAction(currItem:any) {
    setCurrCartItem(currItem)
    setIsOpenQuote(true)
  }

 
 
  return (
    <div className="items-list overflow-y-scroll">

        <IonModal
          id=""
          cssClass="vertical-modal"
          onDidDismiss={()=>setIsOpenQuote(false)}
          isOpen={isOpenQuote}
          backdropDismiss={true}        
          >
            <>
            {currCartItem?
              <ProductionOrderCard currCartItem={currCartItem}/>
            :null}
            </>
        </IonModal>

    {props.cartItems.map((item:any,index:number)=>{
      let material = item.material
      const productDetails = getObjectByParameter(applicationConfig?.data.productsList,"product_id",item.product_id)
      return(
        <div className="display-flex-row position-relative white-card no-shadow border cart-item padding5 space-between border-bottom" key={index}>
          {productDetails || material?
           <div className="product-details-cart display-flex-row no-wrap">
            <div className="">
              <div className="image">
                {material?
                <MaterialImage material={material}/>
                :
                <ProductImage product={productDetails}/>
                }
              </div>
            </div>
            
            <div className="other-details ion-margin-start">

              <div className="">
                {item.configuration || material?
                  <ItemPricing updateTotalPrice={props.updateTotalPrice} product={productDetails} item={item}/>
                :null}
              </div>
            </div>

            {!item.material?
              <div className="top-right marginleft10">
                <div className="display-flex-row no-wrap">
                  <CartProductConfig buttonClass={""} product={productDetails} item={item}/>
                  <Button2
                    configObj={{id:""}}
                    action={()=>{openOrderCardAction(item)}}
                    label={"Generate Order Card"}
                    fontAwesomeIcon={"fas fa-file-invoice color-warning"}
                    iconPos="left"
                    classNames={"no-shadow button-small hide-button-text-in-mobile margin0 cart-button-"}
                  />
                </div>
              </div>
            :null}

            


          </div>
          
          :null}
         
        </div>
      )
    })}
  </div> 
  );
};

export default CartItemsList;
