import React from "react";

const Input: React.FC<{
    id:string
    label:string
    isMandatory:boolean
    onChange:(event:any)=>void
    value:any
    className:string
    type:string
    placeholder?:string
    LabelHint?:any
}> = (props) => {

 
  return (
    <div className="form-label-input-container">
      <div className="label-container ">
        {props.label.length?
          <div className="label">
            {props.label} {props.isMandatory?<span className="mandatory">*</span>:null}
            {props.LabelHint?
              <props.LabelHint/>
            :null}
          </div>
         
        :null}
      </div>
      <div className="input-container">
          <input
            id={props.id}
            className={`input input-mandatory ${props.className}`}
            type={props.type}
            required
            onChange={props.onChange}
            defaultValue={props.value}
            placeholder={props.placeholder}
        />
      </div>
    </div>
  );
};
            
export default Input;
