import $ from "jquery";
import { addMaterial, createCollection, createCompany, getAllCollections, getMaterialCompanies, getTextures } from "../../services/api";
import { hideComponentLoader, showComponentLoader, showToast } from "../UI_methods/global";
import { untarAndSaveTarGzFile } from "../cache/cache";
import { applicationConfig, stringWithoutWhiteSpace } from "../methods";
import { updateProductTarFile } from "../products/createproduct";


export function displayUploadedImage(SELECTED_FILE:any,elementId:string) {
  return new Promise((resolve,reject)=>{
  var reader = new FileReader();
    if(SELECTED_FILE){
      try {
          reader.onload = function (e:any) {
              $('#'+elementId).attr('src', e.target.result)
                  .css("display","block")
                  resolve("done")
          };
          reader.readAsDataURL(SELECTED_FILE);
      } catch (error) {
          reject(error)
      }
    }
  })
    
  }


  export async function displaySelectedImage(SELECTED_FILE:any,elementId:string) {
    return new Promise((resolve,reject)=>{
      var reader = new FileReader();
        if(SELECTED_FILE){
            try {
                reader.onload = function (e:any) {
                    $('#'+elementId)
                        .attr('src', e.target.result)
                        .css("display","block")
                    resolve("loaded")
                };
                reader.readAsDataURL(SELECTED_FILE);
            } catch (error) {
                reject(error)
            }
        }else{
            reject("Please upload FIle")
        }
    })

  }


  export function saveFinishDetails(finishDetails:any,closeModal:(val:boolean)=>void) {
    //get material codes array from details // dont send file object
    let collectionName = finishDetails.collectionName
    let companyName = finishDetails.companyName
    if(!collectionName || !companyName){
      alert("Company and collection required")
      return
    }
    $("#errorContainer").find("li").remove()
    $("#successContainer").find("li").remove()
    Promise.all([createCollection({collectionName:collectionName,clientId:applicationConfig.clientId}),createCompany({companyName:companyName})]).then(data=>{
      if(data[0].data.error){
        $("#errorContainer").append(`<li>Collection already exists</li>`)
      }else{
        updateCompanyAndCollectionData()
        $("#successContainer").append(`<li>Added collection successfully</li>`)
      }
      if(data[1].data.error){
        $("#errorContainer").append(`<li>Company already exists</li>`)
      }else{
        updateCompanyAndCollectionData()
        $("#successContainer").append(`<li>Added company successfully</li>`)
      }
    }).catch(err=>{
        $("#errorContainer").append(`<li>${err}</li>`)
    })
}

export function updateCompanyAndCollectionData() {
  Promise.all([getMaterialCompanies(),getAllCollections()]).then(data=>{
    applicationConfig.updateData("materialCompaniesList",data[0])
    applicationConfig.updateData("allCollections",data[1])
  })
}

export function createMaterialUploadImage(finishDetails:any,closeModal:(val:boolean)=>void) {
  showComponentLoader("mainLoaderSpinner")
  finishDetails.materialCodes = finishDetails.materialCodes.filter(currCode => !currCode.toLowerCase().includes("normal") && !currCode.toLowerCase().includes("roughness")) 
  addMaterial(finishDetails).then(async res=>{
    console.log(res)
    updateFinishData()
      
    //Upload png images to s3 after adding to the database 
    let promisesList = []

    for (const object of finishDetails.finishImageFiles) {     
      let fileName = object.SELECTED_FILE.name
      let albumName = "thumbnails"
      // let collection:any = getObjectByParameter(applicationConfig?.data?.allCollections,"collection_name",finishDetails.collectionName)
      let albumPhotosKey = encodeURIComponent(albumName) + "/OVL/" + finishDetails?.companyName + "/" + finishDetails.collectionName + "/" 
      if(object?.SELECTED_FILE){
        promisesList.push(await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,object.SELECTED_FILE,fileName))
      }
    }  
    await Promise.all(promisesList)
    let prefix = stringWithoutWhiteSpace(`thumbnails/OVL/${finishDetails.companyName}/${finishDetails.collectionName}/`)
    let fileName = stringWithoutWhiteSpace(`${finishDetails.collectionName}.tar.gz`)
    await updateProductTarFile(prefix,fileName)
    await updateTarFile(finishDetails.companyName,finishDetails.collectionName)
    closeModal(false)
    hideComponentLoader("mainLoaderSpinner")
    showToast("Created successfully",2000)
  }).catch(err=>{
    hideComponentLoader("mainLoaderSpinner")
    console.log(err)
    showToast("Error in creating",2000,err)
    closeModal(false)
  })
}

function updateFinishData() {
  getTextures().then(data=>{
    applicationConfig.updateData("materials",data)
  })
}

async function updateTarFile(companyName:string,collectionName:string) {
  let tarFileKey = applicationConfig.awsConfig.getTarFileKey("thumbnails",{companyName:companyName,collectionName:collectionName})
  let urlPrefix = `thumbnails/OVL/${companyName}/${collectionName}`
  console.log(urlPrefix,tarFileKey)
  // await untarAndSaveToCache(applicationConfig.awsConfig,urlPrefix,key)
  await applicationConfig.awsConfig.getObject(tarFileKey).then(async (data:any)=>{
    let bufferView = data.Body;
    await untarAndSaveTarGzFile(bufferView,urlPrefix,tarFileKey)
  })
}