import $ from "jquery";
import React from "react";
import { Quaternion } from "three";
import { rotateCameraDown, rotateCameraLeft, rotateCameraRight, rotateCameraUp, stopCameraRotateInterval } from "../../../../../../../utils/customizein3d/area3dmodel/actions";
import { camera } from "../../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { pointerDownJoystick, pointerMoveJoystick, pointerUpJoystick } from "../../../../../../../utils/customizer/joystick";
import { applicationConfig } from "../../../../../../../utils/methods";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";

const MovementControls: React.FC<{
}> = (props) => {
   
  
  function updateQuaternion() {
    let quaternion = new Quaternion(Number($("#cameraRotationX").val()),Number($("#cameraRotationY").val()),Number($("#cameraRotationZ").val()),Number($("#cameraRotationW").val()))
    camera.quaternion.copy( quaternion )
    camera.updateProjectionMatrix()
  }

  function MovementControls() {
    return(
      <div className="zindex30">
        <div className="position-relative" >
          {/* <div className="top-middle"> <button onMouseUp={stopCameraRotateInterval} onMouseDown={rotateCameraUp} className="op-btn  movement-control"><i className="fas pointer-none fa-chevron-up font-medium icon"></i></button> </div>
          <div className="left-middle"> <button onMouseUp={stopCameraRotateInterval} onMouseDown={rotateCameraLeft} className="op-btn movement-control"><i className="fas fa-chevron-left pointer-none font-medium icon"></i></button> </div>
          <div className="right-middle"> <button onMouseUp={stopCameraRotateInterval} onMouseDown={rotateCameraRight} className="op-btn  movement-control"><i className="fas fa-chevron-right pointer-none font-medium icon"></i></button> </div>
          <div className="bottom-middle padding0"> <button onMouseUp={stopCameraRotateInterval} onMouseDown={rotateCameraDown} className="op-btn movement-control"><i className="fas fa-chevron-down pointer-none font-medium icon"></i></button> </div> */}
        
          {/* <div className="row" style={{display:"inline-flex",clear:"both",zIndex:1002}}>
            <div className="columnLateral">
              <div id="joystickContainer" style={{width:"200px",height:"200px"}}></div>
            </div>
          </div> */}

          <div className="joystick-outer white-card position-relative">
            <div onPointerDown={pointerDownJoystick} onPointerMove={pointerMoveJoystick} onPointerUp={pointerUpJoystick} id="joyStickKnob" className="joystick-inner middle white-card"></div>
          </div>
        
        </div>

        

      </div>
    )
  }

  function RotationDebugging() {
    return(
      <div className="zindex30 top-left display-none">
      <div className="white-card shadow-light padding10">
        <div className="heading1 padding5 font-normal">Quaternion</div>
        <div className="rotation-values">
          <div className="display-flex-row no-wrap padding5">
            <div className="heading3 center-aligned-column">X</div>
            <div className="center-aligned-column marginleft10"><input id="cameraRotationX" type="text" className="input " defaultValue={camera.quaternion.x} style={{width:"80px"}}/></div>
          </div>
          <div className="display-flex-row no-wrap padding5">
            <div className="heading3 center-aligned-column">Y</div>
            <div className="center-aligned-column marginleft10"><input id="cameraRotationY" type="text" className="input " defaultValue={camera.quaternion.y} style={{width:"80px"}}/></div>
          </div>
          <div className="display-flex-row no-wrap padding5">
            <div className="heading3 center-aligned-column">Z</div>
            <div className="center-aligned-column marginleft10"><input id="cameraRotationZ" type="text" className="input " defaultValue={camera.quaternion.z} style={{width:"80px"}}/></div>
          </div>
          <div className="display-flex-row no-wrap padding5">
            <div className="heading3 center-aligned-column">W</div>
            <div className="center-aligned-column marginleft10"><input id="cameraRotationW" type="text" className="input " defaultValue={camera.quaternion.w} style={{width:"80px"}}/></div>
          </div>
        </div>
        <div className="marginTop10">
          <Button2
            configObj={applicationConfig}
            action={updateQuaternion}
            label={"Update"}
            fontAwesomeIcon={"fas fa-check color-danger"}
            iconPos=""
            classNames={"bg-color-success button-"}
          />
        </div>
      </div>
    </div>
    )
  }
  return (
    <>
    <div className="zindex30 bottom-middle ion-padding">
        <div className="display-flex-row no-wrap" style={{width:"auto"}} >
          <div className="marginRight5"> 
            <div onMouseUp={stopCameraRotateInterval} onMouseDown={rotateCameraLeft} className="op-btn op-btn-lg display-flex-row no-wrap movement-control">
              <div className="center-aligned-column"><i className="fas fa-chevron-left pointer-none  font-small icon"></i></div>
              <div className="label marginleft5 color-black heading2 uppercase">Pan Left</div>
            </div> 
          </div>
          <div className=""> 
            <div onMouseUp={stopCameraRotateInterval} onMouseDown={rotateCameraRight} className="op-btn op-btn-lg display-flex-row no-wrap movement-control">
              <div className="label marginRight5 color-black heading2 uppercase">Pan Right</div>
              <div className="center-aligned-column"><i className="fas fa-chevron-right pointer-none center-aligned-column font-small icon"></i></div>
            </div> 
          </div>
        </div>
      </div>
      </>
  );
};

export default MovementControls;
 
