import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import MoodboardCards from "../../common/ui-components/cards/MoodboardCards";
import FilterCardsLayout from "../../templates/layouts/page/CardsLayout";
import CreateMoodboard from "./createmoodboard/CreateMoodboard";
import MoodboardHeader from "./MoodboardHeader";

const MoodboardContainer: React.FC<{
  configObj:any
  setCurrProjectDetails:(id:number,name:string)=>void
  setCurrMoodboardId:(id:string)=>void
  filteredMoodboardList:Array<any>
}> = (props) => {

  const[isCreateMoodboardOpen,setIsCreateMoodboardOpen] = useState(false)
  const[moodboardList,setMoodboardList] = useState([])
  const[filteredMoodboardList,setFilteredMoodboardList] = useState([])

  useEffect(()=>{
    let moodboardList = getArrayOfDistictObjects(props.configObj?.data.moodboardList)
    setMoodboardList(moodboardList)
    props.configObj.setFunctionRef("moodboard",{setMoodboardList:setFilteredMoodboardList})
    props.configObj.setState("moodboard",{moodboardList:moodboardList})
  },[props.configObj])


  function AddCardForm() {
    return(
      <CreateMoodboard
      configObj={props.configObj}
      closeModal={setIsCreateMoodboardOpen}
      refreshMoodboardList={refreshMoodboardList}
      // selectedAreas={selectedAreas}
      // setSelectedAreas={setSelectedAreas}
      // otherAreas={otherAreas}
      // setOtherAreas={setOtherAreas}
      setCurrMoodboardId={props.setCurrMoodboardId}
      setCurrProjectDetails={props.setCurrProjectDetails}
      
    />
    )
  }

  function refreshMoodboardList(data:any) {
    let moodboardList = getArrayOfDistictObjects(data)
    setMoodboardList(moodboardList)
  }


  function getArrayOfDistictObjects(array:Array<any>) {
    let set = new Set()

    let tempArray = []
    for (let i = 0; i < array?.length; i++) {
      const currElement = array[i];
      if(!tempArray.includes(currElement?.moodboard_id)){
        set.add(currElement)
        tempArray.push(currElement?.moodboard_id)
      }
    }
    return Array.from(set)
  }
 

 
  return (
    <React.Fragment>
    <MoodboardHeader
        configObj={props.configObj}
        moodboardList={moodboardList}
        setMoodboardList={setFilteredMoodboardList}
      />
      <FilterCardsLayout
        MainContent={()=>(
          <MoodboardCards
            configObj={props.configObj}
            moodboardList={filteredMoodboardList}
            setCurrMoodboardId={props.setCurrMoodboardId}
            setCurrProjectDetails={props.setCurrProjectDetails}
          />
        )}
        AddCardForm={AddCardForm}
        configObj={props.configObj}
      />

  </React.Fragment>
  );
};

export default MoodboardContainer;
