import React, { useEffect, useState } from "react";
import { saveUploadedFileInCache } from "../../../../../utils/cache/cache";
import { configuration, updateModuleFinish, updateTexture } from "../../../../../utils/customizein3d/modules/customizein3d";
import { TextureInfo } from "../../../../../utils/customizer/ProjectConfiguration";
import { applicationConfig, categoryFilteredTextures, getClientFilteredMaterials, getFilteredArray, getObjectByParameter, getRandomString, getSubDomainName, getUser } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import "../../../../style/controls/customizeLeftControls.css";
import Materials from "../../../shared-components/Materials/Materials";
import UploadImage from "../../../ui-components/forms/UploadImage";
import { getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";
import MaterialsWithDropDown from "../../../shared-components/Materials/MaterialsWithDropDown";

const Finish: React.FC<{
  configObj: any;
}> = (props) => {

  const [allProductMaterials,setAllProductMaterials]: any = useState([]);
  const[storeFronts] = useState(applicationConfig?.user?.storeFronts || [])


  function getStoreFrontId() {
    let result = 0
    let productId =  configuration.product?.productId || configuration.product?.product_id
    if(productId){
        result = getObjectByParameter(applicationConfig.data?.productsList,"product_id",productId)?.storefront_id
    }
    return result
  }

  useEffect(()=>{
    let materials = props.configObj?.data.materials || []
    if(configuration){
      let currProduct = configuration.product
      // if(applicationConfig?.clientName.toLowerCase().includes("democlient")){
      //   materials = getApplicationFiltersMaterials("Customizer",props.configObj?.data.materials,props.configObj?.data.applicationMaterials)
      // }

      //Filter With storeFronts
      if(storeFronts?.length > 1 && applicationConfig.clientName === "DashSquare"){
        let currStoreFrontId = getStoreFrontId()
        if(currStoreFrontId){
          materials = getFilteredArray(materials,"storefront_id",currStoreFrontId)
        }
      }
      materials = getClientFilteredMaterials(materials)

      let categoryName = currProduct?.categoryName || currProduct?.category_name

      materials = categoryFilteredTextures(materials,props.configObj?.data.objectMaterialTypes,categoryName)
       
      if(applicationConfig.clientName === "DemoClient2"){
        materials = materials.filter(currMaterial=> currMaterial.company_name !== "DDecor")
        if(categoryName === "Sofa"){
          materials = materials.filter(currMaterial=> currMaterial.company_name === "OVL" 
            || currMaterial.company_name === "Stanley" || currMaterial.company_name === "Kuka" 
            || currMaterial.material_type === "DemoFab" 
            || currMaterial.company_name === "HTL"
          )
        }
        if(categoryName === "Cot" || categoryName === "Dresser" || categoryName === "Wardrobe"){
          materials = materials.filter(currMaterial=> currMaterial.material_type === "Wood" || currMaterial.material_type === "Laminate" || currMaterial.material_type === "Fabric" || currMaterial.company_name === "Kuka" || currMaterial.company_name === "Tesa")
        }
        if(categoryName === "Chairs"){
          materials = props.configObj?.data.materials.filter(currMaterial=> currMaterial.material_type === "Mesh")
        }
      }

      if(getUser()?.isShareMode){
        let collectionNames = getUser()?.collectionNames
        if(collectionNames?.length){
          materials = materials.filter(currMaterial=> collectionNames.includes(currMaterial.collection_name))
        }
      }
      materials = getClientFilteredMaterials(materials)

      if(getSubDomainName() === "elevate"){
        materials = props.configObj?.data.materials.filter(currMaterial=> currMaterial.company_name === "OVL" && (currMaterial.collection_name === "Classic" || currMaterial.collection_name === "Cooper"))
      }

      STORE.data.materialsForCustomization = materials
      setAllProductMaterials(materials)
    }

    if(STORE.isKanvasMode){
      let collection = getItemFromLocalStorage("currCollection")
      if(collection){
        let companyName = collection.companyName
        let collectionName = collection.collectionName
        let collections = [collectionName]
        if(collection.collectionList){
          collections = collection.collectionList
        }
        if(getUser().isShareMode){
          materials = props.configObj?.data.materials.filter(currMaterial=> currMaterial.company_name === companyName && collections.includes(currMaterial.collection_name))
          STORE.data.materialsForCustomization = materials
          setAllProductMaterials(materials)
        }else{
          STORE.data.materialsForCustomization = materials
          setAllProductMaterials(materials)
        }
      }else{
        window.location.href = "/login"
      }
    }

    props.configObj.setFunctionRef("customizein3d",{setTextures:setAllProductMaterials})
  },[configuration])


  async function changeFinish(val:any) {
    const blob = new Blob([val]);
    let textureInfo = new TextureInfo({
      companyName:"Demo",
      collectionName:"Mesh",
      materialCode:"Uploaded_"+getRandomString(5),
      materialType:"Mesh"
    })
    let key = `thumbnails/OVL/${textureInfo.companyName}/${textureInfo.collectionName}/${textureInfo.materialCode}.png`
    let result = await saveUploadedFileInCache(key,blob)
    updateModuleFinish(textureInfo)
    showGlobalToast("Finish Applied",2000)
  }



  
 
  return (
    <div className="products-left-sidebar full-height-width position-relative display-flex-column no-wrap"
      style={{ justifyContent: "flex-start",paddingBottom:"0" }}>

      {allProductMaterials.length?
      <>
      {getUser()?.username?.includes("Shreedhar") || true?
        <Materials
          materials={allProductMaterials}
          action={updateTexture}
          moduleName="customizein3d"
        />
      :
        <MaterialsWithDropDown
          materials={allProductMaterials}
          action={updateTexture}
          moduleName="customizein3d"
        />
      }
        
      </>
         
      :
        <div className="middle heading4">Materials Not Available</div>
      }


      {STORE?.currProduct?.category_name === "Chairs"?
        <UploadImage
            setSelectedFile={changeFinish}
            imageElementId={"clientLogo"}
            isButton={true}
        />
      :
      null
      }
    </div>
  );
};

export default Finish;