import { IonIcon, IonPage } from "@ionic/react";
import { archive, briefcase, close, colorPalette } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import Actions from "../../common/ui-components/tabs/Actions"

const Home: React.FC<{
  configObj: any;
  setCurrState: (value: string) => void;
  closeModal: (value: boolean) => void;
}> = (props) => {


  const [actions] = useState([
    {
      name: "addProduct",
      label: "Add Product",
      icon:archive,
      iconClassNames:["color-info"],
      action: gotoProduct,
    },

    {
      name: "addFinish",
      label: "Add Finish",
      icon:colorPalette,
      iconClassNames:["color-danger"],
      action: gotoFinish,
    },
  ]);

  function gotoProduct() {
    props.setCurrState("Product")
  }

  function gotoFinish() {
    props.setCurrState("Finish")
  }

  function Header() {
    return (
      <div className="modal-header">
        <div className="center-aligned-row">
          <strong>INVENTORY</strong>
        </div>
        <span
          className="right-middle"
          onClick={() => {
            props.closeModal(false);
          }}>
          <IonIcon icon={close} ios={close} className="font-large"></IonIcon>
        </span>
      </div>
    );
  }

  function Body() {
    return (
      <div className="modal-body bg-color-ternary">
        <div className="full-height-width center-aligned-column">
          <Actions
            configObj={props.configObj}
            actionList={actions}
          />
        </div>
      </div>
    );
  }

  function Footer() {
    return <div className="modal-footer"></div>;
  }

  return (
    <React.Fragment>
      <Header />
      <Body />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
