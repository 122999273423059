import { IonAlert } from "@ionic/react";
import React, { useState } from "react";
import { showGlobalToast, toggleClassInElement } from "../../../../../../../../utils/UI_methods/global";
import { getObjectByParameter } from "../../../../../../../../utils/methods";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";

 
const MarkAsLostOption: React.FC<{
  refreshLeads:()=>void
  lead:any
  Content?:any
}> = (props) => {


  const[isOpen,setIsOpen] = useState(false)
  const[isOpenMarkAsVisited,setIsOpenMarkAsVisited] = useState(false)
  const[isOpenPlaced,setIsOpenPlaced] = useState(false)




  function updateSaledStage(salesStageId:number) {

    if(salesStageId){
      const leadDetails =  {
        salesStageId:salesStageId
      }
      CRM.crmApi.leads.updateLead(props.lead.lead_id,leadDetails).then((data:any)=>{
        if(!data.data.error){
            setIsOpen(false)
            props.refreshLeads()
            showGlobalToast("Marked")
        }else{
          showGlobalToast("Internal server error",2000,"error")
        }
      })
    }else{
      showGlobalToast("Sales stage id not found",2000,"error")
    }
    
  }
 
 
  return (
    <div className="position-relative">

        <IonAlert
          isOpen={isOpen}
          mode="ios"
          onDidDismiss={() => setIsOpen(false)}
          cssClass="my-custom-class"
          header={"Confirm!"}
          message={"Mark as lost lead?"}
          buttons={[{
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: "Confirm",
              handler: () => {
                updateSaledStage(getObjectByParameter(CRM.data.salesStages,"sales_stage","Loss").id);
              },
            },
          ]}
        />

        <IonAlert
          isOpen={isOpenMarkAsVisited}
          mode="ios"
          onDidDismiss={() => setIsOpenMarkAsVisited(false)}
          cssClass="my-custom-class"
          header={"Confirm!"}
          message={"Mark as visited?"}
          buttons={[{
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: "Confirm",
              handler: () => {
                updateSaledStage(getObjectByParameter(CRM.data.salesStages,"sales_stage","Visited").id);
              },
            },
          ]}
        />


        <IonAlert
          isOpen={isOpenPlaced}
          mode="ios"
          onDidDismiss={() => setIsOpenPlaced(false)}
          cssClass="my-custom-class"
          header={"Confirm!"}
          message={"Mark as placed order?"}
          buttons={[{
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: "Confirm",
              handler: () => {
                updateSaledStage(getObjectByParameter(CRM.data.salesStages,"sales_stage","Win").id);
              },
            },
          ]}
        />

        <div className="more-options-button" onClick={()=>{toggleClassInElement(`moreOptionsLead_${props.lead.lead_id}`,"--is-active")}}>
          {props.Content?
            <props.Content/>
          :
          <Button2
            configObj={{id:""}}
            action={()=>{}}
            label={""}
            fontAwesomeIcon={"fas fa-ellipsis-v color-warning"}
            iconPos="left"
            classNames={"no-shadow pointer-none button-small color-white bg-color- "}
          />
          }
          

        </div>

      
      <div id={`moreOptionsLead_${props.lead.lead_id}`} className="more-options-window white-card shadow-medium" >
        <ul className="container">
          <li onClick={()=>{setIsOpen(true)}}>Mark as lost</li>
          {props.lead?.outcome?.toLowerCase().includes("agreed") && !props.lead?.sales_stage?.toLowerCase().includes("visited")?
            <li onClick={()=>{setIsOpenMarkAsVisited(true)}}>Mark as visitied</li>
          :null}
          {props.lead?.outcome?.toLowerCase().includes("agreed") || props.lead?.sales_stage?.toLowerCase().includes("visited")?
            <li onClick={()=>{setIsOpenPlaced(true)}}>Mark as placed order</li>
          :null}
        </ul>
      </div>
  </div>
  );
};

export default MarkAsLostOption;
