import { IonModal } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { printSummary } from "../../../../../utils/customizein3d/area3dmodel/helper";
import { convert3DPointsToInches } from "../../../../../utils/customizein3d/floorplanner/helper";
import { IS_SPACE_PLANNER_MODE, configuration, dimensions, disableSpacePlanner } from "../../../../../utils/customizein3d/modules/customizein3d";
import { applicationConfig, getUser, waitFor } from "../../../../../utils/methods";
import { getFormattedPrice, getRandomNumber } from "../../../../../utils/products/productviewer_shopify";
import { addToCartHandle } from "../../../../../utils/store/product-cart";
import { MaterialAndPart, STORE, getCategoryPartsList } from "../../../../../utils/store/storeConfiguration";
import Loader from "../../../../common/shared-components/main-ui/Loader";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import StartCustomerSession from "../customer/StartCustomerSession";
import PricingDetails from "../quotation/PricingDetails";
import ProductConfigSummary from "../quotation/ProductConfigSummary";
import Quotation from "../quotation/Quotation";
import ShareLink from "../quotation/approval/ShareLink";
import { downloadCutlist } from "../../../../../utils/customizein3d/modules/wardrobe";
 

const Controls: React.FC<{
    closeHandle:()=>void
    isSummaryMode:boolean
    isOpen:boolean
    addToCartHandle:()=>void
    product:any
}> = (props) => {

  const[isOpenQuotation,setIsOpenQuotation] = useState(false)
  const[variantName,setVariantName] = useState(STORE.productConfiguration.variant.variantName)
  const[cartItem,setCartItem] = useState(null)
  const[product] = useState(props.product)
  const[isOpenStartSession,setIsOpenStartSession] = useState(false)

  useEffect(()=>{
    if(props.isOpen){
      reviewAction()
    }else{
      setCartItem(null)
    }
  },[props.isOpen])

      
  async function confirmAction() {
    if(!STORE.activeCustomer && false){
      setIsOpenStartSession(true)
      return
    }
    showComponentLoader("mainLoaderSpinner")
    let productConfiguration = STORE.productConfiguration
    productConfiguration.isCustomVariant = true
    // productConfiguration.updatePrice()
    productConfiguration.price = getRandomNumber(15000,48500)
    await updateVariantImage()
    await addToCartHandle(product,null)
    await waitFor(1000)
    hideComponentLoader("mainLoaderSpinner")
    props.addToCartHandle()
}


async function updateVariantImage() {
  const canvas = $("#mainCustomizein3DWrapper").find("canvas")
    canvas[0].toBlob(async (blob) => {
        const file = new File([blob], 'myImage.png', { type: 'image/png' });
        let fileName = `${variantName}.png`
        await uploadVariantImage(file,fileName)
    }, 'image/png');
}

async function uploadVariantImage(file:any,fileName:string) {
    let albumName = "public"
    var albumPhotosKey = encodeURIComponent(albumName) + "/estre/custom-variants/" + STORE.currProduct.category_name + "/" + STORE.currProduct.product_name + "/"   
    await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,file,fileName,true)
}

async function reviewAction() {
  setCartItem(null)
  showComponentLoader("mainLoaderSpinner")
  let productConfiguration = STORE.productConfiguration
  dimensions.showDimensions()
  await waitFor(200)
  dimensions.hideDimensions()
  productConfiguration.setCustomModulesList3D(configuration)
  setMaterialsMapping()
  await waitFor(500)
  // await waitFor(500)
  // await updateVariantImage()
  // await waitFor(500)
  let annotation1 = dimensions.annotations[0]
  let annotation2 = dimensions.annotations[1]
  let length = convert3DPointsToInches(annotation1.point1,annotation1.point2)
  let depth = convert3DPointsToInches(annotation2.point1,annotation2.point2)
  configuration.dimensions.length = Math.floor(length)
  configuration.dimensions.depth = Math.floor(depth)
  await waitFor(200)
  setCartItem({
    configuration:JSON.stringify(STORE.productConfiguration),
    product_id:STORE.currProduct.product_id
  })
  hideComponentLoader("mainLoaderSpinner")
}

function setMaterialsMapping() {
  try {
    let materialsMapping = STORE.productConfiguration.materials
    materialsMapping = []
    let config = configuration.modules[0].configuration
    // let config = configuration.modules.configuration.find((currConfig => currConfig.companyName !== "Default"))
    let materialsList = config.filter((currConfig => currConfig.companyName !== "Default"))
    let allSofaParts = getCategoryPartsList("Sofa")
    for (const currPart of allSofaParts) {
      let materialAndPart = new MaterialAndPart(materialsList[0])
      materialAndPart.addPart(currPart.partName)
      materialsMapping.push(materialAndPart)
    }
    STORE.productConfiguration.materials = materialsMapping
  } catch (error) {
    showGlobalToast(error,2000,"error")
  }
}


async function closeSummary() {
  showComponentLoader("changeFinishLoader")
  await waitFor(200)
  if(!IS_SPACE_PLANNER_MODE){
    disableSpacePlanner()
    await waitFor(200)
  }
  hideComponentLoader("changeFinishLoader")
  // topView.reset()
  // disableSpacePlanner()
  props.closeHandle()
}

 





  return (
      <>
      <StartCustomerSession isOpen={isOpenStartSession} setIsOpen={setIsOpenStartSession} createCustomerCallback={()=>{}}/>

      <IonModal
        isOpen = {isOpenQuotation}
        onDidDismiss = {()=>setIsOpenQuotation(false)}
        cssClass="medium-y-modal no-border"
        id=""
        >
        <Quotation cartItems={[cartItem]} confirmHandle={null} isShareMode={false}/>
      </IonModal>

     <div  className="full-height-width  bg-color-light">

        <div className="position-relative display-flex-row space-between padding-container" style={{height:"5rem"}}>
          <div className="heading1 middle font-large store-color-primary">
            Summary
          </div>
          <div className="right-middle padding10">
            <Button2
                  configObj={{id:""}}
                  action={closeSummary}
                  label={""}
                  fontAwesomeIcon={"fas fa-times color-black"}
                  iconPos="right"
                  classNames={"margin0 icon-button bg-color-warning-outline"}
            />
          </div>
        </div>


        <div className="overflow-y-scroll" style={{height:"calc(100% - 5rem"}}>


         


          <div className="summary-wrapper padding-container">

            <div  id="SummaryDetailsWrapperCustomize" className="summary-details-wrapper">
              {props.isOpen && cartItem && configuration?
                  <>
                    <div className="pricing-details white-card padding10 shadow-medium " style={{marginBottom:"10px"}}>
                      <PricingDetails configuration3D={configuration} cartItems={[cartItem]}/>
                    </div>
                    <ProductConfigSummary isShowModulesList={false} configuration={STORE.productConfiguration} product={product} configuration3D={configuration}/>
                  
                  </>
              :
              <Loader
                className="fade-background-loader --is-active"
                id="summaryLoader"
                data={{}}
                loaderType="spinner"
              />
              }
            </div>

            <div className="summary-action-wrapper">
              {props.isOpen && cartItem?
                <div className="full-width white-card shadow-medium marginTop10 padding10" style={{position:"sticky",top:"0"}}>
                  <div className="ion-margin-bottom">
                    <div className="font-large heading1 letter-spacing color-black marginBottom5">
                      Total Price
                      </div>
                    <div><span className="heading2 font-large marginRight5 rupee-sign">₹</span> <span className="heading1 summary-final-price letter-spacing rupee-sign" style={{fontSize:"3rem"}} id="summaryTotalPrice">{getFormattedPrice(configuration.pricing.finalPrice)}</span>  </div>
                  </div>

                  <div className="display-flex-row no-wrap full-width marginTop10">

                    {!STORE.isKanvasMode?
                    <ShareLink cartItems={null} Content={()=>(
                      <Button2
                        configObj={{id:""}}
                        action={()=>{}}
                        label={"Share For Approval"}
                        fontAwesomeIcon={"fas fa-share color-primary"}
                        iconPos="right"
                        classNames={"no-shadow text-center button-large cart-button  bg-color-warning-outline"}
                      />

                    )}/>
                    :null}

                   

                    

                    <Button2
                      configObj={{id:""}}
                      action={()=>{printSummary("SummaryDetailsWrapperCustomize")}}
                      label={"Download Pdf"}
                      fontAwesomeIcon={"fas fa-download color-white"}
                      iconPos="right"
                      classNames={"no-shado button-large cart-button bg-color-warning"}
                    />

                    

                  
                  

                  {/* <div className="" style={{width:"49%"}}>
                      <Button2
                          configObj={{id:""}}
                          action={()=>{setIsOpenQuotation(true)}}
                          label={"Share Quotation"}
                          fontAwesomeIcon={"fas fa-clipboard color-primary"}
                          iconPos="left"
                          classNames={"button-large full-width bg-color-primary-outline  center-aligned-row"}
                      />
                  </div> */}
                 
                  
                  </div>

                  {STORE?.getCurrCategory() === "Wardrobe"?
                    <Button2
                      configObj={{id:""}}
                      action={()=>{downloadCutlist()}}
                      label={"Download Cutlist"}
                      fontAwesomeIcon={"fas fa-download color-primary"}
                      iconPos="right"
                      classNames={"no-shado button-large marginTop5 cart-button bg-color-primary-outline"}
                    />
                  :null}

                  

                  {!getUser()?.isShareMode && applicationConfig.isMasterLogin() && !STORE.isKanvasMode?
                      <div className="marginleft5 marginTop10">
                      <Button2
                        configObj={{id:""}}
                        action={confirmAction}
                        label={"Add to cart"}
                        fontAwesomeIcon={"fas fa-cart-plus color-black"}
                        iconPos="left"
                        classNames={"margin0 button-large bg-color-primary width50 center-aligned-row"}
                      />
                      </div>
                    :
                      null
                    }
                  
                </div>
              :null}
            </div>


          
          </div>

          {applicationConfig?.clientName !== "TheLevelFurniture"?
          <div className="center-aligned-column padding10">
              <div className="full-width padding5 center-aligned-row no-wrap">
              <span className="heading4 font-normal center-aligned-column ">Powered by </span>
              <a className="font-normal marginleft5 center-aligned-column color-secondary" style={{textDecoration:"none"}}> Omniview Labs</a>
              </div>
          </div>
          :null}

          
          
        </div>
        <div className="footer-content display-none">
          {!props.isSummaryMode?
            <Button2
              configObj={{id:""}}
              action={confirmAction}
              label={"Add to cart"}
              fontAwesomeIcon={"fas fa-arrow-right color-white"}
              iconPos="right"
              classNames={"button-large store-bg-color-primary"}
          />
          :
          <Button2
              configObj={{id:""}}
              action={closeSummary}
              label={"Close"}
              fontAwesomeIcon={"fas fa-shopping-cart color-white"}
              iconPos=""
              classNames={"store-bg-color-primary"}
          />
          }
          
        </div>
      </div>



   
        
      </>
    );
};

export default Controls;
