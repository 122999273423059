import { IonModal } from '@ionic/react';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toggleActiveClass, toggleThemeSetting, toggleUserOption, updateCartCounter } from '../../../../utils/UI_methods/global';
import { tapEffectOnClick } from '../../../../utils/UI_methods/ui_effects';
import { getLoggedInUserName, getToken, getUserType, logoutHandle } from "../../../../utils/methods";
import Inventory from "../../../admin/inventory/Inventory";
import "../../../style/common/header.css";
import NotificationsList from '../../ui-components/notification/NotificationsList';
import Cart from '../Cart/Cart';


const Header: React.FC<{
  configObj:any
  componentMiddle:any
  componentLeft:any
}> = (props) => {

  const history = useHistory()

  const[isSideNav,setIsSideNav] = useState(false)
  const[isCartOpen,setIsCartOpen] = useState(false)
  const[isSettingsOpen,setIsSettingsOpen] = useState(false)
  const[isOpenInventory,setIsOpenInventory] = useState(false)

  const[username,setUserName] = useState("")
  const[cartCounter,setCartCounter] = useState(0)

  useEffect(()=>{

    let sideNav =  document.getElementById("mySidepanel")!
    if(sideNav){
      setIsSideNav(true)
    }


    if(getToken()){
      let userInfo = document.getElementById("userInfo")!
      userInfo?.addEventListener("mouseover",function(){
        $("#userOptions").css("opacity","1")
        $(".user-options").css("pointer-events","all")
      })
  
      userInfo?.addEventListener("mouseout",function(){
        $("#userOptions").css("opacity","0")
        $(".user-options").css("pointer-events","none")

      })

      let username = getLoggedInUserName()
      setUserName(username)
    }

    setTimeout(() => {
      updateCartCounter()
    }, 500);
   


    // let key = getCartKey()
    // let items = getItemFromLocalStorage(key)
    // if(items){
    //   setCartCounter(JSON.parse(items).length)
    // }

    // $("#userInfo").on("click",function(){
    //   $("#userOptions").css("opacity","1")
    // })

  },[])

  




  function changeFontSize(event:any,fontSize:string) {
    $(event.target).siblings("button").removeClass("op-btn-primary")
    $(event.target).siblings("button").addClass("op-btn-light")
    $(event.target).removeClass("op-btn-light")
    $(event.target).addClass("op-btn-primary")

    updateFontSize(fontSize)

  }

  function updateFontSize(fontSize:string) {
    document.documentElement.style.setProperty("--op-font-size",fontSize)
  }

  function resetFont() {
    updateFontSize("13px")
    $(".font-setting").find("button").removeClass("op-btn-primary")
    $(".font-setting").find("button").addClass("op-btn-light")
    $("#defaultFontButton").removeClass("op-btn-light")
    $("#defaultFontButton").addClass("op-btn-primary")
    
  }


  function Settings() {
    return(
      <div className="full-height-width">
      <div className="">
        <div className="display-flex-column ">
          <div className="ion-padding bg-color-light">Theme Options</div>
          <div className="ion-margin ion-padding border">
              <div className="ion-margin-bottom">Font Size</div>
              <div className="display-flex-row font-setting">
                <button className="op-btn op-btn-light" onClick={(event)=>changeFontSize(event,"12px")}>Small</button>
                <button id="defaultFontButton" className="op-btn op-btn-primary ion-margin-start" onClick={(event)=>changeFontSize(event,"13px")}>Normal</button>
                <button className="op-btn op-btn-light ion-margin-start" onClick={(event)=>changeFontSize(event,"14px")}>Medium</button>
                <button className="op-btn op-btn-light ion-margin-start" onClick={(event)=>changeFontSize(event,"15px")}>Large</button>
              </div>
          </div>
          <div className="ion-padding-start">
            <button className="op-btn op-btn-light" onClick={resetFont}>Reset Default</button>
          </div>
        </div>
      </div>

      <div className="toggle-button center-aligned-column" 
        onClick={()=>  $(".theme-setting-wrapper").toggleClass("--is-active")}>
        <div> <i className="fa fa-cog font-medium"></i></div>
          
         </div>

    </div>
    )
  }


 

  return (
      <div className="main-header-wrapper op-header-wrapper position-relative display-flex-row shadow-light no-wrap">
       
         <IonModal
          onDidDismiss={()=>setIsCartOpen(false)} 
          isOpen={isCartOpen}
          id=""
          cssClass='share-modal'
        >
           <Cart
            configObject={props.configObj}
            closeModal={()=>setIsCartOpen(false)}
          />
        </IonModal>
           

        <IonModal  onDidDismiss={() => setIsSettingsOpen(false)} isOpen={isSettingsOpen}
        id="setting"
        mode="ios"
        cssClass='project-action-modal'>
            <Settings/>
        </IonModal>

        <IonModal 
          onDidDismiss={()=>setIsOpenInventory(false)} 
          isOpen={isOpenInventory}
          backdropDismiss={false}
          id="inventoryModal"
          cssClass='project-action-modal'
        >
          <Inventory
          configObj={props.configObj}
          closeModal={setIsOpenInventory}
          currState="Home"
          product={null}
          finishId={0}
          />
        </IonModal>


        <div className="header-left-content">
          {props.componentLeft?<props.componentLeft/>
           :<div className="full-height padding10 company-logo">
              <img src="assets/images/opus.png" alt=""  className="full-height" />
          </div>}
        </div>
        {getToken()?
        <div className="header-middle-content">
           {props.componentMiddle?<props.componentMiddle/>
           :<div className="full-height padding10 company-logo">
              <img src="assets/images/opus.png" alt=""  className="full-height" />
          </div>}
        </div>
        :null
        }

        {getToken()?
        <div className="header-right-content position-relative">
           {/* {getUserType()?.toLowerCase()==="admin"?
            <div className="position-relative click-effect display-flex-row white-card shadow-light ion-margin-start" style={{padding:"5px 10px"}} onClick={()=>setIsOpenInventory(true)}>
            <i className="fas fa-plus color-primary center-aligned-column font-normal"></i><span className="hide-in-mobile marginleft5"> Inventory</span>  
            </div>
            :null} */}

            <div id=""  onPointerDown={tapEffectOnClick} onClick={()=>toggleActiveClass("#notificationList")} className="position-relative cart-icon click-effect white-card shadow-light center-aligned-column ion-margin-start" style={{width:"28px",height:"28px"}}>
              <i className="fas fa-bell color-primary font-normal"></i>
              <div className="badge notification-counter" id=""></div>
            </div>

            <div id="cartIcon"  onPointerDown={tapEffectOnClick}  className="position-relative cart-icon click-effect white-card shadow-light center-aligned-column" style={{width:"28px",height:"28px"}} onClick={()=>setIsCartOpen(true)}>
              
              <i className="fas fa-cart-plus color-primary font-normal"></i>
              <div className="badge cart-item-counter" id="cartItemCounterMain" style={{background:"var(--op-color-danger)"}}>{cartCounter}</div>
            </div>
   
            {/* <div className="position-relative click-effect white-card shadow-light center-aligned-column ion-margin-start" style={{width:"28px",height:"28px"}} 
            onClick={toggleThemeSetting}
            >
              <i className="fas fa-cog color-dark font-normal"></i>
            </div> */}


            {getUserType()?.toLowerCase() !== "customer"?
                <div onPointerDown={tapEffectOnClick} className="white-card click-effect position-relative  pointer position-relative user-options-link shadow-light center-aligned-column marginleft10" style={{width:"28px",height:"28px"}}
                onClick={toggleUserOption}
                >
                  <i className="fas color-primary fa-user pointer-none font-normal" area-hidden="true"/>
                </div>
            :null}

            {/* <div className="position-relative cart-icon click-effect white-card shadow-light center-aligned-column ion-margin-start" style={{width:"28px",height:"28px"}} onClick={fullScreenBrowserToggle}>
              <IonIcon className="color-primary font-normal" icon={expand} />
            </div> */}

        </div>
    :        <div style={{width:"100px"}}></div>
      }

      <div className='header-user-options white-card shadow-medium border' >
        <div className='option display-flex-row'   onClick={toggleThemeSetting}>
          <div><i className="fas fa-cog  color-primary font-normal"></i></div>
          <div className='marginleft10 heading1 font-small center-aligned-column'>Preference</div>
        </div>

        <div className='option border-top display-flex-row' onClick={()=>setIsOpenInventory(true)}>
          <div><i className="fas fa-plus  color-dark font-normal"></i></div>
          <div className='marginleft10 heading1 font-small center-aligned-column'>Inventory</div>
        </div>

        <div className='option border-top display-flex-row' onClick={logoutHandle}>
          <div><i className="fas fa-power-off  color-danger font-normal"></i></div>
          <div className='marginleft10 heading1 font-small center-aligned-column'>Sign out</div>
        </div>
        </div>

        <NotificationsList/>

  

      </div>
  );
};

export default Header;
