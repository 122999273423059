import React, { useState } from "react";
import { Customer } from "../../../../../../../../utils/store/storeConfiguration";
import Modal from "../../../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import CreateCustomer from "../../../../customer/CreateCustomer/CreateCustomer";
import SearchCustomer from "./Search";
 
const Header: React.FC<{
  refreshList:()=>void
  setSearchedCustomerList:(values:any)=>void
}> = (props) => {

  const[isOpen,setIsOpen] = useState(false)


  
  function LeftContent() {
    return(
      <div className="display-flex-row">

      <div className="center-aligned-column">
        <Button2
          configObj={{id:""}}
          action={()=>{setIsOpen(true)}}
          label={"Add Customer"}
          fontAwesomeIcon={"fas fa-plus color-dark"}
          iconPos="left"
          classNames={"shadow margin0 button- bg-color-primary"}
        />
      </div>

      {/* <div className="center-aligned-column marginleft10">
        <Button2
          configObj={{id:""}}
          action={()=>{}}
          label={"View by & Sort by"}
          fontAwesomeIcon={"fas fa-chevron-down color-dark"}
          iconPos="right"
          classNames={"no-shadow"}
        />
      </div>

      <div className="center-aligned-column marginleft5">
        <Button2
          configObj={{id:""}}
          action={()=>{}}
          label={"Sync"}
          fontAwesomeIcon={"fas fa-sync color-dark"}
          iconPos="left"
          classNames={"no-shadow"}
        />
      </div> */}
      </div>
    )
  }



  function createCustomerCallback() {
    setIsOpen(false)
    props.refreshList()
  }

  function setActiveCustomer(customer:Customer) {
    
  }



  return (
    <div className="full-width position-relative display-flex-row space-between border-bottom bg-color-light calendar-header" style={{padding:"10px 0",height:"fit-content"}}>


        <Modal
          id=""
          classNames="small-y-modal"
          onDismiss={()=>setIsOpen(false)}
          isOpen={isOpen}
          Content={()=>(
            <CreateCustomer isCreateTicketMode={false} createCustomerCallback={createCustomerCallback} isCustomerCheckInMode={true} isStartSessionMode={false} setActiveCustomer={setActiveCustomer}/>
          )}
          heading="Start Customer Session"
          FooterContent={()=>(<div></div>)}
          >
        </Modal>

        <LeftContent/>
        <div className="middle">
          {/* <ToggleView setIsShowCalendar={props.setIsShowCalendar}/> */}
        </div>

        <div className="display-flex-row hide-in-mobile">
          <SearchCustomer setSearchedCustomerList={props.setSearchedCustomerList}/>
        </div>

      </div>  
  );
};

export default Header;
