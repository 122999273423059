import $ from "jquery";
import React, { useState } from "react";
import { CotStorage, STORE } from "../../../../../../utils/store/storeConfiguration";
import { stringWithoutWhiteSpace } from "../../../../../../utils/methods";
 

const StorageSelector: React.FC<{
  onChange:(val:string)=>void

  storage:CotStorage
  product:any
  configuration:any
}> = (props) => {



  const[currConfig,setCurrConfig]:any =  useState({})
  const[options,setOptions]:any =  useState([])
  const[currSelectedOption,setCurrSelectedOption]:any =  useState(props.storage.type)



  function onChange(config:any,id:number) {
      setCurrConfig(config)
      props.storage.name = config.name
      if(config.options?.length){
        $(".more-options-container").fadeOut(0)
        $(`#moreOptionsContainerStorage`).fadeIn(200)
        setOptions(config.options)
        return
      }
      props.storage.type = ""
      // props.onChange(config.name)
  }


  function optionsSelectHandle(option:any) {
    // setCurrConfig(config)
    setCurrSelectedOption(option)
    // setCurrSelectedOption(option)
    // $(".curr-option-container").fadeOut(0)
    // $(`#currOptionContainer_${config.id}`).fadeIn(200)
    $(".more-options-container").fadeOut(0)
    // props.onChange(config.name)
  }


 

  function onChangeSide(event:any,config:any,option:string) {
    event.preventDefault()
    event.stopPropagation()
    optionsSelectHandle(option)
    // props.onChangeMoreOption(option)
    props.storage.type = option
    // props.storage.name = config.name
  }


  function getOptionsIconsUrl(option:string) {

    if(stringWithoutWhiteSpace(option).toLowerCase().includes("4boxes")){

    }
  }


  return (

    <div className="position-relative">

       

      <div id={`moreOptionsContainerStorage`} style={{display:"none",zIndex:100,background:"rgba(0,0,0,0.3)"}} className="middle full-height-width padding10 more-options-container  center-aligned-column">
          <div className="white-card position-relative" style={{width:"25rem",height:"14rem"}}>
            <div className="heading3  iddle padding5 text-center">Choose your variant <span><i className="fas fa-question-circle pointer color-dark"></i></span> </div>
            <div className="center-aligned-column">
            <div className="display-flex-row customization-options">
              {options.map((currOption:any,index:number)=>{
                let url = `${STORE.BASE_URL_ASSETS}icons/UpholsteredBed/storage/${currConfig.name}/${encodeURIComponent(stringWithoutWhiteSpace(currOption))}.svg`
                return(
                  <div key={index} onClick={(event)=>onChangeSide(event,{},currOption)} className="center-aligned-column item item-sm position-relative white-card shadow-light">
                    <div className="pointer white-card no-shadow">
                      <div className="padding5 center-aligned-row"><img className="icon" src={url} style={{width:"3.5rem",margin:"auto"}}/></div>
                      <div className="heading2  font-md-small pc-color-primary text text-center text-no-wrap" style={{marginTop:"-0.5rem"}}>{currOption}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          </div>
      </div>

      <div className="center-aligned-row customization-options ion-padding-bottom">
          
          {props.configuration.map((config:any,index:number)=>{
              let url = `${STORE.BASE_URL_ASSETS}icons/UpholsteredBed/storage/${stringWithoutWhiteSpace(config.name)}.svg`
              return(
                <div key={index} onClick={()=>onChange(config,config.id)} className={`center-aligned-column item position-relative white-card shadow-light ${currConfig?.name === config.name?" --is-active":""}`}>
                  <div className="pointer-none midde">
                    
                    <div className="padding5 center-aligned-row"><img className="icon" src={url} style={{width:"4rem",margin:"auto"}}/></div>
                    <div className="heading2  font-md-small pc-color-primary text text-center text-ellipsis ">{config.name}</div>
                    <div className="heading4 font-small text text-center ">{config.description}</div>

                  </div>
                  <div className="top-right">
                    {currConfig?.name === config.name?
                    <i className="far fa-check-circle select-icon font-medium"></i>
                    :
                    <i className="far fa-circle select-icon font-medium"></i>
                    }
                  </div>
                  <div className={`top-left padding5 heading1 font-small color-warning curr-option-container ${currConfig?.name === config.name?" ":"display-none"}`} id={`currOptionContainer_${config.id}`}>
                    {currConfig.options?.length?currSelectedOption:""}
                  </div>
                </div>
              )
        })}

      
        

      </div>

     
      
    </div>

    
        
  );
};

export default StorageSelector;
