import $ from "jquery"
import { showToast } from "../../UI_methods/global"
import { getObjectDimensionPosition } from "../modules/helper"
import { CURR_SELECTED_PRODUCT } from "../raycasting"
import { controls, customizerConfig, deselectProduct, enableKitchenPlannerMode, isKitchenPlannerMode, updateAreaCustomizer } from "./area3dModel"
import { hideOtherItems, showAllItems } from "./productConfigure"
import { hideWallsBoxHelpers } from "./walls"
import { closeControlsPanelAndHideProducts } from "./UI_methods"


export var isIsolationView = false
//Used on raycasting
export var CURR_ISOLATED_PRODUCT = null


export function enableIsolationView() {
    if(!customizerConfig.isProductsLoaded){
        showToast("Please wait until products are loading",4000,"error")
        return
    }
    isIsolationView = true
    hideOtherItems(CURR_SELECTED_PRODUCT)
    CURR_ISOLATED_PRODUCT = CURR_SELECTED_PRODUCT
    $(".hide-in-isolation-view").addClass("display-none")
    // $(".show-in-kitchen-planner").removeClass("display-none")
    setPosition()
    // deselectProduct()
    $("#disableIsolationViewButton").removeClass("display-none")
    if(isKitchenPlannerMode){
        hideWallsBoxHelpers()
    }
    // toggleVisibilityOfAreaProducts(false)
}

export async function setPosition() {
    let target =  getObjectDimensionPosition(null,CURR_SELECTED_PRODUCT).center
    let delta = 1
    // await controls.setLookAt(position.x + delta,position.y,position.z,position.x,position.y,position.z + delta,true)
    controls.setTarget(target.x,target.y,target.z,true)
    // await controls.setPosition(target.x + delta,target.y,target.z + delta,true)
    controls.fitToBox( CURR_SELECTED_PRODUCT, true, { paddingTop:1, paddingLeft:1, paddingBottom:1, paddingRight:1 },true )

}

export function disableIsolationView() {
    deselectProduct()
    closeControlsPanelAndHideProducts()
    isIsolationView = false
    $(".hide-in-isolation-view").removeClass("display-none")
    $("#disableIsolationViewButton").addClass("display-none")
    showAllItems()
    if(isKitchenPlannerMode){
        enableKitchenPlannerMode(false)
    }else{
        updateAreaCustomizer("all")
    }
    CURR_ISOLATED_PRODUCT = null
}