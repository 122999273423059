import $ from "jquery";
import React, { useEffect, useState } from "react";
import { getArrayOfDistinctValues, getFilteredArray, getObjectByParameter } from "../../../../../../utils/methods";
import { SofaConfiguration } from "../../../../../../utils/store/storeConfiguration";
import Footer from "./Footer";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
 

const Options: React.FC<{
  customizationOption:any
  productConfiguration:SofaConfiguration
  heading:string
  index:number
  setCurrSelectedWindowIndex:(id:number)=>void
  currSelectedWindowIndex:number
  customizingOptions:any
}> = (props) => {


  const[configuration] = useState(props.customizationOption.configuration)

  const[selectedOptionIds,setSelectedOptionsIds] = useState([])
  const[moreSelectedOptions,setMoreSelectedOptions] = useState([])


  const[currConfig,setCurrConfig]:any =  useState({})

  const[options,setOptions] =  useState([])

  const[currSelectedTypeOption,setCurrSelectedTypeOption] =  useState(null)
  const[typeOptions,setTypeOptions]:any =  useState([])

  function toggleSelection(config:any,option:any) {
    setCurrConfig(config)
    const id = config.id
    const configName = config.name
    option.currSelectedValue = configName
    if(config.options?.length){
      $(".more-options-container").fadeOut(0)
      $(`.more-options-container`).fadeIn(200)
      if(config.name.toLowerCase().includes("pillow")){
        let typeOptions = getArrayOfDistinctValues(config.options,"type") 
        setTypeOptions(typeOptions)
        setCurrSelectedTypeOption(typeOptions[0])
      }
      if(config.name.toLowerCase().includes("ottoman")){
        let typeOptions = ["With Storage","Without Storage"]
        setTypeOptions(["With Storage","Without Storage"])
        setCurrSelectedTypeOption(typeOptions[0])
        setOptions(config.options)
      }
      if(config.name.toLowerCase().includes("detachable")){
        setOptions(config.options)
      }
    }

    if((typeof props.productConfiguration[option.configParameterName]) === "string"){
      props.productConfiguration[option.configParameterName] = configName
    }else{
      props.productConfiguration[option.configParameterName].name = configName
    }

    if(option.configuration){
      option.selectedItem =  getObjectByParameter(option.configuration,"id",id) 
    }
    setSelectedOptionsIds(prevValue=>{
        return [id]
    })
  }


  useEffect(()=>{
    if(currConfig && currSelectedTypeOption){
      if(currConfig.name.toLowerCase().includes("pillow")){
        let allOptions = currConfig.options
        allOptions = getFilteredArray(allOptions,"type",currSelectedTypeOption)
        allOptions = getArrayOfDistinctValues(allOptions,"name")
        setOptions(allOptions)
      }
    }
  
  },[currSelectedTypeOption,currConfig])

  function onChangeMoreOption(event:any,option:string) {
    event.preventDefault()
    event.stopPropagation()

    setMoreSelectedOptions(prevValue=>{
      if(prevValue.includes(option)){
        return prevValue.filter(currValue => currValue !== option)
      }else{
        return [...prevValue,option]
      }
    })

    // $(".more-options-container").fadeOut(0)
  }


  function toggleSizeSelection(event:any,currType:string) {
    $(".switch-selection-container").find(".option").removeClass("--is-active")
    $(event.target).addClass("--is-active")
    currConfig.type = currType
    // setCurrSize(size)
    setCurrSelectedTypeOption(currType)
  }

  function closeMoreOptions() {
    $(".more-options-container").fadeOut(500)
  }

  
  return (
    <div className="ion-padding configuration-seletor" key={props.index} id={`ConfigurationSelector_${props.index}`} style={{display:`${props.index === props.currSelectedWindowIndex?"":"none"}`}}>
        
        
        
        <div id={`moreOptionsContainer`} style={{display:"none",zIndex:100,background:"rgba(0,0,0,0.3)"}} className="middle customization-options full-height-width padding10 more-options-container  center-aligned-column">
          
          
          
          <div className="white-card position-relative" style={{width:"70%",height:"22rem"}}>
            <div className="heading3 font-medium  iddle ion-padding text-center">Select Your Option</div>
            
            <div className="center-aligned-row padding5">
              <div className="switch-selection-container display-flex-row no-wrap">
                {typeOptions.map((currType:string,index:number)=>{
                  return(
                    <div key={index} onClick={(event)=>{toggleSizeSelection(event,currType)}} className={`capitalize option ${currSelectedTypeOption === currType?" --is-active":" "}`}>{currType}</div>
                  )
                })}
              </div>
            </div>
            
            <div className="center-aligned-row  no-wrap marginTop10">
              {options.map((currOption:any,index:number)=>{
                return(
                  <div key={index} onClick={(event)=>onChangeMoreOption(event,currOption)} className={`center-aligned-column item position-relative item-md white-card shadow-light ${moreSelectedOptions.includes(currOption)?" --is-active":""}`}>
                      <div className="pointer-none midde full-width">
                        <div className="padding5 center-aligned-row"><i className={`icon fas fa-couch font-large`}/></div>
                        <div className="heading2 font-md-small padding5 pc-color-primary text text-center">{currOption}</div>
                      </div>
                  </div>
                )
              })}
            </div>
            <div className="center-aligned-row padding10">
              <Button2
                configObj={{id:""}}
                action={closeMoreOptions}
                label={"Confirm"}
                fontAwesomeIcon={"fas fa-arrow-left color-black"}
                iconPos=""
                classNames={"no-shadow store-bg-color-primary"}
              />
            </div>
          </div>
        </div>
        
        
        <div className="heading1 t pc-color-primary header-content font-large capitalize text-center ion-padding">{props.heading}</div>
        <div className="display-flex-row ion-padding body-content customization-options advance-customization-options">
          {configuration.map((config:any,index:number)=>{
            return(
              <div key={index} onClick={()=>toggleSelection(config,props.customizationOption)} className={`center-aligned-column item position-relative item-md white-card shadow-light ${selectedOptionIds.includes(config.id)?" --is-active":""}`}>
                <div className="pointer-none midde full-width">
                  
                  {config.svg?
                  <div className="center-aligned-row">
                    <img src={config.svg} alt="" style={{width:"5rem"}} className="icon" />
                  </div>
                  :
                  <div className="padding5 center-aligned-row"><i className={`icon fas fa-couch font-large`}/></div>
                  }
                  <div className="heading2 font-md-small padding5 pc-color-primary text text-center">{config.name}</div>
                  <div className="heading4 font-small text text-center text-ellipsis-mobile ">{config.description}</div>

                </div>
                <div className="top-right">
                  {selectedOptionIds.includes(config.id)?
                  <i className="far fa-check-circle select-icon font-large"></i>
                  :
                  <i className="far fa-circle select-icon font-large"></i>
                  }
                </div>
              </div>
            )
          })}
         
        </div>
        <div className="padding10 center-aligned-column footer-content full-width">
          <Footer  selectedOptionIds={selectedOptionIds} customizingOptions={props.customizingOptions}  index={props.index} setCurrSelectedWindowIndex={props.setCurrSelectedWindowIndex}/>
        </div>
      </div>
  );
};

export default Options;
