import React, { useState } from "react";
import { debugScene } from "../../../../../../utils/customizein3d/modules/customizein3d";

const Configuration = ({}) => {

    
  return (
    <div className="full-height-width">
      <div className="finish-panel-content display-none">
        <h5>Base Color</h5>
        <div className="color-controls">
          <div className="color-control">
            <label style={{ color: "white" }}>Factor</label>
            <div className="color-box-container">
              <div className="color-box factor-color"></div>
              <button className="reset-button">
                <i className="fas fa-undo"></i>
              </button>
            </div>
          </div>
          <div className="color-control">
            <label style={{ color: "white" }}>Texture</label>
            <div className="color-box-container">
              <div className="color-box texture-color"></div>
              <button className="reset-button">
                <i className="fas fa-undo"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container1 white-card shadow-light padding10">

        <div className="panel">
          <div className="heading3">Roughness</div>
          <div className="marginTop5">
            <input
                type="number"
                min={0}
                max={1}
                defaultValue={debugScene.roughness}
                onChange={(event)=>{debugScene.roughness = Number(event.target.value)}}
                className="input"
            />
          </div>
        </div>

        <div className="panel marginTop10">
          <div className="heading3">sheen</div>
          <div className="marginTop5">
            <input
                max={1}
                type="number"
                defaultValue={debugScene.sheen}
                onChange={(event)=>{debugScene.sheen = Number(event.target.value)}}
                className="input"
            />
          </div>
        </div>


        <div className="panel marginTop10">
          <div className="heading3">uv Scale</div>
          <div className="marginTop5">
            <input
                max={1}
                type="number"
                defaultValue={debugScene.uvScale}
                onChange={(event)=>{debugScene.uvScale = Number(event.target.value)}}
                className="input"
            />
          </div>
        </div>


        <div className="panel marginTop10">
          <div className="heading3">ClearCoat</div>
          <div className="marginTop5">
            <input
                max={1}
                type="number"
                defaultValue={debugScene.clearcoat}
                onChange={(event)=>{debugScene.clearcoat = Number(event.target.value)}}
                className="input"
            />
          </div>
        </div>

        <div className="panel marginTop10">
          <div className="heading3">Transmission</div>
          <div className="marginTop5">
            <input
                max={1}
                type="number"
                defaultValue={debugScene.transmission}
                onChange={(event)=>{debugScene.transmission = Number(event.target.value)}}
                className="input"
            />
          </div>
        </div>
         
      </div>
    </div>
  );
};

export default Configuration;
