import React, { useEffect, useState } from "react";

import { useParams } from "react-router";
import { showComponentLoader } from "../../../utils/UI_methods/global";
import MainSideBarLayout from "../../templates/layouts/page/MainSideBarLayout";
import Filters from "./ProductFilters";
import HeaderLeftContent from "./HeaderLeftContent";
import ProductsLayout from "./ProductsLayout";
import { STORE } from "../../../utils/store/storeConfiguration";


const Products: React.FC<{
  setCurrProductDetails: (prodId: number) => void;
  applicationConfig:any
}> = (props) => {

  const parameters:any = useParams();


  const [productsList, setProductsList] = useState<any>([]);
  const [currSelectedCategory, setCurrSelectedCategory] = useState<string>("");
  const [currSelectedSubCategory, setCurrSelectedSubCategory] = useState<string>("All");
  const[isSearchMode,setIsSearchMode] = useState(false)


  useEffect(()=>{
    setCurrSelectedCategory(parameters.category)
    let subCategory = props.applicationConfig.filters?.products?.subCategoryName || "All"
    setCurrSelectedSubCategory(subCategory)
    let products = []
    if(subCategory.toLowerCase() === "all"){
      products = props.applicationConfig?.data?.productsList?.filter(currProduct => currProduct.category_name === parameters.category)
    }else{
      products = props.applicationConfig?.data?.productsList?.filter(currProduct => currProduct.category_name === parameters.category && currProduct.sub_category_name === subCategory)
    }
    setProductsList(products)
  },[parameters.category])

  useEffect(()=>{
    if(props.applicationConfig.shareItemId && props.applicationConfig?.data?.productsList?.length){
      let category = props.applicationConfig?.data?.productsList[0]?.category_name
      let subCategory = props.applicationConfig?.data?.productsList[0]?.sub_category_name
      setCurrSelectedCategory(category)
      setCurrSelectedSubCategory(subCategory)
      setProductsList(props.applicationConfig?.data?.productsList)
    }
  },[])

  // useEffect(()=>{
  //   if(currSelectedSubCategory.length){
  //     removeClassFromElement("sub-category","--is-active",true)
  //     addClassInElement("subCategory"+currSelectedSubCategory,"--is-active")
  //   }
  // },[currSelectedSubCategory])

  // useEffect(()=>{
  //   if(props.applicationConfig){
  //     props.applicationConfig.setFunctionRef("products",{setCurrSelectedSubCategory:setCurrSelectedSubCategory})
  //     props.applicationConfig.setFunctionRef("products",{setProductsList:setProductsList})
  //   }
  // },[props.applicationConfig])

  useEffect(()=>{
    if(props.applicationConfig.filters){
      props.applicationConfig.filters.products.setCategoryName(currSelectedCategory)
      props.applicationConfig.filters.products.setSubCategoryName(currSelectedSubCategory)
    }
  },[currSelectedCategory,currSelectedSubCategory,props.applicationConfig])

  // useEffect(()=>{
  //   if (props.applicationConfig) {
  //     let category = null
  //     category = getSavedFilterValue("products","currSelectedCategory")
  //     if(category){
  //       setCurrSelectedCategory(category)
  //       return
  //     }
  //   }
  // },[props.applicationConfig])
 
  function updateCategory(category:string) {
    showComponentLoader(null,"products-loader")
    setCurrSelectedCategory(category)
  }

  // function filterProductsWithCategory(categoryName:string) {
  //   updateModuleFilters("products","isSearchMode",false)
  //   props.updateCategory(categoryName)
  //   let category = getFilteredArray(applicationConfig?.data?.productsList,"category_name",categoryName)
  //   props.setProductsList(category)
  // }

 
  return (
    <React.Fragment>
      <MainSideBarLayout 
        configObj={props.applicationConfig}
        CardsContainer={()=>(
          <ProductsLayout
            configObj={props.applicationConfig}
            setCurrProductDetails={props.setCurrProductDetails}
            productsList={productsList}
            currSelectedCategory={currSelectedCategory}
            currSelectedSubCategory={currSelectedSubCategory}
            setCurrSelectedCategory={updateCategory}
            isSearchMode={isSearchMode}
            setIsSearchMode={setIsSearchMode}
            setProductsList={setProductsList}
            setCurrSelectedSubCategory={setCurrSelectedSubCategory}
          />
        )}
        HeaderLeftContent={null}
        SideFiltersContainer={()=>(
          <Filters
            setCurrSelectedSubCategory = {setCurrSelectedSubCategory}
            setProductsList = {setProductsList}
            currSelectedCategory={currSelectedCategory}
            currSelectedSubCategory={currSelectedSubCategory}
            productsList={productsList}
          />
          )}
        HeaderMiddleContent={HeaderLeftContent}
        contentWrapperId="mainProductsContentWrapper"
      />
    </React.Fragment>
  );
};

export default Products;
