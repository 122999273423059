import { IonPage } from "@ionic/react";
import React from "react";
import MainHeader from "../../common/MainHeader";
import SubHeader from "../../common/SubHeader";
import StockList from "./MaterailsList";
import "../../../style/inventory/inventory.css"


 
const EstreMaterials: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {

  
 
  return (
    <IonPage>
      {props.isLogin?
        <div className="full-height-width bg-color-white crm-wrapper">
        <MainHeader/>
        <SubHeader heading="Materials"/>

        <div className="display-flex-row no-wrap crm-main-content-wrapper padding-container">
          <StockList/>
        </div>

      </div>
      :null}
    </IonPage>
  );
};

export default EstreMaterials;
