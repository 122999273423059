import React, { useState } from "react";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import Footer from "./Footer";
import { Instruction, SofaConfiguration } from "../../../../../../utils/store/storeConfiguration";
import { addClassInElement, removeClassFromElement, showGlobalToast } from "../../../../../../utils/UI_methods/global";
import $ from "jquery"
import { getFormattedPrice } from "../../../../../../utils/products/productviewer_shopify";
import { logger } from "../../../../../../utils/methods";

const CustomInstructions: React.FC<{
  index:number
  setCurrSelectedWindowIndex:(val:number)=>void
  currSelectedWindowIndex:number
  productConfiguration:SofaConfiguration
  customizingOptions:any
}> = (props) => {

  const[instructions,setInstructions] = useState(props.productConfiguration.instructions)


 
  function addComment() {
      let instructionString:any = $("#instruction").val()  
      let price:any = $("#instructionPrice").val()  
      if(!instructionString){
        showGlobalToast("Please enter instruction",2000,"error")
        return
      }
      let instruction = new Instruction(instructionString)
      if(price){
        instruction.price = price
      }
      props.productConfiguration.instructions.push(instruction)
      setInstructions([...props.productConfiguration.instructions])
      $("#instruction").val("") 
      addClassInElement("addInstructionForm","display-none")
      showGlobalToast("Added")
      logger?.info("customizein3d","A-Ins")
  }

  function hideForm() {
    addClassInElement("addInstructionForm","display-none")
  }

  function removeInstrcution(id:number) {
    props.productConfiguration.instructions = props.productConfiguration.instructions.filter(currIns => currIns.id !== id)
    setInstructions([...props.productConfiguration.instructions])
  }


  return (
    <div className="full-height full-width display-flex-column no-wrap product-customizer-wrapper" key={props.index} id={`ConfigurationSelector_${props.index}`} style={{display:`${props.index === props.currSelectedWindowIndex?"":"none"}`}}>
        <div className="heading1 header-content pc-color-primary text-center padding10 display-none">Add Instructions</div>

        <div className="display-flex-column full-height-width no-wrap space-between" style={{flexDirection:"column-reverse"}}>
          <div className="white-card shadow-light full-height-width ion-padding position-relative">
            <div className="heading2 pc-color-primary marginBottom5">Instructions List</div>

            {instructions?.length?
              <>
              {instructions.map((instruction:Instruction,index:number)=>{
                return(
                  <div key={index} className="white-card position-relative no-shadow padding10 border display-flex-row marginTop10">
                    <span className="heading3 color-dark">{index+1}. </span>
                    <span className="marginleft10 heading4 font-normal  center-aligned-column color-black">{instruction.instruction}</span>
                    <div className="right-middle">
                      <div className="display-flex-row">
                        <span className="marginRight10">{getFormattedPrice(instruction.price)}</span>
                        <span className="" onClick={()=>removeInstrcution(instruction.id)}><i className="fas fa-trash-alt color-danger"></i></span>
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className="marginTop10 center-aligned-row">
                <Button2
                    configObj={{id:""}}
                    action={()=>{removeClassFromElement("addInstructionForm","display-none")}}
                    label={`Add Instruction`}
                    fontAwesomeIcon={"fas fa-plus color-dark"}
                    iconPos="left"
                    classNames={"no-shadow button-small text-center margin0 bg-color-light"}
                />
              </div>
              
              </>
            :
              <div className="middle heading4 mal">
                 <Button2
                    configObj={{id:""}}
                    action={()=>{removeClassFromElement("addInstructionForm","display-none")}}
                    label={`Add Instruction`}
                    fontAwesomeIcon={"fas fa-plus font-large store-color-primary"}
                    iconPos="left"
                    classNames={"no-shadow button-large text-center"}
                />
              </div>
            }

          <div id="addInstructionForm" className="white-card zindex100 top-left center-aligned-column display-none shadow-light full-height-width padding10">
            <div className="display-flex-column no-wrap full-width">
              <span className="label">Instruction</span>
              <textarea name="" id="instruction" className="input" style={{height:"8rem"}}></textarea>
              <span className="label marginTop10">Price (If any)</span>
              <input type="number" placeholder="" className="input " id="instructionPrice" />
              <div className="center-aligned-column ion-padding">
                <div className="display-flex-row no-wrap">
                  <Button2
                      configObj={{id:""}}
                      action={addComment}
                      label={`Add`}
                      fontAwesomeIcon={""}
                      iconPos=""
                      classNames={"no-shadow text-center pc-bg-color-primary"}
                  />
                  <Button2
                    configObj={{id:""}}
                    action={hideForm}
                    label={`Cancel`}
                    fontAwesomeIcon={""}
                    iconPos=""
                    classNames={"no-shadow text-center bg-color-light"}
                />
                </div>
                
              </div>
            </div>
          </div>


          </div>
          
        </div>
 

        

          
        {props.setCurrSelectedWindowIndex?
          <Footer selectedOptionIds={instructions} customizingOptions={props.customizingOptions} index={props.index} setCurrSelectedWindowIndex={props.setCurrSelectedWindowIndex}/>
        :null}

    </div>
  );
};

export default CustomInstructions;
