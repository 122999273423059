import React, { useEffect, useState } from "react";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getObjectByParameter, getRandomString } from "../../../../../utils/methods";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import { hideProductCart, showProductCheckout } from "../../../../../utils/store/product-cart";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ProductImage from "../../../../common/ui-components/elements/ProductImage";
import PriceBreakup from "../product-info/PriceBreakup";
import CartProductConfig from "../common/CartProductConfig";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";
import MaterialImage from "../../../../common/ui-components/elements/MaterialImage";

 

const ProductCart: React.FC<{
  cartItems:any
}> = (props) => {




  const[totalPrice,setTotalPrice] = useState(0)

  useEffect(()=>{
    let total = 0
    for (const item of props.cartItems) {
      total += item.price
    }
    setTotalPrice(total)
  },[props.cartItems])


  function checkoutHandle() {
    showProductCheckout()
    STORE.functionRefs.checkout.setIsCheckoutFromQuotation(false)
    STORE.functionRefs.checkout.setCartItems(props.cartItems)
  }
  

  function removeItem(id:number) {
    // STORE.cartItems.removeItem(id)
    let items = getItemFromLocalStorage("store_cart")
    items = items.filter(currItem => currItem.product_id !== id)
    addItemToLocalStorage("store_cart",items)
    applicationConfig.functions.store_MainHeader.refreshCartItems()
    return
    STORE.storeApi.cart.delete(id).then((data:any)=>{
      if(!data.error){
        showGlobalToast("Deleted")
        applicationConfig.functions.store_MainHeader.refreshCartItems()
      }
    })
  }

  function Header() {
    return(
      <div className="cart-header">

      <div className="display-flex-row space-between">
        <div className="header-heading font-large">Cart items</div>
        <div className="center-aligned-column" onClick={hideProductCart}><i className="fas fa-times font-large color-black"></i></div>
      </div>
{/* 
      <div className="display-flex-row space-between ion-margin-top table-heading">
        <div className="heading4 font-normal">Products</div>
        <div className="heading4 font-normal">Total</div>
      </div> */}

      </div>
    )
  }

  function Footer() {
    return(
      <div className="checkout-details full-width bottom-left">
        <div className="display-flex-row no-wrap space-between">
          <div className="heading1 font-medium">Subtotal</div>
          <div className="heading1 rupee-sign font-medium">{getFormattedPrice(totalPrice)}</div>
        </div>
        
        <div className="heading4 marginTop10">Taxes and shipping calculated at checkout</div>
        <div className="display-flex-row marginTop10">
          <Button2
            configObj={{id:""}}
            action={checkoutHandle}
            label={"Checkout"}
            fontAwesomeIcon={"fas fa-cube color-danger"}
            iconPos=""
            classNames={"no-shadow full-width text-center button-medium bg-color-black margin0"}
          />
        </div>
      </div>
    )
  }

  function Actions({item}) {
    return(
      <div className="actions display-flex-row bottom-right padding5">

          <Button2
            configObj={{id:""}}
            action={()=>{removeItem(item.product_id)}}
            label={""}
            fontAwesomeIcon={"fas fa-trash font-small color-danger"}
            iconPos="left"
            classNames={"no-shadow button-ex-small text-center margin0"}
          />

          
      </div>
    )
  }

 

  function CartItems() {
    return(
      <div  className="items-list  overflow-y-scroll full-width padding-bottom30"  style={{height:"100%",paddingBottom:"150px"}}>
        {props.cartItems.map((item:any,index:number)=>{
          const productDetails = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",item.product_id)
          return(
            <div className="display-flex-row white-card no-shadow border padding5 no-wrap position-relative cart-item space-between" key={index}>
              {productDetails || item.material?
              <>
               <div className="product-details-cart no-wrap display-flex-row">
                <div className="image center-aligned-column">
                  {item.material?
                  <MaterialImage material={item.material}/>
                  :
                  <ProductImage product={productDetails}/>
                  }
                  
                </div>
                <div className="other-details marginleft10">
                  <div className="heading2">
                    {item.material?
                    item.material.material_code
                    :
                    STORE.getProductName(productDetails)
                    }
                    
                    </div>
                  <div className="product-price display-flex-row heading1 marginTop5 font-normal rupee-sign">{getFormattedPrice(item.price)} <PriceBreakup/></div>
                  
                  <Actions item={item}/>
                </div>
              </div>
              {item.configuration?
                <CartProductConfig buttonClass="button-ex-small" product={productDetails} item={item}/>
              :null}
             </>
              :null}
            </div>
          )
        })}
        
      </div>
    )
  }
 
 
  return (
     <div className="top-right  full-height-width display-none product-cart-wrapper padding0">
      <div className="product-cart-container bg-color-white top-right border-left ">
        
        <Header/>
        {props.cartItems.length?
          <Footer/>
        :null}
        
        <div className="display-flex-column full-height hide-scrollbar">
          <CartItems/>
        </div>
      </div>
     </div>
  );
};

export default ProductCart;
