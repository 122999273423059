import React from "react";

const WardrobeControlsBlocker: React.FC<{
}> = (props) => {

  
 
  return (
    <div className="wardrobe-ui-blocker zindex100 fade-background-loader top-left full-height-width">
      <div className="heading4 color-black font-normal bg-color-white middle">No module selected</div>
    </div>
  );
};

export default WardrobeControlsBlocker;
