import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { applicationConfig, getUser, waitFor } from "../../../../../utils/methods";
import { closeSideMenu } from "../../../../../utils/store/UI_methods";

 

const SideMenu: React.FC<{
}> = (props) => {

  const history = useHistory()
  const[logoSrc,setLogoSrc] = useState("assets/images/opus.png")

  const[kfRoutes,setKfRoutes] = useState([
    {
      link:"/",
      label:"Home",
      iconClass:"fas fa-home"
    },
    {
      link:"/about",
      label:"About us",
      iconClass:"far fa-address-card"
    },
    {
      link:"/products",
      label:"Products",
      iconClass:"fas fa-list"
    },
    {
      link:"/contact",
      label:"Contact us",
      iconClass:"fas fa-list"
    }
  ])
  
  useEffect(()=>{
    if(applicationConfig?.user?.isAllowedWhitelabeling){
      let clientName = applicationConfig.clientName
      setLogoSrc(applicationConfig.aws_public_url + `assets/clientLogos/${clientName}.png`)
    }

    if(getUser().userType === "admin"){
      setKfRoutes([
        {
          link:"/",
          label:"Home",
          iconClass:"fas fa-home"
        },
        {
          link:"/about",
          label:"About us",
          iconClass:"far fa-address-card"
        },
        {
          link:"/products",
          label:"Products",
          iconClass:"fas fa-list"
        },
        {
          link:"/contact",
          label:"Contact us",
          iconClass:"fas fa-phone"
        },
        {
          link:"/admin",
          label:"Admin Dashboard",
          iconClass:"far fa-user"
        }
      ])
    }
  },[])

  

  const[primaryOptions] = useState([
    {
      name:"My account",
      iconClass:"far fa-user"
    },
  ])

  const[otherOptions] = useState([
    {
      name:"Track your order",
      iconClass:"fas fa-shipping-fast"
    },
    {
      name:"Need help",
      iconClass:"fas fa-question-circle"
    },
    {
      name:"Policies and more",
      iconClass:"far fa-address-card"
    }
  ])

  async function gotoCategories() {
    closeSideMenu()
    waitFor(200)
    history.push("/store/products")
  }

 

  function Header() {
    return(
      <div className="full-width">
        <div className="display-flex-row padding10 space-between full-width">
          <div className="display-flex-row no-wrap" onClick={gotoCategories}>
            {/* <div className="center-aligned-column padding5"><i className="fas fa-gift color-danger" style={{fontSize:"2.5rem"}}></i></div> */}
            <div className="marginleft5">
              <img src={logoSrc} height={40} alt="" />
            </div>
          </div>
          
          <div onClick={closeSideMenu} className="center-aligned-column  padding5"><i className="fas fa-times font-large pointer-none color-dark"></i></div>
        </div>
      </div>
    )
  }

  function Footer() {
    return(
      <div className="bottom-left full-width border-top position-relative" style={{height:"5rem"}}>
        <div>
          {applicationConfig?.clientName !== "TheLevelFurniture"?
            <div className="middle full-width padding5 center-aligned-row no-wrap">
              <span className="heading4 center-aligned-column ">Powered by </span>
              <a className="font-md-small marginleft5 center-aligned-column color-secondary" style={{textDecoration:"none"}}> Omniview Labs</a>
            </div>
          :null}
        </div>

        <div className="bottom-right" style={{bottom:"-10px"}}>
            <a href="https://omniviewlabs.com/">
            <img src="https://omniviewlabs.com/wp-content/uploads/2020/07/NewLogo_TopAligned-Custom-1.png" width={50} alt="" />
            </a>
          </div>

      </div>
    )
  }

  function CategoriesLink() {
    return(
      <div className="full-width border-bottom ion-margin-top">
      <div className="display-flex-row padding10 space-between full-width" onClick={gotoCategories}>
        <div className="display-flex-row no-wrap pointer-none">
          <div className="center-aligned-column padding10"><i className="fas fa-th-large color-dark font-large"></i></div>
          <div className="marginleft5">
            <div className="heading2 color-black">Browse All Categories</div>
            <div className="heading4 color-black">Sofas, Wardrobes, Cot's and more</div>
          </div>
        </div>
        <div className="center-aligned-column padding5"><i className="fas fa-chevron-right font-medium color-dark"></i></div>
      </div>
    </div>
    )
  }


  function clickHandle(route:string) {
      closeSideMenu()
      history.push(route)

  }

  
  function MainOptions() {
    return(
      <div className="full-width border-bottom ion-margin-top">
        
        {applicationConfig.clientName === "KairaFabrics"?
        <>
        {kfRoutes.map((item:any,index:number)=>{
          return(
            <div onClick={()=>{clickHandle(item.link)}} key={index} className="display-flex-row padding10 space-between full-width">
              <div className="display-flex-row no-wrap">
                <div className="center-aligned-column padding10"><i className={`${item.iconClass } color-warning font-medium`}></i></div>
                <div className="marginleft5 center-aligned-column">
                  <div className="heading2 color-black">{item.label}</div>
                </div>
              </div>
            </div>
          )
        })}
        </>
        :
        <>
        <div onClick={gotoCategories} className="display-flex-row padding10 space-between full-width">
            <div className="display-flex-row no-wrap pointer-none">
              <div className="center-aligned-column padding10"><i className={`fas fa-home color-warning font-medium`}></i></div>
              <div className="marginleft5 center-aligned-column">
                <div className="heading2 color-black">Home</div>
              </div>
            </div>
        </div>
        {primaryOptions.map((item:any,index:number)=>{
          return(
            <div key={index} className="display-flex-row padding10 space-between full-width">
              <div className="display-flex-row no-wrap">
                <div className="center-aligned-column padding10"><i className={`${item.iconClass } color-warning font-medium`}></i></div>
                <div className="marginleft5 center-aligned-column">
                  <div className="heading2 color-black">{item.name}</div>
                </div>
              </div>
            </div>
          )
        })}
        </>
        }
        
      
    </div>
    )
  }

    
  function OtherOptions() {
    return(
      <div className="full-width ion-margin-top">
      {otherOptions.map((item:any,index:number)=>{
        return(
          <div key={index} className="display-flex-row padding10 space-between full-width">
            <div className="display-flex-row no-wrap">
              <div className="center-aligned-column padding10"><i className={`${item.iconClass } color-warning font-medium`}></i></div>
              <div className="marginleft5 center-aligned-column">
                <div className="heading3 font-normal color-black">{item.name}</div>
              </div>
            </div>
            {/* <div className="center-aligned-column padding5"><i className="fas fa-chevron-right font-medium color-dark"></i></div> */}
          </div>
        )
      })}
    </div>
    )
  }
 
 
  return (
    <div className="full-height-width side-menu-wrapper bg-color-white" id="sideMenuWrapper">
       <Header/>
       {applicationConfig.clientName !== "KairaFabrics"?
       <CategoriesLink/>
       :null}
       <MainOptions/>
       <Footer/>
    </div>
  );
};

export default SideMenu;
