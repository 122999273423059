import $ from "jquery";
import React, { useState } from "react";
import { openCategories, toggleCategories } from "../../../../../../utils/moodboard/helper";
import { expandItemsPanel } from "../../../../../../utils/moodboard/UI_methods";
import { rippleEffectOnClick } from "../../../../../../utils/UI_methods/ui_effects";

const MobileTabs: React.FC<{
  configObj:any
  tabItems:Array<any>
}> = (props) => {

  const[currSelectedTab,setCurrSelectedTab] = useState("addModule")
  
  
  function updateTabValue(tabContentId: any, tabId: string,event:any) {

   
    
    expandItemsPanel()
    $(".tab-content").removeClass("--is-active");
    $("#" + tabContentId).addClass("--is-active");

    $(".controls-tab").removeClass("--is-active");
    $(event.target).addClass("--is-active");

    if(tabContentId === "productsContainer"){
      setTimeout(() => {
        openCategories()
      }, 200);
    }
  }
  
  return (
    <React.Fragment>
      <div className="controls-tab-container position-relative controls-tabs-mobile" style={{padding:"0"}}>
          {/* <span className="heading1 padding10 text-center color-danger border-bottom">
            Customize
          </span> */}
         
          {props.tabItems.map((item: any, index: number) => {
            let classNames = [];
            classNames.push("controls-tab border-bottom overflow-hidden position-relative");
            if (index === 0) {
              classNames.push("--is-active");
            }
            // if (item.tabName === "finishContainer" && props.textures) {
            //   classNames.push("--is-active");
            // }
            return (
              <div id={item.tabId}
                className={classNames.join(" ")}
                key={index}
                onPointerDown={rippleEffectOnClick}
                onClick={(event) => {
                  updateTabValue(item.tabName, item.tabId,event);
                }}>
                {/* <img
                  src={item.img}
                  alt=""
                  className="tab-icon-image"
                  width="40"
                  height="40"
                /> */}
                <div className="text pointer-none heading">{item.tabLabel}</div>
              </div>
            );
          })}
        
        </div>
    </React.Fragment>
  );
};

export default MobileTabs;
