import React, { useState } from "react";
import { getRandomNumber } from "../../../../utils/products/productviewer_shopify";
import { ProductConfiguration, STORE } from "../../../../utils/store/storeConfiguration";
import AccordionForProccessCss from "../../../common/ui-components/accordion/AccordionForProccessCss";
import Button2 from "../../../common/ui-components/buttons/Button2";
import MaterialSelector from "./MaterialSelector";
import VariantSelector from "./VariantSelector";
import { confirmProccessAccordion } from "../../../../utils/UI_methods/global";
import { applicationConfig } from "../../../../utils/methods";
 

const Details: React.FC<{
  product:any
  productConfiguration:ProductConfiguration
  allVariants:any
}> = (props) => {


  const[currMaterial,setCurrMaterial] = useState(null)

  const[features] = useState([
    {
      image:applicationConfig.aws_public_url+"assets/icon/svg/store/warranty.svg",
      label:"2 Year Warranty"
    },
    {
      image:applicationConfig.aws_public_url+"assets/icon/svg/store/delivery.svg",
      label:"Free Home Delivery"
    },
    {
      image:applicationConfig.aws_public_url+"assets/icon/svg/store/install.svg",
      label:"Free Installation"
    },
    {
      image:applicationConfig.aws_public_url+"assets/icon/svg/store/return.svg",
      label:"Easy Returns"
    }
  ])


  function Reviews() {
    return(
      <div className="display-flex-row no-wrap marginTop5">
        <div className="display-flex-row no-wrap">
          <i className="fas fa-star color-warning"></i>
          <i className="fas fa-star color-warning marginleft5"></i>
          <i className="fas fa-star color-warning marginleft5"></i>
          <i className="fas fa-star color-warning marginleft5"></i>
          <i className="far fa-star color-warning marginleft5"></i>
        </div>
        <div className="marginleft10 center-aligned-column heading3" style={{textDecoration:"underline"}}>{getRandomNumber(100,2540)} Reviews</div>
        <div className="marginleft10 center-aligned-column heading4 font-small" style={{textDecoration:"underline"}}>SKU {props.product.product_name}{getRandomNumber(100,2540)}</div>
      </div>
    )
  }

  
  function DeliveryDetails() {
    return(
      <div className="" style={{marginTop:"3rem"}}>
        <div className="heading2">
          IN-HOME DELIVERY
        </div>
        <div className="color-back marginTop5 heading4">In stock and ready for delivery to ZIP code Reviews</div>
        <div className="padding10">
          <ul>
            <li className="heading4">Placement in the room of your choice</li>
            <li className="heading4">Assembly & removal of packaging</li>
            <li className="heading4">Order multiple items, pay one flat rate delivery fee</li>
          </ul>
        </div>
      </div>
    )
  }

  function Features() {
    return(
      <div className="full-width" style={{marginTop:"3rem"}}>
        <div className="display-flex-row space-between">
          {features.map((currFeature:any,index:any)=>{
            return(
              <div className="ion-margin-end" key={index}>
                <div className="center-aligned-row">
                  <img width={60} src={currFeature.image} alt="" />
                </div>
                <div className="font-small marginTop10 text-center heading4">
                  {currFeature.label}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }


  function updateVariant(variant:any) {
      // let result = SofaVariantsConfiguration.find(currVariant => currVariant.name === currSelectedVariant.variantName)
      // if(variant){
      //   layouts.create(variant.configuration)
      // }
      // setCurrVariant(new Variant(variant.name))
  }
            
  return (
    <div className="padding5">
      <div className="text heading1 color-black font-large">{props.product.product_name}</div>
      <Reviews/>
      <div className="text heading1 rupee-sign marginTop5">₹ {getRandomNumber(10000,25487)}.00</div>

      <div className="accordion-wrapper ion-margin-top">

        <AccordionForProccessCss
            label="Choose your size"
            SubHeader={()=>(
              <div id="currVariant" className="heading2 font-md-small marginRight10 center-aligned-column color-black">{STORE.productConfiguration.variant.variantName || "No selection"}</div>
            )}
            Content={()=>(
              <VariantSelector product={props.product} allVariants={props.allVariants}/>
            )}
            index={1}
            Footer={()=>(
              <></>
            )}
        />

          <AccordionForProccessCss
            label="Fabric"
            SubHeader={()=>(
              <div id="currMaterial" className="heading2 font-md-small marginRight10 center-aligned-column color-black">{STORE.productConfiguration.texture?.materialCode || "No selection"}</div>
            )}
            Content={()=>(
              <MaterialSelector setCurrMaterial={setCurrMaterial} product={props.product}/>
            )}
            index={2}
            Footer={()=>(
              <></>
            )}
          />

      </div>

      <DeliveryDetails/>

      <Features/>

      <div className="full-width elevate-add-to-cart" style={{marginTop:"3rem"}}>
        <Button2
          configObj={{id:""}}
          action={()=>{}}
          label={"Add to cart"}
          fontAwesomeIcon={"color-danger"}
          iconPos="left"
          classNames={"shadow-light text-center margin0 full-width bg-color-warning"}
        />
      </div>



    </div>
  );
};

export default Details;
