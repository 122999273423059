import React, { useState } from "react";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import { STORE } from "../../../../../utils/store/storeConfiguration";

 

const ItemPricing: React.FC<{
  item:any
  product:any
  updateTotalPrice:()=>void
}> = (props) => {
 
  const[configuration] = useState(JSON.parse(props.item.configuration))

  const[price,setPrice] = useState(configuration?.price || props.item?.price)
  const[discount] = useState(0)
  const[material] = useState(props.item.material)
 

  function setItemDiscount(configuration:any,value:any) {
    configuration.discount = Number(value)
    setPrice(configuration.price-Number(value))
    STORE.cartItemsDiscount[props.item.id] = Number(value)
    props.updateTotalPrice()
  }

 
  return (
    <div className="config-details">

      <div className="heading1 font-medium color-black">
        {material?
          material.material_code
          :
          STORE.getProductName(props.product)
          }
        
      </div>
      <div className="heading3 marginTop5 color-primary" >{configuration?.quality}</div>

      <div className="price-details">
        <div className="display-flex-row space-between item">
          <span className="heading3">MRP</span>
          <span className="rupee-sign heading3 center-aligned-column">{getFormattedPrice(configuration?.price || props.item?.price)}</span>
        </div>
        <div className="display-flex-row space-between item">
          <span className="heading3 color-success">Discount</span>
          <div className="heading3 font-md-small rupee-sign"><input type="number" className="input" onChange={(event)=>{setItemDiscount(configuration,event.target.value)}} defaultValue={discount} style={{borderColor:"#eee",width:"7rem"}} /></div>
        </div>
        <div className="display-flex-row space-between item no-border">
          <span className="heading3">ITEM PRICE</span>
          <span className="rupee-sign heading3 center-aligned-column">{getFormattedPrice(price)}</span>
        </div>
      </div>
    </div>
  );
};

export default ItemPricing;
