import $ from "jquery"

export function showCanvasLoader(){
    $('.canvas-loader').fadeIn()
}

export function hideCanvasLoader(){
    $('.canvas-loader').fadeOut()
}


export function zoomInImages(scale:number){
    scale = scale + 0.1
    $(".customize-canvas-wrapper").addClass("--is-zoom-mode")
    $("#imageContainer").css("zoom",scale)
    $(".canvas").addClass("--is-zoom-mode")
    return scale
}

export function zoomOutImages(scale:number){
    scale = scale - 0.1
    $(".customize-canvas-wrapper").addClass("--is-zoom-mode")
    $("#imageContainer").css("zoom",scale)
    $(".canvas").addClass("--is-zoom-mode")
    return scale
}

export function resetImagesZoom() {
  $("#imageContainer").css("zoom",1)
}


export function showControlsOnCanvas(){
    
    $(".customize-canvas-wrapper").children(".customize-controls-wrapper").removeClass("--is-hidden")
    $(".customize-canvas-wrapper").children(".customize-controls-wrapper").find(".controls-footer").css("visibility","hidden")
    $(".customize-canvas-wrapper").children(".customize-controls-wrapper").find(".search-container").css("visibility","hidden")
  }

  
  export function hideControlsOnCanvas(){
    // $(".customize-controls-wrapper").addClass("--is-hidden")
    $(".customize-canvas-wrapper").children(".customize-controls-wrapper").addClass("--is-hidden")
    $(".customize-canvas-wrapper").children(".customize-controls-wrapper").find(".controls-footer").css("visibility","visible")
    $(".customize-canvas-wrapper").children(".customize-controls-wrapper").find(".search-container").css("visibility","visible")
  }



  export function setCanvasInitialStyle(){
    $('.customize-canvas-wrapper').removeClass("--is-zoom-mode")
    $('.canvas').removeClass("--is-zoom-mode")
    $('.temp-canvas').removeClass("--is-zoom-mode")
    $("#zoomReset").hide()
    // $('#zoomControls').removeClass("--is-zoom-mode")
  }


  export function makeContainerDraggable(){
    $("#zoomReset").show()
    const ele = document.getElementById('customizeCanvasWrapper')!;
    ele.addEventListener("mousedown",mouseDownHandler)
    // $('#zoomControls').addClass("--is-zoom-mode")
  }


  export function removePropertyDraggable(){
    const ele = document.getElementById('customizeCanvasWrapper')!;
    ele.removeEventListener("mousedown",mouseDownHandler)
  }

  let pos = { top: 0, left: 0, x: 0, y: 0 };

const mouseDownHandler = function(e:any) {
  const ele = document.getElementById('customizeCanvasWrapper')!;
    pos = {
        // The current scroll 
        left: ele.scrollLeft,
        top: ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
    };

    ele.addEventListener('mousemove', mouseMoveHandler);
    ele.addEventListener('mouseup', mouseUpHandler);
    ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';
};

const mouseMoveHandler = function(e:any) {
  const ele = document.getElementById('customizeCanvasWrapper')!;

    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
};

const mouseUpHandler = function() {
    const ele = document.getElementById('customizeCanvasWrapper')!;
    ele.removeEventListener("mousemove",mouseMoveHandler)
    // ele.style.cursor = 'grab';
    ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');
};





export function showCanvasControls(isZoomMode=false){
  let array = ['.canvas-area-container',
  // 'canvas-pricing-save-container',
  '.canvas-view-container',
  '.status-count-container',
  '.update-button-container',
  '.canvas-icon-bar',
  '.area-selector-mobile',
    "#pageTourLink",
    ".mobile-share-button",
    ".horizontal-scroll-wrapper",
    ".hr-scroll-icon-wrapper"
  ]
  if(!isZoomMode){
    array.push('#zoomControls')
  }
  if(isZoomMode){
    array.push("#customizerSaveButton")
  }
  array.forEach(element => {
    $(`${element}`).css({"opacity":"1","pointer-events":"all"})
  });
}

export function hideCanvasControls(isZoomMode=false){
  let array = ['.canvas-area-container',
    // 'canvas-pricing-save-container',
    '.canvas-view-container',
    '.status-count-container',
    '.area-selector-mobile',
    // 'update-button-container',
    '.canvas-icon-bar',
    "#pageTourLink",
    ".mobile-share-button",
    ".horizontal-scroll-wrapper",
    ".hr-scroll-icon-wrapper"
    ]
    if(!isZoomMode){
      array.push('#zoomControls')
    }
    if(isZoomMode){
      array.push("#customizerSaveButton")
    }
  array.forEach(element => {
    $(`${element}`).css({"opacity":"0","pointer-events":"none"})
    // $(`${element}`).css("opacity","0")
  });
}
// function clickSprite(objectCanvasMapping:any){
//   let key = `${props.currentConfigObject?.areaName}.${props.currentConfigObject?.viewName}`
//   let products = props.currentConfigObject?.areaViewProductList[key]
//   let selectedProduct = ""
//   let canvas = null
//   for (const product of products) {
//     if(product.category_name==="Walls" || product.category_name==="Floor"){
//       continue
//     }else{
//       selectedProduct = product.product_name
//       break
//     }
//   } 
//   let selectedElements = []
//   for (const objectKey in objectCanvasMapping) {
//     if(objectKey.includes(selectedProduct)){
//       canvas = objectCanvasMapping[objectKey]
//       selectedElements.push(objectKey)
//     }
    
//   }
//   setCurrentSelectedElements(selectedElements)
//   // props.openMaterialWindowFromCanvas(canvas)
//   setShowDeselect(true)
// }


