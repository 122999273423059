import React from "react";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import { showGlobalToast, whatsappAction } from "../../../../../utils/UI_methods/global";
 

const KairaContact: React.FC<{
}> = (props) => {


  

 
  return (
    <div className="configurator-link padding-container full-width" style={{margin:"5rem 0"}}>

    <div className="section position-relative padding-container">
      <div className="position-relative" style={{width:"100%",margin:"auto"}}>
      <iframe className="full-width no-border" height="400" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31306.074382748826!2d75.800832!3d11.242326!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba6599c1ce3f4c1%3A0x5b25cf64b86cbd96!2sKaira%20Fabrics%20%26%20Leather!5e0!3m2!1sen!2sin!4v1733387973529!5m2!1sen!2sin" loading="lazy"></iframe>
      </div>
    </div>

     <div className="section details padding-container">
        <div className="heading3 marginTop10">Contact us</div>
        <div className="heading1 font-ex-large ion-margin-top">Kaira Fabrics & Leather</div>
        <div className="display-flex-row no-wrap">
          <div className="icon">
            <i className="fas fa-map-marker-alt color-dark font-medium" style={{marginTop:"1.7rem"}}></i>
          </div>
          <div className="address marginleft10">
            <div className="heading4 color-dark ion-margin-top font-normal line-height-2" style={{textAlign:"justify"}}>
              Crescent Kings Spear,
              </div>
              <div className="heading4 color-dark font-normal line-height-2" style={{textAlign:"justify"}}>
              Hira arcade, Mini Bypass Rd, Mankave,
              </div>
              <div className="heading4 color-dark font-normal line-height-2" style={{textAlign:"justify"}}>
              Kozhikode, Kerala 673016
              </div>
              
          </div>
        </div>

        <div className="display-flex-row no-wrap marginTop10">
            <div className="icon">
              <i className="fas fa-phone-volume color-dark font-medium" style={{marginTop:"0.5rem"}}></i>
            </div>
            <div className="heading4 marginleft10 color-dark font-normal line-height-2" style={{textAlign:"justify"}}>
              858 99 25 111<br/>
              858 99 25 222<br/>
              858 99 25 333
            </div>
        </div>
        
        <div className="" style={{marginTop:"1.5rem"}}>
          <Button2
              configObj={{id:""}}
              action={()=>{whatsappAction("8589925111","")}}
              label={"Contact Us"}
              fontAwesomeIcon={"far fa-eye elevate-color-primary font-large"}
              iconPos=""
              classNames={"shadow-light button-medium margin0 text-center bg-color-primary"}
            />
        </div>
     </div>
  </div> 
  );
};

export default KairaContact;
