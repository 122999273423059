import { IonRange } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { applicationConfig } from "../../../../utils/methods";
import "../../../style/form/rangeslider.css";

const RangeSlider: React.FC<{
    id:string
    action:(val:any)=>void
    pointerUp:(event:any)=>void
    pointerDown:(event:any)=>void
}> = (props) => {

  const[defaultValue,setDefaultValue] = useState(0)


  useEffect(()=>{
    applicationConfig.setFunctionRef("rangeslider",{setDefaultValue:updateRangeSliderValue})

    $("#rangeSliderValue").keyup(function(event) {
      if (event.keyCode === 13) {
          props.action(event)
      }
    });
  },[])

  function updateRangeSliderValue(number:number) {
    setDefaultValue(number)
  }
 
  return (
    <div className="range-slider-container ion-margin-bottom display-flex-row">
        <div className="center-aligned-column heading2 color-danger hide-in-mobile text-center" style={{margin:"4px 20px 0 0"}}>Rotate by</div>
        <IonRange id={props.id} onPointerDown={props.pointerDown} onPointerUp={props.pointerUp} onIonChange={props.action}  mode={"ios"} min={-180} max={180} value={defaultValue}></IonRange>
        <div className="range-slider-pin center-aligned-column hide-in-mobile position-relative"><span id="rangeSliderValue" className="range-slider-value-input heading2">{defaultValue}</span><span className="top-right padding0" style={{top:"9px",right:"-6px",fontSize:"0.5rem"}}>o</span> </div>
    </div>
  );
};
            
export default RangeSlider;
