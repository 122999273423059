import $ from "jquery";
import React, { useEffect, useState } from "react";
import { displayThumbnails, dropProductImage, searchTexture, showComponentLoader } from "../../../../utils/UI_methods/global";
import { createBounceEffectEvents } from "../../../../utils/UI_methods/ui_effects";
import { untarAndSaveToCache } from "../../../../utils/cache/cache";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getMaterialsImageUrl, logger } from "../../../../utils/methods";
import { addEventListenerOnImages } from "../../../../utils/moodboard/helper";
import { dragStart } from "../../../../utils/moodboard/moodboard";
import DropDown from "../../ui-components/filters/DropDown";
import Search from "../../ui-components/filters/Search";
import Loader from "../main-ui/Loader";
  
  const CollectionMaterials: React.FC<{
    configObj:any
    materials:any
    moduleName:string
  }> = (props) => {
  
  
    const[textureLoaderId] = useState("texturesLoader")
  
    const[materialTypesMaterials,setMaterialTypesMaterials]:any = useState([])
    const[materials,setMaterials]:any = useState([])
    const[collections,setCollections]:any = useState([])
    const[companies,setCompanies]:any = useState([])
    const[currSelectedMaterialType,setCurrSelectedMaterialType] = useState<string>("")
    const[currSelectedCompany,setCurrSelectedCompany] = useState<string>("")
    const[currSelectedCollection,setCurrCollectionName] = useState<string>("All")
  
   
    useEffect(()=>{
      if(props.materials){
        let allMaterialTypes:any = getArrayOfDistinctValues(props.materials,"material_type")
        setCurrSelectedMaterialType(allMaterialTypes[0])
      }
  
    },[props.materials])

    useEffect(()=>{
      if(materials.length){
        setTimeout(() => {
          addEventListenerOnImages(".image-drag-icon")
        }, 500);
      }
    },[materials])

 

  
    //Untar file for all collections
    useEffect(()=>{
      if(currSelectedMaterialType!=""){
        showComponentLoader(textureLoaderId)
        let filteredMaterials = getFilteredArray(props.materials,"material_type",currSelectedMaterialType)
        setMaterialTypesMaterials(filteredMaterials)
    
        let companyNames:Array<any> = getArrayOfDistinctValues(filteredMaterials,"company_name")
        setCompanies(companyNames)
        setCurrSelectedCompany(companyNames[0])
  
        removeActiveClassFromAll()
      }
    },[currSelectedMaterialType])


  
    useEffect(()=>{
      let filteredArray = getFilteredArray(materialTypesMaterials,"company_name",currSelectedCompany)
      let allCollections = getArrayOfDistinctValues(filteredArray,"collection_name")
      setCollections(allCollections)
      let collectionName:any = allCollections[0] || "All"
      setCurrCollectionName("All")
    },[currSelectedCompany,materialTypesMaterials])

    useEffect(()=>{
      if(currSelectedCompany != "" && currSelectedCollection!= ""){
        loadAndDisplayThumbails(currSelectedCompany,currSelectedCollection)
        let materialsList = []
        if(currSelectedCollection.toLocaleLowerCase() === "all"){
          materialsList = materialTypesMaterials.filter(material=> material.company_name === currSelectedCompany)
        }else{
          materialsList = materialTypesMaterials.filter(material=> material.company_name === currSelectedCompany && material.collection_name === currSelectedCollection)
        }
        setMaterials(materialsList)
      }
    },[currSelectedCompany,currSelectedCollection,materialTypesMaterials])



    async function loadAndDisplayThumbails(companyName:string,collectionName:string){
      showComponentLoader(textureLoaderId)
      let key = applicationConfig.awsConfig.getTarFileKey("thumbnails",{companyName:companyName,collectionName:collectionName})
      
      await untarAndSaveToCache(applicationConfig.awsConfig,`thumbnails/OVL/${companyName}/${collectionName}`,key)?.then(data=>{
        displayThumbnails(textureLoaderId,null)
      }).catch(err=>{
        displayThumbnails(textureLoaderId,null)
      })
    }
  
  
  
    function filterMaterialsWithMaterialType(value:string){
      setCurrSelectedMaterialType(value)
      logger?.info("editcollection","filterfinish",`Filter finish with material type: ${value}`)

    }
 
  
    function filterMaterialsWithCollection(value:string){
      setCurrCollectionName(value)
      logger?.info("editcollection","filterfinish",`Filter finish with collction: ${value}`)
    }
  
    function filterMaterialsWithCompany(value:string){
      setCurrSelectedCompany(value)
      logger?.info("editcollection","filterfinish",`Filter finish with company: ${value}`)
    }
  
  
      function removeActiveClassFromAll(){
        let textures = document.querySelectorAll('.material-container')
        textures.forEach(element => {
            $(element).find('.checkmark').remove()
        });
        $(".texture-label-container").removeClass("--is-active")
      }
   
    return (
      <React.Fragment>
  
      <div className="materials-filters-wrapper">

      <Loader className="fade-background-loader"
        id={textureLoaderId}
        data={{}}
        loaderType="progressCompact"
      />
  
        <div className="full-width-drop-down" style={{margin:"0.5rem 0"}}>
            <DropDown
                classNames=""
                columns={1}
                values={getArrayOfDistinctValues(props.materials,"material_type")}
                currSelectedValue={currSelectedMaterialType}
                action={filterMaterialsWithMaterialType}
                label="MaterialType"
                />
        </div>
        <div className="product-material-dropdown-wrapper no-wrap display-flex-row space-between" style={{margin:"0.7rem 0"}}>
              <DropDown
                classNames="width50"
                columns={1}
                values={companies}
                currSelectedValue={currSelectedCompany}
                action={filterMaterialsWithCompany}
                label="Company"
                />
                <DropDown
                classNames="width50"
                columns={1}
                values={["All",...collections]}
                currSelectedValue={currSelectedCollection}
                action={filterMaterialsWithCollection}
                label="Collections"
                />
          </div>
  
          <Search classNames="hide-in-mobile" placeholder="Search texture"
            action={searchTexture}
          />
   
        </div>
  
        <div className="materials-wrapper overflow-y-scroll display-flex-row ion-padding-bottom" onLoad={(e)=>createBounceEffectEvents(e)}>
  
          {materials?.map((material: any, index: number) => {
            let materialUrl = getMaterialsImageUrl(props.configObj?.BASE_URL_MODEL_VIEWER,material.company_name,material.collection_name,material.material_code)
            return (
              <div key={index} className="finish-image-container position-relative texture-label-container" 
              data-company-name={material.company_name}
              data-collection-name={material.collection_name}
              data-material-code={material.material_code}
              style={{aspectRatio:"1",padding:"3px"}}
              
              >
              <img
                data-product-name={material.material_code}
                data-product-category={material.company_name}
                data-product-subcategory={material.collection_name}
                data-product-price={0}
                data-product-id={material.id}
                data-image-type="finish"
                // src={materialUrl}
                id={materialUrl}
                onDragStart={dragStart}
                draggable="true"
                className="border finish-image"
                key={index}
                alt=""
                onClick={(event:any)=>dropProductImage(event,props.moduleName)}
              />
                <div className="top-right padding10 zindex30 hide-in-desktop">
                  <div className="image-drag-icon">
                    <i className="fas fa-grip-vertical color-dark"></i>
                  </div>
                </div>

            </div>
            );
          })}
        </div>
  
      </React.Fragment>
    );
  };
  
  export default CollectionMaterials;
  