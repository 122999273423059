import { BoxHelper } from "three"
import { showGlobalToast } from "../../UI_methods/global"
import { hideColorSwatch, showColorSwatch } from "./UI_methods"
import $ from "jquery"
import { scene } from "./area3dModel"
import { disableSelectWalls } from "./floorplan/floorplanUI"


var boxHelpers = []


export class WallColorCustomization{

    walls = []
    isMultipleSelect = false
    enabled = false


    constructor(){

    }

    addWall(wall:any){
       let isExists = this.walls.find(currWall => currWall.uuid === wall.uuid)
       if(isExists){
            this.walls = this.walls.filter(currWall => currWall.uuid !== wall.uuid)
       }else{
            this.walls.push(wall)
       }
       this.highlightSelectedWalls()
    }


    enable(){
        this.enabled = true
        $(".hide-in-elevation-view").addClass("display-none")
        $(".show-in-wall-color-mode").removeClass("display-none")
        showColorSwatch()
    }

    disable(){
        $(".hide-in-elevation-view").removeClass("display-none")
        $(".show-in-wall-color-mode").addClass("display-none")
        this.enabled = false
        this.walls = []
        hideColorSwatch()
        this.removeAllBoxHelpers()
    }

    reset(){
        this.removeAllBoxHelpers()
        disableSelectWalls()
    }


    highlightSelectedWalls(){
        for (const wall of this.walls) {
            // wall.material.color.setHex(color)
            let boxHelper = new BoxHelper( wall, 0x6c757d)
            scene.add(boxHelper)
            boxHelpers.push(boxHelper)
        }  
    }

    removeAllBoxHelpers(){
        for (const box of boxHelpers) {
            scene.remove(box)
        }
        boxHelpers = []
    }


    updateColor(data:any) {
        if(this.walls.length){
            let color = `0x${data.hex_code}`
            for (const wall of this.walls) {
                wall.material.color.setHex(color)
            }
        }else{
            showGlobalToast("Please select a wall",2000,"error")
        }
    }


    



}