import React from "react";
import Select from "../../../../../../common/ui-components/input/Select";
import VisitorsCard from "./VisitorsCard";
 

 
const VisitiorsListContainer: React.FC<{
  filteredVisitors:any
  currSelectedTime:string
  filterVisitorsWithTime:(time:string)=>void
}> = (props) => {



  function onChangeTime(value:string) {
    props.filterVisitorsWithTime(value)
  }



  
  return (
    <div className="full-height-width position-relative display-flex-column no-wrap">
      <div className="leads-list-container full-height-width position-relative">
          <div className="header-container display-flex-row space-between">
            <div className="date-filter">
                <Select
                  id=""
                  label=""
                  isMandatory={false}
                  onChange={onChangeTime}
                  options={["Today","Week","Month"]}
                  className=""
                  value={props.currSelectedTime}
                />
            </div>
          </div>

        {props.filteredVisitors?.length?
          <div className="container overflow-y-scroll"  style={{paddingBottom:"120px"}}>
            {props.filteredVisitors?.map((visitor:any,index:number)=>{
              return(
                <div className="full-width" key={index}>
                  <VisitorsCard
                    visitor={visitor}
                    index={index}
                  />
                  </div>
                )
              })}
          </div>
        :
        <div className="middle" >
          <div className="heading4 font-normal white-card no-shadow" style={{border:"1px dashed var(--op-color-dark)",padding:"25px 35px"}}>No visitors found</div>
        </div>
        }
      </div>
      
      
    </div>
  );
};

export default VisitiorsListContainer;
