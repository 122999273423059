import { IonIcon, IonPage, IonContent } from "@ionic/react";
import React, { useEffect, useState } from "react";

const Actions: React.FC<{
  configObj: any;
  actionList: Array<any>;
}> = (props) => {



  return (
    <div className="project-actions no-wrap position-relative display-flex-row">
    {props.actionList?.map((action:any,index:number)=>{
      let classNames = ["white-card" ,"shadow-light","project-action","background-white","click-effect"]
      let classNameIcon = ["icon"]
      if(action.name.includes("customizeFinish")){
        classNames.push("coming-soon")
      }
      classNameIcon.push(action.iconClassNames.join(" "))
      return(
        <div className={classNames.join(" ")} key={index} onClick={action.action}>
          <IonIcon
            className={classNameIcon.join(" ")}
            icon={action.icon}
            ios={action.icon}></IonIcon>
          <div className="text color-black heading2">{action.label}</div>   
        </div>
      )
    })}
  </div>
  );
};

export default Actions;
