import { IonIcon, IonModal } from "@ionic/react";
import { add } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { getUserType } from "../../../../utils/methods";
import { createBounceEffectEvents } from "../../../../utils/UI_methods/ui_effects";
import Modal2 from "../../../common/shared-components/Modals/Modal2"
import Button2 from "../../../common/ui-components/buttons/Button2";

const FilterCardsLayout: React.FC<{
  configObj:any
  MainContent: any;
  AddCardForm:any
}> = (props) => {
  //Function to remove project after deleting
  const [addNewCard, setaddNewCard] = useState(false);

  useEffect(()=>{
    props.configObj.functions.setaddNewCard = setaddNewCard
  },[props.configObj])

  return (
      <div className="main-inner-content-wrapper overflow-y-scroll  display-flex-row ion-padding"
        style={{ paddingTop: "0" }} onLoad={(e)=>createBounceEffectEvents(e)}>
        
        <Modal2
          isOpen={addNewCard}
          classNames="form-action-modal"
          onDismiss={setaddNewCard}
          id=""
          Content={()=>(
            <props.AddCardForm/>
          )}
        />

        {(getUserType()?.toLowerCase()==="admin" || getUserType()?.toLowerCase()==="user" || getUserType()?.toLowerCase()==="manager")  && props.AddCardForm?
           <div className="bottom-right add-dimension-icon-container">
           <div className="bg-color-primary add-dimension-icon center-aligned-column shadow-medium" onClick={()=>setaddNewCard(true)}>
             <IonIcon icon={add} className="font-large color-white" />
           </div>
          </div>
        :null}
        <props.MainContent />

    </div>
  )
}

export default FilterCardsLayout;
