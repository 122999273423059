import $ from "jquery";
import { Box3, BoxGeometry, BoxHelper, DoubleSide, Group, Mesh, MeshBasicMaterial, MeshStandardMaterial, Quaternion, Raycaster, Shape, ShapeGeometry, Vector2, Vector3 } from "three";
import { uploadShellForRendering } from "../../../services/api";
import { getGltfFromCamera } from "../../customizer/customizer";
import { isTouchDevice } from "../../customizer/helper";
import { applicationConfig, getRandomString, waitFor } from "../../methods";
import { addFloor, projectConfiguration, updateCameraControls, virtaulPlanesGroup } from "../area3dmodel/area3dModel";
import { addProductToRotationGroup, removeProductFromRotationGroup } from "../area3dmodel/helper";
import { detachModule } from "../common";
import { showFloorDimensions } from "../modules/UI_methods";
import { updateFloorDimensionsPosition } from "../modules/annotations";
import { getObjectDimensionPosition } from "../modules/helper";
import { annotationsInit, removeWallDimensions, showWallDimensionsDropDown, updateAnnotationsPosition, wallDimensions } from "./annotations";
import { addDrawWallsElements, currWallDimensionsForScale, disableDrawWalls, drawWallsInit, get3DPoint, updateWalls } from "./drawwalls";
import { createFrontAndBackPlane, heperInit } from "./helper";
import { currSelectedFloorplanObject, dblclick, pointerdown, pointermove, pointerup, raycastingInit, removecurrSelectedFloorplanObject, setcurrSelectedFloorplanObject, touchstart } from "./raycasting";
import { generatedEnvironmentMap } from "../enviornment";


export var vertexColor:number = 0xDCDCDC
export let vertexMaterial = new MeshStandardMaterial({ color: vertexColor });  
export var wallColor:number = 0x808080
vertexColor = wallColor
export var wallHoverColor:number = 0x0083b7
export var wallMaterial = new MeshStandardMaterial({ color: wallColor,side: DoubleSide })
export var wallHoverMaterial = new MeshStandardMaterial({ color: wallHoverColor })

var axis = new Vector3(0,1,0)


var groupForRotation = new Group()

export var wallHeight = 3
export var wallThickness = isTouchDevice()? 0.12 : 0.07


export class FloorplanConfiguration{
    vertices:Array<Vector3> = []
    constructor(){
    }
}

export class Floorplan {
    group = new Group()

    configuration:FloorplanConfiguration = new FloorplanConfiguration()


    width:number
    height:number

    vertices:Array<Vertex> = []
    walls:Array<Wall> = []
 
   
    floorColor:number = 0xDCDCDC
    floorMaterial = new MeshBasicMaterial({ color: this.floorColor ,side: DoubleSide});  

    floor:any = null
    scene:any = null
    sceneGroup:any = null

    renderer:any = null
    camera:any = null
    controls:any = null
    raycaster:Raycaster = null
    element:any = null
    // walls:any = []

    currShape:string = ""

    maskForFloorplan = 3

    isDrawWallsMode:boolean = false
    hoverBox:Mesh = null
    box3:Box3 = null

    isRoomDefined:boolean = false

    dimensionUnit = "feet"

    isTracingFloorplanMode:boolean = false
    isSetScaleMode:boolean = false
    traceScale = 1

    isSpacePlannerMode:boolean = false

    minDistanceForCreateWall = 0.001

    wallForTraceScale:Mesh = null
    moduleName:string

    isCreatedByShape:boolean = false
    isConfigChanged:boolean = false

  
    constructor(scene:any,sceneGroup:Group,renderer:any,camera:any,controls:any,element:any,
        raycaster:Raycaster,width:number,height:number,moduleName:string){
        this.width = width
        this.height = height
        this.sceneGroup = sceneGroup
        this.group.name = "floorplan"
        // this.updateFloorplanShape("rect")
        this.renderer = renderer
        this.scene = scene
        this.camera = camera
        this.controls = controls
        this.raycaster = raycaster
        this.element = element
        this.moduleName = moduleName
        // this.enableDragging()
        this.createHoverBox()
        this.controls.addEventListener("change", function() {
            updateAnnotationsPosition()
            updateFloorDimensionsPosition()
        }, false);

        drawWallsInit(this)
        annotationsInit(this)
        heperInit(this)
        raycastingInit(this)
        this.createEventListeneres()

        if(moduleName === "customizer"){
            this.minDistanceForCreateWall = 0.001
            wallHeight = 2.5
            wallThickness = isTouchDevice()? 0.12 : 0.08
        }

        vertexMaterial.envMap = generatedEnvironmentMap.texture
        wallMaterial.envMap = generatedEnvironmentMap.texture
        wallHoverMaterial.envMap = generatedEnvironmentMap.texture

        scene.add(this.group)
        scene.add(groupForRotation)
    }

    setIsSpacePlannerMode(val:boolean){
        this.isSpacePlannerMode = val
        if(this.isSpacePlannerMode === false){
            setcurrSelectedFloorplanObject(null)
        }
    }

    createFloorplanFromVertices(points:Array<Vector3>){
        showFloorDimensions()
        for (let i = 0; i < points.length; i++) {
            let nextIndex = (i+1) % points.length
            let point1 = points[i]
            let point2 = points[nextIndex]
            this.createWallAndVertices(point1,point2)
        }
        this.isRoomDefined = true
        this.configuration.vertices = points
    }

    createHoverBox(){
        const boxSize = 0.1
        const geometry = new BoxGeometry(boxSize,boxSize,boxSize)
        this.hoverBox = new Mesh(geometry,vertexMaterial)
        this.box3 = new Box3().setFromObject(this.hoverBox)
    }

    updateHoverBoxPosition(x:any,y:any){
        const position = get3DPoint(x,y,this.camera)
        if(position){
            this.hoverBox.position.copy(position)
            this.box3.setFromObject(this.hoverBox)
        }
    }
    getWallFromHoverBoxIntersection(){
        // if(!isTouchDevice()){
        //     return null
        // }
        this.box3.setFromObject(this.hoverBox)
        let intersectObject = null
        for (const wall of this.walls) {
            const wallBox = new Box3().setFromObject(wall.mesh)
            const isIntersecting = this.box3.intersectsBox(wallBox)
            if (isIntersecting) {
                intersectObject = wall.mesh
            }
        }
        return intersectObject
    }

    createWallAndVertices(point1:Vector3,point2:Vector3){
        
        var distance = point1.distanceTo(point2)
        // distance = distance * 2
        if(distance > this.minDistanceForCreateWall){
            let vertex1 = this.checkIfIntersectingWithVertex(point1)
            if(!vertex1){
                vertex1 = new Vertex(point1)
                this.vertices.push(vertex1)
            }
            let vertex2 = this.checkIfIntersectingWithVertex(point2)
            if(!vertex2){
                vertex2 = new Vertex(point2)
                this.vertices.push(vertex2)
            }else{
                if(!this.isTracingFloorplanMode){
                    this.disableDrawWalls()
                }
            }
            let wall = new Wall(vertex1.mesh,vertex2.mesh,this.group,this.isTracingFloorplanMode)
            if(this.isSetScaleMode){
                this.wallForTraceScale = wall.mesh
            }
            // wall.mesh.attach(vertex1.mesh)
            // wall.mesh.attach(vertex2.mesh)
           this.group.add(vertex1.mesh)
           this.group.add(vertex2.mesh)
            this.walls.push(wall)
        }
    }

    checkIfIntersectingWithVertex(position:Vector3) {
        let boxSize = 0.4
        if(this.moduleName === "customizer"){
            boxSize = 0.1
        }
        let geometry = new BoxGeometry(boxSize,boxSize,boxSize)
        let mesh = new Mesh(geometry,vertexMaterial);
        mesh.position.copy(position)

        var tempVertexBox = new Box3().setFromObject(mesh)

        for (const vertex of this.vertices) {
            var vertexBox = new Box3().setFromObject(vertex.mesh)
            var isIntersecting = tempVertexBox.intersectsBox(vertexBox)
            if (isIntersecting) {
                detachModule(mesh)
                geometry.dispose()
                mesh.clear()
                return vertex
            }
        }
        detachModule(mesh)
        return null
    }

 
    async updateFloorplanShape(type:string){
        this.reset()
        // await waitFor(500)
        this.currShape = type
        switch (type) {
            case "rect":
                this.Rectangle()
            break;

            case "lshape":
                this.lshape()
            break;

            case "pentagon":
                this.Pentagon()
            break;

            case "shape4":
                this.shape4()
            break;

            case "shape5":
                this.shape5()
            break;
        
            default:
            break;
        }

        if(this.moduleName === "customizer"){
            projectConfiguration.setIsFloorplanCreatedFromShape(true)
            updateCameraControls(null,"all")
        }
        this.isCreatedByShape = true
        this.isConfigChanged = true
        this.updateConfiguration()

    }

    reset(){
        this.group.children.forEach(modelObject=>{
            detachModule(modelObject)
        })
        this.group.clear()
        this.vertices = []
        this.walls = []
        // this.configuration.vertices = []
        $(".floor-dimensions").remove()
        this.group.rotation.set(0,0,0)
        this.group.position.set(0,0,0)
        groupForRotation.rotation.set(0,0,0)
        // this.group.parent.remove(this.group)
    }


    Rectangle(){
        let point1 = new Vector3(0, 0, 0)
        let point2 = new Vector3(this.width, 0, 0)
        let point3 = new Vector3(this.width, 0, this.height)
        let point4 = new Vector3(0, 0, this.height)
        // this.createVerticesFromPoint([point1,point2,point3,point4])
        this.createFloorplanFromVertices([point1,point2,point3,point4])

    }

    lshape(){
        let point1 = new Vector3(0, 0, 0)
        let point2 = new Vector3(this.width * 0.7, 0, 0)
        let point3 = new Vector3(this.width * 0.7, 0, this.height * 0.3)
        let point4 = new Vector3(this.width, 0, this.height * 0.3)
        let point5 = new Vector3(this.width, 0, this.height)
        let point6 = new Vector3(0, 0, this.height)
        // this.createFloorplanFromVertices([point1,point2,point3,point4,point5,point6])
        // this.createVerticesFromPoint([point1,point2,point3,point4,point5,point6])
        this.createFloorplanFromVertices([point1,point2,point3,point4,point5,point6])
    }

    Pentagon(){
        let point1 = new Vector3(0, 0, 0)
        let point2 = new Vector3(this.width * 0.7, 0, 0)
        let point3 = new Vector3(this.width, 0, this.height * 0.3)
        let point4 = new Vector3(this.width, 0, this.height)
        let point5 = new Vector3(0, 0, this.height)
        // this.createFloorplanFromVertices([point1,point2,point3,point4,point5])
        // this.createVerticesFromPoint([point1,point2,point3,point4,point5])
        this.createFloorplanFromVertices([point1,point2,point3,point4,point5])
    }

    shape4(){
        let point1 = new Vector3(0, 0, 0)
        let point2 = new Vector3(this.width, 0, 0)
        let point3 = new Vector3(this.width, 0, this.height * 0.3)
        let point4 = new Vector3(this.width * 0.7, 0, this.height * 0.3)
        let point5 = new Vector3(this.width * 0.7, 0, this.height)
        let point6 = new Vector3(0, 0, this.height)
        // this.createVerticesFromPoint([point1,point2,point3,point4,point5,point6])
        this.createFloorplanFromVertices([point1,point2,point3,point4,point5,point6])
    }

    shape5(){
        let point1 = new Vector3(0, 0, 0)
        let point2 = new Vector3(this.width * 0.7, 0, 0)
        let point3 = new Vector3(this.width * 0.7, 0, this.height * 0.2)
        let point4 = new Vector3(this.width, 0, this.height * 0.2)
        let point5 = new Vector3(this.width, 0, this.height * 0.8)
        let point6 = new Vector3(this.width * 0.7, 0, this.height * 0.8)
        let point7 = new Vector3(this.width * 0.7, 0, this.height)
        let point8 = new Vector3(0, 0, this.height)
        // this.createVerticesFromPoint([point1,point2,point3,point4,point5,point6,point7,point8])
        this.createFloorplanFromVertices([point1,point2,point3,point4,point5,point6,point7,point8])
    }

    createFloorFromVertices(){
        const positionForPlane = [] 
        for (let i = 0; i < this.configuration.vertices.length; i++) {      
            const vertex = this.vertices[i].mesh.position
            // let mesh = this.vertices[i].mesh
            // mesh.getWorldPosition(vertex)
            positionForPlane.push(new Vector2(vertex.x,vertex.z))
        }
        var shape = new Shape(positionForPlane);
        var shapeGeo = new ShapeGeometry(shape);
        this.floor = new Mesh(shapeGeo,wallMaterial);
        // wallMaterial.envMap = generatedEnvironmentMap.texture
        this.floor.rotation.set(Math.PI / 2,0,0);
        this.floor.name = "Floor_"
        let position = getObjectDimensionPosition(null,this.group).positions.min
        // shapeFloor.position.copy(position)
        this.floor.position.y = position.y
        this.group.add(this.floor)
    }

    setFloor(floor:Mesh){
        this.floor = floor
    }


    highlightFirstVertex(){
        let vertex = this.vertices[0].mesh
        vertex.material = wallHoverMaterial
    }
   


    createBox(wall:any){
        const box = new BoxHelper( wall, 0x000000 );
        box.userData.canSelected = false
        this.group.add( box )
    }


  
      

    createVerticesFromPoint(points:Array<any>){
        for (let i = 0; i < points.length; i++) {
            let vertex = new Vertex(points[i])
            this.vertices.push(vertex)
        }
    }

    

    toggleVisibility(isSpacePlannerMode:boolean){
        if(isSpacePlannerMode){
            this.group.visible = true  
            $(".floor-dimensions").addClass("--is-active")
            this.createWallDimesnions()
        }else{
            this.group.visible = false
            $(".floor-dimensions").removeClass("--is-active")
        }
    }

    deleteWall(){
        removecurrSelectedFloorplanObject()
    }

    updateWallDimension(){
        let feet = $("#wallDimFeetValue").val()
        let inch = $("#wallDimInchValue").val()
        // let lengthFromInput = (Number(feet) * 12) + Number(inch)
        // lengthFromInput = lengthFromInput / 100
        // lengthFromInput = lengthFromInput * 2.54
        let lengthFromInput = Number(feet)  + (Number(inch) / 100)
        lengthFromInput = lengthFromInput / 100
        lengthFromInput = lengthFromInput * 30.48 

        // let vertex1 = currSelectedFloorplanObject.userData.vertex1
        let vertex1 = this.getVertexFromName(currSelectedFloorplanObject.userData.vertex1Name)
        
        let wallToBeDragged = null

        this.walls.forEach((currWall)=>{
        const vertexBox = new Box3().setFromObject(vertex1)
        const wallBox = new Box3().setFromObject(currWall.mesh)
        const isIntersecting = wallBox.intersectsBox(vertexBox)
        if(isIntersecting){
            if(currWall.mesh.uuid !== currSelectedFloorplanObject.uuid){
                wallToBeDragged = currWall.mesh
            }
        }
        })

        if(wallToBeDragged){
            let vertex1 = this.getVertexFromName(wallToBeDragged.userData.vertex1Name)
            let vertex2 = this.getVertexFromName(wallToBeDragged.userData.vertex2Name)

            // let initialWallLength = (wallDimensions.feet * 12) + wallDimensions.inch
            // initialWallLength = initialWallLength / 100
            // initialWallLength = initialWallLength * 2.54
            let initialWallLength = Number(wallDimensions.feet)  + (Number(wallDimensions.inch) / 100)
            initialWallLength = initialWallLength / 100
            initialWallLength = initialWallLength * 30.48 

            let diff = lengthFromInput -  initialWallLength


            const normal = wallToBeDragged?.userData.normal

            if(normal?.x){
                vertex1.position.x += diff  
                vertex2.position.x += diff   
            }else{
                vertex1.position.z += diff 
                vertex2.position.z += diff  
            }
            updateWalls()
            setcurrSelectedFloorplanObject(null)
        }

        
        // if(currSelectedFloorplanObject.userData.normal.x){
        //     this.height = this.height + diff
        // }
        // if(currSelectedFloorplanObject.userData.normal.z){
        //     this.width = this.width + diff
        // }

        // this.reset()
        // this.updateFloorplanShape(this.currShape)
        // hideAnnotation()
        // setcurrSelectedFloorplanObject(null)
    }

    enableDrawWalls() {
        this.isDrawWallsMode = true
        addDrawWallsElements()
    }

    disableDrawWalls() {
        if(!this.isTracingFloorplanMode || this.isSetScaleMode){
            this.isDrawWallsMode = false
        }
        disableDrawWalls()
    }

    enableTraceMode(){
        this.reset()
        this.isTracingFloorplanMode = true
        this.sceneGroup.visible = false
        this.controls.enabled = false
        this.group.visible = false
        removeWallDimensions()
        $("#moduelsDragIcon").addClass("display-none")
    }

    disableTraceMode(){
        this.isTracingFloorplanMode = false
        this.sceneGroup.visible = true
        this.controls.enabled = true
        this.group.visible = true
        $("#moduelsDragIcon").removeClass("display-none")
        this.createWallDimesnions()
        this.disableDrawWalls()

        // if(!this.isCreatedByShape){
        //     const scale = 1 * this.traceScale
        //     const originalGroupScale = new Vector3(scale,scale,scale);
        //     this.group.scale.copy(originalGroupScale);
        // }

        this.createFloorFromVertices()
        // const inverseGroupScale = new Vector3(1 / originalGroupScale.x, 1 / originalGroupScale.y, 1 / originalGroupScale.z);
        // this.scene.scale.copy(inverseGroupScale);
        // this.sceneGroup.scale.copy(inverseGroupScale);
    }

    confirmTracing(){
        this.isTracingFloorplanMode = false
        $(".temp-wall").remove()
        this.disableTraceMode()
    }

    
    createWallDimesnions(){
        $(".floor-dimensions").remove()
        for (const wall of this.walls) {
            let parentContainer = $("#3dCustomizerWrapper")
            if(window.location.pathname.toLowerCase().includes("/customize")){
                parentContainer = $("#area3DSceneWrapper")
            }
            if(window.location.pathname.toLowerCase().includes("/customizein3d")){
                parentContainer = $("#3dCustomizerWrapper")
            }
            wall.dimensionElement = $("<div></div>",{
                class:"floor-dimensions top-left"
            }).text("125")
    
            wall.dimensionElement.click(function(){
                setcurrSelectedFloorplanObject(wall)
                showWallDimensionsDropDown(wall)
            })
            parentContainer.append(wall.dimensionElement)  
        }
        
    }

    setTraceScale(){
        let feet = Number($("#actualWallDimensionFeet").val())
        let inches = Number($("#actualWallDimensionInch").val())
        const wholeFeetActual = feet + (inches / 12)

        const wholeFeetCurrent = currWallDimensionsForScale.feet + (currWallDimensionsForScale.inch / 12)

        const actualFeetInPixels =  (currWallDimensionsForScale.distance * wholeFeetActual) /  wholeFeetCurrent

        // const wholeFeetCurrent = Math.floor(currWallDimensionsForScale.distance)
        this.traceScale = actualFeetInPixels / currWallDimensionsForScale.distance  
    }
    
    deleteLastWall(){
        if(this.walls.length){
            let lastWallMesh = this.walls[this.walls.length - 1].mesh
            let vertex2 = lastWallMesh.userData.vertex2
            this.walls = this.walls.filter(currWall=>currWall.mesh.uuid != lastWallMesh.uuid)
            lastWallMesh.parent.remove(lastWallMesh)
            vertex2.parent.remove(lastWallMesh)
        }
    }

    removeWallForSetScale(){
        this.wallForTraceScale?.parent.remove(this.wallForTraceScale)
        this.walls = this.walls.filter(currWall => currWall.mesh.uuid !== this.wallForTraceScale.uuid)
    }


    createFloor(){
        // const vertices = []
        // for (const vertex of this.vertices) {
        //     vertices.push(vertex.mesh.position)
        // } 
        // const geometry = new BufferGeometry().setFromPoints(vertices);
        // const material = new MeshStandardMaterial({ color: wallColor,side:DoubleSide});
        // const floor = new Mesh(geometry, material);
        // floor.name = "Floor"
        // floor.position.y = -wallHeight / 2
        // this.group.add(floor);
        addFloor(getObjectDimensionPosition(null,this.group))
    }

    saveFloorplan(){
        getGltfFromCamera(this.group).then((file:any)=>{
            let fileName = `${projectConfiguration.projectName}_Shell.glb`
            let albumName = "models"
            let albumPhotosKey = encodeURIComponent(albumName) + "/" + encodeURIComponent(applicationConfig?.clientName) + "/AreaModels/" + encodeURIComponent(projectConfiguration?.projectName+"_"+projectConfiguration?.projectId) + "/";
            uploadShellForRendering({file:file,clientName:applicationConfig?.clientName,projectName:projectConfiguration.projectName}).then(data=>{
                console.log("Camera saved")
            }).catch(err=>{
                console.log(err)
            })
            return applicationConfig.awsConfig.uploadFile(null,"",albumPhotosKey,fileName,file)
        })
    }

    removeFloorplanListeners() {
        // if(this.isCreatedByShape){
        //     return
        // }
        this.element.removeEventListener('pointerup',pointerup);
        this.element.removeEventListener('pointerdown',pointerdown);
        this.element.removeEventListener('touchstart',touchstart);
        this.element.removeEventListener('pointermove',pointermove)
        this.element.removeEventListener('dblclick',dblclick)
    }

    
    createEventListeneres() {
        this.element.addEventListener('pointerup',pointerup);
        this.element.addEventListener('pointerdown',pointerdown);
        this.element.addEventListener('touchstart',touchstart);
        this.element.addEventListener('pointermove',pointermove)
        this.element.addEventListener('dblclick',dblclick)
    }

    // setWallAndVerticesArray(){
    //     if(!this.walls.length && !this.vertices.length){
    //         this.walls = []
    //         this.vertices = []
    
    //         this.group?.traverse((currMesh:any)=>{
    //             if(currMesh.isMesh && currMesh.userData.isVertex){
    //                 this.vertices.push(new Vertex(null,currMesh))
    //             }
    //         })
    //         this.group?.traverse((currMesh:any)=>{
    //             if(currMesh.isMesh && currMesh.userData.isWall){
    //                 const vertex1 = this.getVertexFromName(currMesh.userData.vertex1Name)
    //                 const vertex2 = this.getVertexFromName(currMesh.userData.vertex2Name)
    //                 this.walls.push(new Wall(vertex1,vertex2,this.group,this.isTracingFloorplanMode,currMesh))
    //             }
    //         })
    //     }
        
    // }

    getVertexFromName(name:string){
        let mesh = null
        this.vertices.forEach((vertex:Vertex)=>{
            if(vertex.mesh.name === name && vertex.mesh.userData.isVertex){
                mesh =  vertex.mesh
                return
            }
        })
        return mesh
    }

    async rotateFloorplan(){
        addProductToRotationGroup(this.group,groupForRotation)
        groupForRotation.rotateOnAxis(axis,-Math.PI/2)
        this.group.updateMatrixWorld();
        await waitFor(200)
        removeProductFromRotationGroup(this.group,groupForRotation,this.scene)
        this.isConfigChanged = true

        // addProductToRotationGroup(virtaulPlanesGroup,groupForRotation)
        // groupForRotation.rotateOnAxis(axis,-Math.PI/2)
        // await waitFor(100)
        // removeProductFromRotationGroup(virtaulPlanesGroup,groupForRotation,this.scene)


        // updateWalls()

        // await waitFor(100)
        // for (const wall of this.walls) {
        //     wall.createPlanes(wall.mesh,this.group)
        //     wall.mesh.userData.normal = wall.mesh.userData.normal.multiplyScalar(-1)
        // }
    }

    
    addCube(pos:Vector3){
        const geometry = new BoxGeometry(0.3,0.3,0.3); 
        const cube = new Mesh( geometry, wallHoverMaterial ); 
        cube.position.copy(pos)
        this.group.add( cube );
        return cube
    }

    async horizontalFlip(){
        return
        const axis = new Vector3(1,0,0)
        addProductToRotationGroup(this.group,groupForRotation)
        groupForRotation.rotateOnAxis(axis,-Math.PI)
        await waitFor(200)
        removeProductFromRotationGroup(this.group,groupForRotation,this.scene)
        this.isConfigChanged = true
    }

    updateConfiguration(){
        this.configuration.vertices = []
        for (const vertex of this.vertices) {
            const position = new Vector3()
            vertex.mesh.getWorldPosition(position)
            this.configuration.vertices.push(position)
        }
        if(this.moduleName === "customizer"){
            projectConfiguration.updateLocalStorage()
        }
    }

    parseConfiguration(points:Array<any>){
        this.configuration.vertices = []
        for (const point of points) {
          this.configuration.vertices.push(
            new Vector3(point.x,point.y,point.z)
          )
        }
      }
}



class Vertex{

    mesh:Mesh = null
    constructor(point:Vector3,mesh:Mesh = null){
        if(mesh){
            this.mesh = mesh
        }else{
            const geometry = new BoxGeometry(wallThickness, wallHeight, wallThickness);
            this.mesh = new Mesh(geometry, vertexMaterial);
            this.mesh.position.set((point.x + point.x) / 2, (point.y + point.y) / 2, (point.z + point.z) / 2);
            // group.add(this.mesh)
            this.mesh.userData.isVertex = true
            this.mesh.userData.canSelected = true
            // this.mesh.renderOrder = 5
            this.mesh.name = "Floorplan_Vertex_" + getRandomString(5)
        }
        
    }
}

class Wall{
  

    mesh:Mesh = null

    startVertex:Mesh
    endVertex:Mesh


    dimensionElement:any = null

    constructor(vertex1:Mesh,vertex2:Mesh,group:Group,isTracingFloorplanMode:boolean,mesh:Mesh = null){
        let point1 = new Vector3()
        let point2 = new Vector3()
        vertex1.getWorldPosition(point1)
        vertex2.getWorldPosition(point2)

        this.startVertex = vertex1
        this.endVertex = vertex2

        if(mesh){
            this.mesh = mesh
        }else{
            var distance = point1.distanceTo(point2)
            const geometry = new BoxGeometry(distance, wallHeight, wallThickness)
    
    
            this.mesh = new Mesh(geometry, wallMaterial)
    
            this.mesh.position.set((point1.x + point2.x) / 2, -(point1.y + point2.y) / 2, (point1.z + point2.z) / 2)
    
            var direction = point2.clone().sub(point1).normalize()
            var quaternion = new Quaternion().setFromUnitVectors(new Vector3(1, 0, 0), direction)
            this.mesh.quaternion.copy(quaternion);
    
            this.mesh.name = "Floorplan_Wall_" + getRandomString(5)
            group.add(this.mesh)
         
         
            this.mesh.userData.direction =  direction
            this.mesh.userData.isWall =  true
            this.mesh.userData.vertex1Name =  vertex1.name
            this.mesh.userData.vertex2Name =  vertex2.name
            // this.createBox(wall)
            this.mesh.userData.canSelected = true


    
            // this.mesh.onBeforeRender = this.onBeforeRender
            // this.mesh.onAfterRender = this.onAfterRender
            this.createPlanes(this.mesh,group)
        }
        if(!isTracingFloorplanMode){
            this.createDimensionElement(this.mesh)
            this.mesh.userData.dimensionElement = this.dimensionElement
        }
        
    }

    createDimensionElement(wall:Mesh){
        let parentContainer = $("#3dCustomizerWrapper")
        if(window.location.pathname.toLowerCase().includes("/customize")){
            parentContainer = $("#area3DSceneWrapper")
        }
        if(window.location.pathname.toLowerCase().includes("/customizein3d")){
            parentContainer = $("#3dCustomizerWrapper")
        }
        this.dimensionElement = $("<div></div>",{
            class:"floor-dimensions top-left"
        }).text("125")

        this.dimensionElement.click(function(){
            setcurrSelectedFloorplanObject(wall)
            showWallDimensionsDropDown(wall)
        })
        parentContainer.append(this.dimensionElement)
    }

    createPlanes(wall:any,group:any){
        createFrontAndBackPlane(wall)
        // console.log(wall.name,wall.userData.normal)
    }

 

    // onBeforeRender = function() {


    //     return function onBeforeRender( renderer, scene, camera, geometry, material, group ) {
    //         if ( v.subVectors( this.camera.position, this.userData.hiddenWall.userData.position ).dot( this.userData.hiddenWall.userData.normal ) < 0 ) {
    //             geometry.setDrawRange( 0, 0 )
    //         }
    
    //     };
    
    // }();
    
    // onAfterRender = function( renderer, scene, camera, geometry, material, group ) {
    //     geometry.setDrawRange( 0, Infinity )
    // };

}