import { IonIcon, IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { fullScreenBrowserToggle, toggleUserOption } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getRandomString, getSubDomainName, getUser } from "../../../../../utils/methods";
import { openSideMenu } from "../../../../../utils/store/UI_methods";
import { showProductCart, updateCartIconCounter } from "../../../../../utils/store/product-cart";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import "../../../../style/store/header.css";
import ProductCart from "../cart/ProductCart";
import ProductCheckout from "../checkout/ProductCheckout";
import CreateCustomOrder from "../custom-order/CreateCustomOrder";
import StartCustomerSession from "../customer/StartCustomerSession";
import SideMenu from "./SideMenu";
import StoreProductSearch from "./StoreProductSearch";
import UserOptions from "./UserOptions";
import { getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";
import { expand } from "ionicons/icons";
import CommonHeader from "./CommonHeader";
import KairaFabricsHeader from "./KairaFabricsHeader";

 
const MainHeader: React.FC<{
}> = (props) => {


  const history = useHistory()
  const[isOpenStartSession,setIsOpenStartSession] = useState(false)
  const[isOpenAddProduct,setIsOpenAddProduct] = useState(false)
  
  const[isOpenOrders,setIsOpenOrders] = useState(false)
  // const[isOpenSearch,setIsOpenSearch] = useState(false)

  const[refresh,setRefresh] = useState(getRandomString(10))
  const[refreshCustomer,setRefreshActiveCustomer] = useState(getRandomString(10))
  const[cartItems,setCartItem] = useState([])
  const[logoSrc,setLogoSrc] = useState("assets/images/opus.png")
  const[subDomain] = useState(getSubDomainName())

  useEffect(()=>{
    if(STORE?.activeCustomer || true){
      fetchCartItems()
    }else{
      setCartItem([])
      updateCartIconCounter(0)
    }
  },[refresh])

  function fetchCartItems() {

    let cartItems = getItemFromLocalStorage("store_cart") || []
    STORE.cartItems = cartItems
    updateCartIconCounter(cartItems.length)
    setCartItem(cartItems)
    return
    STORE.storeApi.cart.getCartItems(STORE?.activeCustomer.id).then((data:any)=>{
      if(!data.error){
        let cartList = data.data?.data
        if(!cartList?.length){
          cartList = []
        }
        setCartItem(cartList)
        STORE.cartItems = cartList
        updateCartIconCounter(cartList.length)
      }
    })
  }
  
  
  useEffect(()=>{
    applicationConfig?.setFunctionRef("store_MainHeader",{refreshCartItems:refreshCartItems})
    applicationConfig?.setFunctionRef("store_MainHeader",{refreshActiveCustomer:refreshActiveCustomer})
    applicationConfig?.setFunctionRef("store_MainHeader",{openOrders:openOrders})

    if(applicationConfig?.user?.isAllowedWhitelabeling){
      let clientName = applicationConfig.clientName
      setLogoSrc(applicationConfig.aws_public_url + `assets/clientLogos/${clientName}.png`)
    }
  },[])

  function refreshCartItems() {
    setRefresh(getRandomString(10))
  }

  function openOrders() {
    setIsOpenOrders(true)
  }

  function closeOrders() {
    setIsOpenOrders(false)
  }

  function refreshActiveCustomer() {
    setRefreshActiveCustomer(getRandomString(10))
  }
   

  function gotoHome() {
    if(!getUser().shareItemDetails){
      history.push("/products")
    }
  }

  function onsuccessCreateOrder() {
    setIsOpenAddProduct(false)
  }

 
  return (
    <>
    {applicationConfig.clientName === "KairaFabrics"?
      <KairaFabricsHeader/>
    :
      <CommonHeader/>
    }
    </>     
  );
};

export default MainHeader;
