import { IonAlert } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { deleteProduct } from "../../../../services/api";
import { applicationConfig, getObjectByParameter, getUser } from "../../../../utils/methods";
import Button2 from "../../../common/ui-components/buttons/Button2";
import ProductImage from "../../../common/ui-components/elements/ProductImage";
import ShareLink from "../../../common/ui-components/links/sharelink/ShareLink";
import Toast from "../../../common/ui-components/notification/Toast";
import ProductGallery from "./ProductGallery";
import $ from "jquery"
import EditLink from "../../../common/ui-components/links/EditLink";
import { showGlobalToast } from "../../../../utils/UI_methods/global";

const Home: React.FC<{
  configObj: any;
  currProductId: number;
  currProduct: any;
  setOpenProjectAction: (value: boolean) => void;
  viewIn360Action: (product: any) => void;
  quickCustomizeAction: () => void;
  gotoCustomizeIn3D: (product: any) => void;
  closeModal?:()=>void
}> = (props) => {
  const [currProduct, setcurrProduct] = useState<any>({});
  const [loaderId] = useState<any>("sharelinkLoader");
  
  const[showDeleteAlert,setShowDeleteAlert] = useState(false)
  const[bodyHeight,setBodyHeight] = useState(0)

  const bodyRef = useRef(null)

  useEffect(()=>{
    setTimeout(() => {
      if(bodyRef){
        setBodyHeight($(bodyRef.current).height())
      }
    }, 500);
  },[bodyRef])

  const [actions,setActions] = useState([
    {
      name: "viewin3d",
      label: "Quick 3D",
      icon: "fas fa-eye",
      svg:applicationConfig.aws_public_url+"assets/icon/svg/viewin3d.svg",
      // action:viewProductin3DAction,
    },
    {
      name: "customizeFinish",
      label: "Customize in 3D",
      iconClassNames: ["color-danger"],
      svg:applicationConfig.aws_public_url+"assets/icon/svg/customize_in_3d.svg",
      icon: "fas fa-cogs",
      action:props.gotoCustomizeIn3D,
    },
  ]);

  useEffect(()=>{
    if(getUser()?.shareItemDetails){
      setActions([
        {
          name: "viewin3d",
          label: "Quick 3D",
          icon: "fas fa-eye",
          svg:applicationConfig.aws_public_url+"assets/icon/svg/viewin3d.svg",
          // action:viewProductin3DAction,
        },
      ])
    } 
  },[])

  useEffect(() => {
    if (props.currProductId) {
      let obj = getObjectByParameter(props.configObj?.data.productsList,"product_id",props.currProductId);
      setcurrProduct(obj);
    }

  }, [props.currProductId,props.configObj]);

  
 

  function deleteProductAction() {
    deleteProduct(props.currProduct.product_id || props.currProduct.productId).then(data=>{
      window.location.href = "/product-categories"
    }).catch(err=>{
      console.log(err)
    })
  }


  function viewIn360Actions() {
    props.viewIn360Action(props.currProduct)
  }

  async function clearSpecificCache(cacheName:string) {
    const cache = await caches.open(cacheName);
    if (cache) {
      const keys = await cache.keys();
      keys.forEach(async (key) => {
        if (key.url.includes(currProduct?.product_name)) {
          await cache.delete(key);
          showGlobalToast("Success")
        }
      });
  
      // window.location.reload();
    }
  }
  



  function clearCacheAction(){
    /* eslint-disable no-restricted-globals */
    let userResponse = confirm("Do you want to delete product cache data?");
    /* eslint-enable no-restricted-globals */

    if (userResponse) {
      clearSpecificCache("OPUS_LOADED_TAR_FILES")
    }
  }



  function Actions() {
    return(
      <>

      <div className="left-middle padding0">

        <div className="">
          <div className="heading1 font-large">{props.currProduct?.product_name}</div>
          <div className="display-flex-row no-wrap">
            <span className="heading4 font-small">{props.currProduct?.category_name} / </span>
            <span className="heading4 font-small marginleft5">{props.currProduct?.sub_category_name}</span>
          </div>
        </div>
          
      </div>

      <div className="middle">
        <div className="display-flex-row no-wrap">
          {/* {applicationConfig?.isMasterLogin()?
            <EditLink
              configObj={props.configObj}
              editItemId={currProduct?.product_id}
              editItemName = {currProduct?.product_name}
            />
            :null} */}
            


        </div>
          
      </div>


          <div className="right-middle">
            <div className="display-flex-row no-wrap">

            <Button2
                configObj={{}}
                action={clearCacheAction}
                label={""}
                fontAwesomeIcon={"fas fa-undo color-danger"}
                iconPos="left"
                classNames={"button-md-small"}
            />

            {applicationConfig?.isMasterLogin()?
              <div className="marginRight5 hide-in-mobile">
                <Button2
                  configObj={{}}
                  action={() => setShowDeleteAlert(true)}
                  label={"Delete"}
                  fontAwesomeIcon={"fas fa-trash color-danger"}
                  iconPos="left"
                  classNames={"button-md-small hide-button-text-in-mobile"}
                />
              </div>
            :null}

              {applicationConfig?.isMasterLogin()?
              <EditLink
                configObj={props.configObj}
                editItemId={currProduct?.product_id}
                editItemName = {currProduct?.product_name}
                Content={()=>{
                  return(
                    <Button2
                      configObj={{}}
                      action={() => props.setOpenProjectAction(false)}
                      label={"Edit"}
                      fontAwesomeIcon={"fas fa-edit color-primary"}
                      iconPos="left"
                      classNames={"button-md-small hide-button-text-in-mobile"}
                    />
                  )
                }}
              />
              :null}

              <ShareLink
                shareItemId={props.currProductId}
                loaderId = {"loaderId"}
                moduleName="products"
                textClass="color-black"
                isShareButton={true}
                Content={()=>{
                  return(
                    <Button2
                    configObj={{}}
                    action={() =>{}}
                    label={"Share"}
                    fontAwesomeIcon={"fas fa-share color-primary"}
                    iconPos="right"
                    classNames={"button-md-small bg-color-primary-outline hide-button-text-in-"}
                  />
                  )
                }
                }
              />

              <Button2
                configObj={{}}
                action={() => props.setOpenProjectAction(false)}
                label={""}
                fontAwesomeIcon={"fas fa-times color-danger"}
                iconPos="left"
                classNames={"button-md-small"}
              />

            </div>
           
          </div>

      </>
    )
  }

  function Gallery() {
    return(
      <div className="position-relative full-height">
          <ProductGallery imageHeight={bodyHeight} product={currProduct} gotoCustomizeIn3D={props.gotoCustomizeIn3D}/>
        <div className='top-right'>
        </div>
      </div>
    )
  }


  function Header(){
    return (
      <div className="full-width white-card shadow-medium position-relative" style={{height:"5rem"}}>
          <div className="display-flex-row space-between">
            <Actions/>
          </div>
      </div>
    )
  }

  function Body(){
    return (
        <div ref={bodyRef} className="full-width" style={{height:"calc(100% - 18rem)"}}>
        <div className="full-width" style={{height:"100%"}}>
            <Gallery/>
          </div>
        </div>
    )
  }



  function setCurrGalleryImageDetails(details:any){
    setCurrGalleryImageDetails(details)
  }

  
  return (
    <React.Fragment>
        <Toast/>

       

        <IonAlert
          isOpen={showDeleteAlert}
          mode="ios"
          onDidDismiss={() => setShowDeleteAlert(false)}
          cssClass="my-custom-class"
          header={"Confirm!"}
          message={"Delete <strong>Product</strong>!!!"}
          buttons={[{
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: "Confirm",
              handler: () => {
                deleteProductAction();
              },
            },
          ]}
        />

        <div className="project-action-container position-relative  product-action-container">
         
        <div className="background-white full-height-width ">
          <Header/>
          <Body/>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
