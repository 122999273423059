import { getParentContainer, hideElement, showElement, showToast } from "../UI_methods/global";
import { getObjectArrayOfDistictValues, logger } from "../methods";
import $ from "jquery"


var categoriesList = []
var subCategories = []


export function searchProduct(configObj:any,currSelectedCategory:string,activateSearchMode:(products:Array<any>,searchStr:string)=>void,filterProductsWithCategory:(cat:string)=>void,filterProductsWithSubCategory:(cat:string,sub:string)=>void,idSuffix:string){
    let input:any, searchString, productsWrapper,productContainers:any, i;

    let productsList = configObj.data.productsList

    if(!categoriesList.length || !subCategories.length){
      categoriesList = getObjectArrayOfDistictValues(configObj.data.productsList,"category_name") 
      subCategories = getObjectArrayOfDistictValues(configObj.data.productsList,"sub_category_name") 
    }

    let parentContainer = getParentContainer()


    if(!parentContainer){
      showToast("Invalid page",2000,"error")
      logger.error("products","filter","Error in search Invalid page")
      return
    }

    input = document.getElementById("productSearch"+idSuffix)!;
    // input = shadowRoot.getElementById("productSearch" + idSuffix);


    if(!input){
      return
    }

    if(!input.value.length){
        closeSearch()
        return
    }

    $("#productSearchCloseButton").removeClass("display-none")
    searchString = String(input.value.toLowerCase());

    // productsWrapper = document.getElementById("productsWrapper")!;
    productContainers = parentContainer.querySelectorAll(".product-image-container")!;

    // if(!searchString.length){
    //   return
    // }
    let found = true
    // for (i = 0; i < productContainers.length; i++) {
    //     let productName = productContainers[i].getAttribute("data-product-name")!
    //     let categoryName = productContainers[i].getAttribute("data-category-name")!
    //     let subCategory = productContainers[i].getAttribute("data-subcategory-name")!
    //     if (productName.toLowerCase().indexOf(searchString) > -1 || categoryName.toLowerCase().indexOf(searchString) > -1 || subCategory.toLowerCase().indexOf(searchString) > -1 ) {
    //       productContainers[i].style.display = "";
    //       found = true
    //     } else {
    //       productContainers[i].style.display = "none";
    //     }
    // }
    //If product not found in current ui
    if(true){
      hideElement("loadMoreButton")
      let categoryResultContainer = $("#resultCategoryProducts"+idSuffix)
      let otherResultProducts = $("#resultOtherProducts"+idSuffix)
      let categoriesContainer = $("#resultCategories"+idSuffix)
      let subCategoriesContainer = $("#resultSubCategories"+idSuffix)

      categoryResultContainer.empty()
      otherResultProducts.empty()
      categoriesContainer.empty()
      subCategoriesContainer.empty()

      let allProducts = []
      let categories = []


      //get all products matching the result
      allProducts = productsList.filter((product:any)=>{
        if (product.product_name.toLowerCase().indexOf(searchString) > -1 || product.category_name.toLowerCase().indexOf(searchString) > -1 || product.sub_category_name.toLowerCase().indexOf(searchString) > -1 ) {
          return product
        }
      })

      //get all categories matching the result
      categories = categoriesList?.filter((category:any)=>{
        if (category.category_name.toLowerCase().indexOf(searchString) > -1 ) {
          return category
        }
      })

       //get all sub categories matching the result
       subCategories = subCategories?.filter((category:any)=>{
        if (category.sub_category_name.toLowerCase().indexOf(searchString) > -1 ) {
          return category
        }
      })

      //Categories
      for (const category of categories) {
        let item = $("<div></div>",{
          class:"category-item display-flex-row op-btn op-btn-primary-inverse-active search-item"
        }).text(`${category.category_name}`)
        categoriesContainer.append(item)
        item.on("click",function() {
          $("#searchResults"+idSuffix).fadeOut()
          filterProductsWithCategory(category.category_name)

          // Create log
          logger?.info("products","filter","Search product with category "+ category.category_name)
        })
      }

       //Sub Categories
      for (const subCategory of subCategories) {
        let item = $("<div></div>",{
          class:"category-item display-flex-row op-btn op-btn-primary-inverse-active search-item"
        }).text(`${subCategory.category_name} -> ${subCategory.sub_category_name}`)
        subCategoriesContainer.append(item)
        item.on("click",function() {
          $("#searchResults"+idSuffix).fadeOut()
          filterProductsWithSubCategory(subCategory.category_name,subCategory.sub_category_name)

          // Create log
          logger?.info("products","filter","Search product with subcategory "+ subCategory.category_name + " / " + subCategory.sub_category_name)
        })
      }



      if(allProducts.length){
        $("#searchResults"+idSuffix).fadeIn()
      }else{
        $("#searchResults"+idSuffix).fadeOut()
      }

      let categoryProducts = []
      let otherProducts  = []

      //Filter products of current category
      for (const product of allProducts) {
        if(product.category_name===currSelectedCategory){
          categoryProducts.push(product)
        }else{
          otherProducts.push(product)
        }
      }

     
      //Other categories
      for (const product of otherProducts) {
        let item = $("<div></div>",{
          class:"display-flex-row search-item full-width"
        }).text(`${product.product_name} / ${product.category_name} / ${product.sub_category_name} `)
        otherResultProducts.append(item)
        item.on("click",function() {
          $("#searchResults"+idSuffix).fadeOut()
          activateSearchMode([product],product.product_name)

          // Create log
          logger?.info("products","filter","Search product: "+ product.product_name)
        })
      }


      //Create appent elments
      for (const product of categoryProducts) {
        let item = $("<div></div>",{
          class:"display-flex-row search-item full-width"
        }).html(`${product.product_name} / ${product.category_name} / ${product.sub_category_name}`)
        categoryResultContainer.append(item)

        item.on("click",function() {
          $("#searchResults"+idSuffix).fadeOut()
          activateSearchMode([product],product.product_name)
          // Create log
          logger?.info("products","filter","Search product: "+ product.product_name)
        })
      }

   
      let viewAllButton = $("<a></a>",{
        class:"center-aligned-column view-all-button bottom-middle"
      }).text("View all results")
      viewAllButton.on("click",function(){
        $("#searchResults"+idSuffix).fadeOut()
        activateSearchMode(allProducts,searchString)
      })
      otherResultProducts.append(viewAllButton)


        highlightSearchedText(searchString)
      //Without lower case

    }else{
      $("#searchResults"+idSuffix).fadeOut()
      showElement("loadMoreButton")

    }

  }



export function expandSearch() {
    $("#searchWrapper").addClass("--is-active")
    $("#searchContainer").addClass("--is-active")
    $("#searchContainer").fadeIn()
}

export function closeSearch() {
// $("#searchWrapper").removeClass("--is-active")
// $("#searchContainer").removeClass("--is-active")
  $(".search-results-container").fadeOut(0)
  $(".product-search-input").val("")
  $("#productSearchCloseButton").addClass("display-none")

}


export function highlightSearchedText(searchString:string) {
    let searchItems = document.querySelectorAll(".search-item")
    for (let i = 0; i < searchItems.length; i++) {
      const currItem:any = searchItems[i]
      let innerHtml = String(currItem.innerHTML.toLowerCase())
      let index = innerHtml.indexOf(searchString)
      if(index >= 0){
        innerHtml = innerHtml.substring(0,index) + '<span class="heading2 font-md-small color-black center-aligned-column">' + innerHtml.substring(index,index+searchString.length) + '</span>' + innerHtml.substring(index+searchString.length);
        // currItem.innerHtml = innerHtml
        $(currItem).html(innerHtml)
      }
      
    }
}