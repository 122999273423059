import React from "react";
import Button2 from "../../../common/ui-components/buttons/Button2";

 

const ProductFilterHeader: React.FC<{
  heading:string
  filterOptions:Array<string>
  isHomePage:boolean
}> = (props) => {

 
  return (
    <div className="full-width margintop10 space-between">

    {props.isHomePage?
    <div className="ion-padding center-aligned-column">
      <div className="heading1 font-ex-large">{props.heading}</div>
    </div>
    :
    <div className="padding5">
      <div className="heading1 font-ex-large">{props.heading}</div>
    </div>
    }
    


    <div className="display-flex-row marginTop10">

      {!props.isHomePage?
      <>
      <Button2
        configObj={{}}
        action={()=>{}}
        label={"Filter"}
        fontAwesomeIcon={"fas fa-filter color-black"}
        iconPos="left"
        classNames={"no-shadow button-small no-border"}
      />
      <div className="marginRight10" style={{border:"0.5px solid black"}}></div>
      </>
      :null}

      

      {props.filterOptions.map((currOption:any,index:number)=>{
        return(
          <div key={index}>
            <Button2
              configObj={{}}
              action={()=>{}}
              label={currOption}
              fontAwesomeIcon={"fas fa-chevron-left color-primary"}
              iconPos=""
              classNames={`no-shadow button-small border ${index === 0?" bg-color-light":" "}`}
            />
          </div>
        )
      })}

    </div>
  </div>
  );
};

export default ProductFilterHeader;
