import React, { useState } from "react";
import { STORE, SofaConfiguration } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ProductConfigSummary from "../quotation/ProductConfigSummary";
import { Configuration } from "../../../../../utils/customizein3d/Configuration";

const CartProductSummary: React.FC<{
  product:any
  configuration3D:Configuration
  productConfiguration:any
  closeHandle:()=>void
}> = (props) => {





  return (
    <div className="full-height display-flex-column no-wrap product-customizer-wrapper">
        
        <div className="heading1 pc-color-primary font-large text-center padding10 header-content">Summary</div>

        <div className="no-wrap overflow-y-scroll body-content padding5">
            <ProductConfigSummary product={props.product} configuration={props.productConfiguration} configuration3D={props.configuration3D} />
        </div>


        <div className="center-aligned-column footer-content" style={{bottom:"0"}}>
            <Button2
              configObj={{id:""}}
              action={props.closeHandle}
              label={"Close"}
              fontAwesomeIcon={"fas fa-arrow-right color-primary"}
              iconPos=""
              classNames={"no-shadow text-center pc-bg-color-primary"}
            />
        </div>
        
      
    </div>
  );
};

export default CartProductSummary;
