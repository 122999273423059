import { IonIcon } from "@ionic/react";
import { closeOutline, search } from "ionicons/icons";
import React, { useState } from "react";
import { closeSearch, searchProduct } from "../../../../utils/products/productSearch";
import { getRandomString } from "../../../../utils/methods";
 
 
const ProductsSearch: React.FC<{
    configObj:any
    currSelectedCategory:string
    activateSearchMode:(productsList:Array<any>,searchString:string)=>void
    filterProductsWithCategory:(cat:string)=>void
    filterProductsWithSubCategory:(cat:string,subCate:string)=>void
    idSuffix:string
    hideCloseButton?:boolean
  }> = (props) => {

  const[idSuffix] = useState(props.idSuffix)


 
  return (

    <React.Fragment>
      <div className="search-container-wrapper" id="searchWrapper"> 
        <div className="search-container white-card shadow-light  position-relative" id="searchContainer">
          <IonIcon className="icon font-normal left-middle" icon={search}></IonIcon>
          <input
            type="text"
            onChange={()=>searchProduct(props.configObj,props.currSelectedCategory,props.activateSearchMode,props.filterProductsWithCategory,props.filterProductsWithSubCategory,props.idSuffix)}
            id={"productSearch"+idSuffix}
            placeholder="Search for products"  
            className="search-input input product-search-input"
            autoComplete="off"
          />

          <div id={"searchResults"+idSuffix} className="search-results-container shadow-light">
            <div className="title">Categories</div>
            <div className="display-flex-row" id={"resultCategories"+idSuffix}>
            </div>

            <div className="title">Sub Categories</div>
            <div className="display-flex-row" id={"resultSubCategories"+idSuffix}>
            </div>

            <div className="title">Products in {props.currSelectedCategory}</div>
            <div className="" id={"resultCategoryProducts"+idSuffix}>
            </div>

            <div className="title">Products in other categories</div>
            <div className="" id={"resultOtherProducts"+idSuffix}>
            </div>
          </div>

          {!props.hideCloseButton?
            <IonIcon onClick={closeSearch} id="productSearchCloseButton" className="icon font-normal right-middle" icon={closeOutline}></IonIcon>
          :null}
    
        </div>

    

      </div>
 
      </React.Fragment>
  );
};

export default ProductsSearch;
