import { IonModal } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import Button2 from "../../../../../../../../common/ui-components/buttons/Button2";
import { getFormatedDate } from "../../../../../../../../../utils/store/crm/crm";
import { getOnlyDateFromTimestamp, getTodayTimestampOnlyDate } from "../../../../../../../../../utils/store/crm/calendar";
import { CRM } from "../../../../../../../../../utils/store/crm/crmConfiguration";
import { showGlobalToast } from "../../../../../../../../../utils/UI_methods/global";
 
const TodaySummary: React.FC<{
}> = (props) => {

  const[isOpenSummary,setIsOpenSummary] = useState(false)

  const[timeStamp,setTimeStamp] = useState(getTodayTimestampOnlyDate())


  const dateRef = useRef(null)


  const[tasks,setTasks] = useState([])

  useEffect(()=>{
    setTimeout(() => {
      setTasks([
        {
          taskName:"No of leads created",
          length:CRM.data.leadsList.filter(currLead => String(currLead.date_of_creation) === String(timeStamp)).length
        },
        {
          taskName:"Qualified leads",
          length:CRM.data.activitiesList.filter(activity => (activity.activity_type.toLowerCase().includes("create appointment") || activity.activity_type.toLowerCase().includes("set up follow up call")) && String(activity.created_at) === String(timeStamp)).length
        },
        {
          taskName:"Appointment Fixed",
          length:CRM.data.activitiesList.filter(activity => activity.activity_type.toLowerCase().includes("create appointment") && String(activity.created_at) === String(timeStamp)).length
        },
        {
          taskName:"Store visits",
          length:CRM.data.visitorsList.filter(visitor => String(visitor.check_in) === String(timeStamp)).length
        }
      ])



    }, 1000);
  },[isOpenSummary,timeStamp])



  function onChangeHandle(event:any) {
    let date = event.target.value
    let result = new Date(date).getTime()
    setTimeStamp(getOnlyDateFromTimestamp(new Date(date)).getTime())
    showGlobalToast(`Showing summary for ${getFormatedDate(result)}`)
  }

 

  function Summary() {
    return(
      <div className="full-height-width ">
        <div className="display-flex-row ion-padding no-wrap space-between padding10 bg-color-light">

            <div className="" style={{padding:"2px 5px"}}>
              <div className="display-flex-row no-wrap">
                <div className="">
                  <div className="color-black heading1">
                      Summary
                  </div>
                  <div className="heading3 font-md-small color-primary margniTop10">{getFormatedDate(timeStamp)}</div>
                </div>
                <div className="center-aligned-column">
                  <div className="position-relative pointer" style={{marginLeft:"-95px"}}>
                  <input type="date" ref={dateRef} onChange={onChangeHandle} id="dateSelector" className="input -hidden" style={{opacity:"0"}} />
                  <div className="pointer top-right pointer-none padding10"> <i className="fas fa-calendar-alt color-dark"></i> </div>
                  </div>
                  {/* <Button2
                    configObj={{id:""}}
                    action={()=>{openCalendar()}}
                    label={""}
                    fontAwesomeIcon={"fas fa-calendar-alt color-warning"}
                    iconPos="left"
                    classNames={"no-shadow button-small bg-color-warning-outline"}
                  /> */}
                </div>
              </div>
                
            </div>

            <div className="center-aligned-column">
                <Button2
                    configObj={{id:""}}
                    action={()=>{setIsOpenSummary(false)}}
                    label={""}
                    fontAwesomeIcon={"fas fa-times color-black"}
                    iconPos="left"
                    classNames={"no-shadow bg-color-warning-outline"}
                />
            </div>
          </div>

        <div className="summary ion-padding">

          {tasks.map((task:any,index:number)=>{
              return(
                <div className="display-flex-row pointer no-wrap border white-card no-shadow ion-margin-top" key={index}>

                    <div className="bg-color-warning" style={{height:"50px",width:"0px"}}>
                    </div>

                    <div className="display-flex-row pointer-none space-between no-wrap full-width marginleft10">
                        <div className="center-aligned-column">
                            <div className="display-flex-row no-wrap">
                                <div className="heading3 font-md-small color-black">{task.taskName}</div>
                            </div>
                        </div>
                        <div className="center-aligned-column padding5">
                            <div className="border white-card no-shadow heading3 bg-color color-black font-md-small center-aligned-column" style={{width:"26px",height:"26px"}}>
                                {task.length}
                            </div>
                            
                        </div>

                    </div>
                </div>
                )
              })}

        </div>
      </div>
    )
  }




  return (
    <div>
      <IonModal
        id=""
        cssClass="today-summary-modal"
        onDidDismiss={()=>setIsOpenSummary(false)}
        isOpen={isOpenSummary}
        >
        <Summary/>
      </IonModal>
      
      <Button2
        configObj={{id:""}}
        action={()=>{setIsOpenSummary(true)}}
        label={""}
        fontAwesomeIcon={"far fa-clipboard color-warning"}
        iconPos="left"
        classNames={"no-shadow button-small hide-in-mobile color-white bg-color-"}
      />
      </div>  
  );
};

export default TodaySummary;
