import { Euler, Vector3 } from "three"
import { addActiveClassOnMaterial, removeActiveClassFromAllTextures, showToast } from "../../UI_methods/global"
import { updateUndoVisibility } from "../area3dmodel/UI_methods"
import { boundingBox } from "../area3dmodel/area3dModel"
import { SELECTED_OBJECT_OUTLINE_NAME, detachModule, updateFraming, updateGroupCenter } from "../common"
import { camera, configuration, controls, getSelectedObjectType, group, groupBoundingBox, redo, renderer, scene, spacePlannerImagesGroup, undo } from "./customizein3d"
import { updateModelPositionOf321, updateOtherModelPositions } from "./helper"
import { deleteObjectFromScene, deselectModule } from "./raycasting"
import $ from "jquery"
import { getArrayOfDistinctValues } from "../../methods"
import { getOriginalPartName } from "../area3dmodel/helper"
import { TextureInfo } from "../../customizer/ProjectConfiguration"
import { updateDragIconPosition } from "./annotations"
var axis = new Vector3(0,1,0)



export function undoHandle() {
    let item = undo.getLastItem()
    if(!item){
      showToast("Nothing to undo",2000,"error")
      return
    }
    deselectModule()
    let action = item.action
    let data = item.data
    let object = null
    switch (action) {  
        case "add":
            object = data.addedModel
            if(object && object.parent){
                let moduleInfo = configuration.getModuleInfoObjectfromModelObject(object)
                let indexInConfig = configuration.getModuleInfoIndexfromModelObject(object)
                redo.add("delete",{deletedModuleInfo:moduleInfo,isAddedAsAddon:moduleInfo?.isAddedAsAddon,index:indexInConfig,deletedModel:object})
                deleteObjectFromScene(scene,spacePlannerImagesGroup,SELECTED_OBJECT_OUTLINE_NAME,object)
                showToast("Deleted modal",2000)
            }
            
        break;
  
        case "delete":
            object = data.deletedModel
            if(object){
                let subCategory = configuration.product.subCategoryName.toLowerCase()
                if(!object.isSprite){
                    addModuleToGroup(object)
                    configuration.addModuleAtPosition(data.deletedModuleInfo,data.index)
                    updateOtherModelPositions(group,configuration,boundingBox,axis,configuration.product.subCategoryName,false)
                    updateFraming(group,groupBoundingBox,camera,controls) 
                }else{
                    spacePlannerImagesGroup.add(object)
                }
                redo.add("add",{addedModel:object})
                showToast("Added modal",2000)
            }
            
        break;
  
        case "duplicate":
            object = data.addedModel
            if(object && object.parent){
                let moduleInfo = configuration.getModuleInfoObjectfromModelObject(object)
                let indexInConfig = configuration.getModuleInfoIndexfromModelObject(object)
                redo.add("delete",{deletedModuleInfo:configuration.modules[indexInConfig],isAddedAsAdon:moduleInfo?.isAddedAsAddon,index:indexInConfig,deletedModel:object})
                deleteObjectFromScene(scene,spacePlannerImagesGroup,SELECTED_OBJECT_OUTLINE_NAME,object)
            }
        break;
  
        case "finish":
            let meshes = data.meshes
            setFinishForUndo(redo,getArrayOfDistinctValues(data.meshes,"mesh"),null)
            let categoryName = configuration.product.categoryName
            meshes?.forEach(currMeshInfo => {
                currMeshInfo.mesh.material = currMeshInfo.material
                let textureInfo = currMeshInfo.mesh.material.userData?.textureInfo || new TextureInfo()
                let partName = getOriginalPartName(categoryName,currMeshInfo.mesh.name)
                configuration?.updateFinish(currMeshInfo.mesh.parent,partName,textureInfo)
                updateActiveClass(currMeshInfo.material)
            });
            showToast("Updated finish",2000)
        break;
    
        default:
        break;
    }
    configuration.setIsConfigChanged(true)
    undo.removeLastItem()
    updateUndoVisibility()
    deselectModule()
  }



  export function redoHandle() {
    let item = redo.getLastItem()
    if(!item){
      showToast("Nothing to redo",2000,"error")
      return
    }
    let action = item.action
    let data = item.data
    let object = null
    switch (action) {  
        case "add":
            object = data.addedModel
            if(object && object.parent){
                deleteObjectFromScene(scene,spacePlannerImagesGroup,SELECTED_OBJECT_OUTLINE_NAME,object)
                showToast("Deleted modal",2000)
            }
        break;
  
        case "delete":
            object = data.deletedModel
            let module = object.userData.configuration
            let isAddedAsAddon = module.isAddedAsAddon
            let subCategory = configuration.product.subCategoryName.toLowerCase()
            if(!object.isSprite){
                if(isAddedAsAddon){

                }else{
                    addModuleToGroup(object)
                    configuration.addModuleAtPosition(data.deletedModuleInfo,data.index)
                    updateOtherModelPositions(group,configuration,boundingBox,axis,configuration.product.subCategoryName,false)
                }
                
                updateFraming(group,groupBoundingBox,camera,controls) 
            }else{
                spacePlannerImagesGroup.add(object)
            }
            showToast("Added modal",2000)
        break;
  
        case "duplicate":
            object = data.addedModel
            if(object && object.parent){
                deleteObjectFromScene(scene,spacePlannerImagesGroup,SELECTED_OBJECT_OUTLINE_NAME,data.addedModel)
                showToast("Deleted modal",2000)
            }
        break;
  
        case "finish":
            let meshes = data.meshes
            let categoryName = configuration.product.categoryName
            meshes?.forEach(currMeshInfo => {
                currMeshInfo.mesh.material = currMeshInfo.material
                let textureInfo = currMeshInfo.mesh.material.userData?.textureInfo || new TextureInfo()
                let partName = getOriginalPartName(categoryName,currMeshInfo.mesh.name)
                configuration?.updateFinish(currMeshInfo.mesh.parent,partName,textureInfo)
                updateActiveClass(currMeshInfo.material)
            });
            showToast("Updated finish",2000)
        break;
    
        default:
        break;
    }
    configuration.setIsConfigChanged(true)
    deselectModule()
    redo.removeLastItem()
  }


  function addModuleToGroup(object:any) {
    group.attach(object)
    updateDragIconPosition(group,renderer,camera)
    updateGroupCenter(group)
  }



  export function setFinishForUndo(undoOrRedu:any,meshes:any,textureInfo:any) {
    let meshesForUndo = []
    meshes.forEach(currMesh=>{
        if(currMesh.isMesh && currMesh.material){
            meshesForUndo.push({mesh:currMesh,material:currMesh.material})
        }
    })
    undoOrRedu.add("finish",{meshes:meshesForUndo,textureInfo:textureInfo})
  }


function updateActiveClass(material:any) {
    try {
        let textureInfo = material.userData.textureInfo
        if(textureInfo){
            let id = `#Finish_${textureInfo.companyName}_${textureInfo.collectionName}_${textureInfo.materialCode}`
            removeActiveClassFromAllTextures()
            addActiveClassOnMaterial(null,$(id)) 
        }else{
            removeActiveClassFromAllTextures()
        }
    } catch (error) {
       console.log(error) 
    }
    
}