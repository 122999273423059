import { IonIcon } from "@ionic/react";
import { search } from "ionicons/icons";
import React from "react";
import "../../../style/ui-components/progress.css"
 
 
const ProductsSearch: React.FC<{
    configObj:any
    action:()=>void
    total:number
    completed:number
  }> = (props) => {


 
  return (
    <div className="progress-ring-wrapper" id="" onClick={()=>props.action()}>
      <div className="progress-ring-circle center-aligned-column pointer" style={{margin:"auto"}}>
        <div className="progress-ring-text heading1 color-white font-small">{props.completed}/{props.total}</div>
      </div>
      <div className="text-center heading1 hide-in-mobile font-small">Category</div>
      <div className="text-center heading1 hide-in-mobile font-small">Added</div>
    </div>
  );
};

export default ProductsSearch;
