import React from "react";
import { Floorplan } from "../../utils/customizein3d/floorplanner/floorplan";
import Button2 from "../common/ui-components/buttons/Button2";

const FloorplanActions: React.FC<{
  floorplan:Floorplan
  moduleName:string
}> = (props) => {
 

  
  return ( 
    <>
      <div className="hide-in-draw-walls ion-padding">
        <div className=" hide-button-text-in-mobile">
          <Button2
            configObj={{id:"rotateFloorplan"}}
            action={()=>{props.floorplan.rotateFloorplan()}}
            label={"Rotate Floorplan"}
            fontAwesomeIcon={"fas fa-undo color-danger"}
            iconPos="left"
            classNames={"shadow-medium display-none  button-small no-border"}
          /> 
          <Button2
            configObj={{id:""}}
            action={()=>{props.floorplan.horizontalFlip()}}
            label={"Flip"}
            fontAwesomeIcon={"fas fa-exchange-alt color-primary"}
            iconPos="left"
            classNames={"shadow-light display-none button-normal"}
          />
        </div>
      </div>
    </>
  );
};

export default FloorplanActions;
