import $ from "jquery";
import React, { useEffect, useState } from "react";
import { customizerConfig, projectConfiguration } from "../../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { enableAnchorDrag, showCameraPropertiesPanel } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/floorplanUI";
import { applicationConfig } from "../../../../../../../utils/methods";
import CreateRender2D from "./CreateRender2D";
import CreateRender3D_new from "./CreateRender3D_new";

const CreateRender: React.FC<{
  isFinalizeMode:boolean
  isFloorPlanMode:boolean
}> = (props) => {

  const[areasList,setAreasList] = useState([])
  //whether selecting walls or anchr..based on call the submit function
  const[currSelectedCamera,setCurrSelectedCamera] = useState(null)

  
  const[isFullScreenMode,setIsFullScreenMode] = useState(customizerConfig.currentRenderingType === "3d" ? true : false)


  useEffect(()=>{
    applicationConfig.setState("customizer",{currSelectedCamera:currSelectedCamera})
  },[currSelectedCamera])

  useEffect(()=>{
    disableFullScreenMode()
    showCameraPropertiesPanel()

    // applicationConfig.setFunctionRef("customizer",{enableCameraSettingsMode:enableCameraSettingsMode})
    // applicationConfig.setFunctionRef("customizer",{disableCameraSettingsMode:disableCameraSettingsMode})
    // applicationConfig.setFunctionRef("customizer",{setShowRenderModal:setShowRenderModal})
  },[])


  useEffect(()=>{
    if(customizerConfig){
      if(customizerConfig.currentRenderingType === "3d"){
        let areaList = projectConfiguration.areasList.filter(area=>area.area_name!=="Other")
        setAreasList(areaList)
      }
      if(customizerConfig.currentRenderingType === "2d"){
        setAreasList([{area_name:applicationConfig.projectName}])
      }
    }
    
  },[customizerConfig])


  useEffect(()=>{
    if(props.isFinalizeMode){
      // setTimeout(() => {
        enableAnchorDrag()
      // }, 1200);
      if(customizerConfig.currentRenderingType === "3d"){
        $("#cameraSettingsContainer").addClass("display-none")
      }else{
        $("#cameraSettingsContainer").removeClass("display-none")
      }
      // enableCameraSettingsMode("BedRoom1")
    }
  },[props.isFinalizeMode])

 

  function disableFullScreenMode() {
    setIsFullScreenMode(false)
    customizerConfig.setIsFullScreenMode(false)
  }

 


  return (
    <>
    {customizerConfig.currentRenderingType === "3d"?
    <CreateRender3D_new
      areasList={areasList}
    />
    :
    <CreateRender2D
    />
    }
 
   
 </>
  );
};

export default CreateRender;
 