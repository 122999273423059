import { IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React from 'react';
import "../../../style/showconfig.css";
import Button2 from '../../ui-components/buttons/Button2';
import ConfigCustomizein3D from './ConfigCustomizein3D';
import ConfigCustomizer from './ConfigCustomizer';
import ConfigMoodBoard from './ConfigMoodBoard';
import { configuration } from '../../../../utils/moodboard/moodboard';


const Summary: React.FC<{
    currentConfigObject:any;
    setShowPricing:(value:boolean)=>void
    moduleName:string
}> = (props) => {

// const[products,setProducts]:any = useState(null)
// const [currProduct, setCurrProduct]:any = useState(null);

// const[configuration,setConfiguration]:any = useState(props.configuration)


// const[summaryButtonClasses,setSummaryButtonClasses]:any = useState([])

// const[currArea,setCurrArea] = useState(props.currentConfigObject?.areaName)


// useEffect(()=>{
//     let products = getAllProductsFromConfig(configuration,props.currentConfigObject,props.currentConfigObject.viewName)
//     setProducts(products)
//     //If summary button is clicked 
//     if(props.currSelection==="Summary"){
//         setSummaryButtonClasses(["configuration-area summary-button --is-active"])
//     }else{
//         setSummaryButtonClasses(["configuration-area summary-button"])
//     }
//     disposeVariables([products])
// },[configuration])


  function Middle() {
    return(
      <div className='center-aligned-column marginTop5'>
        {props.moduleName.toLowerCase() === "customizer"?
          <div className='display-flex-row'>
            <span className='header-heading color-primary'>{props.currentConfigObject.projectName}</span>
            <span className='header-heading color-primary marginleft5 marginRight5'>/</span>
            <span className='header-heading color-primary'>{props.currentConfigObject.areaName}</span>
          </div>
          :props.moduleName.toLowerCase() === "customizein3d"?
          <div className='display-flex-row'>
            <span className='header-heading color-primary'>{props.currentConfigObject.productName}</span>
          </div>
          :
          <div className='display-flex-row'>
            <span className='header-heading color-primary'>{configuration.areaName}</span>
            <span className='header-heading color-primary marginleft5 marginRight5'>/</span>
            <span className='header-heading color-primary'>{configuration.moodboardId}</span>
          </div>
        }
      </div>
    )

  }


  return (
    <React.Fragment>
          <div className="summary-header position-relative">
            <div className="display-flex-row background-white">
                <div className="brand">
                  <img id="companyLogo" src="/assets/images/opus.png" alt="Opus"/>
                </div>

                <div className='right-middle ion-margin-start' style={{right:"2rem"}}>

                <Button2
                  configObj={{}}
                  action={()=>props.setShowPricing(false)}
                  label={"Close"}
                  fontAwesomeIcon={"fas fa-times color-primary"}
                  iconPos="left"
                  classNames={"shadow-light ion-margin-start close-summary-button bg-color-black"}
                />
                  {/* <div className="click-effect summary-back-button  ion-margin-start center-aligned-column" onClick={()=>props.setShowPricing(false)}>
                    <div className="header-heading color-primary letter-spacing font-normal display-flex-row  color-black"> 
                      <IonIcon icon={arrowBack} className="color-danger font-large center-aligned-column" /> <span className="marginleft5">Close</span>
                    </div>
                  </div> */}
                </div>

                

                <div className='top-middle hide-in-mobile'>
                  <Middle/>
                </div>

            </div>
        </div>

        <div className="full-height-width background-white summary-content-wrapper"> 
          <div className="heading2 display-flex-row letter-spacing font-large">
          <span className="center-aligned-column marginRight5"><i className="far fa-clipboard color-primary font-large"></i></span>
            Summary
          </div>
          <div className="full-height-width overflow-y-scroll">
            {props.moduleName.toLowerCase() === "customizer"?
            <ConfigCustomizer
              areaName={props.currentConfigObject?.areaName}
              configObj={props.currentConfigObject}
              areas={[{area_name:props.currentConfigObject?.areaName}]}
            />
            :
            props.moduleName.toLowerCase() === "customizein3d"?
            <ConfigCustomizein3D
              configObj={props.currentConfigObject}
              configuration={props.currentConfigObject.getConfiguration()}
            />
            :
            props.moduleName.toLowerCase() === "moodboard"?
            <ConfigMoodBoard
              configObj={props.currentConfigObject}
              areas={props.currentConfigObject.getAreasList()}
            />
            :
            null}
          </div>
        </div>
  </React.Fragment>
  );
};

export default Summary;
