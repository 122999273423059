import React, { useEffect, useState } from "react";
import { hideComponentLoader, showComponentLoader } from "../../../../utils/UI_methods/global";
import { checkFromCache, untarAndSaveToCache } from "../../../../utils/cache/cache";
import { projectConfiguration } from "../../../../utils/customizein3d/area3dmodel/area3dModel";
import { getUrlPrefix } from "../../../../utils/customizer/customizer";
import { convertHtmlToPdf } from "../../../../utils/html2canvas/html2pdf";
import { applicationConfig, shareProject, waitFor } from "../../../../utils/methods";
import { getProductImageUrl } from "../../../../utils/moodboard/helper";
import ShareLink from "../../ui-components/links/sharelink/ShareLink";
import Loader from "../main-ui/Loader";

  const ConfigCustomizer: React.FC<{
    areaName: string;
    configObj: any;
    areas:Array<any>;
  }> = (props) => {

    const[areaProductsMapping,setareaProductsMapping] = useState(null)
    const[areasList,setAreasList] = useState([])
    const[areaConfig,setAreaConfig] = useState({})
    const[loaderId] = useState("productImageLoader")
    

    
  useEffect(()=>{
    hideComponentLoader(loaderId)
      setAreasList(projectConfiguration.areasList.filter(area=>area.area_name!=="Other"))
      let configuration = projectConfiguration.projectConfiguration
      let mapping = {}
      for (const areaKey in configuration) {
        let products = getProductsList(configuration[areaKey].areaConfiguration)
        mapping[areaKey] = {
          products:products,
        }
      }
      setareaProductsMapping(mapping)
  },[])

  function getProductsList(configuration:any) {
    let products = []
    for (const productGroupKey in configuration) {
      for (const productInstanceKey in configuration[productGroupKey]) {
        if(configuration[productGroupKey][productInstanceKey].enabled){
          products.push(configuration[productGroupKey][productInstanceKey])
        }
      }
    }
    return products
  }


  useEffect(()=>{
    if(areaProductsMapping){
      setTimeout(() => {
        for (const areaKey in areaProductsMapping) {
          let products = areaProductsMapping[areaKey].products
          for (const product of products) {
            loadAndDisplayImages(areaKey,product)
            hideComponentLoader(loaderId)
          }
        }
      }, 500);
    }
  },[areaProductsMapping])
  
 

  async function loadAndDisplayImages(areaName:string,product: any) {
    let imageUrlKey = getProductImageUrl("",applicationConfig?.clientName,
      product.productName,
      product.categoryName,
      product.subCategoryName
    );
  let urlPrefix = getUrlPrefix(imageUrlKey);
  let tarFileKey = applicationConfig.awsConfig.getTarFileKey("products", {
    clientName: applicationConfig?.clientName,
    category: product.categoryName,
    subCategory: product.subCategoryName,
    productName: product.productName,
  });
  await untarAndSaveToCache(applicationConfig.awsConfig, urlPrefix, tarFileKey)
    ?.then((data) => {
      displayImages(areaName,product);
    })
    .catch((err) => {
      displayImages(areaName,product);
    });
  }

  async function displayImages(areaName:string,product: any) {
    let imageUrlKey = getProductImageUrl("",applicationConfig?.clientName,
      product.productName,
      product.categoryName,
      product.subCategoryName
    );

    let image = document.getElementById(`reviewProduct${areaName}${product.productName}`)
    await checkFromCache(imageUrlKey)
      ?.then((url: any) => {
        image?.setAttribute("src", url);
      })
      .catch((err) => {
        image?.setAttribute("src", "assets/images/placeholder.png");
      });
    // }
  }



    function getConfiguration(productName:string) {
      let productConfig = areaConfig[productName]
      for (const partName in productConfig) {
        let partConfig = productConfig[partName] 
        if(partConfig?.companyName?.includes("Default")){
          continue
        }
        return `${partConfig.companyName} / ${partConfig.collectionName} / ${partConfig.materialCode}`
      }
      return "Default / Default / Default"
    }

    async function DownloadAsPdf() {
      showComponentLoader(loaderId)
      await waitFor(300)
      await convertHtmlToPdf("customizer",props.configObj,[],`${projectConfiguration.projectName}`)
      hideComponentLoader(loaderId)
    }

    
    async function shareAPI() {
      showComponentLoader(loaderId)
      await shareProject(props.configObj,"customizer")
      hideComponentLoader(loaderId)
    }



    return (
      <div className="full-height-width summary-wrapper overflow-y-scroll">
        <div className="summary-details-wrapper" id="productSummary">
          <Loader
            className="fade-background-loader --is-active"
            id={loaderId}
            data={{}}
            loaderType="dots"
          />
          {areasList.map((area:any,index:number)=>{
          let products = areaProductsMapping[area.area_name].products
          return(
            <div className="" key={index}>
              {products.length?
              <div className="heading2">{area.area_name}</div>
              :null}
                {products.map((product:any,index:number)=>{
                  // let productObject = props.configObj.projectProductsList.find(currProduct=>stringWithoutWhiteSpace(currProduct.product_name).includes(stringWithoutWhiteSpace(product)) || stringWithoutWhiteSpace(product).includes(stringWithoutWhiteSpace(currProduct.product_name)))
                  // let productConfig = getConfiguration(product)
                  return(
                    <div className="display-flex-row padding10 no-wrap space-between padding10 border-bottom" key={index}>
                      {products.length?
                      <>
                      <div className="product-details display-flex-row no-wrap">
                        <div className="image center-aligned-column" style={{width:"15rem"}} >
                          <img src="" alt="" className="full-height-width padding10"
                            id={`reviewProduct${area.area_name}${product.productName}`}
                          />
                        </div>
                        <div className="other-details">
                          <div className="product-name letter-spacing heading3">{product.productName}</div>
                          <div className="config color-dark">
                            <span>Width: 952 Cm, </span>
                            <span className="marginleft5">Height: 952 Cm, </span>
                            <span className="marginleft5">Depth: 952 Cm</span>
                            <div><span className="heading3">Configuration :</span> {}</div>
                          </div>
                          {/* <div className="rupee-sign heading3">  ₹ {productObject?.price}</div> */}
                          <div className="font-small category-name ion-margin-top ion-margin-bottom letter-spacing color-white bg-color-black" style={{padding:"0.2rem 1rem",width:"max-content"}}>{product?.categoryName}</div>
                        </div>
                      </div>
                      <div className="price hide-in-mobile rupee-sign heading1"> ₹ {}</div>
                      </>
                    :null}
                    </div>
                  )
                })}
            </div>
          )
          })}

        </div>

        <div className="summary-actions-wrapper">
          <div className="ion-margin-bottom">
            <div className="font-large heading2 letter-spacing color-black marginBottom5">
              Total Price
              </div>
            <div><span className="heading3 font-large marginRight5 rupee-sign">₹</span> <span className="heading3 letter-spacing rupee-sign" style={{fontSize:"3rem"}}>45,250</span>  </div>
          </div>
         
          <div className="ion-margin-top">

            <div className="display-flex-row">
              <button className="op-btn op-btn-primary-inverse op-btn-lg" onClick={DownloadAsPdf}>
                <i className="fas fa-download font-large color-dark" aria-hidden="true"></i> 
                <span className="letter-spacing font-medium marginleft5" >Download Pdf</span>
              </button>
              <button className="op-btn op-btn-primary op-btn-lg marginleft10" onClick={shareAPI}>
                <i className="fas fa-share-alt font-large color-white" aria-hidden="true"></i> 
                <span className="letter-spacing color-white font-medium marginleft5" >Share</span>
              </button>
            </div>

            {/* <div className="marginTop10">
              <button className="op-btn marginTop5 op-btn-primary-inverse op-btn-lg" onClick={printSummary}>
                <i className="fas fa-print font-large color-danger" aria-hidden="true"></i> 
                <span className="letter-spacing font-medium marginleft5" >Print</span>
              </button>
            </div> */}
          
            <div className="position-relative marginTop10">
              <ShareLink
                shareItemId={projectConfiguration?.projectId}
                loaderId = {loaderId}
                moduleName="projects"
                textClass="color-black"
                isShareButton={true}
              />
            </div>

            


          </div>
        </div>

      </div>
    );
  };

  export default ConfigCustomizer;