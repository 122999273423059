export class ModuleClass{
    allow_delete
    allow_duplication:any
    allow_movement:any
    allow_rotation:any
    category_name:any
    dim_X:any
    dim_Y:any
    dim_Z:any
    display_name:any
    is_addon:any
    module_description:any
    module_id:any
    module_name:any
    module_type:any
    module_type_id:any
    product_id:any
    product_name:any
    status:any
    sub_module_type:any
    sub_category_name:any
    price:number
    isDuplicatedSectional:boolean
  
    constructor(module:any){
      this.allow_delete = module.allow_delete || module.allowDelete
      this.allow_duplication = module.allow_duplication || module.allowDuplication
      this.allow_movement = module.allow_movement || module.allowMovement
      this.allow_rotation = module.allow_rotation || module.allowRotation
      this.category_name = module.category_name || module.categoryName
      this.dim_X = module.dim_X || module.dimX
      this.dim_Y = module.dim_Y || module.dimY
      this.dim_Z = module.dim_Z || module.dimZ
      this.display_name = module.display_name || module.displayName
      this.is_addon = module.is_addon || module.isAddon
      this.module_description = module.module_description || module.moduleDescription
      this.module_id = module.module_id || module.moduleId
      this.module_name = module.module_name || module.moduleName
      this.module_type = module.module_type || module.moduleType
      this.module_type_id = module.module_type_id || module.moduleTypeId
      this.product_id = module.product_id || module.productId
      this.product_name = module.product_name || module.productName
      this.status = module.status || module.status
      this.sub_module_type = module.sub_category_name || module.subModuleType
      this.sub_category_name = module.sub_category_name || module.subCategoryName
      this.price = module.price || 0
      this.isDuplicatedSectional = module.isDuplicatedSectional
    }
  }
  