import React, { useState } from "react";
import { useHistory } from "react-router";
import { closeSideMenu } from "../../../../../../utils/store/UI_methods";
import { waitFor } from "../../../../../../utils/methods";

 

const SideMenu: React.FC<{
  routesList:any
}> = (props) => {

  const history = useHistory()


  const[primaryOptions] = useState([
    {
      name:"My account",
      iconClass:"far fa-user"
    },
    {
      name:"Help",
      iconClass:"far fa-comment"
    },
    {
      name:"Contact us",
      iconClass:"fas fa-phone-alt"
    },
    {
      name:"About us",
      iconClass:"far fa-comment-dots"
    },
  ])

  const[otherOptions] = useState([
    {
      name:"Log out",
      iconClass:"fas fa-power-off color-danger"
    } 
  ])

 

  function gotoPage(page:string) {
    history.push(`/${page.toLowerCase()}`)
    closeSideMenu()
  }

  function Header() {
    return(
      <div className="full-width border-bottom">
        <div className="display-flex-row padding10 space-between full-width">
          <div className="display-flex-row no-wrap">
            <div className="logo center-aligned-column"><img src="/assets/images/logos/estre.png" height="60" alt=""/></div>
          </div>
          
          <div onClick={closeSideMenu} className="center-aligned-column  padding5"><i className="fas fa-times font-ex-large pointer-none color-dark"></i></div>
        </div>
      </div>
    )
  }

  function Footer() {
    return(
      <div className="bottom-left full-width bg-color-warning">
        <div className="display-flex-row padding10 space-between full-width">
          <div className="">
              <div className="heading2 font-medium color-white">Add to home screen</div>
              <div className="heading4 font-medium color-white">To get updates & more</div>
          </div>
          <div className="center-aligned-column"><i className="fas fa-external-link-alt font-large color-white"></i></div>
        </div>
      </div>
    )
  }
 

  
  function MainOptions() {
    return(
      <div className="full-width border-bottom ion-margin-top padding10">
      {props.routesList.map((currRoute:any,index:number)=>{
        return(
          <div key={index} onClick={()=>gotoPage(currRoute.routeName)} className="display-flex-row marginTop10 padding10 space-between full-width">
            <div className="display-flex-row no-wrap pointer-none">
              <div className="center-aligned-column padding10"><i className={`${currRoute.iconClass } color-warning font-ex-large`}></i></div>
              <div className="marginleft5 center-aligned-column">
                <div className="display-flex-row no-wrap">
                  <div className="heading4 font-large color-black capitalize">{currRoute.routeName} </div>
                  <div className="heading4 font-large color-black capitalize"> ({currRoute.count})</div>
                </div>
              </div>
            </div>
            {/* <div className="center-aligned-column padding5"><i className="fas fa-chevron-right font-medium color-dark"></i></div> */}
          </div>
        )
      })}
    </div>
    )
  }

    
  function OtherOptions() {
    return(
      <div className="full-width ion-margin-top">
      {otherOptions.map((item:any,index:number)=>{
        return(
          <div key={index} className="display-flex-row padding10 border-top space-between full-width">
            <div className="display-flex-row no-wrap ">
              <div className="center-aligned-column padding10 "><i className={`${item.iconClass } color-warning font-medium`}></i></div>
              <div className="marginleft5 center-aligned-column">
                <div className="heading2 color-black">{item.name}</div>
              </div>
            </div>
            {/* <div className="center-aligned-column padding5"><i className="fas fa-chevron-right font-medium color-dark"></i></div> */}
          </div>
        )
      })}
    </div>
    )
  }
 
 
  return (
    <div className="full-height-width side-menu-wrapper bg-color-white" id="sideMenuWrapper">
       <Header/>
       {/* <CategoriesLink/> */}
       <MainOptions/>
       {/* <OtherOptions/> */}
       <Footer/>
    </div>
  );
};

export default SideMenu;
