export class ProductViewer3D {
    constructor() {
         
    }

    init(elementId) {
        // this.element = document.getElementById(elementId);
        // this.scene = new THREE.Scene();
        // this.scene.background = new THREE.Color(0xffffff)
        // this.canvasDimensions = this.getCanvasDimensions();

        // this.camera = new THREE.PerspectiveCamera(75, this.canvasDimensions.width / this.canvasDimensions.height, 0.1, 1000);
        // this.camera.position.z = 5;

        // this.renderer = new THREE.WebGLRenderer();
        // this.renderer.setSize(this.canvasDimensions.width, this.canvasDimensions.height);
        // this.renderer.xr.enabled = true

        // this.controls = new OrbitControls( this.camera, this.renderer.domElement )
        // this.setInitialOrbitControls(this.controls)
        // this.element.appendChild(this.renderer.domElement);

        // this.loadModel()

        // this.animate();
    }

    animate = () => {
    }
   
}
