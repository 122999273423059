import { IonIcon } from "@ionic/react";
import {
  colorPalette, cube, shareSocial, walk
} from "ionicons/icons";
import { default as React, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { updateModelAvailability } from "../../../../services/api";
import { getItemFromLocalStorage } from "../../../../utils/cache/localstorage";
import {
  getCollectionLabel,
  getFilteredArray, getUserType,
  logger
} from "../../../../utils/methods";
import Modal from "../../../common/shared-components/Modals/Modal";
import ConfigMoodBoard from "../../../common/shared-components/Share/ConfigMoodBoard";

const ProjectProgress: React.FC<{
  configObj: any;
  currProjectId: number;
  currProjectName: string;
  setCurrProjectDetails: (id: number, name: string) => void;
  setOpenProjectAction: (val: boolean) => void;
  setCurrMoodboardId: (id: string) => void;
}> = (props) => {
  const history = useHistory();
  const [showFinalSummary, setShowFinalSummary] = useState(false);
  const [collectionLabel, setCollectionLabel] = useState("");
  var isCollectionConfirmed = false;

  const [moodboardId, setMoodboardId] = useState(false);
  const [isRenderCreated, setIsRenderCreated] = useState(false);

  useEffect(() => {
    if (props.configObj) {
      //Get All areas of project
      let moodboardId = null;
      let moodboardList = getFilteredArray(props.configObj?.data.moodboardList,"project_id",props.currProjectId.toString());
      if (moodboardList) {
        moodboardId = moodboardList[0]?.moodboard_id;
      }
      let label = getCollectionLabel(moodboardId)
      setCollectionLabel(label)

      let projectConfig = getItemFromLocalStorage(`Customizer_3d_${props.currProjectName}_${props.currProjectId}`)
      if(projectConfig){
        setIsRenderCreated(projectConfig.isRenderCreated)
      }

      props.configObj.setCurrProjectName(props.currProjectName)
      props.configObj.setCurrProjectId(props.currProjectId)
      

    }
  }, [props.configObj,props.currProjectId,props.currProjectName]);


  function gotoVirtualTour() {
    props.setCurrProjectDetails(props.currProjectId, props.currProjectName);
    props.setOpenProjectAction(false);
    logger?.info("projects","projectactions",`Walkthrough selected: ${props.currProjectId}` )
    history.push("/3dwalkthrough");
  }

  async function gotoCustomizer(area:any) {
    window.location.href = '/customize'
  }

  function gotoMoodBoard() {
    //Check if moodboard if exist
    // let projectId:any = props.currProjectId
    let moodboardId = null;
    let moodboardList = getFilteredArray(props.configObj?.data.moodboardList,"project_id",props.currProjectId.toString());
    if (moodboardList) {
      moodboardId = moodboardList[0]?.moodboard_id;
    }

    // props.applicationConfigObj.setMoodboardId(moodboardId)
    // props.setCurrProjectDetails(props.currProjectId, props.currProjectName);
    // props.configObj.setCurrProjectName(props)
    props.setCurrMoodboardId(moodboardId);
    if (getUserType()?.toLowerCase() != "admin" && isCollectionConfirmed) {
      setMoodboardId(moodboardId);
      setShowFinalSummary(true);
    } else {
      props.setOpenProjectAction(false);
      logger?.info("projects","projectactions",`Edit collection selected: ${props.currProjectId}` )
      history.push('/edit-moodboard');
    }
  }

  function SummaryFooterContent() {
    return (
      <>
        {getUserType()?.toLowerCase() === "admin" ? (
          <div>
            <button
              className="op-btn op-btn-primary"
              onClick={() => history.push("collection")}>
              Edit collection
            </button>
          </div>
        ) : null}
      </>
    );
  }



  return (
    <>
      {/* <div className="middle color-white width80">

        <div className="project-actions full-width no-wrap position-relative display-flex-row">
          <div
            className="white-card shadow-medium project-action"
            onClick={gotoVirtualTour}>
            <IonIcon
              className="icon color-dark"
              icon={settingsOutline}></IonIcon>
            <div className="text">Project Walkthrough</div>
          </div>
        </div>
      </div> */}


      <div onClick={updateModelAvailability} className="bottom-left display-none zindex30 padding10">
        <div className="heading3 font-small color-white">Update model availability</div>
      </div>

      <div className="middle no-wrap  center-aligned-column" style={{width:"80%"}}>
        <Modal
          id=""
          classNames="share-modal"
          onDismiss={setShowFinalSummary}
          Content={() => (
            <div className="full-height overflow-y-scroll">
              <ConfigMoodBoard
                configObj={props.configObj}
                areas={getFilteredArray(
                  props.configObj?.data.moodboardList,
                  "moodboard_id",
                  moodboardId
                )}
              />
            </div>
          )}
          isOpen={showFinalSummary}
          heading="Summary"
          FooterContent={SummaryFooterContent}></Modal>
        {/* 
          <div className="color-white">
            <div className="center-aligned-row white-card walkthrough-link" onClick={gotoMoodBoard}>
              <span className="color-white center-aligned-column ion-margin-end font-medium">Create collection</span> 
              <span className="ion-margin-left border"  style={{padding:"7px 12px",borderRadius:"50%"}}><i className="fas fa-chevron-right"></i></span>
            </div>
          </div>

          <div className="center-aligned-column">
            <div className="background-black-fade" style={{width:"20px",height:"2px"}}></div>
          </div>

          <div className="color-white">
            <div className="center-aligned-row white-card walkthrough-link" onClick={gotoVirtualTour}>
              <span className="color-white center-aligned-column ion-margin-end font-medium">Project Walkthrough</span> 
              <span className="ion-margin-left border"  style={{padding:"7px 12px",borderRadius:"50%"}}><i className="fas fa-chevron-right"></i></span>
            </div>
          </div> */}
        
        {/* <div className="project-actions no-wrap position-relative display-flex-row" >
          <div className="white-card shadow-medium project-action"
            onClick={gotoVirtualTour}
            style={{flexDirection:"row",width:"100%",aspectRatio:"unset",padding:"2.5rem"}}
            >
              <img src="assets/icon/virtualtour_white.png" alt="" className="pointer-none" style={{width:"6rem",filter:"brightness(1)"}}/>
            <div className="text font-large letter-spacing">Project Walkthrough</div>
          </div>
        </div> */}

        <div className="position-relative  center-aligned-row"
          style={{ width: "100%", display: "" }}>
          <div className="project-actions width50 no-wrap position-relative display-flex-row">
            <div className={isRenderCreated?"white-card  shadow-medium project-action":"white-card disable shadow-medium project-action"} onClick={gotoVirtualTour}
              >
              <IonIcon
                className="icon color-danger"
                icon={walk} style={{margin:"0"}}></IonIcon>
              <div className="text font-medium letter-spacing">Walkthrough</div>
            </div>
          </div>

          <div className="project-actions  width50 no-wrap position-relative display-flex-row">
            <div className="white-card shadow-medium project-action"
            onClick={gotoCustomizer}
            >
              <IonIcon
                className="icon color-danger"
                icon={cube} style={{margin:"0"}}></IonIcon>
              <div className="text font-medium letter-spacing">3D Configure</div>
            </div>
          </div>

        </div>



        <div className="position-relative  center-aligned-row"
          style={{ width: "100%", display: "" }}>
          <div className="project-actions width50 no-wrap position-relative display-flex-row">
            <div
              className="white-card  shadow-medium project-action"
              onClick={gotoMoodBoard}
              >
              <IonIcon
                className="icon color-danger"
                icon={colorPalette} style={{margin:"0"}}></IonIcon>
              <div className="text font-medium letter-spacing">{collectionLabel}</div>
            </div>
          </div>

          <div className="project-actions  width50 no-wrap position-relative display-flex-row">
            <div className="white-card shadow-medium project-action"
            >
              <IonIcon
                className="icon color-danger"
                icon={shareSocial} style={{margin:"0"}}></IonIcon>
              <div className="text font-medium letter-spacing">Share Project</div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default ProjectProgress;
