import $ from "jquery";
import Konva from "konva";
import { getWindowWidth } from "../UI_methods/global";
import { applicationConfig, getCurrentConfigurationFromLocalStorage, logger } from "../methods";
import { expandItemsPanel, hideAllProductAnnotations, hideItemsPanel } from "./UI_methods";
import { addImageInConfiguration, getMoodBoardConfigKey } from "./helper";
import { currSelectedGroup, moodboardConfig } from "./moodboard";

var addonsList = []

var radius = 0
var deltaY = 3

export var rectWidth = 25
export var rectHeight = 20

export var currSelectedAddon = null

export var isAddonConfirm = false

var currSelectedColor = ""

// export function addAddon(data:any) {
//     addCircle()
// }


export function addAddon(productGroup:any = null,isAddIcon=true) {
    let group =  productGroup || getCurrProductGroup()
    let layer = group.parent


    let pos = getLastElementPosition(group)

    let posY = pos.y + rectHeight + deltaY
    if(!pos.y){ // First element
        posY = deltaY
    }

    let topLeft = group.find(".topLeft")[0]
    let image = group.find(".canvasItem")[0]
    let posX = topLeft.attrs.x + image.attrs.width + radius + 5


    let addonGroup = new Konva.Group({
        x: posX,
        y: posY,
        draggable: true,
        name: "addon"
    })

    // let shape = new Konva.Circle({
    //     radius: radius ,
    //     stroke: 'gray',
    //     strokeWidth: 1,
    //     shadowBlur: 2
    // });

    let shape = new Konva.Rect({
        width: rectWidth,
        height: rectHeight,
        shadowBlur: 1,
        cornerRadius: 3,
        strokeWidth:0.4,
        stroke: 'gray',
    });

    //Parent of that group
    addonGroup.add(shape)
    group.add(addonGroup)

    if(isAddIcon){
        addFillIcon(addonGroup,layer)
    }

    // updatePlusIconPosition(group)

    // addCloseIcon(addonGroup,addonGroup.parent)

    addonGroup.on("mousedown touchstart",function(event) {
     
        currSelectedAddon = addonGroup

        addonGroup.scale({x:0.9,y:0.9})
        layer.draw()

        setTimeout(() => {
            addonGroup.scale({x:1,y:1})
            layer.draw()
        }, 100);

        if(!currSelectedAddon.attrs.isAddonSelected){
            expandItemsPanel()
            applicationConfig?.functions.moodboard.updateTabValue("colorSwatchContainer","colorSwatch")
        }
        // selectProduct()
     
        updateAddonsActionPosition()
     
    })

    addonGroup.on("mouseover", function () {
        document.body.style.cursor = "pointer";
    })
    addonGroup.on("mouseout", function () {
        document.body.style.cursor = "default";
    })

    currSelectedAddon = addonGroup
    layer.draw()
}


export function updateAddonsActionPosition() {
    let canvasContainerId = getCurrProductGroup().attrs.canvasContainerId

    let x = currSelectedAddon.absolutePosition().x + rectWidth + 5
    let y = currSelectedAddon.absolutePosition().y
    let selectionBox = null

    if(!currSelectedAddon.attrs.isAddonSelected){
        // selectionBox = $("#"+canvasContainerId).parents(".moodboard-canvas-container").find(".addons-confirm-container")
        // selectionBox.css({"top":y,"left":x})
        // selectionBox.addClass("--is-active")
    }else{
        selectionBox = $("#"+canvasContainerId).parents(".moodboard-canvas-container").find(".addon-actions-container")
        selectionBox.css({"top":y,"left":x})
        selectionBox.addClass("--is-active")  
    }
}

export function updateConfirmButtonPosition() {
    let canvasContainerId = getCurrProductGroup().attrs.canvasContainerId

    let x = currSelectedAddon.absolutePosition().x + rectWidth + 2
    let y = currSelectedAddon.absolutePosition().y
    let selectionBox = null
    selectionBox = $("#"+canvasContainerId).parents(".moodboard-canvas-container").find(".addons-confirm-container")
    selectionBox.css({"top":y,"left":x})
    selectionBox.addClass("--is-active")

    isAddonConfirm = false
}


export function updateAddonFill(data:any) {
    currSelectedAddon.children[0].fill("#"+data.hex_code)
    currSelectedColor = data.hex_code
    updateLayer()
    updateConfirmButtonPosition()
    removeFillIcon()

    logger?.info("editcollection","addon",`Added addon: ${data.hex_code}`)
}

export function confirmAddonFill() {
    currSelectedAddon.attrs.isAddonSelected = true
    isAddonConfirm = true
    hideItemsPanel()
    addAddon()
    updateConfiguration()
}

function updateConfiguration() {
    let image = currSelectedGroup.attrs.image
    let canvasContainerId = getCurrProductGroup().attrs.canvasContainerId
    let stage = moodboardConfig.getStage(canvasContainerId)
  

    let key = getMoodBoardConfigKey(String(applicationConfig?.getMoodboardId()),applicationConfig?.getCurrArea())
    let configuration = getCurrentConfigurationFromLocalStorage(key)
    let addonsList = configuration[image.productName+"_"+image.imageId].addonsList
    if(addonsList?.length){
        image.addonsList = [...addonsList,currSelectedColor]    
    }else{
        image.addonsList = [currSelectedColor] 
    }
    addImageInConfiguration(image,stage)
}

export function cancelAddonFill() {
    if(currSelectedAddon){
        currSelectedAddon.attrs.isAddonSelected = false
        currSelectedAddon?.children[0]?.fill("#ffffff")
        addFillIcon(currSelectedAddon,getCurrProductGroup()?.parent)
        updateLayer()
        // hideItemsPanel()
    }
 
}

export function drawAddonsFromConfig(addonsList:Array<any>,productGroup:any) {
    for (const color of addonsList) {
        addAddon(productGroup,false)
        updateAddonFill({hex_code:color})
    }
    confirmAddonFill()
}

export function addonInitialState() {
    
}


export function addFillIcon(group:any,layer:any) {
    //Dont draw if already exists
    if(group.find(".fillIcon")[0]){
        return
    }
    let imageWidthHeight = 15
    if(getWindowWidth() < 481){
      imageWidthHeight = 10
    }
    let imageSrc = "assets/icon/add.png"
    let imageObj:any = new Image()
    imageObj.onload = function () {
      var moduleIconImage = new Konva.Image({
        image: imageObj,
        width: imageWidthHeight,
        height: imageWidthHeight,
        x:rectWidth/2 - imageWidthHeight/2,
        y:rectHeight/2 - imageWidthHeight/2,
        opacity:0.2,
        name:"fillIcon"
      })
      group?.add(moduleIconImage)
      layer?.draw()
    };
    imageObj.src = imageSrc;
  }

  export function removeFillIcon(){
    let group = getCurrProductGroup()
    let icon = group.find(".fillIcon")[0]
    icon?.remove()
    updateLayer()
  }
  

export function removeAddon() {
    let layer = getCurrProductGroup().parent
    let group = getCurrProductGroup()
    currSelectedAddon.removeChildren()
    currSelectedAddon.remove()  
    layer.draw()
    updateAddonsListPosition(group,layer)
    hideAllProductAnnotations()
}

function updateLayer() {
    let layer = getCurrProductGroup()?.parent
    layer?.draw()
}

function getCurrProductGroup(){
    if(currSelectedAddon){
        return currSelectedAddon.parent
    }
    return null
}

export function getLastElementPosition(group) {
    let children = group.children || getCurrProductGroup().children
    addonsList = []

    for (const currItem of children) {
        if(currItem.attrs.name === "addon"){
            addonsList.push(currItem)
        }
    }

    if(!addonsList.length){
        return{
            x:0,
            y:0
        }
    }
    let lastElement = addonsList[addonsList.length-1]
    return {
        x:lastElement.attrs.x,
        y:lastElement.attrs.y
    }
}

export function getElementPositionFromIndex(group:any,index:any) {
    let children = group.children
    addonsList = []

    for (const currItem of children) {
        if(currItem.attrs.name === "addon"){
            addonsList.push(currItem)
        }
    }
    let element = addonsList[index]
    return {
        x:element.attrs.x,
        y:element.attrs.y
    }
}


export function updateAddonsListPosition(group:any,layer:any) {
    let children = group.children || getCurrProductGroup().children
    addonsList = []

    for (const currItem of children) {
        if(currItem.attrs.name === "addon"){
            addonsList.push(currItem)
        }
    }

    let pos:any = {}
    let posY = 0


    for (let i = 0; i < addonsList.length; i++) {
        const currAddon = addonsList[i];
      

        if(i === 0){ // First element
            posY = deltaY
        }else{
            pos = getElementPositionFromIndex(group,i-1)
            posY = pos.y + rectHeight + deltaY  
        }

        currAddon.y(posY)
        let topLeft = group.find(".topLeft")[0]
        currAddon.x(topLeft.attrs.x + group.children[0].attrs.width + radius + 5)
    }

    layer.draw()

}

export function addAddonsPlus(group:any,selectProduct:()=>void) {
    let layer = group.parent

    radius = 15
    deltaY = 17

    let posY = deltaY

    let addAddonGroup = new Konva.Group({
        x: group.children[0].attrs.width + radius + 5,
        y: posY,
        draggable: true,
        //
        name: "addAddonGroup"
    })

    let circle = new Konva.Circle({
        x: 0,
        y: 0,       
        radius: radius ,
        fill: "#fff",
        stroke: 'gray',
        strokeWidth: 1,
        opacity:0.5,
        // custom attribute
        type:"addAddon"
    });

    addFillIcon(addAddonGroup,group.parent)

    //Parent of that group
    addAddonGroup.add(circle)
    group.add(addAddonGroup)

    addAddonGroup.on("mousedown touchstart",function(event) {
        expandItemsPanel()
        applicationConfig?.functions.moodboard.updateTabValue("colorSwatchContainer","colorSwatch")
        selectProduct()
    })

    addAddonGroup.on("mouseover", function () {
    document.body.style.cursor = "pointer";
    })
    addAddonGroup.on("mouseout", function () {
    document.body.style.cursor = "default";
    })

    layer.draw()
}


export function updatePlusIconPosition(group:any) {
    let allItems = group.children
    let plusIconGroup = allItems.filter(currItem => currItem.attrs.name === "addAddonGroup")

    let pos = getLastElementPosition(group)

    let posY = pos.y + 15 + 17

    // plusIconGroup[0].x(0)
    plusIconGroup[0].x(group.children[0].attrs.width + radius + 5)
    plusIconGroup[0].y(posY)


}


export function addCloseIcon(group:any,layer:any) {
    let imageWidthHeight = 10
   
    let imageSrc = "assets/icon/close.png"
    let imageObj:any = new Image()
    imageObj.onload = function () {
      var moduleIconImage = new Konva.Image({
        image: imageObj,
        width: imageWidthHeight,
        height: imageWidthHeight,
        x:8,
        y:-18
      })
    
      group.add(moduleIconImage)
      layer.draw()
    };
    imageObj.src = imageSrc;
  }

 
