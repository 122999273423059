import React, { useState } from "react";
import UploadFile from "../../../../../common/ui-components/forms/rembg/UploadFile";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import { showGlobalToast } from "../../../../../../utils/UI_methods/global";
import { configuration } from "../../../../../../utils/customizein3d/modules/customizein3d";
import { ARConfig } from "../../../../../../utils/customizein3d/webxr";
import { RenderConfig } from "../../../../../../utils/renders/renderConfig";
import { STORE } from "../../../../../../utils/store/storeConfiguration";

const Finish = ({}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownState, setDropdownState] = useState({ open: false });
    const [sliderValue1, setSliderValue1] = useState(50);
    const [sliderValue2, setSliderValue2] = useState(50);
    const [radioState, setRadioState] = useState('production');

    const [checkBoxStates, setCheckBoxStates] = useState({
        sao: true,
        roughnessMap: true,
        actualMeasurements: false,
        negated: false,
        prouction:true
    });


    const handleDropdownClick = () => setDropdownState({ open: !dropdownState.open });

    const handleSliderChange1 = (event) => {
        setSliderValue1(event.target.value);
    };

    const handleSliderChange2 = (event) => {
        setSliderValue2(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckBoxStates({
            ...checkBoxStates,
            [name]: checked,
        });
    };

    const handleRadioChange = (event) => {
        setRadioState(event.target.value);
    };


    async function copyToClipboard() {
        try {
          const renderSettingConfig = new RenderConfig()
          let arConfig = new ARConfig(STORE.currProduct,"","",configuration.configName,
            configuration.configHash,configuration.modules,configuration.isProductRender,"CreateModel.py",renderSettingConfig,null)
                  const jsonString = JSON.stringify(arConfig);
                  const blob = new Blob([jsonString], { type: 'application/json' });
                  const link = document.createElement('a');
                  link.download = `${Number(new Date())}.json`;
                  link.href = window.URL.createObjectURL(blob);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
        } catch (err) {
            showGlobalToast('Unable to copy text to clipboard',2000,"error");
        }
      }
      

  return (
    <div className="panel">
      <div className="content">
        <ul style={{listStyle:"none"}}>
          <li className="padding10 white-card shadow-light">
            <Button2
                configObj={{id:""}}
                action={()=>{copyToClipboard()}}
                label={"Download Config"}
                fontAwesomeIcon={"fas fa-plus color-white"}
                iconPos=""
                classNames={"shadow-medium bg-color-primary"}
            />

            
          </li>
          <li className="padding10 white-card shadow-light marginTop5">
            <input
              type="radio"
              name="environment"
              value="test"
              checked={radioState === "test"}
              onChange={handleRadioChange}
              style={{ marginRight: "8px" }}
            />
            Test
            <input
              type="radio"
              name="environment"
              value="production"
              checked={radioState === "production"}
              onChange={handleRadioChange}
              style={{ marginRight: "8px" }}
            />
            Production
          </li>

          <li className="padding10 white-card shadow-light marginTop5">
            <div>
              <input
                type="checkbox"
                name="sao"
                checked={checkBoxStates.sao}
                onChange={handleCheckboxChange}
                style={{ marginRight: "8px" }}
              />
              SAOO
            </div>
            <div>
              <input
                type="checkbox"
                name="roughnessMap"
                checked={checkBoxStates.roughnessMap}
                onChange={handleCheckboxChange}
                style={{ marginRight: "8px" }}
              />
              Roughness map
            </div>
            <div>
              <input
                type="checkbox"
                name="actualMeasurements"
                checked={checkBoxStates.actualMeasurements}
                onChange={handleCheckboxChange}
                style={{ marginRight: "8px" }}
              />
              Show actual measurements
            </div>
            <div>
              <input
                type="checkbox"
                name="negated"
                checked={checkBoxStates.negated}
                onChange={handleCheckboxChange}
                style={{ marginRight: "8px" }}
              />
              Negated
            </div>
          </li>
          <li className="padding10 white-card shadow-light marginTop5">
            <label>
              Perspective Camera FOV:{" "}
              <span style={{ marginRight: "15px" }}>{sliderValue1}</span>
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                value={sliderValue1}
                onChange={handleSliderChange1}
                className="custom-range-slider"
                style={{ marginRight: "8px" }}
              />
            </label>
            <label>
              Otho Camera FOV:{" "}
              <span style={{ marginRight: "15px" }}>{sliderValue2}</span>
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                value={sliderValue2}
                onChange={handleSliderChange2}
                className="custom-range-slider"
              />
            </label>
          </li>
          
        </ul>
      </div>
    </div>
  );
};

export default Finish;
