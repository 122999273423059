import React, { useEffect, useState } from "react";
import Button from "../../buttons/Button2";
import $ from "jquery"
import Resolutions from "./Resolutions";
import { displayUploadedImage } from "../../../../../utils/inventory/inventory";
import { hideComponentLoader, showComponentLoader } from "../../../../../utils/UI_methods/global";
import { displayWithoutBgImage, processImage } from "../../../../../utils/rembg/rembg";

const ImageActions: React.FC<{
  selectedFile: any;
  isBackgroundRemoved: boolean
  setIsBackgroundRemoved: (val: boolean) => void;
  setRenderingTime: (val: any) => void;
  setIsSelectResolutions: (val: boolean) => void;
  finalizeImage: (val: string) => void;

}> = (props) => {

  const [currState, setCurrState] = useState("original")
  const [loaderId] = useState("rembgLoader");

  // var resolution = "none"

  useEffect(() => {
    showComponentLoader(loaderId);
    setTimeout(() => {
      if (currState === "original") {
        displayUploadedImage(props.selectedFile, "withbgImage")
      } else {
        if (props.isBackgroundRemoved) {
          displayWithoutBgImage()
        } else {
          displayUploadedImage(props.selectedFile, "withbgImageFade")
        }
      }
      hideComponentLoader(loaderId);
    }, 200);

  }, [props.selectedFile, props.isBackgroundRemoved, currState])


  function tabClickHandle(event: any) {
    $(".rembg-tab").removeClass("--is-active")
    $(event.target).addClass("--is-active")

    let value = $(event.target).attr("data-value")
    setCurrState(value)
  }


  function downloadHandle() {
    var link = document.createElement('a');
    link.setAttribute("target", "blank")
    link.href = $("#withoutbgImage").attr("src")
    link.download = String(Number(new Date)) + '.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="ion-padding full-height-width">

      {props.isBackgroundRemoved ?
        <div className="tabs-container rembg-tabs-container">
          <div className="display-flex-row">
            <div data-value="original" className="heading1 font-normal rembg-tab " id="rembgInitialTab" onClick={tabClickHandle}>Original Image</div>
            <div data-value="removed" className="heading1 font-normal marginleft10 rembg-tab --is-active" onClick={tabClickHandle}>Removed Background</div>
          </div>
        </div>
        : null}

      <div className="full-width  overflow-y-scroll  fill-available-height flex-row-50">


        <div className="original-image-container full-height padding10">
          {currState === "original" ?
            <div className=" full-height center-aligned-column">
              <div className="position-relative">
                <img src="" height={300} id="withbgImage" className="shadow-medium" alt="" />
              </div>
            </div>
            :
            <div className=" full-height center-aligned-column">
              <div className="position-relative">
                <img src="" height={300} id="withoutbgImage" className="shadow-medium" alt="" />
                <div className="bottom-right padding10 pointer" onClick={downloadHandle}>
                  <i className="fas fa-download font-medium color-dark"></i>
                </div>
              </div>
            </div>
      
          }
        </div>

        <div className="ion-padding image-actions-container center-aligned-column" style={{ paddingTop: "0" }}>

          <div className="rembg-image-actions ">
              <div className="ion-margin-top">
                <div className=" ion-margin-top">
                  <button className="op-btn op-btn-primary-inverse rembg-button display-flex-row" onClick={() => props.finalizeImage(currState)}>
                    <span className="center-aligned-column full-height-width">
                      <span className="center-aligned-row heading1 font-normal color-black marginleft5">
                        Select {props.isBackgroundRemoved?currState:""} Image
                        <i className="fas fa-chevron-right color-danger center-aligned-column marginleft5"
                          aria-hidden="true"></i>
                      </span>
                    </span>
                  </button>
                </div>
              </div>

          </div>
        </div>

      </div>

      <Resolutions
        selectedFile={props.selectedFile}
        setIsBackgroundRemoved={props.setIsBackgroundRemoved}
        setRenderingTime={props.setRenderingTime}
        setCurrState={setCurrState}
      />

    </div>
  );
};

export default ImageActions;
