import React, { useEffect, useRef } from "react";
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../../utils/UI_methods/global";
import Button2 from "../../../ui-components/buttons/Button2";

const QRcode: React.FC<{
  configObj: any;
  url:string
}> = (props) => {

  const qrRef = useRef(null);

  useEffect(()=>{
    if(props.url && qrRef){
      showComponentLoader("mainLoaderSpinner")
      setTimeout(() => {
        openQrCode()
        hideComponentLoader("mainLoaderSpinner")
      }, 1000);
    }
  
  },[qrRef,props.url])

 


  async function openQrCode() {
    const windowObject:any = window
    if(windowObject.QRCode && qrRef){
      var qrcode = new windowObject.QRCode(qrRef.current, {
        text: props.url,
        width: 200,
        height: 200
      });
    }else{
      showGlobalToast("Try Again",2000,"error")
    }
    
  }

  async function copyToClipboard(link:string) {
    try {
      await navigator.clipboard.writeText(`${link}`);
        showGlobalToast('Coppied!!');
    } catch (err) {
        showGlobalToast('Unable to copy text to clipboard',2000,"error");
    }
  }

  function visitLink(link:string){
    window.location.href = link
  }


  return (
      
        <div className="padding10">
          {props.url? (
            <div className="display-flex-row no-wrap">
              {/* <canvas ref={qrRef} /> */}
              <div className="center-alingned-column">
                <div ref={qrRef} id="qrcode"></div>
              </div>
              <div className="padding10 center-alingned-column">
                <div className="heading1">View in Ar</div>
                <div className="marginTop5 marginBottom5">
                  Scan the QR code with your mobile device to view the product
                  in your room
                </div>
                <div className="display-flex-row">
                <Button2
                  configObj={{id:""}}
                  action={()=>{copyToClipboard(props.url)}}
                  label={"Copy Link"}
                  fontAwesomeIcon={"fas fa-eye color-warning"}
                  iconPos=""
                  classNames={"no-shadow button-small margin0 color-white bg-color-primary"}
                />
                <Button2
                  configObj={{id:""}}
                  action={()=>{visitLink(props.url)}}
                  label={"Visit Link"}
                  fontAwesomeIcon={"fas fa-eye color-warning"}
                  iconPos=""
                  classNames={"no-shadow button-small margin0 color-white bg-color-primary"}
                />
                </div>
              </div>
            </div>
          ) : null}
        </div>
  );
};

export default QRcode;
