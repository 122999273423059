import $ from "jquery";
import React, { useState } from "react";
import { hideComponentLoader, showAlert, showComponentLoader, showGlobalToast } from "../../../../../../../../utils/UI_methods/global";
import { waitFor } from "../../../../../../../../utils/methods";
import { ActivityEvent, getTodayDateObjectOnlyDate } from "../../../../../../../../utils/store/crm/calendar";
import { getFormatedDate, getNextActivityFromOutcome, getSalesStageIdFromOutcome } from "../../../../../../../../utils/store/crm/crm";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import Modal from "../../../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import Select from "../../../../../../../common/ui-components/input/Select";
import Calendar from "../../calender/Calendar";
import AssignSalesAssistForm from "./AssignSalesAssistForm";

 
const CloseActivityForm: React.FC<{
    isOpen:boolean
    setIsOpen:(val)=>void
    lead:any
    refreshLeads:()=>void
    setCurrTab:(val:string)=>void
    currActivity:string
}> = (props) => {


    const[alertId] = useState("alertCloseActi")

    const[activityOutcomes] = useState(["Select outcome","Not Reached","Reached but appointment not fixed","Agreed for home visit","Agreed for factory visit","Need more information","Out of bangalore"])
    const [currSelectedOutcome,setCurrSectedOutcome] = useState(activityOutcomes[0])


    const[isOpenCalender,setIsOpenCalender] = useState(false)
    const[isOpenAssignSalesAssist,setisOpenAssignSalesAssist] = useState(false)

    const[activitiesTypes] = useState(CRM.data.activityTypes)

    const[nextActivity,setNextActivitiy]:any = useState(null)

    const[nextActivityEvent] = useState(new ActivityEvent(null,"")) 



   
 
    async function closeActivityHandle() {

        const currActivityDetails = {
            outcome:currSelectedOutcome,
            activityId:props.lead.last_activity_id,
            currentActivityId:props.lead.current_activity_id,
            endDate:Number(new Date()),
            remarks:$("#closeActivityRemarks").val(),
            salesStageId:getSalesStageIdFromOutcome(currSelectedOutcome)
        }

        const nextActivityDetails = {
            activityTypeId:nextActivityEvent.activityTypeId,
            startDate:nextActivityEvent.start?.getTime()
        }

        if(currActivityDetails && nextActivityDetails.startDate){
          showComponentLoader("storeLoader")
          CRM.crmApi.activity.closeActivity(props.lead.lead_id,currActivityDetails,nextActivityDetails).then(async (data:any)=>{
            if(!data.data.error){
                await CRM.crmApi.leads.updateLead(props.lead.lead_id,{isViewed:0})
                props.setIsOpen(false)
                props.refreshLeads()
                // await waitFor(1000)
                // props.setCurrTab(currActivityDetails.outcome)
                showGlobalToast("Scheduled at " + getFormatedDate(nextActivityEvent.start?.getTime()) ,3000)
                hideComponentLoader("storeLoader")
            }else{
              showAlert(alertId,"Internal server error","error")
              hideComponentLoader("storeLoader")
            }
          })
        }else{
          showAlert(alertId,"Please enter mandatory fields","error")
          hideComponentLoader("storeLoader")
        }
      }

      

    async function onChangeActivityOutcome(outcome:string){
        setCurrSectedOutcome(outcome)
        let activity:any = getNextActivityFromOutcome(outcome)
        if(activity){
            setNextActivitiy(activity)
            nextActivityEvent.updateActivityType(activity.activity_type)
        }else{
            setNextActivitiy(null)
        }

        $("#selectOutcomeWrapper").fadeOut(500)
        await waitFor(500)
        $("#nextActivityWrapper").fadeIn(500)
    }


    async function showOutcomeWindow(){
        $("#nextActivityWrapper").fadeOut(500)
        await waitFor(500)
        $("#selectOutcomeWrapper").fadeIn(500)
    }

      function Footer() {
        return(
            <div className="middle">
                {/* <Button2
                    configObj={{id:""}}
                    action={closeActivityHandle}
                    label={"Confirm"}
                    fontAwesomeIcon={"fas fa-arrow-right color-dark"}
                    iconPos=""
                    classNames={"no-shadow butt color-white bg-color-primary"}
                /> */}
            </div>
        )
    }


 


    function goHandle(){
        if(currSelectedOutcome === "Need more information" || currSelectedOutcome === "Out of bangalore"){
            setisOpenAssignSalesAssist(true)
        }else{
            setIsOpenCalender(true)
        }
    }


    

 
  return (
    <div className="full-height-width padding5 position-relative bg-color-light overflow-y-scroll scroll-down-element">
    <Modal
        isOpen = {isOpenCalender}
        Content = {()=>(
            <Calendar callbackAfterDateSelect={closeActivityHandle} lead={props.lead} isSetEventMode={true} activityEvent={nextActivityEvent}/>
        )}
        onDismiss = {()=>setIsOpenCalender(false)}
        heading={"Select date for : " + nextActivity?.activity_type}
        FooterContent={Footer}
        classNames="large-x-modal"
        id=""
    />

    <AssignSalesAssistForm lead={props.lead} 
    isOpenAssignSalesAssist={isOpenAssignSalesAssist} 
    setisOpenAssignSalesAssist={setisOpenAssignSalesAssist} 
    nextActivityEvent={nextActivityEvent}
    closeActivityHandle={closeActivityHandle}
    />


    {/* <div className="center-aligned-row top-middle" style={{top:"20px"}}>
        <h1 className="heading2 font-large">Activity started...</h1>
    </div> */}


    <div className="middle zindex100" style={{width:"85%"}}>

        <div className="curr-activity-container full-width white-card no-shadow" style={{padding:"35px 10px"}} >
            
            <div className="center-aligned-column">
                <i className="fas fa-user-circle color-dark" style={{fontSize:"3.5rem"}} />
                <div className="heading3 marginTop10 font-large">{props.lead.customer_name}</div>
                <div className="heading3 marginTop5 font-normal color-black">+91-{props.lead.mobile}</div>
            </div>

            <div className="center-aligned-row marginTop10">
                <div className="display-flex-row no-wrap">
                    <div className="center-aligned-column"> <i className={`${props.currActivity === "call"?"fas fa-phone-square-alt color-primary":props.currActivity === "email"?"fas fa-envelope color-danger":props.currActivity === "whatsapp"?"fab fa-whatsapp color-success":null}`} style={{fontSize:"2.5rem"}}></i> </div>
                    <div className="heading4 center-aligned-column color-black font-medium marginleft10">
                        {props.currActivity === "call"?
                        `Calling...`:
                        props.currActivity === "whatsapp"?
                        `Sending message...`:
                        props.currActivity === "email"?
                        `Sending email to... Mr. ${props.lead.customer_name}`
                        :null}
                        
                        </div>  
                </div>
            </div>
        </div>

        <div  className="position-relative display-flex-row no-wrap ion-margin-top full-width white-card no-shadow" style={{padding:"35px 10px",height:"200px"}}>
           
            <div id="selectOutcomeWrapper" className="padding5 full-width">
                <div className="heading3 text-center padding5 font-medium color-black">Please select outcome</div>
                <div className="center-aligned-row full-width ion-margin-top">
                    <Select
                        id="activityOutcome"
                        label=""
                        isMandatory={false}
                        onChange={onChangeActivityOutcome}
                        options={activityOutcomes}
                        className="input-large text-center "
                        value={currSelectedOutcome}
                    />
                </div>
            </div>

            <div id="nextActivityWrapper" className="full-width" style={{display:"none"}}>
                <div onClick={showOutcomeWindow} className="top-left pointer padding10 display-flex-row">
                    <i className="fas fa-arrow-left pointer-none color-dark font-medium"></i>
                    <div className="heading4 font-small marginleft5">{currSelectedOutcome}</div>
                </div>
                <div className="heading3 text-center padding5 marginTop10 font-medium color-black" style={{marginBottom:"10px"}}>Next activity</div>
                <div className="display-flex-row no-wrap space-between input-large" style={{margin:"auto"}}>
                    <span style={{width:"80%",fontSize:"1rem",color:"#444"}} className="white-card border select-value  padding10 no-shadow heading4 center-aligned-column">{nextActivity?.activity_type}</span>
                    <span  style={{width:"20%"}} className="white-card center-aligned-column padding10  border no-shadow marginleft10">
                        {/* <Button2
                            configObj={{id:""}}
                            action={()=>{setIsOpenCalender(true)}}
                            label={"Go"}
                            fontAwesomeIcon={"fas fa-arrow-right color-primary"}
                            iconPos="right"
                            classNames={"no-shadow no-border button-large transparent color-white bg-color-"}
                            /> */}
                            <div className="display-flex-row no-wrap click-effect" onClick={()=>goHandle()}>
                                <span className="heading3 pointer-none font-normal">Go</span>
                                <span className="marginleft5 pointer-none"><i className="fas fa-arrow-right color-primary"></i></span>
                            </div>
                    </span>
                </div>
            </div>

        </div>

        
    </div>

    
    {nextActivity?
            <div id="nextActivityWrapper" className="bottom-middle display-none" style={{bottom:"80px"}}>
                <div className="heading3 text-center padding5 font-medium color-black" style={{marginBottom:"10px"}}>Schedule an appointment</div>
                <div className="display-flex-row no-wrap space-between input-large" style={{margin:"5px"}}>
                    <span style={{width:"80%",fontSize:"1rem",color:"#444"}} className="white-card select-value  padding10 no-shadow heading4 center-aligned-column">{nextActivity?.activity_type}</span>
                    <span  style={{width:"20%"}} className="white-card center-aligned-column padding10 no-shadow marginleft10">
                        <Button2
                            configObj={{id:""}}
                            action={()=>{goHandle()}}
                            label={"Go"}
                            fontAwesomeIcon={"fas fa-arrow-right color-primary"}
                            iconPos="right"
                            classNames={"no-shadow no-border button-large transparent color-white bg-color-"}
                        />
                    </span>
                </div>
            
            </div>
        :null}
        
    </div>
  );
};

export default CloseActivityForm;
