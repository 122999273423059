import React, { useEffect, useState } from "react";
import { Cot_Storage_Options, Cot_Variants, Cot_length } from "../../../../../utils/store/customization-options-data/cot";
import { CotConfiguration, STORE } from "../../../../../utils/store/storeConfiguration";
import AccordionForProccess from "../../../../common/ui-components/accordion/AccordionForProccess";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import SelectQuality from "../product-customizer/common/SelectQuality";
import StorageSelector from "../product-customizer/cot/StorageSelector";
import VariantSelector from "../product-customizer/common/VariantSelector";
import LengthSelector from "../product-customizer/cot/LengthSelector";
import MaterialsOptions from "../product-customizer/Material-Selector/MaterialsOptions";
import { confirmAccordionHandle, confirmProccessAccordion, showGlobalToast } from "../../../../../utils/UI_methods/global";
import Modal2 from "../../../../common/shared-components/Modals/Modal2";
import AccordionForProccessCss from "../../../../common/ui-components/accordion/AccordionForProccessCss";
import VariantIcon from "../product-customizer/common/VariantIcon";
import QualityIcon from "../product-customizer/common/QualityIcon";
import { stringWithoutWhiteSpace } from "../../../../../utils/methods";

 

const CotConfigOptions: React.FC<{
  product:any
  productConfiguration:CotConfiguration
  updatePrice:()=>void
}> = (props) => {

  const[currVariant,setCurrVariant] = useState(props.productConfiguration.variant)
  const[currDepth,setCurrDepth] = useState(props.productConfiguration.length)
  const[currStorage,setCurrStorage] = useState(props.productConfiguration.storage)
  const[storageName,setStorageName] = useState(props.productConfiguration.storage.name)
  const[currQuality,setCurrQuality] = useState(props.productConfiguration.quality)
  const[currCategory] = useState(props.product.category_name)
 

  const[isOpenFabricSelection,setisOpenFabricSelection] = useState(false)
 
  const[isConfirmLength,setisConfirmLength] = useState(false)
  const[isConfirmVariant,setisConfirmVariant] = useState(false)
  const[isConfirmStorage,setisConfirmStorage] = useState(false)
  const[isConfirmQuality,setisConfirmQuality] = useState(false)
  const[isConfirmFabric,setisConfirmFabric] = useState(true)
 

  useEffect(()=>{
    setTimeout(() => {
      props.updatePrice()
    }, 500);
  },[currVariant,currQuality,isConfirmQuality,isConfirmVariant,props.product])

  
  function CurrentSelection(props:any) {
    const heading = props.heading
    const value = props.value
    return(
      <div className="center-aligned-column">
        {currVariant?
            <div className="heading2 font-md-small marginRight10 color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">{value}</div>
        :null}
      </div>
    )
  }

  function CurrentVariant() {
    let variantName = currVariant.variantName
    return(
      <div className="center-aligned-column">
        <div className="display-flex-row no-wrap">
          <div className="padding5 center-aligned-column" style={{width:"5rem"}}>
            <VariantIcon variant={currVariant} currCategory={currCategory}/>
          </div>
          
          <div className="heading2 center-aligned-column hide-in-mobile font-md-small marginRight10  color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">
            {props.productConfiguration.isCustomVariant?
            "Custom Variant"
            :
            `${variantName} ${variantName.includes("Lounger") || variantName.includes("Corner")? `${currVariant.type} (${currVariant.size})`:`(${currVariant.size})`}`
            }
          </div>
        </div>
      </div>
    )
  }
  
  function CurrentQuality(props:any) {
    const quality = props.quality
    return(
      <div className="center-aligned-column">
        <div className="display-flex-row no-wrap">
          <div className="padding5 center-aligned-column" style={{width:"5rem"}}><QualityIcon quality={quality} currCategory={currCategory}/></div>
          <div className="heading2 center-aligned-column hide-in-mobile font-md-small marginRight10  color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">{quality}</div>
        </div>
      </div>
    )
  }

  function CurrentStorage(props:any) {
    let url = `${STORE.BASE_URL_ASSETS}icons/UpholsteredBed/storage/${stringWithoutWhiteSpace(currStorage.name)}.svg`
    return(
      <div className="center-aligned-column">
        <div className="display-flex-row no-wrap">
          <div className="padding5 center-aligned-column" style={{width:"5rem"}}><img className="icon" src={url} style={{width:"4rem",margin:"auto"}}/></div>
          <div className="heading2 center-aligned-column font-md-small marginRight10  color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">{currStorage.name} {currStorage?.type? `(${currStorage?.type})`:``}</div>
        </div>
      </div>
    )
  }



  function Footer(props:any) {
    const confirmHandle = props.confirmHandle
    return(
      <div className="center-aligned-column padding5 full-width bg-color-light">
        <div className="">
          <Button2
            configObj={{id:""}}
            action={()=>{confirmHandle()}}
            label={"Confirm Selection"}
            fontAwesomeIcon={"fas fa-cart-plus color-white"}
            iconPos=""
            classNames={"no-shadow margin0 bg-color-warning"}
          />
        </div>
      </div>
    )
  }

  function confirmMaterialHandle() {
    setisOpenFabricSelection(false)
    setisConfirmFabric(true)
    showGlobalToast("Saved")
  }

  function confirmVariantHandle() {
    confirmAccordionHandle(2)
    setCurrVariant({...props.productConfiguration.variant})
    props.productConfiguration.updatePrice()
  }
  
  return (
    <div className='product-details product-customizer-wrapper'>

      <Modal2
        isOpen = {isOpenFabricSelection}
        Content = {()=>(
          <MaterialsOptions product={props.product} confirmMaterialHandle={confirmMaterialHandle} productConfiguration={props.productConfiguration}/>
        )}
        onDismiss = {()=>setisOpenFabricSelection(false)}
        classNames="medium-x-modal"
        id=""
      />

      <div className="accordion-wrapper">

          <AccordionForProccessCss
            label="Select Depth"
            SubHeader={()=>(
              <CurrentSelection heading="Length" value={`${currDepth}"`}/>
            )}
            Content={()=>(
              <>
                <LengthSelector length={props.productConfiguration.length} productConfiguration={props.productConfiguration} configuration={Cot_length} onChange={setCurrDepth}/>
              </>
            )}
            index={1}
            Footer={()=>(
              <Footer confirmHandle={()=>
                {
                  confirmAccordionHandle(1)
                  setisConfirmLength(false)
                  setCurrDepth(props.productConfiguration.length)
                }
              } />
            )}
          />


        <AccordionForProccessCss
            label="Choose your size"
            SubHeader={()=>(
              <CurrentVariant/>
            )}
            Content={()=>(
              <>
                <VariantSelector confirmHandle={null} variant={props.productConfiguration.variant} product={props.product}  configuration={Cot_Variants} onChange={setCurrVariant} isShowMakeYourOwn={false}/>
              </>
            )}
            index={2}
            Footer={()=>(
              <Footer confirmHandle={confirmVariantHandle} />
            )}
        />

        {/* <AccordionForProccessCss
            label="Choose Your Quality"
            SubHeader={()=>(
              <CurrentQuality quality={props.productConfiguration.quality}/>
            )}
            Content={()=>(
              <SelectQuality productConfiguration={props.productConfiguration} currCategory={props.product.category_name} currQuality={currQuality} onChange={setCurrQuality}/>
            )}
            index={3}
            Footer={()=>(
              <Footer confirmHandle={()=>{
                setisConfirmQuality(false)
                setCurrQuality(props.productConfiguration.quality)
                confirmProccessAccordion(3)
              }} />
            )}
        /> */}

        <AccordionForProccessCss
            label="Select Your Storage"
            SubHeader={()=>(
              <CurrentStorage storage={props.productConfiguration.storage} value={currStorage}/>
            )}
            Content={()=>(
              <>
                <StorageSelector storage={props.productConfiguration.storage} product={props.product}  configuration={Cot_Storage_Options} onChange={setStorageName}/>
              </>
            )}
            index={3}
            Footer={()=>(
              <Footer confirmHandle={()=>{
                setisConfirmStorage(false)
                confirmAccordionHandle(3)
                setCurrStorage({...props.productConfiguration.storage})
              }} />
            )}
        />

          <AccordionForProccessCss
            label="Select Your Fabric"
            SubHeader={()=>(
              <CurrentSelection heading="Fabric" value={props.productConfiguration.materials.length?props.productConfiguration.materials[0]?.material?.materialCode:"No Selection"}/>
            )}
            Content={()=>(
                <></>
            )}
            index={4}
            onClick={()=>{
              setisOpenFabricSelection(true)
              confirmProccessAccordion(4)
            }}
          />

        

      </div>
        </div>
  );
};

export default CotConfigOptions;
