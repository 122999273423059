import { Vector3 } from "three"
import { detachModule } from "../common"
import { group, loadModuleToTheScene, modulesLoader } from "./customizein3d"
import { STORE } from "../../store/storeConfiguration"
import { generatedEnvironmentMap } from "../enviornment"

export async function updateSofaLeg(leg:any) {
    // return
    let isCustomize =  STORE.currProduct?.customizable_legs
    if(!isCustomize){
        return
    }
    let legName = leg.leg_name
    let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/SofaLegs/models/${legName}.glb`
    let object:any =  await loadModuleToTheScene(modulesLoader,url)
    if(object && legName && legName !== "undefined"){
        removeAllLegs()
        for (const currModule of group.children) {
            let anchorGroup = currModule.children.find(currChild => currChild.name.includes("Anchors"))
            if(anchorGroup){
                for (const currAnchor of anchorGroup.children) {
                    let anchorName = currAnchor.name.toLowerCase()    
                    let legType = getLegType(legName)
                    if(legType === "Corner" && (anchorName.includes("cl") || anchorName.includes("cr"))){
                        continue
                    }
                    if(legType === "Center" && (anchorName.includes("fl") || anchorName.includes("fr") || anchorName.includes("bl") || anchorName.includes("br") )){
                        continue
                    }
                    let legModel = object.clone()
                    if(anchorName.includes("fr")){
                        legModel.scale.x = -1
                    }
                    if(anchorName.includes("bl")){
                        legModel.scale.z = -1
                    }
                    if(anchorName.includes("br")){
                        legModel.scale.x = -1
                        legModel.scale.z = -1
                    }
                    currModule.add(legModel)
                    // let worldPos = new Vector3()
                    // currAnchor.getWorldPosition(worldPos)
                    let offsetY = 0
                    let productName = STORE?.currProduct?.product_name
                    legModel.position.copy(currAnchor.position)
                    if(productName?.includes("Boat")){
                        offsetY = 0.05
                    }
                    if(productName?.includes("Titanic")){
                        offsetY = 0.03
                    }
                    legModel.position.y += offsetY 

                    let configuration = currModule.userData.configuration
                    if(configuration?.additionalConfiguration){
                        configuration.additionalConfiguration.currLeg = `${leg.leg_name}_${legType}`
                        configuration.additionalConfiguration.legType = legType
                    }
                    assignTextureMap(legModel)
                }
            }
        }    
    }
    

    
    // $("#currSofaLeg").text(legName)
    // for (const currMesh of legMeshes) {
    //     if(currMesh.name.includes(legName)){
    //     currMesh.visible = true
    //     $("#currSofaLeg").text("Default Leg")
    //     }else{
    //     currMesh.visible = false
    //     }
    // }
}

export function assignTextureMap(modelObject:any){

    // let modelObject =  scene.getObjectByName(moduleName)
    modelObject.traverse(mesh=>{
      if(mesh.isMesh){
        mesh.material.envMap = generatedEnvironmentMap.texture
        mesh.material.needsUpdate = true;
      }
    })
}

export function removeAllLegs() {
    for (const currModule of group.children) {
        if(currModule?.userData.configuration){
            currModule?.traverse(currMesh =>{
            if(currMesh?.isMesh){
                let meshName = currMesh.name?.toLowerCase() 
                if(meshName?.includes("legs") || meshName?.includes("leg")){
                    detachModule(currMesh)
                }
            }
            })
        }
    }
}


function getLegType(legName:String) {
    if(legName.toLowerCase().includes("leg7") || legName.toLowerCase().includes("leg8")){
        return "Center"
    }
    return "Corner"
}
  