import $ from "jquery";
import React, { useEffect, useState } from "react";
import { showAlert } from "../../../../../../utils/UI_methods/global";
import { Address as AddressClass, Customer, STORE } from "../../../../../../utils/store/storeConfiguration";
import Alert from "../../../../../common/ui-components/alerts/Alert";
import Button2 from "../../../../../common/ui-components/buttons/Button2";

 

const CreateNewAddress: React.FC<{
  callBack:()=>void
}> = (props) => {


  const[alertId] = useState("alertAddress")

  var currSelectedAddress = ""

  useEffect(()=>{
    // const windowObject:any = window
    // const script = document.createElement("script")
    // script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCwVeMbybrFTB0ngv2adoKoWUwUuXm2sg8&libraries=places&callback=initMap"
    // script.async = true
    // windowObject.initMap = function() {
    //   initializeAutocomplete(windowObject.google,"address-input")
    // };
    // document.head.appendChild(script);
    // return () => {
    //   document.head.removeChild(script);
    // };
  },[])

  function initializeAutocomplete(google:any, inputId:any) {
    const autocomplete = new google.maps.places.Autocomplete(document.getElementById(inputId));
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      autoCompleteAddress(google, place);
    });
  }

  function autoCompleteAddress(google:any,place:any){
    if (!place.geometry) {
      console.log('Place not found or incomplete address.');
      return;
    }
    const placeId = place.place_id;
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({ placeId }, (result, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const plusCode = result.plus_code ? result.plus_code.global_code : 'N/A';
        fillAddress(result.address_components);
        currSelectedAddress = result.formatted_address
        console.log('Formatted Address:', result.formatted_address);
        console.log('Plus Code:', plusCode);
      } else {
        console.error('Error fetching place details:', status);
      }
    });
  }

  function fillAddress(addressComponents:any) {
    let city = '';
    let postalCode = '';
    let state = '';
    let country = '';
    let address = '';
    let locality = '';
    let formattedAddress = '';

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes('locality')) {
        city = component.long_name;
      } else if (types.includes('postal_code')) {
        postalCode = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        state = component.long_name;
      } else if (types.includes('country')) {
        country = component.long_name;
      } else if (types.includes("sublocality_level_1")) {
        address = component.long_name;
      }else if (types.includes("sublocality_level_2")) {
        locality = component.long_name;
      }
    });

    $("#city").val(city)
    $("#postalCode").val(postalCode)
    $("#state").val(state)
    $("#country").val(country)
    $("#address").val(address)
    $("#locality").val(locality)

  }
  
  
 


  async function save() {
    const addressDetails:any = {
      city:$("#city").val(),
      postalCode:$("#postalCode").val(),
      state:$("#state").val(),
      country:$("#country").val(),
      addressLine1:$("#address").val(),
      addressLine2:$("#locality").val()
    }

    if(addressDetails.city.length && addressDetails.postalCode.length && addressDetails.state.length && addressDetails.country.length){
      const address = new AddressClass(addressDetails)
      const customerId = STORE.activeCustomer.id
      STORE.storeApi.createAddress(customerId,address).then((data:any)=>{
        if(!data.data.error){
          props.callBack()
          showAlert(alertId,"Address has been created successfully","success")
        }else{
          showAlert(alertId,data.data.message,"error")
        }
        emptyFields()
      })
    }else{
      showAlert(alertId,"Please fill mandatory fileds","error")
    }
    // await waitFor(800)
    // hideComponentLoader("storeLoader")
    // let prevValues = savedAddress
    // setSavedAddress([...prevValues,{address:currSelectedAddress,isDefault:false}])
    // props.closeHandle()
  }

  function emptyFields(){
    $("#city").val("")
    $("#postalCode").val("")
    $("#state").val("")
    $("#country").val("")
    $("#address").val("")
    $("#locality").val("")
  }

 

  function Form() {
    return(
      <div className="full-width marginTop10">

        {/* <div className="display-flex-row no-wrap">
          <div className="width50">
            <input  type="text" className="full-width input" placeholder="Name" />
          </div>
          <div className="width50 marginleft5">
            <input defaultValue={"+91-" + props.customer?.phone} type="text" className="full-width input" placeholder="Mobile" />
          </div>
        </div> */}

        <div className="display-flex-row no-wrap marginTop10">
          <div className="width50">
            <input id="city" type="text" className="full-width input" placeholder="City / District / Town" />
          </div>
          <div className="width50 marginleft5">
            <input id="state" type="text" className="full-width input" placeholder="State" />
          </div>
        </div>
        

        <div className="display-flex-row no-wrap marginTop10">
          <div className="width50">
            <input id="postalCode" type="text" className="full-width input" placeholder="Pincode" />
          </div>
          <div className="width50 marginleft5">
            <input id="locality" type="text" className="full-width input" placeholder="Locality" />
          </div>
        </div>

        <div className="display-flex-row no-wrap marginTop10">
          <textarea id="address" className="full-width input" placeholder="Address" style={{height:"90px"}} />
        </div>


        <div className="display-flex-row no-wrap marginTop10">
          <div className="width50">
            <input id="country" type="text" className="full-width input" placeholder="Country" />
          </div>
          <div className="width50 marginleft5">
            <input type="text" className="full-width input" placeholder="Alternate mobile number" />
          </div>
        </div>

        <div className="marginTop10">
          <Button2
              configObj={{id:""}}
              action={save}
              label={"Save Address"}
              fontAwesomeIcon={"fas fa-plus color-white"}
              iconPos=""
              classNames={`no-shadow margin0 bg-color-primary`}
            />
        </div>


      </div>
    )
  }

  function AutoFillInput() {
    return(
      <div className="ful-width">
        <div className="padding5 heading3">Enter your address</div>
        <div className="display-flex-row no-wrap">
          <input id="address-input" type="text" className="full-width input" placeholder="Ex. Udyog vihar, phase I, near airtel building" />
        </div>
      </div>
     
    )
  }
 
 
  return (

    <div className="full-height-width display-flex-column no-wrap position-relative">

    <div className="header bg-color-warning padding10">
      <div className="heading2 padding10 color-white text-center">Create new address</div>
    </div>

    <div className="body bg-color-light padding10 overflow-y-scroll full-height">

    <Alert
      id={alertId}
    />

          <AutoFillInput/>
          <Form/>

      
    </div>

    <div className="full-width padding10 bg-color-light">
    </div>

    </div>
  );
};

export default CreateNewAddress;
