import $ from "jquery";
import React from "react";
import { showAlert, showGlobalToast } from "../../../../../../../../utils/UI_methods/global";
import { applicationConfig, getObjectByParameter } from "../../../../../../../../utils/methods";
import { getFormatedDate } from "../../../../../../../../utils/store/crm/crm";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import { Customer, STORE } from "../../../../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import MarkAsLostOption from "./MarkAsLostOption";

 
const LeadCard: React.FC<{
  lead:any
  openLeadDetailsHandle:(lead:any,view:string)=>void
  refreshLeads:()=>void
  setCurrLead:any
  activeCustomer:Customer
  setActiveCustomer:(customer:Customer)=>void
  startActivityHandle:(lead:any,activity:string)=>void
  updateLeadHandle:(lead:any)=>void
}> = (props) => {




 

 
  function gotoStoreHandle() {
    STORE.setActiveCustomer(props.activeCustomer)
    window.open("https://store.ovlapps.com/","_blank");
  }

  
  async function startSessionHandle() {
    let customer = new Customer({
      customer_id:props.lead.customer_id,
      customer_name:props.lead.customer_name,
      mobile:props.lead.mobile,
      email:props.lead.email,
    })
    await activeCustomerSession(customer)
    props.setActiveCustomer(customer)
  }


  async function activeCustomerSession(customer:Customer) {
    await STORE.storeApi.startCustomerSession(customer).then(data=>{
      if(!data.data.data.error){
        // setIsSessionActive(true)
        customer.customerSessionId = data.data.data.insertId
        STORE.isCustomerSessionActive = true
        showGlobalToast("Customer session has been activated",3000)
        // props.closeAddCustomer()
      }else{
        showAlert("customerFormAlert",data.data.message,"error")
      }
    })
  }

  function Options() {
    return(
      <div className="display-flex-row no-wrap">
            {props.lead.outcome?.toLowerCase().includes("agreed")?
              <div className="hide-in-mobile display-none">
              {props.activeCustomer?.id=== props.lead.customer_id ? 
                <Button2
                  configObj={{id:""}}
                  action={gotoStoreHandle}
                  label={"Go to store"}
                  fontAwesomeIcon={"fas fa-envelope color-dark"}
                  iconPos=""
                  classNames={"no-shadow button-small color-white bg-color-warning"}
                />
              :
                <Button2
                  configObj={{id:""}}
                  action={startSessionHandle}
                  label={"Start session"}
                  fontAwesomeIcon={"fas fa-envelope color-dark"}
                  iconPos=""
                  classNames={"shadow-light button-small color-white bg-color"}
                />
              }
              </div>
            :
            null}

            {props.lead.is_assigned?
            <>
              <Button2
                configObj={{id:""}}
                action={()=>{props.startActivityHandle(props.lead,"call")}}
                label={""}
                fontAwesomeIcon={"fas fa-phone-alt color-primary"}
                iconPos="left"
                classNames={"no-shadow button-small color-white bg-color-"}
              />

              <Button2
                configObj={{id:""}}
                action={()=>{props.startActivityHandle(props.lead,"whatsapp")}}
                label={""}
                fontAwesomeIcon={"fab fa-whatsapp font-medium color-success"}
                iconPos="left"
                classNames={"no-shadow button-small color-white bg-color-"}
              />

              <Button2
                configObj={{id:""}}
                action={()=>{props.openLeadDetailsHandle(props.lead,"calendar")}}
                label={""}
                fontAwesomeIcon={"fas fa-history color-warning"}
                iconPos="left"
                classNames={"no-shadow button-small hide-in-mobile color-white bg-color-"}
              />

              

             
            </>
            :null
            
            }
           
            {(applicationConfig?.user?.userType.toLowerCase() === "manager" ||  !props.lead.is_assigned) && props.lead.sales_stage !== "Win"?
                <Button2
                  configObj={{id:""}}
                  action={()=>{props.updateLeadHandle(props.lead)}}
                  label={""}
                  fontAwesomeIcon={"fas fa-user-plus color-warning"}
                  iconPos="left"
                  classNames={"no-shadow button-small hide-in-mobile color-white bg-color-"}
                />
            :null}

            {props.lead.sales_stage !== "Win"?
            <MarkAsLostOption lead={props.lead} refreshLeads={props.refreshLeads}/>
            :
            null
            }


            
          </div>
    )
  }


  function mouseOverHandle() {
    $(".leads-card-wrapper").css("overflow","visible")
  }


  function getCurrentActivityDate() {
    let activity = CRM.data.activitiesList.find(currActivity => currActivity.lead_id === props.lead.lead_id && currActivity.status === 1)
    return activity?.start_date || null
  }


  function getUserNameFromId(id:number) {
    return getObjectByParameter(CRM.data.users,"user_id",id)?.username || "Not assigned"
  }


  return (
    <div className="full-width padding5 lead-card-container">

      <div className={`white-card pointer position-relative no-shadow border${props.lead.is_viewed === 0?" lead-card-not-opened":" "}`}>

        
        <div className="display-flex-row " onClick={()=>{props.openLeadDetailsHandle(props.lead,"activity")}}>

          <div className="padding5 name-badge-container" >
            <div className="capitalize name-badge center-aligned-column white-card no-shadow">
              {props.lead.customer_name.substring(0,2)}
            </div>
          </div>

          <div  className="customer-details center-aligned-column padding5 marginleft5">

            <div className="contact-details ">

              <div className="display-flex-row item" >
                <span className="hide-in-desktop"><i className="far fa-id-card icon color-dark"></i></span>
                <span className="center-aligned-column heading2 capitalize name color-black text  marginleft5">{props.lead.customer_name}</span>
              </div>


            {props.lead.outcome?
              <div className="display-flex-row item ion-margin-start lead-card-date-container">
                <span className="center-aligned-column"><i className="fas fa-calendar-alt icon color-dark"></i></span>
                <span className="center-aligned-column text marginleft5">{getFormatedDate(getCurrentActivityDate())}</span>
              </div>
            :null
            }

            {applicationConfig?.user?.userType.toLowerCase() === "manager" && props.lead.is_assigned ?
              <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column"><i className="fas fa-user icon color-dark"></i></span>
                <span className="center-aligned-column text marginleft5">{props.lead.assigned_to_username}</span>
              </div>
            :null}

            {props.lead.outcome === "Need more information"?
              <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column"><i className="fas fa-headset icon color-dark"></i></span>
                <span className="center-aligned-column text marginleft5">{getUserNameFromId(props.lead.assigned_to)}</span>
              </div>
            :null}

            {props.lead.comments?.length?
            <div onMouseOver={mouseOverHandle} className="display-flex-row custom-tooltip item ion-margin-start position-relative">
              <span className="center-aligned-column"><i className="far fa-comments icon color-dark"></i></span>
              <span className="text  marginleft5 text-ellipsis " style={{marginTop:"2px"}} >{props.lead.comments}</span>
              <div className="custom-tooltip-text">{props.lead.comments}</div>
            </div>
            :null}

            {!props.lead.is_assigned?
              <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column"><i className="fas fa-calendar-alt icon color-dark"></i></span>
                <span className="center-aligned-column text marginleft5">Check in: {getFormatedDate(props.lead.date_of_creation)}</span>
              </div>
            :null}


            {!props.lead.is_assigned && props.lead.is_direct_visit?
              <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column text white-card no-shadow marginleft5 direct-visit-badge">Direct visit</span>
              </div>
            :null}
         

            {props.lead.is_assigned?
              <div className="display-flex-row ion-margin-start position-relative item">
                <span className="center-aligned-column"><i className="far fa-clock icon color-warning"></i></span>
                <span className="center-aligned-column text marginleft5 heading4 color-warning rupee-sign">Last called on: {props.lead.end_date?getFormatedDate(props.lead.end_date):"Not called yet"} </span>
              </div>
            :null}

             {(props.lead.sales_stage === "Visited" || props.lead.sales_stage_id === 4 || props.lead.is_assigned === 0) && props.lead.is_direct_visit !== 1?
             <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column text white-card no-shadow marginleft5 direct-visit-badge">{props.lead.outcome?.toLowerCase().includes("home")?"Home visit":"Factory visit"}</span>
              </div>
            :null}



            </div>
          </div>

        </div>
        
    

        <div className={`top-right options-container center-aligned-column full-height`}>
            <Options/>
        </div>
      </div>
    </div>
    
  );
};

export default LeadCard;
