import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { applicationConfig } from "../../../../utils/methods";

 

const SubHeader: React.FC<{
}> = (props) => {

  const[categories,setCategories] = useState([])

  useEffect(()=>{
    if(applicationConfig?.data?.categories){
      setCategories(applicationConfig?.data?.categories.slice(0,10))
    }
  },[])

  const history = useHistory()

  function gotoCategory() {
    
  }
 
  return (
    <div className="main-sub-header-wrapper border hide-in-mobile position-relative no-wrap" style={{justifyContent:"space-between"}}>

        <div className="display-flex-row no-wrap full-height space-between padding-container">
          {categories.map((category:any,index:number)=>{
            return(
              <div style={{padding:"0 1rem"}} className={`pointer center-aligned-column ${index===0?" --is-active":""}`} key={index}>
                <span className="text heading3 color-black font-normal">{category.category_name}</span>
              </div>
            )
          })}
        </div>
       

      </div>
  );
};

export default SubHeader;
