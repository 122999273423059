import $ from "jquery";
import React, { useState } from 'react';
import { hideComponentLoader, showComponentLoader, showToast, stopPropagation } from '../../../../../utils/UI_methods/global';
import { applicationConfig, waitFor } from '../../../../../utils/methods';
import Button2 from "../../../ui-components/buttons/Button2";
import { dimensions } from "../../../../../utils/customizein3d/modules/customizein3d";

// import './Home.css';

const UnitSelector: React.FC<{
    action:(unit:string)=>void
}> = (props) => {

    const[currUnit,setCurrUnit] = useState((applicationConfig.clientName === "DashSquare") ? "cm" : "inch")

    const[options] = useState(["feet","inch","cm"])

    async function changeDimensionUnit(event:any,unit:string) {
        stopPropagation(event)
        event.preventDefault()
        hideUnitOptions()
        showComponentLoader("changeFinishLoader")
        setCurrUnit(unit)
        props.action(unit)
        await waitFor(500)
        showToast("Dimensions unit changed to "+unit,2000)
        hideComponentLoader("changeFinishLoader")
    }

    function showUnitOptions() {
        $("#unitOptionsWindow").removeClass("display-none")
    }
     
    function hideUnitOptions() {
        $("#unitOptionsWindow").addClass("display-none")
    }

    return(
        <div className="" onClick={stopPropagation}>
            {/* <button className="action-button zindex30 user-options-link  shadow-light white-card border display-flex-row font-medium" onClick={showUnitOptions}>
                <span className="center-aligned-column pointer-none"><i className="fas fa-cog icon  font-"></i></span>
                <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label" >Unit - </span> 
                <span className="center-aligned-column marginleft5 heading4 hide-in-mobile color-danger ion-text-bold label state-text" >{currUnit}</span> 
            </button> */}
            {/* <div className="pointer zindex30 display-flex-row padding10" onClick={showUnitOptions} style={{justifyContent:"flex-end"}}>
                <div className="display-flex-row no-wrap" style={{border:"1px solid var(--op-color-primary)",padding:"5px 10px"}}>
                    <div className="center-aligned-column">
                        <i className="fas fa-ruler-combined font-small color-danger"></i>
                    </div>
                    <div className="center-aligned-column color-black marginleft5 heading2 font-normal color">
                        {currUnit === "feet"?"ft":currUnit === "inch"?"in":currUnit}
                    </div>
                </div>
            </div> */}

            <Button2
                configObj={{id:""}}
                action={showUnitOptions}
                label={currUnit === "feet"?"ft":currUnit === "inch"?"in":currUnit}
                fontAwesomeIcon={"fas fa-ruler-combined font-small color-danger"}
                iconPos="left"
                classNames={"shadow-medium button-small no-border"}
                svgUrl="assets/icon/svg/unit_danger.svg"
                isPreventDefault={true}
                
            />

            <div id="unitOptionsWindow" className='full-height-width display-none top-left black-fade-background center-aligned-column' style={{position:"fixed",backgroundColor:"rgba(0,0,0,0.7)",zIndex:1002}}>
                <div className=''>
                    <div className='heading4 font-medium ion-padding text-center color-white'>Select unit </div>
                    <div className='display-flex-row'>
                        {options.map((currOpt:any,index:number)=>{
                            return(
                                <div onClick={(event)=>changeDimensionUnit(event,currOpt)} key={index} className={`border pointer marginleft10 font-medium capitalize color-white heading4 ${currOpt === currUnit?"bg-color-primary":"bg-color-"}`} style={{padding:"10px 20px"}}>{currOpt}</div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
 
export default UnitSelector;
