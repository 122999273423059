import { Camera, Color } from "three"

export class View{
    left:number
    bottom:number
    width:number
    height:number
    background:Color
    camera:Camera
    aspectRatio:number

    constructor(camera:Camera,data:any){
        this.left = data.left
        this.bottom = data.bottom
        this.width = data.width
        this.height = data.height
        this.background = data.background
        this.camera = camera
        this.aspectRatio = data.aspectRatio
    }
}