// import PANOLENS from "panolens"
import $ from "jquery";
import { getWindowHeight, getWindowWidth, hideComponentLoader, showComponentLoader } from "../UI_methods/global";
import { checkFromCache, getAndSaveImageToCache, untarAndSaveToCache } from "../cache/cache";
import { getItemFromLocalStorage } from "../cache/localstorage";
import { getLatestConfigurationFromDatabase } from "../customizer/customizer";
import { applicationConfig, getFilteredArray, getObjectByParameter, logger, stringWithoutWhiteSpace, waitFor } from "../methods";
import { startPageTour } from "../siteTour/tour";
import data from "./data";
import VirtualTourConfig from "./virtualtourConfig";

var Marzipano = require('marzipano');

var progressElement: any,
  container: any,
  minimapContainer: any,
  geometry:any,
  viewer:any,
  view:any,
  virtualTourConfig:any


var defaultAreaName = "Foyer"
var scenes:any = []

var reactRef:any = {}

var areasList = []

var areaLocationIconMapping = {
  windowWidth:1366,
  windowHeight:625,
  Foyer:{
    x:140,
    offsetY:170
  },
  DiningRoom:{
    x:95,
    offsetY:121
  },
  Kitchen:{
    x:127,
    offsetY:83
  },
  BedRoom1:{
    x:88,
    offsetY:175
  },
  BedRoom2:{
    x:57,
    offsetY:87
  },
  BedRoom3:{
    x:57,
    offsetY:42
  },
  LivingRoom:{
    x:70,
    offsetY:139
  }
}
 

export async function Init(config:any,areaList:Array<any>) {
 
  scenes = []
  areasList = areaList

  // await downloadAndSaveImages(applicationConfig)




  container = document.getElementById("virtualTourContainer");
  minimapContainer = document.getElementById("minimapContainer");
  progressElement = document.getElementById("progress");

 
  var viewerOpts = {
    controls: {
      mouseViewMode: "drag",
      autorotateEnabled: true,
      fullscreenButton: true,
      viewControlButtons: true,
    
    }
  };
  viewer = new Marzipano.Viewer(container, viewerOpts)


  let limiter = Marzipano.RectilinearView.limit.traditional(4096, 100*Math.PI/180 , 150*Math.PI/180);
  view = new Marzipano.RectilinearView({pitch: -0.047285770062948984,yaw: -0.5149270425077574,fov: 1.5707963267948966}, limiter);
  geometry = new Marzipano.EquirectGeometry([{ width: 4000 }]);

 
  $("#minimapContainer").on("click",function(event:any) {
    console.log(event.clientX,event.offsetY)
  })

  window.addEventListener("resize",function() {
    updateCurrLocationIconPosition(applicationConfig?.areaName)
  })

  // scenes = await createScenes(areaList,scenes,viewer,geometry,view)

  if(!applicationConfig?.areaName.length){
    let area:any = getObjectByParameter(areaList,"area_name",defaultAreaName)
    applicationConfig?.setCurrArea(area.area_name)
    applicationConfig?.setCurrAreaId(area.area_id)
    await updateArea(defaultAreaName)
  }else{
    await updateArea(applicationConfig?.areaName)
  }

  startPageTour()

  logger?.info("virtualtour","start",`Start virtual tour: ${applicationConfig?.projectId}`)
}

export function closeMinimap() {
  $("#minimapContainer").fadeOut()
}

export function setVirtualTourConfigObject(config:VirtualTourConfig) {
  virtualTourConfig = config
}


export function openMinimap() {
  $("#minimapContainer").fadeIn()
}

function updateCurrLocationIconPosition(areaName:string) {

  try {
    let scaleX = 1
    let scaleY = 1
  
    scaleX = getWindowWidth() / areaLocationIconMapping.windowWidth
    scaleY = getWindowHeight() / areaLocationIconMapping.windowHeight 
  
    let left =  areaLocationIconMapping[areaName].x + "px"
    let top = $("#minimapContainer")?.offset()?.top  +  areaLocationIconMapping[areaName]?.offsetY + "px"
    
    $("#currLocationIcon").css("left",left)
    $("#currLocationIcon").css("top",top)
  } catch (error) {
    
  }


}

export function areaInit(setCurrArea:(area:string)=>void) {
  reactRef["setCurrArea"] = setCurrArea
}

async function switchScene(scene) {
  scene.switchTo();
}

export function addActiveClassToArea(itemText:any) {
  let allItems = $("#virtualTourAreaHrScroll").find(".item")
  for (let i = 0; i < allItems.length; i++) {
    const element = allItems[i];

    if(element.innerText === itemText){
      $(element).parent().addClass("--is-active")
      break
    }
  }
}

async function createScenes(areaName:string) {
  return new Promise(async resolve=>{
    // for (let i=0; i < areaList.length; i++) {
      // let area:any = areaList[i]
      await getImage(areaName).then(url=>{

        // panorama.reset()
        let source = Marzipano.ImageUrlSource.fromString(url);
        let scene = viewer.createScene({
          source: source,
          geometry: geometry,
          view: view,
          pinFirstLevel: true
        });

        let linkHotspots = getlinkHotspotsByAreaName(areaName)
        linkHotspots.forEach((hotspot:any)=>{
          let element = getLinkHotspotElement(hotspot.linkArea);
          let position = { yaw: hotspot.position.yaw, pitch: hotspot.position.pitch };
          scene.hotspotContainer().createHotspot(element, position);
        });

        scene.addEventListener("viewChange",function(){
            var view = viewer.view()
            let rotation = view._yaw * 180 / Math.PI
            $("#currLocationIcon").css("transform",`rotateZ(${rotation}deg)`)
            // console.log(view?.screenToCoordinates({x: e.clientX, y: e.clientY}))
        })

        scenes.push({
          areaName:areaName,
          scene:scene
        })

        // if(i===areaList.length-1){
          resolve(scene)
        // }
     })
    // }
  })
}

 
async function getImage(areaName:string) {

  return new Promise(async (resolve,reject)=>{
    let key = getRenderImageKey(areaName)
    await applicationConfig.awsConfig.getObject(key).then(async (data:any)=>{
      const bufferView = data.Body;
      const blob = new Blob([bufferView]);
      const url = URL.createObjectURL(blob);
      console.log(url)
      resolve(url)     
    }).catch((error:any)=>{
       
    })
    
    // checkFromCache(key)?.then((url:any)=>{
    //   resolve(url)     
    // }).catch(err=>{
    //   reject(err) 
    // })
  })
}

function getRenderImageKey(areaName:string) {
  // let configName = null
  let savedConfigurationFromLocalStorage = getItemFromLocalStorage(`Customizer_3d_${virtualTourConfig.projectName}_${virtualTourConfig.projectId}`) || null
  const configName = savedConfigurationFromLocalStorage?.configName || "Default"
  const configId = savedConfigurationFromLocalStorage?.configId || 0
  // if(savedConfigurationFromLocalStorage){
  //   configName = savedConfigurationFromLocalStorage.configName
  // }else{
  //   let savedConfigFromDatabase = getLatestConfigurationFromDatabase()
  //   if(savedConfigFromDatabase){
  //       configName = savedConfigFromDatabase.config_name
  //   }
  // }

  let preFix = "https://opusassets.s3.ap-south-1.amazonaws.com/"

  if (configName) {
    return `360Images/${applicationConfig?.clientName}/${virtualTourConfig.projectName}/${configName}_${configId}/${areaName}0000.png`
  }else{
    return `360Images/${applicationConfig?.clientName}/${virtualTourConfig.projectName}/${areaName}0000.png`
  }

}


export function handleSideBarToggle() {
  $("#virtualTourSideBar").toggleClass("--is-active")
}


function getlinkHotspotsByAreaName(areaName:string) {
  return data[virtualTourConfig.projectName].find(currObj=>currObj.areaName === areaName).infoSpots || []
}

 
export async function updateArea(areaName: string) {

  if(areaName.toLowerCase() === "all" || areaName === virtualTourConfig.projectName){
    areaName = defaultAreaName
  }
  
  await downloadAndSaveImages(areaName)
  showComponentLoader("changeAreaLoader")
  let area:any = getObjectByParameter(applicationConfig?.data?.areasList,"area_name",areaName)
  if(area){
    applicationConfig?.setCurrArea(area.area_name)
    applicationConfig?.setCurrAreaId(area.area_id)
    $("#virtualTourAreaName").text(areaName)
    let scene = scenes.find(currScene=>currScene.areaName === areaName)?.scene
    if(!scene){
      scene = await createScenes(areaName)
    }
    await switchScene(scene)
    reactRef?.setCurrArea(areaName)
    updateCurrLocationIconPosition(areaName)
  
    await waitFor(500)
    generateProductsToolTips(scene,areaName)
    hideComponentLoader("changeAreaLoader")
  
    logger?.info("virtualtour","changearea",`Area changed: ${areaName}`)
  }
  

}


export function generateProductsToolTips(scene:any,currArea:string) {
  let productList = getFilteredArray(virtualTourConfig?.projectProductsList,"area_name",currArea);
  if(productList && productList.length){
    for (const product of productList) {
      if(product?.virtual_tour_data){
        let positions = JSON.parse(product?.virtual_tour_data)
        let element = document.querySelector("#tooltip_" + stringWithoutWhiteSpace(product?.product_name))
        if(element){
          scene.hotspotContainer().createHotspot(element, { yaw: positions?.yaw, pitch: positions?.pitch });
        }
      }
    }
  }
}


async function downloadAndSaveImages(areaName:string) {
  showComponentLoader("walkthroughLoader")
  // showComponentLoader("minimapLoader")
  let urlPrefix = `360Images/${applicationConfig?.clientName}/${applicationConfig?.projectName}`
  let tarFileKey = applicationConfig.awsConfig.getTarFileKey("360Images",{clientName:applicationConfig?.clientName,projectName:applicationConfig?.projectName,areaName:areaName})
  
  return new Promise((resolve,reject)=>{
    getImage(areaName).then(url=>{
      hideComponentLoader("walkthroughLoader")
      resolve(url)
    }).catch(async (err)=>{
      let key = getRenderImageKey(areaName)
      getAndSaveImageToCache(key)?.then(data=>{
        hideComponentLoader("walkthroughLoader")
        resolve(data)
      }).catch(err=>{
        // hideComponentLoader("walkthroughLoader")
        // reject(err)
        //If config is saved but the renders are not generated 
        downloadAndSaveDefaultImages(areaName).then(data=>{
          hideComponentLoader("walkthroughLoader")
          resolve(data)
        }).catch(err=>{
          hideComponentLoader("walkthroughLoader")
          reject(err)
        })
      })
    })
  })
}


async function downloadAndSaveDefaultImages(areaName:string) {
  let urlPrefix = `360Images/${applicationConfig?.clientName}/${applicationConfig?.projectName}`
  let tarFileKey = applicationConfig.awsConfig.getTarFileKey("360Images",{clientName:applicationConfig?.clientName,projectName:applicationConfig?.projectName,areaName:areaName})
  return new Promise((resolve,reject)=>{
      untarAndSaveToCache(applicationConfig.awsConfig,tarFileKey,urlPrefix)?.then(data=>{
        hideComponentLoader("walkthroughLoader")
        resolve(data)
      }).catch(err=>{
        hideComponentLoader("walkthroughLoader")
        reject(err)
      })
  })
}




function getLinkHotspotElement(areaName:string) {

  // Create wrapper element to hold icon and tooltip.
  var wrapper = document.createElement('div');
  wrapper.classList.add('hotspot');
  wrapper.classList.add('link-hotspot');

  // Create image element.
  var icon = document.createElement('img');
  // icon.src = 'https://cdn4.iconfinder.com/data/icons/map-1-0/1024/virtual_tour-512.png';
  icon.src = 'assets/images/link.png';
  icon.classList.add('link-hotspot-icon');
  icon.classList.add(areaName);

  // Set rotation transform.

  // var transformProperties = [ '-ms-transform', '-webkit-transform', 'transform' ];
  // for (var i = 0; i < transformProperties.length; i++) {
  //   var property = transformProperties[i];
  //   icon.style[property] = 'rotate(' + 0 + 'rad)';
  // }

  // Add click event handler.
  wrapper.addEventListener('click', function() {
    updateArea(areaName);
  });

  // Prevent touch and scroll events from reaching the parent element.
  // This prevents the view control logic from interfering with the hotspot.
  stopTouchAndScrollEventPropagation(wrapper);

  // Create tooltip element.
  var tooltip = document.createElement('div');
  tooltip.classList.add('hotspot-tooltip');
  tooltip.classList.add('link-hotspot-tooltip');
  tooltip.innerHTML = areaName;

  wrapper.appendChild(icon);
  wrapper.appendChild(tooltip);

  return wrapper;
}

function stopTouchAndScrollEventPropagation(element) {
  let eventList = [
    'touchstart', 'touchmove', 'touchend', 'touchcancel',
    'pointerdown', 'pointermove', 'pointerup', 'pointercancel',
    'wheel'
  ];
  for (var i = 0; i < eventList.length; i++) {
    element.addEventListener(eventList[i], function(event) {
      event.stopPropagation();
    });
  }
}

export function goback() {
  logger?.info("virtualtour","goback",`End virtual tour`)
  window.history.back()
}

export function releaseMemoryVirtualTour() {
  scenes = []
}