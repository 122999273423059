
export default class VirtualTourConfig{
    projectProductsList: any[];
    areaName: string;
    areaId: string;
    areaViewsMapping: any;
    clientName: string;
    projectName: string;
    projectId: string;
    areaProductsList:Array<any>
   
    constructor(project:any){
       this.projectName = project.project_name || project.projectName
       this.projectId = project.project_id || project.projectId
    }

    // setCurrentState(projectName:string,areaList:object,areaViewList:object,areaViewProductList:object,projectId:number,areaId:number,viewId:number,configName:string,areaName:string,viewName:string){
    //     this.projectName = projectName
    //     this.areaList = areaList
    //     this.areaViewList = areaViewList
    //     this.areaViewProductList = areaViewProductList
    //     this.projectId = projectId
    //     this.areaId = areaId
    //     this.viewId = viewId
    //     this.currConfigName = configName
    //     this.areaName = areaName
    //     this.viewName = viewName
    // }

 
    setProjectProductsList(data:Array<any>){
        this.projectProductsList = data
    }

    setCurrArea(area:string){
        this.areaName = area
    }
    setAreaViewsMapping(mapping:any){
        this.areaViewsMapping = mapping
    }

    setAreaProductsList(products:Array<any>){
        this.areaProductsList = products
    }
 

}