import React, { useEffect, useState } from 'react';
import { applicationConfig, getObjectByParameter } from '../../../../../../utils/methods';

// import './Home.css';

const HeaderMiddle: React.FC<{
}> = (props) => {


  const[collectionName,setCollectionName] = useState("")

  useEffect(()=>{
    let obj = getObjectByParameter(applicationConfig?.data?.moodboardList,"moodboard_id",applicationConfig?.getMoodboardId())
    setCollectionName(obj?.name || "")
  },[])


    return(
      <>
      <div className="full-width hide-in-mobile">
        <div className="center-aligned-row">
          <span className="header-heading color-primary">Moodboards <span className="padding10 color-dark">/</span> </span>
          <span className="header-heading color-primary"> {collectionName} <span className="padding10 color-dark"></span> </span>
        </div>

      </div>
      <div className="full-width hide-in-desktop ">
        <div className="center-aligned-row">
          <div className='option display-flex-row'>
              <div className="center-aligned-column"><i className="fas fa-info-circle  color-primary "></i></div>
              <div className='marginleft5 heading1 letter-spacing center-aligned-column'> {collectionName}</div>
          </div>
        </div>
      </div>
      </>
        
    );
};
 
export default HeaderMiddle;
