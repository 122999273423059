import React, { useState } from "react";
import UpiPayment from "./UpiPayment";
import CardPayment from "./CardPayment";
import CashPayment from "./CashPayment";

 

const PaymentOptions: React.FC<{
  isOpen:boolean
  callback:()=>void
  price:number
}> = (props) => {



  const[currSelectedMethod,setCurrSelectedMethod] = useState("")


  const[paymentMethods] = useState([
    {
      id:1,
      methodName:"Card Payment",
      value:"card",
      imageSrc:"",
      icon:"fab fa-cc-visa"
    },
    {
      id:1,
      value:"upi",
      methodName:"Upi",
      imageSrc:"",
      icon:"fab fa-google-pay"
    },
    {
      id:1,
      value:"cash",
      methodName:"Cash",
      imageSrc:"",
      icon:"far fa-money-bill-alt"
    }
  ])

  function paymentSuccessHandle() {
    props.callback()
  }
 

  function Options() {
    return(
      <div className="">
        <div className="padding5">
          {paymentMethods.map((currMethod:any,index:number)=>{
            return(
              <div onClick={()=>{selectMethodHandle(currMethod.value)}} className="display-flex-row pointer position-relative padding5 border ion-margin-bottom" key={index}>
                <span className="padding5"><i className={`${currMethod.icon} color-black font-ex-large`}></i></span>
                <span className="heading3 center-aligned-column padding5">{currMethod.methodName}</span>
                <span className="right-middle"> <i className="fas fa-chevron-right font-md-small color-dark"></i> </span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  function selectMethodHandle(method:string) {
    setCurrSelectedMethod(method)
    // setIsOpenPayment(true)
  }

  function goBackAction() {
    if(currSelectedMethod){
      setCurrSelectedMethod(null)
    }
  }
 
  return (
    <div className="full-height-width padding10">


        <div className="header-content">
          <div className="display-flex-row">
            <span className="center-aligned-column pointer" onClick={goBackAction}><i className="fas fa-arrow-left ponter-none font-medium color-black"></i></span>
            <span className="heading2 uppercase marginleft10">
              {currSelectedMethod?"Make Payment":"Please select a payment method"}
            </span>
          </div>
        </div>
        <div className="body-content">
          <div className="full-height-width">
            {currSelectedMethod === "upi"?
              <UpiPayment price={props.price} callback={paymentSuccessHandle}/>
            :currSelectedMethod === "card"?
              <CardPayment price={props.price} callback={paymentSuccessHandle}/>
            :currSelectedMethod === "cash"?
              <CashPayment price={props.price} callback={paymentSuccessHandle}/>
            :<Options/>}
          </div>
          
        </div>

        {/* <div className="bottom-left bg-color-white full-width ion-padding">
          <div className="center-aligned-row">
            <span className="heading1">Total</span>
            <span className="heading1 marginleft5">{getFormatedPrice(STORE.productConfiguration.price)}</span>
          </div>
        </div> */}
    </div>
  );
};

export default PaymentOptions;
