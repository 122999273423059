import {
  IonPage
} from "@ionic/react";
import React from "react";

 

const Designs: React.FC<{
    projects:Array<any>;
    BASE_PATH_API:string;
}> = (props) => {
   

   
  return (
    <IonPage>
        
    </IonPage>
  );
};

export default Designs;
