import React, { useEffect, useState } from "react";
import "../../../style/ui-components/accordion.css"
import Button2 from "../buttons/Button2";


const AccordionForProccess: React.FC<{
  label:string
  Content:any
  SubHeader:any
  index:number
  isOpen:boolean
  setIsOpen:(val:boolean)=>void
  isConfirmed:boolean
  Footer?:any
  onClick?:()=>void
  changeButtonText?:string
}> = (props) => {

  // const[isOpen,setIsOpen] = useState(props.isOpen)

  // useEffect(()=>{
  //   setIsOpen(props.isOpen)
  //   console.log(props.isOpen)
  // },[props.isOpen])

  function toggle() {
    if(props.onClick){
      props.onClick()
      return
    }
    props.setIsOpen(!props.isOpen)
  }

 
  return (
    <React.Fragment>
      <div className={`accordion-container marginTop5 full-width accordion-proccess ${props.isOpen?" --is-active shadow-medium":" "} ${props.isConfirmed?" --is-confirmed":" "}`}>

          <div className='display-flex-row position-relative pointer no-wrap space-between accordion-header' onClick={toggle}>
              <div className='pointer-none display-flex-row no-wrap '>
                <div className="accordion-badge">{props.index}</div>
                <div className="marginleft" style={{marginTop:"2px"}}>
                  <div className="display-flex-row no-wrap">
                    <div className="heading2 accordion-text store-color-primary capitalize font-normal center-aligned-column">{props.label} </div>
                    {/* {props.isConfirmed?
                      <span className="center-aligned-column marginleft10"> <i className="fas fa-check-square font-large color-warning"></i> </span>
                    :null} */}
                  </div>

                  
              
                  
                </div>
              </div>
              {/* <div className='pointer-none center-aligned-column'><i className='fas fa-chevron-down accordion-icon'></i></div> */}
              <div className='right-middle'>
                <div className="display-flex-row no-wrap">
                  {!props.isOpen?
                      <props.SubHeader/>
                    :null}
                  {props.isConfirmed?
                  <div className="center-aligned-column">
                    <Button2
                      configObj={{id:""}}
                      action={()=>{}}
                      label={`${props.changeButtonText?props.changeButtonText:"Change"}`}
                      fontAwesomeIcon={"fas fa-cube color-warning"}
                      iconPos=""
                      classNames={"button-ex-small bg-color-"}
                    />
                  </div>
                  :null}

                </div>
               
              </div>
          </div>

          {props.isOpen?
            <div className="position-relative">
            <div className={`content padding5 --is-active`}>
              <props.Content/>
            </div>
            <div className="">
              {props.Footer?
                <props.Footer/>  
              :null}
            </div>
            </div>
          :null}

         

          
      </div>
    </React.Fragment>
  
  );
};

export default AccordionForProccess;
