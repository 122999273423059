import React, { useState } from "react";
import { useEffect } from "react";
import {
  closeDropDowns,
  handleDropDownToggle,
  toggleDropdownMenu,
} from "../../../../utils/UI_methods/global";
import { rippleEffectOnClick } from "../../../../utils/UI_methods/ui_effects";

const DropDown2: React.FC<{
  values: any;
  currSelectedValue: any;
  action: (value: any) => void;
  classNames: string;
  label:string
}> = (props) => {


  return (
    <div
      className={`dropdown-container center-aligned-column position-relative ${props.classNames}`}>
      <div
        className="dropdown-select input display-flex-row overflow-hidden position-relative text-start dropdown-toggle full-width"
        onPointerDown={rippleEffectOnClick}
        onClick={handleDropDownToggle}>
        <div className="ion-margin-end pointer-none select-value pointer-none">
        <span className="capitalize color-dark heading4">{props.label} : </span> <span className="capitalize heading4 color-black">{props.currSelectedValue}</span>  
        </div>
      </div>
      <div
        role="menu"
        aria-hidden="true"
        className="hide-scrollbar dropdown-menu-rounded dropdown-menu">
        <div className="display-flex-row">
          {props.values?.map((value: any, index: number) => {
            let classNames = "dropdown-item"
            let classNamesContainer = "dropdown-item-container custom-col-100"
            if(value===props.currSelectedValue){
              classNames = classNames + " --is-active"
            }
            return (
              <div className={classNamesContainer} key={index}>
                <button
                  key={index}
                  type="button"
                  className={classNames}
                  onClick={() => {
                    props.action(value);
                    closeDropDowns();
                  }}>
                  {value}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DropDown2;
