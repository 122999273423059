import React, { useEffect, useState } from "react";
import { Group } from "three";
import { kitchenPlanner } from "../../../../utils/customizein3d/area3dmodel/area3dModel";
import { openProductInfoPanel } from "../../../../utils/customizein3d/area3dmodel/UI_methods";
import { addItemToGroup, deleteGroup, createNewGroup, unlinkModuleFromGroup } from "../../../../utils/kitchenplanner/groups";
import { applicationConfig } from "../../../../utils/methods";
import { showToast } from "../../../../utils/UI_methods/global";
import Button2 from "../../ui-components/buttons/Button2";
import DropDown from "../../ui-components/filters/DropDown";

const GroupList: React.FC<{
}> = (props) => {

  const[groupsList,setGroupsList] =  useState(kitchenPlanner.groupsList)
  const[refreshList,setRefreshList] =  useState(0)
  
  useEffect(()=>{
    if(applicationConfig){
      applicationConfig.setFunctionRef("kitchenPlanner",{deleteGroupFromUI:deleteGroupFromUI})
      applicationConfig.setFunctionRef("kitchenPlanner",{setRefreshGroupList:setRefreshList})
    }
  },[applicationConfig])

  useEffect(()=>{
    setGroupsList(kitchenPlanner.groupsList)
  },[refreshList])

  // function addGroupHandle() {
  //   let group = getNewGroup()
  //   if(group){
  //     let index = groupsList.length+1
  //     let groupName = "Group"+index
  //     group.name = groupName
  //     let newGroup = {
  //       group:group,
  //       name:groupName
  //     }
  //     setGroupsList(prevData=>{
  //       return [...prevData,newGroup]
  //     })
  //     addItemToGroupHandle(group)
  //     setRefreshList(Number(new Date()))
  //     showToast("New group has been created",3000)
  //   }
   
  // }

  function deleteGroupHandle(group:any) {
    
    deleteGroup(group)
  }

  function deleteGroupFromUI(group:Group) {
    let list = groupsList.filter(currGroup=>currGroup.uuid!==group.uuid)
    setGroupsList(list)
  }
 

  function unlinkObjectAction(group:Group,object:any) {
    unlinkModuleFromGroup(group,object)
    setRefreshList(Number(new Date()))
  }

  // useEffect(()=>{
  // },[currSelectedBreadthFeetValue,currSelectedBreadthInchValue,currSelectedLengthFeetValue,currSelectedLengthInchValue])

  return (
    <>
    <div className="overflow-y-scroll position-relative padding10 full-height-width">
      {groupsList?
        <>
        {groupsList.map((group:any,index:number)=>{
          return(
            <div className="wrapper border marginTop10" key={index}>
              <div className="display-flex-row space-between no-wrap bg-color-light padding10">
                <div className="heading3 font-md-small center-aligned-column">{group.name}</div>
                <div className="display-flex-row no-wrap">
                  {/* <div className="display-flex-row no-wrap click-effect" onClick={()=>addItemToGroupHandle(group.group)}>
                    <div className=" "> <i className="fas fa-plus font-small color-primary"></i> </div>
                    <div className="font-small marginleft5 heading3 center-aligned-column color-black">Add to group</div>
                  </div> */}
                  <div className="display-flex-row marginleft10 no-wrap click-effect" onClick={()=>deleteGroupHandle(group)}>
                    <div className=""> <i className="fas fa-trash font-small color-danger"></i> </div>
                    <div className="font-small marginleft5 heading3 center-aligned-column color-black">Remove group</div>
                  </div>
                </div>
                  
              </div>
              <div className="list">
                {group.children.length?group.children.map((child:any,j:number)=>{
                  return(
                    <div key={j} className="display-flex-row border-bottom no-wrap space-between">
                      <div className="padding10 heading4 " id={`${String(refreshList)}`} key={j}>{j+1}. {child.name}</div>
                      <a className="hyperlink click-effect center-aligned-column font-small padding5" onClick={()=>unlinkObjectAction(group,child)}>Unlink</a>
                    </div>
                  )
                }):
                <div className="white-card no-shadow ion-padding center-aligned-column heading4">
                  No item has been added to group
                </div>
                }
              </div>
            </div>
          )
        })}
        </>
        :
        null
      }

      
    </div>
    </>
  );
};

export default GroupList;
