import React, { useEffect, useState } from 'react';

import { enablePrioritySelectionMode, generateLayoutUI } from '../../../../../../utils/moodboard/layouts/UI_methods';

const Layout: React.FC<{
  configObj:any
}> = (props) => {


  const[refreshLayouts,setRefreshLayouts] = useState(false)

  useEffect(()=>{
    props.configObj.setFunctionRef("moodboard",{setRefreshLayouts:setRefreshLayouts})
  },[])

  useEffect(()=>{
    setTimeout(() => {
      props.configObj.setFunctionRef("moodboard",{refreshLayouts:refreshLayouts})
      generateLayoutUI()
    }, 500);
  },[refreshLayouts])

  return (
    <React.Fragment>
      <div className="border-bottom hide-in-mobile full-width ion-margin-bottom">
          <div className="heading1 text-center font-normal full-width padding10">Layouts</div>
      </div>

      {/* <div className="full-width padding10">  
          <div className="center-aligned-column shadow-light bg-color-primary white-card" onClick={enablePrioritySelectionMode}>
            <div className="display-flex-row padding10">
              <i className="fas fa-list font-medium center-aligned-column color-white"></i>
              <div className="heading2 center-aligned-column marginleft10 color-white">
                Update Priority
              </div>
            </div>
          </div>
      </div> */}

      <div className="full-height-width ion-padding" id="layoutsWrapper"  style={{paddingTop:"0"}}>


      </div>
 
    </React.Fragment>
  );
};

export default Layout;
