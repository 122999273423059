import { IonModal } from "@ionic/react";
import React, { useEffect } from "react";
import RembgForm from "./RembgForm";

const Rembg: React.FC<{
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  uploadImage: (val: any) => void
  isFloorplan:boolean
}> = (props) => {

  useEffect(()=>{
    if(props.isOpen){
      // logger?.info("rembg","rembg",`Start rembg`)
    }
  },[props.isOpen])


  return (

    <IonModal
      onDidDismiss={() => props.setIsOpen(false)}
      isOpen={props.isOpen}

      cssClass='form-action-modal'
    >
     
        <RembgForm
          isOpen={props.isOpen}
          setIsOpen={props.setIsOpen}
          uploadImage={props.uploadImage}
          isFloorplan={props.isFloorplan}
        />


    </IonModal>
  );
};

export default Rembg;
