import { IonSpinner } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useClearCache } from 'react-clear-cache';
import Loader from './components/common/shared-components/main-ui/Loader';
import ThemeSetting from './components/common/shared-components/main-ui/ThemeSetting';
import CartToast from './components/common/ui-components/notification/CartToast';
import NotificationToast from './components/common/ui-components/notification/NotificationToast';
import TokenExpiry from './components/common/ui-components/notification/TokenExpiry';
import UpdateApp from './components/common/ui-components/notification/UpdateApp';
import Welcome from './components/common/ui-components/notification/Welcome';

import Logout from './components/common/ui-components/notification/Logout';
import GlobalToast from './components/common/ui-components/notification/StoreToast';
import CrmController from './components/controllers/CrmController';
import OpusController from './components/controllers/OpusController';
import StoreController from './components/controllers/StoreController';
import UseCacheBuster from './components/routes/UseCacheBuster';
import InventoryController from './components/controllers/InventoryController';
import { updateModelAvailability } from './services/api';
import ElevateContainer from './components/controllers/ElevateContainer';
import { getSubDomainName } from './utils/methods';
import AccessDenied from './components/common/modules/access-denied/AccessDenied';
import KanvasController from './components/controllers/KanvasController';



const App: React.FC = () => {

  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  const[subDomain,setSubDomain] = useState(getSubDomainName())

  // useEffect(()=>{
  //   setSubDomain("crm")
  // },[])
  
  return(
    <React.Fragment>
        <Loader
          id="mainLoader"
          data={{message:"Fetching data"}}
          loaderType="dotsText"
          className=" customizeButton"
        />
        <Loader
          id="mainLoaderSpinner"
          data={{message:"Fetching data"}}
          loaderType="spinner"
          className="fade-background-loader"
        />
        <Loader
          id="storeLoader"
          data={{message:"Please wait..."}}
          loaderType="dotsText"
          className="fade-background-loader position-fixed"
        />
        {/* <ThemeSetting /> */}
        <GlobalToast />
        <CartToast/>
        <NotificationToast/>
        <img id="draggableImg" className="pointer-none display-none" src="assets/images/placeholder.png" style={{position:"fixed",zIndex:1}} width="50" height="50"/>
        <div id='draggableImgLoader' className='zindex100 padding0 pointer-none display-toggle-wrapper' style={{position:"fixed"}}>
          <IonSpinner name="lines-small" color="primary"/>
        </div>

        <Welcome/>
        <UseCacheBuster/>
        <TokenExpiry/>
        <Logout/>
        {!isLatestVersion && true?
         <UpdateApp/>
        :null}

        <button className="top-left display-none" style={{zIndex:1001}} onClick={updateModelAvailability}>UpdateModelAvailability</button>

        {subDomain === "crm"?
          <CrmController/>
        :
        subDomain === "store"?
          <StoreController/>
          // <div className='heading3 font-large text-center center-aligned-column middle full-height-width'>We’re currently performing scheduled maintenance. Please check back soon. Thank you for your patience!</div>
        :
        subDomain === "inventory"?
          <InventoryController/>
        :
        subDomain === "elevate"?
          <ElevateContainer/>
          // <AccessDenied/>
        :
        subDomain.includes("upgraded-three") || subDomain.includes("testing")?
          <KanvasController/>
        :
        subDomain.includes("kanvas") || subDomain.includes("kairafabrics")?
          <KanvasController/>
        :
        subDomain.includes("staging")?
          <OpusController/>
        :
          <StoreController/>
        }

        {/* <MainComponent/> */}
      </React.Fragment>
  )
}

export default App;
