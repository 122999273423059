import React, { useEffect, useState } from "react";
import { confirmAccordionHandle, confirmProccessAccordion, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { ArmChairConfiguration } from "../../../../../utils/store/storeConfiguration";
import Modal2 from "../../../../common/shared-components/Modals/Modal2";
import AccordionForProccess from "../../../../common/ui-components/accordion/AccordionForProccess";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import MaterialsOptions from "../product-customizer/Material-Selector/MaterialsOptions";
import SelectQuality from "../product-customizer/common/SelectQuality";
import AccordionForProccessCss from "../../../../common/ui-components/accordion/AccordionForProccessCss";

 

const ArmChairConfigOptions: React.FC<{
  product:any
  updatePrice:()=>void
  productConfiguration:ArmChairConfiguration
}> = (props) => {

  const[currVariant,setCurrVariant] = useState(props.productConfiguration.variant.variantName)
  const[currQuality,setCurrQuality] = useState(props.productConfiguration.quality)
  const[currFabric,setCurrFabric] = useState(props.productConfiguration.fabric)

  const[isOpenFabricSelection,setisOpenFabricSelection] = useState(false)
 
  const[isConfirmVariant,setisConfirmVariant] = useState(false)
  const[isConfirmQuality,setisConfirmQuality] = useState(false)
  const[isConfirmFabric,setisConfirmFabric] = useState(true)
   

  useEffect(()=>{
    props.updatePrice()
  },[isConfirmVariant,currQuality,isConfirmQuality,props.product])

  function CurrentSelection(props:any) {
    const heading = props.heading
    const value = props.value
    return(
      <div className="center-aligned-column">
          <div className="heading2 font-md-small marginRight10 color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">{value}</div>
      </div>
    )
  }


  function Footer(props:any) {
    const confirmHandle = props.confirmHandle
    return(
      <div className="center-aligned-column padding5 full-width bg-color-light">
        <div className="">
          <Button2
            configObj={{id:""}}
            action={()=>{confirmHandle()}}
            label={"Confirm Selection"}
            fontAwesomeIcon={"fas fa-cart-plus color-white"}
            iconPos=""
            classNames={"no-shadow margin0 bg-color-warning"}
          />
        </div>
      </div>
    )
  }


  function confirmMaterialHandle() {
      setisOpenFabricSelection(false)
      setisConfirmFabric(true)
      showGlobalToast("Saved")
  }


  return (
    <div className='product-details product-customizer-wrapper'>


      <Modal2
        isOpen = {isOpenFabricSelection}
        Content = {()=>(
          <MaterialsOptions product={props.product} confirmMaterialHandle={confirmMaterialHandle} productConfiguration={props.productConfiguration} onChange={setCurrFabric}/>
        )}
        onDismiss = {()=>setisOpenFabricSelection(false)}
        classNames="medium-x-modal"
        id=""
      />
     

      <div className="accordion-wrapper">

        <AccordionForProccessCss
            label="Choose Your Quality"
            SubHeader={()=>(
              <CurrentSelection heading="Quality" value={currQuality}/>
            )}
            Content={()=>(
              <SelectQuality productConfiguration={props.productConfiguration} currCategory={props.product.category_name}  currQuality={currQuality} onChange={setCurrQuality}/>
            )}
            index={1}
            Footer={()=>(
              <Footer confirmHandle={()=>{
                setisConfirmQuality(false)
                if(!isConfirmFabric){
                  setisOpenFabricSelection(true)
                }
                confirmAccordionHandle(1)
              }} />
            )}
        />

          <AccordionForProccessCss
            label="Select Your Fabric"
            SubHeader={()=>(
              <CurrentSelection heading="Fabric" value={props.productConfiguration.materials.length?props.productConfiguration.materials[0]?.material?.materialCode:"No Selection"}/>
            )}
            Content={()=>(
                <></>
            )}
            index={2}
            onClick={()=>{
              setisOpenFabricSelection(true)
              confirmAccordionHandle(2)
            }}
        />
      </div>
        </div>
  );
};

export default ArmChairConfigOptions;
