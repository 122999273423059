import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import $ from "jquery"
import CreateMaterialForm  from "./CreateMaterialForm";
import { getObjectByParameter } from "../../../../utils/methods";
import MaterialDetails from "./MaterialDetails"

const ReviewFinish: React.FC<{
  configObj: any;
  finishDetails:any
  addFinishLoader:string
}> = (props) => {



  const[totalMaterial,setTotalMaterials] = useState([1])

 


  useEffect(()=>{
   console.log(props.finishDetails)
  },[props.finishDetails])
 
 

  return (
    <React.Fragment>
       <div className="display-flex-column no-wrap padding10 full-height hide-scrollbar">
        
        <div className="finish-details-container">
          <div className="heading2 color-danger">Material Details</div>
          <MaterialDetails
            configObj={props.configObj}
            finishDetails={props.finishDetails}
          />
        </div>


        <div className="add-materials-wrapper fill-available-height">


            <CreateMaterialForm
            configObj={props.configObj}
            finishDetails={props.finishDetails}
            addFinishLoader={props.addFinishLoader}
            />
        </div>
       


      

      </div>
    </React.Fragment>
  );
};

export default ReviewFinish;
