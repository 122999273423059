import {
  IonPage
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { configuration, goBackToPreviousPage, setCustomizein3dConfiguration } from "../../../../utils/customizein3d/modules/customizein3d";
import { getPageTourElements } from "../../../../utils/siteTour/elements";
import "../../../style/admin/customizein3d.css";
import InnerPageHeader from "../../shared-components/main-ui/InnerPageHeader";
import Loader from "../../shared-components/main-ui/Loader";
import SiteTour from "../../ui-components/siteTour/SiteTour";
// import Controls from "./controls/Controls"
import { getItemFromLocalStorage } from "../../../../utils/cache/localstorage";
import { applicationConfig as ApplicationConfig } from "../../../../utils/moodboard/applicationConfig";
import Canvas from "./canvas/Canvas";
import CameraSettings from "./canvas/Render/CameraSettings";
import ConfigLoadOptions from "./common/ConfigLoadOptions";
import HeaderMiddle from "./common/HeaderMiddle";
import HeaderRight from "./common/HeaderRight";
import Actions from "./controls/Actions";
import Controls from "./controls/Controls";

const CustomizeIn3D: React.FC<{
  configObj:ApplicationConfig
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {

  const[isSpacePlannerMode,setIsSpacePlannerMode] = useState(false)
  const[isFloorplanMode,setIsFloorplanMode] = useState(false)
  const[isTakeRenderMode,setIsTakeRenderMode] = useState(false)
  const[isViewProductMode,setIsViewProductMode] = useState(configuration?.isViewProductMode)
  const[modulesConfigurtion,setModulesConfiguration] = useState(configuration)
  const[showGoBackAlert,setShowGoBackAlert] = useState(false)

  //used in left side bar to identify which product is selected 
  //State for child components
  
  const[canvasLoaderId]:any = useState("canvasLoader")


  const[tourElementsList] = useState(getPageTourElements("Customizein3D"))


  useEffect(()=>{
    if(configuration){
      setModulesConfiguration(configuration)
    }else{
      let product = getItemFromLocalStorage("currProduct")
      if(props.isLogin && !props.isLoadedPageConfiguration){
        init(product)
      }
      if(!product){
        window.location.href = "/home"
      }
    } 

    props.configObj.setFunctionRef("customizein3d",{setIsViewProductMode:setIsViewProductMode})
    props.configObj.setFunctionRef("customizein3d",{toggleSpacePlannerValue:setIsSpacePlannerMode})
    props.configObj.setFunctionRef("customizein3d",{setShowGoBackAlert:setShowGoBackAlert})
    props.configObj.setFunctionRef("customizein3d",{setIsTakeRenderMode:setIsTakeRenderMode})
    props.configObj.setFunctionRef("customizein3d",{setIsFloorplanMode:setIsFloorplanMode})

  },[])
  
  // const history = useHistory();

  // useEffect(() => {
  //   const unblock = history.block((location) => {
  //     console.log(location.pathname)
  //     if (location.pathname === '/products') {
  //       return 'Are you sure you want to leave this page?';
  //     }
  //   });

  //   return () => {
  //     unblock();
  //   };
  // }, [history]);

  function init(product:any) {
    let configuration = setCustomizein3dConfiguration(product,isViewProductMode)
    setModulesConfiguration(configuration)
    props.setIsLoadedPageConfiguration(true)
  }


  function ContentMiddle() {
    return(
      <HeaderMiddle
        currProduct={modulesConfigurtion.product}
      />
    )
  }



  function ContentRight() {
    return(
      <HeaderRight
        modulesConfigurtion={modulesConfigurtion}
      />
    )
  }

  function goback() {
    window.location.href = ""
  }

 
  return (
    <IonPage>
      {props.configObj && modulesConfigurtion?
      <>
       {/* <SiteTour
          tourElementsList={tourElementsList}
          moduleName="productConfigurator"
        /> */}
        <InnerPageHeader 
          configObj={props.configObj}
          componentRight={ContentRight}
          componentMiddle={ContentMiddle}
          goBack={()=>goBackToPreviousPage(true)}
          moduleName="customizein3d"
        />

    

        {/* <div className="top-middle white-card border padding10">
          <div className="heading4">Near :{camera?.near}</div>
          <div className="heading4">Far :{camera?.far}</div>
        </div> */}

        

        <div   className='display-none top-left full-height-width bg-color-white' id="confirmationMessageWrapper" style={{zIndex:1001}}>
          <div className='middle'>
            <div className='center-aligned-row'><i className='fas fa-check-circle color-success font-ex-large'></i></div>
            <div  className='heading2 marginTop10 text-center'>Your request has been sent succesfully. </div>
          </div>

          <div onClick={goback} className='bottom-middle ion-padding' style={{bottom:"3rem"}}>
            <div className='white-card shadow-medium bg-color-primary color-white heading2 click-effect letter-spacing1' style={{padding:"10px 20px"}}>Back to home</div>
          </div>

        </div>


      <ConfigLoadOptions
        modulesConfigurtion={modulesConfigurtion}
      />
    

        <div className="position-relative customize-canvas-controls-wrapper op-customize-canvas-controls-wrapper" id="mainCustomizein3DWrapper" style={{flexDirection:"row"}}>
        <Loader
                id={canvasLoaderId}
                data={{}}
                loaderType="topProgressBar"
                className="--is-active zindex100 background-black-fade-medium"
              />

        <Loader
          className="fade-background-loader"
          id="changeFinishLoader"
          data={{}}
          loaderType="dots"
        />

        <Loader
          className="fade-background-loader"
          id="createConfigLoader"
          data={{}}
          loaderType="dotsText"
        />

         
          <div className="customize-canvas-wrapper center-aligned-row shadow-light ">
            {/* <ToggleControlsWrapper position="right" /> */}
              <Canvas
                modulesConfigurtion={modulesConfigurtion}
                isSpacePlannerMode={isSpacePlannerMode}
                isFloorplanMode={isFloorplanMode}
                currProduct={modulesConfigurtion.product}
                isViewProductMode={isViewProductMode}
                setIsSpacePlannerMode={setIsSpacePlannerMode}
              />

     

              {isTakeRenderMode?
                <CameraSettings
                />
              :null}

              <Actions
                configObj={props.configObj}
              />
              
          </div>
          <div className="customize-controls-wrapper shadow-light" id="controlsWrapper">
          {/* <DragControlsWrapperIcon/> */}
                <Controls
                  configObj={props.configObj}
                  currProduct={modulesConfigurtion.product}
                  isSpacePlannerMode={isSpacePlannerMode}
                  setIsSpacePlannerMode={setIsSpacePlannerMode}
                  isViewProductMode={isViewProductMode}
                  modulesConfiguration={modulesConfigurtion}
                />
          </div>
        </div>
      </>
      
      :
      <div className="padding10 heading4 color-black font-normal">Unable to load the page. Refresh or go back</div>
      }
        
     
    </IonPage>
  );
};

export default CustomizeIn3D;
