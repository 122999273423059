import React, { useEffect, useState } from 'react';
import { getProductImageUrl } from '../../../../../utils/moodboard/helper';
import { getModulePrice } from '../../../../../utils/methods';
import { addToShortlist } from '../../../../../utils/customizein3d/modules/customizein3d';
import Modal from '../../../shared-components/Modals/Modal';
import Share from '../../../../common/shared-components/Share/Share';
import { captureAndSaveScreenshot } from '../../../../../utils/cache/indexdb';
import { IonModal } from '@ionic/react';

// import './Home.css';

const Summary: React.FC<{
    configObj:any;
    currProduct:any;
    configuration:any
    setShowFinalSummary:(val:boolean)=>void
}> = (props) => {

 
    const[showShareDetails,setshowShareDetails] = useState(false)


   

    function ConfigurationTable(){
        let configuration = props.configuration
        // let configArray = []
        // for (const key in configuration) {
        //     let object:any = {}
        //     object.moduleName = key
        //     let moduleConfig = configuration[key]
        //     for (const configKey in moduleConfig) {
        //         object.partName = configKey                
        //         object.config = moduleConfig[configKey]                
        //     }

        //     configArray.push(object)
        // }
        return(
            <React.Fragment>
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Module Name</th>
                        <th>Specification</th>
                        <th>Finish</th>
                        <th>Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {configuration.map((config:any,index:any)=>{
                        let finish = ''
                        let collectionName = ''
                        for (const key in config.configuration) {
                            finish = `${config.configuration[key].companyName} / ${config.configuration[key].collectionName} / ${config.configuration[key].materialCode}`
                            collectionName = config.configuration[key].collectionName
                        }
                        let price = getModulePrice(props.configObj.data?.productModulesPrice,collectionName,config.moduleId) || 0
                        return (
                        <tr  key={index}>
                            <td >{index+1}</td>
                            <td>{config.moduleName}</td>
                            <td>{config.displayName}</td>
                            <td>{finish}</td>
                            <td>{price}</td>
                        </tr>
                        )
                    })}
                  </tbody>
                </table>
            </React.Fragment>
        )
    }


  return (
    <React.Fragment>
        <IonModal
          id=""
          cssClass="share-modal"
          onDidDismiss={()=>setshowShareDetails(false)}
          isOpen={showShareDetails}
          >
           <Share 
            configuration={props.configuration}
            areas={[{area_name:"Default"}]}
            configObj={props.configObj}
            moduleName="Customizein3D"
            closeModal={setshowShareDetails}
        />
        </IonModal>

      
        <div className="modal-body ion-padding">
            
            <ConfigurationTable/>  

        </div>
    
        
  </React.Fragment>
  );
};

export default Summary;
