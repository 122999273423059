import { IonIcon } from '@ionic/react';
import {
  close
} from "ionicons/icons";
import React from 'react';
import { hideNotificationToast } from '../../../../utils/UI_methods/global';
import Button2 from '../buttons/Button2';
 
const NotificationToast: React.FC<{
  }> = (props) => {


 
  return (

    <React.Fragment>
        <div id="notificationToast" className="center-aligned-row  toast notification-toast">
          <div className="display-flex-row full-width space-between no-wrap">
            <div className="message heading3 center-aligned-column">Product added</div>
            <div className="display-flex-row no-wrap">
              <Button2
                configObj={{}}
                action={()=>{}}
                label={"View"}
                fontAwesomeIcon={"fas fa-eye color-primary"}
                iconPos="left"
                classNames={"shadow-light bg-color-primary view-action toast-button"}
              />
              <Button2
                configObj={{}}
                action={hideNotificationToast}
                label={""}
                fontAwesomeIcon={"fas fa-times btn-small color-primary"}
                iconPos="left"
                classNames={"shadow-light bg-color-white toast-button"}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
  );
};

export default NotificationToast;
