import React, { useState } from "react";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import UpdateLead from "../createlead/UpdateLead";
import LeadActivities from "../activities/LeadActivities";
import Calendar from "../../calender/Calendar";
import LeadDetails from "./LeadDetails";

 
const EditLead: React.FC<{
  lead:any
  setIsEditLeadOpen:(val:boolean)=>void
  refreshLeads:()=>void
  view:string
}> = (props) => {




  const[isOpen,setIsOpen] = useState(false)

 


  function Footer() {
    return(
      <div></div>
    )
  }


  function closePanels(val:boolean) {
    props.setIsEditLeadOpen(false)
    setIsOpen(false)
  }
 

 
  return (
    <div className=" full-height-width ">

      <UpdateLead isOpen={isOpen} setIsOpen={closePanels} lead={props.lead} refreshLeads={props.refreshLeads} />

      <div className="modal-header display-flex-row space-between">
        <div className="display-flex-row">
          <Button2
            configObj={{id:""}}
            action={()=>{props.setIsEditLeadOpen(false)}}
            label={""}
            fontAwesomeIcon={"fas fa-arrow-left color-primary"}
            iconPos="left"
            classNames={"no-shadow button- color-white bg-color-light"}
          />
          <div className="heading1 center-aligned-column marginleft10 capitalize letter-spacing">{props.lead?.customer_name}</div>
        </div>
        <div onClick={()=>{}} className="center-aligned-column">
          
          <div className="display-flex-row">
            {/* <Button2
              configObj={{id:""}}
              action={()=>{setIsOpen(true)}}
              label={"Update sales stage"}
              fontAwesomeIcon={"fas fa-call color-dark"}
              iconPos="left"
              classNames={"no-shadow button- color-white bg-color-light"}
            /> */}

            <Button2
              configObj={{id:""}}
              action={()=>{}}
              label={""}
              fontAwesomeIcon={"fas fa-phone-alt color-dark"}
              iconPos="left"
              classNames={"no-shadow button- color-white bg-color-light"}
            />

            <Button2
              configObj={{id:""}}
              action={()=>{}}
              label={""}
              fontAwesomeIcon={"fab fa-whatsapp color-dark"}
              iconPos="left"
              classNames={"no-shadow button- color-white bg-color-light"}
            />

            {/* <Button2
              configObj={{id:""}}
              action={()=>{}}
              label={""}
              fontAwesomeIcon={"fas fa-edit color-dark"}
              iconPos="left"
              classNames={"no-shadow button- color-white bg-color-light"}
            /> */}

            <Button2
              configObj={{id:""}}
              action={()=>{}}
              label={""}
              fontAwesomeIcon={"fas fa-ellipsis-v color-dark"}
              iconPos="left"
              classNames={"no-shadow button- color-white bg-color-light"}
            />
            
          </div>
            
          </div>
      </div>

      <div className="modal-body full-height overflow-y-scroll bg-color-light">
        {/* <Details/> */}
        <div className="activities-wrapper marginTop10 padding5">
        {/* <div className="heading3 font-medium">Activities</div> */}

        <div className="activites-list full-width">
          {/* <LeadActivities currLead={props.lead}/> */}
          {props.view === "calendar" ? 
            <Calendar lead={props.lead} isSetEventMode={false} activityEvent={null}/>
          :
            <LeadDetails lead={props.lead} />
          }
        </div>

      </div>

      </div>

      

       
    </div>
  );
};

export default EditLead;
