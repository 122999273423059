import { IonModal } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { hideComponentLoader } from "../../../../../../utils/UI_methods/global";
import { AddonsConfiguration, CushionFeelConfiguration, LegsConfiguration, RemovableSeatBackConfiguration, SeatSplitOptions } from "../../../../../../utils/store/customization-options-data/sofa";
import { STORE, SofaConfiguration } from "../../../../../../utils/store/storeConfiguration";
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import "../../../../../style/store/product-customizer.css";
import ConfigurationSelector from "./ConfigurationSelector";
import SelectOptions from "./SelectOptions";
import { getFilteredArray } from "../../../../../../utils/methods";
import CloseButton from "../../../../../common/ui-components/buttons/CloseButton";

const AdvanceConfiguration: React.FC<{
  product:any
  isOpen:boolean
  setIsOpen:(val:boolean)=>void
  productConfiguration:SofaConfiguration
  confirmHandle:()=>void
  isconfigConfirmed:boolean
}> = (props) => {


  const[customizingOptions,setCustomizingOptions] = useState([])
  const[currCategory] = useState(props.product?.category_name)
  const[refreshOptions,setRefresh] = useState(Number(new Date()))


  useEffect(()=>{
    if(props.isconfigConfirmed){
      setTimeout(() => {
        openSummary()
      }, 500);
    }
    setTimeout(() => {
      hideComponentLoader("advanceConfigLoader")
    }, 1000);

    STORE.functionRefs.productCustomizer.refreshAdvanceOptions = refreshAdvanceOptions
  },[props.isconfigConfirmed,props.isOpen])


  const  [options,setOptions] = useState([])

  useEffect(()=>{
    if(currCategory === "Sofa" ){
      setOptions(getAllOptions())
    }else if(currCategory === "Recliner"){
      setOptions(getAllOptions().filter((currOption => currOption.name === "Addons" || currOption.name === "Custom Instructions")))
    }else{
      setOptions(getFilteredArray(getAllOptions(),"id",10))
    }
  },[currCategory])
  

  function refreshAdvanceOptions() {
    setCustomizingOptions([])
  }


  function getAllOptions() {
    return [
      {
        id:5,
        name:"Addons",
        icon:"fas fa-cogs",
        ionIcon:null,
        configParameterName:"addon",
        currSelectedValue:"",
        configuration:currCategory === "Recliner"?AddonsConfiguration.filter(currAddon=>currAddon.name.includes("CupHolder")):AddonsConfiguration,
        selectedItem:AddonsConfiguration[0],
        heading:"What Addons do you want?",
        iconUrl:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/Addons.svg"
      },
      // {
      //   id:6,
      //   name:"Cushion Feel",
      //   icon:"fas fa-couch",
      //   ionIcon:null,
      //   configParameterName:"cushionFeel",
      //   currSelectedValue:props.productConfiguration.cushionFeel,
      //   configuration:CushionFeelConfiguration,
      //   selectedItem:CushionFeelConfiguration[0],
      //   heading:"What Cushion feel do you want?",
      //   iconUrl:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/CushioFeel.svg"
      // },
      // {
      //   id:8,
      //   name:"Removable Seat",
      //   icon:"fas fa-cogs",
      //   ionIcon:null,
      //   configParameterName:"removableSeat",
      //   currSelectedValue:props.productConfiguration.removableSeat,
      //   configuration:RemovableSeatBackConfiguration,
      //   selectedItem:RemovableSeatBackConfiguration[0],
      //   heading:"What removable seat do you want?",
      //   iconUrl:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/RemovableSeat.svg"
      // },
      // {
      //   id:11,
      //   name:"Seat Split Options",
      //   icon:"fas fa-cogs",
      //   ionIcon:null,
      //   configParameterName:"seatSplit",
      //   currSelectedValue:props.productConfiguration.seatSplit,
      //   configuration:props.productConfiguration.variant.variantName === "3Seater"?SeatSplitOptions.filter(currOption=>currOption.id !== 3):SeatSplitOptions,
      //   selectedItem:SeatSplitOptions[0],
      //   heading:"What Split Seat do you want?",
      //   iconUrl:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/Addons.svg"
      // },
      {
        id:10,
        name:"Custom Instructions",
        icon:"far fa-comments",
        ionIcon:null,
        currSelectedValue:"",
        configuration:null,
        selectedItem:null,
        heading:"What Instructions do you want to add?",
        iconUrl:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/CustomInstructions.svg"
      }
    ]
  }
  
  

  function openSummary() {
    $(".configuration-seletor").fadeOut(0)
    $("#selectOptionsContainer").fadeOut(0)
    $("#productConfiguratorContainer").fadeIn(0)
    $("#AdvanceOptionsSummary").fadeIn(0)
  }

 
  return (
    <div className='product-details '>

      <IonModal
        isOpen = {props.isOpen}
        onDidDismiss = {()=>props.setIsOpen(false)}
        cssClass="medium-x-modal no-border"
        id=""
      >
      <div className="position-relative full-height-width product-customizer-wrapper advance-customization-wrapper" >
        <div className="top-right">
            <CloseButton action={()=>props.setIsOpen(false)} classNames="" />
        </div>

        <Loader
          id="advanceConfigLoader"
          data={{message:"Please wait"}}
          loaderType="dotsText"
          className="--is-active"
        />
        <SelectOptions options={options} product={props.product} setCustomizingOptions={setCustomizingOptions}/>
        <ConfigurationSelector  confirmHandle={props.confirmHandle} productConfiguration={props.productConfiguration} product={props.product} customizingOptions={customizingOptions}/>
      </div>
      
      </IonModal>
    
        </div>
  );
};

export default AdvanceConfiguration;
