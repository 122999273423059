import React from "react";
import $ from "jquery"
 
const ToggleView: React.FC<{
  setIsShowCalendar:(value:boolean)=>void
}> = (props) => {


 

  function changeHandle(event:any,value:boolean) {
    $(".view-toggle-icon-wrapper").removeClass("--is-active")
    $(event.target).addClass("--is-active")
    props.setIsShowCalendar(value)
  }
    

  return (

    <div className="center-aligned-column full-height">
      <div className="display-flex-row space-around border white-card no-shadow pointer" style={{minWidth:"90px"}}>
          <span onClick={(event)=>changeHandle(event,false)} className="center-aligned-column width50 view-toggle-icon-wrapper padding10 --is-active"><i className="fas fa-list-alt pointer-none icon font-normal"></i></span>
          <span onClick={(event)=>changeHandle(event,true)} className="center-aligned-column width50 view-toggle-icon-wrapper padding10"><i className="fas fa-border-none pointer-none icon font-normal"></i></span>
      </div>  
    </div>
     
  );
};

export default ToggleView;
