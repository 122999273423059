import { IonButton, IonIcon } from '@ionic/react';
import { closeOutline, eyeOffOutline, eyeOutline, shareSocialOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import $ from "jquery";
import ShowMoodboardConfig from '../common/ShowMoodboardConfig';

// import './Home.css';

const Pricing: React.FC<{
    configObj:any
    currSelection:string
    setShowPricing:(value:boolean)=>void
    areasList:any
}> = (props) => {


  return (
    <React.Fragment>
          <div className="modal-header">
          <div className="">
            <div className="pricing-header">
                <div className="content-left">
                  <span> <strong>Config ID</strong> {props.configObj.configName}</span>
                  </div>
                
              </div>
          </div>
          <IonIcon icon={closeOutline} ios={closeOutline} className="modal-close" onClick={() => props.setShowPricing(false)}/>
        </div>
        <div className="modal-body">
          <ShowMoodboardConfig
            configObj = {props.configObj}
            currSelection={props.currSelection}
            areasList={props.areasList}
          />
        </div>
        <div className="modal-footer display-flex-row full-width pricing-modal-footer space-between">
            <div className="heading color-black">Total Price <span className="heading2 font-medium color-danger" id="moodboardTotalPrice"> </span> </div>
            <button className="op-btn op-btn-light" onClick={() => props.setShowPricing(false)}>Close</button>
            {/* <IonButton color="primary" onClick={()=>loadConfig(selectedConfig[0].config_name)}>Load Config</IonButton> */}
        </div>
  </React.Fragment>
  );
};

export default Pricing;
