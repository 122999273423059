import { IonIcon } from "@ionic/react";
import { close, closeOutline } from "ionicons/icons";
import React from "react";
import { closeProductInfo } from "../../../../../../utils/customizein3d/area3dmodel/UI_methods";


const ProductInfo: React.FC<{
  }> = (props) => {

    
 
   
  return (
    <div id="customizerProductInfo" className="customizer-product-info border-left zindex100 full-height overflow-y-scroll padding0 top-right background-white" style={{width:"100%"}}>
    <div className="pointer position-relative display-flex-row space-between ion-padding border-bottom full-width" >
        <span className="color-black display-flex-row full-width space-between no-wrap heading">
          <span className="center-aligned-column heading2 letter-spacing" id="">Product Info</span> 
          <IonIcon className="color-black center-aligned-column font-large" icon={close} onClick={closeProductInfo}></IonIcon>
        </span>
    </div>

    <div className="full-width ion-padding">
      <div className="full-width">
          <img src="" id="customizeProductInfoImage" className="full-width border product-image" alt="" style={{minHeight:"200px"}}/>
      </div>

      <div className="heading3 font-large product-name ion-margin-top">Product Name</div>
      <div className="font-small category-name ion-margin-top ion-margin-bottom color-white bg-color-black" style={{width:"max-content",padding:"0.2rem 0.5rem"}}>Category</div>
      <div className="dimesnions border-top ion-margin-top border-bottom">
        <div className="marginTop5">
          <span className="heading3 color-dark">Width :</span><span className="heading2 color-black marginleft5">100CM</span>
        </div>
        <div className="marginTop5">
          <span className="heading3 color-dark">Depth :</span><span className="heading2 color-black marginleft5">100CM</span>
        </div>
        <div className="marginTop5 marginBottom5">
          <span className="heading3 color-dark">Height :</span><span className="heading2 color-black marginleft5">100CM</span>
        </div>
      </div>

      <div className="heading3 font-ex-large rupee-sign ion-margin-top">₹ 4,350</div>

    </div>

  </div>
 
  );
};

export default ProductInfo;
