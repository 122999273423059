import { Box3, Group, Object3D, Quaternion, Vector3 } from "three"
import { hideComponentLoader, showComponentLoader, showToast } from "../../UI_methods/global"
import { checkFromCache, untarAndSaveToCache } from "../../cache/cache"
import { snapObjectToNearestCornerWall } from "../../kitchenplanner/helper"
import { applicationConfig, getFilteredArray, getObjectByParameter, logger, stringWithoutQuotes, stringWithoutWhiteSpace, waitFor } from "../../methods"
import { applyEnvMapInModel, attachModules, detachModule, getModelBoundingBox, prepareModule, updateGroupCenter, updateModelBoundingBox } from "../common"
import { addModule, loadModuleToTheScene } from "../modules/customizein3d"
import { getObjectByName, getObjectDimensionPosition } from "../modules/helper"
import { CURR_SELECTED_PRODUCT, setCurrSelectedProduct } from "../raycasting"
import { closeControlsPanelAndHideProducts, updateAttachIconVisibility } from "./UI_methods"
import { removeProduct } from "./actions"
import { CURR_SNAPPED_DISTANCES } from "./annotations"
import { FLOOR, addModelToScene, areaModel, customizerConfig, isKitchenPlannerMode, kitchenPlanner, product3DModelsList, productLoader, projectConfiguration, scene, undo } from "./area3dModel"
import { updateBoxHelper } from "./dragging"
import { applyTransformInObject, getOriginalCategoryName, getOriginalProductName, getOriginalSubCategoryName, getProductFromAreaModel, getProductNameWithoutVariant, postAddingActions, setAddedProductDetailsInUserData, setModelBoundaries } from "./helper"
import { CURR_SELECTED_PRODUCT_GROUP, getModuleConfigurationFromCurrProductGroup, updateControlsForModules } from "./productConfigure"
import { CURR_FRONT_PLANE, removeVirtualPlanes, resetWallsColor } from "./walls"
import { getItemFromLocalStorage } from "../../cache/localstorage"
import { ProductInstance } from "../../customizer/ProjectConfiguration"


var axis = new Vector3(0,1,0)
var target = new Vector3()


export async function addWallModule(module:any,transform:any,productName:string) {
    return new Promise(async (resolve,reject)=>{
       
        let moduleName = module.productName
        // let tarfilekey = applicationConfig.awsConfig.getTarFileKey("models",{clientName:applicationConfig?.clientName,productName:stringWithoutWhiteSpace(productName || module.product_name)})
        // await untarAndSaveToCache(applicationConfig.awsConfig,`models/${module.product_name}`,tarfilekey)
        let key:string = `models/${stringWithoutWhiteSpace(productName)}/${stringWithoutWhiteSpace(productName)}_${moduleName}.glb`
        checkFromCache(key)?.then((url:any)=>{
        loadModuleToTheScene(productLoader,url).then(async (modelObject:any)=>{
            areaModel.add(modelObject)                 
            modelObject.userData.isWallMounted = true
            modelObject.userData.isAttachedToWall = true
            applyEnvMapInModel(modelObject)
            postAddingActions(modelObject)
            applyTransformInObject(modelObject,transform)
            kitchenPlanner.addWallModuleToConfig(module,modelObject)
            resolve(modelObject)
        }).catch(err=>{
            console.log(err)
            showToast("Error in adding to scene",2000)
            hideComponentLoader("changeFinishLoader")
            reject(err)
        })
        }).catch(err=>{
            console.log(err)
            showToast("Module not found",2000)
            hideComponentLoader("changeFinishLoader")
            reject(err)
        })
    })
}

 
export function createGroupForModule() {
    let group = new Group()
    group.children = []
    postAddingActions(group)
    areaModel.add(group)
    return group
}

export function addModuleHandle(module:any) {
    let configuration = getModuleConfigurationFromCurrProductGroup()
    addModule(configuration,CURR_SELECTED_PRODUCT_GROUP,module,false,postAddingActions).then(data=>{
        updateGroupCenter(CURR_SELECTED_PRODUCT_GROUP,[...CURR_SELECTED_PRODUCT_GROUP.children])
        updateControlsForModules(CURR_SELECTED_PRODUCT_GROUP)
    }).catch(err=>{
        console.log(err)
    })
}
 
export function replaceProduct(product){
    replaceCompleteProduct(product)
    applicationConfig.functions.products.resetProductImages()
    closeControlsPanelAndHideProducts()
}


export async function replaceCompleteProduct(product:any) {
    showComponentLoader("changeFinishLoader")
    logger?.info("customizer","addproduct",`Start replacing product: ${product.product_name}` )

    return new Promise(async (resolve,reject)=>{
       

        let prevProductData = removeObjectsWithSameCategoryProduct(product.category_name)
        let tarfilekey = applicationConfig.awsConfig.getTarFileKey("productModels",{clientName:applicationConfig?.clientName,productName:stringWithoutWhiteSpace(product.product_name),categoryName:product.category_name,subCategoryName:product.sub_category_name})
      

        await untarAndSaveToCache(applicationConfig.awsConfig,`productModels/${applicationConfig?.clientName}/${product.category_name}/${product.sub_category_name}`,stringWithoutWhiteSpace(tarfilekey)).catch((err=>{
            hideComponentLoader("changeFinishLoader")
            showToast("Module no found",2000,"error")
            reject(err)
        }))
        let key:string = `productModels/${applicationConfig?.clientName}/${product.category_name}/${product.sub_category_name}/${stringWithoutWhiteSpace(product.product_name)}.glb`
       
        checkFromCache(key)?.then((url:any)=>{
            // areaModel.remove(getProductFromAreaModel(CURR_SELECTED_PRODUCT))
            removeProduct(getProductFromAreaModel(CURR_SELECTED_PRODUCT),false,false)

            loadModuleToTheScene(productLoader,url).then(async (modelObject:any)=>{


                let position:any = prevProductData.position
                // let rotation:any = prevProductData.rotation
                logger?.info("customizer","addproduct",`Added product: ${product.product_name}`)

                applyEnvMapInModel(modelObject)

                
                modelObject.position.set(position.x,position.y,position.z)
                modelObject.quaternion.copy( prevProductData.rotation );

                modelObject.name = product.product_name
                addModelToScene(modelObject)
                
                // modelObject.getWorldQuaternion(rotation)
                // var euler:any = new Euler().setFromQuaternion( rotation, "XYZ" );
        


                hideComponentLoader("changeFinishLoader")
                postAddingActions(modelObject)
                setAddedProductDetailsInUserData(modelObject,product)
                let instance = projectConfiguration.addProduct({productName:product.product_name,categoryName:product.category_name,subCategoryName:product.sub_category_name},modelObject)
                instance.isWallMounted = prevProductData.model.userData.isWallMounted
                instance.isAttachedToWall = prevProductData.model.userData.isAttachedToWall
                instance.normal = prevProductData.model.userData.configuration.normal
                modelObject.userData.configuration = instance
                modelObject.userData.isAttachedToWall = prevProductData.model.userData.isAttachedToWall
                modelObject.userData.isWallMounted = prevProductData.model.userData.isWallMounted
                undo.add("replace",{deletedModel:prevProductData.model,addedModel:modelObject})

                // deselectProduct()
                setCurrSelectedProduct(modelObject)
                resolve(modelObject)
            }).catch(err=>{
                console.log(err)
                hideComponentLoader("changeFinishLoader")
                reject(err)
            })
        }).catch(err=>{
            console.log(err)
            logger.error("customizer","addproduct",`Error in loading to scene:` )
            hideComponentLoader("changeFinishLoader")
            reject(err)
        })
       
    })
}



export async function replaceModulesProduct(product:any) {
    showComponentLoader("changeFinishLoader")
    logger?.info("customizer","addproduct",`Start replacing product: ${product.product_name}` )

    
    return new Promise(async (resolve,reject)=>{
        // areaModel.children.forEach(async currObject=>{
        //     let productName = currObject.name
        //     let categoryName = CURR_SELECTED_PRODUCT.userData.categoryName
        //     if(categoryName === product.category_name){
        let modulesList = getFilteredArray(applicationConfig?.data.productModules,"product_id",product.product_id)
        if(modulesList.length){
            

            let prevModulePos = removeObjectsWithSameCategoryModule(product.category_name)

            let subCategory = getOriginalSubCategoryName(customizerConfig,product.product_name)
            let baseModuleName = getBaseModel(subCategory)
            
            
            let module = getObjectByParameter(modulesList,"module_name",baseModuleName)
            let tarfilekey = applicationConfig.awsConfig.getTarFileKey("models",{clientName:applicationConfig?.clientName,productName:module.product_name})
            await untarAndSaveToCache(applicationConfig.awsConfig,`models/${module.product_name}`,tarfilekey)
            let key:string = `models/${module.product_name}/${module.product_name}_${module.module_name}.glb`
            checkFromCache(key)?.then((url:any)=>{
            loadModuleToTheScene(productLoader,url).then(async (modelObject:any)=>{
                // modelObject.userData.isModule
                // await setModulePosition(object,module)
                    let position:any = prevModulePos
                    // areaModel.remove(currObject)
                    logger?.info("customizer","addproduct",`Added product: ${product.product_name}` )

                    addModelToScene(modelObject)
                    // let dimData = getObjectDimensionPosition(boundingBox,modelObject)
                    modelObject.position.set(position.x,position.y,position.z)
                    prepareModule(modelObject,module.product_name,"Customizer",product.category_name).then(data=>{
                        hideComponentLoader("changeFinishLoader")
                        resolve(data)
                    }).catch(err=>{
                        hideComponentLoader("changeFinishLoader")
                        reject(err)
                    })
                    // resetcustomizeConfigurationuration()
                
                }).catch(err=>{
                    hideComponentLoader("changeFinishLoader")
                    reject(err)
                })
            }).catch(err=>{
                console.log(err)
                logger.error("customizer","addproduct",`Error in loading to scene: ${stringWithoutQuotes(err)}` )
                showComponentLoader("changeFinishLoader")
                reject(err)
            })

        }else{
            hideComponentLoader("changeFinishLoader")
            logger.error("customizer","addproduct",`Product not found: ${product.product_name}` )
            showToast("Module not found",2000,"error")
        }
        //     } 
        // })
    })
}


export async function addProduct(product:any,positions:any = null,isSetBoundaries:boolean=true) {
    isRotated = false
    // showComponentLoader("changeFinishLoader")
    logger?.info("customizer","addproduct",`Start adding product: ${product.product_name}` )
    return new Promise(async (resolve,reject)=>{
       

        let tarfilekey = applicationConfig.awsConfig.getTarFileKey("productModels",{clientName:applicationConfig?.clientName,productName:stringWithoutWhiteSpace(product.product_name),categoryName:stringWithoutWhiteSpace(product.category_name),subCategoryName:stringWithoutWhiteSpace(product.sub_category_name)})
        await untarAndSaveToCache(applicationConfig.awsConfig,`productModels/${applicationConfig?.clientName}/${product.category_name}/${product.sub_category_name}`,stringWithoutWhiteSpace(tarfilekey)).catch((err=>{
            hideComponentLoader("changeFinishLoader")
            showToast("Module no found",2000,"error")
            reject(err)
        }))
        let key:string = `productModels/${applicationConfig?.clientName}/${product.category_name}/${product.sub_category_name}/${stringWithoutWhiteSpace(product.product_name)}.glb`
        // let url:string = `assets/models/${product.category_name}/${stringWithoutWhiteSpace(product.product_name)}.glb`
        checkFromCache(key)?.then((url:any)=>{
            loadModuleToTheScene(productLoader,url).then(async (modelObject:any)=>{
                logger?.info("customizer","addproduct",`Added product: ${product.product_name}` )

                addModelToScene(modelObject)

                modelObject.visible = false
             
                prepareModule(modelObject,product.product_name,"Customizer",product.category_name,isSetBoundaries).then(async(data)=>{
                    hideComponentLoader("changeFinishLoader")
                    postAddingActions(modelObject)
                    
                    await waitFor(200)
                    if(positions){
                        modelObject.position.set(positions.x,0,positions.z)
                    }else{
                        updateAddedProductPosition(modelObject)
                    }
                   
                    modelObject.visible = true
                    if(isSetBoundaries){
                        setModelBoundaries(modelObject)
                    }
                    resolve(modelObject)
                }).catch(err=>{
                    console.log(err)
                    hideComponentLoader("changeFinishLoader")
                    reject(err)
                })
            }).catch(err=>{
                console.log(err)
                hideComponentLoader("changeFinishLoader")
                reject(err)
            })
        }).catch(err=>{
            console.log(err)
            logger.error("customizer","addproduct",`Error in loading to scene:` )
            hideComponentLoader("changeFinishLoader")
            reject(err)
        })
       
    })
}

export async function addDraggedProduct(product:any,isAddToScene:boolean) {
    // showComponentLoader("draggableImgLoader")
    showComponentLoader("draggableImgLoader")
    // updateLoaderProgressWithInterval("draggableImgLoader","")
    isRotated = false
    // showComponentLoader("changeFinishLoader")
    // logger?.info("customizer","addproduct",`Start adding product: ${product.product_name}` )
    return new Promise(async (resolve,reject)=>{

        let productName = product.product_name || product.productName
        let categoryName = product.category_name || product.categoryName
        let subCategoryName = product.sub_category_name || product.subCategoryName
        
        let productNameWithoutVarient = stringWithoutWhiteSpace(getProductNameWithoutVariant(productName)) 
        let tarfilekey = applicationConfig.awsConfig.getTarFileKey("productModels",{clientName:applicationConfig?.clientName,productName:stringWithoutWhiteSpace(productNameWithoutVarient),categoryName:stringWithoutWhiteSpace(categoryName),subCategoryName:stringWithoutWhiteSpace(subCategoryName)})
        let urlPrifix = `productModels/${applicationConfig?.clientName}/${categoryName}/${subCategoryName}`
      
        await untarAndSaveToCache(applicationConfig.awsConfig,urlPrifix,stringWithoutWhiteSpace(tarfilekey)).then(data=>{
            let key:string = `productModels/${applicationConfig?.clientName}/${categoryName}/${subCategoryName}/${productNameWithoutVarient}.glb`
           
            hideComponentLoader("draggableImgLoader")
            // clearProgressBarInterval()
            checkFromCache(key)?.then((url:any)=>{

                loadModuleToTheScene(productLoader,url,false).then(async (modelObject:any)=>{
                    logger?.info("customizer","addproduct",`Added product: ${product.product_name}` )
                    if(isAddToScene){
                        // postLoadToSceneActions(product,modelObject,productInstanceName)
                        scene.add(modelObject)
                    }
                    postAddingActions(modelObject)
                    setAddedProductDetailsInUserData(modelObject,product)

                    // customizerConfig.addItemToStack("add",{deletedModule:prevProductData.model,addedModule:modelObject})
                    resolve(modelObject)
                }).catch(err=>{
                    console.log(err)
                    hideComponentLoader("changeFinishLoader")
                    reject(err)
                })
            }).catch(err=>{
                logger.error("customizer","addproduct",`Error in loading to scene:` )
                hideComponentLoader("changeFinishLoader")
                reject(err)
            })
        }).catch((err=>{
            hideComponentLoader("changeFinishLoader")
            hideComponentLoader("draggableImgLoader")
            // showToast("Not found. Unable to download",2000,"error")
            reject(err)
        }))
       
      
       
    })
}


export function addStoreProductForRender(product:any,url:string){
    let key = `/productRenderModel/${product.product_id}.glb`
    return new Promise((resolve,reject)=>{
        checkFromCache(key)?.then((url:any)=>{
            loadModuleToTheScene(productLoader,url,true).then(async (modelObject:any)=>{
                logger?.info("customizer","addproduct",`Added product: ${product.product_name}` )
                // scene.add(modelObject)
                postAddProductRenderModeActions(product,modelObject)
                resolve("done")
            }).catch(err=>{
                console.log(err)
                hideComponentLoader("changeFinishLoader")
                reject(err)
            })
        }).catch(err=>{
            reject(err)
        })
    })
    
    
}

async function postAddProductRenderModeActions(product:any,currObject:any){
    //   detachModule(currObject)
    let group = new Group();
    scene.add(group);
    
    const children = [...currObject.children]; // Use a copy to avoid modifying the array during the loop
    
    for (const currChild of children) {
        // Calculate the child's world position, rotation, and scale before detaching
        currChild.updateMatrixWorld();
        
        // Save world transformation
        const worldPosition = new Vector3();
        const worldRotation = new Quaternion();
        const worldScale = new Vector3();
        currChild.matrixWorld.decompose(worldPosition, worldRotation, worldScale);
    
        // Detach and re-attach to the new group
        detachModule(currChild);
        attachModules([currChild], group);
    
        // Reapply world transformations
        currChild.position.copy(worldPosition);
        currChild.quaternion.copy(worldRotation);
        currChild.scale.copy(worldScale);
    }
    
    // Finally, remove currObject from the scene
    scene.remove(currObject);
    
    updateModelPosition(group)
    //   group.attach(currObject)
      // detachModule(currObject)
      // attachModules([currObject],scene)
      postAddingActions(group)
    //   let productInstance = new ProductInstance(product)
      setAddedProductDetailsInUserData(group,product)
      projectConfiguration.projectConfiguration["Other"].areaConfiguration = {}
      let productInstance = projectConfiguration.addProduct(product,group,"Other")
      productInstance.additionalConfiguration.configHash = projectConfiguration.productRenderModeConfig.configHash
      productInstance.additionalConfiguration.configId = projectConfiguration.productRenderModeConfig.configId
      group.name = currObject.name
      console.log(projectConfiguration.projectConfiguration["Other"].areaConfiguration,productInstance)
}

function updateModelPosition(group:any){
// Calculate the bounding box of the group
    const boundingBox = new Box3().setFromObject(group);
    const size = new Vector3();
    boundingBox.getSize(size); // Get width, height, depth of the group
    const center = new Vector3();

    // Shift the group's position so the anchor is the top-left corner
    group.position.set(
        -center.x + size.x / 2,
        -center.y ,
        -center.z + size.z / 2// Keep z position as is, or adjust based on your needs
    );
}
// export function postLoadToSceneActions(product:any,modelObject:Object3D,productInstanceName:string = null) {
//     modelObject.visible = false
//     addModelToScene(modelObject)
//     // hideComponentLoader("changeFinishLoader")
    
//     applyEnvMapInModel(modelObject)
//     modelObject.userData.productName = productInstanceName || product.product_name || product.productName
//     modelObject.userData.categoryName = product.category_name  || product.categoryName
//     modelObject.userData.subCategoryName = product.sub_category_name || product.subCategoryName  
//     modelObject.userData.normal = new Vector3(0,0,1)
// }

let posX = 0
let posZ = 0
let pos:any = {}
let deltaforX = 30
let deltaforY = 10
let modelWidth = 0
let isRotated = false

function updateAddedProductPosition(object:any) {
    pos = getObjectDimensionPosition(null,FLOOR)
    posX = pos.positions.min.x + deltaforX
    posZ = pos.positions.max.z

    // posX = 0
    // posZ = 0
    // object.position.set(posX,0,posZ)
    // return


    if(!isRotated){
        modelWidth = getObjectDimensionPosition(null,object).dimensions.dimX
    }else{
        modelWidth = getObjectDimensionPosition(null,object).dimensions.dimZ 
    }

    updatePosition(object,posX,posZ)

    
}


async function updatePosition(object:any,posX:number,posZ:number) {

    // initial position, start positions of the ground
    
    object.position.set(posX,0,posZ)

    updateModelBoundingBox(object)

    //check for the collision
    if(checkCollision(object,object)){

        //if floor ends at x axis 
        if(Math.abs(posX + deltaforX + modelWidth) > Math.abs(pos.positions.max.x)){

            if(!isRotated){
                object.rotateOnWorldAxis(axis,Math.PI/2)
                isRotated = true
                updateAddedProductPosition(object)
                return
            }
            showToast("No space remaining",2000,"error")
            areaModel.remove(object)
            return
        }

        //If floors ends at y axis update x position with some delta 
        if(Math.abs(posZ-10) > Math.abs(getModelBoundingBox(FLOOR).min.z)){
            // set Position z to max of z of the floor
            // set posX to X + 10
            posX = posX + deltaforX
            posZ = pos.positions.max.z
        }

        //Keep moving in z axis
        updatePosition(object , posX , posZ - deltaforY)
    }

}



export function checkCollision(point:any,currObject:any) {
    let result = null
    areaModel.traverse(object => {
      if(object && object?.isMesh && currObject.uuid!=object.uuid){
        if(getModelBoundingBox(object).intersectsBox(getModelBoundingBox(point))){
          if(!object.name.toLowerCase().includes("wall_")){
            result = object
            return
          }
          result = object
        }
      }
    })
    return result
  }
  



function removeObjectsWithSameCategoryModule(productCategory:string) {
    let position = {}
    // let area3dModel = getObjectByName(scene)

    let currObject = null
    for (let i = 0; i < areaModel.children.length; i++) {
        currObject = areaModel.children[i];
        let productName = currObject.name
        let categoryName = getOriginalCategoryName(customizerConfig,productName)
        if(categoryName === productCategory){
            productName = getOriginalProductName(customizerConfig,productName)
            let subCategory = getOriginalSubCategoryName(customizerConfig,productName)
            let baseModuleName = getBaseModel(subCategory)
            let baseModel = getObjectByName(scene,productName+"_"+baseModuleName)
            if(baseModel){
                position = baseModel.getWorldPosition(target)
            }
            areaModel.remove(currObject)
        }
    }
    return position
}


function removeObjectsWithSameCategoryProduct(productCategory:string) {
    let model = CURR_SELECTED_PRODUCT
    if(CURR_SELECTED_PRODUCT.userData.isProductModular){
        model = CURR_SELECTED_PRODUCT.children[0]
    }
    let rotation = new Quaternion()
    model.getWorldQuaternion(rotation)
    return {position:model.getWorldPosition(target),productName:getOriginalProductName(customizerConfig,model.name),
        model:model,rotation:rotation}
}


function getBaseModel(subCategoryName:string){
    switch (stringWithoutWhiteSpace(subCategoryName.toLowerCase())) {
        case "lshape":
            return "Left2"
        break;

        case "3+2+1":
            return "ThreeSeater"
        break;

        case "setsofas":
            return "ThreeSeater"
        break;
        default:
            return "Left2"
            break;
    }

}





export async function loadShellOld() {
    // showComponentLoader("draggableImgLoader")
    showComponentLoader("draggableImgLoader")
    // updateLoaderProgressWithInterval("draggableImgLoader","")
    // showComponentLoader("changeFinishLoader")
    return new Promise(async (resolve,reject)=>{
        
        
        let tarfilekey = applicationConfig.awsConfig.getTarFileKey("ProjectShell",{clientName:applicationConfig?.clientName,projectName:applicationConfig.projectName,areaName:applicationConfig.areaName})
        let urlPrifix = `models/${applicationConfig?.clientName}/AreaModels/${applicationConfig.projectName}`
        
        await untarAndSaveToCache(applicationConfig.awsConfig,urlPrifix,stringWithoutWhiteSpace(tarfilekey)).then(data=>{
            let key:string = `models/${applicationConfig?.clientName}/AreaModels/${applicationConfig.projectName}/${applicationConfig.projectName}_Shell.glb`

            hideComponentLoader("draggableImgLoader")
            // clearProgressBarInterval()
            checkFromCache(key)?.then((url:any)=>{
                loadModuleToTheScene(productLoader,url).then(async (modelObject:any)=>{
                    scene.add(modelObject.scene)
                    hideComponentLoader("changeFinishLoader")
                    applyEnvMapInModel(modelObject)
                    modelObject.visible = true
                    console.log(modelObject,url)
                    resolve(modelObject)
                }).catch(err=>{
                    console.log(err)
                    hideComponentLoader("changeFinishLoader")
                    reject(err)
                })
            }).catch(err=>{
                console.log(err)
                hideComponentLoader("changeFinishLoader")
                reject(err)
            })
        }).catch((err=>{
            hideComponentLoader("changeFinishLoader")
            // showToast("Not found. Unable to download",2000,"error")
            reject(err)
        }))
       
      
       
    })
}




export function setDraggedModelPosition(object:Object3D,detectedArea:string) {
    if(CURR_FRONT_PLANE && customizerConfig.isSnap && detectedArea){
        let normal = object?.userData?.normal?.clone() || new Vector3(0,0,1)
        
        let intersectObjectData = getObjectDimensionPosition(null,CURR_FRONT_PLANE)
        object.userData.snappedWall = CURR_FRONT_PLANE
        
        if(object.userData.isWallMounted){
            // enableSetWallProductPositionMode(CURR_SELECTED_PRODUCT)
            snapWallProduct(object,intersectObjectData,normal)
            object.userData.isAttachedToWall = true                                           
        }

        updateNormalInConfiguration(object,normal)
        // projectConfiguration.updateWallProductData(CURR_SELECTED_PRODUCT.userData.originalProductName,CURR_SELECTED_PRODUCT.userData.productInstanceName,detectedArea,{normal:normal,isAttachedToWall:true,isWallMounted:CURR_SELECTED_PRODUCT.userData.isWallMounted})
        // resetWallProductParameters(true)
        removeVirtualPlanes()
        updateAttachIconVisibility()
        updateBoxHelper()
    }
    if(!object.userData.isWallMounted && !isKitchenPlannerMode && customizerConfig.isSnap){
        snapProduct(object)
    }

    if(isKitchenPlannerMode && customizerConfig.isSnap){
        snapKitchenModule(object)
    }

    // if(isKitchenPlannerMode && !object.userData.isAttachedToWall){
    //     snapKitchenWallProduct(object)
    // }

    updateBoxHelper()

}

export function updateNormalInConfiguration(object:Object3D,normal:Vector3) {
    let configuration = object.userData.configuration
    configuration.updateNormal(normal)
    // If detached set isAttached to false 
    if(normal){
        configuration.isAttachedToWall = true
    }else{
        configuration.isAttachedToWall = false
    }
    projectConfiguration.updateLocalStorage()
}

export function snapProduct(object:Object3D) {
    if(CURR_SNAPPED_DISTANCES.length){
        CURR_SNAPPED_DISTANCES.forEach(currDistance => {
            if(currDistance.distance < 0.3){
                object.position.setX(object.position.x + currDistance.distance * currDistance.normal.x)
                object.position.setZ(object.position.z + currDistance.distance * currDistance.normal.z)
            }
        });
        updateBoxHelper()
        resetWallsColor()
    }
}

export function snapWallProduct(object:Object3D,intersectObjectData:any,normal:Vector3) {
    let center = intersectObjectData.center
    // if(isKitchenPlannerMode && !CURR_SELECTED_PRODUCT.userData.isWallMounted){
    //     kitchenPlanner.group.worldToLocal(center)
    // }
    let x = center.x + (normal.x*0.01)
    let z = center.z + (normal.z*0.01)

    if(normal.x){
        object.position.setX(x)
    }
    if(normal.z){
        object.position.setZ(z)
    } 
}

 
export function snapKitchenWallProduct(object:Object3D) {
    if(CURR_SNAPPED_DISTANCES.length){
        CURR_SNAPPED_DISTANCES.forEach(currDistance => {
            if(currDistance.distance < 0.2){
                object.position.setX(object.position.x + currDistance.distance * currDistance.normal.x)
                object.position.setZ(object.position.z + currDistance.distance * currDistance.normal.z)
            }
        });
    }
}

export function snapKitchenModule(object:Object3D) {
    let allIntersectObjects = kitchenPlanner.detectedObjectOnDrag || []

    
    //First snap then add filter (To get the actual size of filter after translation)
    allIntersectObjects = allIntersectObjects.sort((a:any,b:any)=> a.distance > b.distance?1:-1)

    //Snapped distance to add in width of filter
    let snappedDistance = 0
    for (const currDistance of allIntersectObjects) {
        if(currDistance){
            if(currDistance.distance <= 0.2){
                    if(object.userData.isWallProduct && currDistance.detectedObject.userData.isWallProduct){
                 
                        let position = new Vector3()
                        currDistance.detectedObject.getWorldPosition(position)
                        object.position.setY(position.y)
                    }
                    // if(!object.userData.isWallProduct){
    
                    if((currDistance.distance > 0.1 && currDistance.distance < 0.2) || snappedDistance){
                        kitchenPlanner.addFiller(object,currDistance.detectedObject,currDistance.normal,currDistance.distance + snappedDistance)
                    }
                    //If both distances are less the 0.1 it will stuck in the middle 
                    if(currDistance.distance <= 0.1 && !snappedDistance){
                        object.position.setX(object.position.x + currDistance.distance * currDistance.normal.x)
                        object.position.setZ(object.position.z + currDistance.distance * currDistance.normal.z)  
                        snappedDistance = currDistance.distance
                    }
           
                // }
            }
        }
    }
    snapObjectToNearestCornerWall(object)
}
