import $ from "jquery";
import React, { useState } from "react";
import { kitchenPlanner } from "../../../../utils/customizein3d/area3dmodel/area3dModel";
import { changeViewMode, showProductOptionsPanel, toggleAutoRotation, toggleHideWalls, toggleSnapping } from "../../../../utils/customizein3d/area3dmodel/UI_methods";
import { lshapeConfiguration } from "../../../../utils/kitchenplanner/configurations/lshape";
import { parallelConfiguration } from "../../../../utils/kitchenplanner/configurations/parallelKitchen";
import { ushapeConfiguration } from "../../../../utils/kitchenplanner/configurations/ushape";
import { hideKitchen2dModeElements, showKitchen2dModeElements } from "../../../../utils/kitchenplanner/UI_methods";
import { applicationConfig, waitFor } from "../../../../utils/methods";
import { hideComponentLoader, showComponentLoader, showToast } from "../../../../utils/UI_methods/global";
import Button2 from "../../ui-components/buttons/Button2";
import SummaryLink from "../../ui-components/links/SummaryLink";
  
  const KitchenPlanner: React.FC<{
  }> = (props) => {

    const[kitchens,setKitchens] = useState([
      {
        name:"Start from scratch",
        kitchenType:"scratch"
      },
      {
        name:"L Shape Kitchen",
        kitchenType:"lshape"
      },
      {
        name:"U shape Kitchen",
        kitchenType:"ushape"
      },
      {
        name:"Parallel Kitchen",
        kitchenType:"parallel"
      }
    ])



    async function addKitchenHandle(kitchenType:string){
      showComponentLoader("changeFinishLoader")
      // changeViewMode(null,"2d")
      $("#selectKitchenWindow").addClass("display-none")
      $("#positionMessage").removeClass("display-none")
    
      let product = {
        productName: "LShape1",
        categoryName: "Kitchen",
        subCategoryName: "LShapeKitchen"
      }
      // let modules = productDetails.moduleConfiguration.modules
      // let wallModules = productDetails.moduleConfiguration.wallModules
      // let isParallelKitchen = productDetails.name.toLowerCase().includes("parallel")?true:false
      let isParallelKitchen = kitchenType.toLowerCase().includes("parallel")?true:false

      switch (kitchenType.toLowerCase()) {
        case "scratch":
          await kitchenPlanner.addKitchenToscene(product,isParallelKitchen)
        break;

        case "lshape":
          await kitchenPlanner.addKitchenToscene(lshapeConfiguration,isParallelKitchen)
        break;

        case "ushape":
          await kitchenPlanner.addKitchenToscene(ushapeConfiguration,isParallelKitchen)
        break;

        case "parallel":
          await kitchenPlanner.addKitchenToscene(parallelConfiguration,isParallelKitchen)
        break;
      
        default:
          break;
      }


      hideComponentLoader("changeFinishLoader")
      showToast("Defualt configuration is Loaded",2000)
    }

    async function confirmKitcheAction(){
      hidePositionMessage()
      showComponentLoader("changeFinishLoader")
      await waitFor(1000)
      kitchenPlanner.confirmCustomizeKitchen()
      changeViewMode(null,"3d")
      hideComponentLoader("changeFinishLoader")
      hideKitchen2dModeElements()
    }

    async function updateKitchenPosition() {
      showComponentLoader("changeFinishLoader")
      await waitFor(1000)
      changeViewMode(null,"2d")
      hideComponentLoader("changeFinishLoader")
      showKitchen2dModeElements()
    }

    function hidePositionMessage(){
      $("#positionMessage").addClass("display-none")
    }

 
    return (
    <>

      <div className="top-left full-height-width  display-none background-black-fade" id="selectKitchenWindow" style={{zIndex:1001}}>
          <div className="top-middle color-white heading1 letter-spacing padding10 font-large ion-margin-top">Select Kitchen</div>
          <div className="middle">
            <div className="display-flex-row no-wrap">
              {kitchens.map((kitchen:any,index:number)=>{
                return(
                  <div key={index} onClick={()=>addKitchenHandle(kitchen.kitchenType)} className="border ion-margin-start position-relative pointer" style={{width:"180px",height:"160px"}}>
                    <div className="middle text-center color-white heading2">{kitchen.name}</div>
                  </div>
                )
              })}
            </div>
           
          </div>
      </div>

      <div id="positionMessage" className="top-left full-height-width display-none" style={{zIndex:1002,paddingTop:"10px",background:"rgba(0,0,0,0.7)"}}>
        <div className="full-height-width position-relative">
          <div className="top-middle">
            <div className="display-flex-row no-wrap pulse-box padding10" style={{width:"fit-content",margin:"auto",padding:"15px 35px",borderRadius:"5px"}}>
              <div className="letter-spacing1 marginRight10 heading3 center-aligned-column color-black font-normal">Default configuration is loaded. Start designing</div>
              <Button2
                configObj={applicationConfig}
                action={()=>{}}
                label={"Got it"}
                fontAwesomeIcon={"far fa-thumbs-up color-danger"}
                iconPos="left"
                classNames={"shadow-light button-small bg-color-primary"}
              />
            </div>
          </div>

          <div className="zindex30 bottom-middle show-in-kitchen-2d-mode ion-margin-bottom">
            <Button2
              configObj={applicationConfig}
              action={confirmKitcheAction}
              label={"Start designing"}
              fontAwesomeIcon={"fas fa-chevron-right color-white"}
              iconPos="right"
              classNames={"shadow- button-large bg-color-primary pulse-box"}
            />
          </div>

        </div>
        

        

      </div>

      {/* <div id="kitchenDragIcon" onPointerUp={pointerUpKitchenDrag}   className="top-left zindex30 show-in-kitchen-2d-mode  display-none  bg-color- pointer padding0">
         <div className="position-relative ">
              
            <div onClick={()=>rotateKitchen(kitchenPlanner.group)} className="white-card no-shadow left-middle center-aligned-column"   style={{left:"-25px",width:"20px",height:"20px",transition:"none",background:"rgba(255,255,255,0.6)"}}>
              <i className="fas fa-undo pointer-none font-small color-dark"></i>
            </div>
            
            <div className="white-card no-shadow center-aligned-column"  onPointerUp={pointerUpKitchenDrag}  onPointerMove={pointerMoveKitchenDrag} onPointerDown={pointerDownKitchenDrag}  style={{width:"20px",height:"20px",transition:"none",background:"rgba(255,255,255,0.6)"}}>
              <i className="fas fa-arrows-alt pointer-none font-small color-dark"></i>
            </div>
             
         </div>
      </div> */}

      {/* <div className="zindex30 top-right show-in-kitchen-planner hide-in-kitchen-2d-mode display-none ion-margin-bottom">
          <Button2
            configObj={applicationConfig}
            action={disableKitchenPlannerMode}
            label={"Exit Kitchen Planner"}
            fontAwesomeIcon={"fas fa-times color-danger"}
            iconPos="left"
            classNames={"shadow-light bg-color-black"}
          />
      </div> */}

      <div className="zindex30 hide-on-wall-focus top-right hide-in-isolation-view hide-in-elevation-view show-in-kitchen-planner hide-in-kitchen-2d-mode display-none">
        <div className="display-flex-row no-wrap">
          <Button2
            configObj={applicationConfig}
            action={()=>kitchenPlanner.undoHandle()}
            label={"Undo"}
            fontAwesomeIcon={"fas fa-undo color-danger"}
            iconPos="left"
            classNames={"shadow-light button- bg-color-"}
          />
          <Button2
            configObj={applicationConfig}
            action={()=>showProductOptionsPanel(null)}
            label={"Add modules"}
            fontAwesomeIcon={"fas fa-plus color-danger"}
            iconPos="left"
            classNames={"shadow-light button- marginleft5 bg-color-primary"}
          />
        </div>
          
      </div>

      {/* <div className="zindex30 show-in-kitchen-2d-mode bottom-middle display-none ion-margin-bottom" id="rotateKitchenButton">
          <Button2
            configObj={applicationConfig}
            action={()=>rotateKitchen(kitchenPlanner.group)}
            label={"Rotate kitchen"}
            fontAwesomeIcon={"fas fa-undo color-danger"}
            iconPos="left"
            classNames={"shadow-light bg-color-primary"}
          />
      </div> */}

     

      <div className="zindex30 ion-margin hide-on-wall-focus bottom-left hide-in-isolation-view show-in-kitchen-planner hide-in-kitchen-2d-mode display-none">
        <button className="action-button zindex30 hide-on-configure  shadow-light white-card border display-flex-row font-medium" onClick={toggleHideWalls} id="toggleHideWalls">
          <span className="center-aligned-column"><i className="fas fa-eye icon  font-"></i></span>
          <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label" ></span> 
          <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label state-text" >Hide Walls</span> 
        </button>

        <button className="action-button zindex30 hide-on-configure  shadow-light --is-active white-card border display-flex-row font-medium" onClick={toggleAutoRotation} id="toggleAutoRotation">
          <span className="center-aligned-column"><i className="fas fa-map-pin icon  font-"></i></span>
          <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label" >Auto Rotation</span> 
          <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label state-text" >on</span> 
        </button>

        <button className="action-button zindex30 hide-on-configure  shadow-light --is-active white-card border display-flex-row font-medium" onClick={toggleSnapping} id="toggleSnapping">
          <span className="center-aligned-column"><i className="fas fa-map-pin icon  font-"></i></span>
          <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label" >Snap</span> 
          <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label state-text" >on</span> 
        </button>
      </div>

      <div className="zindex30 ion-margin hide-on-wall-focus bottom-right hide-in-isolation-view show-in-kitchen-planner hide-in-kitchen-2d-mode display-none">
        <SummaryLink
            currentConfigObject={kitchenPlanner?.configuration}
            moduleName="customizein3d"
          />
      </div>


  

      {/* <div className="zindex30 bottom-left padding10 hide-in-kitchen-2d-mode display-none ion-margin-bottom">
        <button className="action-button zindex30 hide-on-configure  shadow-light white-card border display-flex-row font-medium" onClick={enableMultiselectMode} id="toggleMultiselect">
          <span className="center-aligned-column"><i className="fas fa-mouse-pointer icon  font-"></i></span>
          <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label" >MultiSelect off</span> 
        </button>
      </div> */}

      {/* <div className="zindex30 bottom-middle show-in-kitchen-planner display-none ion-margin-bottom">
        <button onClick={toggleSelectKitchen} className="action-button --is-active white-card border display-flex-row font-medium" id="selectKitchenToggle">
          <span className="center-aligned-column"><i className="fas fa-object-ungroup icon  font-"></i></span>
          <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label" >Select</span> 
          <span className="center-aligned-column marginleft5 heading4 hide-in-mobile label  state-text" >Kitchen</span> 
        </button>
      </div> */}
          
    </> 
    );
  };
  
  export default KitchenPlanner;
  