import React, { useState } from "react";
import Button2 from "../buttons/Button2";
import MulipleImageSelector from "./MulipleImageSelector";

const MultipleFileUpload: React.FC<{
  setFiles:(value:any)=>void
  heading:string
  acceptExtensions:string
  multiple:boolean
  isInputView?:boolean
  isMandatory?:boolean
  LabelHint?:any
  isSelectDefaultImage?:boolean
  setDefaultImage?:(value:any)=>void
  selectedFiles?:Array<any>
}> = (props) => {

  const[selectedFilesList,setSelectedFilesList] = useState(props.selectedFiles || [])

  const selectFile = (event:any) => {
    const files = event.target.files
    props.multiple ? props.setFiles(files) : props.setFiles(files[0])
    
    for (const key in files) {
      const file = files[key]
      if(file.name && file.size){
        setSelectedFilesList(prevValues=>{
          return [...prevValues,file]
        })
      }
    }
  }

  function reset() {
    props.setFiles(null)
    setSelectedFilesList([])
  }

 
  return (
    <div className="padding10 full-width center-aligned-row">
      {props.heading.length?
        <div className="label marginBottom5">{props.heading}
        {props.isMandatory?
          <span className="mandatory">*</span>
        :null}
        {props.LabelHint?
          <props.LabelHint/>
        :null}
      </div>
      :null}
      
      {selectedFilesList.length && !props.isInputView?       
        <div className="">
          <div className="filesnames">
            {props.isSelectDefaultImage?
              <MulipleImageSelector selectedFiles={selectedFilesList} setDefaultImage={props.setDefaultImage} />
            :
            <div className="">
              {selectedFilesList.map((file:any,index:number)=>{
                return(
                  <div key={index} className="heading3 font-md-small marginTop5">{file.name}</div>
                )
              })}
            </div>
            }
          </div>
          <div className="padding5 center-aligned-row">
            <Button2
                configObj={{id:""}}
                action={()=>{reset()}}
                label={"Reset"}
                fontAwesomeIcon={"fas fa-undo color-danger"}
                iconPos="left"
                classNames={"no-shadow button-small marginleft5"}
            />
          </div>
          
        </div>
      :
      <div className="full-width">
        {props.isInputView?
          <div className="dislpay">
              <input type="file" id="" className="input" accept={props.acceptExtensions} multiple={props.multiple}  onChange={selectFile} style={{display:"block"}} />
              
          </div>
        :
        <div className="full-width-file-input-container">
          <div className="">
            <div className="text-center heading1 font-medium marginTop"> 
            <label className="op-btn pointer op-btn-light heading4 color-black marginleft5 marginRight5"><i className="fas fa-upload marginRight5 color-black"></i>Select {props.multiple?"Files":"File"}
              <input type="file" id="uploadImage" accept={props.acceptExtensions} multiple={props.multiple}  onChange={selectFile} />
            </label> 
            </div>
            <div className="heading3 color-black text-center marginTop5">{props.multiple?"Drop files here":"Drop file here"}</div>
          </div>
        </div>
          
        }
          
        </div>
      }
        
  </div>
  );
};

export default MultipleFileUpload;
