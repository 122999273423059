import { } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getArrayOfDistinctValuesFromObject } from "../../../../../../utils/methods";
import AreaList from "./AreaList";


  const Areas: React.FC<{
    configObj:any
    updateCurrArea:(areaName:string,areaId:number)=>void
    areaConfigFlagMapping:any
    areasList:Array<any>
    currArea:string
    categoriesStatus:any
    updateProjectAreaCategories:()=>void
  }> = (props) => {

    const[currSelectedArea,setCurrSelectedArea] = useState<string>("")
    // const[areasList,setAreaslist] = useState([])

     useEffect(()=>{
         if(props.areasList){
            // let areas = props.configObj?.getAreas()

            let areas = getArrayOfDistinctValuesFromObject(props.areasList,"area_name")
            areas.sort((a,b)=>a>b?1:-1)
            //get current moodboard Id and filter areas based on moodboard id 
            // console.log(props.configObj?.data)

           
            let currArea = props.areasList[0]?.area_name
            let currAreaId = props.areasList[0]?.area_id


            // When coming from customizer
            // if(props.configObj?.getCurrArea() && props.configObj?.getCurrArea() !== ""){
            //   currArea =  props.configObj?.getCurrArea()
            //   currAreaId = props.configObj.getCurrAreaId()
            //   areas = areas.filter(area=>area===props.configObj?.getCurrArea())

            // }

            // setAreaslist(areas)
            updateCurrArea(currArea,currAreaId)
            //When coming from customizer
           
          
         }


     },[props.areasList,props.configObj])

    //  useEffect(()=>{
    //   props.configObj.functions.currSelectedArea = currSelectedArea
    //  },[currSelectedArea])

   
     const updateCurrArea = (areaName:string,areaId:number) => {
          props.configObj?.setCurrArea(areaName)
          props.configObj?.setCurrAreaId(areaId)
          props.updateCurrArea(areaName,areaId)
          setCurrSelectedArea(areaName)
     }

   
   

  
    return (
      <>
     
        <AreaList
            areasList={props.areasList}
            areaConfigFlagMapping={props.areaConfigFlagMapping}
            updateCurrArea={updateCurrArea}
            currSelectedArea={currSelectedArea}
            configObj={props.configObj}
            currArea={props.currArea}
            categoriesStatus={props.categoriesStatus}
            updateProjectAreaCategories={props.updateProjectAreaCategories}
          />
      {/* <HorizotalScroll
        Content={()=>(
          <AreaList
            areasList={areasList}
            areaConfigFlagMapping={props.areaConfigFlagMapping}
            updateCurrArea={updateCurrArea}
            currSelectedArea={currSelectedArea}
            configObj={props.configObj}
          />
        )}
        containerId="moodboardCanvasAreas"
        wrapperId={"moodboardAreasHrScroll"}
        itemClass={"configuration-area"}
        cssClassWrapper={""}
      /> */}
    </>
    );
  };
  
  export default Areas;
  