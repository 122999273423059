import React, { useEffect, useState } from "react";
import DropDown from "../filters/DropDown";

const Select: React.FC<{
    id:string
    label:string
    isMandatory:boolean
    onChange:(event:any)=>void
    options:Array<any>
    className:string
    value:any
}> = (props) => {


  const[currValue,setCurrValue] = useState(props.value || props.options[0])


  // useEffect(()=>{
  //   props.onChange(currValue)
  // },[currValue])
  useEffect(()=>{
    setCurrValue(props.value || props.options[0])
  },[props.options])

  function changeValueHandle(val:any) {
    setCurrValue(val)
    props.onChange(val)
  }

  return (
    <div className="form-label-input-container">
        {props.label.length?
        <div className="label-container ">
          <div className="label">
            {props.label} {props.isMandatory?<span className="mandatory">*</span>:null}
          </div>
        </div>
        :null}
      <div className="input-container">
          <input type="hidden" value={currValue} className="display-none" id={props.id} />
          <DropDown
            columns={1}
            classNames={props.className}
            values={props.options}
            currSelectedValue={currValue}
            action={changeValueHandle}
            label=""
          />
      </div>
    </div>
  );
};
            
export default Select;
