import React from "react";
import Button2 from "../buttons/Button2";
import { applicationConfig } from "../../../../utils/methods";

const ProcessHeader: React.FC<{
  prevButtonAction:()=>void
  nextButtonAction:()=>void
  prevButtonLabel:string
  nextButtonLabel:string
  heading:string
  HighlightComponent:any
}> = (props) => {

  return (
    <div className="top-left display-flex-row shadow-light bg-color-ternary space-between full-width padding5" style={{borderColor:"2px",borderWidth:"",minHeight:"30px"}}>
        <div className="">
          {props.prevButtonAction?
            <Button2
              configObj={applicationConfig}
              action={props.prevButtonAction}
              label={props.prevButtonLabel}
              fontAwesomeIcon={"fas fa-chevron-left color-danger"}
              iconPos="left"
              classNames={"bg-color-black button no-shadow button-small"}
            />
          :
          null}
            
        </div>
        <div className="center-aligned-column middle">
          <div className="display-flex-row pulse "> 
            <div className="center-aligned-column heading3 uppercase letter-spacing marginRight5">{props.heading}</div>
            {props.HighlightComponent?
              <props.HighlightComponent/>
            :null}
          </div> </div>
        <div className="">
          {props.nextButtonAction?
            <Button2
              configObj={applicationConfig}
              action={props.nextButtonAction}
              label={props.nextButtonLabel}
              fontAwesomeIcon={"fas fa-chevron-right color-danger"}
              iconPos="right"
              classNames={"bg-color-primary button no-shadow button-small"}
            />
          :
          null}
            
        </div>
      </div>
  
  );
};

export default ProcessHeader;
