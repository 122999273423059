import React from "react";
import { SofaConfiguration } from "../../../../../utils/store/storeConfiguration";
import $ from "jquery"
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
 

const SetPriceForm: React.FC<{
  productConfiguration:SofaConfiguration
}> = (props) => {



  function setPrice(value:any) {
    props.productConfiguration.price = Number(value)
    props.productConfiguration.isPriceSetManually = true
    $(".total_price").text(getFormattedPrice(Number(value)))
  }
 
  return (
    <div className="full-height-width center-aligned-column bg-color-light">
      <div className="ion-padding width70">
          <div className="form-label-input-container">
            {/* <div className="label-container">
              <div className="label">
                Price
              </div>
            </div> */}
            <div className="input-container">
              <input
                className="input input-mandatory input large"
                type="number"
                defaultValue={props.productConfiguration?.price || 0}
                onChange={(e)=>setPrice(e.target.value)}
                name="price"
                id=""
                required
              />
            </div>
        </div>

      </div>


    </div>
  );
};

export default SetPriceForm;
