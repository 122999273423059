import React, { useState,useEffect } from "react";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray } from "../../../utils/methods";
import Button2 from "../../common/ui-components/buttons/Button2";
import CreateUser from "./CreateUser/CreateUser";
import Modal from "../../common/shared-components/Modals/Modal";
import Toast from "../../common/ui-components/notification/Toast";
import CreateClientUser from "./CreateUser/CreateClientUser";
import { deleteUser, getUsersList, updateUser } from "../../../services/api";
import { showGlobalToast, showToast } from "../../../utils/UI_methods/global";
import { IonAlert } from "@ionic/react";
import DropDown2 from "../../common/ui-components/filters/DropDown2";


const UsersList: React.FC<{
  }> = (props) => {

    const[isOpenModal,setIsOpenModal] = useState(false)
    const[showAlert,setShowAlert] = useState(false)
    const[currUser,setCurrUSer] = useState(null)
    const[currSelectedClient,setCurrSelectedClient] = useState("All")
    const[usersList,setUsersList] = useState(applicationConfig?.data?.usersList)

    useEffect(()=>{
      getUsersList().then(data => {
        applicationConfig.data.usersList = data
        setUsersList(data)
      }).catch(err=>{

      })
    },[])




    function ModalFooter() {
      return(
        <Button2
            configObj={applicationConfig}
            action={()=>{setIsOpenModal(false)}}
            label={"Close"}
            fontAwesomeIcon={"fas fa-user color-primary"}
            iconPos=""         
            classNames={"button-light margin0 bg-color-light shadow-light"}   
        />
      )
    }
    

    function closeModal(){
      setIsOpenModal(false)
      setUsersList(applicationConfig?.data?.usersList)
    }


    function deleteAction() {
      deleteUser({id:currUser.id}).then((response:any)=>{
          if(response.data.error){
              alert(response.data.message)
          }else{
              getUsersList().then(data=>{
                  console.log(data)
                  applicationConfig.updateData("usersList",data)
                  // props.callBack()
                  setCurrUSer(null)
                  setUsersList(data)
              })
              showToast("User deleted successfully",2000)
          }
      }).catch(err=>{
          console.log(err)
      })
    }

    function deleteLoginSessionHandle(user:any) {
      updateUser({isDeleteSession:true,username:user.username}).then((response:any)=>{
          if(response.data.error){
              alert(response.data.message)
          }else{
              getUsersList().then(data=>{
                  console.log(data)
                  applicationConfig.updateData("usersList",data)
                  // props.callBack()
                  setCurrUSer(null)
                  setUsersList(data)
                  showGlobalToast("Updated",2000)
              })
              showToast("User deleted successfully",2000)
          }
      }).catch(err=>{
          console.log(err)
      })
    }

    function deleteHanlde(user:any) {
      setCurrUSer(user)
      setShowAlert(true)
    }

    function updateHanlde(user:any) {
      setCurrUSer(user)
      setIsOpenModal(true)
    }

    function createHanlde() {
      setCurrUSer(null)
      setIsOpenModal(true)
    }

    function filterUsersWithClientName(clientName:string) {
      setCurrSelectedClient(clientName)
      if(clientName.toLowerCase() === "all"){
        setUsersList(applicationConfig?.data?.usersList)
        return
      }
      setUsersList(getFilteredArray(applicationConfig?.data?.usersList,"client_name",clientName))
    }
    
     
    return (
      <div className="full-height-width display-flex-column no-wrap  white-card" style={{margin:"10px"}}>

          <Modal
            isOpen = {isOpenModal}
            Content = {()=>(
              <CreateClientUser
                callBack={closeModal}
                savedUser={currUser}
              />
            )}
            onDismiss = {()=>setIsOpenModal(false)}
            heading="Create user"
            classNames="vertical-modal"
            id=""
            FooterContent={ModalFooter}
          />

      <IonAlert
        isOpen={showAlert}
        mode="ios"
        onDidDismiss={() => setShowAlert(false)}
        cssClass=""
        header={"Confirm!"}
        message={"Delete user !!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Configuration not finalized");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              deleteAction()
            },
          },
        ]}
      />

        <Toast/>

        <div className="bg-color- border-bottom padding10 display-flex-row no-wrap space-between">
          <div className="header center-aligned-column  heading2"> Users List</div>

          <DropDown2
            classNames=""
            values={["All",...getArrayOfDistinctValues(applicationConfig?.data?.usersList,"client_name")]}
            currSelectedValue={usersList?.length === applicationConfig?.data?.usersList?.length?"All":currSelectedClient}
            action={(value)=>{filterUsersWithClientName(value)}}
            label="Client name"
          />

          <Button2
            configObj={applicationConfig}
            action={()=>createHanlde()}
            label={"Create user"}
            fontAwesomeIcon={"fas fa-plus color-primary"}
            iconPos="left"         
            classNames={"button-light margin0 bg-color-primary no-shadow"}   
          />
        </div>

        <div className="overflow-y-scroll ion-margin-bottom fill-available-height">
          <table className="users-table table">
              <thead>
              <tr className="display-noe">
                  <th>#</th>
                  <th>Username</th>
                  <th>User role</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {usersList?usersList.map((user:any,index:any)=>{
                  return (
                  <tr className={user.username==="testuser"?" disable":""} key={index}>
                      <td>{index+1}</td>
                      <td>{user.username}</td>
                      <td>{user.user_type}</td>
                      <td>{user.location}</td>
                      <td>{user.status?<span className="color-success heading3 font-small">Enabled</span>:<span className="color-danger heading3 font-small">Disabled</span>}</td>
                      <td>
                        <div className="display-flex-row no-wrap">
                          <i className="fas fa-edit color-black pointer" onClick={()=>updateHanlde(user)}></i>
                          <i className="fas fa-trash marginleft10 color-black pointer" onClick={()=>deleteHanlde(user)}></i>
                          <Button2
                            configObj={applicationConfig}
                            action={()=>deleteLoginSessionHandle(user)}
                            label={"Reset"}
                            fontAwesomeIcon={"fas fa-plus color-primary"}
                            iconPos=""         
                            classNames={"button-ex-small border-primary no-shadow"}   
                          />
                        </div>
                      </td>
                  </tr>
                  )
              }):null}
            </tbody>
          </table>
        </div>
      </div>
    )
       
  };
  
export default UsersList;
  