import React, { useEffect, useState } from "react";
import { ReclinerVariantsConfiguration, Reclining_Options } from "../../../../../utils/store/customization-options-data/recliner";
import { ReclinerConfiguration } from "../../../../../utils/store/storeConfiguration";
import AccordionForProccess from "../../../../common/ui-components/accordion/AccordionForProccess";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import SelectQuality from "../product-customizer/common/SelectQuality";
import RecliningOptionSelector from "../product-customizer/recliner/RecliningOptionSelector";
import AccordionForProccessCss from "../../../../common/ui-components/accordion/AccordionForProccessCss";
import { confirmAccordionHandle, confirmProccessAccordion } from "../../../../../utils/UI_methods/global";
import VariantSelector from "../product-customizer/common/VariantSelector";
import VariantIcon from "../product-customizer/common/VariantIcon";

 

const ReclinerConfigOptions: React.FC<{
  product:any
  updatePrice:()=>void
  productConfiguration:ReclinerConfiguration
}> = (props) => {

  const[currQuality,setCurrQuality] = useState(props.productConfiguration.quality)
  const[currCategory] = useState(props.product.category_name)
 
  const[currOption,setCurrOption] = useState(props.productConfiguration.recliningOption)
  const[currVariant,setCurrVariant] = useState(props.productConfiguration.variant)

 
  const[isConfirmReclining,setisConfirmReclinig] = useState(false)
  const[isConfirmQuality,setisConfirmQuality] = useState(false)
 

  useEffect(()=>{
    props.updatePrice()
  },[isConfirmReclining,currQuality,isConfirmQuality,props.product])


  function CurrentVariant() {
    let variantName = currVariant.variantName
    return(
      <div className="center-aligned-column">
        <div className="display-flex-row no-wrap">
          <div className="padding5 center-aligned-column" style={{width:"5rem"}}>
            <VariantIcon variant={currVariant} currCategory={currCategory}/>
          </div>
          
          <div className="heading2 center-aligned-column hide-in-mobile font-md-small marginRight10  color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">
            {props.productConfiguration.isCustomVariant?
            "Custom Variant"
            :
            `${variantName}`
            }
          </div>
        </div>
      </div>
    )
  }
   

  function CurrentSelection(props:any) {
    const heading = props.heading
    const value = props.value
    return(
      <div className="center-aligned-column">
          <div className="heading2 font-md-small marginRight10 color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">{value}</div>
      </div>
    )
  }



  function Footer(props:any) {
    const confirmHandle = props.confirmHandle
    return(
      <div className="center-aligned-column padding5 full-width bg-color-light">
        <div className="">
          <Button2
            configObj={{id:""}}
            action={()=>{confirmHandle()}}
            label={"Confirm Selection"}
            fontAwesomeIcon={"fas fa-cart-plus color-white"}
            iconPos=""
            classNames={"no-shadow margin0 bg-color-warning"}
          />
        </div>
      </div>
    )
  }

  function confirmVariantHandle() {
    confirmAccordionHandle(1)
    setCurrVariant({...props.productConfiguration.variant})
    props.productConfiguration.updatePrice()
  }

  return (
    <div className='product-details product-customizer-wrapper'>



      <div className="accordion-wrapper">

          <AccordionForProccessCss
              label="Choose your size"
              SubHeader={()=>(
                <CurrentVariant/>
              )}
              Content={()=>(
                <>
                  <VariantSelector confirmHandle={confirmVariantHandle} variant={props.productConfiguration.variant} product={props.product} configuration={ReclinerVariantsConfiguration} onChange={setCurrVariant} isShowMakeYourOwn={true}/>
                </>
              )}
              index={1}
              Footer={()=>(
                <Footer confirmHandle={confirmVariantHandle} />
              )}
          />

          <AccordionForProccessCss
            label="Reclining Option"
            SubHeader={()=>(
              <CurrentSelection heading="Reclining Option" value={`${props.productConfiguration.recliningOption}`}/>
            )}
            Content={()=>(
              <>
                <RecliningOptionSelector setCurrOptions={setCurrOption} productConfiguration={props.productConfiguration} configuration={Reclining_Options}/>
              </>
            )}
            index={2}
            Footer={()=>(
              <Footer confirmHandle={()=>{
                setisConfirmReclinig(false)
                confirmAccordionHandle(2)
                setCurrOption(props.productConfiguration.recliningOption)
              }} />
            )}
          />
     
{/* 
        <AccordionForProccessCss
            label="Choose Your Quality"
            SubHeader={()=>(
              <CurrentSelection heading="Quality" value={currQuality}/>
            )}
            Content={()=>(
              <SelectQuality productConfiguration={props.productConfiguration} currCategory={props.product.category_name} currQuality={currQuality} onChange={setCurrQuality}/>
            )}
            index={3}
            Footer={()=>(
              <Footer confirmHandle={()=>{
                confirmProccessAccordion(3)
                setisConfirmQuality(false)
                setCurrQuality(props.productConfiguration.quality)
              }} />
            )}
        /> */}

    

        

      </div>
        </div>
  );
};

export default ReclinerConfigOptions;
