import React, { useEffect, useState } from "react";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
import Header from "./header/Header";
import VisitiorsListContainer from "./VisitiorsListContainer";
import { getTodayTimestampOnlyDate } from "../../../../../../../utils/store/crm/calendar";
 

 
const VisitorsList: React.FC<{
}> = (props) => {

  const[refreshVisitors,setRefreshVisitors] = useState(Number(new Date()))
  const[currLead,setCurrLead] = useState({})
  const[visitorsList,setVisitorsList] = useState([])
  const[filteredVisitors,setFilteredVisitors] = useState([])

  const[currSelectedTab,setCurrSelectedTab] = useState("")
  const [currSelectedTime,setCurrSeletedTime] = useState("Today")


  useEffect(()=>{
    CRM.crmApi.visitors.getVisitors().then(data=>{
      if(!data.data.error){
        setVisitorsList(data.data.data)
        setCurrSeletedTime("Today")
        CRM.data.visitorsList = data.data.data
      }
    }).catch(err=>{
      console.log(err)
    })
  },[refreshVisitors])




  function refreshList() {
    setRefreshVisitors(Number(new Date()))
  }

  function filterVisitorsWithTime(time:string) {
    if(time.toLowerCase() === "all"){
      setFilteredVisitors(visitorsList)
      return
    }

    if(time.toLowerCase() === "today"){
      let filtered = visitorsList.filter(currVisitor => currVisitor.check_in === getTodayTimestampOnlyDate())
      setFilteredVisitors(filtered)
      return
    }
  }

  useEffect(()=>{
    filterVisitorsWithTime(currSelectedTime)
  },[visitorsList,currSelectedTime])
 
 
  return (
    <div className="full-height-width">
 
      <Header refreshVisitors={refreshList}/>

      <div className="display-flex-row no-wrap full-height overflow-y-scroll">
        <div className="border full-height white-card shadow-light display-flex-column" style={{width:"100%"}}>
          <div className="full-width  full-height bg-color-light hide-scrollbar">
            <VisitiorsListContainer filteredVisitors={filteredVisitors} currSelectedTime={currSelectedTime} filterVisitorsWithTime={setCurrSeletedTime}/>  
          </div>
        </div>
      </div>


    </div>
  );
};

export default VisitorsList;
