import { IonPage } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import {
  applicationConfig,
  getArrayOfDistinctValuesFromObject,
  getFilteredArray,
  getObjectArrayOfDistictValues,
} from "../../../../../utils/methods";
import { getPageTourElements } from "../../../../../utils/siteTour/elements";
import { handleSideBarToggle, setVirtualTourConfigObject } from "../../../../../utils/virtualtour/virtualtour";
import VirtualTourConfig from "../../../../../utils/virtualtour/virtualtourConfig";
import SiteTour from "../../../../common/ui-components/siteTour/SiteTour";
import "../../../../style/admin/moodboard.css";
import "../../../../style/admin/virtualtour.css";
// import Controls from "./controls/Controls"
import Canvas from "./canvas/Canvas";
import { getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";

const VirtualTour: React.FC<{
  allAreas: any;
  configObj:any
}> = (props) => {
  const [tourElementsList] = useState(getPageTourElements("VirtualTour"));
  const [currAreaName, setCurrAreaName] = useState("");
  const [projectId,setProjectId] = useState(0);

  const [virtualTourConfig, setVirtualTourConfig] = useState(null);

  useEffect(() => {
    let currProject = getItemFromLocalStorage("currProject")
    if(currProject){
      const projectId = currProject?.project_id
      setProjectId(projectId)
      let config = new VirtualTourConfig(currProject)
      setVirtualTourConfigObject(config)
      let projectProducts = getFilteredArray(applicationConfig?.data.projectAreaProductsList,"project_id",projectId);
      config.setProjectProductsList(projectProducts);
      let areaViewsMapping = {};
      let allAreas = getArrayOfDistinctValuesFromObject(projectProducts,"area_name");
      for (const index in allAreas) {
        let area: any = allAreas[index];
        areaViewsMapping[area] = getObjectArrayOfDistictValues(getFilteredArray(projectProducts, "area_name", area),"view_name");
      }
      config.setAreaViewsMapping(areaViewsMapping);
      setVirtualTourConfig(config)
    }else{
      window.location.href = "/projects"
    }
    
  }, []);



  function BottomRight() {
    return (
      <div className="ion-padding bottom-right" id="leftSidebarToggle">
        <Button2
          configObj={{}}
          action={handleSideBarToggle}
          label={"Price"}
          fontAwesomeIcon={"fas fa-question color-danger"}
          iconPos="left"
          classNames={""}
        />
      </div>
    );
  }

  return (
    <IonPage>
      {props.configObj && virtualTourConfig?
        <>
        <SiteTour
          tourElementsList={tourElementsList}
          moduleName={"VirtualTour"}
        />

        {/* <Header 
            configObj={props.configObj}
            componentLeft={HeaderContentLeft}
            componentMiddle={ContentMiddle}
          
          /> */}
        <div
          className="fill-available-height position-relative"
          id="main3dwalkthroughWrapper">
          <Canvas
            configObj={props.configObj}
            virtualTourConfig={virtualTourConfig}
            setCurrAreaName={setCurrAreaName}
            projectId={projectId}
          />

          <BottomRight />

          <div className="middle" style={{ opacity: 0 }} id="virtualTourAreaLink">
            <div className="" style={{ width: "5rem" }}>
              <img
                className="full-height-width"
                src="assets/images/link.png"
                alt=""
              />
            </div>
          </div>

          <div
            className="top-right full-height virtualtour-sidebar"
            id="virtualTourSideBar">
            <div className="top-left" id="">
              <i
                className="fas fa-times font-medium pointer color-dark"
                onClick={handleSideBarToggle}></i>
            </div>
          </div>
        </div>
        </>
      
      :
      <div className="heading3 ion-padding">Unable to load the page <span onClick={()=>{window.location.href = "/home"}} className="op-btn op-btn-light">Go back</span></div>
      }
      
    </IonPage>
  );
};

export default VirtualTour;
