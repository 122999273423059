import React, { useEffect, useRef } from "react";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import Button2 from "../../../ui-components/buttons/Button2";
import { downloadCanvasAsPng } from "../../../../../utils/customizein3d/modules/sceneBackground";

const CollectionCode: React.FC<{
  url:string
  heading:string
  downloadFileName:string
}> = (props) => {

  const qrRef = useRef(null);

  useEffect(()=>{
    if(props.url && qrRef){
      setTimeout(() => {
        openQrCode()
      }, 1000);
    }
  },[qrRef,props.url])


  async function openQrCode() {
    const windowObject:any = window
    if(windowObject.QRCode && qrRef?.current){
      var qrcode = new windowObject.QRCode(qrRef?.current, {
        text: props.url,
        width: 150,
        height: 150
      });
    }else{
      // showGlobalToast("Try Again",2000,"error")
    }
  }

  async function copyToClipboard(link:string) {
    try {
      await navigator.clipboard.writeText(`${link}`);
        showGlobalToast('Coppied!!');
    } catch (err) {
        showGlobalToast('Unable to copy text to clipboard',2000,"error");
    }
  }

  function downloadAction() {
    var parentContainer = document.getElementById("qrcode");
    let canvas = parentContainer.querySelector("canvas");
    if(canvas){
      downloadCanvasAsPng(canvas,props.downloadFileName)
    }
  }


  return (
      
        <div className="padding10 full-height-width">
          <div className="heading3 center-aligned-column padding5 font-normal" style={{height:"3rem"}}>
            {props.heading}
          </div>
          {props.url? (
            <div className="center-aligned-column" style={{height:"calc(100 - 8rem)"}}>
              <div ref={qrRef} id="qrcode"></div>
            </div>
          ) : null}

          <div className="center-aligned-column" style={{height:"5rem"}}>
            <Button2
              configObj={{id:""}}
              action={downloadAction}
              label={"Download"}
              fontAwesomeIcon={"fas fa-download color-black"}
              iconPos="left"
              classNames={"shadow-medium no-border"}
            />
          </div>


        </div>
  );
};

export default CollectionCode;
