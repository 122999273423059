import React from "react";
import { enableSelectWallForElevationView } from "../../../../../../utils/customizein3d/area3dmodel/elevationView";
import { enableSelectWalls } from "../../../../../../utils/customizein3d/area3dmodel/floorplan/floorplanUI";
import { changeViewMode } from "../../../../../../utils/customizein3d/area3dmodel/UI_methods";
import { applicationConfig } from "../../../../../../utils/methods";
import { wallColorCustomization } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";


const ModesSelector: React.FC<{
  isProductConfigureMode:boolean
  isProductRenderMode:boolean
  }> = (props) => {

    
 
   
  return (
    <div  className="white-card hide-on-configure modes-selector display-flex-row no-wrap" style={{width:"100%"}}>
      <div id="mode2d" className="mode" onClick={(event)=>changeViewMode(event,"2d")}>
        <span className="heading2 text icon">2D</span>
      </div>

      <div  id="mode3d" className="mode border-left --is-active" onClick={(event)=>changeViewMode(event,"3d")}>
        <span className="heading2 text icon">3D</span>
      </div>

      {!props.isProductRenderMode?
      <>
      <div  id="modewalkthrough" className={props.isProductConfigureMode?"display-none":"mode hide-in-kitchen-planner border-left"} onClick={(event)=>changeViewMode(event,"walkthrough")}>
        <span className="center-aligned-column"><img src={`${applicationConfig.aws_public_url}assets/icon/svg/walkthrough.svg`} width={25}/></span>
      </div>

      <div  id="elevationViewMode" className="mode border-left display-" onClick={()=>{wallColorCustomization.enable()}}>
        <span className="center-aligned-column"><img src={`${applicationConfig.aws_public_url}assets/icon/svg/wall_color.svg`} width={22}/></span>
      </div>
      </>
      :null}

      

    </div>
  );
};

export default ModesSelector;
