import { Layout } from "./layout"

export class Layout3 extends Layout{


    name:string = "layout5a"

    constructor(width:number,height:number){
        super(width,height)
    }

    setPadding(padding:number){
        this.padding = padding
    }

    getLayoutsList(){
        return ["a","b"]
    }

    getBlockParameters(layoutName:string,index:number){
        switch (layoutName.toLocaleLowerCase()) {
            case "a":
                return this.getBlockParametersLayoutA(index)
            
            case "b":
                return this.getBlockParametersLayoutB(index)
            default:
                break;
        }
    }

    getBlockParametersLayoutA(index:number){

        switch(index){
            case 1:
                this.block1 = {
                    width: this.stageWidth * 0.55 - this.padding,
                    height: this.stageHeight * 0.7 - this.padding,
                    x: 0 + this.padding,
                    y: 0 + this.padding * 3
                }
                return this.block1
            case 2:
                this.block2 = {
                    width: this.stageWidth * 0.30 - this.padding,
                    height: this.stageWidth * 0.30 - this.padding,
                    x: this.block1.x + this.block1.width + this.padding * 2,
                    y: this.block1.y
                }
                return this.block2

            case 3:
                this.block3 = {
                    width: this.block2.width,
                    height: this.block2.height,
                    x: this.block2.x,
                    y: this.block2.y + this.block2.height + this.padding
                }
                return this.block3
        }

    }

    getBlockParametersLayoutB(index:number){

        switch(index){
            case 1:
                this.block1 = {
                    width: this.stageWidth * 0.50 - this.padding,
                    height: this.stageHeight * 0.48 - this.padding,
                    x: 0 + this.padding,
                    y: 0 + this.padding * 2
                }
                return this.block1

            case 2:
                this.block2 = {
                    width: this.block1.width / 2 - this.padding,
                    height: this.block1.height / 2,
                    x: this.block1.x + this.block1.width + this.padding * 3,
                    y: this.block1.y + this.padding
                }
                return this.block2
            
            case 3:
                this.block3 = {
                    width: this.block1.width - this.padding * 3,
                    height: this.block1.height,
                    x: this.block2.x - this.padding *2,
                    y: this.block2.y + this.block2.height + this.padding 
                }
                return this.block3
            
        }

    }
}
