import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { displayImageFromIndexDb, getFileKey } from "../../../../utils/cache/indexdb";
import { addItemToLocalStorage, getItemFromLocalStorage, updateModuleFilters, updateRecentActivities } from "../../../../utils/cache/localstorage";
import { removeNewBadgeClassFromElement } from "../../../../utils/UI_methods/global";
import ProjectActions from "../../../admin/projects/projectActions/ProjectActions";
import { getArrayOfDistinctValuesFromObject, getFilteredArray, logger } from ".././../../../utils/methods";
import $ from "jquery"
import {getProjectState} from "../../../../utils/projects/projects"

const ProjectCards: React.FC<{
  projects: Array<any>;
  configObj: any;
  gotoCustomizer: (
    projectId: number,
    areaId: number,
    areaName: string,
    projectName: string,
    viewName: string
  ) => void;
  allViews:any
  allAreas:any
  allCategories:any
  moodBoardSavedconfigs:any
  setCurrProjectDetails: (id: number, name: string) => void;
  setCurrMoodboardId: (id: string) => void;
  refreshProjects:(projects: Array<any>, allProjectAreas: Array<any>)=>void
}> = (props) => {
  //Function to remove project after deleting


  // const[currPageName,setCurrPageName] = useState("")


  // var currPageName = getCurrPageName()

  // function getCurrPageName() {
  //   let path = window.location.pathname 
  //   return path.substr(path.indexOf("/")+1)
  // }

  useEffect(()=>{
    setTimeout(() => {
      for (let i = 0; i < props.projects?.length; i++) {
          let project:any = props.projects[i]
          let projectAreas = getFilteredArray(props.configObj?.data?.projectAreaProductsList,"project_id",project.project_id)
          projectAreas = getArrayOfDistinctValuesFromObject(projectAreas,"area_name")
          let fileKey = getFileKey("customizer",{projectName:project.project_name,areaName:projectAreas[0]})
          displayImageFromIndexDb(`projectImage_${project.project_name}`,"customizer",fileKey)
      }
    }, 500);
},[props.projects])

  const [openProjectAction, setOpenProjectAction] = useState(false);
  const [isProjectSteps, setIsProjectSteps] = useState(false);

  const [currProjectName, setCurrProjectName]: any = useState([]);
  const [currProjectId, setCurrProjectId]: any = useState(0);
  const [createNewProject, setCreateNewProject] = useState(false);

  // useEffect(() => {
  //   let currCardsCount = props.projects?.length;
  //   let loadedCardsCount = Number(getItemFromLocalStorage("projects_cards"));
  //   if (currCardsCount > loadedCardsCount) {
  //     let project = document.querySelectorAll(".project-container");
  //   }
  //   localStorage.setItem("projects_cards", String(currCardsCount));
  // }, [props.projects]);

  let PROJECT_STATES = [
    { status: 1, stateName: "Define", color: "--op-color-primary" },
    { status: 2, stateName: "Ideate", color: "--op-color-primary" },
    { status: 3, stateName: "Configure", color: "--op-color-primary" },
    { status: 4, stateName: "Finalize", color: "--op-color-primary" },
  ];
  // const[refreshProjects,setRefreshProjects]:any = useState(false)
  function editProject() {
    setCreateNewProject(true);
  }

  function cardClickHandle(project:any) {
    addItemToLocalStorage("currProject",project)
    updateRecentActivities("projects",project)
    setOpenProjectAction(true);
    setCurrProjectId(project.project_id);
    setCurrProjectName(project.project_name);
    removeNewBadgeClassFromElement(
      "projectCard_" + project.project_id
    );
    logger?.info("projects","projectactions",`View project ${project.project_id}`)
  }




  function StepDetails() {
    return(
      <div className="barhint-details full-width center-aligned-row" id="projectStepsDetails">
        <div className="display-flex-row no-wrap">
          {PROJECT_STATES?.map((state:any,index:number)=>{
            return(
              <div className="project-step-container" key={index}>
              <div className="step display-flex-row no-wrap" key={index}>
                <div className="">
                  <div className="circle center-aligned-column">
                    {state.status}
                  </div>
                </div>
                {index+1 != PROJECT_STATES?.length?
                <div className="center-aligned-column">
                  <div className="line"></div>
                </div>
                :null}
              </div>
              <div className="step-name">{state.stateName}</div>
              </div>
            )
          })}
         
        </div>
      </div>
    )
  }

  function openStepDetails(event:any) {
    try {
      setIsProjectSteps(true)
      let state = $(event.target).attr("data-project-state")
      setTimeout(() => {
        let parentElement = document.getElementById("projectStepsDetails")
        let projectSteps = parentElement?.querySelectorAll(".project-step-container")
        for (let i = 0; i < projectSteps?.length; i++) {
          projectSteps[i]?.classList.remove("--is-active")
        }
        for (let i = 0; i < Number(state); i++) {
          projectSteps[i]?.classList.add("--is-active")
        }
      }, 100);
    } catch (error) {
      
    }
  
   
  }

  return (
      <React.Fragment>
          <IonModal
        onDidDismiss={() => setOpenProjectAction(false)}
        isOpen={openProjectAction}
        id="projectActions"
        cssClass="project-action-modal">
        <ProjectActions
          setCurrProjectDetails={props.setCurrProjectDetails}
          configObj={props.configObj}
          currProjectId={currProjectId}
          setOpenProjectAction={setOpenProjectAction}
          currProjectName={currProjectName}
          setCurrMoodboardId={props.setCurrMoodboardId}
          setProducts={props.gotoCustomizer}
          allViews={props.allViews}
          CLIENT_ID={props.configObj?.clientId}
          allAreas={props.allAreas}
          allProjects={props.projects}
          moodBoardSavedconfigs={props.moodBoardSavedconfigs}
          editProject={editProject}
          refreshProjects={props.refreshProjects}
          allCategories={props.allCategories}
        />
      </IonModal>

          <IonModal
        onDidDismiss={() => setIsProjectSteps(false)}
        isOpen={isProjectSteps}
        id="projectSteps"
        cssClass="project-steps-modal">
           <StepDetails/>
      </IonModal>

 

          {props.projects?.length?props.projects?.map((project: any, index: number) => {
        // let state = getFilteredArray(PROJECT_STATES, "status", project.status);

        let projectState = getProjectState(project.project_id,props.configObj)
        let width = (projectState / 4) * 100;
        // console.log(projectState)
        return (
          <div className="project-container white-card no-shadow" id={`projectCard_${project.project_id}`}
            key={index}
            data-project-location={`${project.location}`}
            data-client-name={`${project.client_name}`}
            data-project-status={`${projectState}`}
            >
            <div className="project-detials-container">
              <div
                className="image"
                onClick={() => cardClickHandle(project)}>
                
                <img src="assets/images/placeholder.png" id={`projectImage_${project.project_name}`} className="project-image" alt="" />

              </div>
              <div className="other-details border-top padding10 display-flex-column">
                <div>
                  {" "}
                  <i
                    className="fa fa-user-circle color-dark"
                    area-hidden="true"
                  />
                  <span className="heading3 padding-start">
                    {project.project_name}
                  </span>
                </div>
                <div>
                  {" "}
                  <i className="fa fa-compass heading4" area-hidden="true" />
                  <span className="heading padding-start">
                    {project.location}
                  </span>
                </div>
                <div className="barfiller">

                  <span className="barhint display-flex-row no-wrap" data-project-state={projectState} onClick={openStepDetails}>
                   
                    <span className="project-state-text font-ex-small heading3">{projectState} /4 Completed</span>
                      <i data-project-state={projectState}  className="fas fa-info-circle font-small marginleft5 color-primary"></i>
                    </span>
                  <span
                    className="fill"
                    data-percentage="25"
                    style={{
                      backgroundColor: `var(--op-color-primary)`,
                      width: `${width}%`,
                    }}></span>
                </div>
              </div>
            </div>
          </div>
        );
      }):null}
        <div className="project-container temp">
      </div>
      <div className="project-container temp">
      </div>
      <div className="project-container temp">
      </div>
        <div className="project-container temp">
      </div>
      </React.Fragment>
  )
}

export default ProjectCards;
