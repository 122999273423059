import $ from "jquery";
import React, { useEffect } from 'react';

// import './Home.css';

const CloseMenuCurved: React.FC<{
    action:()=>void
    wrapperId:string
    itemId:string
}> = (props) => {

    useEffect(()=>{
      // setTimeout(() => {
      //   setPosition()
      // }, 500);
    },[])

 

    return(
      <div id={props.itemId} className="zindex30 right-middle" style={{width:"12px",right:"-12px",height:"70px",}}>
      {/* <div className="border" style={{borderRadius:"",width:"12px",height:"12px"}}></div> */}
      <button className="pointer text-center center-aligned-column  full-height-width border background-white zindex30" 
      onClick={props.action} style={{borderLeft:"unset",borderRadius:"0 20px 20px 0"}}>
      <div className="full-width center-aligned-row">
            <i className='fas fa-chevron-left font-ex-small color-dark'></i>
      </div>
    </button>
    </div>
        
    );
};
 
export default CloseMenuCurved;
