import React from "react";
import { fullScreenToggleConfigurator } from "../../../../../utils/customizein3d/modules/UI_methods";
import { downloadCanvasAsPng, shareCanvasAsPng } from "../../../../../utils/customizein3d/modules/sceneBackground";
import { FabricateConfig } from "../../../../../utils/fabricate/fabricate";
import Button2 from "../../../../common/ui-components/buttons/Button2";

const TopRightActions: React.FC<{
  fabricateConfig:FabricateConfig
}> = (props) => {

 


  return (
    <React.Fragment>

<div className="top-right zindex100">
          <div className="">
            <div className="display-flex-row no-wrap">
              <Button2
                  configObj={{id:""}}
                  action={downloadCanvasAsPng}
                  label={""}
                  fontAwesomeIcon={"fas fa-download color-primary"}
                  iconPos="right"
                  classNames={"shadow-medium no-border button-small"}
              />
             
              <Button2
                configObj={{id:""}}
                action={fullScreenToggleConfigurator}
                label={""}
                fontAwesomeIcon={"fas fa-expand color-primary redo-icon"}
                iconPos="left"
                classNames={"shadow-medium no-border button-small"}
              />
            </div>
              
            </div>
        </div>
      
    </React.Fragment>
  );
};

export default TopRightActions;
