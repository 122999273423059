import { IonCheckbox } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { applicationConfig, getFilteredArray } from "../../../utils/methods";
import { closeFiltersPanelMobile, openFiltersWindowMobile } from "../../../utils/UI_methods/global";
import Button2 from "../../common/ui-components/buttons/Button2";
import "../../style/admin/project.css";
import { developers } from "./createproject/data";

const Filters: React.FC<{
}> = (props) => {

  const [developerLimit,setDeveloperLimit] = useState(5)
  const [developersList,setDevelopersList] = useState(developers)

  const [filtersList,setFiltersList] = useState([])

  // const filtersList = []

  const[projectTypes] = useState(["Apartment","Villa","Independent House"])
  const[cities] = useState(["Bangalore","Hyderabad","Delhi"])
  

  useEffect(()=>{
    setDevelopersList(developersList.slice(0,developerLimit))
  },[developerLimit])

  useEffect(()=>{
    if(filtersList.length){
      let projects = []
      let mapping = {
        "project_type":[],
        "location":[],
        "developer":[]
      }
      for (const value of filtersList) {
        let filter = JSON.parse(value)
        mapping[filter.name] = [...mapping[filter.name],filter.value]
        // projects.push(...projects,...getFilteredArray(props.projects,filter.name,filter.value))
      }
      projects = applicationConfig?.data.projects.filter(currProject=>mapping["developer"]?.includes(currProject.developer) || mapping["project_type"]?.includes(currProject.project_type))
      applicationConfig?.functions.projects?.setProjects(projects)
    }else{
      applicationConfig?.functions.projects?.setProjects(applicationConfig?.data.projects)
    }

  },[filtersList])

  var isRemovingAll = false

  function addFilter(detail:any) {
    if(!isRemovingAll){
      let filters = [...filtersList]
      if(filters.indexOf(detail.value) != -1){
        let index = filters.indexOf(detail.value)
        filters.splice(index,1)
      }else{
        filters.push(detail.value)
      }
      setFiltersList(filters)
    }
   
  }

  function removeAll() {
    isRemovingAll = true
    setFiltersList([])
    setTimeout(() => {
      isRemovingAll = false
    }, 200);
  }
 
  return (
    <React.Fragment>

      <div className="hide-in-mobile">
        <Button2
          configObj={{}}
          action={()=>openFiltersWindowMobile()}
          label={"Filters"}
          fontAwesomeIcon={"fas fa-bars color-primary"}
          iconPos="left"
          classNames={"shadow-light margin0 font-medium full-width bg-color-black"}
        />
      </div>

      <div className="hide-in-desktop">
          <div className='center-aligned-row full-width ion-padding'>
            <div className='subcategories-close' onClick={closeFiltersPanelMobile}></div>
          </div>
          <div className="padding10 heading1">Filters</div>
      </div>
    

    {filtersList.length?
    <div className="display-flex-row padding5 marginTop5">
      <div className="font-small color-primary padding5 pointer marginTop5" onClick={()=>removeAll()}>Clear All</div>
      {filtersList.map((filter,index)=>{
        filter = JSON.parse(filter)
        return(
          <div key={index} className="bg-color-light pointer-none font-small marginleft5 marginTop5 padding5 white-card no-shadow">{filter.value}</div>
        )
      })}
    </div>
    :null}
    
    <div className="">
          <div className="bg-color-ligh heading3 padding10 marginTop10">
            Project type
          </div>
          <div className="ion-padding-start">
            {projectTypes.map((developer,index)=>{
                    return(
                      <div className="display-flex-row marginTop5 no-wrap" key={index}>
                        <IonCheckbox slot="end" checked={filtersList.includes(JSON.stringify({name:"project_type",value:developer}))} onIonChange={(e:any)=>addFilter(e.detail)} value={JSON.stringify({name:"project_type",value:developer})} color="dark" mode='md'/>
                        <div className="heading4 marginleft10">{developer}</div>
                      </div>
                    )
              })}
          </div>
        </div>

        <div className="">
          <div className="bg-color-ligh heading3 padding10 marginTop10">
            Location
          </div>
          <div className="ion-padding-start">
            {cities.map((developer,index)=>{
                return(
                  <div className="display-flex-row marginTop5 no-wrap" key={index}>
                    <IonCheckbox slot="end" checked={filtersList.includes(JSON.stringify({name:"location",value:developer}))} onIonChange={(e:any)=>addFilter(e.detail)} value={JSON.stringify({name:"location",value:developer})} color="dark" mode='md'/>
                    <div className="heading4 marginleft10">{developer}</div>
                  </div>
                )
              })}
          </div>
        </div>

        <div className="">
          <div className="bg-color-ligh heading3 padding10 marginTop10">
            Developer
          </div>
          <div className="ion-padding-start">
            {developersList.map((developer,index)=>{
                  return(
                    <div className="display-flex-row marginTop5 no-wrap" key={index}>
                      <IonCheckbox slot="end" checked={filtersList.includes(JSON.stringify({name:"developer",value:developer}))} onIonChange={(e:any)=>addFilter(e.detail)} value={JSON.stringify({name:"developer",value:developer})} color="dark" mode='md'/>
                      <div className="heading4 marginleft10">{developer}</div>
                    </div>
                  )
            })}
          </div>
        </div>


        <div className="bottom-left full-width display-none">
          <div className="display-flex-row full-width space-around">
            <div>
              <Button2
                configObj={{}}
                action={()=>{}}
                label={"Apply"}
                fontAwesomeIcon={"fas fa-check color-primary"}
                iconPos="left"
                classNames={"no-shadow bg-color margin0 font-medium full-width"}
              />
            </div>
            
            <div className="">
              <Button2
                configObj={{}}
                action={()=>{}}
                label={"Reset"}
                fontAwesomeIcon={"fas fa-undo color-danger"}
                iconPos="left"
                classNames={"no-shadow bg-color- margin0 font-medium full-width"}
              />
            </div>
            
          </div>
        </div>
    </React.Fragment>
  );
};

export default Filters;
