import '../../style/admin/project.css';

import React from 'react';


const HeaderLeftContent: React.FC<{
}> = (props) => {
 
  
 
  return (
    <div className="brand">
    {/* <img src="/assets/images/opus.png" alt="Opus"/> */}
    <div className="heading font-medium">Products</div>
  </div>
  );
};

export default HeaderLeftContent;
