import Konva from "konva"
import { getWindowWidth, hideComponentLoader, setCanvasContainerAspectRatio, showComponentLoader, showGlobalToast } from "../UI_methods/global"
import { isPotraitMode } from "../customizein3d/common"
import { enableConfigureMode } from "../customizein3d/modules/UI_methods"
import $ from "jquery"
import { applicationConfig, waitFor } from "../methods"
import { ProductInstance, TextureInfo } from "../customizer/ProjectConfiguration"
import { kanvasScenes } from "./scenes"
import { getAndSaveImageToCache } from "../cache/cache"


let isImageSelected = false

export class FabricateConfig{
    
    currRoom:string = "Room4_View1"
    containerDimensions = {
        width:0,
        height:0
    }
    stage = null
    layer = null
    foregroundProducts = []

    currSceneUrl:string = ""

    configuration = new Configuration()

    setCurrCategory:(cat:string)=>void

    productsList = []
    
    constructor() {
      let currScene = applicationConfig.clientName === "KairaFabrics"?kanvasScenes[2]:kanvasScenes[1]
      this.productsList = currScene.productsList
      this.currRoom = currScene.room_name
    }

    async init(containerId:string){
        await waitFor(1000)
        setCanvasContainerAspectRatio(16,10,0,0,"customizeIn3D")
        
        // if(getWindowWidth() > 480 && !isPotraitMode()){
        //     enableConfigureMode()
        // }

        enableConfigureMode()

        await waitFor(500)
        this.preapreStage(containerId)
    }

   



    setContainerDimensions(width:number,height:number){
        this.containerDimensions =  {
            width:width,
            height:height
        }
    }


    async preapreStage(containerId: string) {
        let width = $(`#${containerId}`).width();
        let height = $(`#${containerId}`).height();
        this.setContainerDimensions(width, height);
        let dimensions:any = await this.getStageWidthHeight()
        this.stage = new Konva.Stage({
            container: containerId,
            width: dimensions.width,
            height: dimensions.height,
        });
    
        this.layer = new Konva.Layer({
            name: "itemsLayer"
        });
        this.stage.add(this.layer);


        this.prepareDefaultScene()
        // await this.addBackground();
        this.layer.draw();
        this.applyEventListernersOnstage()
    }

    setIsSelected(val:boolean){
      isImageSelected = val
    }


    applyEventListernersOnstage(){
      let stage = this.stage
      let layer = this.layer
      let clickEffect = this.clickEffect
      let productsList = this.foregroundProducts
      let setCurrCategory = this.setCurrCategory
      this.stage.on('click tap', function (e) {
        if (e.target === stage) {
            return;
        }
        const containerRect = stage.container().getBoundingClientRect();
        const pos = stage.getPointerPosition();
        const adjustedPos = {
          x: pos.x - containerRect.left,
          y: pos.y - containerRect.top,
        };
        const dim = {
          width: stage.attrs.width,
          height: stage.attrs.height,
        };
        for (let j = productsList.length - 1; j >= 0; j--) {
          let info = productsList[j]
          const image = info.productImage
          const relativePos = {
            x: pos.x - image.x(),
            y: pos.y - image.y(),
          };
          const imageData = getImageData(info.tempCanvas, relativePos.x + containerRect.left, relativePos.y)
          if(imageData[3] !== 0){
            setCurrCategory(info.productInstance.categoryName)
            isImageSelected = true
            clickEffect(layer,image)
            return
          }
        }
      });

      this.stage.on('mousemove', function() {
        return
        if(!isImageSelected){
          // const containerRect = stage.container().getBoundingClientRect();
          const pos = stage.getPointerPosition();
          // const dim = {
          //   width: stage.attrs.width,
          //   height: stage.attrs.height,
          // };
          for(const info of productsList) {
            const image = info.productImage
            const relativePos = {
              x: pos.x - image.x(),
              y: pos.y - image.y(),
            };
            const imageData = getImageData(info.tempCanvas, relativePos.x , relativePos.y)
            if(imageData[3] !== 0){
              image.brightness(-0.2); 
              layer.draw()
            }else{
              image.brightness(0)
              layer.draw()
            }
          }
        }
      });

      this.stage.on('mouseout', function() {
        for(const info of productsList) {
          const image = info.productImage
          image.brightness(0)
          layer.draw()
        }
        isImageSelected = false
      });
      
    }


    async clickEffect(layer:any,image:any){
      for (let i = 0; i < 2; i++) {
        image.brightness(-0.2); 
        layer.draw()
        await  waitFor(200)  
        image.brightness(0)
        layer.draw()
      }
    }

   
    resetOutlines() {
      for (const currInfo of this.productsList) {
        const image = currInfo.productImage
        image.attrs.borderSize = 3
        image.attrs.borderColor = 'red'
        image.filters([]);
        image.cache();
      }
      this.layer.draw()
    }


    loadBackground() {
        let suffix = `fabricate/${this.currRoom}/Background/Background/${this.currRoom}_Background0000.webp`
        let src = applicationConfig.aws_public_url + suffix
        let imageObj = new Image();
        this.loadSprite(imageObj,src,"background")
    }

    

    async addBackgroundFiller() {
        let attrs = {
            width:this.containerDimensions.width,
            height:this.containerDimensions.height,
            x:0,
            y:0,
        }
        let src = `https://opusassets.s3.ap-south-1.amazonaws.com/public/fabricate/assets/living-room-with-tv-sofas-blurred.jpg`
        let imageObj = new Image();
        return await this.loadSprite(imageObj,src,"background",attrs)
    }

    async addForegroundProducts() {
      showComponentLoader("mainLoaderSpinner")
      
      let foregroundProducts = this.foregroundProducts
      let configuration = this.configuration
        for (const product of this.productsList) {
            let collection = product.collections[0]
            let companyName = collection.companyName
            let collectionName = collection.collectionName
            let categoryName = product.categoryName
            let allParts = product.parts
            let materialCode = product.defaultMaterial || "1"
            for (const currPart of allParts) {
              let imageObj = new Image();
              let imageObjDefault = new Image();
              let suffix = `public/fabricate/${this.currRoom}/Foreground/${product.productName}/${this.currRoom}_${product.productName}_${companyName}_${collectionName}_${materialCode}/${this.currRoom}_${product.productName}_${currPart}_${companyName}_${collectionName}_${materialCode}0000.webp`
              let defaultMaterialSuffix = `public/fabricate/${this.currRoom}/Foreground/${product.productName}/${this.currRoom}_${product.productName}_Default_Default_Default/${this.currRoom}_${product.productName}_${currPart}_Default_Default_Default0000.webp`

              await getAndSaveImageToCache(suffix).then(async (data:any) => {
                let url = getBlobObjectUrl(data.Body)
                let productImage:any = await this.loadSprite(imageObj,url,product.categoryName)
                if(productImage){
                  let texture = {
                    companyName:companyName,
                    collectionName:collectionName,
                    materialCode:materialCode,
                  }
                  let productInstance = configuration.addProduct(product)
                  configuration.updateConfig(productInstance,currPart,new TextureInfo(texture))
                  let info = {
                      productImage:productImage,
                      categoryName:categoryName,
                      productName:product.productName,
                      partName:currPart,
                      productInstance:productInstance,
                      tempCanvas:getTempCanvas(productImage)
                  }
                  productImage.cache();
                  productImage.filters([Konva.Filters.Brighten]);
                  foregroundProducts.push(info)
                }
              })
              .catch((err) => {
                getAndSaveImageToCache(defaultMaterialSuffix).then(async (data:any) => {
                  let url = getBlobObjectUrl(data.Body)
                  let productImage:any = await this.loadSprite(imageObjDefault,url,categoryName)
                  if(productImage){
                    let texture = {
                      companyName:companyName,
                      collectionName:collectionName,
                      materialCode:materialCode,
                    }
                    let productInstance = configuration.addProduct(product)
                    configuration.updateConfig(productInstance,currPart,new TextureInfo(texture))
                    let info = {
                        productImage:productImage,
                        categoryName:categoryName,
                        productName:product.productName,
                        partName:currPart,
                        productInstance:productInstance,
                        tempCanvas:getTempCanvas(productImage)
                    }
                    productImage.cache();
                    productImage.filters([Konva.Filters.Brighten]);
                    foregroundProducts.push(info)
                  }
                })
                .catch((err) => {
                });
              });
              
            }
        }
        setTimeout(() => {
            hideComponentLoader("mainLoaderSpinner")
        }, 5000);
    }



    // postImageAddAction(productImage:any,product:any,currPart:any){
    
    //   let collection = product.collections[0]
    //   let companyName = collection.companyName
    //   let collectionName = collection.collectionName
    //   let categoryName = product.categoryName
    //   let allParts = product.parts
    //   let materialCode = product.defaultMaterial || "1"
    //   if(productImage){
    //     let texture = {
    //       companyName:companyName,
    //       collectionName:collectionName,
    //       materialCode:materialCode,
    //     }
    //     let productInstance = configuration.addProduct(product)
    //     configuration.updateConfig(productInstance,currPart,new TextureInfo(texture))
    //     let info = {
    //         productImage:productImage,
    //         categoryName:categoryName,
    //         productName:product.productName,
    //         partName:currPart,
    //         productInstance:productInstance,
    //         tempCanvas:getTempCanvas(productImage)
    //     }
    //     productImage.cache();
    //     productImage.filters([Konva.Filters.Brighten]);
    //     foregroundProducts.push(info)
    //   }
    // }


    async prepareDefaultScene() {
        // await this.addBackgroundFiller()
        this.addForegroundProducts()
        this.loadBackground()
    }

    updateScene(scene:any){
      this.currRoom = scene.room_name
      this.productsList = scene.productsList
      this.layer.removeChildren();
      this.layer.draw();
      this.foregroundProducts = []
      this.configuration.productsList = []
      this.prepareDefaultScene()
      this.applyEventListernersOnstage()
      isImageSelected = false
      this.setCurrCategory("Select Product")
    }

    async loadSprite(imageObj:any,src:string,name:string,attrs:any = null) {
        return new Promise((resolve,reject)=>{
            imageObj.crossOrigin = 'Anonymous';
            let stage = this.stage
            let layer = this.layer
            // let containerDimensions = this.containerDimensions
            imageObj.onload = async function () {
                // let aspectRatio = imageObj.width / imageObj.height
                // let width = containerDimensions.height * aspectRatio
                // let posX = (containerDimensions.width - width) / 2
                let posX = 0
                if(attrs){
                    posX = attrs.x
                }
                let productImage = new Konva.Image({
                    image: imageObj,
                    width: stage.attrs.width,  
                    height: stage.attrs.height,  
                    x: posX,  
                    y: attrs?.y || 0,  
                    name: name,


                 
                });
                layer.add(productImage);
                productImage.on('click tap', function (e) {
                });
                layer.draw();
                resolve(productImage)
            };
            imageObj.src = src;
            imageObj.onerror = async function (err) {
                reject(null)
            }; 
        })
        
    }

    async getStageWidthHeight() {
        let suffix = `fabricate/${this.currRoom}/Background/Background/${this.currRoom}_Background0000.webp`
        let src = applicationConfig.aws_public_url + suffix
        let imageObj = new Image();
        imageObj.crossOrigin = 'Anonymous';
        return new Promise((resolve,reject)=>{
            let containerDimensions = this.containerDimensions
            imageObj.onload = async function () {
                let aspectRatio = imageObj.width / imageObj.height
                let width = containerDimensions.height * aspectRatio
                if(isPotraitMode()){
                  let height = containerDimensions.width / aspectRatio
                  $(".customize-canvas-wrapper").height(height)
                  $(".customize-controls-wrapper").css("height","-webkit-fill-available")
                  resolve({width:containerDimensions.width,height:height})
                }else{
                  resolve({width:width,height:containerDimensions.height})
                }
            };
            imageObj.src = src;
            imageObj.onerror = async function (err) {
                reject(false)
            }; 
        })
        
    }

        // Function to update the image dynamically
    updateImage(config:any,imageSrc:string,textureInfo:any) {
      let productImage = config.productImage
      let currPart = config.partName
      let productInstance = config.productInstance
        let imageObj = productImage.attrs.image;
        let layer = this.layer;
        let configuration = this.configuration;
        imageObj.onload = async function () {
            let currentWidth = productImage.width();
            let currentHeight = productImage.height();
            productImage.image(imageObj);

            productImage.cache();
            productImage.filters([Konva.Filters.Brighten]);
            productImage.brightness(0);

            productImage.width(currentWidth); 
            productImage.height(currentHeight);  
            configuration.updateConfig(productInstance,currPart,new TextureInfo(textureInfo))
            layer.draw(); 
        };
        imageObj.onerror = async function () {
          console.log("Not Found " + imageSrc)
        };
        imageObj.src = imageSrc;

        isImageSelected = false
    }


}


class Configuration{

  productsList = []

  constructor(){

  }
  addProduct(productInfo:any){
    let product = new ProductInstance(productInfo)
    this.productsList.push(product)
    return product
  }
  updateConfig(productInstance:ProductInstance,partName:string,texture:any){
    productInstance.configuration[partName] = texture
  }
}

 

function getTempCanvas(image:any){
  let tempCanvas = document.createElement('canvas');
  const context = tempCanvas.getContext('2d');
  tempCanvas.width = image.width();
  tempCanvas.height = image.height();
  context.drawImage(image.image(), 0, 0,image.width(),image.height());
  return context

}

function getImageData(context:any, x:number, y:number) {
  return context.getImageData(x, y, 1, 1).data;
}


export function getBlobObjectUrl(arrayBufferView: any) {
  try {
    let blob = new Blob([arrayBufferView], { type: "image/jpeg" });
    let urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  } catch (error) {}
}