import $ from "jquery"
import { disposeVariables, stringWithoutWhiteSpace } from "../methods";
import { getParentContainer, hideImage } from "../UI_methods/global";
var WINDOW_OBJ:any = window
var indexedDB = WINDOW_OBJ.indexedDB || WINDOW_OBJ.webkitIndexedDB || WINDOW_OBJ.mozIndexedDB || WINDOW_OBJ.OIndexedDB || WINDOW_OBJ.msIndexedDB,
IDBTransaction = WINDOW_OBJ.IDBTransaction || WINDOW_OBJ.webkitIDBTransaction || WINDOW_OBJ.OIDBTransaction || WINDOW_OBJ.msIDBTransaction,
dbVersion = 1.0;


export function captureAndSaveScreenshot(myImage:any,moduleName:string,fileName:string) {
  
  // if (canvas) {
  //   //  var ctx = canvas.getContext("2d");                
  //    var myImage = canvas.toDataURL("image/png");
     let key = getScreenshotKey(moduleName,fileName)
     saveScreenshotOnIndexdb(key,myImage)
  // }
}

export function saveScreenshotOnIndexdb(key:string,blobUrl:string) {
    // Create/open database
    key = stringWithoutWhiteSpace(key)
    var request = indexedDB.open("screenshotsDB", dbVersion),db:any,createObjectStore = function (dataBase:any) {
            dataBase.createObjectStore("screenshots");
        },
  
        getImageFile = function (blobUrl:string) {
           try {
            // Create XHR
            let xhr = new XMLHttpRequest(),
                blob;
            xhr.open("GET", blobUrl , true);
            // Set the responseType to blob
            xhr.responseType = "blob";
  
            xhr.addEventListener("load", function () {
                if (xhr.status === 200) {
                    // console.log("Image retrieved");
                    
                    // Blob as response
                    blob = xhr.response;
                    // console.log("Blob:" + blob);
  
                    // Put the received blob into IndexedDB
                    putScreenshotInDb(blob);
                    disposeVariables([blob])
                }
            }, false);
            // Send XHR
            xhr.send();
            // putScreenshotInDb(blobUrl); 
           } catch (error) {
             console.log(error)
           }
            
        },
  
        putScreenshotInDb = function (blob:any) {
          try {
            // console.log("Putting screenshots in IndexedDB");
            // Open a transaction to the database
            var transaction = db.transaction(["screenshots"],'readwrite');
            // Put the blob into the database
            transaction.objectStore("screenshots").put(blobUrl,key);
            disposeVariables([blob])
          } catch (error) {
            console.log(error)
          }
            
        };
  
    request.onerror = function (event:any) {
        console.log("Error creating/accessing IndexedDB database");
    };
  
    request.onsuccess = function (event:any) {
        // console.log("Success creating/accessing IndexedDB database");
        db = request.result;
  
        db.onerror = function (event:any) {
            console.log("Error creating/accessing IndexedDB database");
        };
        
        // Interim solution for Google Chrome to create an objectStore. Will be deprecated
        if (db.setVersion) {
            if (db.version != dbVersion) {
                var setVersion = db.setVersion(dbVersion);
                setVersion.onsuccess = function () {
                    createObjectStore(db);
                    getImageFile(blobUrl);
                };
            }
            else {
                getImageFile(blobUrl);
            }
        }
        else {
            getImageFile(blobUrl);
        }
    }
    // For future use. Currently only in latest Firefox versions
    request.onupgradeneeded = function (event:any) {
        createObjectStore(event.target.result);
    };
  };
  
  export function displayImageFromIndexDb(imageElementId:string,moduleName:string,fileName:string){
    let key = getScreenshotKey(moduleName,fileName)
    try {
      return new Promise((resolve,reject)=>{
        let parentContainer = getParentContainer() || document
        let imgFile = null;
        // Create/open database
        let request = indexedDB.open("screenshotsDB", dbVersion),
          db: any,
          createObjectStore = function (dataBase: any) {
            // Create an objectStore
            // console.log("Creating objectStore");
            dataBase.createObjectStore("screenshots");
          },
          putScreenshotInDb = function () {
            try {
              // console.log("Getting Old one in IndexedDB");
              // Open a transaction to the database
              let transaction = db.transaction(["screenshots"], "readwrite");
              // Retrieve the file that was just stored
              transaction
                .objectStore("screenshots")
                .get(key).onsuccess = function (
                event: any
              ) {
                imgFile = event.target.result;
                let image = parentContainer?.querySelector(`#${imageElementId}`)
                if(!image){
                  image = document?.querySelector(`#${imageElementId}`)
                }
                if(imgFile){
                  image?.setAttribute("src", imgFile);
                  image?.setAttribute("srcset", imgFile);
                  disposeVariables([imgFile])
                  resolve(imgFile)
                  // $(`#${imageElementId}`).attr("src", imgFile);
                  // $(`#${imageElementId}`).attr("srcset", imgFile);
                }else{
                  // $(image).css("visibility","hidden")
                  resolve(false)
                  // image?.setAttribute("src", "");
                  // image?.setAttribute("srcset", "");
                }
              };
            } catch (error) {
              resolve(error)
            }
          
          };
    
        request.onerror = function (event: any) {
          resolve("Error creating/accessing IndexedDB database");
        };
  
        request.onupgradeneeded = function (event:any) {
          createObjectStore(event.target.result);
        };
  
        request.onsuccess = function (event: any) {
          // console.log("Success creating/accessing IndexedDB database");
          db = request?.result;
    
          db.onerror = function (event: any) {
            resolve("Error creating/accessing IndexedDB database");
          };
    
          // Interim solution for Google Chrome to create an objectStore. Will be deprecated
          if (db.setVersion) {
            if (db.version != dbVersion) {
              let setVersion = db.setVersion(dbVersion);
              setVersion.onsuccess = function () {
                createObjectStore(db);
                putScreenshotInDb();
              };
            } else {
              putScreenshotInDb();
            }
          } else {
            putScreenshotInDb();
          }
        };
      })
    } catch (error) {
      console.log(error);
    }
   
   }


   export function getImageFromIndexDb(moduleName:string,fileName:string){

   
    try {
      return new Promise((resolve,reject)=>{
        let key = getScreenshotKey(moduleName,fileName)
        let imgFile = null;
        // Create/open database
        let request = indexedDB.open("screenshotsDB", dbVersion),
          db: any,
          createObjectStore = function (dataBase: any) {
            // Create an objectStore
            // console.log("Creating objectStore");
            dataBase.createObjectStore("screenshots");
          },
          putScreenshotInDb = function () {
            try {
              // console.log("Getting Old one in IndexedDB");
              // Open a transaction to the database
              let transaction = db.transaction(["screenshots"], "readwrite");
              // Retrieve the file that was just stored
              transaction.objectStore("screenshots").get(key).onsuccess = function (event: any) {
                imgFile = event.target.result;
                resolve(imgFile)
                
                // $(`#${imageElementId}`).attr("src", imgFile);
                // $(`#${imageElementId}`).attr("srcset", imgFile);
              };
            } catch (error) {
              reject(error)
            }
          
          };
    
        request.onerror = function (event: any) {
          reject("Error creating/accessing IndexedDB database");
        };
    
        request.onsuccess = function (event: any) {
          // reject("Success creating/accessing IndexedDB database");
          db = request?.result;
    
          db.onerror = function (event: any) {
            reject("Error creating/accessing IndexedDB database");
          };
    
          // Interim solution for Google Chrome to create an objectStore. Will be deprecated
          if (db.setVersion) {
            if (db.version != dbVersion) {
              let setVersion = db.setVersion(dbVersion);
              setVersion.onsuccess = function () {
                createObjectStore(db);
                putScreenshotInDb();
              };
            } else {
              putScreenshotInDb();
            }
          } else {
            putScreenshotInDb();
          }
        };
      })
     
    } catch (error) {
      console.log(error);
    }
   
   }

   export function getFileKey(moduleName:string,info:any){
     switch(moduleName.toLowerCase()){
       case "customizer":
        return `${info.projectName}_${info.areaName}_${info.viewName||stringWithoutWhiteSpace(info.productName)}`
        break
       case "customizer3dmode":
        return `${info.projectName}_${info.areaName}_${stringWithoutWhiteSpace(info.productName)}`
      case "customizein3d":
        return `${stringWithoutWhiteSpace(info.productName)}_${info.mode}`
        break;

     }
   }

   export function getScreenshotKey(moduleName:any,productName:string) {
    return `screenshot_${moduleName}_${productName}`
  }
  