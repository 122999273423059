import { IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import MainHeader from "../common/MainHeader";
import SubHeader from "../common/SubHeader";
import Filters from "./Filters";
import ProductsList from "./ProductsList";

const StoreProductSearchResults: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {

  const history = useHistory()
 
  const[searchedProductsList,setSearchedProductsList] = useState([])

  useEffect(()=>{
    STORE.functionRefs.setSearchedProductsList = setSearchedProductsList
  },[])


  function SearchResults() {
    return(
      <>
        <div className="sub-categories-wrapper marginTop10">
          <div className=""></div>
        </div>
        <ProductsList productsList={searchedProductsList}/>
      </>
    )
  }
 
  function TopFilterHeader() {
    return(
      <div className="full-width display-flex-row space-between marginTop10 ">
        <div className="padding10">
          <div className="heading2 font-medium">Results - Showing {searchedProductsList?.length} product</div>
        </div>
      </div>
    )
  }
  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white">
        <MainHeader/>
        <SubHeader/>
        <div className="full-height-width overflow-y-scroll display-flex-row no-wrap product-filters-wrapper">
          <div className="full-height filters-container">
            <Filters/>
          </div>
          <div className="products-container">
            <TopFilterHeader/>
            <SearchResults/>
          </div>
        </div>
      </div>
      :null}
    </IonPage>
  );
};

export default StoreProductSearchResults;
