import { Group, Object3D, Quaternion, Vector3 } from "three";
import { showToast } from "../UI_methods/global";
import { displayAnnotations, openGroupsListPanel } from "../customizein3d/area3dmodel/UI_methods";
import { deselectProduct, kitchenPlanner, scene } from "../customizein3d/area3dmodel/area3dModel";
import { addDragControlsInProduct } from "../customizein3d/area3dmodel/dragControls";
import { addBoxHelper } from "../customizein3d/area3dmodel/dragging";
import { postAddingActions } from "../customizein3d/area3dmodel/helper";
import { attachModules, detachModules } from "../customizein3d/common";
import { CURR_SELECTED_PRODUCT, setCurrSelectedProduct } from "../customizein3d/raycasting";
import { applicationConfig } from "../methods";

export var CURR_SELECTED_GROUP = null



export function createNewGroup() {
  if(!CURR_SELECTED_PRODUCT){
    showToast("Please select an item to add",3000,"error")
    return 
  }

  if(CURR_SELECTED_PRODUCT.userData.isModulesGroup){
    showToast("Already a group",2000)
    return 
  }

  let group = new Group()
  group.userData.isModulesGroup = true
  let index = kitchenPlanner.groupsList.length+1
  let groupName = "Group"+index
  group.name = groupName
  scene.add(group)
  kitchenPlanner.addGroupToList(group)
  return group
}


export function addItemToGroup(group:any,object:Object3D = null,isOpenGroupsPanel:boolean = true) {
  if(!object){
    object = CURR_SELECTED_PRODUCT
  }
  if(!object){
    showToast("Please select an item to add",3000,"error")
    return 
  }

  if(object.userData.isModulesGroup){
    showToast("Already a group",2000)
    return 
  }

  if(object.userData.isWallMounted === false && group.userData.isWallMounted === true){
    showToast("Modules type mismatch",2000)
    return 
  }

  //To resolve position rotaion issue snapping towall
  setGroupPositionRotation(group,object)
  setUserData(group,object)
  group.attach(object)
  updateGroupPosition(group)
  deselectProduct()
  selectGroupKitchenPlanner(group)
  if(isOpenGroupsPanel){
    openGroupsListPanel(null)
    applicationConfig.functions.kitchenPlanner.setRefreshGroupList(Number(new Date()))
    showToast(object.name+ " Added to group",3000)
  }

}

function setUserData(group:any,object:Object3D) {
  object.userData.modulesGroup = group
  object.userData.isAddedToGroup = true
  group.userData.isAttachedToWall = object.userData.isAttachedToWall
  group.userData.isWallMounted = object.userData.isWallMounted
  group.userData.isWallProduct = object.userData.isWallProduct
  group.userData.normal = object.userData.normal
  group.userData.configuration = object.userData.configuration
}

function setGroupPositionRotation(group:any,object:Object3D){
  let position = new Vector3()
  let quaternion = new Quaternion()
  object.getWorldPosition(position)
  object.getWorldQuaternion(quaternion)
  // group.position.copy(position)
  group.quaternion.copy(quaternion)
}

export function deleteGroup(group:any) {

  let children = [...group.children]

  children.forEach(modelObject=>{
    unlinkModuleFromGroup(group,modelObject)
  })
    
  scene.remove(group)
  kitchenPlanner.removeGroupFromList(group)
  applicationConfig.functions.kitchenPlanner.setRefreshGroupList(Number(new Date()))
  applicationConfig.functions.kitchenPlanner.setRefreshModulesList(Number(new Date()))
  // deselectProduct()
  // removeBoxHelper()
  // areaModel.remove(group)
  // deselectProduct()
}

export function unlinkModuleFromGroup(group:Group,modelObject:any) {
  let position = new Vector3()
  let quaternion = new Quaternion()
  // let modelObject = object.clone()
    // detachModule(modelObject)
  modelObject.getWorldPosition(position)
  modelObject.getWorldQuaternion(quaternion)
  modelObject.position.copy(position)
  modelObject.quaternion.copy(quaternion)
  scene.add(modelObject)
  group.remove(modelObject)
  postAddingActions(modelObject)
  deselectProduct()
}

export function addModulesToGroup() {
  
}

export function duplicateModulesGroup() {
  let children = [...CURR_SELECTED_GROUP.children]
  children.forEach(currChild => {
    kitchenPlanner.duplicateModule(currChild)
  });
  // kitchenPlanner.duplicateModule(CURR_SELECTED_GROUP)
}

export function selectGroupKitchenPlanner(group:Group) {
  CURR_SELECTED_GROUP = group
  setCurrSelectedProduct(group)
  addBoxHelper(group)
  displayAnnotations()
  addDragControlsInProduct(group)
  applicationConfig.functions.kitchenPlanner.setCurrSelectedModuleGroup(group)
}

export function deselectGroupKitchenPlanner() {
  CURR_SELECTED_GROUP = null
  applicationConfig.functions.kitchenPlanner.setCurrSelectedModuleGroup(null)
}

function updateGroupPosition(group:Group) {
  let children = [...group.children]
  let center = children[0].position
  // let center = new Vector3()
  // let box = new Box3()
  // children.forEach(currchild=>{
  //   box.expandByObject(currchild)
  // })
  // center = box.min
  detachModules(children)
  group.position.set(center.x,center.y,center.z)
  attachModules(children,group)
}
