import $ from "jquery";
import React, { useEffect } from "react";
import { ProjectForm } from "../../../../utils/projects/projectForm";

const Hint: React.FC<{
  id:string
   heading:string
   subHeading:string
   buttonText:string
   closeAction:()=>void
   isShow:boolean
}> = (props) => {

  

  useEffect(()=>{
    if(props.isShow){
        $("#hintContainer"+props.id).fadeIn(0)
    }
  },[props.isShow])

  function close() {
    $("#hintContainer"+props.id).fadeOut(0)
    props.closeAction()
  }


  return (
    <React.Fragment>
    <div id={"hintContainer"+props.id} onClick={close} className="full-height-width stop-propagation top-left center-aligned-column" style={{display:"none",background:"rgba(0,0,0,0.8)",zIndex:1002}}>
        <div className=" pointer-none">
            <div className="center-aligned-row">  
              <span className="center-aligned-column"><i className="far fa-check-circle font-large marginRight10 color-success"></i></span>
              <span className="center-aligned-column heading4 letter-spacing color-white" style={{fontSize:"20px"}}>{props.heading}</span>
            </div> 
            <div className="heading4 ion-margin-top letter-spacing color-white" style={{fontSize:"14px"}}>{props.subHeading}</div>
            <div className="center-aligned-column ion-margin-top pointer click-effect">
              <div className="border display-flex-row color-white white-card no-shadow" style={{width:"fit-content",padding:"10px 20px",background:"transparent"}}>
                <span className="center-aligned-column heading4 font-normal letter-spacing stop-propagation marginRight10 color-white">{props.buttonText}</span>
                <span className="center-aligned-column"><i className="fas fa-arrow-right font-normal marginRight10 color-white"></i></span>
              </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Hint;
