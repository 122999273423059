import $ from "jquery";
import React, { useEffect, useState } from "react";
import { updateTexture } from "../../../../utils/customizein3d/modules/customizein3d";
import { applicationConfig, getFilteredArray } from "../../../../utils/methods";
import MaterialImage from "../../../common/ui-components/elements/MaterialImage";
import { STORE } from "../../../../utils/store/storeConfiguration";
import { TextureInfo } from "../../../../utils/customizer/ProjectConfiguration";
import { untarAndSaveToCache } from "../../../../utils/cache/cache";
import { getFilteredMaterialsForElevate } from "../../../../utils/elevate/elevate";
 

const MaterialSelector: React.FC<{
  product:any
  setCurrMaterial:(material:any)=>void
}> = (props) => {

  const[currCategory]:any =  useState(props.product?.category_name)
  const[materials,setMaterials]:any =  useState([])
  const[isLoaded,setIsLoaded]:any =  useState(false)



  useEffect(()=>{
    let list = applicationConfig?.data?.materials
    list = getFilteredMaterialsForElevate(list)
    setMaterials(list)
    downloadTar()
  },[])

 

  async function downloadTar() {
    let key = applicationConfig.awsConfig.getTarFileKey("thumbnails",{companyName:"Demo",collectionName:"Fabric"})
    await untarAndSaveToCache(applicationConfig.awsConfig,`thumbnails/OVL/${"Demo"}/${"Fabric"}`,key)
    setIsLoaded(true)
  }

  function onChange(event:any,material:any) {
    $(".texture-label-container").removeClass("--is-active")
    $(event.target).addClass("--is-active")
    let texture = new TextureInfo(material)
    STORE.productConfiguration.texture = texture

    if(STORE.isViewin3D){
      // updateTexture(event)
      STORE.modelViewer.updateFinish(event)
    }else{
      STORE.updateProductImage("elevateProductImage",STORE.currProduct)
    }
    $("#currMaterial").text(material.material_code)
  }

            
  return (
    <div className="full-height-width display-flex-row padding10">
      
       {isLoaded?materials.map((material:any,index:number)=>{
          return(
            <div key={index} className={`pointer marginTop10 position-relative control-item texture-label-container`} style={{width:"25%"}}
                data-company-name={material.company_name}
                data-collection-name={material.collection_name}
                data-material-id={material.id}
                data-material-code={material.material_code}
                data-material-type={material.material_type}
                data-uv-scale={material.uv_scale}
                data-roughness-value={material.roughness}
                data-metalness-value={material.metalness}
                data-specular-value={material.specular}
                data-clearcoat-value={material.clearcoat}
                data-sheen-value={material.sheen}
                draggable="true"
                onClick={(event)=>{onChange(event,material)}}
            >
              <div className="full-width position-relative border pointer-none" style={{aspectRatio:"1",padding:"5px"}}>
                <div className="center-aligned-row">
                  <MaterialImage material={material}/>
                </div>
                <span className="middle checkmark center-aligned-column"><i className="fas fa-check color-black font-medium"></i></span>
              </div>
              <div className="padding5 center-aligned-column">
                  <div className="heading3 color-dark font-md-small text text-center text-no-wrap">{material.material_code}</div>
                  {/* <div className="heading4 font-md-small marginTop5 text text-center text-no-wrap">{material.collection_name}</div> */}
              </div>
            </div>
          )
        }):
        <div className="padding10 center-aligned-column">Please Wait...</div>
        }
   </div>
  );
};

export default MaterialSelector;
