import $ from "jquery";
import React, { useEffect, useState } from "react";
import { updateHeightUsingTruck } from "../../../../../../utils/customizein3d/area3dmodel/actions";
import { projectConfiguration } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { cameraSettingViewData } from "../../../../../../utils/customizein3d/area3dmodel/floorplan/createRender";
import { disableInteriorVirtualization, gotoInteriorArea, updateInteriorAreaLabelsPosition } from "../../../../../../utils/customizein3d/area3dmodel/interior_visualization";
import { rendererDimensions } from "../../../../../../utils/customizein3d/common";
import { applicationConfig } from "../../../../../../utils/methods";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import MovementControls from "./floorplan/MovementControlsAndDebugCamera";


const InteriorView: React.FC<{
  }> = (props) => {

  var frameHeight = Math.floor( rendererDimensions.height * cameraSettingViewData.height ) + 2;
  var frameWidth = Math.floor(rendererDimensions.width * cameraSettingViewData.width) + 2
  var frameLeft = Math.floor(rendererDimensions.width * cameraSettingViewData.left)  
  var frameBottom = Math.floor(rendererDimensions.height * cameraSettingViewData.bottom)  

  const[areaList,setAreaList] = useState(projectConfiguration?.areasList)

  useEffect(()=>{
      // let list = projectConfiguration?.areasList.filter(area=>area.area_name!=="Other")
      // list.sort((a,b)=>a.area_name<b.area_name?-1:1)
      // setAreaList(list)
      setTimeout(() => {
        updateInteriorAreaLabelsPosition(areaList)
        $(".interior-anchor-container").removeClass("display-none")
      }, 2000);
  },[areaList])

  function showLabel(event:any){
    $(event.target).find('.floorplan-tooltip').removeClass("display-none")
  }

  function hideLabel(event:any){
    $(".floorplan-tooltip").addClass("display-none")
  }
   
  return (
    <>

    <div className="bottom-left white-card scene-minimap-frame padding0 zIndex100 pointer-none" style={{left:`${frameLeft}px`,bottom:`${frameBottom}px`,width:`${frameWidth}px`,height:`${(frameHeight)}px`,borderColor:"#dddddd"}}>
    </div>

      {/* <div id="rangeSliderForInteriorView" className="rig initial-hide " style={{position:"absolute",right:"-15%",top:"49%",transform:"translate(-50%,-50%)",zIndex:1001}}>
          <div className="position-relative" style={{width:"320px"}}>
            <input type="range" className="range-slider camera-height-slider sm-range-slider" onChange={updateHeightUsingTruck} id="rangeSliderHeight" style={{transform:"rotate(-90deg)"}}/>
            <div className="movement-control padding5 bottom-left color-black label white-card no-shadow heading2 uppercase text-center" style={{bottom:"-200px",left:"42%"}}>(Height)</div>
          </div>
      </div> */}


      <div className="zindex30 top-right">
      <Button2
        configObj={applicationConfig}
        action={disableInteriorVirtualization}
        label={"Close Walkthrough"}
        fontAwesomeIcon={"fas fa-times color-danger"}
        iconPos="left"
        classNames={"shadow-light bg-color-black"}
      />
    </div>
      
      <div className="top-middle ion-padding  zindex30 pointer-none">
        <div className={`position-relative zindex30 button2 no-border button-small n shedow-medium button-small white-card click-effect no-wrap display-flex-row`}>
          <img src={`${applicationConfig.aws_public_url}assets/icon/svg/location_primary.svg`} width={18} className=" pointer-none" />
          <span id="walkthroughCurrAreaName" className="text center-aligned-column pointer-none" style={{margin:"0 0.5rem"}}></span>  
        </div> 
      </div>

      <MovementControls/>
      <div className="full-height-width zindex30 top-left pointer-none padding0">
      {projectConfiguration?.areasList.length > 2?
        projectConfiguration?.areasList.map(
          (area: any, index: number) => {
            return (

              <div onClick={()=>gotoInteriorArea(area.area_name)} onPointerEnter={showLabel} onPointerOut={hideLabel} id={`interiorViewanchor${area.area_name}`} key={index} className={area.area_name === "Other"?"display-none":"pointer display-none anchor-icon-container interior-anchor-container top-middle zindex30"}>
                <div className="anchor-icon pointer-none "><i className="fas fa-map-marker-alt pointer-none color-primary font-normal icon"></i></div>
                  <div className="heading4 color-black pointer-none white-card display-none floorplan-tooltip shadow-light anchor-tooltip font-ex-small middle">{area.area_name}
                  </div>
                </div>
            );
          }
        )
      : null}
      </div>

   </>
  );
};

export default InteriorView;
