import React, { useState } from "react";
import { useParams } from "react-router";
import Button2 from "../../../../common/ui-components/buttons/Button2";
 

const Filters: React.FC<{
 
}> = (props) => {

  return (
    <div className="filters-wrapper full-height-width padding10">
    <div className="display-flex-row border-bottom space-between" style={{paddingBottom:"10px"}}>
      <div className="heading2 font-medium">Filters</div>
      <div className="heading4 color-primary font-normal center-aligned-column">Reset</div>
    </div>

    <div className="border-bottom ion-margin-top " style={{paddingBottom:"10px"}}>
      <div className="heading3 font-normal">Price range</div>
        <div className="range-selector"></div>
      <div className="heading4 font-normal marginTop10">0 to  50,000</div>

      <div className="display-flex-row no-wrap space-around marginTop10">
        <Button2
            configObj={{id:""}}
            action={()=>{}}
            label={"Apply"}
            fontAwesomeIcon={"fas fa-chevron-left color-warning"}
            iconPos=""
            classNames={"no-shadow margin0 button-small bg-color-warning cart-button-warning-outline"}
          />
          <Button2
            configObj={{id:""}}
            action={()=>{}}
            label={"Reset"}
            fontAwesomeIcon={"fas fa-chevron-left color-warning"}
            iconPos=""
            classNames={"no-shadow button-small margin0 cart-button cart-button-warning-outline"}
          />
      </div>
    </div>

{/* 
    <div className="border-bottom ion-margin-top" style={{paddingBottom:"10px"}}>
      <div className="heading3 font-normal">Product options</div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">Customizable / Modular</span>
      </div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">3D model available</span>
      </div>

    </div> */}


    <div className="border-bottom ion-margin-top display-none" style={{paddingBottom:"10px"}}>
      <div className="heading3 font-normal">Variants</div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">1 Seater</span>
      </div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">2 Seater</span>
      </div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">3 Seater</span>
      </div>

    </div>

    <div className="border-bottom ion-margin-top display-none" style={{paddingBottom:"10px"}}>
      <div className="heading3 font-normal">Pimrary color</div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">Beige</span>
      </div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">Black</span>
      </div>


      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">Blue</span>
      </div>

    </div>


    <div className="border-bottom ion-margin-top display-none" style={{paddingBottom:"10px"}}>
      <div className="heading3 font-normal">Primary material</div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">Laminate</span>
      </div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">Wood</span>
      </div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">Fabric</span>
      </div>

      <div className="display-flex-row no-wrap marginTop10">
        <input type="checkbox" />
        <span className="heading4 marginleft10">Glass</span>
      </div>

    </div>

 


    
    


  </div>
  );
};

export default Filters;
