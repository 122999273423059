import React, { useState } from "react";
import MaterialImage from "../../../../common/ui-components/elements/MaterialImage";

 
const ProductFinishDetails: React.FC<{
    configuration:any
}> = (props) => {


    const[configurations] = useState(getConfigurationArray())

    

    function getConfigurationArray(){
        let result  = []
        for (const partName in props.configuration) {
            let textureConfig = props.configuration[partName]
            textureConfig.partName = partName
            result.push(textureConfig)
        }
        return result

    }

  return (
    <div className="full-width">
        <table className="table store-quotation-table" style={{tableLayout:"fixed"}}>
        <thead>
            <tr>
                <th>Part Name</th>
                <th className="">Collection</th>
                <th className="">Code</th>
                <th className="">Material</th>
            </tr>
        </thead>
        <tbody>
        {configurations.map((currconfig:any,index:number)=>{
            return(
                <tr key={index}>
                    <td>{currconfig.partName}</td>
                    <td>{currconfig?.collectionName}</td>
                    <td>{currconfig?.materialCode}</td>
                    <td>
                    <span>
                        <MaterialImage dimensions={{width:"2rem",height:"2rem"}} material={currconfig}/>
                    </span>
                    </td>
                </tr>
                
            )
        })}
        </tbody>
        </table>
    </div>
  );
};

export default ProductFinishDetails;
