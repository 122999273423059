import React from 'react';
import { IonIcon, IonModal} from '@ionic/react';
import { close } from 'ionicons/icons';
import { applicationConfig } from '../../../../utils/methods';
 
const Modal: React.FC<{
    isOpen:boolean
    onDismiss:(value:boolean)=>void
    classNames:string
    id:string
    Content:any
    heading:string
    FooterContent:any
}> = (props) => {

 

  return (
    <IonModal  onDidDismiss={() => props.onDismiss(false)} isOpen={props.isOpen}
      id={props.id}
      mode="ios"
      cssClass={props.classNames}>

        <div className="modal-header display-flex-row space-between">
        <div className="heading1 capitalize letter-spacing">{props.heading}</div>
        <div onClick={()=>{props.onDismiss(false)}} className="center-aligned-column">
          <span className='center-aligned-column'>
            <IonIcon icon={close} className='font-large pointer icon' />
          </span>
            {/* <Button2
            configObj={{id:""}}
            action={()=>props.onDismiss(false)}
            label={""}
            fontAwesomeIcon={"fas fa-times color-black"}
            iconPos="left"
            classNames={"no-shadow bg-color-light"}
          /> */}
          </div>
        </div>
        
        <div className="modal-body">
            <props.Content/>
        </div>
        <div className="modal-footer position-relative">
        <div className="display-flex-row full-width ion-padding-end ion-padding-start space-between">
          {!applicationConfig?.clientName?
          <div className="heading font-small center-aligned-column">Powered By Ominiview Labs</div>
          :null}
          <props.FooterContent/>
        </div>
        <div>
        </div>
        </div>



          {/* <props.Component/> */}
      </IonModal>
  );
};

export default Modal;
