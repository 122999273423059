import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getWindowHeight, getWindowWidth } from "../../../../../../utils/UI_methods/global";
import { customizerConfig, releaseMemoryCustomizer } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { cameraSettingViewData } from "../../../../../../utils/customizein3d/area3dmodel/floorplan/createRender";
import { createRender } from "../../../../../../utils/customizer/customizer";
import { applicationConfig } from "../../../../../../utils/methods";
import Modal from "../../../../../common/shared-components/Modals/Modal";
import Button from "../../../../../common/ui-components/buttons/Button";
import { createRenderConfigurator } from "../../../../../../utils/customizein3d/modules/render2d";
import { renderSettingConfig } from "../../../../../../utils/customizein3d/area3dmodel/floorplan/render2d";
import { rendererDimensions } from "../../../../../../utils/customizein3d/common";

const DebugOptions: React.FC<{
  isOpenDebugOptions:boolean
  setIsOpenDebugOptions:(value:boolean)=>void
}> = (props) => {

  const[isOpenAdvanceOptions,setIsOpenAdvanceOptions] = useState(false)
  const history = useHistory()

  const[renderingTime,setRenderingTime] = useState(0)
  const[noOfSamples,setNoOfSamples] = useState(5)
  const[resolution,setResolution] = useState({})
  const[noOfSplites,setNoOfSplits] = useState(8)
  const[percentageResolutions,setPercentageResolution] = useState(100)

  useEffect(()=>{
    renderSettingConfig.setPercentageResolution(Number(percentageResolutions))
    renderSettingConfig.setNoOfSamples(Number(noOfSamples))
  },[percentageResolutions,noOfSamples])

  useEffect(()=>{
    if(customizerConfig?.currentRenderingType === "2d"){
      setResolution({
        x:Math.round(rendererDimensions.width * (1 - cameraSettingViewData.width) || getWindowWidth()),
        y:Math.round(rendererDimensions.height || getWindowHeight()) 
      })
      setNoOfSplits(4)
    }else{
      setResolution({
        x:1280,
        y:640
      })
    }
  },[])


  var timeInterval = null

  function generateRender() {
    // logger?.info("customizer","createrender",`Start create render` )
    
    let renderResolution = $("#renderResolution").val() || resolution
    let renderNoOfSamples = $("#renderNoOfSamples").val() || noOfSamples
    let renderPercentageResolution = $("#renderPercentageResolution").val() || percentageResolutions
    let renderNoOfSplites = $("#renderNoOfSplites").val() || noOfSplites
    

    let formData = {
      resolution:renderResolution,
      noOfSamples:Number(renderNoOfSamples),
      percentageResolution:Number(renderPercentageResolution),
      noOfSplit:Number(renderNoOfSplites)
    }



    if(customizerConfig){
      renderSettingConfig.setDimensions(Math.round(rendererDimensions.width * (1 - cameraSettingViewData.width) || getWindowWidth()),Math.round(rendererDimensions.height  || getWindowHeight()))
      createRender(customizerConfig,formData,callBackAfterRender)
    }else{
      let width = $(".customize-canvas-wrapper").width()
      let height = $(".customize-controls-wrapper").height()
      renderSettingConfig.setDimensions(Math.round(width),Math.round(height))
      createRenderConfigurator(formData,callBackAfterRender)
    }
    

    setIsOpenAdvanceOptions(false)
    props.setIsOpenDebugOptions(false)
  }


  function updateDebugOptions(event:any) {
    let renderType = $(event.target).attr("data-render-type")
    $(".render-option").removeClass("--is-active")
    $(event.target).addClass("--is-active")
    switch (renderType?.toLowerCase()) {
      case "low":
        setNoOfSamples(5)
        setPercentageResolution(50)
        if(customizerConfig?.currentRenderingType === "2d"){
          setNoOfSplits(2)
        }
      
      break;

      case "medium":
        setPercentageResolution(100)
        setNoOfSamples(10)
      break;

      case "high":
        setNoOfSamples(20)
        setPercentageResolution(200)
      break;
    
      default:
        setNoOfSamples(10)
        setPercentageResolution(50)
        setResolution({
          x:1280,
          y:640
        })
        break;
    }
  }

  function callBackAfterRender(isReleaseMemory=true) {
    if(isReleaseMemory){
      releaseMemoryCustomizer()
    }
    props.setIsOpenDebugOptions(false)
  }

 
  function updateTime() {
    setRenderingTime(prevValue=>{
      return prevValue + 1
    })
  }

  function DebugOptionFooter() {
    return(
      <Button
        configObj={{}}
        action={generateRender}
        label={"Create Render"}
        fontAwesomeIcon={"fas fa-cube"}
        iconPos="right"
        classNames={"button-primary"}
      />
    )
  }

  function DebugOptions() {
    return(
      <div>

        {/* <div className="display-flex-row space-between ion-margin-bottom">
          <div className="heading1 font-large"> <i className="fas fa-cog marginRight5 color-primary font-large"></i> Render Options</div>
        
          <div className="display-flex-row">
            <span className="heading1">Rendering Time : </span>
            <span className="font-medium color-danger heading1 marginleft5">{renderingTime} Sec</span>
          </div>
        </div> */}
       

        <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">No of samples <span className="mandatory">*</span></div>
            </div>
            <div className="input-container">
            <select className="input" name="" id="renderNoOfSamples">
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
              </select>
            </div>
          </div>

          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">Percentage Resolution <span className="mandatory">*</span></div>
            </div>
            <div className="input-container">
              <select className="input" name="" id="renderPercentageResolution">
                  <option value="300">300</option>
                  <option value="200">200</option>
                  <option value="100">100</option>
                  <option value="50">50</option>
              </select>
            </div>
          </div>

          {/* <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">Resolution <span className="mandatory">*</span></div>
            </div>
            <div className="input-container">
              <select className="input" name="" id="renderResolution">
                  <option value="2400">2400</option>
                  <option value="3200">3200</option>
                  <option value="1200">1280</option>
                  <option value="512">512</option>
                  <option value="256">256</option>
                  <option value="128">128</option>
              </select>
            </div>
          </div> */}

          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">No of split <span className="mandatory">*</span></div>
            </div>
            <div className="input-container">
              <select className="input" name="" id="renderNoOfSplites">
                  <option value="8">8</option>
                  <option value="4">4</option>
                  <option value="2">2</option>
                  <option value="1">1</option>
              </select>
            </div>
          </div>



        
      

      </div>
    )
  }

  function gotoVirtualTour() {
    applicationConfig.setCurrArea("Foyer")
    history.push("/3dwalkthrough");
  }



  return (
    <React.Fragment>
      <Modal
            classNames="medium-x-modal"
            onDismiss={setIsOpenAdvanceOptions}
            isOpen={isOpenAdvanceOptions}
            heading="Advance render options"
            id=""
            FooterContent={DebugOptionFooter}
            Content={()=>(
              <DebugOptions/>
            )}
          >

        </Modal>
      {/* <DebugOptions/> */}


 

      <div className="display-flex-row top-right space-between ion-margin-bottom">
        {/* <div className="heading2 font-large">
          {" "}
          <i className="fas fa-cog marginRight5 color-primary font-large"></i>{" "}
          Render Options
        </div> */}

        <div className="display-flex-row ion-margin-top">
          <span className="heading2">Rendering Time : </span>
          <span className="font-medium color-danger heading1 marginleft5">
            {renderingTime} Sec
          </span>
        </div>
      </div>

      <div className="center-aligned-column full-height-width">
        <div className="">
          <div className="heading3 font-medium ion-margin-top capitalize color-black">Select from quick options or <a onClick={()=>setIsOpenAdvanceOptions(true)} className="font-small pointer" style={{textDecoration:"underline"}}>Advance options</a> </div>
          <div className="center-aligned-row no-wrap ion-margin-top ion-margin-bottom">
            <button onClick={updateDebugOptions} data-render-type="low" className="render-option button-large op-btn op-btn-primary-inverse  display-flex-row">
              <i className="fas fa-cube marginTop5 center-aligned-column color-dark"
                aria-hidden="true"></i>
              <span className="letter-spacing capitalize center-aligned-column  marginleft5">
                Low
              </span>
            </button>

            <button  onClick={updateDebugOptions} data-render-type="Medium" className="render-option --is-active button-large op-btn op-btn-primary-inverse marginleft5  display-flex-row">
              <i className="fas fa-cube marginTop5 center-aligned-column  color-dark"
                aria-hidden="true"></i>
              <span className="letter-spacing capitalize center-aligned-column  marginleft5">
                Medium
              </span>
            </button>

            <button onClick={updateDebugOptions} data-render-type="High" className="render-option op-btn button-large op-btn-primary-inverse marginleft5  display-flex-row">
              <i className="fas fa-cube marginTop5 center-aligned-column  color-dark"
                aria-hidden="true"></i>
              <span className="letter-spacing capitalize center-aligned-column  marginleft5">
                High
              </span>
            </button>
            </div>

            <div className="center-aligned-row ion-margin-top">
              {/* <div></div> */}
              <button
                onClick={generateRender}
                className="op-btn op-btn-primary-inverse op-btn-lg display-flex-row">
                {/* <i className="fas fa-cube font-large color-danger" aria-hidden="true"></i>  */}
                <img
                  src="assets/icon/virtualtour.png"
                  className="marginRight5"
                  alt=""
                  style={{ width: "2rem" }}
                />
                <span className="letter-spacing capitalize center-aligned-column font-medium marginleft5">
                  Generate Render
                </span>
              </button>

              
            </div>

            <button
            id="gotoVirtualTour"
                onClick={gotoVirtualTour}
                className="op-btn op-btn-primary-inverse display-flex-row" style={{visibility:"hidden"}}>
                {/* <i className="fas fa-cube font-large color-danger" aria-hidden="true"></i>  */}
                <img
                  src="assets/icon/virtualtour.png"
                  className="marginRight5"
                  alt=""
                  style={{ width: "2rem" }}
                />
                <span className="center-aligned-column font-medium marginleft5">
                  Generate Render
                </span>
              </button>

        </div>
      </div>


      

      {/* <div className="heading3 capitalize color-dark">
        <span className="color-dark heading3">No Of samples : {noOfSamples} , </span>
        <span className="color-dark heading3">Resolution : {resolution} , </span>
        <span className="color-dark heading3">Percentage resolution : {percentageResolutions} , </span>
        <span className="color-dark heading3">No of splites : {noOfSplites}</span>
      </div> */}

      
    </React.Fragment>
  );
};

export default DebugOptions;
