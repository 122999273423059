import { IonCheckbox, IonPage } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getWindowWidth } from "../../../../../utils/UI_methods/global";
import { isTouchDevice } from "../../../../../utils/customizer/helper";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getObjectByParameter, getSubCategoryDisplayName, getUser, goback, stringWithoutWhiteSpace } from "../../../../../utils/methods";
import { ChangeProductCardSize } from "../../../../../utils/store/UI_methods";
import { getFilteredProductForDemo } from "../../../../../utils/store/storeConfiguration";
import SelectStoreFront from "../../../../common/ui-components/alerts/SelectStoreFront";
import SessionExpired from "../../../../common/ui-components/alerts/SessionExpired";
import ProductFilters from "../../ProductFilters";
import MainHeader from "../common/MainHeader";
import SubHeader from "../common/SubHeader";
import ProductsList from "./ProductsList";
import SubscriptionAlert from "../../../../common/ui-components/alerts/SubscriptionAlert";
import { getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";
import HorizontalScroll from "../../../../common/ui-components/scrollbar/HorizontalScroll";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import StoreProductSearch from "../common/StoreProductSearch";
import ShareLink from "../../../../common/ui-components/links/sharelink/ShareLink";

const StoreCategoryProducts: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {

  const parameters:any = useParams();
  const history = useHistory()

  const[subCategories,setSubCatgories] = useState([])
  const [currSelectedCategory, setCurrSelectedCategory] = useState<string>(parameters.category);
  const[currSelectedSubCategory,setCurrSelectedSubCategory] = useState("")

  const [allProductsList,setAllProductList]: any = useState([]);
  const [productsList,setProductsList]: any = useState(allProductsList);
  const [loadLimit, setLoadLimit] = useState(10);

  const [isCustomizableProducts,setIsCustomizableProduct] = useState(false);

  const[isOpenSearch,setIsOpenSearch] = useState(false)


  useEffect(()=>{
    if(applicationConfig?.data?.productsList){
      let products = getFilteredProductForDemo()
      products.sort((a:any,b:any)=>a.priority>b.priority?-1:1)
      let recentProduct = getItemFromLocalStorage("recentCustomizeProduct")
      if(recentProduct){
       let product = getObjectByParameter(products,"product_id",recentProduct.product_id)
       if(product){
         let otherProducts = products.filter(currProduct=> currProduct.product_id !== product.product_id)
         setAllProductList([product,...otherProducts])
         return
       }
      }
      setAllProductList(products)
    }
  },[props.configObj,props.isLogin])

  useEffect(()=>{
    let windowWidth = getWindowWidth()
    if(isTouchDevice() && (windowWidth >= 668 && windowWidth <= 2160)){
      setTimeout(() => {
        $("#imageMediumButton").click()
      }, 500);
    }
  },[])


  useEffect(()=>{
    if(props.configObj && props.isLogin){
      const categoryName = parameters.category
      setCurrSelectedCategory(categoryName)
      const filteredSubCat = getFilteredArray(applicationConfig?.data?.subCategories,"category_name",categoryName)
      setSubCatgories(getArrayOfDistinctValues(filteredSubCat,"sub_category_name"))
      // setCurrSelectedSubCategory(getDefaultSubCategory(categoryName))
    }
  },[parameters.category,history])



  useEffect(()=>{
    if(currSelectedCategory!="" && currSelectedSubCategory!= ""){
      setLoadLimit(10)
      let filteredProductsList = allProductsList?.filter(product=>(stringWithoutWhiteSpace(product.category_name)===stringWithoutWhiteSpace(currSelectedCategory)) && product.product_type != "custom");
      // filteredProductsList = filteredProductsList?.slice(0,20)
      if(currSelectedSubCategory?.toLowerCase() === "all"){
        setProductsList(filteredProductsList)
        return
      }
      filteredProductsList = allProductsList?.filter(product=>(product.category_name===currSelectedCategory&&product.sub_category_name===currSelectedSubCategory ) && product.product_type != "custom");
      setProductsList(filteredProductsList)
    }
  },[currSelectedCategory,currSelectedSubCategory,allProductsList])


  function updateProductList(products:any) {
    let recentProduct = getItemFromLocalStorage("recentCustomizeProduct")
    if(recentProduct){
     let product = getObjectByParameter(products,"product_id",recentProduct.product_id)
     if(product){
        let otherProducts = products.filter(currProduct=> currProduct.product_id !== product.product_id).sort((a:any,b:any)=>a.priority>b.priority?-1:1)
        setAllProductList([product,...otherProducts])
        // setCurrSelectedSubCategory("All")
       return
     }
    }
    setAllProductList(products.sort((a:any,b:any)=>a.priority>b.priority?-1:1))
    // setCurrSelectedSubCategory("All")
  }

  // const[subCategories,setSubCategories] = useState([])

  // useEffect(()=>{
  //   let currSubCategories = getFilteredArray(productsList,"category_name",currSelectedCategory);
  //   setSubCategories(currSubCategories)
  // },[currSelectedCategory,productsList])

 
  function SubCategoriesScroll() {
    return(
        <>
            <div className={currSelectedSubCategory==="All"?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrSelectedSubCategory("All")}>{"All"}</div>
            </div>
          {subCategories.map((currValue:any,index:number)=>{
            return(
              <div className={currSelectedSubCategory===currValue?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrSelectedSubCategory(currValue)}>{currValue}</div>
              </div>
            )
          })}
        </>
    )
  }

 
  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white">
        <SubscriptionAlert/>
        <MainHeader/>
        <SubHeader/>

        
        <StoreProductSearch isOpen={isOpenSearch} setIsOpen={setIsOpenSearch} />

        <div className="main-content-wrapper position-relative hide-scrollbar light-background padding10">
            <div className=" background-white position-relative overflow-hidden full-height-width">
              <div className="display-flex-row main-cards-filters-wrapper no-wrap ion-padding full-height-width" >
                <div className="main-sidebar-filters-wrapper shadow-medium white-card padding10">

                <div className="hide-in-mobile">
                  {!getUser()?.shareItemDetails?
                    <Button2
                      configObj={{}}
                      action={()=>history.push("/product-categories")}
                      label={"Back to categories"}
                      fontAwesomeIcon={"fas fa-chevron-left color-primary"}
                      iconPos="left"
                      classNames={"shadow-light margin0 font-medium full-width bg-color-black"}
                    />
                  :null}
                  
                  <div className="full-width marginTop10">
                    <SelectStoreFront
                      updateProductList={updateProductList}
                    />
                  </div>

                  <div className="bg-color-light heading3 padding5 marginTop10">
                    {currSelectedCategory}
                  </div>

                  
                
                </div>
                
                
                  <ProductFilters
                    setCurrSelectedSubCategory = {setCurrSelectedSubCategory}
                    setProductsList = {setProductsList}
                    currSelectedCategory={currSelectedCategory}
                    currSelectedSubCategory={currSelectedSubCategory}
                    productsList={allProductsList}
                  />
                </div>

                <div className="main-cards-wrapper padding10 position-relative display-flex-column no-wrap overflow-hidden full-height shadow-light white-card">
                
                <div className="sort-items-container position-relative">

                  


                  <div className="storefront-in-header">

                  

                    

                    <div className="display-flex-row space-between">

                    {!getUser()?.shareItemDetails?
                      <>
                      <div className="ion-margin-end">
                      <Button2
                          configObj={{id:""}}
                          action={()=>{history.push("/products")}}
                          label={""}
                          fontAwesomeIcon={"fas fa-arrow-left color-black"}
                          iconPos="left"
                          classNames={"shadow-medium margin0"}
                        />
                      </div>
                       <SelectStoreFront
                          updateProductList={updateProductList}
                          productsList={allProductsList}
                      />
                      <div className="ion-margin-start">
                        <Button2
                          configObj={{id:""}}
                          action={()=>{setIsOpenSearch(true)}}
                          label={""}
                          fontAwesomeIcon={"fas fa-search color-black"}
                          iconPos="left"
                          classNames={"shadow-medium margin0"}
                        />
                      </div>
                      </>
                    :null}
                    </div>
                    
                  </div>

                  <div className="full-width display-flex-row position-relative space-between no-wrap">
                    
                    <div className="padding5">
                      <div className="heading2 font-large">{parameters.category} / {getSubCategoryDisplayName(currSelectedSubCategory)}</div>
                      <div className="heading4 font-normal color-black">Showing {productsList?.length} products of {productsList?.length} products</div>
                    </div>

                    {currSelectedCategory === "Sofa"?
                    <div className="middle hide-in-mobile">
                      <div onClick={()=>{setIsCustomizableProduct(!isCustomizableProducts)}} className={isCustomizableProducts?"bg-color-primary color-white pointer":"border pointer"} style={{padding:"0.5rem 0.9rem",borderRadius:"20px",whiteSpace:"nowrap"}}>
                        <div className='heading4 text-center color-black'>Customizable Legs</div>
                      </div>
                    </div>
                    :null}

                    

                 



                    <div className="center-aligned-column marginRight10">
                      <div className="display-flex-row no-wrap">

                        

                        <div className="display-flex-row no-wrap">

                        <div className="hide-in-mobile">
                          <Button2
                            configObj={{id:""}}
                            action={()=>{setIsOpenSearch(true)}}
                            label={"Search"}
                            fontAwesomeIcon={"fas fa-search color-black"}
                            iconPos="left"
                            classNames={"shadow-medium no-border button-small margin0"}
                          />
                        </div>

                        {!getUser().isShareMode && applicationConfig?.isMasterLogin && applicationConfig.clientName === "FurnitureHouse"?
                        <div className="">
                          <ShareLink
                            shareItemId={0}
                            shareItemDetails = {
                              { 
                                categoryName:currSelectedCategory,
                                subCategoryName:currSelectedSubCategory
                              }
                            }
                            loaderId = {"loaderId"}
                            moduleName="products"
                            textClass="color-black"
                            isShareButton={true}
                            Content={()=>(
                              <Button2
                                configObj={{}}
                                action={()=>{}}
                                label={""}
                                fontAwesomeIcon={"fas fa-share color-primary"}
                                iconPos="right"
                                classNames={"button-small hide-button-text-inle bg-color-primary"}
                              />
                            )}
                          />
                        </div>
                        
                        :null}

                        
                          

                        {/* <div className="display-flex-row no-wrap pointer icon-wrapper" onClick={()=>{setIsOpenSearch(true)}}>
                          <span className="center-aligned-column pointer-none position-relative "><i className="fas fa-search color-warning font-large"></i>
                          </span>
                        </div> */}

                          <div className="display-flex-row marginleft5 button-group-container no-wrap">
                            <div id="imageSmallButton" data-value="image-small" className="center-aligned-column button --is-active product-card-size-button" onClick={ChangeProductCardSize}> <i className="fas fa-th-large icon font-ex-small"></i> </div>
                            <div id="imageMediumButton" data-value="image-medium" className="center-aligned-column button   product-card-size-button" onClick={ChangeProductCardSize}> <i className="fas fa-th-large icon font-small"></i> </div>
                            <div data-value="image-large" className="center-aligned-column button product-card-size-button" onClick={ChangeProductCardSize}> <i className="fas fa-th-large icon font-normal"></i> </div>
                          </div>


                        </div>
                       

                      


                      </div>
                    
                    </div>

                  </div>
                </div>


                <div className="marginTop5 marginBottom5 sub-categories-container-potrait">
                    <HorizontalScroll
                      itemsClass="sub-categories-container"
                      Component={SubCategoriesScroll}   
                      itemsList={subCategories}
                      id={`subCategoryMobilesScrollBar`}     
                    />
                </div>

                {productsList?.length?
                <>
                {isCustomizableProducts?
                  <ProductsList productsList={productsList.filter(product => product.customizable_legs)}/>
                :
                  <ProductsList productsList={productsList}/>
                }
                </>
                :
                  <div className="top-left full-height-width center-aligned-column pointer-none">
                    <div className="heading4 font-normal">No products found</div>
                  </div>
                }

                

                </div>
              </div>
            </div>
        </div>
      </div>
      :
      <SessionExpired/>
      }
    </IonPage>
  );
};

export default StoreCategoryProducts;
