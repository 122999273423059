import { IonSpinner } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { loadConfigFromConfigName, projectConfiguration, releaseMemoryCustomizer } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { createRenderLogs, getProjectSavedConfigs } from "../../../../../../utils/customizer/customizer";
import { applicationConfig } from "../../../../../../utils/methods";

const RendersList: React.FC<{
  setIsRendersOpen:(val:boolean)=>void
  isRendersOpen:boolean
}> = (props) => {

   const[rendersList,setRendersList] = useState([])
   const[isShowLogs,setIsShowLogs] = useState(false)
   const[isCreatingRender,setIsCreatingRender] = useState(false)
   const[isRenderComplete,setIsRenderComplete] = useState(false)
   const[showLogLabel,setShowLogLabel] = useState("View logs")
   const[logs,setLogs] = useState([])

   var history = useHistory()

   useEffect(()=>{
    // setRendersList(getProjectSavedConfigs())

    setIsCreatingRender(projectConfiguration.isCreatingRender)

    // setLogs(createRenderLogs)
    // timeInterval = setInterval(updateLogs,500)

    // applicationConfig.setFunctionRef("customizer",{setIsCreatingRender:setIsCreatingRender})
    applicationConfig.setFunctionRef("customizer",{setLogs:setLogs})
    // applicationConfig.setFunctionRef("customizer",{setIsRenderComplete:setIsRenderComplete})
   },[projectConfiguration])

   useEffect(()=>{
    let renders = getProjectSavedConfigs()
    console.log(applicationConfig?.data?.customizerSavedConfigs)
    if(renders.length){
      renders.sort((a:any,b:any)=>a.updated_on>b.updated_on?-1:1)
      setRendersList(renders)
    }
   
   },[isRenderComplete,props.isRendersOpen])

   function updateLogs() {
    setLogs(createRenderLogs)
    }

   function toggleShowLogs(){
    if(isShowLogs){
      setIsShowLogs(false)
      setShowLogLabel("View logs")
      return
    }
    setIsShowLogs(true)
    setShowLogLabel("Hide logs")
   }

   function gotoVirtualTour(configId:any) {
    releaseMemoryCustomizer()
    props.setIsRendersOpen(false)
    applicationConfig.setCurrArea("Foyer")
    if(configId != projectConfiguration.configId){
      projectConfiguration.setConfigId(configId)
      projectConfiguration.createConfigFromConfigId(configId)
    }
    // history.replace("/customize",null);
    window.location.href = "/3dwalkthrough"
    // history.push("/3dwalkthrough");
    }

    function gotoCustomizer(configId:number) {
      props.setIsRendersOpen(false)
      loadConfigFromConfigName(configId)
    }

  return (
    <div className="full-height-width overflow-y-scroll">
      <div className="renders-list display-flex-row">
        {rendersList.length || isCreatingRender?
          <table className="table">
            <thead>
              <tr>
                <th>Config name</th>
                <th>Last updated</th>
                {/* <th>Created at</th> */}
                {/* <th>Status</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>


            {isCreatingRender?
            <tr>
              <td > <div className="center-aligned-column full-height heading4">{projectConfiguration.configName}</div> </td>
              <td ><div className="center-aligned-column full-height heading4">-</div></td>
              {/* <td ><div className="center-aligned-column full-height">
                {!isRenderComplete?
                <IonSpinner name="crescent"/>
                :<span className="success-badge">Completed</span>
                }
              </div>
              </td> */}
              <td className="center-aligned-row">
                    <div onClick={toggleShowLogs} className="render-action pointer heading4 white-card shadow-light bg-color-light color-black">{showLogLabel}</div>
              </td>
            </tr>
            :null}

            {isShowLogs?
            <tr className="overflow-y-scroll">
              <td colSpan={4} className="full-width">
                <ul id="renderLogs" className="render-logs">
                  {logs.map((log,j)=>{
                    return(
                      <li key={j}>{log}</li>
                    )
                  })}
                </ul>
              </td>
            </tr>
            :null}

              {rendersList?.map((render:any,index:number)=>{
                var d = new Date(Number(render.updated_on))
              return(
              <tr key={index} className="" style={{padding:"10px 0"}}>
                  <td ><div className="center-aligned-column position-relative full-height "> <span className="display-flex-row heading4">{render.title}<i className="fas fa-info-circle center-aligned-column pointer color-dark marginleft5 font-ex-small"></i></span>  
                  {render.id === projectConfiguration?.configId?
                    <span className="left-middle display-flex-row">
                      {isCreatingRender?
                        <IonSpinner name="crescent"/>
                        // <span className="success-badge">current</span>
                        :<span className="success-badge">current</span>
                      }
                    </span> 
                  :null}
                  </div></td>
                  <td ><div className="center-aligned-column full-height heading4">{d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear() + " " +  d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds()}</div></td>
                  {/* <td ><div className="center-aligned-column full-height heading4">{d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear()}</div> </td> */}
                  {/* <td ><div className="center-aligned-column full-height heading4">{d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear()}</div> </td> */}
                  {/* <td ><div className="center-aligned-column full-height"> 
                    {render.is_created_render?
                    <span className="success-badge">Completed</span> 
                    :
                    <span className="pending-badge">Pending</span> 
                    } 
                  </div></td> */}
                  <td className="center-aligned-row">
                      {render.is_created_render?
                       <div onClick={()=>gotoVirtualTour(render.id)} className={`render-action heading4 white-card shadow-light bg-color-primary color-white pointer ${isCreatingRender?" disable":""}`}>Walkthrough</div>
                      :null} 
                       <div onClick={()=>gotoCustomizer(render.id)} className="render-action heading4 white-card shadow-light marginleft10 bg-color-dark color-white pointer">Load config</div>
                  </td>
                  
              </tr>
              )
              })}
        
            </tbody>
          </table>
          :
          <div className="center-aligned-column full-width">
            <div className="heading4 ion-padding text-center full-width" style={{border:"1px dashed lightgray",borderRadius:"10px"}}>Renders not available</div>
          </div>
          }
    </div>
    </div>
  );
};

export default RendersList;
