import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import Button2 from "../../../../../../../../common/ui-components/buttons/Button2";
import CreateLead from "../../createlead/CreateLead";
import SearchLeads from "./Search";
import TodaySummary from "./TodaySummary";
import ToggleView from "./ToggleView";
 
const Header: React.FC<{
  refreshLeads:()=>void
  setSearchedLeadsList:(values:any)=>void
  setIsShowCardView:(value:boolean)=>void
}> = (props) => {

  const[isOpen,setIsOpen] = useState(false)
  const[isOpenSummary,setIsOpenSummary] = useState(false)


  
  function LeftContent() {
    return(
      <div className="display-flex-row">

      <div className="center-aligned-column">
        <Button2
          configObj={{id:""}}
          action={()=>{setIsOpen(true)}}
          label={"Create Lead"}
          fontAwesomeIcon={"fas fa-edit color-dark"}
          iconPos="left"
          classNames={"no-shadow margin0 bg-color-primary"}
        />
      </div>

      {/* <div className="center-aligned-column marginleft10">
        <Button2
          configObj={{id:""}}
          action={()=>{}}
          label={"View by & Sort by"}
          fontAwesomeIcon={"fas fa-chevron-down color-dark"}
          iconPos="right"
          classNames={"no-shadow"}
        />
      </div>

      <div className="center-aligned-column marginleft5">
        <Button2
          configObj={{id:""}}
          action={()=>{}}
          label={"Sync"}
          fontAwesomeIcon={"fas fa-sync color-dark"}
          iconPos="left"
          classNames={"no-shadow"}
        />
      </div> */}
      </div>
    )
  }




  return (
    <div className="full-width position-relative display-flex-row space-between border-bottom leads-header" style={{padding:"10px 0"}}>

      <CreateLead refreshLeads={props.refreshLeads} isOpen={isOpen} setIsOpen={setIsOpen}/>
      
      <IonModal
        id=""
        cssClass="small-y-modal"
        onDidDismiss={()=>setIsOpenSummary(false)}
        isOpen={isOpenSummary}
        >
      </IonModal>
        
        <LeftContent/>

        <div className="middle hide-in-mobile">
          <ToggleView setIsShowCalendar={props.setIsShowCardView}/>
        </div>

        <div className="right-content">
          <div className="display-flex-row no-wrap">
            <TodaySummary/>
            <SearchLeads setSearchedLeadsList={props.setSearchedLeadsList}/>
          </div>
        </div>

       
        

      </div>  
  );
};

export default Header;
