import React, { useState } from "react";
import { Customer } from "../../../../../../../../utils/store/storeConfiguration";
import Alert from "../../../../../../../common/ui-components/alerts/Alert";
import CityInput from "../../../../../../../common/ui-components/input/CityInput";
import Input from "../../../../../../../common/ui-components/input/Input";
import Select from "../../../../../../../common/ui-components/input/Select";
import MultipleFileUpload from "../../../../../../../common/ui-components/forms/MultipleFileUpload";
 


 
const Form: React.FC<{
    customer:Customer
    alertId:string
    setSalesOrderImage:(value:any)=>void
    ticket?:any
}> = (props) => {

    const[issueTypes,setIssueTypes] = useState(["Upholstery problems","Problem with Finishing of Product","Stitching Issues","Wrong product","Product not as per customization request","Issue with Legs","Other issues"])
    const[ticketTypes] = useState(["Issue with delivered product","Installation","Update on order"])

    function setIssueTypeFromTicketType(ticketType:any){
        if(ticketType === "Update on order"){
            setIssueTypes(["Update on order"])
        }else if(ticketType === "Installation"){
            setIssueTypes(["Installation"])
        }else{
          setIssueTypes(["Upholstery problems","Problem with Finishing of Product","Stitching Issues","Wrong product","Product not as per customization request","Issue with Legs","Other issues"])
        }
    }


    

  return (

    <div className={`create-lead-form bg-color-light full-width overflow-y-scroll padding10 full-height`}>
        <Alert id={props.alertId}/>
        <div className={`full-width`}>
            <Input
                type="text"
                id="ticketCustomerName"
                label="Customer name & mobile"
                isMandatory={true}
                onChange={()=>{}}
                value={`${props.customer?.customerName} , ${props.customer?.mobile}`}
                className="disable"
            />
        </div>

        <div className={`full-width display-flex-row no-wrap`}>
            <div className={`${props.ticket?" full-width":"width50"}`}>
                <Input
                    type="text"
                    id="ticketSoNumber"
                    label="Sales Order Number"
                    isMandatory={false}
                    onChange={()=>{}}
                    className={""}
                    value={props.ticket?.so_number || ""}
                />
            </div>
            
            {!props.ticket?
            <div className={`width50`}>
                <MultipleFileUpload 
                    heading="Sales Order Receipt"
                    setFiles={props.setSalesOrderImage}
                    acceptExtensions = ".png,.jpeg,.webp"
                    multiple = {false}
                    isMandatory={false}
                    isInputView={true}
                />
            </div>
            :null}
            
        </div>

        <div className={`full-width display-flex-row no-wrap`}>
            {!props.ticket?
                <div className="width50">
                <Select
                    id="ticketType"
                    label="Ticket Type"
                    isMandatory={true}
                    onChange={setIssueTypeFromTicketType}
                    options={ticketTypes}
                    className={props.ticket?"disable":""}
                    value={props.ticket?.issue_type || ""}
                />
                </div>
            :null}
                
            <div className={`${props.ticket?" full-width":"width50"}`}>
                <Select
                    id="issueType"
                    label="Issue Type"
                    isMandatory={true}
                    onChange={()=>{}}
                    options={issueTypes}
                    className={props.ticket?"disable":""}
                    value={props.ticket?.issue_type || issueTypes[0]}
                />
            </div>
            
        </div>

        {/* <div className={`full-width`}>
            <SelectInput
                id="ticketAssignedTo"
                label="Assigned to"
                isMandatory={true}
                onChange={()=>{}}
                options={users}
                className=""
                value={null}
                config={{value:"user_id",displayName:"username"}}
            />
        </div> */}

        
        <div className="full-width">
            <Select
                id="ticketPriority"
                label="Priority"
                isMandatory={true}
                onChange={()=>{}}
                options={["High","Medium","Low"]}
                className=""
                value={""}
            />
        </div>

        <div className={`full-width`}>
            <CityInput
                
            />
        </div>
        


        <div className="form-label-input-container">
            <div className="label-container ">
                <div className="label">
                    Description<span className="mandatory">*</span>
                </div>
            </div>
            <div className="input-container">
                <textarea defaultValue={props.ticket?.description || ""} className={`input ${props.ticket?"":""}`} id="ticketDescription" style={{height:"75px"}} />
            </div>
        </div>

        {props.ticket?
            <div className="form-label-input-container">
            <div className="label-container ">
                <div className="label">
                    Remarks
                </div>
            </div>
            <div className="input-container">
                <textarea className="input" id="ticketRemarks" style={{height:"75px"}} />
            </div>
            </div>
        :null}

        
      
    </div>
  );
};

export default Form;
