import $ from "jquery";
import React, { useState } from "react";
import { STORE } from "../../../../utils/store/storeConfiguration";
import ProductImage from "../../../common/ui-components/elements/ProductImage";
 

const Gallery: React.FC<{
  product:any
  toggleProductViewer:(val:boolean)=>void
  disable3dMode:(index:number)=>void
  // currGalleryIndex:number
  isViewIn3d:boolean
}> = (props) => {



  const[gallery] = useState([
    {
      id:1
    },
    {
      id:2
    },
    {
      id:3
    }
  ])

  function updateGallery(index:number) {
    STORE.galleryIndex = index
    props.disable3dMode(index)
    STORE.updateProductImage("elevateProductImage",STORE.currProduct)
  }
  

  function updateSelection(event,callBack:()=>void) {
    $(".gallery-item").removeClass("--is-active")
    $(event.target).addClass("--is-active")
    callBack()
  }
 
  return (
    <div className="padding10 gallery">
    <div onClick={(event)=>updateSelection(event,()=>{updateGallery(0)})} className={`item gallery-item --is-active`} style={{width:"5rem",height:"5rem"}}> 
      <ProductImage product={props.product}/>
    </div>
    <div onClick={(event)=>updateSelection(event,()=>{props.toggleProductViewer(!props.isViewIn3d)})} className={`padding5 item gallery-item`} style={{width:"5rem",height:"5rem"}}> 
      <img src="assets/icon/virtualtour.png" className="width80 pointer-none" alt="" />
    </div>
    {gallery.map((currItem,index)=> {
      return(
        <div onClick={(event)=>updateSelection(event,()=>{updateGallery(currItem.id)})} key={index} className={`item gallery-item`} style={{width:"5rem",height:"5rem"}}>
          {/* <ProductGalleryImage index={index+1} product={props.product}/> */}
          <img className={`product-image pointer-none elevateProductImage_${currItem.id}`} alt={props.product?.product_name} />
        </div>
      )
    })}
    </div>
  );
};

export default Gallery;
