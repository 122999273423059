import { saveLogs } from "../../services/api"
import { applicationConfig, getSubDomainName } from "../methods"

export class Logging{

    logs = []
    username:string = ""
    sessionId:string = ""
    loginSessionId:string = ""
    sessionDatabaseId:number = 0
    

    constructor(username:string = "",sessionId:string=""){
        this.username = username
        this.sessionId = sessionId
        this.loginSessionId = "kjbasd5d4867d"

        this.saveLogs()
    }

    info(moduleName:string,logCode:string){
        let username = applicationConfig?.user?.username?.toLowerCase()
        if(username){
            if(!username.includes("kartik") && !username.includes("testuser") && 
            !username.includes("shreedhar") && !username.includes("akn") && 
            !username.includes("demo")
            ){
                let data = this.createNewLogData(moduleName,logCode)
                this.logs.push(data)
            }
        }
    }

    error(moduleName:string,actionType:string,message:string){
        let data = this.createNewLogData(moduleName,actionType)
        data.eventType = "error"
        data.message = message
        this.logs.push(data)
    }

    createNewLogData(moduleName:string,logCode:string){
        let data:any = {}
        data.sessionId = this.sessionDatabaseId
        data.moduleName = getSubDomainName() || moduleName
        data.timestamp = Number(new Date())
        data.logCode = logCode
        return data
    }

    getLogCode(moduleName:string,actionType:string){
        return moduleName+"_"+actionType
    }


    saveLogs(){
        // return
        setInterval(()=>{
            if(this.logs.length){
                saveLogs(this.logs)
                .then((response: any) => {
                    this.resetLogs()
                })
                .catch((error: any) => {
                    this.resetLogs()
                })
            }
        },5 * 1000)
    }

    setSessionDatabaseId(id:number){
        this.sessionDatabaseId = id
    }

    resetLogs(){
        this.logs = []
    }

}

 