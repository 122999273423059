import React,{useState,useEffect, useImperativeHandle, forwardRef} from 'react';
import $ from "jquery"
import {IonIcon, IonSpinner, IonToast} from "@ionic/react";
import {updateProductModelSrc} from "../../utils/modelviewer/modelviewer"

import "../style/modelviewer/product3dmodel.css"
import { hideProductModel,getModelViewerElement, getProductModelContainerElement } from '../../utils/UI_methods/modelviewer';
import { showCanvasLoader,hideCanvasLoader } from '../../utils/UI_methods/canvas';


const View3DModel:React.FC<{
    ref:any
}> = forwardRef((props,ref) => {

    const[product,setProduct] = useState<any>({})
    const[productSrc,setProductSrc] = useState<any>("https://s3.ap-south-1.amazonaws.com/demo.io/Pepper.glb")
    const[selectedObject,setSelectedObject] = useState("all")

    const[isLoaded,setIsLoaded] = useState(false)
    const[isCameraControls,setIsCameraControls] = useState(false)

    useEffect(()=>{
        setTimeout(() => {
            console.log(productSrc)
            let container =  getProductModelContainerElement()
            if(container){
                let element = $(`<model-viewer id='productModal' ar ar-modes="scene-viewer quick-look" disable-pan camera-controls class='product-model'></model-viewer>`)
                $("#productModal").remove()
                // element.append(button1)
                container.append(element)
                $('.canvas-loader').fadeOut()
                setTimeout(() => {
                    loadModel(productSrc)
                }, 500);
            }
            
        }, 1000);
    },[productSrc])

    // useEffect(()=>{
        
    // },[,isLoaded])

    function loadModel(src:string) {
        let MODEL_VIEWER:any = getModelViewerElement()
        if(MODEL_VIEWER){
            updateProductModelSrc(src,src)

            //If model is already loaded
            if(MODEL_VIEWER.model){
                showCanvasLoader()
                // applyCurrentConfiguration(props.currentConfigObject,product.product_name,MODEL_VIEWER)
                hideCanvasLoader()
            }
            MODEL_VIEWER?.addEventListener("load", async (event:any) => {
                showCanvasLoader()
                // addEventListeners(MODEL_VIEWER,setSelectedObject)
                // await applyCurrentConfiguration(props.currentConfigObject,product.product_name,MODEL_VIEWER)
                hideCanvasLoader()
            })
          
        } 
    }

    useImperativeHandle(ref, () => ({
            updateProductInfo(product:any){
                setProduct(product)
            },
            setProductModelSrc(src:string){
                setProductSrc(src)
                setIsLoaded(!isLoaded)
            }
        }
    ));
 
  

    return(
        // <div className="custom-row model-controls-wrapper">
        <div id="product3DmodelContainer" className="border --is-active">
          
           {/* <div className="canvas-loader">
            <IonSpinner name="dots" className="canvas-loader-icon" />
            </div> */}
            <div className="top-left model-product-info">
                <div className="product-name">
                   <span className="heading"></span><span>Product Name</span> 
                </div>
            </div>

            <div className="top-right" style={{zIndex:2}} onClick={(event)=>hideProductModel(event)}>
                <i className="fa fa-times" area-hidden="true"></i>
            </div>
            <div className="canvas-icon-bar right-bottom">
          
        </div>
        </div>
    );
});

export default View3DModel;
