import $ from "jquery";
import React, { useEffect, useState } from "react";
import { getWindowWidth } from "../../../../../utils/UI_methods/global";
import { rippleEffectOnClick } from "../../../../../utils/UI_methods/ui_effects";
import { enableConfigureMode } from "../../../../../utils/customizein3d/modules/UI_methods";
import { getArrayOfDistinctValues } from "../../../../../utils/methods";
import { isPotraitMode } from "../../../../../utils/customizein3d/common";

const MobileTabs: React.FC<{
  configObj:any
  tabItems:Array<any>
  isSpacePlannerMode:boolean
  setIsSpacePlannerMode:(value:boolean)=>void
}> = (props) => {

  const[currSelectedTab,setCurrSelectedTab] = useState("addModule")
  var pos = 0
  
  useEffect(() => {
    if(isPotraitMode()){
      if (props.isSpacePlannerMode) {
        enableConfigureMode(false)
        setCurrSelectedTab("roomLayout")
        updateTabValue("roomLayoutContainer","roomLayout")
      } else {
        if(props.tabItems.length){
          let currTab = props.tabItems[0]
          setCurrSelectedTab(currTab.tabId)
          updateTabValue(currTab.tabName,currTab.tabId)
        }
      }
      props.configObj.setFunctionRef("customizein3d",{updateTabValueMobile:updateTabValue})
    }
   
  }, [props.isSpacePlannerMode,props.configObj]);


  function updateTabValue(tabContentId: any, tabId: string) {
    $(".tab-content").removeClass("--is-active");
    $("#" + tabContentId).addClass("--is-active");

    setCurrSelectedTab(tabId)

    //Load texture when finish tab is active
    // if (tabContentId === "finishContainer") {
    //   props.setTextures(props.textures);
    // }

    if (tabId === "roomLayout" && !props.isSpacePlannerMode) {
        props.setIsSpacePlannerMode(true);
    }
  }
  
  return (
    <div className="controls-tabs-mobile hide-in-desktop">
      <div className="controls-tab-container position-relative main-tab-container border " style={{padding:"0"}}>
         
          {props.tabItems.map((item: any, index: number) => {
            let classNames = [];
            classNames.push("controls-tab border-bottom overflow-hidden position-relative " + item.tabId);
            if (currSelectedTab === item.tabId) {
              classNames.push("--is-active");

              let tabItemsArray = getArrayOfDistinctValues(props.tabItems,"tabId")

              if(tabItemsArray.indexOf(currSelectedTab) != 0){
                pos = 100 * (tabItemsArray.indexOf(currSelectedTab) / tabItemsArray.length)
                pos = Math.abs(pos)
              }else{
                pos = 0
              }

            }
            return (
              <div id={item.tabId}
                className={classNames.join(" ")}
                key={index}
                onPointerDown={rippleEffectOnClick}
                onClick={(event) => {
                  updateTabValue(item.tabName, item.tabId);
                }}>
                <div className="text pointer-none heading">{item.tabLabel}</div>
              </div>
            );
          })}

    
        
        </div>

        <div className="controls-tab-active-bar">
        <div className="controls-bar-fill top-left"  style={{left:`${pos}%`,width:`calc(100% / ${props.tabItems.length})` ,padding:"0"}}></div>
      </div>
    </div>
  );
};

export default MobileTabs;
