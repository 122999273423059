import React, { useEffect, useState } from "react";
import { STORE } from "../../../../../../utils/store/storeConfiguration";
import MaterialImage from "../../../../../common/ui-components/elements/MaterialImage";
 

const SelectedMaterialsList: React.FC<{
    selectedOptionIds:any
}> = (props) => {

 
  const[selectedMaterialsList,setSelectedMaterialsList]:any = useState([])
 
  useEffect(()=>{
    if(props.selectedOptionIds.length){
      setSelectedMaterialsList(STORE.data.materialList.filter(currMaterial => props.selectedOptionIds.includes(currMaterial.id)))
    }
  },[props.selectedOptionIds])


  return (
    <div className="left-middle padding0 zindex100" style={{left:"100%",width:"max-content"}}>
      <div className="">
        <div className="heading3 font-md-small text-center">{selectedMaterialsList.length} Materials selected</div>
        {/* {selectedMaterialsList.map((currMaterial:any,index:number)=>{
          return(
            <div className="display-flex-row no-wrap padding5" key={index}>
              <span className="heading3 font-small marginRight5 center-aligned-column">{index+1} . </span>
              <span style={{width:"1.5rem"}}><MaterialImage material={currMaterial}/></span>
              <span className="heading4 font-small marginleft5 center-aligned-column">{currMaterial.material_code}</span>
            </div>
          )
        })} */}
      </div>
    </div>
  );
};

export default SelectedMaterialsList;
