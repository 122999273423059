import $ from "jquery";
import React, { useState } from "react";
import { hideComponentLoader, showAlert, showComponentLoader, showGlobalToast } from "../../../../../../../../utils/UI_methods/global";
import { getObjectByParameter, waitFor } from "../../../../../../../../utils/methods";
import { getTodayTimestampOnlyDate } from "../../../../../../../../utils/store/crm/calendar";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import { Customer } from "../../../../../../../../utils/store/storeConfiguration";
import Modal from "../../../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import CustomerSessionWindow from "../../../../customer/CustomerSessionWindow";
import Form from "./Form";
 


 
const CreateLead: React.FC<{
    isOpen:boolean
    setIsOpen:(val:boolean)=>void
    refreshLeads:()=>void
}> = (props) => {

    const[alertId] = useState("createLeadAlertId")

    const[isOpenCreateLead,setIsOpenCreateLead] = useState(false)
    const[customerId,setCustomerId] = useState(null)
    const[customer,setCustomer] = useState(null)
    const[filteredCustomers,setFilteredCustomer] = useState([])

    

    function Footer() {
        return(
            <div className="display-flex-row">
                <Button2
                    configObj={{id:""}}
                    action={submitHandle}
                    label={"Save"}
                    fontAwesomeIcon={"fas fa-times color-black"}
                    iconPos=""
                    classNames={"no-shadow bg-color-primary"}
                />

                <Button2
                    configObj={{id:""}}
                    action={()=>{setIsOpenCreateLead(false)}}
                    label={"Go back"}
                    fontAwesomeIcon={"fas fa-times color-black"}
                    iconPos=""
                    classNames={"no-shadow bg-color-light"}
                />
            </div>
        )
    }

  

    async function submitHandle() {
        const formData = {
            customerId:Number(customer.id),
            leadSource:$("#leadSource").val(),
            city:$("#cityName").val(),
            assignedTo:Number($("#leadAssignedTo").val()),
            salesStage:$("#salesStage").val(),
            potentialValue:$("#potentialValue").val(),
            sizeOfDeal:$("#sizeOfDeal").val(),
            productCategory:$("#productCategory").val(),
            salesStageId:getObjectByParameter(CRM.data.salesStages,"sales_stage","Prospect").id,
            dateOfCreation:getTodayTimestampOnlyDate(),
            isDirectVisit:false
        }

        if(formData.customerId && formData.city){
          showComponentLoader("storeLoader")
          CRM.crmApi.leads.createLead(formData,formData.assignedTo).then(async (data:any)=>{
            console.log(data)
            if(!data.data.error){
                setIsOpenCreateLead(false)
                props.refreshLeads()
                await waitFor(800)
                hideComponentLoader("storeLoader")
                showGlobalToast("Lead created succesfully",3000)
            //   postCustomerCreateAction(customer)
            }else{
              showGlobalToast("Internal server error",3000,"error")
              hideComponentLoader("storeLoader")
            }
          })
        }else{
          showAlert(alertId,"Please enter mandatory fields","error")
          hideComponentLoader("storeLoader")
        }
      
      }


  
          
    function setCustomerHandle(customer:Customer) {
      // $("#customerNameCreateLead").val(`${customer.customerName}, ${customer.mobile}`)
      // $("#customerId").val(`${customer.id}`)
      setCustomer(customer)
      setFilteredCustomer([])
      setIsOpenCreateLead(true)
      props.setIsOpen(false)
    }


 
  return (

    <>

    <Modal
        id=""
        classNames="small-y-modal"
        onDismiss={()=>props.setIsOpen(false)}
        isOpen={props.isOpen}
        Content={()=>(
            <CustomerSessionWindow closeModal={()=>{}} isStartSessionMode={false} createCustomerCallback={setCustomerHandle} />
        )}
        heading="Start Customer Session"
        FooterContent={()=>(<div></div>)}
        >
    </Modal>

    <Modal
        isOpen = {isOpenCreateLead}
        Content = {()=>(
          <>
              <Form customer={customer} alertId={alertId} isUpdate={false}/>
          </>
        )}
        onDismiss = {()=>setIsOpenCreateLead(false)}
        heading={"Create Lead"}
        classNames="medium-x-modal"
        id=""
        FooterContent={Footer}
    />
    </>
  );
};

export default CreateLead;
