import $ from "jquery";
import { Box3, Group, MathUtils, Quaternion, Vector3 } from "three";
import { attachModules, detachModule } from "../common";
 
import { getIntersectsObjectsInAreaModel } from "../area3dmodel/area3dModel";
import { isChangingControls } from "../modules/helper";
import { hideAnnotation } from "./annotations";
import { createVertexAtClickedPoint, sceneHoverInteractionDrawWalls, updateWalls } from "./drawwalls";
import { Floorplan, vertexColor, vertexMaterial, wallColor, wallHoverMaterial, wallMaterial } from "./floorplan";
import { createFrontAndBackPlane } from "./helper";

var posStartX = 0
var posEndX = 0

var posStartY = 0
var posEndY = 0
var mouseVector = new Vector3();

export var currSelectedFloorplanObject = null
export var clonnedWall = null
export var clonnedVertices = null
let prevMouse = { x: 0, y: 0 };

var isMouseDownOnWall = false

var movableDotForVertexDrag = null

var verticesForSnapping = []
var htmlVerticesForSnapping = []

var floorplanner:Floorplan = null

var currNormal = new Vector3()

export function raycastingInit(floorplanObject:Floorplan) {
    floorplanner = floorplanObject
}


export function pointerup(event:any) {
  posEndX = event.pageX
  posEndY = event.pageY
  pointerUpAction()
}


export function pointerdown(event:any) {
  posStartX = event.pageX
  posStartY = event.pageY
  applyRaycasting(event,event.pageX,event.pageY,floorplanner.group)
}


export function touchstart(event:any) {
  posStartX = event.touches[0].clientX
  posStartY = event.touches[0].clientY 
  applyRaycasting(event,posStartX,posStartY,floorplanner.group) 
}

export function pointermove(event:any) {
  if(floorplanner.isDrawWallsMode){
    sceneHoverInteractionDrawWalls(event.pageX,event.pageY)
  }
  onPointerMove(event) 
}

export function dblclick(event:any) {
  floorplanner.disableDrawWalls()
}

export function removecurrSelectedFloorplanObject(object:any = null) {
  if(object){
    // disableDragging()
    detachModule(object)
    if(object.userData.isWall){
      floorplanner.scene.remove(object)
      object.visible = false
      object.clear()
      // object.material.color.setHex(floorplanner.wallColor)
    }
    if(object.userData.isVertex){
      attachModules([object],floorplanner.group)
      // object.material.color.setHex(floorplanner.vertexColor)
    }
  }
}

export function applyRaycasting(event:any,x:any,y:any,group:Group) {
  if(floorplanner.isSpacePlannerMode){
    if(floorplanner.isDrawWallsMode){
      if(!$(event.target).hasClass("stop-propagation")){
        createVertexAtClickedPoint(x,y)
      }
      return
    }
    floorplanner.updateHoverBoxPosition(x,y)
    const intersectsArray = getIntersectsObjectsInAreaModel(floorplanner.scene,floorplanner.element,x, y,mouseVector,floorplanner.raycaster,floorplanner.camera,"");
    //For touch devices
    const wall = getClickedObject(intersectsArray)?.object || floorplanner.getWallFromHoverBoxIntersection()
    if(wall){
        floorplanner.controls.enablePan = false
        floorplanner.controls.enabled = false
        currSelectedFloorplanObject = wall

        currNormal = createFrontAndBackPlane(currSelectedFloorplanObject) 
        prevMouse = {x: x,y: y}
        // currSelectedFloorplanObject.material = wallHoverMaterial
        if(currSelectedFloorplanObject.userData.isWall){
          // const vertex1 = floorplanner.getVertexFromName(currSelectedFloorplanObject.userData.vertex1Name)
          // const vertex2 = floorplanner.getVertexFromName(currSelectedFloorplanObject.userData.vertex2Name)
          // vertex1.material = wallHoverMaterial
          // vertex2.material = wallHoverMaterial

          // detachModule(vertex1)
          // detachModule(vertex2)
          // attachModules([vertex1,vertex2],currSelectedFloorplanObject)
          // showAnnotations(currSelectedFloorplanObject)
        }
        isMouseDownOnWall = true
        // if(currSelectedFloorplanObject.userData.isVertex){
        //    setVerticesForSnapping()
        // }
      }else{
        if(currSelectedFloorplanObject?.userData.isWall){
          currSelectedFloorplanObject.material.color.setHex(wallColor)
        }
        if(currSelectedFloorplanObject?.userData.isVertex){
          currSelectedFloorplanObject.material.color.setHex(vertexColor)
        }
        hideAnnotation()
      }
  }
}

function onPointerMove(event:any) {
  // if(floorplanner.moduleName === "customizer"){
  //   return
  // }
  // Get the delta (change) in mouse position

  if (event.touches?.length === 2 || isChangingControls) {
    return
  }

  if(currSelectedFloorplanObject && isMouseDownOnWall){
    const deltaMouse = {
      x: event.clientX - prevMouse.x,
      y: event.clientY - prevMouse.y
    };

    if(currSelectedFloorplanObject.userData.isWall){
      const vertex1 = floorplanner.getVertexFromName(currSelectedFloorplanObject.userData.vertex1Name)
      const vertex2 = floorplanner.getVertexFromName(currSelectedFloorplanObject.userData.vertex2Name)

      // let normal = currSelectedFloorplanObject?.userData.normal
      // let dimensionElement = currSelectedFloorplanObject.userData.dimensionElement




      // const quaternion = new Quaternion()
      // floorplanner.group.getWorldQuaternion(quaternion)

      if(currSelectedFloorplanObject?.userData.isWall){
        if(currNormal?.x){
          const translationVector = new Vector3(deltaMouse.x * 0.01, 0, 0)
          translationVector.applyQuaternion(floorplanner.group.quaternion)

          if(Math.abs(MathUtils.radToDeg(floorplanner.group.rotation.y)) === 90){
            translationVector.negate()
          }

          // currSelectedFloorplanObject.position.x += deltaMouse.x * 0.01;  
          // vertex1.position.x += deltaMouse.x * 0.01;  
          // vertex2.position.x += deltaMouse.x * 0.01;  

          currSelectedFloorplanObject.position.add(translationVector)
          vertex1.position.add(translationVector)
          vertex2.position.add(translationVector)
        }
        if(currNormal?.z){
          const translationVector = new Vector3(0, 0, deltaMouse.y * 0.01)
          translationVector.applyQuaternion(floorplanner.group.quaternion)

          if(Math.abs(MathUtils.radToDeg(floorplanner.group.rotation.y)) === 90){
            translationVector.negate()
          }
          // currSelectedFloorplanObject.position.z += deltaMouse.y * 0.01;  
          // vertex1.position.z += deltaMouse.y * 0.01;  
          // vertex2.position.z += deltaMouse.y * 0.01; 

          currSelectedFloorplanObject.position.add(translationVector)
          vertex1.position.add(translationVector)
          vertex2.position.add(translationVector)

        
        }
        // if(dimensionElement){
        //   dimensionElement.addClass("--is-selected")
        // }
        currSelectedFloorplanObject.material = wallHoverMaterial
      }
    
      prevMouse = { x: event.clientX, y: event.clientY }
      updateWalls()
    }

    if(currSelectedFloorplanObject.userData.isVertex){
        currSelectedFloorplanObject.position.x += deltaMouse.x * 0.01;  
        currSelectedFloorplanObject.position.z += deltaMouse.y * 0.01;  
        prevMouse = { x: event.clientX, y: event.clientY }
        updateWalls()
    }
  }

  // if(!floorplanner.isDrawWallsMode){
  //   floorplanner.updateHoverBoxPosition(event.clientX,event.clientY)
  // }
  
}

export function pointerUpAction() {
  if(currSelectedFloorplanObject?.userData.isWall){
    // const vertex1 = floorplanner.getVertexFromName(currSelectedFloorplanObject.userData.vertex1Name)
    // const vertex2 = floorplanner.getVertexFromName(currSelectedFloorplanObject.userData.vertex2Name)
    // currSelectedFloorplanObject.material = wallMaterial
    // vertex1.material = vertexMaterial
    // vertex2.material = vertexMaterial

    // detachModule(vertex1)
    // detachModule(vertex2)
    // attachModules([vertex1,vertex2],floorplanner.group)

    currSelectedFloorplanObject.material = wallMaterial

  }
  if(currSelectedFloorplanObject?.userData.isVertex){
    // currSelectedFloorplanObject.material = vertexMaterial
  }
  floorplanner.controls.enablePan = true
  floorplanner.controls.enabled = true
  // currSelectedFloorplanObject = null
  verticesForSnapping = []
  // $(".movable-dot").remove()
  // $(".distance-line").remove()
  // $(".vertex-drag-distance-line").remove()
  htmlVerticesForSnapping = []
  isMouseDownOnWall = false
}


export function setcurrSelectedFloorplanObject(wall:any) {
  currSelectedFloorplanObject = wall
}

export function getClickedObject(intersects:Array<any>) {
  var res = intersects?.filter(function(res) {
  return res && res?.object 
  && (res?.object?.userData.isWall || res?.object?.userData.isVertex) 
  && res?.object?.userData.canSelected
  })[0];
  if(!res){
      return null
  }
  return res
}

  