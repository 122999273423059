import $ from "jquery";
import React, { useEffect, useState } from "react";
import AwsConfig from "../../../../../utils/aws-sdk/AwsConfig";
import {
  checkFromCache,
  getAndSaveImageToCache
} from "../../../../../utils/cache/cache";
import { displayUploadedImage } from "../../../../../utils/inventory/inventory";
import { logger, stringWithoutQuotes } from "../../../../../utils/methods";
import { closeResolutionPanel, processImage, removeBackground } from "../../../../../utils/rembg/rembg";
import {
  hideComponentLoader,
  showComponentLoader
} from "../../../../../utils/UI_methods/global";


const Resolutions: React.FC<{
  selectedFile: any
  setRenderingTime: (val: any) => void;
  setIsBackgroundRemoved: (val: boolean) => void;
  setCurrState: (val: string) => void;
}> = (props) => {


  const [loaderId] = useState("rembgLoader")
  const [resolution, setResolution] = useState("none");


  // let awsConfig = new AwsConfig();

  // var timeInterval = null;

  // function updateTime() {
  //   props.setRenderingTime((prevValue) => {
  //     return prevValue + 1;
  //   });
  // }




  // const processImage = () => {
  //   timeInterval = setInterval(updateTime, 1000);
  //   showComponentLoader(loaderId);
  //   if (props.selectedFile) {
  //     awsConfig.uploadRembgImage(props.selectedFile.name, props.selectedFile).then(async (data) => {
  //         console.log(data);

  //         await removeBackground(props.selectedFile.name,resolution)
  //           .then(async (data) => {
  //             console.log(data);

  //             let key = "rembg/withoutbg/result.png";
  //             getAndSaveImageToCache(key).then((data) => {
  //                 console.log(data);
  //                 clearInterval(timeInterval);
  //                 props.setIsBackgroundRemoved(true)
  //               })
  //               .catch((err) => {
  //                 console.log(err);
  //                 hideComponentLoader(loaderId);
  //                 clearInterval(timeInterval);
  //               });
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         hideComponentLoader(loaderId);
  //         clearInterval(timeInterval);
  //       });
  //   } else {
  //     alert("No file selected");
  //     hideComponentLoader(loaderId);
  //     clearInterval(timeInterval);
  //   }
  // };

  function changeResolutionHandle(event: any) {
    $(".image-resolution").removeClass("--is-active")
    $(event.target).addClass("--is-active")
    let res = $(event.target).attr("data-resolution")
    setResolution(res)
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     displayUploadedImage(props.selectedFile, "withbgImage")
  //   }, 200);
  // }, [])


  var timeInterval = null;

  function updateTime() {
    props.setRenderingTime((prevValue) => {
      return prevValue + 1;
    });
  }


  function removeBackground() {
    closeResolutionPanel()
    timeInterval = setInterval(updateTime, 1000);
    showComponentLoader(loaderId);
    processImage(props.selectedFile,resolution).then(data => {
      clearInterval(timeInterval);
      props.setIsBackgroundRemoved(true)
      props.setCurrState("removedBg")
      hideComponentLoader(loaderId);
    }).catch(err => {
      hideComponentLoader(loaderId);
      // logger.error("rembg","rmeovebg",`Error in removing background ${stringWithoutQuotes(err)}`)
      clearInterval(timeInterval);
    })
  }



  return (
    <div className="full-height-width padding10 top-left" id="rembgResolutionsContainer">

      <div className="top-left ion-padding  zindex30 pointer center-aligned-column" onClick={closeResolutionPanel}>
        <div className="heading2 letter-spacing font-normal display-flex-row  color-black">
          <i className="fas fa-chevron-left color-danger  center-aligned-column"></i>
          <span className="marginleft5 capitalize color-black">Go back</span>
        </div>
      </div>

      {/* <div className="width50">
        <div className="ion-padding">
          <img src="" className="full-width" id="withbgImage" alt=""/>
        </div>
      </div> */}

      <div className="center-aligned-column full-height-width" >

        <div className="heading1 color-danger text-center font-normal ion-margin-bottom">This is an experimental feature result will depend on the complexity of the image.</div>

        <div className="display-flex-row space-around rembg-resolution-container">

          <button onClick={changeResolutionHandle} data-resolution="none" className="image-resolution marginTop10 --is-active op-btn op-btn-primary-inverse marginleft5 op-btn-lg">
            <div className="letter-spacing capitalize center-aligned-column font-medium marginleft5">
              Original
            </div>
            <div className="heading2 color-dark marginTop5"> pixels (h)</div>
          </button>

          <button onClick={changeResolutionHandle} data-resolution="480" className="image-resolution  marginTop10 op-btn op-btn-primary-inverse marginleft5 op-btn-lg">
            <div className="letter-spacing capitalize center-aligned-column font-medium marginleft5">
              Small
            </div>
            <div className="heading2 color-dark marginTop5">480 pixels (h)</div>
          </button>

          <button onClick={changeResolutionHandle} data-resolution="720" className="image-resolution marginTop10 op-btn op-btn-primary-inverse marginleft5 op-btn-lg">
            <div className="letter-spacing capitalize center-aligned-column font-medium marginleft5">
              Medium
            </div>
            <div className="heading2 color-dark marginTop5">720 pixels (h)</div>
          </button>

          <button onClick={changeResolutionHandle} data-resolution="1080" className="image-resolution marginTop10 op-btn op-btn-primary-inverse marginleft5 op-btn-lg">
            <div className="letter-spacing capitalize center-aligned-column font-medium marginleft5">
              High
            </div>
            <div className="heading2 color-dark marginTop5">1080 pixels (h)</div>
          </button>

        </div>

        {/* <div className="heading2 text-center ion-margin-top color-dark padding5">Note: Aspect ratio will remain unchanged</div> */}

        <div className="center-aligned-column ion-margin-top">

          <button data-resolution="High" onClick={removeBackground} className="op-btn display-flex-row op-btn-primary marginleft5" style={{ padding: "10px 20px" }}>
            <div className="letter-spacing color-white capitalize display-flex-row font-medium marginleft5">
              Process Image   <i className="fas fa-chevron-right marginleft5 center-aligned-column"></i>
            </div>
          </button>

        </div>

      </div>

      <div className="bottom-middle">
        <div className="heading2 color-dark padding5">Note: Aspect ratio will remain unchanged</div>
      </div>
    </div>
  );
};

export default Resolutions;
