import React, { useEffect, useState } from "react";
import { STORE } from "../../../../../../utils/store/storeConfiguration";
import { getCustomVariantImageUrl } from "../../../../../../utils/store/UI_methods";

const VariantIcon: React.FC<{
  variant:any
  currCategory:string
}> = (props) => {

  const[imageUrl,setUrl]  = useState(``)

  useEffect(()=>{
    setUrl(getIconUrl())
  },[props.variant])


  function getIconUrl(){
    let url = ""
    if(props.currCategory === "Sofa" || props.currCategory === "UpholsteredBed"){
      let currSize = props.variant?.size
      let variantNameUrl = encodeURIComponent(props.variant?.variantName)
      url = `${STORE.BASE_URL_ASSETS}icons/variants/${props.currCategory}/${currSize}/${variantNameUrl}.svg`
      if(props.variant?.type){
        url = `${STORE.BASE_URL_ASSETS}icons/variants/${props.currCategory}/${currSize}/${variantNameUrl}_${props.variant?.type}.svg`
      }else{
        url = `${STORE.BASE_URL_ASSETS}icons/variants/${props.currCategory}/${currSize}/${variantNameUrl}.svg`
      }
      if(STORE.productConfiguration?.isCustomVariant){
        url = getCustomVariantImageUrl(STORE.currProduct,props.variant?.variantName)
      }
    }else{
      url = `${STORE.BASE_URL_ASSETS}icons/category/${props.currCategory}.svg`
    }
     
    return url
  }

  return (
    <img  src={imageUrl} className="full-width" alt="" />
  );
};

export default VariantIcon;
