import $ from "jquery"
import { STORE } from "../store/storeConfiguration";
const ProductViewer3D = require('./three_shopify.js');
const applicationConfig = require('../methods');
// import AnimateImages from "@its2easy/animate-images";

const shapesList = ["1Seater","2Seater","3Seater"]
const feetsList = ["Feet1","Feet2"]
const materialsList = ["M1205","M1208","M1209","M1595","M2055"]


// $(document).ready(function() {
//   const viewer = new ProductViewer360("product__media-wrapper","product-form","Dolce",true)
//   viewer.init()
// });



class Configuration{
  shape = shapesList[0]
  finish = materialsList[0]
  feet = feetsList[0]
  productName = ""
  price = ""

  constructor(){

  }

  clone(){
    return{
      shape:this.shape,
      finish:this.finish,
      feet:this.feet,
      productName:this.productName,
      price:this.price
    }
  }
}

export class ProductViewer360{
  canvasContainerClass = ""
  controlsContainerClass = ""

  totalFrames = 12
  currSequence = 1

  configuration = new Configuration()

  animateImageInstance = null

  gallery = []

  isShopifyPlugin = false

  productViewer

  constructor(canvasContainerClass,controlsContainerClass,productName,isShopifyPlugin = false){
    this.canvasContainerClass = canvasContainerClass
    this.controlsContainerClass = controlsContainerClass
    this.configuration.productName = productName
    this.isShopifyPlugin = isShopifyPlugin
  }

  async init(){
    // if(!isLoaded){
    try {
      this.createCanvasUI()
      this.createControlsUI()
      this.addEventListeners()
      this.updateActiveClasses()

      this.productViewer = new ProductViewer3D()

      setTimeout(() => {
        this.productViewer.init("productViewer360Wrapper")
      }, 2000); 
    } catch (error) {
      
    }
    // }
  }

     

  setCanvasHeight(){
    const overallHeight = $(".product-viewer-360-wrapper").height()
    const canvasHeight = overallHeight * 1
    const remainingHeight = overallHeight - canvasHeight
    $("#productViewer360Wrapper").css("height",canvasHeight+"px")
    $("#otherOptionsWrapper").css("height",remainingHeight+"px")
  }

  addEventListeners(){
    let accordionHeaders = $(".accordion-header")
    accordionHeaders.on("click",function(event) {
      const contentWrapper = $(event.target).parent()
      if(contentWrapper){
        contentWrapper.toggleClass("--is-active")
      }
    })
  }

  updateFinish(element) {
    const value = $(element).attr("data-value")
    this.configuration.finish = value
    this.animateImages(false)
    this.updateActiveClasses()
    this.updatePrice(value)
  }
 

  updatePrice(value){
    // if(this.isShopifyPlugin){
    //   let price = randomNumber(18000,32000)
    //   $(".price__regular").find(".price-item--regular").text(`RS. ${price}.00`)
    // }
    const price = getRandomNumber(15000,25000)
    if(this.isShopifyPlugin){
      let allVairnats = $("variant-radios")
      const inputElement = allVairnats.find(`input[value="${value}"]`);
      const inputElementId = inputElement.attr("id")
      const labelElement = allVairnats.find(`label[for="${inputElementId}"]`);
      labelElement.click()
      this.updateCartImage()
    }else{
      $("#priceContainer").text(`Rs. ${price}.00`)
    }
    this.configuration.price = price
  }

  updateCartImage(){
    const allCartItems = $(".cart-item")

    for (let i = 0; i < allCartItems.length; i++) {
      const currCartItem = $(allCartItems[i])
      const cartItemDetails = currCartItem.find(".cart-item__details")
      const productOptions = cartItemDetails.find("dl").find(".product-option")
      const shape = $(productOptions[0]).find("dd").text().replaceAll(" ","").replace(",","")
      const feet = $(productOptions[1]).find("dd").text().replaceAll(" ","").replace(",","")
      const finish = $(productOptions[2]).find("dd").text().replaceAll(" ","").replace(",","")
      const imageUrl = `https://s3.ap-south-1.amazonaws.com/demo.io/productviewer360/${this.configuration.productName}/${this.configuration.productName}_${shape}/${this.configuration.productName}_${shape}_Leather_${finish}/360/${this.configuration.productName}_${shape}_Base_Leather_${finish}_10000.webp`
      currCartItem.find(".cart-item__image").attr("src",imageUrl)
    }
  }

  updateActiveClasses(){
    $(".finish-image-container").removeClass("--is-active")
    $(".finish-image-container[data-value='" + this.configuration.finish + "']").addClass("--is-active")
    $(".finish-image-container[data-value='" + this.configuration.shape + "']").addClass("--is-active")
    $(".finish-image-container[data-value='" + this.configuration.feet + "']").addClass("--is-active")
  }

  createControlsUI(){
    const currObject = this
    let controlsWrapper = $("."+this.controlsContainerClass)
    if(this.isShopifyPlugin){
      controlsWrapper = $(".product__info-container").find("."+this.controlsContainerClass)
      controlsWrapper.before(getControlsHtml())
    }else{
      controlsWrapper.append(getControlsHtml())
    }

    const shapesContainer = $("#shapesContainer")
    const legsContainer = $("#legsContainer")
    const materialsContainer = $("#materialsContainer")

    
    let shapesHtml = getShapesHtml()
    let legsHtml = getLegsHtml()
    let materialsHtml = getMaterialsHtml()


    shapesContainer.append(shapesHtml)
    legsContainer.append(legsHtml)
    materialsContainer.append(materialsHtml)

    // Add an onclick event using jQuery
    $("#shapesContainer .finish-image-container").click(function(event){
      currObject.updateShape(this)
    });

    $("#legsContainer .finish-image-container").click(function(event){
      currObject.updateFeet(this)
    });

    $("#materialsContainer .finish-image-container").click(function(event){
      currObject.updateFinish(this)
    });
  }

  createCanvasUI(){
    const canvasWrapper = $("."+this.canvasContainerClass)
    canvasWrapper.empty()

    const canvasContainerHtml = getCanvasContaier()
    canvasWrapper.append(canvasContainerHtml)
     
    // const shapesContainer = $("#otherOptionsWrapper")

    // const galleryContainer = getGalleryContainer()

    // shapesContainer.append(galleryContainer)
    // this.updateGalleryImages()

  }

}



 


  
function showComponentLoader(id,className=null) {
  if(id){
    $("#"+id).addClass("--is-active")
  }
  if(className){
    $("."+className).addClass("--is-active")
  }
}

function hideComponentLoader(id,className=null) {
  setTimeout(() => {
    if(id){
      $("#"+id)?.removeClass("--is-active")
      $("#"+id+"progressBar").css("width",0)
      $("#"+id+"progressBarValue").text('0%')
      // $("#"+id+"progressMessage").text('Please wait')
    }
    if(className){
      $("."+className)?.removeClass("--is-active")
    }
  }, 300);
}

export function getControlsHtml() {
  return`
   <div class='accordion-wrapper product-viewer-360-controls no-shadow marginTop10'>

                    <div class='accordion-container'>
                        <div class='display-flex-row padding10 pointer no-wrap space-between accordion-header'>
                            <div class='pointer-none display-flex-row'>
                                <div class="accordion-badge">1</div>
                                <div class="heading2 accordion-text font-normal bold center-aligned-column">Select variant</div>
                            </div>
                            <div class='pointer-none center-aligned-column'><i class='fas fa-chevron-down accordion-icon'></i></div>
                        </div>
                        <div class="content">
                            <div class='display-flex-row' id="shapesContainer">
                                
                            </div>
                        </div>
                    </div>

                    <div class='accordion-container'>
                        <div class='display-flex-row padding10 pointer no-wrap space-between accordion-header'>
                            <div class=' pointer-none display-flex-row'>
                                <div class="accordion-badge">3</div>
                                <div class="heading2 accordion-text font-normal bold center-aligned-column">Choose Finish</div>
                            </div>
                            <div class='pointer-none center-aligned-column'><i class='fas fa-chevron-down accordion-icon'></i></div>
                        </div>
                        <div class="content  ">
                            <div class='finish-list padding5 display-flex-row' id="materialsContainer">
                                
                            </div> 
                        </div>
                    </div>


                    <div class='accordion-container'>
                        <div class='display-flex-row padding10 pointer no-wrap space-between accordion-header'>
                            <div class=' pointer-none display-flex-row'>
                                <div class="accordion-badge">2</div>
                                <div class="heading2 accordion-text font-normal bold center-aligned-column">Choose legs</div>
                            </div>
                            <div class='pointer-none center-aligned-column'><i class='fas fa-chevron-down accordion-icon'></i></div>
                        </div>
                        <div class="content ">
                            <div class='display-flex-row' id="legsContainer">
                                
                            </div>
                        </div>
                    </div>

                    

                    

                </div>
   `
}

function getGalleryContainer() {
  return`
    <div class='center-aligned-row  color-dark'>
        <div class='rotation-360-image center-aligned-column'>360</div>
    </div>
    <div class='gallery-wrapper border padding10 center-aligned-column'>
        <div class='display-flex-row container full-height no-wrap' id="galleryImagesContainer">
        
        </div>
    </div>
  `
}

function getCanvasContaier() {
  return`
    <div id="productViewer360Wrapper" class='full-height-width position-relative'>
    </div>
    <div class="component-loader center-aligned-column  white-background full-height-width top-left fade-background-loader --is-active" id="productViewerLoader">
      <img src="https://s3.ap-south-1.amazonaws.com/demo.io/productviewer360/assets/loader.gif" alt="Please wait" width="50"/> 
    </div>
    <div class='full-width' id="otherOptionsWrapper">
        
    </div>
  `
}

function getShapesHtml() {
     
  let shapesHtml = ""

  for (const shape of shapesList) {
    shapesHtml += `
    <div class='finish pointer padding5 display-flex-row'>
        <div class='finish-image-container position-relative'  data-value=${shape}  >
            <img class='full-height-width' src="https://s3.ap-south-1.amazonaws.com/demo.io/productviewer360/Dolce/assets/shapes/${shape}.webp" alt=""  />
        </div>
        <div class='marginleft10'>
            <div class='accordion-text marginTop5 font-md-small'>${shape}</div>
            <div class='accordion-text font-ex-small'>Kuka / Leather</div>
        </div>
    </div>
  `
  }
  return shapesHtml

}

function getMaterialsHtml() {
  let materialsHtml = ""

  for (const material of materialsList) {
    materialsHtml += `
    <div class='finish pointer padding5'>
      <div class='finish-image-container materials-image-container position-relative' data-value=${material} >
          <img class='full-height-width' src="https://s3.ap-south-1.amazonaws.com/demo.io/assests/Thumbnails/Basic/Kuka/Leather/${material}.webp" alt=""  />
        </div>
        
        <div class='accordion-text marginTop5 center-aligned-row font-md-small'>${material}</div>
        <div class='accordion-text center-aligned-row font-ex-small'>Kuka / Leather</div>
    </div>
  `
  }

  return materialsHtml
}

function getLegsHtml() {

  let legsHtml = ""

  for (const feet of feetsList) {
    legsHtml += `
    <div class='finish pointer padding5 display-flex-row'>
    <div class='finish-image-container position-relative' data-value=${feet} >
        <img class='full-height-width' src="https://s3.ap-south-1.amazonaws.com/demo.io/productviewer360/Dolce/assets/feets/${feet}.webp" alt=""  />
        </div>
        <div class='marginleft10'>
            <div class='accordion-text marginTop5 font-md-small'>${feet}</div>
            <div class='accordion-text font-ex-small'>Kuka / Leather</div>
        </div>
    </div>
  `
  }

  return legsHtml
}
export function getRandomNumber(min, max) {
  return parseInt(Math.random() * (max - min) + min);
}     

export function getFormattedPrice(price) {
  let clientName = applicationConfig.getClientName()
  let companyName = STORE.currSelectedCompany
 
  if(applicationConfig?.applicationConfig?.isLiveClient && clientName !== "HomeZone" && companyName !== "Mittal"){
    return "NA"
  }

  if(applicationConfig?.isLiveClient && clientName !== "HomeZone" && companyName !== "Mittal"){
    return "NA"

  }
  return `Rs. ${price}.00`
}


export function getProductImageFromConfiguration(configuration,index) {
  if(configuration){
    return `https://s3.ap-south-1.amazonaws.com/demo.io/productviewer360/${configuration.productName}/${configuration.productName}_${configuration.shape}/${configuration.productName}_${configuration.shape}_Leather_${configuration.finish}/360/${configuration.productName}_${configuration.shape}_Base_Leather_${configuration.finish}_${index}0000.webp`
  }
  return ""
}



