import React from "react";
  
  
  const RoomLayout: React.FC<{
    configObj:any
  }> = (props) => {

 
    return (
      <React.Fragment>
        {/* <div className="rupee-sign top-left  floor-dimensions " id="floorTopDim">10.25</div>
        <div className="rupee-sign top-left  floor-dimensions " id="floorBottomDim">10.25</div>
        <div className="rupee-sign top-left  floor-dimensions " id="floorLeftDim">10.25</div>
        <div className="rupee-sign top-left  floor-dimensions " id="floorRightDim">10.25</div> */}
      </React.Fragment>
    );
  };
  
  export default RoomLayout;
  