import { IonAlert, IonIcon, IonModal } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { getScreenshotKey } from "../../../../utils/cache/cache";
import { displayImageFromIndexDb } from "../../../../utils/cache/indexdb";
import {
  getCartKey,
  getItemFromLocalStorage
} from "../../../../utils/cache/localstorage";
import { getLocalstorageFinishConfigKey } from "../../../../utils/customizein3d/modules/helper";
import { convertHtmlToPdf } from "../../../../utils/html2canvas/html2pdf";
import {  getModulePrice, shareProject, stringWithoutWhiteSpace, waitFor } from "../../../../utils/methods";
import { hideComponentLoader, showComponentLoader, updateCartCounter } from "../../../../utils/UI_methods/global";
import Share from '../../../common/shared-components/Share/Share';
import "../../../style/common/cart.css";
import Loader from "../main-ui/Loader";
import { getFormattedPrice } from "../../../../utils/products/productviewer_shopify";

const Cart: React.FC<{
  configObject: any;
  closeModal: () => void;
}> = (props) => {
  const [items, setItems] = useState([]);
  const[showShareDetails,setshowShareDetails] = useState(false)
  const[showDeleteAlert,setShowDeleteAlert] = useState(false)
  const[currSelectedItem,setCurrSelectedItem] = useState(0)

  const[configuration,setConfiguration] = useState()
  const[cartLoader] = useState("cartLoader")

  const[totalPrice,setTotalPrice]:any = useState(0)


  useEffect(() => {
    let key = getCartKey();
    let items: any = getItemFromLocalStorage(key);
    setItems(items);
  }, []);


  useEffect(()=>{
    setTimeout(() => {
      let images = document.querySelectorAll('.cart-item-image')
      for (let i = 0; i < images.length; i++) {
        let element = images[i];
        let id:any = element.getAttribute('id')
        let itemId:any = element.getAttribute('data-item-id')
        displayImageFromIndexDb(id,"cart",itemId)
        updateCartCounter()
        setPrice()
      }
    }, 500);

  },[items])

 

  function deleteAction(index:number) {
    setCurrSelectedItem(index)
    setShowDeleteAlert(true)
  }




  function deleteItem() {
    // showComponentLoader(cartLoader)
    // let allItems:any = [...items]
    // allItems.splice(currSelectedItem,1) 
    setItems(prevItems=>{
      let newItems = []
      for (const item of prevItems) {
        if(item.itemId!=currSelectedItem){
          newItems.push(item)
        }
      }
      localStorage.setItem('cartItems',JSON.stringify(newItems))
      return newItems
    })
  
    // hideComponentLoader(cartLoader)
  }

  
  function shareAction(productName:string){
    // let canvas = document.querySelector("canvas")
    // if(canvas){
      // let image = canvas.toDataURL("image/png");
      // captureAndSaveScreenshot(image,"customizeIn3D",props.currProduct?.product_name)
      let key = getLocalstorageFinishConfigKey(stringWithoutWhiteSpace(productName),"customizeIn3D")
      let config:any = getItemFromLocalStorage(key)
      setConfiguration(JSON.parse(config)) 
      setshowShareDetails(true)
    // }
}

function setPrice() {
  let price:any = 0
  // if(!items.length){
  //   setTotalPrice(0)
  // }
  // for (let i = 0; i < items.length; i++) {
  //   const item = items[i];
  //   let collectionName = ''
  //   for (const key in item.finish) {
  //     collectionName = item.finish[key].collectionName
  //   }
  //   for (const key in item.modules) {
  //     price = price + getModulePrice(props.configObject?.data.productModulesPrice,collectionName,item.modules[key].module_id) || 0
  //   }
  // } 
  setTotalPrice(0)
}

async function DownloadAsPdf(itemDetails:any) {
  showComponentLoader(cartLoader)
  await waitFor(300)
  await convertHtmlToPdf("cart",props.configObject,itemDetails,"Summary")
  hideComponentLoader(cartLoader)
}

async function shareAPI(itemDetails:any) {
  showComponentLoader(cartLoader)
  await shareProject(props.configObject,"cart")
  hideComponentLoader(cartLoader)
}



  return (
    <React.Fragment>
         
        <IonModal
          id=""
          cssClass="share-modal"
          onDidDismiss={()=>setshowShareDetails(false)}
          isOpen={showShareDetails}
          >
            <Share
                  configuration={configuration}
                  areas={[{area_name:"Default"}]}
                  configObj={props.configObject}
                  moduleName="Customizein3D"
                  closeModal={setshowShareDetails}
              />
        </IonModal>

      <IonAlert
        isOpen={showDeleteAlert}
        mode="ios"
        onDidDismiss={() => setShowDeleteAlert(false)}
        cssClass="my-custom-class"
        header={"Confirm!"}
        message={"Delete <strong>Item</strong>!!!"}
        buttons={[{
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              deleteItem();
            },
          },
        ]}
      />

        <div className="summary-header padding10">
            <div className="display-flex-row background-white">
                <div className="brand">
                  <img id="companyLogo" src="/assets/images/opus.png" alt="Opus"/>
                </div>

                <div className="click-effect summary-back-button ion-margin-start center-aligned-column" onClick={()=>props.closeModal()}>
                  <div className="heading1 letter-spacing font-normal display-flex-row  color-black"> 
                    <IonIcon icon={arrowBack} className="color-danger font-large center-aligned-column" /> <span className="marginleft5">Go back</span>
                  </div>
                </div>

            </div>
        </div>

        <div className="full-height-width background-white summary-content-wrapper"> 
          <div className="heading1 display-flex-row letter-spacing font-large">
          <span className="center-aligned-column marginRight5"><i className="far fa-clipboard color-primary font-large"></i></span>
            Cart Items
          </div>
          <div className="full-height-width overflow-y-scroll">
          <div className="full-height-width summary-wrapper overflow-y-scroll">
        

        <div className="summary-details-wrapper" id="productSummary">
            <Loader
            id={cartLoader}
            className="fade-background"
            loaderType="spinner"
            data={{}}
           />
          {items?.map((item:any,index:number)=>{
            let finish = "";
            let modules = "";
            let price = 0
            let screenshotKey = ""
            let modulesList = []
  
            let collectionName = ''
            for (const key in item.finish) {
              finish = `${item.finish[key].companyName} / ${item.finish[key].collectionName} / ${item.finish[key].materialCode}`
              collectionName = item.finish[key].collectionName
            }
            for (const module of item.modules) {
              modules = modules + `${module?.displayName}`;
              modulesList.push(module.displayName)
              screenshotKey = getScreenshotKey("cart",item.productName)
              // price = price + getModulePrice(props.configObject?.data?.productModulesPrice,collectionName,module.module_id) || 0
            }


            return(
              <div key={item.itemId} className="display-flex-row position-relative no-wrap space-between padding10 border-bottom">
                <div className="product-details display-flex-row no-wrap">
                <Loader
                    className="fade-loader-background"
                    id={cartLoader}
                    data={{}}
                    loaderType="dots"
                  />
                  <div className="image center-aligned-column" style={{width:"15rem"}} >
                    <img src="" alt="" className="full-width padding10 cart-item-image" data-item-id={item?.itemId} id={item?.itemId}
                    />
                  </div>
                  <div className="other-details">
                    <div className="product-name letter-spacing font-medium heading1">{item.productName}</div>
                    <div className="config color-dark">
                      {/* <span>Width: 952 Cm, </span>
                      <span className="marginleft5">Height: 952 Cm, </span>
                      <span className="marginleft5">Depth: 952 Cm</span> */}
                      <div><span className="heading3">Modules :</span> {modules}</div>
                      <div><span className="heading3">Configuration :</span> {finish}</div>
                    </div>
                    {/* <div className="rupee-sign heading3">  ₹ {productObject?.price}</div> */}
                    <div className="font-small category-name ion-margin-top ion-margin-bottom letter-spacing color-white bg-color-black rupee-sign" style={{padding:"0.2rem 1rem",width:"max-content"}}>{getFormattedPrice(price)}</div>
                  </div>
                </div>
                {/* <div className="price hide-in-mobile rupee-sign heading1"> ₹ {price}</div> */}
                <div className="bottom-right  padding10 display-flex-row">
                  
                  <div className="white-card pointer click-effect shadow-light center-aligned-column" style={{"width": "32px","height":"32px"}}
                   onClick={()=>{
                    DownloadAsPdf({
                      productName:item.productName,
                      finish:finish,
                      modules:modulesList,
                      price:String(price),
                      imageId:item?.itemId
                    })
                  }}
                  >
                    <i className="fas color-dark fa-download font-medium" area-hidden="true"></i>
                  </div>
                  
                  <div className="white-card pointer click-effect shadow-light center-aligned-column marginleft10" style={{"width": "32px","height":"32px"}}
                    onClick={()=>{
                      shareAPI({
                        productName:item.productName,
                        finish:finish,
                        modules:modulesList,
                        price:String(price),
                        imageId:item?.itemId
                      })
                    }}
                  >
                    <i className="fas color-primary fa-share-alt font-medium" area-hidden="true"></i>
                  </div>

                  <div  onClick={()=>deleteAction(item.itemId)} className="white-card pointer click-effect shadow-light center-aligned-column marginleft10" style={{"width": "32px","height":"32px"}}>
                    <i className="fas color-danger fa-trash-alt font-medium" area-hidden="true"></i>
                  </div>

                </div>
              </div>
            )
          })}
        </div>

        <div className="summary-actions-wrapper">
          <div className="ion-margin-bottom">
            <div className="font-large heading1 letter-spacing color-black marginBottom5">
              Total Price
              </div>
            <div><span className="heading1 font-large marginRight5 rupee-sign">₹</span> <span className="heading1 letter-spacing rupee-sign" style={{fontSize:"3rem"}}>{getFormattedPrice(totalPrice)}</span>  </div>
          </div>
         
          <div className="ion-margin-top display-flex-row">
           
          </div>
        </div>

      </div>

          </div>
          </div>

      

     

         {/* <div className="cart-items-wrapper full-height overflow-y-scroll">
         <Loader
            id={cartLoader}
            className="fade-background"
            loaderType="spinner"
            data={{}}
           />
        {items?.map((item: any, index: number) => {
          let finish = "";
          let modules = "";
          let price = 0
          let screenshotKey = ""

          let collectionName = ''
          for (const key in item.finish) {
            finish = `${item.finish[key].companyName} / ${item.finish[key].collectionName} / ${item.finish[key].materialCode}`
            collectionName = item.finish[key].collectionName
          }
          for (const key in item.modules) {
            modules =
              modules + 
              `${item.modules[key].display_name}
          `;
          screenshotKey = getScreenshotKey("cart",item.productName)
          price = price + getModulePrice(props.configObject?.data.productModulesPrice,collectionName,item.modules[key].module_id) || 0
          }
          return (
            <div className="shadow-light border ion-margin-top padding10 position-relative" key={item.itemId}>
              <div className="right-bottom">
                <i className="fas fa-trash-alt color-danger click-effect font-large" onClick={()=>deleteAction(item.itemId)}></i>
              </div>
              <div className="display-flex-row">
                <div className="item-image-container">
                  <img src="assets/images/placeholder.png" className="cart-item-image" data-item-id={item?.itemId} alt="" id={item?.itemId}/>
                </div>
                <div className="details-container">
                  <table className="table full-width">
                    <thead className="border-bottom">
                      <tr>
                        <th>Item</th>
                        <th>Configuration</th>
                        <th>Price</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="">
                        <td>
                          <span className="heading">Item:</span>{" "}
                          {item.productName}
                        </td>
                        <td>
                          <span className="heading">Finish Details: </span>
                          <span> {finish}</span>
                        </td>
                        <td></td>
                      </tr>

                      <tr className="">
                        <td>{item.productName}</td>
                        <td>
                          <span className="heading">Module Details: </span>
                          {modules}
                        </td>
                        <td>{price}</td>
                        <td><i className="fas fa-share-alt font-medium click-effect color-primary" onClick={()=>shareAction(item.productName)}></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
    </React.Fragment>
  );
};

export default Cart;
