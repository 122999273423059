import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../utils/UI_methods/global";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getObjectByParameter, waitFor } from "../../../../utils/methods";
import Button2 from "../buttons/Button2";
import DropDown from "../filters/DropDown";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../../../../utils/cache/localstorage";
import { getFilteredProductForDemo } from "../../../../utils/store/storeConfiguration";

const SelectStoreFront: React.FC<{
  updateProductList:(list:any)=>void
  productsList?:any
}> = (props) => {

  const ref = useRef(null)
  const[storeFronts,setStoreFronts] = useState([])
  const[currSelectedStoreFront,setCurrStoreFront] = useState("")
  const[currdisplayName,setCurrDisplayName] = useState("")
  const[allProductsList] = useState(getFilteredProductForDemo())

  useEffect(()=>{

    let result = [{storefront_name:"All",storefront_display_name:"All"},...applicationConfig?.user?.storeFronts || []]
    if(result.length){
      result = result.reverse()

      let recentActivities = getItemFromLocalStorage("recentActivities")
      if(recentActivities?.currStoreFront && recentActivities?.currStoreFront?.storefront_name){
        let storeFront = recentActivities.currStoreFront
        setCurrStoreFront(storeFront.storefront_name)
        setCurrDisplayName(storeFront.storefront_display_name)
      }else{
        let storefront = getObjectByParameter(result,"storefront_name","Dash-K") || result[0]
        setCurrStoreFront(storefront.storefront_name)
        setCurrDisplayName(storefront.storefront_display_name)
      }
      
    }
    setStoreFronts(result)


  },[])

 

  async function hide() {
    showComponentLoader("mainLoaderSpinner")
    await waitFor(1500)
    $(ref.current).fadeOut() 
    await waitFor(200)
    hideComponentLoader("mainLoaderSpinner")
    showGlobalToast(`Loaded ${getProductsListLength(currSelectedStoreFront)} products`,2000)
  }

  async function show() {
    showComponentLoader("mainLoaderSpinner")
    await waitFor(500)
    $(ref.current).fadeIn()
    hideComponentLoader("mainLoaderSpinner")
  }

  useEffect(()=>{
    updateStoreFront(currSelectedStoreFront)
  
  },[currSelectedStoreFront])



  function updateStoreFront(value:string) {
    // setCurrStoreFront(value)
    props.updateProductList(getProductsList(value))
    let object = getObjectByParameter(storeFronts,"storefront_name",value)
    if(object){
      applicationConfig.currStoreFront = object
    }
  }

  function getProductsList(storeFront:string) {
    if(storeFront === "All"){
      return allProductsList
    }
     return getFilteredArray(allProductsList,"storefront_name",storeFront)
  }
  

  function getProductsListLength(storeFront:string) {
    let products = allProductsList
    if(storeFront === "All"){
      //in Case of Approval 
      return products.length || products.length || 0
    }
     return getFilteredArray(products,"storefront_name",storeFront).length
  }
  
  

  // function onChangeExcelHandle(event:any) {
  //   const fileInput = event.target;
  //   let windowObject:any = window
  //   const XLSX = windowObject.XLSX
  //   const file = fileInput.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       const data = e.target.result;
  //       const workbook = XLSX.read(data, { type: 'binary' });
  //       const sheetName = workbook.SheetNames[0];
  //       const sheet = workbook.Sheets[sheetName];
  //       for (let i = 1; i < 400; i++) {
  //         if(sheet["A"+i]){
  //           let productId = sheet["A"+i].v
  //           let moduleId = sheet["E"+i].v
  //           let x = sheet["B"+i].v
  //           let y = sheet["C"+i].v
  //           let z = sheet["D"+i].v
  //           const updateQuery = `UPDATE modules SET dim_X = ${x}, dim_Y = ${y}, dim_Z = ${z} WHERE product_id = ${productId} && module_id = ${moduleId};`;
  //           console.log(updateQuery)
  //         }else{}
  //       }
  //     };
  //     reader.readAsBinaryString(file);
  //   }
  // }



  function updateHandle(displayName:string){

    // let recentActivities = getItemFromLocalStorage("recentActivities")
    // if(recentActivities?.currStoreFront){
    //   let storeFront = recentActivities.currStoreFront
    //   setCurrStoreFront(storeFront)
    //   return
    // }

    
    let storeFront =  getObjectByParameter(storeFronts,"storefront_display_name",displayName)
    setCurrDisplayName(displayName)
    if(storeFront){
      setCurrStoreFront(storeFront.storefront_name)

      let recentActivities =  getItemFromLocalStorage("recentActivities")
      if(!recentActivities){
        recentActivities = {}
      }
      recentActivities.currStoreFront = storeFront
      addItemToLocalStorage("recentActivities",recentActivities)

    }else{
      showGlobalToast("Something went wrong",2000,"error")
    }
  }


  
  return (
    <React.Fragment>

      {storeFronts.length  > 1 ?
        <DropDown
          columns={1}
          classNames="minwidth150 storefront-dropdwon"
          values={getArrayOfDistinctValues(storeFronts,"storefront_display_name")}
          currSelectedValue={currdisplayName}
          action={updateHandle}
          label="Store Front"
        />
      :null}


    <div ref={ref} className="full-height-width center-aligned-column top-left white-card shadow-medium border position-fixed padding0" style={{zIndex:9999,display:"none",transition:"all 1s",background:"rgba(0,0,0,0.5)"}}>
        <div className="white-card shadow-light  position-relative" style={{width:"60%",height:"80%",background:"rgba(255,255,255,0.9)"}}>
        <div className="heading4 top-middle font-ex-large ion-margin-top text-center color-black padding10">Select your store front<br/></div>
          <div className="position-relative middle">
            <div className="center-aligned-row">
              <div className="display-flex-row no-wrap">
                {storeFronts.map((currStore:any,index:number)=>{
                  return(
                    <div onClick={()=>{updateStoreFront(currStore.storefront_name)}} key={index} className={`white-card pointer no-shadow marginleft10 center-aligned-column position-relative ${currSelectedStoreFront === currStore.storefront_name?" border-primary":""}`} style={{width:"10rem",height:"10rem"}}>
                        <div className="heading2 middle color-black">{currStore.storefront_name}</div>
                        <div className="heading4 bottom-left text-center full-width color-black marginBottom5">({getProductsListLength(currStore.storefront_name)} Products)</div>
                    </div>
                  )
                })}
                 
              </div>
            </div>
          </div>
          <div className="center-aligned-column bottom-middle ion-margin-bottom">
              <Button2
                configObj={{id:""}}
                action={hide}
                label={`Browse ${currSelectedStoreFront} Products`}
                fontAwesomeIcon={"fas fa-arrow-right color-"}
                iconPos="right"
                classNames={"no-shadow button-large marginTop10 button-primary"}
              />
          </div>
        </div>
    </div>
    </React.Fragment>
  );
};

export default SelectStoreFront;
