import React, { useEffect, useState } from "react";
import ProductImage from "../../../common/ui-components/elements/ProductImage";
import { getObjectArrayOfDistictValues } from "../../../../utils/methods";
import MaterialImage from "../../../common/ui-components/elements/MaterialImage";
 

 
const StockListContainer: React.FC<{
  allItems:any
  refreshList:()=>void
}> = (props) => {



  const[collections,setCollectionList] = useState([])

  useEffect(()=>{
    setCollectionList(getObjectArrayOfDistictValues(props.allItems,"collection_name"))
  },[props.allItems])

  return (
    <div className="full-height-width display-flex-column no-wrap">
      
        <div className="leads-list-container display-flex-column no-wrap full-height-width position-relative">
          <div className="container overflow-y-scroll"  style={{paddingBottom:"180px"}}>
            <table className="table inventory-stock-table">
                <thead className="light-background">
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Collection Name</th>
                    <th>Company Name</th>
                    <th>Material Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {collections?.map((material: any, index: number) => {
                    return (
                      <tr key={index} className="text-center ">
                        <td>{index+1}</td>
                        <td style={{width:"5rem"}}><MaterialImage material={material}/></td>
                        <td className="heading3 color-primary">{material.collection_name}</td>
                        <td>{material.company_name}</td>
                        <td>{material.material_type}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
          </div>
        </div>
      
    </div>
  );
};

export default StockListContainer;
