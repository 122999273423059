import React from "react";

 
const SubHeader: React.FC<{
  heading:string
}> = (props) => {


  
 
  return (
    <div className="full-width padding-container space-between display-flex-row border-bottom no-wrap main-sub-header-wrapper">
      <div className="display-flex-row full-height">
        <div className="center-aligned-column">
          <div className="bg-color-primary center-aligned-column white-card no-shadow" style={{width:"28px",height:"28px"}}>
            <i className="fas fa-bars color-white font-"></i>
          </div>
        </div>
        
        <div className="heading3 center-aligned-column font-large marginleft10">{props.heading}</div>
      </div>

      <div className="full-height center-aligned-column action-icons">
        <div className="display-flex-row">
          {/* <i className="fas fa-search icon"></i> */}
          {/* <i className="fas fa-history icon "></i>
          <i className="fas fa-plus-circle icon"></i>
          <div className="marginleft10 marginRight10" style={{border:"1px solid #ddd"}}></div>
          <i className="fas fa-bell icon no-margin"></i> */}
          {/* <i className="fas fa-calendar-week icon "></i> */}
          <div className="marginleft10 marginRight10" style={{border:"1px solid #ddd"}}></div>
          
          <div className="logo center-aligned-column" style={{padding:"0 10px"}}>
            <img src="/assets/images/logos/estre.png" height={40}  alt="" />
          </div>

          <div className="marginleft10 marginRight10" style={{border:"1px solid #ddd"}}></div>


        </div>
      </div>
     
    </div> 
  );
};

export default SubHeader;
