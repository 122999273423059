import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import {
  hideComponentLoader,
  showComponentLoader,
  showToast,
  updateLoaderProgress,
} from "../../../../../../utils/UI_methods/global";
import { getNextArea } from "../../../../../../utils/customizer/helper";
import {
  checkIfLastElement,
  getArrayOfDistinctValuesFromObject,
  getObjectByParameter,
  logger,
  waitFor
} from "../../../../../../utils/methods";
import {
  updateConfig
} from "../../../../../../utils/moodboard/helper";
import { configuration } from "../../../../../../utils/moodboard/moodboard";
import Summary from "../../../../../common/shared-components/Share/Summary";
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import SummaryLink from "../../../../../common/ui-components/links/SummaryLink";
import Status from "../common/Status";
import Confirmation from "../controls/Confirmation";

const TopRight: React.FC<{
  configObj: any;
  currArea: string;
  projectAreaCategories: any;
  categoriesStatus: any;
  currentConfigObject: any;

  updateProjectAreaCategories: () => void;
  areasList: Array<any>;
  updateCurrArea: (areaName: string, areaId: number) => void;
}> = (props) => {
  const [nextStep, setNextStep] = useState("Next");
  const [isOpenSummary, setIsOpenSummary] = useState(false);
  const [showPricing, setShowPricing]: any = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(()=>{
    if(showPricing){
      logger?.info("editcollection","viewpricing",`View pricing`)
    }
  },[showPricing])

  useEffect(() => {
    let sortedAreasList = getArrayOfDistinctValuesFromObject(
      props.areasList,
      "area_name"
    );
    sortedAreasList.sort((a, b) => (a > b ? 1 : -1));
    if (checkIfLastElement(sortedAreasList, props.configObj.areaName)) {
      setNextStep("Finalize");
    } else {
      setNextStep("Next");
    }
  }, [props.currArea]);

  function saveAndNextHandle() {
    if (!configuration.images.length) {
      showToast("No item added", 2000, "error");
      return;
    }

    if (nextStep.toLowerCase() === "finalize") {
      setShowConfirmationModal(true);
      // setShowPricing(true)
      return;
    }
    setShowConfirmationModal(true);
  }

  async function gotoNextArea() {
    setShowConfirmationModal(false);

    if (nextStep.toLowerCase() === "finalize") {
      setShowPricing(true);
      return;
    }

    let sortedAreasList = getArrayOfDistinctValuesFromObject(props.areasList,"area_name");
    sortedAreasList.sort((a, b) => (a > b ? 1 : -1));

    setNextStep("Next");

    let nextArea = getNextArea(sortedAreasList, props.configObj.areaName,"moodboard");
    let area = getObjectByParameter(props.configObj?.data.areasList,"area_name",nextArea);
   

    updateConfig(props.configObj.areaName, props.configObj.areaId, () => {});

    logger?.info("editcollection","updateconfig",`Config updated for area: ${props.configObj.areaName}`)

    showComponentLoader("moodboardSaveLoader");
    updateLoaderProgress("moodboardSaveLoader", 1, 1, "Saving..");
    await waitFor(750);
    updateLoaderProgress("moodboardSaveLoader", 1, 1, "Loading " + nextArea);
    await waitFor(750);
    hideComponentLoader("moodboardSaveLoader");

    props.configObj.functions.setCurrSelectedStep(nextArea);
    props.updateCurrArea(area.area_name, area.id);

    if (checkIfLastElement(sortedAreasList, area.area_name)) {
      setNextStep("Finalize");
      return;
    }
  }

  return (
    <React.Fragment>
      <Loader
        id="moodboardSaveLoader"
        data={{ message: "Saving.." }}
        loaderType="dotsText"
        className="fade-background-loader"
      />

      <Confirmation
        configObj={props.configObj}
        categoriesStatus={props.categoriesStatus}
        areasList={props.areasList}
        action={gotoNextArea}
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
      />

      <div
        className="right-middle center-aligned-column hide-in-mobile full-height"
        style={{ width: "15%", minWidth: "130px", zIndex: 5, right: "0" }}>
        <div
          className="container moodboard-product-status-table  full-width"
          style={{ height: "55%" }}
          id="moodboardProductStatusTable">
          <Status
            configObj={props.configObj}
            currArea={props.currArea}
            projectAreaCategories={props.projectAreaCategories}
            categoriesStatus={props.categoriesStatus}
            areasList={props.areasList}
            updateCurrArea={props.updateCurrArea}
          />

          <div className="displ marginTop10 hide-in moodboard-price-link-wrapper">
            <div
              className="center-aligned-row shadow-medium white-card bg-color-ternary"
              id="summaryButtonDesktop"
              style={{ padding: "0.8rem" }}
              onClick={() => setShowPricing(true)}>
              <span className="center-aligned-column color-danger heading1 rupee-sign marginRight5">
                ₹
              </span>
              <span className="heading1 font-md-small letter-spacing color-black color-white click-effect marginleft5 total-price">
                0
              </span>
            </div>

            <div
              id="saveButtonDesktop"
              className="center-aligned-row shadow-medium marginTop10 white-card bg-color-primary"
              style={{ padding: "0.8rem" }}
              onClick={saveAndNextHandle}>
              <span className="heading1 font-md-small color-white letter-spacing click-effect marginleft5 ">
                Save & {nextStep}
              </span>
              <span className="center-aligned-column marginleft5">
                <i className="fas fa-chevron-right color-white"></i>
              </span>
            </div>
          </div>
        </div>
      </div>


      <div className="zindex1 pointer  bottom-right" id="">
        <div className="hide-in-desktop moodbaord-summary-links ion-margin">
          <div className="display-flex-row" style={{ padding: "1rem" }}>
            <SummaryLink
              currentConfigObject={props.currentConfigObject}
              moduleName="moodboard"
            />

            <div className="marginleft5" style={{ marginTop: "2px" }}>
              <div
                id="saveButtonMobile"
                className="shadow-light rue-sign border op-btn op-btn-primary color-white letter-spacing click-effect heading1 letter-spacing"
                style={{
                  padding: "0.7rem 1.2rem",
                  borderRadius: "20px",
                  fontSize: "0.9rem",
                }}
                onClick={saveAndNextHandle}>
                Save & Next{" "}
                <i className="fas fa-chevron-right font-small color-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <IonModal
        cssClass="share-modal"
        onDidDismiss={() => setShowPricing(false)}
        isOpen={showPricing}>
        <Summary
          currentConfigObject={props.currentConfigObject}
          setShowPricing={setShowPricing}
          moduleName={"moodboard"}
        />
      </IonModal>
    </React.Fragment>
  );
};

export default TopRight;
