import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { applicationConfig, getFilteredArray, getObjectArrayOfDistictValues } from "../../../../../utils/methods";
import { getFilteredProductForDemo } from "../../../../../utils/store/storeConfiguration";

 

const SubHeader: React.FC<{
}> = (props) => {

  const[categories,setCategories] = useState([])

  const history = useHistory()

  useEffect(()=>{
    if(applicationConfig?.data){
      let categoryList:Array<any> = getObjectArrayOfDistictValues(getFilteredProductForDemo(),"category_name")
      categoryList = categoryList.slice(0,7)
      setCategories(categoryList)
    }
  },[])

  

  function pointerMoveAction(event:any) {
  }

 
  function gotoCategory(category:string){
    history.replace(`/store/products/${category}`)
  }

  function goToCategories(){
    history.replace(`/store/products/`)
  }
 
  return (
    <div className="main-sub-header-wrapper display-none hide-in-mobile padding-container position-relative no-wrap" style={{justifyContent:"space-between"}}>
        <div className="display-flex-row item center-aligned-column no-wrap">
          <span className="ion-padding-end"><i className="fas fa-bars color-ex-light font-normal"></i></span>
          <span className="text text-center">All Categories</span>
          <span className="ion-padding-start"><i className="fas fa-chevron-down color-ex-light font-normal"></i></span>
          <div className="inner-content left-bottom shadow-medium border">
            {categories.map((category:any,index:number)=>{
                return(
                  <div className={`sub-menu-item border-bottom`} onClick={()=>gotoCategory(category.category_name)} key={index}>
                    <span className="heading3 font-md-small pointer-none color-white">{category.category_name}</span>
                  </div>
                )
            })}
          </div>
        </div>

        {categories.map((category:any,index:number)=>{
          // const subCategories = getFilteredArray(applicationConfig?.data?.subCategories,"category_name",category.category_name)
          return(
            <div onClick={()=>gotoCategory(category.category_name)} className={`item pointer center-aligned-column ${index===0?" --is-active":""}`} key={index}>
              <span className="text">{category.category_name}</span>
              {/* <div className="inner-content left-bottom shadow-medium border">
                {subCategories.map((subCategory:any,j:number)=>{
                    return(
                      <div className={`sub-menu-item border-bottom`}  onClick={()=>gotoCategory(subCategory.category_name)}  key={j}>
                        <span className="heading3 font-md-small color-white">{subCategory.sub_category_name}</span>
                      </div>
                    )
                })}
              </div> */}
            </div>
          )
        })}

      </div>
  );
};

export default SubHeader;
