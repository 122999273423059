import React, { useEffect, useState } from "react";
import { applicationConfig, getArrayOfDistinctValues, getObjectArrayOfDistictValues } from "../../../../../utils/methods";

import $ from "jquery";
import { useHistory } from "react-router";
import { getFilteredProductForDemo } from "../../../../../utils/store/storeConfiguration";
import Categories from "../../../../common/shared-components/products/Categories";
import NotificationBar from "../../../../common/ui-components/alerts/SubscriptionAlert";
import { Carousel } from "react-responsive-carousel";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import StoreProductSearch from "../common/StoreProductSearch";
 

const StoreCategories: React.FC<{
  configObj:any
}> = (props) => {

  const[categories,setCategories] = useState([])
  const[productsList,setProductsList] = useState(getFilteredProductForDemo() || [])
  const[isOpenSearch,setIsOpenSearch] = useState(false)
  const[clientName] = useState(applicationConfig.clientName)
  const[isAllowedBanner] = useState(clientName === "SheeshamFurniture" || clientName === "TheLevelFurniture")

  const history = useHistory()

  useEffect(()=>{
    // let categoryList:Array<any> = props.configObj?.data.categories || []
    let categoryList:Array<any> = getObjectArrayOfDistictValues(productsList,"category_name")
    setCategories(categoryList)
  },[props.configObj,productsList])

  function gotoCategory(category:string){
    history.replace(`/store/products/${category}`)
  }


  function ChangeProductCardSize(event:any) {
    $(".product-card-size-button").removeClass("--is-active")
    $(event.target).addClass("--is-active")

    $(".category-details-wrapper").removeClass("image-small")
    $(".category-details-wrapper").removeClass("image-medium")
    $(".category-details-wrapper").removeClass("image-large")

    const value = $(event.target).attr("data-value")
    $(".category-details-wrapper").addClass(value)
  }


  function TopFilterHeader() {
    return(
      <div className="full-width display-flex-row padding-container space-between marginTop10">
        <div className="padding5">
          <div className="heading1 font-large">Top product categories</div>
          <div className="heading4 font-normal">Select any categories to view the products</div>
        </div>
        <div className="center-aligned-column marginRight10">
          <div className="">
            <Button2
              configObj={{id:""}}
              action={()=>{setIsOpenSearch(true)}}
              label={"Search Product"}
              fontAwesomeIcon={"fas fa-search color-black"}
              iconPos="left"
              classNames={"shadow-medium no-border hide-button-text-in-mobile bg-color-primary button-medium margin0"}
            />
          </div>
        </div>
      </div>
    )
  }

  function WebsiteHeader() {
    return(
      <div className="full-width padding10">
        <div className="full-width white-card shadow-medium">
        <Carousel dynamicHeight={false} infiniteLoop={true} showThumbs={false} autoPlay={true}>
            <div>
                <img style={{height:"20rem",objectFit:"cover"}} src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/${applicationConfig.clientName}/Banner1.jpeg`} />
            </div>
            <div>
                <img style={{height:"20rem",objectFit:"cover"}} className="full-height" src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/${applicationConfig.clientName}/Banner2.jpeg`} />
            </div>
        </Carousel>
        </div>
      </div>
    )
  }
 
  function WebsiteHeading() {
    return(
      <div className="full-width center-aligned-row marginTop10">
        <div className="padding5">
          <div className="heading3 text-center font-large">Top Product Categories</div>
          <div className="heading4 text-center font-normal">Select any categories to view the products</div>
        </div>
      </div>
    )
  }
 
  return (
    <>
    {isAllowedBanner?
    <WebsiteHeader/>
    :
    <TopFilterHeader/>
    }
      <div className="products-categories-wrapper padding10 marginTop10 center-aligned-row hide-scrollbar  fill-available-height" style={{marginTop:"-0.7rem"}}>
      <StoreProductSearch isOpen={isOpenSearch} setIsOpen={setIsOpenSearch} />
        
        <div className="white-card shadow-medium">

        {isAllowedBanner?
        <WebsiteHeading/>
        :
        null
        }


            <Categories     
              categories={applicationConfig?.clientName === "AttalsFurnitureWorks"?["Sofa"]:getArrayOfDistinctValues(categories,"category_name")}
              updateSubCategories={gotoCategory}
            />
        </div>
        
      </div>
    </>
  );
};

export default StoreCategories;
