
export class MaterialCartConfig{
    companyName:string = ""
    id:number = 0
    collectionName:string = ""
    materialCode:string = ""
    length:number = 0

    constructor(material:any){
        this.companyName = material.company_name
        this.collectionName = material.collection_name
        this.materialCode = material.material_code
        this.id = material.material_id || material.id
        this.length = material.length
    }
}

