import $ from "jquery";
import React, { useEffect, useState } from "react";
import Alert from "./common/ui-components/alerts/Alert";
import Input from "./common/ui-components/input/Input";
import { signin, updateUser } from "../services/api";
import { showAlert, showGlobalToast } from "../utils/UI_methods/global";
import Button2 from "./common/ui-components/buttons/Button2";
 
 


 
const PasswordResetForm: React.FC<{
    onSuccess:()=>void
}> = (props) => {

    const[alertId] = useState("dfgsrsgsdf")
    const[users,setUsers] = useState([])
    const[isManager,setIsManager] = useState(false)



    function submitHandle() {
        const username:any = $("#reset_username").val()
        const password:any = $("#reset_old_password").val()
        const newPassword = $("#reset_new_password").val()
        const confirmPassword = $("#reset_confirm_password").val()

        if(newPassword !== confirmPassword){
            showAlert(alertId,"Password mismatch","error")
            return
        }

        
        if(!username || !password){
            showAlert(alertId,"username password required","error")
            return
        }

        signin(username,password).then(data=>{
            if(!data.data.error){
                const user = data.data.user
                updateUser({id:user.userId,password:newPassword}).then((response:any)=>{
                    if(!response.data.error){
                        props.onSuccess()
                        showGlobalToast("Password has been changed successfully")
                    }else{
                        showAlert(alertId,response.data.message,"error")
                    }
                }).catch(err=>{
                    console.log(err)
                })
            }else{
                showAlert(alertId,data.data.message,"error")
            }
        }).catch(err=>{
            console.log(err)
        })
    }
 

  


  return (

    <div className={`full-width overflow-y-scroll full-height`}>

        <div className="middle width70">
        <Alert id={alertId}/>

        <div className={`input-container no-wrap`}>
            <div className="position-relative">
                <Input
                    type="text"
                    id="reset_username"
                    label="Username"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={``}
                    className=""
                />
            </div>
        </div>

        <div className={`input-container no-wrap`}>
            <div className="position-relative">
                <Input
                    type="text"
                    id="reset_old_password"
                    label="Old password"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={``}
                    className=""
                />
            </div>
        </div>


        <div className={`input-container no-wrap`}>
            <div className="position-relative">
                <Input
                    type="text"
                    id="reset_new_password"
                    label="New password"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={``}
                    className=""
                />
            </div>
        </div>


        <div className={`input-container no-wrap`}>
            <div className="position-relative">
                <Input
                    type="text"
                    id="reset_confirm_password"
                    label="Confirm new password"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={``}
                    className=""
                />
            </div>
        </div>

        
        <div className="full-width ion-margin-top">
            <Button2
              configObj={{id:""}}
              action={submitHandle}
              label={"Confirm reset"}
              fontAwesomeIcon={"color-danger"}
              iconPos="left"
              classNames={"shadow-light text-center margin0 full-width  button-medium bg-color-primary"}
            />
          </div>

        </div>
      
    </div>
  );
};

export default PasswordResetForm;
