import React from "react";
import MainLayout from "../../templates/layouts/page/MainLayout";
import UsersContainer from "./UsersContainer";


const Users: React.FC<{
  configObj: any;
}> = (props) => {

 





  function HeaderLeftComponent() {
    return (
      <div className="brand">
        {/* <img src="/assets/images/opus.png" alt="Opus"/> */}
        <div className="heading font-medium">Users</div>
      </div>
    );
  }

  function MainContent() {
    return (
      <UsersContainer
      configObj={props.configObj}
      />
    );
  }


  return (
    <React.Fragment>
    <MainLayout 
      configObj={props.configObj}
      CardsContainer={MainContent}
      HeaderLeftContent={HeaderLeftComponent}
      HeaderMiddleContent={null}
      contentWrapperId="mainUsersContentWrapper"
    />
  </React.Fragment>
  )
};

export default Users;
