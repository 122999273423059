import React from "react";

const MenuHeader: React.FC<{
  action:()=>void
  label:string
}> = (props) => {

  return (
    <div className="pointer shadow-medium  position-relative display-flex-row space-between padding1rem border-bottom full-width" >
        <span className="color-black heading3" onClick={props.action}>
          <i className="fas fa-chevron-left color-danger marginRight5 shadow-light white-card" aria-hidden="true" style={{padding:"7px 10px"}}></i>
          {props.label}
        </span>
        <div className="full-width top-left padding5 moodboard-product-search-wrapper">
        </div>

    </div>
  
  );
};

export default MenuHeader;
