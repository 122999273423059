import React, { useEffect, useState } from "react";
import { getArrayOfDistinctValues, getObjectByParameter } from "../../../../../../../../utils/methods";
import { getTodayTimestampOnlyDate, getTodayTimestampWithDayDifference } from "../../../../../../../../utils/store/crm/calendar";
import { getFormatedDate, getOtherHeadingPrefix, getWeekHeadingPrefix, touchDeviceActions } from "../../../../../../../../utils/store/crm/crm";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import { Customer } from "../../../../../../../../utils/store/storeConfiguration";
import Modal2 from "../../../../../../../common/shared-components/Modals/Modal2";
import Loader from "../../../../../../../common/shared-components/main-ui/Loader";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import DropDown from "../../../../../../../common/ui-components/filters/DropDown2";
import Select from "../../../../../../../common/ui-components/input/Select";
import ControlsTab from "../../../../../../../common/ui-components/tabs/ControlsTab";
import StartActivity from "../activities/StartActivity";
import UpdateLead from "../createlead/UpdateLead";
import EditLead from "./EditLead";
import LeadCardFull from "./LeadCardFull";

 
const LeadsList: React.FC<{
  filteredLeads:any
  setCurrLead:(lead:any)=>void
  refreshLeads:()=>void
  setCurrTab:(val:string)=>void
  activeCustomer:any
  setActiveCustomer:(customer:Customer)=>void
  currSelectedTime:string
  filterLeadsWithTime:(tabname:string,list:any,time:string)=>void
  currTab:string
  tabsList:Array<any>
  searchedLeadsList:Array<any>
  setSearchedLeadsList:(list:any)=>void
}> = (props) => {


  // const[todayLeads,setTodayLeads] = useState([])
  // const[otherLeads,setOtherLeads] = useState([])


  const[allTimestamps,setAllTimestamps] = useState([])

  const[currSelectedLead,setCurrSelectedLead] = useState(null)


  // const todayTimeStamp = getTodayTimestampOnlyDate()

  const[isOpenStartActivity,setIsOpenStartActivity] = useState(false)
  const[currActivity,setCurrActivity] = useState("")
  const[isOpen,setIsOpen] = useState(false)

  const[isEditLeadOpen,setIsEditLeadOpen] = useState(false)
  const[editLeadView,setEditLeadView] = useState("calendar")


  useEffect(()=>{
    // if(props.filteredLeads?.length){
    //   let todayActivities = []
    //   todayActivities = CRM.data.activitiesList.filter(currActivity => currActivity.start_date === todayTimeStamp && currActivity.status === 1)
    //   let leadIdList = getArrayOfDistinctValues(todayActivities,"lead_id")
    //   let todayLeadsList = []
    //   let otherLeadsList = []
    //   if(props.currTab === "Visited"){
    //     todayLeadsList = props.filteredLeads.filter(currLead => String(currLead.date_of_creation) === String(todayTimeStamp))
    //     otherLeadsList = props.filteredLeads.filter(currLead => String(currLead.date_of_creation) !== String(todayTimeStamp))
    //   }else{
    //     todayLeadsList = props.filteredLeads.filter(currLead => leadIdList.includes(currLead.lead_id))
    //     otherLeadsList = props.filteredLeads.filter(currLead => !leadIdList.includes(currLead.lead_id))
    //   }
    //   setTodayLeads(todayLeadsList)
    //   setOtherLeads(otherLeadsList)
    // }
    // console.log(props.filteredLeads)
  },[props.filteredLeads])


  useEffect(()=>{
    let timestampsList = []
    for (let i = 0; i < 7; i++) {
      let delta = +i
      if(props.currTab.includes("New") || props.currTab.includes("Visited")){
        delta = -i 
      }
      let date = getTodayTimestampWithDayDifference(delta)
      timestampsList.push(date)     
    }
    timestampsList.push("other")
    setAllTimestamps(timestampsList)
  },[props.currTab])


  function onChangeTime(value:string) {
    props.filterLeadsWithTime(null,null,value)
  }


  function startActivityHandle(lead:any,activityName:string) {
    setCurrSelectedLead(lead)
    touchDeviceActions(activityName,lead.mobile)
    setCurrActivity(activityName)
    // CRM.crmApi.leads.updateLead(props.lead.lead_id,{isViewed:1})
    setIsOpenStartActivity(true)
    updateLeadStatus(lead)
  }

  
  function updateLeadHandle(lead:any) {
    setCurrSelectedLead(lead)
    setIsOpen(true)
  }




  function openLeadDetailsHandle(lead:any,view:string) {
    setCurrSelectedLead(lead)
    setIsEditLeadOpen(true)
    setEditLeadView(view)
    updateLeadStatus(lead)
  }


  function SearchResult() {
    return(
      <div className="full-width full-height hide-scrollbar">
        <div className="display-flex-row no-wrap paddingTop5">
          <div className="marginTop10">
              <Button2
                  configObj={{id:""}}
                  action={()=>{props.setSearchedLeadsList([])}}
                  label={"All leads"}
                  fontAwesomeIcon={"fas fa-arrow-left color-dark"}
                  iconPos="left"
                  classNames={"no-shadow marginleft5"}
              />
            </div>
            {/* <div className="heading3 padding5 text-center full-width center-aligned-column">Showing {searchedLeadsList.length} results</div> */}
          </div>
          <div className="marginTop10">
            {props.searchedLeadsList?.map((lead:any,index:number)=>{
              return(
                <LeadCardFull
                  lead={lead}
                  openLeadDetailsHandle={openLeadDetailsHandle}
                  setCurrLead={props.setCurrLead}
                  refreshLeads={props.refreshLeads}
                  activeCustomer={props.activeCustomer}
                  setActiveCustomer={props.setActiveCustomer}
                  startActivityHandle={startActivityHandle}
                  updateLeadHandle={updateLeadHandle}
                />
              )
            })}
        </div>
      </div>
    )
  }

  async function updateLeadStatus(lead:any) {
    if(lead.is_viewed === 0){
      await CRM.crmApi.leads.updateLead(lead.lead_id,{isViewed:1})
      // props.refreshLeads()
    }
  }

  return (
    <div className="full-height-width position-relative display-flex-column no-wrap">

        <Loader
          id="leadsListLoader"
          data={{message:"Fetching data"}}
          loaderType="spinner"
          className="fade-background-loader"
        />


        <UpdateLead isOpen={isOpen} setIsOpen={setIsOpen} lead={currSelectedLead} refreshLeads={props.refreshLeads} />

        <StartActivity currActivity={currActivity} setCurrTab={props.setCurrTab} refreshLeads={props.refreshLeads} isOpen={isOpenStartActivity} setIsOpen={setIsOpenStartActivity} lead={currSelectedLead} />
        
        <Modal2
          isOpen = {isEditLeadOpen}
          Content = {()=>(
              <EditLead view={editLeadView} lead={currSelectedLead} refreshLeads={props.refreshLeads} setIsEditLeadOpen={setIsEditLeadOpen}/>
          )}
          onDismiss = {()=>setIsEditLeadOpen(false)}
          classNames="large-x-modal"
          id=""
        />
        
        {!props.searchedLeadsList.length?
        <div className="full-width tabs-container leads-tab-container">
          <ControlsTab
            configObj={{}}
            tabItems={props.tabsList}
            currSelectedValue={props.currTab}
            action={props.setCurrTab}
            theme="black"
          />
        </div>
        :null}

      {props.searchedLeadsList.length?
        <SearchResult/>
      :
      


      <div className="leads-list-container full-height-width position-relative">

        

          <div className="header-container display-none space-between">
            <div className="sort-by marginleft5">
              <DropDown
                classNames={""}
                values={["Last created"]}
                currSelectedValue={"Last created"}
                action={()=>{}}
                label="Sort by"
              />
            </div>
            <div className="date-filter">
                <Select
                  id=""
                  label=""
                  isMandatory={false}
                  onChange={onChangeTime}
                  options={["All","Today"]}
                  className=""
                  value={props.currSelectedTime}
                />
            </div>
          </div>

        {allTimestamps.map((currTimestamp:any,index:number)=>{
          // let activities = CRM.data.activitiesList.filter(currActivity => currActivity.start_date === currTimestamp && currActivity.status === 1)
          // let leadIdList = getArrayOfDistinctValues(activities,"lead_id")
          // let leadsListResult = props.filteredLeads.filter(currLead => leadIdList.includes(currLead.lead_id))
          let leadsListResult = props.filteredLeads.filter(currLead => {
            let startDate = getObjectByParameter(CRM.data.activitiesList,"activity_id",currLead.current_activity_id)?.start_date
            if(currTimestamp === "other"){
              return !allTimestamps.includes(startDate)
            }
            return String(startDate) === String(currTimestamp)
          })
          if(props.currTab.includes("Visited") || props.currTab.includes("Placed")){
            if(currTimestamp === "other"){
              leadsListResult = props.filteredLeads.filter(currLead => !allTimestamps.includes(currLead.date_of_creation))
            }else{
              leadsListResult = props.filteredLeads.filter(currLead => String(currLead.date_of_creation) === String(currTimestamp))
            }
          }
          return(
            <div className={`border marginBottom5 ${leadsListResult.length?"":" display-none"}`} key={index}>
              {currTimestamp === "other"?
                <div className="date-header display-flex-row no-wrap padding10 ">
                  <div className="heading3 font-md-small color-dark">
                    {`${getOtherHeadingPrefix(props.currTab)} other dates`}
                  </div>
                  <div className="center-aligned-column">
                      <span className="heading4 text-ellipsis count-badge color- badge bg-color-dark center-aligned-column padding0 font-ex-small color-white marginleft5" style={{position:"relative",top:"unset",right:"unset"}}>{leadsListResult.length}</span>
                  </div>
                </div>
              :

                <div className="date-header display-flex-row no-wrap padding10 ">
                  <div className="heading3 font-md-small color-dark">
                  {getWeekHeadingPrefix(props.currTab) + " " +getFormatedDate(currTimestamp)} {currTimestamp === getTodayTimestampOnlyDate()?" (Today)":""}
                  </div>
                  <div className="center-aligned-column">
                      <span className="heading4 text-ellipsis count-badge color- badge bg-color-dark center-aligned-column padding0 font-ex-small color-white marginleft5" style={{position:"relative",top:"unset",right:"unset"}}>{leadsListResult.length}</span>
                  </div>
                </div>
                 
              }
              {leadsListResult?.map((lead:any,index:number)=>{
              return(
                <div className="full-width" key={index}>
                  <LeadCardFull
                    lead={lead}
                    openLeadDetailsHandle={openLeadDetailsHandle}
                    setCurrLead={props.setCurrLead}
                    refreshLeads={props.refreshLeads}
                    activeCustomer={props.activeCustomer}
                    setActiveCustomer={props.setActiveCustomer}
                    startActivityHandle={startActivityHandle}
                    updateLeadHandle={updateLeadHandle}
                  />
                  </div>
                )
              })}
            </div>
          )
        })}


        

        {props.filteredLeads?.length?
          <div className="container leads-card-wrapper"  style={{paddingBottom:"120px"}}>

              {/* {todayLeads.length?
              <div className="border marginBottom5">
                <div className="heading3 bg-color-ternary padding10">{getHeadingForTodayContainer()}</div>
                {todayLeads?.map((lead:any,index:number)=>{
                return(
                  <div className="full-width" key={index}>
                    <LeadCardFull
                      lead={lead}
                      openLeadDetailsHandle={openLeadDetailsHandle}
                      setCurrLead={props.setCurrLead}
                      refreshLeads={props.refreshLeads}
                      activeCustomer={props.activeCustomer}
                      setActiveCustomer={props.setActiveCustomer}
                      startActivityHandle={startActivityHandle}
                      updateLeadHandle={updateLeadHandle}
                    />
                    </div>
                  )
                })}
              </div>
              :null} */}

              {/* {otherLeads.length?
              <div className="border">
                <div className="heading3 bg-color-ternary padding10">{getHeadingForOtherContainer()}</div>
                {otherLeads?.map((lead:any,index:number)=>{
                  return(
                    <div className="full-width" key={index}>
                      <LeadCardFull
                        lead={lead}
                        openLeadDetailsHandle={openLeadDetailsHandle}
                        setCurrLead={props.setCurrLead}
                        refreshLeads={props.refreshLeads}
                        activeCustomer={props.activeCustomer}
                        setActiveCustomer={props.setActiveCustomer}
                        startActivityHandle={startActivityHandle}
                        updateLeadHandle={updateLeadHandle}
                      />
                      </div>
                    )
                  })}
              </div>
              :null} */}
            
          </div>
        :
        <div className="middle" >
          <div className="heading4 font-normal white-card no-shadow" style={{border:"1px dashed var(--op-color-dark)",padding:"25px 25px"}}>Leads not available</div>
        </div>
        }
      </div>


      }
      
      
    </div>
  );
};

export default LeadsList;
