import React, { useEffect, useState } from "react";
import { getFilteredArray, sortAreasByPriority, stringWithoutWhiteSpace } from "../../../../../../utils/methods";
import { centerElement } from "../../../../../../utils/UI_methods/global";
import {
  areaInit
} from "../../../../../../utils/virtualtour/virtualtour";
import Button2 from "../../../../../common/ui-components/buttons/Button2";

const Areas: React.FC<{
  configObj: any;
  currentConfigObject: any;
  setCurrAreaName: (area: string) => void;
}> = (props) => {
  const [areas, setAreas]: any = useState([]);
  const [currSelectedAreaId, setCurrSelectedAreaId] = useState<number>(0);
  const [currSelectedArea, setCurrSelectedArea] = useState<string>("");
  const [productsList, setProductsList] = useState<any>([]);


  useEffect(() => {
    //  if(props.configObj){
    let areas = props.configObj?.getAreas();
    areas = sortAreasByPriority(areas);
    setAreas(areas);
    // console.log(props.configObj)
    // let currArea = areas[0]?.area_name
    // let currAreaId = areas[0]?.area_id
    // props.configObj?.setCurrArea(currArea)
    // props.configObj?.setCurrAreaId(currAreaId)
    // setCurrSelectedArea("Foyer")

    areaInit(setCurrSelectedArea);

    if (props.configObj?.areaName?.length) {
      setTimeout(() => {
        centerElement("virtualTourAreaWrapper", props.configObj?.areaName);
        setCurrSelectedArea(props.configObj?.areaName);
      }, 200);
    }

    props.configObj.setFunctionRef("virtualtour",{setCurrSelectedArea:setCurrSelectedArea})


    //  }
  }, [props.configObj]);

  useEffect(() => {
    if (currSelectedArea.length) {
      centerElement("virtualTourAreaWrapper", currSelectedArea);
      props.currentConfigObject?.setCurrArea(currSelectedArea);
      props.setCurrAreaName(currSelectedArea);

      // let productList = getFilteredArray(props.currentConfigObject?.projectProductsList,"area_name",currSelectedArea);
      // if(productList.length){
      //   productList = productList.filter(product=>product.virtual_tour_data!=null) || []
      //   setProductsList(productList)
      // }
    }
  }, [currSelectedArea, props.currentConfigObject]);

 

  // const updateCurrArea = (areaName: string, areaId: number) => {
  //   props.configObj?.setCurrArea(areaName);
  //   props.configObj?.setCurrAreaId(areaId);
  //   setCurrSelectedArea(areaName);
  //   setCurrSelectedAreaId(areaId);
  //   updateArea(areaName);
  // };


  return (
    <>
      {productsList?.map((product:any,index:number)=>{
        let elementId = `tooltip_${stringWithoutWhiteSpace(product.product_name)}`
        return(
          <div key={index}>
          <div id={elementId}  className="product-tooltip">
            <div className="out">
              <div className="in center-aligned-column">
                <div className="image">
                  <i className="fas fa-info pointer-none color-black " style={{opacity:0.5}}></i>
                </div>
              </div>
            </div>
            <div className="tip">
              <p>{product.category_name} 
              {/* <div onClick={()=>gotoCustomizer(product)} className="font-small color-primary display-flex-row no-wrap pointer">Customize <i className="fas fa-chevron-right font-small center-aligned-column color-primary"></i> </div> */}
              </p>
              <div className="product-details">
                <div>Name : {product.product_name}</div> 
                <div>Dimenstion : <span className="rupee-sign color-danger">512 X 256</span></div> 
                <div className="ion-margin-top center-aligned-row">
                  <Button2
                    configObj={props.configObj}
                    action={()=>{}}
                    label={"Configure"}
                    fontAwesomeIcon={"fas fa-cube color-primary"}
                    iconPos="left"
                    classNames={"button-small shadow-light coming-soon"}
                  />
                </div> 
              </div>
            </div>
          </div>
          </div>
        )
      })}
  

      {/* <div className="configuration-area-container hide-scrollbar tour-guide-element position-relative" id="virtualTourAreaWrapper">
          {areas?.length?
            areas.map(
                (area: any, index: number) => {
                  let classNames = [];
                  classNames.push("configuration-area white-card shadow-light");
                  if (area.area_name === currSelectedArea) {
                    classNames.push("--is-active");
                  }
                  return (
                    <div
                      key={index}
                      className={classNames.join(" ")}
                      style={{ margin: "0" }}
                      onClick={(event)=>{updateCurrArea(area.area_name,area.area_id)
                        centerElement("virtualTourAreaWrapper",area.area_name)}
                      }
                       >
                      <div className="item">
                        {area.area_name}
                      </div>
                    </div>
                  );
                }
              )
            : null}
       
        </div> */}

    </>
  );
};

export default Areas;
