import React, { useState } from "react";
import { applicationConfig, getObjectByParameter } from "../../../../../utils/methods";
import { createProductForm } from "../../../../../utils/products/createProductForm";
import { filterCategoryForStore } from "../../../../../utils/products/createproduct";
import SelectInput from "../../../../common/ui-components/input/SelectInput";
 

const SelectCategoryForm: React.FC<{
    productDetails:createProductForm
}> = (props) => {

    const[categories] = useState(filterCategoryForStore(applicationConfig?.data?.categories))

    function categoryChangeHandle(value:any) {
        props.productDetails.categoryId = value
        props.productDetails.categoryName = getObjectByParameter(applicationConfig?.data?.categories,"id",value).category_name
    }


    return (
        <div id="selectCategoryForm" className="width50 create-custom-order-wrapper" style={{display:"none"}}>
            <div className="store-color-primary text-center heading3 padding10 font-medium">Select product category</div>
            <SelectInput
                id="categoryAddOrder"
                label=""
                isMandatory={false}
                onChange={(value)=>{categoryChangeHandle(value)}}
                options={categories}
                className="input-large full-width"
                value={""}
                config={{value:"id",displayName:"category_name"}}
            />
        </div>
    );
};

export default SelectCategoryForm;
