import { IonIcon } from '@ionic/react';
import { cog } from 'ionicons/icons';
import React from 'react';
import { loadDefaultModuleToScene, loadModulsesFromLocalStorage } from '../../../../../utils/customizein3d/modules/customizein3d';


const ConfigLoadOptions: React.FC<{
  modulesConfigurtion:any
}> = (props) => {


    return(
      <div className="full-height-width config-options-container" id="configOptionsWindow" style={{position:"fixed"}}>
      <div className="center-aligned-column full-height-width ">
          <div className="display-flex-row">
              
              <div className='ion-padding pointer center-aligned-column display-none click-effect text-center border shadow-medium' onClick={loadModulsesFromLocalStorage} style={{width:"14rem"}}>
                <div className='pointer-none'>
                  <div className='icon'>
                    <IonIcon className="color-primary marginBottom5" icon={cog} style={{fontSize:"3rem"}} />
                  </div>
                  <div className='font-normal color-white marginTop5'>
                    Load Previous
                  </div>
                </div>
                
              </div>
{/* 
              {props.modulesConfigurtion.isDefaultConfigAvailable?
              <div className='ion-padding pointer marginleft10 center-aligned-column click-effect text-center border shadow-medium' onClick={loadModulsesFromDefaultConfiguration} style={{width:"14rem"}}>
                <div className='pointer-none'>
                  <div className='icon'>
                    <IonIcon className="color-primary marginBottom5" icon={save} style={{fontSize:"3rem"}} />
                  </div>
                  <div className='font-normal color-white marginTop5'>
                    Load Default
                  </div>
                </div>
              </div>
              :null} */}

              <div className='ion-padding center-aligned-column pointer click-effect marginleft10 border bg-color-primary text-center shadow-medium' onClick={()=>loadDefaultModuleToScene()} style={{width:"14rem"}}>
                <div className='pointer-none'>
                  <div className=''>
                    <IonIcon className="color-white marginBottom5" icon={cog} style={{fontSize:"3rem"}} />
                  </div>
                  <div className='color-white font-normal marginTop5'>
                    Start From Scratch
                  </div>
                </div>
              </div>

              <div className='ion-padding center-aligned-column pointer click-effect marginleft10 border text-center shadow-medium' onClick={()=>loadDefaultModuleToScene()} style={{width:"14rem"}}>
                <div className='pointer-none'>
                  <div className=''>
                    <IonIcon className="color-white marginBottom5" icon={cog} style={{fontSize:"3rem"}} />
                  </div>
                  <div className='color-white font-normal marginTop5'>
                    Load Default
                  </div>
                </div>
              </div>

              
          </div>
      </div>
  </div>
        
    );
};
 
export default ConfigLoadOptions;
