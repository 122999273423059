import React, { useState } from "react";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import AccordionForProccess from "../../../../common/ui-components/accordion/AccordionForProccess";
import SavedAddress from "../customer/Address/SavedAddress";
import PaymentReview from "./PaymentReview";
import SelectCustomer from "./SelectCustomer";

 

const CheckoutReview: React.FC<{
  setIsOpenReview:(val:boolean)=>void
  totalPrice:number
}> = (props) => {
 
  const[isConfirmCustomer,setIsConfirmCustomer] = useState(false)
  const[isConfirmAddress,setIsConfirmAddress] = useState(false)
  

  const[currentCustomer,setCurrentCustomer] = useState(STORE.activeCustomer)
  const[deliveryAddress,setDeliveryAddress] = useState(null)
  const[isOpenAddress,setIsOpenAddress] = useState(false)
 

  function setAddressHandle(address:any) {
    setDeliveryAddress({...address})
    setIsOpenAddress(false)
    setIsConfirmAddress(true)
  }

  function CurrentCustomer() {
    return(
      <div className="display-flex-row">
        {currentCustomer?
          <div className="display-flex-row no-wrap">
            <div className="heading3 color-black font-md-small">{currentCustomer.customerName}</div>
            <div className="heading4 font-md-small color-dark marginleft5">+91 {currentCustomer.mobile}</div>
          </div>
        :null}
      </div>
    )
  }

  function DeliveryAddress() {
    return(
        <div className="">
        {deliveryAddress?
          <div className="display-flex-row no-wrap ">
          <div className="heading3 color-dark font-md-small text-ellipsis">
            {deliveryAddress.address_line_1},&nbsp;{deliveryAddress.city},&nbsp;
            {deliveryAddress.state},&nbsp;
            {deliveryAddress.country},&nbsp;
            {deliveryAddress.postal_code}
          </div>
          {/* <div className="heading4 font-md-small color-dark marginleft5">+91 {STORE.activeCustomer.mobile}</div> */}
          </div>
        :null}
        </div>
        
    )
  }

 
 



       
  return (
    <div className="full-height-width display-flex-column no-wrap position-relative">
      <div className="header bg-color-warning padding10">
        <div className="display-flex-row no-wrap space-between">
          <span className=""><i className="fas fa-chevron-left color-white"></i></span>
          <span className="heading3 color-white">Review order & Payment</span>
          <span></span>
        </div>
      </div>

    
    
      <div className="body ion-padding full-width overflow-y-scroll full-height">

        <div data-refresh={deliveryAddress?.city} className="accordion-wrapper accordion-proccess-wrapper full-width">
          <AccordionForProccess
            label="Customer session"
            SubHeader={()=>(
              <CurrentCustomer/>
            )}
            Content={()=>(
              <SelectCustomer confirmHandle={()=>{setIsConfirmCustomer(true)}}/>
            )}
            index={1}
            isOpen={!isConfirmCustomer}
            setIsOpen={()=>{setIsConfirmCustomer(false)}}
            isConfirmed={isConfirmCustomer}
          />

          <AccordionForProccess
            label="Delivery Address"
            SubHeader={()=>(
              <DeliveryAddress/>
            )}
            Content={()=>(
              <SavedAddress selectAddress={setAddressHandle}/>
            )}
            index={2}
            isOpen={isConfirmCustomer && !isConfirmAddress}
            setIsOpen={()=>{setIsConfirmAddress(false)}}
            isConfirmed={isConfirmAddress}
          />

          <AccordionForProccess
            label="Review & Payment"
            SubHeader={()=>(
              <></>
            )}
            Content={()=>(
              <PaymentReview totalPrice={props.totalPrice} paymentSuccessHandle={()=>props.setIsOpenReview(false)}/>
            )}
            index={3}
            isOpen={isConfirmCustomer && isConfirmAddress}
            setIsOpen={()=>{}}
            isConfirmed={false}
          />
        </div>
        
      </div>

    </div>
  );
};

export default CheckoutReview;
