// import * as THREE from "three"
import {
  BackSide,  BoxGeometry, Color, DirectionalLight, DoubleSide, EventDispatcher, GridHelper, Group, Mesh, MeshBasicMaterial, MeshLambertMaterial,
  MeshPhysicalMaterial,
  MeshStandardMaterial, Object3D, PlaneGeometry, PointLight, RectAreaLight, REVISION, Scene, ShaderMaterial, Vector3
} from "three";
import { addEdges, addTextToScene, getConvertedLengthBreadth, getFormatedFeetInchDimensions, getObjectByName, getObjectDimensionPosition } from "./modules/helper";
import { showFloorDimensions } from "./modules/UI_methods";
// import EnvironmentScene from "./ModelViewerEnv";
// import EnvironmentScene from "./ModelViewerEnv";
export var floor:any
export var GRID:GridHelper = null
var pointLightsGroup = new Group()
const lights = [
  {
    intensity: 50,
    position: [-16.116, 14.37, 8.208],
    scale: [0.1, 2.428, 2.739],
  },
  {
    intensity: 50,
    position: [-16.109, 18.021, -8.207],
    scale: [0.1, 2.425, 2.751],
  },
  {
    intensity: 17,
    position: [14.904, 12.198, -1.832],
    scale: [0.15, 4.265, 6.331],
  },
  {
    intensity: 43,
    position: [-0.462, 8.89, 14.520],
    scale: [4.38, 5.441, 0.088],
  },
  {
    intensity: 20,
    position: [3.235, 11.486, -12.541],
    scale: [2.5, 2.0, 0.1],
  },
  {
    intensity: 100,
    position: [0.0, 20.0, 0.0],
    scale: [1.0, 0.1, 1.0],
  },
]
  
export function addDirectionalLights(scene:any) {
    const dirLight = new DirectionalLight( 0xffffff );
    dirLight.position.set(-100,500,100);
    scene.add( dirLight );
}


export function addHemiLights(scene:any,hemiLight:any){
    hemiLight.position.set( 0, 250, 0 );
    scene.add( hemiLight );
}


export function addPointLights(scene:any,pointsLight:any) {
    pointsLight.position.set(250,250,250);
    pointsLight.castShadow = true;
    scene.add(pointsLight);
}

const GENERATED_SIGMA = 0.04;
const userData = {
  url: null,
};

export var generatedEnvironmentMap = null

export function assignTextureMap(scene:any,modelObject:any){
      // let modelObject =  scene.getObjectByName(moduleName)
      modelObject.traverse(mesh=>{
        if(mesh.isMesh){
          mesh.material = new MeshPhysicalMaterial(mesh.material)
          mesh.material.envMap = generatedEnvironmentMap.texture
          mesh.material.needsUpdate = true;
          mesh.material.userData.isAllowNormalMap = false
          if(!mesh.material?.normalMap){
            mesh.material.userData.isAllowNormalMap = true
          }
        }
      })
}


export function showGrid() {
  GRID.visible = true
}

export function hideGrid() {
  GRID.visible = false
}

export function loadGeneratedEnvironmentMap(scene:any,renderer:any,PMREMGeneratorObject:any){

  const defaultScene:any = new EnvironmentSceneOld()
  generatedEnvironmentMap = PMREMGeneratorObject.fromScene(defaultScene, GENERATED_SIGMA);
  // addMetadata(generatedEnvironmentMap.texture, null);
  // scene.add(generatedEnvironmentMap)

}


export function loadGeneratedEnvironmentMapAlt(scene:any,renderer:any,PMREMGeneratorObject:any){

  // const defaultScene = new EnvironmentScene(scene);
  // let generatedEnvironmentMapAlt = PMREMGeneratorObject.fromScene(defaultScene, GENERATED_SIGMA);
  // addMetadata(generatedEnvironmentMapAlt.texture, null);
}


export function addPointLightsToScene(scene:any,object:any,intensity:number = 100) {
  let data = getObjectDimensionPosition(null,object)
  let positions  = data.positions
  let posY = 7
  let delta = 0
  const topLights = [
    {
      intensity: intensity,
      position: new Vector3(positions.min.x + delta,posY,positions.min.z + delta),
    },

    {
      intensity: intensity,
      position: new Vector3(positions.min.x + delta,posY,positions.max.z + delta),
    },
    {
      intensity: intensity,
      position: new Vector3(positions.max.x + delta,posY,positions.min.z + delta),
    },
    {
      intensity: intensity,
      position: new Vector3(positions.max.x + delta,posY,positions.max.z + delta),
    },
  ]

  for (const light of topLights) {
    const mainLight = new PointLight(0xffffff, light.intensity, 28, 2);
    mainLight.position.copy(light.position);
    pointLightsGroup.add(mainLight);
  }

  scene.add(pointLightsGroup)
}


export function updatePointLightsPosition(object:any) {
  let data = getObjectDimensionPosition(null,object)
  let positions  = data.positions
  let posY = 7
  let delta = 0
  const lights = [
    {
      position: new Vector3(positions.min.x + delta,posY,positions.min.z + delta),
    },
    {
      position: new Vector3(positions.min.x + delta,posY,positions.max.z + delta),
    },
    {
      position: new Vector3(positions.max.x + delta,posY,positions.min.z + delta),
    },
    {
      position: new Vector3(positions.max.x + delta,posY,positions.max.z + delta),
    },
  ]
  for (let i = 0; i < pointLightsGroup.children.length; i++) {
    const mainLight = pointLightsGroup.children[i]
    let light = lights[i]
    if(light){
      mainLight.position.copy(lights[i].position);
    }
  }
}

 
  
export function addGround(scene:any,geometry:any,groundGroup:any,name:string,GROUND_OUTLINE_NAME:string,boundingBox:any,fontLoader:any,groundDimName:string,positions:any,isAddOutline:boolean = true) {

    // const shadowMaterial = new ShadowMaterial({ color: 0x999999, depthWrite: false });
    const shadowMaterial = new MeshBasicMaterial({
      opacity: 1,
      transparent: true,
      side: DoubleSide,
      toneMapped: false
    });
    // const shadowMaterial = new MeshStandardMaterial({ color: 0x964B00, depthWrite: false });
    // const phongMaterial = new MeshPhongMaterial({ color: 0x964B00, depthWrite: false });
    // shadowMaterial.envMap = generatedEnvironmentMap.texture
    floor = new Mesh( geometry, shadowMaterial );
    floor.position.set(positions.x,positions.y,positions.z);
    // floor.material.side = DoubleSide
    // floor.receiveShadow = true;
    // floor.castShadow = true;
    // floor.rotation.x = Math.PI/2;
    floor.rotation.x -= Math.PI/2;
    floor.name=name
    parentGroundToGroup(scene,groundGroup,floor,GROUND_OUTLINE_NAME,boundingBox,fontLoader,groundDimName,isAddOutline)
    return floor
  }

  function parentGroundToGroup(scene:any,groundGroup:any,floor:any,GROUND_OUTLINE_NAME:string,boundingBox:any,fontLoader:any,groundDimName:string,isAddOutline:boolean) {
    if(isAddOutline){
      addEdges(groundGroup,floor,GROUND_OUTLINE_NAME,false)
      // addFloorDimensions(scene,floor,boundingBox,fontLoader,groundDimName,false)
    }
    //Add dimensions text
    groundGroup.add( floor );
    scene.add(groundGroup)
  }

  export function showFloor(floor:any) {
    floor.material.transparent = false
    floor.material.side = DoubleSide
    // floor.material.color.setHex("0x898a8b")
    floor.material.color.setHex("0x794B26")
  }


  export function addRectLights(scene:any) {
    const rectLight1 = new RectAreaLight( 0xffffff, 10, 250, 250 )
    rectLight1.position.set( 20, 350, 20 )
    rectLight1.lookAt(20,20,20)
    scene.add( rectLight1 )
  }


  export function addGridHelper(scene:any,data:any) {
    if(GRID){
      scene.remove(GRID)
    }
    GRID = new GridHelper(data.size,data.division,"white","lightgray");
    GRID.visible = false
    GRID.position.set(data.position.x + 10,data.position.y - 0.2,data.position.z + 20)
    scene.add(GRID);
  }





  export function addFloorDimensions(scene:any,floor:any,boundingBox:any,fontLoader:any,name:string,visibility:boolean) {
    // let dim = getModelDimensions(floor,boundingBox)
    let group = new Group()
    group.name = name
    let data = getObjectDimensionPosition(boundingBox,floor)
    let posX_Max = data.positions.max.x 
    let posZ_Max = data.positions.max.z
    let posZ_min = data.positions.min.z
    let posX_Min = data.positions.min.x
    let dimY = data.dimensions.dimY
    let dimZ = data.dimensions.dimZ
    let dimX = data.dimensions.dimX


    let breadth = dimX
    let length = dimZ


    let dimData = getConvertedLengthBreadth(length,breadth,"inch")
    breadth = getFormatedFeetInchDimensions(Number(dimData.breadth))
    length = getFormatedFeetInchDimensions(Number(dimData.length))


    let deltaForCenter = 35
    let deltaForPadding = 20
    let topPositions = {
      x:posX_Min + (dimX/2) - deltaForCenter,
      y:0,
      z:posZ_min - deltaForPadding + 10
    }
    let rightPositions = {
      x:posX_Max + deltaForPadding,
      y:0,
      z:posZ_min + (dimZ/2)   
    }
    let bottomPositions = {
      x:posX_Min + (dimX/2) - deltaForCenter,
      y:0,
      z:posZ_Max + deltaForPadding
    }
    let leftPositions = {
      x:posX_Min - deltaForPadding,
      y:0,
      z:posZ_min + (dimZ/2) - deltaForCenter
    }
    let params={
      fontSize:10
    }
    
    addTextToScene(group,fontLoader,String(breadth),topPositions,{x:-Math.PI/2,y:null,z:null},params,"")
    addTextToScene(group,fontLoader,String(length),rightPositions,{x:-Math.PI/2,y:null,z:Math.PI/2},params,"")
    addTextToScene(group,fontLoader,String(breadth),bottomPositions,{x:-Math.PI/2,y:null,z:null},params,"")
    addTextToScene(group,fontLoader,String(length),leftPositions,{x:-Math.PI/2,y:null,z:-Math.PI/2},params,"")
    // group.rotateX(-Math.PI/2)
  
    group.visible = visibility
    scene.add(group)

  }
  

  
export function updateFloorDimensions(scene:any,boundingBox:any,fontLoader:any,GROUND_DIMENSIONS_GROUP_NAME:string,GROUND_GROUP_NAME:string,GROUND_WIDTH:number,GROUND_HEIGHT:number,length:number,breadth:number) {

  length = Math.round(length * 2.54)
  breadth = Math.round(breadth * 2.54)
  
  let groundGroupObject = getObjectByName(scene,GROUND_GROUP_NAME)
  let scaleLength = length / GROUND_HEIGHT
  let scaleBreadth = breadth / GROUND_WIDTH
  groundGroupObject.scale.set(scaleBreadth,1,scaleLength)
 
  showFloorDimensions()
}


export function initialEnv(light:any) {
  light.castShadow = true
}

export function spacePlannerEnv(light:any) {
  light.castShadow = false
}

 
export default class EnvironmentSceneOld extends Scene{
  scene: any;
  constructor() {
    super();

    let scale = 1
    this.position.y = -3.5;

    const geometry = new BoxGeometry();
    geometry.deleteAttribute('uv');

    const roomMaterial =
        new MeshStandardMaterial({metalness: 0, side: BackSide});
    const boxMaterial = new MeshStandardMaterial({metalness: 0});

    const mainLight = new PointLight(0xffffff, 250.0, 28, 2);
    mainLight.position.set(0.418, 16.199, 0.300);
    this.add(mainLight);

    const room = new Mesh(geometry, roomMaterial);
    room.position.set(-0.757 * scale, 13.219 * scale, 0.717 * scale);
    room.scale.set(31.713 * scale, 28.305 * scale, 28.591 * scale);
    this.add(room);

    const box1 = new Mesh(geometry, boxMaterial);
    box1.position.set(-10.906 * scale, 2.009 * scale, 1.846 * scale);
    box1.rotation.set(0 * scale, -0.195 * scale, 0 * scale);
    box1.scale.set(2.328* scale ,  7.905* scale ,  4.651 * scale);
    this.add(box1);

    const box2 = new Mesh(geometry, boxMaterial);
    box2.position.set(-5.607* scale ,  -0.754* scale ,  -0.758* scale );
    box2.rotation.set(0* scale ,  0.994* scale ,  0* scale );
    box2.scale.set(1.970* scale ,  1.534* scale ,  3.955* scale );
    this.add(box2);

    const box3 = new Mesh(geometry, boxMaterial);
    box3.position.set(6.167* scale ,  0.857* scale ,  7.803 * scale );
    box3.rotation.set(0* scale ,  0.561* scale ,  0 * scale );
    box3.scale.set(3.927* scale ,  6.285* scale ,  3.687 * scale );
    this.add(box3);

    const box4 = new Mesh(geometry, boxMaterial);
    box4.position.set(-2.017* scale ,  0.018* scale ,  6.124 * scale );
    box4.rotation.set(0* scale ,  0.333* scale ,  0 * scale );
    box4.scale.set(2.002* scale ,  4.566* scale ,  2.064 * scale );
    this.add(box4);

    const box5 = new Mesh(geometry, boxMaterial);
    box5.position.set(2.291* scale ,  -0.756* scale ,  -2.621 * scale );
    box5.rotation.set(0* scale ,  -0.286* scale ,  0 * scale );
    box5.scale.set(1.546* scale ,  1.552* scale ,  1.496 * scale );
    this.add(box5);

    const box6 = new Mesh(geometry, boxMaterial);
    box6.position.set(-2.193* scale ,  -0.369* scale ,  -5.547 * scale );
    box6.rotation.set(0* scale ,  0.516* scale ,  0 * scale );
    box6.scale.set(3.875* scale ,  3.487* scale ,  2.986 * scale );
    this.add(box6);


    // -x right
    const light1 = new Mesh(geometry, this.createAreaLightMaterial(50));
    light1.position.set(-16.116* scale ,  14.37* scale ,  8.208 * scale );
    light1.scale.set(0.1* scale ,  2.428* scale ,  2.739 * scale );
    this.add(light1);

    // -x left
    const light2 = new Mesh(geometry, this.createAreaLightMaterial(50));
    light2.position.set(-16.109* scale ,  18.021* scale ,  -8.207 * scale );
    light2.scale.set(0.1* scale ,  2.425* scale ,  2.751 * scale );
    this.add(light2);

    // +x
    const light3 = new Mesh(geometry, this.createAreaLightMaterial(17));
    light3.position.set(14.904* scale ,  12.198* scale ,  -1.832 * scale );
    light3.scale.set(0.15* scale ,  4.265* scale ,  6.331 * scale );
    this.add(light3);

    // +z
    const light4 = new Mesh(geometry, this.createAreaLightMaterial(43));
    light4.position.set(-0.462* scale ,  8.89* scale ,  14.520 * scale );
    light4.scale.set(4.38* scale ,  5.441* scale ,  0.088 * scale );
    this.add(light4);

    // -z
    const light5 = new Mesh(geometry, this.createAreaLightMaterial(20));
    light5.position.set(3.235* scale ,  11.486* scale ,  -12.541 * scale );
    light5.scale.set(2.5* scale ,  2.0* scale ,  0.1 * scale );
    this.add(light5);

    // +y
    const light6 = new Mesh(geometry, this.createAreaLightMaterial(100));
    light6.position.set(0.0* scale ,  20.0* scale ,  0.0 * scale );
    light6.scale.set(1.0* scale ,  0.1* scale ,  1.0 * scale );
    this.add(light6);


    // lightsGroup.scale.set(scale,scale,scale)
    // scene.add(lightsGroup)
  }

  createAreaLightMaterial(intensity: number): MeshBasicMaterial {
    const material = new MeshBasicMaterial();
    material.color.setScalar(intensity/2);
    return material;
  }
}


class DebugEnvironment extends Scene{

  constructor() {

		super();

		const geometry = new BoxGeometry();
		geometry.deleteAttribute( 'uv' );
		const roomMaterial = new MeshStandardMaterial( { metalness: 0, side: BackSide } );
		const room = new Mesh( geometry, roomMaterial );
		room.scale.setScalar( 10 );
		this.add( room );

		const mainLight = new PointLight( 0xffffff, 50, 0, 2 );
		this.add( mainLight );

		const material1 = new MeshLambertMaterial( { color: 0xffffff, emissive: 0xffffff, emissiveIntensity: 10 } );

		const light1 = new Mesh( geometry, material1 );
		light1.position.set( - 5, 2, 0 );
		light1.scale.set( 0.1, 1, 1 );
		this.add( light1 );

		const material2 = new MeshLambertMaterial( { color: 0xffffff, emissive: 0xffffff, emissiveIntensity: 10 } );

		const light2 = new Mesh( geometry, material2 );
		light2.position.set( 0, 5, 0 );
		light2.scale.set( 1, 0.1, 1 );
		this.add( light2 );

		const material3 = new MeshLambertMaterial( { color: 0xffffff, emissive: 0xffffff, emissiveIntensity: 10 } );

		const light3 = new Mesh( geometry, material3 );
		light3.position.set( 2, 1, 5 );
		light3.scale.set( 1.5, 2, 0.1 );
		this.add( light3 );

	}
}


export  class EnvironmentSceneAlt extends Scene{
  scene: any;
  constructor() {
    super();

  //scene: any;
  //constructor(scene) {

    //this.scene = scene

    //let lightsGroup = new Group()

    this.position.y = -3.5;

    const geometry = new BoxGeometry();
    geometry.deleteAttribute('uv');

    const roomMaterial =
        new MeshStandardMaterial({metalness: 0, side: BackSide});
    const boxMaterial = new MeshStandardMaterial({metalness: 0});

    const mainLight = new PointLight(0xffffff, 400.0, 28, 2);
    mainLight.position.set(0.5, 14.0, 0.5);
    this.add(mainLight);

    const room = new Mesh(geometry, roomMaterial);
    room.position.set(0.0, 13.2, 0.0);
    room.scale.set(31.5, 28.5, 31.5);
    this.add(room);

    const box1 = new Mesh(geometry, boxMaterial);
    box1.position.set(-10.906, -1.0, 1.846);
    box1.rotation.set(0, -0.195, 0);
    box1.scale.set(2.328, 7.905, 4.651);
    this.add(box1);

    const box2 = new Mesh(geometry, boxMaterial);
    box2.position.set(-5.607, -0.754, -0.758);
    box2.rotation.set(0, 0.994, 0);
    box2.scale.set(1.970, 1.534, 3.955);
    this.add(box2);

    const box3 = new Mesh(geometry, boxMaterial);
    box3.position.set(6.167, -0.16, 7.803);
    box3.rotation.set(0, 0.561, 0);
    box3.scale.set(3.927, 6.285, 3.687);
    this.add(box3);

    const box4 = new Mesh(geometry, boxMaterial);
    box4.position.set(-2.017, 0.018, 6.124);
    box4.rotation.set(0, 0.333, 0);
    box4.scale.set(2.002, 4.566, 2.064);
    this.add(box4);

    const box5 = new Mesh(geometry, boxMaterial);
    box5.position.set(2.291, -0.756, -2.621);
    box5.rotation.set(0, -0.286, 0);
    box5.scale.set(1.546, 1.552, 1.496);
    this.add(box5);

    const box6 = new Mesh(geometry, boxMaterial);
    box6.position.set(-2.193, -0.369, -5.547);
    box6.rotation.set(0, 0.516, 0);
    box6.scale.set(3.875, 3.487, 2.986);
    this.add(box6);

    // -x_left
    const light1 = new Mesh(geometry, this.createAreaLightMaterial(80));
    light1.position.set(-14.0, 10.0, 8.0);
    light1.scale.set(0.1, 2.5, 2.5);
    this.add(light1);

    // -x_right
    const light2 = new Mesh(geometry, this.createAreaLightMaterial(80));
    light2.position.set(-14.0, 14.0, -4.0);
    light2.scale.set(0.1, 2.5, 2.5);
    this.add(light2);



    // +x only on light
    const light3 = new Mesh(geometry, this.createAreaLightMaterial(23));
    light3.position.set(14.0, 12.0, 0.0);
    light3.scale.set(0.1, 5.0, 5.0);
    this.add(light3);

    // +z
    const light4 = new Mesh(geometry, this.createAreaLightMaterial(16));
    light4.position.set(0.0, 9.0, 14.0);
    light4.scale.set(5.0, 5.0, 0.1);
    this.add(light4);

    // -z right
    const light5 = new Mesh(geometry, this.createAreaLightMaterial(80));
    light5.position.set(7.0, 8.0, -14.0);
    light5.scale.set(2.5, 2.5, 0.1);
    this.add(light5);

    // -z left
    const light6 = new Mesh(geometry, this.createAreaLightMaterial(80));
    light6.position.set(-7.0, 16.0, -14.0);
    light6.scale.set(2.5, 2.5, 0.1);
    this.add(light6);

    // +y
    const light7 = new Mesh(geometry, this.createAreaLightMaterial(1));
    light7.position.set(0.0, 20.0, 0.0);
    light7.scale.set(0.1, 0.1, 0.1);
    this.add(light7);

    //this.scale.set(100,100,100)

    //this.scene.add(lightsGroup)

  }

  createAreaLightMaterial(intensity: number): MeshBasicMaterial {
    const material = new MeshBasicMaterial();
    material.color.setScalar(intensity);
    return material;
  }
}



// Author: Fyrestar https://mevedia.com (https://github.com/Fyrestar/InfiniteGridHelper)

export function InfiniteGridHelper( size1, size2, color, distance, axes = 'xzy' ) {

	color = color || new Color( 'white' );
	size1 = size1 || 10;
	size2 = size2 || 100;

	distance = distance || 8000;



	const planeAxes = axes.substr( 0, 2 );

	const geometry = new PlaneGeometry( 2, 2, 1, 1 );

	const material = new ShaderMaterial( {

		side: DoubleSide,

		uniforms: {
			uSize1: {
				value: size1
			},
			uSize2: {
				value: size2
			},
			uColor: {
				value: color
			},
			uDistance: {
				value: distance
			}
		},
		transparent: true,
		vertexShader: `
           
           varying vec3 worldPosition;
		   
           uniform float uDistance;
           
           void main() {
           
                vec3 pos = position.${axes} * uDistance;
                pos.${planeAxes} += cameraPosition.${planeAxes};
                
                worldPosition = pos;
                
                gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
           
           }
           `,


		fragmentShader: `
           
           varying vec3 worldPosition;
           
           uniform float uSize1;
           uniform float uSize2;
           uniform vec3 uColor;
           uniform float uDistance;
            
            
            
            float getGrid(float size) {
            
                vec2 r = worldPosition.${planeAxes} / size;
                
                
                vec2 grid = abs(fract(r - 0.5) - 0.5) / fwidth(r);
                float line = min(grid.x, grid.y);
                
            
                return 1.0 - min(line, 1.0);
            }
            
           void main() {
           
                
                  float d = 1.0 - min(distance(cameraPosition.${planeAxes}, worldPosition.${planeAxes}) / uDistance, 1.0);
                
                  float g1 = getGrid(uSize1);
                  float g2 = getGrid(uSize2);
                  
                  
                  gl_FragColor = vec4(uColor.rgb, mix(g2, g1, g1) * pow(d, 3.0));
                  gl_FragColor.a = mix(0.5 * gl_FragColor.a, gl_FragColor.a, g2);
                
                  if ( gl_FragColor.a <= 0.0 ) discard;
                
           
           }
           
           `,

		extensions: {
			derivatives: true
		}

	} );


	Mesh.call( this, geometry, material );

	this.frustumCulled = false;

};

InfiniteGridHelper.prototype = {
	...Mesh.prototype,
	...Object3D.prototype,
	...EventDispatcher.prototype
};

if ( parseInt( REVISION ) > 126 ) {

	class InfiniteGridHelper extends Mesh {

		constructor ( size1, size2, color, distance, axes = 'xzy' ) {


			color = color || new Color( 'white' );
			size1 = size1 || 10;
			size2 = size2 || 100;

			distance = distance || 8000;



			const planeAxes = axes.substr( 0, 2 );

			const geometry = new PlaneGeometry( 2, 2, 1, 1 );

			const material = new ShaderMaterial( {

				side: DoubleSide,

				uniforms: {
					uSize1: {
						value: size1
					},
					uSize2: {
						value: size2
					},
					uColor: {
						value: color
					},
					uDistance: {
						value: distance
					}
				},
				transparent: true,
				vertexShader: `
           
           varying vec3 worldPosition;
		   
           uniform float uDistance;
           
           void main() {
           
                vec3 pos = position.${axes} * uDistance;
                pos.${planeAxes} += cameraPosition.${planeAxes};
                
                worldPosition = pos;
                
                gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
           
           }
           `,


				fragmentShader: `
           
           varying vec3 worldPosition;
           
           uniform float uSize1;
           uniform float uSize2;
           uniform vec3 uColor;
           uniform float uDistance;
            
            
            
            float getGrid(float size) {
            
                vec2 r = worldPosition.${planeAxes} / size;
                
                
                vec2 grid = abs(fract(r - 0.5) - 0.5) / fwidth(r);
                float line = min(grid.x, grid.y);
                
            
                return 1.0 - min(line, 1.0);
            }
            
           void main() {
           
                
                  float d = 1.0 - min(distance(cameraPosition.${planeAxes}, worldPosition.${planeAxes}) / uDistance, 1.0);
                
                  float g1 = getGrid(uSize1);
                  float g2 = getGrid(uSize2);
                  
                  
                  gl_FragColor = vec4(uColor.rgb, mix(g2, g1, g1) * pow(d, 3.0));
                  gl_FragColor.a = mix(0.5 * gl_FragColor.a, gl_FragColor.a, g2);
                
                  if ( gl_FragColor.a <= 0.0 ) discard;
                
           
           }
           
           `,

				extensions: {
					derivatives: true
				}

			} );

			super( geometry, material );

			this.frustumCulled = false;

		}

	}
}