import React, { useEffect, useState } from 'react';
import AwsConfig from '../../../../../../../utils/aws-sdk/AwsConfig';
import { checkFromCache, untarAndSaveToCache } from '../../../../../../../utils/cache/cache';
import { projectConfiguration } from '../../../../../../../utils/customizein3d/area3dmodel/area3dModel';
import { getUrlPrefix } from '../../../../../../../utils/customizer/customizer';
import { applicationConfig } from '../../../../../../../utils/methods';
import { getProductImageUrl } from '../../../../../../../utils/moodboard/helper';
import MenuHeader from '../../../../../../common/ui-components/header/MenuHeader';

const Summary: React.FC<{
  setCurrStep:(val:string)=>void
}> = (props) => {

  const [currTab, setCurrTab] = useState<string>("3d");
  let awsConfig = new AwsConfig()
  const[areaProductsMapping,setareaProductsMapping] = useState(null)
  const[areasList,setAreasList] = useState([])

  useEffect(()=>{
    if(projectConfiguration){
      setAreasList(projectConfiguration.areasList)
      let configuration = projectConfiguration.projectConfiguration
      let mapping = {}
      for (const areaKey in configuration) {
        let products = getProductsList(configuration[areaKey].areaConfiguration)
        mapping[areaKey] = {
          products:products,
        }
      }
      setareaProductsMapping(mapping)
    }
  },[projectConfiguration])

 
  useEffect(()=>{
    if(areaProductsMapping){
      setTimeout(() => {
        for (const areaKey in areaProductsMapping) {
          let products = areaProductsMapping[areaKey].products
          for (const product of products) {
            loadAndDisplayImages(areaKey,product)
          }
        }
      }, 500);
    }
  },[areaProductsMapping])

  function getProductsList(configuration:any) {
    let products = []
    for (const productGroupKey in configuration) {
      for (const productInstanceKey in configuration[productGroupKey]) {
        if(configuration[productGroupKey][productInstanceKey].enabled){
          products.push(configuration[productGroupKey][productInstanceKey])
        }
      }
    }
    return products
  }

  function gotoCameraSettings() {
    props.setCurrStep("camera")
  }

  async function loadAndDisplayImages(areaName:string,product: any) {
    let imageUrlKey = getProductImageUrl("",applicationConfig?.clientName,
      product.productName,
      product.categoryName,
      product.subCategoryName
    );
  let urlPrefix = getUrlPrefix(imageUrlKey);
  let tarFileKey = awsConfig.getTarFileKey("products", {
    clientName: applicationConfig?.clientName,
    category: product.categoryName,
    subCategory: product.subCategoryName,
    productName: product.productName,
  });
  await untarAndSaveToCache(awsConfig, urlPrefix, tarFileKey)
    ?.then((data) => {
      displayImages(areaName,product);
    })
    .catch((err) => {
      displayImages(areaName,product);
    });
  }

  async function displayImages(areaName:string,product: any) {
    let imageUrlKey = getProductImageUrl("",applicationConfig?.clientName,
      product.productName,
      product.categoryName,
      product.subCategoryName
    );

    let image = document.getElementById(`reviewProduct${areaName}${product.productName}`)
    await checkFromCache(imageUrlKey)
      ?.then((url: any) => {
        image?.setAttribute("src", url);
      })
      .catch((err) => {
        image?.setAttribute("src", "assets/images/placeholder.png");
      });
    // }
  }


  
  function Summary() {
    return (
      <div className="full-width fill-available-height overflow-y-scroll" style={{padding:"10px 10px 160px 10px"}}>
        {areasList.map((area:any,index:number)=>{
          let products = areaProductsMapping[area.area_name].products
          return(
            <div className="border ion-margin-bottom padding10" key={index}>
              <div className="heading3 ion-margin-bottom color-black font-normal ">{area.area_name}</div>
              
              {products.length?
              <div className=''>
                {products?.map((product:any,j:number)=>{
                  return(
                    <div key={j} className="display-flex-row marginTop5">
                      <img src="" width={80} className="review-product" id={`reviewProduct${area.area_name}${product.productName}`} alt="" />
                      <div className='details marginleft10 padding5'>
                        <div className='heading5 font-small'>{product.productName}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
              :
              <div className="center-aligned-column full-width">
                <div className="heading4 ion-padding text-center full-width" style={{border:"1px dashed lightgray",borderRadius:"10px"}}>Products not added</div>
              </div>
              }
            </div>
          )
        })}
      </div>
    )
  }


  return (
    <React.Fragment>
      <MenuHeader
        label='Summary'
        action={()=>props.setCurrStep("gallery")}
      />
      
      <div className='marginTop5 padding10'>
        <button className='op-btn op-btn-primary-inverse full-width'>View detailed summary</button>
      </div>

      <Summary/>
    
      

      <div className='bottom-left full-width padding10'>
        <div onClick={gotoCameraSettings} className='center-aligned-column bg-color-dark pointer shadow-light white-card  click-effect full-width padding5'>
          <div className='display-flex-row' style={{padding:"10px 0"}}>
            <span className='heading3 color-white'>Confirm & Render</span>
            <span className='marginleft5'><i className='fas fa-chevron-right'></i></span>
          </div>
        </div>
      </div>
          
    </React.Fragment>
  );
};

export default Summary;
