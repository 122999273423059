import React from "react";
import Modal from "../../../../../../../../common/shared-components/Modals/Modal";
import ActivityLogsList from "../../activities/ActivityLogs";
 
const ActivityLogs: React.FC<{
  isOpen:boolean
  setIsOpen:(val:boolean)=>void
}> = (props) => {



  return (
    <Modal
      isOpen = {props.isOpen}
      onDismiss = {()=>props.setIsOpen(false)}
      heading={"Activity Logs"}
      FooterContent={()=>(<div></div>)}
      classNames="large-x-modal"
      id=""
      Content={()=>(
          <ActivityLogsList isShowFilters={true} lead={null}/>
      )}
      >
    </Modal>
       
  );
};

export default ActivityLogs;
