import { IonSpinner } from "@ionic/react";
import $ from "jquery";
import React, { useState } from "react";
import { showGlobalToast } from "../../../../../../../../../utils/UI_methods/global";
import { getFilteredArray } from "../../../../../../../../../utils/methods";
import { CRM } from "../../../../../../../../../utils/store/crm/crmConfiguration";
import { Customer } from "../../../../../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../../../../../common/ui-components/buttons/Button2";
 
const SearchLeads: React.FC<{
  setSearchedLeadsList:(values:any)=>void
}> = (props) => {


  const[filteredCustomers,setFilteredCustomer] = useState([])
  // const[customersList,setCustomersList] = useState([])
  const[currcustomer,setCurrCustomer] = useState(null)
  

  function setCustomerHandle(customer:Customer) {
      setCurrCustomer(customer)
      $("#leadSearchInput").val(customer.customerName+" "+customer.mobile)
      setFilteredCustomer([])
  }

  function serachCustomerHandle() {
    if(currcustomer){
      let filteredCustomer = getFilteredArray(CRM.data.leadsList,"customer_id",currcustomer.id)
      props.setSearchedLeadsList(filteredCustomer)
      if(!filteredCustomer.length){
        showGlobalToast("No results",2000,"error")
      }
      $("#leadSearchInput").val("")
      setCurrCustomer(null)
    }else{
      showGlobalToast("No results",2000,"error")
      $("#leadSearchInput").val("")
    }
  }

  function onchangeMobile(event:any) {
    const value = event.target.value
    if(value.length >= 3){
      setFilteredCustomer(CRM.data.leadsList?.filter(customer=>customer.mobile.includes(value) || customer.customer_name.toLowerCase().includes(value.toLowerCase())))
      $("#leadsSearchLoader").removeClass("visibility-hidden")
    }else{
      setFilteredCustomer([])
    
    }
    setTimeout(() => {
      $("#leadsSearchLoader").addClass("visibility-hidden")
    }, 2000);
  }

  return (

      <div className="display-flex-row no-wrap space-between input-wrapper">
          <div className="container">
            <div className="display-flex-row position-relative white-card no-shadow" style={{padding:"2px 15px"}}>
              <span className="center-aligned-column"><i className="fas fa-search color-dark"></i></span>
              <span><input id="leadSearchInput" onChange={onchangeMobile} type="text" className="input lead-search-input no-focus border-none" placeholder="Search leads" style={{border:"none"}}/></span>
              
              <div className="top-middle" style={{padding:"0",top:"100%",zIndex:999,width:"100%"}}>
              <div className="full-width customers-list bg-color-white">
                {filteredCustomers.map((customer:any,index:number)=>{
                  return(
                    <div className={`pointer padding10 ${index>1?" display-none":" "}`} onClick={()=>{setCustomerHandle(new Customer(customer))}} key={index}>
                      <div className={`heading3 font-normal  `}>{customer.customer_name}</div>
                      <div className={`heading3 marginTop5 color-dark font-md-small`}>+91 {customer.mobile}</div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div id="leadsSearchLoader" className="right-middle visibility-hidden center-aligned-column padding10" style={{marginTop:"0px"}}>
              <IonSpinner name="crescent" />
            </div>
            
            </div>
          </div>
          <Button2
              configObj={{id:""}}
              action={()=>{serachCustomerHandle()}}
              label={"Search"}
              fontAwesomeIcon={"fas fa- color-dark"}
              iconPos=""
              classNames={"no-shadow marginleft5 bg-color-primary"}
          />
      </div>  
  );
};

export default SearchLeads;
