import React, { useState } from 'react';
import Camera from './Camera';
import Gallery from './Gallery';
import Summary from './Summary';

const CreateRender: React.FC<{
}> = (props) => {

  const [currStep, setCurrStep] = useState<string>("gallery");

 

  switch (currStep) {
    case "gallery":
      return(
        <Gallery
          setCurrStep={setCurrStep}
        />
      )
    break;

    case "summary":
      return(
        <Summary
          setCurrStep={setCurrStep}
        />
      )
    break;

    case "camera":
      return(
        <Camera
          setCurrStep={setCurrStep}
        />
      )
    break;
  
    default:
      return(
        <div>Invalid option</div>
      )
    break;
  }


 
};

export default CreateRender;
