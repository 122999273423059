import { IonIcon } from "@ionic/react";
import { close, expand } from "ionicons/icons";
import React from "react";


const CloseButton: React.FC<{
  action:()=>void
  classNames:string
}> = (props) => {

  return (
    <React.Fragment>
        <div className={"position-relative click-effect" + props.classNames}  onClick={props.action}>
          <IonIcon icon={close} className={props.classNames + " font-large color-dark"}/>
        </div>
    </React.Fragment>
  
  );
};

export default CloseButton;
