import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { hideComponentLoader } from "../../../../utils/UI_methods/global";
import { applicationConfig, getCapitalizeString } from "../../../../utils/methods";
import { getRandomNumber } from "../../../../utils/products/productviewer_shopify.js";
import QRcode from "../../../common/modules/customizein3d/canvas/QRcode";
 
import CollectionCode from "../../../common/modules/customizein3d/canvas/CollectionCode";
import { QrCodes } from "../../../../utils/data";

const CollectionGallery: React.FC<{
  companyName: string;
  gotoCustomizeIn3D: () => void;
  collectionName: string;
  imageHeight: any;
  collectionsList?: any;
}> = (props) => {
  const [images, setImages] = useState(["mainImage"]);
  const baseUrl = "https://opusassets.s3.ap-south-1.amazonaws.com/";
  const [refresh, setRefresh] = useState(getRandomNumber(1, 150));
  const [modelUrl, setModelUrl] = useState("");
  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);

  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [isOpenQrCode, setIsOpenQrCode] = useState(false);
  const [qrCodes] = useState(QrCodes[props.companyName]);

  useEffect(() => {
    if (isOpenViewProduct) {
      hideComponentLoader("canvasLoader");
    }
  }, [isOpenViewProduct]);

  const [currGalleryIndexDetails, setCurrGalleryIndexDetails] = useState({
    index: 0,
    url: null,
  });

  useEffect(() => {
    if (props.collectionsList) {
      let array = [];
      for (const collection of props.collectionsList) {
        let image = `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${props.companyName}/${getCapitalizeString(collection)}.webp`;
        array.push(image);
      }
 
      setImages(array);
    } else {
      let frontimage = `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${props.companyName}/${getCapitalizeString(props.collectionName)}.webp`;
      let backImage = `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${props.companyName}/${props.collectionName}.webp`;
      setImages([frontimage, backImage]);
    }
  }, [props.collectionName, refresh]);


  function getBackPageLink(){
    if(props.companyName === "LWIN"){
      return `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${props.companyName}/back/${props.collectionName}_BackPage.webp`
    }
    if(props.companyName === "KairaFabrics"){
      return `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${props.companyName}/${props.collectionName}Back.webp`
    }
    return ""
  }

  return (
    <>
      <IonModal
        id=""
        cssClass="project-steps-modal"
        onDidDismiss={() => setIsOpenQrCode(false)}
        isOpen={isOpenQrCode}>
        <>
          {qrCodeUrl ? (
            <QRcode configObj={applicationConfig} url={qrCodeUrl} />
          ) : null}
        </>
        {/* {url?
            <QRcode 
              configObj={props.configObj}
              url = {url} 
            />
          :null} */}
      </IonModal>

      {props.collectionsList ? (
        <Carousel
          dynamicHeight={false}
          className="full-height"
          infiniteLoop={true}
          showArrows={true}
          showThumbs={false}
          autoPlay={false}>
          {images.map((image: string, index: number) => {
            return (
              <div key={index} className="center-aligned-column full-height-width position-relative">
                <img
                  key={index}
                  style={{
                    height: `${props.imageHeight}px`,
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={image}
                />
              </div>
            );
          })}
        </Carousel>
      ) : (
        <Carousel
          dynamicHeight={false}
          className="full-height"
          infiniteLoop={true}
          showArrows={true}
          showThumbs={false}
          autoPlay={false}>
          <div className="center-aligned-column full-height-width position-relative">
            <img
              style={{
                height: `${props.imageHeight}px`,
                width: "100%",
                objectFit: "contain",
              }}
              src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${props.companyName}/${getCapitalizeString(props.collectionName)}.webp`}
            />
          </div>

          
          <div className="center-aligned-column full-height-width position-relative">
          <img
            style={{
              height: `${props.imageHeight}px`,
              width: "100%",
              objectFit: "contain",
            }}
            src={getBackPageLink()}
          />
        </div>

          <div className="center-aligned-column full-height-width position-relative">
          {qrCodes?
          <CollectionCode
              heading='Scan the code to place order'
              url={
                qrCodes.find((value) =>
                    value?.collectionName === props.collectionName
                )?.url || ""
              }
              downloadFileName={props.collectionName}
              
            />
          :null}
            
          </div>
        </Carousel>
      )}
    </>
  );
};

export default CollectionGallery;
