import $ from "jquery";
import React, { useState } from "react";
import { getVariantImageUrl } from "../../../../utils/store/UI_methods";
import { STORE, Variant } from "../../../../utils/store/storeConfiguration";
import HorizontalScroll from "../../../common/ui-components/scrollbar/HorizontalScroll";
import { getRandomString } from "../../../../utils/methods";
import Button2 from "../../../common/ui-components/buttons/Button2";

const VariantSelector: React.FC<{
  product:any
  allVariants:any
}> = (props) => {

  const[currCategory]:any =  useState(props.product?.category_name)
  const[currVariant,setCurrVariant] =  useState(STORE.productConfiguration.variant)

  function onChange(event:any,config:any,id:number) {
    let variant = new Variant(config.name)
    setCurrVariant(variant)
    STORE.productConfiguration.variant = variant

    if(STORE.isViewin3D){
      STORE.modelViewer.updateSrcWithVariantName(config.name)
    }else{
      STORE.updateProductImage("elevateProductImage",STORE.currProduct)
    }
    $("#currVariant").text(config.name)
  }
  function VariantList() {
    return(
      <>
        {props.allVariants.map((config:any,index:number)=>{
              let url = getVariantImageUrl(currCategory,currVariant,"Standard",config)
              return(
                <div key={index}  onClick={(event)=>onChange(event,config,config.id)} className={`center-aligned-column item position-relative border ${currVariant.variantName === config.name?" --is-active":""}`}>
                  <div className="pointer-none midde">
                    <div className="padding5 center-aligned-row"><img className="icon" src={url} style={{width:"2.7rem",margin:"auto"}}/></div>
                    <div className="heading2  font-md-small pc-color-primary bottom-middle marginBottom5 text-ellipsis text text-center text-no-wrap" style={{bottom:"0.5rem"}}>{config.displayName || config.name}</div>
                    {/* <div className="heading4 font-small text text-center ">{config.description}</div> */}
                  </div>
                  <div className="top-right">
                    {currVariant.variantName === config.name?
                    <i className="far fa-check-circle select-icon font-medium"></i>
                    :
                    <i className="far fa-circle select-icon font-medium"></i>
                    }
                  </div>
                </div>
              )
          })}
      </>
    )
  }

  function customizein3dAction() {
    window.open("/customizein3d", "_blank");
  }

            
  return (
    <div className="full-height-width">
    <div className="padding5 product-customizer-wrapper position-relative" style={{height:"calc(100% - 4rem)"}}>
          <HorizontalScroll
            itemsClass="customization-options"
            Component={VariantList}   
            itemsList={props.allVariants}
            id={`VariantsScrollBar_${getRandomString(10)}`}     
          />
    </div>
     <div className="center-aligned-column pointer" onClick={customizein3dAction} style={{height:"4rem"}}>
     <div className="display-flex-row">
        <Button2
            configObj={{id:""}}
            action={()=>{}}
            label={"Create Your Perfect Sectional"}
            fontAwesomeIcon={"fas fa-share color-white"}
            iconPos=""
            classNames={"no-shadow margin0 bg-color-warning"}
        />
       {/* <span className="heading3" style={{textDecoration:"underline"}}>Create Your Perfect Sectional</span> */}
     </div>
   </div>
   </div>
  );
};

export default VariantSelector;
