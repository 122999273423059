import $ from "jquery";
import React, { useState } from "react";
import Button2 from "../../../../common/ui-components/buttons/Button2";

 
const CustomerConfirmation: React.FC<{
}> = (props) => {
    
    const[isConfirm,setIsConfirmed] = useState(false)
   function gotoSuccess() {
    // window.location.href = "/success"
    setIsConfirmed(true)
   }

   function  Form() {
    return(
        <div className="white-card shadow-light ion-padding position-relative" style={{width:"100%"}}>

            <div className="form-label-input-container ion-margin-top">
            <div className="label-container">
                <div className="label">
                  Mobile Number  
                </div>
                    </div>
            <div className="input-container">
              <input
                className="input"
                type="text"
                name=""
                id="user_name"
                required
              />
            </div>
          </div>

          <div className="form-label-input-container">
            <div className="label-container">
                <div className="label">
                  Full Name 
                </div>
            </div>
            <div className="input-container">
              <input
                className="input"
                type="text"
                name=""
                id="user_name"
                required
              />
            </div>
          </div>


          <div className="form-label-input-container">
            <div className="label-container">
                <div className="label">
                  Pin Code  
                </div>
            </div>
            <div className="input-container">
              <input
                className="input"
                type="number"
                name=""
                id="user_name"
                required
              />
            </div>
          </div>

          <div className="padding10 right-bottom full-width">
            <Button2
              configObj={{id:""}}
              action={gotoSuccess}
              label={"Submit Request"}
              fontAwesomeIcon={"color-danger"}
              iconPos="left"
              classNames={"shadow-light marginTop10 text-center button-rounded bg-color-primary"}
            />
          </div>
    </div>
    )
   }




  function hide() {
    $("#requestQuotationWindow").fadeOut()
  }

  return (

    <>
   
    <div id="requestQuotationWindow" className="full-height-width top-left position-fixed" style={{background:"rgba(0,0,0,0.5)",zIndex:1001,display:"none"}}>

    {isConfirm?
    <div className="white-card shadow-medium bg-color-light full-height-width ion-padding display-flex-column space-between">
 
    <div className="">
            <div className="heading2 text-center" style={{fontSize:"2rem"}}>Success!!</div>
            <div className="heading3 font-normal text-center marginTop10 capitalize">Thank you for your request. We will be in touch with you shortly.</div>
    </div>
 
    <div className="middle"><i className="fas fa-check-circle color-success" style={{fontSize:"8rem"}}></i></div>

    <div className="footer- center-aligned-column">
        <Button2
            configObj={{id:"closeIframeButton"}}
            action={()=>{}}
            label={"Ok"}
            fontAwesomeIcon={"fas fa-share color-white"}
            iconPos=""
            classNames={"no-shadow button-large full-width margin0 text-center bg-color-primary"}
        />
    </div>

    </div>  
    :
    <div className="white-card bg-color-light padding-container middle" style={{width:"75%",height:"80%"}}>

      <div className="top-right" onClick={hide}>
        <i className="fas fa-times color-black font-large"></i>
      </div>
      <div className="display-flex-row full-height-width padding10">
        <div className="width60 padding10 position-relative full-height hide-in-mobile">
          <div className="">
            <div className="heading1 font-large">Request Quotation</div>
            <div className="marginTop5 heading4 font-normal">Fill up the form and our team will get back to you within 24 hours.</div>
          </div>

          <div className="left-middle">
            <div className="display-flex-row">
              <span className=""><i className="fas fa-phone-alt color-primary"></i></span>
              <span className="marginleft10 heading4 font-normal">+91 9998885554</span>
            </div>
            <div className="display-flex-row marginTop10">
              <span className=""><i className="far fa-envelope color-primary"></i></span>
              <span className="marginleft10 heading4 font-normal">democlient@gmail.com</span>
            </div>
            <div className="display-flex-row marginTop10">
              <span className=""><i className="fas fa-map-marker-alt color-primary"></i></span>
              <span className="marginleft10 heading4 font-normal">location, 482005</span>
            </div>
          </div>

          <div className="left-bottom">
            <div className="display-flex-row marginBottom5">
              <span><i className="fab fa-facebook color-dark font-large"></i></span>
              <span><i className="fab fa-instagram marginleft10 color-dark font-large"></i></span>
            </div>
          </div>

        </div>
        <div className="width40 center-aligned-row padding10">
          <Form/>
        </div>
      </div>
      
    </div>
    }
    </div>
    </>
  );
};

export default CustomerConfirmation;
