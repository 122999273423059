import $ from "jquery";
import React, { useState } from 'react';
import { projectConfiguration, traceFloorplan } from '../../utils/customizein3d/area3dmodel/area3dModel';
import { Floorplan as Floorplanner } from "../../utils/customizein3d/floorplanner/floorplan";
import { applicationConfig } from '../../utils/methods';
import Button2 from '../common/ui-components/buttons/Button2';
import ProcessHeader from '../common/ui-components/header/ProcessHeader';
import "../style/modelviewer/product3dmodel.css";
import Annotations from './Annotations';
import FloorplanActions from './FloorplanActions';
import FloorplanShapes from './FloorplanShapes';
import TraceFloorplan from './TraceFloorplan';
import { useHistory } from "react-router";



const Floorplan: React.FC<{
    floorplan:Floorplanner
    isFloorplanMode:boolean
    enableFloorplanMode:()=>void
    disableFloorplanMode:()=>void
    moduleName:string
}> = (props) => {


    const history = useHistory()

    const[currFloorplanOption,setCurrFloorplanOption] = useState(props.moduleName !== "customizer" ?"shape":"")
    const[isTracingFloorplan,setIsTracingFloorplan] = useState(false)


    function openRoomLayoutoptions() {
        $("#roomLayoutContainer").removeClass("display-none")
        // enableConfigureMode(false)
        // if(applicationConfig.functions.customizein3d?.updateTabValueDesktop){
        //     applicationConfig.functions.customizein3d?.updateTabValueDesktop("roomLayoutContainer","roomLayout")
        // }
        // if(applicationConfig.functions.customizein3d?.updateTabValueMobile){
        //     applicationConfig.functions.customizein3d?.updateTabValueMobile("roomLayoutContainer","roomLayout")
        // }
    }
    
    async function confirmTracing() {
        // props.floorplan.traceScale = 4
        traceFloorplan.setFloorplanWallsForSingleRoom()
        props.floorplan.confirmTracing()
        setIsTracingFloorplan(false)
        traceFloorplan.confirmTracing()
        traceFloorplan.disableTraceFloorplan()
        setIsTracingFloorplan(false)
    }


    function goToProjects() {
        history.push("/projects")
    }

    function SelectFloorplanOptions() {
        return(
            <div className='top-left bg-color-white full-height-width bg-color-light'>
                <div className='top-middle ion-padding display-none'>
                    <div className='header-heading text-center' style={{fontSize:"2rem"}}>Create floorplan using given options</div>
                    <div className='heading4 ion-paddingn text-center'>Please select any option to continue</div>
                </div>

                <div className='middle ion-padding'>
                    <div className="ion-padding center-aligned-row">
                        <i className="fas fa-cogs color-primary" style={{fontSize:"5rem"}}></i>
                    </div>
                    <div className='header-heading text-center' style={{fontSize:"2rem"}}>Creating Shell is in progress</div>
                    <div className='heading4 ion-padding color-black font-medium text-center'>Our team is working diligently to bring you an exceptional experience</div>
                    <div className="center-aligned-row ion-padding">
                        <Button2
                            configObj={{}}
                            action={goToProjects}
                            label={"Back to projects"}
                            fontAwesomeIcon={"fas fa-arrow-left color-white"}
                            iconPos="left"
                            classNames={"shadow-medium button-large marginleft5 no-border bg-color-primary"}
                        />
                    </div>
                
                </div>

                <div className='middle'>
                   
                    <div className='display-flex-row'>
                        
                        <div className='white-card ion-padding display-none' style={{width:"300px",height:"350px"}}>
                            <div className='full-height-width position-relative center-aligned-column' style={{border:"1px dashed var(--op-color-dark)",borderRadius:"5px"}}>
                                <div className='padding10'>
                                    <div className='icon center-aligned-row'>
                                        <i className='fas fa-shapes color-light' style={{fontSize:"6rem"}}></i>
                                    </div>
                                    <div className='' style={{marginTop:"25px"}}>
                                        <div className='heading1 text-center '>Floorplan shapes</div>
                                        <div className='text heading4 font-small color-dark text-center  padding0'>Create floorplan using predefined shapes</div>
                                        <div className='center-aligned-row padding10 bottom-middle'>
                                            <Button2
                                                configObj={applicationConfig}
                                                action={()=>{setCurrFloorplanOption("shape")}}
                                                label={"Select Shape"}
                                                fontAwesomeIcon={"fas fa-arrow-right color-danger"}
                                                iconPos="right"
                                                classNames={"bg-color-primary uppercase marginBottom10 heading2 button"}
                                            />
                                        </div>

                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div className='white-card ion-padding display-none' style={{width:"300px",height:"350px",marginLeft:"30px"}}>
                            <div className='full-height-width position-relative center-aligned-column' style={{border:"1px dashed var(--op-color-dark)",borderRadius:"5px"}}>
                                <div className='padding10'>
                                    <div className='icon center-aligned-row'>
                                        <i className='fas fa-image color-light' style={{fontSize:"6rem"}}></i>
                                    </div>
                                    <div className='' style={{marginTop:"25px"}}>
                                        <div className='heading1 text-center '>Trace Floorplan</div>
                                        <div className='text heading4 font-small color-dark text-center  padding0'>Create floorplan by tracing floorplan on image</div>
                                        <div className='center-aligned-row padding10 bottom-middle'>
                                            <Button2
                                                configObj={applicationConfig}
                                                action={()=>{setCurrFloorplanOption("trace")}}
                                                label={"Trace floorplan"}
                                                fontAwesomeIcon={"fas fa-arrow-right color-danger"}
                                                iconPos="right"
                                                classNames={"bg-color-primary uppercase marginBottom10 heading2 button"}
                                            />
                                        </div>

                                    </div>
                                </div>
                               
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }

    return(
        <>
        {!isTracingFloorplan?
        <>
        <Annotations
            floorplan={props.floorplan}
        />
        <div className='top-right padding '>
            <div className='no-wrap hide-on-select hide-on-sprites'>

                {props.moduleName !== "customizer"?
                    <>
                    

                   

                    <Button2
                        configObj={{id:""}}
                        action={openRoomLayoutoptions}
                        label={"Add elements"}
                        fontAwesomeIcon={"fas fa-plus color-black"}
                        iconPos="left"
                        classNames={"shadow-medium no-border button-small hide-button-text-in-mobile hide-in-draw-walls bg-color-primary button-normal"}
                    />

                    <FloorplanShapes
                        moduleName={props.moduleName}
                        floorplan={props.floorplan}
                        currFloorplanOption={currFloorplanOption}
                    />
                    {/* <Button2
                        configObj={{id:""}}
                        action={()=>props.disableFloorplanMode()}
                        label={"Exit floorplan"}
                        fontAwesomeIcon={"fas fa-times color-black"}
                        iconPos="left"
                        classNames={"shadow-light display-none hide-in-draw-walls bg-color-black button-normal"}
                    /> */}
                    </>
                :null}

                

                
            </div>
        </div>
        </>
        :
        null
        }

        <div className='top-left display-none no-wrap' style={{left:"5rem"}}>
            {/* {props.moduleName !== "customizer"? */}
            <>
                <FloorplanShapes
                    moduleName={props.moduleName}
                    floorplan={props.floorplan}
                    currFloorplanOption={currFloorplanOption}
                />
                {/* <DrawWalls
                    moduleName={props.moduleName}
                    floorplan={props.floorplan}
                /> */}
            </>
            {/* :null} */}
        </div>

        <div className={`display-flex-row no-wrap zindex30 ${props.moduleName === "customizer"?"top-middle":"top-middle"}`}>
            {props.floorplan.isRoomDefined?
            <FloorplanActions
                moduleName={props.moduleName}
                floorplan={props.floorplan}
            />
            :null}
        </div>

        {currFloorplanOption === "shape" && props.moduleName === "customizer"?
            <>
            {/* <FloorplanShapes
                floorplan={props.floorplan}
                moduleName={props.moduleName}
            /> */}
            <ProcessHeader
                prevButtonAction={null}
                nextButtonAction={confirmTracing}
                prevButtonLabel={"Go back"}
                nextButtonLabel={"Confirm & Next"}
                heading={"Review floorplan"}
                HighlightComponent={null}
                />
            </>
        
        :
        currFloorplanOption === "trace" && props.moduleName === "customizer"?
            <TraceFloorplan
                floorplan={props.floorplan}
                setIsTracingFloorplan={setIsTracingFloorplan}
                moduleName = {props.moduleName}
            />
        :
        props.moduleName === "customizer" && !projectConfiguration?.isDefinedFloorplan?
        <SelectFloorplanOptions/>
        :null
        }

        

        <svg id="floorplanSvgLines" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" className="dimensionLineContainer">
            <line id="distanceLineForDrawWall" className="dimensionLine"></line>
        </svg>

        </>
    );
};

export default Floorplan;