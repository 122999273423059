import { waitFor } from "../../methods"
import { CURR_AREA_GROUP, CURR_AREA_NAME, floorplanner, postShellFoundActions, updateCameraControls } from "./area3dModel"
import { All_WALLS, areaWallsBoundingBoxMapping, createAreaWallsBoundingBoxMapping, initialWallsSetup, makeWallsTransparent } from "./walls"




export async function postFloorplanEditActions() {
        floorplanner.updateConfiguration()
        floorplanner.reset()
        floorplanner.createFloorplanFromVertices(floorplanner.configuration.vertices)
        initialWallsSetup(floorplanner.group)
        createAreaWallsBoundingBoxMapping()
        makeWallsTransparent(All_WALLS,areaWallsBoundingBoxMapping["all"].boundingBox.center)
        floorplanner.createFloorFromVertices()
        postShellFoundActions()
        updateCameraControls(CURR_AREA_GROUP,CURR_AREA_NAME || "all")
        if(floorplanner.isConfigChanged){
            await waitFor(500)
            console.log('Saving floorplanner...')
            // floorplanner.saveFloorplan()
            floorplanner.isConfigChanged = false
        }
}