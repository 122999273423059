import React from "react";
import { toggleDimensionsOptions } from "../../../../../utils/customizein3d/floorplanner/helper";
import { configuration, dimensions, floorplanner, rotateAllModules } from "../../../../../utils/customizein3d/modules/customizein3d";
import { toggleMultiselect } from "../../../../../utils/customizein3d/modules/raycasting";
import { isTouchDevice } from "../../../../../utils/customizer/helper";
import Button2 from "../../../ui-components/buttons/Button2";
import DimensionToggle from "../common/DimensionToggle";
import $ from "jquery"
import UnitSelector from "../common/UnitSelector";
import Layouts from "../layouts/Layouts";
import { STORE } from "../../../../../utils/store/storeConfiguration";
  
  const LeftBottomActions: React.FC<{
    modulesConfigurtion:any
    isSpacePlannerMode:any
    isFloorplanMode:any
    isViewProductMode:boolean
    isElevateMode:boolean
  }> = (props) => {



    function rotateFloorplan() {
      floorplanner.rotateFloorplan()
    }


   
    return (
      <React.Fragment>

        

       {/* <div className="stop-propagation position-relative zindex100 display-flex-row ion-margin-bottom" onClick={toggleMultiselect} id="">
          <span className="center-aligned-column pointer-none"><i className="fas fa-sync-alt color-primary font-medium"></i></span>
            <span className="center-aligned-column marginleft5 pointer-none" >Multiselect</span> 
          </div> */}
         <div className="bottom-left padding10 hide-in-take-render hide-on-sprites action-link-container customizein3d-bottom-left">



          <div className='marginBottom10' style={{marginBottom:"10px"}}>
            <Layouts/>
          </div>

          <div className="unit-selector-wrapper marginBottom5 display-none">
            <UnitSelector
              action={(unit)=>dimensions?.updateUnit(unit)}
            />
          </div>
          
      

         {/* {props.isSpacePlannerMode?
            <Button2
              configObj={{id:""}}
              action={toggleDimensionsOptions}
              label={"Unit - " + floorplanner.dimensionUnit}
              fontAwesomeIcon={"fas fa-cog color-black"}
              iconPos="left"
              classNames={"shadow-light  user-options-link marginLeft0 button-fixed-width hide-in-panel-open marginBottom5 button-normal"}
            />
          :
          null
          } */}

          {props.isSpacePlannerMode?
            <Button2
              configObj={{id:""}}
              action={rotateFloorplan}
              label={`Rotate Floorplan`}
              fontAwesomeIcon={"fas fa-undo color-danger font-small"}
              iconPos="left"
              classNames={"shadow-medium no-border marginBottom5 button-small"}
              svgUrl="assets/icon/svg/rotate_floorplan_danger.svg"
            />
          :
          null
          }

        

          <DimensionToggle
            dimensions={dimensions}
          />


          

          {!props.isViewProductMode && STORE.getCurrCategory() !== "Swing"?
            <Button2
              configObj={{id:"toggleMultiselect",stateText:"Off"}}
              action={toggleMultiselect}
              label={"Multiselect"}
              fontAwesomeIcon={"fas fa-map-pin color-black"}
              iconPos="left"
              classNames={"shadow-medium width-auto button-small no-border marginTop5"}
            />
          :null}

          

          {props.isSpacePlannerMode?
            <Button2
              configObj={{id:"rotateModules"}}
              action={rotateAllModules}
              label={`Rotate ${configuration.product.categoryName}`}
              fontAwesomeIcon={"fas fa-undo color-danger font-small"}
              iconPos="left"
              classNames={"shadow-medium no-border marginTop5 width-auto button-small"}
              svgUrl="assets/icon/svg/rotate.svg"
            />
          :
          null
          }
          
          <div className='header-user-options dimensions-options white-card shadow-medium border' >
            <div className='option border-top display-flex-row'>
              <div><i className="fas fa-clipboard  color-dark font-normal"></i></div>
              <div className='marginleft10 heading1 font-small center-aligned-column'>Feet & inch</div>
            </div>


            <div className='option display-flex-row'>
              <div><i className="fas fa-clipboard  color-primary font-normal"></i></div>
              <div className='marginleft10 heading1 font-small center-aligned-column'>Inch</div>
            </div>

            <div className='option display-flex-row'>
              <div><i className="fas fa-clipboard  color-primary font-normal"></i></div>
              <div className='marginleft10 heading1 font-small center-aligned-column'>Cm</div>
            </div>
            </div>
         
          
          </div>
          

      </React.Fragment>
    );
  };
  
  export default LeftBottomActions;
  