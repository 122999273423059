import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { updateFinish } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { categoryFilteredArray, disposeVariables, getCategoryFiltersMaterials, getCategoryName, getIdFromName, getObjectArrayOfDistictValues, getPartName, getProductName } from "../../../../../../utils/methods";
import { addActiveClass } from "../../../../../../utils/UI_methods/global";
import Materials from "../../../../../common/shared-components/Materials/Materials";

const Finish: React.FC<{
  textures: any;
  ref:any;
  changeTabOption:(value:string)=>void;
  updateTabValue:(value:string)=>void;
  currentConfigObject:any
  configObj:any
}> = forwardRef((props,ref) => {


  //use for show hint text or materials window 
  const [isOpenMaterials, setIsOpenMaterials] = useState(false)
  const [objectName, setObjectName] = useState<any>(null)

  const [isActive, setIsActive] = useState<boolean>(false)

  const [textures, setTextures] = useState<any>(props.textures)
  const [materialTypeTextures, setMaterialTypeTextures] = useState<any>([])

  
  useEffect(()=>{
    if(props.configObj){
      props.configObj.setFunctionRef("customizer",{setTextures:setTextures})
      props.configObj.setFunctionRef("customizer",{openMaterialWindowFromCanvas:openMaterialsWindow_MaterialTypeBased})
      props.configObj.setFunctionRef("customizer",{closeMaterialWindowFromCanvas:closeMaterialsWindow})
      props.configObj.setState("customizer",{textures:props.textures})
    }
  },[props.configObj,props.textures])


  function closeMaterialsWindow() {
    setIsOpenMaterials(false)
    setIsActive(false)
  }

  function openMaterialsWindow_MaterialTypeBased(productDetails:any) {
    let categoryName = productDetails.categoryName
    let partsList = productDetails.partsList
    let updatedTextures = []


    setObjectName(objectName)
    setIsOpenMaterials(true)
    setIsActive(true)
 
    let categoryId = getIdFromName(props.configObj.data.categories,"id","category_name",categoryName)
    let filteredTextures = getCategoryFiltersMaterials(props.textures,props.configObj.data.projectMaterials,categoryId)
 

    partsList.forEach(partName => {
      let currTextures = categoryFilteredArray(filteredTextures,props.configObj?.data.categoryMaterialTypes,props.configObj?.data.objectMaterialTypes,partName,categoryName)
      if(currTextures.length){
        updatedTextures.push(...currTextures)
      }
    });
    updatedTextures = getObjectArrayOfDistictValues(updatedTextures,"material_name")
    setTextures(updatedTextures)
    setMaterialTypeTextures(updatedTextures)
    props.updateTabValue("Finish")

    disposeVariables([filteredTextures])
  }
 

  
  function updateCanvas(event: any) {
    let companyName = event.target.getAttribute("data-company-name")
    let collectionName = event.target.getAttribute("data-collection-name")
    let materialCode = event.target.getAttribute("data-material-code")
    let objectType = event.target.getAttribute("data-object-type")
    let materialId = event.target.getAttribute("data-material-id")
    let materialType = event.target.getAttribute("data-material-type")

    let uvScale = event.target.getAttribute("data-uv-scale")
    let roughness = event.target.getAttribute("data-roughness-value")
    let metalness = event.target.getAttribute("data-metalness-value")
    let specular = event.target.getAttribute("data-specular-value")
    let clearcoat = event.target.getAttribute("data-clearcoat-value")
    let sheen = event.target.getAttribute("data-sheen-value")
    let transmission = event.target.getAttribute("data-transmission-value")
    let normalStrength = event.target.getAttribute("data-normal-strength")

    addActiveClass(event.target)


      updateFinish({
        companyName:companyName,
        collectionName:collectionName,
        materialCode:materialCode,
        materialId:materialId,
        materialType:materialType,
        uvScale:uvScale,
        roughness:roughness,
        metalness:metalness,
        specular:specular,
        clearcoat:clearcoat,
        sheen:sheen,
        transmission:transmission,
        normalStrength:normalStrength
      })
   
   
  }
 
 

  return (
    <React.Fragment>
      <div className="controls-tab-content material-color-option-container">
      {textures.length?
      <Materials
          materials={textures}
          action={updateCanvas}
          moduleName="customizer"
        />
      :
      <div className="full-height-width center-aligned-column">
        <div className="heading3">Materials not available</div>
      </div>
      }
        </div>
    </React.Fragment>
  )
})

export default React.memo(Finish)
