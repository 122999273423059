import React, { useEffect, useState } from "react";
import AwsConfig from "../../../../../../utils/aws-sdk/AwsConfig";
import { checkFromCache, untarAndSaveToCache } from "../../../../../../utils/cache/cache";
import { controls, customizerConfig, disableFinalizeMode, disableSpacePlanner, enableFinalizeMode, IS_SPACE_PLANNER_MODE, projectConfiguration, undo, updateAreaCustomizer } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { updateCameraSettingViewData } from "../../../../../../utils/customizein3d/area3dmodel/floorplan/createRender";
import { resetCreateRenderParameters, setIsViewRenderMode } from "../../../../../../utils/customizein3d/area3dmodel/floorplan/render2d";
import { isInteriorVisualization, upadateInteriorCameraConfig } from "../../../../../../utils/customizein3d/area3dmodel/interior_visualization";
import { hideFloorplanOptionWindow, showCreateRender, showFloorplanOptionWindow } from "../../../../../../utils/customizein3d/area3dmodel/UI_methods";
import { getUrlPrefix } from "../../../../../../utils/customizer/customizer";
import { applicationConfig, waitFor } from "../../../../../../utils/methods";
import { getProductImageUrl } from "../../../../../../utils/moodboard/helper";
import { hideComponentLoader, showComponentLoader, updateLoaderProgress } from "../../../../../../utils/UI_methods/global";
import Modal from "../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import SummaryLink from "../../../../../common/ui-components/links/SummaryLink";
import SvgButtonRound from "../../../../../common/ui-components/buttons/SvgButtonRound";


const FinalizeRender: React.FC<{
  isProductConfigureMode:boolean
  isProductRenderMode:boolean
}> = (props) => {

  const[showConfirmationModal,setShowConfirmationModal] = useState(false)
  const[areaProductsMapping,setareaProductsMapping] = useState(null)
  const[modularProductConfig,setModularProductConfig] = useState(null)
  const[areasList,setAreasList] = useState([])

  let awsConfig = new AwsConfig()

  useEffect(()=>{
    if(showConfirmationModal){
      setAreasList(projectConfiguration.areasList)
      let configuration = projectConfiguration.projectConfiguration
      let mapping = {}
      for (const areaKey in configuration) {
        let products = getProductsList(configuration[areaKey].areaConfiguration)
        mapping[areaKey] = {
          products:products,
        }
      }
      setareaProductsMapping(mapping)
    }
  },[showConfirmationModal])

  useEffect(()=>{
    applicationConfig.setFunctionRef("customizer",{setModularProductConfig:setModularProductConfig})
  },[])

 

  function getProductsList(configuration:any) {
    let products = []
    for (const productGroupKey in configuration) {
      for (const productInstanceKey in configuration[productGroupKey]) {
        if(configuration[productGroupKey][productInstanceKey].enabled){
          products.push(configuration[productGroupKey][productInstanceKey])
        }
      }
    }
    return products
  }


  useEffect(()=>{
    if(areaProductsMapping){
      setTimeout(() => {
        for (const areaKey in areaProductsMapping) {
          let products = areaProductsMapping[areaKey].products
          for (const product of products) {
            loadAndDisplayImages(areaKey,product)
          }
        }
      }, 500);
    }
  },[areaProductsMapping])


  function ConfirmationModalFooter() {
    return(
      <div className="">
      <button className="op-btn op-btn-light" onClick={() => setShowConfirmationModal(false)}>Back to designing</button>
      <button className="op-btn op-btn-primary marginleft5" onClick={disableFinalizeMode}>Go to render settings</button>
      </div>
    )
  }

 
  function dismisModal(val:boolean) {
    setShowConfirmationModal(false)
    setShowConfirmationModal(false)
  }

  async function loadAndDisplayImages(areaName:string,product: any) {
    let imageUrlKey = getProductImageUrl("",applicationConfig?.clientName,
      product.productName,
      product.categoryName,
      product.subCategoryName
    );
  let urlPrefix = getUrlPrefix(imageUrlKey);
  let tarFileKey = awsConfig.getTarFileKey("products", {
    clientName: applicationConfig?.clientName,
    category: product.categoryName,
    subCategory: product.subCategoryName,
    productName: product.productName,
  });
  await untarAndSaveToCache(awsConfig, urlPrefix, tarFileKey)
    ?.then((data) => {
      displayImages(areaName,product);
    })
    .catch((err) => {
      displayImages(areaName,product);
    });
  }

  async function displayImages(areaName:string,product: any) {
    let imageUrlKey = getProductImageUrl("",applicationConfig?.clientName,
      product.productName,
      product.categoryName,
      product.subCategoryName
    );

    let image = document.getElementById(`reviewProduct${areaName}${product.productName}`)
    await checkFromCache(imageUrlKey)
      ?.then((url: any) => {
        image?.setAttribute("src", url);
      })
      .catch((err) => {
        image?.setAttribute("src", "assets/images/placeholder.png");
      });
    // }
  }

  function Confirmation() {
    return (
      <div className="full-height-width overflow-y-scroll padding5">
        {areasList.map((area:any,index:number)=>{
          let products = areaProductsMapping[area.area_name].products
          return(
            <div className="white-card ion-margin-bottom shadow-light padding10" key={index}>
              <div className="heading3 ion-margin-bottom color-black font-normal ">{area.area_name}</div>
              {products.length?
              <table className="table">
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th>Image</th>
                    <th>ProductName</th>
                    <th>Categoy Name</th>
                    <th>Sub Categoy</th>
                  </tr>
                </thead>

                <tbody>
                  {products?.map((product:any,j:number)=>{
                  return(
                  <tr key={j}>
                      {/* <td >{j+1}</td> */}
                      <td > <img src="" width={80} className="review-product" id={`reviewProduct${area.area_name}${product.productName}`} alt="" /> </td>
                      <td className=""><div className="center-aligned-column heading4 font-md-small full-height">{product.productName}</div></td>
                      <td className=""><div className="center-aligned-column heading4 font-md-small full-height">{product.categoryName}</div></td>
                      <td className=""><div className="center-aligned-column heading4 font-md-small full-height">{product.subCategoryName}</div></td>
                  </tr>
                  )
                  })}
            
                </tbody>
              </table>
              :
              <div className="center-aligned-column full-width">
                <div className="heading4 ion-padding text-center full-width" style={{border:"1px dashed lightgray",borderRadius:"10px"}}>Products not added</div>
              </div>
              }
            </div>
          )
        })}
      </div>
    )
  }


  function renderHandle2d() {
    customizerConfig.setCurrentRenderingType("2d")
    resetCreateRenderParameters()
    if(isInteriorVisualization){
      upadateInteriorCameraConfig()
    }
    gotoSummary()
  }

  async function renderHandle3d() {
    if(!projectConfiguration.areAreasDefined){
      showFloorplanOptionWindow()
      return
    }
    resetCreateRenderParameters()
    customizerConfig.setCurrentRenderingType("3d")
    gotoSummary()
  }

  async function gotoSummary() {
    // disableInteriorVirtualization()
    hideComponentLoader("changeFinishLoader")
    showComponentLoader("canvasLoader")
    controls.reset()
    await waitFor(100)
    updateCameraSettingViewData(customizerConfig.currentRenderingType)
    setIsViewRenderMode(false)
 
    if(IS_SPACE_PLANNER_MODE){
      await disableSpacePlanner(50)
    }
    updateLoaderProgress("canvasLoader",1,1,"Please wait...")
    updateAreaCustomizer("all")
    enableFinalizeMode()
    undo.updateMode(customizerConfig.currentRenderingType)
  }

  return (
    <>
    <Modal
      isOpen = {showConfirmationModal}
      Content = {Confirmation}
      onDismiss = {dismisModal}
      heading="Review Products List"
      classNames="form-action-modal"
      id=""
      FooterContent={ConfirmationModalFooter}
    />

    <div className="display-none">
        <Button2
            configObj={applicationConfig}
            action={enableFinalizeMode}
            label={"2D Renders "}
            fontAwesomeIcon={"fas fa-border-none color-danger"}
            iconPos="left"
            classNames={"button- disable"}
          />
            <Button2
          configObj={applicationConfig}
          action={()=>setShowConfirmationModal(true)}
          label={"Renders"}
          fontAwesomeIcon={"fas fa-cube"}
          iconPos="left"
          classNames={"bg-color-primary marginTop5"}
        />
    </div>

    <div className="display-flex-row ">
      <div className="" style={{display:"flex",alignItems:"end"}}>
        {modularProductConfig?
        <SummaryLink
          currentConfigObject={modularProductConfig}
          moduleName={props.isProductConfigureMode?"customizein3d":"customizer"}
        />  
        :null}
      </div>


      <div className="padding10 hide-on-configure">
        {!props.isProductRenderMode?
        <>
        <SvgButtonRound
          action={showCreateRender}
          label="Gallery"
          classNames=""
          svgUrl="assets/icon/svg/gallery.svg"
          containerClass="hide-on-walkthrough-mode"
        />
        <SvgButtonRound
          action={renderHandle3d}
          label="Walkthrough"
          classNames=""
          svgUrl="assets/icon/svg/walkthrough.svg"
          containerClass="hide-on-walkthrough-mode"
        />
        </>
        :null}
        
        <SvgButtonRound
          action={renderHandle2d}
          label="2D Render"
          classNames="bg-color-primary"
          svgUrl="assets/icon/svg/camera_white.svg"
        />
      </div>

      <div className="create-render-button marginTop5 display-none">

      <Button2
        configObj={{id:"galleryButton"}}
        action={showCreateRender}
        label={"Gallery"}
        fontAwesomeIcon={"fas fa-image color-danger"}
        iconPos="left"
        classNames={"hide-on-configure width-inherit hide-on-walkthrough-mode shadow-medium no-border button-small"}
        svgUrl="assets/icon/svg/gallery_danger.svg"
      />

      <Button2
        configObj={applicationConfig}
        action={renderHandle3d}
        label={"Walkthrough"}
        fontAwesomeIcon={"fas fa-cube color-danger"}
        iconPos="left"
        classNames={"marginTop5 hide-in-mobile hide-on-configure border-color-primary width-inherit hide-on-walkthrough-mode shadow-medium no-border button-small"}
        svgUrl="assets/icon/svg/walkthrough_danger.svg"
      
      />

      <Button2
          configObj={applicationConfig}
          action={renderHandle2d}
          label={"Take Render"}
          fontAwesomeIcon={"fas fa-cube"}
          iconPos="left"
          classNames={"marginTop5 bg-color-primary hide-in-mobile hide-on-configure width-inherit shadow-medium no-border button-small"}
          svgUrl="assets/icon/svg/camera_white.svg"
        />
      
    </div>

    </div>


    </>
  );
};

export default FinalizeRender;
