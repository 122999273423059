import React, { useState } from "react";
import { EstreInventory } from "../../../../../utils/EstreInventory/InventoryConfiguration";
import { getMaterialItemFormData } from "../../../../../utils/EstreInventory/form";
import { hideComponentLoader, showAlert, showComponentLoader, showGlobalToast } from "../../../../../utils/UI_methods/global";
import Modal from "../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import Form from "./Form";
 


 
const CreateStock: React.FC<{
    isOpen:boolean
    setIsOpen:(val:boolean)=>void
    refreshList:()=>void
}> = (props) => {

    const[alertId] = useState("createLeadAlertId")

 
 

    async function submitHandle() {
        const formData = getMaterialItemFormData()
        console.log(formData)
        if(formData){
          showComponentLoader("storeLoader")
          EstreInventory.InventoryApi.stock.create(formData).then(async (data:any)=>{
            console.log(data)
            if(!data.data.error){
                props.setIsOpen(false)
                props.refreshList()
                hideComponentLoader("storeLoader")
                showGlobalToast("Created",3000)
            //   postCustomerCreateAction(customer)
            }else{
              showGlobalToast("Internal server error",3000,"error")
              hideComponentLoader("storeLoader")
            }
          })
        }else{
          showAlert(alertId,"Please enter mandatory fields","error")
          hideComponentLoader("storeLoader")
        }
      
      }

    

    function Footer() {
        return(
            <div className="display-flex-row">
                <Button2
                    configObj={{id:""}}
                    action={submitHandle}
                    label={"Save"}
                    fontAwesomeIcon={"fas fa-times color-black"}
                    iconPos=""
                    classNames={"no-shadow bg-color-primary"}
                />
                <Button2
                    configObj={{id:""}}
                    action={()=>{props.setIsOpen(false)}}
                    label={"Go back"}
                    fontAwesomeIcon={"fas fa-times color-black"}
                    iconPos=""
                    classNames={"no-shadow bg-color-light"}
                />
            </div>
        )
    }

  

 
 

 
  return (

    <>

    <Modal
        isOpen = {props.isOpen}
        Content = {()=>(
          <>
              <Form alertId={alertId} isUpdate={false}/>
          </>
        )}
        onDismiss = {()=>props.setIsOpen(false)}
        heading={"Create Item"}
        classNames="large-x-modal"
        id=""
        FooterContent={Footer}
    />
    </>
  );
};

export default CreateStock;
