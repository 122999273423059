import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { confirmAccordionHandle, confirmProccessAccordion, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import { ArmChairConfiguration, CotConfiguration, ReclinerConfiguration, STORE, SofaConfiguration, Variant } from "../../../../../utils/store/storeConfiguration";
import AccordionForProccessCss from "../../../../common/ui-components/accordion/AccordionForProccessCss";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ProductConfigSelector from "../product-customizer/AdvanceConfig/AdvanceConfiguration";
import ArmChairConfigOptions from "./ArmChairConfigOptions";
import CotConfigOptions from "./CotConfigOptions";
import ReclinerConfigOptions from "./ReclinerConfigOptions";
import SetMeasurement from "./SetMeasurement";
import SetPriceForm from "./SetPriceForm";
import SofaConfigOptions from "./SofaCofigOptions";
import { SofaVariantsConfiguration } from "../../../../../utils/store/customization-options-data/sofa";
import { Cot_Variants } from "../../../../../utils/store/customization-options-data/cot";
import { ReclinerVariantsConfiguration } from "../../../../../utils/store/customization-options-data/recliner";
import ShareLink from "../quotation/approval/ShareLink";

const ProductInfo: React.FC<{
  product:any
  isCustomOrderMode?:boolean
}> = (props) => {


  const[isOpenFabric,setIsOpenFabric] = useState(false)
  const[isPriceAvailable,setIsPriceAvailable] = useState(true)
  
  const[isconfigConfirmed,setIsConfigConfirmed] = useState(false)
 
  const[productConfiguration,setProductConfiguration] = useState(null)

  const[currPrice,setCurrPrice] = useState(productConfiguration?.price)

  const[currCategory] = useState(props.product?.category_name)
  const[isOpenMeasurement,setIsOpenMeasurements]:any =  useState(false)

  useEffect(()=>{
    // if((!STORE.productConfiguration || STORE.currProduct?.product_id !== props.product?.product_id)){
      setProductConfigObject()
    // }
    if(props.product){
      STORE.storeApi.pricing.get(props.product?.product_id).then(data=>{
        STORE.data.configPrices = data.data.data
      }).catch(err=>{
        console.log(err)
      })
    }

    setTimeout(() => {
      updatePrice()
    }, 500);
  },[currCategory,props.product])

  useEffect(()=>{
    STORE.functionRefs.productCustomizer.setIsPriceAvailable = setIsPriceAvailable
  },[])


  function setProductConfigObject() {
    let config = null
    if(currCategory === "Sofa" || currCategory === "SofaBed"){
      let variant = new Variant(SofaVariantsConfiguration[0].name) 
      config = new SofaConfiguration(variant,props.product?.default_configuration)
    }
    if(currCategory === "UpholsteredBed" || currCategory === "Cot"){
      let variant = new Variant(Cot_Variants[0].name) 
      config = new CotConfiguration(variant,props.product?.default_configuration)
    }
    if(currCategory === "Recliner"){
      let variant = new Variant(ReclinerVariantsConfiguration[0].name) 
      config = new ReclinerConfiguration(variant,props.product?.default_configuration)
    }
    if(currCategory === "ArmChair"){
      config = new ArmChairConfiguration(props.product?.default_configuration)
    }
    STORE.productConfiguration = config
    setProductConfiguration(config)

  }


  function CurrPrice() {
    return(
      <div className="center-aligned-column">
        <div className="heading2 font-md-small marginRight10 color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">{getFormattedPrice(currPrice)}  </div>
      </div>
    )
  }

 


  function confirmHandle() {
    setIsOpenFabric(false)
    setIsConfigConfirmed(true)
    showGlobalToast("Config saved")
    confirmProccessAccordion(4)
  }

  
  function confirmPriceHandle() {
    setCurrPrice(productConfiguration.price)
    confirmAccordionHandle(3)
  }



  function updatePrice() {
    STORE.productConfiguration?.updatePrice()
    setCurrPrice(STORE.productConfiguration?.price)
  }


 
 

  return (
    <div className='product-details overflow-y-scroll bg-color-white full-height-width product-customizer-wrapper padding10'>

      <IonModal
        isOpen={isOpenMeasurement}
        onDidDismiss={()=>setIsOpenMeasurements(false)}
        cssClass={"medium-x-modal"}
      >
      <SetMeasurement confirmHandle={()=>{setIsOpenMeasurements(false)}} productConfiguration={STORE.productConfiguration}/>
      </IonModal>

     

      {productConfiguration?
        <ProductConfigSelector
          product={props.product}
          isOpen={isOpenFabric}
          setIsOpen={setIsOpenFabric}
          productConfiguration={productConfiguration}
          confirmHandle={confirmHandle}
          isconfigConfirmed={isconfigConfirmed}
        />
      :null}
 

      {productConfiguration?
        <div className="full-width accordion-wrapper">
        {currCategory === "Sofa" || currCategory === "SofaBed"?
          <SofaConfigOptions setIsOpenAdvancedConfig={setIsOpenFabric} productConfiguration={productConfiguration} product={props.product} updatePrice={updatePrice}/>
        :currCategory === "UpholsteredBed"?
          <CotConfigOptions productConfiguration={productConfiguration} product={props.product} updatePrice={updatePrice}/>
        :currCategory === "Recliner"?
          <ReclinerConfigOptions productConfiguration={productConfiguration} product={props.product} updatePrice={updatePrice}/>
        :currCategory === "ArmChair"?
          <ArmChairConfigOptions productConfiguration={productConfiguration} product={props.product} updatePrice={updatePrice}/>
        :
        null}

      {props.isCustomOrderMode || !isPriceAvailable?
        <AccordionForProccessCss
          label="Pricing"
          SubHeader={()=>(
            <CurrPrice/>
          )}
          Content={()=>(
            <SetPriceForm productConfiguration={productConfiguration}/>
          )}
          classNames=""
          Footer={()=>(
            <div className="center-aligned-column padding5 full-width bg-color-light">
              <div className="">
                <Button2
                  configObj={{id:""}}
                  action={()=>{confirmPriceHandle()}}
                  label={"Confirm"}
                  fontAwesomeIcon={"fas fa-cart-plus color-white"}
                  iconPos=""
                  classNames={"no-shadow margin0 bg-color-warning"}
                />
              </div>
            </div>
          )}
          index={3}
        />
      :null}

        </div>
      :null}

      {/* <div onClick={()=>{setIsOpenFabric(true)}} className="display-flex-row space-between marginTop10">
        <div></div>
        <div className="display-flex-row no-wrap padding5 pointer">
          <span><i className="fas fa-cogs color-danger font-md-small"></i></span>
          <span className="heading3 marginleft5 font-md-small center-aligned-column">{`${isconfigConfirmed?"View / Update Customization":"Advanced Customization"}`}</span>
        </div>
      </div> */}

     
      

      {/* <AccordionForProccessCss
        label="Advanced Configuration"
        SubHeader={()=>(
          <CurrAdvancedConfig/>
        )}
        Content={()=>(
          <></>
        )}
        classNames=""
        onClick={()=>setIsOpenFabric(true)}
        index={4}
      /> */}


     

      
      <div className="marginTop10 display-flex-row space-between">


        <Button2
          configObj={{id:""}}
          action={()=>{setIsOpenMeasurements(true)}}
          label={`Measurements`}
          fontAwesomeIcon={"fas fa-cog color-danger"}
          iconPos="left"
          classNames={"no-shadow margin0 button- bg-color-light"}
        />

        <Button2
          configObj={{id:""}}
          action={()=>{setIsOpenFabric(true)}}
          label={`${isconfigConfirmed?"View / Update Customization":"Advanced Customization"}`}
          fontAwesomeIcon={"fas fa-cogs color-warning"}
          iconPos="left"
          classNames={"no-shadow button margin0 cart-button cart-button-warning-outline"}
        />

      </div>

      {/* <ShareLink cartItems={null}/> */}

    
{/* 
      <div className="marginTop10 padding10">
        <Button2
          configObj={{id:""}}
          action={()=>{setIsOpenFabric(true)}}
          label={`${isconfigConfirmed?"View / Update Customization":"Advanced Customization"}`}
          fontAwesomeIcon={"fas fa-cogs color-warning"}
          iconPos="left"
          classNames={"no-shadow button margin0 cart-button cart-button-warning-outline"}
        />
      </div> */}

           
      </div>
  );
};

export default ProductInfo;
