import $ from "jquery";
import React, { useEffect, useState } from "react";
import { showGlobalToast } from "../../../../../../../utils/UI_methods/global";
import { applicationConfig, getCleanedStringForDatabase } from "../../../../../../../utils/methods";
import { getTodayTimestampOnlyDate } from "../../../../../../../utils/store/crm/calendar";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
 


 
const Comments: React.FC<{
    ticket:any
}> = (props) => {

  const[commentsList,setCommentsList] = useState([])
  const[timeDifference] = useState(Math.floor((getTodayTimestampOnlyDate() - props.ticket?.created_at) / (1000 * 60 * 60 * 24)))


  useEffect(()=>{
    fetchData()
  },[])
  

  function fetchData() {
    CRM.crmApi.ticket.comments.get(props.ticket?.ticket_id).then(data=>{
      if(!data.data.error){
        let list = data.data.data
        setCommentsList(list)
      }
    }).catch(err=>{
      console.log(err)
    })
  }

  

 
  async function submitHandle() {
    const formData = {
        comments:getCleanedStringForDatabase($("#ticketComments").val() || ""),
        createdAt:Number(getTodayTimestampOnlyDate())
    }
    if(formData.comments){
      CRM.crmApi.ticket.comments.create(applicationConfig?.user?.userId,props.ticket?.ticket_id,formData).then(async (data:any)=>{
        if(!data.data.error){
          fetchData()
          $("#ticketComments").val("")
        }else{
          console.log(data)
          showGlobalToast("Internal server error",2000,"error")
        }
      })
    }else{
      showGlobalToast("No comments entered",2000,"error")
    }
  }

  function getTextFromDay() {
    if(timeDifference === 0){
      return "Today"
    }
    if(timeDifference === 1){
      return "1 Day ago"
    }
    if(timeDifference > 1){
      return `${timeDifference} Days ago`
    }
    return `${timeDifference} Days ago`
  }


  return (
    <div className="ion-margin-top white-card border no-shadow position-relative padding10">

      <div className="heading3 font-normal">Comments</div>

      <div className="comments-container overflow-y-scroll">

        {commentsList.map((comment:any,index:number)=>{
          return(
            <div key={index} className="display-flex-row border marginTop5 no-wrap padding10">
              <div className="">
                <div className="display-flex-row">
                  <span className="color-primary heading4 font-md-small capitalize">{applicationConfig?.user?.username}</span>
                  <span className="heading4 marginleft5 font-small">{getTextFromDay()}</span>
                </div>
                <div className="heading4 color-black font-md-small marginTop5">{comment.comments}</div>
              </div>
            </div>
          )
        })}

      </div>


      <div className="display-flex-row no-wrap marginTop10">
        <div className="input-container position-relative">
            <input className="input shadow-light" placeholder="Type to enter comments" id="ticketComments" />
            <div className="right-middle" onClick={submitHandle}>
              <i className="fa fa-paper-plane font-medium pointer click-effect color-primary"></i>
            </div>
        </div>
      </div>
   
  </div>
  );
};

export default Comments;
