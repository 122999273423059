import { addCircle, colorPalette, construct } from "ionicons/icons";
import $ from "jquery";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { kitchenPlanner } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { dragStartCustomizer } from "../../../../../../utils/customizein3d/area3dmodel/dragging";
import { addProductInArea } from "../../../../../../utils/customizein3d/area3dmodel/helper";
import { addModuleHandle, replaceProduct } from "../../../../../../utils/customizein3d/area3dmodel/modules";
import { closeControlsPanelAndHideProducts } from "../../../../../../utils/customizein3d/area3dmodel/UI_methods";
import CloseMenu from "../../../../../common/modules/customizein3d/common/CloseMenu";
import Header from "../../../../../common/modules/customizein3d/common/Header";
import Groups from "../../../../../common/modules/KitchenPlanner/Groups";
import ModuleInfo from "../../../../../common/modules/KitchenPlanner/ModuleInfo";
import ProductModules from "../../../../../common/shared-components/product-modules/ProductModules";
import MenuHeader from "../../../../../common/ui-components/header/MenuHeader";
import ControlsTab from "../../../../../common/ui-components/tabs/ControlsTab";
import ProductDescription from "../../moodboard/common/ProductDescription";
import ProductImages from "../../moodboard/controls/ProductImages";
import ProductInfo from "../canvas/ProductInfo";
import Finish from "./Finish";
import CreateRender from "./renders/CreateRender";
import ColorSwatch from "./ColorSwatch";


const Controls: React.FC<{
  textures: object;
  currentConfigObject: any;
  configObj:any
  isProductConfigureMode:boolean
  isKitchenPlannerMode:boolean
}> = forwardRef((props, ref) => {
  const [currTab, setCurrTab] = useState<string>("Finish");
  // var currTab = "Finish"

  const[currProductId,setCurrProductId] = useState(0)
  const[currProductName,setCurrProductName] = useState("")
  const[isOpenReplaceProduct,setisOpenReplaceProduct] = useState(false)
  const[isOpenAddProduct,setisOpenAddProduct] = useState(false)

  
  const[tabItems,setTabItems] = useState([
    {tabName:"Finish",label:"Finish",ionIcon:colorPalette},
    {tabName:"Replace",label:"Replace",ionIcon:construct}
  ])

  useEffect(()=>{
    if(props.isProductConfigureMode){
      setTabItems([
        {tabName:"AddModule",label:"Add module",ionIcon:addCircle},
        {tabName:"Finish",label:"Finish",ionIcon:colorPalette},
      ])
    }else{
      setTabItems([
        {tabName:"Finish",label:"Finish",ionIcon:colorPalette},
        {tabName:"Replace",label:"Replace",ionIcon:construct}
      ])
    }
  },[props.isProductConfigureMode])

  // useEffect(()=>{
  //   if(props.isKitchenPlannerMode){
  //     setTabItems([
  //       {tabName:"AddModule",label:"Add module",ionIcon:addCircle},
  //       {tabName:"Replace",label:"Replace",ionIcon:construct}
  //     ])
  //   }else{
  //     setTabItems([
  //       {tabName:"Finish",label:"Finish",ionIcon:colorPalette},
  //       {tabName:"Replace",label:"Replace",ionIcon:construct}
  //     ])
  //   }
  // },[props.isKitchenPlannerMode])

  useEffect(()=>{
    if(props.currentConfigObject){
      props.currentConfigObject.functions.setCurrProductId = setCurrProductId
      props.currentConfigObject.functions.setCurrProductName = setCurrProductName
      props.currentConfigObject.functions.setCurrTab = setCurrTab

      props.configObj.setFunctionRef("customizer",{setisOpenReplaceProduct:setisOpenReplaceProduct})
      props.configObj.setFunctionRef("customizer",{setisOpenAddProduct:setisOpenAddProduct})
      props.configObj.setFunctionRef("customizer",{changeTabOption:changeTabOption})
    }
  },[props.currentConfigObject])

  const openMaterialsWindowRef:any = useRef()

  useImperativeHandle(ref,()=>({
    openMaterialsWindow(element:any) {
      try {
        openMaterialsWindowRef.current.openMaterialsWindow(element)
      } catch (error) {
      }
    },
    closeMaterialsWindow(){
      try {
        openMaterialsWindowRef.current.closeMaterialsWindow()
      } catch (error) {
        console.log(error)      
      }
    },
    updateControlsTab(value:string){
      // setCurrTab("Home")
    }
  }))



  function changeTabOption(tabName: string) {
    setCurrTab(tabName);
    updateTabValue(tabName)
  }


 

  function updateTabValue(val:string){
    $(".tab-content").removeClass("--is-active")
    $("#"+val).addClass("--is-active")
  }

 



  return (
    <React.Fragment>
      <ProductInfo/>

      <div className="customizer-add-products shadow-light top-right full-width zindex100 moodboard-wrapper" style={{padding:"0",marginTop:"0"}}>
        
        <div className="fill-available-height position-relative" id="addProductsProductImages">
            <ProductImages
                setProductModelSrc={()=>{}}
                configObj={props.configObj}
                currArea={""}
                action={(product)=>addProductInArea(product)}
                dragStart={dragStartCustomizer}
                moduleName="customizer"
                parentContainerId="addProductsProductImages"
              />
        </div>
        
        </div>

        <div className="customizer-create-render shadow-light top-right full-width zindex100 moodboard-wrapper" style={{padding:"0",marginTop:"0"}}>
         <CreateRender/>
        </div>

        <div id="colorSwatchPanel" className="display-none padding-10-15 overflow-y-scroll controls-panel shadow-light top-right full-height-width zindex100" style={{padding:"0",marginTop:"0"}}>
         <ColorSwatch/>
        </div>

        <div className="bottom-left full-width zindex100">
          <CloseMenu
            heading="Close Menu"
            action={closeControlsPanelAndHideProducts}
          />
        </div>
        
    
      <div className="customize-product-options full-height-width">

        <ProductDescription
          configObj={props.configObj}
          setProductModelSrc={()=>{}}
        />
        
      <div className="" id="customizerControlsTabs">
        {/* <div className="pointer position-relative border-top space-between ion-padding border-bottom full-width" >
            <span className="color-black display-flex-row no-wrap heading">
              <span className="center-aligned-column heading1 letter-spacing" id="productHeading">Configure</span> 
            </span>
        </div> */}
        
        <ControlsTab
          configObj={props.configObj}
          tabItems={tabItems}
          currSelectedValue={currTab}
          action={changeTabOption}
          theme="red"
        />

       
      </div>
        


        <div id="Finish" className="tab-content --is-active" style={{padding:"0"}}>
          <Finish
            textures={props.textures}
            ref={openMaterialsWindowRef}
            changeTabOption={changeTabOption}
            updateTabValue={updateTabValue}
            currentConfigObject={props.currentConfigObject}
            configObj={props.configObj}
          />
        </div>

        <div id="AddModule" className="tab-content" style={{padding:"0"}}>
          <ProductModules
            productId={currProductId}
            moduleRef={null}
            action={(module:any)=>addModuleHandle(module)}
            moduleName="customizer"
            dragStart={()=>{}}
            parentContainerId={"AddModule"}
          />
        </div>

        <div id="Replace" className="tab-content" style={{padding:"0"}}>
          {/* <div className="pointer position-relative border-top space-between ion-padding border-bottom full-width" >
              <span className="color-black display-flex-row no-wrap heading">
                <span className="center-aligned-column heading1 letter-spacing" id="">Edit module</span> 
              </span>
          </div> */}
          {/* <Actions
            configObj={props.configObj}
          /> */}
          <div id="replaceProductProductImages" className="shadow-light zindex30 display-flex-column no-wrap full-height customizer-wrapper moodboard-wrapper" style={{padding:"0",marginTop:"0"}}>
            {/* {!isOpenReplaceProduct? */}
            <ProductImages
              setProductModelSrc={()=>{}}
              configObj={props.configObj}
              currArea={"BedRoom1"}
              action={(product)=>replaceProduct(product)}
              moduleName="customizer"
              dragStart={()=>{}}
              parentContainerId="replaceProductProductImages"
            />
            {/* // :null
            // } */}
            </div>
        </div>

        {props.isKitchenPlannerMode?
          <>
            <div id="AddKitchenModules" className="full-height-width top-left padding0 bg-color-white" style={{zIndex:1001}}>
              <div className="full-height-width position-relative">
                  <ProductModules
                    productId={currProductId}
                    moduleRef={null}
                    action={(module)=>{kitchenPlanner.replaceModuleHandle(module)}}
                    moduleName="kitchen"
                    dragStart={dragStartCustomizer}
                    parentContainerId={"AddKitchenModules"}
                  />
                <div className="bottom-left full-width zindex100">
                  <CloseMenu
                    heading="Close Menu"
                    action={closeControlsPanelAndHideProducts}
                  />
                </div>
              </div>
            </div>

            <div id="productInfoWrapper" className="full-height-width tab-content top-left padding0 bg-color-white" style={{zIndex:1001}}>
              <div className="full-height-width position-relative">
                <Header heading="Module Info" configObj={props.configObj} />
                <ModuleInfo
                />
                <div className="bottom-left full-width zindex100">
                  <CloseMenu
                    heading="Close Menu"
                    action={closeControlsPanelAndHideProducts}
                  />
                </div>
              </div>
            </div>

            <div id="kitchenPlannerGroupsWrapper" className="full-height-width tab-content top-left padding0 bg-color-white" style={{zIndex:1001}}>
              <div className="full-height-width position-relative">
                <Header heading="Groups List" configObj={props.configObj} />
                <Groups
                />
                <div className="bottom-left full-width zindex100">
                  <CloseMenu
                    heading="Close Menu"
                    action={closeControlsPanelAndHideProducts}
                  />
                </div>
              </div>
            </div>

          </>
        :null}



        

       
      </div>

    </React.Fragment>
  );
});

export default Controls;
