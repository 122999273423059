import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { canUserAccessRoute, getUserDefaultRoute } from './authorization/authorization';
import { getToken, isRouteExistsInSubDomain } from './methods';
 
// handle the private routes
const PrivateRoute:React.FC<{component:any;path:any;exact:any}> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props:any) => isRouteExistsInSubDomain(rest.path) ? getToken() ? canUserAccessRoute(rest.path) ? <Component {...props} /> : <Redirect to={{ pathname: getUserDefaultRoute() }} /> : <Redirect to={{ pathname: '/login' }} /> : <Redirect to={{ pathname: '/login' }} />}
    />
  )
}
 
export default PrivateRoute;