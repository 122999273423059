import React from "react";
  
  const InitialText: React.FC<{
  }> = (props) => {
 
   
    return (
      <React.Fragment>
       <div className="full-width middle initial-text --is-active"
          id="initialText">
          <div className="text-center heading ion-padding">
            Create Moodboard
          </div>
          <div className="paragraph text-center">
            Start by dragging items into canvas
          </div>
        </div>
      </React.Fragment>
    );
  };
  
  export default InitialText;
  