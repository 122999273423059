import $ from "jquery";
import { LineSegments, Plane, Raycaster, Scene, Vector2, Vector3 } from "three";
// import { areaModel, camera } from "../area3dmodel/area3dModel";
var mouseVector = new Vector3();
var vector = new Vector3();
var rendererDimensions = new Vector2()

export class MeasurementTool{
    
    scene:Scene = null
    line:LineSegments = null
    raycaster:Raycaster = null
    renderer:any = null
    // camera:any = null
    enabled:boolean = false
    
    snapValues = [-90,90,0,180]
    currAngle:number = 0


    isSetStartPoint:boolean = false

    startPoint:Vector3 = null
    endPoint:Vector3 = null

    parentElement:any = null
    element:any = null
    header:any = null
    closeButton:any = null
    
    startDot:any = null
    endDot:any = null
    movableDot:any = null
    distanceLine:any = null
    distanceText:any = null

    camera:any = null
    group: any = null

    planeForRaycasting:Plane = null
    target:Vector3 = new Vector3()
    
    constructor(scene:Scene,element:any,raycaster:Raycaster,renderer:any,camera:any,groupForRaycasting:any,planeForRaycasting:Plane){
        this.scene = scene
        this.parentElement = $(element)
        this.element = element
        this.raycaster = raycaster

        this.camera = camera
        this.group = groupForRaycasting 

        this.renderer = renderer

        this.planeForRaycasting = planeForRaycasting
    }

    enable(){
        this.enabled = true 
        this.addElements()
        $(".hide-on-measurement").addClass("display-none")
    }


    click(x:any,y:any,camera:any){
        if(this.isSetStartPoint){
            this.isSetStartPoint = false
            this.endDot.css("top",`${y - $(".main-header-wrapper").height() - this.endDot.height() / 2}px`) 
            this.endDot.css("left",`${x - this.endDot.width() / 2}px`) 
            let position = this.get3DPoint(x,y,camera)
            this.endPoint = position
        }else{
            let position = this.get3DPoint(x,y,camera)
            this.startDot.css("top",`${y - $(".main-header-wrapper").height() - this.startDot.height() / 2}px`) 
            this.startDot.css("left",`${x - this.startDot.width() / 2}px`) 
            this.isSetStartPoint = true
            this.startPoint = position

            this.endDot.css("top","-100px")
            this.distanceLine.css("top","-100px")
        }
    }

  
    lineDistance(x:number, y:number, x0:number, y0:number){
        return Math.sqrt((x -= x0) * x + (y -= y0) * y);
    };
    
    drawLine(startPointElement:any,endPointElement:any) {
      var pointA = startPointElement.offset()
      var pointB = endPointElement.offset()
      var width =  startPointElement.width() / 2;
      this.currAngle = Math.atan2(pointB.top - pointA.top, pointB.left - pointA.left) * 180 / Math.PI;
      var distance = this.lineDistance(pointA.left, pointA.top, pointB.left, pointB.top);


      this.distanceLine.css('transform', 'rotate(' + this.currAngle + 'deg)');
    
      this.distanceLine.css('width', distance + 'px');
      this.distanceLine.css('top', (pointA.top - $(".main-header-wrapper").height() + width) + 'px');
      this.distanceLine.css('left', (pointA.left + width) + 'px');
    }

    snapDistanceLineByAngle(){
        for (const currSnapValue of this.snapValues) {
            if(Math.abs(currSnapValue) - Math.abs(this.currAngle) < 10){
                this.distanceLine.css('transform', 'rotate(' + currSnapValue + 'deg)');
            }
        }
    }
    
    
    updateCirclePosition(x:any,y:any,position:Vector3) {
        if(position){
            this.movableDot.css("top",`${y - $(".main-header-wrapper").height() - this.movableDot.height() / 2}px`) 
            this.movableDot.css("left",`${x - this.movableDot.width() / 2}px`) 
        }
    }

    sceneHoverInteraction(x:any,y:any,camera:any){
        let position = this.get3DPoint(x,y,camera)
        if(this.isSetStartPoint && position){
            // const positions = this.line.geometry.attributes.position.array as Array<number>
            // positions[0] = this.startPoint.x
            // positions[1] = this.startPoint.y
            // positions[2] = this.startPoint.z
            // positions[3] = position.x
            // positions[4] = position.y
            // positions[5] = position.z
            // this.line.geometry.attributes.position.needsUpdate = true
            // const startPos = this.get3DPoint(this.startDot.offset().left,this.startDot.offset().top,camera)
            // console.log(this.startDot.offset().left,this.startDot.offset().top)
            const distance = this.startPoint.distanceTo(position)    
            this.drawLine(this.startDot,this.movableDot)
            this.updateDistanceTextPosition(distance)
        }
        this.updateCirclePosition(x,y,position)
    }
    
    updateDistanceTextPosition(distance:number){
        // const vectorA = this.startPoint
        // const vectorB = endPosition

        // const center = new Vector3()
        // center.addVectors(vectorA, vectorB).divideScalar(2)

        // worldToScreenVector?.set(center.x,center.y,center.z)
        // worldToScreenVector?.project(this.camera)
    
        // worldToScreenVector.x = Math.round(((worldToScreenVector?.x + 1) / 2) * renderer?.domElement.width)
        // worldToScreenVector.y = Math.round((-(worldToScreenVector?.y - 1) / 2) * renderer?.domElement.width)

        distance = Math.round((distance * 100)/2.54)
        this.distanceText.text(String(distance + " In"))

        // setScreenPositionOfElement(this.distanceText,worldToScreenVector)
        // console.log(this.distanceText)

    }


    addElements(){
        let disable = this.disable.bind(this)

        this.header = $("<div></div>",{
            class:"white-card shadow-medium pulse-anchor header-wrapper measurement-tool-wrapper top-middle text-center color-black",
            id:"measurementToolHeader"
        }).html("<div class='heading2'>Measurement Tool</div><div class='heading4 color-dark marginTop5'>Select two elements to know the distance between them.</div>")

        this.movableDot = $("<div></div>",{
            class:"top-left movable-dot",
            id:"measurementMovableDot"
        })

        this.startDot = $("<div></div>",{
            class:"top-left movable-dot start-dot",
            id:"measurementStartDot"
        })

        this.endDot = $("<div></div>",{
            class:"top-left movable-dot end-dot",
            id:"measurementEndDot"
        })

        
        this.distanceLine = $("<div></div>",{
            class:"top-left distance-line",
            id:"measurementDistanceLine"
        })

        this.distanceText = $("<div></div>",{
            class:"middle distance-text",
        })

        this.closeButton = $("<button></button>",{
            class:"op-btn bg-color-black top-right measurement-tool-wrapper close-button",
            id:"measurementToolCloseButton"
        }).text("Exit measurement tool")

        this.closeButton.click(function(){
            disable()
        })

        this.parentElement.append(this.header)
        this.parentElement.append(this.movableDot)
        this.parentElement.append(this.startDot)
        this.parentElement.append(this.endDot)
        this.parentElement.append(this.distanceLine)
        this.distanceLine.append(this.distanceText)
        this.parentElement.append(this.closeButton)
    }

    disable(){
        this.enabled = false 
        $("#measurementToolHeader").remove()
        $("#measurementToolCloseButton").remove()
        $("#measurementMovableDot").remove()
        $("#measurementStartDot").remove()
        $("#measurementEndDot").remove()
        $("#measurementDistanceLine").remove()
        this.distanceText.remove()
        $(".hide-on-measurement").removeClass("display-none")
    }

    get3DPoint(x:number, y:number,camera:any) {

        y = y - this.element.getBoundingClientRect().top + window.scrollY
        x = x - this.element.getBoundingClientRect().left + window.scrollX
      
        this.renderer.getSize(rendererDimensions)
  
        x = (x / rendererDimensions.x) * 2 - 1
        y = -(y / rendererDimensions.y) * 2 + 1
    
        mouseVector?.set(x, y,0.5)
        this.raycaster.setFromCamera(mouseVector, camera);
        // var intersects = this.raycaster?.intersectObject(this.group,true)
        // if (intersects.length > 0){
        //     return intersects[0].point;
        // }
        return this.raycaster.ray.intersectPlane(this.planeForRaycasting, this.target).clone()
        return null
    };

    animate(){
        return
        if(this.startPoint && this.endPoint && this.enabled && !this.isSetStartPoint){
            const canvas = this.renderer?.domElement

            vector?.set(this.startPoint.x,this.startPoint.y,this.startPoint.z)
            vector?.project(this.camera)
        
            vector.x = Math.round((0.5 + vector?.x / 2) * (canvas.width))
            vector.y = Math.round((0.5 - vector?.y / 2) * (canvas.height))

            this.startDot.css("top",`${vector.y - this.startDot.height() / 2}px`) 
            this.startDot.css("left",`${vector.x - this.startDot.width() / 2}px`) 

            vector?.set(this.endPoint.x,this.endPoint.y,this.endPoint.z)
            vector?.project(this.camera)

            vector.x = Math.round((0.5 + vector?.x / 2) * (canvas.width))
            vector.y = Math.round((0.5 - vector?.y / 2) * (canvas.height))

            this.endDot.css("top",`${vector.y - this.endDot.height() / 2}px`) 
            this.endDot.css("left",`${vector.x - this.endDot.width() / 2}px`) 

            const distance = this.startPoint.distanceTo(this.endPoint)
            this.drawLine(this.startDot,this.endDot)
            this.updateDistanceTextPosition(distance)
        }
    }

    hide(){
        if(this.enabled){
            this.startDot.css("top","-100px")
            this.endDot.css("top","-100px")
            this.distanceLine.css("top","-100px")
        }
    }
}