import React, { useState } from "react";
 

 
const StockListContainer: React.FC<{
  allItems:any
  refreshList:()=>void
}> = (props) => {


 

  return (
    <div className="full-height-width display-flex-column no-wrap">
      
        <div className="leads-list-container display-flex-column no-wrap full-height-width position-relative">
          <div className="container overflow-y-scroll"  style={{paddingBottom:"180px"}}>
            <table className="table inventory-stock-table">
                <thead className="light-background">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Material Type</th>
                    <th>Quality Variant</th>
                    <th>Quantity in Stock</th>
                    <th>Storage Recommandation</th>
                    <th>Reorder Level</th>
                    <th>Reorder Quantity</th>
                    <th>Lead Time</th>
                    <th>Safety Stock</th>
                    <th>Unit Weight</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {props.allItems?.map((material: any, index: number) => {
                    return (
                      <tr key={index} className="text-center ">
                        <td>{index+1}</td>
                        <td>{material.material_name}</td>
                        <td>{material.material_type}</td>
                        <td>{material.quality_variant}</td>
                        <td>{material.quantity_in_stock}</td>
                        <td>{material.storage_recommandation}</td>
                        <td>{material.reorder_level}</td>
                        <td>{material.reorder_quantity}</td>
                        <td>{material.lead_time}</td>
                        <td>{material.safety_stock}</td>
                        <td>{material.unit_weight}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
          </div>
        </div>
      
    </div>
  );
};

export default StockListContainer;
