import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { applicationConfig, logger } from "../../../../../utils/methods";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify.js";
import SpacePlanner from "../../../../common/modules/customizein3d/controls/SpacePlanner";
import Modal2 from "../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import CustomInstructions from "../product-customizer/AdvanceConfig/CustomInstructions";
import { showProductCart } from "../../../../../utils/store/product-cart";
import Summary from "./Summary";
import { saveScreenshotins3ForSummary, saveScreenShotToS3, uploadCavasScreenshot } from "../../../../../utils/customizein3d/modules/helper";
import { IonModal } from "@ionic/react";
import { sceneBackgrounds } from "../../../../../utils/customizein3d/modules/customizein3d";
import Loader from "../../../../common/shared-components/main-ui/Loader";

const SummaryFooter: React.FC<{
  productConfiguration:any
  product:any
  isElevateMode:boolean
}> = (props) => {
   
  const[isOpenAdvanced,setIsOpenAdvanced] = useState(false)

  const productRef = useRef(null)

  const[isOpenSummary,setIsOpenSummary] = useState(false)


  useEffect(()=>{
    if(isOpenSummary){
      saveScreenshotins3ForSummary()
      logger?.info("customizein3d","V-Sum")
    }
},[isOpenSummary])

  function closeRoomLayout() {
    $("#roomLayoutContainer").addClass("display-none")
  }

  function show() {
    $(productRef.current).removeClass("display-none")
  }

 
  function closeSummary() {
    setIsOpenSummary(false)
  }

  function showCartHandle() {
    window.history.back();
    setTimeout(() => {
        showProductCart()
    }, 500);
  }


  function openSummaryAction() {
    if(!props.isElevateMode){
      // saveScreenShotToS3(true)
        setIsOpenSummary(true)
    }else{
        // setIsOpenConfirm(true)
    }
  }

  
  function showRequestQuotation() {
    $("#requestQuotationWindow").fadeIn()
  }

  function showFinishPanel() {
    $("#accordion_2").find(".accordion-header").click()
  }
 

  return (
      <div className="full-height-width">
      <IonModal
          isOpen = {isOpenSummary}
          onDidDismiss = {()=>setIsOpenSummary(false)}
          cssClass="share-modal"
          id=""
      >
        <>
        {isOpenSummary?
          <Summary product={props.product} isOpen={isOpenSummary} addToCartHandle={showCartHandle} closeHandle={()=>closeSummary()} isSummaryMode={false}/>
        :null}
        </>
      </IonModal>

      <Modal2
          isOpen = {isOpenAdvanced}
          Content = {()=>(
            <CustomInstructions customizingOptions={[]} productConfiguration={props.productConfiguration} setCurrSelectedWindowIndex={null} currSelectedWindowIndex={0} index={0}/>
          )}
          onDismiss = {()=>setIsOpenAdvanced(false)}
          FooterContent={()=>(<></>)}
          heading="Advanced"
          classNames="medium-x-modal"
          id=""
      />

        <div className="footer-summary position-relative white-card no-border-radius border-top center-aligned-column" style={{height:"4rem",zIndex:30}}>
          <div className="top-left bg-color-white show-on-background display-none full-height-width zindex100">
            <div className="center-aligned-column">
              <Button2
                configObj={{id:""}}
                action={()=>{sceneBackgrounds.enable()}}
                label={"Back to backgrounds"}
                fontAwesomeIcon={"fas fa-arrow-left color-danger"}
                iconPos="left"
                classNames={"store-bg-color-primary "}
              />
            </div>
          </div>
          
          <div className="pointer full-width">
              <div className="display-flex-row no-wrap space-between">
                <div className="center-aligned-column marginleft5">
                  <div className="display-flex-row" onClick={()=>{setIsOpenAdvanced(true)}}>
                    <span className=""><i className="fas fa-plus color-danger"></i></span>
                    <span className="heading3 marginleft5">Instruction</span>
                  </div>
                </div>
                  <div className="display-flex-row no-wrap"  >
                  {!props.isElevateMode?
                      <div className="display-flex-row  no-wrap space-between full-width marginRight10">
                          <div className="center-aligned-column position-relative">
                            {applicationConfig.clientName === "HomeZone"?
                              <span style={{zIndex:10,top:"0"}} className="top-right text-no-wrap padding0 font-ex-small change-finish-hint heading1 color-danger" onClick={showFinishPanel}>Change Finish</span>
                            :null}
                              <span className="heading1 marginleft5 font-normal color-black total_price center-aligned-column">{"NA"} </span>
                              <Loader 
                              className="fade-background-loader"
                              id={"updatePriceLoader"}
                              data={{}}
                              loaderType="spinner"
                            />
                          </div>
                          <div onClick={openSummaryAction} className="white-card marginleft10 shadow-light heading2 bg-color-primary color-white" style={{borderRadius:"25px",padding:"0.4rem 1.2rem"}}>
                              Summary
                          </div>
                          
                           
                      </div>
                  :
                  <div className="display-flex-row no-wrap" onClick={showRequestQuotation}>
                  <div className="white-card marginleft10 shadow-light heading2 bg-color-primary color-white" style={{borderRadius:"25px",padding:"0.4rem 1rem"}}>
                      Request Quote
                  </div>
                  </div>
                  }
              </div>
          </div>
          </div>
        </div>
        
        <div id="roomLayoutContainer" className="full-height-width top-left bg-color-white padding0 display-none" style={{zIndex:1001}}>
          <div className="heading2 color-black bg-color-active position-relative zindex1 padding1rem text-center shadow-light border-bottom">
          Room Layout
          </div>
          <SpacePlanner
            configObj={applicationConfig}
          />
          <div className="bottom-middle padding10">
            <Button2
              configObj={{id:""}}
              action={closeRoomLayout}
              label={"Close"}
              fontAwesomeIcon={"fas fa-arrow-left color-danger"}
              iconPos=""
              classNames={"store-bg-color-primary"}
            />
          </div>
        </div>

        <div className="hide-in-desktop bottom-right padding10">
          <Button2
              configObj={{id:""}}
              action={()=>{show()}}
              label={""}
              fontAwesomeIcon={"fas fa-plus color-white"}
              iconPos="left"
              classNames={"shadow-medium bg-color-primary"}
          />
        </div>
      </div>
    );
};

export default SummaryFooter;
