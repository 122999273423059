import React, { useState } from "react";
import { Customer, STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import { hideComponentLoader, showAlert, showComponentLoader, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { applicationConfig, waitFor } from "../../../../../utils/methods";
import QuotationsList from "../quotation/QuotationsList";
import OrdersList from "../orders/OrdersList";
import ControlsTab from "../../../../common/ui-components/tabs/ControlsTab";
import $ from "jquery"

 

const CurrentCustomer: React.FC<{
  customer:Customer
  endCustomerSession:()=>void
  closeAddCustomer:()=>void
  closeModal:()=>void
}> = (props) => {

 

  const[isSessionActive,setIsSessionActive] = useState(true)
  const[currTab,setCurrTab] = useState("SharedQuotations")

  const[tabItems,setTabItems] = useState([
    {tabName:"SharedQuotations",label:"Quotations",ionIcon:null},
    {tabName:"OrdersList",label:"Orders",ionIcon:null},
  ])

  function deactivateCustomerSession() {
    STORE.isCustomerSessionActive = false
    setIsSessionActive(false)
    props.endCustomerSession()
  }

  function endSessionHandle() {
    deactivateCustomerSession()
    if(applicationConfig.functions.store_MainHeader.refreshCartItems){
      applicationConfig.functions.store_MainHeader.refreshCartItems()
    }
    showGlobalToast("Customer session has been deactivate",3000)
  }

  function Quotations() {
    return(
      <div className="full-width white-card shadow-light border padding10 marginTop10">
        <QuotationsList closeModal={props.closeModal}/>
      </div>
    )
  }

  function Orders() {
    return(
      <div className="full-width white-card shadow-light border padding10 marginTop10">
        <OrdersList/>
      </div>
    )
  }

  function CustomerDetails() {
    return(
      <div className="full-width position-relative white-card shadow-light border padding10 ">
        <div className="display-flex-row no-wrap">
          <div className="center-aligned-column">
            <i className="fas fa-user-circle color-dark" style={{fontSize:"3rem"}}></i>
          </div>

          <div className="marginleft10">
            <div className="heading1 letter-spacing capitalize font-large marginTop5 color-black">
              {props.customer.customerName}
            </div>

            <div className="heading2 font-normal marginTop5">
              +91 {props.customer.mobile}
            </div>
          </div>
        </div>

        <div className="top-right padding10">
          {isSessionActive?
            <Button2
              configObj={{id:""}}
              action={()=>{endSessionHandle()}}
              label={"End Session"}
              fontAwesomeIcon={"fas fa-power-off color-danger"}
              iconPos="right"
              classNames={"no-shadow button-small margin0 button-warning bg-color-"}
            />
          :
            null
          }
          
        </div>

      </div>
    )
    
  }


  function changeTabOption(tabName: string) {
    setCurrTab(tabName);
    updateTabValue(tabName)
  }

  function updateTabValue(val:string){
    $(".tab-content").removeClass("--is-active")
    $("#"+val).addClass("--is-active")
  }

    
  return (
    <div className="full-height-width">
      <CustomerDetails/>

      <div className="full-width white-card shadow-light border padding10 marginTop5">
        <div className="" style={{maxWidth:"35rem"}}>
          <ControlsTab
            configObj={applicationConfig}
            tabItems={tabItems}
            currSelectedValue={currTab}
            action={changeTabOption}
            theme="red"
          />
        </div>

        {currTab === "SharedQuotations"?
          <Quotations/>
        :
          <Orders/>
        }
       
      </div>

      
      

        {/* <div className="center-aligned-row marginTop10">
          {isSessionActive?
          <div className="display-flex-row">
            <div className="center-aligned-column">
              <div className="bg-color-success shadow-light" style={{width:"7px",height:"7px",borderRadius:"50%"}}></div> 
            </div>
            <div className="heading4 font-small marginleft5">Active</div>
          </div>
          :
          <div className="display-flex-row">
            <div className="heading3 font- marginleft5 color-danger">Customer Session Inactive</div>
          </div>
          }
        </div> */}

      
    </div>
  );
};

export default CurrentCustomer;
