import { IonSpinner } from "@ionic/react";
import $ from "jquery";
import React, { useState } from "react";
import { CRM } from "../../../../utils/store/crm/crmConfiguration";
import Input from "./Input";


 
const CityInput: React.FC<{
   
}> = (props) => {

    const[filteredCity,setFilteredCity] = useState([])
  

    function onChangeCity(event:any) {
        const value = event.target.value
        if(value.length >= 2){
            setFilteredCity(CRM.data.citiesList?.filter(city=>city.city_name.toLowerCase().includes(value.toLowerCase())))
            $("#citySearchLoader").removeClass("visibility-hidden")
        }else{
            setFilteredCity([])
        
        }
        setTimeout(() => {
        $("#citySearchLoader").addClass("visibility-hidden")
        }, 2000);
    }


    function setCity(city:any) {
        $("#cityName").val(city.city_name)
        setFilteredCity([])
    }

  return (

    <div className="position-relative">
         <Input
            type="text"
            id="cityName"
            label="Address"
            isMandatory={false}
            onChange={onChangeCity}
            value={""}
            className=""
        />
        <div className="full-width customers-list  bg-color-white">
            {filteredCity.map((city:any,index:number)=>{
                return(
                <div className={`pointer display-flex-row no-wrap padding10 ${index>1?" display-none":" "}`} onClick={()=>{setCity(city)}} key={index}>
                    <div className={`heading3 font-md-small  `}>{city.city_name} , </div>
                    <div className={`heading3 marginleft5 color-dark font-md-small`}>{city.state}</div>
                </div>
                )
            })}
        </div>
        <div id="citySearchLoader" className="right-middle visibility-hidden center-aligned-column padding10" style={{marginTop:"0px"}}>
              <IonSpinner name="crescent" />
        </div>
    </div>
  );
};

export default CityInput;
