import React, { useEffect, useState } from "react";
import { updateWardrobeShelf, updateWardrobeShelves } from "../../../../../utils/customizein3d/modules/wardrobe";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import HorizontalScroll from "../../../../common/ui-components/scrollbar/HorizontalScroll";
import WardrobeModulesTypes from "../../../../common/shared-components/product-modules/WardrobeModuleTypes";
import WardrobeControlsBlocker from "../../../../common/shared-components/product-modules/WardrobeControlsBlocker";
import { applicationConfig } from "../../../../../utils/methods";
 
const InternlStructure: React.FC<{
}> = (props) => {


  const[currModuleType,setCurrModuleTypeShelf] = useState("2Door")
  const[filteredShelf,setFilteredShelf] = useState([])
  const[shelves] = useState([
    {
      name:"Shelf1_2Door",
      label:"Shelf1",
      img:"https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Wardrobe/shelf/Shelf1Door2.webp"
    },
    {
      name:"Shelf2_2Door",
      label:"Shelf2",
      img:"https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Wardrobe/shelf/Shelf2Door2.webp"
    },
    {
      name:"Shelf1_1Door",
      label:"Shelf1",
      img:"https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Wardrobe/shelf/Shelf2Door1.webp"
    },
    {
      name:"Shelf2_1Door",
      label:"Shelf2",
      img:"https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Wardrobe/shelf/Shelf1Door1.webp"
    }
  ])


  

  useEffect(()=>{
    let reuslt = shelves.filter(currShelve => currShelve.name.includes(currModuleType))
    setFilteredShelf(reuslt)
  },[currModuleType])

  useEffect(()=>{
    applicationConfig.setFunctionRef("WardrobeModuleType",{setCurrModuleTypeShelf:setCurrModuleTypeShelf})
  },[])
    
    
  return (
    <>
    <WardrobeControlsBlocker/>
    <div className="display-flex-row padding10">
      {filteredShelf.map((shelf:any,index:number)=>{
        return(
        <div key={index} className="padding5  wardrobe-doors position-relative" onClick={()=>updateWardrobeShelf(currModuleType,shelf.label)} style={{width:"33%",padding:"5px",height:"auto"}}>
          <div id={`${shelf.name}_option`} className="white-card shelf-card-option pointer padding5 position-relative full-height-width shadow-light">
            <img className="full-width" src={shelf.img} alt="" />
            <div className="heading3 font-small">{shelf.label}</div>
          </div>
        </div>
        )
      })}
    </div>
    
    
    
    
    </>
   
  );
};

export default InternlStructure;
