import React, { useEffect, useState } from "react";
import { isTouchDevice } from "../../../../../utils/customizer/helper";
import { displayUploadedImage } from "../../../../../utils/inventory/inventory";
import { logger, stringWithoutQuotes } from "../../../../../utils/methods";
import { addEventListenerOnDragArea, getResizedImage } from "../../../../../utils/rembg/rembg";

const UploadFile: React.FC<{
  setSelectedFile:(val:any)=>void
  setIsSelectResolutions:(val:boolean)=>void
}> = (props) => {

  const[isTouchDevices,setIsTouchDevices] = useState(false)

  useEffect(()=>{

    if(isTouchDevice()){
      setIsTouchDevices(true)
    }

    setTimeout(() => {
      addEventListenerOnDragArea(props.setSelectedFile)
    }, 500);
  },[])


  async function selectFile(event:any){
    let fileName = event.target.files[0].name
    props.setSelectedFile(event.target.files[0])
    await displayUploadedImage(event.target.files[0],"withbgImage").then(async (data)=>{
      await getResizedImage(fileName).then(file=>{
        props.setSelectedFile(file)
        displayUploadedImage(file,"withbgImage")
      }).catch(err=>{
        console.log(err)
        props.setSelectedFile(event.target.files[0])
        displayUploadedImage(event.target.files[0],"withbgImage")
      })
      logger?.info("rembg","uploadimage",`Uploaded image ${fileName}`)
    }).catch(err=>{
      logger.error("rembg","uploadimage",`Error in uploading image`)
    })
  }
 
  return (
    <div className="drop-file-container full-height-width position-relative">

      <div className="full-height-width drop-hint-contaner top-left">
        <div className="center-aligned-column full-height-width">
          <div className="padding10">
            <div className="heading1 text-center letter-spacing color-white" style={{fontSize:"3rem"}}>Drop file here</div>
            <div className="heading2 text-center letter-spacing font-normal marginTop10 color-white">1 file at a time</div>
          </div>
        </div>
      </div>

      <div className="full-height-width center-aligned-column" id="dragFileInput" style={{padding:"3rem"}}>

        {!isTouchDevices?
        <div className="full-width center-aligned-column full-width-file-input-container">
          <div className="">
            <div className="center-aligned-row">
              <i className="fas fa-image color-light ion-padding image-icon" />
            </div>
            <div className="text-center heading1 font-medium marginTop">Drag and drop or 
            <label className="op-btn pointer op-btn-light marginleft5 marginRight5">browse
              <input type="file" id="uploadImage" onChange={selectFile} />
            </label> 
            your file</div>
            <div className="heading2 color-light text-center marginTop5">Powered by Omniviewlabs</div>
          </div>
        </div>
        :
        <div className="full-width center-aligned-column full-width-file-input-container">
          <div className="">
            <div className="center-aligned-row">
              <i className="fas fa-image color-light ion-padding image-icon" />
            </div>

            <div className="text-center heading1 font-medium marginTop"> 
            <label className="op-btn pointer op-btn-light marginleft5 marginRight5">Browse files
              <input type="file" id="uploadImage"  onChange={selectFile} />
            </label> 
            or 
            <label className="op-btn pointer op-btn-light marginleft5 marginRight5">Open Camera
              <input type="file"  accept="image/*" capture="camera" onChange={selectFile} />
            </label> 

            </div>

            {/* <div className="heading2 color-light text-center marginTop5">Powered by Omniviewlabs</div> */}
          </div>
        </div>
      }
      </div>


    </div>
  );
};

export default UploadFile;
