import React, { useEffect, useState } from "react";
import { filterUIElements, scrollLeft, scrollRight } from "../../../../../utils/UI_methods/global";
import { createBounceEffectEvents } from "../../../../../utils/UI_methods/ui_effects";
import { appendModel } from "../../../../../utils/customizein3d/modules/customizein3d";
import { getAddonsImagesUrl } from "../../../../../utils/customizein3d/modules/helper";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray } from "../../../../../utils/methods";
import ControlsTab from "../../../ui-components/tabs/ControlsTab";
 

const Addons: React.FC<{
  configObj: any;
}> = (props) => {

  const[currModuleType,setCurrModuleType] = useState(null)
  const[productModules,setProductModules]:any = useState([])
  const[tabItems,setTabItems] = useState([])
  


  useEffect(()=>{
    if(props.configObj && false){
      let filteredArray = getFilteredArray(props.configObj.data.productModules,"is_addon",1)
      let productIds = getArrayOfDistinctValues(applicationConfig?.data?.productsList,"product_id")
      filteredArray = filteredArray.filter(currModule => productIds.includes(currModule.product_id))
      // filteredArray = getObjectArrayOfDistictValues(filteredArray,"module_name")
      setProductModules(filteredArray)
      setCurrModuleType(filteredArray[0]?.module_type)
    }
  },[props.configObj])

  useEffect(()=>{
    filterUIElements(".addon-container","data-module-type",currModuleType)
  },[currModuleType])

  
  useEffect(()=>{
    if(productModules.length){
      
      let items = getArrayOfDistinctValues(productModules,"module_type")
      let tabList = []
      for (const currItem of items) {
        tabList.push({tabName:currItem,label:currItem})
      }
      setTabItems(tabList)
    }
  },[productModules,props.configObj])


  function scrollL() {
    scrollLeft("addonsTabItems",10)
  }

  function scrollR() {
    scrollRight("addonsTabItems",10)
  }




  return (
    <div className="product-modules-wrapper">
      {productModules.length?
      <ControlsTab
            configObj={props.configObj}
            tabItems={tabItems}
            currSelectedValue={currModuleType}
            action={setCurrModuleType}
            theme="red"
      />
      :
      <div className="center-aligned-column top-left full-height-width">
        <div className="heading4">Addons not available</div>
      </div>
      
      }
    
    <div  className="center-aligned display-flex-row full-height ion-padding sparound overflow-y-scroll" onLoad={(e)=>createBounceEffectEvents(e)} style={{alignContent:"baseline"}}>
          {productModules?.map((module:any,index:number)=>{
          let moduleImageUrl = getAddonsImagesUrl("",props.configObj?.clientName,module.module_name,module.category_name,module.product_name)
          return(
            <div className="addon-container click-effect" data-module-type={module.module_type} key={index} onClick={()=>appendModel(module,true)}>
              <img id={"addon_"+moduleImageUrl} className="padding10" src="https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/addonSofa.png" alt="" style={{opacity:"0.5"}}/>
              <div className="center-aligned-row text-center module-details heading3">{module.product_name}</div>
            </div>
          )
          })}
        </div>
    </div>
  );
};

export default Addons;
