import $ from "jquery";
import { BoxHelper, Group, Object3D } from "three";
import { showToast } from "../../UI_methods/global";
import { getObjectByParameter } from "../../methods";
import { getObjectByObjectId } from "../modules/helper";
import { removeProduct } from "./actions";
import { customizerConfig, scene } from "./area3dModel";


var startX,startY = 0

var initialBoxHelperColor = 0x0058a3

var draggableGroup = new Group()

var selectedProducts = []

export function toggleMultiselectMode() {
    if(customizerConfig.isMultipleSelectionMode){
        disableMultiselectMode()
        showToast("Multiple selection disabled",2000)
        $("#toggleMultiselect").find(".label").text("MultiSelect on")
    }else{
        enableMultiselectMode()
        showToast("Multiple selection enabled",2000)
        $("#toggleMultiselect").find(".label").text("MultiSelect off")
    }
}

export function multipleSelectMouseDown(event:any) {
    if(customizerConfig.isMultipleSelectionMode){
        // $("#multipleSelectionBox").addClass("--is-active")
        // $("#multipleSelectionBox").css("left",`${posStartX}px`)
        // $("#multipleSelectionBox").css("top",`${posStartY}px`)
    }
}

export function multipleSelectMouseMove(event:any) {
    if(customizerConfig.isMultipleSelectionMode){
        // let width  = event.pageX -  posStartX
        // let height  = (event.pageY -  posStartY) - $(".main-header-wrapper").height()
        // $("#multipleSelectionBox").css("width",`${width}px`)
        // $("#multipleSelectionBox").css("height",`${height}px`)

    }
}

export function multipleSelectMouseUp(event:any) {
    if(customizerConfig.isMultipleSelectionMode){
        // $("#multipleSelectionBox").removeClass("--is-active")
    }
}

export function enableMultiselectMode() {
    customizerConfig.setIsMultipleSelectionMode(true)
    $(".hide-on-multiselect").addClass("display-none")
    $(".show-on-multiselect").removeClass("display-none")
    $("#toggleMultiselect").addClass("--is-active")
}

export function disableMultiselectMode() {
    customizerConfig.setIsMultipleSelectionMode(false)

    $(".hide-on-multiselect").removeClass("display-none")
    $(".show-on-multiselect").addClass("display-none")
    $("#toggleMultiselect").removeClass("--is-active")

    selectedProducts?.forEach(product=>{
        scene.remove(product.boxHelper)
    })

}

export function addProductMultipleSelect(object:any){
    if(object){
        let isAdded = getObjectByParameter(selectedProducts,"uuid",object.uuid)
        if(isAdded){
            removeItem(isAdded)
        }else{
            addItem(object) 
        }
    }
}

function addItem(object:Object3D) {
    let boxHelper = new BoxHelper( object, initialBoxHelperColor)
    scene.add(boxHelper)
    let details = {
        boxHelper:boxHelper,
        object:object,
        uuid:object.uuid
    }
    selectedProducts.push(details)  
}

function removeItem(productDetails:any) {
    scene.remove(productDetails.boxHelper)  
    selectedProducts = selectedProducts.filter(currProduct=>currProduct.uuid!==productDetails.uuid)
}

export function deleteSelectedProducts() {
    selectedProducts?.forEach(product=>{
        scene.remove(product.boxHelper)
        let object = getObjectByObjectId(scene,product.uuid)
        removeProduct(object,true,true)
    })
}
