
export const DefaultRoomCameraMappping = {
    Room2:{
        "aspect": 1.3333333333333333,
        "fov": 45,
        "transform": {
            "position": {
                "x": -2.5159237851369265,
                "y": 0.72,
                "z": 4.955408455704952
            },
            "rotation": [
                -0.024934647162538102,
                -0.31445779876529295,
                -0.008263071613797785,
                0.948907939588489
            ],
            "scale": {
                "x": 1,
                "y": 1,
                "z": 1
            },
            "rotationDelta": 0
        },
        "areaName": "Other",
        "renderType": "2d",
        "cameraId": 1729163547661,
        "near": 0.025,
        "cameraName": "Other1729163547661",
        "targetPosition": {
            "x": 0,
            "y": 0,
            "z": 0
        },
        "polarAngle": 1.5182540106372977,
        "azimuthAngle": -0.6399995461979908,
        "isRendered": false,
        "renderTime": 0,
        "cropPoints": {
            "minX": 261,
            "maxX": 761,
            "minY": 100,
            "maxY": 474
        }
    },

    Room1:{
        "aspect": 1.3333333333333333,
        "fov": 45,
        "transform": {
            "position": {
                "x": 4.027074904936602,
                "y": 0.82,
                "z": 5.6446915418261865
            },
            "rotation": [
                -0.02372384651655078,
                0.26821070046834056,
                0.006607153869406235,
                0.9630454531217555
            ],
            "scale": {
                "x": 1,
                "y": 1,
                "z": 1
            },
            "rotationDelta": 0
        },
        "areaName": "Other",
        "renderType": "2d",
        "cameraId": 1729164165233,
        "near": 0.025,
        "cameraName": "Other1729164165233",
        "targetPosition": {
            "x": 0,
            "y": 0,
            "z": 0
        },
        "polarAngle": 1.5215379053971478,
        "azimuthAngle": 0.5432393236647342,
        "isRendered": false,
        "renderTime": 0,
        "cropPoints": {
            "minX": 261,
            "maxX": 761,
            "minY": 100,
            "maxY": 474
        }
    },
    Room3:{
        "aspect": 1.3333333333333333,
        "fov": 75,
        "transform": {
            "position": {
                "x": -0.8951199275325545,
                "y": 0.83,
                "z": 3.90117255462028
            },
            "rotation": [
                -0.03366198937931177,
                -0.23591858522425863,
                -0.008177363320296597,
                0.9711551999273541
            ],
            "scale": {
                "x": 1,
                "y": 1,
                "z": 1
            },
            "rotationDelta": 0
        },
        "areaName": "Other",
        "renderType": "2d",
        "cameraId": 1729837442618,
        "near": 0.025,
        "cameraName": "Other1729837393836",
        "targetPosition": {
            "x": 0,
            "y": 0,
            "z": 0
        },
        "polarAngle": 1.5015004652672164,
        "azimuthAngle": 5.806566259743009,
        "isRendered": false,
        "renderTime": 0,
        "cropPoints": {
            "minX": 259,
            "maxX": 893,
            "minY": 100,
            "maxY": 575
        }
    },
    Room6:{
        "aspect": 1.3333333333333333,
        "fov": 49,
        "transform": {
            "position": {
                "x": -1.92343646834374,
                "y": 0.641147819788914,
                "z": 4.2161264522147155
            },
            "rotation": [
                -0.03171378905538975,
                -0.15578117040484984,
                -0.005004115163571501,
                0.9872696801596184
            ],
            "scale": {
                "x": 1,
                "y": 1,
                "z": 1
            },
            "rotationDelta": 0
        },
        "areaName": "Other",
        "renderType": "2d",
        "cameraId": 1731495079667,
        "near": 0.025,
        "cameraName": "Other1731495032918",
        "targetPosition": {
            "x": 0,
            "y": 0,
            "z": 0
        },
        "polarAngle": 1.5065729676073714,
        "azimuthAngle": -0.6749896078240267,
        "isRendered": false,
        "renderTime": 0,
        "cropPoints": {
            "minX": 252,
            "maxX": 770,
            "minY": 125,
            "maxY": 512
        }
    }
}




export const ProductRenderViews = {
    Room1:[
        {
            "aspect": 1.3333333333333333,
            "fov": 45,
            "transform": {
                "position": {
                    "x": 3.414162608379896,
                    "y": 0.9,
                    "z": 5.952693321662228
                },
                "rotation": [
                    -0.02439073719582363,
                    0.13806320799793223,
                    0.003401075562840865,
                    0.9901172027701466
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729754647352,
            "near": 0.025,
            "cameraName": "Other1729754595423",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5215379053971478,
            "azimuthAngle": 0.2770958356293193,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 263,
                "maxX": 764,
                "minY": 104,
                "maxY": 478
            }
        },
        {
            "aspect": 1.3333333333333333,
            "fov": 45,
            "transform": {
                "position": {
                    "x": 5.109048973605913,
                    "y": 0.9,
                    "z": 5.408692486820762
                },
                "rotation": [
                    0,
                    -0.2220977253324918,
                    0,
                    0.9750244101570653
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729754688604,
            "near": 0.025,
            "cameraName": "Other1729754595423",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5707963267948966,
            "azimuthAngle": 0.5659410628385171,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 263,
                "maxX": 764,
                "minY": 104,
                "maxY": 478
            }
        },
        {
            "aspect": 1.3333333333333333,
            "fov": 45,
            "transform": {
                "position": {
                    "x": 0.7598980327035791,
                    "y": 0.9,
                    "z": 6.04869191101242
                },
                "rotation": [
                    0,
                    0.2792092704448441,
                    0,
                    0.9602302761825717
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729754722101,
            "near": 0.025,
            "cameraName": "Other1729754595423",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5707963267948966,
            "azimuthAngle": -0.21294941645216522,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 263,
                "maxX": 764,
                "minY": 104,
                "maxY": 478
            }
        }
    ],

    Room2:[
        {
            "aspect": 1.3333333333333333,
            "fov": 45,
            "transform": {
                "position": {
                    "x": 0.3873654885687759,
                    "y": 0.83,
                    "z": 7.231410165755295
                },
                "rotation": [
                    -0.02493464716243707,
                    -0.3144577987674091,
                    -0.008263071613826,
                    0.9489079395877902
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729837246473,
            "near": 0.025,
            "cameraName": "Other1729837220047",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5182540106372977,
            "azimuthAngle": -0.046377534039942665,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 358,
                "maxX": 862,
                "minY": 135,
                "maxY": 510
            }
        },
        {
            "aspect": 1.3333333333333333,
            "fov": 45,
            "transform": {
                "position": {
                    "x": -3.9006342315673828,
                    "y": 0.83,
                    "z": 4.063410938555078
                },
                "rotation": [
                    0,
                    0,
                    0,
                    1
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729837274067,
            "near": 0.025,
            "cameraName": "Other1729837220047",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5707963267948966,
            "azimuthAngle": -0.9260509284095649,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 358,
                "maxX": 862,
                "minY": 135,
                "maxY": 510
            }
        },
        {
            "aspect": 1.3333333333333333,
            "fov": 45,
            "transform": {
                "position": {
                    "x": 3.075369500447783,
                    "y": 0.83,
                    "z": 7.007410469798413
                },
                "rotation": [
                    0,
                    0.18683914045813185,
                    0,
                    0.9823905209196934
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729837298446,
            "near": 0.025,
            "cameraName": "Other1729837220047",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5707963267948966,
            "azimuthAngle": 0.37588727509989867,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 358,
                "maxX": 862,
                "minY": 135,
                "maxY": 510
            }
        }
    ],
    Room3:[
        {
            "aspect": 1.3333333333333333,
            "fov": 75,
            "transform": {
                "position": {
                    "x": -0.8951199275325545,
                    "y": 0.83,
                    "z": 3.90117255462028
                },
                "rotation": [
                    -0.03366198937931177,
                    -0.23591858522425863,
                    -0.008177363320296597,
                    0.9711551999273541
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729837442618,
            "near": 0.025,
            "cameraName": "Other1729837393836",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5015004652672164,
            "azimuthAngle": 5.806566259743009,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 259,
                "maxX": 893,
                "minY": 100,
                "maxY": 575
            }
        },
        {
            "aspect": 1.3333333333333333,
            "fov": 75,
            "transform": {
                "position": {
                    "x": 3.0088797172671606,
                    "y": 0.8300207721248068,
                    "z": 4.1571722878744515
                },
                "rotation": [
                    -0.033661989379100095,
                    -0.2359185852290617,
                    -0.008177363320421427,
                    0.9711551999261937
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729837455715,
            "near": 0.025,
            "cameraName": "Other1729837393836",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5015004652672164,
            "azimuthAngle": 0.46989409394055903,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 259,
                "maxX": 893,
                "minY": 100,
                "maxY": 575
            }
        },
        {
            "aspect": 1.3333333333333333,
            "fov": 75,
            "transform": {
                "position": {
                    "x": 0.7048891772955976,
                    "y": 0.8300283885705682,
                    "z": 4.3811725614729395
                },
                "rotation": [
                    -0.03368929522246076,
                    0.232651770926011,
                    0.008064129648581273,
                    0.9719429791327346
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729837463774,
            "near": 0.025,
            "cameraName": "Other1729837393836",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5015004652672164,
            "azimuthAngle": -0.01715140120188363,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 259,
                "maxX": 893,
                "minY": 100,
                "maxY": 575
            }
        }
    ],
    Room4:[],
    Room5:[
        {
            "aspect": 1.3333333333333333,
            "fov": 45,
            "transform": {
                "position": {
                    "x": -1.7711030301065072,
                    "y": 0.59,
                    "z": 4.49863676874317
                },
                "rotation": [
                    -0.006358114849019816,
                    -0.3179012593286027,
                    -0.0021318979884121492,
                    0.9481001100642391
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729837603370,
            "near": 0.025,
            "cameraName": "Other1729837570144",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5573841998074378,
            "azimuthAngle": -0.6470452610994859,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 259,
                "maxX": 893,
                "minY": 100,
                "maxY": 575
            }
        },
        {
            "aspect": 1.3333333333333333,
            "fov": 45,
            "transform": {
                "position": {
                    "x": 3.924898785993197,
                    "y": 0.5900045599864613,
                    "z": 4.914636178264265
                },
                "rotation": [
                    -0.006358114849019816,
                    -0.3179012593286027,
                    -0.0021318979884121492,
                    0.9481001100642391
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729837612106,
            "near": 0.025,
            "cameraName": "Other1729837570144",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5573841998074378,
            "azimuthAngle": 0.6760763393171714,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 259,
                "maxX": 893,
                "minY": 100,
                "maxY": 575
            }
        },
        {
            "aspect": 1.3333333333333333,
            "fov": 45,
            "transform": {
                "position": {
                    "x": 0.9169111926465972,
                    "y": 0.5900076446831851,
                    "z": 5.202636486775645
                },
                "rotation": [
                    -0.006326500468561269,
                    0.33162946981720737,
                    0.002223961619653636,
                    0.9433858829425494
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1729837626079,
            "near": 0.025,
            "cameraName": "Other1729837570144",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5573841998074378,
            "azimuthAngle": -0.026537280649651267,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 154,
                "maxX": 998,
                "minY": 100,
                "maxY": 575
            }
        }
    ],
    Room6:[
        {
            "aspect": 1.3333333333333333,
            "fov": 49,
            "transform": {
                "position": {
                    "x": -1.92343646834374,
                    "y": 0.641147819788914,
                    "z": 4.2161264522147155
                },
                "rotation": [
                    -0.03171378905538975,
                    -0.15578117040484984,
                    -0.005004115163571501,
                    0.9872696801596184
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1731495079667,
            "near": 0.025,
            "cameraName": "Other1731495032918",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5065729676073714,
            "azimuthAngle": -0.6749896078240267,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 252,
                "maxX": 770,
                "minY": 125,
                "maxY": 512
            }
        },
        {
            "aspect": 1.3333333333333333,
            "fov": 49,
            "transform": {
                "position": {
                    "x": 3.7082784301075558,
                    "y": 0.6411670735544892,
                    "z": 3.976124749924641
                },
                "rotation": [
                    -0.03029496133082469,
                    -0.3309535564477084,
                    -0.010631128947663705,
                    0.9431007040024282
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1731495092316,
            "near": 0.025,
            "cameraName": "Other1731495032918",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5065729676073714,
            "azimuthAngle": 0.6822331266971938,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 252,
                "maxX": 770,
                "minY": 125,
                "maxY": 512
            }
        },
        {
            "aspect": 1.3333333333333333,
            "fov": 49,
            "transform": {
                "position": {
                    "x": 0.792578770437619,
                    "y": 0.9206065090238567,
                    "z": 4.256124703149994
                },
                "rotation": [
                    -0.03025625933178085,
                    0.33436706228044966,
                    0.010740780045070434,
                    0.9418958870688487
                ],
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "rotationDelta": 0
            },
            "areaName": "Other",
            "renderType": "2d",
            "cameraId": 1731495101947,
            "near": 0.025,
            "cameraName": "Other1731495032918",
            "targetPosition": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "polarAngle": 1.5065729676073714,
            "azimuthAngle": -0.000009118628374627007,
            "isRendered": false,
            "renderTime": 0,
            "cropPoints": {
                "minX": 252,
                "maxX": 770,
                "minY": 125,
                "maxY": 512
            }
        }
    ]
      
}
