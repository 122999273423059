let DemoProject1 = [
  {
    areaName: "LivingRoom",
    infoSpots: [
      {
        linkArea: "Foyer",
        position: {
          pitch: -0.08293413890301693,
          yaw: 1.7589565381525603
        },
      },
      {
        linkArea: "Kitchen",
        position: {
          pitch: -0.05670605599811118,
          yaw: 0.7398920027907678
        },
      },
      {
        linkArea: "DiningRoom",
        position: {
          pitch: -0.09084366398366939,
          yaw: 0.6252627471860865
        },
      },
      {
        linkArea: "BedRoom1",
        position: {
          pitch: 0.012664089135732581,
          yaw: 2.276309827049664
        },
      },
    ],
  },

  {
    areaName: "Foyer",
    infoSpots: [
      {
        linkArea: "LivingRoom",
        position: {
          x: 2906.33,
          y: 191.7327100275865,
          z: 4057.28635455676,
        },
      },
    ],
  },

  {
    areaName: "Kitchen",
    infoSpots: [
      {
        position: {
          x: -3537.79,
          y: 529.1793515465342,
          z: 3478.9394027364874,
        },
        linkArea: "LivingRoom",
      },
    ],
  },
];

let NewDemo = [
  {
    areaName: "DiningRoom",
    infoSpots: [
      {
        position: {
          pitch: -0.08779634648664292,
          yaw: 0.09285225846652523
        },
        linkArea: "Kitchen",
      },
      {
        position: {
          pitch: -0.053587778326981805,
          yaw: 1.6671423834418855
        },
        linkArea: "Foyer",
      },
      {
        position: {
          pitch: -0.08018760900546162,
          yaw: -2.8137475412210406
        },
        linkArea: "LivingRoom",
      },
      {
        position: {
          pitch: 0.04657233403314365,
          yaw: -0.7156510745252973
        },
        linkArea: "BedRoom3",
      },
      {
        position: {
          pitch: -0.11450564407463304,
          yaw: 2.6235808585136304
        },
        linkArea: "BedRoom1",
      },
      {
        position: {
          pitch: -0.06182574407858077,
          yaw: -0.9275960393843974
        },
        linkArea: "BedRoom2",
      },
    ],
  },
  {
    areaName: "LivingRoom",
    infoSpots: [
      {
        linkArea: "Foyer",
        position: {
          pitch: -0.08293413890301693,
          yaw: 1.7589565381525603
        },
      },
      {
        linkArea: "Kitchen",
        position: {
          pitch: -0.05670605599811118,
          yaw: 0.7398920027907678
        },
      },
      {
        linkArea: "DiningRoom",
        position: {
          pitch: -0.09084366398366939,
          yaw: 0.6252627471860865
        },
      },
      {
        linkArea: "BedRoom1",
        position: {
          pitch: 0.012664089135732581,
          yaw: 2.276309827049664
        },
      },
    ],
  },

  {
    areaName: "Foyer",
    infoSpots: [
      {
        linkArea: "DiningRoom",
        position: {
          pitch: 0.0027472354103252883,
          yaw: -0.7570211634310269
        },
      },
    ],
  },

  {
    areaName: "BedRoom2",
    infoSpots: [
      {
        linkArea: "DiningRoom",
        position: {
          pitch: -0.050815315962811525,
          yaw: 1.3805493149094143
        },
      },
    ],
  },

  {
    areaName: "Kitchen",
    infoSpots: [
      {
        position: {
          pitch: -0.010644025093903053,
          yaw: -3.0957873188148852
        },
        linkArea: "DiningRoom",
      },
    ],
  },

  {
    areaName: "BedRoom1",
    infoSpots: [
      {
        position: {
          pitch: -0.07920839183657868,
          yaw: 0.3008919105834611
        },
        linkArea: "DiningRoom",
      },
    ],
  },

  {
    areaName: "BedRoom2",
    infoSpots: [
      {
        position: {
          x: 203.97,
          y: 265.0773882393752,
          z: -4978.895434058622,
        },
        linkArea: "Kitchen",
      },
    ],
  },

  {
    areaName: "BedRoom3",
    infoSpots: [
      {
        position: {
          pitch: -0.15229484441468344,
          yaw: -0.23186254118921568
        },
        linkArea: "DiningRoom",
      },
    ],
  },
];

export default {
  DemoProject1: DemoProject1,
  NewDemo: NewDemo,
};
