import React, { useEffect, useState } from "react";
import Quotation from "../Quotation";
import { IonModal, IonPage } from "@ionic/react";
import { hideComponentLoader, showComponentLoader } from "../../../../../../utils/UI_methods/global";
import { useParams } from "react-router";
import { STORE } from "../../../../../../utils/store/storeConfiguration";
import { applicationConfig, waitFor } from "../../../../../../utils/methods";
import { API_INIT, apiGatwayAuthentication, getProductsList, setClientId, setShareProductId, tokenVerification } from "../../../../../../services/api";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import Success from "./Success";

 
const Approval: React.FC<{
}> = (props) => {


    const parameters:any = useParams();
    const[cartItems,setCartItemDetails] = useState([])
    const[isOpenReview,setisOpenReview]:any =  useState(false)
    const[isApproved,setIsApproved]:any =  useState(false)
    useEffect(()=>{
        setProduct()
    },[])

    async function setProduct() {
        showComponentLoader("mainLoaderSpinner")
        let token = parameters.token
        let items = []

        // tokenVerification(token).then(async (data)=>{
            await apiGatwayAuthentication()

            // let clientId = data?.clientId
            // applicationConfig.clientId = clientId
            // applicationConfig.clientName = data?.clientName

            STORE.storeApi.quotation.getProduct(token).then((data:any)=>{

                if(!data.data.error){
                    let itemsList = data.data.data
                    let configuration = itemsList[0]?.configuration || itemsList[0]?.configuration_3d
                    if(configuration){
                        configuration = JSON.parse(configuration)
                        applicationConfig.clientName = configuration?.clientName || "DemoClient2"
                        setClientId(configuration?.clientId)
                        applicationConfig.isLiveClient = applicationConfig.liveClients?.includes(applicationConfig.clientName)
                    }
                    setIsApproved(itemsList[0].is_approved)
                    setShareProductId(itemsList[0].product_id)
                    getProductsList().then(data=>{
                        applicationConfig.data.productsList = data
                        for (const item of itemsList) {
                            items.push({
                                product_id:item.product_id,
                                configuration:item.configuration,
                                configuration_3d:item.configuration_3d,
                                price:item.price,
                                quantity:1
                            }) 
                        }
                        setCartItemDetails(items)
                        setisOpenReview(true)
                    }).catch(err=>{
                        console.log(err)
                    })
                }else{
                console.log(data);
                }
                hideComponentLoader("mainLoaderSpinner")
            }).catch(function (error) {
                console.log(error);
            });
    }
    async function approveHandle() {
        showComponentLoader("mainLoaderSpinner")
        await waitFor(1000)
        let token = parameters.token
        STORE.storeApi.quotation.approve(token).then((data:any)=>{
            if(!data.data.error){
                setIsApproved(true)
            }else{
            }
            hideComponentLoader("mainLoaderSpinner")
        })
    }

    function ShareDetails() {
        return(
            <div className="full-height-width">
                <div className="header-content center-aligned-column heading1 color-primary uppercase">
                    Share Details
                </div>
                <div className="body-content">
                    {isOpenReview && cartItems.length?
                        <Quotation isShareMode={true} cartItems={cartItems} confirmHandle={null} />
                    :null}
                </div>
                <div className="footer-content center-aligned-column">
                    <Button2
                        configObj={{id:""}}
                        action={approveHandle}
                        label={"Approve"}
                        fontAwesomeIcon={"fas fa-share color-white"}
                        iconPos=""
                        classNames={"margin0 store-bg-color-primary button-medium"}
                    />
                </div>
            </div>
        )
    }
 
    return (
        <IonPage>
        <div className="full-height-width bg-color-light crm-wrapper padding10">

        <IonModal
            isOpen={isOpenReview}
            cssClass={isApproved?"payment-modal":"medium-y-modal"}
        >
            <>
            {isApproved?
                <Success/>
            :
                <ShareDetails/>
            }
            
            </>
        </IonModal>
                
        </div>
        </IonPage>
    );
};

export default Approval;
 
