import React from "react";
import MaterialImage from "../../common/ui-components/elements/MaterialImage";
import "../../style/admin/product.css";



const FinishDetails: React.FC<{
  configObj:any
  currfinish: any;
}> = (props) => {
 
 
  return (
    <div className="full-height-width">
        <div className="full-height-width display-flex-row no-wrap padding5">
            <div className="width50 padding5 center-aligned-column">
              <MaterialImage  material={props.currfinish} classNames="full-height-width" />
            </div>
            <div className="width50 ion-padding">
              <div className="display-flex-row no-wrap">
                <span className="heading3 color-black">Material Type</span>
                <span className="heading3 color-black marginleft10">:</span>
                <span className="heading3 color-danger marginleft10">{props.currfinish?.material_type}</span>
              </div>

              <div className="display-flex-row no-wrap marginTop10">
                <span className="heading3 color-black">Company Name</span>
                <span className="heading3 color-black marginleft10">:</span>
                <span className="heading3 color-danger marginleft10">{props.currfinish?.company_name}</span>
              </div>

              <div className="display-flex-row no-wrap marginTop10">
                <span className="heading3 color-black">Collection Name</span>
                <span className="heading3 color-black marginleft10">:</span>
                <span className="heading3 color-danger marginleft10">{props.currfinish?.collection_name}</span>
              </div>


            </div>
         
        </div>
    </div>
  );
};

export default FinishDetails;
