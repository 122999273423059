import $ from "jquery";
import React, { useEffect, useState } from "react";
import { addProduct } from "../../../../../services/api";
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getObjectByParameter, getRandomString, waitFor } from "../../../../../utils/methods";
import { createProductForm } from "../../../../../utils/products/createProductForm";
import { filterCategoryForStore, updateProductsList, uploadProductImage } from "../../../../../utils/products/createproduct";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import AddToCart from "../cart/AddToCart";
import ProductInfo from "../product-info/ProductInfo";
import SelectCategoryForm from "./SelectCategoryForm";
import SelectImageForm from "./SelectImageForm";
import { addToCartHandle } from "../../../../../utils/store/product-cart";
 

const AddProductForm: React.FC<{
    onSuccess:()=>void
    productDetails:createProductForm
    currProduct:any
    setCurrProduct:(val:any)=>void
}> = (props) => {


    const[categories] = useState(filterCategoryForStore(applicationConfig?.data?.categories))

    useEffect(()=>{
        let productName = getRandomString(10)
        props.productDetails.productName = productName
        props.productDetails.sku = productName
        props.productDetails.subCategoryId = getObjectByParameter(applicationConfig?.data?.subCategories,"sub_category_name","All").id
        props.productDetails.productType = "custom"
        props.productDetails.subCategory = "All"
    },[])

    
  

    async function createProductHandle() {
        if(!props.productDetails.productName || !props.productDetails.categoryId || !props.productDetails.subCategoryId){
          alert("Please check required fields")
          return
        }
        await addProduct(props.productDetails).then(async (res)=>{
            if(res.data.error){
            //   props.closeModal(false)
              showGlobalToast(`Internal server error`,2000,"error")
              hideComponentLoader("mainLoaderSpinner")
              return
            }
            showComponentLoader("mainLoaderSpinner")
            // props.closeModal(false)
            console.log(res)
            await updateProductsList()
    
            if(!props.productDetails.productImageFile && props.productDetails.galleryImages.length){
                props.productDetails.productImageFile = props.productDetails.galleryImages[0]
            }
    
            if(props.productDetails?.productImageFile){
              await uploadProductImage(props.productDetails)
            }

            let product = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",res.data.data.id)
            props.setCurrProduct(product)
            STORE.currProduct = product
            // props.closeModal(false)
            showGlobalToast(`Product added successfully`)
            // gotoSelectConfig()
            hideComponentLoader("mainLoaderSpinner")
            }).catch(err=>{
              hideComponentLoader("mainLoaderSpinner")
              console.log(err)
            })
    }


    async function nextHandle() {
        if(props.productDetails.galleryImages.length || true){
            $("#selectProductImageForm").fadeOut(500)
            await waitFor(500)
            $("#selectCategoryForm").fadeIn(500)
            
        }else{
            showGlobalToast("Please select image",2000,"error")
        }

        if(props.productDetails.galleryImages.length && props.productDetails.categoryId){
            createProductHandle()
        }
    }

    function reviewHandle() {
        $(".create-custom-order-wrapper").fadeOut(0)
        $("#customOrderReview").fadeIn(0)
    }


    function addToCart() {
        STORE.productConfiguration.isCustomOrder = true
        addToCartHandle(props.currProduct,props.onSuccess)
    }

    return (
        <div id="addCustomOrderWrapper" className="full-height-width bg-color-light create-custom-order-wrapper" style={{display:""}}>

            <div className="header-content font-large center-aligned-column heading1 store-color-primary">
                
                {props.currProduct?
                    "Select Product Configuration"
                    :
                    "Add Product"
                }

            </div>

            <div className="body-content">
            
            {props.currProduct?
                <div className="full-width padding-container">
                    <ProductInfo product={props.currProduct} isCustomOrderMode={true}/>
                </div>
            :
                <div className="full-height-width center-aligned-column">
                    <SelectCategoryForm productDetails={props.productDetails} />
                    <SelectImageForm productDetails={props.productDetails} />
                </div>
            }


            </div>
            <div className="footer-content">
                {props.currProduct?
                    <div className="center-aligned-column full-height">
                        {/* <AddToCart currProduct={props.currProduct} onAdd={props.onSuccess}/> */}
                        <Button2
                            configObj={{id:""}}
                            action={()=>{addToCart()}}
                            label={`Add to cart`}
                            fontAwesomeIcon={"fas fa-cart-plus color-white"}
                            iconPos=""
                            classNames={"no-shadow button-medium margin0 store-bg-color-primary"}
                        />
                    </div>
                :
                    <div className="center-aligned-row">
                        <Button2
                            configObj={{id:""}}
                            action={nextHandle}
                            label={"Next"}
                            fontAwesomeIcon={"fas fa-arrow-right"}
                            iconPos="right"
                            classNames={"no-shadow button-large margin0 store-bg-color-primary"}
                        />
                    </div>
                }
                
            </div>

        </div>
    );
};

export default AddProductForm;
