import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { tokenVerification } from "../../services/api";
import { getUser, removeUserSession, setUserSession, showTokenExpiryScreen, updateUserSession } from "../../utils/methods";
 

const Share: React.FC<{
  configObj: any;
  setLoginTrue:()=>void
  setShareToken:(val:string)=>void
  setIsShareMode:(val:boolean)=>void
}> = (props) => {

    const history = useHistory();
    const parameters:any = useParams();


    useEffect(()=>{
        let queryString = window.location.search
        // let urlParams = new URLSearchParams(queryString);
        let token = parameters.token
        console.log(token)

        if(token){
          tokenVerification(token).then(data=>{
            // props.setLoginTrue()
            //If the user is not admin set user details in the local storage
            removeUserSession()
            props.setShareToken(token)
            data.isShareMode = false

            let userType = getUser()?.userType
            if(userType?.toLowerCase() != "admin"){
              data.isShareMode = false
              setUserSession(token, data);
            }else{
              updateUserSession(true,data.moduleName)
              props.setIsShareMode(true)
            }
            // window.location.href = "/"+data.moduleName 
            // props.configObj.token = token
            history.push("/"+data.moduleName)
          }).catch(err=>{
              removeUserSession()
              // history.push("/login")
              showTokenExpiryScreen()
              console.log(err)
          })
        } 
    },[])


 
  return (
    <React.Fragment>
      <div className="full-height-width center-aligned-row padding10">
        <div className="display-flex-row color-danger padding10 font-medium">
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
          Link Expired
        </div>
      </div>
    </React.Fragment>
  );
};

export default Share;
