import { IonModal } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../../../../utils/cache/localstorage";
import { getFilteredArray, getRandomString, getUser } from "../../../../utils/methods";
import Materials from "../../../common/shared-components/Materials/Materials";
import Modal from "../../../common/shared-components/Modals/Modal";
import Button2 from "../../../common/ui-components/buttons/Button2";
import ShareLink from "../../../common/ui-components/links/sharelink/ShareLink";
import CollectionGallery from "../../products/productActions/CollectionGallery";



const Collections: React.FC<{
  collectionsList:any
  currCategory:string
  defaultProduct:any
  materialsList:Array<any>
}> = (props) => {

 

  const [recentConfigure,setRecentConfigure] = useState(getItemFromLocalStorage("recentCustomizeCollection"));
  const [currCompany, setCurrCompany] = useState("");
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [currCollection,setCurrCollection] = useState(null);
  const [productLoaderClass] = useState<string>("product-loader");

  const [loadLimit, setLoadLimit] = useState(20);

  const[bodyHeight,setBodyHeight] = useState(0)
  const[isOpenMaterials,setIsOpenMaterials] = useState(false)
  const[materials,setMaterials] = useState([])

  const bodyRef = useRef(null)

  useEffect(()=>{
    setTimeout(() => {
      if(bodyRef){
        setBodyHeight($("#collectionGalleryParent").height())
      }
    }, 500);
  },[isOpenOptions])

  let history = useHistory()

  useEffect(() => {
    setLoadLimit(20)
    // setProductsList(props.productsList)
  }, [props.collectionsList]);


  function clickHandle(collection:any) {
    setCurrCollection(collection.collection_name)
    setIsOpenOptions(true)
    // addItemToLocalStorage("sharedItem",null)
    // applicationConfig.sharedItem = null

    setCurrCompany(collection.company_name)
    addItemToLocalStorage("currCollection",{
        companyName:collection.company_name,
        collectionName:collection.collection_name,
        categoryName:props.currCategory
    })
  }
  
 

  function customizein3dAction() {
    setIsOpenOptions(false)
    if(getUser()?.isShareMode){
      window.location.href = "/customizein3d"
    }else{
      history.push("/customizein3d")
    }
  }

  function nextPage() {
    $("#imageSmallButton").click()
    setLoadLimit((prevValue) => {
      if (prevValue >= props.collectionsList?.length) {
        return prevValue;
      }
      return prevValue + 20;
    });
  }

  function viewAll() {
    setLoadLimit(props.collectionsList.length)
  }

  function Footer(){
    return (
      <div className="full-width center-aligned-column white-card shadow-light border-top" style={{height:"13rem"}}>
        
      </div>
    )
  }

  function CollectionAction(){
    return(
        <div className="full-height-width">
            <div className="position-relative white-card shadow-light" style={{height:"4rem"}}>
                <div className="left-middle heading2">
                    {currCollection}
                </div>

               
                {/* <div className="middle heading3">
                    <ShareLink
                        shareItemId={props.defaultProduct.product_id}
                        loaderId = {"loaderId"}
                        moduleName="products"
                        textClass="color-black"
                        isShareButton={true}
                        kanvasModeConfig={{
                            collectionName:currCollection,
                            companyName:props.currCompany,
                            categoryName:props.currCategory
                        }}
                        Content={()=>{
                        return(
                            <Button2
                            configObj={{}}
                            action={() =>{}}
                            label={"Share"}
                            fontAwesomeIcon={"fas fa-share color-primary"}
                            iconPos="right"
                            classNames={"button-md-small bg-color-primary-outline"}
                        />
                        )
                        }
                        }
                    />
                </div> */}

                <div className="right-middle heading3">
                    <Button2
                        configObj={{}}
                        action={()=>{setIsOpenOptions(false)}}
                        label={""}
                        fontAwesomeIcon={"fas fa-times color-danger"}
                        iconPos="left"
                        classNames={""}
                    />
                </div>

            </div>
            <div  ref={bodyRef} id="collectionGalleryParent" className="padding5" style={{height:"calc(100% - 16rem)"}}>
              {bodyHeight?
                <CollectionGallery gotoCustomizeIn3D={customizein3dAction} imageHeight={bodyHeight} collectionName={currCollection} companyName={currCompany} />
              :null}
            </div>
            <div className="center-aligned-column border-top full-width" style={{height:"12rem"}}>
                <div className="display-flex-row no-wrap">
                    <div className='display-flex-row no-wrap'>

                        <ShareLink
                          shareItemId={props.defaultProduct.product_id}
                          loaderId = {"loaderId"}
                          moduleName="products"
                          textClass="color-black"
                          isShareButton={true}
                          kanvasModeConfig={{
                              collectionName:currCollection,
                              companyName:currCompany,
                              categoryName:props.currCategory,
                              isShareMultipleCollections:false
                          }}
                          Content={()=>{
                          return(
                            <div style={{width:"11rem",height:"9rem"}} className="white-card pointer   center-aligned-column border shadow-light">
                              <div className="padding5 ">
                              <div className="center-aligned-row">
                                <i className="fas fa-share font-ex-large color-light"></i>
                              </div>
                              <div className="heading2 marginTop10 text-center font-normal store-color-primary">{"Share"}</div>
                              </div>
                          </div>
                          )
                          }
                          }
                        />

                        <div onClick={()=>{openCollectionsHandle(currCollection)}} style={{width:"11rem",height:"9rem"}} className="white-card pointer marginleft10 center-aligned-column border shadow-light">
                            <div className="padding5 ">
                            <div className="center-aligned-row">
                              <i className="fas fa-image font-ex-large color-light"></i>
                            </div>
                            <div className="heading2 marginTop10 text-center font-normal store-color-primary">{"View Materials"}</div>
                            </div>
                        </div>

                        <div onClick={customizein3dAction} style={{width:"11rem",height:"9rem"}} className="white-card marginleft10 pointer  center-aligned-column border shadow-light">
                            <div className="padding5 ">
                            <div className="center-aligned-row">
                              <i className="fas fa-arrow-right font-ex-large color-light"></i>
                            </div>
                            <div className="heading2 marginTop10 text-center font-normal store-color-primary">{"Start Visualizing"}</div>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>
    )
  }


  function openCollectionsHandle(collection:string){
    setMaterials(getFilteredArray(props.materialsList,"collection_name",collection))

    setIsOpenMaterials(true)
  }

  function getMaterialsCount(collection:string){
    return getFilteredArray(props.materialsList,"collection_name",collection).length || 0
  }
    

  return (
    <>

    <div className="display-flex-row product-images-container full-height overflow-y-scroll" id="subCategoryProductsList" style={{paddingBottom:"4rem"}}>

      <IonModal
          id=""
          cssClass="medium-y-modal"
          onDidDismiss={()=>setIsOpenOptions(false)}
          isOpen={isOpenOptions}
          >
              <>
          
          {currCollection?
          <CollectionAction/>
          :null}
        </>
      </IonModal>

      <Modal
        isOpen={isOpenMaterials}
        onDismiss={()=>{setIsOpenMaterials(false)}}
        Content={()=>(
          <div className="full-height-width view-materials-kanvas">
          <Materials
            materials={materials}
            action={()=>{}}
            moduleName="customizein3d"
            isViewMode={true}
          />
          </div>
          
        )}
        FooterContent={()=>(
          <></>
        )}
        heading={currCollection}
        classNames="large-x-modal"
        id=""
      />

      {props.collectionsList?.map((collection:any,index:number)=>{
        let eleId = getRandomString(10)
        let collectionNameForCover = collection.collection_name.charAt(0).toUpperCase() + collection.collection_name.slice(1).toLowerCase()
        return(
          <React.Fragment key={index}>
          {Number(index) < loadLimit ? (
          <div id={eleId} onClick={()=>clickHandle(collection)} className={`category-details-wrapper padding5 image-small`} key={index}>
            <div className="white-card shadow-medium">

              <div className="image  product-image-container position-relative" style={{aspectRatio:"1"}}>
                <img className="full-height-width" style={{objectFit:"cover"}} src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${collection.company_name}/${collectionNameForCover}.webp`}
                 alt=""
                 />
              </div>

              

              <div className="text heading1 marginTop10 text-center">{collection.collection_name}</div>
              <div style={{marginTop:"2px"}} className="text heading4 marginBottom5 font-small text-center">{getMaterialsCount(collection.collection_name)} Options</div>
              <div className="no-shadow marginleft5  display-flex-row no-wrap">
              </div>
            </div>
          </div>
          ) : null}
          </React.Fragment>
        )
      })}

      {loadLimit < props.collectionsList?.length ? (
      <div className="center-aligned-row marginTop10 full-width">
        <div className="display-flex-row no-wrap">
          <Button2
            configObj={{}}
            action={()=>{nextPage()}}
            label={"Load More"}
            fontAwesomeIcon={"fas fa-share-alt color-primary"}
            iconPos=""
            classNames={"button-small bg-color-primary-outline"}
          />
           <Button2
            configObj={{}}
            action={()=>{viewAll()}}
            label={"Show All"}
            fontAwesomeIcon={"fas fa-share-alt color-primary"}
            iconPos=""
            classNames={"button-small bg-color-light"}
          />
        </div>
        
      </div>
    ) : null}

    </div> 
     
    </>
  );
};

export default Collections;
