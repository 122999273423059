
// import * as THREE from "three"
import { BoxGeometry, Mesh, MeshBasicMaterial, Vector3 } from "three";
import { hideComponentLoader } from "../../UI_methods/global";
import { applicationConfig, waitFor } from "../../methods";
import { getObjectDimensionPosition } from "../modules/helper";
import { changeViewMode } from "./UI_methods";
import { addFloor, controls, floorplanner, postShellFoundActions, postShellLoadActions, projectConfiguration, setAreaModelOrigin, setControlsOrbitPoint, updateCameraControls } from "./area3dModel";
import { All_WALLS, areaWallsBoundingBoxMapping, createAreaWallsBoundingBoxMapping, initialWallsSetup, makeWallsTransparent } from "./walls";
import { postFloorplanEditActions } from "./shell";

export var isTraceFloorplanMode = false


export class TraceFloorplan{
    constructor(){

    }


    async enable(){
        this.addCube()
        // postShellLoadActions(floorplanner.group,"all")
        await waitFor(500)
        createAreaWallsBoundingBoxMapping()
        makeWallsTransparent(All_WALLS,areaWallsBoundingBoxMapping["all"].boundingBox.center)
        addFloor(areaWallsBoundingBoxMapping["all"].boundingBox)
        setAreaModelOrigin()
        setControlsOrbitPoint()

        this.enableTraceFloorplan()
    
        hideComponentLoader("canvasLoader")
        hideComponentLoader("changeFinishLoader")
        
    }

    addCube(){
        const geometry = new BoxGeometry( 0.1, 0.1, 0.1 ); 
        const material = new MeshBasicMaterial( {color: 0xffffff} ); 
        const cube = new Mesh( geometry, material ); 
        cube.visible = false
        floorplanner.group.add( cube );
        return cube
    }
    
    enableTraceFloorplan() {
        isTraceFloorplanMode = true
        changeViewMode(null,"2d")
        applicationConfig.functions.customizer.setIsTraceFloorplanMode(true)
        // showFloorDimensions()
      }

    confirmTracing(isRemoveListener:boolean = true){
        // createAreaWallsBoundingBoxMapping()
        // initialWallsSetup(floorplanner.group)
        // createAreaWallsBoundingBoxMapping()
        // let center = getObjectDimensionPosition(null,floorplanner.group).center
        // makeWallsTransparent(All_WALLS,center)
        // updateCameraControls(floorplanner.group,"all")
        // if(isRemoveListener){
        //     floorplanner.removeFloorplanListeners()
        // }
        postFloorplanEditActions()
    }
      
    disableTraceFloorplan() {
        isTraceFloorplanMode = false
        changeViewMode(null,"3d")
        postShellFoundActions()
        // initialWallsSetup(floorplanner.group)
        // createAreaWallsBoundingBoxMapping()
        // let center = getObjectDimensionPosition(null,floorplanner.group).center
        // makeWallsTransparent(All_WALLS,center)
        // updateCameraControls(floorplanner.group,"all")

        floorplanner.createFloor()
        applicationConfig.functions.customizer.setIsTraceFloorplanMode(false)
        // return
        projectConfiguration.updateLocalStorage()
        floorplanner.saveFloorplan()

    }

    async setCameraPosition(target:Vector3){
        await controls.setPosition(target.x,target.y + 5 ,target.z,false)
        // controls.fitToBox( CURR_AREA_GROUP, false, { paddingTop:1.5, paddingLeft:2, paddingBottom:2.5, paddingRight:2 },false )
    }

    setFloorplanWallsForSingleRoom(){
        let areasList = projectConfiguration.areasList
        areasList = areasList.filter(currArea => currArea.area_name !== "Other")
        if(areasList.length === 1 ){
            const areaName = areasList[0].area_name
            projectConfiguration.projectConfiguration[areaName].walls = []
            floorplanner.group.traverse((currMesh:any) => {
                if(currMesh.isMesh && currMesh.name.includes("Floorplan_Wall")){
                    projectConfiguration.projectConfiguration[areaName].walls.push(currMesh.name)
                }
            })
        }
        projectConfiguration.updateLocalStorage()
    }
    
}



  
  