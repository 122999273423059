import {
  IonButton,
  IonIcon,
} from "@ionic/react";
import { arrowBack, close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { getAllProjectAreas, getProjectAreaCategories } from "../../../../services/api";
import { applicationConfig, getFilteredArray, sortAreasByPriority } from "../../../../utils/methods";
import { ProjectForm } from "../../../../utils/projects/projectForm";
import "../../../style/admin/createproject.css";
import AreaCategoryForm from "./AreaCategoryForm";
import AreasForm from "./AreasForm";
import Options from "./Options";
import ProductForm from "./ProductForm";
import ProjectDetailsForm from "./ProjectDetailsForm";
import Summary from "./review/Summary";
import FormStatus from "./FormStatus";
import MaterialsForm from "./materials-form/MaterialForm";

const CreateProject: React.FC<{
  closeModal: (value: boolean) => void;
  CLIENT_ID:number
  refreshProjects:(projects:Array<any>,allAreas:Array<any>)=>void
  projectId:number
  //Categories data to get the price
  allCategories:any
  //Sync moodboard list data
  configObj:any
}> = (props) => {
  const [currState, setCurrState] = useState("Step1");
  const [projectId, setProjectId] = useState<number>(props.projectId);
  const [allAreasList,setAllAreasList]:any = useState([]);
 
  const [areasInApartment, setAreasInApartment]:any = useState([]);
  const [selectedAreas, setSelectedAreas]:any = useState([]);
  const [selectedCategories,setSelectedCategories ]:any = useState({});
  const [otherAreas, setOtherAreas]:any = useState([]);
  const [selectedFile,setSelectedFile]:any = useState(null);


  const [projectForm] = useState<any>(new ProjectForm());
  
  const[mode,setMode] = useState("Create")

  // const[apartmentTypeAreaMapping]:any = useState({
  //   "1BHK":["LivingRoom","Kitchen","BedRoom1"],
  //   "2BHK":["LivingRoom","Kitchen","BedRoom1","BedRoom2"],
  //   "3BHK":["LivingRoom","BedRoom1","BedRoom2","Kitchen","BedRoom3"],
  //   "4BHK":["LivingRoom","BedRoom1","BedRoom2","Kitchen","BedRoom3",,"BedRoom4"],
  //   "5BHK":["LivingRoom","BedRoom1","BedRoom2","Kitchen","BedRoom3","BedRoom4",,"BedRoom5"],
  //   "6BHK":["LivingRoom","BedRoom1","BedRoom2","Kitchen","BedRoom3","BedRoom4","BedRoom5","BedRoom6"],
  // })

  const[apartmentTypeAreaMapping]:any = useState({
    "1BHK":["LivingRoom","Kitchen"],
    "2BHK":["LivingRoom","Kitchen"],
    "3BHK":["LivingRoom","Kitchen"],
    "4BHK":["LivingRoom","Kitchen"],
    "5BHK":["LivingRoom","Kitchen"],
    "6BHK":["LivingRoom","Kitchen"],
  })

  useEffect(()=>{
    let areas = applicationConfig?.data?.areasList.filter(currArea => !currArea.area_name.toLowerCase().includes("bedroom") && !currArea.area_name.toLowerCase().includes("bathroom"))
    setAllAreasList(areas)
  },[])

  //Used when areas is selected or deselected 
  useEffect(()=>{
    setSelectedAndOtherAreas(areasInApartment)
  },[areasInApartment])


  useEffect(()=>{

    if(props.projectId){
      setMode("update")
      let promiseAllAreas = getAllProjectAreas();
      let promiseProjectAreaCategories = getProjectAreaCategories()
      Promise.all([promiseAllAreas,promiseProjectAreaCategories])
        .then((data) => {
          //set predefined areas if project id exists
          let areasArray = []
          let projectAreas = getFilteredArray(data[0],"project_id",props.projectId.toString())
          for (const object of projectAreas) {
            areasArray.push(object.area_name)
          }
          setAreasInApartment(areasArray)
          //set project area categories 
          let areaCategories = {}
          let projectAreaCategories = getFilteredArray(data[1],"project_id",props.projectId.toString())
          //For each area create array of object containing the categories
          for (const area of projectAreas) {
            let categories = []
            for (const areaCategory of projectAreaCategories) {
              if(areaCategory.area_name===area.area_name){
                categories.push({areaId:area.area_id,categoryName:areaCategory.category_name,categoryId:areaCategory.category_id})
              }
            }
            areaCategories[area.area_name] = categories
          }
          setSelectedCategories(areaCategories)
        })
        .catch((error) => {
          console.log(error);
        });
    }else{
      setMode("create")
      setAreasInApartment([])
      setSelectedCategories({})
    }
  
  },[props.projectId])



  const updateApartmentType = (value:any)=>{
    let areasInApartment = apartmentTypeAreaMapping[value]  
    setAreasInApartment(areasInApartment)
    setSelectedAndOtherAreas(areasInApartment)
  }

  const setSelectedAndOtherAreas = (areaArray:any) => {
    let areasList = applicationConfig?.data?.areasList
    let selectedAreas = []
    let otherAreas = []
    for (const area of areasList) {
      if(areaArray.includes(area.area_name)){
        selectedAreas.push(area)
        continue
      }
      otherAreas.push(area)
    }
    setSelectedAreas(sortAreasByPriority(selectedAreas))
    setOtherAreas(sortAreasByPriority(otherAreas))
  }

  function updateAreasInApartment(areaName:string){
    setAreasInApartment((prevState:any)=>{
      //If already exists
      if(prevState.includes(areaName)){
        return prevState.filter(currArea => currArea !== areaName)
      }
      return [areaName, ...prevState]
    })

  }
 

  const updateCurrentState = (state:string) =>{
    setCurrState(state)
  }

  function CreateNewProject() {
    switch (currState) {
      case "Step1":
        return (
          <>
            <ProjectDetailsForm
              updateCurrentState={updateCurrentState}
              closeModal={props.closeModal}
              updateApartmentType={updateApartmentType}
              setProjectId={setProjectId}
              projectId={props.projectId}
              projectForm={projectForm}
              configObj={props.configObj}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          </>
            
        );

        break;

      case "Step2":
        return (
           <>
            <AreasForm 
                updateAreasInApartment={updateAreasInApartment}
                selectedAreas={selectedAreas}
                otherAreas={otherAreas}
                closeModal={props.closeModal}
                updateCurrentState={updateCurrentState}
                projectForm={projectForm}
                setAreasInApartment={setAreasInApartment}
              />
          </>
              
          
        );

        case "Step3":
          return (
            <React.Fragment>
                <AreaCategoryForm 
                  selectedAreas={selectedAreas}
                  selectedCategories = {selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  updateCurrentState={updateCurrentState}
                  currStep = {currState}
                  projectForm={projectForm}
                />
             
            </React.Fragment>
          );

        break;

        case "Step4":
          return (
            <React.Fragment>
                <MaterialsForm
                  updateCurrentState={updateCurrentState}
                  projectForm={projectForm}
                />
            </React.Fragment>
          );

        break;

        case "Step5":
          return (
            <React.Fragment>
              <div className="modal-header">
                <IonIcon
                  icon={close}
                  ios={close}
                  className="modal-close-icon"
                  onClick={() => props.closeModal(false)}></IonIcon>
                Select Products
              </div>
              <div className="modal-body">
                <ProductForm 
                selectedCategories={selectedCategories}
                selectedAreas={selectedAreas}
                />
              </div>
              <div className="modal-footer" style={{justifyContent:"space-between"}}>
                <IonButton color="primary" onClick={()=>setCurrState("Step3")}> <IonIcon icon={arrowBack} ios={arrowBack}></IonIcon> Prev</IonButton>
                <IonButton color="success"> Submit</IonButton>
                {/* <IonButton color="primary">Next <IonIcon icon={arrowForward} ios={arrowForward} ></IonIcon></IonButton> */}
              </div>
            </React.Fragment>
          );

        break;

        case "Summary":
          return(
          <Summary
            closeModal={props.closeModal}
            updateCurrentState={updateCurrentState}
            projectForm={projectForm}
            selectedCategories={selectedCategories}
            selectedAreas={selectedAreas}
            refreshProjects={props.refreshProjects}
            projectId={projectId}
            allCategories={props.allCategories}
            configObj={props.configObj}
            mode={mode}
          />
          )

          case "Options":
            return(
            <Options
              closeModal={props.closeModal}
              updateCurrentState={updateCurrentState}
              configObj={props.configObj}
              projectId={projectId}
              refreshProjects={props.refreshProjects}
              mode={mode}
              projectForm={projectForm}
            />
            )
      default:
        return <div></div>;
    }
    return <div></div>;
  }

  return <CreateNewProject />;

};

export default CreateProject;
