import $ from "jquery";
import React, { useEffect, useState } from "react";
import { applicationConfig, waitFor } from "../../../../../utils/methods";
import ControlsTab from "../../../../common/ui-components/tabs/ControlsTab";
import { sceneBackgrounds, sprites } from "../../../../../utils/customizein3d/modules/customizein3d";
import MyImages from "./MyImages";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import { getWindowWidth, hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { isPotraitMode } from "../../../../../utils/customizein3d/common";

const SceneBackgrounds: React.FC<{
}> = (props) => {
 
  const[backgrounds,setBackground] = useState([])
  const[uploadedImages,setUploadedImages] = useState([])
  const [currTab, setCurrTab] = useState<string>("RoomBackground");
  const [is3dMode] = useState<boolean>(true);

  const[tabItems,setTabItems] = useState([                           
      {tabName:"RoomBackgroundBG",label:"Background",ionIcon:null},
      {tabName:"ChooseFinishBG",label:"My Images",ionIcon:null},
  ])

  useEffect(()=>{
    let albumName = "public";
    var albumPhotosKey = encodeURIComponent(albumName) +"/roomBackgrounds/backgrounds/";
    applicationConfig.awsConfig.viewAlbumContents(albumPhotosKey)
      .then((photos: any) => {
        setBackground(photos.reverse());
      })
      .catch((err) => {
        console.log(err);
    });
  },[])

    

  async function updateBackground(src:string) {
    // productWithbg.updateImage(src,false)
    if(sprites.enable){
      showComponentLoader("changeFinishLoader")
      await waitFor(100)
      sprites.disable()
      await waitFor(500)
      sceneBackgrounds.enable()
      hideComponentLoader("changeFinishLoader")
    }
    sceneBackgrounds.setBackground(src)
  }

  function getImageUrl(key:string) {
    key = key.replace("public/","")
    return applicationConfig.aws_public_url+key
  }


 
function changeTabOption(tabName: string) {
  setCurrTab(tabName);
  updateTabValue(tabName)
}

function updateTabValue(val:string){
  $("#sceneBackgroundsWindow").find(".tab-content").removeClass("--is-active")
  $("#"+val).addClass("--is-active")
  }

  function hideWindow() {
    $("#sceneBackgroundsWindow").addClass("display-none")
  }

  async function customizeBackground(){
    if(!isPotraitMode() && getWindowWidth() > 680){
      showComponentLoader("changeFinishLoader")
      sceneBackgrounds.setBackgroundCustomizeMode()
      await waitFor(200)
      sprites.enable()
    }else{
      showGlobalToast("Only available for desktop",2000,"error")
    }
   
  }

  return (
      <div id="sceneBackgroundsWindow" className="full-height-width top-left display-none bg-color-white zindex100 padding0">
                <div className="padding5 header-content" style={{width:"100%",maxWidth:"25rem",margin:"auto"}}>
                <ControlsTab
                    configObj={applicationConfig}
                    tabItems={tabItems}
                    currSelectedValue={currTab}
                    action={changeTabOption}
                    theme="red"
                    parentContainerId="sceneBackgroundsWindow"
                />
                </div>


                <div id="RoomBackgroundBG" className="tab-content body-content --is-active marginTop10">

                    


                    <div className="full-width display-flex-row overflow-y-scroll">

                          <div className="padding5 position-relative" onClick={customizeBackground} style={{width:"50%"}}>
                            
                            <div className="full-height-width top-left padding0 center-aligned-column pointer" >
                              <div className="white-card no-shadow border display-flex-row no-wrap padding-10-15" style={{background:"rgba(0,0,0,0.2)"}}>
                                <span><img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/icon/svg/customize_danger.svg" width={15} alt="" /></span>
                                <span className="marginleft5 heading3 font-small">Customize</span>
                              </div>
                            </div>

                            <img src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/roomBackgrounds/customizable/Room3_View1.png`} alt="" className="full-height-width border-danger"/>
                          </div>

                            {backgrounds.map((currBack:string,index:number)=>{
                                return(
                                  <div key={index} className="padding5" style={{width:"50%"}}  onClick={()=>{updateBackground(currBack)}}>
                                    <img src={getImageUrl(currBack)} alt="" className="full-width"/>
                                  </div>
                                )
                            })}
                    </div>
                </div>

                
                <div id="ChooseFinishBG" className="tab-content body-content padding">
                    <MyImages
                      updateBackground={updateBackground}
                    />
                </div>

               
                
        <div className="footer-content center-aligned-column">  
          <Button2
              configObj={{id:""}}
              action={hideWindow}
              label={"Customize"}
              fontAwesomeIcon={"fas fa-arrow-left color-white"}
              iconPos="left"
              classNames={"shadow-medium button-small store-bg-color-primary"}
          />
        </div>
                
      </div>
    );
};

export default SceneBackgrounds;
