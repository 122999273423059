import $ from "jquery"
import { customizerConfig,  undo } from "../customizein3d/area3dmodel/area3dModel"
import { cameraFor2dRender } from "../customizein3d/area3dmodel/floorplan/render2d"
import { applicationConfig } from "../methods"
import { rendererDimensions } from "../customizein3d/common"
import { getWindowWidth } from "../UI_methods/global"

var startX = 0
var endX = 0
var startY = 0
var endX = 0

var isMouseDown = false
var borderToBeResized:any


var borderWidthLeft:any
var borderWidthRight:any
var borderWidthTop:any
var borderWidthBottom:any
 
const marginRight = getWindowWidth() < 480 ? 5 : 60
const marginBottom = getWindowWidth() < 480 ? 5 : 60
var currResizer:any
var diff = 0

export function mouseDownCropper(event:any,resizer:string) {
    event.preventDefault()
    resetPositions()   
    isMouseDown = true
    // $("#cropSettingsWrapper").css("pointer-events","none")

    let y = event.pageY || event.touches[0].clientY 
    let x = event.pageX || event.touches[0].clientX 

    $(".crop-box-wrapper").addClass("--is-active")
    undo?.add("aspect",{cropPoints:{...cameraFor2dRender.cropPoints}})
    switch (resizer) {
        case "left":
            startX = x
            borderToBeResized = $("#mainCropBox").css("border-left-width") 
            currResizer = "left"
        break;

        case "right":
            startX = x
            borderToBeResized = $("#mainCropBox").css("border-right-width") 
            currResizer = "right"


        break;

        case "top":
            startY = y
            borderToBeResized = $("#mainCropBox").css("border-top-width") 
            currResizer = "top"
        break;

        case "bottom":
            startY = y
            borderToBeResized = $("#mainCropBox").css("border-bottom-width") 
            currResizer = "bottom"
        break;

        case "middle":
            startX = x
            startY = y
            borderWidthLeft = $("#mainCropBox").css("border-left-width").replace("px","") 
            borderWidthRight = $("#mainCropBox").css("border-right-width").replace("px","") 
            borderWidthTop = $("#mainCropBox").css("border-top-width").replace("px","") 
            borderWidthBottom = $("#mainCropBox").css("border-bottom-width").replace("px","") 
            currResizer = "middle"
        break;
    
        default:
        break;
    }
}

export function mouseMoveCropper(event:any) {
    event.preventDefault()
    if(isMouseDown){
        let newValue = Number(borderToBeResized)  - Number(diff)

        let y = event.pageY || event.touches[0].clientY 
        let x = event.pageX || event.touches[0].clientX 

        switch (currResizer) {
            case "left":
                diff = x - startX  
                borderToBeResized = borderToBeResized.replace("px","")
                $("#mainCropBox").css("border-left-width",Number(borderToBeResized)  + Number(diff) + "px")
                applicationConfig.functions.customizer.setCurrselectedAspect("Custom")

            break;

            case "right":
                diff = x - startX  
                borderToBeResized = borderToBeResized.replace("px","")
                if(newValue < marginRight){
                    return
                }
                $("#mainCropBox").css("border-right-width",newValue + "px")
                applicationConfig.functions.customizer.setCurrselectedAspect("Custom")

            break;

            case "top":
                diff = y - startY
                borderToBeResized = borderToBeResized.replace("px","")
                $("#mainCropBox").css("border-top-width",Number(borderToBeResized) + Number(diff) + "px")
                applicationConfig.functions.customizer.setCurrselectedAspect("Custom")

            break;

            case "bottom":
                diff = y - startY
                borderToBeResized = borderToBeResized.replace("px","")
                if(newValue < marginBottom){
                    return
                }
                $("#mainCropBox").css("border-bottom-width",newValue + "px")
                applicationConfig.functions.customizer.setCurrselectedAspect("Custom")

            break;

            case "middle":
                let diffX =  x - startX
                let diffY =  y - startY

                if( Number(borderWidthRight) - Number(diffX) < marginBottom || 
                    Number(borderWidthBottom) - Number(diffY) < marginRight ||
                    Number(borderWidthLeft) + Number(diffX) < 10 || 
                    Number(borderWidthTop) + Number(diffY) < 10
                ){
                    return
                }
                
                $("#mainCropBox").css("border-left-width",Number(borderWidthLeft) + Number(diffX) + "px")
                $("#mainCropBox").css("border-right-width",Number(borderWidthRight) - Number(diffX) + "px")
                $("#mainCropBox").css("border-top-width",Number(borderWidthTop) + Number(diffY) + "px")
                $("#mainCropBox").css("border-bottom-width",Number(borderWidthBottom) - Number(diffY) + "px")
            break;
        
            default:
            break;
        }
    }
    


}

export function mouseUpCropper(event:any = null) {
    event?.preventDefault()
    isMouseDown = false
    confirmCrop()
    // $("#cropSettingsWrapper").css("pointer-events","all")
    // pointerUpJoystick(null)
    $(".crop-box-wrapper").removeClass("--is-active")
}


export function confirmCrop() {
        $("#cameraBorder").css("border-left-width",$("#mainCropBox").css("border-left-width"))
        $("#cameraBorder").css("border-right-width",$("#mainCropBox").css("border-right-width"))
        $("#cameraBorder").css("border-top-width",$("#mainCropBox").css("border-top-width"))
        $("#cameraBorder").css("border-bottom-width",$("#mainCropBox").css("border-bottom-width"))
    
        // let leftBorderWidth = Number($("#mainCropBox").css("border-left-width").replace("px",""))
        let rightBorderWidth = Number($("#mainCropBox").css("border-right-width").replace("px",""))
        // let topBorderWidth = Number($("#mainCropBox").css("border-top-width").replace("px",""))
        let bottomBorderWidth = Number($("#mainCropBox").css("border-bottom-width").replace("px",""))
    
        let minX = Number($("#mainCropBox").css("border-left-width").replace("px","")) 
        let maxX = Number($("#mainCropBox").outerWidth())  - rightBorderWidth 
        
        let minY = Number($("#mainCropBox").css("border-top-width").replace("px",""))
        let maxY = Number($("#mainCropBox").outerHeight())  - bottomBorderWidth

        // let minX = document.getElementById("topLeft").getBoundingClientRect().left
        // let maxX = document.getElementById("topRight").getBoundingClientRect().left

        // let minY = document.getElementById("topRight").getBoundingClientRect().top
        // let maxY = document.getElementById("bottomRight").getBoundingClientRect().top


        // console.log(minX,maxX,$("#mainCropBox").outerHeight() - maxY,$("#mainCropBox").outerHeight() - minY)
        // console.log(minX,maxX,$("#mainCropBox").outerHeight() - maxY,$("#mainCropBox").outerHeight() - minY)
        // console.log($("#mainCropBox").outerWidth(),$("#mainCropBox").outerHeight(),rendererDimensions)
        cameraFor2dRender?.setCropPoints(minX,maxX,rendererDimensions.height - maxY,rendererDimensions.height - minY)
        // cameraFor2dRender.setCropPoints(minX,maxX,minY,maxY)
        // cameraFor2dRender.setCropPoints(0,getWindowWidth(),0,667)
    
}
export function updateAspectRatio(aspectName:string) {
    let aspect = getNumberFromAspect(aspectName)
    updateAspectFrame(aspect)
    confirmCrop()
}

export function updateAspectFrame(aspect:number) {
    // let leftBorderWidth = Number($("#mainCropBox").css("border-left-width").replace("px",""))
    let topBorderWidth = Number($("#mainCropBox").css("border-top-width").replace("px",""))
    let bottomBorderWidth = Number($("#mainCropBox").css("border-bottom-width").replace("px",""))
    let heightOfBox = Number($("#mainCropBox").outerHeight())  - (topBorderWidth + bottomBorderWidth)

    let width = heightOfBox * aspect

    let remaningWidth = Number($("#mainCropBox").outerWidth()) - width

    $("#mainCropBox").css("border-right-width",Number(remaningWidth / 2) + "px")  
    $("#mainCropBox").css("border-left-width",Number(remaningWidth / 2) + "px")  
    confirmCrop()
}

export function updateAspectFrameFromCropPoints(points:any) {
    // let leftBorderWidth = Number($("#mainCropBox").css("border-left-width").replace("px",""))
    $("#mainCropBox").css("border-left-width",Number(points.minX) + "px")  
    $("#mainCropBox").css("border-right-width",Number($("#mainCropBox").outerWidth()) - Number(points.maxX) + "px")

    $("#mainCropBox").css("border-bottom-width",Number(points.minY) + "px")  
    $("#mainCropBox").css("border-top-width",Number($("#mainCropBox").outerHeight()) - Number(points.maxY) + "px")

    let width = Math.abs(Number(points.maxX) - Number(points.minX)) 
    let height = Math.abs(Number(points.maxY) - Number(points.minY)) 
    applicationConfig.functions.customizer?.setCurrselectedAspect(getAspectFromNumber(width/height))

    // $("#mainCropBox").css("border-bottom-width",Number(points.minY) + "px")  
    // $("#mainCropBox").css("border-top-width",Number(points.maxY) + "px")  
}

function getAspectFromNumber(number:number) {
    switch (number) {
        case 4/3:
            return "4/3"

        case 16/9:
            return "16/9"

        case 2/1:
            return "2/1"

        case 1/1:
            return "1"
    
        default:
            return "Custom"
    } 
}

function getNumberFromAspect(name:string) {
    switch (name) {
        case "4:3":
            return 4/3

        case "16:9":
            return 16/9

        case "2:1":
            return 2/1

        case "1:1":
            return 1
    
        default:
            break;
    }
}



function resetPositions() {
    startX = 0
    endX = 0
    startY = 0
    endX = 0 
}