import React from "react";
import { getFormatedDate } from "../../../../../../../utils/store/crm/crm";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
 

 
const TicketCard: React.FC<{
  ticket:any
  index:number
  openCalendarHandle:(val:any)=>void
  refreshLeads:()=>void
  startActivityHandle:(name:string,ticket:any)=>void
  openLeadDetailsHandle:(lead:any,view:string)=>void
  openConvertTicketHandle:(lead:any)=>void
  
}> = (props) => {

  
  
    
  function Options() {
    return(
      <div className="display-flex-row no-wrap">
          {!props.ticket.is_converted_to_ticket?
            <Button2
              configObj={{id:""}}
              action={()=>{props.openConvertTicketHandle(props.ticket)}}
              label={"Convert To Ticket"}
              fontAwesomeIcon={"fas fa-cog color-warning"}
              iconPos="left"
              classNames={"no-shadow button-small color-white bg-color-warning-outline"}
            />
          :
            <Button2
              configObj={{id:""}}
              action={()=>{}}
              label={"Converted To Ticket"}
              fontAwesomeIcon={"fas fa-check-circle color-success"}
              iconPos="left"
              classNames={"no-shadow button-small color-white bg-color-light"}
            />
          }
      </div>
    )
  }
 
  return (
    <div className="full-width padding5 lead-card-container" key={props.index}>


      <div className={`white-card pointer position-relative no-shadow border`}>

        
        <div className="display-flex-row" onClick={()=>{props.openLeadDetailsHandle(props.ticket,"activity")}}>

          <div className="padding5 name-badge-container" >
            <div className="capitalize name-badge center-aligned-column white-card no-shadow">
              {props.ticket.customer_name.substring(0,2)}
            </div>
          </div>

          <div className="customer-details center-aligned-column padding5 marginleft5">

            <div className="contact-details ">

              <div className="display-flex-row item">
                <span className="hide-in-desktop"><i className="far fa-id-card icon color-dark"></i></span>
                <span className="center-aligned-column heading2 capitalize name color-black text  marginleft5">{props.ticket.customer_name}</span>
              </div>

              <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column"><i className="fas fa-mobile-alt icon color-dark"></i></span>
                <span className="center-aligned-column text  marginleft5">+91 {props.ticket.mobile}</span>
              </div>

              <div className="display-flex-row custom-tooltip item ion-margin-start position-relative">
                <span className="center-aligned-column"><i className="fas fa-id-card icon color-dark"></i></span>
                <span className="text  marginleft5" style={{marginTop:"2px"}} >{props.ticket.ticket_no}</span>
              </div>

              <div className="display-flex-row custom-tooltip item ion-margin-start position-relative">
                <span className="center-aligned-column"><i className="fas fa-info-circle icon color-dark"></i></span>
                <span className="text  marginleft5 text-ellipsis" style={{marginTop:"2px"}} >{props.ticket.issue_type}</span>
                <div className="custom-tooltip-text">{props.ticket.issue_type}</div>
              </div>

              {props.ticket.description?
              <div className="display-flex-row custom-tooltip item ion-margin-start position-relative">
                <span className="center-aligned-column"><i className="far fa-comments icon color-dark"></i></span>
                <span className="text  marginleft5 text-ellipsis" style={{marginTop:"2px"}} >{props.ticket.description}</span>
                <div className="custom-tooltip-text">{props.ticket.description}</div>
              </div>
              :null}

              <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column"><i className="far fa-calendar-alt icon color-warning"></i></span>
                <span className="text marginleft5 color-warning">Created on: {getFormatedDate(props.ticket.created_at)} </span>
              </div>


              {props.ticket.remarks?
              <div className="display-flex-row custom-tooltip item ion-margin-start position-relative">
                <span className="center-aligned-column"><i className="far fa-comment-dots icon color-dark"></i></span>
                <span className="text  marginleft5 text-ellipsis" style={{marginTop:"2px"}} >{props.ticket.remarks}</span>
                <div className="custom-tooltip-text">{props.ticket.remarks}</div>
              </div>
              :null}

            </div>
          </div>

        </div>
        
    

        <div className={`top-right options-container center-aligned-column full-height`}>
          <Options/>
        </div>
      </div>
    </div>
    
  );
};

export default TicketCard;
