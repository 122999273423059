import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import $ from "jquery"

const DragControlsWrapperIcon: React.FC<{
}> = (props) => {


  return (
    <div id="controlsDragIcon" className="padding10 full-width background-white top-left display-flex-row space-between">
    <div><i className="fas fa-arrows-alt color-black font-medium"></i> Drag to move</div>
    <div><i className="fas fa-times color-black font-medium" onClick={()=> $(".customize-controls-wrapper").fadeToggle()}></i></div>
  </div>

  );
};

export default DragControlsWrapperIcon;
