import {
    IonPage
} from "@ionic/react";
import React, { useEffect, useState } from "react";
   
  import $ from "jquery";
import { getShareLink, getUsersList, updateUser } from "../../../../../services/api";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import { rippleEffectOnClick } from "../../../../../utils/UI_methods/ui_effects";
import { applicationConfig, getUser, goback } from "../../../../../utils/methods";
import Modal from "../../../../common/shared-components/Modals/Modal";
import SessionExpired from "../../../../common/ui-components/alerts/SessionExpired";
import SubscriptionAlert from "../../../../common/ui-components/alerts/SubscriptionAlert";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ControlsTab from "../../../../common/ui-components/tabs/ControlsTab";
import MainHeader from "../../../products/store/common/MainHeader";
import CreateClientUser from "../../../users/CreateUser/CreateClientUser";
import OrdersList from "./OrdersList";
import QuotationsList from "../quotation/QuotationsList";
   
   
  
  const KanvasAdmin: React.FC<{
    configObj:any
    setIsLoadedPageConfiguration:(val:any)=>void
    isLoadedPageConfiguration:any
    isLogin:boolean
  }> = (props) => {
    
    const[isOpenModal,setIsOpenModal] = useState(false)
    const[usersList,setUsersList] = useState([])
    const[currUser,setCurrUSer] = useState(null)
    const[currTab,setCurrTab] = useState("Orders")
  
    const[tabItems] = useState([
      {tabName:"Quotations",label:"Quotations",ionIcon:null},
      {tabName:"Orders",label:"Orders",ionIcon:null},
    ])
  
  
    useEffect(()=>{
      let userType = applicationConfig.user.userType
      if(userType !== "admin"){
        window.location.href = "/access-denied"
      }
      updateUsersList()
    },[])
  
   
    async function updateUsersList(){
      getUsersList(applicationConfig.clientId).then(data =>{
        applicationConfig.data.usersList = data
        setUsersList(data)
      })
    }
  
    function closeModal(){
      setIsOpenModal(false)
      // setUsersList(applicationConfig?.data?.usersList)
    }
  
    async function updateLoginToken(userDetails:any) {
      let loginToken = null
  
      if(currUser){
        await updateUsersList()
        showGlobalToast("Updated",2000)
        setCurrUSer(null)
        setIsOpenModal(false)
        return
      }
  
    
  
      let  data={
        moduleName:"logintoken",
        shareItemId:0,
        clientId:applicationConfig?.clientId,
        clientName:applicationConfig?.clientName,
        tokenValidity: 365,
        username:userDetails.username,
        password:userDetails.password,
        userType:"client",
      }
      getShareLink(data).then(data=>{
        loginToken = data.data.data.token
  
        updateUser({isUpdateLoginToken:true,loginToken:loginToken,id:userDetails.id}).then((response:any)=>{
          if(response.data.error){
              console.log(response.data.message)
              setCurrUSer(null)
          }else{
              getUsersList(applicationConfig.clientId).then(data=>{
                  console.log(data)
                  applicationConfig.data.usersList = data
                  setUsersList(data)
                  showGlobalToast("Added",2000)
                  setCurrUSer(null)
                  setIsOpenModal(false)
              })
          }
        }).catch(err=>{
            console.log(err)
            setCurrUSer(null)
        })
  
  
        }).catch(err=>{
        console.log(err)
        setCurrUSer(null)
        showGlobalToast("Error",2000,"Error")
      })
  
      
    }
  
    function updateHanlde(user:any) {
      setCurrUSer(user)
      setIsOpenModal(true)
    }
  
    function changeTabOption(tabName: string) {
      setCurrTab(tabName);
      updateTabValue(tabName)
    }
  
    function updateTabValue(val:string){
      $(".tab-content").removeClass("--is-active")
      $("#"+val).addClass("--is-active")
    }
  
  
    return (
      <IonPage>
        {props.configObj && props.isLogin?
        <div className="full-height-width bg-color-white">
          <SubscriptionAlert/>
          <MainHeader/>
  
          <Modal
            isOpen = {isOpenModal}
            Content = {()=>(
              <CreateClientUser
                callBack={updateLoginToken}
                savedUser={currUser}
                clientId={applicationConfig.clientId}
              />
            )}
            onDismiss = {()=>setIsOpenModal(false)}
            heading="Create user"
            classNames="vertical-modal"
            id=""
            FooterContent={()=>(<div className="middle">
            </div>)}
          />
  
  
            <div className="main-content-wrapper position-relative hide-scrollbar light-background padding10">
  
              <div className="hide-in-desktop full-width">
                <div className="full-width center-aligned-column marginTop5">
                    <ControlsTab
                      configObj={applicationConfig}
                      tabItems={tabItems}
                      currSelectedValue={currTab}
                      action={changeTabOption}
                      theme="red"
                    />
                </div>
              </div>
  
              <div className=" background-white position-relative overflow-hidden full-height-width">
                <div className="display-flex-row main-cards-filters-wrapper no-wrap ion-padding full-height-width" >
                  <div className="main-sidebar-filters-wrapper hide-in-mobile position-relative shadow-medium white-card padding10 overflow-y-scroll">
   
  
                  <div className="bottom-left full-width padding0">
                  <img className="full-width contain" src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/clientLogos/other/${applicationConfig?.clientName}_Tag.PNG`} alt="" />
                  </div>
  
                  <div className="hide-in-mobile display overflow-y-scroll">
                    {!getUser()?.shareItemDetails?
                      <Button2
                        configObj={{}}
                        action={()=>{goback()}}
                        label={"Go back"}
                        fontAwesomeIcon={"fas fa-arrow-left color-primary"}
                        iconPos="left"
                        classNames={"shadow-light margin0 font-medium full-width bg-color-black"}
                      />
                    :null}
                  </div>
  
                   
                  <div onClick={()=>{setCurrTab("Quotations")}} onPointerDown={rippleEffectOnClick} className={`border-bottom click-effect position-relative overflow-hidden sub-category ion-padding pointer ${currTab === "Quotations"?"--is-active":""}`} style={{paddingLeft:"2rem"}}>
                      <div className="heading4 pointer-none text">{"Quotations"}</div>
                  </div>
  
                  <div onClick={()=>{setCurrTab("Orders")}} onPointerDown={rippleEffectOnClick} className={`border-bottom click-effect position-relative overflow-hidden sub-category ion-padding pointer ${currTab === "Orders"?"--is-active":""}`} style={{paddingLeft:"2rem"}}>
                      <div className="heading4 pointer-none text">{"Orders"}</div>
                  </div>
                   
                  </div>
  
                  <div className="main-cards-wrapper padding10 position-relative display-flex-column no-wrap overflow-hidden full-height shadow-light white-card">
                  
                  {currTab === "Quotations"?
                  <>
                  {getUser().userType === "admin"?
                    <div className="display-flex-column no-wrap overflow-y-scroll full-height shadow-light white-card">
                      <div className="" style={{height:"calc(100% - 0)"}}>
                        <QuotationsList closeModal={()=>{}}/>
                      </div>
                    </div>
                    :
                    <div className="heading4 padding10">Invalid Access</div>
                    }
                  
                  </>
                  :
                  <OrdersList/>
                  }
                  
  
                  </div>
  
                </div>
              </div>
          </div>
  
  
          
        </div>
        :
        <SessionExpired/>
        }
      </IonPage>
    );
  };
  
  export default KanvasAdmin;
  