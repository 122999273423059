import React, { useState } from "react";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getObjectByParameter } from "../../../../../utils/methods";

 
const LegDetails: React.FC<{
    configuration:any
    configuration3D:any
    isReadOnly:boolean
}> = (props) => {

    const[currLeg] = useState(getCurrLeg())

 
    function getCurrLeg(){
        let result = null
        let leg = getObjectByParameter(applicationConfig.data.sofaLegs,"leg_name",props.configuration3D?.currSofaLeg)
        if(leg){
            result = leg
        }
        return result
    }



    function Details() {
        return(
            <div className="marginTop10">
                <div className="marginTop10">
                <div className="Pricing"></div>
                <table className="table store-quotation-table">
                    <thead>
                        <th>Image</th>
                        <th>Leg Name</th>
                        <th>Material</th>
                        <th>Height</th>
                    </thead>
                <tbody>
                    <tr>
                        <td className="center-aligned-column"><img className="border" width={70} src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/SofaLegs/${currLeg?.leg_name}.png`} alt="" /></td>
                        <td>{currLeg?.leg_name}</td>
                        <td>{currLeg?.material}</td>
                        <td>{currLeg?.height} In</td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        )
    }
 
  return (
    <Details/>
  );
};

export default LegDetails;
