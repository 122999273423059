import React, { useEffect } from "react";
import { displaySelectedImage } from "../../../../utils/inventory/inventory";
import { hideComponentLoader, showComponentLoader } from "../../../../utils/UI_methods/global";
const Options: React.FC<{
  configObj: any;
  materialsList:Array<any>
  deleteItem:(item:number)=>void
  addFinishLoader:string
  currForm:string
}> = (props) => {

  useEffect(()=>{
    if(props.materialsList.length){
      displayMaterialsImage()
    }
  },[props.materialsList])

  function displayMaterialsImage(){
    showComponentLoader(props.addFinishLoader)
    setTimeout(async () => {
      for (const material of props.materialsList) {
        await displaySelectedImage(material.SELECTED_FILE,"selectedImage_"+material.materialCode)
      }
      hideComponentLoader(props.addFinishLoader)
    }, 100);
  }
  return (
    <React.Fragment>
       {props.materialsList.length?
                // props.materialsList.map((material:any,index:number)=>{
                //     displaySelectedImage(material.SELECTED_FILE,"selectedImage_"+material.materialCode)
                //   return(
                //     <div className="display-flex-row position-relative border-bottom" key={index}>
                //       <div className="heading2 color-black center-aligned-column">{index+1} . </div>
                //       <div className="ion-margin-start"><img src="assets/images/placeholder.png" className="border" id={`selectedImage_${material.materialCode}`} alt="" width={80}/></div>
                //       <div className="heading2 ion-margin-start center-aligned-column">{material.materialCode}</div>
                //       <div className="bottom-right padding5"> <i className="color-danger  fas fa-trash" onClick={()=>deleteItem(index)}></i> </div>
                //     </div>
                //   )
                // })
                <table className="table">
                <thead className="light-background">
                  {props.currForm==="review"?
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Material code</th>
                  </tr>
                :null}
                </thead>
                <tbody>
                  
                  {props.materialsList?.map((material: any, index: number) => {
                    return (
                      <tr key={index} className="text-center ">
                        <td>{index+1}</td>
                        <td className="center-aligned-row"><img src="" className="" id={`selectedImage_${material.materialCode}`} alt="" width={80}/></td>
                        <td>{material.materialCode}</td>
                        {props.currForm==="material"?
                        <td><i className="color-danger  fas fa-trash" onClick={()=>props.deleteItem(index)}></i></td>
                        :null}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
                
                :
                <div className="center-aligned-row full-width border-dashed ion-padding ion-margin-top ion-margin-bottom">
                <div className="label">No Materials added</div>
              </div>
              }
    </React.Fragment>
  );
};

export default Options;
