import React, { useState } from "react";

 

const FormStatus: React.FC<{
     count:number
}> = (props) => {

  const[steps] = useState([
    {
      label:"Project details"
    },
    {
      label:"Select areas"
    },
    {
      label:"Select Products"
    },
    // {
    //   label:"Select Materials"
    // },
    {
      label:"Review"
    },
  ])
 
 
  return (
    <React.Fragment>
    <div className="full-width display-flex-row form-status-wrapper no-wrap">
      {steps.map((currStep:any,index:number)=>{
        return(
          <div className={`center-aligned-column  form-status-container padding5 flex1 ${index+1 <= props.count ? " --is-active" :" "} ${index+1 === props.count ? " --is-current" :" "}`}>
            {/* <div className="chevron-div"></div> */}
            <div className="display-flex-row">
              <div className="center-aligned-column">
                <div key={index} className={`heading4 status-count center-aligned-column bg-color-dark color-white font-ex-small`} style={{borderRadius:"50%",width:"12px",height:"12px"}}>{index+1}</div>
              </div>
              <div key={index} className={`heading3 label marginleft5`}>{currStep.label}</div>
            </div>

          </div>
        )
      })}

    </div>
  </React.Fragment>
  );
};

export default FormStatus;
