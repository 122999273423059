import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import AwsConfig from '../../../../../../utils/aws-sdk/AwsConfig';
import { untarAndSaveToCache } from '../../../../../../utils/cache/cache';
import { displayRenderImage } from "../../../../../../utils/customizer/helper";
import { applicationConfig, getViewFromProjectAreaId } from '../../../../../../utils/methods';
import { hideComponentLoader, showComponentLoader, updateLoaderProgress } from '../../../../../../utils/UI_methods/global';
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import Button2 from '../../../../../common/ui-components/buttons/Button2';
import "../../../../../style/common/minimap.css";

const Minimap: React.FC<{
  allAreas:Array<any>;  
  setProducts:(projectId:number,areaId:number,areaName:string,projectName:string,viewName:string)=>void;
  closeModal:(value:any)=>void;
  configObj:any;
  content:string;
  isUpdateUrl:boolean
  action:(value:any,action:string)=>void
  }> = (props) => {

  const history = useHistory()
  const[allViews,setAllViews]:any = useState([])
  // const[areaList,setAreaList]:any = useState([])


  useEffect(()=>{
    if(props.configObj && props.configObj?.projectId){
      // let views = getViewFromProjectAreaId(props.configObj?.data.projectAreaProductsList,props.configObj?.projectId,props.configObj.areaId)
      // setAllViews(views)
      loadAndDisplayImages()
    }
  },[props.configObj])

  // useEffect(()=>{
  //   setAreaList(props.allAreas.sort((a,b)=>a.area_name<b.area_name?-1:1))
  // },[])

  
  // function gotoCustomizer(projectId:number,areaId:number,areaName:string,projectName:string){
  //   props.setProducts(projectId,areaId,areaName,projectName,props.allViews[0])
  // }

  function updateView(areaName:string,areaId:number,viewName:string){
    props.configObj?.setCurrAreaId(areaId)
    props.configObj?.setCurrArea(areaName)
    props.closeModal(false)
    if(props.isUpdateUrl){
      // history.replace("/3dwalkthrough",null)
      history.push("/customize");
    }
    // setTimeout(() => {
    props.setProducts(props.configObj?.projectId,areaId,areaName,props.configObj?.projectName,viewName);
    // }, 500);
  }

  function generateViews(areaId:number){
    let views = getViewFromProjectAreaId(props.configObj?.data.projectAreaProductsList,props.configObj?.projectId,areaId)
    //Get all views corresponding to project and area id 
    // setCurrAreaId(areaId)
    // setCurrArea(areaName)
    // setAllViews(views)
    return views
  }

  function loadAndDisplayImages() {
    showComponentLoader("minimapLoader")
    let urlPrefix = `sprites/${props.configObj.clientName}/${props.configObj?.projectName}/ViewRenders`
    let tarFileKey = applicationConfig.awsConfig.getTarFileKey("renders",{clientName:props.configObj?.clientName,projectName:props.configObj?.projectName})
    untarAndSaveToCache(applicationConfig.awsConfig,urlPrefix,tarFileKey)?.then(data=>{
      setTimeout(() => {
        displayImages()
      }, 500);
    }).catch(err=>{
      displayImages()
    })
   
  }

  function displayImages() {

    if(props.content==="Areas"){
      for (let i = 0; i < props.allAreas.length; i++) {
        let area = props.allAreas[i]
          displayRenderImage("",props.configObj.clientName,props.configObj?.projectName,area.area_name).then(isLoaded=>{
            updateLoaderProgress("minimapLoader",i,props.allAreas.length-1,"Please wait..")
            if(i===props.allAreas.length-1){
              hideComponentLoader("minimapLoader")
            }
          }).catch(err=>{
            console.log(err)
            if(i===props.allAreas.length-1){
              hideComponentLoader("minimapLoader")
            }
          })
      }
    }else{
        let views = generateViews(props.configObj?.areaId)
        for (let i = 0; i < views.length; i++) {
          let view:any = views[i]
          displayRenderImage("",props.configObj.clientName,props.configObj?.projectName,props.configObj?.areaName,view).then(isLoaded=>{
            updateLoaderProgress("minimapLoader",i,views.length-1,"Please wait..")
            if(i===views.length-1){
              hideComponentLoader("minimapLoader")
            }
          }).catch(err=>{
            console.log(err)
            if(i===views.length-1){
              hideComponentLoader("minimapLoader")
            }
          })
          
        }
    }

 
    
  }

  
  
  return (
      <React.Fragment>
         
          
        <div className="minimap-main-wrapper hide-scrollbar position-relative">
        <Loader className="--is-active"
                id="minimapLoader"
                data={{}} 
                loaderType="progressCompact"
              />
          {props.content==="Areas"?
          <div className="areas-container hide-scrollbar" style={{alignContent:"baseline"}}>
            {props.allAreas.map((area:any,index:any)=>{
              let imageUrlKey = `sprites/${props.configObj.clientName}/${props.configObj?.projectName}/ViewRenders/${area.area_name}_View10000.jpg`

              // let classNames = [];
              // classNames.push("");
              // if (area.area_name === currArea) {
              //   classNames.push("--is-active");
              // }
              // let allViews = generateViews(area.area_name,area.area_id)
              return (
                // <div className={classNames.join(" ")} key={index} onClick={()=>gotoCustomizer(area.project_id,area.area_id,area.area_name,area.project_name)}>
                <div className="area-item position-relative" key={index}>
                  <div className="area-image white-card position-relative ">
                    <img className="image" id={imageUrlKey} src={`./assets/images/DemoClient2/DemoProject1/ViewRenders/${area.area_name}_View10000.jpg`} alt=""/>
                    {/* <div className="minimap-view-container">
                    <div className="minimap-view-title" onClick={()=>updateView(area.area_name,area.area_id,"View1")}>
                              Customize
                      </div>
                    {allViews.map(function(view:any,index:number){
                        return (
                            <div className="minimap-view-title custom-card" key={index} onClick={()=>updateView(area.area_name,area.area_id,view)}>
                              {view}
                            </div>
                        )
                      })}
                      </div> */}
                  </div>
                  <div className="bottom-middle full-width zindex1 display-flex-column">
                    <div className="area-title area-name">
                      {area.area_name}
                    </div>
                    
                  </div>
                  <div className="middle zindex1">
                      <div className='minimap-actions'>

                        <div className="customize-link bg-color-primary display-flex-row no-wrap" onClick={()=>props.action(area,"virualtour")}>
                          <div className="marginRight5 center-aligned-column"><i className="color-white font-small fas fa-eye"></i></div>
                          <div className="color-white center-aligned-column text">Virtual tour</div>
                        </div>

                        {/* <div className="customize-link display-flex-row no-wrap" onClick={()=>props.action(area,"customizer")}>
                          <div className="marginRight5 center-aligned-column"><i className="color-white font-small fas fa-cube"></i></div>
                          <div className="color-white center-aligned-column text">View in 3d</div>
                        </div> */}

                      </div>
                      
                  </div>               
                </div>
              )
            })}
          </div>
          :
          <div className="areas-container hide-scrollbar" style={{alignContent:"baseline"}}>
          {allViews.map((view:any,index:any)=>{
            let imageUrlKey = `sprites/${props.configObj.clientName}/${props.configObj?.projectName}/ViewRenders/${props.configObj?.areaName}_${view}0000.jpg`
            return (
              <div className="area-item position-relative" key={index}>
                <div className="area-image white-card position-relative ">
                  <img className="image" id={imageUrlKey} src="" alt=""/>
                </div>
                <div className="bottom-middle full-width zindex1 display-flex-column">
                  <div className="area-title area-name">
                    {view}
                  </div>
                  
                </div>
                <div className="middle zindex1">
                    <div className="customize-link no-wrap display-flex-row" onClick={()=>updateView(props.configObj?.areaName,props.configObj?.areaId,view)}>
                      <div className="color-white center-aligned-column text">Customize</div>
                      <div className="marginleft5"><i className="color-white fas fa-chevron-right"></i></div>
                    </div>
                </div>               
            
              </div>
            )
          })}
        </div>
          }
        </div>

      </React.Fragment>
 
  );
};

export default Minimap;
