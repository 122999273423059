import React from "react";
import { applicationConfig } from "../../../../utils/methods";


const SvgButtonRound: React.FC<{
  action:()=>void
  label:string
  classNames:string
  svgUrl?:string
  containerClass?:string
}> = (props) => {

 
  return (
    <React.Fragment>
     <div onClick={props.action} className={`display-flex-row ion-margin-top ${props.containerClass}`}>
        <div className={`white-card  padding5  center-aligned-column ${props.classNames}`} style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%"}}>
          <img src={applicationConfig.aws_public_url+props.svgUrl} className="pointer-none" alt="" />
        </div>
        <span className="center-aligned-column heading4 font-md-small color-black marginleft5">{props.label}</span>
    </div>
    </React.Fragment>
  
  );
};

export default SvgButtonRound;
