import React, { useEffect, useState } from "react";
import $ from "jquery"
import { applicationConfig, getObjectByParameter, getRandomString } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import ProductImage from "../../../../common/ui-components/elements/ProductImage";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify.js";
import { getRunningDimensions } from "../../../../../utils/customizein3d/modules/customizein3d";
import Input from "../../../../common/ui-components/input/Input";
import DropDown from "../../../../common/ui-components/filters/DropDown";
import { Configuration } from "../../../../../utils/customizein3d/Configuration";

 
const PricingDetails: React.FC<{
    cartItems:any
    configuration3D:Configuration
}> = (props) => {

    const[finalPrice,setfinalPrice] = useState(props.configuration3D?.pricing?.totalPrice - props.configuration3D?.pricing?.discount)
    const[totalPrice,setTotalPrice] = useState(props.configuration3D?.pricing?.totalPrice)
   
    const[currValue,setCurrValue] = useState(props.configuration3D?.pricing?.quality)

    const[runningFeet] = useState(props.configuration3D?.pricing?.runningFeet || getRunningDimensions(props.configuration3D) || 0)


    const qualities = props.configuration3D?.pricing?.qualities || []


    useEffect(()=>{
        if(props.configuration3D){
            if(props.configuration3D.isShowRunningFeet){
                 
            }else{
                try {
                    let modulesList = props.configuration3D?.modules
                    for (const item of modulesList) {
                        setfinalPrice(prevValue=>{
                            return prevValue + Number(item.price)
                        })
                    }  
                } catch (error) {
                    console.log(error)
                }
                
            }
            
        }
        
    },[props.cartItems])

    function updatePrice(value:number){
        let result = Math.round(((runningFeet / 12) * value)) 
        props.configuration3D.pricing.totalPrice = result
        setTotalPrice(props.configuration3D.pricing.totalPrice)
        updatePriceWithDiscount(props.configuration3D?.pricing?.discount)
    }
    
    function updatequality(value:string){
        setCurrValue(value)
        props.configuration3D.pricing.quality = value
    }

    function updatePriceWithDiscount(value:number){
        props.configuration3D.pricing.discount = value
        props.configuration3D.pricing.finalPrice = props.configuration3D?.pricing?.totalPrice -  props.configuration3D?.pricing?.discount
        setfinalPrice(props.configuration3D?.pricing?.finalPrice)
        $(".summary-final-price").text(getFormattedPrice(props.configuration3D?.pricing?.finalPrice))
    }

    function convertInchesToFeetAndInches(inches:number) {
        let feet = Math.floor(inches / 12);         
        let remainingInches = inches % 12;         
        return `${feet} ft ${remainingInches} in`;
    }

    function PricingFormOld(){
        return(
            <tr>
                <td className="text-start" colSpan={4}>
                    <div className="">
                        <DropDown
                            columns={1}
                            classNames="minwidth150 storefront-dropdwon"
                            values={["Standard","Premium","Luxury","Ultra"]}
                            currSelectedValue={currValue}
                            action={updatequality}
                            label="Quality"
                        />
                    </div>
                </td>
                <td className="rupee-sign">
                    <div className={`input-container no-wrap`}>
                        <input id={getRandomString(5)} type="number" defaultValue={props.configuration3D?.pricing?.totalPrice} className="input" onChange={(event:any)=>{updatePrice(Number(event.target.value))}} />
                    </div>
                </td>
            </tr>
        )
    }

    function PricingForm(){
        return(
            <>
            {qualities.map((currOption:any,i:number)=>{
                            let inputId = getRandomString(5)
                            return(
                            <tr key={i} className="pointer">
                                <td  onClick={()=>{
                                    updatequality(currOption.name)
                                    updatePrice(Number($(`#${inputId}`).val()))
                                    }} className="text-start price-heading pointer" colSpan={4}>
                                    <div className="display-flex-row no-wrap ">
                                        <div className="center-aligned-column">
                                            <i className={`${currOption.name === currValue ?"fas fa-circle":"far fa-circle"}  color-warning`}></i>
                                        </div>
                                        <div className="text-start marginleft10 heading3 font-md-small">
                                        {currOption.name}
                                        </div>
                                    </div>
                                    
                                </td>
                                <td className="rupee-sign">
                                    <div className={`input-container no-wrap`}>
                                        <input id={inputId} type="number" defaultValue={props.configuration3D?.pricing?.totalPrice} className="input input-small" onChange={(event:any)=>{updatePrice(Number(event.target.value))}} />
                                    </div>
                                </td>
                            </tr>
                            )
                            
                        })}
                
            </>
            
            
        )
    }

    function PricingFormFinal(){
        return(
            <>
            

            </>
        )
    }

    function updateValues(value:number,qualitiy:any){
        if(qualitiy){
            let totalPrice = Math.round(((runningFeet / 12) * value)) 
            let grandTotal = totalPrice - qualitiy.discount
            qualitiy.perFeetPrice = value
            qualitiy.totalPrice = totalPrice
            qualitiy.grandTotal = grandTotal

            $(`#subtotal_${qualitiy.name}`).text(getFormattedPrice(qualitiy.totalPrice))
            $(`#grandtotal_${qualitiy.name}`).text(getFormattedPrice(qualitiy.grandTotal))
            
        }
    }

    

 
  return (
    <div className="marginTop10">
                <div className="Pricing"></div>
                <table className="table store-quotation-table">
                <thead >
                  <tr >
                    <th className="bg-color-primary color-white">Image</th>
                    <th className="bg-color-primary color-white">Product Name</th>
                    <th className="bg-color-primary color-white">Category</th>
                    <th className="bg-color-primary color-white">Quality</th>
                    <th className="bg-color-primary color-white">Price</th>
                  </tr>
                </thead>
                    <tbody>
                        {props.cartItems?.map((item:any,j:number)=>{
                            let configuration = item?.configuration
                            configuration = JSON.parse(configuration)
                            let product = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",item.product_id)
                            return(
                            <tr key={j}>
                                <td className="image">
                                    <ProductImage product={product}/>
                                </td>
                                <td className="">
                                    {STORE.getProductName(product)}
                                </td>
                                <td className="">
                                    {product?.category_name}
                                </td>
                                <td className="store-color-primary heading3">{props.configuration3D?.pricing?.quality}</td>
                                <td className="rupee-sign">{getFormattedPrice(finalPrice)}</td>
                            </tr>
                            )
                        })}

                        {props.configuration3D?.isShowRunningFeet?
                        <>
                        <tr>
                            <td className="text-start price-heading" colSpan={4}>Running Feet</td>
                            <td className="rupee-sign">
                                    {convertInchesToFeetAndInches(runningFeet)}
                            </td>
                        </tr>

                        
                        <tr>
                        <td className="price-heading">Pricing</td>

                        {qualities.map((currOption:any,i:number)=>{
                            let inputId = getRandomString(5)
                            return(
                                <td key={i} id={getRandomString(5)} className="text-start price-heading" onClick={()=>{
                                    updatequality(currOption.name)
                                    updatePrice(Number($(`#${inputId}`).val()))
                                    }}>
                                    <div className="display-flex-row no-wrap ">
                                    <div className="center-aligned-column">
                                        <i className={`${currOption.name === currValue ?"fas fa-circle":"far fa-circle"}  color-warning`}></i>
                                    </div>
                                        <div className="text-start marginleft10 heading3 font-normal">
                                        {currOption.name}
                                        </div>
                                    </div>

                                    <div className={`input-container marginTop10 full-width no-wrap`}>
                                        <input style={{maxWidth:"initial"}}  id={inputId} type="number" defaultValue={currOption?.perFeetPrice} className="input full-width" onChange={(event:any)=>{updateValues(Number(event.target.value),currOption)}} />
                                    </div>

                                </td>

                            )
                        })}

                        </tr>

                        <tr>
                        <td className="price-heading">Sub total</td>
                        {qualities.map((currOption:any,i:number)=>{
                            return(
                                <td id={`subtotal_${currOption.name}`} key={i} className="rupee-sign">{getFormattedPrice(currOption.totalPrice)}</td>
                            )
                        })}
                        </tr>

                        <tr>

                        <td className="price-heading">Discount</td>
                        {qualities.map((currOption:any,i:number)=>{
                            let inputId = getRandomString(5)
                            return(
                                <td key={i} id={getRandomString(5)} className="text-start price-heading">

                                    <div className={`input-container marginTop10 full-width no-wrap`}>
                                        <input style={{maxWidth:"initial"}}  id={inputId} type="number" defaultValue={currOption.discount} className="input full-width" 
                                        onChange={(event:any)=>{
                                            currOption.discount = Number(event.target.value) 
                                            updateValues(currOption.perFeetPrice,currOption)
                                        }} />
                                    </div>

                                </td>

                            )
                        })}


                        </tr>

                        <tr>
                        <td  className="price-heading">Grand total</td>
                        {qualities.map((currOption:any,i:number)=>{
                            return(
                                <td id={`grandtotal_${currOption.name}`} key={i} className="rupee-sign">{getFormattedPrice(currOption.grandTotal)}</td>
                            )
                        })}
                        </tr>
                        
                        </>
                        
                        :
                        <>
                            <tr>
                                <td className="text-start price-heading" colSpan={4}>Sub total</td>
                                <td className="rupee-sign">{getFormattedPrice(totalPrice)}</td>
                            </tr>

                            <tr>
                                <td className="text-start price-heading" colSpan={4}>Discount</td>
                                <td className="">
                                    <div className={`input-container no-wrap`}>
                                        <input  id="fdbgbtb" type="number" className="input" defaultValue={props.configuration3D?.pricing?.discount} onChange={(event:any)=>{updatePriceWithDiscount(Number(event.target.value))}} />
                                    </div>
                                </td>
                                    
                            </tr>

                            <tr>
                                <td className="text-start price-heading" colSpan={4}>Grand total</td>
                                <td className=" rupee-sign">{getFormattedPrice(finalPrice)}</td>
                            </tr>
                        </>
                        
                        
                        }


                        
                
                    </tbody>
                </table>
            </div>
  );
};

export default PricingDetails;
