import $ from "jquery";
import { Object3D } from "three";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { attachModules, detachModule, detachModules, updateGroupCenter } from "../common";
import { cancelmoduleAnnotationAnimation, updateAnnotationsPosition, updateDragIconPosition } from "./annotations";
import { addonsGroup, camera, controls, dimensions, dragging, group, spacePlannerImagesGroup } from "./customizein3d";
import { CURR_SELECTED_MODULE, updateBoxHelper } from "./raycasting";
import { getDuplicatedSectionalModules, getSectionalModules, getStandaloneModules } from "./helper";
import { waitFor } from "../../methods";
 

export var dragControls:any = null
export var isObjectDragging = false
var startY,endY,startX,endX = 0


var draggableModulesList = []


let groupToAttach = null

export function createDragControls(currCamera:any = null,renderer:any) {
    dragControls = new DragControls( [], currCamera,renderer.domElement );
    dragControls.transformGroup =   true;
    // dragControls.recursive = false

    dragControls.addEventListener( 'drag', function ( event:any ) {
      updateDragIconPosition(group,renderer,camera)
      updateBoxHelper()
    })
    dragControls.addEventListener( 'dragstart', function ( event:any ) {
      controls.enablePan = false
      startX = event.object.position.x
      startY = event.object.position.z
      $(".product-annotations").addClass("visibility-hidden")
      $(".product-annotation").addClass("visibility-hidden")
      dimensions.hideDimensions()
      cancelmoduleAnnotationAnimation()
    })
    dragControls.addEventListener( 'dragend', function ( event:any ) {  
      controls.enablePan = true
      endX = event.object.position.x
      endY = event.object.position.z
      $(".product-annotations").removeClass("visibility-hidden")
      $(".product-annotation").removeClass("visibility-hidden")
      //To resolve the click issue in mobile
      // if((Math.abs(startX-endX) < 0.05 && Math.abs(startY-endY) < 0.05)){
      //   showActionPanel()
      // }
      updateGroupCenter(group)
      dimensions.hideDimensions()
      updateAnnotationsPosition()
    })
    
}


export function makeObjectDraggable(object:any) {
  disableDragging()
  let isAddon = object.isSprite ? true : object?.userData?.configuration?.isAddedAsAddon
  let subCategoryName = object?.userData?.configuration?.subCategory ||  object?.userData?.configuration?.subCategoryName
  let categoryName = object?.userData?.configuration?.categoryName ||  object?.userData?.configuration?.categoryName
  if(object.isSprite){
    groupToAttach = spacePlannerImagesGroup
  }else{
    groupToAttach = group
  }
  
  if(isAddon || subCategoryName === "SetSofas" || subCategoryName === "Chairs" || categoryName === "Cot"){
    let isDuplicatedSectional = object?.userData?.configuration?.isDuplicatedSectional
    draggableModulesList = [object]
    if(!isDuplicatedSectional){
      detachModules(draggableModulesList)
      addonsGroup.attach(object)
      dragging.setObject(object)
    }else{
      draggableModulesList = getDuplicatedSectionalModules()
      detachModules(draggableModulesList)
      attachModules(draggableModulesList,addonsGroup)
      dragging.object = addonsGroup
    }
    
  }else{
    draggableModulesList = getSectionalModules()
    detachModules(draggableModulesList)
    attachModules(draggableModulesList,addonsGroup)
    dragging.object = addonsGroup
  }
  return
  // dragControls.dispose()
  // createDragControls(camera,renderer)
  let objects = dragControls?.getObjects()
  objects.length = 0

  if(CURR_SELECTED_MODULE){
    let module = CURR_SELECTED_MODULE.userData.configuration
    if(module?.allowMovement){
      // scene.attach( object )
      objects.push(object)
    }
  }
}

export function makeWallDraggable(object:Object3D) {
  let objects = dragControls?.getObjects()
  objects.length = 0
  objects.push(object)
}


export async function disableDragging() {
  try {
    detachModules(draggableModulesList)
    attachModules(draggableModulesList,groupToAttach)
    dragging?.resetObject()
    draggableModulesList = []
    return
    let objects = dragControls?.getObjects()
    objects.length = 0
  } catch (error) {
    
  }
  
}  
