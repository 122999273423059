export const Cot_Variants = [
    {
        id:1,
        name:"Single",
        displayName:"",
        options:[],
        description:""
    },
    {
        id:2,
        name:"Twin",
        displayName:"",
        description:""
    },
    {
        id:3,
        name:"Queen",
        displayName:"",
        description:""
    },
    {
        id:4,
        name:"King",
        displayName:"",
        description:""
    }
]

export const Cot_length = [
    {
        id:1,
        name:"72",
        displayName:"",
        description:""
    },
    {
        id:2,
        name:"75",
        displayName:"",
        description:""
    },
    {
        id:3,
        name:"78",
        displayName:"",
        description:""
    }
]

export const Cot_Storage_Options = [
    {
        id:1,
        name:"Hydraulic Storage",
        displayName:"",
        description:""
    },
    {
        id:2,
        name:"Manual",
        displayName:"",
        options:["4 Boxes","2 Doors","Custom"],
        description:""
    },
    {
        id:3,
        name:"Drawer",
        displayName:"",
        options:["Left","Right","Left+Right"],
        description:""
    }
]