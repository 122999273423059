import $ from "jquery";
import React, { useState } from "react";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
 

const SelectOptions: React.FC<{
  product:any
  setCustomizingOptions:(val:Array<any>)=>void
  options:any
}> = (props) => {


  const[selectedOptionIds,setSelectedOptionsIds] = useState([])

 

 
  function toggleSelection(id:number) {
    setSelectedOptionsIds(prevValue=>{
      if(prevValue.includes(id)){
        return prevValue.filter(currValue => currValue !== id)
      }else{
        return [...prevValue,id]
      }
    })
  }


  function Options() {
    return(
      <div className="ion-padding full-height-width bg-color-light">
        <div className="heading1 pc-color-primary font-large header-content text-center padding10">What do you want to customize?</div>
        <div className="display-flex-row ion-padding width90 body-content customization-options" style={{justifyContent:"center",margin:"auto"}}>
          {props.options.map((option:any,index:number)=>{
            return(
              <div key={index} onClick={()=>toggleSelection(option.id)} className={`center-aligned-column item-md item padding10 position-relative white-card shadow-light ${selectedOptionIds.includes(option.id)?" --is-active":""}`}>
                <div className="pointer-none">
                  {/* {option.ionIcon?
                  <div className="ion-padding center-aligned-row"><IonIcon className="font-large icon" icon={option.ionIcon}/></div>
                  :
                  <div className="ion-padding center-aligned-row"><i className={`icon font-large ${option.icon}`}/></div>
                  } */}
                  <div className="center-aligned-row"><img src={option.iconUrl} className={`icon`} style={{height:"5rem"}}/></div>
                  <div className="heading3 font-md-small text text-center">{option.name}</div>
                </div>
                <div className="top-right">
                  {selectedOptionIds.includes(option.id)?
                  <i className="far fa-check-circle select-icon font-large"></i>
                  :
                  <i className="far fa-circle select-icon font-large"></i>
                  }
                </div>
              </div>
            )
          })}
        </div>
        <div className="padding10 center-aligned-column footer-content">
          <Button2
              configObj={{id:""}}
              action={nextStepHandle}
              label={"Next"}
              fontAwesomeIcon={"fas fa-arrow-right color-primary"}
              iconPos="right"
              classNames={"no-shadow button-large button-fixed-width text-center pc-bg-color-primary"}
            />
        </div>
      </div>
    )
  }



  function nextStepHandle() {
    let selectedOptions = props.options.filter(currOption=> selectedOptionIds.includes(currOption.id))
    props.setCustomizingOptions(selectedOptions)
    $("#selectOptionsContainer").fadeOut(500)
    setTimeout(() => {
      $("#productConfiguratorContainer").fadeIn(500)
      $("#ConfigurationSelector_0").fadeIn(200)
    }, 500);
  }

  return (
    <div className="full-height-width" id="selectOptionsContainer">
      <Options/>
    </div>
  );
};

export default SelectOptions;
