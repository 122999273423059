import { addCamera } from "../../controls"
import { View } from "../view"
export var VIEWS_FOR_3D = []

export  var cameraSettingViewData = {
    left:0,
    bottom:0,
    width:0.25,
    height:1,
    aspectRatio:0
}
export var minimapFrameData = {
    left:0,
    bottom:0,
    width:0.25,
    height:1,
    aspectRatio:0
}
export var VIEWS_FOR_2D = []
export var VIEWS_FOR_WALKTHROUGH = []

export var cameraForSettingPosition = null


var perspectiveCamera = null

export function createCameraForRender(camera:any,width:number,height:number) {
    perspectiveCamera = camera
    cameraForSettingPosition = addCamera({width:width,height:height},"orthographic")
}


export function updateCameraSettingViewData(renderType:string,isFullScreenMode:boolean = false) {
    if(renderType === "3d"){
        cameraSettingViewData = {
            left:0,
            bottom:0,
            width:1,
            height:1,
            aspectRatio:0
        }
        VIEWS_FOR_3D = []
        VIEWS_FOR_3D.push(new View(cameraForSettingPosition,cameraSettingViewData))
        let cameraData = {
            left:0.65,
            bottom:0.30,
            width:isFullScreenMode?0:0.30,
            height:isFullScreenMode?0:0.40,
            aspectRatio:4/3
        }
        minimapFrameData = cameraData
        VIEWS_FOR_3D.push(new View(perspectiveCamera,cameraData))
    }
    if(renderType === "2d"){
        cameraSettingViewData = {
            left:0,
            bottom:0,
            width:0.25,
            height:1,
            aspectRatio:0
        }
        VIEWS_FOR_2D = []
        VIEWS_FOR_2D.push(new View(cameraForSettingPosition,cameraSettingViewData))
        let cameraData = {
            left:cameraSettingViewData.width,
            bottom:1-cameraSettingViewData.height,
            width:1-cameraSettingViewData.width,
            height:1,
        }
        VIEWS_FOR_2D.push(new View(perspectiveCamera,cameraData))
    }

    if(renderType === "walkthrough"){
        cameraSettingViewData = {
            left:0,
            bottom:0,
            width:0.12,
            height:0.37,
            aspectRatio:0
        }
        VIEWS_FOR_WALKTHROUGH = []
        VIEWS_FOR_WALKTHROUGH.push(new View(perspectiveCamera,{
            left:0,
            bottom:0,
            width:1,
            height:1,
        }))
        VIEWS_FOR_WALKTHROUGH.push(new View(cameraForSettingPosition,cameraSettingViewData))
    }
}


// if(renderType === "3d"){
//     cameraSettingViewData = {
//         left:0.60,
//         bottom:0.25,
//         width:0.30,
//         height:0.50,
//         aspectRatio:4/3
//     }
//     VIEWS_FOR_3D = []
//     VIEWS_FOR_3D.push(new View(orthoCamera,{
//         left:0,
//         bottom:0,
//         width:1,
//         height:1,
//       }))
//     VIEWS_FOR_3D.push(new View(perspectiveCamera,cameraSettingViewData))
// }



//3d mode full screen toggle code 
// cameraSettingViewData = {
//     left:0,
//     bottom:0,
//     width:isFullScreenMode?1:0.35,
//     height:1,
//     aspectRatio:0
// }
// VIEWS_FOR_3D = []
// VIEWS_FOR_3D.push(new View(cameraForSettingPosition,cameraSettingViewData))
// let cameraData = {
//     left:cameraSettingViewData.width,
//     bottom:1-cameraSettingViewData.height,
//     width:1-cameraSettingViewData.width,
//     height:1,
// }
// VIEWS_FOR_3D.push(new View(perspectiveCamera,cameraData))