import { applicationConfig, getFilteredArray, getObjectByParameter, getUser } from "../methods"

export class createUserForm{
    
    id:number = 0
    username:string = ""
    password:string = ""
    email:string = ""
    clientId:number = 0
    location:string = ""
    userType:string = ""
    businessName:string = ""
    gstin:string = ""
    status:number = 1
    phone:string = ""



    constructor(clientId:number,clientName:string,savedUser:any){

        if(savedUser){
            this.username = savedUser.username
            this.password = savedUser.password
            this.email = savedUser.email
            this.clientId = savedUser.client_id
            this.location = savedUser.location
            this.userType = savedUser.user_type
            this.status =  savedUser.status
            this.id = savedUser.id

            this.businessName = savedUser.business_name
            this.gstin = savedUser.gstin
            this.phone = savedUser.phone
        }

        if(clientId && clientName){
            let client = getObjectByParameter(applicationConfig?.data?.clientsList,"client_name",clientName)
            let totalUsers = getFilteredArray(applicationConfig?.data?.usersList,"client_id",clientId).length 
            this.username = totalUsers === 0 ? clientName : clientName + "_" + (totalUsers + 1)
            this.password = this.username
            this.email = client.email || "NA"
            this.clientId = clientId
        }
    }

    setUserName(name:string){
        this.username = name
    }

    setPassword(password:string){
        this.password = password
    }

    setLocation(location:string){
        this.location = location
    }

    setEmail(email:string){
        this.email = email
    }

    setClientId(clientId:number){
        this.clientId = clientId
    }

    setUserType(userType:string){
        this.userType = userType
    }

    setStatus(value:number){
        this.status = value
    }
   
}