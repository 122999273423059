import { alertCircleOutline, checkmark, listCircleOutline, listOutline, ticket, warningOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { touchDeviceActions } from "../../../../../../../utils/store/crm/crm";
import DropDown from "../../../../../../common/ui-components/filters/DropDown2";
import ControlsTab from "../../../../../../common/ui-components/tabs/ControlsTab";
import ScheduleAppointmentForm from "./ScheduleAppointmentForm";
import TicketCard from "./TicketCard";
import Modal2 from "../../../../../../common/shared-components/Modals/Modal";
import EditLead from "../leads/leadslist/EditLead";
import TicketDetails from "./TicketDetails";
import HorizontalScroll from "../../../../../../common/ui-components/scrollbar/HorizontalScroll";
import { hideComponentLoader, showComponentLoader } from "../../../../../../../utils/UI_methods/global";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import { applicationConfig, stringWithoutWhiteSpace } from "../../../../../../../utils/methods";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
 

 
const TicketListContainer: React.FC<{
  allTickets:any
  currSelectedTime:string
  refreshList:()=>void
  allCalendarEvents:Array<any>
  searchedTicketList:any
  setSearchTicketsList:(val:any)=>void
}> = (props) => {


  const [loadLimit, setLoadLimit] = useState(10);
  const[currTabTickets,setCurrTabTickets] = useState([])

  const[scheduledTickets,setScheduledTickets] = useState([])
  const[otherTickets,setOtherTickets] = useState([])

  const [currTab, setCurrTab] = useState<string>("NewTickets");
  const[currTicket,setCurrTicket] = useState(null)

  const[currActivity,setCurrActivity] = useState("")
  const[isOpenStartActivity,setIsOpenStartActivity] = useState(false)

  const[isEditLeadOpen,setIsEditLeadOpen] = useState(false)
  const[editLeadView,setEditLeadView] = useState("calendar")
  const[currSelectedLead,setCurrSelectedLead] = useState(null)

  var tabItems = [
    {tabName:"NewTickets",
    label:"Open Tickets",
    ionIcon:listOutline,
    count:props.allTickets.filter(currTicket => currTicket.status === 1).length
    },
    {tabName:"ClosedTickets",
    label:"Closed Tickets",
    ionIcon:checkmark,
    count:props.allTickets.filter(currTicket => currTicket.status === 0).length
    }
  ]

  const[ticketTypeTabs,setTicketTabs] = useState(getTicketTabs())

  const[currSelectedTicketType,setCurrSelectedTicketType] = useState(ticketTypeTabs[0].tabName)

  useEffect(()=>{
    setTicketTabs(getTicketTabs())
  },[currTabTickets])

  useEffect(()=>{
    filterTickets(currTab)
 
  },[currTab,props.allTickets,currSelectedTicketType])



  function getTicketTabs() {
    if(applicationConfig?.user?.userType === "Dispatcher"){
      return [{
        tabName:"Installation",
        label:"Installation",
        ionIcon:warningOutline,
        count:filterTicketsWithTicketType(currTabTickets,"Installation").length,
      },]

    }

    return [{
      tabName:"Issue",
      label:"All",
      ionIcon:listCircleOutline,
      count:currTabTickets.length,
    },
    {
      tabName:"IssueWithDeliveredProduct",
      label:"Issue with delivered product",
      ionIcon:warningOutline,
      count:filterTicketsWithTicketType(currTabTickets,"IssueWithDeliveredProduct").length,
    },
    {
      tabName:"Installation",
      label:"Installation",
      ionIcon:warningOutline,
      count:filterTicketsWithTicketType(currTabTickets,"Installation").length,
    },
    {
      tabName:"Update on order",
      label:"Update on order",
      ionIcon:alertCircleOutline,
      count:filterTicketsWithTicketType(currTabTickets,"Update on order").length,
    },]
  }

  function filterTickets(currTab:string) {
    let result = []
    if(currTab === "NewTickets"){
      result = props.allTickets.filter(currTicket => currTicket.status === 1)
      setCurrTabTickets(result)
      result = filterTicketsWithTicketType(result)
      setScheduledTickets(result.filter(currTicket => currTicket.resolution_date !== null))
      setOtherTickets(result.filter(currTicket => currTicket.resolution_date === null))
    }
    if(currTab === "ClosedTickets"){
      result = props.allTickets.filter(currTicket => currTicket.status === 0)
      setCurrTabTickets(result)
      result = filterTicketsWithTicketType(result)
      setScheduledTickets(result)
      setOtherTickets([])
    }
  }



  function updateTabHandle(tabName: string) {
    setCurrTab(tabName)
  }

  function openCalendarHandle(ticket:any) {
      setCurrTicket(ticket)
      setIsOpenStartActivity(true)
      updateTicketStatus(ticket)
  }

  function startActivityHandle(activityName,ticket:any) {
    setCurrTicket(ticket)
    touchDeviceActions(activityName,ticket.mobile)
    setCurrActivity(activityName)
    updateTicketStatus(ticket)
  }

  function openTicketDetailsHandle(ticket:any,view:string) {
    setCurrSelectedLead(ticket)
    setIsEditLeadOpen(true)
    setEditLeadView(view)
    updateTicketStatus(ticket)
  }


  async function updateTicketStatus(ticket:any) {
    if(ticket.is_viewed === 0){
      await CRM.crmApi.ticket.update(ticket.ticket_id,{isViewed:1})
      props.refreshList()
    }
  }

  function TicketTypes() {
    return(
        <>
          {ticketTypeTabs.map((currTab:any,index:number)=>{
            return(
              
              <div className={`marginleft10 display-flex-row no-wrap position-relative item marginTop5 ${currTab.tabName === currSelectedTicketType?" --is-active":""}`} key={index}  onClick={()=>onChangeHandle(currTab.tabName)}>
                <div className='heading3 font-normal text-center text'>{currTab.label}</div>
                  {currTab.count !== undefined?
                  <div className="center-aligned-column">
                    <span id={`Count_${stringWithoutWhiteSpace(currTab.tabName)}`} className="heading4 count-badge badge center-aligned-column padding0 font-ex-small color-white marginleft5" style={{position:"relative",borderRadius:"50%",top:"unset",right:"unset"}}>{currTab.count|| 0}</span>
                  </div>
                  :
                  null
                  }
              </div>
            )
          })}
        </>
    )
  }

  async function onChangeHandle(currTab:string) {
    setCurrSelectedTicketType(currTab)
  }

  function filterTicketsWithTicketType(tickets:Array<any>,ticketType:string = currSelectedTicketType) {
    if(ticketType === "Installation"){
      return tickets.filter(currTicket => currTicket.issue_type === "Installation")
    }else if(ticketType === "Update on order" || ticketType === "Update about Order delivery"){
      return tickets.filter(currTicket => currTicket.issue_type === "Update on order" || currTicket.issue_type === "Update about Order delivery")
    }else if(ticketType === "IssueWithDeliveredProduct"){
      return tickets.filter(currTicket => (currTicket.issue_type !== "Update on order" && currTicket.issue_type !== "Update about Order delivery") && currTicket.issue_type !== "Installation")
    }
    else{
      return tickets
    }
  }

  function SearchResult() {
    return(
      <div className="full-width  full-height bg-color-light hide-scrollbar calendar-header padding10">
          <div className="">
              <Button2
                  configObj={{id:""}}
                  action={()=>{props.setSearchTicketsList([])}}
                  label={"All Tickets"}
                  fontAwesomeIcon={"fas fa-arrow-left color-dark"}
                  iconPos="left"
                  classNames={"no-shadow marginleft5"}
              />
            </div>

            {props.searchedTicketList.length?
            <div className="border marginBottom5">
              {props.searchedTicketList?.map((ticket:any,index:number)=>{
                return(
                  <div className="full-width" key={index}>
                    <TicketCard
                      ticket={ticket}
                      index={index}
                      openCalendarHandle={openCalendarHandle}
                      refreshLeads={props.refreshList}
                      startActivityHandle={startActivityHandle}
                      openLeadDetailsHandle={openTicketDetailsHandle}
                    />
                    </div>
                  )
              })}
            </div>
            :null}

      </div>
    )
  }
 

  return (
    <div className="full-height-width display-flex-column no-wrap">

      <ScheduleAppointmentForm allCalendarEvents={props.allCalendarEvents} isOpen={isOpenStartActivity} ticket={currTicket} refreshLeads={props.refreshList} currActivity={currActivity} setIsOpen={setIsOpenStartActivity} />

      <div className="ticket-type-tabs" style={{width:"27rem"}}>
        <ControlsTab
          configObj={{}}
          tabItems={tabItems}
          currSelectedValue={currTab}
          action={updateTabHandle}
          theme="black"
        />
      </div>

      <Modal2
          isOpen = {isEditLeadOpen}
          Content = {()=>(
              <TicketDetails ticket={currSelectedLead}/>
          )}
          onDismiss = {()=>setIsEditLeadOpen(false)}
          FooterContent={()=>(<></>)}
          heading="Ticket details"
          classNames="medium-y-modal"
          id=""
      />
        {!props.searchedTicketList.length?
          <div className="bg-color-white" style={{padding:"10px 0"}}>
            <HorizontalScroll
              itemsClass="sub-categories-container ticket-types-container"
              Component={TicketTypes}   
              itemsList={ticketTypeTabs}
              id={`ProductSbuCategoriesScrollBar`}     
            />
          </div>
        :null
        }


        

        {props.searchedTicketList.length?
              <SearchResult/>            
        :
        <div className="leads-list-container display-flex-column no-wrap full-height-width position-relative">

        {scheduledTickets?.length || otherTickets.length?
        
          <div className="container overflow-y-scroll"  style={{paddingBottom:"180px"}}>
            {scheduledTickets.length?
            <div className="border marginBottom5">
              <div className="heading3 font-md-small bg-color-ternary padding10"><span className="marginRight5 display-none"><i className="fas fa-check-circle color-primary"></i></span>Scheduled</div>
              {scheduledTickets?.map((ticket:any,index:number)=>{
                return(
                  <div className="full-width" key={index}>
                    <TicketCard
                      ticket={ticket}
                      index={index}
                      openCalendarHandle={openCalendarHandle}
                      refreshLeads={props.refreshList}
                      startActivityHandle={startActivityHandle}
                      openLeadDetailsHandle={openTicketDetailsHandle}
                    />
                    </div>
                  )
              })}
            </div>
            :null}

            {otherTickets.length?
              <div className="border marginBottom5">
              <div className="heading3 font-md-small bg-color-ternary padding10"><span className="marginRight5 display-none"><i className="fas fa-exclamation-circle color-warning"></i></span>Pending scheduling</div>
                {otherTickets?.map((ticket:any,index:number)=>{
                  return(
                    <div className="full-width" key={index}>
                      <TicketCard
                        ticket={ticket}
                        index={index}
                        openCalendarHandle={openCalendarHandle}
                        refreshLeads={props.refreshList}
                        startActivityHandle={startActivityHandle}
                        openLeadDetailsHandle={openTicketDetailsHandle}

                      />
                      </div>
                    )
                })}
              </div>
            :null}
            

          </div>
        :
        <div className="middle" >
          <div className="heading4 font-normal white-card no-shadow" style={{border:"1px dashed var(--op-color-dark)",padding:"25px 35px"}}>No tickets available</div>
        </div>
        }

        

      </div>
        
        }


      
      
      
    </div>
  );
};

export default TicketListContainer;
