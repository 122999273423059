import React, { useEffect, useState } from "react";
import { ActivityEvent } from "../../../../../../../utils/store/crm/calendar";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import TicketListContainer from "./TicketListContainer";
import Header from "./header/Header";
import TicketCard from "./TicketCard";
import { applicationConfig } from "../../../../../../../utils/methods";
 

 
const TicketsList: React.FC<{
}> = (props) => {

  const[ticketsList,setTicketsList] = useState([])
  const[allCalendarEvents,setAllCalendarEvents] = useState([])
  
  const[searchedTicketList,setSearchTicketsList] = useState([])

  const [currSelectedTime,setCurrSeletedTime] = useState("Today")

  const[refresh,setIsReferesh] = useState(Number(new Date()))

  useEffect(()=>{
    CRM.crmApi.ticket.getTickets().then(data=>{
      if(!data.data.error){
        let list = data.data.data
        if(applicationConfig?.userType === "Dispatcher"){
          list = list.filter(currTicket => currTicket.issue_type === "Installation")
        }
        setTicketsList(list)
        setAllCalendarEvents(getEventsList(list))
        CRM.data.ticketsList = list
      }
    }).catch(err=>{
      console.log(err)
    })
  },[refresh])
  



  function refreshList() {
    setIsReferesh(Number(new Date()))
  }

 

  function getEventsList(allTickets:Array<any>) {
    let events = []
    for (const ticket of allTickets) {
      events.push(new ActivityEvent(new Date(ticket.resolution_date),`${ticket.customer_name} - ${ticket.issue_type}`))
    }
    return events
  }  


 
  return (
    <div className="full-height-width">
 
      <Header allCalendarEvents={allCalendarEvents} refreshList={refreshList} setSearchTicketsList={setSearchTicketsList}/>

      <div className="display-flex-row no-wrap full-height">
        <div className="border full-height white-card shadow-light display-flex-column" style={{width:"100%"}}>
          <div className="full-width  full-height bg-color-light hide-scrollbar">
           
            <TicketListContainer 
              allCalendarEvents={allCalendarEvents} 
              refreshList={refreshList} allTickets={ticketsList} 
              currSelectedTime={currSelectedTime}
              searchedTicketList={searchedTicketList}
              setSearchTicketsList={setSearchTicketsList}
            />  
      
          </div>
        </div>
      </div>

    </div>
  );
};

export default TicketsList;
