import React from "react";
import {
  performObjectAction
} from "../../../../../utils/customizein3d/modules/customizein3d";
import Button2 from "../../../ui-components/buttons/Button2";

const Actions: React.FC<{
  configObj: any;
}> = (props) => {
  return (
    <div
      id="objectActionsContainer"
      className="object-actions-container display-none display-flex-column no-wrap space-between background-white">
      <div
        className="white-card shadow-light container"
        style={{ width: "auto" }}>

        <div className={`position-relative zindex30 button2 white-card click-effect no-wrap display-flex-row shadow-light`} onClick={()=>performObjectAction("Duplicate")}>
          <i className={`fas fa-copy color-primary icon pointer-none center-aligned-column`}></i>
          <span className="text hide-in-mobile center-aligned-column pointer-none" style={{margin:"0 0.5rem"}}>Duplicate</span>  
        </div> 

        <div className={`position-relative zindex30 button2 white-card click-effect no-wrap display-flex-row shadow-light`} onClick={()=>performObjectAction("Delete")}>
          <i className={`fas fa-trash color-danger icon pointer-none center-aligned-column`}></i>
          <span className="text hide-in-mobile center-aligned-column pointer-none" style={{margin:"0 0.5rem"}}>Delete</span>  
        </div> 

        <div className={`position-relative zindex30 button2 white-card click-effect no-wrap display-flex-row shadow-light`} onClick={()=>performObjectAction("Rotate")}>
          <i className={`fas fa-undo color-danger icon pointer-none center-aligned-column`}></i>
          <span className="text hide-in-mobile center-aligned-column pointer-none" style={{margin:"0 0.5rem"}}>Rotate</span>  
        </div> 

      </div>
    </div>
  );
};

export default Actions;
