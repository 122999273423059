import { addItemToLocalStorage, getItemFromLocalStorage } from "../cache/localstorage"
import { ProductInstance, TextureInfo } from "../customizer/ProjectConfiguration"
import { applicationConfig, getObjectByParameter } from "../methods"
import { getMoodBoardConfigKey } from "./helper"

export class Configuration{
    
    moodboardId:string = ""
    areaName:string = "Default"
    areaId:number = 0
    images:Array<Image> = []
    

    constructor(moodboardId:string,areaName:string){
        this.moodboardId = moodboardId
        this.areaName = areaName
        if(applicationConfig?.data?.areasList){
            this.areaId = getObjectByParameter(applicationConfig?.data?.areasList,"area_name",areaName)?.id
        }
    }

    addImage(image:Image){
        // let image = new Image(canvasDetails,imageDetails,transform,dimensions)
        this.images.push(image)
        this.updateLocalStorage()
    }

    updateImageConfiguration(imageId:string,transform:any,dimensions:any){
        let image:Image = getObjectByParameter(this.images,"imageId",imageId)
        image.dimensions.updateDimensions(dimensions)
        image.transform.updateTransform(transform)
        this.updateLocalStorage()
    }


    updateLocalStorage(){
        let key = getMoodBoardConfigKey(this.moodboardId,this.areaName)
        addItemToLocalStorage(key,this)
    }

    createConfigFromSavedConfig(savedConfig:any){
        this.moodboardId = savedConfig.moodboardId
        this.areaName = savedConfig.areaName

        for (const image of savedConfig.images) {
            let imageClass = new Image(image.imageId,image.canvas,image.imageReference,image.transform,image.dimensions,image.imageType)
            this.addImage(imageClass)
        }
    }

    removeImage(imageId:string){
        this.images = this.images.filter(image => image.imageId !== imageId)
        this.updateLocalStorage()
    }
}


export class Image{

    imageId:string
    imageType:string

    // productCategory:string
    // productId:string
    // productName:string
    // productSubCategory:string

    // imageReference: ProductInstance | TextureInfo | Uploaded
    imageReference: any
    
    transform:Transform
    dimensions:Dimensions
    canvas:Canvas

    addonsList:any = []


    constructor(imageId:string,canvasDetails:any,imageDetails:any,transform:any,dimensions:any,imageType:string){
        this.imageId = imageDetails.imageId
        this.imageType = imageType
        this.imageId = imageId

        // this.productCategory = imageDetails.productCategory
        // this.productId = imageDetails.productId
        // this.productName = imageDetails.productName
        // this.productSubCategory = imageDetails.productSubCategory

        this.transform = new Transform(transform)
        this.dimensions = new Dimensions(dimensions)
        this.canvas = new Canvas(canvasDetails)

        this.imageReference = this.getImageReference(imageDetails,imageType)
    }

    getImageReference(imageDetails:any,imageType:string){
        if(imageType === "product" || imageType === "module"){
            return new ProductInstance(imageDetails)
        }

        if(imageType === "finish"){
            return new TextureInfo(imageDetails)
        }

        if(imageType === "uploaded"){
            return new Uploaded(imageDetails)
        }
    }




}

class Transform{

    posX:number
    posY:number
    rotation:number

    constructor(transform:any){
        this.posX = transform.posX
        this.posY = transform.posY
        this.rotation = transform.rotation
    }

    updateTransform(transform:any){
        this.posX = transform.posX
        this.posY = transform.posY
        this.rotation = transform.rotation
    }
}

class Dimensions{
    width:number
    height:number

    constructor(dimensions:any){
        this.width = dimensions.width
        this.height = dimensions.height
    }

    updateDimensions(dimensions){
        this.width = dimensions.width
        this.height = dimensions.height
    }

}

class Canvas{
    canvasContainerId:string
    canvasHeight:number
    canvasWidth:number

    constructor(canvasDetails:any){
        this.canvasContainerId = canvasDetails.canvasContainerId
        this.canvasHeight = canvasDetails.canvasHeight
        this.canvasWidth = canvasDetails.canvasWidth
    }
}


class Uploaded{
    imageKey:string

    constructor(imageDetails:any){
        this.imageKey = imageDetails.imageKey
    }
}