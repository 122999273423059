import $ from "jquery";
import React, { useEffect, useState } from "react";
import { getFilteredArray } from "../../../../utils/methods";
 
const AreasForm: React.FC<{
  closeModal: (value: boolean) => void;
  configObj:any
  refreshMoodboardList:(data:Array<any>)=>void
  // selectedAreas:any
  // setSelectedAreas:(value:any)=>void
  // otherAreas:any
  // setOtherAreas:(value:any)=>void
  setCurrProjectDetails:(id:number,name:string)=>void
  setCurrMoodboardId:(id:string)=>void
  formData:any
  setCurrStep:(val:string)=>void

  selectedAreas:any
  setSelectedAreas:(values:any)=>void
  areaCategoryRelationship:Array<any>
}> = (props) => {



  
  const[otherAreas,setOtherAreas]:Array<any> = useState([])
  

  useEffect(()=>{

    let allAreas = props.configObj?.data.areasList.sort((a,b)=>a.area_name<b.area_name?-1:1)
    let areasList = []
    for (const area of allAreas) {
      let array = getFilteredArray(props.areaCategoryRelationship,"area_name",area.area_name)
      if(array.length){
        areasList.push(area)
      }
    }
    setOtherAreas(areasList)
  },[ props.configObj])

  function prevHandle() {
    props.setCurrStep("step1")
  }


 
  const addArea = (event:any,area:any) => {
    props.setSelectedAreas((prevValue:Array<any>)=>{
      return [area,...prevValue]
    })
    // setOtherAreas(prevValue=>{
    //   let array = prevValue.filter(currArea=>currArea.area_name!=area.area_name)
    //   return array
    // })

    $(event.target).toggleClass("--is-active")
  };

  // const removeArea = (area:any) => {
  //   setOtherAreas(prevValue=>{
  //     return [area,...prevValue]
  //   })
  //   setSelectedAreas(prevValue=>{
  //     let array = prevValue.filter(currArea=>currArea.area_name!=area.area_name)
  //     return array
  //   })
  // };


  function checkIfSelected(areaName: string) {
    for (const [i, area] of props.selectedAreas?.entries()) {
      if (area.area_name === areaName) {
        return true;
      }
    }
    return false;
  }

  function reviewHandle() {
    if(!props.selectedAreas.length){
      alert("Please Select Areas")
      return
    }
    props.formData.selectedAreas = props.selectedAreas
    props.setCurrStep("step3")
    return
  }

  

  const OtherAreas = () =>{
    return(
      <div className="padding5" style={{height:"100%"}} >
      {/* <div className="center-aligned-row color-dark padding10 border-bottom">Choose areas</div> */}
      <div className="areas-container overflow-y-scroll" style={{height:"95%"}}>
      {otherAreas?.map((area: any, index: any) => {
        let classNames = [];
        classNames.push("area-item");
       
        let classNamesAreaImage = ["area-image","white-card","shadow-light"]
        if(checkIfSelected(area.area_name)){
          classNamesAreaImage.push("--is-active")
        }
        return (
          <div className={classNames.join(" ")} key={index}>
            <div
              className={classNamesAreaImage.join(" ")}
              onClick={(event) => addArea(event,area)}>
              {checkIfSelected(area.area_name) ? (
                <div className="top-left check-container">
                  <div className="check"></div>
                </div>
              ) : null}
              <div className="title">{area.area_name}</div>
            </div>
          </div>
        );
      })}
    </div>
    </div>
    )
  }

  return(
    <React.Fragment>
      <div className="modal-header capitalize">Select areas</div>
      <div className="modal-body">
      <div className="create-project-areas-container" style={{padding:"0 0",overflowY:"hidden"}}>
          <OtherAreas/>
        </div>
      </div>
      <div className="modal-footer space-between">
        <div><button className="op-btn op-btn-light" onClick={prevHandle}><i className="fa fa-arrow-left color-black"></i> Prev</button></div>
        <div><button className="op-btn op-btn-primary" onClick={reviewHandle}>Next <i className="fa fa-arrow-right"></i></button></div>
      </div>
    </React.Fragment>
  );
};

export default AreasForm;
