import $ from "jquery";
import { Group, Object3D, Vector3 } from "three";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { showToast } from "../../UI_methods/global";
import { hideKitchenDragIcon, showKitchenDragIcon } from "../../kitchenplanner/UI_methods";
import { removeNearestFillers, setSnappingObjectInKitchenPlanner } from "../../kitchenplanner/helper";
import { updateModelBoundingBox } from "../common";
import { getObjectDimensionPosition } from "../modules/helper";
import { CURR_SELECTED_PRODUCT, removeHoverBoxHelper } from "../raycasting";
import { hideAnnotations } from "./UI_methods";
import { updateCurrSelectedProductConfig } from "./actions";
import { hideDistanceAnnotations, setDataForDistanceLines, showDistanceAnnotations, updateDistanceCheckPointsPosition, updateSelectedProductAnnotation } from "./annotations";
import { AREA_MODEL_ORIGIN, IS_VERTICAL_DRAGGING, camera, controls, customizerConfig, dimensions, isKitchenPlannerMode, kitchenPlanner, projectConfiguration, renderer, scene, undo } from "./area3dModel";
import { updateBoxHelper } from "./dragging";
import { checkCollisionWithBoundingBox } from "./helper";
import { setDraggedModelPosition } from "./modules";
import { getAreaFromWallsBoundingBox, isWallFocusMode, resetWallProductParameters, resetWallsColor } from "./walls";

 //Variables for wall dragging 
 let objPosX = 0 
 let objPosZ = 0 
 let objPosY = 0 

export var dragControls = null
export var isObjectDragging = false

let tempGroup = new Group()

export function createDragControls(currCamera:any = null,objects:any = []) {
    dragControls = null
    dragControls = new DragControls( [], currCamera || camera, renderer.domElement );
    dragControls.transformGroup =  true;

    dragControls.addEventListener( 'drag', function ( event:any ) {
        onDrag(event.object)
    })
    dragControls.addEventListener( 'dragstart', function ( event:any ) {
        onDragStart(event.object)
    })
    dragControls.addEventListener( 'dragend', function ( event:any ) {
        onDragEnd(event.object)
    })
}


export function addDragControlsInProduct(object:Object3D) {
    dragControls.dispose()
    createDragControls(camera)
    let objects = dragControls?.getObjects()
    objects.length = 0
    objects.push(object)
}

 
export function disableDragging() {
  // dragging.resetObject()
  let objects = dragControls?.getObjects()
  objects.length = 0
}



export function onDrag(object:any) {
    hideAnnotations()
    $(".hide-on-dragging").css("pointer-events","none")

    if(IS_VERTICAL_DRAGGING){
        object.position.z  = objPosZ     
        object.position.x  = objPosX   
    }else{
        if(object.userData.isWallMounted && object.userData.isAttachedToWall){
            let normal = object.userData.configuration.normal
            if(normal?.z){
                object.position.z  = objPosZ   
            }else{
                object.position.x  = objPosX   
            }
        }else{
            object.position.y = objPosY   
            if(isKitchenPlannerMode){
              kitchenPlanner.clamp.clampObject(object)
            }
        }
    }

    updateModelBoundingBox(object)
    updateDistanceCheckPointsPosition(object)
    updateBoxHelper()

    if(isKitchenPlannerMode){
      kitchenPlanner.updateDragIconPosition()
    }


    if(object.userData.subCategoryName === "Vase" || object.userData.subCategoryName === "Planters"){
      let resultPosY = checkCollisionWithBoundingBox(object)
      if(resultPosY){
        objPosY = resultPosY  
      }else{
        objPosY = AREA_MODEL_ORIGIN.y
        object.userData.isOnProductSurface = false
      }   
    }
  }

  export function onDragStart(object:any) {
      resetWallProductParameters()
      removeHoverBoxHelper(scene)
      isObjectDragging = true
      showDistanceAnnotations()
      setDataForDistanceLines(object)
      dimensions.hideDimensions()
      objPosZ  = object.position.z     
      objPosX  = object.position.x   
      objPosY  = object.position.y
      let detectedArea = getAreaFromWallsBoundingBox(object) 
      customizerConfig.setDragStartArea(detectedArea)
      controls.enabled = false
      hideKitchenDragIcon()
      updateDistanceCheckPointsPosition(object)
      if(isKitchenPlannerMode){
        kitchenPlanner.clamp.resetClampObjectParameters()
        kitchenPlanner.setDetectedObjectOnDrag(null)
        removeNearestFillers(object)
      }
  }

  function clampObject(object:Object3D) {
    let position = getObjectDimensionPosition(null,kitchenPlanner.groupForClampModules).positions
    object.position.clamp(position.min, position.max);
  }

  export function onDragEnd(object:any) {
    $(".hide-on-dragging").css("pointer-events","all")
    // if(!event.object.userData.isProductSelected){
    //   return
    // }
    isObjectDragging = false
    showKitchenDragIcon()
    let detectedArea = getAreaFromWallsBoundingBox(object) 
    if(detectedArea){
    //   setCurrAreaName(detectedArea)
      customizerConfig.setDragEndArea(detectedArea)
      hideDistanceAnnotations()
      updateSelectedProductAnnotation()
      
      updateProductAreaInConfiguration()
    
   
      if(isKitchenPlannerMode){
        if(object.userData.isWallProduct && kitchenPlanner.wallModulePositionY === 0 ){
          let position = new Vector3()
          object.getWorldPosition(position)
          kitchenPlanner.setWallModulePositionY(position.y)
          setSnappingObjectInKitchenPlanner(object)
        }
        kitchenPlanner.undo.add("position",{prevPosition:new Vector3(objPosX,objPosY,objPosZ),
          objectId:object?.uuid,
        })
        // kitchenPlanner.setPositionOfDraggingModule(event.object)
        // kitchenPlanner.updateAddedModulePosition(event.object)
        // snapKitchenProduct(event.object)
      }


      if(!isWallFocusMode || object.userData.isWallMounted){
        setDraggedModelPosition(object,detectedArea)
      }
    
      controls.enabled = true
      undo.add("position",{prevPosition:new Vector3(objPosX,objPosY,objPosZ),
        productInstanceName:object?.userData.productInstanceName,
      })
      updateCurrSelectedProductConfig()
      projectConfiguration.setAreaIsConfigChanged(detectedArea,true)
      resetWallsColor()
    }else{
      showToast("Area not found",2000,"error")
    }
  }


  function updateProductAreaInConfiguration() {
    if(customizerConfig.dragStartArea !== customizerConfig.dragEndArea){
      let productInstance = CURR_SELECTED_PRODUCT.userData.configuration
      projectConfiguration.deleteProductInstance(productInstance.productInstanceName)  
      projectConfiguration.addProduct(productInstance,CURR_SELECTED_PRODUCT)
    }
  }
 
