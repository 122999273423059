import { IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import $ from "jquery";
import { useHistory } from 'react-router';
import { getArrayOfDistinctValues, getUserType } from '../../../utils/methods';
import Modal2 from '../../common/shared-components/Modals/Modal2';
import Categories from '../../common/shared-components/products/Categories';
import Button2 from '../../common/ui-components/buttons/Button2';
import Inventory from '../inventory/Inventory';



const CategoryLayout: React.FC<{
  configObj: any;
  updateCategory:(val:string)=>void
}> = (props) => {


  var history = useHistory()

  const [createNewProduct, setCreateNewProduct] = useState(false);

  useEffect(()=>{
    updateBackToDashboardButtonPosition()
    window.removeEventListener("orientationchange",updateBackToDashboardButtonPosition)
    window.addEventListener("orientationchange",updateBackToDashboardButtonPosition)
  },[])

  function updateBackToDashboardButtonPosition() {
    var elm:any = document.querySelector('#categoryFirstElement');
    if(elm){
      setTimeout(() => {
        $("#backToDashboardButton").css({top:"",left:elm?.offsetLeft +"px"})
      }, 200);
    }
  }

  function AddCardForm() {
    return(
      <Inventory
      currState="Product"
      configObj={props.configObj}
      closeModal={setCreateNewProduct}
      product={null}
      finishId={0}
    />
    )
  }

  function activateSearchMode(products:Array<any>,searchString:string) {
    // props.setProductsList(products)
    props.updateCategory(products[0].category_name)
  }



  function gotoDashboard() {
    history.push("/home")
  }

  function filterProductsWithCategory(categoryName:string) {
    props.updateCategory(categoryName)
    // let category = getFilteredArray(applicationConfig?.data?.productsList,"category_name",categoryName)
    // props.setProductsList(category)
  }

  return (
    <div className="display-flex-column categories-main-wrapper no-wrap full-height-width">

    <Modal2
      isOpen={createNewProduct}
      classNames="large-x-modal"
      onDismiss={setCreateNewProduct}
      id=""
      Content={AddCardForm}
    />

    

    {getUserType()?.toLowerCase()==="admin" || getUserType()?.toLowerCase()==="manager"?
        <div className="bottom-right add-dimension-icon-container">
        <div className="bg-color-primary add-dimension-icon center-aligned-column shadow-medium" onClick={()=>setCreateNewProduct(true)}>
          <IonIcon icon={add} className="font-large color-white" />
        </div>
      </div>
    :null}

    <div className="display-flex-row padding10 no-wrap space-between position-relative">
      <div>
        {/* <div className='left-middle' id="backToDashboardButton">
          <Button2
            configObj={{id:""}}
            action={()=>gotoDashboard()}
            label={"Go back"}
            fontAwesomeIcon={"fas fa-chevron-left color-primary"}
            iconPos="left"
            classNames={"no-shadow bg-color-light margin0 "}
          />
        </div> */}
      </div>
      
      <div className='category-page-heading hide-in-desktop middle'>
        <div className="heading1 text-center filters-container color-black ion-padding">Categories</div>
      </div>
      <div className='center-aligned-column'>
        <div className='hide-in-mobile'>
          {/* <ProductsSearch
            configObj={applicationConfig}
            currSelectedCategory={props.currSelectedCategory}
            activateSearchMode={activateSearchMode}
            filterProductsWithCategory={filterProductsWithCategory}
            filterProductsWithSubCategory={filterProductsWithSubCategory}
            idSuffix={"mainProductsPageCategory"}
          /> */}
        </div>
      </div>
    </div>
    <div className="products-categories-wrapper center-aligned-row overflow-y-scroll fill-available-height">
      <Categories     
        categories={getArrayOfDistinctValues(props.configObj?.data.productsList,"category_name")}
        updateSubCategories={filterProductsWithCategory}
      />
    </div>
  </div>

  );
};

export default CategoryLayout;
