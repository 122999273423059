import $ from "jquery"
import { deselectProduct, kitchenPlanner, selectProduct } from "../customizein3d/area3dmodel/area3dModel"
import { setCurrSelectedProduct } from "../customizein3d/raycasting"

export var isSelectKitchen = false

export function toggleSelectKitchen() {
    if(isSelectKitchen){
        isSelectKitchen = false
        $("#selectKitchenToggle").removeClass("--is-active")
        deselectProduct()
    }else{
        isSelectKitchen = true
        $("#selectKitchenToggle").addClass("--is-active")
        let object = kitchenPlanner.group
        let product = kitchenPlanner.product
        // setCurrSelectedProduct(object,product.originalProductName,product.categoryName,product.subCategoryName)
        setCurrSelectedProduct(object)
        selectProduct()
    }
}

export function hideKitchenDragIcon() {
    $("#kitchenDragIcon").css("pointer-events","none")
}

export function showKitchenDragIcon() {
    $("#kitchenDragIcon").css("pointer-events","all")
}

export function hideKitchen2dModeElements() {
    $(".show-in-kitchen-2d-mode").addClass("display-none")
    $(".hide-in-kitchen-2d-mode").removeClass("display-none")
}

export function showKitchen2dModeElements() {
    $(".hide-in-kitchen-2d-mode").addClass("display-none")
    $(".show-in-kitchen-2d-mode").removeClass("display-none")
}
