import React from 'react';
import { logout } from '../../../../services/api';
import { removeUserSession } from '../../../../utils/methods';
 
const TokenExpiry: React.FC<{
  }> = (props) => {

    function handleLogout() {
        logout()
        .then((response:any) => {
          removeUserSession()
          window.location.href = ""
        })
        .catch((error:any) => {
          // removeUserSession()
          console.log(error)
        });
    }

  return (

    <div   className='top-left welcome-screen display-none full-height-width' id="tokenExpiryScreen">
    <div className='full-height-width'>

      <div className='top-middle' style={{top:"3rem"}}>
        <img src="assets/images/opus.png" alt="" />
      </div>
      <div className='middle full-width ion-padding'>
        <div className='product-details  padding5 hide-in-desktop'>
          <div className='color-black font-large text-center heading1'>Thank you for using Opus product configurator!!</div>
        </div>
        <div className='color-black font-normal marginTop10 center-aligned-column heading1' id="shareItemText"></div>
        <div className='color-dark font-normal marginTop10 text-center center-aligned-column heading4'>This link has expired. Please Ask vendor to re-share the link.</div>
      </div>

      <div className='bottom-middle ion-padding full-width' style={{bottom:"3rem"}}>
        <div className='display-flex-row no-wrap'>

        <div className='display-flex-row no-wrap padding10 full-width space-around'>
          <div className='white-card shadow-medium bg-color-light color-black heading2 click-effect letter-spacing1' style={{padding:"10px 20px"}}>Contact us</div>
        </div>

        <div className='display-flex-row no-wrap padding10 full-width space-around' onClick={handleLogout}>
          <div className='white-card shadow-medium bg-color-primary color-white heading2 click-effect letter-spacing1' style={{padding:"10px 20px"}}>Login</div>
        </div>

        </div>
        
      </div>

    </div>
  </div>
  );
};

export default TokenExpiry;
