import React, { useRef, useState } from "react";
import { hideComponentLoader, showComponentLoader } from "../../../../../utils/UI_methods/global";
import { convertHtmlToImage } from "../../../../../utils/html2canvas/html2canvas";
import { applicationConfig, getObjectByParameter, waitFor } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ProductConfigSummary from "./ProductConfigSummary";
import { printSummary } from "../../../../../utils/customizein3d/area3dmodel/helper";

 
const ProductionOrderCard: React.FC<{
    currCartItem:any
}> = (props) => {
 


    const[product] = useState(getObjectByParameter(applicationConfig?.data?.productsList,"product_id",props.currCartItem.product_id))
    const[configuration] = useState(getConfiguration())

    function getConfiguration(){
        if(props.currCartItem?.configuration_3d){
          return JSON.parse(props.currCartItem.configuration_3d)
        }
        return null
      }

    function HeaderDetails() {
        return(
            <div className="marginTop10">
                <div className="marginTop10">
                <div className="Pricing"></div>
                <table className="table store-quotation-table">
                    <tbody>
                        <tr>
                            <td  colSpan={3} className="">
                                <div className="heading1 font-large uppercase">Pre Production Work order card</div>
                                <div className="heading4 uppercase font-normal color-black">(For customer reconfirmation)</div>
                            </td>
                            <td className="capitalize">
                                <div className="heading1 font-large uppercase">WOC NO.</div>
                                <div className="heading3">32547</div>
                            </td>
                        </tr>

                        
                        <tr>
                            <td className="">Print Date & Time</td>
                            <td className="">04/01/2024</td>
                            <td className="">Customer Name</td>
                            <td className="capitalize">{STORE.activeCustomer?.customerName}</td>
                        </tr>
                        <tr>
                            <td className="">Customer Mobile</td>
                            <td className="">{STORE.activeCustomer?.mobile}</td>
                            <td className="">Model Name</td>
                            <td className="capitalize">{}</td>
                        </tr>
                        <tr>
                            <td className="">Size</td>
                            <td className="">Other</td>
                            <td className="">Order Form Number</td>
                            <td className="capitalize">2082/1</td>
                        </tr>
                        <tr>
                            <td className="">Quality of Product</td>
                            <td className="store-color-primary">{configuration?.quality}</td>
                            <td className="">Warranty</td>
                            <td className="capitalize"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            </div> 
        )
    }

    
    async function DownloadAsPdf() {
        showComponentLoader("storeLoader")
        await waitFor(3000)
        await convertHtmlToImage("cartItemsWrapperCheckout")
        hideComponentLoader("storeLoader")
    }

    function printContent() {
        printSummary("cartItemsWrapperCheckout")
        return
        var contentToPrint = document.getElementById("cartItemsWrapperCheckout");
        var contentCopy = contentToPrint.cloneNode(true);
        var printWindow = window.open('', '_blank');
        printWindow.document.body.appendChild(contentCopy);
        printWindow.print();
      }
    
 
  return (
     <div className="full-height-width bg-color-ex-light  overflow-y-scroll" style={{paddingBottom:"5rem"}}>
        <div  id="cartItemsWrapperCheckout"  className="quote-container border ion-padding">
            <div className=""> <HeaderDetails/> </div>
            <ProductConfigSummary configuration={null}  product={product} configuration3D={configuration} />
        </div>

        <div className="actions container center-aligned-column ion-padding">
                <div className="display-flex-row no-wrap ">
                    <Button2
                        configObj={{id:""}}
                        action={printContent}
                        label={"Download Pdf"}
                        fontAwesomeIcon={"fas fa-download color-warning"}
                        iconPos="right"
                        classNames={"no-shado cart-button bg-color-warning"}
                    />
                </div>
        </div>

        
        
     </div>   
  );
};

export default ProductionOrderCard;
