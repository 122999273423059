import { IonIcon } from "@ionic/react";
import {
  close,
  colorPalette
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { deleteCollections } from "../../../../services/api";
import { getCollectionLabel } from "../../../../utils/methods";
import { removeNewBadgeClassFromElement } from "../../../../utils/UI_methods/global";
import Loader from "../../../common/shared-components/main-ui/Loader";
import DeleteLink from "../../../common/ui-components/links/DeleteLink";
import ShareLink from "../../../common/ui-components/links/sharelink/ShareLink";
import Toast from "../../../common/ui-components/notification/Toast";
import Actions from "../../../common/ui-components/tabs/Actions";

const MoodboardActions: React.FC<{
  configObj: any;
  setOpenMoodboardAction: (value: boolean) => void;
  setCurrProjectDetails:(id:number,name:string)=>void
  setCurrMoodboardId:(id:string)=>void
  currMoodboardDetails:any
}> = (props) => {
  const history = useHistory();
  const [loaderId] = useState<any>("sharelinkLoader");
  const [collectionLabel, setCollectionLabel] = useState("");


  const [actions,setActions] = useState([]);

  useEffect(() => {
    if (props.currMoodboardDetails) {
      let label = getCollectionLabel(props.currMoodboardDetails?.moodboard_id)
      setActions([
          {
            name: "ContinueDesigning",
            label: label,
            iconClassNames: ["color-danger"],
            icon: colorPalette,
            action: gotomoodboard,
          },
      ])
    }
  }, [props.currMoodboardDetails]);
 
 
  

  function gotomoodboard() {
    let projectId = props.currMoodboardDetails?.project_id
    let moodboardId = props.currMoodboardDetails?.moodboard_id
    // Set products for the project
    let object = props.configObj?.data.moodboardList.filter(data=>{return String(data.moodboard_id) === String(moodboardId)})
    let projectName = object[0].project_name
    props.setCurrProjectDetails(projectId,projectName)
    props.setCurrMoodboardId(moodboardId)
    removeNewBadgeClassFromElement("moodboardCard_"+moodboardId)

    props.configObj.setModuleFilter("moodboard","lastEdited",moodboardId)

    // props.setOpenProjectAction(false)
    history.push('/edit-moodboard')
  }

  function removeCollection() {
    deleteCollections(props.currMoodboardDetails?.moodboard_id).then(data=>{
      console.log(data)
      alert("Deleted")
    }).catch(err=>{
      console.log(err)
    })
  }
  
 
  return (
    <React.Fragment>
        <Toast/>

        <div className="project-action-container position-relative  product-action-container">
        <IonIcon
          className="modal-close-icon zindex30"
          icon={close}
          ios={close}
          onClick={() => props.setOpenMoodboardAction(false)}></IonIcon>
         
        <div className="background-white full-height-width">

        
        <div className="project-details  position-relative center-aligned-row border-bottom background-white product-details ion-padding"
        style={{ height: "60%" }}>

      
            <div className="top-middle text-center">
              <span className="heading1 font-normal text-center">{props.currMoodboardDetails?.name}</span>
          </div>

          <div className="top-left zindex30 padding10">
            <DeleteLink
              configObj={props.configObj}
              action={removeCollection}
            />
            </div>

            <div className="bottom-right zindex30 padding10">
              <ShareLink
                shareItemId={props.currMoodboardDetails?.moodboard_id}
                loaderId = {loaderId}
                moduleName="collections"
                textClass="color-black"
                isShareButton={false}
              />
            </div>

         
            
        

              <div className="display-flex-row no-wrap full-height">
                {/* <div className="full-height center-aligned-column">
                  <i className="fas font-large color-black fa-chevron-left"></i>
                </div> */}
                <div className="product-image-container position-relative full-height ion-padding">
                  <img id="projectActionimage" alt="" className=""/>
                  <Loader
                      id={loaderId}
                      loaderType="dots"
                      data={{}}
                      className="fade-background-loader"
                    />
                </div>
                {/* <div className="full-height center-aligned-column">
                  <i className="fas font-large color-black fa-chevron-right"></i>
                </div> */}
              </div>
            </div>
          <div className="product-actions" style={{ height: "40%" }}>
            <Actions configObj={props.configObj} actionList={actions} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MoodboardActions;
