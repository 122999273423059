import { CrmApi } from "../../../services/crmApis"
import { applicationConfig, getUser } from "../../methods"

export var CRM:CrmConfig = null


export class CrmConfig{

    crmApi:CrmApi = new CrmApi()

    data = new Data()

    constructor(){
        CRM = this
    }
  
    
}


class Data{

    salesStages = []
    activityTypes = []
    users = []
    leadsList = []
    unAssignedleadsList = []
    activitiesList = []
    visitorsList = []
    customersList = []
    citiesList = []
    ticketsList = []
    customerTicketsList = []

    constructor(){
        
    }


    async fetch(){
        let clientId = null
        if(applicationConfig?.user?.userType.toLowerCase() === "manager"){
          clientId = applicationConfig.clientId
        }
        const promises = [CRM.crmApi.common.getActivityTypes(),
            CRM.crmApi.common.getSalesStages(),
            CRM.crmApi.common.getUsers()
            ,CRM.crmApi.leads.getUnassignedLeads(),
            CRM.crmApi.common.getCities()
            ,CRM.crmApi.activity.getActivities()
            ,CRM.crmApi.customers.getCustomersList()
            ,CRM.crmApi.customerCreatedTicket.getTickets(),
            CRM.crmApi.leads.getLeads(clientId,applicationConfig?.user?.userId),
            CRM.crmApi.ticket.getTickets()
        ]
        return await Promise.all(promises).then(data=>{
            this.activityTypes = data[0].data.data || []
            this.salesStages = data[1].data.data || []
            this.users = data[2].data.data || []
            this.unAssignedleadsList = data[3].data.data || []
            this.citiesList = data[4].data.data || []
            this.activitiesList = data[5].data.data || []
            this.customersList = data[6].data.data || []
            this.customerTicketsList = data[7].data.data || []
            this.leadsList = data[8].data.data || []
            this.ticketsList = data[9].data.data || []
            if(applicationConfig?.userType === "Dispatcher"){
                this.ticketsList = this.ticketsList.filter(currTicket => currTicket.issue_type === "Installation")
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}