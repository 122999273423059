import { IonCheckbox, IonIcon } from '@ionic/react';
import { link, logOutOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { expandControlsPanel } from '../../../../../../utils/customizein3d/common';
import { getNextArea } from '../../../../../../utils/customizer/helper';
import { checkIfLastElement, getArrayOfDistinctValuesFromObject, getCurrentConfigurationFromLocalStorage, getFilteredArray, getObjectByParameter, waitFor } from '../../../../../../utils/methods';
import { closeCategories, getMoodBoardConfigKey, openCategories } from '../../../../../../utils/moodboard/helper';
import { expandSubCategories } from '../../../../../../utils/moodboard/UI_methods';
import {showToast } from '../../../../../../utils/UI_methods/global';
import "../../../../../style/controls/customizeLeftControls.css";
import { configuration } from '../../../../../../utils/moodboard/moodboard';

const Status: React.FC<{
  configObj:any
  currArea:string
  projectAreaCategories:any
  categoriesStatus:any
  areasList:Array<any>
  updateCurrArea: (areaName: string, areaId: number) => void;
}> = (props) => {


  async function updateCategory(category:string) {
    props.configObj.functions.moodboard.updateTabValue("productsContainer","products")
    expandSubCategories()
    openCategories()
    await waitFor(500)
    props.configObj.functions.moodboard.updateCategory(category)
    expandControlsPanel(function() {
      props.configObj.functions.moodboard.updateCategory(category)
    })
  }

 
      function SelectedCategories(){
        let currAreaCategories = getFilteredArray(props.projectAreaCategories,"area_name",props.currArea)
        return (
          <React.Fragment>
                  {currAreaCategories?.map((category: any, index: number) => {
                  let isAdded = false
                  if(props.categoriesStatus[configuration?.areaName]?.confirmed?.includes(category.category_name)){
                    isAdded = true
                  }
                  return (
                    <tr key={index} id={`Category_${category.category_name}`} onClick={()=>updateCategory(category.category_name)}>
                      <td className={isAdded?"font-small center-aligned-row":"font-small opacity05 center-aligned-row"}> {category.category_name} <i className='fas fa-external-link-alt font-small center-aligned-column marginleft5 color-dark'></i> </td>
                        <td className="">
                        <IonCheckbox slot="end" color="primary" mode='md' checked={props.categoriesStatus[props.currArea]?.confirmed?.includes(category.category_name)} disabled={true} />
                        {/* {props.categoriesStatus[props.currArea]?.confirmed?.
                      includes(category.category_name)?<div className="bg-color-light status-container"><i className="fa fa-check color-success" area-hidden="true"/></div>
                    : <div className="status-container bg-color-light"><i className="fa fa-times color-danger" area-hidden="true"/></div>} */}
                        </td>
                      </tr>
                  );
                })}
            
          </React.Fragment>
        );
      }

      function Additionalcategories(){
        return(
          <React.Fragment>
          {props.categoriesStatus[props.currArea]?.additional?.map((category:any,index:number)=>{
            return(
              <tr key={index} id={`Category_${category}`}>
              <td className="font-small center-aligned-row"> {category}<i className='fas fa-external-link-alt font-small center-aligned-column marginleft5 color-dark'></i></td>
                <td className="">
                <IonCheckbox slot="end" color="primary" mode='md' checked={true} disabled={true} />
                {/* <div className="bg-color-light status-container"><i className="fa fa-check color-success" area-hidden="true"/></div> */}
                </td>
              </tr>
            )
        })}
        </React.Fragment>
        )
      }

      
      const [nextStep, setNextStep] = useState("Next");
   
  
      async function gotoNextArea() {

 
    
        let sortedAreasList = getArrayOfDistinctValuesFromObject(props.areasList,"area_name");
        sortedAreasList.sort((a, b) => (a > b ? 1 : -1));
      
        // if (checkIfLastElement(sortedAreasList, props.configObj.areaName)) {
        //   setIsOpenSummary(true);
        //   setNextStep("Finalize");
        //   return;
        // }
    
        setNextStep("Next");
    
        let nextArea = getNextArea(sortedAreasList, props.configObj.areaName,"moodboard");
        let area = getObjectByParameter(
          props.configObj?.data.areasList,
          "area_name",
          nextArea
        );
        // console.log(area,props.areasList,nextArea,props.currSelectedArea,props.configObj)
        // props.setActiveItems((prevItems) => {
        //   if (!prevItems.includes(nextArea)) {
        //     return [...prevItems, nextArea];
        //   }
        //   return prevItems;
        // });
    
        let key = getMoodBoardConfigKey(props.configObj?.getMoodboardId(),props.configObj.areaName);
        let config = getCurrentConfigurationFromLocalStorage(key);
        if(!config){
          showToast("No item added",2000,"error")
          return
        }
    
        // showComponentLoader("moodboardSaveLoader")
        // updateLoaderProgress("moodboardSaveLoader",1,1,"Saving..")
        // await waitFor(200)
        // hideComponentLoader("moodboardSaveLoader")
        // await waitFor(100)
    
        props.configObj.functions.setCurrSelectedStep(nextArea);
        props.updateCurrArea(area.area_name, area.id);
    
    
      }
    
   

  
 

  return (
    <React.Fragment>
      
    <div id="" className="full-width white-card shadow-light overflow-y-scroll" style={{height:"80%"}}>
      {/* <div className="text-center heading1 center-aligned-column font-small">Selected products for <span className="color-danger">{props.currArea}</span> </div> */}
      <div className="full-width" style={{overflow:"auto"}}>
        <div id="moodboardAddedItemsList" className="products-info-container full-width position-relative overflow-y-scroll background-white">
        <table className="table ">
              <thead className="light-background">
                <tr>
                  <th className="" style={{fontWeight:"normal",background:"var(--ion-color-dark)",color:"#ffffffe6"}}>Product</th>
                  <th className="" style={{fontWeight:"normal",background:"var(--ion-color-dark)",color:"#ffffffe6"}}>Selected ?</th>
                </tr>
              </thead>
              <tbody>
            <SelectedCategories/>
            <Additionalcategories/>
            </tbody>
            </table>
        </div>

        {/* <div className="center-aligned-row marginTop10 border bg-color-primary" style={{padding:"8px 10px"}} onClick={gotoNextArea}>
                <span className="heading1 font-small color-white click-effect marginleft5 center-aligned-column">Save & {nextStep}</span>
                <span className="center-aligned-column marginleft5"><i className="fas fa-chevron-right color-white"></i></span> 
            </div> */}
      </div>
      
    </div>
    </React.Fragment>

    
  );
};

export default Status;
