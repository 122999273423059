import { IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { applicationConfig, getObjectByParameter } from "../../../../../utils/methods";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import { addItemToLocalStorage } from "../../../../../utils/cache/localstorage";

 
const ScanProduct: React.FC<{
    configObj:any
    setIsLoadedPageConfiguration:(val:any)=>void
    isLoadedPageConfiguration:any
    setIsLogin:(val:boolean)=>void
}> = (props) => {


    const[product,setProduct] = useState(null)
    const parameters:any = useParams();
    const history:any = useHistory();

    useEffect(()=>{
        let productName = parameters.productName
        if(props.configObj){
            let productsList = applicationConfig.data.productsList
            let product= getObjectByParameter(productsList,"product_name",productName)
            if(product){
                setProduct(product)
                addItemToLocalStorage("currProduct",product)
                history.push("/customizein3d")
            }
        }
    },[props.configObj])
   
 
    return (
        <IonPage>
            {!product?
                <div>Product Not Found</div>
            :null}
        </IonPage>
    );
};

export default ScanProduct;
 
