import $ from "jquery";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Calendar as ReactCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getWindowWidth } from "../../../../../../../utils/UI_methods/global";
import { getFilteredArray, getObjectArrayOfDistictValues, waitFor } from "../../../../../../../utils/methods";
import { ActivityEvent, getOnlyDateFromTimestamp } from "../../../../../../../utils/store/crm/calendar";
import { getFormatedDate } from "../../../../../../../utils/store/crm/crm";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
import Modal from "../../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import "../../../../../../style/store/crm/calendar.css";
import { isTouchDevice } from "../../../../../../../utils/customizer/helper";
import TouchDeviceCalendar from "./TouchDeviceCalendar";

const Calendar: React.FC<{
    lead:any
    activityEvent:ActivityEvent
    isSetEventMode:boolean
    callbackAfterDateSelect?:()=>void
    eventsList?:any
    isShowRemarks?:boolean
}> = (props) => {

    const localizer = momentLocalizer(moment)

    //So that we can toggle it
    const [currSelectedEvent,setCurrSelectedEvent] = useState(props.activityEvent?.start?props.activityEvent : null) 

    const [activitiesList] = useState(CRM.data.activitiesList)

    const [eventsList,setEvents] = useState([])
    const [distinctEventsList,setDistinctEventsList] = useState([])
    const today = new Date()

    
    const [showConfirmAlert,setShowConfirmAlert] = useState(false)
    const [calendarHeight,setCalendarHeight] = useState(0)


    const [isTouch] = useState(isTouchDevice())


    useEffect(()=>{
        if(!props.eventsList){
            //By default create for actvities 
            let allEvents = CRM.data.activitiesList
            setEventsList(props.isSetEventMode?allEvents:getFilteredArray(allEvents,"lead_id",props.lead?.lead_id))
        }else{
            setEvents(props.eventsList)  
        }
        setTimeout(() => {
            updateUI()
            updateCalendarHeight()
        }, 500);
    },[])

    useEffect(()=>{
        if(eventsList.length){
            let distinct = getObjectArrayOfDistictValues(eventsList,"timestamp")
            setDistinctEventsList(distinct)
        }
    },[eventsList])


    function updateCalendarHeight() {
        let height:any = $(".modal-body").height() || 0
        if(typeof height === "number"){
            setCalendarHeight(Math.ceil(height))
        }
    }

    function updateUI() {
        if(props.isSetEventMode && props.activityEvent){
            $(".calender-info-container").remove()

            let addButton = $("<div></div>",{
                class:"bottom-right button2 click-effect white-card marginBottom5 no-shadow font-ex-small heading1 border-black calender-info-container"
            }).html('Add')
            .on("click",(event)=>showConfirmation(event))
            
            $(".rbc-day-bg").append(addButton)
            // $(".rbc-day-bg").append(addButton)
        }
    }

    

    function addActiveClass(event:any) {
        let element = $(event.target)
        let dayCell = $(event.target).parent().parent()

        $(".rbc-day-bg").removeClass("--is-active")
        $(".select-date-icon").addClass("fa-plus-square color-dark")
        $(".select-date-icon").removeClass("fa-minus-circle color-danger")



        element.removeClass("fa-plus-square color-dark")
        element.addClass("fa-minus-circle color-danger")
        dayCell.addClass("--is-active")
        dayCell.trigger("click")
    }


    function setEventsList(activities:any) {
        for (const activity of activities) {
            setEvents(prevValues=>{
                return [...prevValues,new ActivityEvent(getOnlyDateFromTimestamp(new Date(activity.start_date)),activity.activity_type)]
            })
        }
    }                                        
  


    const handleSelectSlot = useCallback(({ start, end }) => {
        if(!props.isSetEventMode){
            return
        }
        try {
            const newEvent = new ActivityEvent(start,props.activityEvent?.title)
            if(currSelectedEvent){
                if(currSelectedEvent.start.getTime() === start.getTime()){
                    setEvents(prevValues=>{
                        return prevValues.filter(currEvent => currEvent.id != currSelectedEvent.id)
                    })
                    setCurrSelectedEvent(null)
                }else{
                    setEvents(prevValues=>{
                        return [...prevValues.filter(currEvent => currEvent.id != currSelectedEvent.id),newEvent]
                    })
                    setCurrSelectedEvent(newEvent)
                    // currSelectedEvent = newEvent
                    props.activityEvent.start = start
                }
            }else{
                setCurrSelectedEvent(newEvent)
                props.activityEvent.start = start
                setEvents(prevValues=>{
                    return [...prevValues,newEvent]
                })
            } 
        } catch (error) {
            
        }
        
       
    },[setEvents])


    
    const handleSelectEvent = useCallback((event) => {

    },[])

    function CustomEventBlock(props:any) {
        let startDate = props.event.start
        let callsLength = activitiesList.filter(currActivity => currActivity.start_date === startDate.getTime() && currActivity.activity_type?.includes("call")).length
        let homeVisitLength = activitiesList.filter(currActivity => currActivity.start_date === startDate.getTime() && currActivity.activity_type?.includes("home")).length
        let factoryVisitLength = activitiesList.filter(currActivity => currActivity.start_date === startDate.getTime() && currActivity.activity_type?.includes("factory")).length
        return(
            <div className="padding0 custom-date-cell position-relative hide-in-mobile">
                    <div className="top-left padding0" style={{top:"-15px",left:"2px"}}>
                        <div className="position-relative display-flex-row">

                            {/* <div className="padding5 center-aligned-row"> 
                                <span className="position-relative ">
                                    <i className="fas fa-phone-square-alt color-dark font-normal"></i> 
                                    <span className="center-aligned-column heading3 padding0 top-right font-ex-small color-black" style={{right:"-8px",top:"-5px",width:"12px",height:"12px",borderRadius:"50%",border:"0px solid #000"}}> {callsLength} </span>
                                </span>
                            </div> */}

                            <div className="padding5 center-aligned-row marginleft10"> 
                                <span className="position-relative">
                                    <i className="far fa-calendar-alt color-dark font-normal"></i> 
                                    <span className="center-aligned-column heading2 padding0 top-right font-ex-small color-black bg-color-light " style={{right:"-12px",top:"-5px",width:"14px",height:"14px",borderRadius:"50%",border:"0px solid #000"}}> {callsLength + homeVisitLength + factoryVisitLength} </span>
                                </span>
                            </div>

                        </div>
                    </div>
            </div>
        )
    }


    function EventBlockForTickets(props:any) {
        let startDate = props.event.start
        let eventsList = CRM.data.ticketsList.filter(currTicket => currTicket.resolution_date === startDate.getTime()).length
        return(
            <div className="padding0 custom-date-cell position-relative hide-in-mobile">
                    <div className="top-left padding0" style={{top:"-15px",left:"2px"}}>
                        <div className="position-relative display-flex-row">

                            <div className="padding5 center-aligned-row"> 
                                <span className="position-relative ">
                                    <i className="far fa-calendar-alt color-dark font-normal"></i> 
                                    <span className="center-aligned-column heading3 padding0 top-right font-ex-small color-black" style={{right:"-8px",top:"-5px",width:"12px",height:"12px",borderRadius:"50%",border:"0px solid #000"}}> {eventsList} </span>
                                </span>
                            </div>

                        </div>
                    </div>
            </div>
        )
    }

    const dayPropGetter = (date) => {
        let tomorrow = new Date();
        tomorrow.setDate(today.getDate() - 1);
        const isDisabled = date < tomorrow;
        return {
          className: isDisabled ? 'disabled-date-cell' : '',
          disabled: isDisabled,
        };
    };
 
    const onView = useCallback((newView) =>{
        if(props.isSetEventMode){
            setTimeout(() => {
                updateUI()
            }, 500);
        }
    },[])

    function confirmDateSelection() {
        props.callbackAfterDateSelect()
    }

    async function showConfirmation(event:any) {
        // if(isTouchDevice()){
        //     let element = $(event.target)
        //     element.parent().trigger("click")
        // }
        await waitFor(200)
        setShowConfirmAlert(true)
    }


    function ConfirmationFooter() {
        return(
            <div className="display-flex-row no-wrap">
            <Button2
                configObj={{id:""}}
                action={()=>{setShowConfirmAlert(false)}}
                label={"Go back"}
                fontAwesomeIcon={"fas fa-arrow-right color-dark"}
                iconPos=""
                classNames={"no-shadow bg-color-light"}
            />

            <Button2
                configObj={{id:""}}
                action={()=>{confirmDateSelection()}}
                label={"Confirm"}
                fontAwesomeIcon={"fas fa-arrow-right color-dark"}
                iconPos=""
                classNames={"no-shadow bg-color-primary"}
            />
            </div>
        )
    }

    function ConfirmationForm() {
        return(
            <div className="full-height-width bg-color-light center-aligned-column">
                <div className="width70 ion-padding">
                    <div className="heading4 font-medium color-black text-center capitalize padding10">
                        {props.lead?.customer_name} {props.lead?.customer_name?"'s":""} {props.activityEvent?.title} Scheduling on {getFormatedDate(currSelectedEvent?.start.getTime())}
                    </div>

                    {props.isShowRemarks !== false?
                        <div className="form-label-input-container ion-margin-top">
                            <div className="label-container ">
                            <div className="label">
                                Remarks
                            </div>
                            </div>
                            <div className="input-container">
                                <textarea className="input" id="closeActivityRemarks" style={{height:"75px"}} />
                            </div>
                        </div>
                    :null}

                    
                </div>
            </div>
        )
    }

 
    return (
    <div className="full-height-width padding10 position-relative overflow-y-scroll">

        

        <Modal
            isOpen = {showConfirmAlert}
            onDismiss = {()=>setShowConfirmAlert(false)}
            heading={"Confirm!!!"}
            FooterContent={ConfirmationFooter}
            classNames="small-x-modal"
            id=""
            Content={()=>(
                <ConfirmationForm/>
            )}
        >
        </Modal>

        {!isTouch ? 
        <ReactCalendar
            localizer={localizer}
            events={props.isSetEventMode?distinctEventsList:eventsList}
            components={props.isSetEventMode?{
                eventWrapper: props.eventsList?EventBlockForTickets:CustomEventBlock,  
            }:{}}
            dayPropGetter={dayPropGetter}
            style={{ height: getWindowWidth() >= 1920 ? 700 : 500 }}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            defaultView={true?"month":"agenda"}
            onRangeChange={onView}
            selectable
        />
        
        :
        <TouchDeviceCalendar  
            lead={props.lead}
            activityEvent={props.activityEvent}
            callbackAfterDateSelect={props.callbackAfterDateSelect}
            isShowRemarks={props.isShowRemarks}
        />
        
        }


    </div>
    );
};

export default Calendar;
