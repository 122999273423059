import { IonIcon } from '@ionic/react';
import { colorPalette } from 'ionicons/icons';
import React from 'react';
import { enableConfigureMode, isPortraitMode } from '../../../../utils/customizein3d/modules/UI_methods';
import Button2 from '../../ui-components/buttons/Button2';


  
  
const ConfigureModelToggle: React.FC<{
  configObj:any
  heading:string
  desktopModeAction:()=>void
}> = (props) => {


  function enable() {
    if(isPortraitMode()){
      enableConfigureMode()
    }else{
      props.desktopModeAction()
    }
  }


  return (
    <>
    <div className="marginBottom5 customization-customize-button center-aligned-row">
    {/* <Button2
        configObj={props.configObj}
        action={enable}
        label={"Customize"}
        fontAwesomeIcon={"fas fa-cog color-danger"}
        iconPos="left"
        classNames={"shadow-medium border"}
    /> */}

    <div className="summary-link tooltip action-link-container display-none">
        <div  className={`shadow-medium pointer configure-button display-flex-row op-btn op-btn-primary- font-small heading1 letter-spacing }`}
          style={{
            padding: "0.7rem 1.2rem",
            borderRadius: "20px",
          }}
          onClick={enable}>
          <span className="color-white center-aligned-column heading1 rupee-sign">
            {/* <i className="fas fa-cog color-danger center-aligned-column"></i> */}
            <IonIcon className="color-white font-large center-aligned-column" icon={colorPalette}></IonIcon>
          </span>
          <span className="color-white center-aligned-column marginleft5">
            {props.heading}
          </span>
        </div>
    </div>

    <Button2
      configObj={{id:"customizeButton"}}
      action={enable}
      label={"Customize"}
      fontAwesomeIcon={"fas fa-cog color-primary"}
      iconPos="left"
      classNames={"shadow-light  button-medium button-rounded store-bg-color-primary"}
    />
    </div>
    </>
  );
};

export default ConfigureModelToggle;
