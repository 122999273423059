import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import CreateStock from "../CreateStock/CreateStock";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import Search from "./Search";
 
const Header: React.FC<{
  refreshList:()=>void
  setSearchedLeadsList:(values:any)=>void
}> = (props) => {

  const[isOpen,setIsOpen] = useState(false)


  
  function LeftContent() {
    return(
      <div className="display-flex-row">

      <div className="center-aligned-column">
        <Button2
          configObj={{id:""}}
          action={()=>{setIsOpen(true)}}
          label={"Create Item"}
          fontAwesomeIcon={"fas fa-edit color-dark"}
          iconPos="left"
          classNames={"no-shadow margin0 bg-color-primary"}
        />
      </div>

      {/* <div className="center-aligned-column marginleft10">
        <Button2
          configObj={{id:""}}
          action={()=>{}}
          label={"View by & Sort by"}
          fontAwesomeIcon={"fas fa-chevron-down color-dark"}
          iconPos="right"
          classNames={"no-shadow"}
        />
      </div>

      <div className="center-aligned-column marginleft5">
        <Button2
          configObj={{id:""}}
          action={()=>{}}
          label={"Sync"}
          fontAwesomeIcon={"fas fa-sync color-dark"}
          iconPos="left"
          classNames={"no-shadow"}
        />
      </div> */}
      </div>
    )
  }




  return (
    <div className="full-width position-relative display-flex-row space-between border-bottom leads-header" style={{padding:"10px 0"}}>

      <CreateStock refreshList={props.refreshList} isOpen={isOpen} setIsOpen={setIsOpen}/>
      
        <LeftContent/>

        <div className="middle hide-in-mobile">
        </div>

        <div className="right-content">
          <div className="display-flex-row no-wrap">
            <Search/>
          </div>
        </div>

       
        

      </div>  
  );
};

export default Header;
