import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import '../../theme/variables.css';
import '../style/admin/product.css';
import '../style/animations.css';
import '../style/controls/controls.css';
import '../style/cssvariables.css';
import "../style/elevate/elevate.css";
import '../style/ionic.css';
import '../style/layout.css';
import '../style/measurement-tool/measurement.css';
import "../style/store/store.css";
import '../style/style.css';
import '../style/ui-components/borders.css';
import '../style/ui-components/buttons.css';
import '../style/ui-components/design.css';
import '../style/ui-components/form.css';
import '../style/ui-components/tables.css';

import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import {
  API_INIT,
  apiGatwayAuthentication,
  createSession,
  getAllCategories,
  getAllMaterialTypes,
  getAllSubCategories,
  getForegroundSavedConfig,
  getIpAddress,
  getMaterialCompanies,
  getObjectMaterialTypes,
  getProductModules,
  getProductsList,
  getTextures,
  logout
} from '../../services/api';
import PublicRoute from '../../utils/PublicRoute';
import { hideComponentLoader } from '../../utils/UI_methods/global';
import Authorization from '../../utils/authorization/authorization';
import { Logging } from '../../utils/logging/logs';
import {
  generateSessionId,
  getToken,
  getUser,
  getUserType,
  globalMethodsInit,
  logger,
  removeUserSession,
  showTokenExpiryScreen,
  updateUserSession
} from '../../utils/methods';
import { applicationConfig } from '../../utils/moodboard/applicationConfig';
import { Store, getFilteredProductForDemo } from '../../utils/store/storeConfiguration';
import ElevateCategoryProducts from '../admin/Elevate/ElevateCategoryProducts';
import ElevateProductDetails from '../admin/Elevate/productDetails/ElevateProductDetails';
import VariantCreater from '../admin/products/store/variant-creator-3d/VariantCreater3D';
import Login from '../login';
import SharedProductViewer from '../share/SharedProductViewer';
import Success from '../admin/products/store/quotation/approval/Success';
import ElevateHomepage from '../admin/Elevate/homepage/ElevateHomepage';

/* Core CSS required for Ionic components to work properly */
/* Basic CSS for apps built with Ionic */
/* Optional CSS utils that can be commented out */

const ElevateContainer: React.FC = () => {

  const [clientName,setClientName] = useState<string>("");
  const [CLIENT_ID,setClientId] = useState<number>(0);


  const [BASE_PATH] = useState<string>("http://13.233.90.128/model-viewer/model-viewer/");

 
 
  const [allCategories, setAllCategories]:Array<any> = useState([]);
  const [allSubCategories, setAllSubCategories]:Array<any> = useState([]);
  const [productsList, setProductsList]:Array<any> = useState([]);
 

  //Authentication and authorization
  const [isLogin, setIsLogin] = useState<boolean>(false);
  // const[token,setToken]:any = useState(null)
  

  //config objects
  const[applicationConfigObject,setApplicationConfigObject] = useState<applicationConfig>(null)
  const[storeConfig,setStoreConfig] = useState<any>(null)

  const[mainLoaderId] = useState<string>("mainLoader")
  const[isLoadedLogs,setIsLoadedLogs] = useState(false)


  var logs = null

  useEffect(()=>{
    let user = getUser()

   
    //Set is share mode false 
    if(user){
      if(user.userType === "admin"){
        updateUserSession(false,null)
      }
    }
    
  },[])




  useEffect(() => {
    loadData()
    logger?.info("elevate","elevate",`Elevate Session Start`)
    // return () => {
    //   isDataLoaded = true
    // }
  // },[isLogin,shareToken]);
},[applicationConfigObject]);


async function loadData() {
  if(!applicationConfigObject){
    return
  }
  let isDataLoaded = false

  let token:string = getToken()!

  if((token && !isDataLoaded) || true){
    // tokenVerification(token).then(async (data)=>{
      await apiGatwayAuthentication()
      //Check if user is online
      // if(!isError){
        let clientId = 60
        setClientId(60)  
        setClientName("OVL")
        applicationConfigObject.clientId = clientId
        applicationConfigObject.clientName = "OVL"
        API_INIT({clientId:clientId,shareItemId:0,moduleName:null})

        let userType = "admin"
        let moduleName = null
        let shareItemId = null

        let authorization = new Authorization(userType,moduleName,shareItemId)

        let promiseArray = [getProductsList(),getAllCategories(),getAllSubCategories(),
          getProductModules(),getMaterialCompanies(),getAllMaterialTypes()
          ,getObjectMaterialTypes(),getTextures(),getForegroundSavedConfig()]
        // storeConfig.data.fetch()

        Promise.all(promiseArray)
        .then(function (apidata) {
          if(!isDataLoaded){
            // let products = filterProductsWithCategoryType(data.username,apidata[0])
            let allProductsList = apidata[0]
            let products = getFilteredProductForDemo(apidata[0])
            
            applicationConfigObject.data.productsList = products
            applicationConfigObject.data.allProductsList = allProductsList

            applicationConfigObject.data.categories = apidata[1]
            applicationConfigObject.data.subCategories = apidata[2]
            applicationConfigObject.data.productModules = apidata[3]
            
            applicationConfigObject.data.materialCompaniesList = apidata[4]
            applicationConfigObject.data.allMaterialTypes = apidata[5]

            //For 3d configuration
            applicationConfigObject.data.objectMaterialTypes = apidata[6]
            applicationConfigObject.data.materials = apidata[7]
            applicationConfigObject.data.customizerSavedConfigs = apidata[8]

            setIsLogin(true)
            if(!window.location.pathname.toLowerCase().includes("customizein3d")){
              hideComponentLoader(mainLoaderId)
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // }
    
    // }).catch(err=>{
    //   console.log(err)
    //   hideComponentLoader(mainLoaderId)
    //     handleLogout()
    // })
  }else{
      hideComponentLoader(mainLoaderId)
      handleLogout()
  }
}




  function handleLogout() {
    if(getUserType() === "customer"){
      showTokenExpiryScreen()
    }else{
      logout()
      .then((response:any) => {
        removeUserSession()
      })
      .catch((error:any) => {
        console.log(error)
      });
    }
  }



 
  useEffect(()=>{
    let configObj:any = new applicationConfig(CLIENT_ID,"",0,0,'','',[],{})
    let store:any = new Store()
    configObj?.setBasePathAssets(BASE_PATH)
    configObj.setUser(getUser())
    setStoreConfig(store)
    setApplicationConfigObject(configObj)
  },[])

  
  useEffect(()=>{
    // let configObj:any = new applicationConfig(CLIENT_ID,clientName,0,0,'','',[],{})

    //Create config object for the application which will contain the data and other 

    if(applicationConfigObject){
      setLogs()
      globalMethodsInit(applicationConfigObject,logs)
    }
  },[allCategories,allSubCategories,productsList,isLogin,applicationConfigObject])
 


    function setLogs() {
      if(!isLoadedLogs){
        let sessionId = generateSessionId(32)
        logs = new Logging("testuser",sessionId)
        getIpAddress().then(ipInfo=>{
          saveSessionToDatabse(sessionId,ipInfo.ipAddress)
        }).catch(err=>{
          console.log(err)
          saveSessionToDatabse(sessionId,"")
        })
        globalMethodsInit(applicationConfigObject,logs)
      }
      setIsLoadedLogs(true)
    }

    function saveSessionToDatabse(sessionId:string,ipAddress:string) {
        createSession(sessionId,ipAddress).then(data=>{
          const insertId = data.data.data.id
          applicationConfigObject.setSessionId(insertId)
          logs.setSessionDatabaseId(insertId)
          logs.info("login","session",`START SessionId: ${sessionId}`)
        }).catch(err=>{
          console.log(err)
          logs.error("login","session",`START unable to create session:`)
        })
    }


    function setLoginTrue(){
    }
  

  return (

    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>

          <PublicRoute path="/login" component={()=>(<Login
            setLoginTrue = {setLoginTrue}
          />)} exact={true} />

             
          <Route 
            path="/"
            component={()=>(
              <ElevateHomepage
                configObj = {applicationConfigObject}
                isLogin={isLogin}
              />
            )}
            exact={true}
          />


          <Route 
              path="/furnitures/:category"
              component={()=>(
                <ElevateCategoryProducts
                  configObj = {applicationConfigObject}
                  isLogin={isLogin}
                />
              )}
              exact={true}
          />

          <Route 
              path="/furnitures/:category/:productId"
              component={()=>(
                <ElevateProductDetails
                  configObj = {applicationConfigObject}
                  isLogin={isLogin}
                />
              )}
              exact={true}
          />

          <Route 
            path="/customizein3d"
            component={()=>(
              <VariantCreater
                configObj = {applicationConfigObject}
                isLogin={isLogin}
              />
            )}
            exact={true}
          />

          <Route 
            path="/customizein3d/:productName"
            component={()=>(
              <VariantCreater
                configObj = {applicationConfigObject}
                isLogin={isLogin}
                isElevateMode={true}
              />
            )}
            exact={true}
          />

            <Route 
              path="/success"
              component={()=>(
                <Success
                  
                />
              )}
              exact={true}
            />


          <Route
            path="/product-viewer"
            component={()=>(
              <SharedProductViewer
                configObj={applicationConfigObject}
              />
            )}
            exact={true}
          />
 

          {/* <Route exact path="/" render={() => <Redirect to="/" />} /> */}
          <Redirect to="/" />

        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default ElevateContainer;
