import { IonFab, IonFabButton, IonIcon, IonModal, IonPage } from "@ionic/react";
import { add } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { sideBarToggle } from "../../../../utils/methods";
import Header from "../../../common/shared-components/main-ui/Header";
import MainSideBar from "../../../common/shared-components/main-ui/MainSideBar";
import ThemeSetting from "../../../common/shared-components/main-ui/ThemeSetting";

const MainLayout: React.FC<{
  configObj: any;
  // FilterHeaderContent:any
  CardsContainer:any
  HeaderLeftContent:any
  HeaderMiddleContent:any
  contentWrapperId:string
}> = (props) => {
 
  //Function to remove project after deleting

  useEffect(()=>{
    setTimeout(() => {
      sideBarToggle()
    }, 100);
  },[])


  return (
    <IonPage>
      <div className="page position-relative full-height-width">
        <div className="display-flex-row no-wrap full-height-width">
          <MainSideBar 
          configObj={props.configObj}
          />
          {/* <ThemeSetting /> */}
          <div className="header-content-wrapper" style={{ padding: "0" }}>
            <Header
              configObj={props.configObj}
              componentMiddle={props.HeaderMiddleContent}
              componentLeft={props.HeaderLeftContent}
            />
            <div id={props.contentWrapperId} className="main-content-wrapper position-relative hide-scrollbar light-background padding10">
              
            <div className=" background-white position-relative overflow-hidden full-height-width">
              <props.CardsContainer/>
            </div>

            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default MainLayout;
