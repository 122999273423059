import { isTouchDevice } from "../customizer/helper"
import $ from "jquery"

  var startY,endY = 0
  var animationDuration = 200


  var lastScrollTop = 0

  var element = null
  var margin = 100

  var mobileMargin = 0
  
  //Horizontal slider
  var slider = null
  let isDown = false;
  let startX;
  let scrollLeft;

  export async function bounceEffect(event:any) {


    element = getElement(event)

    if(!element){
      return
    }

    let box = createBox()

    let scrollTop = element.scrollTop()
    // console.log(Math.ceil(element[0]?.clientHeight + scrollTop),element[0]?.scrollHeight)

    //scroll down effect ...ceil or floor value making diff of 1 -- post bottom scroll check 
    if(Math.abs(Math.ceil(element[0]?.clientHeight + scrollTop) - element[0]?.scrollHeight) <= 1 ){
      // return if scrolling up
      if(event.deltaY < 0 ){
        return
      }

      if(!isTouchDevice()){
        desktopEffect(box)
      }
    }

    //For scroll up effect
    if(scrollTop == 0 ){
      if(event.deltaY > 0){
        return
      }
      element.css("margin-top",margin+"px")
      setTimeout(() => {
        element.css("margin-top","0px")
      }, animationDuration);
    }else{
      element.css("margin-top","0px")
    }

  }


  function mobileEffect(box:any) {
    if(!element){
      return
    }
    if(!element.find(".overflow-message-container").length){
        element.append(box)
    }
    
    startBottomEffect(box)


  }

  function getElement(event:any) {
      let ele =  $(event.target)
      //Ensure the overflow element
      if(!ele.hasClass("overflow-y-scroll")){
        ele = $(event.target).parents(".overflow-y-scroll")
      }

      return ele
  }

  function desktopEffect(box:any) {
    if(!element){
      return
    }
    //Skip adding box if already added 
    if(!element.find(".overflow-message-container").length){
      element.append(box)
    }

    startBottomEffect(box)

    setTimeout(() => {
        endBottomEffect(box)
    },animationDuration); 
  }



  function startBottomEffect(box:any) {
    if(!element){
      return
    }
    box.animate({"height":"+=" + margin + "px"},{
        duration:animationDuration,
        step:function() {
            element.scrollTop(element[0]?.scrollHeight)
        }
    })
  }

  function endBottomEffect(box:any) {
    if(!element){
      return
    }
    box.animate({"height":"-=" + margin + "px"},{
        duration:animationDuration,
        complete:function() {
          box.remove()
        }
    })
  }

  function createBox() {
    return $("<div></div>",{
        class:"full-width center-aligned-column color-dark font-small overflow-message-container"
      }).html("<div class='color-dark padding10 text-center heading3    font-small'></div>")

      return $("<div></div>",{
        class:"full-width center-aligned-column color-dark font-small overflow-message-container"
      }).html("<div class='middle text-center heading1 font-normal'>No more items...</div>")
  }


  export function startBounceEffect(event:any) {
    startY = event.touches[0].clientY
  }


  export function moveBounceEffect(event:any) {
    if(!element){
      return
    }
    endY = event.touches[0].clientY
    element = getElement(event)
    let box = createBox()
    let scrollTop = element.scrollTop()
    //scroll down effect ...ceil or floor value making diff of 1 -- post bottom scroll check 
    if(Math.abs(Math.ceil(element[0]?.clientHeight + scrollTop) - element[0]?.scrollHeight) <= 1 ){
      // return if scrolling up
      if(startY - endY < 0){
        return
    }

      if(isTouchDevice()){
        mobileEffect(box)
      }
    }

     //For scroll up effect
    if(scrollTop == 0 ){
        if(event.deltaY > 0){
        return
        }
        element.css("margin-top",margin+"px")
        setTimeout(() => {
        element.css("margin-top","0px")
        }, animationDuration);
    }else{
        element.css("margin-top","0px")
    }

  }


  export function endBounceEffect(event:any) {
    // startY = event.touches[0].clientX
    if(!element){
      return
    }
    element = getElement(event)
    let box = element.find(".overflow-message-container")
    endBottomEffect(box)
  }

  export function createBounceEffectEvents(event:any) {
    if(!isTouchDevice()){
      event.preventDefault()
      element = getElement(event)
      if(element?.length){
        element[0]?.addEventListener("wheel",bounceEffect)
        element[0]?.addEventListener("touchstart",startBounceEffect)
        element[0]?.addEventListener("touchmove",moveBounceEffect)
        element[0]?.addEventListener("touchend",endBounceEffect)
      }
    }
      
  }

  export function enableHorizontalSlider(id:string) {
    slider = document.querySelector("#"+id);
    if(slider){
      slider?.addEventListener('mousedown', (e:any) => {
        isDown = true;
        slider?.classList.add('active');
        startX = e.pageX - slider?.offsetLeft;
        scrollLeft = slider?.scrollLeft;
      });
      slider?.addEventListener('mouseleave', () => {
        isDown = false;
        slider?.classList.remove('active');
      });
      slider?.addEventListener('mouseup', () => {
        isDown = false;
        slider?.classList.remove('active');
      });
      slider?.addEventListener('mousemove', (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider?.offsetLeft;
        const walk = (x - startX) * 0.4; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }

  export function onPointerDownSlider(event:any,slider:any) {
    // let slider = event.current
    isDown = true;
    slider?.classList.add('active');
    startX = event.pageX - slider?.offsetLeft;
    scrollLeft = slider?.scrollLeft;
  }

  export function onPointerUpSlider(event:any,slider:any) {
    // let slider = event.current
    isDown = false;
    slider?.classList.remove('active');
    
  }

  export function onPointerMoveSlider(event:any,slider:any) {
    // let slider = event.current
    if(!isDown) return;
    // event.preventDefault();
    const x = event.pageX - slider?.offsetLeft;
    const walk = (x - startX) * 0.8; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
    
  }


  export function rippleEffectOnClick(event:any) {
    try {
      let element = $(event.target)
      let classes = element.attr("class")
      const ripple = $('<div>').addClass(`${classes.includes("primary")?'ripple-effect color-primary':classes.includes("bg-color-light")?"ripple-effect color-dark":'ripple-effect'}`);
      const size = Math.max(element.outerWidth(), element.outerHeight());
      ripple.css({
        width: size,
        height: size,
        top: event.clientY - element.offset().top - size / 2,
        left: event.clientX - element.offset().left - size / 2
      });
      element.append(ripple);

      setTimeout(() => {
        ripple.remove();
      }, 500);
    } catch (error) {
      
    }
  }


  export function shadowEffectOnClick(event:any) {
    try {
      let element = $(event.target)
      element.addClass('clicked');
      setTimeout(() => {
        element.removeClass('clicked');
      }, 500);
    } catch (error) {
      
    }
  }

  

  export function tapEffectOnClick(event:any) {
    try {
      let element = $(event.target)
      let classes = element.attr("class")
      const ripple = $('<div>').addClass(`tap-button`);
      element.append(ripple);

      setTimeout(() => {
        ripple.remove();
      }, 500);
    } catch (error) {
      
    }
  }





 