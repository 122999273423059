import React from "react";
 

 
const CustomerCard: React.FC<{
  customer:any
  index:number
}> = (props) => {

    
  return (
    <div className="full-width padding5 lead-card-container" key={props.index}>

      <div className={`white-card pointer position-relative no-shadow`}>

        
        <div className="display-flex-row ">

          <div className="padding5 name-badge-container">
            <div className="capitalize name-badge center-aligned-column white-card no-shadow">
              {props.customer.customer_name.substring(0,2)}
            </div>
          </div>

          <div className="customer-details center-aligned-column padding5 marginleft5">

            <div className="contact-details ">

              <div className="display-flex-row item">
                <span className="center-aligned-column"><i className="far fa-id-card icon color-dark"></i></span>
                <span className="center-aligned-column heading2 capitalize name color-black text  marginleft5">{props.customer.customer_name}</span>
              </div>

              <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column"><i className="fas fa-mobile-alt icon color-dark"></i></span>
                <span className="center-aligned-column text  marginleft5">{props.customer.mobile}</span>
              </div>

              <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column"><i className="far fa-envelope icon color-dark"></i></span>
                <span className="center-aligned-column text text-ellipsis marginleft5">{props.customer.email}</span>
              </div>

            </div>
          </div>

        </div>
        
    

        <div className={`top-right options-container center-aligned-column full-height`}>
        </div>
      </div>
    </div>
    
  );
};

export default CustomerCard;
