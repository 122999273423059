import React, { useEffect, useState } from "react";
import { getAllAreas, getAreacategoryRelationship } from "../../../../services/api";
import AreaCategoryForm from "../../projects/createproject/AreaCategoryForm";
import FinalActions from "./Actions";
import AreasForm from "./AreasForm";
import MoodboardDetailsForm from "./MoodboardDetailsForm";
import Review from "./Review";
 
const CreateMoodboard: React.FC<{
  closeModal: (value: boolean) => void;
  configObj:any
  refreshMoodboardList:(data:Array<any>)=>void
  // selectedAreas:any
  // setSelectedAreas:(value:any)=>void
  // otherAreas:any
  // setOtherAreas:(value:any)=>void
  setCurrProjectDetails:(id:number,name:string)=>void
  setCurrMoodboardId:(id:string)=>void
}> = (props) => {

  // var formData:any = {}

  const[step,setCurrStep] = useState("step1")
  const[formData]:any = useState({})

  const[selectedAreas,setSelectedAreas]:Array<any> = useState([])
  const [areaCategoryRelationship, setAreaCategoryRelationship] = useState([]);
  const [areas, setAreas]:any = useState([]);
  const [selectedCategories,setSelectedCategories ]:any = useState({});


  useEffect(() => {
    let promiseAreas = getAllAreas();
    let promiseAreaCategoryRel = getAreacategoryRelationship();
    Promise.all([promiseAreas,promiseAreaCategoryRel])
      .then((data) => {
        setAreas(data[0]); 
        setAreaCategoryRelationship(data[1])
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

 

  return(
    <React.Fragment>
      {step.toLocaleLowerCase() === "step1"?
      <MoodboardDetailsForm
        configObj={props.configObj}
        setCurrStep={setCurrStep}
        formData={formData}
      />
        
      :

      step.toLocaleLowerCase() === "step2"?

      <AreasForm
        configObj={props.configObj}
        closeModal={props.closeModal}
        refreshMoodboardList={props.refreshMoodboardList}

        setCurrMoodboardId={props.setCurrMoodboardId}
        setCurrProjectDetails={props.setCurrProjectDetails}
        formData={formData}
        setCurrStep={setCurrStep}
        selectedAreas={selectedAreas}
        setSelectedAreas={setSelectedAreas}
        areaCategoryRelationship={areaCategoryRelationship}
      />

      :

      step === "Summary"?

      <Review
        formData={formData}
        setCurrStep={setCurrStep}
        setCurrMoodboardId={props.setCurrMoodboardId}
        setCurrProjectDetails={props.setCurrProjectDetails}
        configObj={props.configObj}
        refreshMoodboardList={props.refreshMoodboardList}

        selectedAreas={selectedAreas}
        selectedCategories={selectedCategories}

      />

    :

    step.toLocaleLowerCase() === "step3"?
         
        <AreaCategoryForm 
          selectedAreas={selectedAreas}
          selectedCategories = {selectedCategories}
          setSelectedCategories={setSelectedCategories}
          updateCurrentState={setCurrStep}
          currStep={""}
          projectForm={null}
        />
    :

    step === "options"?

    <FinalActions
      formData={formData}
      setCurrMoodboardId={props.setCurrMoodboardId}
      setCurrProjectDetails={props.setCurrProjectDetails}
      configObj={props.configObj}
      refreshMoodboardList={props.refreshMoodboardList}
    />

    :
      null
    }
    </React.Fragment>
  );
};

export default CreateMoodboard;
