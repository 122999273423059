import React, { useEffect, useState } from "react";
import Button2 from "../../../common/ui-components/buttons/Button2";
import { IonModal } from "@ionic/react";
import { STORE } from "../../../../utils/store/storeConfiguration";
import QRcode from "../../../common/modules/customizein3d/canvas/QRcode";
import { applicationConfig } from "../../../../utils/methods";
import { isTouchDevice } from "../../../../utils/customizer/helper";
import { hideComponentLoader, showComponentLoader } from "../../../../utils/UI_methods/global";
import { getShareLink } from "../../../../services/api";
 

const ArAction: React.FC<{
}> = (props) => {
 
  const [isOpen, setIsOpen] = useState(false);
  const [url,setUrl] = useState("");

 
  useEffect(()=>{
    if(isOpen){
      // setUrl(STORE.modelViewer.getVariantUrl(STORE.productConfiguration.variant.variantName))
    }
  },[isOpen])


  async function generateModelLink() {
    showComponentLoader("mainLoaderSpinner")
    let  data={
      moduleName:"product-viewer",
      shareItemId:[0],
      collectionNames:JSON.stringify({url:STORE.modelViewer.getVariantUrl(STORE.productConfiguration.variant.variantName)}),
      clientId:applicationConfig?.clientId,
      clientName:applicationConfig?.clientName,
      tokenValidity: 1/24 // default 1 hr
    }
    getShareLink(data).then(data=>{
      console.log(data)
      let shareLink = window.location.origin + "/product-viewer?token=" + data.data.data.token
      setUrl(shareLink)
      setIsOpen(true)
    }).catch(err=>{
      console.log(err)
    })
    hideComponentLoader("canvasLoader")
  }



  return (
    <>
    <IonModal
        id=""
        cssClass="project-steps-modal"
        onDidDismiss={() => setIsOpen(false)}
        isOpen={isOpen}>
          <>
          {url?
            <QRcode 
              configObj={applicationConfig}
              url = {url} 
            />
          :null}
          </>
        
      </IonModal>
      {!isTouchDevice()?
        <Button2
            configObj={{id:""}}
            action={()=>{generateModelLink()}}
            label={"View In Your Space"}
            fontAwesomeIcon={"color-danger"}
            iconPos="left"
            classNames={"shadow-medium no-border margin0 "}
            svgUrl="assets/icon/svg/ar.svg"
        />
      :null}
      
    </>
   
  );
};

export default ArAction;
