import { IonAlert, IonIcon } from "@ionic/react";
import { addCircleOutline, arrowUndo, downloadOutline, settingsOutline, text, trashOutline } from "ionicons/icons";
import React, { useState } from "react";
import { arrangeClickHandle } from "../../../../../../utils/moodboard/UI_methods";
import { updateImagePriority } from "../../../../../../utils/moodboard/layouts/UI_methods";
import {
  addNewPage, confirmEmptyCanvas, copyProduct,
  downloadCanvas, removeProduct, undo
} from "../../../../../../utils/moodboard/moodboard";
import { addText } from "../../../../../../utils/moodboard/text";
  
  const CanvasFooter: React.FC<{
    configObj:any
  }> = (props) => {

    const [showAlertDeleteProduct, setShowAlertDeleteProduct] = useState(false);
 
   
    return (
      <React.Fragment>

<IonAlert
        isOpen={showAlertDeleteProduct}
        mode="ios"
        onDidDismiss={() => setShowAlertDeleteProduct(false)}
        cssClass="my-custom-class"
        header={"Confirm!"}
        message={"Delete <strong>product</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              removeProduct();
            },
          },
        ]}
      />
      
       <div className="left-bottom full-width zindex30 moodboard-canvas-actions-container" id="canvasActionContainer">
          <div className="display-flex-row full-width space-between no-wrap">
          
            <div className="canvas-action full-width">
              <div className="actions display-flex-row space-between full-width pointer no-wrap">

                  <div className="display-flex-row auto-arrange-images" onClick={arrangeClickHandle}>
                    <span className="center-aligned-column pointer-none">
                      <IonIcon className="font-medium color-dark" icon={settingsOutline} />
                    </span>
                    <span className="heading3 pointer-none center-aligned-column marginleft5 color-dark">Arrange</span>
                  </div>

                  <div className="middle" >
                    
                    <div className="product-actions-container  display-flex-row">
                      <div className="white-card click-effect shadow-light display-flex-row copy-product" onClick={copyProduct} style={{padding:"5px 10px"}}>
                        <span><i className="fas fa-copy color-primary pointer-none font-small"></i></span>
                        <span className="heading2 pointer-none hide-in-mobile font-small marginleft5 center-aligned-column color-dark">Copy</span>
                      </div>

                      <div className="white-card click-effect marginleft5 shadow-light remove-product display-flex-row" onClick={()=>{setShowAlertDeleteProduct(true)}} style={{padding:"5px 10px"}}>
                        <span><i className="fas fa-trash color-danger pointer-none font-small"></i></span>
                        <span className="heading2 hide-in-mobile font-small pointer-none marginleft5 center-aligned-column color-dark">Delete</span>
                      </div>

                      <div className="white-card click-effect marginleft5 shadow-light mark-main-image display-flex-row" onClick={updateImagePriority} style={{padding:"5px 10px"}}>
                        <span><i className="fas fa-arrow-up color-primary pointer-none font-small"></i></span>
                        <span className="heading2 hide-in-mobile font-small pointer-none marginleft5 center-aligned-column color-dark">Mark as main</span>
                      </div>

                    </div>
                  </div>

                  <div className="display-flex-row">

                    <div className="display-flex-row undo-product" onClick={undo} style={{position:"fixed",right:"16px",top:"12px"}}>
                      <span className="center-aligned-column ">
                        <IonIcon className="font-normal color-danger" icon={arrowUndo} />
                      </span>
                      <span className="heading2 center-aligned-column marginleft5 hide-in-mobile color-dark">Undo</span>
                    </div>

                    <div className="display-flex-row add-text" onClick={addText}>
                      <span className="center-aligned-column ">
                        <IonIcon className="font-small color-dark" icon={text} />
                      </span>
                      <span className="heading3 center-aligned-column marginleft5 hide-in-mobile color-dark">Add text</span>
                    </div>

                    <IonIcon className="font-medium marginleft10 color-dark download-canvas" icon={downloadOutline} onClick={downloadCanvas} />
                    <IonIcon className="font-medium marginleft10 color-dark reset-canvas" icon={trashOutline} onClick={confirmEmptyCanvas}/>
                    <IonIcon className="font-medium marginleft10 color-dark add-page" icon={addCircleOutline} onClick={addNewPage}/>
                  </div>
                  
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  
  export default CanvasFooter;
  