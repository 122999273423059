import $ from "jquery";
import { BoxGeometry, Euler, Plane, Quaternion, Vector2, Vector3 } from "three";
import { convert3DpointsTo2D } from "../common";
import { Floorplan, wallThickness } from "./floorplan";
import { convert3dPointsToFeet } from "./helper";


var mouseVector = new Vector3();
var rendererDimensions = new Vector2()

    


var isSetStartPoint:boolean = false

var startPoint3d:Vector3 = new Vector3()
var endPoint3d:Vector3 = new Vector3()
 
var closeButton:any = null

var startPoint2d:any = new Vector2()
var endPoint2d:any = new Vector2()

var movableDot:any = null
var distanceLine:any = null
var distanceText:any = null

var planeForRaycasting:Plane = new Plane(new Vector3(0, 1, 0))
var target:Vector3 = new Vector3()

export var wall2dPointsForTracing = []
export var currWallDimensionsForScale = {feet:0,inch:0,distance:0}

const minValueForDistanceLine = 12 
const minValueForDistanceText = 40  

const snappingAngles = [-Math.PI,-Math.PI/2,-Math.PI/3,-Math.PI/4,-Math.PI/6,0,Math.PI/6,Math.PI/4,Math.PI/3,Math.PI/2,Math.PI]

var floorplanner:Floorplan = null

export function drawWallsInit(floorplanObject:Floorplan) {
    floorplanner = floorplanObject
}

export function createVertexAtClickedPoint(x:any,y:any){
    if(isSetStartPoint){
        setEndPointsPositions(x,y)
        let distance = getDistanceBetweenTwoPoints(startPoint2d,endPoint2d)

        if(floorplanner.isTracingFloorplanMode || true){
            const clonnedDistanceText =  distanceText.clone()
            const clonnedWall =  distanceLine.clone()
            clonnedWall.addClass("temp-wall")
            clonnedDistanceText.addClass("temp-wall")
            $("#floorplanSvgLines").append(clonnedWall)
            $(floorplanner.element).append(clonnedDistanceText)
        }
        
        if(distance >= 0.5){
            floorplanner.createWallAndVertices(startPoint3d,endPoint3d)
            const newX = distanceLine.attr("x2")
            const newY = distanceLine.attr("y2")
            setStartPointsPositions(Number(newX),Number(newY))  
        }

        if(floorplanner.isSetScaleMode){
            floorplanner.disableDrawWalls()
            setWallDimensionsForScale()
            $("#setScaleWrapper").removeClass("display-none")
        }
    }else{
        setStartPointsPositions(x,y)
        let vertex = floorplanner.checkIfIntersectingWithVertex(startPoint3d)
        if(vertex){
            let position = new Vector3()
            vertex.mesh.getWorldPosition(position)
            // position.x -= wallThickness / 2
            // position.z += wallThickness / 2
            let points2d = convert3DpointsTo2D(floorplanner.renderer,floorplanner.camera,floorplanner.camera,position,true)
            setStartPointsPositions(points2d.x,points2d.y)
        }

        isSetStartPoint = true
        movableDot.css("opacity",0)
        distanceLine.css("visibility","hidden")
    }
}


function setWallDimensionsForScale() {
    $("#wallDimensionFeet").val(currWallDimensionsForScale.feet)
    $("#wallDimensionInch").val(currWallDimensionsForScale.inch)
    $("#actualWallDimensionFeet").val(currWallDimensionsForScale.feet)
    $("#actualWallDimensionInch").val(currWallDimensionsForScale.inch)
}


function setStartPointsPositions(x:number,y:number,newPosition:Vector3 = null) {
    startPoint2d.x = x
    startPoint2d.y = y
    updateDistanceLinePosition()
    setVerticesPositions3D()
}

function setEndPointsPositions(x:number,y:number) {
    endPoint2d.x = x
    endPoint2d.y = y
    setVerticesPositions3D()
}

function setVerticesPositions3D() {
    let startPos = get3DPoint(startPoint2d.x,startPoint2d.y,floorplanner.camera)
    startPoint3d = startPos.clone()

    const x = distanceLine.attr('x2');
    const y = distanceLine.attr('y2');

    let endPos = get3DPoint(x,y,floorplanner.camera)
    endPoint3d = endPos.clone() 
}


function lineDistance(x:number, y:number, x0:number, y0:number){
    return Math.sqrt((x -= x0) * x + (y -= y0) * y);
};


function updateDistanceLinePosition() {
    distanceLine.attr('x1', startPoint2d.x);
    distanceLine.attr('y1', startPoint2d.y);   
}


function updateDistanceLineWidth() {
 
    let distance = getDistanceBetweenTwoPoints(startPoint2d,endPoint2d)

    var deltaX = endPoint2d.x - startPoint2d.x;
    var deltaY = endPoint2d.y - startPoint2d.y;

    var angleInRadian = Math.atan2(deltaY, deltaX);

    var closestSnapAngle = snappingAngles[0];
    var minAngleDifference = Math.abs(angleInRadian - closestSnapAngle);

    for (var i = 1; i < snappingAngles.length; i++) {
        var angleDifference = Math.abs(angleInRadian - snappingAngles[i]);
        
        if (angleDifference < minAngleDifference) {
            closestSnapAngle = snappingAngles[i];
            minAngleDifference = angleDifference;
        }
    }

   

    var snappedDeltaX = distance * Math.cos(closestSnapAngle);
    var snappedDeltaY = distance * Math.sin(closestSnapAngle);

    var snappedEndPointX = startPoint2d.x + snappedDeltaX;
    var snappedEndPointY = startPoint2d.y + snappedDeltaY;

    distanceLine.attr('x2', snappedEndPointX);
    distanceLine.attr('y2', snappedEndPointY);
    
    //For set scale
    currWallDimensionsForScale.distance = distance
}
 

export function getDistanceBetweenTwoPoints(startPoint:Vector2,endPoint:Vector2) {
    return lineDistance(startPoint.x, startPoint.y, endPoint.x, endPoint.y);
}

export function getAngleBetweenTwoHtmlPoints(startPoint:Vector2,endPoint:Vector2) {
    return Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x) * 180 / Math.PI;
}

function updateCirclePosition(x:any,y:any,position:Vector3) {
    if(position){
        movableDot.css("top",`${y - $(".main-header-wrapper").height() - movableDot.height() / 2}px`) 
        movableDot.css("left",`${x - movableDot.width() / 2}px`) 
    }
}

export function sceneHoverInteractionDrawWalls(x:any,y:any){
    setEndPointsPositions(x,y)
    if(isSetStartPoint){
        updateDistanceLineWidth()
        updateDistanceTextPosition()
    }
    updateCirclePosition(x,y,endPoint3d)
    distanceLine.css("visibility","visible")
}

function updateDistanceTextPosition(){
    let distance = getDistanceBetweenTwoPoints(startPoint2d,endPoint2d)
    if(distance >= minValueForDistanceText){
        const dimData = convert3dPointsToFeet(startPoint3d,endPoint3d)
        currWallDimensionsForScale.feet = dimData.feet
        currWallDimensionsForScale.inch = dimData.inch

        let center = getCenterBetweenTwoHtmlPoints(startPoint2d,endPoint2d)


        let scaledDimData = convert3dPointsToFeet(startPoint3d,endPoint3d,floorplanner.traceScale)

        distanceText.text(`${String(scaledDimData.feet)}' ${String(scaledDimData.inch)}"`)
        // distanceText.text(`${String(feetInches)}"`)
        distanceText.css("top",`${center.y - $(".main-header-wrapper").height() - distanceText.height()/2}px`) 
        distanceText.css("left",`${center.x}px`) 
        distanceText.css("opacity",1)
    }else{
        distanceText.css("opacity",0)
    }
}


function getCenterBetweenTwoHtmlPoints(point1:any,point2:any) {
    var centerX = (point1.x + point2.x) / 2;
    var centerY = (point1.y + point2.y) / 2;
    return { x: centerX, y: centerY };
}


export function addDrawWallsElements(){
   
    movableDot = $("<div></div>",{
        class:"top-left movable-dot draw-walls-dot",
    })

    distanceLine = $("#distanceLineForDrawWall")

    distanceText = $("<div></div>",{
        class:"middle distance-text",
    })


    if(!floorplanner.isTracingFloorplanMode){
        closeButton = $("<button></button>",{
            class:"op-btn bg-color-black top-right measurement-tool-wrapper close-button",
            id:"measurementToolCloseButton"
        }).text("Exit draw walls")
        closeButton.click(function(){
            floorplanner.disableDrawWalls()
        })
        $(floorplanner.element).append(closeButton)
    }

  

    $(floorplanner.element).append(movableDot)
    $(floorplanner.element).append(distanceText)

    $(".hide-in-draw-walls").addClass("display-none")
}

export function disableDrawWalls(){
    if(!floorplanner.isTracingFloorplanMode || floorplanner.isSetScaleMode){
        $(".movable-dot").remove()
        $(".distance-line").remove()
        $(".temp-wall").remove()
        distanceText?.remove()
        distanceLine?.attr("x1",0)
        distanceLine?.attr("y1",0)
        distanceLine?.attr("x2",0)
        distanceLine?.attr("x2",0)
        $("#measurementToolCloseButton").remove()
        $(".hide-in-draw-walls").removeClass("display-none")
        isSetStartPoint = false
    }else{
        isSetStartPoint = false
    }
    
}

export function  get3DPoint(x:number, y:number,camera:any) {

    y = y - floorplanner.element.getBoundingClientRect().top + window.scrollY
    x = x - floorplanner.element.getBoundingClientRect().left + window.scrollX
    
    floorplanner.renderer.getSize(rendererDimensions)

    x = (x / rendererDimensions.x) * 2 - 1
    y = -(y / rendererDimensions.y) * 2 + 1

    mouseVector?.set(x, y,0.5)
    floorplanner.raycaster.setFromCamera(mouseVector, camera)
    return floorplanner.raycaster.ray.intersectPlane(planeForRaycasting, target) || new Vector3(0,0,0)
};
 

export function updateWalls(){
    for (const wall of floorplanner.walls) {
        const wallMesh = wall.mesh
   

        const vertex1 = floorplanner.getVertexFromName(wallMesh.userData.vertex1Name) 
        const vertex2 = floorplanner.getVertexFromName(wallMesh.userData.vertex2Name) 

        const point1 = vertex1.position
        const point2 = vertex2.position

        // vertex1.getWorldPosition(point1)
        // vertex2.getWorldPosition(point2)

        const distance = point1.distanceTo(point2) 

        const oldGeometry = wall.mesh.geometry;
        oldGeometry.dispose()

        wallMesh.position.set((point1.x + point2.x) / 2, (point1.y + point2.y) / 2, (point1.z + point2.z) / 2)
        const direction = point2.clone().sub(point1).normalize()
        const euler = new Euler().setFromQuaternion(new Quaternion().setFromUnitVectors(new Vector3(1, 0, 0), direction));
 
        wallMesh.rotation.copy(euler);

        wallMesh.geometry = new BoxGeometry(distance, 3,wallThickness)
    }
    floorplanner.isConfigChanged = true
}

export function deleteLastWall() {
    let allWalls = $(".temp-wall")
    let wallsLength = allWalls.length
    if(wallsLength){
        let lastWall = allWalls[wallsLength - 1]
        lastWall?.remove()
    }
    
}

 