import {
  IonAlert, IonIcon
} from "@ionic/react";
import { copy, sync, trash } from "ionicons/icons";
import $ from "jquery";
import React, { useState } from "react";
import {
  copyProduct,
  removeAllProductsFromCanvas, removeProduct, rotateProduct
} from "../../../../../../utils/moodboard/moodboard";

const Notes: React.FC<{
  configObj: any;
  isConfigUpdated: boolean;
  canvasLoaderId: string;
  areaConfigFlagMapping: any;
  reloadProjectSavedConfig: boolean;
  setReloadProjectSavedConfig: (value: boolean) => void;
}> = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDeleteProduct, setShowAlertDeleteProduct] = useState(false);





  function checkIfAllAreasCompleted() {
    let flag = true;
    for (const area of props.configObj?.areas) {
      if (!props.areaConfigFlagMapping[area.area_name]) {
        flag = false;
      }
    }
    if (flag) {
      $("#summaryLink").addClass("--is-active");
      $("#statusCount").addClass("--is-active");
      $("#summaryTooltip").addClass("--is-active");
    }
  }

  function reloadSavedConfig() {
    props.setReloadProjectSavedConfig(!props.reloadProjectSavedConfig);
  }

  function onConfigSave() {
    reloadSavedConfig();
    checkIfAllAreasCompleted();
  }

  return (
    <React.Fragment>
       {/* <div className="top-left">
        <div>Add Area</div>
      </div> */}

    <div className="product-actions-container zindex30" id="productActionContainer" style={{position:"absolute",top:"-100%",left:"-100%"}}>
        <div className="display-flex-column pointer">
          <IonIcon className="color-black font-small click-effect white-card shadow-light padding5" icon={copy} onClick={copyProduct}/>
          <IonIcon className="marginTop10 font-small color-black click-effect white-card shadow-light padding5" icon={sync} onClick={rotateProduct}/>
          <IonIcon className="marginTop10 font-small color-black click-effect white-card shadow-light padding5" icon={trash} onClick={()=>{setShowAlertDeleteProduct(true)}}/>
        </div>
    </div>


    <div className="moodboard-controls-container right-middle zindex30">
     
      <IonAlert
        isOpen={showAlert}
        mode="ios"
        onDidDismiss={() => setShowAlert(false)}
        cssClass="my-custom-class"
        header={"Confirm!"}
        message={"Reset <strong>canvas</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              removeAllProductsFromCanvas();
            },
          },
        ]}
      />

    <IonAlert
        isOpen={showAlertDeleteProduct}
        mode="ios"
        onDidDismiss={() => setShowAlertDeleteProduct(false)}
        cssClass="my-custom-class"
        header={"Confirm!"}
        message={"Delete <strong>product</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              removeProduct();
            },
          },
        ]}
      />

      {/* <div className="controls-container ion-margin-bottom">
        <div className="control" onClick={showTitleEditor}>
          <i className="fas fa-text-height font-normal color-black" aria-hidden="true"></i>
        </div>
      </div> */}

      <div className="controls-container">
        {/* <div className="control" onClick={copyProduct}>
          <i
            className="fa fa-clone font-normal color-primary"
            aria-hidden="true"></i>
        </div>
        <div className="control" onClick={removeProduct}>
          <i
            className="fa fa-trash font-normal color-danger"
            aria-hidden="true"></i>
        </div>
      
        <div className="control" onClick={rotateProduct}>
          <i
            className="fas fa-undo font-normal color-dark"
            aria-hidden="true"></i>
        </div> */}

        {/* <div className="control" onClick={() => setShowAlert(true)}>
          <i
            className="fas fa-sync font-normal color-black"
            aria-hidden="true"></i>
        </div> */}
        
        {/* <div className="control" onClick={downloadMoodboard}>
          <i
            className="fa fa-download font-normal color-black"
            aria-hidden="true"></i>
        </div> */}
      </div>

      {/* <div className="controls-container ion-margin-top save-moodboard-container" id="moodboardSaveButton"> */}
        
        {/* <div className="control" onClick={()=>updateConfig(props.configObj,props.canvasLoaderId,props.configObj?.areaName,props.configObj?.areaId,onConfigSave)}>
          {props.isConfigUpdated ? <div className="update-hint ">*</div> : null}
          {props.isConfigUpdated ? (
            <i
              className="fa fa-save font-normal"
              aria-hidden="true"
              style={{ color: "#fff" }}></i>
          ) : (
            <i
              className="fa fa-check-circle font-normal"
              aria-hidden="true"
              style={{ color: "#fff" }}></i>
          )}
        </div> */}

      {/* </div> */}
    </div>
    </React.Fragment>
  );
};

export default Notes;
