import $ from "jquery"
import { Box3,  Object3D, Vector3 } from "three"
import { setSnappingObjectInKitchenPlanner } from "../../kitchenplanner/helper"
import { updateModelBoundingBox } from "../common"
import { convert3dPointsToFeet } from "../floorplanner/helper"
import { getObjectByName, getObjectDimensionPosition } from "../modules/helper"
import { CURR_SELECTED_PRODUCT } from "../raycasting"
import { isProductRotating } from "./actions"
import { AREA_MODEL_ORIGIN, CURR_AREA_NAME, GROUND_NAME, IS_PRODUCT_SELECTED, IS_SPACE_PLANNER_MODE, camera, customizerConfig, floorplanner, isFloorPlanMode, isKitchenPlannerMode, kitchenPlanner, projectConfiguration, renderer, scale, scene } from "./area3dModel"
import { checkCollision, getNormal } from "./helper"
import { isInteriorVisualization } from "./interior_visualization"
import { WALL_WINDOWS, areaWallsBoundingBoxMapping, getAreaFromPoint, getFrontAndBackPlane, resetWallProductParameters, rotateWallProductOnDrag } from "./walls"

const vector = new Vector3()
const boundingBox = new Box3()

export var LEFT_POINT = null
export var RIGHT_POINT = null
export var TOP_POINT = null
export var BOTTOM_POINT = null

export var IS_DIMENSION_VISIBLE = false

export const directions = ["left","top","right","bottom"]


let animationFrameIdControls = null



export var WALL_ROTATION = 0


export var startPoints:any = {}
export var endPoints:any = {}
let points = []
let dragObjectData:any = {}
export var floorData:any = null
let posY = 0.01 * scale


export var CURR_SNAPPED_DISTANCES = []

export function updateSelectedProductAnnotation() {

    if(!CURR_SELECTED_PRODUCT){
      return
    }
    $(".product-annotations-wrapper").removeClass("--is-hide")

    let delta = 20

    if(isKitchenPlannerMode){
      delta = 50
    }

    updateModelBoundingBox(CURR_SELECTED_PRODUCT)
    // let data = getObjectDimensionPosition(boundingBox,object)
    let box = getObjectDimensionPosition(null,CURR_SELECTED_PRODUCT)
    let data = box.positions
    let center = box.center
  
    let annotation:any = document.querySelector( `#productAnnotationWrapper`)
    let rotationSlider:any = document.querySelector( `#productRotationRangeSlider`)

    
    const canvas = renderer?.domElement
  
    if(CURR_SELECTED_PRODUCT.rotation.x === 0 && CURR_SELECTED_PRODUCT.rotation.y === 0 && CURR_SELECTED_PRODUCT.rotation.z === 0){
      vector?.set(data.max.x,data.max.y,data.max.z)
    }else{
      vector?.set(data.max.x,data.max.y,data.min.z)
    } 
    vector?.project(camera)
  
    vector.x = Math.round((0.5 + vector?.x / 2) * (canvas.width))
    vector.y = Math.round((0.5 - vector?.y / 2) * (canvas.height))

    annotation.style.top = `${vector?.y - delta}px`
    annotation.style.left = `${vector?.x + delta}px`

    //Rotation slider set 
    if(!isProductRotating){
      if(IS_SPACE_PLANNER_MODE){
        vector.set(data.min.x,data.min.y,data.min.z)
      }else{
        vector.set(center.x,AREA_MODEL_ORIGIN.y,center.z)
      }
      vector.project(camera)
    
      vector.x = Math.round((0.5 + vector?.x / 2) * (canvas.width))
      vector.y = Math.round((0.5 - vector?.y / 2) * (canvas.height))
      
      rotationSlider.style.top = `${vector?.y}px`
      rotationSlider.style.left = `${vector?.x }px`
  
  
      
    }
    
      
}
 


export function resetCurrSnappedDistances() {
  CURR_SNAPPED_DISTANCES = []
}


export function createProductAnnotations() {
  return 
  // animateProductAnnotations()
}

export function updateAllAreasLabels() {
  if(!isFloorPlanMode && !isInteriorVisualization && areaWallsBoundingBoxMapping && projectConfiguration?.areasList.length && !CURR_SELECTED_PRODUCT && (CURR_AREA_NAME === projectConfiguration.projectName || CURR_AREA_NAME === "all")){
    // if(!isFloorPlanMode && areaWallsBoundingBoxMapping && projectConfiguration?.areasList.length && isControlsDragEnded){
    for (const area of projectConfiguration.areasList) {
      let data = areaWallsBoundingBoxMapping[area.area_name]
      if(data){
        data = areaWallsBoundingBoxMapping[area.area_name].boundingBox.center
        
        vector?.set(data.x,data.y,data.z)
        vector?.project(camera)
    
        vector.x = Math.round((0.5 + vector?.x / 2) * (renderer?.domElement.width))
        vector.y = Math.round((0.5 - vector?.y / 2) * (renderer?.domElement.height))
        $(`#areaLabel${area.area_name}`)?.css({"top":`${vector?.y}px`,"left":`${vector?.x}px`}) 
        // $(`#areaLabel${area.area_name}`)?.removeClass("--is-hide") 
      }else{
        $(`#areaLabel${area.area_name}`)?.addClass("--is-hide") 
      }
    }
  }else{
    $(`.area-label`)?.addClass("--is-hide") 
  }
}

export function showAreaLabel() {
  $(`.area-label`)?.removeClass("--is-hide")
}

export function hideAreaLabel() {
  $(`.area-label`)?.addClass("--is-hide")
}



export function updateAllProductsAnnotationDots() {
  return
}


export function updateFloorDimensionsPosition() {
  
  if(IS_SPACE_PLANNER_MODE){
    $(".floor-dimensions").css("visibility","visible")
    let walls = floorplanner.walls
    if(walls.length){
      for (const currWall of walls) {
          let currElement = currWall.dimensionElement
          if(currElement){
            let wallTranform = getObjectDimensionPosition(boundingBox,currWall.mesh)
            let vertex1 = currWall.startVertex.position
            let vertex2 = currWall.endVertex.position
  
            // var distance = vertex1.distanceTo(vertex2);
            // let dimData = getConvertedLengthBreadth(distance,distance,"feet")
            let dimData = convert3dPointsToFeet(vertex1,vertex2)
            
            
            vector?.set(wallTranform.center.x,wallTranform.center.y,wallTranform.center.z)
            vector?.project(camera)
            vector.x = Math.round((0.5 + vector?.x / 2) * (renderer?.domElement.width))
            vector.y = Math.round((0.5 - vector?.y / 2) * (renderer?.domElement.height))
            currElement.css("top",`${vector?.y}px`)
            currElement.css("left",`${vector?.x}px`)
            currElement.text(`${String(dimData.feet)}' ${String(dimData.inch)}"`)
          }
      }
    }
    
  }else{
    $(".floor-dimensions").css("visibility","hidden")
  }
  // animateFloorDimensions()
}




export function checkIfProductSelectable(name:string) {
  if(!name.toLowerCase().includes("camera") && !name.toLowerCase().includes("plan") 
  && !name.toLowerCase().includes("area") && !name.toLowerCase().includes("props")
  && !name.toLowerCase().includes("text") && !name.toLowerCase().includes("background")
  && !name.toLowerCase().includes("wall") && !name.toLowerCase().includes("cladding")
  && !name.toLowerCase().includes("point")
  && !name.toLowerCase().includes("floor")
  && !name.toLowerCase().includes("shell")
  ){
    return true
  }
  return false
}



export function updateAnnotationsAnimation(){
  animationFrameIdControls = requestAnimationFrame(updateAnnotationsAnimation)
  updateAnnotation()
}



function updateAnnotation() {
  if(!isInteriorVisualization && IS_PRODUCT_SELECTED){
      updateSelectedProductAnnotation()
  }
  updateAllAreasLabels()
}


export function cancelControlsCheckAnimation() {    
  cancelAnimationFrame(animationFrameIdControls)
  animationFrameIdControls = undefined
}


  export function setDataForDistanceLines(object:any=null) {
    if(!floorData){
      let ground = getObjectByName(scene,GROUND_NAME)
      if(ground){
        floorData = getObjectDimensionPosition(null,ground)
      }
    }
  }

  function setStartAndEndPoints(direction:string,object:any) {

    posY = AREA_MODEL_ORIGIN.y + 0.02

    dragObjectData = getObjectDimensionPosition(null,object)

    switch (direction) {
      case "left":
        startPoints = {
          x:dragObjectData.positions.min.x,
          y:posY,
          z:dragObjectData.positions.min.z + dragObjectData.dimensions.dimZ /2
        }
        endPoints = {
          x:floorData?.positions.min.x || 0,
          y:posY,
          z:startPoints.z
        }
      break;

      case "top":
        startPoints = {
          x:dragObjectData.positions.min.x + dragObjectData.dimensions.dimX /2,
          y:posY,
          z:dragObjectData.positions.min.z 
        }
        endPoints = {
          x:startPoints.x,
          y:posY,
          z:floorData?.positions.min.z || 0,
        }
      break;

      case "right":
        startPoints = {
          x:dragObjectData.positions.max.x,
          y:posY,
          z:dragObjectData.positions.max.z - dragObjectData.dimensions.dimZ /2
        }
        endPoints = {
          x:floorData?.positions.max.x || 0,
          y:posY,
          z:startPoints.z
        }
      break;

      case "bottom":
        startPoints = {
          x:dragObjectData.positions.max.x - dragObjectData.dimensions.dimX /2,
          y:posY,
          z:dragObjectData.positions.max.z 
        }
        endPoints = {
          x:startPoints.x,
          y:posY,
          z:floorData?.positions.max.z || 0,
        }
      break;

      default:
        break;
    }
    points = [
      new Vector3(startPoints.x,startPoints.y,startPoints.z),
      new Vector3(endPoints.x,endPoints.y,endPoints.z)
    ]
  }


 export function setIntersectObjectEndPoints(direction:string,intersectObjectData:any) {
    posY = AREA_MODEL_ORIGIN.y + 0.02
    switch (direction) {
      case "left":
        endPoints = {
          x:intersectObjectData.positions.max.x,
          y:posY,
          z:startPoints.z
        }
      break;

      case "top":  
        endPoints = {
          x:startPoints.x,
          y:posY,
          z:intersectObjectData.positions.max.z,
        }
      break;

      case "right":
        endPoints = {
          x:intersectObjectData.positions.min.x,
          y:posY,
          z:startPoints.z
        }
      break;

      case "bottom":

        endPoints = {
          x:startPoints.x,
          y:posY,
          z:intersectObjectData.positions.min.z,
        }
      break;

      default:
        break;
    }
  }


  export function updateDistanceCheckPointsPosition(object:any) {
    if(!object){
      return
    }
    let distance = 0
    let distances = []

    let areaName = getAreaFromPoint(getObjectDimensionPosition(null,object).center)

    if(isKitchenPlannerMode && object.uuid != kitchenPlanner.group.uuid){
      setSnappingObjectInKitchenPlanner(object)
    }

    for (const direction of directions) {
      setStartAndEndPoints(direction,object)

      let intersectObject = null

      intersectObject = getIntersectObject(object,direction)

     

      if(intersectObject){
          let planes = getFrontAndBackPlane(intersectObject,areaName)
          let intersectObjectData = getObjectDimensionPosition(null,planes.FrontPlane)
          setIntersectObjectEndPoints(direction,intersectObjectData)
          points.pop()
          points.push(new Vector3(endPoints.x,endPoints.y,endPoints.z))
  
          if(direction.includes("left") || direction.includes("right")){
            distance = Math.abs(endPoints.x-startPoints.x)
          }else{
            distance = Math.abs(endPoints.z-startPoints.z)
          }
          updateDistanceAnnotationsPosition(object,direction,distance)
  
          //Keep normal for floor product to snap
          distances.push({
            distance:distance,
            object:planes.FrontPlane,
            detectedObject:intersectObject,
            normal:getNormal(direction)
          })
  
          if(!object.userData.isWallMounted){
            if(distance < getSnapLimit(object)){
              intersectObject.material.opacity = 0.5
            }else{
              intersectObject.material.opacity = 1
            }
          }
      }
    }

    if(distances.length){
      let result = getLowestDistance(distances)
      if(object.userData.isWallMounted && customizerConfig.isAutoRotation){
        snappingOnWallItem(object,result,areaName)
        CURR_SNAPPED_DISTANCES = distances
      }else{
        CURR_SNAPPED_DISTANCES = distances
      }
    }
    
  }

 export function getLowestDistance(distances:Array<any>) {
    let min = distances[0].distance
    let result = distances[0]
    distances.forEach(currDistance => {
      if(currDistance.distance < min){
        min = currDistance.distance
        result = currDistance
      }
    });
    return result
  }


  function snappingOnWallItem(object:any,result:any,areaName:string) {
    if(result?.distance < getSnapLimit(object)){
      result.object.material.color.setHex(0x373737)
      if(areaName && object.userData.categoryName !== "Curtain"){
        let currPlane = object?.userData.snappedWall
        if(!object.userData.isAttachedToWall){
          rotateWallProductOnDrag(result.object,areaName)
        }
        result.object.opacity = 1
        result.object.visible = true
      }
    }
    else{
      resetWallProductParameters(true)
      result.object.opacity = 0
      result.object.visible = false
    }
  }

  function snappingOnFloorItem(distances:Array<any>,object:any) {
    distances.forEach(currDistance => {
      if(currDistance.distance < getSnapLimit(object)){
         
      }
    });
  }


  export function updateDistanceAnnotationsPosition(object:any,direction:string,width:number) {
    if(IS_SPACE_PLANNER_MODE){
      let data = getObjectDimensionPosition(boundingBox,object)
  
      let elementId =  `#productDistanceCheckWrapper`
      let annotation:any = document.querySelector(elementId)
  
      const canvas = renderer?.domElement
    
      vector?.set(data.positions.min.x,data.positions.min.y,data.positions.min.z)
      vector?.project(camera)
    
      vector.x = Math.round((0.5 + vector?.x / 2) * (canvas.width))
      vector.y = Math.round((0.5 - vector?.y / 2) * (canvas.height))
  
      annotation.style.top = `${vector?.y}px`
      annotation.style.left = `${vector?.x}px`
      annotation.style.width = `${data.dimensions.dimX * camera.zoom}px`
      annotation.style.height = `${data.dimensions.dimZ * camera.zoom}px`

      updateDistanceLineWidth(direction,width)
    }
    
}

function updateDistanceLineWidth(direction:string,width:number) {
  width = width * camera.zoom
  let currLineElement = null
  let currTextElement = null

  switch (direction) {
    case "left":
      currLineElement = $("#leftDistanceLine")
      currTextElement = $("#leftDistanceLine").find(".text")
      $("#leftDistanceLine").width(width)
    break;

    case "right":
      currLineElement = $("#rightDistanceLine")
      currTextElement = $("#rightDistanceLine").find(".text")
      $("#rightDistanceLine").width(width)
    break;

    case "top":
      currLineElement = $("#topDistanceLine")
      currTextElement = $("#topDistanceLine").find(".text")
      $("#topDistanceLine").height(width)
    break;
  
    case "bottom":
      currLineElement = $("#bottomDistanceLine")
      currTextElement = $("#bottomDistanceLine").find(".text")
      $("#bottomDistanceLine").height(width)
    break;

    default:
    break;
  }


  if(width < 0.25 * scale){
    currLineElement?.addClass("warning")
  }else{
    currLineElement?.removeClass("warning")
  }

  currTextElement.text(String(Math.floor(width))+"''")
}
    


export function hideDistanceAnnotations() {
  $("#productDistanceCheckWrapper").removeClass("--is-active")
}

export function showDistanceAnnotations() {
  if(IS_SPACE_PLANNER_MODE){
    $("#productDistanceCheckWrapper").addClass("--is-active")
  }
}



function getSnapLimit(object:Object3D) {
  if(object?.userData.isWallMounted){
    return 0.25
  }
  if(isKitchenPlannerMode && !object?.userData.isWallMounted){
    return 0.01
  }
  else{
    return 0.30
  }
}


  function getIntersectObject(draggingObject:any,direction:any) {
    let objCenter = getObjectDimensionPosition(null,draggingObject).center
    let wall = null

    //if inside the wall
    WALL_WINDOWS.forEach(currWall=>{
      if(currWall.userData.boundingBox.containsPoint(objCenter)){
        wall = currWall
      }
    })
    if(wall){
      return wall
    }

    let intersectObjects = checkCollision(draggingObject,direction,WALL_WINDOWS)
    // let intersectObjects = checkCollision(draggingObject,direction,WALL_WINDOWS)
    intersectObjects = intersectObjects?.filter((object:any)=>object.object.parent?.uuid!=draggingObject?.uuid 
    && !object.object.name.toLowerCase().includes("door")
    && !object.object.name.toLowerCase().includes("untitled")
    && !object.object.name.toLowerCase().includes("skirting")
    &&  object.object.name.toLowerCase().includes("wall")
    &&  object.object.name.length
    )
    if(intersectObjects.length){
      if(intersectObjects[0].object.name.toLowerCase().includes("window")){
        return intersectObjects[0].object.parent 
      }
      return intersectObjects[0].object
    }
   
    return null
  }


 

  var startX,startY,endX,endY = 0
  var isPointerDownAnnotationDrag = false

  export function pointerDownAnnotationDrag(event:any) {
    isPointerDownAnnotationDrag = true
    startX = event.clientX
    startY =  event.clientY
  }

  export function pointerMoveAnnotationDrag(event:any) {
    if(isPointerDownAnnotationDrag){
      let x = event.pageX || event.touches[0].clientX 
      let y = event.pageY || event.touches[0].clientY 

      let deltaX = x - startX
      let deltaY = y - startY

      $("#productAnnotationWrapper").css("margin-left",Number($("#productAnnotationWrapper").css("margin-left").replace("px","")) + deltaX)
      $("#productAnnotationWrapper").css("margin-top",Number($("#productAnnotationWrapper").css("margin-top").replace("px","")) + deltaY)
    
      startX = x
      startY =  y
    }
  }

  export function pointerUpAnnotationDrag(event:any) {
    isPointerDownAnnotationDrag = false
  }


