import React from "react";
import { wallColorCustomization } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { confirmWallForElevationView, disableSelectWallForElevationView, flipWallElevationView, hideWallSelectionHint } from "../../../../../../utils/customizein3d/area3dmodel/elevationView";
import { applicationConfig } from "../../../../../../utils/methods";
import Button2 from "../../../../../common/ui-components/buttons/Button2";


const ElevationView: React.FC<{
  }> = (props) => {

    
 
   
  return (
    <>
     <div className="zindex30 top-right display-none show-in-wall-color-mode">
      <div className="display-flex-row no-wrap">
        <Button2
          configObj={applicationConfig}
          action={()=>{wallColorCustomization.reset()}}
          label={"Reset"}
          fontAwesomeIcon={"fas fa-undo color-danger"}
          iconPos="left"
          classNames={"shadow-light bg-color-"}
        />
        <Button2
          configObj={applicationConfig}
          action={()=>{wallColorCustomization.disable()}}
          label={"Exit"}
          fontAwesomeIcon={"fas fa-times color-danger"}
          iconPos="left"
          classNames={"shadow-light bg-color-black"}
        />
      </div>
        
      </div>

      <div className="zindex30 top-right display-none show-in-elevation-view">
        <Button2
          configObj={applicationConfig}
          action={disableSelectWallForElevationView}
          label={"Exit"}
          fontAwesomeIcon={"fas fa-times color-danger"}
          iconPos="left"
          classNames={"shadow-light bg-color-black"}
        />
      </div>


      <div id="confirmWallButtonElevationView" className="zindex30 bottom-middle visibility-hidden ion-margin-bottom display-none show-in-elevation-view">
        <Button2
          configObj={applicationConfig}
          action={confirmWallForElevationView}
          label={"Confirm wall"}
          fontAwesomeIcon={"fas fa-check color-danger"}
          iconPos="left"
          classNames={"shadow- button-large bg-color-primary pulse-anchor"}
        />
      </div>

      <div id="flipWallButtonElevationView" className="zindex30 bottom-middle  ion-margin-bottom display-none">
        <Button2
          configObj={applicationConfig}
          action={flipWallElevationView}
          label={"Flip Wall"}
          fontAwesomeIcon={"fas fa-check color-danger"}
          iconPos=""
          classNames={"button-large bg-color-primary bg-color"}
        />
      </div>


      <div id="selectWallHintElevationView" className="zindex30 middle  full-height-width show-in-elevation-view display-none" style={{zIndex:1002,paddingTop:"10px",background:"rgba(0,0,0,0.7)"}}>
        <div className="display-flex-row no-wrap pulse-box" style={{width:"fit-content",margin:"auto",padding:"15px 35px",borderRadius:"5px"}}>
          <div className="letter-spacing1 marginRight10 heading3 center-aligned-column color-black font-medium">Select any wall to change wall color</div>
          <Button2
            configObj={applicationConfig}
            action={hideWallSelectionHint}
            label={"Got it"}
            fontAwesomeIcon={"far fa-thumbs-up color-danger"}
            iconPos="left"
            classNames={"shadow-light button-small bg-color-primary"}
          />
        </div>
      </div>

    </>
  );
};

export default ElevationView;
