
import html2canvas from "html2canvas";
import AwsConfig from "../aws-sdk/AwsConfig";
import { hideComponentLoader, showComponentLoader } from "../UI_methods/global";
import jsPDF from "jspdf";
import $ from "jquery"


const pdf = new jsPDF();

export function convertHtmlToImage(elementId:string,isDownload:boolean = true) {

    return new Promise((resolve,reject)=>{
        var container:any = document.getElementById("screenshotClone")!
        const targetElement = document.getElementById(elementId)!.cloneNode(true);
        container.appendChild(targetElement);
        $(container).css("width","45rem")
        // const option = {allowTaint: true, useCORS: true};
        html2canvas(container).then(async function(canvas) {
            if(isDownload){
                var link = document.createElement("a");
                document.body.appendChild(link);
                link.download = "html_image.png";
                link.href = canvas.toDataURL("image/png");
                link.target = '_blank';
                link.click();
            }
            let dataUrl = canvas.toDataURL("image/png")
            const blob = await fetch(dataUrl).then(response => response.blob());
            container.firstChild.remove();
            resolve(blob)
        });
    })
}

export async function convertHtmlToImageAndShare(elementId:string,title:string) {
    showComponentLoader("sharePageLoader")
    let navigatorObject:any = window.navigator
    if(!navigatorObject?.share){
      return
    }
  
    await convertHtmlToImage(elementId,false).then(async (blob:any)=>{
          let file = new File([blob], "share.png", { type: 'image/png' });
          let filesArray = [file];
          await navigatorObject.share({
            title: title,
            files:filesArray
          }).then(() => {
              hideComponentLoader("sharePageLoader")
          }).catch(err => {
              console.log(err);
              hideComponentLoader("sharePageLoader")
          });
      }).catch(err=>{
        console.log(err)
        hideComponentLoader("sharePageLoader")
      })
  }

  
  export function convertHtmlImageToPdf(containerId:string) {
    // Create a new FileReader to read the Blob data

    convertHtmlToImage(containerId,false).then((imageUrl:any)=>{
        const img = new Image();
        img.src = imageUrl;

        // When the image is loaded, add it to the PDF and save it
        img.onload = function () {
            const maxWidth = pdf.internal.pageSize.getWidth();
            const maxHeight = pdf.internal.pageSize.getHeight();
            const widthRatio = maxWidth / img.width;
            const heightRatio = maxHeight / img.height;

            // Choose the smaller ratio to ensure the image fits within the PDF page
            const ratio = Math.min(widthRatio, heightRatio);
            const newWidth = img.width * ratio;
            const newHeight = img.height * ratio;

            // Center the image on the page
            const x = (maxWidth - newWidth) / 2;
            const y = (maxHeight - newHeight) / 2;

            pdf.addImage(img, 'JPEG', x, y, newWidth, newHeight, null, 'FAST', 0); // No border
            pdf.save(String(Number(new Date()))+".pdf");
        };
    })

  
    
  }
  
 
   