export let developers = [
"Raheja",
"Sobha",
"Brigade",
"Prestige",
"Aparna",
"Sumadhura",
"DLF",
"Hiranandani",
"Godrej",
"L&T",
"Mahindra",
"Ozone",
"Purvankara",
"Lodha",
"KoltePatil",
"EMAAR",
"Embassy",
"Tata",
"Ansal",
"Salarpuria",
"GM Group",
"JayPee",
"ShapoorJi Pallonji",
"Oberoi",
"Shriram",
"Ajmera",
"Birla Estates",
"Total Environment",
"Rohan",
"Adarsh",
"Ramky",
"Others",
]