import { IonIcon } from "@ionic/react";
import { trash } from "ionicons/icons";
import React from "react";
import { confirmAddonFill, removeAddon } from "../../../../../../utils/moodboard/addons";
import $ from "jquery"

const SelectionBox: React.FC<{
  configObj: any;
}> = (props) => {


  function mouseEnter(event:any) {
    $(event.target).css({"pointer-events":"all","cursor":"grab"})
  }

  
  function mouseDown(event:any) {
    $(event.target).css("pointer-events","none")
  }

 
 
  return (
    <React.Fragment>
       {/* <div className="top-left">
        <div>Add Area</div>
      </div> */}

      <div className="product-selection-box-container shadow-medium product-selection-box" id="productSelectionBox" style={{position:"absolute",top:"-100%",left:"-100%",zIndex:2}}>
          <div className="circle shadow-medium top-left"></div>
          <div className="circle shadow-medium top-right"></div>
          <div className="circle shadow-medium bottom-left"></div>
          <div className="circle shadow-medium bottom-right"></div>
          <div className="top-middle rotation-icon"> <i className="fas fa-undo color-dark font-normal"></i> </div>
      </div>

      <div className="product-selection-box-container product-hover-box" id="productHoverBox" style={{position:"absolute",top:"-100%",left:"-100%",zIndex:1}}>
      </div>

      {/* <div className="addons-confirm-container addons-container" id="productAddonsBox" onClick={confirmAddonFill} style={{position:"absolute",top:"-100%",left:"-100%",zIndex:1}}> */}
        <div className="center-aligned-column addons-confirm-container addons-container pointer white-card font-small shadow-light" onClick={confirmAddonFill} style={{position:"absolute",top:"-100%",left:"-100%",zIndex:1,width:"20px",height:"20px"}}>
          <i className="color-black center-aligned-column font-normal click-effect fas fa-check"></i>
            {/* <IonIcon className="marginleft10 font-small color-black click-effect white-card shadow-light padding5" onClick={cancelAddonFill} icon={close}/> */}
          {/* </div> */}

          {/* <div className="display-flex-row pointer white-card font-small shadow-light padding5 marginleft10" onClick={cancelAddonFill} >
          <i className="color-danger center-aligned-column font-small marginRight5 click-effect fas fa-times" style={{marginTop:""}}></i>
            Cancel
          </div> */}
      </div>

      <div className="addon-actions-container addons-container" id="" style={{position:"absolute",top:"-100%",left:"-100%",zIndex:1}}>
        <div className="display-flex-row pointer">
            {/* <IonIcon className="color-black font-small click-effect white-card shadow-light padding5" icon={text}/> */}
            <IonIcon className="font-small color-danger click-effect white-card shadow-light padding5" onClick={removeAddon} icon={trash}/>
          </div>
      </div>

    </React.Fragment>
  );
};

export default SelectionBox;
