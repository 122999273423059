import {
    IonIcon,
    IonPage,
    IonContent,
    IonModal,
  } from "@ionic/react";
import { search } from "ionicons/icons";
  
  import React,{useEffect, useState} from "react";
  import "../../style/admin/project.css"
import CreateProject from "./createproject/CreateProject";
import CardsFilterHeaderLayout from "../../templates/layouts/page/CardsFilterHeaderLayout";
import { addActiveClass, openFiltersWindowMobile, searchProject } from "../../../utils/UI_methods/global";
import DropDown2 from "../../common/ui-components/filters/DropDown2";
import { getArrayOfDistinctValuesFromObject } from "../../../utils/methods";
import DropDown from "../../common/ui-components/filters/DropDown";
import Button2 from "../../common/ui-components/buttons/Button2";
  
  const ProjectHeader: React.FC<{
    // setCreateNewProject:(value:boolean)=>void
    // setCurrProjectId:(id:number)=>void
    configObj:any
  }> = (props) => {


 





    return(
      // <CardsFilterHeaderLayout
      // FilterHeaderContent={FilterContent}
      // />
      <div className="filter-header-wrapper" style={{padding:"0"}}>
      <div className="display-flex-row no-wrap hide-in-mobile ion-padding" style={{justifyContent:"space-between"}}>
        <div className="display-flex-row">
        <DropDown2
          classNames=""
          values={["Project name","Location"]}
          currSelectedValue={"Project name"}
          action={()=>{}}
          label="Sort By"
          />
        </div>
          
        <div className="search-container position-relative" style={{width:"25rem"}}>
          <input 
          type="text" 
          placeholder="Search project" 
          className="search-input input"
          id="projectSearchInput"
          onChange={searchProject}
          />
          <IonIcon className="icon font-normal left-middle" icon={search}></IonIcon>
          </div>
        </div>

        <div className="hide-in-desktop full-width  filters-container " style={{padding:"10px"}}>
      <div className='full-width display-flex-row position-relative space-between'>

        <div className="">
          <Button2
            configObj={{}}
            action={()=>openFiltersWindowMobile()}
            label={"Filters"}
            fontAwesomeIcon={"fas fa-bars color-black"}
            iconPos="left"
            classNames={"no-shadow bg-color-black margin0 font-medium full-width"}
          />
        </div>

        <div className="middle">
           
        </div>

        <div className="center-aligned-column">
          <div className='display-flex-row'>
            <Button2
              configObj={{}}
              action={()=>addActiveClass("#projectFiltersMobileWrapper")}
              label={""}
              fontAwesomeIcon={"fas fa-search font-medium color-primary"}
              iconPos="right"
              classNames={"margin0 no-shadow font-large full-width bg-color-p"}
            />
          </div>
        </div>


      </div>

      
    </div>

        
        </div>
        
      )
  };
  
  export default ProjectHeader;
  