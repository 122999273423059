import { Box3, BoxGeometry, Group, MathUtils, Mesh, MeshBasicMaterial, Object3D, Quaternion, Vector2, Vector3 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import AwsConfig from "../aws-sdk/AwsConfig";
import { checkFromCache, untarAndSaveToCache } from "../cache/cache";
import { removeProduct, rotate, setDuplicateProductPosition, updateCurrSelectedProductConfig } from "../customizein3d/area3dmodel/actions";
import { updateDistanceCheckPointsPosition } from "../customizein3d/area3dmodel/annotations";
import { addModelToScene, areaModel, controls, deselectProduct, getAreaGroupFromAreaName, getClicked3DPoint, kitchenPlanner, projectConfiguration, renderer } from "../customizein3d/area3dmodel/area3dModel";
import { ClampObject } from "../customizein3d/area3dmodel/clamp";
import { removeBoxHelper, updateBoxHelper } from "../customizein3d/area3dmodel/dragging";
import { applyTransformInObject, postAddingActions, setAddedProductDetailsInUserData, updateUserDataFromProductInstance } from "../customizein3d/area3dmodel/helper";
import { createGroupForModule, snapProduct } from "../customizein3d/area3dmodel/modules";
import { closeControlsPanelAndHideProducts, updateAttachIconVisibility } from "../customizein3d/area3dmodel/UI_methods";
import { addModuleToConfiguration, applyEnvMapInModel, detachModule } from "../customizein3d/common";
import { Configuration } from "../customizein3d/Configuration";
import { loadModuleToTheScene } from "../customizein3d/modules/customizein3d";
import { getObjectByObjectId, getObjectDimensionPosition, updateOtherModelPositions } from "../customizein3d/modules/helper";
import { CURR_SELECTED_PRODUCT, setCurrSelectedProduct } from "../customizein3d/raycasting";
import { Undo } from "../customizer/CustomizerConfig";
import { ProductInstance, Transform } from "../customizer/ProjectConfiguration";
import { applicationConfig, stringWithoutWhiteSpace } from "../methods";
import { showToast } from "../UI_methods/global";
import { lshapeConfiguration } from "./configurations/lshape";
import { parallelConfiguration } from "./configurations/parallelKitchen";
import { ushapeConfiguration } from "./configurations/ushape";
import { addItemToGroup, deselectGroupKitchenPlanner, unlinkModuleFromGroup } from "./groups";
import { removeNearestFillers, setInitialSnappedWall, updateModulePositionsOnReplace } from "./helper";


const vector = new Vector3()
export var isPointerDownKitchenDragIcon = false
export var kitchenDimensions:any = null


export class KitchenPlanner {
  scene: Object3D = areaModel;
  loader: GLTFLoader;
  group:Object3D = null
  fillersGroup:Group = new Group()
  wallModulePositionY:number = 0
  detectedObjectOnDrag:any = null
  isReplaceMode:boolean = false
  groupsList:Array<Group> = []
  product:ProductInstance = null
  configuration:Configuration = new Configuration()

  multiselect:multiselect = new multiselect()
  clamp:ClampObject =  new ClampObject()
  kitchenModulesList:Array<Object3D> = []


  undo = new Undo("360")
  
  constructor(scene: Group, loader: GLTFLoader) {
    this.loader = loader;
  }

  async addKitchenToscene(productDetails:any){
    this.kitchenModulesList = []
    this.group =  createGroupForModule()

    let productInstance = projectConfiguration.addProduct(productDetails,this.group,"Kitchen")
    this.configuration = productInstance.moduleConfiguration

    projectConfiguration.updateModulesConfig(productInstance.moduleConfiguration,productDetails.moduleConfiguration,productInstance)
    await this.addKitchenToSceneFromConfiguration(productInstance)
  }


  async addKitchenToSceneFromConfiguration(productInstance:ProductInstance){
    // return new Promise(async (resolve,reject)=>{
      if(!this.group){
        this.group = createGroupForModule()
        this.clamp.createBoundingBoxForClamp(getAreaGroupFromAreaName("Kitchen"))
      }
      this.group.userData.isKitchenGroup = true
      areaModel.add(this.group)
      areaModel.add(this.fillersGroup)
      this.product = productInstance
      this.configuration = this.product.moduleConfiguration
  
      updateUserDataFromProductInstance(this.group,this.product)
  
      let modulesList = this.configuration.modules
      
      // productInstance.moduleConfiguration.configuration = []
      
      // let tarfilekey = applicationConfig.awsConfig.getTarFileKey("models",{clientName:applicationConfig?.clientName,productName:stringWithoutWhiteSpace(this.product.productName)})
      let tarfilekey = applicationConfig.awsConfig.getTarFileKey("models",{clientName:applicationConfig?.clientName,productName:stringWithoutWhiteSpace("LShape1")})
      await untarAndSaveToCache(applicationConfig.awsConfig,`models/${this.product.productName}`,tarfilekey)

  
      for (let i = 0; i < modulesList.length; i++) {
        let module = modulesList[i]

        this.addModule(module).then((object:any)=>{
          module.moduleObjectId = object.uuid
          if(module.moduleType?.toLowerCase().includes("wall")){
            object.userData.isWallMounted = true
            object.userData.isWallProduct = true
            object.userData.isAttachedToWall = true
          }
          object.userData.configuration = module
          object.name = module.moduleName
          this.group.attach(object)
          applyTransformInObject(object,module.transform)
          setInitialSnappedWall(object)
        })
      }
  }


  async addKitchenFromDraggingProduct(productDetails:any){
    if(this.group?.children){
      this.removeKitchen()
    }

    let kitchenType = productDetails.subCategoryName || "lshapekitchen"
    switch (kitchenType.toLowerCase()) {
      case "lshapekitchen":
        await this.addKitchenToscene(lshapeConfiguration)
      break;

      case "ushapekitchen":
        await this.addKitchenToscene(ushapeConfiguration)
      break;

      case "parallelkitchen":
        await this.addKitchenToscene(parallelConfiguration)
      break;

      default:
      break;
    }
    kitchenPlanner.clamp.createBoundingBoxForClamp(getAreaGroupFromAreaName("Kitchen"))
  }

  getObjectByUuid(uuid:string){
    return this.group.children.find(currObject=>currObject.uuid === uuid)
  }

  removeKitchen(){
    this.kitchenModulesList.forEach(modelObject=>{
      modelObject.parent.remove(modelObject)
    })
    this.group.clear()
    areaModel.remove(this.group)
    this.group = null
    kitchenPlanner.kitchenModulesList = []
    this.removeAllFillers()
  }

  removeAllChildren(){
    this.group.children.forEach(modelObject=>{
      modelObject.parent.remove(modelObject)
    })
  }

  setWallModulePositionY(posY:number) {
    this.wallModulePositionY = posY
  }

  removeChildren(){
    this.group.children = []
  }

  removeModule(object:Object3D) {
    kitchenPlanner.removeModuleFromList(object)
    removeNearestFillers(object)
    this.configuration.removeModule(object)
    object.parent.remove(object)
    if(object.userData.isModulesGroup){
      applicationConfig.functions.kitchenPlanner.deleteGroupFromUI(object)
    }
  }

  setDetectedObjectOnDrag(dis:any){
    this.detectedObjectOnDrag = dis
  }
 
  confirmCustomizeKitchen(){
    let position = new Vector3()
    let quaternion = new Quaternion()
    kitchenPlanner.kitchenModulesList.forEach(modelObject=>{
      // let modelObject = object.clone()
      detachModule(modelObject)
      modelObject.getWorldPosition(position)
      modelObject.getWorldQuaternion(quaternion)
      modelObject.position.copy(position)
      modelObject.quaternion.copy(quaternion)
      postAddingActions(modelObject)
      areaModel.add(modelObject)
    })
    // this.group.children.forEach(object=>{
    //   let modelObject = getObjectByObjectId(this.group,object.uuid)
    //   object.parent.remove(modelObject)
    // })
    // areaModel.remove(this.group)
    this.updateKitchenModulesConfiguration()
    deselectProduct()
    removeBoxHelper()
  }

  addModule(module:any){
    return new Promise(async (resolve,reject)=>{

        let moduleName = module.module_name || module.moduleName|| module.product_name
        moduleName = moduleName.substring(0,moduleName.indexOf("-")) || moduleName

        // let tarfilekey = applicationConfig.awsConfig.getTarFileKey("models",{clientName:applicationConfig?.clientName,productName:stringWithoutWhiteSpace(module.product_name)})
        // await untarAndSaveToCache(applicationConfig.awsConfig,`models/${module.product_name}`,tarfilekey)

        let key:string = `models/${stringWithoutWhiteSpace(this.product.originalProductName)}/${stringWithoutWhiteSpace(this.product.originalProductName)}_${moduleName}.glb`
     
        checkFromCache(key)?.then((url:any)=>{
        loadModuleToTheScene(this.loader,url).then(async (modelObject:any)=>{
            kitchenPlanner.kitchenModulesList.push(modelObject)
            setAddedProductDetailsInUserData(modelObject,module)

            postAddingActions(modelObject)
            applyEnvMapInModel(modelObject)
            resolve(modelObject)
        }).catch(err=>{
            console.log(err)
            reject(err)
        })
        }).catch(err=>{
            console.log(err)
            reject(err)
        })
    })
  }

  replaceModuleHandle(module:any){
    if(this.isReplaceMode){
      // console.log(module,CURR_SELECTED_PRODUCT.userData)
      // if(module.sub_module_type != CURR_SELECTED_PRODUCT.userData.subCategoryName){
      //   showToast("Module types are not same",2000,"error")
      //   return
      // }
      let position = new Vector3()
      let quaternion = new Quaternion()
      CURR_SELECTED_PRODUCT.getWorldPosition(position)
      CURR_SELECTED_PRODUCT.getWorldQuaternion(quaternion)
      removeNearestFillers(CURR_SELECTED_PRODUCT)
      let replaceModuleDimensions = getObjectDimensionPosition(null,CURR_SELECTED_PRODUCT)
      let deletedModel = CURR_SELECTED_PRODUCT
      // let removedModuleType = CURR_SELECTED_PRODUCT.userData.configuration.subModuleType || CURR_SELECTED_PRODUCT.userData.configuration.moduleType || CURR_SELECTED_PRODUCT.userData.configuration.moduleName
      removeProduct(CURR_SELECTED_PRODUCT,true)
      this.addModule(module).then((object:any)=>{
        addModelToScene(object)
        addModuleToConfiguration(module,object,this.configuration)
        // postLoadToSceneActions({product_name:module.module_name,category_name:this.product.categoryName,sub_category_name:module.module_type},object)
        object.position.copy(position)
        object.quaternion.copy(quaternion)
        object.visible = true
        // if(!removedModuleType.toLowerCase().includes("corner")){
          updateModulePositionsOnReplace(object,replaceModuleDimensions)
        // }
        this.undo.add("replace",{deletedModel:deletedModel,addedModel:object})
        this.updateKitchenModulesConfiguration()
        closeControlsPanelAndHideProducts()
      })
    }else{
      showToast("Drag to add module",2000)
    }
  }

  setIsReplaceMode(val:boolean){
    this.isReplaceMode = val
  }

  addWallModuleToConfig(module:any,object:any){
    let transform = new Transform()
    kitchenPlanner.this.configuration.addWallModule({module:module,objectId:object.uuid,transform:transform})
    object.userData.transform = transform
    updateCurrSelectedProductConfig(object)
  }
 
 


  updateDragIconPosition(){
    return
    let annotation:any = document.querySelector( `#kitchenDragIcon`)

    const canvas = renderer?.domElement
    let center = getObjectDimensionPosition(null,this.group).center
    // let center = this.group.position
  
    vector?.set(center.x,center.y,center.z)
    vector?.project(controls.camera)
  
    vector.x = Math.round((0.5 + vector?.x / 2) * (canvas.width))
    vector.y = Math.round((0.5 - vector?.y / 2) * (canvas.height))

    annotation.style.top = `${vector?.y}px`
    annotation.style.left = `${vector?.x }px`
 
  }

  detachModule(currArea:Group,module:any) {
    let center = getObjectDimensionPosition(null,currArea).center
    let modelPosition = getObjectDimensionPosition(null,module).center
    let position = new Vector3(center.x,modelPosition.y,center.z)
    this.group.worldToLocal(position)
    module.position.copy(position)
		this.configuration.setLastDeletedModule(module)
    // this.configuration.removeModuleFromConfig(module)
    this.configuration.removeModule(module)
		updateOtherModelPositions(kitchenPlanner.group,kitchenPlanner.configuration,new Box3(),null,"lshape",false)
		module.userData.isSnapped = false
  }

  addModuleToGroup(object:any){
    this.group.add(object)
  }

  addGroupToList(group:Group){
    this.groupsList.push(group)
  }

  removeGroupFromList(group:Group){
    this.groupsList = this.groupsList.filter(currGroup=>currGroup.uuid!=group.uuid)
  }

  addModulesToGroup(){
    this.kitchenModulesList.forEach(modelObject=>{
      this.group.attach(modelObject)
      areaModel.remove(modelObject)
    })
    // areaModel.add(this.group)
  }

  removeModuleFromList(currObject:any){
    this.kitchenModulesList = this.kitchenModulesList.filter(object=> currObject.uuid !== object.uuid)
  }

  updateKitchenModulesConfiguration(){
    //Modules list
    let modulesList = this.configuration.modules
    for (const currModule of modulesList){
      let object = getObjectByObjectId(areaModel,currModule.moduleObjectId)
      var position = new Vector3()
      let rotation = new Quaternion()
      object.getWorldPosition(position)
      object.getWorldQuaternion(rotation)
      currModule.transform.updateTransform(position,rotation,object.scale,object.userData.rotationDelta)
    }
    projectConfiguration.updateLocalStorage()
  }

  snapSurfaceProduct(object:Object3D,detectedObject:Object3D){
    let position = new Vector3()
    let quaternion = new Quaternion()
    detectedObject.getWorldPosition(position)
    detectedObject.getWorldQuaternion(quaternion)
    object.position.copy(position)
    object.quaternion.copy(quaternion)

    let snappedWall = object.userData.snappedWall
    if(snappedWall){
      let planeNormal = snappedWall.userData.normal
      let angle = new Vector2(planeNormal.z,planeNormal.x).angle()
      object.rotation.set(0,0,0)
      rotate(object,angle)
    }

  }

  duplicateModule(object:any){
    let clonedObject = object.clone()
    this.kitchenModulesList.push(clonedObject)
    clonedObject.userData.isAttachedToWall = false
    areaModel.add(clonedObject)
    setDuplicateProductPosition(clonedObject)
    addModelToScene(clonedObject)
    postAddingActions(clonedObject)
    addModuleToConfiguration(clonedObject.userData.configuration,clonedObject,kitchenPlanner.configuration)
    // projectConfiguration.addProduct(clonedObject.userData.configuration,clonedObject,detectedArea)
    this.updateKitchenModulesConfiguration()
    updateAttachIconVisibility()
    deselectProduct()
    this.undo.add("duplicate",{addedModel:clonedObject})
  }

  rotateSurfaceProduct(object:Object3D){

    if(object.userData.snappedWall){
      let wall = object.userData.snappedWall
      let planeNormal = wall.userData.normal
      let angle = new Vector2(planeNormal.z,planeNormal.x).angle()
      object.rotation.set(0,0,0)
      if(angle){
        rotate(object,angle)
        this.clamp.resetClampObjectParameters()
      }
   
    }
    // console.log(object.userData.snappedWall)
  }

  deselectActions(){
    deselectGroupKitchenPlanner()
  }

  addFiller(object:any,detectedObject:any,normal:Vector3,distance:number){
    //dont add filler in corner module
    // if(!object.userData.snappedWall || object.userData.isModulesGroup){
    if(!object.userData.snappedWall || object.userData.isModulesGroup){
      return
    }
    let wallNormal = object.userData.snappedWall.userData.normal
    let draggingObjectDimensions = getObjectDimensionPosition(null,object).dimensions
    let detectedObjectDimensions = getObjectDimensionPosition(null,detectedObject).dimensions
    let position = new Vector3()
    let dimZ =  Math.abs(normal.z) * distance
    let dimX =  Math.abs(normal.x) * distance
    let dimY =  Math.min(detectedObjectDimensions.dimY,draggingObjectDimensions.dimY) 
    var geometry = null
    if(normal.z){
      geometry = new BoxGeometry( draggingObjectDimensions.dimX, dimY , dimZ)
    }else{
      geometry = new BoxGeometry( dimX, dimY , draggingObjectDimensions.dimZ)
    }
    const material = new MeshBasicMaterial( {color: 0x808080} );
    const cube = new Mesh(geometry,material)
    object.getWorldPosition(position)
    cube.position.copy(position)

    if(normal.z && wallNormal.x === -1){
      if(normal.z === 1){
        cube.position.setZ(object.position.z + normal.z * draggingObjectDimensions.dimZ + dimZ/2)
      }
      if(normal.z === -1){
        cube.position.setZ(object.position.z - dimZ/2)
      }
      cube.position.setX(object.position.x - draggingObjectDimensions.dimX/2)
    }

    if(normal.z && wallNormal.x === 1){
      if(normal.z === 1){
        cube.position.setZ(object.position.z + dimZ/2)
      }
      if(normal.z === -1){
        cube.position.setZ(object.position.z + normal.z * draggingObjectDimensions.dimZ - dimZ/2)
      }
      cube.position.setX(object.position.x + draggingObjectDimensions.dimX/2)
    }


    if(normal.x && wallNormal.z === -1){
      if(normal.x === 1){
        cube.position.setX(object.position.x + dimX/2)
      }
      if(normal.x === -1){
        cube.position.setX(object.position.x + normal.x * draggingObjectDimensions.dimX - dimX/2)
      }
      cube.position.setZ(object.position.z - draggingObjectDimensions.dimZ/2)
    }

    if(normal.x && wallNormal.z === 1){
      if(normal.x === 1){
        cube.position.setX(object.position.x + normal.x * draggingObjectDimensions.dimX + dimX/2)
      }
      if(normal.x === -1){
        cube.position.setX(object.position.x - dimX/2)
      }
      cube.position.setZ(object.position.z + draggingObjectDimensions.dimZ/2)
    }

    cube.position.setY(object.position.y + dimY/2)
    this.fillersGroup.attach(cube)
  }

  removeAllFillers(){
    this.fillersGroup.clear()
    this.fillersGroup.parent.remove(this.fillersGroup)
  }

  
  undoHandle() {
    let item = this.undo.getLastItem()
    if(!item){
      showToast("Nothing to undo",2000,"error")
      return
    }
    let action = item.action
    let data = item.data
    let productInstance = null
    let object = null

    switch (action) {  
        case "add":
          removeProduct(data.addedModel,true,false)
          showToast("Deleted modal",2000)
        break;
  
        case "delete":
          areaModel.add(data.deletedModel)
          addModuleToConfiguration(data.deletedModel.userData.configuration,data.deletedModel,kitchenPlanner.configuration)
          showToast("Added modal",2000)
        break;
  
        case "duplicate":
          removeProduct(data.addedModel,true,false)
        break;
        case "replace":
          removeProduct(data.addedModel,true,false)
          areaModel.add(data.deletedModel)
          addModuleToConfiguration(data.deletedModel.userData.configuration,data.deletedModel,kitchenPlanner.configuration)
          showToast("Added modal",2000)
          // showToast("Replaced modal",2000)
        break;
        case "position":
          let position = data.prevPosition
          object = getObjectByObjectId(areaModel,data.objectId)
          if(object){
            removeNearestFillers(object)
            object.position.set(position.x,position.y,position.z)
          }
          showToast("Updated position",2000)
          
        break;
  
        case "rotation":
          let prevQuaternion = data.prevRotation
          object = getObjectByObjectId(areaModel,data.objectId)
          let quaternion = new Quaternion(prevQuaternion._x,prevQuaternion._y,prevQuaternion._z,prevQuaternion._w)
          object.quaternion.copy(quaternion)
          showToast("Updated rotation",2000)
        break;
    
        default:
        break;
    }
    this.undo.removeLastItem()
    deselectProduct()
    this.updateKitchenModulesConfiguration()
  }
}


export class multiselect{
  group:Group
  isAddedToScene:boolean = false
  constructor(){
    this.group = new Group()
    let groupName = "Group"
    this.group.name = groupName
    this.group.userData.isModulesGroup = true
  }

  addItemToGroup(object:Object3D){
    if(!this.isAddedToScene){
      areaModel.add(this.group)
    }
    addItemToGroup(this.group,object,false)
    this.isAddedToScene = true
  }

  removeItemsFromGroup(){
    let children = [...this.group.children]

    children.forEach(modelObject=>{
      unlinkModuleFromGroup(this.group,modelObject)
    })
  }

}
 



export function pointerDownKitchenDrag(event:any){
  isPointerDownKitchenDragIcon = true
  // setCurrSelectedProduct(kitchenPlanner.group,kitchenPlanner.product.originalProductName,kitchenPlanner.product.categoryName,kitchenPlanner.product.subCategoryName)
  setCurrSelectedProduct(kitchenPlanner.group)
  kitchenDimensions = getObjectDimensionPosition(null,kitchenPlanner.group).dimensions
}

export function pointerMoveKitchenDrag(event:any){
  if(isPointerDownKitchenDragIcon){
    let y = event.pageY
    let x = event.pageX
    let pos = getClicked3DPoint(x,y,vector)
    let deltaX = kitchenDimensions.dimX/2
    let deltaY = kitchenDimensions.dimY/2

    let rotation = kitchenPlanner.group.rotation.y
    

                                   
    if(MathUtils.radToDeg(rotation) === -90){
      deltaX = 0
    }

    kitchenPlanner.group.position.set(pos.x,pos.y,pos.z)
    // kitchenPlanner.group.position.set(pos.x,pos.y,pos.z)
    kitchenPlanner.updateDragIconPosition()
    updateDistanceCheckPointsPosition(kitchenPlanner.group)
    updateBoxHelper()
  }
}

export function pointerUpKitchenDrag(event:any){
  isPointerDownKitchenDragIcon = false
  snapProduct(kitchenPlanner.group)
  updateCurrSelectedProductConfig(kitchenPlanner.group)
}


export function rotateKitchen(group:Group) {
    let rotation = -90
    group.rotateOnWorldAxis(new Vector3(0,1,0),Number(rotation) * (Math.PI / 180))
    updateCurrSelectedProductConfig(kitchenPlanner.group)
    updateBoxHelper()
    kitchenPlanner.updateDragIconPosition()
}