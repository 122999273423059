import React, { useState } from "react";
import { useHistory } from "react-router";
import Button2 from "../../common/ui-components/buttons/Button2";
import { logoutHandle } from "../../../utils/methods";

 
const MainHeader: React.FC<{
  
}> = (props) => {


  const[routesList,setRoutesList] = useState(getRoutsList())


  const history = useHistory()
 
  function getRoutsList() {
     return[
      {
        routeName:"Products",
        count:0,
        url:"/inventory/products"
      },
      {
        routeName:"Materials",
        count:0,
        url:"/inventory/materials"
      }
     ]
  }

  function goto(route:string) {
    history.push(route)
  }

  function gotoStore() {
    window.location.href = `/products`
  }
 
  return (
    <div className="full-width padding-container display-flex-row border-bottom no-wrap crm-main-header-wrapper main-header-wrapper">
      <ul className="items display-flex-row no-wrap">
        <div className="logo center-aligned-column" style={{padding:"0"}}>
          <img src="/assets/images/logos/estre.png" height={40}  alt="" />
        </div>
        {routesList.map((currRoute:any,index:number)=>{
          return(
            <li key={index} onClick={()=>goto(currRoute.url)} className={`item display-flex-row border-right pointer center-aligned-column `}>
                <span className="capitalize heading4 color-black font-normal">{currRoute.routeName}</span>
                <div className="center-aligned-column">
                  <span className="heading1 center-aligned-column padding0 font-small color-warning marginleft5" style={{position:"relative",top:"unset",right:"unset"}}>({currRoute.count})</span>
                </div>
            </li>
          )
        })}
      </ul>

      <div className="display-flex-row no-wrap">

        <Button2
          configObj={{}}
          action={()=>gotoStore()}
          label={"Go to store"}
          fontAwesomeIcon={"fas fa-store color-primary"}
          iconPos="right"
          classNames={"no-shadow margin0 bg-color-warning"}
        />

        <Button2
          configObj={{}}
          action={()=>{logoutHandle()}}
          label={"Log out"}
          fontAwesomeIcon={"fas fa-power-off color-danger"}
          iconPos="right"
          classNames={"no-shadow bg-color-light"}
        />
      </div>
    </div>
  );
};

export default MainHeader;
