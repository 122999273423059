import React, { useEffect, useState } from "react";
import AwsConfig from "../../../../../../utils/aws-sdk/AwsConfig";
import { untarAndSaveToCache } from "../../../../../../utils/cache/cache";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray } from "../../../../../../utils/methods";
import { addEventListenerOnImages } from "../../../../../../utils/moodboard/helper";
import { displayThumbnails, hideComponentLoader, showComponentLoader, updateLoaderProgress } from "../../../../../../utils/UI_methods/global";
import CollectionMaterials from "../../../../../common/shared-components/Materials/CollectionMaterials";

const Finish: React.FC<{
  configObj:any
}> = (props) => {


  let awsConfig = new AwsConfig()

  const[textureLoaderId] = useState("textureLoader")

  const[materialTypesMaterials,setMaterialTypesMaterials]:any = useState([])
  const[collections,setCollections]:any = useState([])
  const[companies,setCompanies]:any = useState([])
  const[currSelectedMaterialType,setCurrSelectedMaterialType] = useState<string>("")
  const[currSelectedCompany,setCurrSelectedCompany] = useState<string>("All")
  const[currSelectedCollection,setCurrCollectionName] = useState<string>("All")

  useEffect(()=>{
    addEventListenerOnImages(".image-drag-icon")
  },[materialTypesMaterials])

  useEffect(()=>{
    if(props.configObj){
      // setAllMaterials(props.configObj?.data.materials)
      //Select one company name from all company names 
      let allMaterialTypes:any = getArrayOfDistinctValues(props.configObj?.data.materials,"material_type")
      allMaterialTypes.splice(allMaterialTypes.indexOf("Default"),1)
      setCurrSelectedMaterialType(allMaterialTypes[0])
    }

  },[props.configObj])



  //Untar file for all collections
  useEffect(()=>{
    showComponentLoader(textureLoaderId)
    let filteredMaterials = getFilteredArray(props.configObj?.data.materials,"material_type",currSelectedMaterialType)
    setMaterialTypesMaterials(filteredMaterials)

    let companyNames:Array<any> = getArrayOfDistinctValues(filteredMaterials,"company_name")
    setCompanies(companyNames)
    setCurrSelectedCompany(companyNames[0])

    for (let i = 0; i < companyNames.length; i++) {
      let companyName:string = companyNames[i]
      if(companyName==="Default"){
        continue
      }
      let filteredCompanyMaterials = getFilteredArray(props.configObj?.data.materials,"company_name",companyNames[i])
      let allCollections = getArrayOfDistinctValues(filteredCompanyMaterials,"collection_name")
      for (let j = 0; j < allCollections.length; j++) {
        const collectionName = allCollections[j];
        if(collectionName!="Default"){
          updateLoaderProgress(textureLoaderId,i,allCollections.length,"Downloading textures")
          let key = awsConfig.getTarFileKey("thumbnails",{companyName:companyName,collectionName:collectionName})
          untarAndSaveToCache(awsConfig,`thumbnails/OVL/${companyName}/${collectionName}`,key)?.then(data=>{
            if(j===allCollections.length-1){
              setTimeout(() => {
                displayThumbnails(textureLoaderId,null)
                hideComponentLoader(textureLoaderId)
              }, 500);
            }
  
          }).catch(err=>{
            console.log(err)
            // displayThumbnails()
            if(j===allCollections.length-1){
              setTimeout(() => {
                displayThumbnails(textureLoaderId,null)
                hideComponentLoader(textureLoaderId)
              }, 500);
            }
          })
        }
      }
    }
  },[currSelectedMaterialType])

 


  useEffect(()=>{
    let filteredArray = getFilteredArray(materialTypesMaterials,"company_name",currSelectedCompany)
    let allCollections = getArrayOfDistinctValues(filteredArray,"collection_name")
    setCollections(allCollections)
  },[currSelectedCompany])

 

 
  return (
    <React.Fragment>
      <div className="border-bottom hide-in-mobile full-width ion-margin-bottom">
              <div className="heading1 text-center font-normal full-width padding10">Finish</div>
          </div>
      <CollectionMaterials
        configObj={props.configObj}
        materials={props.configObj.data.materials}
        moduleName="moodboard"
      />
 
    </React.Fragment>
  );
};

export default Finish;
