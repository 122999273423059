export const AddonsConfiguration = [
    {
        id:2,
        name:"CupHolder",
        displayName:"",
        pricing:{
            "Basic":1000,
            "Superior":1200,
            "Premium":1400,
            "Exclusive":1600,
        },
        svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/cupholder.svg",
        description:""
    },
    {
        id:3,
        name:"Ottoman",
        svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/ottoman-square.svg",
        displayName:"",
        options:[
            {
                id:101,
                name:"14inch - Square",
                pricing:{
                    "Basic":3000,
                    "Superior":3500,
                    "Premium":4000,
                    "Exclusive":6000,
                },
                svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/ottoman-square.svg",
                type:"With Storage"
            },
            {
                id:102,
                name:"18inch - Square",
                pricing:{
                    "Basic":3000,
                    "Superior":3500,
                    "Premium":4000,
                    "Exclusive":6000,
                },
                svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/ottoman-square.svg",
                type:"With Storage"
            },
            {
                id:103,
                name:"14inch - Circular",
                pricing:{
                    "Basic":3000,
                    "Superior":3500,
                    "Premium":4000,
                    "Exclusive":6000,
                },
                svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/ottoman-square.svg",
                type:"With Storage"
            },
            {
                id:104,
                name:"18inch - Circular",
                pricing:{
                    "Basic":3000,
                    "Superior":3500,
                    "Premium":4000,
                    "Exclusive":6000,
                },
                svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/ottoman-square.svg",
                type:"With Storage"
            },
            {
                id:105,
                name:"14inch - Square",
                pricing:{
                    "Basic":3000,
                    "Superior":3500,
                    "Premium":4000,
                    "Exclusive":6000,
                },
                svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/ottoman-square.svg",
                type:"Without Storage"
            },
            {
                id:106,
                name:"18inch - Square",
                pricing:{
                    "Basic":3000,
                    "Superior":3500,
                    "Premium":4000,
                    "Exclusive":6000,
                },
                svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/ottoman-square.svg",
                type:"Without Storage"
            },
            {
                id:107,
                name:"14inch - Circular",
                pricing:{
                    "Basic":3000,
                    "Superior":3500,
                    "Premium":4000,
                    "Exclusive":6000,
                },
                svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/ottoman-square.svg",
                type:"Without Storage"
            },
            {
                id:108,
                name:"18inch - Circular",
                pricing:{
                    "Basic":3000,
                    "Superior":3500,
                    "Premium":4000,
                    "Exclusive":6000,
                },
                svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/ottoman-square.svg",
                type:"Without Storage"
            },
        ],
        description:""
    },
    {
        id:7,
        name:"Pillows",
        svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/pillow.svg",
        displayName:"",
        options:[
        {
            id:109,
            name:"Throw Pillow 16 X 16",
            type:"square",
            pricing:{
                "Basic":500,
                "Superior":800,
                "Premium":1000,
                "Exclusive":1200,
            },
            svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/pillow.svg",
            dimensions:"16 X 16" 
        },
        {
            id:110,
            name:"Throw Pillow 18 X 18",
            type:"square",
            pricing:{
                "Basic":500,
                "Superior":800,
                "Premium":1000,
                "Exclusive":1200,
            },
            svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/pillow.svg",
            dimensions:"18 X 18" 
        },
        {
            id:111,
            name:"Throw Pillow 20 X 20",
            type:"square",
            pricing:{
                "Basic":500,
                "Superior":800,
                "Premium":1000,
                "Exclusive":1200,
            },
            svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/pillow.svg",
            dimensions:"20 X 20" 
        },
        {
            id:112,
            name:"Throw Pillow 22 X 22",
            type:"square",
            pricing:{
                "Basic":500,
                "Superior":800,
                "Premium":1000,
                "Exclusive":1200,
            },
            svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/pillow.svg",
            dimensions:"22 X 22" 
        },
        {
            id:113,
            name:"Lumber Pillow 6 X 18",
            type:"rectangle",
            pricing:{
                "Basic":500,
                "Superior":800,
                "Premium":1000,
                "Exclusive":1200,
            },
            svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/pillow.svg",
            dimensions:"6 X 18" 
        },
        {
            id:114,
            name:"Lumber Pillow 7 X 20",
            type:"rectangle",
            pricing:{
                "Basic":500,
                "Superior":800,
                "Premium":1000,
                "Exclusive":1200,
            },
            svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/pillow.svg",
            dimensions:"7 X 20" 
        },
        {
            id:115,
            name:"Round Pillow 16",
            type:"round",
            pricing:{
                "Basic":500,
                "Superior":800,
                "Premium":1000,
                "Exclusive":1200,
            },
            svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/pillow.svg",
            dimensions:"16" 
        },
        {
            id:116,
            name:"Round Pillow 18",
            type:"round",
            pricing:{
                "Basic":500,
                "Superior":800,
                "Premium":1000,
                "Exclusive":1200,
            },
            svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/pillow.svg",
            dimensions:"18" 
        },
        ],
        description:""
    }
]

export const CushionFeelConfiguration = [
    {
        id:1,
        name:"Medium-Soft",
        displayName:"",
        svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/CushioFeel.svg",
        description:"Pocket Sprig + Foma"
    },
    {
        id:2,
        name:"Firm",
        svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/CushioFeel.svg",
        displayName:"",
        description:"Full foam"
    }
    
]

export const SeatSplitOptions = [
    {
        id:1,
        name:"Split By Seat ",
        svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/RemovableSeat.svg",
        price:0,
        displayName:"",
        description:""
    },
    {
        id:2,
        name:"No split ",
        svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/RemovableSeat.svg",
        price:0,
        displayName:"",
        description:""
    },
    {
        id:3,
        name:"Large split",
        svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/RemovableSeat.svg",
        price:0,
        displayName:"",
        description:""
    }
]


export const RemovableSeatBackConfiguration = [
    {
        id:1,
        name:"Fixed",
        price:0,
        displayName:"",
        svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/RemovableSeat.svg",
        description:""
    },
    {
        id:3,
        name:"Detachable",
        price:500,
        options:["Detachable BackRest with zipper","Detachable Seat with zipper"],
        svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/advance-cusomization/RemovableSeat.svg",
        displayName:"",
        description:""
    },
    
]

export const SofaDimensionsConfiguration = [
    {
        id:1,
        name:"SeatDepth",
        displayName:"",
        description:""
    },
    {
        id:2,
        name:"SeatHeight",
        displayName:"",
        description:""
    },
    {
        id:3,
        name:"ArmRestWidth",
        displayName:"",
        description:""
    },
    {
        id:4,
        name:"ArmRestHeight",
        displayName:"",
        description:""
    },
    {
        id:5,
        name:"ArmRestDepth",
        displayName:"",
        description:""
    },
    {
        id:6,
        name:"LegHeight",
        displayName:"",
        description:""
    },
    {
        id:7,
        name:"BackRestHeight",
        displayName:"",
        description:""
    },
    {
        id:8,
        name:"SeatCushionHeight",
        displayName:"",
        description:""
    },
    {
        id:9,
        name:"LoungerDepth",
        displayName:"",
        description:""
    },
    {
        id:10,
        name:"LoungerWidth",
        displayName:"",
        description:""
    },
    {
        id:11,
        name:"Sofa-OuterWidth",
        displayName:"",
        description:""
    },
    {
        id:12,
        name:"Sofa-OuterHeight",
        displayName:"",
        description:""
    },
    {
        id:13,
        name:"Sofa-OuterDepth",
        displayName:"",
        description:""
    },
]




export const LegsConfiguration = [
    {
        id:1,
        name:"Wooden",
        displayName:"",
        description:""
    },
    {
        id:2,
        name:"Metal - Black",
        displayName:"",
        description:""
    },
    {
        id:3,
        name:"Metal - Chrome",
        displayName:"",
        description:""
    },
    {
        id:4,
        name:"Metal - Brass",
        displayName:"",
        description:""
    },
    {
        id:5,
        name:"Metal - RoseGold",
        displayName:"",
        description:""
    },
    {
        id:6,
        name:"DefaultAsPerModel",
        displayName:"",
        description:""
    }
]




export const SplitSeatConfiguration = [
    {
        id:1,
        name:"SeatBasedDivision",
        displayName:"",
        description:""
    },
    {
        id:2,
        name:"LargeDivision",
        displayName:"",
        description:""
    },
    {
        id:3,
        name:"XLSeatBasedDivision",
        displayName:"",
        description:""
    },
    {
        id:4,
        name:"BenchSeat",
        displayName:"",
        description:""
    }
    
]


export const QualityConfiguration = [
    {
        id:1,
        name:"Basic",
        displayName:"",
        description:"1 Year Warranty",
        starCount:[1],
    },
    {
        id:2,
        name:"Superior",
        displayName:"",
        description:"2 Years Warranty",
        starCount:[1,1],
    },
    {
        id:3,
        name:"Premium",
        displayName:"",
        description:"3 Years Warranty",
        starCount:[1,1,1],
    },
    {
        id:4,
        name:"Luxury",
        displayName:"",
        description:"5 Years Warranty",
        starCount:[1,1,1,1,1],
    }
]



export const SofaVariantsConfiguration = [
    // {
    //     id:9,
    //     name:"2+Lounger",
    //     displayName:"",
    //     options:["LHS","RHS"],
    //     noOfSeats:3,
    //     description:"",
    //     categories:["LShape"],
    //     configuration:["Left2","Right3"]
    // },
    // {
    //     id:11,
    //     name:"3+Lounger",
    //     displayName:"",
    //     noOfSeats:4,
    //     options:["LHS","RHS"],
    //     description:"",
    //     categories:["LShape"],
    //     configuration:["Left2","Right3"]
    // },
    // {
    //     id:14,
    //     name:"2+Corner+2",
    //     noOfSeats:5,
    //     displayName:"",
    //     description:"",
    //     categories:["LShape"],
    //     configuration:["Left2","Corner","Right2"]
    // },
    // {
    //     id:15,
    //     name:"2+Corner+3",
    //     noOfSeats:6,
    //     options:["LHS","RHS"],
    //     displayName:"",
    //     description:"",
    //     categories:["LShape"],
    //     configuration:["Left2","Corner","Right2"]
    // },
    // {
    //     id:16,
    //     name:"Lounger+2+Corner+2",
    //     noOfSeats:6,
    //     options:[],
    //     displayName:"Lounger+2+C+2",
    //     description:"",
    //     categories:["LShape"],
    //     configuration:["Left3","Center","Center","Right2"]
    // },
    // {
    //     id:17,
    //     name:"Lounger+2+Corner+3",
    //     noOfSeats:6,
    //     options:[],
    //     displayName:"Lounger+2+C+3",
    //     description:"",
    //     categories:["LShape"],
    //     configuration:["Left3","Center","Center","Corner","Right2"]
    // },
    // {
    //     id:18,
    //     name:"2+Corner+2+Lounger",
    //     noOfSeats:6,
    //     options:[],
    //     displayName:"2+C+2+Lounger",
    //     description:"",
    //     categories:["LShape"],
    //     configuration:["Left1","Corner","Center","Center","Right3"]
    // },
    // {
    //     id:19,
    //     name:"3+Corner+2+Lounger",
    //     noOfSeats:6,
    //     options:[],
    //     displayName:"3+C+2+Lounger",
    //     description:"",
    //     categories:["LShape"],
    //     configuration:["Left2","Corner","Center","Center","Right3"]
    // },

    {
        id:20,
        name:"3+Lounger_LHS",
        noOfSeats:6,
        options:[],
        displayName:"3+Lounger (Left)",
        description:"",
        categories:["LShape"],
        configuration:[],
        isDefault:true
    },

    {
        id:21,
        name:"3+Lounger_RHS",
        noOfSeats:6,
        options:[],
        displayName:"3+Lounger (Right)",
        description:"",
        categories:["LShape"],
        configuration:[],
        isDefault:false
    },




    // {
    //     id:20,
    //     name:"3+2+2",
    //     noOfSeats:7,
    //     options:[],
    //     displayName:"",
    //     description:"",
    //     categories:["SetSofas","3+2+1"],
    //     configuration:["ThreeSeater","DoubleSeater","DoubleSeater"]
    // },
    // {
    //     id:21,
    //     name:"3+2+1",
    //     noOfSeats:6,
    //     options:[],
    //     displayName:"",
    //     description:"",
    //     categories:["SetSofas","3+2+1"],
    //     configuration:["ThreeSeater","DoubleSeater","SingleSeater"]
    // },

   
    {
        id:1,
        name:"1Seater",
        noOfSeats:1,
        displayName:"",
        description:"",
        categories:["SetSofas","3+2+1"],
        configuration:["SingleSeater"],
        isDefault:false
    },
    {
        id:2,
        name:"2Seater",
        noOfSeats:2,
        displayName:"",
        description:"",
        categories:["SetSofas","3+2+1"],
        configuration:["DoubleSeater"],
        isDefault:false
    },
    {
        id:3,
        name:"3Seater",
        noOfSeats:3,
        displayName:"",
        description:"",
        categories:["SetSofas","3+2+1"],
        configuration:["ThreeSeater"],
        isDefault:true
    },
    // {
    //     id:5,
    //     name:"3+2",
    //     noOfSeats:5,
    //     displayName:"",
    //     description:"",
    //     categories:["SetSofas","3+2+1"],
    //     configuration:["ThreeSeater","DoubleSeater"],
    //     isDefault:false
    // },
    // {
    //     id:6,
    //     name:"3+1+1",
    //     noOfSeats:5,
    //     displayName:"",
    //     description:"",
    //     categories:["SetSofas","3+2+1"],
    //     configuration:["ThreeSeater","SingleSeater","SingleSeater"],
    //     isDefault:false
    // },
]

export const QualityDetailsData = [
    {
        material:"Wood Type",
        basic:"Soft Wood",
        essential:"Solid Wood",
        premium:"Solid Wood",
        luxury:"Solid Wood",
    },
    {
        material:"Wood Species",
        basic:"Silver",
        essential:"Wood	Neem",
        premium:"Neem",
        luxury:"Neem",
    },
    {
        material:"Wood Grade",
        basic:"A",
        essential:"A",
        premium:"A",
        luxury:"A+",
    },
    {
        material:"Plywood",
        basic:"Grade",
        essential:"MR",
        premium:"MR",
        luxury:"Calibrated",
    },
    {
        material:"Plywood Thickness",
        basic:"10 MMv",
        essential:"10 MM",
        premium:"12 MM",
        luxury:"12 MM",
    },
    {
        material:"Plywood",
        basic:"Grade",
        essential:"MR",
        premium:"MR",
        luxury:"Calibrated",
    },
    {
        material:"Foam Type",
        basic:"Commercial",
        essential:"Commercial",
        premium:"High resillince",
        luxury:"High resillince",
    },
    {
        material:"Foam Density",
        basic:"32",
        essential:"32",
        premium:"32",
        luxury:"40",
    },
    {
        material:"Foam Lifespan",
        basic:"2-3 years",
        essential:"2-3 years",
        premium:"6-8 years",
        luxury:"10-15 years",
    },
    {
        material:"Foam Thickness",
        basic:"3 Inches",
        essential:"3 Inches",
        premium:"5 Inches & above",
        luxury:"5 Inches & above",
    },
    {
        material:"Fabric Quaity",
        basic:"Standard",
        essential:"Enhanced",
        premium:"Premium",
        luxury:"Luxury",
    },
    {
        material:"Fabric Avg Price/Meter",
        basic:"280",
        essential:"480",
        premium:"700",
        luxury:"1300",
    },
    {
        material:"Fabric Avg Price/Seat",
        basic:"1100",
        essential:"1900",
        premium:"2800",
        luxury:"5200",
    },
    {
        material:"Warranty",
        basic:"1 Year",
        essential:"1 Year",
        premium:"3 Year",
        luxury:"3 Year",
    }
]



























