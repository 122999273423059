import React from "react";
import {
  resizeObject
} from "../../../../../utils/customizein3d/modules/customizein3d";
import Button2 from "../../../ui-components/buttons/Button2";
import { hideResizerWindow } from "../../../../../utils/customizein3d/modules/UI_methods";
import { updateReclinerHeight } from "../../../../../utils/customizein3d/modules/helper";

const ResizeModule: React.FC<{
}> = (props) => {


  function OtherOptions(){
    return(
      <>
        <div className="display-flex-row ion-margin-top">

          <Button2
            configObj={{}}
            action={() => resizeObject("depth", "minus")}
            label={""}
            fontAwesomeIcon={"fas fa-minus color-black"}
            iconPos="left"
            classNames={"shadow-light bg-color-light button-ex-small"}
          />

          <div className="center-aligned-row space-around" style={{minWidth:"8rem"}}>
            <div className="label">Depth:</div>
            <div id="depthValue" className="color-danger center-aligned-column marginleft5">
              20'
            </div>
          </div>

          <Button2
            configObj={{}}
            action={() => resizeObject("depth", "plus")}
            label={""}
            fontAwesomeIcon={"fas fa-plus color-black"}
            iconPos="left"
            classNames={"shadow-light bg-color-light button-ex-small"}
          />

          </div>


          
          <div className="display-flex-row ion-margin-top">

          <Button2
            configObj={{}}
            action={() => resizeObject("height", "minus")}
            label={""}
            fontAwesomeIcon={"fas fa-minus color-black"}
            iconPos="left"
            classNames={"shadow-light bg-color-light button-ex-small"}
          />

          <div className="center-aligned-row space-around" style={{minWidth:"8rem"}}>
            <div className="label">Height:</div>
            <div id="heightValue" className="color-danger center-aligned-column marginleft5">
              20'
            </div>
          </div>

          <Button2
            configObj={{}}
            action={() => resizeObject("height", "plus")}
            label={""}
            fontAwesomeIcon={"fas fa-plus color-black"}
            iconPos="left"
            classNames={"shadow-light bg-color-light button-ex-small"}
          />

          </div>
      </>
    )
  }


  return (
    <div className="bottom-middle object-resizer-container" style={{zIndex:1001,display:"none"}}>
      <div className="white-card padding10" >

        <div className="top-right" onClick={hideResizerWindow}>
          <i className="fas fa-times color-dark"></i>
        </div>
        
        {/* <div className="border-bottom ">
          <div className="heading3  text-center font-md-small">
            Action
          </div>
        </div> */}

        <div className="size-options-wrapper marginTop5">
          <div className="center-aligned-row">
            <div className="wrapper size-options-container">
              <div className="size-display-values">
                
              </div>

              <div className="size-change-form">

                <div className="display-flex-row ion-margin-top">

                  <Button2
                    configObj={{}}
                    action={() => resizeObject("breadth", "minus")}
                    label={""}
                    fontAwesomeIcon={"fas fa-minus color-black"}
                    iconPos="left"
                    classNames={"shadow-light bg-color-light button-ex-small"}
                  />
                  
                  <div className="center-aligned-row space-around" style={{minWidth:"8rem"}}>
                    <div className="label">Breadth:</div>
                    <div id="breadthValue" className="color-danger center-aligned-column marginleft5">
                      20'
                    </div>
                  </div>
                 
                  <Button2
                    configObj={{}}
                    action={() => resizeObject("breadth", "plus")}
                    label={""}
                    fontAwesomeIcon={"fas fa-plus color-black"}
                    iconPos="left"
                    classNames={"shadow-light bg-color-light button-ex-small"}
                  />

                </div>

              
                {/* <OtherOptions/> */}


                <div className="display-none ion-margin-top ">

                  <Button2
                    configObj={{}}
                    action={() => updateReclinerHeight("minus")}
                    label={""}
                    fontAwesomeIcon={"fas fa-minus color-black"}
                    iconPos="left"
                    classNames={"shadow-light bg-color-light button-ex-small"}
                  />
                  
                  <div className="center-aligned-row space-around" style={{minWidth:"8rem"}}>
                    <div className="label">Offset Y:</div>
                    <div id="reclinerHeightValue" className="color-danger center-aligned-column marginleft5">
                      0 In
                    </div>
                  </div>
                 
                  <Button2
                    configObj={{}}
                    action={() => updateReclinerHeight("plus")}
                    label={""}
                    fontAwesomeIcon={"fas fa-plus color-black"}
                    iconPos="left"
                    classNames={"shadow-light bg-color-light button-ex-small"}
                  />

                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      {/* <CloseMenu heading="Back to Menu" action={hideObjectActionPanel} /> */}
    </div>
  );
};

export default ResizeModule;
