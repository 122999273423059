import React from "react";
const Options: React.FC<{
  configObj: any;
  setCurrForm:(value:string)=>void
}> = (props) => {

 
  return (
    <React.Fragment>
      <div className="full-height-width center-aligned-column">
        <div className="form-wrapper">
          <div className="label text-center">To start, Select an option to from the following</div>
          <div className="display-flex-row select-options-wrapper">
            <div className="select-option">
              <div className="icon-wrapper center-aligned-column" onClick={()=>props.setCurrForm("newCollection")}>
                  <i className="fas fa-plus-square icon"></i>
              </div>
              <div className="title">Create new <br/> collection</div>
            </div>

            <div className="select-option">
              <div className="icon-wrapper center-aligned-column"onClick={()=>props.setCurrForm("existingCollection")} >
                  <i className="far fa-images  icon"></i>
              </div>
              <div className="title">Add to <br/> Existing</div>
            </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Options;
