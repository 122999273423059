import React, { useEffect, useState } from 'react';
import '../../../../../style/style.css';
import {IonModal } from '@ionic/react';


const ConfirmationModal: React.FC<{
    isOpen:any
    Component:any
    closeModal:(value:boolean)=>void 
}> = (props) => {

 
  return (
    <React.Fragment>
       <IonModal
          cssClass="confirmation-modal"
          onDidDismiss={() => props.closeModal(false)}
          isOpen={props.isOpen}
          >
        <props.Component/>
        </IonModal>
    </React.Fragment>
  );
};

export default ConfirmationModal;
