import React from "react";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";

 
const CustomInstructionsList: React.FC<{
    configuration:any
}> = (props) => {


    function Instructions() {
        let configuration = props.configuration
        const instructions = configuration.instructions
        return(
            <div className="marginTop10">
                <div className="marginTop10">
                <div className="Pricing"></div>
                {instructions?.length?
                    <table className="table store-quotation-table">
                    <tbody>
                        {instructions.map((ins:any,index:number)=>{
                            return(
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{ins.instruction}</td>
                                    <td>{getFormattedPrice(ins.price||0)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                    </table>
                :
                <div className="center-aligned-column">
                    <div className="heading4">NA</div>
                </div>
                }
            </div>
            </div>
        )
    }
   
 
  return (
    <Instructions/>
  );
};

export default CustomInstructionsList;
