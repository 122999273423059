import React, { useEffect, useState } from "react";
import { applicationConfig } from "../../../../utils/methods";
import Button2 from "../../../common/ui-components/buttons/Button2";
import Rembg from "../../../common/ui-components/forms/rembg/Rembg";

 

const UploadFloorplan: React.FC<{
  setSelectedFile:(file:any)=>void
}> = (props) => {
  
  const [isOpenImageUpload, setIsOpenImageUpload] = useState(false);
  const [imageName, setImageName] = useState("Choose files");
  // const [imageSrc,setImageSrc] = useState(`https://opusassets.s3.ap-south-1.amazonaws.com/uploads/floorplan/${applicationConfig?.clientName}/${props.projectForm.projectName}/flooplan.png`);
 

 

  function selectImage(imageDetails:any) {
    setImageName(imageDetails.selectedFile.name)
    props.setSelectedFile(imageDetails.selectedFile)
  }
  



 
  return (
    <React.Fragment>
      
    <Rembg
        isOpen={isOpenImageUpload}
        setIsOpen={setIsOpenImageUpload}
        uploadImage={selectImage}
        isFloorplan={true}
      />

    <div className="display-flex-row" style={{padding:"10px 10px 100px 0"}}>
        <div className="form-label-input-container full-width">
            <div className="label-container">
              <div className="label">Upload floorplan</div>
            </div>
            <div className="input-container">
               <div onClick={()=>setIsOpenImageUpload(true)} className="display-flex-row  no-wrap" style={{border:"1px solid var(--op-color-dark)",width:"fit-content"}}>
                <div className="heading4 color-dark overflow-hidden center-aligned-column" style={{width:"180px"}}>{imageName}</div>
                <div className="bg-color-light center-aligned-column padding5 heading2 font-normal"  style={{borderLeft:"1px solid var(--op-color-dark)",width:"100px"}}>Browse file</div>
               </div>
            </div>
          </div>
        {/* <img src={imageSrc} width={200} alt="" />
        <Button2
          configObj={applicationConfig}
          action={()=>setIsOpenImageUpload(true)}
          label={"Upload floorplan"}
          fontAwesomeIcon={"fas fa-image color-danger"}
          iconPos="left"
          classNames={"shadow-light bg-color-light"}
        /> */}
      </div>
  </React.Fragment>
        
  );
};

export default UploadFloorplan;
