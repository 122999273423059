import React, { useEffect, useState } from "react";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray } from "../../../utils/methods";
import DropDown from "../../common/ui-components/filters/DropDown";
import CardsFilterHeaderLayout from "../../templates/layouts/page/CardsFilterHeaderLayout";
import TimeStampFilter from "./TimeStampFilter";


const LogsHeader: React.FC<{
  configObj: any;
  setFilteredLogs:(values:Array<any>)=>void
  logsList:Array<any>
}> = (props) => {


  const[eventTypes,setEventypes] = useState(["All","info","error"])
  const[currEventType,setCurrEventType] = useState("All")

  const[moduleNames,setModuleNames] = useState([])
  const[userTypes,setUserTypes] = useState([])
  const[currModuleName,setCurrModuleName] = useState("All")
  const[currUserType,setCurrUserType] = useState("All")

  
  const[actionTypes,setActionTypes] = useState([])
  const[currActionType,setCurrActionType] = useState("All")

  const[currSelectedTimeStamp,setCurrSelectedTimeStamp] = useState("")
  const[logsList] = useState(props.logsList)


  useEffect(()=>{
    let moduleNameList = getArrayOfDistinctValues(logsList,"module_name")
    let userTypeList = getArrayOfDistinctValues(logsList,"username")
    moduleNameList.splice(0,0,"All")
    setModuleNames(moduleNameList)
    setUserTypes(userTypeList)
  },[applicationConfig,logsList])

  useEffect(()=>{
    let actionTypesList = getArrayOfDistinctValues(props.logsList,"action_type")
    actionTypesList.splice(0,0,"All")
    setActionTypes(actionTypesList)
  },[props.logsList])

 
  function updateEventType(val:string) {
    if(val.toLocaleLowerCase() === "all"){
      setCurrEventType(val)
      props.setFilteredLogs(props.logsList)
      return
    }
    let updatedLogList = getFilteredArray(props.logsList,"event_type",val)
    setCurrEventType(val)
    props.setFilteredLogs(updatedLogList)
  }

   
  function updateModuleName(val:string) {
    setCurrActionType("All")
    setCurrEventType("All")

    if(val.toLocaleLowerCase() === "all"){
      setCurrModuleName(val)
      props.setFilteredLogs(logsList)
      return
    }
    let updatedLogList = getFilteredArray(props.logsList,"module_name",val)
    setCurrModuleName(val)
    props.setFilteredLogs(updatedLogList)

  
  }

  function updateActionType(val:string) {
    if(val.toLocaleLowerCase() === "all"){
      setCurrActionType(val)
      props.setFilteredLogs(logsList)
      return
    }
    let updatedLogList = getFilteredArray(props.logsList,"action_type",val)
    setCurrActionType(val)
    props.setFilteredLogs(updatedLogList)
  }

  function updateUserType(val:string) {
    if(val.toLocaleLowerCase() === "all"){
      setCurrActionType(val)
      props.setFilteredLogs(logsList)
      return
    }
    let updatedLogList = getFilteredArray(props.logsList,"username",val)
    setCurrUserType(val)
    props.setFilteredLogs(updatedLogList)
  }


  function filterWithTimestamp(startTimestamp:number,endTimestamp:number) {
    let filteredLogs = logsList?.filter(currLog => Number(currLog.timestamp) >= startTimestamp && Number(currLog.timestamp) <= endTimestamp)
    props.setFilteredLogs(filteredLogs)
  }


  function resetLogs() {
    props.setFilteredLogs(props.logsList)
    setCurrModuleName("All")
    setCurrActionType("All")
    setCurrEventType("All")
    setCurrSelectedTimeStamp("")
  }
 
 

  function FilterHeaderContent() {
    return(
      <div className="display-flex-row no-wrap"
      style={{ justifyContent: "space-between" }}>

     

      <div className="display-flex-row">

        <TimeStampFilter
          configObj={applicationConfig}
          filterWithTimestamp={filterWithTimestamp}
          resetLogs={resetLogs}
          currSelectedTimeStamp={currSelectedTimeStamp}
          setCurrSelectedTimeStamp={setCurrSelectedTimeStamp}
        />
        
        <DropDown
          columns={1}
          classNames="minwidth150"
          values={moduleNames}
          currSelectedValue={currModuleName}
          action={updateModuleName}
          label="Module name"
        />

        <DropDown
          columns={1}
          classNames="minwidth150 marginleft10"
          values={userTypes}
          currSelectedValue={currUserType}
          action={updateUserType}
          label="User type"
        />

        {/* <DropDown
          columns={1}
          classNames="marginleft10 minwidth150"
          values={eventTypes}
          currSelectedValue={currEventType}
          action={updateEventType}
          label="Event Type"
        /> */}

        {/* <DropDown
          columns={1}
          classNames="marginleft10 minwidth150"
          values={actionTypes}
          currSelectedValue={currActionType}
          action={updateActionType}
          label="Action Type"
        /> */}
      </div>
{/* 
      <div className="delete" style={{ marginRight: "12px" }}>
        <Button2
          configObj={applicationConfig}
          action={()=>{}}
          label="Delete All"
          fontAwesomeIcon="fas fa-trash color-danger"
          iconPos="left"
          classNames="shadow-light"
        />
      </div> */}

    </div>
    )
  }

  return (

    <CardsFilterHeaderLayout
      FilterHeaderContent={FilterHeaderContent}
    />
 
  );
};

export default LogsHeader;
