import {
  IonIcon
} from "@ionic/react";
import { albums, brush, colorPalette, images } from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { dragStart } from "../../../../../../utils/moodboard/moodboard";
import { hideItemsPanel } from "../../../../../../utils/moodboard/UI_methods";
import CloseMenu from "../../../../../common/modules/customizein3d/common/CloseMenu";
import CloseMenuCurved from "../../../../../common/modules/customizein3d/common/CloseMenuCurved";
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import ProductDescription from "../common/ProductDescription";
import ColorOptions from "./ColorOptions";
import Finish from "./Finish";
import Layout from "./Layouts";
import MobileTabs from "./MobileTabs";
import MyImages from "./MyImages";
import ProductImages from "./ProductImages";
import Templates from "./Templates";
import { updateAddonFill } from "../../../../../../utils/moodboard/addons";
 

const Controls: React.FC<{
  configObj: any;
  currArea:string
  setProductModelSrc:(src:string)=>void
  tabItems:Array<any>
}> = (props) => {

  const[productLoaderId] = useState("productLoader")

  const[currSelectedTab,setCurrSelectedTab] = useState("")

  useEffect(()=>{
    $(".tab-content").removeClass("--is-active")
    $("#productsContainer").addClass("--is-active")
    setCurrSelectedTab("productsContainer")
  },[props.currArea])
  
  function updateTabValue(event:any,val:string){
    let value = $(event.target).attr("data-tab-target")!
    $(".tab-content").removeClass("--is-active")
    $(".controls-tab").removeClass("--is-active")
    $("#"+val).addClass("--is-active")
    // $(event.target).siblings().removeClass("--is-active")
    $(event.target).addClass("--is-active")
    setCurrSelectedTab(val)
  }


// function updateTabValue(value:string){
//   $(".controls-tab").removeClass("--is-active")
//   $(`#${value}`).addClass("--is-active")
//   setCurrTab(value)
// }

  function ControlsHeader() {
    let tabItems = [{tabName:"productsContainer",tabIcon:albums,tabLabel:"All Products"},
    {tabName:"finishContainer",tabIcon:brush,tabLabel:"Finshes"},
    {tabName:"myImagesContainer",tabIcon:images,tabLabel:"My Images"},
    {tabName:"templatesContainer",tabIcon:colorPalette,tabLabel:"Templates"}
  ]
    return (
      <div className="controls-tab-container">
        {tabItems.map((item:any,index:number)=>{
          let classNames = []
          classNames.push("controls-tab shadow-light")
          if(currSelectedTab===item.tabName){
            classNames.push("--is-active")
          }
          return(
            <div className={classNames.join(" ")} key={index} onClick={(event) => updateTabValue(event,item.tabName)}>
            <IonIcon icon={item.tabIcon} className="pointer-none hide-in-mobile font-large" ios={item.tabIcon}></IonIcon>
            <div className="text heading">{item.tabLabel}</div>
            </div>
          )
        })}
      
      </div>
    );
  }
  


 
  return (
    <div className="full-height-width customize-product-options --is-visible" id="moodboardControlsWrapper">
        {/* <ControlsHeader/> */}
        <MobileTabs
            configObj={props.configObj}
            tabItems={props.tabItems}
          />

        <ProductDescription
          configObj={props.configObj}
          setProductModelSrc={props.setProductModelSrc}
        />
    
        {/* <div className="tab-content-wrapper"> */}
     
        <div id="productsContainer" className="tab-content" >
          <div  className="full-height-width " id="moodboardProductsFilter">
            <ProductImages
              setProductModelSrc={props.setProductModelSrc}
              configObj={props.configObj}
              currArea={props.currArea}
              action={()=>{}}
              moduleName="moodboard"
              dragStart={dragStart}
              parentContainerId="moodboardProductsFilter"
            />
          </div>

         
      </div>

      <div id="myImagesContainer" className="tab-content" >
      <MyImages
        configObj={props.configObj}
      />
      </div>

      <div id="layoutsContainer" className="tab-content" >
      <Layout
        configObj={props.configObj}
      />
      </div>

      <div id="finishContainer" className="tab-content padding5" >
        <Finish
          configObj={props.configObj}
        />
      </div>


      
      <div id="templatesContainer" className="tab-content padding5" >
        <Templates
          configObj={props.configObj}
        />
      </div>

      
      <div id="colorSwatchContainer" className="tab-content padding5" >
        <ColorOptions
          configObj={props.configObj}
          action={updateAddonFill}
        />
      </div>

      <CloseMenuCurved
          action={hideItemsPanel}
          wrapperId={""}
          itemId={"moodboardControlsClose"}
        />

      <div className="hide-in-desktop zindex30 full-width left-bottom">
      <CloseMenu
          action={hideItemsPanel}
          heading={"Back to Menu"}
        />
      </div>
   
   
    </div>
  );
};

export default Controls;
