import React, { useState } from "react";
import { Customer } from "../../../../../../../../utils/store/storeConfiguration";
import Modal from "../../../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import CreateCustomer from "../../../../customer/CreateCustomer/CreateCustomer";
 
const Header: React.FC<{
  refreshVisitors:()=>void
}> = (props) => {

  const[isOpen,setIsOpen] = useState(false)


  
  function LeftContent() {
    return(
      <div className="display-flex-row">

      <div className="center-aligned-column">
        <Button2
          configObj={{id:""}}
          action={()=>{setIsOpen(true)}}
          label={"Add Visitor"}
          fontAwesomeIcon={"fas fa-plus color-dark"}
          iconPos="left"
          classNames={"shadow margin0 button-large bg-color-primary"}
        />
      </div>
      </div>
    )
  }



  function createCustomerCallback() {
    setIsOpen(false)
    props.refreshVisitors()
  }

  function setActiveCustomer(customer:Customer) {
    
  }



  return (
    <div className="full-width position-relative display-flex-row space-between border-bottom bg-color-light calendar-header" style={{padding:"10px 0",height:"fit-content"}}>

        {/* <CreateLead refreshLeads={props.refreshVisitors} isOpen={isOpen} setIsOpen={setIsOpen}/> */}

        <Modal
          id=""
          classNames="small-y-modal"
          onDismiss={()=>setIsOpen(false)}
          isOpen={isOpen}
          Content={()=>(
            <CreateCustomer isCreateTicketMode={false} createCustomerCallback={createCustomerCallback} isCustomerCheckInMode={true} isStartSessionMode={false} setActiveCustomer={setActiveCustomer}/>
          )}
          heading="Start Customer Session"
          FooterContent={()=>(<div></div>)}
          >
        </Modal>

        <LeftContent/>

        <div className="middle">
          {/* <ToggleView setIsShowCalendar={props.setIsShowCalendar}/> */}
        </div>

        {/* <div className="display-flex-row">
          <SearchLeads setSearchedLeadsList={props.setSearchedLeadsList}/>
        </div> */}
        

      </div>  
  );
};

export default Header;
