import React, { useState } from "react";
import { IonSpinner } from "@ionic/react";
import Button2 from "../../../../common/ui-components/buttons/Button2";
 
const Search: React.FC<{
}> = (props) => {


  return (

      <div className="display-flex-row">
          <div className="center-aligned-column marginleft10">
            <div className="display-flex-row position-relative white-card no-shadow" style={{padding:"2px 15px"}}>
              <span className="center-aligned-column"><i className="fas fa-search color-dark"></i></span>
              <span><input type="text" className="input no-focus border-none" placeholder="Search for orders" style={{border:"none",width:"300px"}}/></span>
              
              <div className="top-middle" style={{padding:"0",top:"100%",zIndex:999,width:"342px"}}>
            </div>

            <div className="right-middle visibility-hidden center-aligned-column padding10" style={{marginTop:"0px"}}>
              <IonSpinner name="crescent" />
            </div>
            
            </div>
          </div>

          <Button2
              configObj={{id:""}}
              action={()=>{}}
              label={"Search"}
              fontAwesomeIcon={"fas fa- color-dark"}
              iconPos=""
              classNames={"no-shadow marginleft5 bg-color-primary"}
          />
      </div>  
  );
};

export default Search;
