import React from "react";


const Button: React.FC<{
  configObj: any;
  action:()=>void
  label:string
  fontAwesomeIcon:string
  iconPos:string
  classNames:string
}> = (props) => {

  return (
    <React.Fragment>
      <div className={`position-relative zindex30 shadow-medium button click-effect no-wrap display-flex-row ${props.classNames}`} onClick={()=>props.action()}>
        {props.iconPos==="left"?
        <i className={`${props.fontAwesomeIcon} icon center-aligned-column`}></i>
        :null}
        <span className="text" style={{margin:"0 0.5rem"}}>{props.label}</span>  
        {props.iconPos==="right"?
         <i className={`${props.fontAwesomeIcon} icon center-aligned-column`}></i>
        :null}
      </div> 
    </React.Fragment>
  
  );
};

export default Button;
