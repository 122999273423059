import $ from "jquery";
import { BoxHelper, Object3D, Vector3 } from "three";
import { hideComponentLoader, showComponentLoader, showGlobalToast, showToast } from "../../UI_methods/global";
import { untarAndSaveToCache } from "../../cache/cache";
import { hideKitchenDragIcon, showKitchenDragIcon } from "../../kitchenplanner/UI_methods";
import { setSnappingObjectInKitchenPlanner } from "../../kitchenplanner/helper";
import { applicationConfig, getFilteredArray, stringWithoutWhiteSpace } from "../../methods";
import { addModuleToConfiguration, updateGroupCenter, updateModelBoundingBox } from "../common";
import { hideConfigOptionsWindow } from "../modules/UI_methods";
import { addModule } from "../modules/customizein3d";
import { getDefaultModel } from "../modules/helper";
import { mouseVector, setCurrSelectedProduct } from "../raycasting";
import { hideModularProductActions, showFloorplanOptionWindow, showRotationSlider } from "./UI_methods";
import { hideDistanceAnnotations, setDataForDistanceLines, updateDistanceCheckPointsPosition } from "./annotations";
import { AREA_MODEL_ORIGIN, GROUND_NAME, IS_SPACE_PLANNER_MODE, addModelToScene, deselectProduct, getClicked3DPoint, isKitchenPlannerMode, kitchenPlanner, mouse, projectConfiguration, scene, selectProduct, setCurrAreaName, undo } from "./area3dModel";
import { checkCollisionWithBoundingBox, isWallMountedProduct, postAddingActions } from "./helper";
import { addDraggedProduct, createGroupForModule, setDraggedModelPosition } from "./modules";
import { enableProductConfigureMode } from "./productConfigure";
import { areaWallsBoundingBoxMapping, disableWallsHiding, getAreaFromPoint, getAreaFromWallsBoundingBox, isWallFocusMode, setInitialWallColor } from "./walls";


export var isDraggingProduct = false
export var isAddedModelToScene = false

export var CURR_DRAGGING_PRODUCT = null
export var CURR_DRAGGING_MODEL = null
export var boxHelper = null
var isPointerUpAfterProductDragging = false

var isProductDownloaded = false

var currPosition:any

export function dragStartCustomizer(event: any) {
   
  deselectProduct()
  resetDraggingParameters()
  setCurrDraggingProduct(getProductDetailsFromEvent(event))
  $("#draggableImg").fadeIn(0)

  if(isWallMountedProduct(CURR_DRAGGING_PRODUCT?.productName,CURR_DRAGGING_PRODUCT?.categoryName) && IS_SPACE_PLANNER_MODE){
    showToast("Can't add wall product in 2d mode",2000,"error")
    hideLoaders()
    return
  }

  if(isWallMountedProduct(CURR_DRAGGING_PRODUCT?.productName,CURR_DRAGGING_PRODUCT?.categoryName) && !projectConfiguration.areAreasDefined){
    showFloorplanOptionWindow()
    return
  }

  event.dataTransfer.setDragImage(document.createElement("img"), 0, 0)
  if(CURR_DRAGGING_PRODUCT?.imageType === "module"){
    downloadAndAddModuleToScene()
  }else{
    downloadAndAddProductToScene()
  }
  setDataForDistanceLines()
  hideKitchenDragIcon()
}

function setCurrDraggingProduct(product:any) {
  CURR_DRAGGING_PRODUCT = product
}

function getProductDetailsFromEvent(event:any) {
  return {
    productName:event.target.getAttribute("data-product-name"),
    productId:event.target.getAttribute("data-product-id"),
    categoryId:event.target.getAttribute("data-product-category-id"),
    categoryName:event.target.getAttribute("data-product-category"),
    subCategoryName:event.target.getAttribute("data-product-subcategory"),
    moduleType:event.target.getAttribute("data-module-type"),
    subModuleType:event.target.getAttribute("data-sub-module-type"),
    imageType:event.target.getAttribute("data-image-type"),
  }
}

function hideLoaders() {
  $("#draggableImgLoader").removeClass("--is-active")
  hideComponentLoader("changeFinishLoader")
  $("#draggableImg").fadeOut(700)
}

export function downloadAndAddProductToScene(currProduct:any = null) {
  if((CURR_DRAGGING_PRODUCT?.categoryName.toLowerCase() === "kitchen" && false)){
    return
  }
  let productModules = getFilteredArray(applicationConfig?.data?.productModules,"product_id",currProduct?.product_id || CURR_DRAGGING_PRODUCT?.productId)
  let product = currProduct || {product_name:CURR_DRAGGING_PRODUCT?.productName,category_name:CURR_DRAGGING_PRODUCT?.categoryName,sub_category_name:CURR_DRAGGING_PRODUCT?.subCategoryName}
  addDraggedProduct(product,false).then((model:Object3D)=>{
    // const scale = 1/floorplanner.traceScale
    // model.scale.set(scale,scale,scale)
    isProductDownloaded = true
    $("#draggableImgLoader").removeClass("--is-active")
    CURR_DRAGGING_MODEL = model
    CURR_DRAGGING_MODEL.name = "dragging"
    setCurrSelectedProduct(model)
    if(isPointerUpAfterProductDragging){
      allowDrop(null)
      dropProductAction()
    }
    $("#draggableImg").fadeOut(700)
  }).catch(err=>{
    if(!productModules?.length){
      hideLoaders()
      showToast(err,2000,"error")
    }
    $("#draggableImg").fadeOut(700)
    showGlobalToast(err,2000)
    isProductDownloaded = true
  })
}

function downloadAndAddModuleToScene() {
  kitchenPlanner.addModule({product_name:CURR_DRAGGING_PRODUCT?.productName,category_name:CURR_DRAGGING_PRODUCT?.categoryName,sub_category_name:CURR_DRAGGING_PRODUCT?.subCategoryName}).then(model=>{
    $("#draggableImgLoader").removeClass("--is-active")
    CURR_DRAGGING_MODEL = model
    CURR_DRAGGING_MODEL.name = "dragging"
    if(CURR_DRAGGING_PRODUCT?.moduleType?.toLowerCase().includes("wall")){
      CURR_DRAGGING_MODEL.userData.isWallMounted = true
      CURR_DRAGGING_MODEL.userData.isWallProduct = true
    }
    // setCurrSelectedProduct(model,CURR_DRAGGING_PRODUCT?.productName,CURR_DRAGGING_PRODUCT?.categoryName,CURR_DRAGGING_PRODUCT?.subCategoryName)
    setCurrSelectedProduct(model)
    if(isPointerUpAfterProductDragging){
      allowDrop(null)
      dropProductAction()
      hideComponentLoader("changeFinishLoader")
    }
    $("#draggableImg").fadeOut(500)
    isProductDownloaded = true
  }).catch(err=>{
      $("#draggableImgLoader").removeClass("--is-active")
      isProductDownloaded = true
      hideComponentLoader("changeFinishLoader")
      $("#draggableImg").fadeOut(500)
      
      showToast(err,2000,"error")
  })
}


export function addBoxHelper(object:any,parent:any=null) {
  removeBoxHelper()
  boxHelper = new BoxHelper( object, 0x0058a3)
  if(parent){
    parent.add(boxHelper)
  }
  scene.add(boxHelper)
  return boxHelper
}

export function allowDrop(event: any) {
  $(".hide-on-dragging").css("pointer-events","none")
  if(event){
    currPosition = {
      x:event.pageX,
      y:event.pageY
    }
  }
  if(!isAddedModelToScene && CURR_DRAGGING_MODEL){
    addModelToScene(CURR_DRAGGING_MODEL)
    CURR_DRAGGING_MODEL.visible = false
    isAddedModelToScene = true
    $("#draggableImg").fadeOut(700)
    if(CURR_DRAGGING_MODEL?.isWallMounted){
      disableWallsHiding()
    }
  }
  event?.preventDefault();
  updateDraggingModelPosition(event?.pageX || currPosition.x,event?.pageY || currPosition.y)
  
}


export function updateDraggingModelPosition(posX:any,posY:any,object:any = null) {
  isDraggingProduct = true
  if(!object){
    object = CURR_DRAGGING_MODEL
  }
  if(object){
    mouse.x = posX
    mouse.y = posY

    if(IS_SPACE_PLANNER_MODE){
      updatePositionInSpacePlannerMode(object)
    }else{  
      updatePositionIn3dMode(object) 
    }

    if(isKitchenPlannerMode && !object.userData.isWallMounted){
      kitchenPlanner.clamp.clampObject(object)
    }
   
  }
}

function updatePositionInSpacePlannerMode(object:any) {
  let position:any = getClicked3DPoint(mouse.x,mouse.y,mouseVector);
  // let groundObject = getClickedObjectInArea(intersectsArray)
  let posY = AREA_MODEL_ORIGIN.y
  if(position && object){
    object?.position.set(position.x,posY,position.z)
    object.visible = true
    if(boxHelper){
      boxHelper!.visible = true
      boxHelper!.update()
    }
  }
}

function updatePositionIn3dMode(object:any) {
  let pos = getClicked3DPoint(mouse.x,mouse.y,mouseVector)
  if(pos && object){
    let posY = getPosY(object)
    let position = new Vector3(pos.x - object?.userData.dimensions.dimX / 2,posY,pos.z - object?.userData.dimensions.dimZ / 2)
    object?.position.copy(position)
    object.visible = true
    updateModelBoundingBox(object)
    updateDistanceCheckPointsPosition(object)
  }
}

export function getPosY(object:any) {
  let posY = AREA_MODEL_ORIGIN.y
  if(object.userData.subCategoryName === "Vase" || object.userData.subCategoryName === "Planters"){
    posY = checkCollisionWithBoundingBox(object) || AREA_MODEL_ORIGIN.y
    let resultPosY = checkCollisionWithBoundingBox(object)
    if(resultPosY){
      posY = resultPosY  
    }else{
      posY = AREA_MODEL_ORIGIN.y
      object.userData.isOnProductSurface = false
    }   
  }
  if(object?.userData.isWallMounted){
    posY = areaWallsBoundingBoxMapping["all"].boundingBox.center.y + 1 -  object?.userData.dimensions.dimY / 2
  }
  if(isKitchenPlannerMode && kitchenPlanner.wallModulePositionY && object?.userData.isWallProduct){
    posY = kitchenPlanner.wallModulePositionY || areaWallsBoundingBoxMapping["all"].boundingBox.center.y + 1 -  object?.userData.dimensions.dimY / 2
  }
  
  return posY
}


 

export async function dropProductCustomizer(event: any) {
  isDraggingProduct = false

  if(isWallMountedProduct(CURR_DRAGGING_PRODUCT?.productName,CURR_DRAGGING_PRODUCT?.categoryName) && IS_SPACE_PLANNER_MODE){
    hideComponentLoader("changeFinishLoader")
    return
  }
  showKitchenDragIcon()
  $(".hide-on-dragging").css("pointer-events","all")
  isPointerUpAfterProductDragging = true

  //Temp disabled add false flag
  if(CURR_DRAGGING_PRODUCT?.categoryName.toLowerCase() === "kitchen" && false){
    kitchenPlanner.addKitchenFromDraggingProduct(CURR_DRAGGING_PRODUCT)
    $("#draggableImg").fadeOut(300)
    return
  }
  //Id object is not downloaded and mouse up
  if(!isProductDownloaded){
    $("#draggableImg").fadeOut(700)
    showComponentLoader("changeFinishLoader")
    return
  }

  let productModules = getFilteredArray(applicationConfig?.data?.productModules,"product_id",CURR_DRAGGING_PRODUCT?.productId)
  if(productModules?.length && false){     
    $(".modular-product-actions").removeClass("display-none")
    hideDraggableImage()
    return
  }

  if(isKitchenPlannerMode){
    let module = {
      module_name:CURR_DRAGGING_PRODUCT?.productName,
      module_type:CURR_DRAGGING_PRODUCT?.moduleType,
      sub_module_type:CURR_DRAGGING_PRODUCT?.subModuleType,
    }
    addModuleToConfiguration(module,CURR_DRAGGING_MODEL,kitchenPlanner.configuration)
    kitchenPlanner.updateKitchenModulesConfiguration()
    setSnappingObjectInKitchenPlanner(CURR_DRAGGING_MODEL)
    isAddedModelToScene = false
    if(!isWallFocusMode){
      let detectedArea = getAreaFromWallsBoundingBox(CURR_DRAGGING_MODEL) 
      setDraggedModelPosition(CURR_DRAGGING_MODEL,detectedArea)
    } 
    CURR_DRAGGING_MODEL.name = module.module_name
    kitchenPlanner.undo.add("add",{addedModel:CURR_DRAGGING_MODEL})
    
  }else{
    dropProductAction()
  }
}     

function dropProductAction() {    
  if(isAddedModelToScene && CURR_DRAGGING_MODEL){
    isAddedModelToScene = false
    CURR_DRAGGING_MODEL.name = CURR_DRAGGING_PRODUCT?.productName
    hideDistanceAnnotations()
    setInitialWallColor()
    let detectedArea = getAreaFromWallsBoundingBox(CURR_DRAGGING_MODEL) 
    if(detectedArea && !isKitchenPlannerMode){
      setCurrAreaName(detectedArea)
      let productInstance = projectConfiguration.addProduct(CURR_DRAGGING_PRODUCT,CURR_DRAGGING_MODEL,detectedArea)
      CURR_DRAGGING_MODEL.userData.configuration = productInstance
      undo.add("add",{addedModel:CURR_DRAGGING_MODEL})
      projectConfiguration.setAreaIsConfigChanged(detectedArea,true)
    }
    selectProduct()
    addBoxHelper(CURR_DRAGGING_MODEL)
    if(!isWallFocusMode){
      let detectedArea = getAreaFromWallsBoundingBox(CURR_DRAGGING_MODEL) 
      setDraggedModelPosition(CURR_DRAGGING_MODEL,detectedArea)
    }  
    let productModules = getFilteredArray(applicationConfig?.data?.productModules,"product_id",CURR_DRAGGING_PRODUCT?.productId)
    if(productModules?.length && false){
      $(".modular-product-actions").removeClass("display-none")
      hideDraggableImage()
    }
    hideLoaders()
    resetDraggingParameters(productModules?.length?false:true)
  }
}


export async function configureProduct() {
  showComponentLoader("changeFinishLoader")
  hideDraggableImage()
  let pos = getClicked3DPoint(mouse.x,mouse.y,mouseVector)
  let posY = AREA_MODEL_ORIGIN.y
  let position = new Vector3(pos.x,posY,pos.z)
  let group = createGroupForModule()
  group.position.copy(position)
  group.userData.isProductModular = true
  let detectedArea = getAreaFromPoint(position)
  if(detectedArea){
    if(CURR_DRAGGING_MODEL){
      scene.remove(CURR_DRAGGING_MODEL)
    }
    let productModules = getFilteredArray(applicationConfig?.data?.productModules,"product_id",CURR_DRAGGING_PRODUCT?.productId)
    let productName = CURR_DRAGGING_PRODUCT?.productName
    hideConfigOptionsWindow()
    let module = getDefaultModel(stringWithoutWhiteSpace(CURR_DRAGGING_PRODUCT?.subCategoryName),productModules)
    if(module){
      let productInstance = projectConfiguration.addProduct(CURR_DRAGGING_PRODUCT,group,detectedArea)
      let moduleConfiguration = productInstance.moduleConfiguration
      moduleConfiguration.setProduct(productInstance)
      group.userData.configuration = productInstance
      group.name = module.product_name
      let tarKey = applicationConfig.awsConfig.getTarFileKey("models",{clientName:applicationConfig?.clientName,productName:productName})
      await untarAndSaveToCache(applicationConfig.awsConfig,`models/${productName}`,tarKey)
      addModule(moduleConfiguration,group,module,false,postAddingActions).then((object:any)=>{
        object.position.set(0,0,0)
        updateGroupCenter(group)
        enableProductConfigureMode(group)
        group.visible = true
        hideComponentLoader("changeFinishLoader")
      }).catch(err=>{
        console.log(err)
        
        hideComponentLoader("changeFinishLoader")
      })
    }
  }
  hideModularProductActions()
}



export function confirmAddProduct(){
  hideModularProductActions()
  if(CURR_DRAGGING_MODEL && CURR_DRAGGING_PRODUCT){
    setCurrAreaName(getAreaFromWallsBoundingBox(CURR_DRAGGING_MODEL))
    selectProduct()
    addBoxHelper(CURR_DRAGGING_MODEL)
    projectConfiguration.addProduct(CURR_DRAGGING_PRODUCT,CURR_DRAGGING_MODEL)
    // customizerConfig.addItemToStack("add",{addedModule:CURR_DRAGGING_MODEL})
    CURR_DRAGGING_MODEL.name = CURR_DRAGGING_PRODUCT?.productName
    showRotationSlider()
  }else{
    showToast("Dragging model not found",2000)
  }
  resetDraggingParameters()    
}

export function isOnGround(object:any) {
    if(object?.name != GROUND_NAME){
        showToast("Not on ground / Collision",2000,"error") 
        return false
    }
    return true
}

export function  resetDraggingParameters(isReset:boolean = true){
    removeBoxHelper()
    if(isReset){
      CURR_DRAGGING_PRODUCT = null
      CURR_DRAGGING_MODEL = null
    }
    isDraggingProduct = false
    isPointerUpAfterProductDragging = false
    isProductDownloaded = false
    isAddedModelToScene = false
    $(".hide-on-dragging").css("pointer-events","all")
    $("#draggableImg").fadeOut(0)
}

export function setCurrDraggingModel(object:any) {
  CURR_DRAGGING_MODEL = object
}
 
export function removeBoxHelper() {
    scene.remove(boxHelper)
    boxHelper = null
}

export function updateBoxHelper() {
  if(boxHelper){
    boxHelper!.update()
  }
}



//For mobile view // All images
export function addEventListenerOnMobileDragIcon(className:string){
  let allIcons = document.querySelectorAll(".image-drag-icon")
  // let addImageIcons = document.querySelectorAll(".product-icon-mobile")
  for (let i = 0; i < allIcons.length; i++) {
    allIcons[i].addEventListener("touchstart",startDragging,false)
    allIcons[i].addEventListener("touchmove",startMoving,false)
    allIcons[i].addEventListener("touchend",endDragging,false)
  }
}

function startDragging(event:any) {
  $("#draggableImg").fadeIn(0)

  let image = null

  if($(event.target).parents(".product-image-container").length){
    image = $(event.target).parents(".product-image-container").find("img").attr("src")
  }

  if($(event.target).parents(".finish-image-container").length){
    image = $(event.target).parents(".finish-image-container").find("img").attr("src")
  }
  

  let element = $(event.target).parents(".product-image-container")


  setCurrDraggingProduct({
    productName:element.attr("data-product-name"),
    categoryName:element.attr("data-product-category"),
    subCategoryName:element.attr("data-product-subcategory"),
  })

  downloadAndAddProductToScene()

  let touchLocation = event.targetTouches[0]

  $("#draggableImg").css("left",touchLocation.pageX + 'px')
  $("#draggableImg").css("top",touchLocation.pageY + 'px')
  $("#draggableImg").attr("src",image)
}

function startMoving(event:any) {
  let touchLocation = event.targetTouches[0]
  $("#draggableImg").css("left",touchLocation.pageX + 'px')
  $("#draggableImg").css("top",touchLocation.pageY + 'px')

  updateDraggingModelPosition(touchLocation.pageX,touchLocation.pageY)
}

function endDragging(event:any) {
  $("#draggableImg").fadeOut(0)
}


function hideDraggableImage() {
  $("#draggableImg").fadeOut(200)
}