import { IonAlert, IonCheckbox } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { hideComponentLoader, showComponentLoader, showToast } from "../../../../../../../utils/UI_methods/global";
import { disableFinalizeMode, projectConfiguration, setCurrAreaName } from "../../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { cameraForSettingPosition, cameraSettingViewData, updateCameraSettingViewData } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/createRender";
import { removeAllAnchors, updateAllAreaAnchorsPosition } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/floorplanUI";
import { resetCameraFor2dRender } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/render2d";
import { addCamera, confirmAddCamera, init, removeCamera } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/render3d_new";
import { rendererDimensions } from "../../../../../../../utils/customizein3d/common";
import { updateCameraZoom } from "../../../../../../../utils/customizein3d/controls";
import { applicationConfig, getFilteredArray, waitFor } from "../../../../../../../utils/methods";
import { getPageTourElements } from "../../../../../../../utils/siteTour/elements";
import { startPageTour } from "../../../../../../../utils/siteTour/tour";
import Modal from "../../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import SiteTour from "../../../../../../common/ui-components/siteTour/SiteTour";
import TakeRender from "../TakeRender";

const CreateRender3D_new: React.FC<{
  areasList:Array<any>
}> = (props) => {

  const[currselectedArea,setCurrAreaFloorplan] = useState(null)
  //whether selecting walls or anchr..based on call the submit function

  const[tourElementsList] = useState(getPageTourElements("CreateRender3d"))
  
  const[isAddCameraMode,setIsAddCameraMode] = useState(false)
  const[isOpenSummary,setIsOpenSummary] = useState(false)
  const[showDeleteAlert,setShowDeleteAlert] = useState(false)
  const[isFullScreenMode,setIsFullScreenMode] = useState(false)


  var minimapFrameData = {
    left:0.65,
    bottom:0.30,
    width:0.30,
    height:0.40,
    aspectRatio:4/3
  }

  var frameHeight = Math.floor( rendererDimensions.height * minimapFrameData.height ) + 2 ;
  var frameWidth = Math.floor(frameHeight * minimapFrameData.aspectRatio) 
  var frameLeft = Math.floor(rendererDimensions.width * minimapFrameData.left)  - 1
  var frameBottom = Math.floor( (rendererDimensions.height - frameHeight) / 2 )  - 1

  
  useEffect(()=>{
    projectConfiguration.remove3DCameras()
    removeAllAnchors()
    init()
    // setTimeout(() => {
    //   let camerasList = projectConfiguration.cameras.filter(cam=>cam.renderType==="3d")
    //   if(camerasList.length){
    //     addCamerasFromConfiguration(camerasList)
    //   }
    // }, 500);
  },[])
  
  useEffect(()=>{
    if(currselectedArea){
      setCurrAreaName(currselectedArea)
    }
  },[currselectedArea])

  useEffect(()=>{
    if(props.areasList.length){
      setCurrAreaFloorplan(props.areasList[0].area_name)
      setTimeout(() => {
        hideComponentLoader("canvasLoader")
        startPageTour()
      }, 1000);
      applicationConfig.setFunctionRef("customizer",{setCurrAreaFloorplan:setCurrAreaFloorplan})
      applicationConfig.setFunctionRef("customizer",{setIsFullScreenMode:setIsFullScreenMode})
      applicationConfig.setFunctionRef("customizer",{setIsAddCameraMode:setIsAddCameraMode})
    }
  },[props.areasList])

  useEffect(()=>{
    updateCameraSettingViewData("3d",isFullScreenMode)
    if(isFullScreenMode){
      updateCameraZoom(cameraForSettingPosition,35)
    }else{
      $(".anchor-icon-container").addClass("display-none")
      updateCameraZoom(cameraForSettingPosition,35)

      let position = cameraForSettingPosition.position
      // translateObject(cameraForSettingPosition,new Vector3(1,0,0),5)
      // customizerConfig.setCurrPositionCameraTranslation(5)
      cameraForSettingPosition.position.set(position.x+5,position.y,position.z)

      // cameraForSettingPosition.setViewOffset(canvasDimensions.width,canvasDimensions.height,-10,0,canvasDimensions.width,canvasDimensions.height)
      setTimeout(() => {
        updateAllAreaAnchorsPosition()
        $(".anchor-icon-container").removeClass("display-none")
      }, 200);
    }
  },[isFullScreenMode])
 
 
 

  async function closeCreateRenderHandle() {
    // showComponentLoader("canvasLoader")
    // await waitFor(100)
    resetCameraFor2dRender()
    disableFinalizeMode()
  }

  function Confirmation(){
    return(
    <div className="font-small padding10 overflow-y-scroll full-height">
        <div className="shadow-medium padding10 ion-margin-bottom">
          <table className="table text-start-table">
          <thead>
            <tr>
              <th>Area Name</th>
              <th>Camera Availability</th>
            </tr>
          </thead>

          <tbody>
            {props.areasList?.map((area:any,index:number)=>{
            let isAvailable = projectConfiguration.cameras.find(currCam=>currCam.areaName === area.area_name && currCam.renderType === "3d") || null

            return(
            <tr key={index}>
                <td>{area.area_name}</td>
                <td><IonCheckbox slot="end" color="primary" mode="md" checked={isAvailable?true:false} disabled={true} /></td>
            </tr>
            )
            })}
          
          </tbody>
        </table>
        </div>
    </div>
    )
  }



 

  

  async function addCameraAction(){
    setIsFullScreenMode(false)
    $("#initialMessgaeWindow").removeClass("display-none")
    updateCameraSettingViewData("3d",false)
    addCamera()
    setIsAddCameraMode(true)
  }

  function cancleAddCamera() {
    $("#initialMessgaeWindow").removeClass("display-none")
    setIsAddCameraMode(false)
    removeCamera()
  }

  async function confirmAddCameraHandle() {
    $("#initialMessgaeWindow").removeClass("display-none")
    showComponentLoader("changeFinishLoader")
    setIsAddCameraMode(false)
    await confirmAddCamera()
    // updateCameraTransform()
    hideComponentLoader("changeFinishLoader")
    showToast("Saved..",1000)
  }

  async function confirmAndAddCameraHandle() {
    await confirmAddCameraHandle()
    await waitFor(200)
    addCameraAction()
  }

  function addCameraHandle() {
    setIsAddCameraMode(true)
  }

  function deleteAllHandle() {
    setShowDeleteAlert(true)
  }

  
  async function removeAllHandle() {
    showComponentLoader("changeFinishLoader")
    await waitFor(500)
    projectConfiguration.remove3DCameras()
    hideComponentLoader("changeFinishLoader")
    setIsOpenSummary(false)
    closeCreateRenderHandle()
  }

  function Footer() {
    return(
      <div className="display-flex-row">

          <div className='display-flex-row padding10 pointer' onClick={deleteAllHandle}>
          <div> <i className='fas fa-trash pointer-none color-danger font-'></i> </div>
          <div className='marginleft5 heading2 pointer-none font-md-small center-aligned-column'>Remove cameras</div>
          </div>

        <Button2
          configObj={applicationConfig}
          action={()=>setIsOpenSummary(false)}
          label={"Go back"}
          fontAwesomeIcon={"fas fa-plus color-danger"}
          iconPos=""
          classNames={"bg-color-light button-"}
        />

        <Button2
          configObj={applicationConfig}
          action={()=>applicationConfig.functions.customizer.setShowRenderModal(true)}
          label={"Proceed update walkthrough"}
          fontAwesomeIcon={"fas fa-plus color-danger"}
          iconPos=""
          classNames={"bg-color-primary button-"}
        />
        
      </div>
    )
  }

  function updateWalkthroughHandle() {
    setIsOpenSummary(true)
  }



  return (
    <>

      <Modal
        isOpen={isOpenSummary} 
        classNames="project-action-modal"
        Content={Confirmation}
        FooterContent={Footer}
        id={""}
        onDismiss={setIsOpenSummary}
        heading={"Cameras availablity for update walkthrough"}
        
      />

    <IonAlert
        isOpen={showDeleteAlert}
        mode="ios"
        onDidDismiss={() => setShowDeleteAlert(false)}
        cssClass="my-custom-class"
        header={"Confirm!"}
        message={"Delete <strong>cameras</strong>!!!"}
        buttons={[{
            text: "Cancel",
            role: "Cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              removeAllHandle();
            },
          },
        ]}
      />

    <TakeRender/>


      <SiteTour
        tourElementsList={tourElementsList}
        moduleName={"CreateRender3d"}
      />

    

      {/* <div className="top-left padding0 border-bottom border-right" style={{width:`${cameraSettingViewData.width*100}%`}}>
        <div className="center-aligned-column bg-color-white position-relative" style={{padding:"20px 0"}}>
          <div className="heading2 middle">Set camera position</div>
        </div>
      </div> */}

      {/* {!isFullScreenMode?
        <div className="top-left padding0 border-bottom" style={{left:`${cameraSettingViewData.width*100}%`,width:`${(1-cameraSettingViewData.width)*100}%`}}>
          <div className="center-aligned-column bg-color-white position-relative" style={{padding:"20px 0"}}>
            <div className="heading2 middle">Set camera angle</div>
          </div>
        </div>
      :null} */}

      

     
        <div id="cameraMinimapFrame"  className={isFullScreenMode?"display-none":"bottom-left block initial-hide white-card scene-minimap-frame padding0 zIndex100 pointer-none"}  style={{left:`${frameLeft}px`,bottom:`${frameBottom}px`,width:`${frameWidth}px`,height:`${(frameHeight + 30)}px`}}>
          <div className="position-relative full-height-width">
            <div className="top-left top-header heading3">Preview</div>
          </div>
          <div id="initialMessgaeWindow" className="top-left center-aligned-column full-height-width padding0">
            <div className="heading3 font-mesium color-dark">Create or select camera to load view</div>
          </div>
        </div>
   
    <div id="addCameraButton" className="bottom-middle ion-margin-bottom" style={{left:`${(cameraSettingViewData.width * 100)/2}%`,zIndex:1001,pointerEvents:"all",background:"transparent"}}>
      {!isAddCameraMode?
        <div className="display-flex-row no-wrap">
          <Button2
            configObj={applicationConfig}
            action={addCameraAction}
            label={"Create Camera"}
            fontAwesomeIcon={"fas fa-plus color-danger"}
            iconPos="left"
            classNames={isFullScreenMode?"display-none":"bg-color-primary"}
          />
          {isFullScreenMode?
            <>
              <Button2
                configObj={applicationConfig}
                action={()=>setIsFullScreenMode(false)}
                label={"Edit walkthrough"}
                fontAwesomeIcon={"fas fa-edit color-danger"}
                iconPos="left"
                classNames={getFilteredArray(projectConfiguration.cameras,"renderType","3d").length?"":"display-none"}
              />
              <Button2
                configObj={applicationConfig}
                action={()=>setIsFullScreenMode(false)}
                label={"Create walkthrough"}
                fontAwesomeIcon={"fas fa-edit color-danger"}
                iconPos="left"
                classNames={!getFilteredArray(projectConfiguration.cameras,"renderType","3d").length?"bg-color-primary":"display-none"}
              />
            </>
          :
            null
          }
        </div>
        :
        <div className={`display-flex-row space-between no-wrap`}>
            <Button2
              configObj={applicationConfig}
              action={confirmAddCameraHandle}
              label={"Confirm"}
              fontAwesomeIcon={"fas fa-check color-dark"}
              iconPos="left"
              classNames={"bg-color-primary button-"}
            />

            <Button2
              configObj={applicationConfig}
              action={confirmAndAddCameraHandle}
              label={"Confirm & Add Camera"}
              fontAwesomeIcon={"fas fa-plus-square color-dark"}
              iconPos="left"
              classNames={"bg-color-primary ion-margin-start button-"}
            />
            
            <Button2
              configObj={applicationConfig}
              action={cancleAddCamera}
              label={"Cancle"}
              fontAwesomeIcon={"fas fa-times color-danger"}
              iconPos="left"
              classNames={"bg-color-light ion-margin-start button-"}
            />
        </div>
    
      }
       
      
    </div>

    {
      !isAddCameraMode?
      <>
      <div id="finalizeRenderButton"  className="bottom-right ion-margin-bottom marginRight10" style={{zIndex:1005}}>
        <Button2
          configObj={applicationConfig}
          action={updateWalkthroughHandle}
          label={"Update walkthrough"}
          fontAwesomeIcon={"fas fa-cube color-danger"}
          iconPos="left"
          classNames={getFilteredArray(projectConfiguration.cameras,"renderType","3d").length?"bg-color-primary":"display-none"}
        />
      </div>
      
      <div className="top-right padding5 " style={{zIndex:1005}}>
        <Button2
          configObj={applicationConfig}
          action={closeCreateRenderHandle}
          label={"Close"}
          fontAwesomeIcon={"fas fa-times color-danger"}
          iconPos="left"
          classNames={"button- bg-color-black"}
          />
      </div>
      </>
      :null
    }

    
 </>
  );
};

export default CreateRender3D_new;
 