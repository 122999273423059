import $ from "jquery";
import React, { useState } from 'react';
import { hideComponentLoader, showComponentLoader } from '../../../../../utils/UI_methods/global';
import { appendModel, configuration, resetConfiguration } from '../../../../../utils/customizein3d/modules/customizein3d';
import { getImageFromModules, STORE } from '../../../../../utils/store/storeConfiguration';
import { stringWithoutWhiteSpace, waitFor } from "../../../../../utils/methods";

// import './Home.css';

const LayoutOptions: React.FC<{
}> = (props) => {
 
    const[layoutsList,setLayoutsList] = useState(configuration.layouts.currLayout.getLayoutsList())


    
    async function loadConfiguration(modules:any) {
      // props.closeHandle()
      showComponentLoader("canvasLoader")
      resetConfiguration()
      await waitFor(500)
      for (const module of modules) {
        await appendModel(module,false)
      }
      hideComponentLoader("canvasLoader")
    }


    function showActions(event:any) {
      $(event.target).find(".config-action").fadeIn(100)
      $(event.target).find(".config-action").css("top","0")
    }

    function hideActions(event:any) {
      $(".config-action").fadeOut(0)
      $(".config-action").css("top","-100%")
    }

    function touchStartAction(event:any) {
      hideActions(event)
      showActions(event)
    }
 

    function getImage(modules:any){
      let fileName = ""
      for (const module of modules) {
        let moduleName = module.module_name
        moduleName = moduleName.replace("_XL","")
        moduleName = moduleName.replace("_Centre_Console","")
        moduleName = moduleName.replace("_Centre_Console_XL","")
        fileName = fileName + moduleName + "_"
      }
      fileName = fileName.slice(0, -1)
      let extension = "webp"
      if(STORE.currProduct.sub_category_name === "SetSofas"){
        extension = "svg"
      }
      return `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Sofa/${fileName}.${extension}`
    }


    function getTitle(modules:any){
      let title = ""
      let subCategory = stringWithoutWhiteSpace(STORE.currProduct?.sub_category_name?.toLowerCase()) || ""
      
      if(subCategory === "setsofas"){
        for (const module of modules) {
          let moduleName = module.module_name === "ThreeSeater" ? "3" :  module.module_name === "DoubleSeater" ? "2" :"1"
          title = title + moduleName + "+"
        }
      }
      if(subCategory === "lshape"){
        try {
          for (const module of modules) {
            let moduleName = module.module_name;  
            moduleName = moduleName.replace(/Left_Lounger/g, "Lounger").replace(/Right_Lounger/g, "Lounger");
            const match = moduleName.match(/(1s|2s|3s)/);
            if (match) {
                title += match[0] + "+";
            } else {
                title += moduleName + "+";
            }
          }
        } catch (error) {
          title = "";
        }
      }
      title = title.slice(0, -1)
      return title
    }

    function List() {
      return(
        <div className="renders-list bg-color-white display-flex-row full-height-width" style={{flexWrap:"wrap",paddingBottom:"60rem"}}>
          

            {layoutsList?.map((layout:any,index:number)=>{
              let url = getImage(layout)
              // let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/savedConfigs/${applicationConfig?.clientName}/${product.category_name}/${encodeURIComponent(product.product_name)}/${render.id}.png`
            return(
            <div key={index} className="padding5 position-relative" style={{width:"50%",height:"min-content"}}>
               
               <div className="border full-height-width position-relative"> 

                <div className='display-flex-row space-between padding5'>

                  {/* {configuration.configId === render.id?
                  <span className=''>
                    <i className='far fa-circle store-color-primary'></i>
                  </span>
                  :
                  <span className=''>
                    <i className='fas fa-circle store-color-primary'></i>
                  </span>
                  } */}

                  <div className='display-flex-row'>


                    <div className="center-aligned-column">
                      <div className="display-flex-row custom-tooltip item position-relative">
                        <span className="text  marginleft5 font-small text-ellipsis">{getTitle(layout)}</span>
                      </div>
                    </div>


                  </div>


                </div>

                <div className='center-aligned-column' onClick={()=>{loadConfiguration(layout)}}>
                  <img className="ion-padding pointer-none"
                    alt="" 
                    src={url}
                    style={{transform:"scale(1)",objectFit:"contain",height:"8rem",width:"8rem"}}
                    onError={(e:any) => { e.target.onerror = null; e.target.src = 'assets/images/gallery_placeholder.png'; }}
                  />
                </div>

                
                </div>
                
            </div>
            )
            })}
        <div className="center-aligned-column full-width display-none">
          <div className="heading4 ion-padding text-center full-width" style={{border:"1px dashed lightgray",borderRadius:"10px"}}>No saved Configs</div>
        </div>
  </div>
      )
    }
 
  return (
    <div className="full-height-width overflow-y-scroll">
      <List/>
    </div>
  );
};

export default LayoutOptions;
