import React, { useState } from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from 'react-datepicker';
import { ActivityEvent } from "../../../../../../../utils/store/crm/calendar";
import { getFormatedDate } from "../../../../../../../utils/store/crm/crm";
import Modal from "../../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import "../../../../../../style/store/crm/calendar.css";
import "react-datepicker/dist/react-datepicker.css";


const Calendar: React.FC<{
    lead:any
    activityEvent:ActivityEvent
    callbackAfterDateSelect?:()=>void
    isShowRemarks?:boolean
}> = (props) => {

    //So that we can toggle it
    const [currSelectedEvent,setCurrSelectedEvent] = useState(props.activityEvent?.start?props.activityEvent : null) 

    const today = new Date()

    
    const [showConfirmAlert,setShowConfirmAlert] = useState(false)
  


    const setStartDate = (start:any) => {
        setShowConfirmAlert(true)
        try {
            const newEvent = new ActivityEvent(start,props.activityEvent?.title)
            if(currSelectedEvent){
                if(currSelectedEvent.start.getTime() === start.getTime()){
                    setCurrSelectedEvent(null)
                }else{
                    setCurrSelectedEvent(newEvent)
                    props.activityEvent.start = start
                }
            }else{
                setCurrSelectedEvent(newEvent)
                props.activityEvent.start = start
            } 
        } catch (error) {
            
        }
        
       
    }



    function confirmDateSelection() {
        props.callbackAfterDateSelect()
    }


    function ConfirmationFooter() {
        return(
            <div className="display-flex-row no-wrap">
            <Button2
                configObj={{id:""}}
                action={()=>{setShowConfirmAlert(false)}}
                label={"Go back"}
                fontAwesomeIcon={"fas fa-arrow-right color-dark"}
                iconPos=""
                classNames={"no-shadow bg-color-light"}
            />

            <Button2
                configObj={{id:""}}
                action={()=>{confirmDateSelection()}}
                label={"Confirm"}
                fontAwesomeIcon={"fas fa-arrow-right color-dark"}
                iconPos=""
                classNames={"no-shadow bg-color-primary"}
            />
            </div>
        )
    }

    function ConfirmationForm() {
        return(
            <div className="full-height-width bg-color-light center-aligned-column">
                <div className="width70 ion-padding">
                    <div className="heading4 font-medium color-black text-center capitalize padding10">
                        {props.lead?.customer_name} {props.lead?.customer_name?"'s":""} {props.activityEvent?.title} Scheduling on {getFormatedDate(currSelectedEvent?.start.getTime())}
                    </div>

                    {props.isShowRemarks !== false?
                        <div className="form-label-input-container ion-margin-top">
                            <div className="label-container ">
                            <div className="label">
                                Remarks
                            </div>
                            </div>
                            <div className="input-container">
                                <textarea className="input" id="closeActivityRemarks" style={{height:"75px"}} />
                            </div>
                        </div>
                    :null}

                    
                </div>
            </div>
        )
    }

 
    return (
    <div className="full-height-width center-aligned-column bg-color-light position-relative overflow-y-scroll">

        

        <Modal
            isOpen = {showConfirmAlert}
            onDismiss = {()=>setShowConfirmAlert(false)}
            heading={"Confirm!!!"}
            FooterContent={ConfirmationFooter}
            classNames="small-x-modal"
            id=""
            Content={()=>(
                <ConfirmationForm/>
            )}
        >
        </Modal>

        <DatePicker
            onChange={(date) => setStartDate(date)}
            minDate={today}
            inline
        />
    </div>
    );
};

export default Calendar;
