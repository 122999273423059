import { IonModal } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import PaymentOptions from "./PaymentOptions";
import { STORE } from "../../../../../utils/store/storeConfiguration";

 
const PaymentReview: React.FC<{
  paymentSuccessHandle:()=>void
  totalPrice:number
}> = (props) => {

  const[isOpenPayment,setIsOpenPayment] = useState(false)
  const[isShowError,setIsShowError] = useState(false)

  const[totalPrice] = useState(props.totalPrice - STORE.currDiscount)
  const[minAmount] = useState(Math.floor(totalPrice/2))
  const[advanceAmount,setAdvanceAmount] = useState(Math.floor(totalPrice/2))

  useEffect(()=>{
    STORE.checkoutPricing.advanceAmount = advanceAmount
    STORE.checkoutPricing.totalPrice = totalPrice
  },[advanceAmount,totalPrice])


  function paymentSuccessHandle() {
    props.paymentSuccessHandle()
    setIsOpenPayment(true)
  }

  function updateAdvanceAmount(event:any) {
    let value = Number($(event.target).val())
    if(value < minAmount || value > totalPrice){
      setIsShowError(true)
    }else{
      setIsShowError(false)
    }
    setAdvanceAmount(value)
  }


  function openPayment() {
    setIsOpenPayment(true)
    // props.setIsOpenReview(false)
  }

  return (
    <div className="ion-padding">

      <IonModal
          id=""
          cssClass="payment-modal"
          onDidDismiss={()=>setIsOpenPayment(false)}
          isOpen={isOpenPayment}
        >
        <PaymentOptions price={advanceAmount} isOpen={isOpenPayment} callback={paymentSuccessHandle}/>
      </IonModal>

    <div className="pricing-summary marginTop5">
      <div className="display-flex-row space-between item">
        <div className="heading3 font-md-small">Tota amount</div>
        <div className="heading1 font-md-small rupee-sign">₹{totalPrice}</div>
      </div>
      <div className="display-flex-row space-between item">
        <div className="heading3 font-md-small">Minimum amount to pay</div>
        <div className="heading2 font-md-small rupee-sign">₹{minAmount}</div>
      </div>
      <div className="display-flex-row space-between item">
        <div className="heading3 font-md-small">Discount</div>
        <div className="heading2 font-md-small rupee-sign">{STORE.currDiscount}</div>
      </div>
      <div className="display-flex-row no-wrap space-between marginTop10 item">
        <div className="">
          <div className="heading3 font-md-small">Advance</div>
          <div className="heading4 color-warning font-small">Should be greater then 50% of total amount</div>
        </div>
        <div className="heading3 font-md-small color-success">
          <input type="number" min={minAmount} max={totalPrice} defaultValue={minAmount} className="input" onChange={updateAdvanceAmount} />
          {isShowError?
            <div className="marginTop5">
              <div className="color-danger heading3 rupee-sign font-small">Min amount: ₹{minAmount}</div>
              <div className="color-danger heading3 rupee-sign font-small">Max amount: ₹{totalPrice}</div>
            </div>
          :null}
        </div>
      </div>
      <div className="display-flex-row space-between marginTop10 item">
        <div className="heading3 font-md-small">Ramaining amount</div>
        <div className="heading3 font-md-small rupee-sign">₹{totalPrice-advanceAmount}</div>
      </div>
    </div>  

    



      <div className="full-width center-aligned-row ion-margin-top padding10 marginTop10">
        <Button2
          configObj={{id:""}}
          action={()=>{openPayment()}}
          label={`Confirm & Pay ₹${advanceAmount}`}
          fontAwesomeIcon={"fas fa-plus color-white"}
          iconPos=""
          classNames={`no-shadow rupee-sign color-white button-large bg-color-success margin0 ${!isShowError?" ":" disable"}`}
        />
      </div>
      {isShowError?
        <div className="">
          <div className="color-danger text-center heading3 rupee-sign">Please enter valid amount</div>
        </div>
      :null}

     
    </div>     
  );
};

export default PaymentReview;
