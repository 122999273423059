import React, { useEffect, useState } from "react";
import { getFilteredArray } from "../../../utils/methods";
import ProductCards from "../../common/ui-components/cards/ProductCards";
import CardsSideBarLayout from "../../templates/layouts/page/CardsSideBarLayout";
import FilterHeaderContent from "./FilterHeaderContent";
import ProductDetails from "./ProductDetails";




const ProductsLayout: React.FC<{
  configObj: any;
  setCurrProductDetails: (prodId: number) => void;
  setCurrSelectedCategory: (value: string) => void;
  setCurrSelectedSubCategory: (value: string) => void;
  productsList:Array<any>
  setIsSearchMode:(val:boolean)=>void
  setProductsList:(val:boolean)=>void
  isSearchMode:boolean
  currSelectedCategory:string
  currSelectedSubCategory:string
}> = (props) => {


  const[isShowProductDetails,setIsShowProductDetails] = useState(false)
  const[currProduct,setCurrProduct] = useState(null)

  useEffect(()=>{
    props.configObj.setFunctionRef("products",{setCurrProduct:setCurrProduct})
  },[props.configObj])


  return (
    <React.Fragment>

      <>
        <FilterHeaderContent
          setCurrSelectedCategory={props.setCurrSelectedCategory}
          setCurrSelectedSubCategory={props.setCurrSelectedSubCategory}
          currSelectedSubCategory={props.currSelectedSubCategory}
          currSelectedCategory={props.currSelectedCategory}
          productsList={props.productsList}
          isSearchMode={props.isSearchMode}
          setIsSearchMode={props.setIsSearchMode}
          setProductsList={props.setProductsList}
        />

        <CardsSideBarLayout
          MainContent={()=>(
            <ProductCards
              configObj={props.configObj}
              productsList={props.productsList}
              setCurrProductDetails={props.setCurrProductDetails}
              isSearchMode={props.isSearchMode}
              currSelectedSubCategory={props.currSelectedSubCategory}
            />
          )}
        />
      </>

    </React.Fragment>
  );
};

export default ProductsLayout;
