import $ from "jquery";
import React, { useEffect, useState } from "react";
import Home from "./Home";


  const ProjectActions: React.FC<{
    currProjectId:number
    setOpenProjectAction:(value:boolean)=>void
    currProjectName:string
    // currAreaName:string
    // currAreaId:number
    setProducts:(projectId:number,areaId:number,areaName:string,projectName:string,viewName:string)=>void
    allAreas:Array<any>;
    allViews:Array<any>;
    CLIENT_ID:number
    allProjects:any
    moodBoardSavedconfigs:any
    setCurrProjectDetails:(id:number,name:string)=>void
    configObj:any
    setCurrMoodboardId:(id:string)=>void
    editProject:(id:number)=>void
    refreshProjects:(projects:Array<any>,allAreas:Array<any>)=>void
    allCategories:any
    // setProductsFromView:(projectId:number,areaId:number,areaName:string,projectName:string,viewName:string)=>void
  }> = (props) => {


    const[currState,setCurrState] = useState("Home")

    useEffect(()=>{
      $("#projectActions").removeClass("moodboard-modal")
      if(currState==="MoodBoard"){
        $("#projectActions").addClass("moodboard-modal")
      }
    },[currState])

      function AreaMinimap(){
        let projectAreas = props.allAreas.filter((area:any)=>area.project_id===props.currProjectId)

        return(
          <React.Fragment>
        
        </React.Fragment>
        )
      }
    


    switch(currState){
        case "Home":
        return(
            <Home
             currProjectId={props.currProjectId}
             setCurrState={setCurrState}
             setOpenProjectAction={props.setOpenProjectAction}
             allProjects={props.allProjects}
             allAreas = {props.allAreas}
             moodBoardSavedconfigs={props.moodBoardSavedconfigs}
             setCurrProjectDetails={props.setCurrProjectDetails}
             currProjectName={props.currProjectName}
             CLIENT_ID={props.CLIENT_ID}
             applicationConfigObj={props.configObj}
             setCurrMoodboardId={props.setCurrMoodboardId}
             editProject={props.editProject}
             refreshProjects={props.refreshProjects}
             configObj={props.configObj}
             allCategories={props.allCategories}
            />
        )
        case "Minimap":
        return(
            <AreaMinimap
            />
        )
        break;

        default:
        return(
          <div></div>
        )
    }
     
   
  
    return (
      <div>Some content</div>
    );
  };
  
  export default ProjectActions;
  