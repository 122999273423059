import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getRandomNumber } from "../../../../utils/products/productviewer_shopify";
import { applicationConfig, getFilteredArray, getObjectArrayOfDistictValues, getObjectByParameter, getUser } from "../../../../utils/methods";
import { addItemToLocalStorage } from "../../../../utils/cache/localstorage";
import Collections from "./Collections";
import Button2 from "../../../common/ui-components/buttons/Button2";
 

const TopCollections: React.FC<{
}> = (props) => {

 

  let history = useHistory()
  const [categoryMaterials,setCategoryMaterials]: any = useState([]);
  const [defaultProduct,setDefaultProduct] = useState<any>(null);
  const [currCategory,setCurrCategory] = useState<string>();
  const[collectionsList,setCollectionsList] = useState([])

  useEffect(()=>{
    if(applicationConfig.data?.materials){
      let materials = applicationConfig.data.materials
      let allProductsList = applicationConfig.data.allProductsList
      let filterProducts = [] 

      setCategoryMaterials(materials)
      filterProducts = allProductsList.filter(product => product.category_name === "Sofa")

      applicationConfig.data.productsList = filterProducts
      let resultProduct = getObjectByParameter(filterProducts,"product_name","Linda") || filterProducts[0]
      if(resultProduct){
        setDefaultProduct(resultProduct)
        setCurrCategory(resultProduct.category_name)
        addItemToLocalStorage("currProduct",resultProduct)
      }else{
        // clearCache()
      }
      updateCollections(materials,"Leatherite")
      
    }
  },[])


  function clickHandle() {
    history.push(`/products`)
  }

  function updateCollections(categoryMaterials:any,materialType:string) {
    let collections = []
    collections = getFilteredArray(categoryMaterials,"material_type",materialType)
    collections = getObjectArrayOfDistictValues(collections,"collection_name")
    setCollectionsList(collections.slice(0,4))
  }
  
 
  return (
    <>
    <div className="display-flex-row product-images-container marginTop10 full-height" >
      <Collections materialsList={categoryMaterials} defaultProduct={defaultProduct} currCategory={currCategory} collectionsList={collectionsList}/>
{/* 
      {categories?.map((category:any,index:number)=>{
        return(
          <div onClick={()=>clickHandle(category)} className={`category-details-wrapper padding5 image-small`} key={index}>
            <div className="">
              <div className="image product-image-container position-relative">
                <img src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/elevate/images/category/${category.name}.jpg`} alt="" />
              </div>
                <div className="padding10 center-aligned-column">
                  <div className="heading2 text-center color-black font-medium">{category.displayName}</div>
                  <div className="heading4 text-center rupee-sign marginTop5 font-normal color-black">From ₹ {getRandomNumber(10000,25487)}.00</div>
                </div>
              <div className="no-shadow marginleft5  display-flex-row no-wrap">
              </div>
            </div>
          </div>
        )
      })} */}
      <div className="center-aligned-column full-width" style={{padding:"0"}}>
        <Button2
          configObj={{}}
          action={()=>{clickHandle()}}
          label={"Explore All"}
          fontAwesomeIcon={"fas fa-arrow-right color-primary"}
          iconPos={""}
          classNames={"bg-color-primary button-medium"}
        />
      </div>
      
    </div> 
    </>
  );
};

export default TopCollections;
