import React, { useEffect, useState } from "react";
import { applicationConfig, logger } from "../../../../../utils/methods";
import { configuration } from "../../../../../utils/customizein3d/modules/customizein3d";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import { updateSofaLeg } from "../../../../../utils/customizein3d/modules/legs";

const SofaLegs: React.FC<{
}> = (props) => {
 
  const[legs] = useState(applicationConfig.data.sofaLegs)
  const[currLeg,setCurrLeg] = useState(null)

  // useEffect(()=>{
  //   if(legs?.length){
  //     setCurrLeg(legs[0])
  //   }
  // },[legs])



  function updateLeg(leg:any){
    setCurrLeg(leg)
    configuration.currSofaLeg = leg
    updateSofaLeg(leg)
    showGlobalToast("Leg updated",2000)
    logger?.info("customizein3d","Leg")
  }


  return (
      <div className="full-height-width display-flex-row overflow-y-scroll bg-color-white padding10">
        {legs?.map((leg:any,index:number)=>{
          return(
            <div onClick={()=>{updateLeg(leg)}} key={index} className={`padding5 pointer ${leg.leg_name === "Leg5"?" display-none":" "}`} style={{width:"33%",height:"8rem"}}>
              <img src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/SofaLegs/png/${leg.leg_name}.png`} className="full-height-width contain" style={{border:`1px solid ${currLeg?.leg_name === leg.leg_name?"var(--op-color-primary)":"var(--op-color-light)"}`}} alt="" />
            </div>
          )
        })}
      </div>
    );
};

export default SofaLegs;
