export class ProjectForm{
    projectName: string =  "NewDemo"
    projectLocation: string =  "Delhi"
    clientName: string = "DemoClient2"
    clientMobile: string =  ""
    projectType: string =  ""
    developer: string =  ""
    status:string = ""
    apartmentType:string = ""
    projectId:number = 0

    isShowAreaHint:boolean = true
    isShowProductsHint:boolean = true
    isShowMaterialsHint:boolean = true

    projectMaterials = new ProjectMaterials()




    bedroomCount = 0
    bathroomCount = 0

    constructor(){

    }
}


class ProjectMaterials{
    list = []
    constructor(){

    }
}