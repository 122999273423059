import React, { useState } from "react";
import Modal from "../../../../common/shared-components/Modals/Modal";
import { STORE } from "../../../../../utils/store/storeConfiguration";
 
const SofaPartImage: React.FC<{
    product:any
}> = (props) => {

    const[isOpen,setIsOpen] = useState(false)

    function getFabricIconUrl(){
        let url = ""
        let category = props.product?.category_name
        if(category === "Sofa"){
            url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/orders/fabricmapping/Sofa/Sofa.svg`
        }
        if(category === "UpholsteredBed"){
            url = `${STORE.BASE_URL_ASSETS}icons/category/UpholsteredBed.svg`
        }
        if(category === "ArmChair"){
            url = `${STORE.BASE_URL_ASSETS}icons/category/ArmChair.svg`
        }
        if(category === "Recliner"){
            url = `${STORE.BASE_URL_ASSETS}icons/category/Recliner.svg`
        }
        if(category === "SofaBed"){
            url = `${STORE.BASE_URL_ASSETS}icons/category/SofaBed.svg`
        }
        return url
    }
    
  return (
    <>

    <span id="partMappingHelper" onClick={()=>{setIsOpen(true)}}>
        <i className="fas fa-question-circle color-black"></i>
    </span>

    <Modal
        isOpen={isOpen}
        onDismiss={()=>{setIsOpen(false)}}
        classNames="medium-y-modal"
        FooterContent={()=>(<></>)}
        Content={()=>(
            <div className="full-height-width overflow-y-scroll">
                <img  src={getFabricIconUrl()}  className="full-width" alt="" />
            </div>
        )}
        heading={""}
        id=""
    />
    </>
   
  );
};

export default SofaPartImage;
