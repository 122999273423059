import React from "react";
import { useHistory } from "react-router";

 
const MainHeader: React.FC<{
}> = (props) => {


  const history = useHistory()
 
  function gotoHome() {
    window.location.href = "/"
  }
 

 
  return (
    <>
    <div className="text-center color-white elevate-bg-color-primary padding5 heading4">Free Shipping Till 26 March!!!</div>
    
      <div className="main-header-wrapper  position-relative display-flex-row no-wrap padding-container">
        <div className="header-left-content">

          <div className="position-relative hide-in-mobile">
            <span className="left-middle"><i className="fas fa-search color-dark"></i></span>
            <input type="text" className="white-card input no-shadow" style={{minWidth:"20rem",paddingLeft:"3rem"}} placeholder="What can we help you find?" />
          </div>

          <div className="position-relative hide-in-desktop" onClick={gotoHome}> 
            <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/elevate/icons/logo.png" alt="" style={{width:"10rem"}} />
          </div>


        </div>

        <div className="header-middle-content middle" onClick={gotoHome}>
              <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/elevate/icons/logo.png" alt="" className="hide-in-mobile"  style={{height:"85%",objectFit:"contain"}} />
        </div>

        <div className="header-right-content position-relative">

          <div className="display-flex-row no-wrap">



          <div className="display-flex-row no-wrap pointer icon-wrapper">
            <span className="center-aligned-column marginRight5 hide-in-mobile">Order & Sign in</span>
            <span className="center-aligned-column pointer-none position-relative "><i className="fas fa-user color-warning font-large"></i>
            </span>
          </div>

          
          <div className="display-flex-row no-wrap pointer icon-wrapper">
            <span className="center-aligned-column pointer-none position-relative "><i className="far fa-heart color-warning font-large"></i>
            </span>
          </div>


          <div className="display-flex-row no-wrap pointer icon-wrapper">
            <span className="center-aligned-column pointer-none position-relative "><i className="fas fa-map-marker-alt color-warning font-large"></i>
            </span>
          </div>


          <div className="display-flex-row no-wrap pointer icon-wrapper">
            <span className="center-aligned-column pointer-none position-relative "><i className="fas fa-cart-plus color-warning font-large"></i>
              <div className="badge cart-item-counter" id="cartItemCounterInner">0</div>
            </span>
          </div>

          </div>

          
        </div>

    </div>
    </>     
  );
};

export default MainHeader;
