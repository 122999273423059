import React from "react";
import { createProductForm } from "../../../../utils/products/createProductForm";
import { SofaVariantsConfiguration } from "../../../../utils/store/customization-options-data/sofa";

const DefaultConigForm: React.FC<{
  productDetails:createProductForm
}> = (props) => {



  function setVariant(variant:string) {
    props.productDetails.defaultConfiguration.variant = variant
  }


  return (
    <React.Fragment>
      <div className="padding10">

        <div className="custom-row">
          <div className="custom-col-50">
            <div className="form-label-input-container">
              <div className="label-container">
                <div className="label">
                  Variant  
                </div>
              </div>
              <div className="input-container">
                <select defaultValue={props.productDetails?.defaultConfiguration?.variant} className={false?"input disable":"input"} name=""   onChange={(e)=>setVariant(String(e.target.value))}>
                    <option value={""}>Select Variant</option>
                    {SofaVariantsConfiguration.map((variant:any,index:number)=>{
                        return(
                            <option value={variant.name} key={index}>{variant.name}</option>
                        )
                    })}
                </select>
              </div>
            </div>
          </div>
          
        </div>

       
        
      </div>
    </React.Fragment>
  );
};

export default DefaultConigForm;
