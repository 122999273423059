import axios from "axios";
import { AWS_AUTH_TOKEN, BASE_PATH } from "./api";


export class InventoryApi{
  
  stock = new Stock()
 
  
  constructor(){

  }

}


class Stock{
  constructor(){

  }

  create = (details:any) => {
    let data = {
      details:details,
    }
    return axios
      .post(`${BASE_PATH}api/inventory/stock/create`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  get = () => {
    return axios
      .get(`${BASE_PATH}api/inventory/stock`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }
}


 