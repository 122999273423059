import { IonCheckbox, IonLabel } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { setSiteTourUserPreference } from '../../../../utils/cache/localstorage';
import { closeTour, init, start } from "../../../../utils/siteTour/tour";
import "../../../style/ui-components/tour.css";
 
const SiteTour: React.FC<{
    tourElementsList:Array<any>
    moduleName:string
  }> = (props) => {

    const[tourMode,setTourMode] = useState("")


    useEffect(()=>{
        setTimeout(() => {
            init(props.tourElementsList,setTourMode,`guideWrapper${props.moduleName}`,props.moduleName)
        }, 3000);
    },[props.tourElementsList,props.moduleName])


    function ThumbGuide() {
        return(
            <React.Fragment>
              <div className="addImage">
                <i className="far fa-hand-point-up" id="guideThumb"></i>
            </div>
            {/* <div className="tour-information bottom-middle">
                <div className="tour-heading center-aligned-row">Drag images to add items into the canvas</div>
                <div className="tour-button center-aligned-row">
                    <button className="op-btn op-btn-light op-btn-lg">Don't show again</button>
                    <button className="marginleft5 op-btn op-btn-primary op-btn-lg" onClick={closeTour}>Got it</button>
                </div>
            </div>     */}
            </React.Fragment>
        )
    }

    function dontShowAgain() {
        setSiteTourUserPreference(props.moduleName,false)
    }


 
  return (

    <React.Fragment>
        <div className="tour-main-wrapper" id={`guideWrapper${props.moduleName}`}>

            {/* {props.tourElementsList.map((element:any,index:number)=>{
                return(
                    <div className='white-card padding10'>
                        <div className='header heading2 display-flex-row space-between'>
                            Heading
                        </div>
                        <div className='heading4 font-normal'>{index+1 / props.tourElementsList.length}/</div>
                        <div className='heading4'>{element.calloutMessage}</div>
                    </div>
                )
            })} */}

              <div className="tour-heading center-aligned-row white-card" id="tourMessage"></div>
                <div id="tourNextButton" className="display-flex-row zindex100">
            </div>
            
            <div className="tour-information bottom-middle center-aligned-column">
                <div className="tour-button center-aligned-row">
                    <div className="color-white bg-color-primary font-medium zindex30 border " id="startTourButton" onClick={start}>Start tour <i className="fas fa-play color-white"></i> </div>
                    <div className="color-white font-medium marginleft5 zindex30 border " onClick={closeTour}>Skip Tour <i className="fas fa-times color-white"></i> </div>
                </div>

                <div className="center-aligned-row ion-margin-top">
                    <IonCheckbox checked={false} onIonChange={dontShowAgain} />
                    <IonLabel className="color-white marginleft5">Don't show again</IonLabel>
                </div>
            </div>    
        </div>
    </React.Fragment>
  );
};

export default SiteTour;
