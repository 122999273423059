import React from "react";
import { getTodayDateObjectOnlyDate } from "../../../../../../../../utils/store/crm/calendar";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import SelectInput from "../../../../../../../common/ui-components/input/SelectInput";
import MultipleFileUpload from "../../../../../../../common/ui-components/forms/MultipleFileUpload";
import { applicationConfig, getRandomString, stringWithoutWhiteSpace } from "../../../../../../../../utils/methods";
import Modal from "../../../../../../../common/shared-components/Modals/Modal";
import { showGlobalToast } from "../../../../../../../../utils/UI_methods/global";
import $ from "jquery"
 
const AssignSalesAssistForm: React.FC<{
    lead:any
    isOpenAssignSalesAssist:boolean
    setisOpenAssignSalesAssist:(val:boolean)=>void
    closeActivityHandle:()=>void
    nextActivityEvent:any
}> = (props) => {

    // const[nextActivity,setNextActivity]:any = useState({})


    var files = []

    function submitSalesAssistHandle() {
        let assignedTo = $("#leadAssignedToValue").val()
        if(assignedTo){
            props.nextActivityEvent.start = getTodayDateObjectOnlyDate()
            props.closeActivityHandle()
            CRM.crmApi.leads.updateLead(props.lead.lead_id,{assignedTo:Number(assignedTo)})
            for (const file of files) {
                uploadProductImage(file) 
            }
        }else{
            showGlobalToast("Can not find user",2000,"error")
        }
       
    }



    function setImages(value:any) {
        files = value
    }

    async function uploadProductImage(file:any) {
        let fileName = getRandomString(5) + ".png"
        let albumName = "public"
        var albumPhotosKey = encodeURIComponent(albumName) + "/estre/salesassist/" + props.lead?.lead_id + "/"  
        await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,file,fileName)
    }


    function Form() {
        return(
            <div className="padding10 full-height-width ion-padding overflow-y-scroll">
                    <div className="">
                        <SelectInput
                            id="leadAssignedToValue"
                            label="Assign to"
                            isMandatory={false}
                            onChange={()=>{}}
                            options={CRM.data.users.filter(currUser => (currUser.user_type === "SalesAssist" || currUser.user_type === "Manager" || currUser.user_type === "SalesSpecialist") && currUser.username !== applicationConfig?.user?.username)}
                            className="input"
                            value={""}
                            config={{value:"user_id",displayName:"username"}}
                        />
                    </div>

                    <div className="form-label-input-container ion-margin-top">
                        <div className="label-container ">
                        <div className="label">
                            Remarks
                        </div>
                        </div>
                        <div className="input-container">
                            <textarea className="input" id="closeActivityRemarks" style={{height:"10rem"}} />
                        </div>
                    </div>

                    <div className="upload-form">
                        <MultipleFileUpload 
                            heading="Upload files"
                            setFiles={setImages}
                            acceptExtensions = ".png,.jpeg"
                            multiple = {true}
                        />
                    </div>


                </div>
        )
    }

    return (
    

    <Modal
        isOpen = {props.isOpenAssignSalesAssist}
        Content = {()=>(
            <Form/>
        )}
        onDismiss = {()=>props.setisOpenAssignSalesAssist(false)}
        heading={"Assign sales assist"}
        FooterContent={()=>(
            <Button2
                configObj={{id:""}}
                action={()=>{submitSalesAssistHandle()}}
                label={"Submit"}
                fontAwesomeIcon={"fas fa-arrow-right color-primary"}
                iconPos=""
                classNames={"no-shadow bg-color-primary"}
            />
        )}
        classNames="medium-y-modal"
        id=""
    />
    );
};

export default AssignSalesAssistForm;
