import $ from "jquery";
import React, { useEffect } from "react";
import { rippleEffectOnClick } from "../../../../../utils/UI_methods/ui_effects";
import { isPotraitMode } from "../../../../../utils/customizein3d/common";
import {
  closeMenuHandleLandscape,
  enableConfigureMode,
  hideActionTabs
} from "../../../../../utils/customizein3d/modules/UI_methods";
import CloseMenu from "../common/CloseMenu";
import { STORE } from "../../../../../utils/store/storeConfiguration";

const Controls: React.FC<{
  tabItems:Array<any>
  isSpacePlannerMode:boolean
  setIsSpacePlannerMode:(value:boolean)=>void
  configObj:any
}> = (props) => {

  // const[currSelectedTab,setCurrSelectedTab] = useState("addModule")
  
  useEffect(() => {
    if(!isPotraitMode()){
      // if (props.isSpacePlannerMode) {
      //   setTimeout(() => {
      //     updateTabValue("roomLayoutContainer","roomLayout")
      //   }, 500);
      // } else {
      //   updateTabValue("addModulesContainer","addModule",false)
      // }
      props.configObj.setFunctionRef("customizein3d",{updateTabValueDesktop:updateTabValue})
    }
   
  }, [props.isSpacePlannerMode,props.configObj]);

  function updateTabValue(tabContentId: any, tabId: string,isEnable:boolean = true) {
    hideActionTabs()

    //If toggling space planner dont updateFraming 
    if(tabId === "roomLayout"){
      enableConfigureMode(false)
    }
    if(isEnable){
      enableConfigureMode()
    }

    $(".tab-content").removeClass("--is-active");
    $("#" + tabContentId).addClass("--is-active");

    // setCurrSelectedTab(tabId)

    //Load texture when finish tab is active
    // if (tabContentId === "finishContainer") {
    //   props.setTextures(props.textures);
    // }

    if (tabId === "roomLayout" && !props.isSpacePlannerMode) {
      props.setIsSpacePlannerMode(true);
    }
  }



  return (
    <React.Fragment>
      <div className="controls-tab-container  main-tab-container border customizein3d-controls-tab-container" id="customizein3dActionTabs">
        <div className="full-height-width customizein3d-tabs-wrapper"
          style={{ justifyContent: "flex-start", padding: "0", opacity: "0" }}>
          <div className="heading2 full-width padding1rem text-center color-danger border-bottom">
            Customize
          </div>
          <div className="overflow-y-scroll full-height-width padding-bottom10">
          {props.tabItems.map((item: any, index: number) => {
            let classNames = [];
            classNames.push("controls-tab border-bottom overflow-hidden position-relative "+ item.tabId);
           
            return (
              <div
                id={item.tabId}
                className={classNames.join(" ")}
                onPointerDown={rippleEffectOnClick}
                key={index}
                onClick={(event) => {
                  if(item.isCustomTab){
                    STORE?.functionRefs.productCustomizer.openAdvanceConfiguration()
                  }else{
                    updateTabValue(item.tabName, item.tabId);
                  }
                }}>
                <img
                  src={item.img}
                  alt=""
                  className="tab-icon-image"
                  width="40"
                  height="40"
                />
                <div className="text pointer-none heading">{item.tabLabel}</div>
              </div>
            );
          })}
          </div>
        </div>

        <CloseMenu
          heading="Close"
          action={closeMenuHandleLandscape}
        />


      </div>
 
    </React.Fragment>
  );
};

export default Controls;
