import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { openResolutionPanel, tabsInitialState } from "../../../../../utils/rembg/rembg";
import Loader from "../../../shared-components/main-ui/Loader";
import ImageActions from "./ImageActions";
import Review from "./Review";
import UploadFile from "./UploadFile";

const RembgForm: React.FC<{
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  uploadImage: (val: any) => void
  isFloorplan:boolean
}> = (props) => {


  const [renderingTime, setRenderingTime] = useState(0)


  const [selectedFile, setSelectedFile] = useState(null)
  const [isBackgroundRemoved, setIsBackgroundRemoved] = useState(false)
  const [isSelectResolutions, setIsSelectResolutions] = useState(false)

  const [isFinalizeImage, setIsFinalizeImage] = useState(false)
  const [finalizeType, setFinalizeType] = useState("original")

  const [loaderId] = useState("rembgLoader")

  useEffect(() => {
    setSelectedFile(null)
  }, [props.isOpen])






  function handleResolutionChange(event) {
    let text = $.trim($(event.target).children("option:selected").text())
    // props.finishDetails["companyId"] = event.target.value
    // props.finishDetails["companyName"] = text

  }

  function initialState() {
    setSelectedFile(null)
    setIsBackgroundRemoved(false)
    setIsSelectResolutions(false)
    setRenderingTime(0)
    setIsFinalizeImage(false)
  
  }

  function imageActions() {
    setRenderingTime(0)
    setIsFinalizeImage(false)
    setTimeout(() => {
      tabsInitialState()
    }, 200);
  }


  function finalizeImage(value: string) {
    setFinalizeType(value)
    setIsFinalizeImage(true)
  }



  


  return (
    <>
      <Loader
        id={loaderId}
        data={{ gifSrc: "assets/gif/setting.gif" }}
        loaderType="svg"
        className="fade-background-loader"
      />

      {isBackgroundRemoved ?
        <div className="bottom-right ion-padding">
          <div className="display-flex-row">
            <span className="heading2 font-normal">Processing Time : </span>
            <span className="font-normal color-dark heading2 marginleft5">
              {renderingTime} Sec
            </span>
          </div>
        </div>
        : null}

      <div className="modal-header heading1">

        {selectedFile ?
          <div className="left-middle zindex30 pointer center-aligned-column padding0" onClick={initialState}>
            <div className="heading2 letter-spacing font-normal display-flex-row  color-black">
              <i className="fas fa-chevron-left color-primary  center-aligned-column"></i>
              <span className="marginleft5 capitalize color-black">Go back</span></div>
          </div>
          : null}

          <div className="right-middle">
            <IonIcon
            icon={close}
            className="font-large"
            onClick={() => props.setIsOpen(false)}></IonIcon>
          </div>
     
        Upload Image
      </div>


      <div className="modal-body">


        {
          isFinalizeImage || (selectedFile && props.isFloorplan) ?
            <Review
              selectedFile={selectedFile}
              initialState={imageActions}
              finalizeType={finalizeType}
              uploadImage={props.uploadImage}
              setIsOpen={props.setIsOpen}
            />
            :
            selectedFile?
              <ImageActions
                selectedFile={selectedFile}
                isBackgroundRemoved={isBackgroundRemoved}
                setIsBackgroundRemoved={setIsBackgroundRemoved}
                setRenderingTime={setRenderingTime}
                setIsSelectResolutions={setIsSelectResolutions}
                finalizeImage={finalizeImage}
              />
              :
              <UploadFile
                setSelectedFile={setSelectedFile}
                setIsSelectResolutions={setIsSelectResolutions}
              />
        }



        {/* {
        isBackgroundRemoved?
        <Review
          selectedFile={selectedFile}
          initialState={initialState}
        />
        :
          selectedFile ?
            !isSelectResolutions?
            <ImageActions
              selectedFile={selectedFile}
              isBackgroundRemoved={isBackgroundRemoved}
              setIsBackgroundRemoved={setIsBackgroundRemoved}
              setRenderingTime={setRenderingTime}
              setIsSelectResolutions={setIsSelectResolutions}
            />
            :
            <Resolutions
              selectedFile={selectedFile}
              setIsBackgroundRemoved={setIsBackgroundRemoved}
              setRenderingTime={setRenderingTime}
            />
        :
        <UploadFile
          setSelectedFile={setSelectedFile}
          setIsSelectResolutions={setIsSelectResolutions}
        />
      } */}
      </div>

      <div className="modal-footer position-relative">
        {selectedFile && !isBackgroundRemoved && !props.isFloorplan?
        <div className="left-middle">
          <button className="rembg-inverse-button display-flex-row" onClick={openResolutionPanel}>
            <span className="center-aligned-column full-height-width">
              <span className="text center-aligned-row  marginleft5">
                <i className="fas fa-cog center-aligned-column marginRight5 icon"
                  aria-hidden="true"></i>
                Remove background
                <span className="font-ex-small color-danger marginleft5">Experimental</span>
              </span>
            </span>
          </button>
        </div>
        :null}
      </div>
    </>
  );
};

export default RembgForm;
