import { IonModal } from "@ionic/react";
import { arrowUndoCircleOutline, colorPalette } from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { configuration, disableLoading, group } from "../../../../../utils/customizein3d/modules/customizein3d";
import { enableModelViewer, enableModelViewerForSceneViewer, enableSceneViewer, generateModelFromExporter, generateModelLink, getModelLinkForSceneViewer, viewInAr } from "../../../../../utils/customizein3d/webxr";
import { isTouchDevice } from "../../../../../utils/customizer/helper";
import { applicationConfig, getObjectByParameter, logger, waitFor } from "../../../../../utils/methods";
import { getMobileOperatingSystem, hideComponentLoader, showComponentLoader, showGlobalToast, showToast } from "../../../../../utils/UI_methods/global";
import LaunchAr from "../../../shared-components/AR/LaunchAr";
import Modal from "../../../shared-components/Modals/Modal";
import QRcode from "./QRcode";
import Button2 from "../../../ui-components/buttons/Button2";
import ProductViewer from "../../../../admin/products/productviewer/ProductViewer";
import { ModelViewer, productViewerInit } from "../../../../../utils/customizein3d/productViewer/productViewer";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Finish from "../controls/Finish";
import { getBlobUrlFromExporter } from "../../../../../utils/webxr/webxr";
import Layouts from "../layouts/Layouts";
import LayoutsListWebXr from "../layouts/LayoutsListWebXr";

const ARLinks: React.FC<{
  configObj: any;
  isViewProductMode:boolean
}> = (props) => {

  const [url, setUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [isCreateModel, setIsCreateModel] = useState(false);

  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);

  var isXrMode = true

  const finishContainerRef = useRef(null);
  const layoutContainerRef = useRef(null);

  useEffect(()=>{
    STORE.isPresentingWebXr = isOpenViewProduct
  },[isOpenViewProduct])
  
  function closeModel(){
    setIsCreateModel(false)
    $(".confirm-createmodel-wrapper").removeClass("--is-active")
  }

  async function selectARMode() {
    getLinkForModules()   
  }

  async function getLinkForModules(isXrMode:boolean = true) {
    closeModel()
    hideComponentLoader("mainLoaderSpinner")
    await getBlobUrlFromExporter().then((url:string)=>{
      hideComponentLoader("canvasLoader")
      if(isXrMode){
        viewProductInModelViewer(url,isXrMode)
      }else{
        viewProductInModelViewer(url,isXrMode)
      }
    }).catch(err=>{
      hideComponentLoader("canvasLoader")
    }) 
  }



  function getQrCodeLink() {
    if(props.isViewProductMode){
      generateModelFromExporter()
      .then((link: string) => {
        setUrl(link)
        setIsOpen(true)
        })
        .catch((err) => {
          console.log(err);
      });
      return
    }
    generateModelLink()
      .then((link: string) => {
        console.log(link)
        setUrl(link)
        setIsOpen(true)
      })
      .catch((err) => {
        console.log(err);
    });
  }

 

  function XrClickHandle(isXr:boolean = false) {
    isXrMode = isXr
    if(isTouchDevice()){
      getLinkForModules(isXrMode)
    }else{
      getQrCodeLink()
    }
    return
    if(isTouchDevice()){
      $("#confirmCreateModel").addClass("--is-active")
    }else{
      getQrCodeLink()
    }
  }

  function ArClickHandle() {
    $("#confirmCreateModel").removeClass("--is-active")
    if(!configuration.isConfigChanged && isTouchDevice()){
        let savedConfig = getObjectByParameter(applicationConfig?.data?.savedProductConfiguration,"config_hash",configuration.configHash)
        if(savedConfig){
            setUrl(savedConfig.model_url)
            if(getMobileOperatingSystem() === "ios"){
              viewProductInModelViewerSceneViewer(savedConfig.model_url)
            }else{
              $(".lunch-ar-wrapper").addClass("--is-active")
            }
            return
        }
        
    }
    showComponentLoader("canvasLoader")
    getModelLinkForSceneViewer().then((url:string)=>{
      setUrl(url)
      hideComponentLoader("canvasLoader")
      if(getMobileOperatingSystem() === "ios"){
        viewProductInModelViewerSceneViewer(url)
      }else{
        $(".lunch-ar-wrapper").addClass("--is-active")
      }
    }).catch(err=>{
      console.log(err)
      hideComponentLoader("canvasLoader")
      showGlobalToast("Error in genrating link",2000,"error")
    })
    // let savedConfig = getObjectByParameter(applicationConfig?.data?.savedProductConfiguration,"config_hash",configuration.configHash)
    // if(savedConfig){
    //     setUrl(savedConfig.model_url)
    // }
  }

  function ModalFooter() {
    return(
      <>
    
      </>
      
    )
  }

  async function viewProductInModelViewer(url:string,isXrMode:boolean) {
    try {   
      disableLoading()
      setIsOpenViewProduct(true)
      await waitFor(500)
      let product = STORE.currProduct
      productViewerInit(product,"productViewerConfigurator")
      return
      // let MODEL_VIEWER = null
      // MODEL_VIEWER = enableModelViewer(url,"productViewerConfigurator",isXrMode)
      let modelviewer = new ModelViewer()
      modelviewer.loadWithUrl("productViewerConfigurator",url)
      STORE.modelViewer = modelviewer
      // if(isXrMode){
        $($(finishContainerRef.current)).removeClass("display-none")
        $($(finishContainerRef.current)).find(".materials-wrapper").removeClass("overflow-y-scroll")
        finishContainerRef.current.addEventListener('beforexrselect', (ev) => {
          ev.preventDefault();
        });
        $($(layoutContainerRef.current)).removeClass("display-none")
        layoutContainerRef.current.addEventListener('beforexrselect', (ev) => {
          ev.preventDefault();
        });
        $("#modelViewerElement").append($(finishContainerRef.current))
        $("#modelViewerElement").append($(layoutContainerRef.current))
      // }
  
      hideComponentLoader("canvasLoader")
    } catch (error) {
      console.log(error)
      showGlobalToast(error,2000,"error")
    }
  }


  async function viewProductInModelViewerSceneViewer(url:string) {
    try {
      disableLoading()
      setIsOpenViewProduct(true)
      await waitFor(500)
      productViewerInit(configuration.product,"productViewerConfigurator",url)
    } catch (error) {
      showToast(error,2000,"error")
    }
  }

  function closeProductViewer() {
    setIsOpenViewProduct(false)
    hideComponentLoader("canvasLoader")
  }


  function generateModelConfirmation(){
    if(configuration.isViewProductMode){
      getLinkForModules(isXrMode)
      return
    }

    if(isTouchDevice()){
      $("#confirmCreateModel").addClass("--is-active")
    }else{
      getQrCodeLink()
    }
  }
 

  return (
    <React.Fragment>
      <IonModal
        id=""
        cssClass="project-steps-modal"
        onDidDismiss={() => setIsOpen(false)}
        isOpen={isOpen}>
          <>
          {url?
            <QRcode 
              configObj={props.configObj}
              url = {url} 
            />
          :null}
          </>
        
      </IonModal>


        <LaunchAr
          url={url}
        />

        <Modal
          isOpen = {isOpenViewProduct}
          Content = {()=>(
            <ProductViewer
              id="productViewerConfigurator"
            />
          )}
          onDismiss = {closeProductViewer}
          heading={"Product viewer"}
          classNames="form-action-modal"
          id=""
          FooterContent={ModalFooter}
        />


        <div ref={finishContainerRef} className={`full-width visibility-hidden finish-container-webxr left-bottom display-none`}>
          <Finish
            configObj={applicationConfig}
          />
        </div>

        <div ref={layoutContainerRef} className='top-left display-none visibility-hidden full-width' style={{top:"2rem"}}>
          <div className="display-flex-row no-wrap full-width">
            <Button2
                configObj={{id:""}}
                action={()=>{STORE.modelViewer.captureViewerScreenshot()}}
                label={""}
                fontAwesomeIcon={"fas fa-camera color-black"}
                iconPos="left"
                classNames={"shadow-medium no-border button-small"}
            />
            {/* <LayoutsListWebXr/> */}
          </div>
          
        </div>
 
        <div className="confirm-createmodel-wrapper" id="confirmCreateModel">
        <div className="center-aligned-column full-height-width">

        <div className="top-right ion-padding ion-margin-top">
          <button onClick={closeModel} 
            style={{background:"transparent"}}
            className="color-white font-large button marginleft5 zindex30 border">
              <i className="fas fa-times font-large color-danger marginRight5"></i>
              Close
          </button>
        </div>
        
        <div className="center-aligned-row full-width bottom-middle ion-margin-bottom ion-padding" style={{marginBottom:"5rem"}}>
          <button onClick={ArClickHandle} 
            style={{background:"transparent"}}
            className="color-white font-large  full-width button marginRight5 marginleft5 zindex30 border">
              <i className="fas fa-cube color-white font-large marginRight5"></i>
              Generate 3d model
          </button>
        </div>
        </div>
      </div>

      <a style={{visibility:"hidden",position:"fixed"}} className="top-left" id="sceneViewer" href="">Avocado</a>

      <div  className="action-link-container display-flex-row no-wrap pointer">

        <Button2
            configObj={{id:"arButtonProduct"}}
            action={()=>{generateModelConfirmation()}}
            label={"AR"}
            fontAwesomeIcon={"fas fa-cube color-white"}
            iconPos="left"
            classNames={"shadow-medium no-border button-small bg-color-primary"}
        />

        <Button2
            configObj={{id:"XRButtonProduct"}}
            action={()=>{XrClickHandle(true)}}
            label={"XR"}
            fontAwesomeIcon={"fas fa-cube color-black"}
            iconPos="left"
            classNames={"shadow-medium no-border button-small display-none bg-color-light"}
        />

      </div>
    </React.Fragment>
  );
};

export default ARLinks;
