import React from "react";
import { applicationConfig, getObjectByParameter } from "../../../../../utils/methods";

 

const Options: React.FC<{
    configObj:any
    areaList:Array<any>
    selectedCategories:any
    allCategories:any
    isShowPrice:boolean
}> = (props) => {

 
const getCategories = (areaName:string) => {
  return props.selectedCategories[areaName]
}

  return (
    <React.Fragment>
    <div className="project-area-product-details marginTop10 white-card no-shadow padding10 position-relative">
      <div className="heading2 color-danger">Product categories</div>
      <div className="display-flex-row space-between">
        {props.areaList?.map((area: any, index: number) => {
          let categories = getCategories(area.area_name);
          return (
            <div className={"marginTop10 bg-color-light white-card shadow-light padding10"} style={{width:"49.5%"}} key={index}>
              <div  className="heading3 font-md-small marginRight10">{area.area_name}</div>
              <div className="display-flex-row marginTop10">
                {categories?.map((currCategory:any,index:number)=>{
                  return(
                    <div className="white-card  border padding10 marginRight10 no-shadow center-aligned-column" key={index}>
                      <div className="">
                        <div className="center-aligned-column">
                          <img width={30} src={`${applicationConfig.aws_public_url}assets/images/icons/productIcons/${currCategory.categoryName}.png`} alt="" />
                        </div>
                        <div className="heading4 text-center font-small">{currCategory.categoryName}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          );
        })}
      </div>
      

    </div>
  </React.Fragment>
        
  );
};

export default Options;
