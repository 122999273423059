import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { updateModelAvailability } from "../../../services/api";
import { getFilteredArray } from "../../../utils/methods";
import { filterUIElements } from "../../../utils/UI_methods/global";
import "../../style/admin/project.css";
import MainSideBarLayout from "../../templates/layouts/page/MainSideBarLayout";
import Filters from "./Filters";
import ProjectsContainer from "./ProjectsContainer";

const Projects: React.FC<{
  projects: Array<any>;
  allAreas: Array<any>;
  allViews: Array<any>;
  BASE_PATH_API: string;
  CLIENT_ID: number;
  setCurrProjectDetails: (id: number, name: string) => void;
  allCategories: any;
  moodBoardSavedconfigs: any;
  configObj: any;
  setCurrMoodboardId: (id: string) => void;
}> = (props) => {
  const [allProjectsCount, setAllProjectsCount] = useState<number>(0);
  const [progressProjectsCount, setProgressProjectsCount] = useState<number>(0);
  const [completedProjectsCount, setCompletedProjectsCount] =
    useState<number>(0);

  var TOTAL_STATES: any = 4;
  const history = useHistory();

  const [projects, setProjects]: Array<any> = useState(props.projects);
  const [allAreas, setAllAreas]: Array<any> = useState(props.allAreas);




  function refreshProjects(projects: Array<any>, allProjectAreas: Array<any>) {
    setProjects(projects);
    setAllAreas(allProjectAreas);
  }

  //Function to remove project after deleting



  useEffect(() => {
    if (projects) {
      setAllProjectsCount(projects?.length);
      let completedProjectsCount = getFilteredArray(
        projects,
        "status",
        TOTAL_STATES
      ).length;
      let progressCount = projects?.length - completedProjectsCount;
      setCompletedProjectsCount(completedProjectsCount);
      setProgressProjectsCount(progressCount);
    }
  }, [projects]);


  function filterProjects(event:any,state:string) {
    $(".project-status").removeClass("--is-active")
    $(event.target).addClass("--is-active")
    if(Number(state)){
      filterUIElements(".project-container","data-project-status",state)
    }else{
      filterUIElements(".project-container","data-project-status","")
    }
  }

  

 
  function HeaderLeftComponent() {
    return (
      <div className="brand">
        {/* <img src="/assets/images/opus.png" alt="Opus"/> */}
        <div className="heading font-medium">Projects</div>
      </div>
    );
  }

 
  function CardsContainer(){
    return(
      <ProjectsContainer
        projects={projects}
        configObj={props.configObj}
        gotoCustomizer={props.configObj.functions.gotoCustomizer}
        allViews={props.allViews}
        allAreas={props.allAreas}
        moodBoardSavedconfigs={props.moodBoardSavedconfigs}
        setCurrProjectDetails={props.setCurrProjectDetails}
        setCurrMoodboardId={props.setCurrMoodboardId}
        refreshProjects={refreshProjects}
        allCategories={props.allCategories}
      />
    )
  }

  
  return (
    <React.Fragment>
      <MainSideBarLayout 
        configObj={props.configObj}
        CardsContainer={CardsContainer}
        HeaderLeftContent={HeaderLeftComponent}
        SideFiltersContainer={Filters}
        HeaderMiddleContent={null}
        contentWrapperId="mainProjectsContentWrapper"
      />
    </React.Fragment>
  );
};

export default Projects;
