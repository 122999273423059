import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { applicationConfig, getArrayOfDistinctValues, getCapitalizeString, getFilteredArray, getObjectByParameter, getToken, getUser, waitFor } from "../../../../utils/methods";
import DropDown from "../../../common/ui-components/filters/DropDown";
import Button2 from "../../../common/ui-components/buttons/Button2";
import { addMaterialToCart } from "../../../../utils/store/product-cart";
import { IonModal } from "@ionic/react";
import MaterialImage from "../../../common/ui-components/elements/MaterialImage";
import { getFormattedPrice, getRandomNumber } from "../../../../utils/products/productviewer_shopify";
import { hideComponentLoader, showComponentLoader, showGlobalToast, whatsappAction } from "../../../../utils/UI_methods/global";
import { MaterialCartConfig } from "../../../../utils/kanvas/kanvas";
import $ from "jquery"
import { getTodayTimestampOnlyDate } from "../../../../utils/store/crm/calendar";
import { STORE } from "../../../../utils/store/storeConfiguration";
import { getTextures } from "../../../../services/api";
import { useHistory } from "react-router";
import { getItemFromLocalStorage } from "../../../../utils/cache/localstorage";
 

const PlaceOrder: React.FC<{
  collectionName:any
  companyName:any
}> = (props) => {


  const[allMaterials,setAllMaterials] = useState([])
  const[materials,setMaterials] = useState([])
  const[isOpenOrder,setIsOpenOrder] = useState(false)
  const[currMaterialCode,setcurrMaterialCode] = useState("Select sequence number")
  const[currMaterial,setcurrMaterial] = useState(null)
  const[addedMaterials,setAddedMaterials] = useState([])
  const [state,setState] = useState("initial");
  const [isOpenConfirmWindow,setIsOpenConfirmWindow] = useState(false);
  const [isOrderPlaced,setIsOrderPlaced] = useState(false);


  useEffect(()=>{

    let isLogin = getItemFromLocalStorage("isLogin")
    if(isLogin){
      getTextures().then(data=>{
        applicationConfig.data.materials = data
        setAllMaterials(data)
      }).catch(err=>{
        setAllMaterials([])
      })
    }else{
      window.location.href = "/access-denied"
    }
    
  },[])

  

  useEffect(()=>{
    let result = getFilteredArray(allMaterials,"collection_name",props.collectionName)
    result = getArrayOfDistinctValues(result,"material_code")
    setMaterials(result)
    // setcurrMaterialCode(result[0])
    setState("initial")
    setcurrMaterialCode("Select sequence number")
    setcurrMaterial(null)
    setAddedMaterials([])
  },[props.collectionName,isOpenOrder,allMaterials])

  function selectMaterialHandle(materialCode:string){
      setcurrMaterialCode(materialCode)    
      let material = getObjectByParameter(allMaterials,"material_code",materialCode)
      if(material){
        setcurrMaterial(material)
      }else{
        showGlobalToast("material not found",2000,"error")
      }

  }
 
 

  function addMaterialAction(){
    if(!currMaterial?.length){
      alert("Please enter length")
      return
    }
    let material = new MaterialCartConfig(currMaterial)
    setAddedMaterials(prevValues=>{
      return [material,...prevValues]
    })
    setcurrMaterial(null)
    setIsOpenConfirmWindow(false)
    setcurrMaterialCode("Select sequence number")
  }

  function deleteMaterial(id:number){
    setAddedMaterials(prevValues=>{
      return prevValues.filter(material => material.id !== id)
    })
  }

  function selectLengthHandle(){
    setIsOpenConfirmWindow(true)
  }

  function getWhatsappNumber(){
    let number = ["+919902594100"]
    if(applicationConfig.clientName === "KairaFabrics"){
      number = ["858 99 25 111","858 99 25 222","858 99 25 333","+918848836808"]
    }
    if(applicationConfig.clientName === "LWIN"){
      number = ["+919447798560","7223901017"]
    }
    return number
  }

  async function confirmOrder() {
    const userId = applicationConfig.user.userId || getUser().userId
    let cartItems = []
    let colletionName = addedMaterials[0]?.collectionName || ""
    let Message = `Hi \nI would like to place an order for the following materials from the collection ${colletionName}\n`
    for (const material of addedMaterials) {
      let item = {
        product_id:null,
        material_id:material.id,
        price:0,
        quantity:1,
        configuration:JSON.stringify({length:material.length}),
      }
      cartItems.push(item)
      Message += `- ${material.materialCode} (${material.length} Meters)\n`
    }
    Message += `Please confirm the availability and share the details regarding pricing and delivery timelines\n\n`
    Message += `Thank You`
    
    showComponentLoader("mainLoaderSpinner")

    if(userId){
      const customerSessionId = null
      const customerId = null
      const orderDate = getTodayTimestampOnlyDate()
      showComponentLoader("mainLoaderSpinner")
      STORE.storeApi.orders.add(userId,customerId,{items:cartItems,customerSessionId:customerSessionId,orderDate:orderDate,totalPrice:STORE.checkoutPricing.totalPrice,advanceAmount:STORE.checkoutPricing.advanceAmount}).then(async (data:any)=>{
        await waitFor(500)
        if(!data.data.error){
          setIsOpenOrder(false)
          hideComponentLoader("mainLoaderSpinner")
          showGlobalToast("Order has been placed successfully")
          setIsOrderPlaced(true)
          let numbers = getWhatsappNumber()
          for (const currNumber of numbers) {
            whatsappAction(currNumber,Message)
          }
          // applicationConfig.functions.store_MainHeader.openOrders()
        }else{
          console.log(data)
        }
      })
    }else{
        alert("Please login Again using authentication link..")
    }
    hideComponentLoader("mainLoaderSpinner")
    
  }




  function RenderUI(){

    return(
      <div className="full-height-width">
        <div className="header-content padding10">
        <div className="display-flex-row" onClick={()=>setIsOpenOrder(false)}>
          <span className="center-aligned-column pointer" ><i className="fas fa-arrow-left ponter-none font-medium color-black"></i></span>
          <span className="heading1 color-primary uppercase marginleft10">
            {props.collectionName}
          </span>
        </div>
      </div>
      <div className="body-content" style={{padding:"0 1rem"}}>
        {state === "initial"?
        <Order/>:
        state === "review"?
        <Review/>:
        <Success/>
        }
        
        
      </div>


      <div className="footer-content center-aligned-column">
        {addedMaterials?.length?
          <Button2
            configObj={{}}
            action={()=>{confirmOrder()}}
            label={"Confirm Order"}
            fontAwesomeIcon={"fas fa-arrow-right color-primary"}
            iconPos={""}
            classNames={"bg-color-primary button-medium"}
          />
        :null}
        
      </div>
      {isOpenConfirmWindow?
      <div id="confirmMaterialWindow" className="full-height-width top-left padding0 center-aligned-column zindex100" style={{background:"rgba(0,0,0,0.5)"}}>
        <div className="white-card ion-padding" style={{width:"20rem"}}>
          
          <div className="full-width center-aligned-column">
          <div className="" style={{width:"7rem",height:"7rem"}}>
            <MaterialImage material={currMaterial}/>
          </div>
          </div>

          {currMaterial?
          <div className="center-aligned-column full-width">
            <div className="form-label-input-container width90" style={{width:"95%"}} >
              <div className="color-black font-normal text-center label">Select Length (In Meters)</div>
              <div className="input-container marginTop5">
              <input className="input full-width input-large" name="length" onChange={(event)=>currMaterial.length =  Number(event.target.value)} type="number" />
            </div>
            </div>
          </div>
          :
          <div className="heading4 padding10 text-center">Try again</div>
          }
          

          


          <div className="center-aligned-column" style={{marginTop:"1rem"}}>
            <Button2
                configObj={{}}
                action={()=>{addMaterialAction()}}
                label={"Add"}
                fontAwesomeIcon={"fas fa-cart-plus color-white"}
                iconPos=""
                classNames={"no-shadow button-large bg-color-success"}
            />
          </div>
        </div>
      </div>
      :null}

   

      

      </div>
    )

  }

  function OrderOld(){
    return(
      <div className="full-height-width">

        <div className="full-width display-flex-row no-wrap">

          <DropDown
            classNames="storefront-dropdwon"
            columns={1}
            values={["Select sequence number",...materials]}
            currSelectedValue={currMaterialCode}
            action={selectMaterialHandle}
            label="Select sequence number"
            isShowMaterialThumbnail={true}
          />


        <Button2
                configObj={{}}
                action={()=>{
                  if(currMaterial){
                    setIsOpenConfirmWindow(true)
                  }else{
                    showGlobalToast("Please select",2000,"error")
                  } 
                }}
                label={"Add"}
                fontAwesomeIcon={"fas fa-cart-plus color-white"}
                iconPos=""
                classNames={"no-shadow button-small bg-color-success"}
            />
        </div>

        

          {currMaterial?
          <div className="full-width ion-margin-top border">
            <div className="full-width bg-color-light padding5 heading2 font-md-small">Selected Materials - {currMaterialCode}</div>
            <div className=" full-width marginTop5 padding5 display-flex-row no-wrap space-between">

              <div className="center-aligned-column" style={{width:"7rem",height:"7rem"}}>
                <MaterialImage material={currMaterial}/>
              </div>

              <div className="center-aligned-column full-width">
                <div className="form-label-input-container width90" style={{width:"95%"}} >
                  <div className="color-black font-md-small label">Select Length (In Meters)</div>
                  <div className="input-container marginTop5">
                  <input className="input" name="length" onChange={(event)=>currMaterial.length =  Number(event.target.value)} type="number" />
                </div>
                </div>
              </div>


              <div className="center-aligned-column" style={{marginTop:"2.2rem"}}>
                <Button2
                    configObj={{}}
                    action={()=>{setIsOpenOrder(true)}}
                    label={"Add"}
                    fontAwesomeIcon={"fas fa-cart-plus color-white"}
                    iconPos=""
                    classNames={"no-shadow bg-color-success"}
                />
              </div>


            

            </div>
          </div>
          :null}

      </div>
    )
  }

  function Order(){
    return(
      <div className="full-height-width" style={{paddingTop:"0.2rem"}}>

          <div className="full-width display-flex-row no-wrap">

          <DropDown
              classNames="storefront-dropdwon"
              columns={1}
              values={["Select sequence number",...materials]}
              currSelectedValue={currMaterialCode}
              action={selectMaterialHandle}
              label="Select sequence number"
              isShowMaterialThumbnail={true}
            />


          <Button2
                  configObj={{}}
                  action={()=>{
                    if(currMaterial){
                      setIsOpenConfirmWindow(true)
                    }else{
                      showGlobalToast("Please select",2000,"error")
                    } 
                  }}
                  label={"Add"}
                  fontAwesomeIcon={"fas fa-cart-plus color-white"}
                  iconPos=""
                  classNames={"no-shadow button-small bg-color-success"}
              />
          </div>

          {addedMaterials.length?
          <div className="full-width ion-margin-top border">
            <div className="full-width bg-color-light padding5 heading3 font-md-small">Cart Items</div>
            
            {addedMaterials?.map((material:any,index:number)=>{
              return(
                <div key={index} className=" full-width padding5 ">

                  <div className="white-card border padding5 shadow-light full-width display-flex-row no-wrap space-between">

                    <div className="">
                        <div className="heading3 font-small">{index+1}. </div>
                    </div>

                    <div className="marginleft5" style={{width:"6rem"}}>
                      <MaterialImage material={material}/>
                    </div>

                    <div className="form-label-input-container marginleft5 width90" style={{width:"95%"}} >
                      <div className="heading3 font-small  color-primary">Shade No -  {material.materialCode}</div>
                      <div className="input-container">
                      <div className="heading4 font-small">{material.length} Meters</div>
                    </div>
                    </div>


                    <div className="">
                      <Button2
                          configObj={{}}
                          action={()=>{deleteMaterial(material.id)}}
                          label={""}
                          fontAwesomeIcon={"fas fa-trash-alt color-danger font-small"}
                          iconPos="left"
                          classNames={"no-shadow button-ex-small"}
                      />
                    </div>

                  </div>

                
              </div>
              
              )
            
            })}
            
            
          </div>
          :null}

      </div>
    )
  }

  function Review(){
    return(
      <div className="center-aligned-column position-relative white-card no-shadow border cart-item padding5 space-between border-bottom">
           <div className="product-details-cart display-flex-row no-wrap">
            <div className="" style={{width:"40%"}}>
                <MaterialImage material={currMaterial}/>
            </div>
            
            <div className="other-details ion-padding-start" style={{width:"60%"}}>

              <div className="">
              <div className="config-details">

                <div className="heading1 font-medium color-black">
                    {currMaterialCode}
                </div>
                <div className="heading3 marginTop5 color-primary" >{currMaterial.length} Meters</div>

                <div className="price-details">
                  <div className="display-flex-row space-between item">
                    <span className="heading3">MRP</span>
                    <span className="rupee-sign heading3 center-aligned-column">{getFormattedPrice(getRandomNumber(5000,6000))}</span>
                  </div>
                  <div className="display-flex-row space-between item">
                    <span className="heading3 color-success">Discount</span>
                    <div className="heading3 font-md-small rupee-sign"><input type="number" className="input" defaultValue={0} style={{borderColor:"#eee",width:"7rem"}} /></div>
                  </div>
                  <div className="display-flex-row space-between item no-border">
                    <span className="heading3">ITEM PRICE</span>
                    <span className="rupee-sign heading3 center-aligned-column">{getFormattedPrice(getRandomNumber(5000,8000))}</span>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
    )
  }

  function Success(){
    return(
      <div className="full-height-width bg-color-white position-fixed top-left center-aligned-column" style={{zIndex:1001}}>
        <div className="ion-padding">
            <div className="font-ex-large color-success">Your Order Has Been Successfully Received !!!</div>
            <div className="font-medium marginTop5">Our team will contact you shortly on your registered phone number</div>
        </div>
    </div>
    )
  }


  return (
    <>
   {isOrderPlaced?
        <Success/>
      :null}
    <IonModal
        id=""
        cssClass="payment-modal"
        onDidDismiss={()=>setIsOpenOrder(false)}
        isOpen={isOpenOrder}
      >
        <RenderUI/>
    </IonModal>

    <Button2
        configObj={{}}
        action={()=>{setIsOpenOrder(true)}}
        label={"Place Order"}
        fontAwesomeIcon={"fas fa-cart-plus color-white"}
        iconPos="left"
        classNames={"no-shadow button-large bg-color-primary"}
    />

    
  </>
  );
};

export default PlaceOrder;
