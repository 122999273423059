import $ from "jquery";
import React, { useState } from "react";
import { createProductForm } from "../../../../../utils/products/createProductForm";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import Input from "../../../../common/ui-components/input/Input";
import AddToCart from "../cart/AddToCart";
import { addToCartHandle } from "../../../../../utils/store/product-cart";
import { STORE } from "../../../../../utils/store/storeConfiguration";

 

const Review: React.FC<{
    productDetails:createProductForm
    onSuccess:()=>void
    currProduct:any
}> = (props) => {

  

    function onchangePrice(value:any) {
        console.log(value)
    }

    // function addToCartHandle() {
    //     if(productDetails){
    //       if(!STORE.activeCustomer){
    //         setIsOpenStartSession(true)
    //         return
    //       }
    //       const customerId = STORE.activeCustomer.id
    //       console.log(STORE.productConfiguration)
    //       STORE.storeApi.cart.add(customerId,{productId:productDetails.product_id,quantity:1,configuration:JSON.stringify(STORE.productConfiguration),price:STORE.productConfiguration.price}).then((data:any)=>{
    //         if(!data.data.error){
    //           showGlobalToast("Product Added to cart")
    //         }else{
    //           showGlobalToast(data.data.message,2000,"error")
    //         }
    //       })
    //       props.onAdd()
    //       updateCartIconCounter(STORE.cartItems?.length)
    //       showProductCart()
    //     }
    //   }


    function addToCart() {
        STORE.productConfiguration.isCustomOrder = true
        addToCartHandle(props.currProduct,props.onSuccess)
    }
     


    return (
        <div className='full-height-width bg-color-light create-custom-order-wrapper' id="customOrderReview" style={{display:"none"}}>
            <div className="header-content font-large center-aligned-column heading1 store-color-primary">
                Review
            </div>
            <div className="body-content center-aligned-column">
                <div className="width50">
                    <div className="store-color-primary text-center heading3 padding10 font-medium">Enter Price</div>
                    <div className=" center-aligned-column">
                        <Input
                            type="number"
                            id="categoryAddOrder"
                            label=""
                            isMandatory={false}
                            onChange={(value)=>{onchangePrice(value)}}
                            className="input-large full-width"
                            value={""}
                        />
                    </div>
                </div>
                
                
            </div>
            <div className="footer-content">
                <div className="center-aligned-column">
                    {/* <Button2
                        configObj={{id:""}}
                        action={confirmHandle}
                        label={"Confirm"}
                        fontAwesomeIcon={"fas fa-arrow-right"}
                        iconPos="right"
                        classNames={"no-shadow button-large margin0 store-bg-color-primary"}
                    /> */}
                    <div className="full-width">
                        <Button2
                        configObj={{id:""}}
                        action={()=>{addToCart()}}
                        label={`Add to cart`}
                        fontAwesomeIcon={"fas fa-cart-plus color-white"}
                        iconPos=""
                        classNames={"no-shadow button-medium margin0 store-bg-color-primary"}
                        />
                    </div>
                    {/* <AddToCart currProduct={props.currProduct} onAdd={props.onSuccess}/> */}
                </div>
            </div>

        </div>
    );
};

export default Review;
