import { IonAlert } from "@ionic/react";
import React, { useState } from "react";
import { showGlobalToast, toggleClassInElement } from "../../../../../../../utils/UI_methods/global";
import { applicationConfig, getObjectByParameter } from "../../../../../../../utils/methods";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
import { getTodayTimestampOnlyDate } from "../../../../../../../utils/store/crm/calendar";
import Modal2 from "../../../../../../common/shared-components/Modals/Modal";
import $ from "jquery"
import Input from "../../../../../../common/ui-components/input/Input";

 
const MarkAsClosedOption: React.FC<{
  refreshLeads:()=>void
  ticket:any
  Content?:any
}> = (props) => {


  const[isOpen,setIsOpen] = useState(false)




  function closeTicketHandle() {

    const details =  {
      status:0,
      remarks:$("#closeTicketRemarks").val(),
      closedDate:Number(getTodayTimestampOnlyDate())
    }
    if(!details.remarks){
      showGlobalToast("Please enter remarks",2000,"error")
      return
    }
    CRM.crmApi.ticket.update(props.ticket.ticket_id,details).then((data:any)=>{
      if(!data.data.error){
          setIsOpen(false)
          props.refreshLeads()
          showGlobalToast("Marked as closed")
      }else{
        showGlobalToast("Internal server error",2000,"error")
      }
    })
  }

  function CloseTicketRemarks() {
    return(
      <div className="full-height-width bg-color-light ion-padding">
        <div className="padding5">
          <div className="row">
            <Input
                type="text"
                id=""
                label="Ticket Number"
                isMandatory={false}
                onChange={()=>{}}
                value={props.ticket.ticket_no}
                className="disable"
            />
          </div>

          <div className="row">
            <Input
                type="text"
                id=""
                label="Customer Name"
                isMandatory={false}
                onChange={()=>{}}
                value={props.ticket.customer_name}
                className="disable"
            />
          </div>

          <div className="row">
            <Input
                type="text"
                id=""
                label="Issue Type"
                isMandatory={false}
                onChange={()=>{}}
                value={props.ticket.issue_type}
                className="disable"
            />
          </div>

          <div className="form-label-input-container full-width">
            <div className="label-container ">
                <div className="label">
                    Remarks <span className="mandatory">*</span>
                </div>
            </div>
            <div className="input-container">
                <textarea className={`input`} id="closeTicketRemarks" style={{height:"7rem"}} />
            </div>
          </div>

        </div>
         
        
      </div>
      
    )
  }
 

  function Footer() {
    return(
      <Button2
          configObj={{id:""}}
          action={()=>{closeTicketHandle()}}
          label={"Confirm close"}
          fontAwesomeIcon={"fas fa-edit color-dark"}
          iconPos=""
          classNames={"no-shadow margin0 bg-color-primary"}
      />
    )
  }
 
  return (
    <div className="position-relative">

        <Modal2
          isOpen = {isOpen}
          Content = {()=>(
               <CloseTicketRemarks/>
          )}
          onDismiss = {()=>setIsOpen(false)}
          FooterContent={Footer}
          heading="Close Ticket"
          classNames="small-y-modal"
          id=""
        />

        <div className="more-options-button" onClick={()=>{toggleClassInElement(`moreOptionsTicket${props.ticket.ticket_id}`,"--is-active")}}>
          {props.Content?
            <props.Content/>
          :
          <Button2
            configObj={{id:""}}
            action={()=>{}}
            label={""}
            fontAwesomeIcon={"fas fa-ellipsis-v color-warning"}
            iconPos="left"
            classNames={"no-shadow button-small pointer-none hide-in-mobile color-white bg-color- "}
          />
          }
          

        </div>

      
      <div id={`moreOptionsTicket${props.ticket.ticket_id}`} className="more-options-window white-card shadow-medium" >
        <ul className="container">
          <li onClick={()=>{setIsOpen(true)}}>Mark as closed</li>
          {applicationConfig?.user?.username === "Shreedhar"?
            <li onClick={()=>{}}>Delete Ticket</li>
          :
              null
          }
        </ul>
      </div>

      

  </div>
  );
};

export default MarkAsClosedOption;
