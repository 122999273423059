import { IonIcon } from "@ionic/react";
import { closeOutline, search } from "ionicons/icons";
import React from "react";
import { closeSearch, searchMaterial } from "../../../../utils/products/materialSearch";
 
 
const MaterialSearch: React.FC<{
    configObj:any
    currSelectedMaterialType:string
    activateSearchMode:(materialsList:Array<any>,searchString:string)=>void
    filterMaterialWithMaterialType:(cat:string)=>void
    filterMaterialWithCollection:(cat:string)=>void
    idSuffix:string
  }> = (props) => {

 
  return (

    <React.Fragment>
      <div className="search-container-wrapper" id={"searchWrapper"+props.idSuffix}> 
        <div className="search-container white-card no-shadow  position-relative" id="searchContainer">
          <IonIcon className="icon font-normal left-middle" icon={search}></IonIcon>
          <input
            type="text"
            onChange={()=>searchMaterial(props.configObj,props.currSelectedMaterialType,props.activateSearchMode,props.filterMaterialWithMaterialType,props.filterMaterialWithCollection,props.idSuffix)}
            id={"materialSearch"+props.idSuffix}
            placeholder="Search material"  
            className="search-input input material-search-input"
            autoComplete="off"
          />

          <div id={"materialSearchResult"+props.idSuffix} className="search-results-container shadow-light">
            <div className="heading3 color-danger title">Material types</div>
            <div className="display-flex-row" id={"resultMaterialTypes"+props.idSuffix}>
            </div>

            <div className="heading3 color-danger title">Collections</div>
            <div className="display-flex-row" id={"resultCollections"+props.idSuffix}>
            </div>

            <div className="heading3 color-danger title">Materials</div>
            <div className="" id={"resultMaterials"+props.idSuffix}>
            </div>

            {/* <div className="heading3 color-danger title">Products in other categories</div>
            <div className="" id={"resultOtherProducts"+props.idSuffix}>
            </div> */}
          </div>
    
          <IonIcon onClick={closeSearch} id="materialSearchCloseButton" className="icon font-normal display-none right-middle" icon={closeOutline}></IonIcon>
        </div>

    

      </div>
 
      </React.Fragment>
  );
};

export default MaterialSearch;
