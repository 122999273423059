import React, { useState } from "react";
import ProductImage from "../../../common/ui-components/elements/ProductImage";
import Button2 from "../../../common/ui-components/buttons/Button2";
import { hideComponentLoader, showGlobalToast } from "../../../../utils/UI_methods/global";
import { STORE, SofaConfiguration } from "../../../../utils/store/storeConfiguration";
import { applicationConfig, getObjectByParameter, stringWithoutWhiteSpace } from "../../../../utils/methods";
 

 
const StockListContainer: React.FC<{
  allItems:any
  refreshList:()=>void
  openAddProduct:(product:any)=>void
  openAddConfig:(product:any)=>void
}> = (props) => {



  function onChangeExcelHandle(event:any) {
    const fileInput = event.target;
    let windowObject:any = window
    const XLSX = windowObject.XLSX
    const file = fileInput.files[0];

    let productConfigMapping:any = {}
    let notFound:any = []

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        for (let i = 1; i < 634; i++) {
          let productName = sheet["A"+i].v
          let product = applicationConfig?.data?.productsList.find(currProduct => stringWithoutWhiteSpace(currProduct.product_name) === stringWithoutWhiteSpace(productName))
          if(product){
            let productId = product.product_id
            let config = new SofaConfiguration(null)
            config.variant.variantName = sheet["B"+i].v
            config.price =  sheet["C"+i].v
            let configs = productConfigMapping[productId]
            if(configs){
              productConfigMapping[productId] = [...configs,JSON.stringify(config)]
            }else{
              productConfigMapping[productId] = [JSON.stringify(config)]
            }
             
          }else{
            notFound.push(productName)
          }
        }
        saveProductPricing(productConfigMapping)
        return
      };

      reader.readAsBinaryString(file);
    }
  }


  async function saveProductPricing(productConfigMapping:any) {

    for (const index in productConfigMapping) {
      // let product = productConfigMapping[index]
      let productId = Number(index)
      let allConfiguratons = productConfigMapping[index]

      STORE.storeApi.pricing.add(Number(productId),allConfiguratons,0).then(async (data:any)=>{
        if(!data.data.error){
            // props.setIsOpen(false)
            hideComponentLoader("storeLoader")
            showGlobalToast("Created",3000)
        }else{
          showGlobalToast("Internal server error",3000,"error")
          hideComponentLoader("storeLoader")
        }
      })
      
    }

    let configurations = []

    // for (const configuration of allConfiguratons) {
    //   configurations.push(JSON.stringify(configuration))
    // }
    
    
  }

  return (
    <div className="full-height-width display-flex-column no-wrap">

        {/* <input style={{display:"block",visibility:"visible"}} type="file" onChange={onChangeExcelHandle} id="excelFileInput" /> */}

      
        <div className="leads-list-container display-flex-column no-wrap full-height-width position-relative">
          <div className="container overflow-y-scroll"  style={{paddingBottom:"180px"}}>
            <table className="table inventory-stock-table">
                <thead className="light-background">
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Sub Category</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {props.allItems?.map((product: any, index: number) => {
                    return (
                      <tr key={index} className="text-center ">
                        <td>{index+1}</td>
                        <td style={{width:"7rem"}}><ProductImage product={product}/></td>
                        <td className="heading3">{product.product_name}</td>
                        <td>{product.category_name}</td>
                        <td>{product.sub_category_name}</td>
                        <td className="">
                          <div className="display-flex-row no-wrap">
                            <Button2
                              configObj={{id:""}}
                              action={()=>{props.openAddProduct(product)}}
                              label={`View`}
                              fontAwesomeIcon={"fas fa-eye color-warning"}
                              iconPos="left"
                              classNames={"no-shadow button-ex-small margin0 cart-button cart-button-warning-outline"}
                            />
                            <Button2
                              configObj={{id:""}}
                              action={()=>{props.openAddConfig(product)}}
                              label={`Config`}
                              fontAwesomeIcon={"fas fa-eye color-warning"}
                              iconPos="Config"
                              classNames={"no-shadow button-ex-small cart-button cart-button-warning-outline"}
                            />
                          </div>
                          
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
          </div>
        </div>
      
    </div>
  );
};

export default StockListContainer;
