import React from "react";
import { getArrayOfDistinctValues } from "../../../../../utils/methods";
import { getRandomNumber } from "../../../../../utils/products/productviewer_shopify.js";
import { QualityConfiguration, SofaVariantsConfiguration } from "../../../../../utils/store/customization-options-data/sofa";
import Alert from "../../../../common/ui-components/alerts/Alert";
import Input from "../../../../common/ui-components/input/Input";
import Select from "../../../../common/ui-components/input/Select";
import "../../../../style/inventory/form.css";
 
 


 
const SofaForm: React.FC<{
    alertId:string
    isUpdate:boolean
    allConfiguratons:any
}> = (props) => {



  


  return (

    <div className={`create-stock-form bg-color-light full-width overflow-y-scroll ion-padding-top ion-padding-bottom full-height ${!props.isUpdate?" padding-container ":""}`}>
        <Alert id={props.alertId}/>

        <div className={``}>
            {props.allConfiguratons.map((config:any,index:any)=>{
                return(
                <div className="display-flex-row space-around" key={index}>
                <div className="row" >
                    <Input
                        type="text"
                        id=""
                        label={"Variant Name"}
                        isMandatory={true}
                        onChange={()=>{}}
                        className="disable"
                        value={config.variant.variantName}
                    />
                </div>
                <div className="row" >
                    <Input
                        type="text"
                        id=""
                        label={"Price"}
                        isMandatory={true}
                        onChange={()=>{}}
                        className=""
                        value={config.price}
                    />
                </div>
                </div>
                )
                
                
            })}
            
        </div>
    </div>
  );
};

export default SofaForm;
