import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { getCurrentConfigurationFromLocalStorage, getObjectByParameter } from '../../../../../../utils/methods';
import { getMoodBoardConfigKey } from '../../../../../../utils/moodboard/helper';
import { setElementInnerContent } from '../../../../../../utils/UI_methods/global';
import "../../../../../style/showconfig.css";
import SummaryMoodboard from './SummaryMoodboard';

// import './Home.css';

const ShowMoodboardConfig: React.FC<{
    configObj:any
    currSelection:string
    areasList:any
}> = (props) => {

const[products,setProducts]:any = useState(null)
// const [currProduct, setCurrProduct]:any = useState(null);
// const[allImages,setAllImages]:any = useState([]) 
// const[productImages,setProductImages]:any = useState([]) 

const[currArea,setCurrArea] = useState(props.configObj.areaName)
const[currSelection,setCurrSelection] = useState(props.currSelection)


useEffect(()=>{
    
    let products = getAllProductsFromConfig(props.configObj.areaName)
    setProducts(products)

},[props.configObj])

 
 
function getAllProductsFromConfig(areaName:string){
    let key = getMoodBoardConfigKey(props.configObj?.getMoodboardId(),areaName)
    let configuration = getCurrentConfigurationFromLocalStorage(key)
    let productsArray = []
    for (const key in configuration) {
        productsArray.push(configuration[key])
    }
    return productsArray
}


  


    function updateConfig(areaName:string){
        let products = getAllProductsFromConfig(areaName)
        setProducts(products)
        setCurrArea(areaName)
    }


   


    function ConfigurationTable(){
        let totalPrice = 0
        return(
            <React.Fragment>
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {products?products.map((product:any,index:any)=>{
                         let object:any = getObjectByParameter(props.configObj?.data.productsList,"product_id",parseInt(product.productId))
                         let price:any = object?.price
                         totalPrice += Number(price)
                         setTimeout(() => {
                            setElementInnerContent("₹ "+String(totalPrice)+".00","moodboardTotalPrice")
                         }, 200);
                        return (
                        <tr className="" key={index}>
                            <td>{index+1}</td>
                            <td>{product.productName}</td>
                            <td>Sofa</td>
                            <td>{price}.00 INR</td>
                        </tr>
                        )
                    }):null}
                  </tbody>
                </table>
            </React.Fragment>
        )
    }

    function ShowConfig(){
        return(
            <React.Fragment>
             <div className="configuration-product-info-container ion-padding-top" style={{height:"90%"}}>
             <ConfigurationTable/>
            </div>
            </React.Fragment>
        )
    }

  return (
    <React.Fragment>
         <div className="configuration-container" style={{paddingTop:"0"}}>
            <div className="configuration-area-container">
                {props.areasList.map((area:any,index:number)=>{
                    let classNames=[]
                    classNames.push("configuration-area")
                    if(area.area_name===currArea && currSelection!="Summary"){
                        classNames.push("--is-active")
                    }
                    return(
                        <div className={classNames.join(" ")} key={index} onClick={()=>{
                            updateConfig(area.area_name);
                            setCurrSelection("Config")
                            $(".summary-button").removeClass("--is-active")
                            }}>
                            {area.area_name}
                        </div>
                    )
                })}
                <div className="configuration-area summary-button" onClick={(event)=>{
                        setCurrSelection("Summary")
                        $(".modal-body").find(".configuration-area").removeClass("--is-active")
                        $(event.target).addClass("--is-active")
                    }}>
                    Summary
                </div>
            </div>
            {currSelection==="Config"?
            <ShowConfig/>
            :
            <SummaryMoodboard
                configObj={props.configObj}
                areasList={props.areasList}
            />
            }
      </div>
      
  
  </React.Fragment>
  );
};

export default ShowMoodboardConfig;
