import React from "react";
import { QualityDetailsData } from "../../../../../../utils/store/customization-options-data/sofa";
 

const QualityDetails: React.FC<{
}> = (props) => {


 
 

  return (
    <div className="full-height-width overflow-y-scroll padding10">
        <table className="users-table table quality-details">
              <thead>
              <tr className="">
                  {/* <th>#</th> */}
                  <th>Material</th>
                  <th>Basic</th>
                  <th>Essential</th>
                  <th>Premium</th>
                  <th>Exclusive</th>
              </tr>
              </thead>
              <tbody>
              {QualityDetailsData.map((data:any,index:any)=>{
                  return (
                  <tr className="" key={index}>
                      <td className="text-" style={{fontWeight:500}}>{data.material}</td>
                      <td>{data.basic}</td>
                      <td>{data.essential}</td>
                      <td>{data.premium}</td>
                      <td>{data.luxury}</td>
                  </tr>
                  )
              })}
               <tr className="bg-color-light">
                      <td style={{fontWeight:500}}>Additional Charges</td>
                      <td style={{fontWeight:500}}>Free</td>
                      <td style={{fontWeight:500}}>2600</td>
                      <td style={{fontWeight:500}}>3700</td>
                      <td style={{fontWeight:500}}>6300</td>
                </tr>
                <tr className="">
                      <td>Benefits</td>
                      <td>A budget-friendly option that offers reliable comfort and durability. Ideal for occasional use.</td>
                      <td>Enhanced durability, suitable for everyday use. Adds a touch of luxury and a more fabric options</td>
                      <td>Excellent comfort and top-notch durability. A long-term investment for those seeking superior quality and comfort.</td>
                      <td>The best of the best. Superior comfort, and durability. A luxurious option for those who want a lasting investment.</td>
                </tr>
            </tbody>
          </table>
    </div>
  );
};

export default QualityDetails;


















