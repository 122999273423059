import React, { useEffect, useState } from "react";
import { getWindowWidth } from "../../../../../../utils/UI_methods/global";
import { applicationConfig } from "../../../../../../utils/methods";
import { addAnchorFor2dRender, cameraFor2dRender } from "../../../../../../utils/customizein3d/area3dmodel/floorplan/render2d";
import DropDown2 from "../../../../ui-components/filters/DropDown2";
import Button2 from "../../../../ui-components/buttons/Button2";
import Cropper from "../../../../shared-components/Helpers/Cropper";
import { camera } from "../../../../../../utils/customizein3d/modules/customizein3d";
import { confirmCrop, updateAspectFrame, updateAspectRatio } from "../../../../../../utils/cropper/cropper";
import { disableTakeRenderMode, updateFov, updateNearValue } from "../../../../../../utils/customizein3d/modules/render2d";
import TakeRender from "../../../../../admin/projects/projectActions/customizer/canvas/TakeRender";
 

const CameraSettings: React.FC<{
  // disableCameraSettingsMode:()=>void
  // setShowRenderModal:(val:boolean)=>void
  // setCurrSelectedStep:(val:string)=>void
  // currSelectedCamera:any
}> = (props) => {
 

  const[currselectedAspect,setCurrselectedAspect] = useState("4:3")
  const[isSettingAspectRatio,setisSettingAspectRatio] = useState(false)

  useEffect(()=>{
    setTimeout(() => {
      updateAspectFrame(4/3)
    }, 500);
  },[])
 

  useEffect(()=>{
    // renderSettingConfig?.setDimensions(getWindowWidth(),getWindowHeight())
    applicationConfig?.setFunctionRef("customizer",{setCurrselectedAspect:setCurrselectedAspect})
  },[])


  async function confirmAction2D() {
    // showComponentLoader("changeFinishLoader")
    // updateCameraTransform()
    // await waitFor(200)
    confirmCrop()
    // await waitFor(500)
    // hideComponentLoader("changeFinishLoader")

    applicationConfig.functions.customizer.setShowRenderModal(true)
  }


  function updatedAspectRationHandle(val:string) {
    updateAspectRatio(val)
    setCurrselectedAspect(val)
  }

 
  return (
    <>
      <TakeRender/>
      <Cropper/>

      <div id="cameraSettingsWrapper" className="center-aligned-row bottom-left no-wrap full-width" style={{zIndex:1003}} >
        {/* <div className="heading1 font-small padding5 bottom-left" style={{bottom:"100%"}}>Camera settings</div> */}
        <div className={`display-flex-row no-wrap camera-settings-wrapper shadow-light position-relative full-width"}`} style={{background:"rgba(255,255,255,1)",borderTop:"0px solid #000",justifyContent:"space-evenly"}}>
        
 
      
  
        <div className={`display-flex-row control no-wrap`}>
          <div className="heading3 center-aligned-column">Fov</div>
          <div className="range-slider-container marginleft10 center-aligned-column" style={{width:"150px"}}>
              <input type="range" className="range-slider"  min={15} max={105} onChange={updateFov} id="rangeSliderFov"/>
          </div>
          <div className="center-aligned-column color-black marginleft10"><div id="rangeSliderFovVal" className="heading3">{camera?.fov}</div></div>
        </div>
  
  
        <div className={`display-flex-row control no-wrap`}>
          <div className="heading3 center-aligned-column">Cliping</div>
          <div className="range-slider-container marginleft10 center-aligned-column" style={{width:"150px"}}>
              <input type="range" className="range-slider" min={1} defaultValue={1} max={500} onChange={updateNearValue} id="rangeSliderNear"/>
          </div>
          <div className="center-aligned-column color-black marginleft10"><div id="rangeSliderNearVal" className="heading3">{camera?.near}</div></div>
        </div>

        <div className="display-flex-row no-wrap aspect-ratio-container">
         <div className={`center-aligned-column aspect-ratio-inner`}  style={{width:"auto"}}>
             <div className="display-flex-row no-wrap ion-margin-start">
               <DropDown2
                 classNames=""
                 values={["4:3","16:9","2:1","1:1"]}
                 currSelectedValue={currselectedAspect}
                 action={updatedAspectRationHandle}
                 label="Aspect"
                 />
           </div>
         </div>
       </div>


 

          <div id="finalizeRenderButton" className="center-aligned-column  full-height" style={{borderColor:"#000"}}>
            <div className="display-flex-row no-wrap">
              <Button2
                configObj={applicationConfig}
                action={()=>{
                    confirmAction2D()
                }}
                label={"Take Render"}
                fontAwesomeIcon={"fas fa-camera"}
                iconPos="left"
                classNames={"bg-color-primary marginleft10 button-"}
              />

              <Button2
                configObj={applicationConfig}
                action={()=>{
                    disableTakeRenderMode()
                }}
                label={"Cancel"}
                fontAwesomeIcon={"fas fa-times color-danger"}
                iconPos="left"
                classNames={"bg-color-light marginleft10 button-"}
              />
            </div>
          </div>
          
        </div>

       


      </div>
      
    </>
  );
};

export default CameraSettings;
 
