import React from "react";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import CloseButton from "../../../../../common/ui-components/buttons/CloseButton";

 
const Success: React.FC<{
}> = (props) => {
 


    function goBack() {
        window.location.href = "/"
    }
 
return (
    <div className="white-card shadow-medium bg-color-light full-height-width ion-padding display-flex-column space-between">
 
        <div className="">
                <div className="heading2 text-center" style={{fontSize:"2rem"}}>Success!!</div>
                <div className="heading3 font-normal text-center marginTop10 capitalize">Thank you for your approval. Our Sales team will be in touch with you shortly.</div>
        </div>
     
        <div className="middle"><i className="fas fa-check-circle color-success" style={{fontSize:"8rem"}}></i></div>

        <div className="footer- center-aligned-column">
            <Button2
                configObj={{id:""}}
                action={goBack}
                label={"Ok"}
                fontAwesomeIcon={"fas fa-share color-white"}
                iconPos=""
                classNames={"no-shadow button-large full-width margin0 text-center bg-color-primary"}
            />
        </div>

    </div>   
);
};

export default Success;
