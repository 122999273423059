import AwsConfig from "../aws-sdk/AwsConfig";
import { applicationConfig, disposeVariables, stringWithoutWhiteSpace } from "../methods";

const untar = require("js-untar");
const pako = require("pako");
const CACHE_NAME = "OPUS_ASSETS";
const TAR_CACHE_NAME = "OPUS_LOADED_TAR_FILES";


export function untarAndSaveToCache(awsConfig: any,urlPrefix:string,tarFileKey:string) {
    return new Promise((resolve,reject)=>{
      checkIfExists(tarFileKey).then(cache=>{
        applicationConfig.awsConfig.getObject(tarFileKey).then(async (data:any)=>{
            let bufferView = data.Body;
            untarAndSaveTarGzFile(bufferView,urlPrefix,tarFileKey).then(message=>{
              updateTarFilesReference(tarFileKey,cache)
              disposeVariables([bufferView,data])
              resolve(true)
            }).catch(err=>{
              disposeVariables([bufferView])
              resolve(false)
            })
        }).catch((error:any)=>{
          resolve(false)
        })
      }).catch(err=>{
        resolve(true)
      })
    })
}


function checkIfExists(tarFileKey:string) {
  return new Promise((resolve,reject)=>{
    window.caches.open(TAR_CACHE_NAME).then((cache) => {
      cache.match(tarFileKey).then((cacheRes) => {
        if(!cacheRes){
          resolve(cache)
        }else{
          reject("Exits")
        }
  })
})
})
}



async function untarAndSaveTarFile(bufferView: any,urlPrefix:string,tarFileKey:string) {
  return new Promise(async (resolve,reject)=>{
  
    let myBuffer = new Uint8Array(bufferView).buffer;
    await untar(myBuffer).then(
      function (extractedFiles: any) {
      },
      function (err: any) {
        reject("Unable to untar")
      },
      async function (extractedFile: any) {
        let fileName:string = extractedFile.name
        //folder name from file name if exists
        let index = fileName.indexOf("/")
        if(index !== -1){
          fileName = fileName.substr(index+1)
        }
        saveToCache(`${urlPrefix}/${fileName}`, extractedFile.buffer).then(data=>{
          resolve(data)
        }).catch(err=>{
          reject(err)
        })
      }
    );
  }).catch(err=>{
  })
}

export  async function untarAndSaveTarGzFile(bufferView: any,urlPrefix:string,tarFileKey:string) {
  return new Promise(async (resolve,reject)=>{
  
    let myBuffer = new Uint8Array(bufferView).buffer;
    let result = await pako.inflate(myBuffer)

    await untar(result.buffer).then(
      async function (extractedFiles: any) {
        for (let i = 0; i < extractedFiles.length; i++) {
          let extractedFile = extractedFiles[i];
          let fileName:string = stringWithoutWhiteSpace(extractedFile.name)
          //folder name from file name if exists
          let index = fileName.lastIndexOf("/")
          if(index !== -1){
            fileName = fileName.substr(index+1)
          }
          extractedFile.name = fileName
          await saveToCache(`${stringWithoutWhiteSpace(urlPrefix)}/${fileName}`, extractedFile.buffer).then(data=>{
            if(i===extractedFiles.length-1){
              disposeVariables([extractedFile,myBuffer,result,extractedFiles])
              resolve(data)
            }
          }).catch(err=>{
            if(i===extractedFiles.length-1){
              disposeVariables([extractedFile,myBuffer,result,extractedFiles])
              reject(err)
            }
          })
        }
      },
      function (err: any) {
        reject("Unable to untar")
      }
    );
  })
}



export function getAndSaveImageToCache(key:string) {
  return new Promise((resolve,reject)=>{
    applicationConfig.awsConfig.getObject(key).then(async (data:any)=>{
      var bufferView = data.Body;
      saveToCache(key,bufferView).then(message=>{
        // disposeVariables([bufferView,data])
        resolve(data)
      }).catch(err=>{
        resolve(false)
      })
    }).catch(err=>{
      resolve(false)
    })
  })
 
}

export function updateTarFilesReference(tarFileKey:string,cache:any) {
  try {
      // window.caches.open(TAR_CACHE_NAME).then((cache) => {
          cache.match(tarFileKey).then((cacheRes) => {
            if(!cacheRes){
              const response = new Response(tarFileKey);
              const request = new Request(tarFileKey);
              cache.put(request,response);
            }
          }).catch(err=>{
            console.log(err)
        });
    // });
  } catch (error) {

  }
}


export async function checkIfTarFileExists(tarFileKey:string) {
   try {
      window.caches.open(TAR_CACHE_NAME).then((cache) => {
          cache.match(tarFileKey).then((cacheRes) => {
            if(cacheRes){
              return true
            }else{
              console.log("Not Exists")
              return false
            }
          }).catch(err=>{
            console.log(err)
            return false
        });
    });
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function saveToCache(req: string, res: any) {
  try {
    return new Promise((resolve,reject)=>{
      window.caches.open(CACHE_NAME).then(async (cache) => {
        const response = new Response(res);
        const request = new Request(req);
        await cache.put(request, response);
        disposeVariables([response,request])
        resolve("Saved to cache")
      }).catch(err=>{
        reject(err)
      })
    })
  
  } catch (error) {}
}

export const deleteFromCache = async (key:string) => {
  try {
    const cache = await window.caches.open(CACHE_NAME);
    const request = new Request(key);
    const response = await cache.delete(request);
    return response ? "Deleted from cache" : "No such key in cache";
  } catch (err) {
    throw new Error(err);
  }
};


export function getThumbnailsUrlKey(companyName:string,collectionName:string,materialCode:string) {
  return `thumbnails/OVL/${companyName}/${collectionName}/${materialCode}.png`
}

export function displayImageFromCache(imageElement,key){
  return checkFromCache(key)?.then((url:any)=>{
    imageElement.attr("src",url)
  }).catch(err=>{
    console.log(err)
  })
}


export function getScreenshotKey(moduleName:any,productName:string) {
  return `screenshot_${moduleName}_${productName}`
}

export function getBlobObjectUrl(arrayBufferView: any) {
  try {
    let blob = new Blob([arrayBufferView], { type: "image/jpeg" });
    let urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  } catch (error) {}
}

export async function checkFromCache(key: string) {
  return new Promise(async (resolve,reject)=>{
    await window.caches.match(key).then(async (cacheRes:any) => {
      if(cacheRes){
        await cacheRes.arrayBuffer().then(async (buffer:any)=>{
          var arrayBufferView = new Uint8Array(buffer)
          let url = getBlobObjectUrl(arrayBufferView)
          disposeVariables([arrayBufferView,buffer])
          resolve(url)
        }).catch(err=>{
          reject(err)
        })
      }else{
        reject("File not found")
      }
    });
  }) 
  
}

export function getBlobFileFromCache(key:string) {
  try {
    return new Promise((resolve,reject)=>{
      window.caches.match(key).then((cacheRes:any) => {
        if(cacheRes){
          cacheRes.arrayBuffer().then((buffer:any)=>{
            var arrayBufferView = new Uint8Array(buffer)
            let blob = new Blob([arrayBufferView], { type: "image/jpeg" });
            disposeVariables([arrayBufferView,buffer])
            resolve(blob)
          }).catch(err=>{
            reject(err)
          })
        }else{
          reject("File not found")
        }
      });
    }) 
  } catch (error) {
    console.log(error);
  }
}

export async function saveImageToCache(req: string, res: any) {
  try {
    return new Promise((resolve,reject)=>{
      window.caches.open(CACHE_NAME).then((cache) => {
        const options = {
          headers: {
            "Content-Type": "image/jpeg",
          },
        };
        const request = new Request(req);
        const blob = new Blob([res])
        blob.arrayBuffer().then((buffer:any)=>{
          var arrayBufferView = new Uint8Array(buffer)
          let url = getBlobObjectUrl(arrayBufferView)
          const response = new Response(buffer);
          cache.put(request, response);
          disposeVariables([arrayBufferView,buffer,blob])
          resolve("Saved to cache")
        })
       
      }).catch(err=>{
        reject(err)
      })
    })
  
  } catch (error) {}
}

export function checkScreenshotInCache(key: string) {
  try {
    return new Promise((resolve,reject)=>{
      window.caches.match(key).then((cacheRes:any) => {
        if(cacheRes){
          cacheRes.arrayBuffer().then((buffer:any)=>{
            let blob = new Blob([buffer]);
            let url = getBlobObjectUrl(blob)
            disposeVariables([blob,buffer])
            resolve(url)
          })
        }else{
          reject("File not found")
        }
      });
    }) 
  } catch (error) {
    console.log(error);
  }
}

export async function saveUploadedFileInCache(key: string,blob:any) {
  try {
    return new Promise((resolve,reject)=>{
      window.caches.open(CACHE_NAME).then((cache) => {
        const options = {
          headers: {
            "Content-Type": "image/jpeg",
          },
        };
        const request = new Request(key);
        console.log(request)
        blob.arrayBuffer().then((buffer:any)=>{
          const response = new Response(buffer);
          cache.put(request, response);
          resolve("Saved to cache")
        })
       
      }).catch(err=>{
        reject(err)
      })
    })
  
  } catch (error) {}
}
 