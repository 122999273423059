import React from "react";
import { Customer } from "../../../../../../utils/store/storeConfiguration";
import Input from "../../../../../common/ui-components/input/Input";

 

const CustomerDetailsForm: React.FC<{
  foundCustomer:Customer
}> = (props) => {


 
    
  return (
    <div className="marginTop10">
    <div className="heading2 text-center ion-padding">Enter customer name</div>
    <Input
      type="text"
      id="customerName"
      label=""
      isMandatory={true}
      onChange={()=>{}}
      value={props.foundCustomer?.customerName}
      className={props.foundCustomer?"disable":""}
    />
  </div>
  );
};

export default CustomerDetailsForm;
