import React, { useRef, useState } from "react";
import Button2 from "../../../common/ui-components/buttons/Button2";
import $ from "jquery"
 

const DemoMessage: React.FC<{
}> = (props) => {
 

  const componentRef = useRef()

  function hide() {
    $(componentRef.current).fadeOut()
  }
 
  return (

    <div ref={componentRef} className="full-height-width top-left position-fixed" style={{background:"rgba(0,0,0,0.5)",zIndex:1001}}>
         <div className="middle">
          <div className="white-card center-aligned-column " style={{width:"25rem",height:"auto"}}>
            <div className="padding10 full-width heading1 text-center border-bottom center-aligned-column bg-color-light">Product Configurator Integration Demo!!</div>
            <div className="heading4 color-black text-center font-normal ion-padding">
            This is a Demo Website designed to showcase the seamless integration of our product configurator with your online shopping experience. 
            Explore our Demo Products and see how you can personalize your selections to suit your style and needs.
            </div>
            <div className="center-aligned-column ion-padding">
            <Button2
                configObj={{id:""}}
                action={hide}
                label={"Ok"}
                fontAwesomeIcon={""}
                iconPos=""
                classNames={"no-shadow margin0 display- bg-color-warning"}
            />
            </div>
          </div>
         </div>
    </div>
    
  );
};

export default DemoMessage;
