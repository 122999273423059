import { IonIcon } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import {
  showCustomizeButton
} from "../../../../../../utils/customizein3d/modules/UI_methods";
import { openCategories } from "../../../../../../utils/moodboard/helper";
import { updateLayouts } from "../../../../../../utils/moodboard/layouts/layout";
import { hidePriorityToggleButton } from "../../../../../../utils/moodboard/layouts/UI_methods";
import { expandItemsPanel, hideActionTabs } from "../../../../../../utils/moodboard/UI_methods";
import { rippleEffectOnClick } from "../../../../../../utils/UI_methods/ui_effects";

const DesktopTabs: React.FC<{
  configObj:any
  tabItems:Array<any>
}> = (props) => {


  useEffect(()=>{
    props.configObj.setFunctionRef("moodboard",{updateTabValue:updateTabValue})
  },[])
  
  function updateTabValue(tabContentId: any, tabId: string,isEnable:boolean = true) {
    // hideActionTabs()  

    //If toggling space planner dont updateFraming 
    
    expandItemsPanel()


    $(".tab-content").removeClass("--is-active");
    $("#" + tabContentId).addClass("--is-active");

    $(".controls-tab").removeClass("--is-active");
    $("#" + tabId).addClass("--is-active");

    if(tabContentId === "productsContainer"){
      setTimeout(() => {
        openCategories()
      }, 200);
    }

    if(tabId === "layouts"){
      updateLayouts()
    }else{
      hidePriorityToggleButton()
    }

   
  }


  function closeMenuHandle() {
    hideActionTabs();
    showCustomizeButton();
  }
  
  return (
    <React.Fragment>
      
      <div className="main-tab-container border position-relative hide-in-mobile" id="customizein3dActionTabs">
    
        <div className="full-height-width"
          style={{ justifyContent: "flex-start", padding: "0"}}>
          <div className="heading1 full-width padding1rem font-normal text-center color-danger border-bottom">
            Add Items
          </div>
          <div className="overflow-y-scroll full-height-width" style={{paddingBottom:"5rem"}}>
          {props.tabItems.map((item: any, index: number) => {
            let classNames = [];
            classNames.push("controls-tab desktop-controls-tab border-bottom overflow-hidden position-relative center-aligned-column");
           
            return (
              <div
                id={item.tabId}
                className={classNames.join(" ")}
                key={index}
                onPointerDown={rippleEffectOnClick}
                onClick={(event) => {
                  updateTabValue(item.tabName, item.tabId);
                }}>
                {/* <img
                  src={item.img}
                  alt=""
                  className="tab-icon-image"
                  width="40"
                  height="40"
                /> */}

                <IonIcon className="font-large text pointer-none marginBottom5" icon={item.tabIcon} />
                <div className="text heading1 pointer-none font-md-small">{item.tabLabel}</div>
              </div>
            );
          })}
          </div>
        </div>

        {/* <CloseMenu
          heading="Close"
          action={hideActionTabs}
        /> */}

        {/* <div className="right-middle" style={{right:"-10px"}} onClick={hideActionTabs}>
          <div className="background-white center-aligned-column" style={{padding:"15px 3px"}}>
            <i className="fas fa-chevron-left color-dark font-small"></i>
          </div>
        </div> */}

      </div>
 
    </React.Fragment>
  );
};

export default DesktopTabs;
