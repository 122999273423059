import $ from "jquery"
import { showGlobalToast } from "../../UI_methods/global"

export class Debugger{
    sheen:number = 0 
    roughness:number = 0 
    clearcoat:number = 0 
    uvScale:number = 12
    transmission:number = 0
    metalness:number = 0


    isEnabled:boolean = false


    constructor(){

    }

    open(){
        if(this.isEnabled === false){
            this.isEnabled = true
            showGlobalToast("Enabled Debugging Mode")
        }
        $("#DebuggerstoreControlsPanel").fadeIn()
    }

    close(){
        this.isEnabled = false
        $("#DebuggerstoreControlsPanel").fadeOut()
        showGlobalToast("Disable Debugging Mode")
    }

    testMode(){
        $("#DebuggerstoreControlsPanel").fadeOut()
        showGlobalToast("Testing Mode")
    }
}