import React, { useState } from 'react';

import FinishContainer from './FinishContainer';


const Finish: React.FC<{
  configObj: any;
}> = (props) => {




  return (
    <React.Fragment>
       <FinishContainer
        configObj={props.configObj}
      />
  </React.Fragment>
  )
};

export default Finish;
