import React, { useState } from "react";
import { disableFloorplanMode, projectConfiguration } from "../../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { currSelectedAreaForEdit, disableSelectWalls, enableSelectWalls, hideWallSelectionHint, highlightSelectedAreaWalls, highlightSelectedWalls, removeAllAnchors, showWallSelectionHint } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/floorplanUI";
import { saveConfiguration } from "../../../../../../../utils/customizein3d/area3dmodel/helper";
import { createAreaWallsBoundingBoxMapping, setInitialWallColor } from "../../../../../../../utils/customizein3d/area3dmodel/walls";
import { applicationConfig } from "../../../../../../../utils/methods";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import SelectNorthWall from "./SelectNorthWall";

const EditFloorplan: React.FC<{
  areaDetailsMapping:any
  isFinalizeMode:boolean
  areasList:Array<any>
  updateWallHandle:(val:string)=>void
  completeCount:number
  addAnchorHandle:(areaName:string)=>void
  setIsUpdateMode:(val:boolean)=>void
  isUpdateMode:boolean
}> = (props) => {
 
  const[currSelectedArea,setCurrSelectedArea] = useState(null)
   
  // function updateHandle(areaName:string) {
  //   props.setCurrAreaFloorplan(areaName)
  //   showWallSelectionHint()
  //   enableSelectWalls(props.currselectedArea)
  //   setTimeout(() => {
  //     highlightSelectedAreaWalls(areaName)
  //   }, 600);
  // }

  
  function addHandle(event:any,areaName:string) {
    // props.setCurrAreaFloorplan(areaName)
    // showWallSelectionHint()
    // enableSelectWalls(props.currselectedArea)
  }

  function updateAreaWallsHandle(areaName:string) {
    props.setIsUpdateMode(true)
    setCurrSelectedArea(areaName)
    props.updateWallHandle(areaName)
  }

  function confirmWallsHandle() {
    let areaName = currSelectedArea || currSelectedAreaForEdit
    props.setIsUpdateMode(false)
    props.addAnchorHandle(areaName)
  }

  function disableFloorPlanMode() {
    projectConfiguration.setAreAreasDefined(true)
    // projectConfiguration.update360CamerasPosition()
    projectConfiguration.updateLocalStorage()
    saveConfiguration(true,projectConfiguration).then(data=>{
      disableFloorplanMode()
      setInitialWallColor()
      removeAllAnchors()
      createAreaWallsBoundingBoxMapping()
      projectConfiguration.updateLocalStorage()
    }).catch(err=>{
      console.log(err)
    })
  }


  function cancelHandle() {
    props.setIsUpdateMode(false)
    disableSelectWalls()
    hideWallSelectionHint()
    setCurrSelectedArea(null)
    highlightSelectedWalls()
  }


  return (
    <>
    <div id="floorplanAreasPanel" className={`right-middle display-none add-anchor-walls-wrapper zindex30 white-card padding10 shadow-light overflow-y-scroll hide-scrollbar ${props.isFinalizeMode?" display-none":""}`} style={{width:"250px",right:"100px",height:"auto"}}>
      
      <div className="display-flex-row border-bottom no-wrap bg-color-black" style={{padding:"15px 10px"}}>
        {!props.isFinalizeMode && false?
        <div className="heading3 font-normal color-white">Set camera position & walls <span className="heading3 marginleft5 color-white font-small">({props.completeCount}/{props.areasList.length} completed)</span> </div>
        :
        <div className="heading3 font-normal color-white">Update camera position</div>
        }
        </div>
      {props.areasList?.map((area:any,index:any)=>{
        let classNames = "padding10 border-bottom display-flex-row full-width no-wrap space-between background-white marginTop10 area-details-wrapper"
        if(props.areaDetailsMapping){
          if(!props.areaDetailsMapping[area.area_name]?.walls){
            classNames = classNames + ""
          }
        }
        return(
          <div className={classNames} key={index} id={`areaDetailsWrapper${area.area_name}`}>
            <div className="area-header display-flex-row no-wrap">
              {props.areaDetailsMapping?props.areaDetailsMapping[area.area_name]?.walls?
              <div className="center-aligned-column"> <i className="fas fa-check font-small color-success"></i> </div>
              :
              <div className="center-aligned-column"> <i className="fas fa-exclamation-circle font-small color-warning"></i> </div>
              :null  
              }
              <div className="heading3 font-md-small center-aligned-column marginleft10 color-black">{area.area_name}</div>
            </div>
            <div className="area-details full-width display-flex-row no-wrap " style={{justifyContent:"flex-end"}}>

              <div className="display-flex-row no-wrap">
                {props.areaDetailsMapping?!props.areaDetailsMapping[area.area_name]?.walls?
                <div className="pointer visibility-hidden color-white border bg-color-dark heading4  white-card shadow-light" onMouseDown={(event)=>addHandle(event,area.area_name)} style={{padding:"5px 10px"}}>
                  set
                </div>
                :
                <div className={`pointer font-small color-black border bg-color-light heading4 white-card shadow-light ${props.isFinalizeMode?" disable":""}`} onClick={()=>updateAreaWallsHandle(area.area_name)} style={{padding:"3px 7px"}}>
                  Edit
                </div>
                :null}
              </div>
            </div>

          </div>
        )
      })}

    </div>

    

    <SelectNorthWall/>

    {props.isUpdateMode?
    <div className="bottom-middle zindex100">
      <div className="padding5  white-card ion-margin-bottom">
      <div className="display-flex-row no-wrap">
          <Button2
            configObj={applicationConfig}
            action={cancelHandle}
            label={"Cancel"}
            fontAwesomeIcon={"fas fa-times color-danger"}
            iconPos="left"
            classNames={"bg-color-light no-shadow button-"}
          />
          <Button2
            configObj={applicationConfig}
            action={()=>confirmWallsHandle()}
            label={"Confirm"}
            fontAwesomeIcon={"fas fa-check"}
            iconPos="left"
            classNames={"bg-color-primary no-shadow border button- marginleft5"}
          />
      </div>
    </div>
    </div>
    :
    <div  id="" className="bottom-right  ion-padding zindex30" style={{right:"15px"}}>
      <div className="pulse-anchor hide-in-north-wall-selection" id="">
        <Button2
          configObj={applicationConfig}
          action={disableFloorPlanMode}
          label={`${projectConfiguration.areAreasDefined?"Confirm walls":"Confirm walls"}`}
          fontAwesomeIcon={"fas fa-chevron-right"}
          iconPos="right"
          classNames={"bg-color-primary button-large margin0"}
        />
      </div>
    </div>
    }
    

    </>
  );
};

export default EditFloorplan;
 
