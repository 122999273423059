import React from "react";

const ProjectInfo: React.FC<{
  projectName: string;
}> = (props) => {


  return (
    <div className="flex1 position-relative">
      <div
        className="padding10 top-left"
        style={{ paddingTop: 0, paddingLeft: 0 }}>
        <span className="color-black heading1 font-normal"></span>
        {/* <span className="heading2 color-black">{props.projectName}</span>   */}
      </div>

      {/* <div className="aspect-four-by-three">
        <img src="assets/images/placeholder.png" className="full-height" id="shareProductImage" alt="" />{" "}
      </div> */}
    </div>
  );
};

export default ProjectInfo;
