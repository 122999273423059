export var lshapeConfiguration = {
    "productName": "LShape1",
    "categoryName": "Kitchen",
    "subCategoryName": "LShapeKitchen",
    "enabled": true,
    "isAddedAsNew": true,
    "transform": {
        "position": {
            "x": 0,
            "y": 0,
            "z": 0
        },
        "rotation": {
            "_x": 0,
            "_y": 0,
            "_z": 0,
            "_w": 1
        },
        "scale": {
            "x": 1,
            "y": 1,
            "z": 1
        }
    },
    "productId": "",
    "categoryId": "",
    "originalProductName": "LShape1",
    "productInstanceName": "LShape1_5",
    "isAttachedToWall": false,
    "isWallMounted": 0,
    "normal": {
        "x": 0,
        "y": 0,
        "z": 1
    },
    "configuration": {
        "BaseShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter3": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter4": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter5": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter6": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter7": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter8": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter9": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter3": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter4": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter5": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter6": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter7": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter3": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter4": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter5": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter6": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter7": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter8": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftBase": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Base": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "Skirting": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "Handle": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "CounterTop": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BackSplash": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseDrawer1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseDrawer2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "TallUnitShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "WallUnitShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "WallUnitShutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "WallUnitBase": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "OpenShelf": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        }
    },
    "moduleConfiguration": {
        "modules": [
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "Corner1Kitchen",
                "moduleType": "Base",
                "subModuleType": "Corner",
                "moduleObjectId": "8403C607-B3CB-41B4-AEEB-43045EC7ED1F",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -571.0195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 2,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "8907C982-6887-4A84-9466-534C00BC3BC8",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -571.5195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "1C5770EA-F578-4294-A911-CAABB5FE4564",
                "transform": {
                    "position": {
                        "x": 265.63646093750003,
                        "y": 4176.518847584724,
                        "z": -570.0295546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 1,
                        "_z": 0,
                        "_w": 6.123233995736766e-17
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "F931033E-5088-4200-85D1-9FDD8366319F",
                "transform": {
                    "position": {
                        "x": 265.13646093750003,
                        "y": 4176.518847584724,
                        "z": -570.0295546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 1,
                        "_z": 0,
                        "_w": 6.123233995736766e-17
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "4413D325-EEB0-4801-9FFC-D40B3A347617",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -572.0195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "TallUnit1Kitchen",
                "moduleType": "Tall",
                "subModuleType": "For fridge and freezer",
                "moduleObjectId": "2625FA7E-8967-40B6-ABF1-E95D77CD2457",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -573.0605631961012
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 2,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "509D1577-0E7D-4ADA-A7C2-F79EBC58CE1A",
                "transform": {
                    "position": {
                        "x": 264.63646093750003,
                        "y": 4176.518847584724,
                        "z": -570.0295546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 1,
                        "_z": 0,
                        "_w": 6.123233995736766e-17
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "4A80CBBE-41CA-4330-965B-0165B1402C0D",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -572.5195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            }
        ],
        "addons": [],
        "isGroupRotationVertical": false,
        "groupRotation": 0,
        "isViewProductMode": false,
        "allowSpacePlanner": true,
        "allowFinish": true,
        "allowAr": true,
        "allowAddons": true
    }
}