import React, { useState } from "react";
import { CotConfiguration, STORE } from "../../../../../../utils/store/storeConfiguration";
 

const LengthSelector: React.FC<{
  onChange:(val:string)=>void
  productConfiguration:CotConfiguration
  length:string
  configuration:any
}> = (props) => {

  const[currSelection,setCurrSelection]:any =  useState(props.productConfiguration.length)

  function onChange(config:any,id:number) {
    // props.onChange(config.name)
    setCurrSelection(config.name)
    props.productConfiguration.length = config.name
    props.productConfiguration.dimensions.depth = config.name
  }


  return (

    <div className="position-relative">
 

      <div className="center-aligned-row customization-options ion-padding-bottom">
       

          
          {props.configuration.map((config:any,index:number)=>{
              let url = `${STORE.BASE_URL_ASSETS}icons/UpholsteredBed/depth.svg`
              return(
                <div key={index} onClick={()=>onChange(config,config.id)} className={`center-aligned-column item position-relative white-card shadow-light ${currSelection == config.name?" --is-active":""}`}>
                  <div className="pointer-none midde">
                  <div className="padding5 center-aligned-row"><img src={url} className="icon" style={{width:"6rem",margin:"auto"}}/></div>
                    <div className="heading2  font-normal pc-color-primary text text-center text-ellipsis ">{config.name}"</div>
                    <div className="heading4 font-small text text-center ">{config.description}</div>

                  </div>
                  <div className="top-right">
                    {currSelection == config.name?
                    <i className="far fa-check-circle select-icon font-medium"></i>
                    :
                    <i className="far fa-circle select-icon font-medium"></i>
                    }
                  </div>


                </div>
              )
        })}

      
        

      </div>

     
      
    </div>

    
        
  );
};

export default LengthSelector;
