import { IonIcon } from '@ionic/react';
import {
  checkmarkCircle,
  close
} from "ionicons/icons";
import React from 'react';
 
const CartToast: React.FC<{
  }> = (props) => {


 
  return (

    <React.Fragment>
      <div className='cart-toast-wrapper' id="cartToast">
        <div className='center-aligned-column cart-toast-container'>
          <div className='display-flex-row'>
            <div className='center-aligned-column'>
              <i id="cartToastIcon" className='fas fa-check-circle center-aligned-column font-medium marginRight5 color-warning' />
              {/* <IonIcon id="cartToastIcon" className='center-aligned-column font-medium marginRight5 color-warning' icon={checkmarkCircle} /> */}
            </div>
            <div id="cartToastMessage" className='color-white'>Item Added To cart</div>
          </div>
        </div>
      </div>
      </React.Fragment>
  );
};

export default CartToast;
