import { getTodayTimestampOnlyDate } from "../store/crm/calendar";
import $ from "jquery"


export function  getMaterialItemFormData() {
    return {
        material_name:$("#material_name").val(),
        material_type:$("#material_type").val(),
        description:$("#description").val(),
        quality_variant:$("#quality_variant").val(),
        unit_of_measure:$("#unit_of_measure").val(),
        storage_recommandation:$("#storage_recommandation").val(),
        alternative_unit_of_measure:$("#alternative_unit_of_measure").val(),
        quantity_in_stock:$("#quantity_in_stock").val(),
        reorder_level:$("#reorder_level").val(),
        reorder_quantity:$("#reorder_quantity").val(),
        lead_time:$("#lead_time").val(),
        safety_stock:$("#safety_stock").val(),
        average_consumption_rate:$("#average_consumption_rate").val() || 1,
        minimum_order_quantity:$("#minimum_order_quantity").val(),
        maximum_order_quantity:$("#maximum_order_quantity").val(),
        unit_weight:$("#unit_weight").val(),
        notes:$("#notes").val(),
        created_at:getTodayTimestampOnlyDate(),
    }
}