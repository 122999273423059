import $ from "jquery";
import React, { useEffect, useState } from "react";
import AwsConfig from "../../../../../utils/aws-sdk/AwsConfig";
import { untarAndSaveToCache } from "../../../../../utils/cache/cache";
import {
  addSpacePlannerImages,
  createLayout,
  removeAllSprites,
  toggleLayoutVisibility
} from "../../../../../utils/customizein3d/modules/customizein3d";
import { getSpacePlannerImageUrl } from "../../../../../utils/customizein3d/modules/helper";
import { getUrlPrefix } from "../../../../../utils/customizer/customizer";
import DropDown from "../../../ui-components/filters/DropDown";
import Button2 from "../../../ui-components/buttons/Button2";
import { rippleEffectOnClick } from "../../../../../utils/UI_methods/ui_effects";

const SpacePlanner: React.FC<{
  configObj: any;
}> = (props) => {
  // let elements = ["Door", "Bed", "Lamp", "Dining", "TV_Unit"];
  let elements = [
    {name:"Door",icon:"fas fa-window-restore color-dark"},
    {name:"Bed",icon:"fas fa-bed color-dark"},
    // {name:"Lamp",icon:"fas fa-lightbulb color-dark"},
    {name:"Dining",icon:"fas fa-utensils color-dark"},
    {name:"TV_Unit",icon:"fas fa-tv color-dark"},
    {name:"Table",icon:"fas fa-chair color-dark"},
  ]
  useEffect(() => {
    let awsConfig = new AwsConfig();
    //untar and save product images in cache
    for (let i = 0; i < elements.length; i++) {
      let url = getSpacePlannerImageUrl("/", props.configObj?.clientName,elements[i].name);
      let tarFileKey = url.replace(".png", ".tar.gz");
      let urlPrefix = getUrlPrefix(url);
      untarAndSaveToCache(awsConfig, urlPrefix, tarFileKey)
    }

    return () => {
      awsConfig = null
    }
  }, []);

  let lengthFeetDropDownValues = [];
  let breadthFeetDropDownValues = [];

  let lengthInchDropDownValues = [];
  let breadthInchDropDownValues = [];

  for (let i = 5; i < 36; i++) {
    lengthFeetDropDownValues.push(i);
    breadthFeetDropDownValues.push(i);
  }
  
  for (let i = 1; i < 12; i++) {
    lengthInchDropDownValues.push(i);
    breadthInchDropDownValues.push(i);
  }

  const [currSelectedLengthFeetValue, setcurrSelectedLengthFeetValue] = useState("19");
  const [currSelectedBreadthFeetValue, setcurrSelectedBreadthFeetValue] = useState("19");

  
  const [currSelectedLengthInchValue, setcurrSelectedLengthInchValue] = useState("9");
  const [currSelectedBreadthInchValue, setcurrSelectedBreadthInchValue] = useState("9");

  function toggleLayoutHandle() {
    toggleLayoutVisibility(!isGroundOutlineVisible)
    setIsGroundOutlineVisible(!isGroundOutlineVisible)
  }

  // useEffect(()=>{
  // },[currSelectedBreadthFeetValue,currSelectedBreadthInchValue,currSelectedLengthFeetValue,currSelectedLengthInchValue])

  const[isGroundOutlineVisible,setIsGroundOutlineVisible] = useState(true)
  return (
    <div className="spaceplanner-controls-wrapper overflow-y-scroll position-relative full-height-width">
      <div className="room-options-container display-none">
        {/* <div className="heading text-center border-bottom padding10">
          Room Options
        </div> */}
        <div className="heading2 font-normal bg-color-light text-center color-black padding5">1. Room Size</div>

        <div className="room-layout-inputs-container">

          <div className="ion-padding-top display-flex-row space-between no-wrap option">
            <div className="label color-black heading4 marginRight10 center-aligned-column">Length</div>
            <div className="display-flex-row space-between no-wrap">
              <div className="display-flex-row no-wrap">
                <DropDown
                  columns={1}
                  classNames="hide-search room-length-feet"
                  values={lengthFeetDropDownValues}
                  currSelectedValue={currSelectedLengthFeetValue}
                  action={setcurrSelectedLengthFeetValue}
                  label=""
                />
                <div className="center-aligned-column dimension-label label" >
                  {" "}
                  Ft.
                </div>
              </div>
              <div className="display-flex-row no-wrap">
                <DropDown
                  columns={1}
                  classNames="hide-search room-length-inch"
                  values={lengthInchDropDownValues}
                  currSelectedValue={currSelectedLengthInchValue}
                  action={setcurrSelectedLengthInchValue}
                  label=""

                />
                <div className="center-aligned-column dimension-label label" >
                  {" "}
                  In.
                </div>
              </div>
            </div>
          </div>

            <div className="ion-padding-top display-flex-row  space-between no-wrap option">
            <div className="label color-black heading4 marginRight10 center-aligned-column">Breadth</div>
            <div className="display-flex-row space-between no-wrap">
              <div className="display-flex-row no-wrap">
                <DropDown
                  columns={1}
                  classNames="hide-search room-breadth-feet"
                  values={breadthFeetDropDownValues}
                  currSelectedValue={currSelectedBreadthFeetValue}
                  action={setcurrSelectedBreadthFeetValue}
                  label=""

                />
                <div className="center-aligned-column dimension-label label" >
                  {" "}
                  Ft.
                </div>
              </div>
              <div className="display-flex-row no-wrap">
                <DropDown
                  columns={1}
                  classNames="hide-search room-breadth-inch"
                  values={breadthInchDropDownValues}
                  currSelectedValue={currSelectedBreadthInchValue}
                  action={setcurrSelectedBreadthInchValue}
                  label=""

                />
                <div className="center-aligned-column dimension-label label" >
                  {" "}
                  In.
                </div>
              </div>
            </div>
          </div>


        </div>

        

        

        <div className="center-aligned-column no-wrap ion-padding-top">
          <div className="display-flex-row ion-padding no-wrap">
            <Button2
              configObj={{id:"updateLayoutButton"}}
              action={() => createLayout(currSelectedLengthFeetValue,currSelectedLengthInchValue,currSelectedBreadthFeetValue,currSelectedBreadthInchValue)}
              label={"Update Layout"}
              fontAwesomeIcon={"fas fa-edit color-black"}
              iconPos="left"
              classNames={"shadow-light button-small"}
            />
            {/* <button id="updateLayoutButton" className="op-btn op-btn-primary-inverse" onClick={() => createLayout(currSelectedLengthFeetValue,currSelectedLengthInchValue,currSelectedBreadthFeetValue,currSelectedBreadthInchValue)
              }><i className="fas fa-edit color-dark"></i> Update Layout</button> */}
              <Button2
                configObj={{id:"toggleLayoutButton"}}
                action={toggleLayoutHandle}
                label={isGroundOutlineVisible?"Hide layout":"Show layout"}
                fontAwesomeIcon={"fas fa-eye-slash color-black"}
                iconPos="left"
                classNames={"shadow-light button-small"}
              />
            {/* <button className="op-btn op-btn-primary-inverse marginleft5" onClick={()=>{ }}><i className="fas fa-eye-slash"></i> {isGroundOutlineVisible?<>Hide layout</>:<>Show layout</>}</button> */}
          </div>
        </div>
      </div>

      <div className="elements-container ion-margin-bottom ion-padding-top">
        <div className="heading2 font-normal bg-color-light text-center color-black padding5">Add elements to room</div>

      
        <div className="center-aligned-row full-width option space-planner-images" style={{flexWrap:"wrap"}}>
          {/* <Search classNames="" 
            placeholder="Search element"
            action={()=>{}}
          /> */}
          {elements.map((element: any, index: number) => {
            return (
              <div key={index} onPointerDown={rippleEffectOnClick} className="ion-margin-top width30 overflow-hidden position-relative border space-planner-image click-effect marginleft5 pointer shadow-light width30"  onClick={() => addSpacePlannerImages(element.name)}>
                <div className="padding10 full-width">
                  <div className="center-aligned-row padding10"><i className={`${element.icon} font-large`}></i></div>
                  <div className="heading4 font-md-small color-black text-center">{element.name}</div>
                </div>
                {/* <button
                  className="op-btn-primary-inverse op-btn"
                  onClick={() => addSpacePlannerImages(element.name)}>
                  <i className={element.icon}></i> {element.name}
                </button>{" "} */}
              </div>
            );
          })}
        </div>

        <div className="delete-button center-aligned-row ion-padding">
            <Button2
              configObj={{}}
              action={removeAllSprites}
              label={"Remove all"}
              fontAwesomeIcon={"fas fa-trash color-danger"}
              iconPos="left"
              classNames={"shadow-light border"}
            />
          </div>

      </div>
    </div>
  );
};

export default SpacePlanner;
