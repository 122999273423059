import { IonIcon } from "@ionic/react";
import { alertCircle, checkmarkCircle } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import "../../../style/ui-components/multistep.css";
import $ from "jquery"
import { updateFillbarPosition } from "../../../../utils/UI_methods/global";


const MultiSteps: React.FC<{
  configObj: any;
  steps:Array<any>
  activeItemsList:Array<any>
  action:(value:string)=>void
  currSelectedValue:string
}> = (props) => {

  // useEffect(()=>{
  //   console.log(props.currSelectedValue)
  // },[props.currSelectedValue])


  const[barFillWidth,setBarFillWidth] = useState(0)

  useEffect(()=>{
    if(props.steps){
      setBarFillWidth(props.steps.length)
      //If calling from products
        setTimeout(() => {
          // let index = props.steps.indexOf(props.currSelectedValue)
          updateFillbarPosition()
        }, 1000);
    }
  },[props.steps,props.currSelectedValue])

  useEffect(()=>{
    if(props.steps.length && props.currSelectedValue!=""){
      // let index = props.steps.indexOf(props.currSelectedValue)
      updateFillbarPosition()
    }
  },[props.currSelectedValue,props.steps,props.activeItemsList])
 
  return (
    <div className="full-width  multisteps-wrapper">
      {/* <div className="multisteps-progress-bar">
        <div className="multisteps-bar-fill top-left" id="multistepsFillBar" style={{padding:"0"}}></div>
      </div> */}
        <div className="multisteps-container full-width hide-scrollbar overflow-x-scroll">
          {props.steps?.map((step:any,index:number)=>{
            let classNamesContainer = "step-container center-aligned-column"
            let classNamesIonIcon = "font-medium icon"
            let ionIconName = alertCircle
            // if(index===0){
            //   classNamesContainer = classNamesContainer + " --is-visible"
            // }
            if(props.activeItemsList?.includes(step)){
              classNamesContainer = classNamesContainer + " --is-visible"
              classNamesIonIcon = classNamesIonIcon + " color-primary"
              ionIconName = checkmarkCircle
            }else{
              classNamesIonIcon = classNamesIonIcon + " color-warning"
            }
            if(step === props.currSelectedValue){
              classNamesContainer = classNamesContainer + " --is-active"
              ionIconName = checkmarkCircle
            }
            return(
              <div className={classNamesContainer} key={index}  onClick={()=>props.action(step)} style={{width:`calc(100% / ${barFillWidth})`}}>
                <div className="step display-flex-row no-wrap">
                  <div className="text capitalize letter-spacing marginleft5 full-height center-aligned-column">
                    {index+1} . {step}
                  </div>
                </div>
              </div>
            )
          })}

          {/* <VirtualTourLink
            configObj={props.configObj}
            steps={props.steps}
            activeItemsList={props.activeItemsList}
          /> */}
        </div>
      </div>
  );
};

export default MultiSteps;
