import React, { useEffect, useState } from "react";
import { applicationConfig, getFilteredArray } from "../../../../../../../../utils/methods";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import { Customer, STORE } from "../../../../../../../../utils/store/storeConfiguration";
import Alert from "../../../../../../../common/ui-components/alerts/Alert";
import Input from "../../../../../../../common/ui-components/input/Input";
import Select from "../../../../../../../common/ui-components/input/Select";
import SelectInput from "../../../../../../../common/ui-components/input/SelectInput";
 


 
const Form: React.FC<{
    alertId:string
    isUpdate:boolean
    customer:Customer
}> = (props) => {

    const[customersList,setCustomersList] = useState([])
    const[users,setUsers] = useState([])
    const[isManager,setIsManager] = useState(false)



    useEffect(()=>{
        STORE.storeApi.getCustomersList().then((data:any)=>{
            if(!data.error){
            if(data.data?.data?.length){
                setCustomersList(data.data.data)
            }
            }
        })

        if(applicationConfig?.user?.userType.toLowerCase() === "manager"){
            setUsers(CRM.data.users.filter(currUser => currUser.user_type === "InsideSales" || currUser.user_type === "FieldSales"))
            setIsManager(true)
        }else{
            let userId = applicationConfig?.user?.userId
            setUsers(getFilteredArray(CRM.data.users,"user_id",userId))
        }
    },[])

  

    function onChangeCity(event:any) {
    }


  return (

    <div className={`create-lead-form full-width overflow-y-scroll full-height ${!props.isUpdate?" padding-container ":""}`}>
        <Alert id={props.alertId}/>

        <div className={`input-container no-wrap ${props.isUpdate?" display-none":""}`}>
            <div className="position-relative">
                <input type="hidden" id="customerId" className="display-none" />
                <Input
                    type="text"
                    id="customerNameCreateLead"
                    label="Customer mobile and name"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={`${props.customer?.customerName} , ${props.customer?.mobile}`}
                    className="disable"
                />
                    {/* <div className={`${filteredCustomers.length?" top-middle":" display-none"}`} style={{padding:"0 5px",top:"100%",zIndex:999,width:"100%"}}>
                        <div className="full-width shadow-medium border customers-list bg-color-white">
                        {filteredCustomers.map((customer:any,index:number)=>{
                            return(
                            <div className={`pointer display-flex-row border-bottom no-wrap padding10 ${index>1?" display-none":" "}`} onClick={()=>{setCustomerHandle(new Customer(customer))}} key={index}>
                                <span className={`heading3 font-md-small`}>{customer.customer_name}</span>
                                <span className={`heading3 marginleft10 color-dark font-small`}>+91 {customer.mobile}</span>
                            </div>
                            )
                        })}
                        </div>
                    </div> */}
            </div>
        </div>

        {props.isUpdate?
            <div className={`display-flex-row`}>
                <div className="width50">
                    <Input
                        type="text"
                        id="customerNameCreateLead"
                        label="Customer name"
                        isMandatory={false}
                        onChange={()=>{}}
                        value={"Kartik Katahre"}
                        className="disable"
                    />
                </div>
                <div className="width50">
                    <Input
                        type="text"
                        id="customerMobile"
                        label="Mobile"
                        isMandatory={false}
                        onChange={()=>{}}
                        value={"9993974654"}
                        className="disable"
                    />
                </div>
            </div>
        :null}

        

        <div className={`display-flex-row ${props.isUpdate?" display-none":""}`}>
            <div className="width50">
                <Select
                    id="leadSource"
                    label="Lead source"
                    isMandatory={true}
                    onChange={()=>{}}
                    options={["Chat bot","Reference","Direct call","WhatsApp","Other"]}
                    className=""
                    value={""}
                />
            </div>
            <div className="width50">
                <Select
                    id="salesStage"
                    label="Sales stage"
                    isMandatory={false}
                    onChange={()=>{}}
                    options={["Prospect"]}
                    className="disable"
                    value={""}
                />
            </div>
        </div>
       

        <div className="display-flex-row">
            <div className="width50">
                <Select
                    id="potentialValue"
                    label="Potential value"
                    isMandatory={true}
                    onChange={()=>{}}
                    options={["Less then 50K","50K to 1 Lacs ","Above 1 Lacs"]}
                    className=""
                    value={""}
                />
            </div>
            <div className="width50">
                <Select
                    id="productCategory"
                    label="Product Category"
                    isMandatory={true}
                    onChange={()=>{}}
                    options={["Sofa","Upholstered Cot","SofaCumBed","Recliner","ArmChair"]}
                    className=""
                    value={""}
                />
            </div>
        </div>

        <div className={`display-flex-row ${props.isUpdate?" display-none":""}`}>
            <div className="width50">
                <SelectInput
                    id="cityName"
                    label="City"
                    isMandatory={true}
                    onChange={()=>{}}
                    options={CRM.data.citiesList}
                    className=""
                    value={""}
                    config={{value:"city_name",displayName:"city_name"}}
                />
                {/* <CityInput/> */}
            </div>
            <div className="width50">
                <Select
                    id="sizeOfDeal"
                    label="Size of deal"
                    isMandatory={true}
                    onChange={()=>{}}
                    options={["High","Medium","Low"]}
                    className=""
                    value={""}
                />
            </div>
        </div>

       

        <div className={`input-container no-wrap ${isManager?"":" disable"}`}>
            <SelectInput
                id="leadAssignedTo"
                label="Asigned to"
                isMandatory={false}
                onChange={()=>{}}
                options={users}
                className=""
                value={null}
                config={{value:"user_id",displayName:"username"}}
            />
        </div>
      
    </div>
  );
};

export default Form;
