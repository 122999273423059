import React, { useState } from "react";
import CardsLayout from "../../templates/layouts/page/CardsLayout";
import UsersList from "./UsersList";
import ClientsList from "./ClientsList";
import { getUser } from "../../../utils/methods";


const UsersContainer: React.FC<{
  configObj:any
}> = (props) => {


 

  const [createNewFinish, setcreateNewFinish] = useState(false);



  function MainContent() {
    return(
      <div className="full-height-width display-flex-row space-between">
        <div className="" style={{flex:"1 0 49.5%"}}>
          <ClientsList/>
        </div>
        <div className="" style={{flex:"1 0 49.5%",marginLeft:"1%"}}>
          <UsersList/>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>

        
      {/* <LogsHeader
        configObj={props.configObj}
        logsList={logsList}
        setLogsList={setLogsList}
      /> */}

      {getUser()?.username !== "testuser"?
      <div className="ion-padding">unauthorized access...</div>
      :
      <CardsLayout
        MainContent={()=>(
          <MainContent/>
        )}
        AddCardForm={null}
        configObj={props.configObj}
      />
      }

      
  </React.Fragment>
  );
};

export default UsersContainer;
