import $ from "jquery"
import { BoxGeometry, ConeGeometry, Group, Mesh, MeshBasicMaterial, Quaternion, Vector3 } from "three"
import { saveToCache } from "../../../cache/cache"
import { camera } from "../../../customizer/ProjectConfiguration"
import { applicationConfig, disposeVariables, waitFor } from "../../../methods"
import { hideComponentLoader, showComponentLoader, updateLoaderProgress } from "../../../UI_methods/global"
import { makeObjectDraggable } from "../../modules/helper"
import { setCurrSelectedProduct } from "../../raycasting"
import { disableControls, enableControls } from "../actions"
import { camera as sceneCamera, controls, customizerConfig, orthoCamera, perspectiveCamera, projectConfiguration, renderer, scene } from "../area3dModel"
import { areaWallsBoundingBoxMapping } from "../walls"
import { addPulseAnimation, enableAnchorDrag, getAnchorElement, hideAllAreaAnchors, mouseDownAnchor, showAllAreaAnchors, updateAllAreaAnchorsPosition, updateAnchorPosition, updateCameraTransform } from "./floorplanUI"

var axis = new Vector3(0,1,0)

export var rendersCamerasGroup = new Group()
var currselectedArea = null
export var isPointerDownCone = false
var startX,startY,endX,endY = 0

var areaCameraMapping = {}


export function setInitialAreasScreenshots() {
    showComponentLoader("canvasLoader")
    disableControls(controls)
    updateLoaderProgress("canvasLoader",1,1,"Updating screenshots")
    return new Promise(async (resolve,reject)=>{
        let cameras = projectConfiguration.cameras.filter(currCamera=>currCamera.renderType === "3d")
        for (let i = 0; i < cameras.length; i++) {
            const camera = cameras[i];
            let areaName = camera.areaName
            if(!camera.sceneCamera){
                gotoCameraView(camera)
                await waitFor(200)
                updateCameraTransform(areaName)
                await takeSceneScreeShot(camera)
                if(i === cameras.length - 1){
                    enableControls(controls)
                    hideComponentLoader("canvasLoader")
                    resolve("Done")
                }
            }
            if(i === cameras.length - 1){
                enableControls(controls)
                hideComponentLoader("canvasLoader")
                resolve("Done")
            }
            // await checkFromCache(imageUrlKey)?.then((url: any) => {
            //     if(i === cameras.length - 1){
            //         hideComponentLoader("canvasLoader")
            //         resolve("Done")
            //     }
            // })
            // .catch(async (err) => {
                
            // });
        }
    })
}


export async function takeSceneScreeShot(currCamera:camera) {
    let blobUrl = renderer.domElement.toDataURL('image/jpeg') 
    var bufferView = new Buffer(blobUrl.split(",")[1], 'base64');
    let key = currCamera.cameraName + ".png"
    await saveToCache(key,bufferView).then(message=>{
      console.log(message)
      disposeVariables([bufferView])
    }).catch(err=>{
    })
}

export async function updateCameraScreenshot(areaName:string) {
    let cameraObj = projectConfiguration.getCameraFromAreaName(areaName)
    if(cameraObj){
        showComponentLoader("canvasLoader")
        updateLoaderProgress("canvasLoader",1,1,"Updating screenshot..")
        gotoCameraView(cameraObj)
        await waitFor(200)
        updateCameraTransform(cameraObj.areaName)
        await takeSceneScreeShot(cameraObj)
        hideComponentLoader("canvasLoader")
    }
    
}



export function gotoCameraView(cameraObj:camera) {
    if(cameraObj){
        // #change
        //Change to camera name
        let position = projectConfiguration.projectConfiguration[cameraObj.areaName]?.anchor  
        if(position){
            sceneCamera.position.set(position.x,position.y,position.z)
            const quaternion = new Quaternion(0,0,0,1);
            sceneCamera.applyQuaternion(quaternion); 
            sceneCamera.quaternion.normalize(); 
        }else{
            console.log("camera pos not found")
        }
    }
}

export function addRenderCamerastoScene() {
    // scene.add(rendersCamerasGroup)
    for (const areaKey in projectConfiguration.projectConfiguration) {
        addCamera(projectConfiguration.projectConfiguration[areaKey])
    }
}

export function removeRenderCamerasFromScene() {
    scene.remove(rendersCamerasGroup)
}


export function addCamera(area:any) {
    let parentContainer = document.getElementById("area3DSceneWrapper")

    let anchor = $("<div></div>").addClass("anchor-icon-container anchor-3d top-middle zindex30").
    attr("id",`areaAnchor${area.areaName}`)
    .appendTo($(parentContainer))
     
    getAnchorIcon(area.areaName,anchor)

    let position = areaWallsBoundingBoxMapping["all"].center

    updateAnchorPosition(area.areaName,position)
}

var currentElement = null



export function getAnchorIcon(areaName:string,anchorElement:any) {

    $("<div></div>").addClass("anchor-icon")
    .html(`<i class='fas fa-video pointer-none font-normal icon'></i>`)
    .mousedown(onPointerDown)
    .appendTo(anchorElement)
    
    $("<i></i>").addClass("fas fa-caret-up pointer-none anchor-cone top-right")
    .appendTo(anchorElement)

    $("<i></i>").addClass("fas fa-arrows-alt-v padding0  alt rotate-icon font-small top-right")
    .mousemove(onPointerMoveCone)
    .mousedown(onPointerDownCone)
    .mouseup(onPointerUpCone)
    .appendTo(anchorElement)

    $("<div></div>").addClass("heading2 pointer-none white-card shadow-light anchor-tooltip font-ex-small top-right")
    .text(`${areaName}`)
    .appendTo(anchorElement)

}

export function onPointerDown(event:any) {
    enableAnchorDrag() 
    mouseDownAnchor(event)
   
}

export function onPointerDownCone(event:any) {
    isPointerDownCone = true
    

    var box:any = document.getElementById(`areaAnchor${currselectedArea || projectConfiguration.projectName}`)
    var rect = box.getBoundingClientRect();

    // store the center as the element has css `transform-origin: center center`
    box.setAttribute('data-center-x', rect.left + rect.width / 2);
    box.setAttribute('data-center-y', rect.top + rect.height / 2);
    // get the angle of the element when the drag starts
    // box.setAttribute('data-angle', getDragAngle(event));
}

export function onPointerMoveCone(event:any) {
    if(isPointerDownCone){
        // let diffX = event.clientX - startX
        // let diffY = startY - event.clientY 
        // let prevRotationValue = getRotationDegrees(getAnchorElement(currselectedArea))  
        // getAnchorElement(currselectedArea).css("transform",`rotateZ(${degree}deg)`)

        var angle = getDragAngle(event);
        let degree = Math.round((angle * (180 / Math.PI) * -1) + 100);
    
        getAnchorElement(currselectedArea).css("transform",`rotateZ(${degree+170}deg)`)
  
        // getAnchorElement(currselectedArea).css("transform",`rotateZ(${angle}rad)`)

        if(customizerConfig.currentRenderingType === "2d"){
            let diff = angle - controls.azimuthAngle
            controls.rotateAzimuthTo(Number(-diff))
        }else{
            let diff = angle - prevRotationValue
            perspectiveCamera.rotateOnWorldAxis(axis,Number(-diff))
        }
        prevRotationValue = angle
        startX = event.clientX
        startY = event.clientY



    }
}

export function onPointerUpCone(event:any) {
    isPointerDownCone = false

    var box:any = document.getElementById(`areaAnchor${currselectedArea || projectConfiguration.projectName}`)

    // save the angle on dragend
    box.setAttribute('data-angle', getDragAngle(event));

}


export function selectAreaCamera(areaName:string) {
    addPulseAnimation(areaName)
    currselectedArea = areaName
    let position = projectConfiguration.projectConfiguration[areaName].anchor
    perspectiveCamera.position.set(position.x,position.y,position.z)
    // perspectiveCamera.rotation.set(0,0,0)
    prevRotationValue = 0
    perspectiveCamera.rotateOnWorldAxis(axis,Number(-90) * (Math.PI / 180))
    perspectiveCamera.updateProjectionMatrix()
    // focusArea(areaName)
}


function getDragAngle(event:any) {
    var box:any = document.getElementById(`areaAnchor${currselectedArea || projectConfiguration.projectName}`)
    var startAngle = parseFloat(box.getAttribute('data-angle')) || 0;
    var center = {
        x: parseFloat(box.getAttribute('data-center-x')) || 0,
        y: parseFloat(box.getAttribute('data-center-y')) || 0
      };
      var angle = Math.atan2(center.x - event.clientX,center.y - event.clientY);
      return angle
    return angle - startAngle;
  }

function getRotationDegrees(obj) {
    var matrix = obj.css("-webkit-transform") ||
    obj.css("-moz-transform")    ||
    obj.css("-ms-transform")     ||
    obj.css("-o-transform")      ||
    obj.css("transform");
    if(matrix !== 'none') {
        var values = matrix.split('(')[1].split(')')[0].split(',');
        var a = values[0];
        var b = values[1];
        var angle = Math.round(Math.atan2(b, a) * (180/Math.PI));
    } else { var angle = 0; }
    return (angle < 0) ? angle + 360 : angle;
}


var prevRotationValue = 0

export function rotationCameraAnchorHandle(event:any) {
    // let r = 1
    let rotation = Number(event.target.value)
    let diff = rotation - prevRotationValue
    //   CURR_SELECTED_PRODUCT.rotateOnWorldAxis(axis,Number(diff) * (Math.PI / 180))
    //   applicationConfig.functions.rangeslider.setDefaultValue(rotation)

 
  
    perspectiveCamera.rotateOnWorldAxis(axis,Number(-diff) * (Math.PI / 180))
    getAnchorElement(currselectedArea).css("transform",`rotateZ(${rotation}deg)`)
    applicationConfig.functions.rangeslider.setDefaultValue(rotation)
    $("#rangeSliderValue").text(String(event.target.value))
    prevRotationValue = rotation
  }



export function updateCameraHeight360(event:any) {
    let positionY = Number(event.target.value)
    positionY = positionY / 100

    perspectiveCamera.position.set(perspectiveCamera.position.x,Number(positionY),perspectiveCamera.position.z)
    perspectiveCamera.updateProjectionMatrix()
    
    // projectConfiguration.updateCameraTransform(currselectedArea,camera)
}
 



export function focusArea(areaName:string) {
    hideAllAreaAnchors()
    let center = areaWallsBoundingBoxMapping[areaName].boundingBox.center
    let posY = orthoCamera.position.y
    controls.moveTo(center.x,posY+50,center.z,true)
    setTimeout(() => {
        updateAllAreaAnchorsPosition()
        showAllAreaAnchors()
    }, 500);
}

export function markAsConfirmed(areaName:string) {
    getAnchorElement(areaName).addClass("--is-confirmed")
}

 

export function  setAreaCamera(areaName:string,cameraGroup:any) {
    areaCameraMapping[areaName] = cameraGroup
}

export function enableCameraDrag(cameraGroup:any) {
    let dragControls = null
    dragControls =  makeObjectDraggable(scene,sceneCamera,renderer,"",dragControls,[cameraGroup],true)
    dragControls.enabled = true
    dragControls.addEventListener( 'drag', function ( event:any ) {
        console.log("dragging")
    })
    dragControls.addEventListener( 'dragstart', function ( event:any ) {
        disableControls(controls)
    })
    dragControls.addEventListener( 'dragend', function ( event:any ) {
        enableControls(controls)
    })
  }


























  export function deselectAllCameras() {
    for (const area of projectConfiguration.areasList) {
        let cameraGroup = getAreaCamera(area.area_name) 
        deselectCamera(cameraGroup)
    } 
}

function deselectCamera(cameraGroup:any) {
    if(cameraGroup){
        cameraGroup.traverse(mesh=>{
            if (mesh.isMesh) {
                mesh.material.color.set(0x000000)
                mesh.material.opacity = 0.2
            }
        })
    }
    
}

export function getAreaCamera(areaName:string) {
    return areaCameraMapping[areaName] || null
}



export function addCameraOld(area:any) {

   
    let position = area.anchor

    if(!getAreaCamera(area.areaName)){
        let cameraGroup = new Group()
        const geometry = new ConeGeometry( 0.2, 0.4, 15 );
        const material = new MeshBasicMaterial( {color: 0x000000} );
        material.needsUpdate = true
        const cone = new Mesh( geometry, material );

        let posY = areaWallsBoundingBoxMapping["all"].boundingBox.positions.max.y
        cameraGroup.position.set(position.x,posY + 10,position.z)
        
        cone.position.set(0,0,-0.25)
      
        cone.rotateOnWorldAxis(axis,Number(90) * (Math.PI / 180))

        let cameraIcon = getCameraIcon()
        cameraGroup.add( cameraIcon );
        cameraGroup.add( cone );
        rendersCamerasGroup.add( cameraGroup );

        setAreaCamera(area.areaName,cameraGroup)
    }
}

function getCameraIcon() {
    let cameraIconGroup = new Group()
    // let box = RoundedRectangle(20,20,10,15)
    let box = new BoxGeometry(0.7,0.2,0.5)
    const material = new MeshBasicMaterial( {color: 0x0083b7} );
    // material.envMap = generatedEnvironmentMap.texture
    material.needsUpdate = true
    const lowerBox = new Mesh( box, material );
    cameraIconGroup.add(lowerBox)
    return cameraIconGroup
}
