import { IonRadio, IonRadioGroup } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { applicationConfig } from '../../../utils/methods';
import { closeFiltersPanelMobile } from '../../../utils/UI_methods/global';
import Button2 from '../../common/ui-components/buttons/Button2';


  
  const Filters: React.FC<{
  }> = (props) => {

    const[sagments] = useState(["Interior","Other"])
    const[currSagement,setCurrSagment] = useState("Interior")

    useEffect(()=>{
      applicationConfig?.setState("moodboard",{currSagement:currSagement})
    },[])

    function updateSagment(value:string) {
      applicationConfig?.setModuleFilter("moodboard","sagment",value)
      let filteredArray = applicationConfig?.states.moodboard.moodboardList.filter((object:any)=>object.sagment===value)
      filteredArray.sort((a:any,b:any)=>a.moodboard_id<b.moodboard_id?-1:1)
      applicationConfig?.functions.moodboard.setMoodboardList(filteredArray)
      // props.setMoodboardList(filteredArray)
      setCurrSagment(value)
      closeFiltersPanelMobile()
    }

    return (
      <div className="">
        <div className='hide-in-mobile'>
          <Button2
              configObj={{}}
              action={()=>{}}
              label={"Filters"}
              fontAwesomeIcon={"fas fa-bars color-primary"}
              iconPos="left"
              classNames={"shadow-light margin0 font-medium full-width bg-color-black"}
            />
        </div>

        <div className='hide-in-desktop'>
          <div className='center-aligned-row full-width ion-padding'>
            <div className='subcategories-close' onClick={closeFiltersPanelMobile}></div>
          </div>
        </div>

        <div className="bg-color-ligh heading3 padding10 marginTop10">
          Sagment
        </div>
        
        <div className="ion-padding-start">
          {sagments.map((sagment,index)=>{
              return(
                <IonRadioGroup key={index}  value={currSagement} onIonChange={e => updateSagment(e.detail.value)}>
                  <div className="display-flex-row marginTop10" key={index}>
                  <IonRadio slot="start" value={sagment} />
                      <div className="heading4 center-aligned-column marginleft10">{sagment}</div>
                  </div>
                </IonRadioGroup>
              )
            })}
        </div>
      </div>
    );
  };
  
  export default Filters;
  