import React, { useEffect, useRef, useState } from "react";
import { IonIcon, IonModal } from "@ionic/react";
import {
    close, pencil,
  } from "ionicons/icons";
import { applicationConfig, getFilteredArray, getObjectByParameter, getUserType } from "../../../../utils/methods";
import ProductForm from "../../../admin/inventory/product/ProductForm";
import Inventory from "../../../admin/inventory/Inventory";


const EditLink: React.FC<{
  configObj: any;
  editItemName:string
  editItemId:number
  EditItemComponent?:any
  Content?:any
}> = (props) => {

  const [isOpenInventory, setIsOpenInventory] = useState<boolean>(false);


  const[product,setProduct]=  useState(null)
 

  useEffect(()=>{
    let res = getFilteredArray(applicationConfig.data.productsList,"product_id",props.editItemId)
    if(res){
      setProduct(res[0])
    }
  },[props.editItemId])

 
  return (
    <React.Fragment>
        <IonModal
          onDidDismiss={()=>setIsOpenInventory(false)} 
          isOpen={isOpenInventory}
          id="inventoryModal"
          cssClass='large-x-modal'
        >
          {product?
            <Inventory
            currState="Product"
            configObj={applicationConfig}
            closeModal={()=>{}}
            product={product}
            finishId={0}
            isUpdateMode={true}
          />
          :null}
       
        </IonModal>
        {getUserType()?.toLowerCase()==="admin"?
        <div className="click-effect display-flex-row no-wrap" onClick={()=>setIsOpenInventory(true)}>
          {props.Content?
            <props.Content/>
          :
          <>
          <div className="center-aligned-column">
              <span className="font-normal heading2 edit-heading heading3">{props.editItemId}</span>
            </div>
            <div className="marginleft5 center-aligned-column">
              <IonIcon
                icon={pencil}
                className="font-medium md hydrated color-danger"
                ></IonIcon>
            </div>
          </>
          }
            
          </div>
          :null}
    </React.Fragment>
  
  );
};

export default EditLink;
