import { MathUtils, Matrix4, Object3D, Sprite, Vector2, Vector3 } from "three";
import { updateGroupCenter } from "../common";
import { onDrag, onDragEnd, onDragStart } from "./dragControls";
import { CURR_DRAGGING_MODEL, getPosY, resetDraggingParameters, setCurrDraggingModel, updateDraggingModelPosition } from "./dragging";
import { getClicked3DPoint, isKitchenPlannerMode, kitchenPlanner } from "./area3dModel";
export var mouseVector = new Vector3();

let objPosX = 0 
let objPosZ = 0 
let objPosY = 0 


export class Dragging {
  mouseDown = false;
  object:any
  client = new Vector2(Infinity,Infinity)
  isDragging:boolean = false

  constructor() {}

  setObject(object:any){
    if(object){
      this.object = object
    }
  }

  resetObject(){
    this.object = null
  }

  pointerDown(event:any) {
    if(this.object){
      this.mouseDown = true;
      const x = event.pageX || event.targetTouches[0].pageX 
      const y = event.pageY || event.targetTouches[0].pageY
      this.client.x = x;
      this.client.y = y;

      //For snapped Object
      objPosZ  = this.object.position.z     
      objPosX  = this.object.position.x   
      objPosY  = this.object.position.y
      
      onDragStart(this.object)
    }
  }

  pointerMove(event:any) {
    if(this.object){
      this.isDragging = true
      if (this.mouseDown && this.object) {

        const x = event.pageX || event.targetTouches[0].pageX 
        const y = event.pageY || event.targetTouches[0].pageY
  
        let deltaMove = {
          x: ((x-this.client.x)* 0.015),
          y: ((y-this.client.y) * 0.015)
        };
  
        // let rotationMatrix = new Matrix4().makeRotationY(-this.object.rotation.y);
        // let movementVector = new Vector3(deltaMove.x,0,deltaMove.y);
        // movementVector.applyMatrix4(rotationMatrix);
        // this.object.position.add(movementVector);
        this.updatePositionIn3dMode(this.object)
  
        this.client.x = x
        this.client.y = y
  
        onDrag(this.object)
      }
    }
  }

  
  updatePositionIn3dMode(object:any) {
    let pos = getClicked3DPoint(this.client.x,this.client.y,mouseVector)
    if(pos && object){
      let posY = getPosY(object)
      let position = new Vector3(pos.x - object?.userData.dimensions.dimX / 2,posY,pos.z - object?.userData.dimensions.dimZ / 2)
      object?.position.copy(position)

      if(object.userData.isWallMounted && object.userData.isAttachedToWall){
            // let snappedWall = event.object.userData.snappedWall
            let normal = object.userData.configuration.normal
            if(normal?.z){
                object.position.z  = objPosZ   
            }else{
                object.position.x  = objPosX   
            }
        }else{
            object.position.y = objPosY   
            if(isKitchenPlannerMode){
              kitchenPlanner.clamp.clampObject(object)
            }
      }
    }
  }

  pointerUp(event:any) {
    this.mouseDown = false;
    if(this.isDragging){
      this.isDragging = false
      if(this.object){
        onDragEnd(this.object)
        this.resetObject()
      }
    }
  }
}
