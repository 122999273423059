import $ from "jquery";
import React, { useEffect, useState } from "react";
import { hideComponentLoader, showComponentLoader } from "../../../../utils/UI_methods/global";
import AwsConfig from "../../../../utils/aws-sdk/AwsConfig";
import {
  displayImageFromIndexDb
} from "../../../../utils/cache/indexdb";
import { convertHtmlToPdf } from "../../../../utils/html2canvas/html2pdf";
import { disposeVariables, getCurrentConfigurationFromLocalStorage,  getUserType, shareProject, waitFor } from "../../../../utils/methods";
import {
  getMoodBoardConfigKey,
  getPriceFromConfiguration
} from "../../../../utils/moodboard/helper";
import { configuration } from "../../../../utils/moodboard/moodboard";
import Loader from "../main-ui/Loader";
import { getFormattedPrice } from "../../../../utils/products/productviewer_shopify.js";

  
  const ConfigMoodBoard: React.FC<{
    configObj: any;
    areas:Array<any>;
  }> = (props) => {

    const[loaderId] = useState("moodboardSummaryLoader")

    var totalPrice = 0


    useEffect(() => {

      //For share link
      setTimeout(() => {
        if(getUserType().toLowerCase()!="admin"){
          displayImagesFromS3()
        }else{
          displayImages()
        }

        $("#summaryTotalPrice").text(getFormattedPrice(totalPrice))
      }, 500);

    }, []);

 

    function displayImages() {
        for (const area of props.areas) {
          // displayImageFromIndexDb(props.configObj.projectName,area.area_name,`${props.configObj.projectName}_${area.area_name}`);
          displayImageFromIndexDb(`shareAreaImage${area.area_name}`,"moodboardProducts_"+props.configObj.moodboardId,area.area_name)
        }
    }

    function displayImagesFromS3() {
      let awsConfig = new AwsConfig()
        for (const area of props.areas) {
          let key = `screenshots/collections/${props.configObj?.clientName}/${props.configObj?.projectName}/${props.configObj?.moodboardId}${area.area_name}.png`
          awsConfig.getObject(key).then(data=>{
            let bufferView = data.Body
            $("#shareAreaImage"+area.area_name).attr("src",bufferView.toString())
          }).catch(err=>{
            console.log(err)
          })
        }
        disposeVariables([awsConfig])
    }


    
    async function DownloadAsPdf() {
      showComponentLoader(loaderId)
      await waitFor(300)
      await convertHtmlToPdf("moodboard",props.configObj,props.areas,`${props.configObj.moodboardId}`)
      hideComponentLoader(loaderId)
    }

    
    async function shareAPI() {
      showComponentLoader(loaderId)
      await shareProject(props.configObj,"moodboard")
      hideComponentLoader(loaderId)
    }



    return (
      <React.Fragment>
        <div className="full-height-width summary-wrapper moodboard-summary-rapper">
        <div className="summary-details-wrapper" id="productSummary">
          <Loader
            className="fade-background-loader"
            id={loaderId}
            data={{}}
            loaderType="dots"
          />
        {props.areas.map((area: any, index: number) => {
        let key = getMoodBoardConfigKey(configuration.moodboardId,area.area_name);
        let config = getCurrentConfigurationFromLocalStorage(key);
        
        let elementId = `shareScreenshot${area.area_name}`

        let price = getPriceFromConfiguration(config,props.configObj)

        totalPrice = totalPrice + price

        return (
          <div key={index} className="padding10 border-bottom position-relative"  id={elementId} >
          <div className="product-details display-flex-row no-wrap">
            <div className="image center-aligned-column" style={{width:"100%"}} >
              <img src="" alt="" className="full-height-width padding10"
                // id={`shareAreaImage${stringWithoutWhiteSpace(area.area_name)}`}
                id={`shareAreaImage${area.area_name}`}
              />
            </div>
            <div className="other-details fill-available-width">
              <div className="product-name letter-spacing font-medium heading1">{area.area_name}</div>
             
              {/* <div><span className="heading1">Products List</span></div> */}
              <div className="marginTop5">
                <table className="table" id={`table${area.area_name}`}>
                   <thead className="bg-color-ternary">
                    <tr>
                      <th className="text-start">Product Name</th>
                      <th className="text-start">Type</th>
                      <th className="text-start">Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {config?.images?.map((config: any, index: any) => {
                      return (
                        <tr className="" key={index}>
                          <td className="text-start">{config.imageReference.productName || config.imageReference.materialCode}</td>
                          <td className="text-start">{config.imageType || config.image_type}</td>
                          <td className="text-start">{config.imageReference.categoryName || config.imageReference.collectionName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <div className="font-small category-name ion-margin-top ion-margin-bottom letter-spacing color-white bg-color-black" style={{padding:"0.2rem 1rem",width:"max-content"}}>20000</div> */}
            </div>
          </div>
          <div className="price hide-in-mobile rupee-sign top-right heading1"> {getFormattedPrice(price)}</div>
        </div>
        );
      })}
      </div>

      <div className="summary-actions-wrapper">
          <div className="ion-margin-bottom">
            <div className="font-large heading1 letter-spacing color-black marginBottom5">
              Total Price
              </div>
            <div><span className="heading2 font-large marginRight5 rupee-sign">₹</span> <span className="heading1 letter-spacing rupee-sign total-price" style={{fontSize:"3rem"}} id="summaryTotalPrice"> 0 </span>  </div>
          </div>
         
          <div className="ion-margin-top display-flex-row">
          
         
            <button className="op-btn op-btn-primary op-btn-lg" onClick={shareAPI}>
              <i className="fas fa-share-alt font-large color-white" aria-hidden="true"></i> 
              <span className="letter-spacing color-white font-medium marginleft5" >Share</span>
            </button>

            <button className="op-btn marginleft5 op-btn-primary-inverse op-btn-lg" onClick={()=>{}}>
              <i className="fas fa-print font-large color-danger" aria-hidden="true"></i> 
              <span className="letter-spacing font-medium marginleft10" >Print</span>
            </button>

           

          </div>

          <div className="">
            <button className="op-btn op-btn-primary-inverse marginTop10 op-btn-lg" onClick={DownloadAsPdf}>
              <i className="fas fa-download font-large color-dark" aria-hidden="true"></i> 
              <span className="letter-spacing font-medium marginleft5" >Download Pdf</span>
            </button>
          </div>

        </div>

      </div>
      </React.Fragment>
    
    );
  };

  export default ConfigMoodBoard;
