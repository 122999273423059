import $ from "jquery";
import React, { useState } from "react";
import { getVariantImageUrl } from "../../../../../../utils/store/UI_methods";
import { STORE, Variant } from "../../../../../../utils/store/storeConfiguration";
import VariantCreater from "../../variant-creater/VariantCreater";
import { getRandomString, stringWithoutWhiteSpace } from "../../../../../../utils/methods";
import HorizontalScroll from "../../../../../common/ui-components/scrollbar/HorizontalScroll";

const VariantSelector: React.FC<{
  onChange:(val:any)=>void

  variant:Variant
  product:any
  configuration:any
  isShowMakeYourOwn?:boolean

  confirmHandle:()=>void

  isSelectLayoutMode?:boolean
}> = (props) => {



  const[currConfig,setCurrConfig]:any =  useState({})
  const[currCategory]:any =  useState(props.product?.category_name)
  const[currSize,setCurrSize] =  useState(props.variant?.size)
  // const[selectedElement,setSelectedElement] =  useState(null)
  const[options,setOptions]:any =  useState([])

  // const[allVariants,setAll] = useState(props.configuration.filter(currVariant => currVariant.categories.includes(stringWithoutWhiteSpace(props.product?.sub_category_name))))
  const[allVariants,setAll] = useState(props.configuration)


  function onChange(event:any,config:any,id:number) {
    // setSelectedElement(event.target)
    STORE.productConfiguration.isCustomVariant = false
    setCurrConfig(config)
    if(config.options?.length){
      $(".more-options-container").fadeOut(0)
      $(`#moreOptionsContainer`).fadeIn(500)
      setOptions(config.options)
      return
    }
    // props.onChange(config.name)
    props.variant.variantName = config.name
    props.variant.type = ""
  }


  function optionsSelectHandle(config:any) {

    // setCurrSelectedOption(option)
    $(".curr-option-container").fadeOut(0)
    $(`#currOptionContainer_${config.id}`).fadeIn(200)
    $(".more-options-container").fadeOut(0)
    // props.onChange(config.name)
    props.variant.variantName = currConfig.name
    props.variant.noOfSeats = currConfig.noOfSeats || 0
    props.variant.type = config.type
  }


  function toggleSizeSelection(event:any,size:string) {
    $(".switch-selection-container").find(".option").removeClass("--is-active")
    $(event.target).addClass("--is-active")
    props.variant.size = size
    setCurrSize(size)
  }

  function onChangeSide(event:any,config:any,option:string) {
    event.preventDefault()
    event.stopPropagation()
    optionsSelectHandle(currConfig)
    // props.onChangeMoreOption(option)
    setCurrConfig(config)


    props.variant.type = option
    props.variant.variantName = currConfig.name
  }

 
 
  function VariantsList() {
    return(
        <>
        {props.isShowMakeYourOwn?
          <VariantCreater product={props.product} confirmHandle={props.confirmHandle} />
        :null}
        {allVariants.map((config:any,index:number)=>{
                let url = getVariantImageUrl(currCategory,props.variant,currSize,config)
                return(
                  <div key={index}  onClick={(event)=>onChange(event,config,config.id)} className={`center-aligned-column item position-relative white-card shadow-light ${props.variant.variantName === config.name?" --is-active":""} ${props.isSelectLayoutMode?" item-md":""}`}>
                    <div className="pointer-none midde">
                      {/* <div className="padding5 center-aligned-row"><i className={`icon font-large ${getIconForOptions(currCategory)}`}/></div> */}
                      <div className="padding5 center-aligned-row"><img className="icon" src={url} style={{width:"5rem",margin:"auto"}}/></div>
                      <div className="heading2  font-md-small pc-color-primary text text-center text-no-wrap">{config.displayName || config.name}</div>
                      <div className="heading4 font-small text text-center ">{config.description}</div>
                    </div>
                    <div className="top-right">
                      {props.variant.variantName === config.name?
                      <i className="far fa-check-circle select-icon font-medium"></i>
                      :
                      <i className="far fa-circle select-icon font-medium"></i>
                      }
                    </div>
                      <div className={`top-left padding5 heading1 font-small color-warning curr-option-container ${props.variant.variantName === config.name?" ":"display-none"}`} id={`currOptionContainer_${config.id}`}>
                        {config.name.includes("Lounge")?props.variant.type:""}
                      </div>
                  </div>
                )
          })}
        </>
    )
  }

  return (

    <div className="position-relative full-width">

    


     {currCategory === "Sofa" || currCategory === "SofaBed"?
     <div className="center-aligned-row padding5 display-none">
      <div className="switch-selection-container display-flex-row no-wrap">
        <div onClick={(event)=>{toggleSizeSelection(event,"Standard")}} className={`option ${props.variant.size === "Standard"?" --is-active":" "}`}>Standard</div>
        <div onClick={(event)=>{toggleSizeSelection(event,"XL")}}  className={`option ${props.variant.size === "XL"?" --is-active":" "}`}>XL</div>
      </div>
    </div>
     :null}

      

      <div id={`moreOptionsContainer`} style={{display:"none",zIndex:100,background:"rgba(0,0,0,0.3)"}} className="middle full-height-width padding10 more-options-container  center-aligned-column">
          <div className="white-card position-relative" style={{width:"25rem",height:"14rem"}}>
            <div className="heading3 padding5 text-center">Choose your variant <span><i className="fas fa-question-circle pointer color-dark"></i></span> </div>
            <div className="center-aligned-column">
              <div className="display-flex-row customization-options">
                {options.map((currOption:any,index:number)=>{
                    let variantName = encodeURIComponent(currConfig.name)
                    let url = `${STORE.BASE_URL_ASSETS}icons/variants/${currCategory}/${currSize}/${variantName}_${currOption}.svg`
                    return(
                      <div key={index} onClick={(event)=>onChangeSide(event,{},currOption)} className={`center-aligned-column item item-sm position-relative white-card shadow-light`}>
                        <div className="pointer-none midde">
                          <div className="padding5 center-aligned-row"><img src={url} className="icon" style={{width:"3.2rem",margin:"auto"}}/></div>
                          <div className="heading2  font-md-small pc-color-primary text text-center text-no-wrap">{currOption}</div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
      </div>

        {props.product && props.variant?
        <>
        {props.isSelectLayoutMode?
          <div className="display-flex-row">
            <VariantsList/>
          </div>
        :
         <HorizontalScroll
            itemsClass="customization-options"
            Component={VariantsList}   
            itemsList={props.configuration}
            id={`VariantsScrollBar_${getRandomString(10)}`}     
          />
        }
        </>
        :null}
        
        {/* {props.isShowMakeYourOwn?
          <VariantCreater product={props.product} />
        :null} */}

        

     
      
    </div>

    
        
  );
};

export default VariantSelector;
