import $ from "jquery";
import Konva from "konva";
import { getMoodBoardSavedConfig, saveMoodBoardConfig } from "../../services/api";
import { getWindowWidth } from "../../utils/UI_methods/global";
import { getParentContainer, hideComponentLoader, showToast } from "../UI_methods/global";
import { captureAndSaveScreenshot, displayImageFromIndexDb } from "../cache/indexdb";
import { customizein3dInitialLayout, iPadPotraitModeLayout, isPotraitMode } from "../customizein3d/common";
import { applicationConfig, disposeVariables, getArrayOfDistinctValues, getCurrentConfigurationFromLocalStorage, getFilteredArray, getObjectByParameter, logger, setCurrentConfigurationInLocalStorage, stringWithoutWhiteSpace } from "../methods";
import { Image as MoodboardImage } from "./Configuration";
import { closeProductModules, disablePotraitModeLayout, enablePotraitModeLayout, hideAllProductAnnotations, openProductModules, scrollCanvasToTop, scrollToCanvas } from "./UI_methods";
import { autoArrangeLShape, updateRotateIconVisibility } from "./modules/sofa";
import { addImageToCanvasTouchDevice, canvasLoaderId, configuration, currSelectedGroup, getConfigurationFromLocalStorage, isModulesMode, moodboardConfig, postDeleteAction, projectAreaCategories, resetTransformer, updateTransformer } from "./moodboard";
import { productsForDemo, productsForElevate } from "../data";





export function getMoodBoardConfigMappingArrayFromLocalStorage(configuration:any) {
    let formatedConfiguration: any = []
    for (const configkey in configuration) {
      let currObj = configuration[configkey]
      let configObj: any = {
        height: currObj.height,
        posX: currObj.posX,
        posY: currObj.posY,
        productId: currObj.productId,
        productName: currObj.productName,
        width: currObj.width,
        imageType: currObj.imageType,
        productCategory: currObj.productCategory,
      };
      formatedConfiguration.push(configObj)
    }
    return formatedConfiguration;
  }

  export function getStageWidthHeight() {
    let delta = 0.45
    if(getWindowWidth() <=480){
      delta = 0.95
    }

    if(isPotraitMode()){
      delta = 0.75
    }

    let width = $("#moodboardCanvasWrapper").width() * delta
    return {
      width: width,
      height: width / (4 / 3)
    }
  }
  
  export function fitStageIntoParentContainer(stage:any,isUpdateLayout:boolean=true) {
    if(isUpdateLayout){
      iPadPotraitModeLayout("moodboard")
      customizein3dInitialLayout()
    }

  
    updateCanvasContainerDim()

    let containerWidth:any = $(".moodboard-canvas-container").width()
    let containerHeight:any = $(".moodboard-canvas-container").height()


    // let scale = containerWidth / width;
  
  
    stage.width(containerWidth)
    stage.height(containerHeight)
    // stage.scale({ x: scale, y: scale })
    // stage.draw()
    return stage
  }

  export function getPotraitModeCanvasWidthPrecantage() {
    return getWindowWidth() > 480 ? 0.8 : 0.95
  }


  export function updateCanvasContainerDim() {

    
    let wrapperWidth = $("#moodboardCanvasWrapper").width()
    let wrapperHeight = $("#moodboardCanvasWrapper").height()
    
    if(isPotraitMode()){
      let width = wrapperWidth * getPotraitModeCanvasWidthPrecantage()
      let height = width / (4 / 3)
      $(".moodboard-canvas-container").width(width)
      $(".moodboard-canvas-container").height(height)
      enablePotraitModeLayout()
      return
    }

    disablePotraitModeLayout()

    let delta = 0.75


    let remainingWidth = wrapperWidth - (wrapperWidth * 0.15)
    let finalWidth = remainingWidth * delta
    let height = finalWidth / (4 / 3)

    //If overflowing height
    if(wrapperHeight-height < 60){
      delta = 0.6
    }

    let marginLeft = parseInt($(".moodboard-canvas-container").css("marginLeft"))

    // console.log(((wrapperWidth - finalWidth) / 2) , marginLeft)

    //if width is overflowing
    if(((wrapperWidth - finalWidth) / 2) + Math.abs(marginLeft) < $("#moodboardAddedItemsList").width()){
      // remainingWidth = wrapperWidth
      // enablePotraitModeLayout()
      // delta = 0.6
    }

    finalWidth = remainingWidth * delta
    height = finalWidth / (4 / 3)


    $(".moodboard-canvas-container").width(finalWidth)
    $(".moodboard-canvas-container").height(height)

  }
  
export function getMoodBoardConfigKey(moodboardId: any, areaName: string) {
    return `MoodBoardConfig_${String(moodboardId)}_${areaName}`;
  }
  
  export function getMoodBoardNotesConfigKey() {
    return `MoodBoardNotesConfig`;
  }
  
  
  export function getMoodBoardNotesConfigAreaKey(moodboardId: string, areaName: string) {
    return `MoodBoardNotesConfig_${moodboardId}_${areaName}`;
  }


  

  
export function updatePrice(configObject:any) {
    //get all configuration from local storage
    let key = getMoodBoardConfigKey(configObject?.getMoodboardId(),configObject?.getCurrArea())
    let configuration = getCurrentConfigurationFromLocalStorage(key)
    let price = 0
    
    if(configuration){
      price = getPriceFromConfiguration(configuration,configObject)
    }
  
    let text = price + ".00 INR"
    $(".total-price").html(text)
    $(".total-price").addClass("--is-active")
  
    setTimeout(() => {
      $(".total-price").removeClass("--is-active")
    }, 500)
  }
  
export function getPriceFromConfiguration(configuration:any,configObject:any) {
    let price = 0
    if (configuration) {
      for (const key in configuration) {
        let product = configuration[key]
        let imageType = product.imageType
        let object:any = null
        let productPrice = 0
        let productId = parseInt(product.productId)
        object = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",productId)
        if(imageType==="product"){
          // object = getObjectByParameter(configObject?.data.productsList,"material_id",productId)
          productPrice = object?.price
        }

        price = price + Number(productPrice)
      }
    }
    return price
  }


 
  
   

  
export function downloadURI(uri: any, name: string) {
    var link = document.createElement("a")
    link.download = name;
    link.href = uri;
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    // delete link;
  }
  
  export function downloadMoodboard(stage:any) {
    var dataURL = stage.toDataURL({ pixelRatio: 3 })
    downloadURI(dataURL, "moodboard.png")
  }
  
  export function getProductImageUrl(BASE_URL: string,clientName: string,productName: string,categoryName:string,subCategoryName:string) {
    productName = productName?.replace(/ /g,'')
    let extension = productsForDemo.includes(productName) || productsForElevate.includes(productName) ? "webp" : "png"
    return `productImages/${clientName}/${categoryName?.replace(" ","")}/${subCategoryName?.replace(" ","")}/${productName}/${productName}.${extension}`;
  }

  export function getProductGalleryImageUrl(clientName: string,productName: string,categoryName:string,subCategoryName:string,index:number) {
    productName = productName?.replace(/ /g,'')
    return `productImages/${clientName}/${categoryName?.replace(" ","")}/${subCategoryName?.replace(" ","")}/${productName}/${productName}_${index}.webp`;
  }
  
  export function getUploadedImageUrl(BASE_URL: string,clientName: string,productName: string) {
    return `uploads/productImages/${clientName}/${productName}`;
  }
  

  export function hideAnchorsFromAllGroup(canvasContainerId:string,isRemove:boolean = false) {
      if(isRemove){
        let layer = moodboardConfig.getLayer(canvasContainerId)
        let groups = layer.getChildren()
        for (let i = 0; i < groups.length; i++) {
          const group = groups[i]
          if(group.getChildren().length && group.attrs.name === "canvasImageItemGroup"){
            hideProductSelectionBox(group,canvasContainerId)
          }
        }
        layer?.draw()
      }
  }

  export function removeAnchorsFromAllGroup() {
    let layer = moodboardConfig.getLayer("mainMoodboardCanvasContainer")
    let groups = layer.getChildren()
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      if(group.getChildren().length && group.attrs.name === "canvasImageItemGroup"){
        // group.find(".productRect")[0].destroy()
        group.find(".rotationIcon")[0]?.destroy()
        group.find(".topLeft")[0].destroy()
        group.find(".topRight")[0].destroy()
        group.find(".bottomRight")[0].destroy()
        group.find(".bottomLeft")[0].destroy()

      }
    }
    layer?.draw()
}

  export async function removeAnchorsFromOtherImages(layer:any) {
    return
    try {
      let groups = layer.getChildren()
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i]
        if(group.getChildren().length){
          let topLeft = group.find(".topLeft")[0]
          let topRight = group.find(".topRight")[0]
          let bottomRight = group.find(".bottomRight")[0]
          let bottomLeft = group.find(".bottomLeft")[0]
          topLeft?.remove()
          topRight?.remove()
          bottomRight?.remove()
          bottomLeft?.remove()
        }
        layer?.draw()
      }
    } catch (error) {
      console.log(error)
    }
  }

  
export function showProductDesc(event:any) {
  let productName = $(event.target).parents(".product-image-container").attr("data-product-name")
  let productPrice = $(event.target).parents(".product-image-container").attr("data-product-price")
  let productCategory = $(event.target).parents(".product-image-container").attr("data-category-name")
  let productSubCategory = $(event.target).parents(".product-image-container").attr("data-subcategory-name")
  let imgSrc = $(event.target).parents(".product-image-container").find(".product-image").attr("src")

  $("#productDescription").addClass("--is-active")
  $("#productDescImg").attr("src",imgSrc)
  $("#productDescName").text(productName)
  $("#productDescPrice").text(productPrice)
  $("#productDescCat").text(productCategory)
  $("#productDescSubCat").text(productSubCategory)
}


export function hideProductDesc() {
  $("#productDescription").removeClass("--is-active")
}

export function showProductDescription(product:any,imgSrc:string) {
  $("#productDescription").addClass("--is-active")
  $("#productDescImg").attr("src",imgSrc)
  $("#productDescName").text(product.product_name)
  $("#productDescPrice").text(product.price)
  $("#productDescCat").text(product.category_name)
  $("#productDescSubCat").text(product.sub_category_name)
}
  

//For mobile view // All images
export function addEventListenerOnImages(className:string){


  let allProducts = document.querySelectorAll(className)

  // let addImageIcons = document.querySelectorAll(".product-icon-mobile")
  for (let i = 0; i < allProducts.length; i++) {
    let imageType = allProducts[i].getAttribute("data-image-type") 
    if(imageType==="product"){
      allProducts[i].addEventListener("mouseover", showProductDesc)
      allProducts[i].addEventListener("mouseout",function(event:any){
        $("#productDescription").removeClass("--is-active")
      })
      allProducts[i].addEventListener("mousedown",function(event:any){
        $("#productDescription").removeClass("--is-active")
      })
    }
    allProducts[i].addEventListener("touchstart",startDragging,false)
    allProducts[i].addEventListener("touchmove",startMoving,false)
    allProducts[i].addEventListener("touchend",endDragging,false)
  }
}

function startDragging(event:any) {
  $("#draggableImg").fadeIn(0)

  let image = null

  if($(event.target).parents(".product-image-container").length){
    image = $(event.target).parents(".product-image-container").find("img").attr("src")
  }

  if($(event.target).parents(".finish-image-container").length){
    image = $(event.target).parents(".finish-image-container").find("img").attr("src")
  }


  let touchLocation = event.targetTouches[0]

  $("#draggableImg").css("left",touchLocation.pageX + 'px')
  $("#draggableImg").css("top",touchLocation.pageY + 'px')
  $("#draggableImg").attr("src",image)
}

function startMoving(event:any) {
  let touchLocation = event.targetTouches[0]
  $("#draggableImg").css("left",touchLocation.pageX + 'px')
  $("#draggableImg").css("top",touchLocation.pageY + 'px')
}

function endDragging(event:any) {
  $("#draggableImg").fadeOut(0)
  let touchLocation = event.changedTouches[0]

  let leftStartOffset = $("#mainMoodboardCanvasContainer")?.offset()?.left!
  let leftEndOffset = leftStartOffset + $("#mainMoodboardCanvasContainer")?.width()!

  let topStartOffset = $("#mainMoodboardCanvasContainer")?.offset()?.top!
  let topEndOffset = topStartOffset + $("#mainMoodboardCanvasContainer")?.height()!

  if(touchLocation.pageX >= leftStartOffset && touchLocation.pageX <= leftEndOffset && touchLocation.pageY >= topStartOffset && touchLocation.pageY <= topEndOffset){
    addImageToCanvasTouchDevice(event)
  }
}

export function updateScreenshot(stage:any,configObject:any){
  let dataURL = stage.toDataURL({ pixelRatio: 3 })
  captureAndSaveScreenshot(dataURL,"moodboardProducts_"+configObject.moodboardId,configObject?.areaName)
}

 

export function updateMoodBoardConfig(productAttributes: any,stage:any) {
  let transform = getTransformValues(productAttributes)
  let dimensions = getDimensionsValues(productAttributes)
  configuration.updateImageConfiguration(currSelectedGroup.attrs.image.imageId,transform,dimensions)
  updateCanvasScreenshot(stage)
}


export function addImageInConfiguration(image:MoodboardImage,stage:any) {
  configuration.addImage(image)
  updateCanvasScreenshot(stage)
  if(image.imageType.includes("product")){
    varifyCategories([{area_name:configuration.areaName}],null,projectAreaCategories)
  }
}


export function getTransformValues(productAttributes:any) {
  return {
    posX: productAttributes.posX,
    posY: productAttributes.posY, 
    rotation:productAttributes.rotation,
  }
}

export function getDimensionsValues(productAttributes:any) {
  return {
    width: productAttributes.width,
    height: productAttributes.height,
  }
}

function updateCanvasScreenshot(stage:any) {
  if(!stage){
    stage = moodboardConfig.getStage("mainMoodboardCanvasContainer")
  }
  let dataURL = stage?.toDataURL({ pixelRatio: 3 })
  captureAndSaveScreenshot(dataURL,"moodboardProducts_"+applicationConfig?.moodboardId,applicationConfig?.areaName)
}


export function higlightUIComponents(categoryName:string,configObject:any) {
  highlightTable(categoryName)
  updatePrice(configObject)
  // showToast("Added "+ categoryName,2000)
}

export function highlightTable(categoryName:string) {
  setTimeout(() => {
    $(`#Category_${categoryName}`).addClass("--is-highlight")
  }, 200)
  setTimeout(() => {
    $(`#Category_${categoryName}`).removeClass("--is-highlight")
  }, 1000)
}


export function varifyCategories(areas:Array<any>,configObject:any,projectAreaCategories:any) {
  try {
    if(isModulesMode){
      return
    }
    let updateCategoriesStatus = applicationConfig.functions.moodboard.updateCategoriesstatus
    let categoriesFromConfig = []
    configuration.images.forEach(currImage=>{
      categoriesFromConfig.push(currImage.imageReference.categoryName)
    })
    for (const area of areas) {
      let confirmed = []
      let remaining = []
      let additional = []
      let currAreaCategories = getFilteredArray(projectAreaCategories,"area_name",area.area_name)
      let selectedCategories = getArrayOfDistinctValues(currAreaCategories,"category_name")
      // let categoriesFromConfig = getArrayOfDistinctValues(configuration.images,"categoryName")
      for (const category of selectedCategories) {
        if(categoriesFromConfig.includes(category)){
          confirmed.push(category)
        }else{
          remaining.push(category)
        }
      }
      for (const category of categoriesFromConfig) {
        if(!selectedCategories.includes(category)){
          additional.push(category)
        }
      }
      updateCategoriesStatus(confirmed,remaining,additional,area.area_name)
    }
  } catch (error) {
    console.log(error)
  }
}


export function removeAllProducts(configObject:any,layer:any,projectAreaCategories:any,updateSetIsConfigUpdated:(val:boolean)=>void) {
  resetCanvas(layer)
  let key = getMoodBoardConfigKey(
    configObject?.getMoodboardId(),
    configObject.areaName
  )
  localStorage.removeItem(key)
  compareConfigurations(false,configObject,updateSetIsConfigUpdated)
  updatePrice(configObject)
  varifyCategories([{area_name:configObject?.areaName}],configObject,projectAreaCategories)
}

export function resetCanvas(layer:any) {

  try {
    if(Object.keys(layer.children).length){
      let canvasContainerId = ""
      let groups = layer.getChildren()
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i]
        canvasContainerId = group.attrs.canvasContainerId
        if(group.getChildren().length){
          group.removeChildren()
        }
      }
      layer.draw()
      postDeleteAction(canvasContainerId)
      logger?.info("editcollection","resetcanvas",`Cavnas reset: ${canvasContainerId}`)
    }
  
  } catch (error) {
    console.log(error)
  }
}

export function removeMoodboardConfig(){
  let key = getMoodBoardConfigKey(applicationConfig?.getMoodboardId(),applicationConfig?.getCurrArea())
  localStorage.removeItem(key)
}


export function removeOtherCanvases() {
  let parentContainer = getParentContainer()
  let canvases = parentContainer?.querySelectorAll(".moodboard-canvas-container")
  for (let i = 0; i < canvases?.length; i++) {
    const canvas = $(canvases[i])
    if(canvas.attr("id") != "moodboardCanvasContainer"){
      canvas.remove()
    }
  }
}


export function compareConfigurations(isCheckPosition: boolean,configObject:any,updateSetIsConfigUpdated:(val:boolean)=>void) {
  if(!configObject || !updateSetIsConfigUpdated){
    return
  }
  let savedConfig = configObject.savedConfiguration;
  let key = getMoodBoardConfigKey(
    configObject?.getMoodboardId(),
    configObject.areaName
  )
  let localstoarageConfig = getCurrentConfigurationFromLocalStorage(key)

  if(!localstoarageConfig || !savedConfig){
    updateSetIsConfigUpdated(true)
    return;
  }
  

  //if Length is not same return false //  used for products
  if (Object.keys(localstoarageConfig).length != Object.keys(savedConfig).length) {
    updateSetIsConfigUpdated(true)
    return;
  }

  if (isCheckPosition) {
    for (const productKey in localstoarageConfig) {
      // console.log(localstoarageConfig[productKey])
      if (
        savedConfig[productKey]?.height != localstoarageConfig[productKey]?.height ||
        savedConfig[productKey]?.width != localstoarageConfig[productKey]?.width ||
        savedConfig[productKey]?.posX != localstoarageConfig[productKey]?.posX ||
        savedConfig[productKey]?.posY != localstoarageConfig[productKey]?.posY
      ) {
        updateSetIsConfigUpdated(true)
        return;
      }
    }
  }
  updateSetIsConfigUpdated(false)
  return;
}

export function updateImagePosition(activeAnchor: any,imageAspectRatio:any) {
  var group = activeAnchor.getParent()

  var topLeft = group.find(".topLeft")[0]
  var topRight = group.find(".topRight")[0]
  var bottomRight = group.find(".bottomRight")[0]
  var bottomLeft = group.find(".bottomLeft")[0]
  var image = group.find("Image")[0]

  var anchorX = activeAnchor.getX()
  
  var width = topRight.getX() - topLeft.getX()
  // var height = bottomLeft.getY() - topLeft.getY()
  var height = width / imageAspectRatio



  // update anchor positions
  switch (activeAnchor.getName()) {
    case "topLeft":

      topLeft.y(topRight.getY())

      bottomLeft.x(topLeft.getX())
      bottomLeft.y(height + topLeft.getY())
      bottomRight.x(topRight.getX())
      bottomRight.y(height + topLeft.getY())

      image.x(topRight.getX() - width)



      break;
    case "topRight":

      bottomLeft.x(topLeft.getX())
      bottomLeft.y(height + topLeft.getY())

      topRight.x(width + topLeft.getX())
      topRight.y(topLeft.getY())

      bottomRight.x(topRight.getX())
      bottomRight.y(height + topLeft.getY())
      image.position(topLeft.position())

      break;
    case "bottomRight":
      topRight.x(anchorX)
      bottomLeft.x(topLeft.getX())
      bottomLeft.y(height)

      bottomRight.x(topRight.getX())
      bottomRight.y(height)
      image.position(topLeft.position())

      break;
    case "bottomLeft":
      topLeft.x(anchorX)

      bottomLeft.x(topLeft.getX())
      bottomLeft.y(height)

      bottomRight.x(topRight.getX())
      bottomRight.y(height)
      image.position(topLeft.position())

      break;
  }

  // updateProductSelectionBox(topLeft.absolutePosition().x,topLeft.absolutePosition().y,0,width,height,"mainMoodboardCanvasContainer")

  if (width && height) {
    image.width(width)
    image.height(height)
  }

}



export function updateAnchorsPositions(group: any) {

  var topLeft = group.find(".topLeft")[0]
  var topRight = group.find(".topRight")[0]
  var bottomRight = group.find(".bottomRight")[0]
  var bottomLeft = group.find(".bottomLeft")[0]
  var image = group.find("Image")[0]

  let box = image.getClientRect()

  topLeft.x(topLeft.x())
  topLeft.y(topLeft.y())

  topRight.x(topLeft.x() + box.width)
  topRight.y(topLeft.y())

  bottomLeft.x(topLeft.x())
  bottomLeft.y(box.height)

  bottomRight.x(topLeft.x() + box.width)
  bottomRight.y(box.height)

  group.parent.draw()
}

export function updateConfig(areaName:string,areaId:number,onComplete:()=>void,isTemplate:number = 0,templateName:string="") {

  return new Promise(async (resolve,reject)=>{

    if(!configuration.images.length){
        showToast("Nothing to save",2000)
        hideComponentLoader(canvasLoaderId)
        return
    }

    let data = {
      clientId: applicationConfig?.clientId,
      projectId: applicationConfig?.projectId,
      notes: "notes",
      areaId: areaId,
      configuration: JSON.stringify(configuration),
      moodboardId:configuration.moodboardId,
      isTemplate:isTemplate,
      templateName:templateName
    };

    await saveMoodBoardConfig(data)
      .then((response: any) => {
        onComplete()
        if(response.data?.status===201){
            showToast("Updated successfully",2000)
            syncConfigData()
        }else{
            showToast("Error in upadating",2000)
        }
        hideComponentLoader(canvasLoaderId)
        resolve(data)
      })
      .catch((error: any) => {
        console.log(error)
        reject(error)
        hideComponentLoader(canvasLoaderId)
      })
  })

  
}

export function syncConfigData() {
  getMoodBoardSavedConfig().then((data:any)=>{
     applicationConfig?.updateData("moodBoardSavedConfigs",data)
  }).catch((error:any)=>{
    console.log(error)
  }) 
}


export function uploadCollectionScreenshots(areas:any,configObj:any) {
  return new Promise( (resolve)=>{
    for (let i = 0; i < areas.length; i++) {
      let area = areas[i]
      displayImageFromIndexDb(`shareAreaImage${area.area_name}`,"moodboardProducts_"+configObj.moodboardId,area.area_name).then(async (imageFile)=>{
        if(imageFile){
          let fileName = stringWithoutWhiteSpace(String(configObj.moodboardId)) + area.area_name + ".png"
          let albumName = "screenshots"
          // let collection:any = getObjectByParameter(configObj.data.allCollections,"id",Number(finishDetails.collectionName))
          let albumPhotosKey = encodeURIComponent(albumName) + "/collections" + "/" + configObj.clientName + "/" + configObj.projectName + "/" 
          await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,imageFile,fileName)
          if(i===area.length-1){
            resolve("Done")
          }
        }
      }).catch(err=>{
        console.log(err)
        if(i===area.length-1){
          resolve("Done")
        }
      })
    }
  })
}


export function isProductModuler(configObj:any,product:any) {
  let productModules = getFilteredArray(applicationConfig?.data.productModules,"product_id",product?.product_id || product?.productId) || []
  if(productModules.length){
    return true
  }
  return false
}

export function addModulerIcon(group:any,layer:any,product:any) {
  let imageWidthHeight = 25
  if(getWindowWidth() < 481){
    imageWidthHeight = 10
  }
  let imageSrc = "assets/icon/modular.png"
  let imageObj:any = new Image()
  imageObj.onload = function () {
    var moduleIconImage = new Konva.Image({
      image: imageObj,
      width: imageWidthHeight,
      height: imageWidthHeight,
    })
  
    // moduleIconImage.x(0)
    // moduleIconImage.y(0)
  
    group.add(moduleIconImage)

    moduleIconImage.on("mousedown touchstart",function(event) {
      scrollToCanvas(product.productName)
      openProductModules()
    } )

    moduleIconImage.on("mouseover", function () {
      document.body.style.cursor = "pointer";
    })
    moduleIconImage.on("mouseout", function () {
      document.body.style.cursor = "default";
    })
    layer.draw()
  };
  imageObj.src = imageSrc;

}


export function toggleCategories(parentId:string = "") {
  $(".moodboard-category-wrapper").toggleClass("--is-active")
}
export function closeCategories(parentId:string = "") {
  $(".moodboard-category-wrapper").removeClass("--is-active")
}


export function openCategories(parentId:string = "") {
  $(".moodboard-category-wrapper").addClass("--is-active")
}

export function closeProductModulesWindow() {
  scrollCanvasToTop()
  closeProductModules()
}


export function getCanvasContainerWrapperName(product:any) {
  return `moodboardCanvas${product.productName || product.productName}`
}
export function getCanvasContainerName(product:any) {
  return `container${product.productName || product.productName}`
}

export function removeProductModulesFromConfig(configObject:any,product:any) {
  let key = getMoodBoardConfigKey(configObject?.getMoodboardId(),configObject.getCurrArea())
  let configuration = getCurrentConfigurationFromLocalStorage(key)
  for (const key in configuration) {
    let currProduct = configuration[key]
    let canvasContainerId = getCanvasContainerName(product)
    if(currProduct.canvasContainerId === canvasContainerId){
      delete configuration[currProduct.productName+"_"+currProduct.imageId]
    }
  }
  setCurrentConfigurationInLocalStorage(key, configuration)
}




export function autoArrangeImages(event:any) {
  let actionContainer = $(event.target).parents(".moodboard-canvas-actions-container")
  let canvasContainerId = actionContainer.siblings(".canvas-container").attr("id") || "mainMoodboardCanvasContainer"

  // let configuration = getConfigurationFromLocalStorage(applicationConfig?.projectName,applicationConfig?.areaName)
  let stage = moodboardConfig.getStage(canvasContainerId)
  let layer = moodboardConfig.getLayer(canvasContainerId)

  logger?.info("editcollection","autoarrange",`Auto arrange canvas: ${canvasContainerId}`)

  if(canvasContainerId.toLocaleLowerCase().includes("ds1735")){
    autoArrangeLShape(canvasContainerId)
    return
  }


  hideAllProductAnnotations()
 
    let allGroups = layer.children
    let currGroup = null
    let currImage = null

    let delta = 30
    let marginTop = 20

    let currCol = 0
    let currRow = 0
    let posX = 0
    let posY = 0
    let aspectRatio = 1
    let imagesPerRow = 4
    let stageWidth = stage.attrs.width - delta // Canvas width
    let width = stageWidth / imagesPerRow // Initial image width

    let minWidthFinish = 50 // Min width for finish

    let imageWidth = 0 // Final image width


    //Return group which has images // ignore empty groups
    allGroups = allGroups.filter(group=>group.children.length)

    if(allGroups.length < 7){
      delta = 40
      marginTop = 30
      imagesPerRow = 3
      stageWidth = stage.attrs.width - delta
      width = stageWidth / imagesPerRow
    }


    for (let i = 0; i < allGroups.length; i++) {
      imageWidth = width
      currGroup = allGroups[i]
      currImage = currGroup.find(".canvasItem")[0]

      if(!currImage){
        continue
      }

      //Get Aspect ration

      // Get posX
      posX = currCol * width

      //Ignore calculating posY for first row
      if(currRow > 0){
        let refIndex = (i - imagesPerRow)
        let refGroup = allGroups[refIndex]
        let refImage = refGroup.find(".canvasItem")[0]
        posY = refImage.attrs.height + refGroup.attrs.y
      }else{
        //For the first row pos Y
        posY = marginTop
      }
      
      //Update position
      currGroup.absolutePosition({
        x: posX + delta/2,
        y: posY + delta/2
      });

      if(currImage.attrs.product.imageType.toLowerCase() === "finish" && width > minWidthFinish){
        imageWidth = width / 2
      }
      //Update Width and height
      aspectRatio = currImage.attrs.width / currImage.attrs.height
      currImage.setAttrs({"width":imageWidth - delta/2,"height":imageWidth / aspectRatio - delta/2})


      // updateAnchorPositions(currGroup,layer,currImage,"mainMoodboardCanvasContainer",aspectRatio)

      updateImagePosition(currGroup.find(".topLeft")[0],aspectRatio)
      updateImagePosition(currGroup.find(".topRight")[0],aspectRatio)
      updateImagePosition(currGroup.find(".bottomRight")[0],aspectRatio)
      updateImagePosition(currGroup.find(".bottomLeft")[0],aspectRatio)
   


      //Col increament
      currCol = currCol + 1

      //Next Row increament
      if(i != 0 && (i + 1) % imagesPerRow === 0){
        currRow = currRow + 1
        currCol = 0
      }

      layer.draw()
      // Update Configuration  
      currGroup.attrs.image.posX = posX
      currGroup.attrs.image.posY = posY
      currGroup.attrs.image.width = currImage.width()
      currGroup.attrs.image.height = currImage.height()
    }
    configuration.updateLocalStorage()
}


export function showProductSelectionBox(group:any) {

  let canvasContainerId = group.attrs.canvasContainerId
  hideAnchorsFromAllGroup(canvasContainerId,true)

  $("#"+canvasContainerId).parent().find(".product-actions-container").addClass("--is-active")

  updateTransformer(group,canvasContainerId)
 
  if(isModulesMode){
    updateRotateIconVisibility(group)
  }
  

}

export function  hideProductSelectionBox(group:any=null,canvasContainerId:string) {
  // hideAnchorsFromAllGroup(null,true)

  $(".product-actions-container").removeClass("--is-active")
  // $(".product-selection-box-container").removeClass("--is-active")
  
  
  if(!group){
    group = currSelectedGroup
  }
  if(group){
    group?.parent?.draw()
    resetTransformer(canvasContainerId)
  }
}

 

export function getFormatedConfigFromSavedConfig(configArray:Array<any>){
  let configuration:any = {}
  for (const config of configArray) {
      let productAttributes = JSON.parse(config.product_attributes)
      let imageType = config.image_type
      let productName = null
      let productId = null
      
      switch (imageType) {
        case "finish":
          productId = config.material_id
          productName = config.image_name
        break;

        case "uploaded":
          productName = config.image_name
          productId = config.product_id
        break;
        default:
          productId = config.product_id
          productName = config.product_name

      }
      
      configuration[productName+"_"+config.imageId] = {
        imageId: config.imageId,
        height: productAttributes.height,
        posX: productAttributes.posX,
        posY: productAttributes.posY,
        productId: productId, 
        productName: productName,
        productCategory:config.category_name,
        productSubCategory:config.sub_category_name,
        width: productAttributes.width,
        imageType:imageType,
        canvasWidth:productAttributes.canvasWidth,
        canvasHeight:productAttributes.canvasHeight
      }
  }
  return configuration
}



export function getDefaultWidth() {
  
  let stage = moodboardConfig?.getStage("mainMoodboardCanvasContainer")
  if(getWindowWidth() < 480){
    return stage?.width()/3
  }
  return stage?.width()/4
}


export function createTemplate(templateName:string) {
  updateConfig(configuration?.areaName,configuration?.areaId,() => {},1,templateName).then((data:any)=>{
    showToast("Template created",2000)
      let albumName = "public"
      let albumPhotosKey = encodeURIComponent(albumName) + "/moodboard/templates/" + encodeURIComponent(applicationConfig?.clientName) + "/";
      let stage = moodboardConfig.getStage("mainMoodboardCanvasContainer")
      let dataURL = stage.toDataURL({ pixelRatio: 3 })
      applicationConfig.awsConfig.uploadFile(dataURL,"moduleName",albumPhotosKey,`${String(templateName)}.png`).then(data=>{
            console.log(data)
            applicationConfig?.functions.moodboard.setRefreshTemplates(!applicationConfig?.functions.refreshTemplates)
        }).catch(err=>{
          console.log(err)
      })
  }).catch(err=>{
    console.log(err)
  })            
}

export function checkIfTemplateExists() {
  let templatesList = applicationConfig?.data.moodBoardSavedConfigs?.filter(config=>Number(config.is_template)===1)
  if(!templatesList){
    return true
  }
  let configMapping:any = {}
  for (const config of templatesList) {
    if(configMapping[config.config_name] && configMapping[config.config_name]?.length){
      configMapping[config.config_name] = [...configMapping[config.config_name],config.product_name]
    }else{
      configMapping[config.config_name] = [config.product_name]
    }
    // configMapping[config.config_name] = [...configMapping[config.config_name],config.product_name]
  }

  let currentConfigArray = []
  let key = getMoodBoardConfigKey(applicationConfig?.getMoodboardId(),applicationConfig?.getCurrArea())
  let configuration = getCurrentConfigurationFromLocalStorage(key)
  
  for (const index in configuration) {
    currentConfigArray.push(configuration[index].productName)
  }


  for (const index in configMapping) {

    if(JSON.stringify(configMapping[index]) === JSON.stringify(currentConfigArray)){
      // console.log(JSON.stringify(configMapping[index]),JSON.stringify(currentConfigArray))
      return true
    }
  }
  // console.log(currentConfigArray)
  return false
}


export function updateConfiguratonWithSavedConfig(areaName:string){
    let areaId = getObjectByParameter(applicationConfig?.data?.areasList,"area_name",areaName)?.id

    let savedConfig = null
    let currAreaSavedConfig:any = getCurrAreaSavedConfig(configuration.moodboardId,areaId)
    savedConfig = getConfigurationFromLocalStorage(areaName)
    if(savedConfig){
      return savedConfig
    }
    if(currAreaSavedConfig){
      savedConfig = JSON.parse(currAreaSavedConfig.configuration)
    }
    return savedConfig
}

function getCurrAreaSavedConfig(moodboardId:string,areaId:number){
  try {
    return applicationConfig?.data?.moodBoardSavedConfigs?.find((config:any)=>String(config.moodboard_id) === String(moodboardId) && Number(config.area_id) === Number(areaId) && !Number(config.is_template))
  } catch (error) {
    console.log(error)      
  }
}

export function getImageReferenceName(image:MoodboardImage) {
  switch(image.imageType.toLowerCase()){
    case "product":
      return image.imageReference.productName

    case "finish":
      return image.imageReference.materiaCode

    case "uploaded":
      return "Uploaded"

    default:
      return ""
  }
}


export function getImageDetails(data:any) {
  switch(data.type.toLowerCase()){
    case "product":
      return {
        productId:data.id,
        productName:data.name,
        categoryName:data.category,
        subCategoryName:data.subCategory,
        imageType:data.type,
        src:data.src,
        imageId:data.imageId,
      };

    case "module":
      return {
        productId:data.id,
        productName:data.name,
        categoryName:data.category,
        subCategoryName:data.subCategory,
        imageType:data.type,
        src:data.src,
        imageId:data.imageId,
      };

    case "finish":
      return {
        materialId:data.id,
        materialCode:data.name,
        companyName:data.category,
        collectionName:data.subCategory,
        imageType:data.type,
        src:data.src,
        imageId:data.imageId,
      };

    case "uploaded":
      return {
        imageType:data.type,
        src:data.src,
        imageKey:data.subCategory,
        imageId:data.imageId,
      };

    default:
      return {}
  }
  
}