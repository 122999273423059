import React from "react";
import ActivityLogsList from "../activities/ActivityLogs";
 


 
const LeadDetails: React.FC<{
    lead:any
}> = (props) => {

 
  function Details() {
    return(
      <div className="display-flex-row white-card no-shadow full-width border no-wrap">

      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="profile center-aligned-column">
          <div className="bg-color-primary font-small color-white center-aligned-column white-card no-shadow" style={{width:"28px",height:"28px",borderRadius:"50%"}}>
              {props.lead.customer_name.substring(0,2)}
          </div>
        </div>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Customer name</div>
          <div className="heading4 font-normal color-black">{props.lead.customer_name}</div>
        </div>
      </div>

      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">City</div>
          <div className="heading4 font-normal color-black">{props.lead.city}</div>
        </div>
      </div>


      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Contact no.</div>
          <div className="heading4v font-normal color-black">+91-{props.lead?.mobile}</div>
        </div>
      </div>


      {/* <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Created time</div>
          <div className="heading4 font-normal color-primary">23 Oct 2023 10:00 AM</div>
        </div>
      </div>

      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Modified time</div>
          <div className="heading4 font-normal color-primary">23 Oct 2023 10:00 AM</div>
        </div>
      </div> */}

      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Lead Source</div>
          <div className="heading4 font-normal color-black">{props.lead.lead_source}</div>
        </div>
      </div>

      <div className="display-flex-row border-right" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Sales Stage</div>
          <div className="heading4 font-normal color-primary">{props.lead.sales_stage}</div>
        </div>
      </div>

      <div className="display-flex-row" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Size of deal</div>
          <div className="heading4 font-normal color-black">High</div>
        </div>
      </div>

      
      <div className="display-flex-row" style={{padding:"10px 20px"}}>
        <div className="marginleft10">
          <div className="heading4 font-normal color-dark">Potential value</div>
          <div className="heading4 font-normal color-black">{props.lead.potential_value}</div>
        </div>
      </div>

      </div>
    )
  }
  
  
 
  return (
    <div className="full-height-width ion-padding">
    <Details/>

    <div className="activities-list-wrapper ion-margin-top white-card border no-shadow padding10 full-height">
      <div className="heading3 font-medium">Activities</div>
      <ActivityLogsList lead={props.lead} isShowFilters={false}/>

    </div>


    <div className="activities-list-wrapper ion-margin-top white-card border no-shadow padding10 full-height">
      <div className="heading3 font-medium">References </div>
      <div className="center-aligned-column" >
          <div className="heading4 font-normal white-card no-shadow" style={{border:"1px dashed var(--op-color-dark)",padding:"20px 30px"}}>No media available</div>
      </div>
    </div>


    </div>
  );
};

export default LeadDetails;
