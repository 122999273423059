import { IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getAllMaterialTypes, getCategoryMaterialTypes, getMaterialCompanies, getObjectMaterialTypes, getTextures } from "../../../../../services/api";
import { FabricateConfig } from "../../../../../utils/fabricate/fabricate";
import { applicationConfig } from "../../../../../utils/methods";
import Loader from "../../../../common/shared-components/main-ui/Loader";
import SessionExpired from "../../../../common/ui-components/alerts/SessionExpired";
import CustomizeIn3D from "./CustomizeIn3D";
import Header from "./Header";

const Fabricate: React.FC<{
    configObj:any
    isLogin:boolean
}> = (props) => {
 
    const[fabricateConfig,setFabricateConfig] = useState(null)
    
    const[isDataLoaded,setIsDataLoaded] = useState(null)
    
    useEffect(()=>{
        if(props.isLogin){
            let config = new FabricateConfig()
            setFabricateConfig(config)
            loadApiData()
        }
       
    },[props.isLogin])


    async function loadApiData() {
        if(isDataLoaded){
            return
        }
        let promiseArray = [getMaterialCompanies(),getAllMaterialTypes()
        ,getObjectMaterialTypes(),getTextures(),getCategoryMaterialTypes()]

        Promise.all(promiseArray)
        .then(function (apidata) {
            applicationConfig.data.materialCompaniesList = apidata[0]
            applicationConfig.data.allMaterialTypes = apidata[1]

            //For 3d configuration
            applicationConfig.data.objectMaterialTypes = apidata[2]
            applicationConfig.data.materials = apidata[3]
            applicationConfig.data.categoryMaterialTypes = apidata[4]
            setIsDataLoaded(true)
        })
        .catch(function (error) {
            alert("Token Expired. Please Go back..")
            console.log(error);
        });
    }

   
 
    return (
    <IonPage>
    {props.isLogin && props.configObj && isDataLoaded?
        <>
        {isDataLoaded && fabricateConfig?
        <div className="full-height-width store-customizein3d-wrapper">
        
        <div className="main-sub-header-wrapper display-none bg-color-white position-relative white-card" style={{zIndex:2,borderRadius:"0"}}>
            <div className="display-flex-row full-height no-wrap bg-color-white pointer" >
  
            </div>
        </div>

        <Header fabricateConfig={fabricateConfig}/>
        
        <div className="position-relative" style={{height:"calc(100% - var(--main-header-height))"}}>
            <CustomizeIn3D 
                fabricateConfig={fabricateConfig}
            />
        </div>
           

    </div>
    :
    <Loader
        id={""}
        data={{message:"Fetching Data..."}}
        loaderType="dotsText"
        className="--is-active fade-background-loader"
    />
    }
    </>
    :
    <SessionExpired/>
    }
    
        
    </IonPage>
    );
};

export default Fabricate;
