import { IonPage } from "@ionic/react";
import React from "react";
import "../../../../../../style/store/crm/crm.css";
import MainHeader from "../../common/MainHeader";
import SubHeader from "../../common/SubHeader";
import TicketsList from "./TicketsList";


 
const CustomerCreatedTickets: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {



  
 
  return (
    <IonPage>
      {props.isLogin?
        <div className="full-height-width bg-color-white crm-wrapper">
        <MainHeader />
        <SubHeader heading="Customer Tickets"/>

        <div className="display-flex-row no-wrap crm-main-content-wrapper padding-container bg-color-light">
          <TicketsList/>
        </div>

      </div>
      :null}
    </IonPage>
  );
};

export default CustomerCreatedTickets;
