import React, { useEffect, useState } from "react";
import Modal from "../../../../../../../common/shared-components/Modals/Modal";
import CloseActivityForm from "./CloseActivityForm";
import { IonModal } from "@ionic/react";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import { getObjectByParameter } from "../../../../../../../../utils/methods";

 
const StartActivity: React.FC<{
    isOpen:boolean
    setIsOpen:(val:boolean)=>void
    refreshLeads:()=>void
    lead:any
    setCurrTab:(val:string)=>void
    currActivity:string
}> = (props) => {

    const[nextActivity,setNextActivity]:any = useState({})

    useEffect(()=>{
        if(props.lead){
            const allActivityTypes = CRM.data.activityTypes
            let length = allActivityTypes.length
            let lastActivityIndex = props.lead.sequence_no
            let nextActivityIndex = lastActivityIndex + 1 % length
            const nextActvityType = getObjectByParameter(allActivityTypes,"sequence_no",nextActivityIndex) 
            if(nextActvityType){
                setNextActivity(nextActvityType)
            }
        }
    },[])



    function Footer() {
        return(
            <div className="">
                
            </div>
        )
    }
 
 
 
  return (
    <Modal
        isOpen = {props.isOpen}
        onDismiss = {()=>props.setIsOpen(false)}
        heading={"Activity Started"}
        FooterContent={Footer}
        classNames="vertical-modal"
        id=""
        Content={()=>(
            <>
            {props.lead?
                <CloseActivityForm currActivity={props.currActivity} setCurrTab={props.setCurrTab} refreshLeads={props.refreshLeads} isOpen={props.isOpen} setIsOpen={props.setIsOpen} lead={props.lead}/>
            :null}
            </>
        )}
    >
    </Modal>
  );
};

export default StartActivity;
