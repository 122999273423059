import React, { useEffect, useState } from "react";
 
import $ from "jquery"
 
import { IonSpinner } from "@ionic/react";
import { getFilteredArray } from "../../../../../../../../utils/methods";
import { showGlobalToast } from "../../../../../../../../utils/UI_methods/global";
import { Customer } from "../../../../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
 
const Search: React.FC<{
  setSearchedCustomerList:(values:any)=>void
}> = (props) => {


  const[filteredTickets,setFilteredCustomer] = useState([])
  const[currcustomer,setCurrCustomer] = useState(null)
  
 

  function setCustomerHandle(customer:Customer) {
      setCurrCustomer(customer)
      $("#customerSearchInput").val(customer.customerName+" "+customer.mobile)
      setFilteredCustomer([])
  }

  function serachCustomerHandle() {
    if(currcustomer){
      let filteredCustomer = getFilteredArray(CRM.data.customersList,"customer_id",currcustomer.id)
      props.setSearchedCustomerList(filteredCustomer)
      if(!filteredCustomer.length){
        showGlobalToast("No results",2000,"error")
      }
      $("#customerSearchInput").val("")
      setCurrCustomer(null)
    }else{
      showGlobalToast("No results",2000,"error")
      $("#customerSearchInput").val("")
    }
  }

  function onchangeHandle(event:any) {
    const value = event.target.value
    if(value.length >= 3){
      let result = CRM.data.ticketsList?.filter(currTicket=>currTicket.mobile.includes(value) || currTicket.customer_name.toLowerCase().includes(value.toLowerCase()) || currTicket.ticket_no.includes(value) )
      setFilteredCustomer(result)
      console.log(result)
      $("#customerSearchLoader").removeClass("visibility-hidden")
    }else{
      setFilteredCustomer([])
    
    }
    setTimeout(() => {
      $("#customerSearchLoader").addClass("visibility-hidden")
    }, 2000);
  }

  return (

      <div className="display-flex-row">

        
          <div className="center-aligned-column marginleft10">
            <div className="display-flex-row position-relative white-card no-shadow" style={{padding:"2px 15px"}}>
              <span className="center-aligned-column"><i className="fas fa-search color-dark"></i></span>
              <span><input id="customerSearchInput" onChange={onchangeHandle} type="text" className="input no-focus border-none" placeholder="Search tickets" style={{border:"none",width:"300px"}}/></span>
              
              <div className="top-middle" style={{padding:"0",top:"100%",zIndex:999,width:"342px"}}>
              <div className="full-width customers-list bg-color-white">
                {filteredTickets.map((customer:any,index:number)=>{
                  return(
                    <div className={`pointer padding10 ${index>1?" display-none":" "}`} onClick={()=>{setCustomerHandle(new Customer(customer))}} key={index}>
                      <div className={`heading3 font-normal  `}>{customer.customer_name}</div>
                      <div className={`heading3 marginTop5 color-dark font-md-small`}>Ticket No: {customer.ticket_no}</div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div id="customerSearchLoader" className="right-middle visibility-hidden center-aligned-column padding10" style={{marginTop:"0px"}}>
              <IonSpinner name="crescent" />
            </div>
            
            </div>
          </div>

          <Button2
              configObj={{id:""}}
              action={()=>{serachCustomerHandle()}}
              label={"Search"}
              fontAwesomeIcon={"fas fa- color-dark"}
              iconPos=""
              classNames={"no-shadow marginleft5 bg-color-primary"}
          />
      </div>  
  );
};

export default Search;
