import $ from "jquery";
import React from 'react';
import { createTemplate } from '../../../../../../utils/moodboard/helper';

  
  const CreateTemplateForm: React.FC<{
    configObj:any
  }> = (props) => {


    function enterSubmit(event:any) {
      if (event.keyCode === 13) {
        create()
      }
    }

    function create() {
      let name:any = $("#collectionTemplateName").val() || ""
        if(!name.length){
          alert("Please enter template name")
          return
        }
        createTemplate(name)
        closeTemplate()
    }

    function closeTemplate() {
      $("#createTemplateWrapper").removeClass("--is-active")
    }
   
    return (
        <div className="full-height-width create-template-wrapper" id="createTemplateWrapper">
          <div className='center-aligned-column full-height-width'>
            <div>
              <div className="color-white text-center font-medium">Please enter template name to create template</div>
              <div className="input-container ion-margin-top center-aligned-row full-width" >
                  <input
                    className="input input-mandatory"
                    type="text"
                    name="collectionName"
                    id="collectionTemplateName"
                    placeholder="Enter template name"
                    required
                    style={{width:"200px",background:"transparent"}}
                    onKeyUp={(event)=>{enterSubmit(event)}}
                  />
                </div>

                <div className='ion-margin-top center-aligned-row'>
                  <div>
                    <div onClick={create} className="color-white pointer bg-color-primary font-normal op-btn marginleft5 zindex30 border ">Create</div>
                    <div onClick={closeTemplate} className="color-white pointer font-normal op-btn marginleft5 zindex30 border ">Cancle</div>
                  </div>
                </div>

            </div>
          </div>
        </div>
    );
  };
  
  export default CreateTemplateForm;
  