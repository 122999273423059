import React, { useEffect } from "react";

const FinishCards: React.FC<{
  configObj: any;
  logsList:any
}> = (props) => {
  //Function to remove project after deleting


  const getDateStringServ = (timestamp:number) => {

    const plus0 = num => `0${num.toString()}`.slice(-2)
  
    const d = new Date(timestamp)
  
    const year = d.getFullYear()
    const monthTmp = d.getMonth() + 1
    const month = plus0(monthTmp)
    const date = plus0(d.getDate())
    const hour = plus0(d.getHours())
    const minute = plus0(d.getMinutes())
    const second = plus0(d.getSeconds())
    const rest = timestamp.toString().slice(-5)
  
    return `${year}-${month}-${date}     ${hour}:${minute}:${second}`
  }


  return (
      <div className="full-width">

          <table className="table logs-table full-width">
                <thead className="light-background">
                  <tr>
                    <th>Timestamp</th>
                    <th>Ip Address</th>
                    <th>User name</th>
                    <th>Log code</th>
                    <th>Device Description</th>
                    <th>Screen Information</th>
                    {/* <th>User name</th>
                    <th>Action Type</th> */}
                    {/* <th>log code</th> */}
                    {/* <th>Event Type</th> */}
                  </tr>
                </thead>
                <tbody>
                  
                  {props.logsList?.map((currLog: any, index: number) => {
                    return (
                      <tr key={index} className="">
                        <td>{getDateStringServ(Number(currLog.timestamp))}</td>
                        <td>{currLog.ip_address}</td>
                        <td>{currLog.username}</td>
                        <td>{currLog.log_code}</td>
                        <td>{currLog.device_decscription}</td>
                        <td>{currLog.screen_info}</td>
                        {/* <td>{currLog.username}</td>
                        <td>{currLog.module_name}</td>
                        <td>{currLog.action_type}</td> */}
                        {/* <td>{currLog.log_code}</td> */}
                        {/* <td className="uppercase">{currLog.event_type}</td> */}
                        {/* <td><span className="uppercase padding0">{currLog.event_type}</span> : {currLog.message}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
      
      </div>
  )
}

export default FinishCards;
