import React, { useEffect } from "react";
import AreaProductDetails from "../../../admin/projects/createproject/review/AreaProductDetails";

  const CreateProjectSummary: React.FC<{
    configObj: any;
    configuration:any
  }> = (props) => {

   
  

    return (
      <React.Fragment>
          <AreaProductDetails
          configObj={props.configObj}
          areaList={props.configuration.selectedAreas}
          selectedCategories={props.configuration.selectedCategories}
          allCategories={props.configuration.allCategories}
          isShowPrice={true}
        />
    </React.Fragment>
    );
  };

  export default CreateProjectSummary;