import $ from "jquery";
import { BoxHelper, Object3D, Vector3 } from "three";
import { hideComponentLoader, showComponentLoader } from "../../UI_methods/global";
import { checkFromCache, untarAndSaveToCache } from "../../cache/cache";
import { applicationConfig, stringWithoutWhiteSpace } from "../../methods";
import { detachModule, updateModelBoundingBox } from "../common";
import { setCurrSelectedProduct } from "../raycasting";
import { getClicked3DPoint } from "./annotations";
import { IS_SPACE_PLANNER_MODE, addAddonToConfiguration, camera, configuration, element, group, loadModuleToTheScene, modulesLoader, postAddModuleAction, postAddingActions, scene } from "./customizein3d";


export var isDraggingProduct = false
export var isAddedModelToScene = false

export var CURR_DRAGGING_PRODUCT = null
export var MODULE_INFO = null
export var CURR_DRAGGING_MODEL = null
export var boxHelper = null
var isPointerUpAfterProductDragging = false

var isProductDownloaded = false
const vector = new Vector3()

var currPosition:any = {x: 0, y: 0};
export var mouse = {x: 0, y: 0};



export function dragStartProduct(event:any) {
  resetDraggingParameters()
  setCurrDraggingProduct(getProductDetailsFromElement($(event.target)))
  event.dataTransfer.setDragImage(document.createElement("img"),0,0)
  downloadAndAddProductToScene()
  let src = $(event.target).find("img").attr("src")
  if(src){
    setDragImageSrc(src)
  }
}

function setCurrDraggingProduct(product:any) {
  CURR_DRAGGING_PRODUCT = product
}

function getProductDetailsFromElement(element:any) {
  return {
    productName:element.attr("data-product-name"),
    productId:element.attr("data-product-id"),
    categoryId:element.attr("data-product-category-id"),
    categoryName:element.attr("data-product-category"),
    subCategoryName:element.attr("data-product-subcategory"),
    moduleType:element.attr("data-module-type"),
    subModuleType:element.attr("data-sub-module-type"),
    imageType:element.attr("data-image-type"),
    storeFrontName:element.attr("data-storefront"),
  }
}

function hideLoaders() {
  $("#draggableImgLoader").removeClass("--is-active")
  hideComponentLoader("changeFinishLoader")
  $("#draggableImg").fadeOut(700)
}


function downloadAndAddProductToScene() {
  
  let product = {product_name:CURR_DRAGGING_PRODUCT.productName,category_name:CURR_DRAGGING_PRODUCT.categoryName,sub_category_name:CURR_DRAGGING_PRODUCT.subCategoryName}
  MODULE_INFO = {
    module_name:product.product_name,
    category_name:product.category_name,
    sub_category_name:product.sub_category_name,
    sub_module_type:"Default",
    module_id:CURR_DRAGGING_PRODUCT.productId,
    storefront_name:CURR_DRAGGING_PRODUCT.storefront_name,
    module_type:"Product",
    price:0,
    allow_rotation:1,
    allow_movement:1,
    allow_duplication:1,
    allow_delete:1,
  }
  addProductToScene(module,product).then((model:Object3D)=>{
    isProductDownloaded = true
    $("#draggableImgLoader").removeClass("--is-active")
    CURR_DRAGGING_MODEL = model
    CURR_DRAGGING_MODEL.name = "dragging"
    setCurrSelectedProduct(model)
    if(isPointerUpAfterProductDragging){
      allowDropProduct(null)
      dropProductAction()
    }
    $("#draggableImg").fadeOut()
  }).catch(err=>{
    isProductDownloaded = true
    $("#draggableImg").fadeOut()
  })
}



export async function addProductToScene(module:any,product:any) {
  let productName = product.product_name || product.productName
  let categoryName = product.category_name || product.categoryName
  let subCategoryName = product.sub_category_name || product.subCategoryName

  let clientName = applicationConfig?.clientName
  if(CURR_DRAGGING_PRODUCT){
    clientName = CURR_DRAGGING_PRODUCT?.storeFrontName === "OVL" ? "OVL" : applicationConfig?.clientName
  }


  
  let tarfilekey = applicationConfig.awsConfig.getTarFileKey("productModels",{clientName:clientName,productName:stringWithoutWhiteSpace(productName),categoryName:stringWithoutWhiteSpace(categoryName),subCategoryName:stringWithoutWhiteSpace(subCategoryName)})
  let urlPrifix = `productModels/${clientName}/${categoryName}/${subCategoryName}`
  await untarAndSaveToCache(applicationConfig.awsConfig,urlPrifix,stringWithoutWhiteSpace(tarfilekey))
  let key:string = `productModels/${clientName}/${categoryName}/${subCategoryName}/${stringWithoutWhiteSpace(productName)}.glb`
  
  let loader = modulesLoader  
  let url:any =  await checkFromCache(key)
  let object:any =  await loadModuleToTheScene(loader,url)
  return object
}

 


export function addBoxHelper(object:any,parent:any=null) {
  removeBoxHelper()
  boxHelper = new BoxHelper( object, 0x0058a3)
  if(parent){
    parent.add(boxHelper)
  }
  scene.add(boxHelper)
  return boxHelper
}

export function allowDropProduct(event: any) {
  $(".hide-on-dragging").css("pointer-events","none")
  if(event){
    currPosition = {
      x:event.pageX || event.touches[0].clientX,
      y:event.pageY || event.touches[0].clientY
    }
  }
  if(!isAddedModelToScene && CURR_DRAGGING_MODEL){
    scene.add(CURR_DRAGGING_MODEL)
    CURR_DRAGGING_MODEL.visible = false
    isAddedModelToScene = true
    $("#draggableImg").fadeOut()
  }
  event?.preventDefault()
  updateDraggingModelPosition(event?.pageX || currPosition.x , event?.pageY || currPosition.y)
}


export function updateDraggingModelPosition(posX:any,posY:any,object:any = null) {
  isDraggingProduct = true
  if(!object){
    object = CURR_DRAGGING_MODEL
  }
  if(object){
    mouse.x = posX
    mouse.y = posY
    if(IS_SPACE_PLANNER_MODE){
      updatePositionInSpacePlannerMode(object)
    }else{  
      updatePositionIn3dMode(object) 
    }
  }
}

function updatePositionInSpacePlannerMode(object:any) {
  let position:any = getClicked3DPoint(mouse.x,mouse.y,vector,element,camera)
  let posY = 0
  if(position && object){
    object?.position.set(position.x,posY,position.z)
    object.visible = true
    if(boxHelper){
      boxHelper!.visible = true
      boxHelper!.update()
    }
  }
}

function updatePositionIn3dMode(object:any){
  let pos = getClicked3DPoint(mouse.x,mouse.y,vector,element,camera)
  if(pos && object){
    let posY = getPosY(pos)
    let position = new Vector3(pos.x,posY,pos.z)
    object?.position.copy(position)
    object.visible = true
    updateModelBoundingBox(object)
  }
}

export function getPosY(object:any) {
  let posY = 0
  return posY
}


export async function dropProduct(event: any) {
  isDraggingProduct = false
  $(".hide-on-dragging").css("pointer-events","all")
  isPointerUpAfterProductDragging = true
  //Id object is not downloaded and mouse up
  if(!isProductDownloaded){
    $("#draggableImg").fadeOut(700)
    showComponentLoader("changeFinishLoader")
    return
  }
  dropProductAction()
}     

function dropProductAction() {    
  if(isAddedModelToScene && CURR_DRAGGING_MODEL){
    isAddedModelToScene = false
    CURR_DRAGGING_MODEL.name = CURR_DRAGGING_PRODUCT.productName
    detachModule(CURR_DRAGGING_MODEL)
    group.attach(CURR_DRAGGING_MODEL)
    addBoxHelper(CURR_DRAGGING_MODEL)
    hideLoaders()
    postAddingActions(CURR_DRAGGING_MODEL)
    postAddModuleAction(MODULE_INFO,CURR_DRAGGING_MODEL)
    addAddonToConfiguration(MODULE_INFO,CURR_DRAGGING_MODEL,configuration)
 
    resetDraggingParameters()
  }
}


export function  resetDraggingParameters(isReset:boolean = true){
    removeBoxHelper()
    if(isReset){
      CURR_DRAGGING_PRODUCT = null
      CURR_DRAGGING_MODEL = null
      MODULE_INFO = null
    }
    isDraggingProduct = false
    isPointerUpAfterProductDragging = false
    isProductDownloaded = false
    isAddedModelToScene = false
    currPosition.x = 0 
    currPosition.y = 0 
    $(".hide-on-dragging").css("pointer-events","all")
    $("#draggableImg").fadeOut(0)
}

export function setCurrDraggingModel(object:any) {
  CURR_DRAGGING_MODEL = object
}
 
export function removeBoxHelper() {
    scene.remove(boxHelper)
    boxHelper = null
}

export function updateBoxHelper() {
  if(boxHelper){
    boxHelper!.update()
  }
}



//For mobile view // All images
export function addEventListenerOnMobileDragIconAddons(){
  // let allIcons = document.querySelectorAll(".image-drag-icon")
  // for (let i = 0; i < allIcons.length; i++) {
  //   allIcons[i].addEventListener("touchmove",updateDragImagePos,false)
  //   allIcons[i].addEventListener("touchend",onTouchEndProductDrag,false)
  // }
}

export function onTouchStartProductDrag(event:any) {
  let element = $(event.target).parents(".module-container")
  if(element){
    $(".product-addons-container").removeClass("overflow-y-scroll")
    resetDraggingParameters()
    setCurrDraggingProduct(getProductDetailsFromElement(element))
    downloadAndAddProductToScene()
    let src = element.find("img").attr("src")
    if(src){
      setDragImageSrc(src)
      updateDragImagePos(event)
    }
  }
}

export function onTouchMoveProductDrag(event:any) {
  allowDropProduct(event)
  updateDragImagePos(event)
}

function setDragImageSrc(src:string) {
  $("#draggableImg").attr("src",src)
  updateDragImagePos()
  $("#draggableImg").fadeIn()
}

export function updateDragImagePos(touchEvent:any = null) {
  if(CURR_DRAGGING_PRODUCT){
    if(touchEvent){
      currPosition.x = touchEvent.touches[0].clientX
      currPosition.y = touchEvent.touches[0].clientY 
    }
    if(currPosition.x || currPosition.y){
      $("#draggableImg").css("left",currPosition.x + 'px')
      $("#draggableImg").css("top",currPosition.y + 'px')
    }
  }
}


export function onTouchEndProductDrag(event:any) {
  $("#draggableImg").fadeOut(0)
  $(".product-addons-container").addClass("overflow-y-scroll")
  dropProduct(event)
}
 