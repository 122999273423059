import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import { applicationConfig, getUserType } from "../../../../utils/methods";
import Summary from "../../../common/shared-components/Share/Summary";
import Button2 from "../buttons/Button2";

const SummaryLink: React.FC<{
  currentConfigObject: any;
  moduleName: string;
}> = (props) => {
  const [showPricing, setShowPricing]: any = useState(false);


  return (
    <React.Fragment>
      <IonModal
        cssClass="share-modal"
        onDidDismiss={() => setShowPricing(false)}
        isOpen={showPricing}>
        <Summary
          currentConfigObject={props.currentConfigObject}
          setShowPricing={setShowPricing}
          moduleName={props.moduleName}
        />
      </IonModal>

      <div className={`position-relative zindex30 display-none button2 shadow-light white-card click-effect no-wrap display-flex-row ${getUserType() === "customer"?" summary-price-button":""}`} onClick={() => {
              setShowPricing(true);
            }}>
              <span className="color-danger center-aligned-column font-normal heading1 rupee-sign">
              ₹{" "}
            </span>
        <span id="modulesPrice" className={`text center-aligned-column modules-price total-price pointer-none ${getUserType() === "customer"?" hide-in-mobile":""}`} style={{margin:"0 0.5rem"}}>Price</span>  
      </div> 

      <div className={`summary-link tooltip display-none ${getUserType() === "customer"?" display-none":""} `} id="summaryLink">
            <Button2
              configObj={applicationConfig}
              action={()=>setShowPricing(true)}
              label={"Share"}
              fontAwesomeIcon={"fas fa-share-alt color-primary"}
              iconPos="right"
              classNames={"no-shadow margin0"}
            />
      </div>
      {/* <Button2
        configObj={applicationConfig}
        action={()=>{setShowPricing(true)}}
        label={"Summary"}
        fontAwesomeIcon={"fas fa-clipboard color-danger"}
        iconPos="left"
        classNames={"bg-color- button- marginTop10 button-fixed-width"}
      /> */}

          {/* <Button2
              configObj={props.configObj}
              action={enable}
              label={"Price"}
              fontAwesomeIcon={"fas fa-summary font-normal color-primary"}
              iconPos="left"
              classNames={"shadow-medium ruppe-sign hide-in-dektop"}
            /> */}
    </React.Fragment>
  );
};

export default SummaryLink;
