import React, { useState } from "react";
import { applicationConfig } from "../../../utils/methods";
import FinishCards from "../../common/ui-components/cards/FinishCards";
import CardsSideBarLayout from "../../templates/layouts/page/CardsSideBarLayout";
import FinishHeader from "./FinishHeader";
import Home from "./finishActions/Home";




const FinishLayout: React.FC<{
  configObj: any;
  materialsList:Array<any>
  collections:Array<any>
}> = (props) => {

  const[searchedMaterials,setSearchedMaterials] = useState([])

  return (
    <React.Fragment>

      <FinishHeader
        searchedMaterials={searchedMaterials}
        setSearchedMaterials={setSearchedMaterials}
      />

      {searchedMaterials.length?
        <div className="padding10">
          <Home
            configObj={applicationConfig}
            currCollection={searchedMaterials[0]?.collection_name}
            setopenFinishAction={()=>{}}
            materials={searchedMaterials}
          />
        </div>
      :
        <CardsSideBarLayout
          MainContent={()=>(
            <FinishCards
              configObj={props.configObj}
              collections={props.collections}
              materials={props.materialsList}
            />
          )}
        />
      }

      

    </React.Fragment>
  );
};

export default FinishLayout;
