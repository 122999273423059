import React, { useEffect, useState } from 'react';
import { list } from 'tar';
import { getArrayOfDistinctValues, getFilteredArray, getObjectArrayOfDistictValues } from '../../../../../../utils/methods';
import { getFormatedConfigFromSavedConfig } from '../../../../../../utils/moodboard/helper';
import { configuration, loadAndDrawImagesFromConfig, moodboardFullPageLoaderId } from '../../../../../../utils/moodboard/moodboard';
import { hideComponentLoader, showComponentLoader } from '../../../../../../utils/UI_methods/global';
import DropDown from '../../../../../common/ui-components/filters/DropDown';


const Templates: React.FC<{
  configObj:any
}> = (props) => {

  const[templateList,setTemplatesList] = useState([])
  const[allTemplates,setAllTemplates] = useState([])
  const[currSelectedArea,setCurrSelectedArea] = useState("All")

  const[refreshTemplates,setRefreshTemplates] = useState(false)


  useEffect(()=>{
    props.configObj.setFunctionRef("moodboard",{setRefreshTemplates:setRefreshTemplates})
  },[])   

  useEffect(()=>{
    if(currSelectedArea.length && allTemplates.length){
      if(currSelectedArea.toLocaleLowerCase() === "all"){
        setTemplatesList(allTemplates)
      }else{
        setTemplatesList(getFilteredArray(allTemplates,"area_name",currSelectedArea))
      }
    }
  },[currSelectedArea,allTemplates])


 

  useEffect(()=>{
    if(props.configObj.data.moodBoardSavedConfigs){
      let list = props.configObj.data.moodBoardSavedConfigs?.filter(config=>Number(config.is_template)===1 && config.area_id === configuration.areaId)
      // list = getObjectArrayOfDistictValues(list,"template_name")
      setAllTemplates(list)
      props.configObj.setFunctionRef("moodboard",{refreshTemplates:refreshTemplates})
    }
  },[refreshTemplates])


  async function updateConfig(configuration:string) {
    showComponentLoader(moodboardFullPageLoaderId)
    await loadAndDrawImagesFromConfig(JSON.parse(configuration))
    hideComponentLoader(moodboardFullPageLoaderId)
  }

  function updateTemplateList(areaName:string) {
    setCurrSelectedArea(areaName)
  }
 
  return (
    <React.Fragment>
        <div className="border-bottom hide-in-mobile full-width ion-margin-bottom">
          <div className="heading1 text-center font-normal full-width padding10">Templates</div>
      </div>

      {/* <div className='' style={{padding:"0 20px"}}>
        <DropDown
          classNames=""
          columns={1}
          values={getArrayOfDistinctValues(allTemplates,"area_name")}
          currSelectedValue={currSelectedArea}
          action={updateTemplateList}
          label="Area"
        />
      </div> */}

    

      <div className='full-height full-width overflow-y-scroll padding10'>
        {templateList?.map((config:any,index:number)=>{
          let source = `https://opusassets.s3.ap-south-1.amazonaws.com/public/moodboard/templates/DemoClient2/${config.template_name}.png`
          return(
            <div className='position-relative full-width border marginTop10'>
              <div className='top-left padding0'>
                <div className='font-small capitalize color-white' style={{padding:"2px 10px",background:"var(--ion-color-medium)"}}>{config.template_name}</div>
              </div>
              <img onClick={()=>updateConfig(config.configuration)} className='full-width pointer  ' src={source} id={`template${config.template_name}`} key={index}/>
            </div>
          )
        })}
      </div>
 
    </React.Fragment>
  );
};

export default Templates;
