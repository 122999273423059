import React, { useState } from "react";
import { getFormatedDate } from "../../../../../../../utils/store/crm/crm";
 

 
const VisitorsCard: React.FC<{
  visitor:any
  index:number
}> = (props) => {

  const[isOpenStartActivity,setIsOpenStartActivity] = useState(false)
  const[currActivity,setCurrActivity] = useState("")

    

 
  return (
    <div className="full-width padding5 lead-card-container" key={props.index}>

      <div className={`white-card pointer position-relative no-shadow border`}>

        
        <div className="display-flex-row ">

          <div className="padding5 name-badge-container">
            <div className="capitalize name-badge center-aligned-column white-card no-shadow">
              {props.visitor.customer_name.substring(0,2)}
            </div>
          </div>

          <div className="customer-details center-aligned-column padding5 marginleft5">

            <div className="contact-details ">

              <div className="display-flex-row item">
                <span className="center-aligned-column"><i className="far fa-id-card icon color-dark"></i></span>
                <span className="center-aligned-column heading2 capitalize name color-black text  marginleft5">{props.visitor.customer_name}</span>
              </div>

              <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column"><i className="fas fa-mobile-alt icon color-dark"></i></span>
                <span className="center-aligned-column text  marginleft5">+91 {props.visitor.mobile}</span>
              </div>


            <div className="display-flex-row ion-margin-start position-relative item">
              <span className="center-aligned-column"><i className="far fa-clock icon color-dark"></i></span>
              <span className="center-aligned-column text marginleft5 color-dark">Check in: {props.visitor.check_in?getFormatedDate(props.visitor.end_date):"Not called yet"} </span>
            </div>

            </div>
          </div>

        </div>
        
    

        <div className={`top-right options-container center-aligned-column full-height`}>
        </div>
      </div>
    </div>
    
  );
};

export default VisitorsCard;
