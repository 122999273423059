import { IonIcon, IonModal, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { fullScreenBrowserToggle, toggleUserOption } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getRandomString, getSubDomainName, getUser } from "../../../../../utils/methods";
import { openSideMenu } from "../../../../../utils/store/UI_methods";
import { showProductCart, updateCartIconCounter } from "../../../../../utils/store/product-cart";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import "../../../../style/store/header.css";
import ProductCart from "../cart/ProductCart";
import ProductCheckout from "../checkout/ProductCheckout";
import CreateCustomOrder from "../custom-order/CreateCustomOrder";
import StartCustomerSession from "../customer/StartCustomerSession";
import SideMenu from "./SideMenu";
import StoreProductSearch from "./StoreProductSearch";
import UserOptions from "./UserOptions";
import { getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";
import { expand } from "ionicons/icons";
import SubscriptionAlert from "../../../../common/ui-components/alerts/SubscriptionAlert";
import MainHeader from "./MainHeader";
import SessionExpired from "../../../../common/ui-components/alerts/SessionExpired";
import KairaContact from "../../../Elevate/Kanvas/KairaFabrics/KairaContact";

 
const KairaContactUs: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {

  const history = useHistory()

  
  function Footer(){
    return (
      <div className="full-width bg-color-dark center-aligned-column" style={{height:"10rem"}}>
         <p className="color-white heading4">&copy; 2024 Kaira Fabrics. All rights reserved. | Powered by <a href="https://omniviewlabs.com/" className="color-white" style={{textDecoration:"none"}}> omniviewlabs.com</a></p>
      </div>
    )
  }
 
  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white">
        <SubscriptionAlert/>
        <MainHeader/>
        <div className="main-content-wrapper overflow-y-scroll hide-scrollbar  position-relative light-background">
        <KairaContact/>
        <Footer/>
        </div>
      </div>
      :
      <SessionExpired/>
      }
    </IonPage>   
  );
};

export default KairaContactUs;
