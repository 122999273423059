import React, { useEffect, useState } from "react";
import DropDown from "../../../../../../common/ui-components/filters/DropDown2";
import CustomerCard from "./CustomerCard";
 

 
const CustomerListContainer: React.FC<{
  filteredCustomer:any
  currSelectedTime:string
}> = (props) => {


  const [loadLimit, setLoadLimit] = useState(10);
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    if (props.filteredCustomer?.length) {
      // updateModuleFilters("products", "productsLoadLimit", loadLimit);
      setCustomerList((prevProducts) => {
        return prevProducts.concat(
          props.filteredCustomer?.slice(loadLimit - 10, loadLimit)
        );
      });
    }
  }, [props.filteredCustomer, loadLimit]);

  function onChangeTime(value:string) {
    // props.filterVisitorsWithTime(value)
  }


  function nextPage() {
    setLoadLimit((prevValue) => {
      if (prevValue >= customerList?.length) {
        return prevValue;
      }
      return prevValue + 10;
    });
  }

  
  return (
    <div className="padding10 full-height-width display-flex-column no-wrap">
      <div className="leads-list-container display-flex-column no-wrap full-height-width position-relative">
          <div className="header-container display-flex-row space-between">
            <div className="date-filter padding5">
              <DropDown
                classNames={""}
                values={["Last created"]}
                currSelectedValue={"Last created"}
                action={()=>{}}
                label="Sort by"
              />
            </div>
          </div>

        {customerList?.length?
          <div className="container overflow-y-scroll"  style={{paddingBottom:"180px"}}>
            {customerList?.map((customer:any,index:number)=>{
              return(
                <div className="full-width" key={index}>
                  <CustomerCard
                    customer={customer}
                    index={index}
                  />
                  </div>
                )
              })}
              {loadLimit < props.filteredCustomer?.length ?
                  <div className="center-aligned-row full-width ion-margin-top">
                    <div className="display-flex-row pointer border bg-color-white padding10" onClick={nextPage}>
                      <div className="font-small center-aligned-column products-toggle marginleft5  heading3 pointer-none"  style={{padding:"10 12px"}}>Load more</div>
                    </div>
                  </div>
              : null}
          </div>
        :
        <div className="middle" >
          <div className="heading4 font-normal white-card no-shadow" style={{border:"1px dashed var(--op-color-dark)",padding:"25px 35px"}}>No customers found</div>
        </div>
        }

        

      </div>
      
      
    </div>
  );
};

export default CustomerListContainer;
