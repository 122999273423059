import React, { useEffect, useState } from "react";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
import Header from "./header/Header";
import CustomerListContainer from "./CustomerListContainer";
import { getTodayTimestampOnlyDate } from "../../../../../../../utils/store/crm/calendar";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import CustomerCard from "./CustomerCard";
 

 
const CustomersList: React.FC<{
}> = (props) => {

  const[currLead,setCurrLead] = useState({})
  const[customersList,setCustomersList] = useState([])
  const[searchedCustomerList,setSearchedCustomerList] = useState([])

  const[currSelectedTab,setCurrSelectedTab] = useState("")
  const [currSelectedTime,setCurrSeletedTime] = useState("Today")


  useEffect(()=>{
    CRM.crmApi.customers.getCustomersList().then(data=>{
      if(!data.data.error){
        setCustomersList(data.data.data)
        // setCurrSeletedTime("Today")
        CRM.data.customersList = data.data.data
      }
    }).catch(err=>{
      console.log(err)
    })
  },[])




  function refreshList() {
  }

  function SearchResult() {
    return(
      <div className="full-width  full-height bg-color-light hide-scrollbar calendar-header padding10">
        <div className="display-flex-row no-wrap paddingTop5">
          <div className="">
              <Button2
                  configObj={{id:""}}
                  action={()=>{setSearchedCustomerList([])}}
                  label={"All customers"}
                  fontAwesomeIcon={"fas fa-arrow-left color-dark"}
                  iconPos="left"
                  classNames={"no-shadow marginleft5"}
              />
            </div>
            {/* <div className="heading3 padding5 text-center full-width center-aligned-column">Showing {searchedLeadsList.length} results</div> */}
          </div>
          <div className="marginTop10">
            {searchedCustomerList?.map((customer:any,index:number)=>{
              return(
                <CustomerCard
                  customer={customer}
                  index={index}
                />
              )
            })}
        </div>
      </div>
    )
  }

 
 
  return (
    <div className="full-height-width">
 
      <Header refreshList={refreshList} setSearchedCustomerList={setSearchedCustomerList}/>

      <div className="display-flex-row no-wrap full-height">
        <div className="border full-height white-card shadow-light display-flex-column" style={{width:"100%"}}>
          <div className="full-width  full-height bg-color-light hide-scrollbar">
            {searchedCustomerList.length?
              <SearchResult/>            
            :
            <CustomerListContainer filteredCustomer={customersList} currSelectedTime={currSelectedTime}/>  
            }
          </div>
        </div>
      </div>


    </div>
  );
};

export default CustomersList;
