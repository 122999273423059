import { IonToggle } from "@ionic/react";
import React from "react";
import { toggleSpacePlannerMode } from "../../../../../utils/customizein3d/modules/customizein3d";

const SpacePlannerToggle: React.FC<{
  isSpacePlannerMode:boolean
  setIsSpacePlannerMode:(val:boolean)=>void
  action:()=>void
}> = (props) => {
 
  

 

  return (
    <div className='display-flex-row'>
      <div  className="space-planner-toggle-button" id="spacePlannerToggle">
        <IonToggle
          mode="ios"
          id=""
          className="space-planner-toggle"
          checked={props.isSpacePlannerMode}
          onIonChange={(e) => {
            props.setIsSpacePlannerMode(e.detail.checked);
            props.action();
          }}
        />
        <div className="display-flex-column text-center"> <span className="heading3 color-danger text-center">Space</span> 
        <span className="heading3 color-danger" style={{marginTop:"-2px"}}>Planner</span> </div>
      </div>
    </div>
       
  );
};

export default SpacePlannerToggle;
