import React from 'react';
import $ from "jquery"
import { useEffect } from 'react';
import { useState } from 'react';
const ColorSwatch: React.FC<{
  configObj:any
  colorOptions:Array<any>
  action:(event:any)=>void
}> = (props) => {

 
  const[limit,setLimit] = useState(150)

  function addActiveClass(event:any){
    let element = $(event?.target)
    removeActiveClassFromAll()
    let checkmarkElement = $('<span></span>')
    let thumbnailURL = './assets/images/checkmark.png'
    checkmarkElement.css({ 'background-image': 'url(' + thumbnailURL + ')'});
    checkmarkElement.addClass('checkmark')
    $(element).children().eq(0).append(checkmarkElement)
  }

  function removeActiveClassFromAll(){
    let textures = document.querySelectorAll('.material-container')
    textures.forEach(element => {
        $(element).find('.checkmark').remove()
    });
    $(".texture-label-container").removeClass("--is-active")
  }

  return (
      <>
        <div className="overflow-y-scroll display-flex-row fill-available-height ion-padding-bottom " style={{alignContent:"baseline"}}>
          {props.colorOptions?.map((color: any, index: number) => {
            return (
            <React.Fragment key={index}>
                {Number(index)<limit?
                <div className="color-option-container position-relative" 
                data-company-name={color.hex_code}
                data-collection-name={color.hex_code}
                data-material-id={color.id}
                data-material-type="Color"
                data-material-code={color.color_r_value+'-'+color.color_g_value+'-'+color.color_b_value}
                data-object-type="background"
                onClick={(event) => 
                  {
                    props.action(color)
                    addActiveClass(event)
                  }
                } key={index}
                style={{
                  padding:"0.1rem 0.1rem"
                }}
                
                >
                  <div className='full-height-width'
                  style={{
                    backgroundColor: `rgb(${color.color_r_value},${color.color_g_value},${color.color_b_value})`,
                  }}
                  ></div>
              </div>
              :null}
            </React.Fragment>
            
            );
          })}
        </div>
        <div className="center-aligned-row load-more"> 
        <div>
            <button className="op-btn-light button-small op-btn" style={{padding:"0.6rem 1.5rem"}} onClick={()=>setLimit(prev=>{
                return prev + 50
            })}> <span className="ion-text-capitalize font-small color-black heading2">Load more...</span> </button>
        </div> </div>
      </>
  );
};

export default ColorSwatch;
