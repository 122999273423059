import React, { useEffect } from 'react';
import Loader from '../../../common/shared-components/main-ui/Loader';
import { hideComponentLoader } from '../../../../utils/UI_methods/global';

  
const ProductViewer: React.FC<{
    id:string
}> = (props) => {


 

    return (
        <div className='full-height-width' id={props.id}>
            <Loader
                className="--is-active fade-background-loader"
                id="modelViewerLoader"
                data={{message:"Loading model"}}
                loaderType="dotsText"
            />
        </div>
    );
};

export default ProductViewer;