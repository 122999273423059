export var parallelConfiguration = {
    "productName": "LShape1",
    "categoryName": "Kitchen",
    "subCategoryName": "LShapeKitchen",
    "enabled": true,
    "isAddedAsNew": true,
    "transform": {
        "position": {
            "x": 0,
            "y": 0,
            "z": 0
        },
        "rotation": {
            "_x": 0,
            "_y": 0,
            "_z": 0,
            "_w": 1
        },
        "scale": {
            "x": 1,
            "y": 1,
            "z": 1
        }
    },
    "productId": "",
    "categoryId": "",
    "originalProductName": "LShape1",
    "productInstanceName": "LShape1",
    "isAttachedToWall": false,
    "isWallMounted": 0,
    "normal": {
        "x": 0,
        "y": 0,
        "z": 1
    },
    "configuration": {
        "BaseShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter3": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter4": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter5": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter6": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter7": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter8": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter9": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter3": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter4": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter5": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter6": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter7": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter3": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter4": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter5": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter6": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter7": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter8": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftBase": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Base": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "Skirting": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "Handle": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "CounterTop": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BackSplash": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseDrawer1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseDrawer2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "TallUnitShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "WallUnitShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "WallUnitShutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "WallUnitBase": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "OpenShelf": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        }
    },
    "moduleConfiguration": {
        "modules": [
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "Corner1Kitchen",
                "moduleType": "Base",
                "subModuleType": "Corner",
                "moduleObjectId": "E878A2B6-447A-439B-B780-3516D71A58E1",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -571.0195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 2,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "BF891838-2587-45DD-9F31-260A911F5C1F",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -571.5195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "0E6003C2-55EB-42FA-BC73-EC86AA580929",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -572.0195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "TallUnit1Kitchen",
                "moduleType": "Tall",
                "subModuleType": "For fridge and freezer",
                "moduleObjectId": "639F874F-52A6-4E0E-A9F4-7135802F99FB",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -573.0605631961012
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 2,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "DAF7A8B0-68EF-4829-B063-E841383E8F74",
                "transform": {
                    "position": {
                        "x": 263.8279129781941,
                        "y": 4176.518847584724,
                        "z": -570.9672658408451
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 0.7071067811865475,
                        "_z": 0,
                        "_w": 0.7071067811865476
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "73348CDA-9D4F-487A-8458-CAA259A831F5",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -572.5195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "Corner1Kitchen",
                "moduleType": "Base",
                "subModuleType": "Corner",
                "moduleObjectId": "FE74F558-A7AA-4D79-A7AB-B1B2E383FE3A",
                "transform": {
                    "position": {
                        "x": 263.81846459960934,
                        "y": 4176.518847584724,
                        "z": -570.0195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 0.7071067811865475,
                        "_z": 0,
                        "_w": 0.7071067811865476
                    },
                    "scale": {
                        "x": 2,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": 1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "C75C7AC3-4FC1-491F-90C7-9734EFDE845C",
                "transform": {
                    "position": {
                        "x": 263.82739198414936,
                        "y": 4176.518847584724,
                        "z": -571.4672658408451
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 0.7071067811865475,
                        "_z": 0,
                        "_w": 0.7071067811865476
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "EBE062F5-D718-4D81-839E-CA753B5D4369",
                "transform": {
                    "position": {
                        "x": 263.81846459960934,
                        "y": 4176.518847584724,
                        "z": -571.9672658408451
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 0.7071067811865475,
                        "_z": 0,
                        "_w": 0.7071067811865476
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "89143974-25E3-4BFE-849A-9B88140BD3FC",
                "transform": {
                    "position": {
                        "x": 263.81846459960934,
                        "y": 4176.518847584724,
                        "z": -572.4672658408451
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 0.7071067811865475,
                        "_z": 0,
                        "_w": 0.7071067811865476
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            }
        ],
        "addons": [],
        "isGroupRotationVertical": false,
        "groupRotation": 0,
        "isViewProductMode": false,
        "allowSpacePlanner": true,
        "allowFinish": true,
        "allowAr": true,
        "allowAddons": true
    }
}