import { IonIcon } from '@ionic/react';
import { cloudUploadOutline, imagesOutline } from 'ionicons/icons';
import $ from "jquery";
import React from 'react';
import { sceneBackgrounds } from '../../../../../utils/customizein3d/modules/customizein3d';
import { deselectModule } from '../../../../../utils/customizein3d/modules/raycasting';
import { showGlobalToast } from '../../../../../utils/UI_methods/global';


const BackgroundModeOptions: React.FC<{
}> = (props) => {



  async function open() {   
    closeBackgroundModelOptions()
    sceneBackgrounds.enable()
    $(`#sceneBackgroundsWindow_RoomBackgroundBG`).click()
    // if(!isPotraitMode() && getWindowWidth() > 480){
    //   customizeBackground()
    // }
    showGlobalToast("Background Mode Activated")

  }

  
  async function uploadImage() {   
    closeBackgroundModelOptions()
    sceneBackgrounds.enable()
    $(`#sceneBackgroundsWindow_ChooseFinishBG`).click()
    $("#uploadBackgroundImageButton").click()
  }

  async function previouslyUploaded() {   
    closeBackgroundModelOptions()
    sceneBackgrounds.enable()
    $(`#sceneBackgroundsWindow_ChooseFinishBG`).click()
  }

  async function closeBackgroundModelOptions() {   
    $("#backgroundModeOptions").fadeOut()
    deselectModule()
  }
  
  
    return(
      <div className="full-height-width config-options-container" id="backgroundModeOptions" style={{position:"fixed"}}>
      <div className='top-right padding10' onClick={closeBackgroundModelOptions}>
        <i className='fas fa-times font-large color-white'></i>
      </div>
      <div className="center-aligned-column full-height-width ">
          <div className="display-flex-row">
               

              <div className='ion-padding center-aligned-column pointer click-effect border bg-color-primary text-center shadow-medium' onClick={()=>{open()}} style={{width:"14rem"}}>
                <div className='pointer-none'>
                  <div className=''>
                    <IonIcon className="color-white marginBottom5" icon={imagesOutline} style={{fontSize:"3rem"}} />
                  </div>
                  <div className='color-white font-normal heading4 marginTop5'>
                    Preset
                  </div>
                </div>
              </div>

              <div className='ion-padding center-aligned-column pointer click-effect marginleft10 border text-center shadow-medium' onClick={()=>{open()}} style={{width:"14rem"}}>
                <div className='pointer-none'>
                  <div className=''>
                    <IonIcon className="color-white marginBottom5" icon={imagesOutline} style={{fontSize:"3rem"}} />
                  </div>
                  <div className='color-white font-normal heading4 marginTop5'>
                    Previously Uploaded
                  </div>
                </div>
              </div>

              <div className='ion-padding center-aligned-column pointer click-effect marginleft10 border text-center shadow-medium' onClick={uploadImage} style={{width:"14rem"}}>
                <div className='pointer-none'>
                  <div className=''>
                    <IonIcon className="color-white marginBottom5" icon={cloudUploadOutline} style={{fontSize:"3rem"}} />
                  </div>
                  <div className='color-white heading4 font-normal marginTop5'>
                    Upload New Image
                  </div>
                </div>
              </div>

              
          </div>
      </div>
  </div>
        
    );
};
 
export default BackgroundModeOptions;
