import { IonIcon } from '@ionic/react';
import { bookmarkOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { createTemplate } from '../../../../../../utils/moodboard/helper';
import Modal from '../../../../../common/shared-components/Modals/Modal';
import $ from "jquery"

  
  const CreateTemplateLink: React.FC<{
    configObj:any
  }> = (props) => {

    const [isOpen,setIsOpen] = useState(false);

   function updatetemplateName(value:string) {
    // settemplateName(value)
    }

    function enterSubmit(event:any) {
      if (event.keyCode === 13) {
        create()
      }
    }

    function create() {
      let name:any = $("#collectionTemplateName").val() || ""
        if(!name.length){
          alert("Please enter template name")
          return
        }
        setIsOpen(false)
        createTemplate(name)
        closeTemplate()
    }

    function openTemplate() {
      $("#collectionTemplateName").val("")
      $("#createTemplateWrapper").addClass("--is-active")
    }
    function closeTemplate() {
      $("#createTemplateWrapper").removeClass("--is-active")
    }
   
    return (
      <React.Fragment>

      <div className="top-left zindex100">
        <div className="display-flex-row auto-arrange-images zindex100 pointer" onClick={openTemplate}>
          <span className="center-aligned-column pointer-none">
            <IonIcon className="font-medium color-primary" icon={bookmarkOutline} />
          </span>
          <span className="heading3 hide-in-mobile pointer-none center-aligned-column marginleft5 color-dark">Save as template</span>
        </div>
      </div>
  
      </React.Fragment>
    );
  };
  
  export default CreateTemplateLink;
  