import React from "react";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import CloseButton from "../../../../../common/ui-components/buttons/CloseButton";
import { showGlobalToast } from "../../../../../../utils/UI_methods/global";

 
const ShareLinkAction: React.FC<{
    link:any
    closeHandle:()=>void
    shareOptionClickHandle:()=>void
    
}> = (props) => {
 

    async function copyToClipboard() {
        try {
          await navigator.clipboard.writeText(`${window.location.origin}/view/${props.link}`);
            showGlobalToast('Coppied!!');
        } catch (err) {
            showGlobalToast('Unable to copy text to clipboard',2000,"error");
        }
      }

 
return (
    <div className="white-card shadow-medium bg-color-light full-height-width padding10 display-flex-column space-between">

        <div className="top-right">
            <CloseButton action={props.closeHandle} classNames="" />
        </div>
        <div className="heading1 color-primary header- ">Share Link</div>

        <div className="full-width">
            <div className="share-options" onClick={props.shareOptionClickHandle}>
                <div className="heading3 font-md-small">Share link via</div>
                <div className="display-flex-row no-wrap marginTop10">
                    <span className="center-aligned-column white-card shadow-light" style={{width:"2.5rem",height:"2.5rem"}}><i className="fab fa-whatsapp color-success font-large"></i></span>
                    <span className="white-card shadow-light center-aligned-column marginleft5" style={{width:"2.5rem",height:"2.5rem"}}><i className="far fa-envelope color-primary font-large"></i></span>
                </div>
            </div>

            <div className="copy-link marginTop10 full-width">
                <div className="heading3 font-md-small">Copy link</div>
                <div className="display-flex-row overflow-hidden no-wrap border padding5 marginTop10 border-primary white-card no-shadow">
                    <div className="center-aligned-column"><i className="fas fa-link color-dark"></i></div>
                    <div className="heading4 marginleft5">{`${window.location.origin}/view/${props.link}`}</div>
                </div>
            </div>

        </div>

       

        <div className="footer- center-aligned-column">
            <Button2
                configObj={{id:""}}
                action={copyToClipboard}
                label={"Copy link"}
                fontAwesomeIcon={"fas fa-share color-white"}
                iconPos=""
                classNames={"no-shadow full-width margin0 text-center bg-color-primary"}
            />
        </div>

        

    </div>   
);
};

export default ShareLinkAction;
