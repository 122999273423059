import {
  IonPage
} from "@ionic/react";
import React from "react";
import "../../../style/admin/productviewer.css";
import "../../../style/store/products.css"
import "../../../style/store/cart.css"
import "../../../style/store/product-details.css"
import ProductDetailsContainer from "./ProductDetailsContainer";
import MainHeader from "./common/MainHeader";
import SubHeader from "./common/SubHeader";

 

const ProductDetails: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {


 
  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white">
        {/* <InnerPageHeader 
          configObj={applicationConfig}
          componentRight={ContentRight}
          componentMiddle={ContentMiddle}
          goBack={()=>goBackToPreviousPage(true)}
          moduleName="productDetails"
        /> */}

        <MainHeader 
        />
        <SubHeader 
        />

        


        <ProductDetailsContainer
          configObj={props.configObj}
          setIsLoadedPageConfiguration={props.setIsLoadedPageConfiguration}
          isLoadedPageConfiguration={props.isLoadedPageConfiguration}
          isLogin={props.isLogin}
        />
   
       
      </div>
      
      :
      <div className="padding10 heading4 color-black font-normal">Unable to load the page. Refresh or go back</div>
      }
        
     
    </IonPage>
  );
};

export default ProductDetails;
