import React from "react";
import { createOrder } from "../../../../../utils/store/product-cart";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import { addItemToLocalStorage } from "../../../../../utils/cache/localstorage";
import { applicationConfig } from "../../../../../utils/methods";

 

const CardPayment: React.FC<{
  callback:()=>void
  price:number
}> = (props) => {

 
 
  return (
    <div className="full-height-width">
        <div className="center-aligned-column full-height-width">
          <div className="padding10">
            <div className="padding5 center-aligned-row"><i className="fab fa-cc-visa color-black" style={{fontSize:"4rem"}}></i></div>
            <div className="marginTop10 heading3 text-center">Please confirm the order after reciving the payment</div>
            <div className="marginTop10 heading1 font-large text-center rupee-sign">Total  {getFormattedPrice(props.price)}</div>
            <div className="marginTop10 heading3 text-center">Company Pvt. Ltd.</div>
          </div>
        </div>

        <div className="bottom-middle ion-padding">
          <Button2
            configObj={{id:""}}
            action={()=>{createOrder(props.callback)}}
            label={"Confirm order"}
            fontAwesomeIcon={"fas fa-times color-danger"}
            iconPos=""
            classNames={"no-shadow margin0 button-large button-warning bg-color-success"}
          />
        </div>
    </div>
  );
};

export default CardPayment;
