import { IonPage } from "@ionic/react";
import axios from "axios";
import { DeviceUUID } from "device-uuid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { BASE_PATH, tokenVerification } from "../../../../../services/api";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import { addItemToLocalStorage } from "../../../../../utils/cache/localstorage";
import { setUserSession } from "../../../../../utils/methods";
import AccessDenied from "../../../../common/modules/access-denied/AccessDenied";
import Loader from "../../../../common/shared-components/main-ui/Loader";

 
const KanvasAuth: React.FC<{
}> = (props) => {


    const parameters:any = useParams()

    const[isAuth,setIsAuth] = useState(true)

    const handleLogin = (data:any) => {
        axios.post(
          `${BASE_PATH}api/signin`,
          {
            username: data.username,
            password: data.password,
            deviceId:new DeviceUUID().get()
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response)
          if(response.data.error){
            showGlobalToast("Something went wrong",2000,"error")
            setIsAuth(false)
            return
          }
          addItemToLocalStorage("isLogin",true)
          addItemToLocalStorage("recentActivities",null)
          
          const user = response.data.user 
          user.isShareMode = false
          setUserSession(response.data.token,user);
          window.location.href = ""
        })
        .catch((error) => {
            setIsAuth(false)
            showGlobalToast("Something went wrong",2000,"error")
        });
      };
   
 

    useEffect(()=>{

        let token = parameters.token
        tokenVerification(token).then(async (data)=>{
            console.log(data)
            handleLogin(data)

        }).catch(err=>{
            console.log(err)
            setIsAuth(false)
            showGlobalToast("Link Expired",2000,"error")
        })

       
    },[])
 
  
    return (
        <IonPage>
        {isAuth?
        <Loader
            loaderType="dots"
            id="mainLoader"
            className="--is-active"
            data={""}
        />        
        :
        <AccessDenied/>
        }
        </IonPage>
    );
};

export default KanvasAuth;
