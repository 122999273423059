import React from "react";
import Accordion from "../../../../common/ui-components/accordion/Accordion";
import Finish from "../../../../common/modules/customizein3d/controls/Finish";
import { applicationConfig } from "../../../../../utils/methods";

 

const Controls: React.FC<{
 
}> = (props) => {


 
  return (
    <div className="accordion-wrapper">
      <Accordion
        label="Choose Finish"
        Content={()=>(
          <Finish
              configObj={applicationConfig}
          />
        )}
        index={1}
        isOpen={false}
      />
    </div>
  );
};

export default Controls;
