import Konva from "konva";
import {
  fitStageIntoParentContainer, removeAnchorsFromOtherImages
} from "./helper";
import { addMultipleSelect } from "./multipleSelect";
import { waitFor } from "../methods";
import { isModulesMode } from "./moodboard";
import { addonsGroup, modulesGroup } from "./modules/sofa";

// export var selectionRectangle = null;

export default class Moodboardconfig {
  stageCanvasMapping: any = {};
  areasList: any[];

  itemActionsStack:Array<any> = []

  constructor() {}

  async createStageAndLayer(containerId: string) {
    let stage = new Konva.Stage({
      container: containerId,
      width: 0,
      height: 0,
    });
    let layer = new Konva.Layer({
      name:"itemsLayer"
    });
    let upperLayer = new Konva.Layer();
    
    let transformer = new Konva.Transformer({
      rotationSnaps: [0, 90, 180, 270],
      resizeEnabled: false,
      opacity:0,
      rotateAnchorOffset:10
    });

    transformer.findOne('.rotater').on('mouseenter', () => {
      stage.content.style.cursor = 'move';
    });

    stage.add(layer);
    stage.add(upperLayer);

    if(isModulesMode){
      layer.add(modulesGroup)
      layer.add(addonsGroup)
    }

    let selectionRectangle = this.addSelectionRect(upperLayer);
    upperLayer.add(selectionRectangle);
    upperLayer.draw()
 
    
    this.stageCanvasMapping[containerId] = {
      stage: stage,
      layer: layer,
      upperLayer: upperLayer,
      selectionRectangle:selectionRectangle,
      transformer:transformer,
      itemsList: [],
    };

    // await waitFor(200)
    this.prepareStage(stage, upperLayer,containerId);

    // setTimeout(() => {
    // }, 500);


    return stage;
  }

  addSelectionRect(layer: any) {
    // add a new feature, lets add ability to draw selection rectangle
    return new Konva.Rect({
      fill: "rgba(0,0,255,0.2)",
      visible:false
    });

  }

  getLayer(containerId: string) {
    return this.stageCanvasMapping[containerId]?.layer;
  }

  
  getSelectionRectangle(containerId: string) {
    return this.stageCanvasMapping[containerId]?.selectionRectangle;
  }

  getUpperLayer(containerId: string) {
    return this.stageCanvasMapping[containerId]?.upperLayer;
  }


  getStage(containerId: string) {
    return this.stageCanvasMapping[containerId]?.stage;
  }

  getTransformer(containerId: string) {
    return this.stageCanvasMapping[containerId]?.transformer;
  }

  setAreasList(areas: Array<any>) {
    this.areasList = areas;
  }

  getAreasList() {
    return this.areasList;
  }

  reset(){
    this.stageCanvasMapping = null
    this.itemActionsStack = []
  }

  add(group: any, containerId: string) {
    // let items = this.stageCanvasMapping[containerId].itemsList
    this.stageCanvasMapping[containerId].itemsList = [
      group,
      ...this.stageCanvasMapping[containerId].itemsList,
    ];
  }

  getCanvasItemsList(containerId: string) {
    return this.stageCanvasMapping[containerId]?.itemsList.sort(
      (a: any, b: any) => (a._id > b._id ? 1 : -1)
    );
  }

  prepareStage(stage: any, layer: any,canvasContainerId:string) {
    // fitStageIntoParentContainer(stage);

    stage.on("dragstart", function (event: any) {
      if (event.target.nodeType === "Group") {
        removeAnchorsFromOtherImages(layer);
      }
    });

    addMultipleSelect(stage,layer,canvasContainerId);

    setTimeout(() => {
      stage = fitStageIntoParentContainer(stage);
    }, 500);

    window.addEventListener("resize", function () {
      // setTimeout(() => {
    // return fitStageIntoParentContainer(stage);
      // }, 500);
    });
  }


  addItemToStack(action:string,data:any){
    let item = {
      action:action,
      itemInfo:data
    }
    this.itemActionsStack.push(item)
  }

  getLastStackItem(){
    if(!this.itemActionsStack.length){
      return null
    }
    return this.itemActionsStack[this.itemActionsStack.length - 1] || null
  }

  resetLayer(){
    let layer = this.getLayer("mainMoodboardCanvasContainer")
    layer.destroyChildren()
    layer.draw()
  }

  removeItemFromStack(){
    this.itemActionsStack.pop()
  }

  getImagesList(canvasContainerId:string = "mainMoodboardCanvasContainer"){
    let layer = this?.getLayer(canvasContainerId)
    let allGroups = layer?.children
    return allGroups?.filter(group=>group.attrs.name === "canvasImageItemGroup")
  }
}
