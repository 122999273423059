import React from "react";
import { expandItemsPanel } from "../../../../../../utils/moodboard/UI_methods";
  
  
  const LeftBottom: React.FC<{
    configObj:any
    currentConfigObject:any
  }> = (props) => {
 
  
  
    return (
      <React.Fragment>

        <div className="bottom-left ion-padding ">
          <div className="hide-in-desktop">
          <div className="customization-customize-button --is-active" >
                <div id="addItemsButtonMobile" className="shadow-light border op-btn op-btn-primary color-white letter-spacing click-effect heading1 letter-spacing"
                  style={{
                    padding: "0.7rem 1.2rem",
                    borderRadius: "20px",
                    fontSize:"0.9rem"
                  }}
                  onClick={expandItemsPanel}
                  >
                  Add <i className="fas fa-plus font-small color-white"></i>
            </div>
          </div>
          </div>

       


        </div>

        

      </React.Fragment>
    );
  };
  
  export default LeftBottom;
  