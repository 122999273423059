import React from "react";
import Button2 from "../buttons/Button2";
import { getSubDomainName } from "../../../../utils/methods";

const SessionExpired: React.FC<{
}> = (props) => {



  function refresh() {
    window.location.reload()
  }

  function Header() {
    return(
      <div className="main-header-wrapper op-header-wrapper position-relative display-flex-row shadow-light no-wrap">
            <div className="header-left-content">
           <div className="full-height padding10 company-logo">
              <img src="assets/images/opus.png" alt=""  className="full-height" />
          </div>
        </div>
      </div>
    )
  }


  return (
    <React.Fragment>
      {getSubDomainName() !== "elevate"?
      <div className={`full-height-width bg-color-light`}>
        <Header/>
        <div className="full-width middle">
          <div className="font-ex-large text-center heading3 store-color-primary">Session Expired!!</div>
          <div className="font-normal marginTop5 text-center heading4">Please Log in Again to Continue</div>
          <div className="center-aligned-column ion-margin-top">
            <Button2
                configObj={{id:""}}
                action={refresh}
                label={"Login"}
                fontAwesomeIcon={"fas fa-camera color-black"}
                iconPos=""
                classNames={"shadow-medium bg-color-primary"}
            />
          </div>
        </div>
      </div>
      :null}
      
    </React.Fragment>
  );
};

export default SessionExpired;
