import React from "react";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import $ from "jquery"
import { showGlobalToast } from "../../../../../../utils/UI_methods/global";
import { STORE } from "../../../../../../utils/store/storeConfiguration";
 

const Footer: React.FC<{
  index:number
  setCurrSelectedWindowIndex:(val:number)=>void
  customizingOptions:any
  selectedOptionIds:Array<any>
}> = (props) => {

 
  function nextStepHandle(index:number,isSkip:boolean = false) {

    if(!props.selectedOptionIds.length && !isSkip){
      showGlobalToast("Please select an option",2000,"error")
      return
    }

    if(index === props.customizingOptions.length - 1){
      $(`.configuration-seletor`).fadeOut(500)
      setTimeout(() => {
        $(`#AdvanceOptionsSummary`).fadeIn(500)
        props.setCurrSelectedWindowIndex(index + 1)
      }, 500);
      return
    }

    $(`#ConfigurationSelector_${index}`).fadeOut(500)
    setTimeout(() => {
      $(`#ConfigurationSelector_${index + 1}`).fadeIn(500)
      props.setCurrSelectedWindowIndex(index + 1)
    }, 500);
  }

  function prevStepHandle(index:number) {
    if(index === 0){
      $(`.configuration-seletor`).fadeOut(500)
      props.setCurrSelectedWindowIndex(0)
      setTimeout(() => {
        $(`#selectOptionsContainer`).fadeIn(500)
        STORE.functionRefs.productCustomizer?.refreshAdvanceOptions()
      }, 500);
      return
    }

    $(`#ConfigurationSelector_${index}`).fadeOut(500)
    setTimeout(() => {
      $(`#ConfigurationSelector_${index - 1}`).fadeIn(500)
      props.setCurrSelectedWindowIndex(index - 1)
    }, 500);
  }

  return (
    <>
    <div className="center-aligned-column full-width position-relative footer-content">
      <div className="display-flex-row  no-wrap padding10">
          <Button2
              configObj={{id:""}}
              action={()=>{prevStepHandle(props.index)}}
              label={"Prev"}
              fontAwesomeIcon={"fas fa-arrow-left color-black"}
              iconPos="left"
              classNames={"no-shadow button-large button-fixed-width text-center"}
            />
          <Button2
              configObj={{id:""}}
              action={()=>{nextStepHandle(props.index)}}
              label={"Next"}
              fontAwesomeIcon={"fas fa-arrow-right color-primary"}
              iconPos="right"
              classNames={"no-shadow button-large button-fixed-width text-center pc-bg-color-primary"}
            />
      </div>

      <div className="right-middle">
        <Button2
          configObj={{id:""}}
          action={()=>{nextStepHandle(props.index,true)}}
          label={"Skip"}
          fontAwesomeIcon={"fas color-black"}
          iconPos=""
          classNames={"no-shadow button-small text-center"}
        />
    </div>
    
    </div>

    
    </> 
  );
};

export default Footer;
