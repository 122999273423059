import React, { useEffect, useState } from "react";
import { displayThumbnails } from "../../../../../../utils/UI_methods/global";
import { getArrayOfDistinctValues } from "../../../../../../utils/methods";
import { MaterialAndPart, STORE, SofaConfiguration, SofaPart, getCategoryPartsList } from "../../../../../../utils/store/storeConfiguration";
import Options from "./Options";

 

const MaterialsPartMapping: React.FC<{
  productConfiguration:SofaConfiguration
  resetHandle:()=>void
  product:any
}> = (props) => {

  
  const[isOpenOptions,setIsOpenOptions] = useState(false)

  const[materials] = useState(STORE.data.materialList.splice(0,3))
  const[selectedMaterials,setSelectedMaterials] = useState(props.productConfiguration.materials)
  const[currMaterialAndPart,setCurrMaterialAndPart] = useState(null)
  const[SofaParts,setSofaParts] = useState([])
  const[selectedOptionIds,setSelectedOptionsIds] = useState([])

  useEffect(()=>{
    setTimeout(() => {
      displayThumbnails("",null)
    }, 500);
    createSofaParts()
  },[materials])


  function createSofaParts() {
    let category = props.product?.category_name
    let sofaParts = getCategoryPartsList(category)

    const selectedIds = []
    const materialPartsMappingList = props.productConfiguration.materials
    for (const currMaterialPart of materialPartsMappingList) {
      const ids = getArrayOfDistinctValues(currMaterialPart.partNames,"id")
      if(ids.length){
        selectedIds.push(...ids)
      }
    }
    if(selectedIds.length){
      setSofaParts(sofaParts.filter(currPart => !selectedIds.includes(currPart.id)))
    }else{
      setSofaParts(sofaParts)
    }
  }
 

  function confirmSelection(ids:Array<any>) {
    currMaterialAndPart.partNames = SofaParts.filter(currPart => ids.includes(currPart.id))
    setSofaParts(SofaParts.filter(currPart => !ids.includes(currPart.id)))
    setIsOpenOptions(false)
  }

 

  return (
    <div className="bg-color-light full-height-width">
        <div className="white-card shadow-light customization-options  overflow-y-scroll position-relative full-height-width">
            <div className="display-flex-row no-wrap">

              <div className="width30"><img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/orders/fabricmapping/Sofa/Sofa.svg" alt="" /></div>
              <div className="width70">
                {selectedMaterials.map((materialAndPart:MaterialAndPart,index:number)=>{
                  return(
                    <div className="position-relative overflow-x-scroll marginTop5">
                      <div className="display-flex-row padding5 no-wrap">
                        <Options selectedOptionIds={selectedOptionIds} setSelectedOptionsIds={setSelectedOptionsIds} confirmHandle={confirmSelection} SofaParts={SofaParts} materailAndParts={materialAndPart}/>
                      </div>
                    </div>
                  )
                })
              }

              </div>
              
               
            </div>
            
      </div>
        
       
    </div>
  );
};

export default MaterialsPartMapping;
