import { awsLambdaApiTrigger, getProductsList } from "../../services/api"
import { applicationConfig, stringWithoutWhiteSpace } from "../methods"
import { showGlobalToast } from "../UI_methods/global"
import { createProductForm } from "./createProductForm"


export async function updateProductTarFile(prefix:string,fileName:string) {
  
    let myconfig: any = {
      requestId: null,
      prefix:prefix,
      fileName:fileName
    }
  
    let timestamp = Number(new Date())
  
    let requestId = `${applicationConfig?.clientName}${timestamp}`
    //For polling the messages from s3
    myconfig.requestId = requestId
  
  
    return new Promise(async (resolve, reject) => {
      //   updateLoaderProgress("mainLoader",100,100,"Creating model")
      await awsLambdaApiTrigger(myconfig,"createTar").then(async data => {
          console.log(myconfig)
          console.log(data)
        await applicationConfig.awsConfig.pollForCreateTarMessages(requestId)
        resolve(data)
        // updateLoaderProgress("mainLoader",100,100,"Generating link")
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }


  export async function uploadProductImage(productDetails:createProductForm) {
    let product = productDetails
    let galleryImages = productDetails.galleryImages
    let productName = stringWithoutWhiteSpace(productDetails?.productName)
    let fileName = productName + ".png"
    let prefix = stringWithoutWhiteSpace(`productImages/${applicationConfig?.clientName}/${product.categoryName}/${product.subCategory}/${product.productName}/`)
    let albumName = "productImages"
    var albumPhotosKey = encodeURIComponent(albumName) + "/" + applicationConfig?.clientName + "/" + product.categoryName + "/" + product.subCategory + "/" + stringWithoutWhiteSpace(product?.productName) + "/" 
    
    let promises = []

    promises.push(await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,product.productImageFile,fileName)) 
    //Upload images other then default image
    galleryImages = galleryImages.filter((currImage:any) => currImage.name !== product.productImageFile.name)
    for (let i = 0 ; i < galleryImages.length ; i++) {
      const currImage = galleryImages[i]
      promises.push(await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,currImage,`${productName}_${i+1}.png`)) 
    }
    await Promise.all(promises).then(async (data)=>{
      fileName = stringWithoutWhiteSpace(`${product.productName}.tar.gz`)
      await updateProductTarFile(prefix,fileName) 
    })
  }


  export async function updateProductsList() {
    await getProductsList().then(data=>{
      applicationConfig.updateData("productsList",data)
    })
  }

  export function filterCategoryForStore(categories:any) {
    return categories.filter(currCategory => currCategory.category_name === "Sofa" 
    || currCategory.category_name === "Cot" || currCategory.category_name === "Recliner"  || currCategory.category_name === "ArmChair"
    || currCategory.category_name === "Mattress" || currCategory.category_name === "Storage" || currCategory.category_name === "SofaBed"
    ||currCategory.category_name === "UpholsteredBed" ||currCategory.category_name === "Other")
    
  }
