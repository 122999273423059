import React, { useEffect, useState } from "react";
import { applicationConfig, getFilteredArray, getObjectArrayOfDistictValues, getObjectByParameter } from "../../../../utils/methods";
import $ from "jquery"


const Collections: React.FC<{
  configObj: any;
  finishDetails:any
}> = (props) => {

  const[currSelectedCollection,setCurrSelectedCollection] = useState("")
  const[collectionList,setCollectionList] = useState([])
  const[companyList,setCompanyList] = useState([])
  const[materialTypeList,setMaterialTypeList] = useState([])


  function handleCollectionChange(event) {
    let collection:any = getObjectByParameter(props.configObj.data.allCollections,"id",Number(event.target.value))
    setCurrSelectedCollection(collection.collection_name)
    props.finishDetails["collectionId"] = event.target.value
    props.finishDetails["collectionName"] = collection.collection_name
  }

  
  function handleCompanyChange(event) {
    let text = $.trim($(event.target).children("option:selected").text())
    props.finishDetails["companyId"] = event.target.value
    props.finishDetails["companyName"] = text
  }

  
  function handleMaterialTypeChange(event) {
    let text = $.trim($(event.target).children("option:selected").text())
    props.finishDetails["materialTypeId"] = event.target.value
    props.finishDetails["materialType"] = text
  }



  useEffect(()=>{
    let collections =  getObjectArrayOfDistictValues(applicationConfig?.data.allCollections,"collection_name")
    if(collections?.length){
      setCollectionList(collections)
      let currCollection = collections[0].collection_name
      setCurrSelectedCollection(currCollection)
  
      props.finishDetails["collectionId"] = collections[0].id
      props.finishDetails["collectionName"] = collections[0].collection_name
    }
    setMaterialTypes()
  },[props.configObj])

  useEffect(()=>{
    if(currSelectedCollection!=""){
      let companies =  getObjectArrayOfDistictValues(applicationConfig?.data.materialCompaniesList,"company_name")
      setCompanyList(companies)
      setMaterialTypes()
    }
  },[currSelectedCollection])
 

  function setMaterialTypes() {
    let materialTypes =  getObjectArrayOfDistictValues(applicationConfig?.data?.allMaterialTypes,"material_type")
    setMaterialTypeList(materialTypes) 
    props.finishDetails["materialTypeId"] = materialTypes[0].id
    props.finishDetails["materialType"] = materialTypes[0].material_type
  }

  return (
    <React.Fragment>
      <div className="center-aligned-column full-height-width">
        
        <div className="finish-details-container">
          <div className="heading2 font-normal color-black text-center">Select Company name, Collection Name & Material Type</div>
          <div className="display-flex-row padding10">



          <div className="form-label-input-container">
                <div className="label-container">
                  <div className="label">
                    Company Name <span className="mandatory">*</span>
                  </div>
                </div>
                <div className="input-container">
                  <select
                    className="input"
                    name=""
                    id="companyName"
                    onChange={handleCompanyChange}
                  >
                  {companyList?.map(
                    (company: any, index: number) => {
                      return (
                        <option value={company.id} key={index}>
                          {company.company_name}
                        </option>
                      );
                    }
                  )}
                </select>
                </div>
              </div>


              <div className="form-label-input-container">
                <div className="label-container">
                  <div className="label">
                    Collection Name <span className="mandatory">*</span>
                  </div>
                </div>
                <div className="input-container">
                  <select
                    className="input"
                    name=""
                    id="collectionName"
                    onChange={handleCollectionChange}
                  >
                  {collectionList?.map(
                    (collection: any, index: number) => {
                      return (
                        <option value={collection.id} key={index}>
                          {collection.collection_name}
                        </option>
                      );
                    }
                  )}
                </select>
                </div>
              </div>


          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">
                Material Type<span className="mandatory">*</span>
              </div>
              <div className="hint"></div>
            </div>
            <div className="input-container">
              <select
                className="input"
                name=""
                id="materialType"
                onChange={handleMaterialTypeChange}
                >
                {materialTypeList?.map(
                  (materialType: any, index: number) => {
                    return (
                      <option value={materialType.id} key={index}>
                        {materialType.material_type}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Collections;
