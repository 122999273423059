import { awsLambdaApiTrigger } from "../../services/api"
import AwsConfig from "../aws-sdk/AwsConfig"
import { applicationConfig } from "../methods"
import $ from "jquery"
import { displayUploadedImage } from "../inventory/inventory"
import "../../components/style/rembg/rembg.css"
import { hideComponentLoader, showComponentLoader } from "../UI_methods/global"
import { checkFromCache, getAndSaveImageToCache, getBlobFileFromCache } from "../cache/cache"

let awsConfig = new AwsConfig()


export async function removeBackground(fileName: string, res: string) {
  let myconfig: any = {
    requestId: null,
    configuration: {}
  }

  let timestamp = Number(new Date())

  let requestId = `${applicationConfig?.clientName}${timestamp}`
  //For polling the messages from s3
  myconfig.requestId = requestId

  myconfig.configuration.fileName = fileName
  myconfig.resX = res
  myconfig.resY = res
  myconfig.configuration.requestId = requestId

  myconfig.fileName = fileName

  return new Promise(async (resolve, reject) => {
    //   updateLoaderProgress("mainLoader",100,100,"Creating model")
    await awsLambdaApiTrigger(myconfig,"rembg").then(async data => {
        console.log(data)
      await awsConfig.pollForRembgMessages(requestId)
      resolve(data)
      // updateLoaderProgress("mainLoader",100,100,"Generating link")
    }).catch(err => {
      console.log(err)
      reject(err)
    })
  })
}


export function addEventListenerOnDragArea(setSelectedFile: (val: any) => void) {
  let dropContainer = document.getElementById("dragFileInput")

  if (dropContainer) {
    dropContainer.ondragover = dropContainer.ondragenter = function (evt) {
      evt.preventDefault();
      $(".drop-hint-contaner").addClass("--is-active")
    };

    dropContainer.ondrop = function (evt) {
      // pretty simple -- but not for IE :(
      setSelectedFile(evt.dataTransfer.files[0]);
      displayUploadedImage(evt.dataTransfer.files[0], "withbgImage")
      evt.preventDefault();
      $(".drop-hint-contaner").removeClass("--is-active")
    };
  }
}



export const processImage = (selectedFile: any, resolution: string) => {

  return new Promise((resolve, reject) => {
    if (selectedFile) {
      awsConfig.uploadRembgImage(selectedFile.name, selectedFile).then(async (data) => {
        console.log(data);
        await removeBackground(selectedFile.name, resolution)
          .then(async (data) => {
            console.log(data);
            let key = "rembg/withoutbg/result.png";
            getAndSaveImageToCache(key).then((data) => {
              resolve(data);
            })
              .catch((err) => {
                console.log(err)
                reject(err);
              });
          })
          .catch((err) => {
            console.log(err)
            reject(err);
          });
      })
        .catch((err) => {
          console.log(err)
          reject(err);

        });
    } else {
      reject("No file selected");
    }
  })
};





export function displayWithoutBgImage() {
  let key = "rembg/withoutbg/result.png";
  let image = $("#withoutbgImage");
  checkFromCache(key)?.then((url: any) => {
    image.attr("src", url);
    hideComponentLoader("rembgLoader");
  })
  .catch((err) => {
    image.attr("src", "");
    console.log(err);
  });
}

export function getRemovedBgImageFile(fileName:string) {
  return new Promise((resolve,reject)=>{
    let key = "rembg/withoutbg/result.png";
    getBlobFileFromCache(key)?.then((blob: any) => {
      console.log(blob)
      // var resizedImage = dataURLToBlob(url);
      resolve(new File([blob],fileName))
    })
    .catch((err) => {
      reject(err);
    });
  })
 
}



export function openResolutionPanel() {
  $("#rembgResolutionsContainer").addClass("--is-active")
}

export function closeResolutionPanel() {
  $("#rembgResolutionsContainer").removeClass("--is-active")
}


export function tabsInitialState() {
  $(".rembg-tab").removeClass("--is-active")
  $("#rembgInitialTab").addClass("--is-active")
}

export function tabsRemovedBgState() {
  $(".rembg-tab").removeClass("--is-active")
  $("#rembgRemovedBgTab").addClass("--is-active")
}


export function getResizedImage(fileName:string) {

  return new Promise((resolve,reject)=>{
    let imageSrc = document.getElementById("withbgImage").getAttribute("src")
    var image = new Image();
    image.onload = function (imageEvent) {
        // Resize the image
        var canvas = document.createElement('canvas'),
            max_size = 720,// TODO : pull max size from a site config
            width = image.width,
            height = image.height;
        if (height > max_size) {
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          var dataUrl = canvas.toDataURL('image/jpeg');
          var resizedImage = dataURLToBlob(dataUrl);
          canvas.remove()
          resolve(new File([resizedImage],fileName))
        } else {
          reject("Image height " +height+ " skip resizing")
        }
    }
    image.src = imageSrc;
  })
  
  
}

var dataURLToBlob = function(dataURL:any) {
  var BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = parts[1];

      return new Blob([raw], {type: contentType});
  }

  var parts = dataURL.split(BASE64_MARKER);
  var contentType = parts[0].split(':')[1];
  var raw:any = window.atob(parts[1]);
  var rawLength = raw.length;

  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], {type: contentType});
}


export const warmUpLambda = () => {

  return new Promise((resolve, reject) => {
    let resolution = "320"

    getTestFile().then((file:any)=>{
      awsConfig.uploadRembgImage(file.name, file).then(async (data) => {
        console.log(data);
        await removeBackground(file.name, resolution)
          .then(async (data) => {
            console.log(data);
            let key = "rembg/withoutbg/result.png";
            getAndSaveImageToCache(key).then((data) => {
              resolve(data);
            })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      })
        .catch((err) => {
          reject(err);
        });
    })
  })
};


function getTestFile() {
  return new Promise((resolve,reject)=>{

    let image = new Image()
    image.onload = function(event) {
      var canvas = document.createElement('canvas'),
      width = image.width,
      height = image.height;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      var dataUrl = canvas.toDataURL('image/jpeg');
      var resizedImage = dataURLToBlob(dataUrl);
      canvas.remove()
      
      resolve(new File([resizedImage],"lamda.jpg"))
    }
    image.src = "assets/images/lambda.jpg"
  })
}
