import React, { useEffect, useState } from "react";
import { createProductForm } from "../../../utils/products/createProductForm";
import "../../style/form/form.css";
import Home from "./Home";
import FinishForm from "./finish/FinishForm";
import ProductForm from "./product/ProductForm";
import Review from "./product/Review";


const Inventory: React.FC<{
  configObj: any;
  closeModal: (value: boolean) => void;
  currState:string
  product:any
  finishId:number
  isUpdateMode?:boolean
}> = (props) => {

  const [currState, setCurrState] = useState(props.currState);
  const [productDetails, setProductDetails] = useState(null);

  useEffect(()=>{
    if(props.product){
      const productDetails = new createProductForm(props.product) 
      setProductDetails(productDetails)
      setCurrState("Product")
    }else{
      setProductDetails(new createProductForm())
    }
  },[props.product])



  function CreateNewProject() {
    switch (currState) {
      case "Home":
        return (
          <Home
            configObj={props.configObj}
            setCurrState={setCurrState}
            closeModal={props.closeModal}
          />
        );

        break;

      case "Product":
        return (
          <ProductForm
            configObj={props.configObj}
            setCurrState={setCurrState}
            closeModal={props.closeModal}
            setProductDetails={setProductDetails}
            productDetails={productDetails}
            isUpdateMode={props.isUpdateMode}
          />
        );

        case "Finish":
        return (
          <FinishForm
            configObj={props.configObj}
            setCurrState={setCurrState}
            closeModal={props.closeModal}
            currState={currState}
          />
        );

      case "Review":
        return (
          <Review
            configObj={props.configObj}
            setCurrState={setCurrState}
            closeModal={props.closeModal}
            productDetails={productDetails}
            setProductDetails={setProductDetails}
            isUpdateMode={props.isUpdateMode}
          />
        );

        
      // case "ReviewFinish":
      //   return (
      //     <ReviewFinish
      //       configObj={props.configObj}
      //       setCurrState={setCurrState}
      //       closeModal={props.closeModal}
      //       finishDetails={finishDetails}
      //       setFinishDetails={setFinishDetails}
      //     />
      //   );
      default:
        return <div></div>;
    }
    return <div></div>;
  }

  return <CreateNewProject />;
};

export default Inventory;
