import React, { useState } from "react";
import { addToCartHandle } from "../../../../../utils/store/product-cart";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import StartCustomerSession from "../customer/StartCustomerSession";

 

const AddToCart: React.FC<{
  currProduct:any
  onAdd:()=>void
}> = (props) => {

  const[productDetails] = useState(props.currProduct)
  const[isOpenStartSession,setIsOpenStartSession] = useState(false)

  function addToCart() {
    if(!STORE.activeCustomer){
      setIsOpenStartSession(true)
      return
    }
    addToCartHandle(productDetails,props.onAdd)
  }
 


  return (
    <div className="display-flex-row">
      {/* <div className="width50 center-aligned-column border">
         <div className="center-aligned-column">
              <div className='heading3 price-text color-black rupee-sign font-medium' id="priceContainer">Rs. 19000.00</div>
          </div>
      </div> */}
      <StartCustomerSession isOpen={isOpenStartSession} setIsOpen={setIsOpenStartSession} createCustomerCallback={()=>{}}/>
      <div className="full-width">
        <Button2
          configObj={{id:""}}
          action={()=>{addToCart()}}
          label={`Add to cart`}
          fontAwesomeIcon={"fas fa-cart-plus color-white"}
          iconPos=""
          classNames={"no-shadow button-medium margin0 store-bg-color-primary"}
        />
      </div>
    </div>
  );
};

export default AddToCart;
