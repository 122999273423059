import {
  IonIcon
} from "@ionic/react";
import {
  search
} from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useState } from "react";


const ProductForm: React.FC<{
  selectedCategories: any;
  selectedAreas:any
}> = (props) => {


    const[currSelectedArea,setCurrSelectedArea]:any = useState(null) 
    const[categories,setCategories]:any = useState(null) 
    useEffect(()=>{
        if(props.selectedAreas){
            let area = props.selectedAreas[0].area_name
            setCurrSelectedArea(area)
        }
    },[props.selectedAreas])

    useEffect(()=>{

        if(currSelectedArea){
            setCategories(props.selectedCategories[currSelectedArea])
            console.log(props.selectedCategories[currSelectedArea])
        }

    },[currSelectedArea])



  const Filters = () => {
    return (
      <React.Fragment>
        <div className="filter">
          <select>
            <option value="0">Product Type</option>
            <option value="1">3 Seater</option>
            <option value="2">L Shape</option>
          </select>
        </div>
        <div className="filter">
          <select>
            <option value="0">Budget</option>
            <option value="1">20K-25K</option>
            <option value="2">30K-35K</option>
          </select>
        </div>
        <div className="filter">
          <div className="search-container">
            <input placeholder="Search Product" className="search"></input>
            <IonIcon
              className="icon center-aligned-column"
              icon={search}></IonIcon>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const tempData = [
    "image1",
    "image2",
    "image3",
    "image3",
    "image3",
    "image3",
    "image3",
    "image3",
    "image3",
    "image3",
    "image3",
    "image3",
    "image3",
    "image3",
  ];

  const ProductImages = () => {
    return (
      <React.Fragment>
        <div className="product-images-container">
          {tempData?.map((area: any, index: any) => {
            let classNames = [];
            classNames.push("");
            //   if (area.area_name === currArea) {
            //     classNames.push("--is-active");
            //   }
            let classNamesAreaImage = ["area-image", "custom-card"];
            // if(checkIfSelected(area.area_name)){
            //   classNamesAreaImage.push("--is-active")
            // }
            return (
              <div className={classNames.join(" ")}>
                <img
                  src="/assets/images/placeholder.png"
                  alt=""
                  width="100%"
                  height="100%"
                />
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };
 

  const Categories = () => {
    return (
      <React.Fragment>
           {categories?.map((category:any,index:number)=>{
                return(
                    <React.Fragment>
                        <div className="center-aligned-column">{category}</div>
                    </React.Fragment>
                )
            })}
      </React.Fragment>
    );
  };


  const updateCurrArea = (event:any,areaName:string) => {
    setCurrSelectedArea(areaName)
    $(event.target).toggleClass("--is-active")
  }

  const Areas = () => {
      return(
          <React.Fragment>
            {props.selectedAreas?.map((area:any,index:number)=>{
                return(
                    <div className="configuration-area" onClick={(e)=>updateCurrArea(e,area.area_name)} key={index}>{area.area_name}</div>
                )
            })}

          </React.Fragment>
      )
  }
  return (
    <React.Fragment>
      <div className="configuration-area-container">
        <Areas/>
      </div>
      <div className="custom-row categories-action-container">
        <div className="categories-container">
          <Categories />
        </div>
        <div className="actions-container">
          <div className="filters-container custom-row">
            <Filters />
          </div>
          <div className="filtered-content-wrapper ion-padding">
            <ProductImages />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductForm;
