import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { checkFromCache } from "../../../../utils/cache/cache";
import { Init, setCustomizein3dConfiguration } from "../../../../utils/customizein3d/modules/customizein3d";
import { applicationConfig, stringWithoutWhiteSpace, waitFor } from "../../../../utils/methods";
import { getProductImageUrl } from "../../../../utils/moodboard/helper";
import { updateCartIconCounter } from "../../../../utils/store/product-cart";
import { initProductDetails, productDetails } from "../../../../utils/store/productDetails";
import { STORE } from "../../../../utils/store/storeConfiguration";
import Button2 from "../../../common/ui-components/buttons/Button2";
import ProductImage from "../../../common/ui-components/elements/ProductImage";
import Controls from "./customizein3d/Controls";
import Breadcrumb from "./product-info/Breadcrumb";
import ProductInfo from "./product-info/ProductInfo";

 

const ProductDetailsContainer: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {
  
  const parameters:any = useParams();

  const[is360ImagesAvailable,setIs360ImagesAvailable] = useState(productDetails?.is360ImagesAvailable)
  const[currProduct,setCurrProduct] = useState(productDetails?.product)
  const[productName,setCurrProductName] = useState(productDetails?.product.product_name)

  const[isCustomizeIn3d,setIsCustomizein3d] = useState(false)


  useEffect(()=>{
      // let urlPath = window.location.pathname
      // let tempArray = urlPath.split("/") 
      // let currProductName = tempArray[tempArray.length - 1]
      let currProductName = parameters.sku

      if(!props.isLoadedPageConfiguration && props.isLogin || (currProduct?.product_name !== currProductName)){
        let productsList = props.configObj?.data.productsList || []
        let product = productsList.find(currProduct => stringWithoutWhiteSpace(currProduct.product_name) === stringWithoutWhiteSpace(currProductName))
        if(product){
          setCurrProduct(product)
          setCurrProductName(product.product_name)
  
          if(currProductName === "Dolce"){
              initProductDetails(product,true)
          }else{
              initProductDetails(product,false)
              displayProductImage(product)
          }
          updateCartIconCounter(STORE.cartItems?.length)
        }
        props.setIsLoadedPageConfiguration(true)
        applicationConfig.setFunctionRef("productDetails",{updateProduct:updateProduct})
      }
  },[parameters.sku])

  function updateProduct(product:any) {
    if(product.product_name === "Dolce"){
      setIs360ImagesAvailable(true)
      initProductDetails(product,true)
    }else{
      setIs360ImagesAvailable(false)
      initProductDetails(product,false)
      displayProductImage(product)
    }
    setCurrProduct(product)
    setCurrProductName(product.product_name)
  }

 




  async function displayProductImage(product:any) {
    await waitFor(500)
      let imageUrlKey = getProductImageUrl(
        props.configObj?.BASE_URL_ASSETS,
        props.configObj?.clientName,
        product.product_name,
        product.category_name,
        product.sub_category_name
      );
      let image = document.querySelector("#productImage");
      await checkFromCache(imageUrlKey)
        ?.then((url: any) => {
          image?.setAttribute("src", url);
        })
        .catch((err) => {
          image?.setAttribute("src", "assets/images/placeholder.png");
        });
  }
 




  function Icons() {
    return(
      <div className="top-left">
        <div className="center-aligned-column icon-container">
          <i className="fas fa-expand icon"></i>
        </div>
        <div className="center-aligned-column icon-container">
          <i className="far fa-share-square icon"></i>
        </div>
      </div>
    )
  }

 
  function Gallery() {
    return(
     
      <div className="gallery-wrapper padding10 center-aligned-column">
        <div className="display-flex-row container full-height no-wrap" id="galleryImagesContainer">
        <div className="full-height pointer image-container" data-value="1">
          <img src="assets/images/gallery_placeholder.png" id="gallery-image-1" className="full-height gallery-image pointer-none alt= --is-active" />
        </div>
      
        <div className="full-height pointer image-container" data-value="3">
          <img src="assets/images/gallery_placeholder.png" id="gallery-image-3" className="full-height gallery-image pointer-none alt=" />
        </div>
      
        <div className="full-height pointer image-container" data-value="6">
          <img src="assets/images/gallery_placeholder.png" id="gallery-image-6" className="full-height gallery-image pointer-none alt=" />
        </div>
      
        <div className="full-height pointer image-container" data-value="9">
          <img src="assets/images/gallery_placeholder.png" id="gallery-image-9" className="full-height gallery-image pointer-none alt=" />
        </div>
      
        <div className="full-height pointer image-container" data-value="12">
          <img src="assets/images/gallery_placeholder.png" id="gallery-image-12" className="full-height gallery-image pointer-none alt=" />
        </div>

      </div>
    </div>
    )
  }




 

  async function enableCustomizein3d() {
    setIsCustomizein3d(true)
    await waitFor(200)
    Init(props.configObj)
    setCustomizein3dConfiguration(currProduct,false)
  }

  async function disableCustomizein3d() {
    setIsCustomizein3d(false)
    updateProduct(currProduct)
  }


  function Customizein3dButton(){
    return(
      <div className="marginTop10 display-none">
        <Button2
          configObj={{id:""}}
          action={()=>{enableCustomizein3d()}}
          label={"View in 3D"}
          fontAwesomeIcon={"fas fa-cube color-warning"}
          iconPos="left"
          classNames={"no-shadow margin0 cart-button cart-button-warning-outline"}
        />
      </div>
    )
  }

  function Close3dButton(){
    return(
      <div className="top-right">
        <Button2
          configObj={{id:""}}
          action={()=>{disableCustomizein3d()}}
          label={""}
          fontAwesomeIcon={"fas fa-times color-warning"}
          iconPos="left"
          classNames={"shadow-medium margin0 cart-button cart-button-warning-outline"}
        />
      </div>
    )
  }

 

 
  return (
      <div className="product-details-wrapper padding-container position-relative full-width">
          
          <div className="canvas-controls-container position-relative">
          
          <div className="image border canvas-container padding10 center-aligned-column">
          <Icons/>
          {isCustomizeIn3d?
            <>
            <div className="customize-canvas-wrapper">
                <div className="position-relative full-height overflow-hidden" id="3dCustomizerWrapper"></div>
              </div>
              <Close3dButton/>
            </>
          :          
          is360ImagesAvailable?
            <Customizein3dButton/>
          :
          <>
            <ProductImage product={currProduct}/>
            <Customizein3dButton/>
            <Gallery/>
          </>
           
          }

          </div>


          
          <div className="controls-container">
            <Breadcrumb product={currProduct}/>
            <ProductInfo product={currProduct}/>
            <div className="ion-margin-top">
              {isCustomizeIn3d?
                <Controls/>
              :null}
            </div>
            {/* <AddToCart/> */}
            {/* <ProductDescription/> */}
          </div>
        </div>

        </div>
  );
};

export default ProductDetailsContainer;
