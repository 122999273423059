import React, { useEffect, useState } from "react";
import { hideComponentLoader, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { getRandomNumber } from "../../../../../utils/products/productviewer_shopify.js";
import { Cot_Storage_Options, Cot_Variants, Cot_length } from "../../../../../utils/store/customization-options-data/cot";
import { QualityConfiguration, SofaVariantsConfiguration } from "../../../../../utils/store/customization-options-data/sofa";
import { CotConfiguration, ReclinerConfiguration, STORE, SofaConfiguration } from "../../../../../utils/store/storeConfiguration";
import Modal from "../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import SofaForm from "./SofaForm";
import CotForm from "./CotForm";
import { Reclining_Options } from "../../../../../utils/store/customization-options-data/recliner";
import ReclinerForm from "./ReclinerForm";
 


 
const AddConfig: React.FC<{
    isOpen:boolean
    setIsOpen:(val:boolean)=>void
    product:any
}> = (props) => {

    const[alertId] = useState("createLeadAlertId")
    const[allConfiguratons,setAllConfigurations] = useState([])
    const[currCategory,setCurrCategory] = useState("")

    useEffect(()=>{
      let category = props.product?.category_name
      if(category === "Sofa"){
        createConfigForSofa()
      }
      if(category === "Cot"){
        createConfigForCot()
      }
      if(category === "Recliner"){
        createConfigForRecliner()
      }
      setCurrCategory(category)

    },[props.product])


    function createConfigForSofa() {
      let allconfig = []
      // for (const quality of QualityConfiguration) {
          // for (const variantSize of variantSizes) {
              for (const variant of SofaVariantsConfiguration) {
                  let config = new SofaConfiguration(null)
                  config.variant.variantName = variant.name
                  config.price = getRandomNumber(15000,35000)
                  allconfig.push(config)
              }
          // }
      // }
      setAllConfigurations(allconfig) 
    }

    function createConfigForCot() {
      let allconfig = []
      // for (const quality of QualityConfiguration) {
          // for (const variantSize of variantSizes) {
            for (const currLength of Cot_length) {
              for (const currStorageOption of Cot_Storage_Options) {
                for (const variant of Cot_Variants) {
                  let config = new CotConfiguration(null)
                  config.variant.variantName = variant.name
                  // config.quality = quality.name
                  config.length = currLength.name
                  config.price = getRandomNumber(15000,35000)
  
                  config.storage.name = currStorageOption.name
                  if(currStorageOption.options?.length){
                    for (const option of currStorageOption.options) {
                        config.storage.type = option
                        allconfig.push(config)
                    }
                  }else{
                      allconfig.push(config)
                  }
                }
              }
            }
            

              
          // }
      // }
      setAllConfigurations(allconfig) 
    }

    function createConfigForRecliner() {
      let allconfig = []
      for (const currOption of Reclining_Options) {
          let config = new ReclinerConfiguration(null)
          config.recliningOption = currOption.name
          config.price = getRandomNumber(15000,35000)
          allconfig.push(config)
      }
      setAllConfigurations(allconfig) 
    }

    async function submitHandle() {
      let configurations = []

      for (const configuration of allConfiguratons) {
        configurations.push(JSON.stringify(configuration))
      }
      
      STORE.storeApi.pricing.add(props.product.product_id,configurations,0).then(async (data:any)=>{
        if(!data.data.error){
            props.setIsOpen(false)
            hideComponentLoader("storeLoader")
            showGlobalToast("Created",3000)
        }else{
          showGlobalToast("Internal server error",3000,"error")
          hideComponentLoader("storeLoader")
        }
      })
    }

    

    function Footer() {
        return(
            <div className="display-flex-row">
                <Button2
                    configObj={{id:""}}
                    action={submitHandle}
                    label={"Save"}
                    fontAwesomeIcon={"fas fa-times color-black"}
                    iconPos=""
                    classNames={"no-shadow bg-color-primary"}
                />
                <Button2
                    configObj={{id:""}}
                    action={()=>{props.setIsOpen(false)}}
                    label={"Go back"}
                    fontAwesomeIcon={"fas fa-times color-black"}
                    iconPos=""
                    classNames={"no-shadow bg-color-light"}
                />
            </div>
        )
    }



    function Form() {
      return(
        <div className="full-height-width">
          {currCategory === "Sofa"?
              <SofaForm allConfiguratons={allConfiguratons} alertId={alertId} isUpdate={false}/>
          :
          currCategory === "Cot"?
              <CotForm allConfiguratons={allConfiguratons} alertId={alertId} isUpdate={false}/>
          :
          currCategory === "Recliner"?
              <ReclinerForm allConfiguratons={allConfiguratons} alertId={alertId} isUpdate={false}/>
          :null
          }
        </div>
      )
    }
 
  return (

    <>

    <Modal
        isOpen = {props.isOpen}
        Content = {()=>(
          <Form/>
        )}
        onDismiss = {()=>props.setIsOpen(false)}
        heading={"Create Item"}
        classNames="large-x-modal"
        id=""
        FooterContent={Footer}
    />
    </>
  );
};

export default AddConfig;
