import $ from "jquery";
import React, { useEffect } from "react";

const SelectWallsHint: React.FC<{
    currSelectedArea:string
}> = (props) => {

  
  useEffect(()=>{
    show()
  },[props.currSelectedArea])

  function show() {
    $("#wallSelectionHintWindow").removeClass("display-none")
  }
 
  function hide() {
      $("#wallSelectionHintWindow").addClass("display-none")
  }
  

  return (
    <>
    <div id="wallSelectionHintWindow" className='full-height-width display-none top-left black-fade-background center-aligned-column' style={{position:"fixed",backgroundColor:"rgba(0,0,0,0.7)",zIndex:1002}}>
        <div className=''>
            <div className='heading font-large padding5 text-center color-white' style={{letterSpacing:"0.8px"}}>Select walls for {props.currSelectedArea} </div>
            {/* <div className='heading4 text-center font-small color-white' style={{letterSpacing:"0.7px"}}>Click on the wall to select or deselect</div> */}
            <div className='center-aligned-row marginTop10'>
              <div onClick={()=>hide()} className={`border bg-color-primary  pointer marginleft10 font-medium capitalize color-white heading4`} style={{padding:"7px 15px"}}>Start</div>
            </div>
        </div>
    </div>
    </>
  );
};

export default SelectWallsHint;
 
