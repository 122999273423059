import React from 'react';
import { applicationConfig } from '../../../../../utils/methods';

// import './Home.css';

const HeaderMiddle: React.FC<{
    currProduct:any
}> = (props) => {


  function goBackToSubCategory() {
    applicationConfig.filters.products.setSubCategoryName(props.currProduct?.subCategoryName)
    window.location.href = "/products/"+props.currProduct?.categoryName 
  }

    return(
      <>
      <div className="full-width hide-in-mobile">
        <div className="center-aligned-row breadcrumb">
          <span className="font header-heading inactive color-black font-medium pointer" onClick={()=>{window.location.href = "/products/"+props.currProduct?.categoryName}}>{props.currProduct?.categoryName} <span className="padding10 color-dark">/</span> </span>
          <span className="font header-heading inactive color-black font-medium pointer"  onClick={()=>{goBackToSubCategory()}}> {props.currProduct?.subCategoryName} <span className="padding10 color-dark">/</span> </span>
          <span className="font header-heading active color-danger font-medium"> {props.currProduct?.productName}</span>
        </div>

      </div>
      <div className="full-width hide-in-desktop ">
        <div className="center-aligned-row">
          <div className='option display-flex-row'>
              {/* <div className="center-aligned-column"><i className="fas fa-info-circle  color-primary "></i></div> */}
              <div className='marginleft5 heading1 color-primary letter-spacing center-aligned-column'> {props.currProduct?.productName}</div>
          </div>
        </div>
      </div>
      </>
        
    );
};
 
export default HeaderMiddle;
