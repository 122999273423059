import $ from "jquery"
import { BoxGeometry, Mesh, MeshBasicMaterial, Vector3 } from "three"
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter"
import { uploadRenderCamera } from "../../../../services/api"
import { hideComponentLoader, showComponentLoader, showToast } from "../../../UI_methods/global"
import { getAndSaveImageToCache, saveToCache } from "../../../cache/cache"
import { confirmCrop, updateAspectFrameFromCropPoints } from "../../../cropper/cropper"
import { ProjectConfiguration, camera as cameraClass } from "../../../customizer/ProjectConfiguration"
import { applicationConfig, disposeVariables, getObjectByParameter, waitFor } from "../../../methods"
import { RenderConfig, customizerRenderConfig } from "../../../renders/renderConfig"
import { configuration } from "../../modules/customizein3d"
import { getObjectDimensionPosition } from "../../modules/helper"
import { hideFloorplanOptionWindow, updateUndoVisibility } from "../UI_methods"
import { areaModel, camera, canvasDimensions, controls, customizerConfig, enableFinalizeMode, enablePerspectiveCameraForRender, floorplanner, perspectiveCamera, projectConfiguration, renderer, scene, undo, updateAreaCustomizer } from "../area3dModel"
import { setPositionFromControlsForInteriorVirualization, updateInteriorCameraView } from "../interior_visualization"
import { All_WALLS, areaWallsBoundingBoxMapping } from "../walls"
import { cameraForSettingPosition, cameraSettingViewData, updateCameraSettingViewData } from "./createRender"
import { getAnchorElement, mouseDownAnchor, setPulseAnimation, updateAnchorPosition, updateFov, updateNearValue } from "./floorplanUI"
import { isPointerDownCone } from "./render3d"
import { onPointerDownCone, onPointerMoveCone, onPointerUpCone, showCameraHeightSlider } from "./render3d_new"
import { DefaultRoomCameraMappping } from "./DefaultRoomsBackground"

export var cameraFor2dRender:cameraClass = null
const exporter = new GLTFExporter();
export const EPS = 1e-5;

export var isViewRenderMode = false
var currCameraCount = 1
export var renderSettingConfig = new RenderConfig()
var isFineTuned = false

export function setIsViewRenderMode(val:boolean) {
    isViewRenderMode = val
}

export function addAnchorFor2dRender(renderType:string,cameraName:string) {
    if(cameraFor2dRender){
        //Update camera id and isrenderfalse
        if(!isViewRenderMode){
            cameraFor2dRender?.updateCameraId(Number(new Date))
        }
        cameraFor2dRender?.setIsRendered(false)
        createAnchorElement(cameraFor2dRender)
        loadSavedCamera(cameraFor2dRender)
    }else{
        cameraFor2dRender = new cameraClass("",renderType)
        createAnchorElement(cameraFor2dRender)
        loadNewCamera(cameraFor2dRender?.cameraName)
        isViewRenderMode = false
    }
    // setCurrSelectedCamera(cameraFor2dRender)
    postAnchorAddingAction(cameraFor2dRender)
    return cameraFor2dRender
}

export function createRenderCameraForProduct() {
    cameraFor2dRender = new cameraClass("","2d")
    createAnchorElement(cameraFor2dRender)
    loadNewCamera(cameraFor2dRender?.cameraName)
    isViewRenderMode = false
}

export function addAnchorFor3dRender(savedCamera:cameraClass = null) {
    if(savedCamera){
        setCameraFor2dRender(savedCamera)
        createAnchorElement(cameraFor2dRender)
        // loadSavedCamera(savedCamera)
    }else{
        cameraFor2dRender = new cameraClass("","3d")
        createAnchorElement(cameraFor2dRender)
        loadNewCamera(cameraFor2dRender?.cameraName)
        cameraFor2dRender?.setRotation(controls?.polarAngle,controls?.azimuthAngle)
    }
    
    isViewRenderMode = false  
    postAnchorAddingAction(cameraFor2dRender)
}


export function postAnchorAddingAction(currCamera:cameraClass) {
    if(customizerConfig){
        let anchor = getAnchorElement(String(currCamera?.cameraId))
        updateAnchorPosition(String(currCamera?.cameraId),currCamera?.transform.position)
        setPulseAnimation(anchor)
        setAnchorElementInitialAngle(String(currCamera?.cameraId))
        rotateCameraIconFromCameraControls(anchor,currCamera?.azimuthAngle)
        hideComponentLoader("canvasLoader")
    }
}

export function setAnchorElementInitialAngle(cameraName:string) {
    var box:any = document.getElementById(`areaAnchor${cameraName}`)
    box.setAttribute('data-angle', cameraFor2dRender?.azimuthAngle);
}

export async function loadSavedCamera(savedCamera:any) {
    let position = savedCamera?.transform.position
    setControlsPositionRotationFor2dRender(position,savedCamera?.azimuthAngle,savedCamera?.polarAngle)
    if(customizerConfig.currentRenderingType === "2d" && savedCamera?.cropPoints.minX && savedCamera?.cropPoints.maxX && savedCamera?.cropPoints.minY && savedCamera?.cropPoints.maxY){
        let cropPoints = {...savedCamera?.cropPoints}
        setTimeout(() => {
            updateAspectFrameFromCropPoints(cropPoints)
        }, 500);
    }

    // update near
    updateNearRangeSliderValue(Number(savedCamera?.near))
    perspectiveCamera.near = Number(savedCamera?.near)
    perspectiveCamera.updateProjectionMatrix()

    updateFovRangeSliderValue(Number(savedCamera?.fov))
    camera.fov = Number(savedCamera?.fov)
    camera.updateProjectionMatrix()

    setTimeout(() => {
        adjustCameraPositionIfBelowFloor()
    }, 500);
    // rotateCameraIconFromCameraControls(anchor,controls?.azimuthAngle)
    hideComponentLoader("canvasLoader")

    //Slider height value is going doing Hardcoding some values to get results 

}

export function setIsFineTunedTrue(){
    isFineTuned = true
}

function adjustCameraPositionIfBelowFloor(){
    let minValue:any = Number($("#rangeSliderHeight").attr("min"))
    let maxValue:any = Number($("#rangeSliderHeight").attr("max"))
    if(!isNaN(minValue) && !isNaN(maxValue) && isFineTuned){
        let delta = 0.5
        $("#rangeSliderHeight").val(Math.round((minValue+maxValue)/2) - (delta * 100))
        let position = controls.getPosition()
        let target = controls.getTarget()
        let posY = Math.round((minValue+maxValue)/2) + 5
        controls.setLookAt(position.x,posY/100,position.z,target.x,posY/100,target.z,false)
        cameraFor2dRender?.transform.updateTransform(controls.getPosition(),cameraFor2dRender?.transform.rotation,cameraFor2dRender?.transform.scale,0)
    }
}

export function updateFovRangeSliderValue(fov:number) {
    $("#rangeSliderFov").val(fov)
    $("#rangeSliderFovVal").text(String(fov))  
}

export function updateNearRangeSliderValue(near:number) {
    $("#rangeSliderNear").val(near*100)
    $("#rangeSliderNearVal").val(String(near))
}

export async function loadNewCamera(cameraName:string) {
    if(areaWallsBoundingBoxMapping){
        // let position = getObjectDimensionPosition(null,floorplanner.group).center
        // position.y = position.y - 1
        // setControlsPositionRotationFor2dRender(position,0,0)
        setPositionFromControlsForInteriorVirualization("all",false)
        cameraFor2dRender?.transform.updateTransform(controls?.getPosition(),cameraFor2dRender?.transform.rotation,cameraFor2dRender?.transform.scale,0)
        setHeightSliderValues(customizerConfig.cameraMinHeight,customizerConfig.cameraMaxHeight,controls?.getPosition())
        $("#areaAnchor"+cameraName).css("transform",`rotate(${1.5708}rad)`)  
    }
}

export async function setControlsPositionRotationFor2dRender(position:Vector3,azimuthAngle:number,polarAngle:number) {
    controls?.setLookAt(position.x,position.y,position.z,position.x,position.y,position.z + EPS,false)
    if(azimuthAngle){
        controls?.rotateTo(azimuthAngle,polarAngle,false)
    }
    // updateInteriorCameraView(cameraFor2dRender,position,false)  
    setHeightSliderValues(customizerConfig.cameraMinHeight,customizerConfig.cameraMaxHeight,controls?.getPosition())
}

 
// Parse the input and generate the glTF output
let options = {
    trs:true,
    binary:false
}
 
export function set2dCameraForRender(currCamera:any) {
    // cameraFor2dRender = currCamera
    cameraFor2dRender = new cameraClass(currCamera.areaName,currCamera.renderType,currCamera.cameraId,currCamera)
    // cameraFor2dRender?.transform.updateTransform(currCamera.transform.position,currCamera.transform.rotation,currCamera.transform.scale,0)
    // cameraFor2dRender?.updateFov(currCamera.fov)
    // cameraFor2dRender?.updateCropPoints(currCamera.cropPoints)
    // cameraFor2dRender?.setRotation(currCamera.polarAngle,currCamera.azimuthAngle)
}


export function saveRenderCameraFor2D(currCamera:any,camera:any) {
    return new Promise(async (resolve,reject)=>{
        if(customizerConfig?.currentRenderingType === "3d" || currCamera?.isRendered){
            resolve("completed")
        }else{
            await saveRenderCamera(currCamera,camera.clone()).then(data=>{
                resolve(data)
            }).catch(err=>{  
                reject(err)
            })
        }
        
    })
}
export function saveRenderCamera(cameraObj:any,cameraTobeRender:any) {
    return new Promise(async (resolve,reject)=>{
            let config = null
            let pathName = window.location.pathname
            let currentConfiguration = projectConfiguration
            if(pathName.includes("product") || pathName.includes("customizein3d")){
                currentConfiguration = new ProjectConfiguration(applicationConfig.clientId,applicationConfig?.clientName,0,configuration.product.productName)
            }
            config = new customizerRenderConfig(currentConfiguration,cameraObj)
            config.removeAreasConfig()
            config.setProjectConfigForConfigurator()
            //To make it default config
            if(pathName.includes("product") || pathName.includes("customizein3d")){
                config.projectRenderConfig.configId = 0
            }
            getGltfFromCamera(cameraTobeRender).then(async (output)=>{
                await uploadRenderCamera({file:output,configuration:JSON.stringify(config)}).then(data=>{
                    // console.log("Camera saved")
                    resolve(data)
                }).catch(err=>{
                    reject(err)
                })
            })
    })
     
}

export function getGltfFromCamera(currCamera:any) {
    return new Promise((resolve,reject)=>{
        exporter.parse(
            currCamera,
            async function ( gltf:any ) {
                const output = JSON.stringify(gltf, null, 2);
                resolve(output)
            },
            options
        );
    })
}

export function createAnchorElement(currCamera:any) {
   
    let parentContainer = document.getElementById("area3DSceneWrapper")
    let anchorElement = $("<div></div>").addClass("anchor-icon-container top-middle zindex30").
    attr("id",`areaAnchor${currCamera.cameraId}`)
    .appendTo($(parentContainer))

    $("<div></div>").addClass("anchor-icon")
    .html(`<i class='fas fa-video pointer-none icon'></i>`)
    // .html(`<img draggable="false" src="https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/icon/svg/video_camera.svg" class='icon pointer-none'/>`)
    .mousedown((event)=>{
        setCameraFor2dRender(currCamera)
        mouseDownAnchor(event)
        if(customizerConfig.currentRenderingType === "3d"){
            showCameraHeightSlider()
        }
    })
    .appendTo(anchorElement)
    
    $("<i></i>").addClass("fas fa-caret-up pointer-none anchor-cone top-right")
    .appendTo(anchorElement)

    $("<i></i>").addClass("fas fa-arrows-alt-v padding5 alt rotate-icon font-normal top-right")
    .on("mousemove",onPointerMoveCone)
    .mousedown((event)=>{
        if(customizerConfig.currentRenderingType === "2d"){
            setCameraFor2dRender(currCamera)
        }
        onPointerDownCone(event)
    })
    .mouseup(onPointerUpCone)
    .appendTo(anchorElement)

    $("<div></div>").addClass("heading2 pointer-none anchor-tooltip font-ex-small middle")
    .text(`${String(currCameraCount)}`)
    .appendTo(anchorElement)


    currCameraCount += 1

    return anchorElement
}

 

export function goto2DRenderView(cameraObj:any) {
    enablePerspectiveCameraForRender()
    // composer.updateCamera(perspectiveCameraForRender)
    // setIsCameraSettingMode(true)
    let position = cameraObj.transform?.position
    let quaternion = cameraObj.transform?.rotation
    // controls?.enabled = false
    // let eular = cameraObj.sceneCamera?.rotation
    if(position && quaternion){
        // tweenCamera(camera,position,500)
        // camera.position.set(position.x,position.y,position.z)
        controls?.setPosition(position.x,position.y,position.z,false)
        // camera.applyQuaternion(new Quaternion(quaternion._x,quaternion._y,quaternion._z,quaternion._w)); 
        // camera.quaternion.normalize(); 
        camera.fov = cameraObj.fov
        camera.updateProjectionMatrix()
    }
    // tweenCameraControls(camera,controls,{x:data.positions.min.x-10,y:data.positions.min.y+(data.dimensions.dimY*3),z:data.positions.min.z-10},data.center,tweenTime)
}


export function rotateCameraIconFromCameraControls(anchorElement:any,angle:number) {
    if(!isPointerDownCone){
        let degree = Math.round((angle * (180 / Math.PI) * -1) + 100);
            if(!anchorElement){
                anchorElement = $("#areaAnchor"+cameraFor2dRender?.cameraId)
            }
            anchorElement.css("transform",`rotateZ(${degree+170}deg)`)
    }
}


export async function setCameraMinMaxValues(y:Vector3 = null) {
    // await waitFor(2000)
    // showComponentLoader("setCameraHeightLoader")
    // const firstWall = All_WALLS[0]
    const firstWall = floorplanner.group
    let dimensions = getObjectDimensionPosition(null,firstWall).dimensions
    let maxY:any = getObjectDimensionPosition(null,firstWall).positions.max.y  
    // let minY = Math.round(areaWallsBoundingBoxMapping["all"].boundingBox.positions.min.y)
    let minY = getObjectDimensionPosition(null,firstWall).positions.min.y   
    // let minY = getObjectDimensionPosition(null,firstWall).positions.min.y  + dimensions.dimY / 2
    // console.log(All_WALLS[0])
    // console.log(dimensions.dimY / 2)
    customizerConfig.cameraMinHeight = minY
    customizerConfig.cameraMaxHeight = maxY
    // setHeightSliderValues(minY,maxY,posY)
    // hideComponentLoader("setCameraHeightLoader")
}

export async function setHeightSliderValues(minY:number = 0,maxY:number = 0,posY:number = 0) {
    showComponentLoader("setCameraHeightLoader")
    // setCameraMinMaxValues()
    await waitFor(500)
    
    const firstWall = floorplanner.group
    let dimensions = getObjectDimensionPosition(null,firstWall).dimensions
    let delta = customizerConfig.isFinalizeMode ? 0 : dimensions.dimY / 2
 

    minY = parseInt(String((customizerConfig.cameraMinHeight + delta) * 100))
    maxY = parseInt(String(customizerConfig.cameraMaxHeight * 100))
    posY = parseInt(String(controls?.getPosition().y * 100))


    $("#rangeSliderHeight").attr("min",String(minY))
    $("#rangeSliderHeight").attr("max",String(maxY))
    $("#rangeSliderHeight").val(String(posY))
    // $("#rangeSliderHeightValue").text(String(Number(posY)))
    $("#rangeSliderFov").attr("value",Math.round(camera.fov))
    $("#rangeSliderFovVal").text(String(Math.round(camera.fov)))

    $("#rangeSliderNearVal").text(String(camera.near))   
    hideComponentLoader("setCameraHeightLoader")
}



export function resetCameraFor2dRender() {
    cameraFor2dRender = null
}

export function getCameraObjectFromCamera(cam:any) {
    return new cameraClass(cam.areaName,cam.renderType,cam.cameraId,cam)
}

export function setCameraFor2dRender(cam:any) {
    cameraFor2dRender = cam
}

export function setOrthographicCameraPosition() {
    cameraForSettingPosition.zoom = cameraSettingViewData.width * 100
}



export async function saveView() {
    return new Promise(async (resolve,reject)=>{
        showComponentLoader("changeFinishLoader")
        confirmCrop()
        let cropPoints = cameraFor2dRender?.cropPoints
        let width = Math.abs(cropPoints.minX - cropPoints.maxX)
        let height = Math.abs(cropPoints.minY - cropPoints.maxY)
        let left = cropPoints.minX + (canvasDimensions.width * cameraSettingViewData.width)
        let top = canvasDimensions.height - cropPoints.maxY
        let croppedCanvas = cropCanvas(renderer.domElement,left,top,width,height)
        
        let blobUrl = croppedCanvas.toDataURL('image/jpeg') 
        var bufferView = new Buffer(blobUrl.split(",")[1], 'base64');
        let key = `2DImages/DemoClient2/NewDemo/${cameraFor2dRender?.areaName}${cameraFor2dRender?.cameraId}/${cameraFor2dRender?.areaName}0000.png`
        console.log(cameraFor2dRender,key)

        await saveRenderCamera(cameraFor2dRender,perspectiveCamera.clone()).then(async (data)=>{
            await saveToCache(key,bufferView).then(message=>{
                showToast("Saved view successfully",2000)
                hideComponentLoader("changeFinishLoader")
                $(croppedCanvas).remove()
                disposeVariables([bufferView])
                resolve(message)
            }).catch(err=>{
              $(croppedCanvas).remove()
              hideComponentLoader("changeFinishLoader")
              reject(err)
            })
        }).catch(err=>{
            $(croppedCanvas).remove()
            hideComponentLoader("changeFinishLoader")
            reject(err)
        })
    })
    
}

export function updateView() {
    saveView().then(data=>{
        let existedCamera = getObjectByParameter(projectConfiguration.cameras,"cameraId",cameraFor2dRender?.cameraId) 
        if(existedCamera){
          projectConfiguration.delete2dCamera(existedCamera)
        }
        cameraFor2dRender?.setIsRendered(true)
        projectConfiguration.appendCamera(cameraFor2dRender)
        projectConfiguration.updateLocalStorage()
        applicationConfig.functions.customizer.setRefreshRenders(Number(new Date()))
    }).catch(err=>{
        showToast(err,2000,"error")
        console.log(err)
    })
}

export function saveAsNewView() {
    cameraFor2dRender?.updateCameraId(Number(new Date()))
    saveView().then(data=>{
        projectConfiguration.appendCamera(cameraFor2dRender)
        projectConfiguration.updateLocalStorage()
        applicationConfig.functions.customizer.setRefreshRenders(Number(new Date()))
    }).catch(err=>{
        showToast(err,2000,"error")
    })
}

export function cropCanvas(sourceCanvas:any,left:number,top:number,width:number,height:number){
    let destCanvas = document.createElement('canvas');
    destCanvas.width = width;
    destCanvas.height = height;
    destCanvas.getContext("2d").drawImage(
        sourceCanvas,
        left,top,width,height,  // source rect with content to crop
        0,0,width,height);      // newCanvas, same size as source rect
    return destCanvas;
}

export function pointerDownFov() {
    undo.add("fov",{fov:perspectiveCamera.fov})
}

export function pointerDownNear() {
    undo.add("near",{near:perspectiveCamera.near})
}

export function pointerDownHeightSlider() {
    let posY = Number($("#rangeSliderHeight").val()) / 100
    let pos = new Vector3(cameraFor2dRender?.transform.position.x,posY,cameraFor2dRender?.transform.position.z)
    undo.add("position",{cameraId:cameraFor2dRender?.cameraId,prevPosition:pos})
}

export async function update2dRenderImageInCache(currCamera:any) {
    let imageUrlKey = `2DImages/DemoClient2/NewDemo/${currCamera.areaName}${currCamera.cameraId}/${currCamera.areaName}0000.png`
    await getAndSaveImageToCache(imageUrlKey)
}

export function resetCreateRenderParameters() {
    currCameraCount = 1
    // cameraFor2dRender = null
    // resetCameraFor2dRender()
}

export function undoHandle2dRender() {
    let item = undo.getLastItem()
    if(!item){
      showToast("Nothing to undo",2000,"error")
      return
    }
    let action = item.action
    let data = item.data

    let anchor =  getAnchorElement(data.cameraId)

    switch (action) {

        case "position":
            
            if(anchor){
                updateAnchorPosition(data?.cameraId,data?.prevPosition)
            }
            cameraFor2dRender?.transform.updatePosition(data.prevPosition)
            updateInteriorCameraView(null,data.prevPosition,true)  

            //For height undo same as position
            setHeightSliderValues(customizerConfig.cameraMinHeight,customizerConfig.cameraMaxHeight,controls?.getPosition())

            
            showToast("Position updated",2000)

        break;

        case "rotation":
            //remove camera from ui and configuration
            if(anchor){
                rotateCameraIconFromCameraControls(anchor,data.azimuthAngle)
            }
            //Update config
            cameraFor2dRender?.setRotation(data.polarAngle,data.azimuthAngle)
            controls?.rotateTo(data.azimuthAngle,data.polarAngle,false)

            showToast("Rotation updated",2000)
        break;

        case "fov":
            //Update config
            updateFov(null,data.fov)
            updateFovRangeSliderValue(Number(data.fov))
            showToast("Fov updated",2000)
        break;

        case "near":
            //Update config
            updateNearValue(null,data.near)
            updateNearRangeSliderValue(Number(data.near))
            showToast("Near updated",2000)
        break;

        case "aspect":
            updateAspectFrameFromCropPoints(data.cropPoints)
            //Update config
            confirmCrop()
            showToast("Aspect ratio updated",2000)
        break;
    
        default:
        break;
    }

    
    undo.removeLastItem()
    updateUndoVisibility()
    projectConfiguration.updateLocalStorage()
}


export async function postProductRenderModeAction(){
    showComponentLoader("mainLoader")
    customizerConfig.setCurrentRenderingType("2d")
    resetCreateRenderParameters()
    showComponentLoader("canvasLoader")
    controls?.reset()
    // updateSceneCameraFov(camera,45)
    await waitFor(100)
    updateCameraSettingViewData(customizerConfig.currentRenderingType)
    setIsViewRenderMode(false)
    updateAreaCustomizer("all")
    enableFinalizeMode()
    hideFloorplanOptionWindow()

    await waitFor(100)
    let currRoom = projectConfiguration.productRenderModeConfig.currScene
    let camera = DefaultRoomCameraMappping[currRoom]
    if(camera){
        set2dCameraForRender(camera)
    }
    $(".hide-in-product-render-mode").addClass("visibility-hidden")
    hideComponentLoader("mainLoader")
}




 