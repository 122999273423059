import { getParentContainer } from "../../utils/UI_methods/global";
import $ from "jquery"
import { getUserPreferece } from "../cache/localstorage";

var setTourMode:(value:string)=>void
var guideElements = []
var isTourCompleted = false
var guideWrapperId = ""
var currModuleName = ""

export function addImageGuide() {
    setTourMode("thumbguide")
    $("#"+guideWrapperId).addClass("--is-active")
    animateThumb()
}

export function init(tourElementsList,updateTourMode:(value:string)=>void,guideWrapperIdName:string,moduleName:string) {

    isTourCompleted = false
    currModuleName=moduleName
    setTourMode = updateTourMode
    guideElements = tourElementsList
    for (const i in tourElementsList) {
        let element = getElementFromIndex(Number(i))
        element?.addClass("tour-guide-element")
    }
    guideWrapperId = "guideWrapper"+moduleName
}


function animateThumb() {
    let position:any = $(".product-image-container")
    let thumb:any = $("#"+guideWrapperId).find("#guideThumb")
    let canvas:any = $("#"+guideWrapperId).find("#container")
    // console.log(position?.offset()?.top)
    thumb?.css({left:position?.offset()?.left + 25,top:position?.offset()?.top + 25})
    // console.log(position?.offset()?.left)
    // console.log(position?.offsetTop)
    thumb?.animate({
        left:canvas?.offset()?.left + 25,
        top:canvas?.offset()?.top + 50
    },2000,function() {
        thumb?.css({left:position?.offset()?.left + 25,top:position?.offset()?.top + 25})
        animateThumb()
    }) 
}


export function startPageTourAgain() {
    try {
        $("#"+guideWrapperId).addClass("--is-active")
        setFirstElementPoistion()  
    } catch (error) {
        
    }
}


export async function startPageTour() {
    //at page level
    try {
        if(isTourCompleted){
            return
        }
        $("#"+guideWrapperId).addClass("--is-active")
        isTourCompleted = true  
    } catch (error) {
        
    }
    
}

export function start() {
    $("#"+guideWrapperId).find("#startTourButton").hide()
    setFirstElementPoistion()
}

export function setFirstElementPoistion() {
    //Add active class on first element and remove from other
    removeActiveClassFromElements()
    //Display first element initially
    let element = getElementFromIndex(0)
    if(!element.offset().left){
        gotoNextElement()
        return
    }
    displayTourElement(element,guideElements[0]?.calloutMessage,guideElements[0]?.calloutPosition,guideElements[0]?.nextButtonPosition) 
}

function getElementFromIndex(index:number) {
    let parentContainer = getParentContainer() || null
    return $(parentContainer?.querySelector("#"+guideElements[index]?.elementId)) || null
}

 

export function gotoNextElement(event:any = null) {
    let index = 0


    //Remove active class from currnet elements and get the index of next
    for (let i = 0; i < guideElements?.length; i++) {
        try {
            const element = getElementFromIndex(i)
            if(!element.offset().left){
                index = i+1
            }else{
                if(element.hasClass("--is-active")){
                    index = i+1
                    element.removeClass("--is-active")
                }
            }    
        } catch (error) {
            continue
        }
        
    }
    //if all elements finished close tour
    if(Number(index)>guideElements.length-1){
        $(guideElements[index]).removeClass("--is-active")
        $("#"+guideWrapperId).removeClass("--is-active")
        return
    }

    let element = getElementFromIndex(index)
    if(!element?.offset()?.left || !Object.keys(element)){
        gotoNextElement()
        return
    }
    displayTourElement(element,guideElements[index].calloutMessage,guideElements[index].calloutPosition,guideElements[index].nextButtonPosition)
    //If last element change next to finish
    if(Number(index)===guideElements.length-1){
        let nextButtonElement = getNextButtonElement()
        let content = "<div class='center-aligned-column white-card click-effect' id='closeTour'><span class='heading3 font-small'>End tour<i class='fas fa-times marginleft5 font-small color-danger'></i></span></div><div class='dimension-icon-container white-card click-effect marginleft5' id='refreshTour'><i class='fas fa-redo-alt color-dark'></i></div>"
        nextButtonElement?.html(content)
        addEventListerOnIcons()
    }
}

function addEventListerOnIcons() {
    $("#"+guideWrapperId).find("#nextElement").on("click",gotoNextElement)
    $("#"+guideWrapperId).find("#closeTour").on("click",closeTour)
    $("#"+guideWrapperId).find("#refreshTour").on("click",startPageTourAgain)
}

function removeActiveClassFromElements() {
    for (let i = 0; i < guideElements.length; i++) {
        const element = getElementFromIndex(i)
        element.removeClass("--is-active")
    }
}

function displayTourElement(element:any,message:string,calloutPosition:string,nextButtonPosition:string) {
    // if(!element.offset().top && !element.offset().left){
    //     gotoNextElement(null)
    // }
    element.addClass("--is-active")
    let callOutElement = getCallOutElement() 
    callOutElement.stop(true)
    callOutElement?.text(message)
    updateCalloutMessagePoition(element,callOutElement,calloutPosition)
    updateNextButtonPosition(element,nextButtonPosition)
    setTimeout(() => {
        animateCalloutMessage(callOutElement,calloutPosition)
    }, 500);

}


function updateNextButtonPosition(element:any,position:string){
    let nextButtonElement = getNextButtonElement() 
    switch (position) {
        case "topRight":
            nextButtonElement?.css({left:element?.offset()?.left + element?.innerWidth() + 5,top:element?.offset()?.top})
            nextButtonElement.html("<div id='nextElement' class='dimension-icon-container white-card click-effect'>Next<i class='fas fa-chevron-right'></i></div>")
        break;

        case "topLeft":
            nextButtonElement?.css({left:element?.offset()?.left - nextButtonElement.innerWidth() - 25,top:element?.offset()?.top})
            nextButtonElement.html("<div id='nextElement' class='dimension-icon-container white-card click-effect'><i class='fas fa-chevron-left'></i> Next</div>")
        break;

        case "bottomLeft":
            nextButtonElement?.css({left:element?.offset()?.left,top:element?.offset()?.top + element?.innerHeight()+5})
            nextButtonElement.html("<div id='nextElement' class='dimension-icon-container white-card click-effect'>Next<i class='fas fa-chevron-right'></i></div>")
        break;

        case "bottomRight":
            nextButtonElement?.css({left:element?.offset()?.left + element?.innerWidth() + 5,top:element?.offset()?.top + element?.innerHeight()+15})
            nextButtonElement.html("<div id='nextElement' class='dimension-icon-container white-card click-effect'>Next<i class='fas fa-chevron-right'></i></div>")
        break;

        case "leftTop":
            nextButtonElement?.css({left:element?.offset()?.left,top:element?.offset()?.top - nextButtonElement.innerHeight() - 27})
            nextButtonElement.html("<div id='nextElement' class='dimension-icon-container white-card click-effect'><i class='fas fa-chevron-right'></i> Next</div>")
        break;

        case "leftBottom":
            nextButtonElement?.css({left:element?.offset()?.left , top:element?.offset()?.top + element?.innerHeight() + 5})
            nextButtonElement.html("<div id='nextElement' class='dimension-icon-container white-card click-effect'>Next<i class='fas fa-chevron-right'></i></div>")
        break;

        default:
            nextButtonElement?.css({left:element?.offset()?.left +element?.innerWidth() + 2,top:element?.offset()?.top})
        break;
    }

    addEventListerOnIcons()
}

function removePositionClassFromCallout(callOutElement:any) {
    let claases = ["left","top","bottom","right"]
    for (const className of claases) {
        callOutElement.removeClass(className)
    }
  
}

function updateCalloutMessagePoition(element:any,callOutElement:any,position:string) {
    let deltaForLeft = element.innerWidth() - $(callOutElement).innerWidth()
    let deltaForTop = callOutElement.innerHeight() + 15
    deltaForLeft = deltaForLeft/2

    removePositionClassFromCallout(callOutElement)

    switch (position) {
        case "top":
            callOutElement?.css({left:element?.offset()?.left + deltaForLeft,top:element?.offset()?.top - deltaForTop})
            callOutElement.addClass("top")
        
        break;

        case "right":
            callOutElement?.css({left:element?.offset()?.left + element?.innerWidth() + 15,top:element?.offset()?.top + element?.innerHeight()/2 -20 })
            callOutElement.addClass("right")
        break;

        case "bottom":
            callOutElement?.css({left:element?.offset()?.left + deltaForLeft,top:element?.offset()?.top + element.innerHeight() + 15})
            callOutElement.addClass("bottom")
        break;

        case "left":
            callOutElement?.css({left:element?.offset()?.left - callOutElement.innerWidth() -15,top:element?.offset()?.top + element?.innerHeight()/2 -20 })
            callOutElement.addClass("left")
        break;
    
        default:
            break;
    }


}

function animateCalloutMessage(callOutElement:any,position:string) {
    if(position==="right"){
        position = "left"
    }
    if(position==="bottom"){
        position = "top"
    }
    let delta = 15
    try {
        callOutElement.animate({[position]:callOutElement?.offset()[position] - delta},"slow",function(){
            callOutElement.animate({[position]:callOutElement?.offset()[position] + delta},"slow",function(){
                animateCalloutMessage(callOutElement,position)
            })
        })
    } catch (error) {
        console.log(error)
    }
  
}



function getCallOutElement() {
    return $("#"+guideWrapperId).find("#tourMessage") || null
}
function getNextButtonElement() {
    return $("#"+guideWrapperId).find("#tourNextButton") || null
}

function getActionButtonContainer() {
    return $("#"+guideWrapperId).find("#actionButtonContainer") || null
}

export function closeTour() {
    removeActiveClassFromElements()
    $("#"+guideWrapperId).removeClass("--is-active")
}