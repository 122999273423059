import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { hideComponentLoader, showComponentLoader } from "../../../../../utils/UI_methods/global";
import { isPotraitMode } from "../../../../../utils/customizein3d/common";
import { appendModel, appendProductAddon } from "../../../../../utils/customizein3d/modules/customizein3d";
import { wardrobeAccordionClickActions } from "../../../../../utils/customizein3d/modules/wardrobe";
import { applicationConfig } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Finish from "../../../../common/modules/customizein3d/controls/Finish";
import SwingFinish from "../../../../common/modules/customizein3d/controls/SwingFinish";
import WardrobeFinish from "../../../../common/modules/customizein3d/controls/WardrobeFinish";
import Modal2 from "../../../../common/shared-components/Modals/Modal";
import Loader from "../../../../common/shared-components/main-ui/Loader";
import Addons from "../../../../common/shared-components/product-modules/Addons";
import CotModules from "../../../../common/shared-components/product-modules/CotModules";
import ProductModulesAndLayouts from "../../../../common/shared-components/product-modules/ProductModulesAndLayouts";
import WardrobeModules from "../../../../common/shared-components/product-modules/WardrobeModules";
import AccordionForProccessCss from "../../../../common/ui-components/accordion/AccordionForProccessCss";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ControlsTab from "../../../../common/ui-components/tabs/ControlsTab";
import CustomInstructions from "../product-customizer/AdvanceConfig/CustomInstructions";
import InternlStructure from "./InternlStructure";
import SceneBackgrounds from "./SceneBackgrounds";
import SofaLegs from "./SofaLegs";
import SummaryFooter from "./SummaryFooter";
import WardrobeDoorAndHandles from "./WardrobeDoorAndHandles";
import WardrobeModuleControls from "./WardrobeModuleControls";
import Controls from "./debugger/Controls";

const StoreControls: React.FC<{
  modulesConfigurtion:any
  productConfiguration:any
  product:any
  isElevateMode:boolean
}> = (props) => {

  const[isLegsAvailable] = useState(props.product?.customizable_legs)
  const[productsList] = useState(applicationConfig?.data?.productsList)
  const[isOpenAdvanced,setIsOpenAdvanced] = useState(false)
  const[isPotrait,setIsPotrait] = useState(isPotraitMode())
  const[categoryName] = useState(props.product.category_name)
  const[modulesHeading] = useState(getModulesHeading())
  const productRef = useRef(null)
  const accordoinWrapperRef = useRef(null)
  
  useEffect(()=>{
    if(isPotraitMode()){
      $(accordoinWrapperRef.current).addClass("overflow-y-scroll hide-scrollbar")
    }
    window.addEventListener('orientationchange',updatePotraitMode);
    return () => {
      window.removeEventListener('orientationchange',updatePotraitMode);
    }
  },[])

  function updatePotraitMode() {
    showComponentLoader("controlsUILoader")
    setTimeout(() => {
      setIsPotrait(isPotraitMode())
      setCurrTab("AddModules")
      hideComponentLoader("controlsUILoader")
    }, 1000);
  }


  const [currTab, setCurrTab] = useState<string>("AddModules");
  const[tabItems,setTabItems] = useState(getTabItmes())

function getTabItmes() {
  if(isLegsAvailable){
    return [
      {tabName:"AddModules",label:categoryName === "Cot"?"2. Choose Module":"1. Add Modules",ionIcon:null},
      {tabName:"ChooseFinish",label:"2. Choose Finish",ionIcon:null},
      {tabName:"Addons",label:"3. Addons",ionIcon:null},
      {tabName:"sofaLegs",label:"4. Legs",ionIcon:null}
      ]
  }
  if(categoryName === "Wardrobe"){
    return [
      {tabName:"AddModules",label:"1.Frame",ionIcon:null},
      {tabName:"InternalStructure",label:"2.Shelf",ionIcon:null},
      {tabName:"DoorsAndHandles",label:"3.Doors & Handles",ionIcon:null},
      {tabName:"ChooseFinish",label:"4.Finish",ionIcon:null},
      {tabName:"Addons",label:"3.Addons",ionIcon:null},
      ]
  }
  return [
    {tabName:"AddModules",label:categoryName === "Cot"?"2. Choose Module":"1. Add Modules",ionIcon:null},
    {tabName:"ChooseFinish",label:"2. Choose Finish",ionIcon:null},
    {tabName:"Addons",label:"3. Addons",ionIcon:null}
  ]
}



function getModulesHeading() {
  return categoryName === "Cot"?"Choose Module":categoryName === "Wardrobe"?"Wardrobe Structure":categoryName==="Swing"?"Select Variant":"Add Modules"
}


  function closeRoomLayout() {
    $("#roomLayoutContainer").addClass("display-none")
  }

  function show() {
    $(productRef.current).removeClass("display-none")
  }

  function hide() {
    $(productRef.current).addClass("display-none")
  }

  function ModulesList() {
    return(
      <div className="full-height-width position-relative" id="addModulesContainer">
        {categoryName === "Sofa"?
          <ProductModulesAndLayouts
            product={props.product}
            moduleRef={{}}
            action={(module: any) => appendModel(module, false)}
            moduleName="customizein3d"
            dragStart={()=>{}}
            parentContainerId={"addModulesContainer"}
          />
        :
        categoryName === "Cot" || categoryName === "Chairs" || categoryName === "Swing" ?
          <CotModules
            action={({...module}: any) => appendModel(module, false)}
            moduleName="customizein3d"
            dragStart={()=>{}}
            parentContainerId={"addModulesContainer"}
            product={props.product}
          />
          :
        categoryName === "Wardrobe"?
          <WardrobeModules
            action={({...module}: any) => appendModel(module, false)}
            moduleName="customizein3d"
            dragStart={()=>{}}
            parentContainerId={"addModulesContainer"}
            product={props.product}
          />
        :
        <div className="middle heading4">Modules Not Available</div>
        }
      </div>
    )
  }


  function changeTabOption(tabName: string) {
    setCurrTab(tabName);
    updateTabValue(tabName)
    wardrobeAccordionClickActions(tabName)
  }
  function updateTabValue(val:string){
    $("#storeControlsPanelMobile").find(".tab-content").removeClass("--is-active")
    $("#"+val).addClass("--is-active")
  }


  function FinishPanel(){
    return(
      <>
      {categoryName === "Swing"?
      <SwingFinish
      />
      :categoryName === "Wardrobe"?
      <WardrobeFinish
      />
      :
      <Finish
        configObj={applicationConfig}
      />
      }
      </>
    )
  }

  return (
      <div className="full-height-width position-relative">

      


      <Modal2
          isOpen = {isOpenAdvanced}
          Content = {()=>(
            <CustomInstructions customizingOptions={[]} productConfiguration={props.productConfiguration} setCurrSelectedWindowIndex={null} currSelectedWindowIndex={0} index={0}/>
          )}
          onDismiss = {()=>setIsOpenAdvanced(false)}
          FooterContent={()=>(<></>)}
          heading="Advanced"
          classNames="small-x-modal"
          id=""
      />

        <Loader
          className="fade-background-loader"
          id="controlsUILoader"
          data={{}}
          loaderType="spinner"
        />
        {!isPotrait?
          <div id="storeControlsWrapper" ref={accordoinWrapperRef} className="accordion-wrapper overflow-y-scroll full-width padding5" style={{height:"calc(100% - 4rem)"}}>
            
            {categoryName !== "Dresser"?
              <AccordionForProccessCss
                label={modulesHeading}
                SubHeader={()=>(
                  <div id="currVariant" className="heading2 font-md-small marginRight10 center-aligned-column color-black">{}</div>
                )}
                Content={()=>(
                  <ModulesList/>
                )}
                index={1}
                Footer={()=>(
                  <></>
                )}
              />
            :null}

            

            {categoryName === "Wardrobe"?
              <AccordionForProccessCss
                label="Internal Structure"
                SubHeader={()=>(
                  <div id="currInternalStructure" className="heading3 font-md-small marginRight10 center-aligned-column color-danger"></div>
                )}
                Content={()=>(
                  <InternlStructure/>
                )}
                index={2}
                Footer={()=>(
                  <></>
                )}
              />
            :null}

            {categoryName === "Wardrobe"?
              <AccordionForProccessCss
                label="Handles"
                SubHeader={()=>(
                  <div style={{maxWidth:"5rem",alignItems:"start"}} className="heading2 text-ellipsis font-md-small marginRight10 center-aligned-column color-danger"></div>
                )}
                Content={()=>(
                  <WardrobeDoorAndHandles/>
                )}
                index={3}
                Footer={()=>(
                  <></>
                )}
              />
            :null}
            
            

            <AccordionForProccessCss
              label="Choose Finish"
              SubHeader={()=>(
                <div style={{maxWidth:"5rem",alignItems:"start"}} className="heading2 curr-selected-material text-ellipsis font-md-small marginRight10 center-aligned-column color-danger"></div>
              )}
              Content={()=>(
                <FinishPanel/>
              )}
              index={categoryName === "Wardrobe"?4:2}
              Footer={()=>(
                <></>
              )}
            />

            

            

            

            {categoryName !== "Wardorbe"?
              <AccordionForProccessCss
                label="Addons"
                SubHeader={()=>(
                  <div id="currMaterial" className="heading2 font-md-small marginRight10 center-aligned-column color-black"></div>
                )}
                Content={()=>(
                  <Addons
                    product={props.product}
                    action={(module: any) => appendModel(module, true)}
                    appendProductAddon={(module: any,product:any) => appendProductAddon(module, product)}
                    moduleName="customizein3d"
                    dragStart={()=>{}}
                    parentContainerId={"addAddonsContainer"}
                  />
                )}
                index={categoryName === "Wardrobe"?5:3}
                Footer={()=>(
                  <></>
                )}
              />
            :null}
            

            {isLegsAvailable?
              <AccordionForProccessCss
                label="Legs"
                SubHeader={()=>(
                  <div id="currSofaLeg" className="heading3 font-md-small marginRight10 center-aligned-column color-danger"></div>
                )}
                Content={()=>(
                  <SofaLegs/>
                )}
                index={4}
                Footer={()=>(
                  <></>
                )}
              />
            :null}

            

          </div>
        :
        <div id="storeControlsPanelMobile" className="controls-options" style={{height:"calc(100% - 4rem)"}}>
          <div className="full-width tabs">
            <ControlsTab
              configObj={applicationConfig}
              tabItems={tabItems}
              currSelectedValue={currTab}
              action={changeTabOption}
              theme="black"
            />
          </div>
           <div id="AddModules" className="tab-content --is-active">
              <ModulesList/>
            </div>

            <div id="ChooseFinish" className="tab-content padding">
                <FinishPanel/>
            </div>

            <div id="Addons" className="tab-content">
                <Addons
                  product={props.product}
                  action={(module: any) => appendModel(module, true)}
                  appendProductAddon={(module: any,product:any) => appendProductAddon(module, product)}
                  moduleName="customizein3d"
                  dragStart={()=>{}}
                  parentContainerId={"addAddonsContainer"}
                />
            </div>

            <div id="DoorsAndHandles" className="tab-content">
              <WardrobeDoorAndHandles/>
            </div>

            <div id="InternalStructure" className="tab-content">
              <InternlStructure/>
            </div>


            {isLegsAvailable?
             <div id="sofaLegs" className="tab-content">
              <SofaLegs/>
            </div>
            :null}

           
        </div>
        }

        <SummaryFooter
            product={props.product}
            productConfiguration={props.productConfiguration}
            isElevateMode={props.isElevateMode}
        />

    
        
        <SceneBackgrounds/>
        <Controls/>
        {STORE.getCurrCategory() !== "Sofa"?
          <WardrobeModuleControls/>
        :null}
        <div className="hide-in-desktop bottom-right padding10">
          <Button2
              configObj={{id:""}}
              action={()=>{show()}}
              label={""}
              fontAwesomeIcon={"fas fa-plus color-white"}
              iconPos="left"
              classNames={"shadow-medium bg-color-primary"}
          />
        </div>
      </div>
    );
};

export default StoreControls;
