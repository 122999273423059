import React, { useState } from "react";
import MainLayout from "../../templates/layouts/page/MainLayout";
import LogsContainer from "./LogsContainer";


const Logs: React.FC<{
  configObj: any;
  isLogin:boolean
}> = (props) => {

 





  function HeaderLeftComponent() {
    return (
      <div className="brand">
        {/* <img src="/assets/images/opus.png" alt="Opus"/> */}
        <div className="heading font-medium">Logs</div>
      </div>
    );
  }

  function MainContent() {
    return (
      <LogsContainer
      configObj={props.configObj}
      />
    );
  }


  return (
    <React.Fragment>
      {props.configObj && props.isLogin?
          <MainLayout 
          configObj={props.configObj}
          CardsContainer={MainContent}
          HeaderLeftContent={HeaderLeftComponent}
          HeaderMiddleContent={null}
          contentWrapperId="mainLogsContentWrapper"
        />
      :<span>Please Wait...</span>}

  </React.Fragment>
  )
};

export default Logs;
