import React, { useEffect, useState } from "react";
import DropDown from "../filters/DropDown";

const SelectInput: React.FC<{
    id:string
    label:string
    isMandatory:boolean
    onChange:(event:any)=>void
    options:Array<any>
    className:string
    value:any
    config?:any
}> = (props) => {


  // const[currValue,setCurrValue] = useState(props.value || props.options[0][props.config.value])


  // useEffect(()=>{
  //   props.onChange(currValue)
  // },[])


  function changeValueHandle(val:any) {
    // setCurrValue(val)
    props.onChange(val)
  }

  return (
    <div className="form-label-input-container">
        {props.label.length?
        <div className="label-container ">
          <div className="label">
            {props.label} {props.isMandatory?<span className="mandatory">*</span>:null}
          </div>
        </div>
        :null}
      <div className="input-container">
          <select id={props.id} name="" onChange={(event)=>{props.onChange(event.target.value)}}  className={`input ${props.className}`}>
            {props.options.length>1 && !props.value?
              <option className="input" value={""}>Please select a value</option>
            :
              null
            }
            {props.options?.map((option:any,index:any)=>{
              return(
                <option  className="input" key={index} value={option[props.config.value]}>{option[props.config.displayName]}</option>
              )
            })}
          </select>
      </div>
    </div>
  );
};
            
export default SelectInput;
