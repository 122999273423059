import { IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { TextureInfo } from "../../../../utils/customizer/ProjectConfiguration";
import { getFilteredMaterialsForElevate } from "../../../../utils/elevate/elevate";
import { applicationConfig, getObjectByParameter, stringWithoutWhiteSpace, waitFor } from "../../../../utils/methods";
import { SofaVariantsConfiguration } from "../../../../utils/store/customization-options-data/sofa";
import { STORE, SofaConfiguration, Variant } from "../../../../utils/store/storeConfiguration";
import ProductViewer from "../../products/productviewer/ProductViewer";
import MainHeader from "../common/MainHeader";
import SubHeader from "../common/SubHeader";
import ArAction from "./ArAction";
import Details from "./Details";
import Footer from "./Footer";
import Gallery from "./Gallery";
import OtherDetails from "./OtherDetails";
import { untarAndSaveToCache } from "../../../../utils/cache/cache";
import { hideComponentLoader, showComponentLoader } from "../../../../utils/UI_methods/global";
 

const ElevateProductDetails: React.FC<{
  configObj:any
  isLogin:boolean
}> = (props) => {

  const parameters:any = useParams();
  const history = useHistory()


  const[isViewin3D,setIsViewIn3d] = useState(false)
  const[currProduct,setCurrProduct] = useState(null)
  const[productConfiguration,setProductConfiguration] = useState(null)
  const[allVariant,setAllVariants] = useState([])


  useEffect(()=>{
    if(currProduct){
      STORE.updateProductImage("elevateProductImage",currProduct)
      STORE.isViewin3D = isViewin3D
    }
  },[isViewin3D,currProduct,parameters.category,history])
  


  useEffect(()=>{
    if(props.configObj){
      init()
    }
  },[parameters.category,history])

  async function init() {
    showComponentLoader("mainLoaderSpinner")
      const productId = parameters.productId
      let product = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",productId)
      if(product){
        STORE.currProduct = product
        setProductConfigObject(product)
        await downloadGalleryImages(product)
        setCurrProduct(product)
        setTimeout(() => {
          STORE.updateProductImage("elevateProductImage",product)
        }, 500);
      }
  }

  async function downloadGalleryImages(product:any) {
    let awsConfig = applicationConfig.awsConfig
    let key =  `productImages/${applicationConfig.clientName}/${stringWithoutWhiteSpace(product.category_name)}/${stringWithoutWhiteSpace(product.sub_category_name)}/${stringWithoutWhiteSpace(product.product_name)}/gallery/${stringWithoutWhiteSpace(product.product_name)}.tar.gz`
    let urlprefix = `productImages/${applicationConfig.clientName}/${stringWithoutWhiteSpace(product.category_name)}/${stringWithoutWhiteSpace(product.sub_category_name)}/${stringWithoutWhiteSpace(product.product_name)}`
    await untarAndSaveToCache(awsConfig,urlprefix,key)?.then(data=>{
       
    }).catch(err=>{
      console.log(err)
    })
    await downloadProductImage(product)
    hideComponentLoader("mainLoaderSpinner")
  }

  async function downloadProductImage(product:any) {
    let awsConfig = applicationConfig.awsConfig
    let key =  `productImages/${applicationConfig.clientName}/${stringWithoutWhiteSpace(product.category_name)}/${stringWithoutWhiteSpace(product.sub_category_name)}/${stringWithoutWhiteSpace(product.product_name)}/${stringWithoutWhiteSpace(product.product_name)}.tar.gz`
    let urlprefix = `productImages/${applicationConfig.clientName}/${stringWithoutWhiteSpace(product.category_name)}/${stringWithoutWhiteSpace(product.sub_category_name)}/${stringWithoutWhiteSpace(product.product_name)}`
    untarAndSaveToCache(awsConfig,urlprefix,key)?.then(data=>{
       
    }).catch(err=>{
      console.log(err)
    })
  }


  function setProductConfigObject(product:any) {
    let config = null
    let currCategory = product.category_name
    const allVariants = SofaVariantsConfiguration.filter(currVariant => currVariant.categories.includes(stringWithoutWhiteSpace(product?.sub_category_name)))
    // allVariant = await getFilteredListWithAssets()
    setAllVariants(allVariants)
    if(currCategory === "Sofa"){
      let defaultVariant = allVariants.find(currVariant => currVariant.isDefault)
      let variant = new Variant(defaultVariant.name) 
      config = new SofaConfiguration(variant,currProduct?.default_configuration)
    }
    STORE.productConfiguration = config
    let list = getFilteredMaterialsForElevate(applicationConfig?.data?.materials)
    if(list.length){
      STORE.productConfiguration.texture = new TextureInfo(list[0])
    }
    setProductConfiguration(config)
  }

  async function toggleProductViewer(val:boolean) {
    if(val){
      setIsViewIn3d(true)
      await waitFor(500)
      STORE.modelViewer.init(currProduct,"productViewerWrapper",productConfiguration.variant.variantName)
    }else{
      // disable3dMode(currGalleryIndex)
    }
  }

  function disable3dMode(index:number) {
    setIsViewIn3d(false)
    // setCurrGalleryIndex(index)
  }
 
  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white elevate-main-wrapper elevate-main-product-details-wrapper overflow-y-scroll">
        <MainHeader/>
        <SubHeader/>
        <div className="main-content-wrapper position-relative bg-color-white">
          {currProduct?
            <div className="background-white position-relative full-height-width">
              <div className="elevate-product-details-wrapper padding-container full-height-width" >
                <div className="gallery-wrapper">
                  <Gallery isViewIn3d={isViewin3D} disable3dMode={disable3dMode} toggleProductViewer={toggleProductViewer} product={currProduct}/>
                </div>
                <div className="marginTop10 product-viewer-wrapper   elevate-product-viewer position-relative overflow-hidden">
                  {isViewin3D?
                    <>
                    {/* <Loader
                        id={"canvasLoader"}
                        data={{}}
                        loaderType="topProgressBar"
                        className="--is-active fade-background-loader"
                      />

                      <Loader
                        className="fade-background-loader"
                        id="changeFinishLoader"
                        data={{}}
                        loaderType="dots"
                      />
                      <CustomizeIn3D product={currProduct}/> */}
                      <div className="top-left">
                        <ArAction/>
                      </div>
                      <ProductViewer
                        id="productViewerWrapper"
                      />
                    </>
                  :
                  <>
                  {/* {currGalleryIndex === 0 ?
                    <ProductImage product={currProduct}/>
                    // <></>
                  :
                    <ProductGalleryImage index={currGalleryIndex} product={currProduct}/>
                  } */}
                  <img id={"elevateProductImage"} className="product-image"  />
                  </>
                  }
                </div>
                <div className="gallery-wrapper-mobile hide-in-desktop">
                  <Gallery isViewIn3d={isViewin3D} disable3dMode={disable3dMode} toggleProductViewer={toggleProductViewer} product={currProduct}/>
                </div>
                <div className="controls-wrapper elevate-controls-container">
                  {productConfiguration?
                    <Details allVariants={allVariant} productConfiguration={productConfiguration} product={currProduct}/>
                  :null}
                </div>
              </div>
              <OtherDetails product={currProduct}/>
              <Footer/>
            </div>
          :
            null
          }
        </div>

      </div>
      :null}
    </IonPage>
  );
};

export default ElevateProductDetails;
