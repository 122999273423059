import React from "react";
import ColorOptions from "../../moodboard/controls/ColorOptions";
import { applicationConfig } from "../../../../../../utils/methods";
import { updateWallColor } from "../../../../../../utils/customizein3d/area3dmodel/elevationView";

const ColorSwatch: React.FC<{
}> = (props) => {



  return (
    <React.Fragment>
      <ColorOptions
          configObj={applicationConfig}
          action={updateWallColor}
      />
    </React.Fragment>
  );
};

export default ColorSwatch;
