import $ from "jquery";
import React, { useEffect } from "react";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import { applicationConfig } from "../../../../../../../utils/methods";
import { confirmWalls, disableNorthWallSelectionMode, enableNorthWallSelectionMode } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/floorplanUI";
import { disableFloorplanMode, projectConfiguration } from "../../../../../../../utils/customizein3d/area3dmodel/area3dModel";

const SelectNorthWall: React.FC<{
}> = (props) => {

  
 

 

  return (
    <>
    <div className='full-height-width display-none top-left black-fade-background center-aligned-column' style={{position:"fixed",backgroundColor:"rgba(0,0,0,0.7)",zIndex:1002}}>
       
    </div>

    <div className="zindex30 top-right hide-in-north-wall-selection">
      <div className="display-flex-row no-wrap">
        <Button2
          configObj={applicationConfig}
          action={enableNorthWallSelectionMode}
          label={`${projectConfiguration.northWall?.length?"Update north wall":"Mark north wall"}`}
          fontAwesomeIcon={"fas fa-edit color-danger"}
          iconPos="left"
          classNames={"shadow-light bg-color-primary"}
        />
        <Button2
          configObj={applicationConfig}
          action={disableFloorplanMode}
          label={`Exit floorplan`}
          fontAwesomeIcon={"fas fa-times color-danger"}
          iconPos="left"
          classNames={"shadow-light marginleft5 bg-color-black"}
        />
      </div>
    </div>

    <div className="bottom-middle zindex100 display-none" id="northWallConfirmButton">
      <div className="padding5  white-card ion-margin-bottom">
      <div className="display-flex-row no-wrap pulse-anchor">
          <Button2
            configObj={applicationConfig}
            action={disableNorthWallSelectionMode}
            label={"Cancel"}
            fontAwesomeIcon={"fas fa-times color-danger"}
            iconPos="left"
            classNames={"bg-color-light no-shadow button-"}
          />
          <Button2
            configObj={applicationConfig}
            action={confirmWalls}
            label={"Confirm"}
            fontAwesomeIcon={"fas fa-check"}
            iconPos="left"
            classNames={"bg-color-primary no-shadow border button- marginleft5"}
          />
      </div>
    </div>
    </div>


    </>
  );
};

export default SelectNorthWall;
 
