import React from "react";
import { imageContainerId, pointerDown, pointerDownResizer, pointerMove, pointerMoveResizer, pointerUp } from "../../utils/image-viewer/imageviewer";
import "../style/image-viewer.css"
import Button2 from "../common/ui-components/buttons/Button2";

const ImageViewer: React.FC<{
  imageId:string
  src:string
}> = (props) => {

   
 
  return (
    <>
      {/* <div className="frame image-viewer-container border padding0 left-middle" id={imageContainerId} style={{width:"200px"}}> */}
      <div className="frame image-viewer-container full-height-width  zindex30" onPointerDown={pointerDown} onPointerUp={pointerUp} onPointerMove={pointerMove} id={imageContainerId}>
        <img src={props.src} className="full-height middle" id={props.imageId} alt="" style={{height:"75%"}} />
        {/* <div className="image-resizer top-left padding0"  onPointerDown={pointerDownResizer} onPointerUp={pointerUp} onPointerMove={pointerMoveResizer}></div>
        <div className="image-resizer top-right padding0" onPointerDown={pointerDownResizer} onPointerUp={pointerUp} onPointerMove={pointerMoveResizer}></div>
        <div className="image-resizer bottom-left padding0" onPointerDown={pointerDownResizer} onPointerUp={pointerUp} onPointerMove={pointerMoveResizer}></div>
        <div className="image-resizer bottom-right padding0" onPointerDown={pointerDownResizer} onPointerUp={pointerUp} onPointerMove={pointerMoveResizer}></div> */}
      </div>

     
    </>
  );
};

export default ImageViewer;
