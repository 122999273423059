import { IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import React, { useState } from 'react';

import { getArrayOfDistinctValues, getUserType } from '../../../utils/methods';
import Modal2 from '../../common/shared-components/Modals/Modal2';
import Categories from '../../common/shared-components/products/Categories';
import Inventory from '../inventory/Inventory';



const MaterialTypeLayout: React.FC<{
  configObj: any;
  updateMaterialType:(val:string)=>void
}> = (props) => {


  const [createNewProduct, setCreateNewProduct] = useState(false);

  function AddCardForm() {
    return(
      <Inventory
      currState="Finish"
      configObj={props.configObj}
      closeModal={setCreateNewProduct}
      product={null}
      finishId={0}
    />
    )
  }

  return (
    <div className="display-flex-column categories-main-wrapper no-wrap full-height-width">

    <Modal2
      isOpen={createNewProduct}
      classNames="form-action-modal"
      onDismiss={setCreateNewProduct}
      id=""
      Content={AddCardForm}
    />

    {getUserType()?.toLowerCase()==="admin"?
        <div className="bottom-right add-dimension-icon-container">
        <div className="bg-color-primary add-dimension-icon center-aligned-column shadow-medium" onClick={()=>setCreateNewProduct(true)}>
          <IonIcon icon={add} className="font-large color-white" />
        </div>
      </div>
    :null}

    <div className="heading2 text-center full-width color-black ion-padding">Material Types</div>
    <div className="products-categories-wrapper center-aligned-row overflow-y-scroll fill-available-height">
      <Categories     
        categories={getArrayOfDistinctValues(props.configObj?.data.materials,"material_type")}
        updateSubCategories={props.updateMaterialType}
      />
    </div>
  </div>

  
  );
};

export default MaterialTypeLayout;
