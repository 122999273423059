import {  currSelectedGroup, moodboardConfig } from "../moodboard";
import { Layout6 } from "./layout6";
import { Layout7 } from "./layout7";
import $ from "jquery"
import { applyLayout } from "./layout";
import { Layout5 } from "./layout5";
import { Layout8 } from "./layout8";
import { showToast } from "../../UI_methods/global";
import { Layout4 } from "./layout4";
import { Layout3 } from "./layout3";
import { Layout2 } from "./layout2";


var currSelectedLayout = null
var currLayoutName = ""
var canvasContainerId = "mainMoodboardCanvasContainer"

export function generateLayoutUI() {
    let width = 250
    let height = 200
    let allGroups = moodboardConfig.getImagesList("mainMoodboardCanvasContainer")   
    let layout = null
    let totalItems = allGroups.length
    let wrapper = $("#layoutsWrapper")

    console.log(allGroups)

    wrapper.empty()

    if(totalItems < 2){
        return
    }

    console.log(totalItems)

    layout = getLayout()

    if(!layout){
        return
    }

    let layoutsList = layout.getLayoutsList()

  
    console.log(layout)

    for (let i = 0; i < layoutsList.length; i++) {
        const currLayout = layoutsList[i];

        let element = $("<div></div>",{
            class:"layout pointer position-relative white-card shadow-light marginTop10 full-width"
        }).css({"width":width+"px","height":height+"px"})

        element.on("click",()=>{
            disablePrioritySelectionMode()
            applyLayout(layout,currLayout)
            currSelectedLayout = layout
            currLayoutName = currLayout
        })

 

        for (let i = 1; i <= totalItems; i++) {
            let data = layout.getBlockParameters(currLayout,i)
            let ele = $("<div></div>",{
              class:"bg-color-light padding0",
            }).css({"position":"absolute","width": data.width + "px" , "height": data.height+ "px" , left: data.x + "px", top: data.y + "px"})
            element.append(ele)
        }

        wrapper.append(element)

    }
}

export function resetCurrSelectedLayout() {
    currSelectedLayout = null
}


export function getLayout() {
    let width = 250
    let height = 200
    let allGroups = moodboardConfig.getImagesList("mainMoodboardCanvasContainer")   
    let totalItems = allGroups.length

    switch (totalItems) {

        case 2:
            return new Layout2(width,height)
        break;

        case 3:
            return new Layout3(width,height)
        break;

        case 4:
            return new Layout4(width,height)
        break;

        case 5:
            return new Layout5(width,height)
        break;

        case 6:
            return new Layout6(width,height)
        break;

        case 7:
            return new Layout7(width,height)
        break;

        case 8:
            return new Layout8(width,height)
        break;
    
        default:
            return null
            break;
    }
}


export function enablePrioritySelectionMode() {
    if(moodboardConfig.getImagesList()?.length < 2){
        return
    }
    $("#prioritySelectorWrapper").addClass("--is-active")

    let container = $("#prioritySelector")
    let canvasContainerId = "mainMoodboardCanvasContainer"
    let allGroups = moodboardConfig.getImagesList(canvasContainerId)

    container.empty()

    for (let i = 0; i < allGroups.length; i++) {
        let currGroup = allGroups[i]
        let currImage = currGroup.find(".canvasItem")[0]
        let data:any = currImage.getClientRect()
        let ele = $("<div></div>",{
            class:currGroup.attrs.priority === 1 ? "selector padding0 center-aligned-column --is-active" : "selector padding0 center-aligned-column",
        }).css({"position":"absolute","width": data.width + "px" , "height": data.height+ "px" , left: data.x + "px", top: data.y + "px"})
        
        ele.on("click",()=>{
            $(".selector").removeClass("--is-active")
            setInitialPriority(allGroups)
            currGroup.attrs.priority = 1
            ele.addClass("--is-active")
        })
        container.append(ele)
    }
}

function setInitialPriority(allGroups:Array<any>) {
    for (let i = 0; i < allGroups.length; i++) {
        let currGroup = allGroups[i]
        currGroup.attrs.priority = 0
    }
}

export function updatePriority() {
    disablePrioritySelectionMode()
    if(currSelectedLayout){
        applyLayout(currSelectedLayout,currLayoutName)
    }else{
        let layout = getLayout()
        currSelectedLayout = layout
        currLayoutName = "a"
        applyLayout(layout,"a")
    }
}

export function updateImagePriority() {
    let group = currSelectedGroup
    let allGroups = moodboardConfig.getImagesList(canvasContainerId)
    setInitialPriority(allGroups)
    group.attrs.priority = 1
    if(currSelectedLayout){
        applyLayout(currSelectedLayout,currLayoutName)
    }else{
        let layout = getLayout()
        currSelectedLayout = layout
        currLayoutName = "a"
        applyLayout(layout,"a")                 
    }  
}

export function disablePrioritySelectionMode() {
    $("#prioritySelectorWrapper").removeClass("--is-active")
}

export function showPriorityToggleButton() {
    if(moodboardConfig.getImagesList()?.length > 1){
        $("#priorityFormToggle").addClass("--is-active")
    }
}

export function hidePriorityToggleButton() {
    $("#priorityFormToggle").removeClass("--is-active")
}