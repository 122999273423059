import React from "react";
import Button2 from "../../../../common/ui-components/buttons/Button2";
 

const KairaAbout: React.FC<{
}> = (props) => {

 
  return (
    <div className="configurator-link padding-container full-width" style={{margin:"5rem 0"}}>

      <div className="section position-relative padding-container">
        <div className="position-relative" style={{width:"100%",margin:"auto"}}>
          <img style={{objectFit:"cover",width:"100%",height:"32rem"}} className="" src="https://kairafabrics.in/wp-content/themes/kairafab/img/ab1.jpg" alt="" />
        </div>
        <img style={{objectFit:"cover",width:"25rem",height:"20rem",right:"5rem",bottom:"-3rem"}} className="bottom-right" src="https://kairafabrics.in/wp-content/themes/kairafab/img/ab2.jpg" alt="" />
      </div>

       <div className="section details padding-container">
          <div className="heading3 marginTop10">About us</div>
          <div className="heading1 font-ex-large ion-margin-top">Kaira : the house of sofa fabrics</div>
          <div className="heading4 color-black ion-margin-top font-normal line-height-2" style={{textAlign:"justify"}}>
          Kaira is an entity under Kurikkal group who specialize in a wide range of sofa fabrics and leathers. Kaira ensures enhanced remarkable qulaity and availability to the doorsteps. with the physical presence , Kaira focuses on best sofa fabrics and leather solutionswhich brings affordability, design and comfort. kaira born by understanding the change of the things in the furniture industry for the last few years, we are a perfect blend of form and function with an emphasis on quality and style.
          </div>
          <div className="" style={{marginTop:"2rem"}}>
            <Button2
                configObj={{id:""}}
                action={()=>{}}
                label={"Know More"}
                fontAwesomeIcon={"far fa-eye elevate-color-primary font-large"}
                iconPos=""
                classNames={"shadow-light button-medium margin0 text-center bg-color-primary"}
              />
          </div>
       </div>
    </div> 
  );
};

export default KairaAbout;
