import React, { useEffect, useState } from "react";
import { getArrayOfDistinctValues, getFilteredArray } from "../../../../../../../utils/methods";
import { getTodayTimestampOnlyDate } from "../../../../../../../utils/store/crm/calendar";
import { getFilteredLeadsWithOutcome, getFormatedDate, outcomesList } from "../../../../../../../utils/store/crm/crm";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
import "../../../../../../style/store/crm/tasks.css";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import Modal from "../../../../../../common/shared-components/Modals/Modal";
import Calendar from "../calender/Calendar";
import { showGlobalToast } from "../../../../../../../utils/UI_methods/global";

const TodayTasks: React.FC<{
    refreshLeads:number
    allLeads:any
    filterLeadsWithTime:(tabname:string,list:any,time:string)=>void
}> = (props) => {

    const[agendaList,setAgendaList] = useState([])
    // const[tabsList] = useState(["Pending","Completed"])
    const[currSelectedTab,setCurrSelectedTab] = useState("")
    const[isOpenCalendar,setIsOpenCalendar] = useState(false)

    const todayTimeStamp = getTodayTimestampOnlyDate()
    
    const[outcomes] = useState(outcomesList)

    const tabsList = [
        {
          displayName:"Pending",
          outcomeName:"Pending",
          icon:"fas fa-exclamation-circle",
        },
        {
          displayName:"Completed",
          outcomeName:"Completed",
          icon:"fas fa-check-square",
        } 
      ]

    useEffect(()=>{
        CRM.crmApi.activity.getActivities().then(data=>{
            if(!data.data.error){
                let list = data.data.data
                list = list.filter(currActivity => currActivity.start_date === todayTimeStamp && currActivity.status === 1)
                // list = getFilteredArray(list,"start_date",todayTimeStamp)
                setAgendaList(list)
                setCurrSelectedTab(tabsList[0].outcomeName)
            }
        })
    },[props.refreshLeads])

 

    function filterLeadsWithTime(currTab:string) {
        let leadIdList = getArrayOfDistinctValues(agendaList,"lead_id")
        let filteredLeads = props.allLeads.filter(currLead => leadIdList.includes(currLead.lead_id))
        // props.filterLeadsWithTime(currTab,filteredLeads,"today")
        return filteredLeads
    }


    function openTodaysLeadsHandle(filteredLeads:Array<any>,currTab:any) {
        props.filterLeadsWithTime(currTab,filteredLeads,"today")
        if(filteredLeads.length){
            showGlobalToast(`Showing ${filteredLeads.length} lead${filteredLeads.length>1?"s":""}` , 2000)
        }
    }

 
    return (
    <div className="full-height-width today-tasks-wrapper display-flex-column  position-relative">

        <Modal
            id=""
            classNames="large-x-modal"
            onDismiss={()=>setIsOpenCalendar(false)}
            isOpen={isOpenCalendar}
            Content={()=>(
                <Calendar lead={null} isSetEventMode={true} activityEvent={null}/>
            )}
            heading="Activities"
            FooterContent={()=>(<div></div>)}
            >
        </Modal>

        <div className="header border-bottom full-height">
            <div className="display-flex-row no-wrap space-between padding10 bg-color-light">
                {/* <div className="width50 border-right center-aligned-column" style={{padding:"15px 0"}}>
                    <span className=""><i className="fas fa-phone-alt font-large color-black"></i></span>
                </div>
                <div className="width50 center-aligned-column" style={{padding:"15px 0"}}>
                    <span className=""><i className="fas fa-calendar font-large color-black"></i></span>
                </div> */}

                <div className="" style={{padding:"2px 5px"}}>
                    <div className="color-black heading2">
                        Your Agenda
                    </div>
                    <div className="heading4 margniTop10">
                        {getFormatedDate(getTodayTimestampOnlyDate())}
                    </div>
                </div> 

                <div className="center-aligned-column">
                    <Button2
                        configObj={{id:""}}
                        action={()=>{setIsOpenCalendar(true)}}
                        label={""}
                        fontAwesomeIcon={"fas fa-calendar-alt color-black"}
                        iconPos="left"
                        classNames={"no-shadow bg-color-warning-outline"}
                    />
                </div>
            </div>

            {/* <VerticalTabs 
                currSelectedTab={currSelectedTab} 
                configObj={{}} 
                allLeads={agendaList} 
                onChange={onChangeHandle} 
                tabsList={tabsList} 
                Content={AgendaList}
            /> */}


            <div className="agenda-list padding5 overflow-y-scroll">
                {outcomes.map((outcome:any,index:number)=>{
                    // let filteredLeads = getFilteredLeadsWithOutcome(props.allLeads,outcome.outcomeName)
                    let filteredLeads = filterLeadsWithTime(outcome.outcomeName)
                    filteredLeads = getFilteredLeadsWithOutcome(filteredLeads,outcome.outcomeName)
                    // filteredLeads = filterLeadsWithTime(outcome.outcomeName)
                    return(
                        <div onClick={()=>{openTodaysLeadsHandle(filteredLeads,outcome.outcomeName)}}  className="display-flex-row pointer no-wrap border white-card no-shadow ion-margin-top" key={index}>

                            <div className="bg-color-warning" style={{height:"50px",width:"3px"}}>
                            </div>

                            <div className="display-flex-row pointer-none space-between no-wrap full-width marginleft10">
                                <div className="center-aligned-column">
                                    <div className="display-flex-row no-wrap">
                                        <div className="heading3 font-md-small color-black">{outcome.outcomeName}</div>
                                    </div>
                                </div>
                                <div className="center-aligned-column padding5">
                                    <div className="border white-card shadow-light heading2 bg-color-dark color-white font-small center-aligned-column" style={{width:"22px",height:"22px"}}>
                                        {filteredLeads.length}
                                    </div>
                                   
                                </div>

                            </div>
                        </div>
                    )
                })}
            </div>

            

            

        </div>
        
    </div>
    );
};

export default TodayTasks;
