import $ from "jquery";
import React, { useEffect, useState } from "react";
import { displayThumbnails, hideComponentLoader, removeActiveClassFromAllTextures, removeClassFromElement, showComponentLoader, showGlobalToast } from "../../../../../../utils/UI_methods/global";
 
import AwsConfig from "../../../../../../utils/aws-sdk/AwsConfig";
import { untarAndSaveToCache } from "../../../../../../utils/cache/cache";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, waitFor } from "../../../../../../utils/methods";
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import DropDown from "../../../../../common/ui-components/filters/DropDown";
import MaterialSearch from "../../../../../common/ui-components/search/MaterialSearch";
import MaterialImage from "../../../../../common/ui-components/elements/MaterialImage";
  
  const MaterialSelector: React.FC<{
    materials:any
    action:(event:any)=>void
    selectedOptionIds:Array<any>
    setSelectedOptionsIds:(val:any)=>void
  }> = (props) => {
  
  
    let awsConfig = new AwsConfig()
  
    const[textureLoaderId] = useState("texturesLoader")
  
    const[materialTypesMaterials,setMaterialTypesMaterials]:any = useState([])
    const[materials,setMaterials]:any = useState(props.materials)
    const[collections,setCollections]:any = useState([])
    const[companies,setCompanies]:any = useState([])
    const[currSelectedMaterialType,setCurrSelectedMaterialType] = useState<string>("")
    const[currSelectedCompany,setCurrSelectedCompany] = useState<string>("")
    const[currSelectedCollection,setCurrCollectionName] = useState<string>("")
    const[isSearchModle,setIsSearchModle] = useState<boolean>(false)
  


  
    useEffect(()=>{
      if(props.materials){
        let allMaterialTypes:any = getArrayOfDistinctValues(props.materials,"material_type")
        setCurrSelectedMaterialType(allMaterialTypes[0])
      }
  
    },[props.materials])

  
  
    //Untar file for all collections
    useEffect(()=>{
      if(currSelectedMaterialType?.length){
        let filteredMaterials = getFilteredArray(props.materials,"material_type",currSelectedMaterialType)
        setMaterialTypesMaterials(filteredMaterials)
    
        let companyNames:Array<any> = getArrayOfDistinctValues(filteredMaterials,"company_name")
        setCompanies(companyNames)
        setCurrSelectedCompany(companyNames[0])
  
        removeActiveClassFromAll()
      }
    },[currSelectedMaterialType])

    //SET collectiosn
    useEffect(()=>{
      if(currSelectedCompany?.length && materialTypesMaterials.length){
        let filteredArray = getFilteredArray(materialTypesMaterials,"company_name",currSelectedCompany)
        let allCollections = getArrayOfDistinctValues(filteredArray,"collection_name")
        setCollections(allCollections)
        let collectionName:any = allCollections[0] || "All"
        setCurrCollectionName(collectionName)
      }
    },[currSelectedCompany,materialTypesMaterials])

    //set materials and load thumbnails
    useEffect(()=>{
      if(currSelectedCompany != "" && currSelectedCollection!= "" && materialTypesMaterials.length){
        let array = materialTypesMaterials.filter(material=> material.company_name === currSelectedCompany && material.collection_name === currSelectedCollection)
        setMaterials(array)
      }
    },[currSelectedCompany,currSelectedCollection,materialTypesMaterials])

    useEffect(()=>{
      if(materials.length){
          loadAndDisplayThumbails()
      }
    },[materials])



    async function loadAndDisplayThumbails(){
      showComponentLoader(textureLoaderId)
      let key = awsConfig.getTarFileKey("thumbnails",{companyName:currSelectedCompany,collectionName:currSelectedCollection})
      await untarAndSaveToCache(awsConfig,`thumbnails/OVL/${currSelectedCompany}/${currSelectedCollection}`,key)
      await waitFor(500)
      displayThumbnails(textureLoaderId,null)
      await waitFor(2000)
      hideComponentLoader(textureLoaderId)
    }
  
  
    function filterMaterialsWithMaterialType(value:string){
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setCurrSelectedMaterialType(value)
    }
 
  
    function filterMaterialsWithCollection(value:string){
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setCurrCollectionName(value)
    }
  
    function filterMaterialsWithCompany(value:string){
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setCurrSelectedCompany(value)
    }
  
  
    

    function removeActiveClassFromAll(){
      removeActiveClassFromAllTextures()
      $(".texture-label-container").removeClass("--is-active")
    }

    function activateSearchMode(materials:Array<any>,searchString:string) {
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setMaterials(materials)
      setIsSearchModle(true)
    }
   

    function toggleSelection(id:number) {
  
      props.setSelectedOptionsIds(prevValue=>{
        if(prevValue.includes(id)){
          return prevValue.filter(currValue => currValue !== id)
        }else{
          return [...prevValue,id]
        }
      })
    }


    function resetMaterials() {
      setMaterials(props.materials)
      setIsSearchModle(false)
    }

    return (
      <React.Fragment>

      <div className="materials-filters-wrapper">
      <Loader 
        className="fade-background-loader"
        id={textureLoaderId}
        data={{}}
        loaderType="progressCompact"
      />

      <div id="searchWrapperMaterial" className="full-height-width material-wrapper-mobile bg-color-white" style={{position:"fixed",zIndex:10005}}>
        <MaterialSearch
            configObj={applicationConfig}
            currSelectedMaterialType={currSelectedMaterialType}
            activateSearchMode={activateSearchMode}
            filterMaterialWithMaterialType={filterMaterialsWithMaterialType}
            filterMaterialWithCollection={filterMaterialsWithCollection}
            idSuffix={"materialsPanelForMobile"}
        />

        <div className="bottom-middle ion-margin-bottom">
          <Button2
            configObj={{id:""}}
            action={()=>removeClassFromElement("searchWrapperMaterial","--is-active")}
            label={"Close"}
            fontAwesomeIcon={"fas fa-close color-black"}
            iconPos="left"
            classNames={"shadow-light bg-color-light"}
          />
        </div>
      </div>
      

      {/* <div className="bottom-right zindex100 hide-in-desktop" style={{bottom:"80px",right:"20px"}}>
            <Button2
                configObj={{id:""}}
                action={()=>openMaterialSearchHandle()}
                label={""}
                fontAwesomeIcon={"fas fa-search font-medium color-danger"}
                iconPos="left"
                classNames={"button-rounded button-large bg-color-primary"}
            />
      </div> */}

      {/* <div classNme="fade-background-loader top-left zindex100 full-height-width display-none" id="materialsWrapperBlocker"></div> */}
  
        <div className="product-material-dropdown-wrapper no-wrap display-flex-row space-between" style={{margin:"0.5rem 0"}}>
              
                <DropDown
                  classNames="hide-in-desktop"
                  columns={1}
                  values={getArrayOfDistinctValues(props.materials,"material_type")}
                  currSelectedValue={currSelectedMaterialType}
                  action={filterMaterialsWithMaterialType}
                  label="MaterialType"
                />
              
              <DropDown
                classNames=""
                columns={1}
                values={companies}
                currSelectedValue={currSelectedCompany}
                action={filterMaterialsWithCompany}
                label="Company"
                />
                <DropDown
                classNames=""
                columns={1}
                values={collections}
                currSelectedValue={currSelectedCollection}
                action={filterMaterialsWithCollection}
                label="Collections"
                />
          </div>

          
  

          <div className="full-width hide-in-mobile">
            <MaterialSearch
              configObj={applicationConfig}
              currSelectedMaterialType={currSelectedMaterialType}
              activateSearchMode={activateSearchMode}
              filterMaterialWithMaterialType={filterMaterialsWithMaterialType}
              filterMaterialWithCollection={filterMaterialsWithCollection}
              idSuffix={"materialsPanel"}
            />
          </div>

          {isSearchModle?
          <div className="marginTop10">
            <Button2
                configObj={{id:""}}
                action={resetMaterials}
                label={"All materials"}
                fontAwesomeIcon={"fas fa-arrow-left color-danger"}
                iconPos="left"
                classNames={"Go back bg-color- margin0"}
            />
          </div>
          :null}
          
          
   
        </div>

        
  
        <div id="materialsWrapper" className="materials-wrapper marginTop10  overflow-y-scroll display-flex-row ion-padding-bottom">
  
          {materials?.map((material: any, index: number) => {
            // let materialUrl = getMaterialsImageUrl(props.configObj?.BASE_URL_MODEL_VIEWER,material.company_name,material.collection_name,material.material_code)
            return (
              <div className={`texture-label-container material-container item finish-image-container ${props.selectedOptionIds.includes(material.id)?" --is-active":""}`}
                id={`Finish_${material.company_name}_${material.collection_name}_${material.material_code}`}
                data-company-name={material.company_name}
                data-collection-name={material.collection_name}
                data-material-id={material.id}
                data-material-code={material.material_code}
                data-material-type={material.material_type}
                data-uv-scale={material.uv_scale}
                data-roughness-value={material.roughness}
                data-metalness-value={material.metalness}
                data-specular-value={material.specular}
                data-clearcoat-value={material.clearcoat}
                data-sheen-value={material.sheen}
                onClick={()=>toggleSelection(material.id)} 
                key={index}
                >

              

              <div className={`position-relative`}>
                 
                <div className="top-right zindex100">
                  {props.selectedOptionIds.includes(material.id)?
                  <i className="far fa-check-circle select-icon font-large"></i>
                  :
                  <i className="far fa-circle select-icon font-large"></i>
                  }
                </div>

                <div className="texture ion-padding">
                <div className="texture-name-mobile bottom-middle text-center text-ellipsis">{material.material_code}</div>
                  <img className="texture-img" style={{objectFit:"none"}} />
                </div>

                <div className="texture-details ion-padding">
                  <div className="detail center-aligned-column color-black heading3 text-center">{material.material_code}</div>
                </div>


              </div>
          
          

             
            </div>       
            );
          })}

          <div className="item no-border"></div>
          <div className="item no-border"></div>
          <div className="item no-border"></div>
          <div className="item no-border"></div>
          <div className="item no-border"></div>
        </div>
  
      </React.Fragment>
    );
  };
  
  export default MaterialSelector;
  