export function setCreateProjectFormData(data:any){
    let projectNameInput: any = document.getElementById("projectName")!;
    let projectLocationInput: any = document.getElementById("projectLocation")!;
    let projectTypeInput: any = document.getElementById("projectType")!;
    let developerInput: any = document.getElementById("developer")!;
    let clientNameInput: any = document.getElementById("clientName")!;
    let clientMobileInput: any = document.getElementById("clientMobile")!;

    if(projectNameInput){
      projectNameInput.value = data.project_name
      projectLocationInput.value = data.location
      projectTypeInput.value = data.project_type
      developerInput.value = data.developer
      clientNameInput.value = data.client_name
      clientMobileInput.value = data.client_mobile_number
    }

   
  }


  export  function setProjectIdInLocalStorage(projectId:number){
    localStorage.setItem("createdProjectId",JSON.stringify(projectId))
  }

  export  function getCreatedProjectIdFromLocalStorage(){
    let projectId:string = localStorage.getItem("createdProjectId")!
    if(projectId){
      return parseInt(projectId)
    }
    return projectId
  }

  export  function removeProjectIdFromLocalStorage(){
    localStorage.removeItem("createdProjectId")
  }