import React, { useEffect, useState } from "react";
import { checkFromCache } from "../../../../../utils/cache/cache";
import { displayUploadedImage } from "../../../../../utils/inventory/inventory";
import { hideComponentLoader, ifEmptyFields, showComponentLoader } from "../../../../../utils/UI_methods/global";
import $ from "jquery"
import Button from "../../buttons/Button2";
import { displayWithoutBgImage, getRemovedBgImageFile } from "../../../../../utils/rembg/rembg";

const Review: React.FC<{
  selectedFile:any
  finalizeType:string
  initialState:()=>void
  uploadImage: (val: any) => void
  setIsOpen: (val: boolean) => void
}> = (props) => {
  
  const [loaderId] = useState("rembgLoader");

  useEffect(()=>{
    showComponentLoader(loaderId);
    setTimeout(() => {
      if(props.finalizeType === "original"){
        displayUploadedImage(props.selectedFile,"withbgImage")
      }else{
        displayWithoutBgImage()
      }
      hideComponentLoader(loaderId);
    }, 200);
   
  },[props.finalizeType])



  function uploadFile() {
    // if(ifEmptyFields()){
    //   return
    // }
    let formData = {
      selectedFile:null,
      name:$("#imageName").val() || props.selectedFile.name,
      description:$("#imageDescription").val(),
    }

    if(props.finalizeType === "original"){
      formData.selectedFile = props.selectedFile
      props.setIsOpen(false)
      props.uploadImage(formData)
    }else{
      getRemovedBgImageFile(props.selectedFile.name).then(file=>{
        formData.selectedFile = file
        props.setIsOpen(false)
        props.uploadImage(formData)
      }).catch(err=>{
        console.log(err)
      })
    }
    
  
  }
 
  
  return (
      <div className="full-height-width ion-padding">
        <div className="flex-row-50 full-height-width">
          <div className="ion-padding full-height center-aligned-column">
            {/* <div className="heading2 text-center color-danger">Original Image</div> */}
            <div className="center-aligned-row padding10">
              <div>
                <div className="center-aligned-row"><img src="" height={250} id={props.finalizeType === "original" ? "withbgImage" : "withoutbgImage"} alt="" /></div>
                  {/* <div className="ion-margin-top center-aligned-row">

                    <Button
                      configObj={{}}
                      action={()=>props.initialState()}
                      label="Go back"
                      fontAwesomeIcon="fas fa-undo color-danger"
                      iconPos="left"
                      classNames="button-light shadow-light"
                    />
                  </div> */}
              </div>
            </div>
          </div>

          <div className="ion-padding full-height-width center-aligned-column">
            {/* <div className="heading2 text-center color-danger">Processed Image</div> */}
            <div className="padding10 full-width">
              <div className="full-width">

                <div className="form-label-input-container">
                  <div className="label-container">
                    <div className="label">Name <span className="mandatory">*</span></div>
                  </div>
                  <div className="input-container">
                    <input
                      className="input input-mandatory"
                      type="text"
                      name="imageName"
                      id="imageName"
                      required
                    />
                  </div>
                </div>

                <div className="form-label-input-container">
                  <div className="label-container">
                    <div className="label">Description</div>
                  </div>
                  <div className="input-container">
                    <textarea
                      name=""
                      rows={3}
                      id="imageDescription"
                      className="input full-width"
                      style={{ height: "auto" }}
                    ></textarea>
                  </div>
                </div>


                <div className="display-flex-row padding5 ion-margin-top">

                  <button className="op-btn op-btn-light display-flex-row" onClick={()=>props.initialState()}>
                        <i className="fas fa-times font-small center-aligned-column marginRight5 color-danger"
                        aria-hidden="true" style={{marginTop:"3px"}}></i>
                         Cancel 
                  </button>

                  <button className="op-btn op-btn-primary display-flex-row marginleft10" onClick={uploadFile}>
                        Confirm upload
                        <i className="fas fa-upload font-small center-aligned-column marginleft5"
                        aria-hidden="true" style={{marginTop:"3px"}}></i>
                  </button>

                </div>
                
              </div>
            </div>
          </div>
        </div>


      </div>
  );
};

export default Review;
