import $ from "jquery"


export function showProductModel(event:any){
    $("#imageContainer").removeClass("--is-active")
    $("#product3DmodelContainer").addClass("--is-active")
    $(".view-in-3d-button").removeClass("--is-active")
    $(event.target).addClass("--is-active")
}



export function hideProductModel(event:any){
    $("#imageContainer").addClass("--is-active")
    $("#product3DmodelContainer").removeClass("--is-active")
    $(".view-in-3d-button").removeClass("--is-active")
    $(event.target).removeClass("--is-active")


}

export function getModelViewerElement(){
    return document.querySelector("model-viewer#productModal")
}


export function getProductModelContainerElement(){
    return  $("#product3DmodelContainer")
}

