import React, { useEffect } from "react";
import { createProductForm } from "../../../../utils/products/createProductForm";
import { displayUploadedImage } from "../../../../utils/inventory/inventory";

const ProductForm: React.FC<{
  productDetails:createProductForm
  galleryImages:any
}> = (props) => {


  useEffect(()=>{
    if(props.galleryImages.length){
      for (let i = 0; i < props.galleryImages.length; i++) {
        const file = props.galleryImages[i];
        displayUploadedImage(file,`image_${i}`)
      }
    }
    displayUploadedImage(props.productDetails.productImageFile,`mainProductImage`)
  },[props.galleryImages])

 

  function ProductDetailsComponent() {
    return(
      <div className="white-card no-shadow padding5">

      <div className="display-flex-row">
        <div className="width50 padding5">
          <div className="white-card no-shadow display-flex-row padding10 space-between">
            <div className="heading4">Product Name</div>
            <div className="heading3">{props.productDetails.productName}</div>
          </div>
        </div>
        <div className="width50 padding5">
          <div className="white-card no-shadow display-flex-row padding10 space-between">
            <div className="heading4">SKU</div>
            <div className="heading3">{props.productDetails.sku}</div>
          </div>
        </div>
      </div>

      <div className="display-flex-row">
        <div className="width50 padding5">
          <div className="white-card no-shadow display-flex-row padding10 space-between">
            <div className="heading4">Category</div>
            <div className="heading3">{props.productDetails.categoryName}</div>
          </div>
        </div>
        <div className="width50 padding5">
          <div className="white-card no-shadow display-flex-row padding10 space-between">
            <div className="heading4">Sub Category</div>
            <div className="heading3">{props.productDetails.subCategory}</div>
          </div>
        </div>
      </div>

      <div className="display-flex-row">
        <div className="width50 padding5">
          <div className="white-card no-shadow display-flex-row padding10 space-between">
            <div className="heading4">Is Template Available </div>
            <div className="heading3">{props.productDetails.isTemplate?"Yes":"No"}</div>
          </div>
        </div>
        <div className="width50 padding5">
          <div className="white-card no-shadow display-flex-row padding10 space-between">
            <div className="heading4">Is Leather Material Available </div>
            <div className="heading3">{props.productDetails.isLeatherAvailable?"Yes":"No"}</div>
          </div>
        </div>
      </div>


      </div>
    )
  }
 

  function DefaultConfig() {
    return(
      <div className="white-card no-shadow padding5">
        <div className="display-flex-row">
          <div className="width50 padding5">
            <div className="white-card no-shadow display-flex-row padding10 space-between">
              <div className="heading4">Variant</div>
              <div className="heading3">{props.productDetails.defaultConfiguration.variant}</div>
            </div>
          </div>
          <div className="width50 padding5">
            
          </div>
        </div>
      </div>
    )
  }
 

  function ProductImages() {
    return(
      <div className="white-card no-shadow padding10">
        <div className="heading3 color-dark">Default Image</div>
        <div style={{width:"calc(100%/3)"}} className={`marginTop5`}>
              <img  className="padding0 full-width" id={`mainProductImage`} alt=""/>
        </div>
        <div className="heading3 color-dark marginTop5">Other Images</div>
        <div className="display-flex-row space-between padding5">
        {props.galleryImages.map((file:any,index:number)=>{
          return(
            <div key={index} style={{width:"calc(100%/3)"}} className={` marginTop5`}>
                <img  className="padding0 full-width marginleft5" id={`image_${index}`} alt=""/>
            </div>
          )
        })}
        </div>
       
      </div>
    )
  }

  function ProductModel() {
    return(
      <div className="white-card no-shadow padding10">
        <div className="ion-padding white-card border no-shadow heading4 text-center">Not available</div>
      </div>
    )
  }


  function Modules() {
    return(
      <div className="white-card no-shadow padding10">
        <div className="ion-padding white-card border no-shadow heading4 text-center">Not available</div>
      </div>
    )
  }

  return (
    <div className="modal-body bg-color-ex-light ion-padding">
    <div className="full-height-width overflow-y-scroll">

      <div className="display-flex-row">
        <div className="width70 padding5">
          <div className="border">
            <div className="heading3 bg-color-light color-black padding10">Product details</div>
            <ProductDetailsComponent/>
          </div>

          <div className="border marginTop10 display-none">
            <div className="heading3 bg-color-light color-black padding10">Default Configuration</div>
            <DefaultConfig/>
            
          </div>

        </div>
        <div className="width30 padding5">

          <div className="border">
          <div className="heading3 bg-color-light color-black padding10">Product Images</div>
          <ProductImages/>
          </div>
          <div className="border ion-margin-top">
          <div className="heading3 bg-color-light color-black padding10">Product 3d model</div>
          <ProductModel/>
          </div>

          <div className="border ion-margin-top">
          <div className="heading3 bg-color-light color-black padding10">Product Modules</div>
          <Modules/>
          </div>

          
        </div>
      </div>

      
    </div>
  </div>
  );
};

export default ProductForm;
