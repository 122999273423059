import { applicationConfig } from "../methods"

 
  export class createFinishForm{
  
    companyName:string = ""
    companyId:number = 0
    collectionName:string = ""
    collectionId:number = 0
    materialType:string = ""
    materialTypeId:number = 0
    materialCodes:Array<string> = []
    
    
    finishImageFiles:any = []
  
    constructor(product:any = null){
       
    }
  }
  