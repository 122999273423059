import React, { useState } from "react";
 

const Footer: React.FC<{
}> = (props) => {
 

 
  return (
    <div className="full-width padding-container bg-color-warning" style={{height:"5rem",marginTop:"3rem"}}>
      <div className="display-flex-row marginTop10">
        <div className="width50 padding-container">
          
          
        </div>
        <div className="width50 padding-container">

        </div>
      </div>
      
    
    </div>
  );
};

export default Footer;
