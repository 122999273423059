import $ from "jquery"
import { Box3, Vector3 } from "three"
import { toScreenPosition } from "../common"
 
import { getObjectDimensionPosition } from "../modules/helper"
import { Floorplan } from "./floorplan"
import { convert3dPointsToFeet } from "./helper"
import { currSelectedFloorplanObject } from "./raycasting"

const vector = new Vector3()
const boundingBox = new Box3()
var floorPositionDimensions = null


var canvas = null
var annotationAnimationId = undefined

export var isPointerDownModulesDragIcon = false

export var wallDimensions = {
  feet:0,
  inch:0
}

 
var floorplanner = null

export function annotationsInit(floorplanObject:Floorplan) {
    floorplanner = floorplanObject
}


export function updateAnnotationsPosition(wall:any = currSelectedFloorplanObject) {
  let object = wall;
  if(object){
    let box = getObjectDimensionPosition(null, object);
    let center = box.center;

    let annotation: any = document.querySelector(`#floorplanAnnotationWrapper`);

    canvas = floorplanner.renderer?.domElement;
    if (annotation) {
      vector?.set(center.x, center.y, center.z);
      toScreenPosition(vector, floorplanner.camera, canvas);

      annotation.style.top = `${vector?.y + 10}px`;
      annotation.style.left = `${vector?.x - 80}px`;
    }
  }
}



export function showAnnotations(wall:any) {
  updateAnnotationsPosition(wall)
  $(`#floorplanAnnotationWrapper`).removeClass("display-none")
  // setTimeout(() => {
  //   updateWallDimFormValues(wall)
  // }, 200);
}

export function hideAnnotation() {
  $(`#floorplanAnnotationWrapper`).addClass("display-none")
}

export function removeWallDimensions() {
  $(`.floor-dimensions`).remove()
}

export function showWallDimensionsDropDown(wall:any) {
  $(`#wallDimensions`).removeClass("display-none")
  setTimeout(() => {
    updateWallDimFormValues(wall)
  }, 200);
}

export function hideWallDimensionsDropDown() {
  $(`#wallDimensions`).addClass("display-none")
}

export function updateWallDimFormValues(wall:any) {
  if(wall && wall.userData.isWall){
    let vertex1 = floorplanner.getVertexFromName(wall.userData.vertex1Name).position
    let vertex2 = floorplanner.getVertexFromName(wall.userData.vertex2Name).position

    // var distance = vertex1.distanceTo(vertex2);
    // let dimData = getConvertedLengthBreadth(distance,distance,"feet")
    let dimData = convert3dPointsToFeet(vertex1,vertex2)

    $("#wallDimFeetValue").val(dimData.feet)
    $("#wallDimInchValue").val(dimData.inch)

    wallDimensions.feet = Number(dimData.feet) 
    wallDimensions.inch = Number(dimData.inch) 

  }
  
}