import React, { useEffect, useState } from "react";
import { applicationConfig } from "../../../../../../utils/methods";
import Modal from "../../../../../common/shared-components/Modals/Modal";
import DebugOptions from "../common/DebugOptions";


const TakeRender: React.FC<{
}> = (props) => {
  const[showRenderModal,setShowRenderModal] = useState(false)


  useEffect(()=>{
    applicationConfig.setFunctionRef("customizer",{setShowRenderModal:setShowRenderModal})
  },[])

   
  function ConfirmationModalFooter() {
    return(
      <div className="">
      <button className="op-btn op-btn-light" onClick={() => setShowRenderModal(false)}>Close</button>
      </div>
    )
  }



  return (
    <Modal
    isOpen = {showRenderModal}
    Content = {()=>(
      <DebugOptions
        isOpenDebugOptions={showRenderModal}
        setIsOpenDebugOptions={setShowRenderModal}
      />
    )}
    onDismiss = {()=>setShowRenderModal(false)}
    heading="Render options"
    classNames="medium-x-modal"
    id=""
    FooterContent={ConfirmationModalFooter}
  />
  );
};

export default TakeRender;
