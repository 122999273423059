import { Box3, Group, Object3D, Vector3 } from "three"
import { getModelBoundingBox, updateModelBoundingBox } from "../common"
import { getObjectDimensionPosition } from "../modules/helper"
import { customizerConfig, kitchenPlanner } from "./area3dModel"
import { getFrontAndBackPlane } from "./walls"

export class ClampObject{
    
    objectPosMinX = 0
    objectPosMaxX = 0
    isReachedMinX = false
    isReachedMaxX = false

    objectPosMinZ = 0
    objectPosMaxZ = 0
    isReachedMinZ = false
    isReachedMaxZ = false

    deltaForRotate = 0.5


    groupForClampModules:Group = null
    clampBox: Box3

    constructor(){

    }


    clampObject(object:Object3D) {
        let objectData = getObjectDimensionPosition(null,object)
        let objectPos = objectData.positions
        let objectDim = objectData.dimensions
        let data = this.clampBox
        let min = data.min
        let max = data.max

        let snappedWall = this.checkCollision(object)
        if(snappedWall && customizerConfig.isAutoRotation){
            let worldDirection = new Vector3()
            snappedWall.getWorldDirection(worldDirection)
            let detectedWallData = getObjectDimensionPosition(null,snappedWall)
            let center = detectedWallData.center
            let detectedWallPositions = detectedWallData.positions
            let normal = snappedWall.userData.normal

            if(
                //top
                (objectPos.max.x + objectDim.dimZ < detectedWallPositions.max.x
                && normal.z === 1)
                ||
                // bottom
                (objectPos.min.x + objectDim.dimZ > detectedWallPositions.min.x
                && normal.z === -1)
                ||
                 //left
                 (objectPos.min.z - objectDim.dimX > detectedWallPositions.min.z
                && normal.x === 1)
                ||
                //right
                (objectPos.max.z + objectDim.dimX < detectedWallPositions.max.z
                && normal.x === -1)
            ){
                //Rotation
                object.userData.snappedWall = snappedWall
                kitchenPlanner.rotateSurfaceProduct(object)
                //Position to wall vertices 
                let x = center.x + (normal.x*0.01)
                let z = center.z + (normal.z*0.01)
                if(normal.x){
                    object.position.setX(x)
                }
                if(normal.z){
                    object.position.setZ(z)
                } 
            }

           
        }


        if(objectPos.min.x < min.x){
            if(!this.isReachedMinX){
                this.objectPosMinX = object.position.x
            }
            this.isReachedMinX = true
            this.deltaForRotate =  Math.abs(object.position.x) - Math.abs(this.objectPosMinX)
            object.position.x = this.objectPosMinX
        }
    
        if(objectPos.max.x > max.x){
            if(!this.isReachedMaxX){
                this.objectPosMaxX = object.position.x
            }
            this.isReachedMaxX = true
            this.deltaForRotate =  Math.abs(object.position.x) - Math.abs(this.objectPosMaxX)
            object.position.x = this.objectPosMaxX
        }

        if(objectPos.min.z < min.z){
            if(!this.isReachedMinZ){
                this.objectPosMinZ = object.position.z
            }
            this.isReachedMinZ = true
            this.deltaForRotate =  Math.abs(object.position.z) - Math.abs(this.objectPosMinZ)
            object.position.z = this.objectPosMinZ
        }
    
        if(objectPos.max.z > max.z){
            if(!this.isReachedMaxZ){
                this.objectPosMaxZ = object.position.z
            }
            this.isReachedMaxZ = true
            this.deltaForRotate =  Math.abs(object.position.z) - Math.abs(this.objectPosMaxZ)
            object.position.z = this.objectPosMaxZ
        }


        
    
    }

    createBoundingBoxForClamp(wallsGroup:Group){
        // let delta = 0  + 0.22000616306547727
        let delta = 0 
        if(!this.groupForClampModules){
          this.groupForClampModules = new Group()
          wallsGroup.children.forEach((wall:any) => {
            let planes = getFrontAndBackPlane(wall,"Kitchen")
            planes.FrontPlane.material.color.setHex(0xff0000)
            planes.FrontPlane.visible = true
            this.groupForClampModules.attach(planes.FrontPlane)
          });
          this.clampBox = getObjectDimensionPosition(null,this.groupForClampModules).positions
        //   this.clampBox.expandByScalar(-0.5)
          this.clampBox.min.x = this.clampBox.min.x + delta
          this.clampBox.max.x = this.clampBox.max.x - delta

          this.clampBox.min.z = this.clampBox.min.z - delta
          this.clampBox.max.z = this.clampBox.max.z + delta
        }
    }

    resetClampObjectParameters(){
        this.objectPosMinX = 0
        this.objectPosMaxX = 0
        this.isReachedMinX = false
        this.isReachedMaxX = false
    
        this.objectPosMinZ = 0
        this.objectPosMaxZ = 0
        this.isReachedMinZ = false
        this.isReachedMaxZ = false
    }

    checkCollision(object:any){
        let result = null
        this.groupForClampModules.children.forEach(currWall => {
            updateModelBoundingBox(object)
            if(getObjectDimensionPosition(null,currWall).positions.intersectsBox(getModelBoundingBox(object))){
                result = currWall
                return
            }
        });
        return result
    }

    // getNearestWall(){
    //     let intersectObject = null
    //     for (const direction of directions) {
    //         let intersectObjectData = getIntersectObject(object,direction)
    //         if(intersectObjectData){
    //             intersectObject = intersectObjectData.object
    //             setIntersectObjectEndPoints(direction,getObjectDimensionPosition(null,intersectObject))
    //             if(direction.includes("left") || direction.includes("right")){
    //                 distance = Math.abs(endPoints.x-startPoints.x)
    //             }else{
    //                 distance = Math.abs(endPoints.z-startPoints.z)
    //             }
    //             distances.push({
    //               distance:distance,
    //               detectedObject:intersectObject,
    //               normal:getNormal(getDirectionName(direction))
    //             })
    //         }
    //     }
    // }

}