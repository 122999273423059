import {
  IonIcon
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { rippleEffectOnClick } from "../../../../utils/UI_methods/ui_effects";
import { getTabslistFromSubDomain } from "../../../../utils/methods";
  
  
  const MainSideBar: React.FC<{
    configObj:any
  }> = (props) => {

    const[tabs] = useState(getTabslistFromSubDomain())

    const history = useHistory()

    function handleClick(event:any,tab:any) {
      history.replace(tab.link)
    }
  
    return (
        <div className="main-side-bar background-primary">
        <div className="company-info hide-in-mobile">
          {/* <div className="center-aligned-column padding10">
            <img src="assets/icon/icon.png" alt="" style={{width:"70%"}} />
            <div className="color-white font-small">OminiviewLabs</div>
          </div> */}
        </div>
        <div className="full-height-width center-aligned-column nav-links-container">
          {tabs.map((tab:any,index:number)=>{
            let classNames = ["center-aligned-column nav-links-wrapper pointer overflow-hidden position-relative"]
            if(tab.link === window.location.pathname || (tab.link.includes("product") && window.location.pathname.includes("product"))){
              classNames.push("--is-active")
            }
            return(
              <div onPointerDown={rippleEffectOnClick} key={index} className={classNames.join(" ")} onClick={(event)=>handleClick(event,tab)}>
              <IonIcon className="color-white pointer-none icon link-text" icon={tab.tabIcon}></IonIcon>
              <span className="font-small pointer-none color-white link-text">{tab.tabName}</span>
            </div>
            )
          
          })}
        </div>
      </div>
    );
  };
  
  export default MainSideBar;
  