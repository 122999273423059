import React from 'react';
import { mouseDownCropper, mouseMoveCropper, mouseUpCropper } from '../../../../utils/cropper/cropper';
import "../../../style/cropper/cropper.css"

  
  
const Cropper: React.FC<{
}> = (props) => {



  return (
    <div className='full-height crop-box-wrapper padding0 top-left' 
    onTouchMove={(event)=>mouseMoveCropper(event)} onTouchEnd={mouseUpCropper} onPointerUp={mouseUpCropper} onPointerMove={(event)=>mouseMoveCropper(event)} 
    >
      <div className='full-height-width  position-relative main-crop-box' id="mainCropBox">

        <div className='top-middle resizer-line resizer-line-top padding0'>
          {/* <div className='top-left padding0 resizer' id="topLeft"></div>
          <div className='top-right padding0 resizer' id="topRight"></div> */}
          <div onPointerDown={(event)=>mouseDownCropper(event,"top")} className='middle  resizer resizer-top'></div>
        </div>

        <div className='bottom-middle resizer-line resizer-line-bottom padding0'>
          {/* <div className='bottom-left padding0 resizer' id="bottomLeft"></div> */}
          {/* <div className='bottom-right padding0 resizer' id="bottomRight"></div> */}

          <div onPointerDown={(event)=>mouseDownCropper(event,"bottom")} className='middle  resizer resizer-bottom'></div>
        </div>

        <div className='left-middle resizer-line resizer-line-left padding0'>
          <div onPointerDown={(event)=>mouseDownCropper(event,"left")}   className='middle  resizer resizer-left'></div>
        </div>

        <div className='right-middle resizer-line resizer-line-right padding0'>
          <div  onPointerDown={(event)=>mouseDownCropper(event,"right")} className='middle  resizer resizer-right'></div>
        </div>


       

        <div className='middle drag'>
          <div className='padding5 white-card shadow-light' style={{background:"rgba(255,255,255,0.2)"}}  onPointerDown={(event)=>mouseDownCropper(event,"middle")}>
            <i className='fas fa-arrows-alt font-normal color-black drag-icon'></i>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Cropper;
  