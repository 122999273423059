import React, { useState } from "react";
import Modal from "../../../../../common/shared-components/Modals/Modal";
import ProgressRing from "../../../../../common/ui-components/progress/ProgressRing";
import Status from "../common/Status";
  
  
  const TopRight: React.FC<{
    configObj:any
    currentConfigObject:any
    currArea:string
    projectAreaCategories:any
    categoriesStatus:any

    areasList: Array<any>;
    updateCurrArea: (areaName: string, areaId: number) => void;
  }> = (props) => {
 

    const[isOpen,setIsOpen] = useState(false)


    function FooterContent() {
      return(
          <div className="">
            <button className="op-btn op-btn-light" onClick={()=>setIsOpen(false)}>Close</button>
          </div>
      )
    }
 
    return (
      <React.Fragment>
        <div className="top-right zindex30">
          <div className="moodbaord-product-status" id="moodboardProductStatus">
          <ProgressRing
            configObj={props.configObj}
            // action = {toggleProductStatusPanel}
            action = {()=>setIsOpen(true)}
            total = {props.categoriesStatus[props.currArea]?.remaining.length + props.categoriesStatus[props.currArea]?.confirmed.length}
            completed = {props.categoriesStatus[props.currArea]?.confirmed.length}
          />
          </div>
        </div>

           {/* <SummaryLink
            configObj={props.configObj}
            currentConfigObject={props.currentConfigObject}
            moduleName="moodboard"
          /> */}


        <Modal
          id=""
          classNames="share-modal"
          onDismiss={setIsOpen}
          Content={() => (
            <Status
              configObj={props.configObj}
              currArea={props.currArea}
              projectAreaCategories={props.projectAreaCategories}
              categoriesStatus={props.categoriesStatus}
              areasList={props.areasList}
              updateCurrArea={props.updateCurrArea}
            />
          )}
          isOpen={isOpen}
          heading="Product Selection Status"
          FooterContent={FooterContent}></Modal>

{/* 
        <Modal
          id=""
          classNames="project-action-modal"
          onDismiss={setIsOpen}
          Content={() => (
            <div className="full-height overflow-y-scroll">
               <Status
                  configObj={props.configObj}
                  currArea={props.currArea}
                  projectAreaCategories={props.projectAreaCategories}
                  categoriesStatus={props.categoriesStatus}
                />
            </div>
          )}
          isOpen={isOpen}
          heading="Product selection status"
          FooterContent={()=>(<div></div>)}></Modal> */}

      </React.Fragment>
    );
  };
  
  export default TopRight;
  