 
  import React from "react";
import { useHistory } from "react-router";
import Home from "./Home";


  const FinishActions: React.FC<{
    configObj:any
    currCollection:string
    setopenFinishAction:(value:boolean)=>void
    materials:Array<any>
  }> = (props) => {


    const history = useHistory()
  
    return (
      <Home
        configObj={props.configObj}
        currCollection={props.currCollection}
        setopenFinishAction={props.setopenFinishAction}
        materials={props.materials}
      />
    );
  };
  
  export default FinishActions;
  