import React, { useState } from "react";
// import { addArea3dModelToScene, area3dModelInit, loadModuleToTheScene } from "../../../../../../utils/customizein3d/area3dModel";
import $ from "jquery";
import { hideComponentLoader, showComponentLoader } from "../../../../../../utils/UI_methods/global";
import { changeViewMode, showAddProducts } from "../../../../../../utils/customizein3d/area3dmodel/UI_methods";
import { controls, disableFinalizeMode, enableFinalizeMode, projectConfiguration } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { cameraSettingViewData } from "../../../../../../utils/customizein3d/area3dmodel/floorplan/createRender";
import { applicationConfig, waitFor } from "../../../../../../utils/methods";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import "../../../../../style/admin/customizein3d.css";
import { setIsFineTunedTrue } from "../../../../../../utils/customizein3d/area3dmodel/floorplan/render2d";




const ProductRenderModeUI: React.FC<{
  }> = (props) => {



    const[isFineTuneMode,setIsFineTuneMode] = useState(false)

  function action(){
   

    showComponentLoader("canvasLoader")
    // $(".customize-in-productrendermode-button").fadeOut()
    $(".add-products-button").fadeIn(0)
    disableFinalizeMode()
    changeViewMode(null,"2d")
    hideComponentLoader("canvasLoader")
    setIsFineTuneMode(true)
    
  }

  async function closeFineTune(){
    showComponentLoader("canvasLoader")
    setIsFineTuneMode(false)
    enableFinalizeMode()
    await waitFor(3000)
    hideComponentLoader("canvasLoader")
  }


  function closeFrameHandle(){
    if(isFineTuneMode){
      closeFineTune()
    }else{
      window.parent.document.getElementById('exitRenderModeButton').click();
    }
  }
  
  function fineTuneAction(){
    showComponentLoader("canvasLoader")
    // $(".customize-in-productrendermode-button").fadeOut()
    $(".add-products-button").fadeIn(0)
    disableFinalizeMode()
    changeViewMode(null,"2d")
    hideComponentLoader("canvasLoader")
    setIsFineTuneMode(true)
    setIsFineTunedTrue()
  }

  return (
    <>

    {!isFineTuneMode?
      <div className="top-left padding0 bg-color-light border" style={{width:`${cameraSettingViewData.width*100}%`,marginTop:`${projectConfiguration.isProductRenderMode?"3.5rem":"0"}`}}>
        <div className="center-aligned-column padding5 bg-color-white">
            <Button2
              configObj={applicationConfig}
              action={fineTuneAction}
              label={"Fine Tune"}
              fontAwesomeIcon={"fas fa-cog color-danger"}
              iconPos="left"
              classNames={"bg-color-white border-primary button-ex-sma"}
            />
          </div>
      </div>    
    :null}



    <div className="top-left full-width bg-color-black" style={{zIndex:1001,height:"3.5rem",background:"rgba(255,255,255,0.7)"}}>
      <div className="middle color-white heading2">Take Render</div>

      <div className="left-middle padding0">
          <Button2
            configObj={applicationConfig}
            action={closeFrameHandle}
            label={`${isFineTuneMode?"Go to studio":"Go back"}`}
            fontAwesomeIcon={"fas fa-arrow-left color-danger"}
            iconPos="left"
            classNames={"button-small no-shadow border"}
          />
      </div>

      <div className="right-middle padding0">
        {isFineTuneMode?
        <div className="display-none">
          <Button2
            configObj={applicationConfig}
            action={closeFineTune}
            label={"Take Render"}
            fontAwesomeIcon={"fas fa-camera color-danger"}
            iconPos="left"
            classNames={"button-small no-shadow border"}
          />
          <Button2
            configObj={applicationConfig}
            action={showAddProducts}
            label={"Add Products"}
            fontAwesomeIcon={"fas fa-plus-square color-danger"}
            iconPos="left"
            classNames={"button-small no-shadow border"}
          />
        </div>
        :
        <Button2
            configObj={applicationConfig}
            action={action}
            label={"Fine tune"}
            fontAwesomeIcon={"fas fa-arrow-right color-danger"}
            iconPos="right"
            classNames={"button-small display-none no-shadow border"}
          />
        }
          
      </div>
     
    </div>
    
    </>
  );
};

export default ProductRenderModeUI;
