import $ from "jquery"
import { Vector3 } from "three"
import { hideComponentLoader, showToast } from "../../UI_methods/global"
import { applicationConfig, categoryFilteredTextures, getApplicationFiltersMaterials, stringWithoutWhiteSpace, waitFor } from "../../methods"
import { loadAndApplyTexture, updateGroupCenter } from "../common"
import { GRID } from "../enviornment"
import { pointerMoveDragIcon, updateDragIconPosition } from "../modules/annotations"
import { addModule, removeModuleFromConfiguration } from "../modules/customizein3d"
import { getObjectByObjectId, getObjectDimensionPosition, updateOtherModelPositions } from "../modules/helper"
import { CURR_SELECTED_PRODUCT } from "../raycasting"
import { closeControlsPanelAndHideProducts, setProductModules, showProductOptionsPanel, showWalls } from "./UI_methods"
import { area3DSceneWrapper, areaModel, boundingBox, controls, deselectProduct, floorplanner, orthoCamera, renderer, resetCurrArea, scene, setCurrAreaGroup, showAreaWalls, updateAreaCustomizer, updateCameraControls } from "./area3dModel"
import { postAddingActions } from "./helper"
import { autoWallsHiding, enableWallsHiding, getAreaFromWallsBoundingBox, hideWallsBoxHelpers, showWallsBoxHelpers, wallBoxHelpersGroup } from "./walls"


export var isProductConfigureMode = false
export var CURR_SELECTED_PRODUCT_GROUP = null
var axis = new Vector3(0,1,0)


export function enableProductConfigureMode(group:any = null) {
    if(!group){
        group = CURR_SELECTED_PRODUCT
    }
    CURR_SELECTED_PRODUCT_GROUP = group
    // setCurrSelectedProduct(group)
    isProductConfigureMode = true
    $(".hide-on-configure").addClass("display-none")
    $(".show-on-configure").removeClass("display-none")
    $(".exit-configure-mode-button").removeClass("display-none")
    $("#productReplaceIcon").fadeOut(0)
    hideOtherItems(group)
    hideProductConfigureButton()
    deselectProduct()
    showProductOptionsPanel(null)
    setProductModules(group.userData.configuration)
    applicationConfig.functions.customizer.setIsProductConfigurationMode(true)
    applicationConfig.functions.customizer.setModularProductConfig(CURR_SELECTED_PRODUCT_GROUP.userData.configuration?.moduleConfiguration)
    applicationConfig.functions.customizer.changeTabOption("AddModule")
    updateControlsForModules(group)
    let detectedArea = getAreaFromWallsBoundingBox(group)
    if(detectedArea){
        setCurrAreaGroup(detectedArea)
    }
    enableWallsHiding()
    updateMaterials()
    updateDragIconPosition(group,renderer,orthoCamera)
    showToast("Configurator mode activated",2000)
}
  
export function disableProductConfigureMode() {
    closeControlsPanelAndHideProducts()
    deselectProduct()
    isProductConfigureMode = false
    $(".hide-on-configure").removeClass("display-none")
    $(".show-on-configure").addClass("display-none")
    $(".exit-configure-mode-button").addClass("display-none")
    $("#moduelsDragIcon").addClass("display-none")
    $("#productReplaceIcon").fadeIn(0)
    // showAllItems()
    updateCameraControls(null,"all")
    applicationConfig.functions.customizer.setIsProductConfigurationMode(false)
    resetCurrSelectedProductGroup()
    resetCurrArea()
    updateAreaCustomizer("all")
    // floorplanner.group.visible = true
    showWallsBoxHelpers()
    showAllItems()
    autoWallsHiding()
}

export function updateControlsForModules(group:any) {
    let box = getObjectDimensionPosition(null,group)
    let target = box.center
    if(controls.camera.isPerspectiveCamera){
        let fitToBoxDistance = controls.getDistanceToFitBox(box.dimensions.dimX,box.dimensions.dimY,box.dimensions.dimZ,true)
        controls.setLookAt(target.x - 0.2,target.y + fitToBoxDistance + 2,target.z + fitToBoxDistance,target.x,target.y + 1,target.z,true)
    }
}

export function customizeProduct() {
    showProductOptionsPanel(null)
    applicationConfig.functions.customizer.changeTabOption("AddModule")
}

export function hideOtherItems(objectToBeVisible:any) {
    scene.children.forEach(currObject=>{
        if(currObject.uuid != objectToBeVisible.uuid){
            currObject.visible = false
        }
    })
    hideWallsBoxHelpers()
    GRID.visible = false
    floorplanner.group.visible = false
    wallBoxHelpersGroup.visible = false
    // FLOOR?.visible = false
}
export function showAllItems() {
    scene.children.forEach(currObject=>{
        currObject.visible = true
    })
    GRID.visible = true
    floorplanner.group.visible = true
    wallBoxHelpersGroup.visible = true
}

export function showProductConfigureButton() {
    $(".product-configure-button").removeClass("display-none") 
}

export function hideProductConfigureButton() {
    $(".product-configure-button").addClass("display-none")
}
export function setCurrProductGroup(group:any) {
    CURR_SELECTED_PRODUCT_GROUP = group
}
export function resetCurrSelectedProductGroup() {
    CURR_SELECTED_PRODUCT_GROUP = null
}


export function removeModule(object:any) {
    let productConfig = CURR_SELECTED_PRODUCT_GROUP.userData.configuration
    let configuration = productConfig.moduleConfiguration
    let modelIndex = configuration.getModuleIndexFromObject(object)
    if(modelIndex===0){
      showToast("Cannot remove first module",1000,"error")
    }
    deselectProduct()
    removeModuleFromConfiguration(configuration,object)
    object.parent.remove(object)
    //If deleting the default model dont update anything 
    updateOtherModelPositions(CURR_SELECTED_PRODUCT_GROUP,configuration,boundingBox,axis,productConfig.subCategoryName,false)
    updateGroupCenter(CURR_SELECTED_PRODUCT_GROUP,[...CURR_SELECTED_PRODUCT_GROUP.children])
    updateControlsForModules(CURR_SELECTED_PRODUCT_GROUP)
    updateDragIconPosition(CURR_SELECTED_PRODUCT_GROUP,renderer,orthoCamera)
    showToast("Removed " + object.name,1000)
}


export async function duplicateModule() {
    let module = CURR_SELECTED_PRODUCT.userData.configuration
    let configuration = getModuleConfigurationFromCurrProductGroup()
    if(module){
        await addModule(configuration,CURR_SELECTED_PRODUCT_GROUP,module,module.isAddedAsAddon,postAddingActions).then(object=>{
            // undo.add("duplicate",{addedModel:object})
            updateControlsForModules(CURR_SELECTED_PRODUCT_GROUP)
            hideComponentLoader("changeFinishLoader")
        }).catch(err=>{
            hideComponentLoader("changeFinishLoader")
        })
    }else{
      hideComponentLoader("changeFinishLoader")
    }
}


export function updateMaterials() {
    let productConfig = CURR_SELECTED_PRODUCT_GROUP.userData.configuration
    let materials = getApplicationFiltersMaterials("Customizer",applicationConfig?.data.materials,applicationConfig?.data.applicationMaterials)
    let objectMaterialTypes = applicationConfig?.data.objectMaterialTypes
    materials = categoryFilteredTextures(materials,objectMaterialTypes,productConfig.categoryName)
    applicationConfig.functions.customizer.setTextures(materials)
}


export function updateModulesFinish(textureInfo:any) {
    let configuration = CURR_SELECTED_PRODUCT_GROUP.userData.configuration.moduleConfiguration
    updateFinishOfAllModules(configuration.modules.concat(configuration.addons),textureInfo)
}


function updateFinishOfAllModules(modulesList:any,textureInfo:any){
    let productConfig = CURR_SELECTED_PRODUCT_GROUP.userData.configuration
    let modulesConfig = productConfig.moduleConfiguration
    for (const index in modulesList) {
      let module:any = modulesList[index]
      let moduleObject = getObjectByObjectId(CURR_SELECTED_PRODUCT_GROUP,module.moduleObjectId) 
      loadAndApplyTexture(moduleObject,textureInfo,productConfig.productName,"customizer",productConfig.categoryName,modulesConfig).then(data=>{
        
      }).catch(err=>{
        console.log(err)
      })
    }
  }

export function pointerMoveAnnotationDragAction(event:any) {
    pointerMoveDragIcon(event,CURR_SELECTED_PRODUCT_GROUP,area3DSceneWrapper,orthoCamera)
}


export function rotateModules() {
    let productConfig = CURR_SELECTED_PRODUCT_GROUP.userData.configuration
    let modulesConfig = productConfig.moduleConfiguration
    CURR_SELECTED_PRODUCT_GROUP.rotateOnAxis(axis,-Math.PI/2)
    modulesConfig.updateGroupRotation()
    updateDragIconPosition(CURR_SELECTED_PRODUCT_GROUP,renderer,orthoCamera)
}

export function getModuleConfigurationFromCurrProductGroup() {
    return CURR_SELECTED_PRODUCT_GROUP.userData.configuration.moduleConfiguration
}

export async function productConfigurationSpacePlannerActions() {
    let detectedArea = getAreaFromWallsBoundingBox(CURR_SELECTED_PRODUCT_GROUP)
    if(detectedArea){
    updateAreaCustomizer(detectedArea)
    }
    $("#moduelsDragIcon").removeClass("display-none")
    await waitFor(500)
    updateDragIconPosition(CURR_SELECTED_PRODUCT_GROUP,renderer,orthoCamera)
}