import $ from "jquery"
import { checkFromCache } from "../cache/cache"
import { closeControlsPanelAndHideProducts, hideAddProducts } from "../customizein3d/area3dmodel/UI_methods"
import { expandControlsPanel, hideControlsPanel, isPotraitMode } from "../customizein3d/common"
import { getProductModuleUrl } from "../customizein3d/modules/helper"
import { expandActionBar, hideActionBar } from "../customizein3d/modules/UI_methods"
import { logger, stringWithoutWhiteSpace, waitFor } from "../methods"
import { focusOnInput, getParentContainer, getWindowWidth, showComponentLoader, updateFillbarPosition } from "../UI_methods/global"
import { autoArrangeImages, fitStageIntoParentContainer, getProductImageUrl, hideProductSelectionBox } from "./helper"
import { hidePriorityToggleButton } from "./layouts/UI_methods"
import {
    addNewPage,
    allowDrop,
    confirmEmptyCanvas,
    copyProduct,
    downloadCanvas,
    dropImageMoodboard,
    moodboardConfig,
    removeProduct,
    scaleAndDrawImages,
    undo
  } from "./moodboard";
import { addText } from "./text"
import {Image as MoodboardImage} from "./Configuration"


export var IS_ADDING_ITEMS = false


export function addCanvas(moodboardConfig:any,productName:string,isProductCanvas:boolean=true,canvasContainerId:string="") {
    let mainContainer = $("#moodboardCanvasWrapper")


    if(!canvasContainerId.length){
        canvasContainerId = `container${stringWithoutWhiteSpace(productName)}`
    }


    let id = stringWithoutWhiteSpace(`moodboardCanvas${stringWithoutWhiteSpace(productName)}`)

    let parent = $("<div></div>",{
        class:"moodboard-canvas-container",
        id:id
    })

    // let header = getCanvasHeader(image)

    let child = document.createElement("div")
    child.setAttribute("id",canvasContainerId)
    child.setAttribute("class","canvas-container")

    //Set height as main container
    child.style.height = $("#mainMoodboardCanvasContainer").innerHeight() + "px"
    child.style.width = $("#mainMoodboardCanvasContainer").innerWidth() + "px"

    // if(isProductCanvas){
    //     parent.append(header)
    //     let imageSrc = getProductImageUrl(configObject.BASE_URL_ASSETS,configObject.clientName,image.productName,image.productCategory,image.productSubCategory);

    //     let productImage = document.getElementById(`moodboardHeader${stringWithoutWhiteSpace(image.productName)}`) 
    //     checkFromCache(imageSrc)?.then((url: any) => {
    //         productImage?.setAttribute("src", url);
    //     })
    //     .catch((err) => {
    //     });
    // }

    parent.append(child)
    mainContainer.append(parent)


    addCanvasActions(parent)
    addSelectionBoxes(parent)

    child.addEventListener("drop",dropImageMoodboard)
    child.addEventListener("dragover",allowDrop)

   
    moodboardConfig.createStageAndLayer(canvasContainerId)

    eventListenersOnModuleLink()

    logger?.info("editcollection","addcanvas",`Canvas added: ${canvasContainerId}`)

}

function eventListenersOnModuleLink() {

    let parentContainer = getParentContainer()
    let openModuleLinks = parentContainer.querySelectorAll(".add-modules-link")
    for (let i = 0; i < openModuleLinks.length; i++) {
        const element = openModuleLinks[i]
        element.removeEventListener("pointerup",openProductModules)
    }
    for (let i = 0; i < openModuleLinks.length; i++) {
        const element = openModuleLinks[i]
        element.addEventListener("pointerup",openProductModules)
    }
}


function getCanvasHeader(image:any) {
    return ` <div class="moodboard-product-header border-bottom">
                <div class="image">
                <img src="assets/images/placeholder.png" alt="" id="moodboardHeader${stringWithoutWhiteSpace(image.productName)}"/>
                </div>
                <div class="">
                    <div class="display-flex-row border pointer add-modules-link" style="padding:0.2rem 1rem;margin:1rem">
                    <div class="moduler-icon"> <img src="assets/icon/modular.png" class="full-width"/> </div>
                    <div class="center-aligned-column heading2 color-dark">Add modules</div>
                    </div>       
                </div>
            </div>
            `
}


function addCanvasActions(parent:any) {
    let mainContainer = $("#canvasActionContainer")
    let clonedElement = mainContainer.clone()
    clonedElement.find(".reset-canvas").on("click",confirmEmptyCanvas) 
    clonedElement.find(".download-canvas").on("click",downloadCanvas) 
    clonedElement.find(".add-page").on("click",addNewPage) 
    clonedElement.find(".auto-arrange-images").on("click",autoArrangeImages) 
    clonedElement.find(".copy-product").on("click",copyProduct) 
    clonedElement.find(".remove-product").on("click",removeProduct) 
    clonedElement.find(".undo-product").on("click",undo) 
    clonedElement.find(".add-text").on("click",addText) 
    parent.append(clonedElement)
}

function addSelectionBoxes(parent:any) {
    let hoverBox = $("#productHoverBox") 
    let selectionBox = $("#productSelectionBox")
    let hoverBoxCloned = hoverBox.clone()
    let selectionBoxCloned = selectionBox.clone()
    // clonedElement.find(".reset-canvas").on("click",confirmEmptyCanvas) 
    // clonedElement.find(".download-canvas").on("click",downloadCanvas) 
    // clonedElement.find(".add-page").on("click",addNewPage) 
    parent.append(hoverBoxCloned)
    parent.append(selectionBoxCloned)
}


export function scrollCanvasToTop() {
    $("#moodboardCanvasWrapper").animate({
        scrollTop: 0
    }, 1000);
}

export function scrollToCanvas(productName:string) {
    $("#moodboardCanvasWrapper").animate({
        scrollTop: $(`#moodboardCanvas${stringWithoutWhiteSpace(productName)}`).offset().top
    }, 1000);
}

export function updateProductActionPosition(x:any,y:any,rotation:number,imageWidth:any,imageHeight:any,canvasContainerId:string) {
    x = x + imageWidth
    if(rotation === 90){
        x = x - imageWidth
    }
    if(rotation === 180){
        x = x - imageWidth
        y = y - imageHeight
    }
    if(rotation === 270){
        y = y - imageHeight
    }
    $("#productActionContainer").detach().appendTo("#"+canvasContainerId)
    $("#productActionContainer").css({"top":y,"left":x - imageWidth - 25})
  }
  
  export function hideProductActions() {
    $(".product-actions-container").removeClass("--is-active")
    $(".product-selection-box-container").removeClass("--is-active")
  }
  


export function expandSubCategories(event:any=null) {
    // $(event.target).siblings(".sub-categories-wrapper").toggleClass("--is-active")
    $("#subCategoryWrapper").addClass("--is-active")
}

export function closeSubCategories() {
    // $(".sub-categories-wrapper").removeClass("--is-active")
    $("#subCategoryWrapper").removeClass("--is-active")
}

export function removeProductCanvas(containerId:string) {
    $("#"+containerId).remove()
}

export function toggleProductSearch() {
    $(".moodboard-product-search-wrapper").toggleClass("--is-active")

  }

  export function openProductSearch(parentContainerId:string,suffix:string) {
    $(".moodboard-product-search-wrapper").addClass("--is-active")
 
    // $("#"+parentContainerId).find(`#productSearch${suffix}`).focus()
    focusOnInput("productSearchaddProductsProductImagesresult")
  }

  export function closeProductSearch() {
    $(".moodboard-product-search-wrapper").removeClass("--is-active")
  }
  

  export function expandItemsPanel() {
    IS_ADDING_ITEMS = true
    hideAllProductAnnotations()
    if(getWindowWidth() > 480 && !isPotraitMode()){
        let offset = 0
        offset = $(".customize-controls-wrapper").width() / 2
        offset = offset + $("#moodboardHeaderCenter").width() / 2 
        $("#moodboardHeaderCenter").css("left",offset+"px")
        $(".moodboard-canvas-container").css("margin-left","-11rem")
    }
    expandControlsPanel(function() {
        if(getWindowWidth() > 480){
            let stage =  moodboardConfig.getStage("mainMoodboardCanvasContainer")
            fitStageIntoParentContainer(stage,false)
            // scaleAndDrawImages()
        }
        updateFillbarPosition()
    })
  }

  export function hideItemsPanel(moduleName:string = "moodboard") {
    if(moduleName==="customizer"){
        closeControlsPanelAndHideProducts()
        return
    }
    IS_ADDING_ITEMS = false
    hideAllProductAnnotations()
    hidePriorityToggleButton()
    $("#moodboardHeaderCenter").css("left","0px")
    $(".moodboard-canvas-container").css("margin-left","0")
    hideControlsPanel(function() {
        $(".desktop-controls-tab").removeClass("--is-active")
        $(".customization-customize-button").addClass("--is-active")
        if(getWindowWidth() > 480){
            let stage =  moodboardConfig.getStage("mainMoodboardCanvasContainer")
            fitStageIntoParentContainer(stage,false)
            // scaleAndDrawImages()
        }
        updateFillbarPosition()
    })
  }

  export async function showActionTabs() {
    expandActionBar(function() {
    
 })
 // $("#customizein3dActionTabs").addClass("--is-active")
 // hideCustomizeButton()
}

export function hideActionTabs() {
 hideActionBar(function() {
    $(".customization-customize-button").addClass("--is-active")
 })
 // $("#customizein3dActionTabs").removeClass("--is-active")
}


export function openProductModules() {
    expandControlsPanel(function(){
        $("#moodboardControlsWrapper")?.removeClass("--is-visible")
        $("#moodboardProductModules")?.addClass("--is-visible")
    })
  
  }
  
  export function closeProductModules() {
    $("#moodboardControlsWrapper")?.addClass("--is-visible")
    $("#moodboardProductModules")?.removeClass("--is-visible")
  }
   

export function enablePotraitModeLayout() {
    $("#moodboardProductStatusTable").addClass("--is-hide")
    $("#moodboardProductStatus").addClass("--is-active")
    $(".moodbaord-summary-links").addClass("--is-active")
    // $(".moodboard-price-link-wrapper").addClass("--is-hide")
}


export function disablePotraitModeLayout() {
    $("#moodboardProductStatusTable").removeClass("--is-hide")
    $("#moodboardProductStatus").removeClass("--is-active")
    $(".moodbaord-summary-links").removeClass("--is-active")

    // $(".moodboard-price-link-wrapper").removeClass("--is-hide")
}

export function toggleProductStatusPanel() {
    $("#moodboardProductStatusTable").toggleClass("--is-hide")
}

export function shareClickHandle() {
    // $("#summaryButtonDesktop").click()
    $("#summaryButton").click()
    // $("#summaryButton").click()
}


export function setCurvedIconPosition(wrapperId:string,itemId:string) {
    let element = null
    if(wrapperId.length){
      element = $("#"+wrapperId)
    }
    let position = element.offset()
    let posLeft = position.left + element.innerWidth() 
    let posTop = position.top + (element.innerHeight() / 2) - 35
    $("#"+itemId).css({left:posLeft,top:posTop}) 
  }


  export function hideAllProductAnnotations() {
    hideProductActions()
    $(".addons-container").removeClass("--is-active")
  }

  export function updateUndoVisibility(){
    if(!moodboardConfig.itemActionsStack.length){
        $(".undo-product").css("opacity","0.5")
    }else{
        $(".undo-product").css("opacity","1")
    }
  }


  export function arrangeClickHandle() {
    $("#layouts").click()
  }


