import { Intersection, Object3D, Vector3 } from "three";
import { AREA_MODEL_ORIGIN, floorplanner, kitchenPlanner } from "../customizein3d/area3dmodel/area3dModel";
import { updateBoxHelper } from "../customizein3d/area3dmodel/dragging";
import { getNormal } from "../customizein3d/area3dmodel/helper";
import { getObjectDimensionPosition } from "../customizein3d/modules/helper";
import { CURR_SELECTED_PRODUCT, raycaster } from "../customizein3d/raycasting";
import { getObjectArrayOfDistictValues } from "../methods";
import { showToast } from "../UI_methods/global";
 
 
export var startPoints:any = {}
export var endPoints:any = {}

// export const directions = ["top","right","left","bottom"]
export const directions = ["left","right","top","bottom"]

export function setSnappingObjectInKitchenPlanner(object:any){
    let intersectObjectData = null
    let intersectObject = null
    let distances = []
    let distance = 0


    for (const direction of directions) {
        setStartPoint(direction,object)
        intersectObjectData = getIntersectObjectKitchenPlanner(object,getNormal(direction),false)
        if(intersectObjectData){
            intersectObject = intersectObjectData.object
            setIntersectObjectEndPoints(direction,getObjectDimensionPosition(null,intersectObject))
            if(direction.includes("left") || direction.includes("right")){
                distance = Math.abs(endPoints.x-startPoints.x)
            }else{
                distance = Math.abs(endPoints.z-startPoints.z)
            }
            distances.push({
              distance:distance,
              detectedObject:intersectObject,
              normal:getNormal(direction)
            })
        }
    }
   

    if(distances.length){
        // let result = getLowestDistance(distances)
        // if(Number(result.distance) < 0.20){
        //     kitchenPlanner.setDetectedObjectOnDrag(result)
        // }else{
        //     kitchenPlanner.setDetectedObjectOnDrag(null)
        // }
        kitchenPlanner.setDetectedObjectOnDrag(distances)
    }
}

export function snapObjectToNearestCornerWall(object:any){
  let intersectObjectData = null
  let intersectObject = null
  let distance = 0
  let directions = ["top","bottom"]

  let subModuleType = object.userData.configuration.subModuleType
  let normal = object.userData?.snappedWall?.userData?.normal
  if(!normal){
    return
  }
  if(normal.x){
    directions = ["top","bottom"]
  }else{
    directions = ["left","right"]
  }

  var isSnapped = false
  
  for (const direction of directions) {
      setStartPoint(direction,object)
      intersectObjectData = getIntersectObjectKitchenPlanner(object,getNormal(direction),false,[...kitchenPlanner.clamp.groupForClampModules.children])
      if(intersectObjectData){
          intersectObject = intersectObjectData.object
          setIntersectObjectEndPoints(direction,getObjectDimensionPosition(null,intersectObject))
          if(direction.includes("left") || direction.includes("right")){
              distance = Math.abs(endPoints.x-startPoints.x)
          }else{
              distance = Math.abs(endPoints.z-startPoints.z)
          }
          if(distance < 0.3){
            if(subModuleType?.toLowerCase().includes("corner")){
              object.position.setX(object.position.x + distance * getNormal(direction).x)
              object.position.setZ(object.position.z + distance * getNormal(direction).z)
            }else{
              kitchenPlanner.addFiller(object,object,getNormal(direction),distance)
            }
            isSnapped = true
          }
      }
  }

  return isSnapped
}


export function removeNearestFillers(object:any){
  let intersectObjectData = null
  let intersectObject = null
  let distance = 0
  for (const direction of directions) {
      setStartPoint(direction,object)
      intersectObjectData = getIntersectObjectKitchenPlanner(object,getNormal(direction),false,[...kitchenPlanner.fillersGroup.children])
      if(intersectObjectData){
          intersectObject = intersectObjectData.object
          setIntersectObjectEndPoints(direction,getObjectDimensionPosition(null,intersectObject))
          if(direction.includes("left") || direction.includes("right")){
              distance = Math.abs(endPoints.x-startPoints.x)
          }else{
              distance = Math.abs(endPoints.z-startPoints.z)
          }
          if(distance < 0.2){
            intersectObject = intersectObjectData.object
            intersectObject.parent.remove(intersectObject)
            intersectObject.clear()
          }
      }
  }
}


export function scaleToFit(){
  let object = CURR_SELECTED_PRODUCT
  removeNearestFillers(object)
  let intersectObjectData = null
  let intersectObject = null
  let distance = 0
  let directions = ["top","bottom"]

  let subModuleType = object.userData.configuration.subModuleType
  let normal = object.userData?.snappedWall?.userData?.normal
  if(!normal){
    return
  }
  if(normal.x){
    directions = ["top","bottom"]
  }else{
    directions = ["left","right"]
  }

  var isSnapped = false
  
  for (const direction of directions) {
      setStartPoint(direction,object)
      intersectObjectData = getIntersectObjectKitchenPlanner(object,getNormal(direction),false,[...kitchenPlanner.clamp.groupForClampModules.children,...kitchenPlanner.kitchenModulesList])
      if(intersectObjectData){
          intersectObject = intersectObjectData.object
          setIntersectObjectEndPoints(direction,getObjectDimensionPosition(null,intersectObject))
          if(direction.includes("left") || direction.includes("right")){
              distance = Math.abs(endPoints.x-startPoints.x)
          }else{
              distance = Math.abs(endPoints.z-startPoints.z)
          }
          if(distance < 0.25){
            let normal = getNormal(direction)
            if(object.scale.x + distance * Math.abs(normal.x) + distance * Math.abs(normal.z) > 1.25){
              showToast("Reached scale limit",2000,"error")
              return
            }
            object.scale.setX(object.scale.x + distance * Math.abs(normal.x) + distance * Math.abs(normal.z))
            // if(!subModuleType?.toLowerCase().includes("corner")){
                object.position.setX(object.position.x + distance * normal.x)
                object.position.setZ(object.position.z + distance * normal.z)
            // }
            isSnapped = true
          }
      }
  }

  updateBoxHelper()
  return isSnapped
}

export function resetObjectScale() {
  let object = CURR_SELECTED_PRODUCT
  removeNearestFillers(object)
  object.scale.set(1,1,1)
  updateBoxHelper()
}



export function updateModulePositionsOnReplace(object:any,replaceModuleDimensions:any){



  let resultVectorToMoveObjects:Vector3 = null
  let resultObjectsToMove:any = []
  let translatedObjetIds:any = []

  for (const direction of directions) {
      let intersectObjects:any = getIntersectObjectKitchenPlanner(object,getNormal(direction),true)
      if(intersectObjects){
          for (const currObject of intersectObjects) {
            if(currObject.object.name.toLowerCase().includes("corner")){
              resultVectorToMoveObjects = getNormal(direction).negate()
              break
            }
          }
      }
  }

  if(!resultVectorToMoveObjects || !resultObjectsToMove){
    return
  }
  
  let dimDifferenceX = getObjectDimensionPosition(null,object).dimensions.dimX - replaceModuleDimensions.dimensions.dimX
  let dimDifferenceZ = getObjectDimensionPosition(null,object).dimensions.dimZ - replaceModuleDimensions.dimensions.dimZ

  // Object center is in top left translate the object itselft if it is on z axis 
  if(resultVectorToMoveObjects?.z){
    object.position.setZ(object.position.z + dimDifferenceZ * resultVectorToMoveObjects.z)
  }
  
  //Array having duplicate values 
  resultObjectsToMove = getIntersectObjectKitchenPlanner(object,resultVectorToMoveObjects,true)
  if(resultObjectsToMove?.length){
      for (const currObjectData of resultObjectsToMove) {
        let objectToBeMoved = currObjectData.object
        if(!translatedObjetIds.includes(objectToBeMoved.uuid)){
          objectToBeMoved.position.setX(objectToBeMoved.position.x + dimDifferenceX * resultVectorToMoveObjects.x)
          objectToBeMoved.position.setZ(objectToBeMoved.position.z + dimDifferenceZ * resultVectorToMoveObjects.z) 
          translatedObjetIds.push(objectToBeMoved.uuid)
        }
      }
   
  }
}


// export function getDistictObjects(intersectionArray:Array<Intersection>) {
//   let addedObjectsIds = []
//   for (const currObject of intersectionArray) {
//     if(!addedObjectsIds.includes(currObject.object.uuid)){
//       resultVectorToMoveObjects = getNormal(direction).negate()
//       break
//     }
//   }
// }


export function setInitialSnappedWall(object:any){
  let intersectObjectData = null
  let intersectObject = null
  let distances = []
  let distance = 0


  for (const direction of directions) {
      setStartPoint(direction,object)
      // intersectObjectData = getIntersectObjectKitchenPlanner(object,getNormal(direction),false,[...kitchenPlanner.clamp.groupForClampModules.children])
      intersectObjectData = getIntersectObjectKitchenPlanner(object,getNormal(direction),false,[...floorplanner.group.children])
      if(intersectObjectData){
          intersectObject = intersectObjectData.object
          setIntersectObjectEndPoints(direction,getObjectDimensionPosition(null,intersectObject))
          if(direction.includes("left") || direction.includes("right")){
              distance = Math.abs(endPoints.x-startPoints.x)
          }else{
              distance = Math.abs(endPoints.z-startPoints.z)
          }
          distances.push({
            distance:distance,
            detectedObject:intersectObject,
            normal:getNormal(direction)
          })
      }
  }

  if(distances.length){
      let result = getLowestDistance(distances)
      if(Number(result.distance) < 0.05){
          object.userData.snappedWall = result.detectedObject
          object.userData.configuration.updateNormal(object.userData.snappedWall.userData.normal)
      } 
  }
}


export function getLowestDistance(distances:Array<any>) {
  let min = distances[0].distance
  let result = distances[0]
  distances.forEach(currDistance => {
    if(currDistance.distance < min){
      min = currDistance.distance
      result = currDistance
    }
  });
  return result
}


function setStartPoint(direction:string,object:any) {

  let posY = AREA_MODEL_ORIGIN.y + 0.02

  let dragObjectData = getObjectDimensionPosition(null,object)
  // let posY = dragObjectData.center.y

  switch (direction) {
    case "left":
      startPoints = {
        x:dragObjectData.positions.min.x,
        y:posY,
        z:dragObjectData.positions.min.z + dragObjectData.dimensions.dimZ /2
      }
    break;

    case "top":
      startPoints = {
        x:dragObjectData.positions.min.x + dragObjectData.dimensions.dimX /2,
        y:posY,
        z:dragObjectData.positions.min.z 
      }
    break;

    case "right":
      startPoints = {
        x:dragObjectData.positions.max.x,
        y:posY,
        z:dragObjectData.positions.max.z - dragObjectData.dimensions.dimZ /2
      }
    break;

    case "bottom":
      startPoints = {
        x:dragObjectData.positions.max.x - dragObjectData.dimensions.dimX /2,
        y:posY,
        z:dragObjectData.positions.max.z 
      }
    break;

    default:
      break;
  }
}


export function setIntersectObjectEndPoints(direction:string,intersectObjectData:any) {
  // let posY = AREA_MODEL_ORIGIN.y + 0.02
  let posY = intersectObjectData.center.y
  switch (direction) {
    case "left":
      endPoints = {
        x:intersectObjectData.positions.max.x,
        y:posY,
        z:startPoints.z
      }
    break;

    case "top":  
      endPoints = {
        x:startPoints.x,
        y:posY,
        z:intersectObjectData.positions.max.z,
      }
    break;

    case "right":
      endPoints = {
        x:intersectObjectData.positions.min.x,
        y:posY,
        z:startPoints.z
      }
    break;

    case "bottom":

      endPoints = {
        x:startPoints.x,
        y:posY,
        z:intersectObjectData.positions.min.z,
      }
    break;

    default:
      break;
  }
}


export function getIntersectObjectKitchenPlanner(draggingObject:any,normal:Vector3,isGetAllObjects:boolean,targetObjects:Array<any> = null) {
    if(!targetObjects){
      targetObjects = [...kitchenPlanner.kitchenModulesList]
    }
    let intersectObjects = checkCollision(draggingObject,normal,targetObjects)
    if(draggingObject.userData.isModulesGroup){
      intersectObjects = intersectObjects?.filter((object:any)=>object.object.uuid!=draggingObject.uuid
      && !object.object.parent.userData.isModulesGroup
      )
    }else{
      intersectObjects = intersectObjects?.filter((object:any)=>object.object.uuid!=draggingObject.uuid )
    }
    
    if(intersectObjects.length){
      if(isGetAllObjects){
        return intersectObjects
      }else{
        return intersectObjects[0]
      }
    }
   
    return null
  }

  export function checkCollision(currObject:any,normal:Vector3,targetObjects:Array<any>) {
    let objCenter = getObjectDimensionPosition(null,currObject).center
    raycaster.set(new Vector3(objCenter.x,objCenter.y,objCenter.z),normal)
    return raycaster.intersectObjects(targetObjects,true)
  }
