import React from "react";
import { startPageTourAgain } from "../../../../utils/siteTour/tour";
import Button2 from "../buttons/Button2";

const PageTourLink: React.FC<{
 
 
}> = (props) => {

 
 
  return (
    <div className="pointer">
    {/* <i className="fas fa-question-circle font-medium color-black font-medium" onClick={startPageTourAgain}></i> */}
    <Button2
      configObj={{}}
      action={startPageTourAgain}
      label={""}
      fontAwesomeIcon={"fas fa-question-circle color-black"}
      iconPos="right"
      classNames={"no-shadow margin0"}
    />
  </div>
  );
};

export default PageTourLink;
