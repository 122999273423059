import $ from "jquery";
import React from 'react';
import { enableSceneViewer } from '../../../../utils/customizein3d/webxr';


const LaunchAr: React.FC<{
    url:string
}> = (props) => {
 
 
    function handleLaunchAr() {
        $(".lunch-ar-wrapper").removeClass("--is-active")
        enableSceneViewer(props.url)
      }
    
      function closePanel(){
        $(".lunch-ar-wrapper").removeClass("--is-active")
      }
    

  return (
    <div className="lunch-ar-wrapper">
    <div className="full-height-width center-aligned-column position-relative">

      <div className="top-right ion-padding ion-margin-top">
        <button onClick={closePanel} 
          style={{background:"transparent"}}
          className="color-white font-large button marginleft5 zindex30 border">
            <i className="fas fa-times font-large color-danger marginRight5"></i>
            Close
        </button>
      </div>
     
      <a style={{visibility:"hidden",position:"fixed"}} className="top-left" id="sceneViewer" href="">Avocado</a>

      <div className="center-aligned-row full-width bottom-middle ion-padding" style={{marginBottom:"5rem"}}>
        <button onClick={handleLaunchAr} 
          style={{background:"transparent"}}
          className="color-white font-large full-width ion-margin-bottom button marginRight5 marginleft5 zindex30 border">
            <i className="fas fa-cube color-warning font-large marginRight5"></i>
            Launch Live View
        </button>
      </div>
    </div>
  </div>
  );
};

export default LaunchAr;
