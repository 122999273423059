import React, { useState } from "react";
import { STORE } from "../../../../../../utils/store/storeConfiguration";
import Modal from "../../../../../common/shared-components/Modals/Modal";
import HorizontalScroll from "../../../../../common/ui-components/scrollbar/HorizontalScroll";
import QualityDetails from "./QualityDetails";
 

const SelectQuality: React.FC<{
  currQuality:any
  onChange:(val:string)=>void
  currCategory:string
  productConfiguration:any
}> = (props) => {


  const[isOpen,setIsOpen] = useState(false)
  const[selectedQuality,setSelectedQuality] = useState(props.productConfiguration.quality)

  const  options = [
    {
      id:1,
      name:"Basic",
      icon:"fas fa-couch",
      ionIcon:null,
      startCount:[1,1],
      warranty:"1 Year"
    },
    {
      id:2,
      name:"Superior",
      icon:"fas fa-couch",
      ionIcon:null,
      startCount:[1,1,1],
      warranty:"2 Years"
    },
    {
      id:3,
      name:"Premium",
      icon:"fas fa-couch",
      ionIcon:null,
      startCount:[1,1,1,1,1],
      warranty:"5 Years"
    },
    {
      id:3,
      name:"Exclusive",
      icon:"fas fa-couch",
      ionIcon:null,
      startCount:[1,1,1,1,1],
      warranty:"5 Years"
    }
  ]



  function toggleSelection(config:any) {
    // props.onChange(config.name)
    setSelectedQuality(config.name)
    props.productConfiguration.quality = config.name
  }

  function getIconUrl(option:any){
    let currCategory = STORE.currProduct.category_name
    let url = ""
    if(currCategory === "Sofa" || currCategory === "UpholsteredBed"){
      url = `${STORE.BASE_URL_ASSETS}icons/quality/${props.currCategory}/${option.name}.svg`
    }else{
      url = `${STORE.BASE_URL_ASSETS}icons/category/${props.currCategory}.svg`
    }
    return url
  }


  function Options() {
    return(
      <>
          {options.map((option:any,index:number)=>{
            let url = getIconUrl(option)
            return(
              <div key={index} onClick={()=>toggleSelection(option)} className={`center-aligned-column item item-lg padding10 position-relative white-card shadow-light ${selectedQuality === option.name?" --is-active":""}`}>
                <div className="pointer-none">
                  <div className="padding5 center-aligned-row"><img className="icon" src={url} style={{width:"5rem",margin:"auto"}}/></div>
                  <div className="heading2 font-normal pc-color-primary text text-center">{option.name}</div>
                  <div className="marginTop5">
                    <div className="heading4 font-ex-small text-center">({option.warranty} Warranty)</div>
                  </div>
                </div>
                <div className="top-right">
                  {selectedQuality === option.name?
                  <i className="far fa-check-circle select-icon font-medium"></i>
                  :
                  <i className="far fa-circle select-icon font-medium"></i>
                  }
                </div>

                <div className="top-left padding10">
                  <i onClick={()=>{setIsOpen(true)}} className="fas fa-info-circle pc-color-primary"></i>
                </div>

              </div>
            )
          })}
      </>
    )
  }
 

  return (
    <div className="full-height-width product-customizer-wrapper" id="selectQualityContainer">
        <Modal
          id=""
          classNames="medium-y-modal"
          onDismiss={()=>setIsOpen(false)}
          isOpen={isOpen}
          Content={()=>(
            <QualityDetails></QualityDetails>
          )}
          heading="Quality details"
          FooterContent={()=>(<></>)}
          >
        </Modal>
        <HorizontalScroll
          itemsClass="customization-options space-between center-aligned-row qualiy-scrollbar-container"
          Component={Options}   
          itemsList={options}
          id={`QualityScrollBar`}     
        />
  </div>
  );
};

export default SelectQuality;
