import React from "react";

const CardsFilterHeader: React.FC<{
  FilterHeaderContent: any;
}> = (props) => {
  //Function to remove project after deleting

  return (
      <div className="filter-header-wrapper">
        <props.FilterHeaderContent />
      </div>
  )
}

export default CardsFilterHeader;
