import React, { useState } from "react";
import "../../style/admin/product.css";
import MainSideBarLayout from "../../templates/layouts/page/MainSideBarLayout";
import Filters from "./Filters";
import MoodboardContainer from "./MoodboardContainer";


const Moodboards: React.FC<{
  configObj:any
  setCurrProjectDetails:(id:number,name:string)=>void
  setCurrMoodboardId:(id:string)=>void
}> = (props) => {
  
  const[filteredMoodboardList,setFilteredMoodboardList] = useState([])

  // const[currSelectedSagment,setCurrSelectedSagment] = useState("Interior")
  

  function HeaderLeftComponent() {
    return(
      <div className="brand">
        {/* <img src="/assets/images/opus.png" alt="Opus"/> */}
        <div className="heading font-medium">Collections</div>
      </div>
    )
  }

  // function FilterHeader() {
  //   return (
  //     <MoodboardHeader
  //     configObj={props.configObj}
  //     refreshMoodboardList={refreshMoodboardList}
  //     currSelectedSagment={currSelectedSagment}
  //     setCurrSelectedSagment={setCurrSelectedSagment}
  //   />
  //   );
  // }


  function MainContent() {
    return (
            <MoodboardContainer
            configObj={props.configObj}
            setCurrProjectDetails={props.setCurrProjectDetails}
            setCurrMoodboardId={props.setCurrMoodboardId}
            filteredMoodboardList={filteredMoodboardList}
          />
    );
  }



  return (
    <React.Fragment>

      <MainSideBarLayout 
        configObj={props.configObj}
        CardsContainer={MainContent}
        HeaderLeftContent={HeaderLeftComponent}
        SideFiltersContainer={Filters}
        HeaderMiddleContent={null}
        contentWrapperId="mainProjectsContentWrapper"
      />
    </React.Fragment>
 
  );
};

export default Moodboards;
