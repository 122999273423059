import React, { useEffect, useState } from "react";
import { displayUploadedImage } from "../../../../utils/inventory/inventory";
 

const MulipleImageSelector: React.FC<{
  selectedFiles:Array<any>
  setDefaultImage:(value:any)=>void
}> = (props) => {


  const[selectedOptionIds,setSelectedOptionsIds] = useState([])

  useEffect(()=>{
    if(props.selectedFiles.length){
      for (let i = 0; i < props.selectedFiles.length; i++) {
        const file = props.selectedFiles[i]
        displayUploadedImage(file,`image_${i}`)
      }
      setSelectedOptionsIds(props.selectedFiles[0].name)
    }
  },[props.selectedFiles])


  function toggleSelection(file:any) {
    let fileName = file.name
    props.setDefaultImage(file)
    setSelectedOptionsIds(prevValue=>{
        return [fileName]
    })
  }


  return (
    <div className="">
        {/* <div className="heading3">Set Default Image</div> */}
        <div className="display-flex-row customization-options advance-customization-options">
          {props.selectedFiles.map((file:any,index:number)=>{
            return(
              <div key={index} onClick={()=>toggleSelection(file)} className={`center-aligned-column item position-relative padding0 white-card shadow-light ${selectedOptionIds.includes(file.name)?" --is-active":""}`}>
                  <img className="top-left padding0 full-height-width" id={`image_${index}`} alt=""/>
                <div className="top-right">
                  {selectedOptionIds.includes(file.name)?
                  <i className="far fa-check-circle select-icon font-large"></i>
                  :
                  <i className="far fa-circle select-icon font-large"></i>
                  }
                </div>
              </div>
            )
          })}
        </div>
    </div>
      
  );
};

export default MulipleImageSelector;
