import { save } from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { undoHandle } from "../../../../../../utils/customizein3d/area3dmodel/actions";
import { customizerConfig, disableKitchenPlannerMode, enableSpacePlanner, loadConfigFromConfigName, projectConfiguration, releaseMemoryCustomizer, updateCurrentConfig } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { saveConfiguration } from "../../../../../../utils/customizein3d/area3dmodel/helper";
import { isInteriorVisualization } from "../../../../../../utils/customizein3d/area3dmodel/interior_visualization";
import { disableProductConfigureMode } from "../../../../../../utils/customizein3d/area3dmodel/productConfigure";
import { toggleSaveOptions } from "../../../../../../utils/customizein3d/area3dmodel/UI_methods";
import { applicationConfig, logger, waitFor } from "../../../../../../utils/methods";
import { shareClickHandle } from "../../../../../../utils/moodboard/UI_methods";
import { hideComponentLoader, showComponentLoader, updateLoaderProgress } from "../../../../../../utils/UI_methods/global";
import { updateArea } from "../../../../../../utils/virtualtour/virtualtour";
import InnerPageHeader from "../../../../../common/shared-components/main-ui/InnerPageHeader";
import Modal from "../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import Actions from "../../../../../common/ui-components/tabs/Actions";
import AreaMinimap from "../../virtualtour/canvas/AreaMinimap";
import RendersList from "../common/RendersList";
import Minimap from "./Minimap";
import Share from "../../../../../common/shared-components/Share/Share";
import { IonModal } from "@ionic/react";



const CustomizerHeader: React.FC<{
  configObj:any
  currentConfigObject:any
  isProductConfigureMode:boolean
  isInteriorVisualization:boolean
  isKitchenPlannerMode:boolean
  isProductRenderMode:boolean
  }> = (props) => {



    const history = useHistory()
    // useEffect(()=>{
    //   console.log(props.currSelectedStep)
    // },[props.currSelectedStep])

    const[isOpenMinimap,setIsOpenMinimap] = useState(false)
    const[isOpenShare,setIsOpenShare] = useState(false)
    const[isRendersOpen,setIsRendersOpen] = useState(false)
    const[openSaveOption,setOpenSaveOption] = useState(false)
    const[openSaveForm,setOpenSaveForm] = useState(false)
    const[hintText,setHintText] = useState("")
    const[isCreatingRender,isCreatingRenderHeader] = useState("")
    const[refreshHeaderMiddle,setIsRefreshHeaderMiddle] = useState(Number(new Date()))

    const [actionList] = useState([
      {
        name: "Update",
        label: "Update",
        icon:save,
        iconClassNames:["color-dark"],
        action: updateConfiguration,
      },
  
      {
        name: "save",
        label: "Save",
        icon:save,
        iconClassNames:["color-primary"],
        action: saveAsNew,
      },
    ]);

    useEffect(()=>{
      applicationConfig.setFunctionRef("customizer",{setIsRendersOpen:setIsRendersOpen})
      applicationConfig.setFunctionRef("customizer",{setHintText:setHintText})
      applicationConfig.setFunctionRef("customizer",{isCreatingRenderHeader:isCreatingRenderHeader})
      applicationConfig.setFunctionRef("customizer",{setIsRefreshHeaderMiddle:setIsRefreshHeaderMiddle})
    },[])

    function gotoCustomizer(area:any) {
      // setTimeout(() => {
        setIsOpenMinimap(false)
        props.configObj.functions.customizer.updateArea(area)
        // props.setProducts(props.configObj?.projectId,area.area_id,area.area_name,props.configObj?.projectName,"View1");
        logger?.info("virtualtour","customize",`Start customizing: ${props.configObj?.projectId}`) 
      // }, 200);
      }
 

  function gotoArea(area:any,action:string){
    props.configObj?.setCurrArea(area.area_name)
    props.configObj?.setCurrAreaId(area.area_id || area.id)
    if(action === "customizer"){
      gotoCustomizer(area)
    }else{
      releaseMemoryCustomizer()
      history.push("/3dwalkthrough");
      setTimeout(() => {
        updateArea(area.area_name)
      }, 200);
    }
    setIsOpenMinimap(false)
  }

  function gotoVirtualTour() {
    if(props.isKitchenPlannerMode){
      disableKitchenPlannerMode()
      return
    }
    if(props.isProductConfigureMode){
      disableProductConfigureMode()
      return
    }
    window.history.back()

  }


  function saveForm() {
    return(
      <div className="full-height-width center-aligned-column">
        <div className="form-container width60">
          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">Config Name</div>
            </div>
            <div className="input-container">
              <input
                className="input"
                type="text"
                name="title"
                id="configTitle"
                required
              />
            </div>
          </div>

          <div className="form-label-input-container ion-margin-top">
            <div className="label-container">
              <div className="label">Description</div>
            </div>
            <div className="input-container">
              <textarea
                className="input"
                name="description"
                id="configDescription"
                required
                cols={50}
                style={{height:"80px"}}
              />
            </div>
          </div>

          <div className="center-aligned-column  full-width">
            <div className="ion-margin">
            <button className="op-btn op-btn-primary" onClick={saveAsNew}>Save</button>
            <button className="op-btn op-btn-light marginleft10" onClick={()=>setOpenSaveForm(false)}>Cancle</button>
            </div>
          </div>

        </div>
      </div>
    )
  }

  function Middle() {
    return(
      <>
      {customizerConfig?.isFinalizeMode && customizerConfig.currentRenderingType === "2d" || projectConfiguration.isProductRenderMode?
       <div className="heading2">Take 2d render</div> 
      :
      customizerConfig?.isFinalizeMode && customizerConfig.currentRenderingType === "3d"?
      <div className="heading2">Update 360 cameras</div> 
      :
      props.isProductConfigureMode?
      <div className="heading2">Product Configurator</div> 
      :
      props.isKitchenPlannerMode?
      <div className="heading2">Kitchen Planner</div> 
      :
      props.isInteriorVisualization?
      <div className="heading2">360 Walthrough</div> 
      :
      customizerConfig?.isFloorplanMode?
      <div className="heading2 display-none">Update 360 cameras</div> 
      :
        <>
        {/* <div className="header-heading">{projectConfiguration.projectName}</div> */}
        <div className={`full-width minimap-containerhide-in-mobile`} style={{background:"rgba(255,255,255,1)"}}>
           <AreaMinimap
            configObj={props.configObj}
            currentConfigObject={props.configObj}
            areas={[{area_name:"all"},...projectConfiguration?.areasList.filter(area=>area.area_name !== "Other")]}
            module="customizer"
          />
        </div>

        <div className="hide-in-desktop display-none">
          <Button2
            configObj={{}}
            action={()=>{
              applicationConfig.functions.customizer.setIsFloorPlanMode(true)
              enableSpacePlanner()
            }}
            label={props.currentConfigObject?.areaName}
            fontAwesomeIcon={"fas fa-share-alt color-primary"}
            iconPos=""
            classNames={"no-border shadow-medium button-small margin0"}
          />
        </div>
        </>
      }
      </>
    )
  }

  async function saveAsNew(val:any) {
    // isUpdate,isCreatedRender
    showComponentLoader("saveConfigLoader")
    updateLoaderProgress("saveConfigLoader",1,1,"Saving config")

    if(!String($("#configTitle").val()).length){
      alert("Please enter config name")
      return
    }
   
    projectConfiguration.setIsRenderCreated(false)
    projectConfiguration.remove2DCameras()
    saveConfiguration(false,projectConfiguration,String($("#configTitle").val())).then((data:any)=>{
        updateCurrentConfig(data.data.data.insertId)
        loadConfigFromConfigName(data.data.data.insertId)
        projectConfiguration.setAreasIsChangedConfig(true)
        applicationConfig.functions.customizer.setRefreshRenders(Number(new Date()))
    }).catch(err=>{

    })
    setOpenSaveOption(false)
    setOpenSaveForm(false)
    hideComponentLoader("saveConfigLoader")
  }

  async function updateConfiguration() {
    showComponentLoader("saveConfigLoader")
    updateLoaderProgress("saveConfigLoader",1,1,"Updating config")
    saveConfiguration(true,projectConfiguration,projectConfiguration.configName)
    setOpenSaveOption(false)
    await waitFor(1000)
    hideComponentLoader("saveConfigLoader")
  }


  function Right() {
    return(
      <div className="display-flex-row hide-in-mobile">
        {!projectConfiguration.isProductRenderMode?
        <>
          <Button2
            configObj={{}}
            action={undoHandle}
            label={""}
            fontAwesomeIcon={"fas fa-undo color-danger"}
            iconPos="left"
            classNames={props.isProductConfigureMode || props.isKitchenPlannerMode?"display-none":"shadow-medium no-border button-small undo-product"}
          />


          <Button2
            configObj={{}}
            action={toggleSaveOptions}
            label={""}
            fontAwesomeIcon={"fas fa-save color-primary"}
            iconPos="left"
            classNames={props.isProductConfigureMode || props.isKitchenPlannerMode?"display-none":"shadow-medium no-border button-small user-options-link"}
            svgUrl="assets/icon/svg/save.svg"
          />
          <Button2
            configObj={{}}
            action={()=>setIsRendersOpen(true)}
            label={"Configurations"}
            fontAwesomeIcon={"fas fa-cogs color-dark"}
            iconPos="left"
            classNames={"shadow-medium button-small no-border"}
            svgUrl="assets/icon/svg/cog.svg"
          />

          <div className="display-flex-row">
          <Button2
            configObj={{}}
            action={()=>{setIsOpenShare(true)}}
            label={"Share"}
            fontAwesomeIcon={"fas fa-share-alt color-white"}
            iconPos="left"
            classNames={"no-border shadow-medium button-small bg-color-primary"}
            svgUrl="assets/icon/svg/share_white.svg"
          />
          </div>
        </>
        :null}

       
        
      </div>
     
    )
  }

  function SaveActions() {
    return (
        <div className="full-height-width center-aligned-column">
          <Actions
            configObj={props.configObj}
            actionList={actionList}
          />
        </div>
    );
  }

  function Footer() {
    return(
      <div onClick={()=>applicationConfig.functions.customizer.toggleFloorPlanner()} className="display-flex-row zindex30">
        <i className="fas fa-edit color-danger font-medium"></i>
        <div className="display-flex-row heading3 marginleft5 color-black text-center"> Edit floor plan </div>
      </div>
    )
  }
   
  return (
    <>

      <Modal
        isOpen={isOpenMinimap} 
        classNames="project-action-modal"
        Content={()=>(
            <Minimap
            allAreas={props.configObj?.data.projectAreasList.filter((area:any)=>area.project_id===props.currentConfigObject?.projectId && area.area_name !== "Other")}
            setProducts = {()=>{}}
            closeModal = {setIsOpenMinimap}
            configObj={props.configObj}
            content="Areas"
            isUpdateUrl={false}
            action={gotoArea}
            />
            )
          }
          FooterContent={Footer}
          id={""}
          onDismiss={setIsOpenMinimap}
          heading={"Select Area"}
        
      />

       <Modal
          isOpen={isOpenShare} 
          classNames="share-modal"
          Content={()=>(
            <Share configuration={{}}
              areas={props.currentConfigObject?.areaList}
              configObj={props.currentConfigObject}
              moduleName="projects"
              closeModal={setIsOpenShare}
            />
            )
          }
          FooterContent={()=>(<></>)}
          id={""}
          onDismiss={setIsOpenShare}
          heading={"Share"}
        />
          <div className="top-left hide-in-desktop white-card hide-in-desktop zindex30 mobile-share-button" onClick={()=>setIsOpenShare(true)} style={{width:"28px",height:"28px",margin:"0.5rem"}}>
                <i className="fas fa-share-alt color-primary middle"></i>
          </div>

      <Modal
        isOpen={isRendersOpen} 
        classNames="project-action-modal"
        Content={()=>(
          <RendersList
            setIsRendersOpen={setIsRendersOpen}
            isRendersOpen={isRendersOpen}
          />
        )}
        FooterContent={()=>(<div></div>)}
        id={""}
        onDismiss={setIsRendersOpen}
        heading={"Renders list"}
      />

      <Modal
        isOpen={openSaveOption} 
        classNames="project-action-modal"
        Content={SaveActions}
        FooterContent={()=>(<div></div>)}
        id={""}
        onDismiss={setOpenSaveOption}
        heading={"Save options"}
      />

      <Modal
        isOpen={openSaveForm} 
        classNames="project-action-modal"
        Content={saveForm}
        FooterContent={()=>(<div></div>)}
        id={""}
        onDismiss={setOpenSaveForm}
        heading={"Save as new"}
      />

      <InnerPageHeader 
        configObj={props.configObj}
        componentRight={Right}
        componentMiddle={Middle}
        goBack={()=>gotoVirtualTour()}
        moduleName="customizer"
      />

      <div className='header-user-options customizer-save-options white-card' >
        <div className='option border-top display-flex-row' onClick={updateConfiguration}>
          <div><i className="fas fa-save  color-dark font-normal"></i></div>
          <div className='marginleft5 heading3 font-md-small center-aligned-column'>Save</div>
        </div>


        <div className='option display-flex-row'   onClick={()=>setOpenSaveForm(true)}>
          <div><i className="fas fa-save  color-primary font-normal"></i></div>
          <div className='marginleft5 heading3 font-md-small center-aligned-column'>Save as new</div>
        </div>
        </div>
    
</>
  );
};

export default CustomizerHeader;
