import $ from "jquery";
import Konva from "konva";
import { getParentContainer, hideComponentLoader, showComponentLoader, showElement, updateLoaderProgress } from "../UI_methods/global";
import AwsConfig from "../aws-sdk/AwsConfig";
import { checkFromCache, untarAndSaveToCache } from "../cache/cache";
import { getUrlPrefix } from "../customizer/customizer";
import {
  applicationConfig,
  getCurrentConfigurationFromLocalStorage,
  getMaterialsImageUrl,
  logger,
  setCurrentConfigurationInLocalStorage,
  stringWithoutWhiteSpace,
  waitFor
} from "../methods";
import { addCanvas, hideAllProductAnnotations, hideItemsPanel, hideProductActions, removeProductCanvas, updateProductActionPosition, updateUndoVisibility } from "./UI_methods";
import { addAddon, updateAddonsListPosition } from "./addons";
import { addImageInConfiguration, addModulerIcon, compareConfigurations, downloadMoodboard, getCanvasContainerWrapperName, getDefaultWidth, getDimensionsValues, getImageDetails, getImageReferenceName, getMoodBoardConfigKey, getMoodBoardNotesConfigAreaKey, getMoodBoardNotesConfigKey, getProductImageUrl, getTransformValues, hideProductSelectionBox, isProductModuler, removeAllProducts, removeMoodboardConfig, removeOtherCanvases, removeProductModulesFromConfig, resetCanvas, showProductSelectionBox, updateAnchorsPositions, updateConfiguratonWithSavedConfig, updateImagePosition, updateMoodBoardConfig, updatePrice, updateScreenshot, varifyCategories } from "./helper";
import { addonsGroup, autoArrangeLShape, centerModuleGroup, isValidConfig, modulesGroup, modulesInit, resetModuleGroups, updateModulePositionOfLShape } from "./modules/sofa";
import { isMultipleSelectionMode } from "./multipleSelect";
import { updateProductSelectionBox } from "./raycasting";
import { addText } from "./text";
import { Configuration } from "./Configuration";

import {Image as MoodboardImage} from "./Configuration"
import Moodboardconfig from "./moodboardConfig";


export var currSelectedGroup: any = null;
// var stage: any;

//Widht of canvas
var configObject: any = null;
export var IMAGE_WIDTH = 512 / 3;
export var IMAGE_HEIGHT = (IMAGE_WIDTH * 3) / 4;
var ASPECT_RATIO = 1
var POS_X = 0;
var POS_Y = 0;
var updateSetIsConfigUpdated: (value: boolean) => void;
export var projectAreaCategories:any = null
export var moodboardConfig:Moodboardconfig = null
var moduleRef = null

var pageTourCompleted = false
 

var defaultPosX = 50,defaultPosY = 50

export var canvasLoaderId:string

export var moodboardFullPageLoaderId:string = "moodboardFullPageLoader"

var addonCategories = ["WallPaint","Carpet","WallCladding"]

var awsConfig = null


// export var transformer = null
export var selectionRectangle = null

export var configuration:Configuration = null

export var isModulesMode = false
 

export async function Init(configObj: any , moduleReferences:any,moodboardConfigObject:Moodboardconfig,canvasLoader:string,isModulesModeUI:boolean = false) {
  configObject = configObj;
  moduleRef = moduleReferences
  moodboardConfig = moodboardConfigObject

  awsConfig = new AwsConfig()

  canvasLoaderId = canvasLoader
  isModulesMode = isModulesModeUI

  if(isModulesMode){
    resetModuleGroups()
  }

  let stage = await moodboardConfig.createStageAndLayer("mainMoodboardCanvasContainer")
 
  window.addEventListener("resize",scaleAndDrawImages)
  updateUndoVisibility()

  if(isModulesMode){
    modulesInit(stage,stage.children[0])
  }

  if(isModulesMode){
    defaultPosX = 250
    defaultPosY = 30
  }
}


export  function createConfigruation(moodboardId:string,areaName:string = "Default") {
  configuration = new Configuration(moodboardId,areaName)
  let savedConfig = updateConfiguratonWithSavedConfig(areaName)
  if(savedConfig){
    configuration.createConfigFromSavedConfig(savedConfig)
  }
  configuration.updateLocalStorage()
  return configuration
}
export async function loadSavedConfiguration() {
  updateLoaderProgress(moodboardFullPageLoaderId,0,1,`Loading config`)
  showComponentLoader(moodboardFullPageLoaderId)
  await loadAndDrawImagesFromConfig(configuration)
  hideComponentLoader(moodboardFullPageLoaderId)
  updateCanvasScreenshot()
  varifyCategories([{area_name:configuration.areaName}],null,projectAreaCategories)
}

export async function scaleAndDrawImages() {
  showComponentLoader(moodboardFullPageLoaderId)
  await waitFor(500)
  removeOtherCanvases()
  await loadAndDrawImagesFromConfig(configuration)
  updateCanvasScreenshot()
  hideComponentLoader(moodboardFullPageLoaderId)
}

export function InitMoodBoardCanvas(setIsConfigUpdated: (value: boolean) => void,updateStatus:(confirmed:Array<any>,remaining:Array<any>,additional:Array<any>,areaName:string)=>void,areaCategories:any) {
  updateSetIsConfigUpdated = setIsConfigUpdated;
  projectAreaCategories = areaCategories
  moodboardConfig.resetLayer()
}


export function showTitleEditor() {
  showElement("titleEditor")
}

export function getCanvasWidth() {
  return $(".moodboard-canvas-container").width()
}

export function getCanvasHeight(){
  return $(".moodboard-canvas-container").width() * (3/4)
}

export function getScale(prevCanvasWidth:any,prevCanvasHeight:any) {
  return {
    changeInWidth : (getCanvasWidth() - prevCanvasWidth) / prevCanvasWidth,
    changeInHeight : (getCanvasHeight() - prevCanvasHeight) / prevCanvasHeight
  }
}

export async function loadAndDrawImagesFromConfig(configuration: any) {
  let layer = moodboardConfig.getLayer("mainMoodboardCanvasContainer")
  let images = configuration.images
  resetCanvas(layer)
  hideProductActions()
  let i = 0
  let promises = []
    // let posY = -1
  for (const currImage of images) {
    promises.push(await loadAndDrawImage(currImage)) 
    updateLoaderProgress(moodboardFullPageLoaderId,i+1,configuration.images.length,`${i+1} / ${configuration.images.length} items added`)
    i++
  }
  await Promise.all(promises)
  updatePrice(configObject)
}



export function removeProductFromCurrentConfig(imageId:string) {
  configuration.removeImage(imageId)
}



export function updateMoodBoardNotes(notes:string) {
  let key = getMoodBoardNotesConfigKey()
  let areaKey = getMoodBoardNotesConfigAreaKey(configObject?.getMoodboardId(),configObject.getCurrArea())
  let configuration = getCurrentConfigurationFromLocalStorage(key)
  if(!configuration){
    setCurrentConfigurationInLocalStorage(key,{})
  }
  configuration = getCurrentConfigurationFromLocalStorage(key)
  configuration[areaKey] = notes 
  setCurrentConfigurationInLocalStorage(key,configuration)
}


export function getConfigurationFromLocalStorage(areaName: string) {
  let key = getMoodBoardConfigKey(configuration.moodboardId, areaName)
  let config = getCurrentConfigurationFromLocalStorage(key)
  return config;
}


export function addAnchor(layer:any,group: any, x: number, y: number, name: string,imageAspectRatio:any) {

  // let strokColor = "#fff"
  let strokColor = "#666"
  // let fillColor = "#f1f5fa"
  let fillColor = "#ffffff"

  //for undo 
  let startW,startH = 0
  let image = null

   
  var anchor = new Konva.Circle({
    x: x,
    y: y,
    stroke: strokColor,
    fill: fillColor,
    strokeWidth: 0.5,
    radius: 6,
    name: name,
    draggable: true,
    dragOnTop: false,
    opacity:0,
    index:2
  })

  anchor.on("dragmove", function () {
    updateImagePosition(this,imageAspectRatio)
    layer.draw()
  })
  anchor.on("mousedown touchstart", function () {
    group.draggable(false)
    this.moveToTop()

    image = group.find(".canvasItem")[0]
    startW = image.attrs.width
    startH = image.attrs.height
  })

  anchor.on("dragend", function () {
    group.draggable(true)
    layer.draw()

    moodboardConfig.addItemToStack("resize",{startW:startW,startH:startH,canvasContainerId:group.attrs.canvasContainerId,group:group,image:image})
  })
  // add hover styling
  anchor.on("mouseover", function () {
    document.body.style.cursor = "pointer";
    this.strokeWidth(1)
    layer.draw()
  })
  anchor.on("mouseout", function () {
    document.body.style.cursor = "default";
    this.strokeWidth(0.5)
    layer.draw()
  })

  group.add(anchor)
}



export async function drawImageKonva(imageObj:any,image:MoodboardImage) {

  return new Promise(async (resolve,reject)=>{
    let canvas = image.canvas
    let dimensions = image.dimensions
    let transform = image.transform
    let canvasContainerId = canvas.canvasContainerId || "mainMoodboardCanvasContainer"

    let stage = moodboardConfig.getStage(canvasContainerId)
    let layer = moodboardConfig.getLayer(canvasContainerId)
    let upperLayer = moodboardConfig.getUpperLayer(canvasContainerId)
  
    if(!layer || !upperLayer || !stage){
      addCanvas(moodboardConfig,getImageReferenceName(image),false,canvasContainerId)
      await waitFor(500)
      stage = moodboardConfig.getStage(canvasContainerId)
      layer = moodboardConfig.getLayer(canvasContainerId)
      upperLayer = moodboardConfig.getUpperLayer(canvasContainerId)
    }

    const product = image.imageReference
    const isAddedAsAddon = product?.subCategoryName?.toLowerCase().includes("standalone") ? true : false

  
    //Base width for stage
    let imageAspectRatio = dimensions.width / dimensions.height
  
    let productImage = new Konva.Image({
      image: imageObj,
      width: dimensions.width,
      height: dimensions.height,
      name:'canvasItem',
      index:0,
    })
  
  
    //Use in auto arranging
    productImage.setAttrs({
      product:image,
    })


    let productAttributes = {
      posX: transform.posX,
      posY: transform.posY,
      width: dimensions.width,
      height: dimensions.height,
      imageId:image.imageId,
      canvasContainerId:canvasContainerId
    };

    //Imageobj for redrawing in case of undo 
    let productAnchorGroup = new Konva.Group({
      rotation:transform.rotation,
      x: transform.posX,
      y: transform.posY,
      draggable: isAddedAsAddon || !isModulesMode,
      imageObj:imageObj,
      //Use in addons
      canvasContainerId:canvasContainerId,
      image:image,
      productImage:productImage,
      name:"canvasImageItemGroup",
      // For layouts
      priority: 0,
      product:image.imageReference,
      productAttribites:productAttributes,
      isAddedAsAddon:isAddedAsAddon
    })

    if(isModulesMode){
      if(isAddedAsAddon){
        addonsGroup.add(productAnchorGroup)
      }else{
        modulesGroup.add(productAnchorGroup)  
      }
    }else{
      layer.add(productAnchorGroup)  
    }
    productAnchorGroup.add(productImage)
    
  
    if(isProductModuler(configObject,image)){
      addModulerIcon(productAnchorGroup,layer,image)
      addCanvas(moodboardConfig,getImageReferenceName(image),true)
      await waitFor(500)
    }
  
    
    updateAnchorPositions(productAnchorGroup,layer,productImage,canvasContainerId,imageAspectRatio)
    
    addEventListenersOnGroup(productAnchorGroup,productImage,image,layer)
  
    if(addonCategories.includes(image.imageReference.categoryName) ){
      if(image?.addonsList?.length){
        // setTimeout(() => {
        //   drawAddonsFromConfig(productAttributes?.addonsList,productAnchorGroup)
        // }, 500);
      }
      addAddon(productAnchorGroup)
    }

    let transformer = moodboardConfig.getTransformer(canvasContainerId)
    upperLayer.add(transformer);
    layer.draw()

    moodboardConfig.add(productAnchorGroup,canvasContainerId)
    updateCanvasScreenshot()
  
    resolve(productAnchorGroup)
  })
}


export function addEventListenersOnGroup(productAnchorGroup:any,productImage:any,image:MoodboardImage,layer:any) {
  
  let canvas = image.canvas
  let dimensions = image.dimensions
  let transform = image.transform
  //For undo 
  let startX,startY = 0
  productImage.on("mouseover", function () {
    document.body.style.cursor = "grab";
  })
  productImage.on("mousedown", function () {
    document.body.style.cursor = "grabbing";
  })
  productImage.on("mouseout", function () {
    document.body.style.cursor = "default";
  })

  productAnchorGroup.on("dragend", function () {
    if(isMultipleSelectionMode){
      return
    }
    moodboardConfig.addItemToStack("move",{startX:startX,startY:startY,canvasContainerId:canvas.canvasContainerId,group:productAnchorGroup})
    // console.log(productAnchorGroup.getAttrs())
 
    transform.posX = productAnchorGroup.getAttrs().x;
    transform.posY = productAnchorGroup.getAttrs().y;
    dimensions.width = productAnchorGroup.find("Image")[0]?.getAttrs().width;
    dimensions.height = productAnchorGroup.find("Image")[0]?.getAttrs().height;

    logger?.info("editcollection","resizeitem",`Resize item: ${getImageReferenceName(image)}`)
   
    if(addonCategories.includes(image.imageReference.categoryName) ){
      updateAddonsListPosition(productAnchorGroup,layer)
      $(".addons-container").removeClass("--is-active")
    }

    showProductSelectionBox(productAnchorGroup)
    updateUndoVisibility()
    configuration.updateLocalStorage()
  })

  productAnchorGroup.on("dragmove", function () {
    if(isMultipleSelectionMode){
      return
    }
    updateProductSelectionBox(productAnchorGroup.attrs.rotation,productImage.getWidth(),productImage.getHeight(),canvas.canvasContainerId,productImage)
  })

  productAnchorGroup.on("mousedown touchstart", function (event: any) {
    startX = productAnchorGroup.attrs.x
    startY = productAnchorGroup.attrs.y
    if(isMultipleSelectionMode){
      return
    }
    currSelectedGroup = productAnchorGroup

    showProductSelectionBox(productAnchorGroup)
    updateProductSelectionBox(productAnchorGroup.attrs.rotation || 0,productImage.getWidth(),productImage.getHeight(),canvas.canvasContainerId,productImage)
    updateTransformer(productAnchorGroup,canvas.canvasContainerId)
  })
  


  productAnchorGroup.on("transform", function (event: any) {
    updateProductSelectionBox(productAnchorGroup.attrs.rotation,productImage.getWidth(),productImage.getHeight(),canvas.canvasContainerId,productImage)
  })

  productAnchorGroup.on("transformend", function (event: any) {
    transform.rotation = productAnchorGroup.attrs.rotation
    configuration.updateLocalStorage()
  })
}

 
 

export function resetTransformer(canvasContainerId:string){
  let transformer = moodboardConfig.getTransformer(canvasContainerId)
  transformer.nodes([])
  transformer.update()
}

export function updateTransformer(group:any,canvasContainerId:string) {
  let transformer = moodboardConfig.getTransformer(canvasContainerId)
  transformer.nodes([group])
  transformer.update() 
}


export function updateAnchorPositions(group:any,layer:any,productImage:any,canvasContainerId:string,imageAspectRatio:any) {
  let width = productImage.getWidth()
  let height = productImage.getHeight()

  // removeAnchorsFromOtherImages(layer)

  //if anchors has been already drawn skip drawing

  if (!group.find(".topLeft")[0]) {
    addAnchor(layer,group, productImage.position().x, 0, "topLeft",imageAspectRatio)
    addAnchor(layer,group, width + productImage.position().x, 0, "topRight",imageAspectRatio)
    addAnchor(layer,group, width + productImage.position().x, height, "bottomRight",imageAspectRatio)
    addAnchor(layer,group, productImage.position().x, height, "bottomLeft",imageAspectRatio)
  }
}

export function emptyCanvas(event:any) {
  if(event){
    let canvasContainer = $(event?.target).parents(".moodboard-canvas-container").children(".canvas-container")
    if(canvasContainer){
      let id = canvasContainer.attr("id")
      let layer = moodboardConfig.getLayer(id)
      removeMoodboardConfig()
      resetCanvas(layer)
      return
    }
  }
  resetCanvas(moodboardConfig.getLayer("mainMoodboardCanvasContainer"))
}


export function confirmEmptyCanvas(event:any) {
  moduleRef.confirmEmptyCanvas(event)
}

export function removeAllProductsFromCanvas() {
  let layer = moodboardConfig.getLayer("mainMoodboardCanvasContainer")
  removeAllProducts(configObject,layer,projectAreaCategories,updateSetIsConfigUpdated)
}

export function removeProduct() {
  try {
    if (currSelectedGroup) {
      let group = currSelectedGroup
      removeGroup(group,group.parent)
      moodboardConfig.addItemToStack("delete",{...group.attrs})
      updateUndoVisibility()
    }
  } catch (error) {
    console.log(error)
  }
}

export function removeGroup(group:any,layer:any,isUndo=true) {
  let image = group.attrs.image
  let imageType = image.imageType
  let canvasContainerId = group.attrs.canvasContainerId

  group.removeChildren()
  group.remove()
  layer.draw()
  removeProductFromCurrentConfig(image.imageId)

  if(isProductModuler(configObject,image)){
    removeProductCanvas(getCanvasContainerWrapperName(image))
    removeProductModulesFromConfig(configObject,image)
  }

  if(imageType === "module"){
    autoArrangeLShape(canvasContainerId)
  }

  postDeleteAction(canvasContainerId)
  logger?.info("editcollection","deleteitem",`Delete Item: ${image?.productName}`)
}


export async function undo(event:any = null) {

  let item = moodboardConfig.getLastStackItem()
  if(!item){
    alert("Nothing to undo")
    return
  }
  switch (item.action.toLocaleLowerCase()) {
    case "add":
      removeGroup(item.itemInfo.group,item.itemInfo.layer,true)
    break;
  
    case "delete":
      await drawImageKonva(item.itemInfo.imageObj,item.itemInfo.image)
      if(isModulesMode){
        autoArrangeLShape(item.itemInfo?.canvasContainerId)
      }
    break;

    case "move":
      hideProductSelectionBox(item.itemInfo.group,item.itemInfo.canvasContainerId)
      $(".product-selection-box").removeClass("--is-active")
      item.itemInfo.group.absolutePosition({
        x: item.itemInfo.startX,
        y: item.itemInfo.startY
      });
      // item.itemInfo.group.parent.draw()
    break

    case "resize":
      hideProductSelectionBox(item.itemInfo.group,item.itemInfo.canvasContainerId)
      $(".product-selection-box").removeClass("--is-active")
      item.itemInfo.image.width(item.itemInfo.startW)
      item.itemInfo.image.height(item.itemInfo.startH)
      // item.itemInfo.group.parent.draw()
      updateAnchorsPositions(item.itemInfo.group)
    break

    default:
      break;
  }
 
  moodboardConfig.removeItemFromStack()
  updateUndoVisibility()
  let layer = item.itemInfo?.layer
  if(layer){
    layer.draw()
  }
}

export function postDeleteAction(canvasContainerId:string) {
  currSelectedGroup = null;
  updatePrice(configObject)
  compareConfigurations(false,configObject,updateSetIsConfigUpdated)
  varifyCategories([{area_name:configObject?.areaName}],configObject,projectAreaCategories)
  hideAllProductAnnotations()
  hideProductSelectionBox(null,canvasContainerId)
  hideItemsPanel()
  updateCanvasScreenshot()
}


export function getUploadedImageUrl(productName:string) {
  return "uploads/" + configObject?.clientName + "/collections/"+ productName
}


export async function loadAndDrawImage(image:MoodboardImage,imageUrl:any = null) {
    let group = null
    let isDownloadImage = true
    let imageReference = image.imageReference

    $("#initialText").removeClass("--is-active")
    let imageSrc:string = ''
    switch(image.imageType){
      case "finish":
        imageSrc = getMaterialsImageUrl(configObject.BASE_URL_MODEL_VIEWER,imageReference.companyName,imageReference.collectionName,imageReference.materialCode)
        break
      case "uploaded":
        imageSrc = imageReference.imageKey
        isDownloadImage = true
        break
      case "module":
        imageSrc = imageUrl
        isDownloadImage = false
        break
      default:
        imageSrc = getProductImageUrl(configObject.BASE_URL_ASSETS,configObject.clientName,imageReference.productName,imageReference.categoryName,imageReference.subCategoryName)
      }

    if(image.imageType==="uploaded"){
      group = await drawImage(isDownloadImage,imageSrc,image)
      return group
    }
    if(image.imageType==="text"){
      await waitFor(500)
      addText({text:imageReference.productName,...image})
      return group
    }
    group = await drawImage(isDownloadImage,imageSrc,image)
    return group
}

//When loading config from database don't update config
//Perform other operations like update config and compare config
async function drawImage(isDownloadImage:boolean,imageSrc:string,image:MoodboardImage) {
  try {
    if(isDownloadImage && image.imageType.includes("product")){
      let imageReference = image.imageReference
      let tarFileKey = awsConfig.getTarFileKey("products",{clientName:configObject.clientName,productName:imageReference.productName,category:imageReference.categoryName,subCategory:imageReference.subCategoryName})
      let urlPrefix = getUrlPrefix(imageSrc)
      await untarAndSaveToCache(awsConfig,urlPrefix,tarFileKey).catch(err=>{})
    }
    const loadImage = isDownloadImage ? loadImageFromCache : loadImageFromImageSrc;
    let group = await loadImage(imageSrc, image)
    return group
  } catch (error) {
    return null
  }
  
}


async function loadImageFromImageSrc(imageSrc:string,image:MoodboardImage) {
  return new Promise(async (resolve,reject)=>{
    let imageObj:any = new Image()
    imageObj.onload = async function () {

      let dimensions = image.dimensions
      //Update Aspect ratio based on the croped image 
      if(image.imageType === "module"){
        ASPECT_RATIO = this.height / this.width
        dimensions.width = dimensions.height / ASPECT_RATIO
      }
      let group = await drawImageKonva(imageObj, image)
      resolve(group)
    };
    imageObj.src = imageSrc;
    imageObj.onerror = async function () {
      reject("Error in loading the image")
    };
  })
  
}

function loadImageFromCache(imageSrc:string,image:MoodboardImage) {
  return new Promise(async (resolve,reject)=>{
    await checkFromCache(imageSrc)?.then(async (data:string)=>{
      loadImageFromImageSrc(data,image).then(group=>{
        resolve(group)
      }).catch(err=>{
        console.log(err)
        reject(err)
      })
    }).catch(err=>{
      console.log(err)
      reject(err)
    })
  })
}


export function allowDrop(event: any) {
  event.preventDefault()
}


export async function copyProduct() {

 

  if (currSelectedGroup) {
    let image:MoodboardImage = currSelectedGroup.attrs.image
    let duplicateImage = new MoodboardImage(image.imageId,image.canvas,image.imageReference,image.transform,image.dimensions,image.imageType)
    const containerId = duplicateImage.canvas.canvasContainerId
    duplicateImage.imageId = String(Number(new Date()))
    duplicateImage.transform.posX += 50
    duplicateImage.transform.posY += 50 
    let stage = moodboardConfig.getStage(containerId)
    let duplicateGroup:any = await drawImageKonva(currSelectedGroup.attrs.imageObj,duplicateImage)

    if(isModulesMode){
      if(isValidConfig(duplicateGroup,containerId)){
        updateModulePositionOfLShape(duplicateGroup,containerId)
      }else{
        removeGroup(duplicateGroup,duplicateGroup.parent)
      }
    }

    moodboardConfig.addItemToStack("add",{group:duplicateGroup,layer:duplicateGroup.parent})
    addImageInConfiguration(duplicateImage,stage)
    postDeleteAction(containerId)
  }
}


export function rotateProduct() {
  if (currSelectedGroup) {
    let layer = moodboardConfig.getLayer(currSelectedGroup.attrs.canvasContainerId)
    currSelectedGroup?.rotate(90)
    // removeAnchorsFromOtherImages(layer)
    updateProductActionPosition(currSelectedGroup.position().x,currSelectedGroup.position().y,currSelectedGroup.attrs.rotation,currSelectedGroup.attrs.productImage.getWidth(),currSelectedGroup.attrs.productImage.getHeight(),currSelectedGroup.attrs.canvasContainerId)
    layer.draw()
  }
}


export function downloadCanvas(event) {
  if(event){
    let canvasContainer = $(event?.target).parents(".moodboard-canvas-container").children(".canvas-container")
    if(canvasContainer){
      let id = canvasContainer.attr("id")
      let stage = moodboardConfig.getStage(id)
      downloadMoodboard(stage)

      logger?.info("editcollection","download",`Canvas downloaded: ${id}`)

    }
  }
}


export function updateCanvasScreenshot(){
  let stage = moodboardConfig.getStage("mainMoodboardCanvasContainer")
  updateScreenshot(stage,configObject)
}


export async function dropImageMoodboard(event: any) {
  // showComponentLoader(canvasLoaderId)
  //If module is not collections
  $("#draggableImg").fadeOut()
  let parentContainer = getParentContainer()
  
  if($(parentContainer).attr("id") != "createMoodboardWrapper"){
    return
  }

  let canvas = $(event.target)
  event.preventDefault()

  let posX = defaultPosX
  let posY = defaultPosY

   //Current timestamp to create uniue product in config 
   $("#initialText").removeClass("--is-active")
   // return
   let imageData = getImageData(event)

  //get container id where image is droped
  let containerId = $(event.target).parent().parent().attr("id") 
  //For clicked event instead of drop
  if(containerId === "moodboardProductsWrapper" || !containerId){
    containerId = "mainMoodboardCanvasContainer"
    //random position for clicked image 
    updateDefaultPositions(containerId)
    let imageWidth = canvas.width()!
    let imageHeight = canvas.height()!
    ASPECT_RATIO = imageHeight / imageWidth
    IMAGE_HEIGHT = IMAGE_WIDTH * ASPECT_RATIO

  }else{
    let offsetTop:any = $("#"+containerId).offset()!.top
    let offsetLeft:any = $("#"+containerId).offset()!.left
    posX = event.clientX - offsetLeft - (IMAGE_WIDTH / 2)
    posY = event.clientY - offsetTop - (IMAGE_HEIGHT / 2)
  }

  //if image is module lower the image size
  if(imageData.type === "module"){
    containerId = "containerDS1735"
    ASPECT_RATIO = ASPECT_RATIO / 3
    IMAGE_WIDTH = IMAGE_WIDTH / 3
    IMAGE_HEIGHT = IMAGE_HEIGHT / 3
  }

  

  let canvasDetails = {
    canvasWidth:getCanvasWidth(),
    canvasHeight:getCanvasHeight(),
    canvasContainerId:containerId,
  }


  let imageDetails = getImageDetails(imageData)

  let transform = {
    posX:posX,
    posY:posY,
    canvasContainerId:containerId
  };

  let dimensions = {
    width: IMAGE_WIDTH ,
    height: IMAGE_HEIGHT,
  }

  let image = new MoodboardImage(imageData.imageId,canvasDetails,imageDetails,transform,dimensions,imageData.type)

  await loadAndDrawImage(image).then((group:any)=>{
    if(imageData.type === "module"){
      if(isValidConfig(group,containerId)){
        updateModulePositionOfLShape(group,containerId)
      }else{
        removeGroup(group,group.parent)
      }
    }

    addImageInConfiguration(image,null)
    compareConfigurations(false,configObject,updateSetIsConfigUpdated)

    moodboardConfig.addItemToStack("add",{group:group,layer:group.parent})
    updateUndoVisibility()
    hideComponentLoader(canvasLoaderId)
  })
}


export function createConfigurationForTopView(modules:any,product:any){
  let containerId = "mainMoodboardCanvasContainer"
  
  let transform = {
    posX:0,
    posY:0,
    canvasContainerId:containerId
  };

  let dimensions = {
    width: IMAGE_WIDTH ,
    height: IMAGE_HEIGHT,
  }
 

  let posX = defaultPosX
  let posY = defaultPosY

  let canvasDetails = {
    canvasWidth:getCanvasWidth(),
    canvasHeight:getCanvasHeight(),
    canvasContainerId:containerId,
  }



  for (const module of modules) {
    let imageData = {
      imageId : String(Number(new Date)),
      id : product.product_id,
      name : module.moduleName,
      category : product.category_name,
      subCategory : module.moduleType,
      type : "module",
      src : null
    };
    let imageDetails = getImageDetails(imageData)
    let image = new MoodboardImage(imageData.imageId,canvasDetails,imageDetails,transform,dimensions,imageData.type)
    let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/moduleImages/${module.moduleName}.png`
    loadImageFromImageSrc(url,image)
  }
}

export function addModuleImageInCanvas(event:any) {
  let canvas = $(event.target)
  event.preventDefault()
  let posX = defaultPosX
  let posY = defaultPosY

  let imageData = getImageData(event)
  let imageDetails = getImageDetails(imageData)

  let containerId = "mainMoodboardCanvasContainer" 
  updateDefaultPositions(containerId)
  let imageWidth = canvas.width()!
  let imageHeight = canvas.height()!

  ASPECT_RATIO = imageHeight / imageWidth

  IMAGE_HEIGHT = IMAGE_WIDTH * ASPECT_RATIO

  IMAGE_HEIGHT = getCanvasWidth() / 8
  
  if(imageDetails.productName.toLowerCase().includes("lounger") || imageDetails.productName.toLowerCase().includes("bumper")){
    IMAGE_HEIGHT = getCanvasWidth() / 6
  }

  let canvasDetails = {
    canvasWidth:getCanvasWidth(),
    canvasHeight:getCanvasHeight(),
    canvasContainerId:containerId,
  }
  let transform = {
    posX:posX,
    posY:posY,
    canvasContainerId:containerId
  };

  let dimensions = {
    width: IMAGE_WIDTH ,
    height: IMAGE_HEIGHT,
  }


  let image = new MoodboardImage(imageData.imageId,canvasDetails,imageDetails,transform,dimensions,imageData.type)
  loadAndDrawImage(image,imageDetails.src).then((group:any)=>{
    if(group){
      if(isValidConfig(group,containerId)){
        updateModulePositionOfLShape(group,containerId)
      }else{
        removeGroup(group,group.parent)
      }
      addImageInConfiguration(image,null)
      moodboardConfig.addItemToStack("add",{group:group,layer:group.parent})
      updateUndoVisibility()
      centerModuleGroup()
    }
      
  })
 
}


function getImageData(event:any) {
  return {
    imageId : String(Number(new Date)),
    id : event.dataTransfer?.getData("productId") || $(event.target).attr("data-product-id"),
    name : event.dataTransfer?.getData("productName") || $(event.target).attr("data-product-name"),
    category : event.dataTransfer?.getData("categoryName") || $(event.target).attr("data-product-Category"),
    subCategory : event.dataTransfer?.getData("subCategoryName") || $(event.target).attr("data-module-type"),
    type : event.dataTransfer?.getData("imageType") || $(event.target).attr("data-image-type"),
    src : event.dataTransfer?.getData("src") || $(event.target).attr("src")
  };
}


export  function updateDefaultPositions(containerId:string) {
  defaultPosX += 50
  defaultPosY += 50
  let stage = moodboardConfig.getStage(containerId)
  if(defaultPosY+IMAGE_HEIGHT > stage.attrs.height){
    defaultPosX = 20
    defaultPosY = 20
  }
}

    
export function dragStart(event: any) {
  if(event.target.getAttribute("data-image-type").toLowerCase() === "product"){
    $("#draggableImg").fadeIn(0)
  }
  let imageWidth = $(event.target).find("img").width()! || $(event.target).width()!
  let imageHeight = $(event.target).find("img").height()! || $(event.target).height()!
  ASPECT_RATIO = imageHeight / imageWidth
  event.dataTransfer.setDragImage(document.createElement("img"), 0, 0)
  imageWidth = getDefaultWidth()
  
  IMAGE_WIDTH = imageWidth
  IMAGE_HEIGHT = IMAGE_WIDTH * ASPECT_RATIO


  // event.dataTransfer?.setData("product", event.target.id)
  event.dataTransfer?.setData("productId", event.target.getAttribute("data-product-id"))
  event.dataTransfer?.setData("productName", event.target.getAttribute("data-product-name"))
  event.dataTransfer?.setData("categoryName", event.target.getAttribute("data-product-category"))
  event.dataTransfer?.setData("subCategoryName", event.target.getAttribute("data-product-subcategory"))
  event.dataTransfer?.setData("imageType", event.target.getAttribute("data-image-type"))
  event.dataTransfer?.setData("src", event.target.getAttribute("src"))
}

export async function addImageToCanvasTouchDevice(event:any) {
  let imageId = String(Number(new Date()))
  let touchLocation = event.changedTouches[0]

  let scale = 0.6
  let containerId = "mainMoodboardCanvasContainer"

  let leftStartOffset = $("#mainMoodboardCanvasContainer")?.offset()?.left!
  let topStartOffset = $("#mainMoodboardCanvasContainer")?.offset()?.top!



 
  let element = null

  if($(event.target).parents(".finish-image-container").length){
    element = $(event.target).parents(".finish-image-container").find("img")
  }

  if($(event.target).parents(".product-image-container").length){
    element = $(event.target).parents(".product-image-container").find("img")
  }

  if($(event.target).parents(".uploaded-image-container").length){
    element = $(event.target).parents(".uploaded-image-container").find("img")
  }


  let imageWidth = element.width()!
  let imageHeight = element.height()!
  ASPECT_RATIO = imageHeight / imageWidth
  imageWidth = getDefaultWidth()
  IMAGE_WIDTH = imageWidth
  IMAGE_HEIGHT = IMAGE_WIDTH * ASPECT_RATIO
 

  var id = element.attr("data-product-id")
  var name = element.attr("data-product-name")
  var category = element.attr("data-product-category")
  var subCategory = element.attr("data-product-subcategory")
  var type = element.attr("data-image-type")
  
  if(type === "module"){
   containerId = `container${stringWithoutWhiteSpace(name)}`
  }

  let canvasDetails = {
    canvasWidth:getCanvasWidth(),
    canvasHeight:getCanvasHeight(),
    canvasContainerId:containerId,
  }

  let product = {
    productId:id,
    productName:name,
    categoryName:category,
    subCategoryName:subCategory,
    imageType:type
  };
  let productAttributes = {
    posX: touchLocation.pageX - leftStartOffset,
    posY: touchLocation.pageY - topStartOffset,
    width: IMAGE_WIDTH * scale,
    height: IMAGE_HEIGHT * scale,
    imageId:imageId,
    canvasContainerId:containerId
  };

  let transform = getTransformValues(productAttributes)
  let dimensions = getDimensionsValues(productAttributes)

  let image = new MoodboardImage(imageId,canvasDetails,product,transform,dimensions,type)

  await loadAndDrawImage(image)
  addImageInConfiguration(image,null)
  compareConfigurations(false,configObject,updateSetIsConfigUpdated)
}


export function addNewPage(){
  let timestamp = String(Number(new Date()))
  let canvasContainerId = `containerDefault${timestamp}`
  addCanvas(moodboardConfig,"Default",false,canvasContainerId)
}

