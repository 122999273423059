import React, { useState } from 'react';
import UploadModal from './UploadModal';
import { debugScene } from '../../../../../../utils/customizein3d/modules/customizein3d';
import Button2 from '../../../../../common/ui-components/buttons/Button2';
import { applicationConfig } from '../../../../../../utils/methods';
import ControlsTab from '../../../../../common/ui-components/tabs/ControlsTab';
import $ from "jquery"
import Finish from './Finish';
import Configuration from './Configuration';



const Controls = ({}) => {
   
  
  
   
    const [activePanel, setActivePanel] = useState('upload'); // Default to 'upload' panel

    const[tabItems,setTabItems] = useState([
        {tabName:"DebugUpload",label:"Upload",ionIcon:null},
        {tabName:"DebugFinish",label:"Finish",ionIcon:null},
        {tabName:"DebugConfiguration",label:"Configuration",ionIcon:null},
        ])
    const [currTab, setCurrTab] = useState<string>("DebugConfiguration");


   

 

 

  

    const closeUploadModal = () => {
        setActivePanel(null); // Close the modal by resetting the active panel
    };


    function Upload(){
        return(
            <div className="panel">
                <UploadModal onClose={closeUploadModal} />
            </div>
        )
    }

   
    
    function changeTabOption(tabName: string) {
        setCurrTab(tabName);
        updateTabValue(tabName)
    }
    function updateTabValue(val:string){
        $("#DebuggerstoreControlsPanel").find(".tab-content").removeClass("--is-active")
        $("#"+val).addClass("--is-active")
    }


    return (
        <div id="DebuggerstoreControlsPanel" className="controls bg-color-white top-left full-height-width zindex100" style={{display:"none"}}>
            <div className='bottom-middle display-flex-row' style={{zIndex:1001}}>
                <Button2
                    configObj={{id:""}}
                    action={()=>{debugScene.testMode()}}
                    label={"Test Values"}
                    fontAwesomeIcon={"fas fa-arrow-left color-danger"}
                    iconPos="left"
                    classNames={"shadow-medium bg-color-primary-outline marginTop5 button-small"}
                />
                <Button2
                    configObj={{id:""}}
                    action={()=>{debugScene.close()}}
                    label={"Close Debugging"}
                    fontAwesomeIcon={"fas fa-times color-white"}
                    iconPos="left"
                    classNames={"shadow-medium marginTop5 bg-color-black margin-auto button-small"}
                />
            </div>
        

            <div className="header-container">

                    <ControlsTab
                    configObj={applicationConfig}
                    tabItems={tabItems}
                    currSelectedValue={currTab}
                    action={changeTabOption}
                    theme="red"
                    />

                
            </div>

         


            <div id="DebugUpload" className="tab-content">
              {/* <Upload/> */}
            </div>

            <div id="DebugFinish" className="tab-content ">
              <Finish/>
            </div>

            <div id="DebugConfiguration" className="tab-content --is-active">
              <Configuration/>
            </div>
                

                 
        </div>
    );
};

export default Controls;
