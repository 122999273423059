import React, { useState } from "react";
import { createClient, getClientsList, getUsersList, updateClient, updateUser } from "../../../../services/api";
import { showToast } from "../../../../utils/UI_methods/global";
import { createClientForm } from "../../../../utils/createuser/createClientForm";
import { applicationConfig, stringWithoutWhiteSpace } from "../../../../utils/methods";
import Button2 from "../../../common/ui-components/buttons/Button2";
import FileUpload from "../../../common/ui-components/forms/FileUpload";
import AwsConfig from "../../../../utils/aws-sdk/AwsConfig";

  const CreateClient: React.FC<{
    callBack:()=>void
    savedUser:any
  }> = (props) => {


    const[userDetails,setUserDetails] = useState(new createClientForm(props.savedUser))
    const[error,setError] = useState(null)
    const[selectedFile,setSelectedFile] = useState(null)
    // const[userName,setUserName] = useState(userDetails.username)
    // const[password,setPassword] = useState(userDetails.username)

    const[uploadedImage] = useState({
        SELECTED_FILE:null
    })


    // useEffect(()=>{
    //     userDetails.setUserName(userName)
    //     userDetails.setPassword(password)
    // },[userName,password])

 

    function setClientName(event:any) {
        userDetails.setClientName(event.target.value)
        // setUserName(stringWithoutWhiteSpace(userDetails.clientName))
        // setPassword(stringWithoutWhiteSpace(userDetails.clientName))
    }

    function setStartDate(event:any) {
        userDetails.setStartDate(event.target.value)
    }

    function setEndDate(event:any) {
        userDetails.setEndDate(event.target.value)
    }

    function setLocation(event:any) {
        userDetails.setLocation(event.target.value)
    }

    
    function setEmail(event:any) {
        userDetails.setEmail(event.target.value)
    }

    function setStatus(event:any) {
        userDetails.setStatus(event.target.value)
    }

    function submitHandle() {
        if(props.savedUser){
            updateUserAction()
        }else{
            createNewClient()
        }
    }

    function createNewClient() {
       
        if(!userDetails.clientName?.length){
            alert("Please fill required fields")
            return
        }
        
        if(!uploadedImage.SELECTED_FILE){
            let albumPhotosKey = `public/logo/${stringWithoutWhiteSpace(userDetails.clientName)}/`
            applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,uploadedImage.SELECTED_FILE,"logo.png",true).then(data=>{
                console.log(data)
            }).catch(err=>{
                console.log(err)
            })
        }

        createClient(userDetails).then((response:any)=>{
            console.log(response)
            if(response.data.error){
                setError(response.data.message)
            }else{
                onSuccessAction()
            }
        }).catch(err=>{
            console.log(err)
        })
    }
 
    function getTimeStampFromDate(dateString:string) {
        // const date = new Date(dateString);
        // // Get the Unix timestamp in milliseconds and convert to seconds
        // const timestamp = Math.floor(date.getTime() / 1000);
        // return timestamp

        return new Date(dateString).getTime()
    }
     
    function updateUserAction() {
        let startDate = userDetails.subscriptionStartDate
        let endDate = userDetails.subscriptionEndDate

        userDetails.subscriptionStartDate = getTimeStampFromDate(startDate)
        userDetails.subscriptionEndDate = getTimeStampFromDate(endDate)


        updateClient(userDetails).then((response:any)=>{
            if(response.data.error){
                setError(response.data.message)
            }else{
                onSuccessAction()
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    function onSuccessAction() {
        setError(null)
        getClientsList().then(data=>{
            applicationConfig.updateData("clientsList",data)
            props.callBack()
        })
        showToast("User created successfully",2000)
    }

    function setClientImage(file:any) {
        uploadedImage["SELECTED_FILE"] = file  
    }

    return (
        <div className="full-height-width overflow-y-scroll">

            {error?
            <div className="color-danger heading2 font-md-small padding5">{error}</div>
            :null
            }




            <div className="full-width ion-margin-top display-flex-row no-wrap">
                <div className="form-label-input-container width50">
                    <div className="color-black font-md-small marginBottom5 label">Client Name</div>
                    <div className="input-container">
                    <input className="input" type="text" defaultValue={userDetails.clientName} onChange={(event)=>setClientName(event)}/>
                    </div>
                </div>

                <div className="form-label-input-container width50">
                    <div className="color-black font-md-small marginBottom5 label">Email</div>
                    <div className="input-container">
                    <input className="input" type="text" defaultValue={userDetails.email} onChange={(event)=>setEmail(event)}/>
                    </div>
                </div>
            </div>
            
 

           

            <div className="full-width ion-margin-top display-flex-row no-wrap ion-margin-top">
                
                <div className="form-label-input-container width50 ">
                    <div className="color-black font-md-small marginBottom5 label">Location</div>
                    <div className="input-container">
                    <input className="input" type="text" defaultValue={userDetails.location} onChange={(event)=>setLocation(event)}/>
                    </div>
                </div>

                <div className="form-label-input-container width50">
                    <div className="color-black font-md-small marginBottom5 label">Status</div>
                    <div className="input-container">
                        <select className="input input-mandatory" defaultValue={userDetails.status.toLowerCase()}  onChange={(e)=>setStatus(e)}>
                            <option value="">Select status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="suspended">Suspended</option>
                            <option value="demo">Demo</option>
                            <option value="test">Test</option>
                        </select>
                    </div>
                </div>
            
            </div>


            {/* <div className="form-label-input-container ion-margin-top">
                <div className="color-black font-md-small marginBottom5 label">User Name</div>
                <div className="input-container">
                <input className="input" type="text" defaultValue={userName} onChange={(event)=>setUserName(event.target.value)}/>
                </div>
            </div>


            <div className="form-label-input-container ion-margin-top">
                <div className="color-black font-md-small marginBottom5 label">Password</div>
                <div className="input-container">
                <input className="input" type="text" defaultValue={password} onChange={(event)=>setPassword(event.target.value)}/>
                </div>
            </div> */}

            <div className="full-width ion-margin-top display-flex-row no-wrap">
                <div className="form-label-input-container  width50">
                    <div className="color-black font-md-small marginBottom5 label">Start Date</div>
                    <div className="input-container">
                    <input className="input" type="date" defaultValue={userDetails.subscriptionStartDate} onChange={(event)=>setStartDate(event)}/>
                    </div>
                </div>

                <div className="form-label-input-container  width50">
                    <div className="color-black font-md-small marginBottom5 label">End Date</div>
                    <div className="input-container">
                    <input className="input" type="date" defaultValue={userDetails.subscriptionEndDate} onChange={(event)=>setEndDate(event)}/>
                    </div>
                </div>
            </div>

           


            <div className="form-label-input-container ion-margin-top">
                <div className="color-black font-md-small marginBottom5 label">Upload logo</div>
                <div className="input-container">
                <FileUpload
                    configObj={applicationConfig}
                    heading=""
                    setFile={setClientImage}
                />
                </div>
            </div>

            
            {/* <UploadImage
                setSelectedFile={setSelectedFile}
                imageElementId={"clientLogo"}
            /> */}

          



            <div className="ion-margin-top">
                <Button2
                    configObj={applicationConfig}
                    action={submitHandle}
                    label={props.savedUser?"Update":"Submit"}
                    fontAwesomeIcon={"fas fa-user color-primary"}
                    iconPos=""         
                    classNames={"button-light  bg-color-primary no-shadow"}   
                />
            </div>

            


        </div>
    );  
  };
  
  export default CreateClient;
  