import $ from "jquery";
import React, { useState } from 'react';
import { toggleSaveOptions } from '../../../../../utils/customizein3d/area3dmodel/UI_methods';
import { isDeviceIpadPotrait } from '../../../../../utils/customizein3d/modules/UI_methods';
import { getUserType } from '../../../../../utils/methods';
import Modal from '../../../shared-components/Modals/Modal';
import Button2 from '../../../ui-components/buttons/Button2';
import SummaryLink from '../../../ui-components/links/SummaryLink';
import ConfigList from './ConfigList';
import SaveConfigForm from "./SaveConfigForm";

// import './Home.css';

const HeaderRight: React.FC<{
  modulesConfigurtion:any
}> = (props) => {

  const[isRendersOpen,setIsRendersOpen] = useState(false)

  function shareClickHandle() {
    $("#summaryButton").click()
  }

  

    return(
      <>
      <Modal
        isOpen={isRendersOpen} 
        classNames="project-action-modal"
        Content={()=>(
          <ConfigList
          setIsRendersOpen={setIsRendersOpen}
          />
        )}
        FooterContent={()=>(<div></div>)}
        id={""}
        onDismiss={setIsRendersOpen}
        heading={"Renders list"}
      />

        <div className={getUserType().toLowerCase() === "customer" || isDeviceIpadPotrait()?"display-none":"hide-in-desktop display-none"}>
        <Button2
          configObj={{}}
          action={shareClickHandle}
          label={""}
          fontAwesomeIcon={"fas fa-share-alt color-primary"}
          iconPos="left"
          classNames={"margin0 shadow-light display-none bg-color-"}
        />
       
        </div>

        <SummaryLink
          currentConfigObject={props.modulesConfigurtion}
          moduleName="customizein3d"
        />

        {getUserType().toLowerCase() !== "customer"?
        <>
          <Button2
            configObj={{}}
            action={()=>setIsRendersOpen(true)}
            label={"Configurations"}
            fontAwesomeIcon={"fas fa-cog color-primary"}
            iconPos="left"
            classNames={"no-shadow bg-color- hide-in-mobile"}
          />
        </>
        :null}
      </>
        
    );
};
 
export default HeaderRight;
