import React, { useEffect, useState } from "react";
import { deleteUser, getUsersList, signin, updateUser } from "../../../../../services/api";
import { showGlobalToast, showToast, whatsappAction } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getFilteredArray, openNativeShareOptions } from "../../../../../utils/methods";
import Button2 from "../../../../common/ui-components/buttons/Button2";
 
 
 

const UsersList: React.FC<{
    usersList:Array<any>
    updateHanlde:(val:any)=>void
    updateUsersList:()=>void
}> = (props) => {


  const[usersList,setUsers] = useState([])
  const[updatedUsers,setUpdatedUsers] = useState([])

  useEffect(()=>{
    let result = getFilteredArray(props.usersList,"user_type","client")
    setUsers(result)
  },[props.usersList])

  useEffect(()=>{
    setUpdatedUsersList()
  },[usersList])

  async function setUpdatedUsersList(){
    let result = []
    for (let i = 0; i < usersList.length; i++) {
      const user = usersList[i];
      let data = await getIsAssigned(user)
      let isAssigned = data?.data?.data ? true : false
      user.isAssigned = isAssigned
      result.push(user)
      if(i === usersList.length-1){
        setUpdatedUsers(result)
      }
    }
  }




  function getLink(token:string){
    return `${window.location.origin}/auth/${token}`
  }

  function shareHandle(userName:string,token:string){
    openNativeShareOptions(`${window.location.origin}/auth/${token}`,userName,"mainLoaderSpinner")
  }
  

  function deleteLoginSessionHandle(user:any) {
    updateUser({isDeleteSession:true,username:user.username}).then((response:any)=>{
        if(response.data.error){
            alert(response.data.message)
        }else{
            getUsersList().then(data=>{
                console.log(data)
                showGlobalToast("Updated",2000)
            })
            showToast("User deleted successfully",2000)
        }
    }).catch(err=>{
        console.log(err)
    })
  }

  async function copyToClipboard(token:string) {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/auth/${token}`);
        showGlobalToast('Coppied!!');
    } catch (err) {
        showGlobalToast('Unable to copy text to clipboard',2000,"error");
    }
  }


  function sendWhatsAppMessages(users:Array<any>) {
    if (Array.isArray(users) && users.length > 0) {
      users.forEach((user) => {
        if (user.mobile && user.link) {
          whatsappAction(user.mobile, user.link);
        } else {
          showGlobalToast("Invalid user details", 2000, "error");
        }
      });
    } else {
      showGlobalToast("No users to send messages", 2000, "error");
    }
  }

  function deleteUserAction(userId:number){
    /* eslint-disable no-restricted-globals */
    let userResponse = confirm("Do you want to delete user?");
    /* eslint-enable no-restricted-globals */
    if (userResponse) {
      deleteAction(userId)
    }
  }

  function deleteAction(userId:number) {
    deleteUser({id:userId}).then((response:any)=>{
        if(response.data.error){
            alert(response.data.message)
        }else{
            props.updateUsersList()
            showGlobalToast("User deleted successfully",2000)
        }
    }).catch(err=>{
        console.log(err)
    })
  }

  async function getIsAssigned(user:any){
    return await signin(user.username,user.password,true)
  }



  return (
    <div className="overflow-y-scroll ion-margin-bottom full-height">
          <table className="users-table table">
              <thead>
              <tr className="display-noe">
                  <th>#</th>
                  <th>Client Name</th>
                  <th>Contact Number</th>
                  <th>Address</th>
                  {/* <th>Application Link</th> */}
                  <th>Status</th>
                  <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {updatedUsers?.map((user:any,index:any)=>{
                  
                  return (
                  <tr key={index}>
                      <td>{index+1}</td>
                      <td className="heading2">{user.business_name}</td>
                      <td>{user.phone}</td>
                      <td>{user.location}</td>
                      {/* <td>{getLink(user.login_token)}</td> */}
                      <td className={user.isAssigned?"color-success":"color-danger"}>{user.isAssigned?"Assigned":"Not Assigned"}</td>
                      <td>
                        <div className="display-flex-row no-wrap">

                        <Button2
                            configObj={applicationConfig}
                            action={()=>{shareHandle(user.username,user.login_token)}}
                            label={"Share link"}
                            fontAwesomeIcon={"fas fa-share color-success"}
                            iconPos="right"         
                            classNames={"button-"}   
                          />

                          
                          <Button2
                            configObj={applicationConfig}
                            action={()=>{whatsappAction(user.phone,"")}}
                            label={""}
                            fontAwesomeIcon={"fab fa-whatsapp color-success"}
                            iconPos="left"         
                            classNames={"button-"}   
                          />

                          
                          <Button2
                            configObj={applicationConfig}
                            action={()=>props.updateHanlde(user)}
                            label={""}
                            fontAwesomeIcon={"fas fa-edit font-small color-black"}
                            iconPos="left"         
                            classNames={"button-small"}   
                          />

                          <Button2
                            configObj={applicationConfig}
                            action={()=>deleteUserAction(user.id)}
                            label={""}
                            fontAwesomeIcon={"fas fa-trash font-small color-danger"}
                            iconPos="left"         
                            classNames={"button-small"}   
                          />

                          {applicationConfig.isMasterLogin()?
                            <Button2
                              configObj={applicationConfig}
                              action={()=>deleteLoginSessionHandle(user)}
                              label={""}
                              fontAwesomeIcon={"fas fa-undo font-small color-black"}
                              iconPos="left"         
                              classNames={"button-small"}   
                            />
                          :null}

                          

                          {/* <Button2
                            configObj={applicationConfig}
                            action={()=>deleteLoginSessionHandle(user)}
                            label={""}
                            fontAwesomeIcon={"fas fa-edit font-small color-dark"}
                            iconPos="left"         
                            classNames={"button-small"}   
                          /> */}
                        </div>
                      </td>
                  </tr>
                  )
              })}
            </tbody>
          </table>
      </div>
  );
};

export default UsersList;
