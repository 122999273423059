import $ from "jquery";
import React, { useState } from 'react';
import { enableTakeRenderMode, gotoProjectsForTakeRender } from '../../../../../utils/customizein3d/modules/render2d';
import Loader from '../../../shared-components/main-ui/Loader';
import Modal2 from '../../../shared-components/Modals/Modal2';
import Button2 from '../../../ui-components/buttons/Button2';
import { hideComponentLoader } from "../../../../../utils/UI_methods/global";

// import './Home.css';

const SceneSelector: React.FC<{
}> = (props) => {

  const[isOpen,setIsOpen] = useState(false)
  const[isOpenIFrame,setisOpenIFrame] = useState(false)
  const[iframeUrl,setIframeUrl] = useState("")

  const[scenes] = useState([
    {
      sceneName:"default",
      displayName:"Plain Background"
    },
    {
      sceneName:"Room1",
      displayName:"Scene1"
    },
    {
      sceneName:"Room2",
      displayName:"Scene2"
    },
    {
      sceneName:"Room3",
      displayName:"Scene3"
    },
    {
      sceneName:"Room4",
      displayName:"Scene4"
    },
    {
      sceneName:"Room5",
      displayName:"Scene5"
    },
    {
      sceneName:"Room6",
      displayName:"Scene6"
    }
  ])

  function openIframeAction(sceneName:string){
    setisOpenIFrame(true)
    gotoProjectsForTakeRender(sceneName).then((url:any)=>{
      setIframeUrl(url)
      hideComponentLoader("canvasLoader")
    }).catch(err=>{
    setisOpenIFrame(false)
      hideComponentLoader("canvasLoader")
    })
  }

  function selectAction(sceneName:string){
    setIsOpen(false)
    sceneName === "default" ? enableTakeRenderMode() : openIframeAction(sceneName);
  }



  function SceneSelector(){
    return(
      <div className='full-height-width'>
        <div className='modal-header white-card shadow-medium center-aligned-column' style={{height:"5rem",zIndex:1}}>
          <div className='heading2'>Select Your Scene </div>
        </div>
        
        <div className='modal-body full-width bg-color-ternary padding10' style={{height:"calc(100% - 10rem)"}}>
          <div className='display-flex-row full-height overflow-y-scroll' style={{margin:"auto"}}>
            {scenes.map((currScene:any,index:number)=>{
              return(
              <div onClick={()=>{selectAction(currScene.sceneName)}} style={{width:"33%",aspectRatio:"4/3"}} className='padding5 pointer position-relative ' key={index} >
                <div className="white-card border full-height-width" >
                  <img  className="full-height-width" src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/background_shells/images/${currScene.sceneName}.webp`} alt="" />
                </div>
                <div className='top-middle marginTop10 white-card no-shadow padding-10-15 fade-background-loader heading3 font-small text-center'>{currScene.displayName}</div>
              </div>
              )
            })}
            
          </div>
        
        </div>
        
        <div className='modal-footer position-relative' style={{height:"5rem"}}>
          <Button2
            configObj={{id:"",classNames:"width"}}
            action={()=>{setIsOpen(true)}}
            label={"Close"}
            fontAwesomeIcon={"fas fa-camera color-primary"}
            iconPos=""
            classNames={"shadow-medium store-bg-color-primary"}
          />
        </div>
      </div>
    )
  }

  function onLoadHandle() {
    // hideComponentLoader("iFrameLoader")
    const iframe:any = document.getElementById('productRenderIframe');
    iframe.contentWindow.document.addEventListener('click', function(event:any) {
        if ($(event.target)?.attr("id")?.includes("exitRenderModeButton")){
            event.preventDefault();
            // window.location.reload();
            setisOpenIFrame(false)
        }
    });
  }


  return(
    <>
    <Modal2
      isOpen={isOpen}
      classNames="medium-x-modal"
      onDismiss={setIsOpen}
      id=""
      Content={SceneSelector}
    />

        {isOpenIFrame?
        <div id="productRenderIframeContainer" className="full-height-width top-left padding0" style={{position:"fixed",background:"rgba(0,0,0,0.5)",zIndex:1005}}>
          <iframe id="productRenderIframe" onLoad={onLoadHandle} src={iframeUrl} className="middle" style={{width:"100%",height:"100%"}}>
          </iframe>
          <div className="top-right padding5" onClick={()=>{setisOpenIFrame(false)}}>
            <i className="fas fa-times color-white font-large"></i>
          </div>
          <Loader
            id="iFrameLoader"
            data={{message:"Fetching data"}}
            loaderType="dots"
            className="fade-background-loader"
          />
          <div className="top-left visibility-hidden" style={{top:"calc(var(--main-header-height))",margin:"1rem 0"}}>
          <Button2
              configObj={{id:"exitRenderModeButton"}}
              action={()=>{setisOpenIFrame(false)}}
              label={"Exit Render Mode"}
              fontAwesomeIcon={"fas fa-times color-danger"}
              iconPos="left"
              classNames={"shadow-medium no-border"}
            />
          </div>
        </div>
        :null}

    <Button2
      configObj={{id:"",classNames:"width"}}
      action={()=>{setIsOpen(true)}}
      label={"Studio"}
      fontAwesomeIcon={"fas fa-camera color-primary"}
      iconPos="left"
      classNames={"shadow-medium no-border button-small bg-color-primary hide-button-text-in-mobile fill-available-width marginTop5"}
      svgUrl="assets/icon/svg/camera_white.svg"
    />
    </>
  );
};
 
export default SceneSelector;
