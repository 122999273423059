import { Layout } from "./layout"

export class Layout6 extends Layout{


    name:string = "layout6a"

    constructor(width:number,height:number){
        super(width,height)
    }

    setPadding(padding:number){
        this.padding = padding
    }

    getLayoutsList(){
        return ["a"]
    }

    getBlockParameters(layoutName:string,index:number){
        switch (layoutName.toLocaleLowerCase()) {
            case "a":
                return this.getBlockParametersLayoutA(index)
        
            default:
                break;
        }
    }

    getBlockParametersLayoutA(index:number){

        switch(index){
            case 1:
                this.block1 = {
                    width: this.stageWidth * 0.4 - this.padding,
                    height: this.stageHeight * 0.6 - this.padding,
                    x: 0 + this.padding,
                    y: 0 + this.padding
                }
                return this.block1
            case 2:
                this.block2 = {
                    width: this.stageWidth * 0.28 - this.padding,
                    height: this.block1.height * 0.6 - this.padding,
                    x: this.block1.x + this.block1.width + this.padding,
                    y: this.block1.y + this.padding
                }
                return this.block2

            case 3:
                this.block3 = {
                    width: this.block2.width,
                    height: this.block2.height,
                    x: this.block2.x + this.block2.width + this.padding,
                    y: this.block2.y
                }
                return this.block3

            case 4:
                this.block4 = {
                    width: this.block1.width / 2 - this.padding/2,
                    height: this.stageHeight * 0.3 - this.padding/2,
                    x: this.block1.x,
                    y: this.block1.y + this.block1.height + this.padding
                }
                return this.block4
            
            case 5:
                this.block5 = {
                    width: this.block4.width,
                    height: this.block4.height,
                    x: this.block4.x + this.block4.width + this.padding,
                    y: this.block4.y
                }
                return this.block5

            case 6:
                this.block6 = {
                    width: this.stageWidth * 0.55 - this.padding/2,
                    height: this.stageHeight * 0.55 - this.padding,
                    x: this.block2.x,
                    y: this.block2.y + this.block2.height + this.padding 
                }
                return this.block6
            
        }

    }
}
