import React, { useEffect, useState } from "react";
import { getNextArea, getPrevArea } from "../../../../../../utils/customizer/helper";
import { getArrayOfDistinctValuesFromObject, getCurrentConfigurationFromLocalStorage, getObjectByParameter, logger } from "../../../../../../utils/methods";
import { closeCategories, getMoodBoardConfigKey } from "../../../../../../utils/moodboard/helper";
import { showToast } from "../../../../../../utils/UI_methods/global";
import MultiSteps from "../../../../../common/ui-components/multisteps/MultiSteps";
  
  const AreaList: React.FC<{
    areasList:any
    areaConfigFlagMapping:any
    updateCurrArea:(areaName:string,id:number)=>void
    currSelectedArea:string
    configObj:any
    currArea:string
    categoriesStatus:any
    updateProjectAreaCategories:()=>void
  }> = (props) => {

    const[currSelectedStep,setCurrSelectedStep] = useState("")
    const[steps,setSteps] = useState([])
    const[activeItems,setActiveItems] = useState([])

    
    useEffect(()=>{
      if(props.areasList && props.configObj && props.currArea!= ""){
        let areas = getArrayOfDistinctValuesFromObject(props.areasList,"area_name")
        areas.sort((a,b)=>a>b?1:-1)
        setSteps(areas)
        // setSteps(prevValues=>{
        //   return [...prevValues,"Finalize"]
        // })
        // console.log(props.configObj.areaName)
        setActiveItems([])
        setCurrSelectedStep(props.currArea)

        props.configObj.functions.setCurrSelectedStep = setCurrSelectedStep
      }


  
    },[props.areasList,props.configObj,props.currArea])

   
    
    function gotoPrevArea() {
      let sortedAreasList = getArrayOfDistinctValuesFromObject(props.areasList,"area_name");
      sortedAreasList.sort((a, b) => (a > b ? 1 : -1));
      let prevArea = getPrevArea(sortedAreasList, props.configObj.areaName,"moodboard");
      props.configObj.functions.setCurrSelectedStep(prevArea);
      let area = getObjectByParameter(props.configObj?.data.areasList,"area_name",prevArea);
      props.updateCurrArea(area.area_name, area.id);
    }

    
    function gotoNextArea() {
      
      let sortedAreasList = getArrayOfDistinctValuesFromObject(props.areasList,"area_name");
      sortedAreasList.sort((a, b) => (a > b ? 1 : -1));
      let nextArea = getNextArea(sortedAreasList, props.configObj.areaName,"moodboard");
      let area = getObjectByParameter(props.configObj?.data.areasList,"area_name",nextArea);
      props.configObj.functions.setCurrSelectedStep(nextArea);
      props.updateCurrArea(area.area_name, area.id);
    }

    function updateArea(area:string) {
      // let key = getMoodBoardConfigKey(props.configObj?.getMoodboardId(),area);
      // let config = getCurrentConfigurationFromLocalStorage(key);
      // if(!config){
      //   showToast("Config not available",2000,"error")
      //   return
      // }
      let areaObject = getObjectByParameter(props.configObj?.data.areasList,"area_name",area);
      props.configObj.functions.setCurrSelectedStep(area);
      props.updateCurrArea(areaObject.area_name, areaObject.id);

      logger?.info("editcollection","changearea",`Area changed: ${areaObject.area_name}`)

    }

   

   
    return (
      // <div className="configuration-area-container hide-scrollbar" id="moodboardCanvasAreas" style={{left:"unset",transform:"unset"}}>
      // {props.areasList?.map((area:any,index:number)=>{
      //     let areaObject:any = getObjectByParameter(props.configObj?.data.areasList,"area_name",area)
      //     let classNames=["configuration-area","white-card"]
      //     if(area===props.currSelectedArea){
      //         classNames.push("--is-active")
      //     }
      //     return(
      //         <div className={classNames.join(" ")} onClick={()=>props.updateCurrArea(area,areaObject.id)} key={index}>
      //           {area}{!props.areaConfigFlagMapping[area]?<span className="update-hint-area-view">*</span>:null}
      //         </div>
      //     )
      // })}
      // </div>
      <>

      <div className="full-width top-left zindex30" style={{padding:"0"}}>
           {/* <div className="full-width center-aligned-row position-relative" id="" >
            <div className="full-width center-aligned-row" style={{background:"rgba(255,255,255,1)",padding:"0.5rem"}}>
              
              <div className="center-aligned-column heading1 uppercase letter-spacing">{props.configObj?.projectName}</div>
              <div className="center-aligned-column marginleft5"> <i className="fas fa-map-marker-alt color-dark"></i> </div>

          </div>

            <div className="left-middle zindex30 pointer center-aligned-column" onClick={goback} style={{padding:"0"}}>
              <div className="heading2 letter-spacing font-normal display-flex-row uppercase color-black"> 
                <i className="fas fa-chevron-left color-primary  center-aligned-column"></i> <span className="marginleft5">Go Back</span>
              </div>
            </div>

          </div> */}

            <MultiSteps
              configObj={props.configObj}
              steps={steps}
              activeItemsList={activeItems}
              action={updateArea}
              currSelectedValue={currSelectedStep}
            />
      </div>

      
      {/* <div className="top-left" style={{padding:"0",top:"45px",left:"10px"}}>
        <Button2
          configObj={props.configObj}
          action={gotoPrevArea}
          label={"Prev"}
          fontAwesomeIcon={"fas fa-chevron-left color-primary"}
          iconPos="left"
          classNames={"shadow-light"}
        />
      </div>


      <div className="top-right" style={{padding:"0",top:"45px",right:"10px"}}>
        <Button2
          configObj={props.configObj}
          action={gotoNextArea}
          label={"Next"}
          fontAwesomeIcon={"fas fa-chevron-right color-primary"}
          iconPos="right"
          classNames={"shadow-light"}
        />
      </div> */}


        



      </>
    )
  };
  
  export default AreaList;
  