import React, { useEffect, useState } from "react";
import { getProductsList } from "../../../../services/api";
import StockListContainer from "./ProductListContainer";
import Header from "./header/Header";
import Modal2 from "../../../common/shared-components/Modals/Modal2";
import Inventory from "../../../admin/inventory/Inventory";
import { applicationConfig } from "../../../../utils/methods";
import AddConfig from "./AddConfig/AddConfig";
 
const StockList: React.FC<{
}> = (props) => {

 
  const[itemsList,setItemsList] = useState([])

  const[searchedItems,setSearchedLeadsList] = useState([])
  const[isOpenAddProduct,setIsOpenAddProduct] = useState(false)
  const[isOpenAddConfig,setIsOpenAddConfig] = useState(false)
  const[currSelectedProduct,setCurrSelectedProduct] = useState(null)
  
  const[refresh,setIsReferesh] = useState(Number(new Date()))

  useEffect(()=>{
    getProductsList().then(data=>{
        setItemsList(data)
    }).catch(err=>{
      console.log(err)
    })
  },[refresh])

  function refreshList() {
    setIsReferesh(Number(new Date()))
  }

  function openAddProduct(product:any = null) {
    setCurrSelectedProduct(product)
    setIsOpenAddProduct(true)
  }

  function openAddConfig(product:any) {
    setCurrSelectedProduct(product)
    setIsOpenAddConfig(true)
  }


  function closeAddProductHandle(val:boolean) {
    setIsOpenAddProduct(false)
    // window.location.href = "/inventory/products"
    refreshList()
  }
  return (
    <div className="full-height-width">

      <Modal2
          id=""
          isOpen={isOpenAddProduct}
          classNames="large-x-modal"
          onDismiss={()=>setIsOpenAddProduct(false)}
          Content={()=>(
            <Inventory
              currState="Product"
              configObj={applicationConfig}
              closeModal={closeAddProductHandle}
              product={currSelectedProduct}
              finishId={0}
              isUpdateMode={currSelectedProduct?true:false}
            />
          )}
      />

      
      <AddConfig
          isOpen={isOpenAddConfig}
          setIsOpen={setIsOpenAddConfig}
          product={currSelectedProduct}
      />
         

      <Header openAddProduct={openAddProduct} setSearchedLeadsList={setSearchedLeadsList} refreshList={refreshList}/>

      

      <div className="display-flex-row no-wrap full-height-width position-relative">


        <div data-refresh={refresh} className="full-width full-height display-flex-column">
          <StockListContainer openAddProduct={openAddProduct} openAddConfig={openAddConfig} allItems={itemsList} refreshList={refreshList} />
        </div>
      </div>


    </div>
  );
};

export default StockList;
