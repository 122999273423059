import React from "react";
import $ from "jquery"
import "../../../style/ui-components/accordion.css"


const Accordion: React.FC<{
  label:string
  Content:any
  index:number
  isOpen:boolean
  onClickHeader?:any
  currSelection?:string
}> = (props) => {

  function toggle(event:any) {
    if(props.onClickHeader){
      props.onClickHeader()
      return
    }
    const contentWrapper = $(event.target).parent().find(".content")
    const headerWrapper = $(event.target).parent().find(".accordion-header")
    $(".accordion-header").removeClass("--is-active")
    // $(".content").removeClass("--is-active")
    if(contentWrapper){
      contentWrapper.toggleClass("--is-active")
      headerWrapper.addClass("--is-active")
    }
  }

  return (
    <React.Fragment>
      <div className='accordion-container'>
          <div className={`display-flex-row pointer no-wrap space-between accordion-header ${props.isOpen?" --is-active":""}`} onClick={toggle}>
              <div className=' pointer-none display-flex-row'>
                {props.index !== 0 ?
                <div className="accordion-badge">{props.index}.</div>
                :null
                }
                <div className="heading2 accordion-text font-normal bold center-aligned-column">{props.label}</div>
              </div>
              <div className="display-flex-row no-wrap">
                {props.currSelection?
                <div className="center-aligned-column pointer-none">
                  <span className="heading4 pointer-none font-md-small marginRight10 color-black white-card no-shadow" style={{background:"#fff",padding:"0.2rem 0.5rem"}}>{props.currSelection}</span>
                </div>
                :null}
                <div className='pointer-none center-aligned-column'><i className='fas fa-chevron-down accordion-icon'></i></div>
              </div>
          </div>
          <div className={`content padding5 ${props.isOpen?" --is-active":""}`}>
            <props.Content/>
          </div>
      </div>
    </React.Fragment>
  
  );
};

export default Accordion;
