export const kitchenModuleypesModules = {
    Base:["Corner","Sink","Hob","Firdge & freezer","With drawer","With door","With door & Drawer","With Pull-out"],
    High:["Fridge & Freezer","Oven","Microwave oven","Combi open"],
    Wall:["With Door","Horizontal Cabinates","Corner","Extractor hood"],
}
 
export const kitchensList = [
    {
        "productName": "LShape1",
        "name":"L Shape",
        "categoryName": "Kitchen",
        "subCategoryName": "LShapeKitchen",
        "enabled": true,
        "isAddedAsNew": true,
        "transform": {
            "position": {
                "x": 263.7734062574187,
                "y": 4176.518847584724,
                "z": -573.3366183093946
            },
            "rotation": {
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "_w": 1
            },
            "scale": {
                "x": 1,
                "y": 1,
                "z": 1
            }
        },
        "productId": "3291",
        "categoryId": "15",
        "originalProductName": "LShape1",
        "productInstanceName": "LShape1",
        "isAttachedToWall": false,
        "isWallMounted": 0,
        "normal": {
            "x": 0,
            "y": 0,
            "z": 1
        },
        "moduleConfiguration": {
            "modules": [
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 31,
                        "dim_X": 34,
                        "dim_Y": 43,
                        "dim_Z": 34,
                        "module_name": "TallUnit1Kitchen",
                        "module_description": "Tall",
                        "display_name": "Tall for config",
                        "module_type_id": 1,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Left",
                        "is_addon": 0
                    },
                    "objectId": "CA2179F6-25BB-4796-9F59-E08F679E3B2A"
                },
                // {
                //     "module": {
                //         "product_id": 3291,
                //         "module_id": 29,
                //         "dim_X": 34,
                //         "dim_Y": 23,
                //         "dim_Z": 43,
                //         "module_name": "Base1Kitchen",
                //         "module_description": "Base unit cabinate for kitchen ",
                //         "display_name": "Base unit",
                //         "module_type_id": 2,
                //         "product_name": "LShape1 ",
                //         "category_name": "Kitchen",
                //         "module_type": "Centre",
                //         "is_addon": 0
                //     },
                //     "objectId": "902B8667-D674-40AD-9387-1460FC599133"
                // },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "1D128DD0-5969-4FE5-A0D4-8B05FCE1831E"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "840D5482-3DB2-48F5-AD89-B280ADA504A2"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "5EC688E5-B42F-44A8-A222-147CB7A32208"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 30,
                        "dim_X": 56,
                        "dim_Y": 543,
                        "dim_Z": 34,
                        "module_name": "Corner1Kitchen",
                        "module_description": "Corner unit for kitchen",
                        "display_name": "Corner unit for kitchen",
                        "module_type_id": 3,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Corner",
                        "is_addon": 0
                    },
                    "objectId": "3711D1EC-AE5B-410B-B742-E0BBE504E9C3"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "81C3B746-DCD1-4073-94F9-B1FF2E37631B"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "68011DD3-79A7-4E15-95DF-730C2DA3BF8E"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "754EFA6E-6E4C-47AF-9315-C0687EAF5678"
                }
            ],
            "addons": [],
            "groupRotation": 0,
            "materials": []
        }
    },



    // kitchen2

    {
        "productName": "LShape1",
        "name":"U Shape",
        "categoryName": "Kitchen",
        "subCategoryName": "LShapeKitchen",
        "enabled": true,
        "isAddedAsNew": true,
        "transform": {
            "position": {
                "x": 263.81273869320074,
                "y": 4176.518847584724,
                "z": -573.3052724543635
            },
            "rotation": {
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "_w": 1
            },
            "scale": {
                "x": 1,
                "y": 1,
                "z": 1
            }
        },
        "productId": "3291",
        "categoryId": "15",
        "originalProductName": "LShape1",
        "productInstanceName": "LShape1",
        "isAttachedToWall": false,
        "isWallMounted": false,
        "normal": {
            "x": 0,
            "y": 0,
            "z": 1
        },
        "moduleConfiguration": {
            "modules": [
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 31,
                        "dim_X": 34,
                        "dim_Y": 43,
                        "dim_Z": 34,
                        "module_name": "Left1Kitchen",
                        "module_description": "Left",
                        "display_name": "Left for config",
                        "module_type_id": 1,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Left",
                        "is_addon": 0
                    },
                    "objectId": "C00DFBE9-0E83-441C-81BE-C2D55ED59666"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "C9D5F34B-4237-4AC3-BD22-4EB7A18A6C24"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "27619A98-F958-4543-8E89-811C8E68E818"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 30,
                        "dim_X": 56,
                        "dim_Y": 543,
                        "dim_Z": 34,
                        "module_name": "Corner1Kitchen",
                        "module_description": "Corner unit for kitchen",
                        "display_name": "Corner unit for kitchen",
                        "module_type_id": 3,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Corner",
                        "is_addon": 0
                    },
                    "objectId": "D57F3688-7E68-4BA0-B902-F514A0F79864"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "2E459D80-9479-4F62-A3C2-C5D375AEA78B"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "7D842034-C81C-468D-8508-BDBA3FA93497"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 30,
                        "dim_X": 56,
                        "dim_Y": 543,
                        "dim_Z": 34,
                        "module_name": "Corner1Kitchen",
                        "module_description": "Corner unit for kitchen",
                        "display_name": "Corner unit for kitchen",
                        "module_type_id": 3,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Corner",
                        "is_addon": 0
                    },
                    "objectId": "ECFC935C-2C23-487B-A5F3-94F46A1232BA"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "CDF49669-619E-4A71-A012-C748AF70326E"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "44AB8BA9-CA74-4F41-A0CE-BF98BE1A5286"
                }
            ],
            "addons": [],
            "groupRotation": 0,
            "materials": []
        }
    },

    // Kitchen 3
    {
        "productName": "LShape1",
        "name":"Parallel Kitchen",
        "categoryName": "Kitchen",
        "subCategoryName": "LShapeKitchen",
        "enabled": true,
        "isAddedAsNew": true,
        "transform": {
            "position": {
                "x": 263.81273869320074,
                "y": 4176.518847584724,
                "z": -573.3052724543635
            },
            "rotation": {
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "_w": 1
            },
            "scale": {
                "x": 1,
                "y": 1,
                "z": 1
            }
        },
        "productId": "3291",
        "categoryId": "15",
        "originalProductName": "LShape1",
        "productInstanceName": "LShape1",
        "isAttachedToWall": false,
        "isWallMounted": false,
        "normal": {
            "x": 0,
            "y": 0,
            "z": 1
        },
        "moduleConfiguration": {
            "modules": [
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 31,
                        "dim_X": 34,
                        "dim_Y": 43,
                        "dim_Z": 34,
                        "module_name": "Left1Kitchen",
                        "module_description": "Left",
                        "display_name": "Left for config",
                        "module_type_id": 1,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Left",
                        "is_addon": 0
                    },
                    "objectId": "C00DFBE9-0E83-441C-81BE-C2D55ED59666"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "C9D5F34B-4237-4AC3-BD22-4EB7A18A6C24"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "C9D5F34B-4237-4AC3-BD22-4EB7A18A6C24"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "27619A98-F958-4543-8E89-811C8E68E818"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 30,
                        "dim_X": 56,
                        "dim_Y": 543,
                        "dim_Z": 34,
                        "module_name": "Corner1Kitchen",
                        "module_description": "Corner unit for kitchen",
                        "display_name": "Corner unit for kitchen",
                        "module_type_id": 3,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Corner",
                        "is_addon": 0
                    },
                    "objectId": "D57F3688-7E68-4BA0-B902-F514A0F79864"
                },
               
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "7D842034-C81C-468D-8508-BDBA3FA93497"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 30,
                        "dim_X": 56,
                        "dim_Y": 543,
                        "dim_Z": 34,
                        "module_name": "Corner1Kitchen",
                        "module_description": "Corner unit for kitchen",
                        "display_name": "Corner unit for kitchen",
                        "module_type_id": 3,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Corner",
                        "is_addon": 0
                    },
                    "objectId": "ECFC935C-2C23-487B-A5F3-94F46A1232BA"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "CDF49669-619E-4A71-A012-C748AF70326E"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "44AB8BA9-CA74-4F41-A0CE-BF98BE1A5286"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "44AB8BA9-CA74-4F41-A0CE-BF98BE1A5286"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "44AB8BA9-CA74-4F41-A0CE-BF98BE1A5286"
                },
                {
                    "module": {
                        "product_id": 3291,
                        "module_id": 29,
                        "dim_X": 34,
                        "dim_Y": 23,
                        "dim_Z": 43,
                        "module_name": "Base1Kitchen",
                        "module_description": "Base unit cabinate for kitchen ",
                        "display_name": "Base unit",
                        "module_type_id": 2,
                        "product_name": "LShape1 ",
                        "category_name": "Kitchen",
                        "module_type": "Centre",
                        "is_addon": 0
                    },
                    "objectId": "44AB8BA9-CA74-4F41-A0CE-BF98BE1A5286"
                }
            ],
            "addons": [],
            "groupRotation": 0,
            "materials": []
        }
    }
];
