import { IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import { getUserType, sideBarToggle } from "../../../../utils/methods";
import Header from "../../../common/shared-components/main-ui/Header";
import MainSideBar from "../../../common/shared-components/main-ui/MainSideBar";

const MainSideBarLayout: React.FC<{
  configObj: any;
  // FilterHeaderContent:any
  CardsContainer:any
  SideFiltersContainer:any
  HeaderLeftContent:any
  HeaderMiddleContent:any
  contentWrapperId:string
}> = (props) => {
 
  //Function to remove project after deleting

  useEffect(()=>{
    setTimeout(() => {
      sideBarToggle()
    }, 100);
  },[])


  return (
    <IonPage>
      <div className="page position-relative full-height-width">
        <div className="display-flex-row no-wrap full-height-width">
          {getUserType().toLowerCase() !== "customer"?
            <MainSideBar 
            configObj={props.configObj}
            />
          :null}
          
          {/* <ThemeSetting /> */}
          <div className="header-content-wrapper" style={{ padding: "0" }}>
            <Header
              configObj={props.configObj}
              componentMiddle={props.HeaderMiddleContent}
              componentLeft={props.HeaderLeftContent}
            />
            <div id={props.contentWrapperId} className="main-content-wrapper position-relative hide-scrollbar light-background padding10">
              
            <div className=" background-white position-relative overflow-hidden full-height-width">
              <div className="display-flex-row main-cards-filters-wrapper no-wrap ion-padding full-height-width" >
                <div className="main-sidebar-filters-wrapper shadow-medium white-card padding10">
                  <props.SideFiltersContainer/>
                </div>

                <div className="main-cards-wrapper display-flex-column no-wrap overflow-hidden full-height shadow-light white-card">
                  <props.CardsContainer/>
                </div>
              </div>
        
            </div>

            </div>
            
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default MainSideBarLayout;
