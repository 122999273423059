import { getFilteredArray } from "../methods"

export class Elevate{
    constructor(){
        
    }
}


export function getFilteredMaterialsForElevate(materialsList:any) {
    let allMaterials = [1,2,3,4,5,6,7,8,9,10]
    let list = getFilteredArray(materialsList,"company_name","Demo")
    list = getFilteredArray(list,"collection_name","DemoFab")
    return list
    list = list.filter(currMaterial => allMaterials.includes(Number(currMaterial.material_code)))
    return list
}