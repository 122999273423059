import React from 'react';
import { useClearCache } from 'react-clear-cache';
import $ from "jquery"
import Button2 from '../buttons/Button2';
 
const UpdateApp: React.FC<{
  }> = (props) => {

    const { isLatestVersion, emptyCacheStorage } = useClearCache();

    function updateAppHandle(event:any) {
      event.preventDefault();
      emptyCacheStorage();
      localStorage?.clear();
      $("#updateAppScreen").addClass("display-none")
    }

  return (

    <div className='top-left welcome-screen full-height-width' id="updateAppScreen">
    <div className='full-height-width'>

      <div className='header-content'>
        <img src="assets/images/opus.png" width={120} alt="" />
      </div>
      <div className='body-content full-width center-aligned-column'>
        <div className='padding10'>
          <div className='padding5'>
            <div className='color-black font-ex-large text-center heading1'>New version is available</div>
          </div>
        </div>
      </div>

      <div  className='footer-content ion-padding center-aligned-column'>
        <div onClick={updateAppHandle} className='click-effect'>
            <Button2
              configObj={{id:""}}
              action={null}
              label={"Update App"}
              fontAwesomeIcon={"fas fa-share color-white"}
              iconPos=""
              classNames={"no-shadow button-large pointer-none margin0 bg-color-primary"}
            />
        </div>
              
      </div>

    </div>
  </div>
  );
};

export default UpdateApp;
