import React, { useState } from "react";
 

const Features: React.FC<{
}> = (props) => {
  const[features] = useState([
    {
      name:"120 Days Free Return",
      icon:"far fa-calendar-alt"
    },
    {
      name:"Fast & Flexible Delivery",
      icon:"fas fa-truck"
    },
    {
      name:"Warranty",
      icon:"fas fa-shield-alt"
    }
  ])
 
  return (

    <div className="full-width center-aligned-column bg-color-light">
      <div className="display-flex-row no-wrap features-container">
          {features.map((feature:any,index:number)=>{
              return(
                <div className="feature" key={index}>
                  <span className="center-aligned-column icon"><i className={`${feature.icon} elevate-color-primary font-ex-large`}></i></span>
                  <span className="center-aligned-column marginleft5 text heading4 color-black font-normal">{feature.name}</span>
                </div>
              )
          })}
      </div>
    </div>
    
  );
};

export default Features;
