import { alertCircleOutline, homeOutline, listCircleOutline, storefrontOutline, warningOutline } from "ionicons/icons";
import moment from "moment";
import { isTouchDevice } from "../../customizer/helper";
import { getFilteredArray, getObjectByParameter } from "../../methods";
import { CRM } from "./crmConfiguration";

export function getFilteredLeadsWithOutcome(leadsList:any,currTab:string){
  if(currTab === "New Leads"){
    return getFilteredArray(leadsList,"outcome",null)
  }
  if(currTab === "Not Reached"){
    return getFilteredArray(leadsList,"outcome","Not Reached")
  }
  if(currTab === "Reached but appointment not fixed"){
    return getFilteredArray(leadsList,"outcome","Reached but appointment not fixed")
  }
  if(currTab === "Agreed for home visit"){
    return getFilteredArray(leadsList,"outcome","Agreed for home visit")
  }
  if(currTab === "Agreed for factory visit"){
    return getFilteredArray(leadsList,"outcome","Agreed for factory visit")
  }
  if(currTab === "Need more information"){
    return getFilteredArray(leadsList,"outcome","Need more information")
  }
  if(currTab === "Out of bangalore"){
    return getFilteredArray(leadsList,"outcome","Out of bangalore")
  }
  return []
}


export function getSalesStageIdFromOutcome(outcome:string){
  if(outcome === "New Leads"){
    return 1
  }
  if(outcome === "Not Reached"){
    return 1
  }
  if(outcome === "Reached but appointment not fixed"){
    return 2 
  }
  if(outcome === "Agreed for home visit"){
    return 3
  }
  if(outcome === "Agreed for factory visit"){
    return 3
  }
  if(outcome === "Need more information"){
    return 2
  }
  return 1
}


export function getNextActivityFromOutcome(outcome:any){
  const activitiesTypes = CRM.data.activityTypes
  let activity:any = null
  if(outcome === "Not Reached"){
    activity = getObjectByParameter(activitiesTypes,"activity_type","Set up Follow up call")
  }
  if(outcome === "Reached but appointment not fixed"){
      activity = getObjectByParameter(activitiesTypes,"activity_type","Set up Follow up call")
  }
  if(outcome === "Appointment Scheduled"){
      activity = getObjectByParameter(activitiesTypes,"activity_type","Call to Follow up on Quotation")
  }
  if(outcome === "Agreed for home visit"){
      activity = getObjectByParameter(activitiesTypes,"activity_type","Create appointment for home visit")
  }
  if(outcome === "Agreed for factory visit"){
      activity = getObjectByParameter(activitiesTypes,"activity_type","Create appointment for factory visit")
  }
  if(outcome === "Need more information"){
    activity = getObjectByParameter(activitiesTypes,"activity_type","Needs sales assist")
  }
  if(outcome === "Out of bangalore"){
    activity = getObjectByParameter(activitiesTypes,"activity_type","Out of bangalore")
  }
  return activity
}


export function getFormatedDate(timestamp:number,format:string = "DD-MMM-YYYY" ) {
  const date = moment(timestamp)
  const formattedDate = date.format(format)
  return formattedDate
}

export function touchDeviceActions(activityName:string,mobile:number) {
  if(isTouchDevice()){

    if(activityName === "call"){
      window.location.href = `tel:${mobile}`
    }

    if(activityName === "whatsapp"){
      var message = 'Message';  

      var encodedPhoneNumber = encodeURIComponent(mobile);
      var encodedMessage = encodeURIComponent(message);

      var whatsappURL = 'https://wa.me/' + encodedPhoneNumber + '?text=' + encodedMessage;

      window.open(whatsappURL);
    }
  }
}


export function getWeekHeadingPrefix(currTab:string) {
  if(currTab === "New Leads"){
    return `Created on`
  }

  if(currTab.toLowerCase().includes("agreed")){
    return `Appointments for`
  }

  if(currTab.toLowerCase().includes("visited")){
    return `Visited on`
  }

  if(currTab.toLowerCase().includes("placed")){
    return `Order placed on`
  }

  if(currTab.toLowerCase().includes("need more information")){
    return `Assigned on`
  }

  if(currTab.toLowerCase().includes("out of")){
    return `Created on`
  }

  return `Follow up call on `
}


export function getOtherHeadingPrefix(currTab:string) {
  if(currTab === "New Leads"){
    return `Created on`
  }

  if(currTab.toLowerCase().includes("agreed")){
    return `Appointments for`
  }

  if(currTab.toLowerCase().includes("visited")){
    return `Visited on`
  }

  if(currTab.toLowerCase().includes("placed")){
    return `Order placed on`
  }

  if(currTab.toLowerCase().includes("need more information")){
    return `Assigned on`
  }

  if(currTab.toLowerCase().includes("out of")){
    return `Created on`
  }

  return `Follow up call on `
}

export const outcomesList = [
  {
    displayName:"New Leads",
    outcomeName:"New Leads",
    icon:"far fa-list-alt",
    stage:"Prospect",
  },
  {
    displayName:"Not Reached",
    outcomeName:"Not Reached",
    icon:"fas fa-exclamation-triangle",
    stage:"Loss",
  },
  {
    displayName:"Pending appointment",
    outcomeName:"Reached but appointment not fixed",
    icon:"fas fa-exclamation-circle",
    stage:"QualifiedLead",
  },
  {
    displayName:"Agreed for home visit",
    outcomeName:"Agreed for home visit",
    icon:"fas fa-home",
    stage:"Visited",
  },
  {
    displayName:"Agreed for factory visit",
    outcomeName:"Agreed for factory visit",
    icon:"fas fa-store",
    stage:"Visited",
  }
]



export function getLeadsTabItems(leadsList:Array<any>){
  return [
    {
      tabName:"New Leads",
      label:"New Leads",
      ionIcon:listCircleOutline,
      count:getFilteredLeadsWithOutcome(leadsList,"New Leads").length,
    },
    {
      tabName:"Not Reached",
      label:"Not Reached",
      ionIcon:warningOutline,
      count:getFilteredLeadsWithOutcome(leadsList,"Not Reached").length,
    },
    {
      tabName:"Reached but appointment not fixed",
      label:"Pending appointment",
      ionIcon:alertCircleOutline,
      count:getFilteredLeadsWithOutcome(leadsList,"Reached but appointment not fixed").length,
    },
    {
      tabName:"Agreed for home visit",
      label:"Home visit",
      ionIcon:homeOutline,
      count:getFilteredLeadsWithOutcome(leadsList,"Agreed for home visit").length,
    },
    {
      tabName:"Agreed for factory visit",
      label:"Factory visit",
      ionIcon:storefrontOutline,
      count:getFilteredLeadsWithOutcome(leadsList,"Agreed for factory visit").length,
    },
    {
      tabName:"Visited",
      label:"Visited",
      icon:"fas fa-walking",
      count:getFilteredLeadsWithOutcome(leadsList,"Visited").length,
    },
    {
      tabName:"Placed order",
      label:"Placed order",
      icon:"fas fa-shopping-cart",
      count:getFilteredLeadsWithOutcome(leadsList,"Visited").length,
    },
    {
      tabName:"Need more information",
      label:"Sales assist",
      icon:"fas fa-user-alt",
      count:getFilteredLeadsWithOutcome(leadsList,"Quotation").length,
    },
    {
      tabName:"Out of bangalore",
      label:"Out of bangalore",
      icon:"fas fa-city",
      count:getFilteredLeadsWithOutcome(leadsList,"Quotation").length,
    }
  ]
}

