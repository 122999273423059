import React, { useEffect, useState } from "react";
import { getSavedFilterValue } from "../../../utils/cache/localstorage";
import MainLayout from "../../templates/layouts/page/MainLayout";
import MainSideBarLayout from "../../templates/layouts/page/MainSideBarLayout";
import Filters from "./Filters";
import FinishLayout from "./FinishLayout";
import HeaderLeftContent from "./HeaderLeftContent";
import MaterialTypeLayout from "./MaterialTypeLayout";


const FinishContainer: React.FC<{
  configObj:any
}> = (props) => {


  const[materials,setMaterials]:any = useState([])
  const[collections,setCollections]:any = useState([])



  const [currSelectedMaterialType, setCurrSelectedMaterialType] = useState<string>("");

  useEffect(()=>{
    props.configObj.setFunctionRef("finish",{setMaterials:setMaterials})
    props.configObj.setFunctionRef("finish",{setCollectionList:setCollections})
  },[props.configObj])



  useEffect(()=>{
    props.configObj.setFunctionRef("finish",{setCurrSelectedMaterialType:setCurrSelectedMaterialType})
  },[props.configObj])

  useEffect(()=>{
    props.configObj.setState("finish",{currSelectedMaterialType:currSelectedMaterialType})
  },[currSelectedMaterialType])

  useEffect(()=>{
    if (props.configObj) {
      let materialType = null
      materialType = getSavedFilterValue("finish","currSelectedMaterialType")
      if(materialType){
        setCurrSelectedMaterialType(materialType)
        return
      }
    }
  },[props.configObj])


 

  

  return (
    <React.Fragment>


      {!currSelectedMaterialType.length?
        <MainLayout 
            configObj={props.configObj}  
            CardsContainer={()=>(
              <MaterialTypeLayout
                configObj={props.configObj}
                updateMaterialType={setCurrSelectedMaterialType}
              />
            )}
            HeaderLeftContent={null}
            HeaderMiddleContent={HeaderLeftContent}
            contentWrapperId="mainFinishContentWrapper"
        />
        
        :
        <MainSideBarLayout 
          configObj={props.configObj}
          CardsContainer={()=>(
            <FinishLayout
              configObj={props.configObj}
              materialsList={materials}
              collections={collections}
            />
          )}
          HeaderLeftContent={null}
          SideFiltersContainer={Filters}
          HeaderMiddleContent={HeaderLeftContent}
          contentWrapperId="mainFinishContentWrapper"
        />
        
      }
  </React.Fragment>
  );
};

export default FinishContainer;
