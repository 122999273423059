import React, { useEffect, useState } from "react";
import { circleRangleSliderInit } from "../../../../utils/form/circlerangeslider";
import { applicationConfig } from "../../../../utils/methods";
import "../../../style/form/circlerangeslider.css";
 


const CircleRangeSlider: React.FC<{
    id:string
    onRotate:(val:any)=>void
    pointerUp:(val:any)=>void
    pointerDown:()=>void
}> = (props) => {

  const[defaultValue,setDefaultValue] = useState(0)


  useEffect(()=>{
    applicationConfig.setFunctionRef("rangeslider",{setDefaultValue:updateRangeSliderValue})

    // $("#rangeSliderValue").keyup(function(event) {
    //   if (event.keyCode === 13) {
    //       props.action(event)
    //   }
    // });

    setTimeout(() => {
      circleRangleSliderInit(props.onRotate,props.pointerDown,props.pointerUp,props.id)
    }, 500);

  },[])

  function updateRangeSliderValue(number:number) {
    setDefaultValue(number)
  }
 
  return (
    <>
    <div className="circle-range-slider pointer-none padding0" id="circleRangeSliderWrapper">
      <input type="range" name="points" id="circleRangeSlider" min="-180" max="180" value="" className="count pointer-none"/>
      <div className="slice left shadow-medium ">
        <div className="blocker shadow-medium"></div>
      </div>
      <div className="slice right shadow-medium ">
        <div className="blocker shadow-medium"></div>
      </div>
      <span className="info ">
      </span>
      <div className="dial pointer-all hide-on-dragging center-aligned-column" tabIndex={0}> <i className="fas fa-undo color-black font-ex-small"></i> </div>
      <span className="count middle show-on-rotate color-black no-shadow">0 </span>

      <svg height="100" width="100" className="top-left padding0 pointer-none svg-circle-container">
        <circle cx="32.5" cy="32.5" r="30" stroke="#dddddd" strokeWidth="3" fill="transparent" />
        <circle cx="32.5" cy="32.5" r="30" className="svg-circle shadow-medium" strokeWidth="3" fill="transparent" />
        Sorry, your browser does not support inline SVG.  
      </svg> 

    </div>

    {/* <CircularSlider
      startAngle={0}
      angleLength={2*Math.PI}
      onUpdate={({ startAngle, angleLength }) => console.log(startAngle,angleLength)}
      segments={5}
      strokeWidth={40}
      radius={145}
      gradientColorFrom="#ff9800"
      gradientColorTo="#ffcf00"
      showClockFace={false}
      clockFaceColor="#9d9d9d"
      bgCircleColor="#171717"
      initialStartTime="18:00"
      initialEndTime="16:00"
    /> */}
    </>
    
  );
};
             
export default CircleRangeSlider;
