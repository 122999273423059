import React, { useState } from "react";
import { SofaConfiguration } from "../../../../../../utils/store/storeConfiguration";
import VariantIcon from "../common/VariantIcon";
import { applicationConfig } from "../../../../../../utils/methods";

 

const ProductVariantMeasurements: React.FC<{
  isReadOnly?:boolean
  productConfiguration:SofaConfiguration
  product:any
}> = (props) => {


  const[variant] = useState(props.productConfiguration?.variant)

  function setLength(value:any) {
    props.productConfiguration.dimensions.length = Number(value)
  }
  function setDepth(value:any) {
    props.productConfiguration.dimensions.depth = Number(value)
  }

 
  return (
    <div className="center-aligned-row position-relative" style={{height:"25rem"}}>
    <div className="top-middle ion-padding heading3  full-width">
        <span className="middle" style={{background:"#000",padding:"1px",width:"70%"}}></span>
        <span className="middle heading3 color-black" style={{padding:"5px"}}>
          <div className="white-card no-shadow padding5 display-flex-row no-wrap">
            {props.isReadOnly || !applicationConfig?.isLiveClient?
            <span className="heading3">{props.productConfiguration?.dimensions.length}</span>
            :
            <input onChange={(event)=>setLength(event.target.value)} style={{maxWidth:"5rem"}} type="number" className="input marginleft5" defaultValue={props.productConfiguration?.dimensions?.length}/>
            }
            <span className="heading3 center-aligned-column marginleft5">Inches</span>
          </div>
        </span>
    </div>

    <div className="right-middle ion-padding heading3  full-height" style={{right:"2rem"}}>
        <span className="middle" style={{background:"#000",padding:"1px",height:"70%"}}></span>
        <span className="middle heading3 color-black" style={{padding:"5px",width:"max-content"}}>
          <div className="white-card no-shadow padding5 display-flex-row no-wrap">
            {props.isReadOnly || !applicationConfig?.isLiveClient?
            <span className="heading3">{props.productConfiguration?.dimensions.depth}</span>
            :
            <input onChange={(event)=>setDepth(event.target.value)} style={{maxWidth:"5rem"}} type="number" className="input marginleft5" defaultValue={props.productConfiguration?.dimensions?.depth}/>
            }
            <span className="heading3 center-aligned-column marginleft5">Inches</span>
          </div>  
        </span>
    </div>
    <div className="" style={{width:"40%",margin:"auto"}}><VariantIcon variant={variant} currCategory={props.product?.category_name}/></div>
</div>
  );
};

export default ProductVariantMeasurements;
