import { IonCheckbox, IonIcon, IonModal } from '@ionic/react';
import { arrowBack, closeOutline } from 'ionicons/icons';
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { saveProjectAreaCategories } from '../../../../../../services/api';
import { getIdFromName } from '../../../../../../utils/methods';
import { updateConfig, uploadCollectionScreenshots } from '../../../../../../utils/moodboard/helper';
import Modal from "../../../../../common/shared-components/Modals/Modal";
import ConfigMoodBoard from '../../../../../common/shared-components/Share/ConfigMoodBoard';
import "../../../../../style/controls/customizeLeftControls.css";
import ConfirmationModal from '../common/ConfirmationModal';
 
  const Summary: React.FC<{
    configObj:any
    categoriesStatus:any
    areasList:Array<any>
    updateProjectAreaCategories:()=>void
    isOpenSummary:boolean
    setIsOpenSummary:(value:boolean)=>void
    callBack:()=>void
  }> = (props) => {

    const[showFinalSummary,setShowFinalSummary] = useState(false)
    const[showConfirmationModal,setShowConfirmationModal] = useState(false)
    const[showFinalConfirmationModal,setShowFinalConfirmationModal] = useState(false)
    const history = useHistory()

    useEffect(()=>{
      if(props.isOpenSummary){
        checkIfRemainingCategories()
      }
    },[props.isOpenSummary])

    useEffect(()=>{
      if(!showFinalSummary || !showConfirmationModal){
        props.callBack()
      }
    },[showFinalSummary,showConfirmationModal])

    async function updateCategories() {

      //Upload images to s3
      for (const area of props.areasList) {
        await updateConfig(area.area_name,area.area_id,()=>{})
      }
      setShowFinalSummary(false)
      setShowFinalConfirmationModal(true)
      uploadCollectionScreenshots(props.areasList,props.configObj)
 
      return 


      //merge additional and confirmed areas array 
      //for each new array get category and area id
      //set object with areaId,categoryid,catName
      let areaCategories:any = {}
      for (const areaKey in props.categoriesStatus) {
        
        let finalArray = props.categoriesStatus[areaKey].additional.concat(props.categoriesStatus[areaKey].confirmed) 
        
        let data = []
        for (const categoryName of finalArray) {
          let categoryId = getIdFromName(props.configObj?.data.categories,"id","category_name",categoryName)
          let areaId = getIdFromName(props.areasList,"area_id","area_name",areaKey)

          //Skip adding category id for finish materials
          if(categoryId){
            data.push({
              categoryName:categoryName,
              categoryId:categoryId,
              areaId:areaId
            })
          }
        }
        areaCategories[areaKey]  = data
      }

      saveProjectAreaCategories(props.configObj?.projectId,areaCategories)
      .then((response: any) => {
        setShowFinalSummary(false)
        setShowFinalConfirmationModal(true)
      })
      .catch((error: any) => {
          console.log(error);
      });
    
    }


    
    
    function gotoProjects() {
      // window.location.href = "/projects"
      setShowFinalConfirmationModal(false)
      window.history.back()
    }



    function gotoFinalSummary() {
      // update cagtegories
      setShowConfirmationModal(false)
      setShowFinalSummary(true)
    }
    function ConfirmationModalFooter() {
      return(
        <div className="">
        <button className="op-btn op-btn-light" onClick={() => setShowConfirmationModal(false)}>Cancel</button>
        <button className="op-btn op-btn-primary marginleft5" onClick={gotoFinalSummary}>Continue Anyway</button>
        </div>
      )
    }

    function FinalConfirmation(){
      return(
            <React.Fragment>
            <div className="modal-header" style={{height:"15%"}}>
            <div className="">
              <div className="pricing-header">
                  <div className="content-left">
                    <span><strong>Confirm!!</strong></span>
                    </div>
                  
                </div>
            </div>
            <IonIcon icon={closeOutline} ios={closeOutline} className="modal-close" onClick={() => setShowFinalConfirmationModal(false)}/>
          </div>
          <div className="modal-body ion-padding" style={{height:"70%"}}>
           
          </div>
          <div className="modal-footer" style={{height:"15%",justifyContent:"space-between",backgroundColor:"#fff"}}>
              <button className="op-btn op-btn-light" onClick={() => 
                {
                  props.updateProjectAreaCategories()
                  setShowFinalConfirmationModal(false)
                }
                }
              >Continue designing</button>
              <button className="op-btn op-btn-primary" onClick={gotoProjects}>Go to Projects</button>
          </div>
          </React.Fragment>
          )
    }

    function Confirmation(){
      return(
        
      <div className="font-small padding10 overflow-y-scroll full-height">
        {props.areasList?.map((area:any,index:number)=>{
          return(
          <div className="shadow-medium padding10 ion-margin-bottom" key={index}>
            <div className="ion-margin-bottom"><div className="heading">{area.area_name.toUpperCase()}</div> </div>
            <table className="table text-start-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {props.categoriesStatus[area.area_name]?.remaining?.map((category:any,i:number)=>{
              return(
              <tr key={i}>
                  <td key={index}>{category}</td>
                  <td><IonCheckbox slot="end" color="primary" mode="md" checked={false} disabled={true} /></td>
              </tr>
              )
              })}

            {props.categoriesStatus[area.area_name]?.confirmed?.map((category:any,i:number)=>{
              return(
              <tr key={i}>
                  <td key={index}>{category}</td>
                  <td><IonCheckbox slot="end" color="primary" mode="md" checked={true} disabled={true} /></td>
              </tr>
              )
              })}

            {props.categoriesStatus[area.area_name]?.additional?.map((category:any,i:number)=>{
              return(
              <tr key={i}>
                  <td key={index}>{category}</td>
                  <td><IonCheckbox slot="end" color="primary" mode="md" checked={true} disabled={true} /></td>
              </tr>
              )
              })}
            </tbody>
          </table>
          </div>
          )
        })}
      </div>
      )
    }


    function SummaryFooterContent() {
      return (
        <div className="center-aligned-row">
          <div>
          <button
            className="op-btn op-btn-primary"
            onClick={() => {
              updateCategories();
            }}>
            Confirm
          </button>
          </div>
        
        </div>
      );
    }
 
    function checkIfRemainingCategories() {
      //Check remaning categories for each area
      for (const key in props.categoriesStatus) {
        let object = props.categoriesStatus[key]
        let remainingCategories = object?.remaining
        if(remainingCategories.length){
          setShowConfirmationModal(true)
          return
        }
      }
      setShowFinalSummary(true)
    }

    function closeSummary() {
      setShowFinalSummary(false)
      props.callBack()
    }

    return (
      <>
         <IonModal
          id=""
          cssClass="share-modal"
          onDidDismiss={()=>{closeSummary()}}
          isOpen={showFinalSummary}
          >
             <div className="summary-header padding10">
            <div className="display-flex-row background-white">
                <div className="brand">
                  <img id="companyLogo" src="/assets/images/opus.png" alt="Opus"/>
                </div>

                <div className="click-effect summary-back-button top-right ion-margin-start center-aligned-column" onClick={()=>closeSummary()}>
                  <div className="heading1 letter-spacing font-normal display-flex-row  color-black"> 
                    <IonIcon icon={arrowBack} className="color-danger font-large center-aligned-column" /> <span className="marginleft5">Back to customize</span>
                  </div>
                </div>

            </div>
        </div>
        <div className="full-height-width background-white summary-content-wrapper"> 
          <div className="heading1 display-flex-row letter-spacing font-large">
          <span className="center-aligned-column marginRight5"><i className="far fa-clipboard color-primary font-large"></i></span>
            Summary
          </div>
        
          <div className="full-height overflow-y-scroll">
            <ConfigMoodBoard
            configObj={props.configObj}
            areas={props.areasList}
          />
          </div>
          </div>
        </IonModal>

        <ConfirmationModal
          isOpen = {showFinalConfirmationModal}
          Component = {FinalConfirmation}
          closeModal = {setShowFinalConfirmationModal}
        />
        
        <Modal
          isOpen = {showConfirmationModal}
          Content = {Confirmation}
          onDismiss = {setShowConfirmationModal}
          heading="Remaining Categories"
          classNames="project-action-modal"
          id=""
          FooterContent={ConfirmationModalFooter}
        />


      {/* <div className="summary-link shadow-medium tooltipclick-effect" id="summaryLink" onClick={()=>
        {
            checkIfRemainingCategories()
            $("#summaryTooltip").removeClass("--is-active")
        }
      }>
      <span className="tooltiptext border summary-tooltip" id="summaryTooltip">Click to see Summary </span>
      <div> <i className="fa fa-clipboard color-black"></i> Finalize</div>
    </div> */}
    </>
    ); 
  };
  
  export default Summary;
  