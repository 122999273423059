import { IonCheckbox } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Modal from "../../../../../common/shared-components/Modals/Modal";

const Confirmation: React.FC<{
  configObj:any
  categoriesStatus:any
  areasList:Array<any>
  action:()=>void
  showConfirmationModal:boolean
  setShowConfirmationModal:(val:boolean)=>void
}> = (props) => {


  useEffect(()=>{
    if(props.showConfirmationModal){
      checkIfRemainingCategories()
    }
  },[props.showConfirmationModal])

  const[showConfirmationModal,setShowConfirmationModal] = useState(false)


  function checkIfRemainingCategories() {
    //Check remaning categories for each area

    let object = props.categoriesStatus[props.configObj?.areaName]
    let remainingCategories = object?.remaining
    if(remainingCategories.length){
      setShowConfirmationModal(true)
      return
    }
    props.action()
  }




 
  function Confirmation(){
    return(
      
    <div className="font-small padding10 overflow-y-scroll full-height">
    
        <div className="shadow-medium padding10 ion-margin-bottom" >
          <div className="ion-margin-bottom"><div className="heading">{props.configObj.areaName}</div> </div>
          <table className="table text-start-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {props.categoriesStatus[props.configObj.areaName]?.remaining?.map((category:any,i:number)=>{
            return(
            <tr key={i}>
                <td >{category}</td>
                <td><IonCheckbox slot="end" className="is-disable" color="primary" mode="md" checked={false} disabled={true} /></td>
            </tr>
            )
            })}

          {props.categoriesStatus[props.configObj.areaName]?.confirmed?.map((category:any,i:number)=>{
            return(
            <tr key={i}>
                <td>{category}</td>
                <td><IonCheckbox slot="end" color="primary" mode="md" checked={true} disabled={true} /></td>
            </tr>
            )
            })}

          {props.categoriesStatus[props.configObj.areaName]?.additional?.map((category:any,i:number)=>{
            return(
            <tr key={i}>
                <td >{category}</td>
                <td><IonCheckbox slot="end" color="primary" mode="md" checked={true} disabled={true} /></td>
            </tr>
            )
            })}
          </tbody>
        </table>
        </div>
    </div>
    )
  }

  function ConfirmationModalFooter() {
    return(
      <div className="">
      <button className="op-btn op-btn-light" onClick={() => setShowConfirmationModal(false)}>Back to designing</button>
      <button className="op-btn op-btn-primary marginleft5" onClick={closeModal}>Continue Anyway</button>
      </div>
    )
  }

  function closeModal() {
    setShowConfirmationModal(false)
    props.action()
    props.setShowConfirmationModal(false)
  }

  function dismisModal(val:boolean) {
    setShowConfirmationModal(false)
    props.setShowConfirmationModal(false)
  }
  return (
    <React.Fragment>

      <Modal
          isOpen = {showConfirmationModal}
          Content = {Confirmation}
          onDismiss = {dismisModal}
          heading="Following categories not added"
          classNames="form-action-modal"
          id=""
          FooterContent={ConfirmationModalFooter}
        />
    </React.Fragment>
  );
};

export default Confirmation;
