import { Configuration } from "../../customizein3d/Configuration"
import { showGlobalToast, showToast } from "../../UI_methods/global"
import { addImageInConfiguration, resetCanvas, updateImagePosition } from "../helper"
import { drawImageKonva, moodboardConfig, removeGroup } from "../moodboard"
import { hideAllProductAnnotations } from "../UI_methods"
import {Image as MoodboardImage} from "../Configuration"
import { waitFor } from "../../methods"
import Konva from "konva"
import $ from "jquery"


export var modulesGroup = new Konva.Group({})
export var addonsGroup = new Konva.Group({})

var stage = null
var layer = null


var currGroup = null
var currImage = null

var posX = 0
var posY = 0
var aspectRatio = 1



export function modulesInit(stageRef:any,layerRef:any) {
  stage = stageRef
  layer = layerRef
}

export function resetModuleGroups() {
  modulesGroup = new Konva.Group({x:0,y:0})
  addonsGroup = new Konva.Group({x:0,y:0})
}

export function autoArrangeLShape(canvasContainerId:string) {

    // showComponentLoader(canvasLoaderId)
		resetInitialPosition()

		// modulesGroup = getModulesGroup(canvasContainerId)

    
    for (let i = 0; i < modulesGroup.children.length; i++) {
        currGroup = modulesGroup.children[i]
        currImage = currGroup.find(".canvasItem")[0]

        let currModuleType = currGroup.attrs.product.subCategoryName
        if(i === 0 || currModuleType === "Left"){
            let boundingBox = currGroup.getClientRect()
            posX = boundingBox.x + boundingBox.width
            posY = boundingBox.y
            continue
        }
        updateModulePositionOfLShape(currGroup,canvasContainerId)
    }

		hideAllProductAnnotations()
    centerModuleGroup()
		layer.draw()
}


// export function getModulesGroup(canvasContainerId:string) {
// 	stage = moodboardConfig.getStage(canvasContainerId)
// 	layer = moodboardConfig.getLayer(canvasContainerId)

// 	modulesGroup = layer.children
// 	modulesGroup = modulesGroup.children.filter(group=>group.children.length)

// 	return modulesGroup
// }

export function resetInitialPosition() {
  posX = 0
  posY = 0
}

//currModule = group
export async function updateModulePositionOfLShape(currModule:any,canvasContainerId:string) {

		// modulesGroup =  getModulesGroup(canvasContainerId)
    if(currModule?.attrs?.isAddedAsAddon){
      updatePositionOfAddon(currModule)
      return
    }
    let rotation = 0


    //In case of first left
    if(modulesGroup.children.length === 1){
      currModule.absolutePosition({x:posX,y:posY})
      updateConfiguration(currModule,rotation)
      layer.draw()
      return
    }
    
    let currModuleType = currModule.attrs.product.subCategoryName
    let lastModuleType = getLastModelModuleType()
    
    //If left is already added
    if(modulesGroup.children.length >= 1 && currModuleType === "Left"){
      return false
    }

     //if last module is right return
    // if(lastModuleType === "Right" && currModuleType==="Right"){
    //   console.log("here")
    //   return true
    // }

    let prevModule = getPrevModuleFromCurrModule(currModule) 
    prevModule = prevModule.find(".canvasItem")[0]


    //Needed when rearranging the model 
    let cornerModulesLength = getNumberOfCornerModulesTillLength(currModule)
    
    
    //Set model initial rotation (USE case in delete)
   
    let boundingBox = prevModule.getClientRect()
    posX = boundingBox.x + boundingBox.width
    posY = boundingBox.y


    //set rotation to 90
    if(cornerModulesLength === 1){
			posX = boundingBox.x + prevModule.getClientRect().width
			posY = posY + prevModule.getClientRect().height
			rotation = 90
    }//set rotation to 180
    if(cornerModulesLength === 2){
			posX = boundingBox.x 
			posY = posY + prevModule.getClientRect().height
			rotation = 180
    }

    currModule.absolutePosition({x:posX,y:posY})
    currModule.rotation(rotation)

		layer.draw()

    updateConfiguration(currModule,rotation)

}


function updateConfiguration(currModule:any,rotation:any) {
  let productAttributes = currModule.attrs.productAttributes
  if(productAttributes){
    productAttributes.posX = currModule.getAttrs().x;
    productAttributes.posY = currModule.getAttrs().y;
    productAttributes.width = currModule.find("Image")[0]?.getAttrs().width;
    productAttributes.height = currModule.find("Image")[0]?.getAttrs().height;
    productAttributes.rotation = rotation
  }
  addImageInConfiguration(currModule.attrs.image,stage)
}

export function getPrevModuleFromCurrModule(currModule:any) {
   // let array:any = modulesGroup.filter((module:any)=>module.object.uuid===moduleObject.uuid)
      //if array is not empty return the object
      let moduleIndex = 0
      for (let index = 0; index < modulesGroup.children.length; index++) {
        let currObject:any = modulesGroup.children[index]
        if(currModule._id === currObject._id){
            moduleIndex = index
          break
        }
      }
      if(moduleIndex){
        return modulesGroup.children[moduleIndex-1]
      }

      return getLastModelObject()
      //else return last element object  
}

export function getNumberOfCornerModulesTillLength(currModule:any) {
    let lastIndex = 0
      for (let index = 0; index < modulesGroup.children.length; index++) {
        let currObject:any = modulesGroup.children[index]
        if(currModule._id === currObject._id){
          lastIndex = index
          break
        }
      }
    //   if index found splice array and count number of corne modules
      if(lastIndex){
        let allModules = modulesGroup.children
        return getNumberOfCornerModules(Object.values(allModules).slice(0,lastIndex))
      }
      return getNumberOfCornerModules(modulesGroup.children)

    return 0
}

function getNumberOfCornerModules(groups:any){

    let array = groups.filter((group:any)=>{
        if(group.attrs.product.subCategoryName.toLowerCase() === "corner"){
            return group
        }
    })
    return array.length
}


function getLastModelObject(){
    let object:any = getLastModel()
    return object
}

function getLastModel(){
    //As its already added to the config 
    return modulesGroup.children[modulesGroup.children.length - 2]
}



export function isValidConfig(group:any,containerId:string) {

  // modulesGroup =  moodboardConfig.getCanvasItemsList(containerId)
  // modulesGroup =  getModulesGroup(containerId)

  if(modulesGroup.children.length <= 1){
    return true
  }

  let currModuleType = group.attrs.product.subCategoryName

  let lastElementModuleType = getLastModelModuleType()
  let cornerModulesLength = getNumberOfCornerModulesTillLength(group)


  //If left already added
  if(modulesGroup.children.length >= 1 && currModuleType === "Left"){
    showGlobalToast("Invalid Confing",2000,"error")
    return false
  }

  //1 means 0, if there is an empty configuration // for initial module position 
  if(modulesGroup.children.length === 1 && currModuleType != "Left"){
    showGlobalToast("Invalid Confing",2000,"error")
    return false
  }

  //If left is already added
  if(modulesGroup.children.length >= 1 && currModuleType === "Left"){
    replaceLeftModule()
    // showGlobalToast("Invalid Confing",2000,"error")
    return true
  }

  //if last module is right return
  if(lastElementModuleType==="Right"){
    //invalid config 
    if(currModuleType!="Right"){
      showGlobalToast("Invalid Confing",2000,"error")
      return false
    }
    replaceRightModule()
    return true
  }
  //If curr and last module typea are corner 
  if(currModuleType==="Corner" && lastElementModuleType=="Corner"){
    //Invalid config 
    showGlobalToast("Invalid Confing",2000,"error")
    return  false
  }
  //If already 2 corner modules are added
  if(currModuleType==="Corner" && cornerModulesLength===2){
    // Invalid config 
    showGlobalToast("Invalid Confing",2000,"error")
    return false
  }
  // addModuleToConfiguration(module,modelObject,configuration,customizeConfiguration,configObj)

  // addModelToScene(modelObject,module.display_name)
  return true
}


function getLastModelModuleType(){
  // let object = this.modules[this.getModulesLength()-1] 
  let object:any = getLastModel()
  return object.attrs.product.subCategoryName
}


function replaceLeftModule() {
  const leftModuleGroup = modulesGroup.children[0]
  removeGroup(leftModuleGroup,leftModuleGroup.parent)
}

function replaceRightModule() {
  const group = modulesGroup.children[modulesGroup.children.length-2]
  removeGroup(group,group.parent)
}

export function centerModuleGroup() {
  // modulesGroup = getModulesGroup(canvasContainerId)
  var stageWidth = stage.width();
  var stageHeight = stage.height();
  var groupClientRect = modulesGroup.getClientRect();
  var groupWidth = groupClientRect.width;
  var groupHeight = groupClientRect.height;

  modulesGroup.position({
    x: (stageWidth - groupWidth) / 2,
    y: (stageHeight - groupHeight) / 2,
  });
  // Draw the stage
  layer.draw();
}

export function updatePositionOfAddon(currModule:any) {
  // modulesGroup = getModulesGroup(canvasContainerId)
 
  var groupClientRect = modulesGroup.getClientRect();
  var groupWidth = groupClientRect.width;
  var groupHeight = groupClientRect.height;

  let x = groupClientRect.x + groupWidth + 5 
  let y =  groupClientRect.y 

  if(!modulesGroup.children.length && addonsGroup.children.length === 1){
    x = (stage.width() - currModule.getClientRect().width) / 2
    y = (stage.height() - currModule.getClientRect().height) / 2
  }

  currModule.position({
    x: x,
    y: y,
  });
  layer.draw();
}



export function updateRotateIconVisibility(group:any) {
  if(group.attrs.isAddedAsAddon){
    $("#moduleRotateIcon").removeClass("display-none")
  }else{
    $("#moduleRotateIcon").addClass("display-none")
  }
}