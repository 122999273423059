import { applicationConfig, getUser } from "../methods"

export function addItemToLocalStorage(key:string,data:any) {
    localStorage.setItem(key,JSON.stringify(data))
}


export function getItemFromLocalStorage(key:string) {
    return JSON.parse(localStorage.getItem(key))
}

export function getShortlistKey(moduleName:string,productName:string) {
    return `shortlist_${moduleName}_${productName}`
}

export function getCartKey() {
    if(window.location.pathname.includes("/store/")){
        return 'product-cart-items'
    }
    return 'cartItems'
}


export function updateRecentActivities(pageName:string,activityDetails:any){
    return
    try {
        let recentActivities = JSON.parse(localStorage.getItem("recentActivities")) || {}
        //Time stamp to sort the items 
        let currModulesActivities = recentActivities[pageName] || []
        let activity = {
            moduleName:pageName,
            timestamp:Number(new Date()),
            activityDetails:activityDetails
        }
        currModulesActivities.push(activity)
        currModulesActivities.sort((a:any,b:any)=>a.timestamp<b.timestamp?1:-1)
        if(currModulesActivities.length>4){
            currModulesActivities = currModulesActivities.slice(0,4)
        }
        recentActivities[pageName] = currModulesActivities
        localStorage.setItem("recentActivities",JSON.stringify(recentActivities)) 
    } catch (error) {
        console.log(error)
    }
   
}

export function updatedNotificationsList(currNotification:any){
    let notifications = JSON.parse(localStorage.getItem("notifications")) || null
    //Time stamp to sort the items 
    if(notifications){
        localStorage.setItem("notifications",JSON.stringify([...notifications,currNotification]))
    }else{
        localStorage.setItem("notifications",JSON.stringify([currNotification]))
    }

    // console.log(applicationConfig.states.notifications.updateNotifications,applicationConfig.functions.notifications.setUpdateNotifications)
    applicationConfig.functions.notifications.setUpdateNotifications(!applicationConfig.states.notifications.updateNotifications)
}


export function getRecentActivitiesOfModule(moduleName:string) {
    let recentActivities = JSON.parse(localStorage.getItem("recentActivities")) || {}
    let currModulesActivities = recentActivities[moduleName] || []
    return currModulesActivities
}

export function updateModuleFilters(pageName:string,key:string,value:any){
    //If customer share mode link
    if(getUser()?.isShareMode){
        return
    }
    let moduleFilters = JSON.parse(localStorage.getItem("moduleFilters")) || {}

    let config = moduleFilters[pageName] || {}
    config[key] = value

    moduleFilters[pageName] = config

    localStorage.setItem("moduleFilters",JSON.stringify(moduleFilters))
}

export function getSavedFilterValue(pageName:string,key:string){
    let moduleFilters = JSON.parse(localStorage.getItem("moduleFilters")) || {}
    let config = moduleFilters[pageName] || {}
    return config[key] || null
}


export function setSiteTourUserPreference(moduleName:string,value:boolean) {
    let userPreference = getUserPreferece("SiteTour") || {"isShowSiteTour":{}}
    userPreference["isShowSiteTour"][moduleName] = value
    setUserPreference("SiteTour",userPreference)
}


export function setUserPreference(moduleName:string,data:any) {
    let user = getItemFromLocalStorage("user")
    let userPreference = user?.userPreference || {}
    userPreference[moduleName] = data
    user["userPreference"] = userPreference  
    // console.log(user)
    addItemToLocalStorage("user",user)
}

export function getUserPreferece(moduleName:string) {
    let user = getItemFromLocalStorage("user")
    let userPreference = user?.userPreference || null
    if(!userPreference){
        return null
    }
    return userPreference[moduleName]
}