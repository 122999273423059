import React from 'react';
import { hideLogoutScreen } from '../../../../utils/methods';
import { useHistory } from 'react-router';
 
const Logout: React.FC<{
  
}> = (props) => {


  var history = useHistory()

  function handleLogout() {
      // window.location.href = "/login"
      history.push("/login")
      hideLogoutScreen()
  }

  return (

    <div   className='top-left welcome-screen display-none full-height-width' id="logoutScreen">
    <div className='full-height-width'>

      <div className='top-middle' style={{top:"3rem"}}>
        <img src="assets/images/opus.png" alt="" />
      </div>
      <div className='middle full-width ion-padding'>
        <div className='product-details  padding5'>
          <div className='color-black font-large text-center heading1'>Session expired !!</div>
        </div>
        <div className='color-black font-normal marginTop10 center-aligned-column heading1' id="shareItemText"></div>
        <div className='color-dark font-normal text-center center-aligned-column heading4'>Your session has been expired.</div>
      </div>

      <div className='bottom-middle ion-padding full-width' style={{bottom:"3rem"}}>
        <div className='display-flex-row no-wrap'>

        <div className='display-flex-row no-wrap padding10 full-width space-around' onClick={handleLogout}>
          <div className='white-card shadow-medium bg-color-primary color-white heading2 click-effect letter-spacing1' style={{padding:"10px 20px"}}>Login</div>
        </div>

        </div>
        
      </div>

    </div>
  </div>
  );
};

export default Logout;
