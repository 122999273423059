import React, { useEffect, useState } from "react";
import { getRandomString, stringWithoutWhiteSpace } from "../../../../utils/methods";
import { loadAndDisplayMaterialImage } from "../../../../utils/store/UI_methods";

const MaterialImage: React.FC<{
  material:any
  dimensions?:any
  classNames?:string
}> = (props) => {

  const[imageId]  = useState(`${stringWithoutWhiteSpace(props.material?.material_code || props.material?.materialCode)}_${getRandomString(10)}`)

  useEffect(()=>{
    setTimeout(() => {
      loadAndDisplayMaterialImage(imageId)
    }, 800);
  },[])


  return (
    <img  data-company={props.material?.company_name || props.material?.companyName} 
    data-collection={props.material?.collection_name || props.material?.collectionName} 
    data-material-code={props.material?.material_code || props.material?.materialCode} style={{width:`${props.dimensions?props.dimensions.width:"auto"}`,height:`${props.dimensions?props.dimensions.height:"auto"}`}} id={imageId} className={`product-image ${props.classNames}`} alt="" />
  );
};

export default MaterialImage;
