import $ from "jquery";
import React from "react";

const Alert: React.FC<{
  id:string
  classNames?:string
}> = (props) => {

  function hide() {
    $(`.${props.id}`).addClass("display-none")
  }
  return (
    <React.Fragment>
      <div className={`heading3 marginTop10 display-none font-md-small alert-container ${props.id} ${props.classNames}`}>
        <div className="right-middle">
          <i className="fas fa-times icon padding10"  onClick={hide}></i>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Alert;
