import React from "react";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import Search from "./Search";
 
const Header: React.FC<{
  refreshList:()=>void
  setSearchedLeadsList:(values:any)=>void
  openAddProduct:()=>void
}> = (props) => {

  
  function LeftContent() {
    return(
      <div className="display-flex-row">

      <div className="center-aligned-column">
        <Button2
          configObj={{id:""}}
          action={()=>{props.openAddProduct()}}
          label={"Create Product"}
          fontAwesomeIcon={"fas fa-edit color-dark"}
          iconPos="left"
          classNames={"no-shadow margin0 bg-color-primary"}
        />
      </div>
      </div>
    )
  }




  return (
    <div className="full-width position-relative display-flex-row space-between border-bottom leads-header" style={{padding:"10px 0"}}>

     

      
        <LeftContent/>

        <div className="middle hide-in-mobile">
        </div>

        <div className="right-content">
          <div className="display-flex-row no-wrap">
            <Search/>
          </div>
        </div>

       
        

      </div>  
  );
};

export default Header;
