import React, { useEffect, useState } from "react";
import  "../../../../../style/store/crm/header.css"
import { getRandomString, getUserType } from "../../../../../../utils/methods";
import { useHistory } from "react-router";
import { CRM } from "../../../../../../utils/store/crm/crmConfiguration";
import SideMenu from "./SideMenu";

 
const MainHeader: React.FC<{
  
}> = (props) => {


  const history = useHistory()

  const[currSelected,setCurrSelected] = useState("Leads")

  const[leadsCount] = useState(CRM.data.leadsList.length)
  const[customersListCount] = useState(CRM.data.customersList.length)
  const[ticketsListCount] = useState(CRM.data.ticketsList.length)
  const[customerTicketsListCount] = useState(CRM.data.customerTicketsList.length)

  const[routesList,setRoutesList] = useState(getRoutsList())

  useEffect(()=>{
    let userType = getUserType()
    if(userType?.toLowerCase() === "security"){
      setRoutesList(routesList.filter(currRoute => currRoute.routeName === "Visitors" || currRoute.routeName === "Visitors"))
      setCurrSelected("Visitors")
    }
    if(userType?.toLowerCase() === "customercare"){
      setRoutesList(routesList.filter(currRoute => currRoute.routeName === "Tickets" || currRoute.routeName === "customer-tickets"))
      setCurrSelected("Tickets")
    }
    if(userType?.toLowerCase() === "dispatcher"){
      setRoutesList(routesList.filter(currRoute => currRoute.routeName === "Tickets"))
      setCurrSelected("Tickets")
    }
    if(userType?.toLowerCase() === "salesspecialist" || userType?.toLowerCase() === "insidesales"){
      setRoutesList(routesList.filter(currRoute => currRoute.routeName === "Leads"))
      setCurrSelected("Lead")
    }
    
    if(!userType){
      setRoutesList([])
      setCurrSelected("")
    }

  },[history,leadsCount,ticketsListCount,customerTicketsListCount,customersListCount])

  function gotoPage(page:string) {
    history.push(`/${page.toLowerCase()}`)
  }

 
  function getRoutsList() {
     return[
      {
        routeName:"Leads",
        count:leadsCount,
        iconClass:"far fa-list-alt "
      },
      {
        routeName:"Customers",
        count:customersListCount,
        iconClass:"fas fa-users "
      },
      {
        routeName:"Tickets",
        count:ticketsListCount,
        iconClass:"fas fa-ticket-alt "
      },
      {
        routeName:"customer-tickets",
        count:customerTicketsListCount,
        iconClass:"fas fa-ticket-alt "
      },
     ]
  }
 
  return (
    <>
    <SideMenu routesList={routesList}/>
    <div className="full-width padding-container display-flex-row border-bottom no-wrap crm-main-header-wrapper main-header-wrapper">
      
      <ul className="items display-flex-row no-wrap">
        <div className="logo center-aligned-column" style={{padding:"0"}}>
          <img src="/assets/images/logos/estre.png" height={40}  alt="" />
        </div>
        {routesList.map((currRoute:any,index:number)=>{
          return(
            <li onClick={()=>gotoPage(currRoute.routeName)} key={index} className={`item display-flex-row border-right pointer center-aligned-column `}>
                <span className="capitalize heading4 color-black font-normal">{currRoute.routeName}</span>
                <div className="center-aligned-column">
                  <span className="heading1 center-aligned-column padding0 font-small color-warning marginleft5" style={{position:"relative",top:"unset",right:"unset"}}>({currRoute.count})</span>
                </div>
            </li>
          )
        })}
      </ul>
    </div>
    </>
  );
};

export default MainHeader;
