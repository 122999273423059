import $ from "jquery"

var isDragging = false
var startX, startY, startLeft, startTop,startWidth, startHeight
var isResizing = false

export var imageContainerId = "imageViewerImage"

var imageAspectRatio = 1

// Register the mousedown event to start dragging
export function pointerDown(event:any){
    return
    if(!isResizing){
        isDragging = true
        startX = event.clientX
        startY = event.clientY
        startLeft = parseInt($('#imageViewerImage').css('left'), 10)
        startTop = parseInt($('#imageViewerImage').css('top'), 10)
    }
}

export function pointerUp(event:any){
    isDragging = false
    isResizing = false
}


export function pointerMove(event:any){
    if (isDragging) {
        var deltaX = event.clientX - startX
        var deltaY = event.clientY - startY

        // Update the image position
        var newLeft = startLeft + deltaX
        var newTop = startTop + deltaY
        $('#imageViewerImage').css('left', newLeft)
        $('#imageViewerImage').css('top', newTop)
    }
}


export function pointerDownResizer(e:any){
    if(!isDragging){
        isResizing = true
        startX = e.clientX
        startY = e.clientY
        startWidth = $('#imageViewerImage').width()
        startHeight = $('#imageViewerImage').height()
    }
}


export function pointerMoveResizer(e:any){
    if(isResizing){
        var deltaX = e.clientX - startX
        var deltaY = e.clientY - startY
    
        // Resize the image
        var newWidth = startWidth + deltaX
        // var newHeight = startHeight + deltaY
        var newHeight = newWidth / getImageAspectRatio()

        $('#imageViewerImage').css('width', newWidth)
        $('#imageViewerImage').css('height', newHeight)
    }
    
}


function getImageAspectRatio() {
    return parseInt($('#imageViewerImage').find("img").css('width'), 10) / parseInt($('#imageViewerImage').find("img").css('height'), 10)
}


export function updateImageViewerFrame() {
    var newHeight = parseInt($('#imageViewerImage').find("img").css('width')) / getImageAspectRatio()
    $('#imageViewerImage').css('height', newHeight)  
}
  