import { IonModal } from "@ionic/react";
import $ from "jquery";
import React, { useState } from "react";
import { FabricateConfig } from "../../../../../utils/fabricate/fabricate";
import { kanvasScenes } from "../../../../../utils/fabricate/scenes";
import { applicationConfig } from "../../../../../utils/methods";
import Button2 from "../../../../common/ui-components/buttons/Button2";
 
const ScenesList: React.FC<{
    fabricateConfig:FabricateConfig
}> = (props) => {

const[isOpen,setisOpen] = useState(false)
const[list] = useState(applicationConfig.clientName === "KairaFabrics"?[kanvasScenes[2]]:kanvasScenes)

const [currTab, setCurrTab] = useState<string>("BedroomDesigns");
const[tabItems,setTabItems] = useState([
    {tabName:"BedroomDesigns",label:"Bedroom Designs",ionIcon:null},
    {tabName:"CushionFabrics",label:"Cushion Fabrics",ionIcon:null}
  ])

  
  function changeTabOption(tabName: string) {
    setCurrTab(tabName);
    updateTabValue(tabName)
  }


 

  function updateTabValue(val:string){
    $(".tab-content").removeClass("--is-active")
    $("#"+val).addClass("--is-active")
  }

  function load(currScene:any){
    setisOpen(false)
    props.fabricateConfig.updateScene(currScene)
  }



function List(){
    return(
        <div className="full-height-width">
            <div className="header-content heading3 font-large border-bottom center-aligned-column">Select a Scene</div>
            <div className="body-content center-aligned-column ion-padding">

                {/* <div className="center-aligned-row padding10">
                <div className="width50">
                    <ControlsTab
                        configObj={applicationConfig}
                        tabItems={tabItems}
                        currSelectedValue={currTab}
                        action={changeTabOption}
                        theme="black"
                    />
                </div>
                </div> */}
                

                

                <div className="center-aligned-row full-width no-wrap marginTop10">
                    {list.map((currScene:any,index:any)=>{
                        return(
                            <div  key={index} className="white-card border position-relative no-shadow marginleft10" style={{width:"20rem",aspectRatio:"4/3"}}>
                                <div className="middle">
                                    <Button2
                                        configObj={{id:""}}
                                        action={()=>{load(currScene)}}
                                        label={"Load Scene"}
                                        fontAwesomeIcon={"fas fa-cogs color-black "}
                                        iconPos="left"
                                        classNames={"shadow-medium button-small"}
                                    />
                                </div>

                                <img className="full-height-width" src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/fabricate/assets/${currScene.scene_name}.png`} alt="" />

                                <div className="top-left padding5">
                                    <div className="heading3">{currScene.scene_name}</div>
                                </div>

                            </div>
                        )
                    })}
                </div>
            

            </div>
            <div className="footer-content center-aligned-column">
                <Button2
                    configObj={{id:""}}
                    action={()=>{setisOpen(false)}}
                    label={"Close"}
                    fontAwesomeIcon={"fas fa-share color "}
                    iconPos=""
                    classNames={"shadow-medium button- store-bg-color-primary"}
                />

            </div>
        </div>
       
    )
}

  
  return (
    
    <>
        <IonModal
            isOpen = {isOpen}
            onDidDismiss = {()=>setisOpen(false)}
            cssClass="large-x-modal no-border"
            id=""
            >
            <List/>
        </IonModal>

        <div className="display-flex-row no-wrap">
            <div className="heading3 center-aligned-column">Bedroom1 </div>
            <div className="marginleft5 center-aligned-column">
                <Button2
                    configObj={{id:""}}
                    action={()=>{setisOpen(true)}}
                    label={"Change Scene"}
                    fontAwesomeIcon={"fas fa-share color "}
                    iconPos=""
                    classNames={"shadow-medium button-ex-small bg-color-primary-outline"}
                />
            </div>
           
        </div>
    </>
  );
};

export default ScenesList;
