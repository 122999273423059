import axios from "axios";
import { applicationConfig, getUser } from "../utils/methods";
import { AWS_AUTH_TOKEN, BASE_PATH } from "./api";


export class CrmApi{
  
  leads = new Leads()
  activity = new Activity()
  visitors = new Visitors()
  customers = new Customers()
  common = new Common()
  ticket = new Tickets()
  customerCreatedTicket = new CustomerCreatedTicket()
  
  constructor(){

  }

}


class Leads{
  constructor(){

  }

  createLead = (leadDetails:any,assignedTo:number) => {
    let data = {
      leadDetails:leadDetails,
      userId:assignedTo,
      clientId:applicationConfig.clientId,
    }
    return axios
      .post(`${BASE_PATH}api/crm/leads/create`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }


  updateLead = (leadId:number,leadDetails:any) => {
    let data = {
      leadDetails:leadDetails,
      leadId:leadId
    }
    return axios
      .post(`${BASE_PATH}api/crm/leads/update`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  assignLead = (userId:number,leadId:any) => {
    let data = {
      userId:userId,
      leadId:leadId
    }
    return axios
      .post(`${BASE_PATH}api/crm/leads/assign`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getLeads = (clientId:number,userId:number) => {
    return axios
      .get(`${BASE_PATH}api/crm/leads/getItems/?userId=${userId}&&clientId=${clientId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getUnassignedLeads = (clientId:number = applicationConfig.clientId) => {
    return axios
      .get(`${BASE_PATH}api/crm/leads/unassginedleads/?clientId=${clientId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

}


class Visitors{
  constructor() {
    
  }

  createVisitor = (visitorDetails:any) => {
    let data = {
      visitorDetails:visitorDetails,
      userId:getUser()?.userId
    }
    return axios
      .post(`${BASE_PATH}api/crm/visitors/create`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getVisitors = (userId:number = getUser()?.userId) => {
    return axios
      .get(`${BASE_PATH}api/crm/visitors/?userId=${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

}


class Activity{
  constructor(){

  }

  createActivity = (leadId:number,details:any) => {
    let data = {
      leadId:leadId,
      details:details
    }
    return axios
      .post(`${BASE_PATH}api/crm/activities/createActivity`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  updateActivity = (activityId:number,details:any) => {
    let data = {
      details:details,
      activityId:activityId
    }
    return axios
      .post(`${BASE_PATH}api/crm/activities/updateLead`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  closeActivity = (leadId:number,details:any,nextActivityDetails:any) => {
    let data = {
      leadId:leadId,
      activityDetails:details,
      nextActivityDetails:nextActivityDetails
    }
    return axios
      .post(`${BASE_PATH}api/crm/activities/close`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getActivities = (leadId:any = null) => {
    return axios
      .get(`${BASE_PATH}api/crm/activities/getItems/?leadId=${leadId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

}

class Common{
  constructor(){

  }

  getActivityTypes = () => {
    return axios
      .get(`${BASE_PATH}api/crm/common/activityTypes`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }


  getSalesStages = () => {
    return axios
      .get(`${BASE_PATH}api/crm/common/salesStages`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getUsers = () => {
    return axios
      .get(`${BASE_PATH}api/crm/common/users?clientId=${applicationConfig.clientId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getCities = () => {
    return axios
      .get(`${BASE_PATH}api/crm/common/cities`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

}

class Customers{
  constructor(){

  }

  getCustomersList = () => {
    return axios.get(`${BASE_PATH}api/store/getCustomers?clientId=${applicationConfig.clientId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

}

class Tickets{

  comments:Comments = new Comments()
  
  constructor(){

  }

  create = (userId:any,details:any) => {
    let data = {
      details:details,
      userId:userId,
    }
    return axios
      .post(`${BASE_PATH}api/crm/tickets/create`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  update = (id:any,details:any) => {
    let data = {
      details:details,
      ticketId:id,
    }
    return axios
      .post(`${BASE_PATH}api/crm/tickets/update`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  getTickets = (userId:any = null) => {
    return axios
      .get(`${BASE_PATH}api/crm/tickets?userId=${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }
}

class Comments{
  constructor(){

  }

  create = (userId:any,ticketId:number,details:any) => {
    let data = {
      details:details,
      userId:userId,
      ticketId:ticketId,
    }
    return axios
      .post(`${BASE_PATH}api/crm/tickets/comments/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  get = (ticketId:number) => {
    return axios
      .get(`${BASE_PATH}api/crm/tickets/comments?ticketId=${ticketId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }
}

class CustomerCreatedTicket{
  constructor(){

  }

  create = (details:any) => {
    let data = {
      details:details,
    }
    return axios
      .post(`${BASE_PATH}api/crm/customerCreatedTickets/create`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }

  update = (id:any,details:any) => {
    let data = {
      details:details,
      ticketId:id,
    }
    return axios
      .post(`${BASE_PATH}api/crm/customerCreatedTickets/update`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }


  getTickets = (userId:any = null) => {
    return axios
      .get(`${BASE_PATH}api/crm/customerCreatedTickets`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
  }
}