import { IonModal } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { hideComponentLoader } from "../../../../utils/UI_methods/global";
import { displayImageFromCache, getThumbnailsUrlKey, untarAndSaveToCache } from "../../../../utils/cache/cache";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray } from "../../../../utils/methods";
import FinishAction from "../../../admin/finish/finishActions/FinishActions";
import Home from "../../../admin/finish/finishActions/Home";
import Loader from "../../shared-components/main-ui/Loader";

const FinishCards: React.FC<{
  configObj: any;
  materials:any
  collections:any
}> = (props) => {
  //Function to remove project after deleting
  const[currCollection,setCurrCollection] = useState("")
  
  const[openFinishAction,setopenFinishAction] = useState(false)
  const[finishLoaderClass] = useState<string>("finish-loader")

  useEffect(()=>{
    setTimeout(() => {
      if(props.collections.length && props.materials.length){
        let companyNames:Array<any> = getArrayOfDistinctValues(props.materials,"company_name")
        loadAndDisplayImages(companyNames)
      }
    }, 500);
  },[props.collections,props.materials])

 

  async function loadAndDisplayImages(companyNames:any) {
    //for each company get collection name
    for (let i = 0; i < companyNames.length; i++) {
      let companyName:string = companyNames[i]
      if(companyName==="Default"){
        continue
      }
      let filteredCompanyMaterials = getFilteredArray(props.configObj?.data.materials,"company_name",companyNames[i])
      let allCollections = getArrayOfDistinctValues(filteredCompanyMaterials,"collection_name")

      for (let j = 0; j < allCollections.length; j++) {
        const collectionName = allCollections[j];
        if(collectionName!="Default"){
          let key = applicationConfig.awsConfig.getTarFileKey("thumbnails",{companyName:companyName,collectionName:collectionName})
          await untarAndSaveToCache(applicationConfig.awsConfig,`thumbnails/OVL/${companyName}/${collectionName}`,key)?.then(data=>{
            displayImage(collectionName)
          }).catch(err=>{
            console.log(err)
          })
        }
      }
    }
}

async function displayImage(collection:any) {
  // for (const collection of props.collections) {
    let image = $(`#collectionImage${collection}`);
    let filteredMaterials = getFilteredArray(props.materials,"collection_name",collection)
    let key = getThumbnailsUrlKey(filteredMaterials[0]?.company_name,collection,filteredMaterials[0]?.material_code)
    await displayImageFromCache(image,key)
    hideComponentLoader(`collectionLoader${collection}`)
  // }
}

useEffect(()=>{
  applicationConfig.setState("finish",{currCollection:currCollection})
  applicationConfig.functions.finish.setCurrSelectedCollectionForBreadcrumb(currCollection)
},[currCollection])

useEffect(()=>{
  applicationConfig.setFunctionRef("finish",{setCurrCollection:setCurrCollection})
},[currCollection])

  return (
      <React.Fragment>
      
      <IonModal  onDidDismiss={() => setopenFinishAction(false)} isOpen={openFinishAction}
        id="finishActions"
        cssClass='form-action-modal'>
          <FinishAction
            configObj={props.configObj}
            setopenFinishAction={setopenFinishAction}
            currCollection={currCollection}
            materials={props.materials}
          />
      </IonModal>

      {currCollection?
        <>
          <Home
            configObj={props.configObj}
            currCollection={currCollection}
            setopenFinishAction={setopenFinishAction}
            materials={props.materials}
          />
        </>
      :
      <>
      {props.collections?.map((collection:any,index:number)=>{
        return (
              <div className="project-container no-shadow white-card" key={index} 
              >
                <div className="project-detials-container">
                  <div className="image position-relative center-aligned-column ion-padding"  onClick={()=>{
                      // setopenFinishAction(true)
                      setCurrCollection(collection)
                    }}>
                       <Loader 
                          className={`${finishLoaderClass} --is-active`}
                          id={`collectionLoader${collection}`}
                          data={{}}
                          loaderType="spinner"
                        />
                        <img src="assets/images/placeholder.png" id={`collectionImage${collection}`} alt="" />
                  </div>
                  <div className="other-details border-top padding10 display-flex-column">
                      <div className="display-flex-row no-wrap overflow-hidden"> <i className="fa fa-user-circle color-dark" area-hidden="true" /><span className="heading3 padding-start">{collection}</span></div>
                      <div className="display-flex-row no-wrap overflow-hidden"> <i className="fa fa-compass color-dark" area-hidden="true" /><span className="heading padding-start white-space-nowrap">Materials available: {getFilteredArray(props.materials,"collection_name",collection).length}</span></div>
                  </div>
                </div>
               </div>
        )
      })}

        <div className="project-container product-image-container product-container" style={{height:"0.1px",visibility:"hidden"}}></div>
        <div className="project-container product-image-container product-container" style={{height:"0.1px",visibility:"hidden"}}></div>
        <div className="project-container product-image-container product-container" style={{height:"0.1px",visibility:"hidden"}}></div>
        <div className="project-container product-image-container product-container" style={{height:"0.1px",visibility:"hidden"}}></div>
        <div className="project-container product-image-container product-container" style={{height:"0.1px",visibility:"hidden"}}></div>

      </>
      
      }

        


        
      </React.Fragment>
  )
}

export default FinishCards;
