import { getFilteredArray } from "../methods"

export   function getProjectState(projectId:number,configObj:any) {
    let state = 1
    //if moodboard config exists
    if(getFilteredArray(configObj?.data?.moodBoardSavedConfigs,"project_id",projectId)?.length){
      state = state + 1
    }

    if(configObj?.data?.customizerSavedConfigs){
      if(getFilteredArray(configObj?.data?.customizerSavedConfigs,"project_id",projectId)?.length){
        state = state + 1
      }
    }
    //if cusomise Config exists
    return state
  } 