import React, { useState } from "react";
import { useHistory } from "react-router";
import { projectConfiguration, updateAreaCustomizer } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { getNextArea, getPrevArea } from "../../../../../../utils/customizer/helper";
import { applicationConfig, logger } from "../../../../../../utils/methods";
import { closeMinimap, updateArea } from "../../../../../../utils/virtualtour/virtualtour";
import Modal from "../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import Minimap from "../../customizer/canvas/Minimap";


  const AreaMinimap: React.FC<{
    configObj:any
    currentConfigObject:any
    areas:Array<any>
    module:string
  }> = (props) => {

 
   const[isOpenMinimap,setIsOpenMinimap] = useState(false)
   const[currArea,setCurrArea] = useState(applicationConfig.areaName)

   var history = useHistory()

   function gotoArea(area:any,action:string){
    props.configObj?.setCurrArea(area.area_name)
    props.configObj?.setCurrAreaId(area.area_id || area.id)
    if(action === "customizer"){
      gotoCustomizer(area)
    }else{
      if(props.module === "customizer"){
        updateAreaCustomizer(area.area_name)
      }else{
        updateArea(area.area_name)
      }
    }
    setCurrArea(area.area_name)
    setIsOpenMinimap(false)
   }

  


   function gotoCustomizer(area:any) {
    // history.replace("/3dwalkthrough",null)
    history.push("/customize")
    setTimeout(() => {
      // props.setProducts(props.configObj?.projectId,props.configObj?.projectName);
      logger?.info("virtualtour","customize",`Start customizing: ${props.configObj?.projectId}`) 
    }, 200);
    }

   function gotoNextArea() {
    let nextArea = getNextArea(props.areas,currArea,props.module)
    if(props.module === "customizer"){
      updateAreaCustomizer(nextArea.area_name)
    }else{
      updateArea(nextArea.area_name)
    }
    setCurrArea(nextArea.area_name)
   }

   function gotoPrevArea(){
    let prevArea = getPrevArea(props.areas,currArea,props.module)
    if(props.module === "customizer"){
      updateAreaCustomizer(prevArea.area_name)
    }else{
      updateArea(prevArea.area_name)
    }
    setCurrArea(prevArea.area_name)
   }

   function editFloorplanAction() {
    setIsOpenMinimap(false)
    applicationConfig.functions.customizer.toggleFloorPlanner()
   }

   function Footer() {
    return(
      <div onClick={editFloorplanAction} className="display-flex-row zindex30">
        <i className="fas fa-edit color-danger font-medium"></i>
        <div className="display-flex-row heading3 marginleft5 color-black text-center"> Edit floor plan </div>
      </div>
    )
  }
  
    return (
      <React.Fragment>
        

        {/* <div className="top-right zindex30" onClick={()=>setIsOpenMinimap(true)}>
          <i className="fas fa-location-arrow color-white font-medium"></i>
        </div> */}

        <Modal
        isOpen={isOpenMinimap} 
        classNames="project-action-modal"
        Content={()=>(
          <Minimap
            allAreas={props.areas}
            // allAreas={sortAreasByPriority(props.areas)}
            setProducts = {()=>{}}
            closeModal = {setIsOpenMinimap}
            configObj={props.configObj}
            content="Areas"
            isUpdateUrl={false}
            action={gotoArea}
          />
        )
      }
      FooterContent={Footer}
      id={""}
      onDismiss={setIsOpenMinimap}
      heading={"Select Area"}
        
        />

        {projectConfiguration?.areasList.length !== 2?
          <div className="area-view-selector-wrapper no-wrap padding10 display-flex-row pointer top-middle" id="areaSelectorMobile">
          <Button2
            configObj={props.configObj}
            action={gotoPrevArea}
            label={""}
            fontAwesomeIcon={"fas fa-chevron-left color-dark"}
            iconPos="right"
            classNames={"height-inherit shadow-medium no-border button-small"}
          />
          
          <Button2
            configObj={props.configObj}
            action={()=>setIsOpenMinimap(true)}
            label={currArea}
            fontAwesomeIcon={"fas fa-map-pin color-dark"}
            iconPos="left"
            classNames={"shadow-medium no-border button-small"}
            svgUrl="assets/icon/svg/location_primary.svg"
          />

          <Button2
            configObj={props.configObj}
            action={gotoNextArea}
            label={""}
            fontAwesomeIcon={"fas fa-chevron-right color-dark"}
            iconPos="right"
            classNames={"height-inherit shadow-medium no-border button-small"}
          />

        <Button2
            configObj={props.configObj}
            action={()=>setIsOpenMinimap(true)}
            label={""}
            fontAwesomeIcon={"fas fa-location-arrow color-danger"}
            iconPos="right"
            classNames={"hide-in-mobile shadow-medium no-border button-small"}
            svgUrl="assets/icon/svg/navigation.svg"

          />
          
        </div>
        :
        <div className="header-heading">{projectConfiguration.projectName}</div>
        }

      

    {/* <div className="bottom-left hide-in-desktop zindex30 ion-padding" id="openMinimap"  onClick={openMinimap}>
              <i className="fas fa-street-view color-white font-ex-large"></i>
          </div> */}

        {props.module === "virtualtour"?

        <div className="bottom-left zindex30 pointer display-none" id="virtualTourAreaWrapper" >
          {/* <div className="position-relative" style={{width:"180px",aspectRatio:"0.75"}} id="minimapContainer"> */}
          <div className="position-relative" style={{width:"180px"}} id="minimapContainer">
            <img src="assets/images/FloorPlan1.png" className="full-height-width" alt="" />

            {/* <canvas className="full-height-width top-left" id="minimapCanvas" style={{zIndex:1,padding:0}}></canvas> */}
            {/* <canvas className="full-height-width top-left" id="iconCanvas" style={{zIndex:2,padding:0}}></canvas> */}


            <div className="zindex30" id="currLocationIcon" style={{position:"fixed"}}>
              <div className="tour-view-icon shadow-medium" >
              </div>
              <div className="look-at"></div>
            </div>

            <div className="top-right hide-in-desktop" onClick={closeMinimap}>
              <i className="fas fa-times color-black font-medium"></i>
            </div>

       

          </div>
        </div>

        :null}


         
      </React.Fragment>
    );
  };
  
  export default AreaMinimap;
  