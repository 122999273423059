import $ from "jquery"
import { updateTransformer } from "./moodboard"



export function updateProductSelectionBox(rotation:number,imageWidth:any,imageHeight:any,canvasContainerId:string,group:any) {

    // x = x + $("#"+canvasContainerId).offset().left
    // y = y + $("#"+canvasContainerId).offset().top

    const box = group.getClientRect()
    let x = box.x + box.width/2
    let y = box.y + box.height/2


    // let centerIcon = group.find(".centerAnchor")[0]
    // x = centerIcon.absolutePosition().x
    // y = centerIcon.absolutePosition().y
  


    let selectionBox = $("#"+canvasContainerId).parents(".moodboard-canvas-container").find(".product-selection-box")
    selectionBox.addClass("--is-active")
    selectionBox.css({"top":y,"left":x,"width":imageWidth,"height":imageHeight,"transform":`translateX(${-imageWidth/2}px) translateY(${-imageHeight/2}px) rotate(${rotation || 0}deg) `})
    // hoverBox.css({"top":y,"left":x,"width":imageWidth,"height":imageHeight,"transform":`rotate(${rotation}deg)`})
    // $("#"+canvasContainerId).removeClass("--is-hover")
    $("#productActionsContainer").addClass("--is-active")
  }


  export function hideProductSelectionBox(canvasContainerId:string=  "mainMoodboardCanvasContainer") {
    $(".product-selection-box").removeClass("--is-active")
    $("#"+canvasContainerId).addClass("--is-hover")
    disableProductSelectionMode()
  }

  export function enableProductSelectionMode() {
    $(".product-selection-box").addClass("--is-selection-mode")
  }

  export function disableProductSelectionMode() {
    $(".product-selection-box").removeClass("--is-selection-mode")
  }


  export function updateProductHoverBoxPosition(x:any,y:any,rotation:number,imageWidth:any,imageHeight:any,canvasContainerId:string,group:any) {
    // x = x + $("#"+canvasContainerId).offset().left
    // y = y + $("#"+canvasContainerId).offset().top

    // let bottomLeft = group.find(".bottomLeft")[0]
    // let topLeft = group.find(".topLeft")[0]
    // let topRight = group.find(".topRight")[0]
    // let transformX = topLeft.absolutePosition().x - bottomLeft.absolutePosition().x
    // let transformY = Math.abs(topLeft.absolutePosition().y)  - Math.abs(topRight.absolutePosition().y) 

    // console.log(topLeft.absolutePosition().y,y)
    
    // x = x - transformX/2
    // y = y - Math.abs(transformY/2) 


    // x = x + imageWidth/2
    // y = y + imageHeight/2

    //position of top left with rotation 0 


 
    let centerIcon = group.find(".centerAnchor")[0]
    x = centerIcon.absolutePosition().x
    y = centerIcon.absolutePosition().y
  

    let hoverBox = $("#"+canvasContainerId).parents(".moodboard-canvas-container").find(".product-hover-box")

    hoverBox.css({"top":y,"left":x,"width":imageWidth,"height":imageHeight,"transform":`translateX(${-imageWidth/2}px) translateY(${-imageHeight/2}px) rotate(${rotation}deg) `})
    // hoverBox.css({"top":y,"left":x,"width":imageWidth,"height":imageHeight,"transform":`rotate(${rotation}deg)`})
    hoverBox.addClass("--is-active")
  }


  function rotatedTopLeft(x:any,y:any,width:any,height:any,rotationAngle:any){

    // get the center of the rectangle (==rotation point)
    var cx=x+width/2;
    var cy=y+height/2;

    // calc the angle of the unrotated TL corner vs the center point
    var dx=x-cx;
    var dy=y-cy;
    var originalTopLeftAngle=Math.atan2(dy,dx);

    // Add the unrotatedTL + rotationAngle to get total rotation
    var rotatedTopLeftAngle=originalTopLeftAngle+rotationAngle;

    // calc the radius of the rectangle (==diagonalLength/2)
    var radius=Math.sqrt(width*width+height*height)/2;

    // calc the rotated top & left corner
    var rx=cx+radius*Math.cos(rotatedTopLeftAngle);
    var ry=cy+radius*Math.sin(rotatedTopLeftAngle);

    // return the results
    return({left:rx,top:ry});
}

  export function rotateHoverBox(rotation:number,canvasContainerId:string) {
    return
    // x = x + $("#"+canvasContainerId).offset().left
    // y = y + $("#"+canvasContainerId).offset().top
    let hoverBox = $("#"+canvasContainerId).parents(".moodboard-canvas-container").find(".product-hover-box")
    hoverBox.css({"transform":`rotate(${rotation}deg)`})
    hoverBox.addClass("--is-active")
  }


  export function hideProductHoverBox() {
    $(".product-hover-box").removeClass("--is-active")
  }

  export function isProductSelectionMode() {
      if($("#productSelectionBox").hasClass("--is-selection-mode")){
          return true
      }
      return false
  }


  export function updateProductAddonsPosition(x:any,y:any,rotation:number,imageWidth:any,imageHeight:any,canvasContainerId:string) {
    // x = x + $("#"+canvasContainerId).offset().left
    // y = y + $("#"+canvasContainerId).offset().top
    let addonsBox = $("#"+canvasContainerId).parents(".moodboard-canvas-container").find(".product-addons-box")
    addonsBox.css({"top":y,"left":x,"width":imageWidth,"height":imageHeight})
    addonsBox.addClass("--is-active")
  }