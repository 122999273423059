import React from "react";
import { useHistory } from "react-router";
import { getMoodboardAreaCategories, getMoodboardList, getProjectAreaCategories, saveMoodboard, saveMoodboardAreaCategories } from "../../../../services/api";
import { logger } from "../../../../utils/methods";
import { addNewBadgeClassToElement, hideComponentLoader, updateLoaderProgress } from "../../../../utils/UI_methods/global";
import AreaProductDetails from "../../projects/createproject/review/AreaProductDetails";

const Review: React.FC<{
     formData:any
     setCurrStep:(val:string)=>void
     configObj:any
     refreshMoodboardList:(data:Array<any>)=>void
     setCurrProjectDetails:(id:number,name:string)=>void
     setCurrMoodboardId:(id:string)=>void

     selectedCategories:any
     selectedAreas:any
}> = (props) => {


  let history  = useHistory()


 


  function prevHandle() {
    props.setCurrStep("step2")
  }

  
  function createMoodboardFromScratch() {
    // showComponentLoader("mainLoader")
    let moodboardId = Number(new Date())
    let projectId = 0
    let sagment = "Other"

    // let promiseSaveAreaCategories = saveMoodboardAreaCategories(moodboardId,props.selectedCategories)
    // let promiseSaveMoodboard = saveMoodboard(9,moodboardId,projectId,props.selectedAreas,props.formData.name,sagment)

    saveMoodboard(9,moodboardId,projectId,props.selectedAreas,props.formData.name,sagment).then(data=>{
      saveMoodboardAreaCategories(data.data.data.insertId,props.selectedCategories).then(data=>{
        let promiseMoodboardAreaCategories = getMoodboardAreaCategories()
        let promiseMoodbboardList = getMoodboardList()
        Promise.all([promiseMoodbboardList,promiseMoodboardAreaCategories]).then(data=>{
          let configData = props.configObj.getData()
          configData['moodboardList'] = data[0]
          configData['moodboardAreaCategories'] = data[1]
          props.configObj.setData(configData)
          props.setCurrStep("options")
          props.formData.moodboardId = moodboardId
          logger?.info("collections","createcollection",`Collection created successfully: ${moodboardId}`)
        }).catch(err=>{
          logger.error("collections","createcollection",`Error in creating collection: `)
          console.log(err)
        })
      }).catch(err=>{
        logger.error("collections","createcollection",`Error in creating collection: `)
        console.log(err)
      })
    }).catch(err=>{
      logger.error("collections","createcollection",`Error in creating collection: `)
      console.log(err)
    })


    // saveMoodboard(props.configObj?.clientId,moodboardId,projectId,props.formData.selectedAreas,"styleBoard",sagment).then(data=>{
    //   props.formData.moodboardId = moodboardId
    //   props.setCurrStep("step4")
    //   logger?.info("collections","createcollection",`Collection created successfully: ${moodboardId}`)
    // }).catch(err=>{
    // })
  }

  const ProjectDetails = () => {
    return(
        <React.Fragment>
            <div className="project-details position-relative ion-padding white-card no-shadow">
                <div className="padding10 bottom-left full-width display-flex-row space-between no-wrap">
                    <button className="op-btn op-btn-primary ">Get Price range</button>
                    <button className="op-btn op-btn-info marginleft5">Share <i className="fas fa-share"></i> </button>
                </div>
                <div className="upload-image">
                    <img src="assets/images/placeholder.png" id="uploadedImage" alt="" />
                </div>
                <div className="details-container ion-margin-top">
                    
                </div>
            </div>
        </React.Fragment>
    )
}

 
 
  return (
    <React.Fragment>
    <div className="modal-header capitalize">Review</div>
      <div className="modal-body padding10">
        <div className="project-details-summary-container display-flex-row full-height">
          {/* <ProjectDetails/> */}
          <AreaProductDetails
            configObj={props.configObj}
            areaList={props.selectedAreas}
            selectedCategories={props.selectedCategories}
            allCategories={[]}
            isShowPrice={false}
          />
        </div>
      </div>
      <div className="modal-footer space-between">
        <div><button className="op-btn op-btn-light" onClick={prevHandle}><i className="fa fa-arrow-left color-black"></i> Prev</button></div>
        <div><button className="op-btn op-btn-primary" onClick={createMoodboardFromScratch}><i className="fa fa-save"></i> Review & Confirm</button></div>
      </div>
      
        
    </React.Fragment>
        
  );
};

export default Review;
