import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { logger } from "../../../../utils/methods";
import {
  removeNewBadgeClassFromElement
} from "../../../../utils/UI_methods/global";
import MoodboardActions from "../../../admin/moodboards/moodboardActions/MoodboardActions";

const MoodboardCards: React.FC<{
  configObj: any;
  moodboardList:any
  setCurrProjectDetails:(id:number,name:string)=>void
  setCurrMoodboardId:(id:string)=>void
}> = (props) => {
 
  const [openMoodboardAction, setOpenMoodboardAction] = useState(false);

  const history = useHistory()

  const[currMoodboardDetails,setCurrMoodbaordDetails] = useState(null)


  const[lastEditedId,setLastEditedId] = useState(null)

  useEffect(()=>{
    if(props.moodboardList.length){
      let filters = props.configObj.getModuleFilters("moodboard")
      if(filters?.lastEdited?.length){
        setLastEditedId(filters?.lastEdited)
      } 

      let index = 0
      let item = {}
      for (let i = 0; i < props.moodboardList.length; i++) {
        const moodboard = props.moodboardList[i];
        if(moodboard.moodboard_id === filters.lastEdited){
          index = i
          item = moodboard
        }
      }

      if(index){
        props.moodboardList.splice(index,1)
        props.moodboardList.splice(0,0,item)
      }
    }
   
  },[props.moodboardList])



  function cardClickHandle(moodboard: any) {
    // let productId = product.product_id;
    // updateRecentActivities("products", product);
    setOpenMoodboardAction(true);
    // setCurrProductId(productId);

    setCurrMoodbaordDetails(moodboard)
    logger?.info("collections","viewdetails",`Viewed collection: ${moodboard.moodboard_id}`)

    // gotomoodboard(moodboard.moodboard_id,moodboard?.project_id)
  }


  return (
      <React.Fragment>
  
  <IonModal  onDidDismiss={() => setOpenMoodboardAction(false)} isOpen={openMoodboardAction}
      id="projectActions"
      cssClass='product-actions-modal'>
          <MoodboardActions
            configObj={props.configObj}
            setOpenMoodboardAction={setOpenMoodboardAction}
            setCurrMoodboardId={props.setCurrMoodboardId}
            setCurrProjectDetails={props.setCurrProjectDetails}
            currMoodboardDetails={currMoodboardDetails}
          />
      </IonModal>

    {props.moodboardList?.map((moodboard:any,index:number)=>{

       return (
          <div className={lastEditedId == moodboard.moodboard_id?"recent-item-container project-container white-card no-shadow":"project-container white-card no-shadow"} id={`moodboardCard_${moodboard.moodboard_id}`} key={index}>
            <div className="project-detials-container">
              <div className="image"  onClick={()=>{
                  cardClickHandle(moodboard)
                }}>
                  <img src="assets/images/placeholder.png" className="" alt="" />
              </div>

              <div className="other-details border-top padding10 display-flex-column">
                  <div> <i className="fa fa-user-circle color-dark" area-hidden="true" /><span className="heading3 padding-start">{moodboard.project_name}</span></div>
                  <div> <i className="fa fa-compass color-dark" area-hidden="true" /><span className="heading4 padding-start">{moodboard.name}</span></div>
              </div>
            </div>
          </div>
       )
     })}
      <div className="project-container temp">
      </div>
      <div className="project-container temp">
      </div>
      <div className="project-container temp">
      </div>
      <div className="project-container temp">
      </div>
      </React.Fragment>
  )
}

export default MoodboardCards;
