import $ from "jquery"
import { showToast } from "../../UI_methods/global"
import { set3dControls, setSpacePlannerControls } from "../controls"
import { getObjectDimensionPosition } from "../modules/helper"
import { CURR_SELECTED_PRODUCT } from "../raycasting"
import { IS_SPACE_PLANNER_MODE, composer, controls, customizerConfig, deselectProduct, disableSpacePlanner, enableKitchenPlannerMode, isKitchenPlannerMode, labelRenderer, orthoCamera, renderer, switchCameraArea, updateAreaCustomizer, wallColorCustomization } from "./area3dModel"
import { currSelectedWallForElevationView, resetCurrSelectedWallForElevationView, resetWalls, setCurrSelectedWallForElevationView } from "./floorplan/floorplanUI"
import { getAreaFromPoint, getAreaNamesFromWall, getFrontAndBackPlane, setInitialWallColor } from "./walls"
import { closeControlsPanelAndHideProducts, showColorSwatch } from "./UI_methods"

export var setWallColorMode = false
export var isSelectWallForElevationView = false
let currAreaName = null
let areaNames = []

export function enableSelectWallForElevationView() {
    if(!customizerConfig.isProductsLoaded){
        showToast("Please wait until products are loading",4000,"error")
        return
    }
    currAreaName = null
    deselectProduct()
    isSelectWallForElevationView = true
    $(".hide-in-elevation-view").addClass("display-none")
    $(".show-in-elevation-view").removeClass("display-none")
    setWallColorMode = true
    showToast("Wall selection activated",2000)
}

export function enableElecationViewForProduct(){
    // deselectProduct()
    if(CURR_SELECTED_PRODUCT.userData.snappedWall){
        $(".hide-in-elevation-view").addClass("display-none")
        $(".show-in-elevation-view").removeClass("display-none")
        $("#selectWallHintElevationView").addClass("display-none")
        setCurrSelectedWallForElevationView(CURR_SELECTED_PRODUCT.userData.snappedWall)
        let center = getObjectDimensionPosition(null,CURR_SELECTED_PRODUCT).center
        let areaName = getAreaFromPoint(center)
        if(areaName){
            confirmWallForElevationView(areaName)
        }
        deselectProduct()
    }else{
        showToast("No wall detected",2000,"error")
    }

}

 
export function disableSelectWallForElevationView() {
    closeControlsPanelAndHideProducts()
    $(".hide-in-elevation-view").removeClass("display-none")
    $(".show-in-elevation-view").addClass("display-none")
    isSelectWallForElevationView = false
    // resetWalls()
    resetCurrSelectedWallForElevationView()
    disableSelectedWall()
    // removeBoxHelper()
    if(!isKitchenPlannerMode){
        updateAreaCustomizer("all")
    }
    $("#flipWallButtonElevationView").addClass("display-none")
    if(isKitchenPlannerMode){
        enableKitchenPlannerMode(false)
    }
    deselectProduct()
    composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    setWallColorMode = false
}

     
export async function confirmWallForElevationView(areaName:string = "") {
    if(!currSelectedWallForElevationView){
        showToast("No wall selected",2000)
        return
    }
    setInitialWallColor()
    areaNames = getAreaNamesFromWall(currSelectedWallForElevationView)
    if(areaName){
        areaNames = [areaName]
    }
    if(areaNames.length){
        currAreaName = areaNames[0]
    }else{
        return 
    }
    if(!isKitchenPlannerMode){
        updateAreaCustomizer(currAreaName)
    }
    await gotoElevationView(currAreaName)    
    resetWalls()
    composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    isSelectWallForElevationView = false
    $("#confirmWallButtonElevationView").addClass("display-none")
    if(currAreaName){
        $("#flipWallButtonElevationView").removeClass("display-none")
    }
    // showColorSwatch()
}



async function gotoElevationView(areaName:string) {
    let plane = getFrontAndBackPlane(currSelectedWallForElevationView,areaName).FrontPlane
    if(plane){
        let normal = plane?.userData?.normal
        let center = getObjectDimensionPosition(null,plane)?.center
        await controls.setPosition(center.x + normal.x * 3,center.y,center.z + normal.z * 3,false)
        // switchCameraArea(controls,orthoCamera)
        controls.setTarget(center.x,center.y,center.z,false)
        controls.fitToBox( plane, false, { paddingTop:1, paddingLeft:1, paddingBottom:1, paddingRight:1 },false )
        // setSpacePlannerControls(controls)
    } 
}

export function flipWallElevationView() {
    if(areaNames.length === 1){
        showToast("This wall belongs to no other room",2000)
        return
    }
    disableSelectedWall()
    let currIndex = areaNames.indexOf(currAreaName)
    if(currIndex === 0){
        currAreaName = areaNames[1]
    }else{
        currAreaName = areaNames[0]
    }
    if(!isKitchenPlannerMode){
        updateAreaCustomizer(currAreaName)
    }
    gotoElevationView(currAreaName)
   
}


export function updateWallColor(data:any) {
    if(currSelectedWallForElevationView){
        let color = `0x${data.hex_code}`
        currSelectedWallForElevationView.material.color.setHex(color)
    }else{
        wallColorCustomization.updateColor(data)
    }
}


export function disableSelectedWall() {
	disableSpacePlanner()
	deselectProduct()
	set3dControls(controls)
}

export function hideWallSelectionHint() {
    $("#selectWallHintElevationView").addClass("display-none")
}