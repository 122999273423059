import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useEffect } from "react";
import { getThumbnailsUrlKey } from "../../../../utils/cache/cache";
import { displayUploadedImage } from "../../../../utils/inventory/inventory";
import { getFilteredArray } from "../../../../utils/methods";
import { getProductImageUrl } from "../../../../utils/moodboard/helper";
import { setUploadedImage } from "../../../../utils/UI_methods/global";

const Review: React.FC<{
  configObj: any;
  setCurrState: (value: string) => void;
  closeModal: (value: boolean) => void;
  save:(...values)=>void
  Details:any
  formData:any
  module:string
}> = (props) => {



  useEffect(()=>{
    if(props.formData.productImageFile){
      displayUploadedImage(props.formData.productImageFile,"uploadedImage")
    }else{
      //If no file selected display from cache if exists for the existed product
      let imageUrlKey = ""
      if(props.module==='finish'){
        imageUrlKey = getThumbnailsUrlKey(props.formData?.companyName,props.formData?.collectionName,props.formData?.materialCode)
      }
      if(props.module==="product"){
        let category = getFilteredArray(props.configObj?.data.categories,"id",props.formData?.categoryId)
        let subCategory = getFilteredArray(props.configObj?.data.subCategories,"id",props.formData?.subCategoryId)
        imageUrlKey = getProductImageUrl(props.configObj?.BASE_URL_ASSETS,props.configObj?.clientName,props.formData.productName,category[0]?.category_name,subCategory[0]?.sub_category_name)
      }
      setUploadedImage(imageUrlKey)
    }
  },[props.formData])


    

  function Header() {
    return (
      <div className="modal-header">
        <div className="center-aligned-row">
          <strong>Review</strong>
        </div>
        <span
          className="right-middle"
          onClick={() => {
            props.closeModal(false);
          }}>
          <IonIcon icon={close} ios={close} className="font-large"></IonIcon>
        </span>
      </div>
    );
  }

 

  function Footer() {
    return (
      <div className="modal-footer">
        <div className="full-width display-flex-row space-between">
          <div>
            <button
              className="op-btn op-btn-light"
              onClick={() =>{
                if(props.module==="finish"){
                    props.setCurrState("Finish")
                }
                if(props.module==="product"){
                    props.setCurrState("Product")
                }
              }}>
              Back to form
            </button>
          </div>
          <div>
            <button className="op-btn op-btn-primary" onClick={props.save}>Confirm</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {props.module==="product"?<Header />:null}
      <props.Details/>
      {props.module==="product"?<Footer />:null}
    </React.Fragment>
  );
};

export default Review;
