import $ from "jquery";
import React, { useState } from "react";
import { hideComponentLoader, showAlert, showComponentLoader, showGlobalToast } from "../../../../../../../../utils/UI_methods/global";
import { applicationConfig, getRandomString, waitFor } from "../../../../../../../../utils/methods";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import { Customer } from "../../../../../../../../utils/store/storeConfiguration";
import Modal from "../../../../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../../../../common/ui-components/buttons/Button2";
import Form from "./Form";
import { getTodayTimestampOnlyDate } from "../../../../../../../../utils/store/crm/calendar";
 
const CreateTicket: React.FC<{
  isOpen:boolean
  setIsOpen:(values:any)=>void
  customer:Customer
  createTicketCallback:()=>void
  ticket?:any
}> = (props) => {



  const[alertId] = useState("alertCreateTicket")

  var salesOrderImage = null

  

  async function submitHandle() {

    const issueType = $("#issueType").val()
    const formData = {
        customerId:Number(props.customer.id),
        title:$("#ticketTitle").val(),
        ticketNo: props.ticket?props.ticket.ticket_no:getTicketNumberFromTicketType(issueType),
        address:$("#cityName").val() || "",
        soNumber:$("#ticketSoNumber").val() || "",
        issueType:$("#issueType").val(),
        description:$("#ticketDescription").val(),
        priority:$("#ticketPriority").val(),
        remarks:$("#ticketRemarks").val() || "",
        createdAt:Number(getTodayTimestampOnlyDate())
    }


    if(formData.description && formData.customerId  && formData.issueType){
      showComponentLoader("storeLoader")
      CRM.crmApi.ticket.create(applicationConfig?.user?.userId,formData).then(async (data:any)=>{
        if(!data.data.error){
            // setIsOpenCreateLead(false)
            props.createTicketCallback()
            if(salesOrderImage){
              uploadSalesOrderImage(salesOrderImage,formData.ticketNo)
            }
            await waitFor(800)
            hideComponentLoader("storeLoader")
            showGlobalToast("Ticket created succesfully",3000)
        }else{
          showAlert(alertId,"Internal server erro","error")
          hideComponentLoader("storeLoader")
        }
      })
    }else{
      showAlert(alertId,"Please enter mandatory fields","error")
      hideComponentLoader("storeLoader")
    }
  }

  function setSalesOrderImage(value:any) {
    salesOrderImage = value
  }

  async function uploadSalesOrderImage(file:any,ticketNo:string) {
    let fileName = "receipt" + ".png"
    let albumName = "public"
    var albumPhotosKey = encodeURIComponent(albumName) + "/estre/customer-tickets/" + ticketNo + "/receipt/"  
    await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,file,fileName,true)
  }

  
  function Footer() {
    return(
      <div className="display-flex-row">

      <div className="center-aligned-column">
        <Button2
          configObj={{id:""}}
          action={submitHandle}
          label={"Confirm"}
          fontAwesomeIcon={"fas fa-plus color-dark"}
          iconPos=""
          classNames={"shadow margin0 button- bg-color-primary"}
        />
      </div>
 
      </div>
    )
  }

  function getTicketNumberFromTicketType(issueType:any){
    let charSet = "123456789"
    if(issueType === "Update on order"){
      return `U-${getRandomString(6,charSet)}`
    }else if(issueType === "Installation"){
      return `I-${getRandomString(6,charSet)}`
    }else{
      return `O-${getRandomString(6,charSet)}`
    }
  }

  return (
    <div className="full-width position-relative display-flex-row space-between border-bottom bg-color-light calendar-header" style={{padding:"10px 0",height:"fit-content"}}>


        <Modal
          id=""
          classNames="medium-y-modal"
          onDismiss={()=>props.setIsOpen(false)}
          isOpen={props.isOpen}
          Content={()=>(
            <Form setSalesOrderImage={setSalesOrderImage} alertId={alertId} ticket={props.ticket} customer={props.customer}/>
          )}
          heading={`Create ticket`}
          FooterContent={()=>(<Footer></Footer>)}
          >
        </Modal>

        <div className="middle">
          {/* <ToggleView setIsShowCalendar={props.setIsShowCalendar}/> */}
        </div>

        {/* <div className="display-flex-row">
          <SearchCustomer setSearchedCustomerList={props.setSearchedCustomerList}/>
        </div> */}

      </div>  
  );
};

export default CreateTicket;
