import $ from "jquery";
import React, { useEffect, useState } from "react";
import { addActiveClassOnMaterial, displayThumbnails, hideComponentLoader, removeActiveClassFromAllTextures, removeClassFromElement, showComponentLoader } from "../../../../utils/UI_methods/global";
import { createBounceEffectEvents } from "../../../../utils/UI_methods/ui_effects";
import AwsConfig from "../../../../utils/aws-sdk/AwsConfig";
import { untarAndSaveToCache } from "../../../../utils/cache/cache";
import { updateDeviceElementsVisibility } from "../../../../utils/customizein3d/common";
import { dragStart, getValuesFromMaterialType } from "../../../../utils/customizein3d/modules/helper";
import { addDragEventListener } from "../../../../utils/customizein3d/modules/raycasting";
import { isTouchDevice } from "../../../../utils/customizer/helper";
import { applicationConfig, getArrayOfDistinctValues, getCapitalizeString, getFilteredArray, getObjectByParameter, getRandomString, getSubDomainName, getUser, waitFor } from "../../../../utils/methods";
import Button2 from "../../ui-components/buttons/Button2";
import DropDown from "../../ui-components/filters/DropDown";
import MaterialSearch from "../../ui-components/search/MaterialSearch";
import Loader from "../main-ui/Loader";
import Modal2 from "../Modals/Modal";
import FinishDetails from "../../../admin/products/FinishDetails";
import { STORE } from "../../../../utils/store/storeConfiguration";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../../../../utils/cache/localstorage";
import Modal from "../Modals/Modal";
import { productViewerInit } from "../../../../utils/customizein3d/productViewer/productViewer";
import ProductViewer from "../../../admin/products/productviewer/ProductViewer";
import { enableModelViewer, updateModelViewerFinish } from "../../../../utils/customizein3d/webxr";
import { TextureInfo } from "../../../../utils/customizer/ProjectConfiguration";
  
  const Materials: React.FC<{
    materials:any
    action:(event:any)=>void
    moduleName:string
    isViewMode?:boolean
  }> = (props) => {
  
  
    let awsConfig = new AwsConfig()
  
    const[textureLoaderId] = useState("texturesLoader")
  
    const[materialTypesMaterials,setMaterialTypesMaterials]:any = useState([])
    const[materials,setMaterials]:any = useState([])
    const[isSearchMode,setIsSearchMode]:any = useState(false)
    const[collections,setCollections]:any = useState([])
    const[companies,setCompanies]:any = useState([])
    const[currSelectedMaterialType,setCurrSelectedMaterialType] = useState<string>("")
    const[currSelectedCompany,setCurrSelectedCompany] = useState<string>("")
    const[currSelectedCollection,setCurrCollectionName] = useState<string>("")
    const[refresh,setRefresh] = useState(Number(new Date()))
    const [isOpenFinishDetails,setisOpenFinishDetails] = useState<any>(null);
    const [currFinish,setCurrFinish] = useState<any>(null);


    const [isCollectionSelected,setIsCollectionSelected] = useState(false);
    const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);
  
    // useEffect(()=>{
    //   addEventListenerOnImages(".finish-image")
    // },[materialTypesMaterials])
  
    useEffect(()=>{
      if(props.materials){
        let allMaterialTypes:any = getArrayOfDistinctValues(props.materials,"material_type")
        // allMaterialTypes.splice(allMaterialTypes.indexOf("Default"),1)
        setCurrSelectedMaterialType(allMaterialTypes[0])
        setIsCollectionSelected(false)
        setCurrSelectedCompany("")
        setCurrCollectionName("")
      }
  
    },[props.materials])

    useEffect(()=>{
      if(materials.length){
        setTimeout(() => {
          addDragEventListener()
          updateDeviceElementsVisibility()
        }, 500);
      }
      removeActiveClassFromAll()
    },[materials])
  
  
  
    //Untar file for all collections
    useEffect(()=>{
      if(currSelectedMaterialType?.length){
        // let filteredMaterials = getFilteredArray(props.materials,"material_type",currSelectedMaterialType)
        setMaterialTypesMaterials(props.materials)
        let companyNames:Array<any> = getArrayOfDistinctValues(props.materials,"company_name")
        setCompanies(companyNames)
        if(companyNames.includes("Mittal")){
          setCurrSelectedCompany("Mittal")
        }else{
          if(STORE.isKanvasMode){
            let collection = getItemFromLocalStorage("currCollection")
            let companyName = collection.companyName
            setCurrSelectedCompany(companyName)
          }else{
            setCurrSelectedCompany(companyNames[0])
          }
        }

        

        
        removeActiveClassFromAll()
      }
    },[currSelectedMaterialType,refresh,props.materials])

    function getDefaultCollection(allMaterials:any,allCollections:any) {
      let result = allCollections[0]
      if(applicationConfig.clientName === "DashSquare"){
        let material = getObjectByParameter(allMaterials,"material_type","Leather")
        if(material){
          result = material.collection_name
        }
      }
      return result
    }

    //SET collectiosn
    useEffect(()=>{
      if(currSelectedCompany?.length && materialTypesMaterials.length){
        let filteredArray = getFilteredArray(materialTypesMaterials,"company_name",currSelectedCompany)
        let allCollections:any = getArrayOfDistinctValues(filteredArray,"collection_name")
        setCollections(allCollections)
        if(allCollections.length === 1){
          setCurrCollectionName(allCollections[0])
          setIsCollectionSelected(true)
        }
        STORE.currSelectedCompany = currSelectedCompany
      }
    },[currSelectedCompany,materialTypesMaterials])

    useEffect(()=>{
      let collection = getItemFromLocalStorage("currCollection")
      if(collection){
        let collectionName = collection.collectionName
        let companyName = collection.companyName
        setCurrCollectionName(collectionName)
        setCurrSelectedCompany(companyName)
        setIsCollectionSelected(true)
      }
    },[materialTypesMaterials])

    //set materials and load thumbnails
    useEffect(()=>{
      if(currSelectedCompany != "" && currSelectedCollection!= "" && materialTypesMaterials.length){
        let array = materialTypesMaterials.filter(material=> 
          material.company_name === currSelectedCompany && 
          material.collection_name.toLowerCase() === currSelectedCollection.toLowerCase()
        )
        setMaterials(array)
      }
    },[currSelectedCompany,currSelectedCollection,materialTypesMaterials])

    useEffect(()=>{
      if(materials.length){
        setTimeout(() => {
          loadAndDisplayThumbails()
        }, 500);
      }
    },[materials])



    async function loadAndDisplayThumbails(){
      showComponentLoader(null,"materials-loader")
      let key = awsConfig.getTarFileKey("thumbnails",{companyName:currSelectedCompany,collectionName:currSelectedCollection})
      await untarAndSaveToCache(awsConfig,`thumbnails/OVL/${currSelectedCompany}/${currSelectedCollection}`,key)
      displayThumbnails(textureLoaderId,null)
      await waitFor(3000)
      hideComponentLoader(textureLoaderId)
    }
  
  
    function filterMaterialsWithMaterialType(value:string){
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setCurrSelectedMaterialType(value)
    }
 
  
    function filterMaterialsWithCollection(value:string){
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setCurrCollectionName(value)
    }
  
    function filterMaterialsWithCompany(value:string){
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setCurrSelectedCompany(value)
    }
  
  
    

    function removeActiveClassFromAll(){
      removeActiveClassFromAllTextures()
      $(".texture-label-container").removeClass("--is-active")
    }

    function activateSearchMode(materials:Array<any>,searchString:string) {
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setMaterials(materials)
      setIsSearchMode(true)
    }

    function disableSearchMode() {
       setIsSearchMode(false)
       setMaterials(props.materials)
       setRefresh(Number(new Date()))
       $(".material-search-input").val("")
    }

    function openCollectionHandle(collection:string){
      setCurrCollectionName(collection)
      setIsCollectionSelected(true)
    }

    function closeCollection(){
      setCurrCollectionName("")
      setIsCollectionSelected(false)
      addItemToLocalStorage("currCollection",null)
    }

    function onErrorHandle(event:any){
      event.target.src = `https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/icon/icon.png` 
      $(event.target).css("opacity","0.05")
      $(event.target).css("width","50px")
      $(event.target).css("height","50px")
    }
    function Collections(){
      return(
        <div className="marginTop10 display-flex-row">
          {collections.map((collection:any,index:number)=>{
            return(
              <div onClick={()=>{openCollectionHandle(collection)}} className="pointer" key={index} style={{width:"33%",padding:"5px",height:"11rem"}}>
                <div className="white-card border center-aligned-column position-relative shadow-light full-height-width">
                  <img style={{objectFit:"cover",opacity:"0.9",height:"calc(100% - 2rem)"}} 
                  onError={(e:any) => { onErrorHandle(e) }}
                  src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/ThumnailsCover/${currSelectedCompany}/${getCapitalizeString(collection)}.webp`} className="full-width" alt="" />
                  <div style={{height:"2rem"}} className="padding5 bg-color-light border-top center-aligned-column full-width text-center font-md-small heading3">
                    {collection}
                  </div>
                </div>
                  
              </div>
            )
          })}

        </div>
      )
    }


    function MaterialSearchWindow(){
      return(
        <>
        <div id="searchWrapperMaterial" className="full-height-width  material-wrapper-mobile bg-color-white" style={{position:"fixed",zIndex:10005}}>
        <MaterialSearch
            configObj={applicationConfig}
            currSelectedMaterialType={currSelectedMaterialType}
            activateSearchMode={activateSearchMode}
            filterMaterialWithMaterialType={filterMaterialsWithMaterialType}
            filterMaterialWithCollection={filterMaterialsWithCollection}
            idSuffix={"materialsPanelForMobile"}
        />

        <div className="bottom-middle ion-margin-bottom">
          <Button2
            configObj={{id:""}}
            action={()=>removeClassFromElement("searchWrapperMaterial","--is-active")}
            label={"Close"}
            fontAwesomeIcon={"fas fa-close color-black"}
            iconPos="left"
            classNames={"shadow-light bg-color-light"}
          />
        </div>
      </div>
        </>
      )
    }

    function openMaterial(event:any,material:any){
      event?.preventDefault()
      event?.stopPropagation()
      setCurrFinish(material)
      setisOpenFinishDetails(true)
    }


    function closeProductViewer() {
      setIsOpenViewProduct(false)
      hideComponentLoader("canvasLoader")
    }
  
    async function productViewerHandle(material:any){
      let texture = new  TextureInfo(material)
      setIsOpenViewProduct(true)
      let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/kanvas/defaultmodel/Linda_ThreeSeater.glb`
      await waitFor(500)
      enableModelViewer(url,"productViewerMaterialView",texture)
    }

   
    return (
      <React.Fragment>


        {props.isViewMode?
        <Modal
          isOpen = {isOpenViewProduct}
          Content = {()=>(
            <ProductViewer
              id="productViewerMaterialView"
            />
          )}
          onDismiss = {closeProductViewer}
          heading={"Product viewer"}
          classNames="medium-y-modal"
          id=""
          FooterContent={()=>(<></>)}
        />
        
        :null}

      {!isCollectionSelected?
      <div className="materials-filters-wrapper full-height-width ">
            
          <MaterialSearchWindow/>
            
            {!applicationConfig.isLiveClient?
            <DropDown
              classNames="hide-in-desktop display-none"
              columns={1}
              values={getArrayOfDistinctValues(props.materials,"material_type")}
              currSelectedValue={currSelectedMaterialType}
              action={filterMaterialsWithMaterialType}
              label="MaterialType"
            />
            :null}

            <div className="product-material-dropdown-wrapper overflow-y-scroll padding5 full-height-width" style={{margin:"0.5rem 0"}}>
              <DropDown
                classNames="storefront-dropdwon"
                columns={1}
                values={companies}
                currSelectedValue={currSelectedCompany}
                action={filterMaterialsWithCompany}
                label="Company"
              />
              <Collections/>
              </div>
            :
      </div>
      
      :null}
      
        
        {isCollectionSelected?
        <div className="full-height-width">


            <Modal2
              id=""
              heading="Finish Details"
              isOpen={isOpenFinishDetails} 
              classNames={"large-x-modal"}
              onDismiss={setisOpenFinishDetails}
              Content={()=>(
                <FinishDetails
                configObj={applicationConfig}
                currfinish={currFinish}
              />
              )}
              FooterContent={()=>(
                <></>
              )}
            >
              
          </Modal2>

          <Loader 
            className="fade-background-loader materials-loader"
            id={textureLoaderId}
            data={{}}
            loaderType="dots"
          />

          {!props.isViewMode?
            <div className="full-width position-relative" style={{height:"3rem"}}>

              {collections.length === 1 && getSubDomainName().includes("Kanvas") &&  getUser().isShareMode?
                null
              :
                <div className="left-middle">
                  <Button2
                    configObj={{id:""}}
                    action={closeCollection}
                    label={"All Collections"}
                    fontAwesomeIcon={"fas fa-chevron-left color-danger"}
                    iconPos="left"
                    classNames={"no-shadow bg-color-black margin0 button-ex-small"}
                  />
                </div>}

              

                
                <div className={`display-flex-row no-wrap ${collections.length === 1 && getSubDomainName().includes("Kanvas") &&  getUser().isShareMode ? "middle ":"right-middle " }`}>
                  <div className="center-aligned-column marginleft5">
                    <span className="heading3 color-black">{currSelectedCompany}</span>
                  </div>
                  <div className="center-aligned-column marginleft5">
                    <span className="heading3 color-black">/</span>
                  </div>
                  <div className="center-aligned-column marginleft5">
                    <span className="heading3 color-danger">{currSelectedCollection}</span>
                  </div>
                </div>

              

             

            </div>

            
            
          :null}

          

          <div className={`full-width overflow-y-scroll ${props.isViewMode?"margin5":null}`} style={{paddingBottom:"10rem",height:`calc(100% - ${props.isViewMode?"0.011px":"3rem"})`}}>

              <div className="materials-search-wrapper full-width">
                <MaterialSearch
                  configObj={applicationConfig}
                  currSelectedMaterialType={currSelectedMaterialType}
                  activateSearchMode={activateSearchMode}
                  filterMaterialWithMaterialType={filterMaterialsWithMaterialType}
                  filterMaterialWithCollection={filterMaterialsWithCollection}
                  idSuffix={"materialsPanel"+getRandomString(5)}
                />
              </div>

            {isSearchMode?
            <div className="marginTop5">
              <Button2
                configObj={{id:""}}
                action={disableSearchMode}
                label={"go back"}
                fontAwesomeIcon={"fas fa-chevron-left color-danger"}
                iconPos="left"
                classNames={"shadow-light bg-color-light button-small"}
              />
            </div>
            :null}

            <div id="materialsWrapper" className="materials-wrapper display-flex-row" style={{paddingBottom:"55rem"}}>
              
              {materials?.map((material: any, index: number) => {
                // let materialUrl = getMaterialsImageUrl(props.configObj?.BASE_URL_MODEL_VIEWER,material.company_name,material.collection_name,material.material_code)
                let defaultValues = getValuesFromMaterialType(material.material_type)
                let materialName = material.material_name.replace(material.collection_name,"")
                materialName = material.material_name.replace(material.collection_name.toUpperCase(),"")
                materialName = materialName.replace("-","")
                materialName = materialName.replace("_","")
                return (
                    <div className={`texture-label-container material-container finish-image-container border ${material.material_code.toLowerCase().includes("roughness")?" display-none":""}`}
                    data-company-name={material.company_name}
                    data-collection-name={material.collection_name}
                    data-material-id={material.id}
                    data-material-code={material.material_code}
                    data-material-type={material.material_type}
                    data-uv-scale={material.uv_scale}
                    data-roughness-value={material.roughness}
                    data-metalness-value={defaultValues.metalnessValue}
                    data-transmission-value={material.transmission}
                    data-specular-value={material.specular}
                    data-clearcoat-value={material.clearcoat}
                    data-sheen-value={material.sheen}
                    id={`Finish_${material.company_name}_${material.collection_name}_${material.material_code}`}
                    data-object-type="foreground"
                    onDragStart={dragStart}
                    draggable="false"
                    onClick={(event) => {
                      if(props.isViewMode){
                        openMaterial(event,material)
                      }else{
                        props.action(event)
                        addActiveClassOnMaterial(event)
                      }

                      
                    }} key={index}
                    >
                      {/* for mobile drag finish */}
                  

                  <div className="texture">
                    <div className="texture-name-mobile bottom-middle center-aligned-column">{material.material_code}</div>
                      <img
                        className="texture-img"
                      />
                  </div>

                  <div className="top-right hide-in-mobile pointer-all" style={{padding:"0.7rem",zIndex:1}}>
                      <div className="pointer-all" >
                        <i className="fas fa-info-circle font-small color-white" onClick={(event)=>{{
                        openMaterial(event,material)
                      }}}
                        ></i>
                      </div>
                  </div>

                  {/* Drag icon for mobile */}
                  {props.moduleName==="customizein3d" && !isTouchDevice()?
                    <div className="top-right padding0 display-none pointer-all zindex30 hide-in-desktop touch-device-element" >
                      <div className="finish-drag-icon pointer-all">
                        <i className="fas fa-grip-vertical font-medium color-dark"></i>
                      </div>
                    </div>
                    :null}


                    <div className="display-flex-row no-wrap space-between">

                      <div className="texture-details">
                        <div className={`detail heading text-ellipsis ${props.isViewMode?" heading2 font-normal":""}`}>{materialName}</div>
                        <div className="detail">Company: {material.company_name}</div>
                        <div className="detail">Collections: {material.collection_name}</div>
                      </div>

                      {props.isViewMode?
                      <div className="padding5" style={{marginRight:"0.7rem",marginTop:"-10px",zIndex:2}}>
                        <Button2
                          configObj={{id:""}}
                          action={()=>{productViewerHandle(material)}}
                          label={"3D"}
                          fontAwesomeIcon={"fas fa-cube color-dark"}
                          iconPos="left"
                          classNames={"shadow-light button-small"}
                          isPreventDefault={true}
                        />
                      </div>
                      :null}

                    </div>

                  
                </div>       
                );
              })}
            </div>



          </div>

          

         

          
        
        </div>


        
        :null}

        
  
        
  
      </React.Fragment>
    );
  };
  
  export default Materials;
  