import React, { useState } from "react";
import { STORE, SofaConfiguration } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import CustomVariantMeasurements from "../product-customizer/measurements/CustomVariantMeasurements";
import ProductVariantMeasurements from "../product-customizer/measurements/ProductVariantMeasurements";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";

 

const SetMeasurement: React.FC<{
  productConfiguration:SofaConfiguration
  confirmHandle:()=>void
}> = (props) => {

  const[isCustomVariant] = useState(props.productConfiguration?.isCustomVariant)

  function confirm() {
    props.confirmHandle()
    showGlobalToast("Measurement Set")
  }


    return (
    <div className="full-height-width  bg-color-light">
      <div className="header-content center-aligned-column heading1 store-color-primary">Set Measurements</div>
      <div className="body-content center-aligned-column">
        {isCustomVariant?
        <CustomVariantMeasurements product={STORE.currProduct} configuration3D={null}/>
        :
        <ProductVariantMeasurements productConfiguration={props.productConfiguration} product={STORE.currProduct}/>
        }
      </div>
      <div className="footer-content center-aligned-column">
        <Button2
          configObj={{id:""}}
          action={()=>{confirm()}}
          label={"Confirm"}
          fontAwesomeIcon={"fas fa-cart-plus color-white"}
          iconPos=""
          classNames={"no-shadow margin0 bg-color-warning"}
        />
      </div>
      
    </div>
  );
};

export default SetMeasurement;
