import $ from "jquery"
import { checkFromCache } from "../cache/cache"
import { foregroundParts } from "../data"
import {
  compareArrays,
  disposeVariables,
  getCategoryName,
  getCurrentConfigurationFromClass, getCurrentConfigurationKey,
  getMaterialTypes, getObjectNamesArray, getPartName, getProductName, getProductPartConfigKey,
  setCurrentConfigurationInLocalStorage
} from "../methods"



export async function captureScreenshot() {
  return new Promise(async (resolve,reject)=>{
    try {
      let count:number = 0
      let canvases = document.querySelectorAll(".canvas")
      let screenshotCanvas:any = document.getElementById("screenshotCanvas")!
      let screenshotCtx = screenshotCanvas?.getContext("2d")
      let currCanvas:any = null
      let currObject:string = ""
      //First clear reactagle
      screenshotCtx?.clearRect(0, 0, screenshotCanvas.width, screenshotCanvas.height);
      //First draw background 
      for (let i = 0; i < canvases.length; i++) {
        currCanvas = canvases[i];
        currObject = currCanvas.getAttribute("data-object-part-name")
        if(currObject==="Background"){
          count = count + 1
          // screenshotCtx.drawImage(currCanvas,0,0)
          await screenshotCtx?.drawImage(currCanvas, 0,0, currCanvas.width, currCanvas.height, 0,0,screenshotCanvas.width, screenshotCanvas.height);
          if(count===Number(canvases.length)-1){
            resolve("done")
          }
        }
      }


      //Rest of the products
      for (let i = 0; i < canvases.length; i++) {
        currCanvas = canvases[i];
        currObject = currCanvas.getAttribute("data-object-part-name")
        if(currObject!="Background"){
          count = count + 1
          // screenshotCtx.drawImage(currCanvas,0,0)
          await screenshotCtx.drawImage(currCanvas, 0,0, currCanvas.width, currCanvas.height, 0,0,screenshotCanvas.width, screenshotCanvas.height);
          if(count===Number(canvases.length)-1){
            disposeVariables([canvases,screenshotCanvas,screenshotCtx,currCanvas,currObject])
            resolve("done")
          }
        }
      }

    } catch (error) {
      reject(error)
    }
  })
   
}

export async function downloadScene() {
  await captureScreenshot()
  let canvas:any = document.getElementById("screenshotCanvas")!
  let link = document.createElement("a");
  document.body.appendChild(link);
  link.download = "html_image.png";
  link.href = canvas.toDataURL("image/png");
  link.target = '_blank';
  link.click();
}


export function setScreenshotCanvasAspectRatio() {
    try {
        let screenshotCanvas:any = document.getElementById("screenshotCanvas")!
        // let width = $("#imageContainer").width()
        // let height = $("#imageContainer").height()
        screenshotCanvas.width = 400
        screenshotCanvas.height = 300
        disposeVariables([screenshotCanvas])
    } catch (error) {
        
    }
  
}

export function getAllProductsFromConfig(configuration:any,currentConfigObject:any,viewName:string){
  try {
    let productSet:any = []
    let productsArray:any = []
    for (const config of configuration) {
        let myObj = {productName:config.product_name,productCategory:config.category_name,configuration:[]}
        if(config.view_name===viewName){
            if(!productsArray.includes(JSON.stringify(myObj))){
                productsArray.push(JSON.stringify(myObj))
                productSet.push(myObj)
            }
        }
    }
  
    for (const product of productSet) {
        for (const config of configuration) {
            // if(config.view_name===props.currentConfigObject?.viewName){
                if(config.product_name===product.productName){
                    let myObj = {companyName:config.company_name,collectionName:config.collection_name,materialCode:config.material_code}
                    product.configuration.push(myObj)
                }
            // }   
        }
    }
    disposeVariables([productsArray])
    return productSet
  } catch (error) {
    console.log(error)
    return []
  }
 

}




export function displayRenderImage(elementId:string = "",clientName:string,projectName:string,areaName:string,viewName:string="View1") {
  return new Promise((resolve,reject)=>{
    let imageUrlKey = `sprites/${clientName}/${projectName}/ViewRenders/${areaName}_${viewName}0000.jpg`
    if(elementId===""){
      elementId = imageUrlKey
    }
    let image = document.getElementById(elementId)
    checkFromCache(imageUrlKey)?.then((url:any)=>{
      image?.setAttribute("src",url)
      image?.setAttribute("srcset",url)
      resolve(true)
    }).catch(err=>{
      reject(err)
    })
  })
}



export function getAllProductPartsOfSameType(areaName:string,viewName:string,objectName:string,objectMaterialTypes:any,objectCanvasMapping:any){
    let allElements:any = []
    let partCategory = getCategoryName(objectName)
    let partName = getPartName(objectName)
    let productName = getProductName(objectName)
    let key = getProductPartConfigKey(areaName,viewName,productName,partName,partCategory)
    //This will be used to only append the objects present in the scene
    let objectNamesArray = getObjectNamesArray(objectCanvasMapping)
    //Push clicked one 
    allElements.push(key)
    //Get the material types 
    let clickedElementMaterialTypes: any = [];
    clickedElementMaterialTypes = getMaterialTypes(objectMaterialTypes,partCategory,partName)
    let productParts = foregroundParts[partCategory]
    if(productParts?.length!=0){
      for (const partname in productParts) {
        let otherElementMaterialTypes = getMaterialTypes(objectMaterialTypes,partCategory,productParts[partname])
        let key = getProductPartConfigKey(areaName,viewName,productName,productParts[partname],partCategory)
        if(compareArrays(clickedElementMaterialTypes,otherElementMaterialTypes)){
          if(objectNamesArray.includes(key)){
            allElements.push(key)
          }
        }
      }
    }

    disposeVariables([objectNamesArray,clickedElementMaterialTypes,productParts])
    return allElements
  }
  

  export   function generateCanvasElements(length:number) {
    let canvases = []
    let canvas = null
    for (let i = 0; i < length; i++) {
      canvas = document.createElement('canvas');
      canvas.classList.add('canvas')
      canvas.classList.add('ovl-canvas')

      canvases.push(canvas)
    }
    return canvases
  }

  export function createCanvas(canvasList:Array<any>,index:number,objectName:string){
    let canvas = document.createElement('canvas');
    // let testIndex = document.querySelectorAll(".ovl-canvas").length
    // let canvas = canvasList[testIndex]
    // canvas?.removeAttribute("data-object-part-name")
    canvas.setAttribute("data-object-part-name",objectName)
    canvas.classList.add('canvas')
    canvas.classList.add('ovl-canvas')

    // $(canvas).css("z-index","1")
    // canvas.setAttribute("data-object-part-category",categoryName)
    canvas.width =  getContainerWidth()!
    canvas.height = getContainerHeight()!
    return canvas 
  }
  export async function clearCanvases(canvasList:Array<any>,mainContainer:any) {
    let canvases = document.querySelectorAll(".canvas")
    if(!canvases.length){
      return
    }
    // let tempCanvas:any = document.querySelector(".temp-canvas")
    return new Promise(async (resolve)=>{
      let canvas:any = null
      for (let i = 0; i < canvases.length; i++) {
        canvas = canvases[i]
        await emptyCanvas(canvas)
        if(i===canvases.length-1){
          disposeVariables([canvas]) 
          clearTempCanvases()
          resolve("Done")
        }
      }
    
    })
  
  }

  export async function emptyCanvas(canvas:any) {
    let context:any = null
    context = getContext(canvas)
    await context?.clearRect(0,0,canvas?.width,canvas?.height)
    canvas?.parentNode?.removeChild(canvas);
    disposeVariables([context]) 
  }

  export async function clearTempCanvases() {
    let canvases = document.querySelectorAll(".temp-canvas")
    if(!canvases?.length){
      return
    }
    let canvas:any = null
    let context:any = null
    for (let i = 0; i < canvases?.length; i++) {
      canvas = canvases[i]
      context = getContext(canvas)
      context.clearRect(0,0,canvas?.width,canvas?.height)
    }
    disposeVariables([canvas,context,canvases]) 
    $(".temp-canvas").remove()
  }

  export function removeDomElementsFromRef(domElementsList:Array<any>) {
    for (const elementRef of domElementsList) {
      for (const objectName in elementRef) {
        let element = elementRef[objectName]
        element?.parentNode?.removeChild(element);
        delete elementRef[objectName]
      }
    }
    disposeVariables([domElementsList])
  }


  export const getContext = (canvas:any) => {
    // let canvas:any = document.getElementById(objectName)
    return canvas?.getContext("2d") || null
  }
 export function getContainerWidth(){
    return $('#imageContainer').width()
  }

  export function getContainerHeight(){
    return $('#imageContainer').height()
  }

  // export function isTouchDevice() {
  //   let navigatorObject:any = window.navigator
  //   if(!navigatorObject?.share){
  //     return
  //   }
  //   return ( 'ontouchstart' in window ) || 
  //          ( navigatorObject.maxTouchPoints > 0 ) ||
  //          ( navigatorObject.msMaxTouchPoints > 0 );
  // }

  export function isTouchDevice() {
    let navigatorObject:any = window.navigator
    if(!navigatorObject){
      return false
    }
    const isTouchScreen =
        'ontouchstart' in window ||
        navigatorObject.maxTouchPoints > 0 ||
        navigatorObject.msMaxTouchPoints > 0;

    const isMobileOrTablet =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigatorObject.userAgent);
    return isTouchScreen && isMobileOrTablet;
  }

  export function updateCurrentConfig(allViews:any,currentConfigObject:any,currObjectName:string,projectName:string,areaName:string,companyName:string,collectionName:string,materialCode:string,materialId:any,colorOptionId:any,materialType:string){
    try {
      let objectLabel = getPartName(currObjectName)
      let categoryName = getCategoryName(currObjectName)
      let productName = getProductName(currObjectName)
        //get the key...get the current config..set the current config 
      // let savedConfig = getCurrentConfiguration(key)
      let key = getCurrentConfigurationKey(projectName,areaName)
      let savedConfig = getCurrentConfigurationFromClass(currentConfigObject,key)
      for (const view of allViews) {
        let configKey = getProductPartConfigKey(areaName,view,productName,objectLabel,categoryName)
        //Each view can have different products
        if(savedConfig[configKey]){
          if(colorOptionId){
            savedConfig[configKey].companyName = "Default"
            savedConfig[configKey].colorOptionId =  colorOptionId
            savedConfig[configKey].materialCode = "Default"
            savedConfig[configKey].collectionName =  "Default"
            savedConfig[configKey].materialType =  "Default"

          }
          if(materialId){
            savedConfig[configKey].companyName = companyName
            savedConfig[configKey].materialCode = materialCode
            savedConfig[configKey].materialId =  materialId
            savedConfig[configKey].collectionName =  collectionName
            savedConfig[configKey].materialType =  materialType
          }
        }
      }
      //Set in localstorage
      setCurrentConfigurationInLocalStorage(key,savedConfig) 
      //set in CurrentConfig object
      currentConfigObject.setConfiguration(key,savedConfig)
      disposeVariables([savedConfig])
      //Current config ends here
    } catch (error) {
      console.log(error)
    }
  }

  


  

  export function showLeftSidebar(){
    $(".customize-left-wrapper").removeClass("--is-hidden")
  }

  export function getAreaConfigStatus(areaViewConfigStatusMappning:any,areaName:string){
   
    //check status for current area
     let allViewsConfig = areaViewConfigStatusMappning[areaName]
     for (const object of allViewsConfig) {
       // console.log(object.isSavedConfig)
       if(object.isSavedConfig === false){
         return false
       }
     }
     return true
   }
 
   export function getViewConfigStatus(areaViewConfigStatusMappning:any,areaName:string,viewName:string){
    
     //check status for current area
      let allViewsConfig = areaViewConfigStatusMappning[areaName]
      for (const object of allViewsConfig) {
        // console.log(object.isSavedConfig)
        if(viewName === object.viewName && object.isSavedConfig === false){
          return false
        }
      }
      return true
    }
    
    
    export function getNextView(viewsList:Array<any>,currView:string){
      let currViewIndex:number = 0
      let totalItems:number = viewsList.length
      let nextViewIndex:number = 0
      viewsList.forEach((view:any,index:number) => {
        if(view===currView){
          currViewIndex = index
          return
        }
      });
      nextViewIndex = (currViewIndex + 1) % totalItems 
      return viewsList[nextViewIndex] || viewsList[0]

    }

    
    export function getPrevView(viewList:Array<any>,currView:string){
      let currAreaIndex:number = 0
      let totalItems:number = viewList.length
      let prevViewIndex:number = 0
      viewList.forEach((viewName:any,index:number) => {
        if(viewName===currView){
          currAreaIndex = index
          return
        }
      });
      prevViewIndex = (currAreaIndex - 1) % totalItems 
  
      if(prevViewIndex == -1){
        prevViewIndex = totalItems -1
      }
      return viewList[prevViewIndex] || viewList[viewList.length-1]
    }
  

    export function getNextArea(areaList:Array<any>,currArea:string,moduleName:string){
      let currAreaIndex:number = 0
      let totalItems:number = areaList.length
      let nextAreaIndex:number = 0
      areaList.forEach((area:any,index:number) => {
        let areaName = area.area_name || area
        if(areaName === currArea){
          currAreaIndex = index
          return
        }
      });
      if(currAreaIndex+1 === areaList.length && moduleName === "customizer"){
        return {area_name:"all"}
      }

      nextAreaIndex = (currAreaIndex + 1) % totalItems 
     
      return areaList[nextAreaIndex]
    }

    export function getPrevArea(areaList:Array<any>,currArea:string,moduleName:string){
      let currAreaIndex:number = 0
      let totalItems:number = areaList.length
      let prevAreaIndex:number = 0
      areaList.forEach((area:any,index:number) => {
        let areaName = area.area_name || area
        if(areaName === currArea){
          currAreaIndex = index
          return
        }
      });
      prevAreaIndex = (currAreaIndex - 1) % totalItems 
  
      if(prevAreaIndex == -1){
        prevAreaIndex = totalItems -1
        if(moduleName === "customizer"){
          return {area_name:"all"}
        }
      }
      return areaList[prevAreaIndex]
    }
  


 
 
    // useEffect(()=>{

    //     if(allObjects?.length){
    //       addEventListnerOnCanvas()
    
    //       let lastIndex = allObjects.length - 1
    //       for (const objectName in objectCanvasMapping) {
    //         let canvas = objectCanvasMapping[objectName]
    //         canvas.addEventListener('dblclick', function (event:any) {
    //           selectSprite(event, canvas, lastIndex,objectName,"all")
    //         })
    
    //         canvas.addEventListener('click', function (event:any) {
    //           selectSprite(event, canvas, lastIndex,objectName,"single")
    //         })
    
    //         canvas.addEventListener('touchstart', function(e:any) {
    //           selectSprite(e, canvas, lastIndex,objectName,"single")
    //           touchStartTimeStamp = e.timeStamp;
    //         });
    
    //         canvas.addEventListener('touchend', function (e:any) {
    //           touchEndTimeStamp = e.timeStamp;
    //           // console.log(touchEndTimeStamp - touchStartTimeStamp);// in miliseconds
    //           let time = touchEndTimeStamp - touchStartTimeStamp
    //           if(time>=1000){
    //             isMultipleCanvasSelectionMode =  true
    //             selectSprite(e, canvas, lastIndex,objectName,"multiple")
    //           }
    //         });
    
    //         $(canvas).on('onmousedown', function (event) {
    //           console.log(event)
    //           selectSprite(event, this, lastIndex,objectName,"all")
    //         })
    
    //       }
    //     }
    //     $(`canvas[data-object-part-name = "Background"]`).css("z-index",'0')
    //     setCurrentSelectedElements(null)
    //     setShowDeselect(false)
    //   },[objectCanvasMapping,allObjects])


    export class MaterialTypes{
        materialTypes: string | undefined
        isOpen: boolean | undefined
        
        
        setMaterialTypes(value:string){
            this.materialTypes = value
        }

        getMaterialTypes(){
            return this.materialTypes
        }

        setIsOpen(value:boolean){
            this.isOpen = value
        }
    }


    //SELect multiple canvas code

       // canvas.addEventListener('touchstart', function(e:any) {
        //   selectSprite(e, canvas, lastIndex,objectName,"single")
        //   touchStartTimeStamp = e.timeStamp;
        // });

        // canvas.addEventListener('touchend', function (e:any) {
        //   touchEndTimeStamp = e.timeStamp;
        //   // console.log(touchEndTimeStamp - touchStartTimeStamp);// in miliseconds
        //   let time = touchEndTimeStamp - touchStartTimeStamp
        //   if(time>=1000){
        //     isMultipleCanvasSelectionMode =  true
        //     selectSprite(e, canvas, lastIndex,objectName,"multiple")
        //   }
        // });

        // $(canvas).on('onmousedown', function (event) {
        //   console.log(event)
        //   selectSprite(event, this, lastIndex,objectName,"all")
        // })

        
      // tempCanvas.addEventListener('touchstart', function(e:any) {
      //   selectSprite(e, canvas, lastIndex,currObjectName,"single")
      //   touchStartTimeStamp = e.timeStamp;
      // });

      // tempCanvas.addEventListener('touchend', function (e:any) {
      //   touchEndTimeStamp = e.timeStamp;
      //   let time = touchEndTimeStamp - touchStartTimeStamp
      //   if(time>=1000){
      //     isMultipleCanvasSelectionMode =  true
      //     selectSprite(e, canvas, lastIndex,currObjectName,"multiple")
      //   }
      // });
     