import React from "react";
import { toggleUserOption } from "../../../../../../utils/UI_methods/global";
import { isTouchDevice } from "../../../../../../utils/customizer/helper";
import { getUser } from "../../../../../../utils/methods";
import { openSideMenu } from "../../../../../../utils/store/UI_methods";

 
const SubHeader: React.FC<{
  heading:string
}> = (props) => {


  function openSideMenuHandle() {
    if(isTouchDevice()){
      openSideMenu()
    }
  }
 
  return (
    <div className="full-width padding-container space-between bg-color-white display-flex-row border-bottom no-wrap main-sub-header-wrapper">
      <div className="display-flex-row full-height">
        <div className="center-aligned-column">
          <div onClick={openSideMenuHandle} className="bg-color-primary center-aligned-column white-card no-shadow" style={{width:"28px",height:"28px"}}>
            <i className="fas fa-bars pointer-none color-white font-"></i>
          </div>
        </div>
        
        <div className="heading3 center-aligned-column font-large marginleft10">{props.heading}</div>
      </div>

      <div className="full-height center-aligned-column action-icons">
        <div className="display-flex-row">
        
          <div className="marginleft10 marginRight10" style={{border:"1px solid #ddd"}}></div>
          
          <div className="logo center-aligned-column" style={{padding:"0 10px"}}>
            <img src="/assets/images/opus.png" height={40}  alt="" />
          </div>

          <div className="marginleft10 marginRight10" style={{border:"1px solid #ddd"}}></div>
        </div>
      </div>
     
    </div> 
  );
};

export default SubHeader;
