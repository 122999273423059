import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { showProductModel } from '../../../../../../utils/UI_methods/modelviewer';


const ProductDescription: React.FC<{
    configObj:any
    setProductModelSrc:(src:string)=>void
}> = (props) => {

 

  return (
    <React.Fragment>
        <div id="productDescription" className="product-description-wrapper zindex100 ion-padding white-card">
        

          <div className="display-flex-row" style={{justifyContent:"space-between"}}>
            <div className="heading">
            <span id="productDescCat"></span> <span> / </span>
              <span id="productDescSubCat"></span>

            </div>
            
                
            <div onClick={()=>$("#productDescription").removeClass("--is-active")}>
              <i className="fas pointer fa-times font-medium color-light"></i>
            </div>
          </div>
          
          <div className="image">
              <img src="assets/images/placeholder.png" id="productDescImg" alt="" className="full-height-width"/>
          </div>
          <div className="border-bottom padding10 display-flex-row" style={{justifyContent:"space-between"}}>
              <div id="productDescName" className="heading"></div>
              <div className="heading"> &#8377; <span id="productDescPrice" ></span></div>
          </div>
          <div className="center-aligned-row">
            <button className="op-btn disable op-btn-info ion-margin-top"
            onClick={(event:any)=>{
              showProductModel(event)
              props.setProductModelSrc("/assets/model/Dolce_3Seater.glb")
              $("#productDescription").removeClass("--is-active")
            }}
            >View in 3D</button>
          </div>
        </div>
    </React.Fragment>
  );
};

export default ProductDescription;






