import { IonAlert, IonIcon } from "@ionic/react";
import { construct, copy, ellipsisVertical, link } from "ionicons/icons";
import React, { useState } from "react";
import { enableRotationMode, openProductInfoPanel, openReplaceProductPanel, showProductOptionsPanel, toggleMoreOptions } from "../../../../../../utils/customizein3d/area3dmodel/UI_methods";
import { duplicateProduct, removeProduct } from "../../../../../../utils/customizein3d/area3dmodel/actions";
import { detachObjectFromWall, enableSetWallProductPositionMode } from "../../../../../../utils/customizein3d/area3dmodel/walls";
// import { addArea3dModelToScene, area3dModelInit, loadModuleToTheScene } from "../../../../../../utils/customizein3d/area3dModel";
import { hideComponentLoader } from "../../../../../../utils/UI_methods/global";
import { Module } from "../../../../../../utils/customizein3d/Configuration";
import { pointerDownAnnotationDrag, pointerMoveAnnotationDrag, pointerUpAnnotationDrag } from "../../../../../../utils/customizein3d/area3dmodel/annotations";
import { enableIsolationView } from "../../../../../../utils/customizein3d/area3dmodel/isolationView";
import { productViewerInit } from "../../../../../../utils/customizein3d/productViewer/productViewer";
import { CURR_SELECTED_PRODUCT } from "../../../../../../utils/customizein3d/raycasting";
import { generateModel } from "../../../../../../utils/customizein3d/webxr";
import { applicationConfig, waitFor } from "../../../../../../utils/methods";
import Modal from "../../../../../common/shared-components/Modals/Modal";
import "../../../../../style/admin/customizein3d.css";
import ProductViewer from "../../../../products/productviewer/ProductViewer";
import { enableElecationViewForProduct } from "../../../../../../utils/customizein3d/area3dmodel/elevationView";





const CustomizerHeader: React.FC<{
  }> = (props) => {

    const[showDeleteAlert,setShowDeleteAlert] = useState(false)
    const [openProjectAction, setOpenProjectAction] = useState(false);
    const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);
    const [currProduct, setCurrProduct] = useState(null);
  
  
  
  
    async function viewProductin3DAction() {
      let product = CURR_SELECTED_PRODUCT.userData.configuration
      let moduleConfig = CURR_SELECTED_PRODUCT.userData.configuration.moduleConfiguration
      setIsOpenViewProduct(true)
      setOpenProjectAction(false)
      setCurrProduct(product)
      await waitFor(500)
      let module = new Module(product,"")
      module.configuration = getArrayOfConfiguration(product.configuration)
      let url = await generateModel(product,moduleConfig,[module],true,null)
      productViewerInit(product,"productViewerWrapper",url)
      hideComponentLoader("canvasLoader")
    }

    function getArrayOfConfiguration(finishConfiguration:any) {
      let configArray = []
      for (const partName in finishConfiguration) {
         const texture = finishConfiguration[partName]
         texture.partName = partName
         if(texture.materialCode !== "Default"){
          configArray.push(texture)
         }
      }
      return configArray
    }

    
  return (
    <>
      <Modal
        isOpen = {isOpenViewProduct}
        Content = {()=>(
          <ProductViewer
            id="productViewerWrapper"
          />
        )}
        onDismiss = {()=>setIsOpenViewProduct(false)}
        heading={"Ar"}
        classNames="vertical-modal"
        id=""
        FooterContent={()=>(<></>)}
      />


      <IonAlert
        isOpen={showDeleteAlert}
        mode="ios"
        onDidDismiss={() => setShowDeleteAlert(false)}
        cssClass="my-custom-class"
        header={"Confirm!"}
        message={"Delete <strong>Product</strong>!!!"}
        buttons={[{
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              removeProduct(CURR_SELECTED_PRODUCT,true,true);
            },
          },
        ]}
      />

   <div id="productAnnotationWrapper" className="product-annotation  hide-on-rotation  disp hide-on-wall-focus product-annotations-wrapper zindex30 hide-on-dragging">
        
      
        
        <div className="display-flex-row no-wrap">


          <div className="center-aligned-column" id="annotationDragIcon">
            <div className="center-aligned-column border white-card shadow-light" onPointerDown={pointerDownAnnotationDrag}  onPointerMove={pointerMoveAnnotationDrag}  onPointerUp={pointerUpAnnotationDrag} style={{width:"15px",height:"25px",backgroundColor:"#fff",cursor:"grab"}}> 
                <div className="display-flex-row no-wrap pointer-none">
                  <IonIcon className="color-dark padding0" icon={ellipsisVertical}></IonIcon>
                </div>
            </div>
          </div>
          

          <div className="center-aligned-column pointer-none" id="annotaionLabel">
            <div className="display-flex-row no-wrap white-card product-name border shadow-light">
              <div id="categoryHeading" className="color-primary heading2 font-small pointer-none">
                Category
              </div>
            </div>
          </div>

          <div className="icons-wrapper">
            <div className="display-flex-row no-wrap">
            
            
            <div id="productWallElevation" className="icon-container hide-in-isolation-view center-aligned-column border shadow-light" onClick={()=>{enableElecationViewForProduct()}}> 
                <img src={`${applicationConfig.aws_public_url}assets/icon/svg/wall.svg`} width="15" alt="" />
            </div>

            <div id="productWallAttach" className="icon-container hide-in-isolation-view center-aligned-column border shadow-light" onClick={detachObjectFromWall}> 
                  <IonIcon className="icon center-aligned-column" icon={link}></IonIcon>
            </div>

            

            <div id="productRotateAnnotation" className="icon-container hide-in-isolation-view center-aligned-column border shadow-light" onClick={enableRotationMode}>
                <i className="fas fa-undo font-small icon center-aligned-column"></i>
            </div> 

            {/* <div id="isolationViewIcon" className="icon-container center-aligned-column border shadow-light" onClick={toggleElevationView}> 
                  <IonIcon className="icon center-aligned-column pointer-none" icon={eye}></IonIcon>
            </div> */}

            {/* <div id="isolationViewIcon" className="icon-container hide-on-configure hide-in-isolation-view hide-in-kitchen-planner center-aligned-column border shadow-light" onClick={enableIsolationView}> 
                  <IonIcon className="icon center-aligned-column pointer-none" icon={eye}></IonIcon>
            </div> */}

            <div id="customizeProductIcon" className="icon-container center-aligned-column border shadow-light" onClick={showProductOptionsPanel}> 
                  <IonIcon className="icon center-aligned-column" icon={construct}></IonIcon>
            </div>

          
 

              {/* <div id="productVerticalDrag" className="icon-container center-aligned-column border shadow-light" onClick={verticalDragAction}>
                <IonIcon className="icon center-aligned-column" icon={arrowUp}></IonIcon>
              </div>  */}

              <div id="duplicateProduct" className="icon-container hide-in-isolation-view center-aligned-column border shadow-light" onClick={duplicateProduct}>
                <IonIcon className="icon center-aligned-column" icon={copy}></IonIcon>
              </div> 


              <div id="productDelete" className="icon-container hide-on-rotation hide-in-isolation-view center-aligned-column border shadow-light" onClick={()=>setShowDeleteAlert(true)}>
                <i className="fas fa-trash font-small icon center-aligned-column"></i>
              </div>

              

              {/* <div id="productRotateAnnotation" className="icon-container hide-in-isolation-view center-aligned-column border shadow-light" onClick={showRotationSlider}>
                <i className="fas fa-undo font-small icon center-aligned-column"></i>
              </div> */}

              {/* <CircleRangeSlider
                id="rangeSlider"
                onRotate={rotationProductByValue}
                pointerUp={endRotation}
                pointerDown={startRotation}
              /> */}

              <div id="productInfoButton" className="position-relative hide-on-rotation">
                <div  className="icon-container user-options-link  center-aligned-column border shadow-light"  onClick={toggleMoreOptions}>
                  <i className="fas fa-ellipsis-h font-small icon center-aligned-column"></i>
                </div>

                <div className='header-user-options more-options white-card shadow-medium border' >
                  <div className='option show-in-kitchen-planner display-none border-top display-flex-row' onClick={openProductInfoPanel}>
                    <div><i className="fas fa-object-group color-primary  font-small"></i></div>
                    <div className='marginleft10 heading3 font-small center-aligned-column'>Group item</div>
                  </div>


                  <div className='option hide-in-elevation-view border-top display-flex-row' onClick={enableIsolationView}>
                    <div><i className="fas fa-eye  color-black font-small"></i></div>
                    <div className='marginleft10 heading3 font-small center-aligned-column'>Isolation view</div>
                  </div>

                  {/* <div className='option hide-in-elevation-view border-top display-flex-row' onClick={duplicateProduct}>
                    <div><i className="fas fa-copy  color-black font-small"></i></div>
                    <div className='marginleft10 heading3 font-small center-aligned-column'>Duplicate Item</div>
                  </div> */}

                  <div className='option hide-in-elevation-view border-top display-flex-row' onClick={openReplaceProductPanel}>
                    <div><i className="fas fa-exchange-alt  color-black font-small"></i></div>
                    <div className='marginleft10 heading3 font-small center-aligned-column'>Replace Item</div>
                  </div>

                  <div className='option hide-in-elevation-view border-top display-flex-row' onClick={viewProductin3DAction}>
                    <div><i className="fas fa-cube  color-black font-small"></i></div>
                    <div className='marginleft10 heading3 font-small center-aligned-column'>View in Ar</div>
                  </div>


                </div>
              </div>

          </div>
          
          </div>
        </div>

       
    </div>

    <div id="productDistanceCheckWrapper" className="pointer-none shadow-medium display-toggle-wrapper product-distance-check-wrapper top-left padding0 zindex30">
      <div className="full-height-width position-relative">
        <div className="left-middle shadow-medium distance-line" id="leftDistanceLine" style={{transform:"translate(-100%,50%)",left:"0"}}>
          <div className="position-relative full-height-width">
            <i className="fas fa-circle icon left-middle" style={{left:"-2px"}}></i>
            <div className="left-middle text text-left font-small">14"</div>
          </div>
        </div>
        <div className="right-middle shadow-medium distance-line" id="rightDistanceLine" style={{transform:"translate(100%,-50%)",right:"0"}}>
          <div className="position-relative full-height-width">
            <i className="fas fa-circle icon right-middle" style={{right:"0"}}></i>
            <div className="right-middle text text-right font-small">14"</div>
          </div>
        </div>
        <div className="top-middle shadow-medium distance-line top-distance-line" id="topDistanceLine" style={{transform:"translate(-50%,-100%)",top:"0"}}>
          <div className="position-relative full-height-width">
            <i className="fas fa-circle icon top-middle" style={{top:"-3px"}}></i>
            <div className="top-middle text text-top font-small">14"</div>
          </div>
        </div>
        <div className="bottom-middle shadow-medium distance-line bottom-distance-line" id="bottomDistanceLine" style={{transform:"translate(-50%,100%)",bottom:"0"}}>
          <div className="position-relative full-height-width">
            <i className="fas fa-circle icon bottom-middle" style={{bottom:"-3px"}}></i>
            <div className="bottom-middle text text-bottom font-small">14"</div>
          </div>
        </div>
      </div>
    </div>

    
</>
  );
};

export default CustomizerHeader;
