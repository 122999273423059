import React, { useState } from "react";
import { Floorplan } from "../../utils/customizein3d/floorplanner/floorplan";
import DropDown from "../common/ui-components/filters/DropDown";
import $ from "jquery"
import Button2 from "../common/ui-components/buttons/Button2";
import { IonIcon, IonSelect, IonSelectOption } from "@ionic/react";
import { hideAnnotation } from "../../utils/customizein3d/floorplanner/annotations";
import { ellipsisVertical } from "ionicons/icons";
import { pointerDownAnnotationDrag, pointerMoveAnnotationDrag, pointerUpAnnotationDrag } from "../../utils/customizein3d/area3dmodel/annotations";
  
  
  const Annotations: React.FC<{
    floorplan:Floorplan
  }> = (props) => {

    
 

    let lengthFeetDropDownValues = [];
  let breadthFeetDropDownValues = [];

  let lengthInchDropDownValues = [];
  let breadthInchDropDownValues = [];

    
  for (let i = 5; i < 36; i++) {
    lengthFeetDropDownValues.push(i);
    breadthFeetDropDownValues.push(i);
  }
  
  for (let i = 1; i <= 12; i++) {
    lengthInchDropDownValues.push(i);
    breadthInchDropDownValues.push(i);
  }

  function toggleWallDimensions(event:any  = null) {
    $("#wallDimensions").toggleClass("display-none")
    $("#updateWallAnnotation").toggleClass("--is-active")
  }

  function closeHandle(event:any) {
    if($(event.target).hasClass("floorplan-annotation-wrapper")){
      hideAnnotation()
    }
  }

  function updateDimHandle() {
    toggleWallDimensions()
    props.floorplan.updateWallDimension()
  }

    return (
    <>
    <div id="floorplanAnnotationWrapper" className="product-annotation floorplan-annotation-wrapper" style={{zIndex:1002}}  >
        <div className="display-none no-wrap" style={{zIndex:1002}}>

            <div className="center-aligned-column marginRight5" id="annotationDragIcon">
              <div className="center-aligned-column border white-card shadow-light" onPointerDown={pointerDownAnnotationDrag}  onPointerMove={pointerMoveAnnotationDrag}  onPointerUp={pointerUpAnnotationDrag} style={{width:"15px",height:"25px",backgroundColor:"#fff",cursor:"grab"}}> 
                  <div className="display-flex-row no-wrap pointer-none">
                    <IonIcon className="color-dark padding0  pointer-none" icon={ellipsisVertical}></IonIcon>
                  </div>
              </div>
            </div>

            <div id="deleteWallAnnotation" className="icon-container center-aligned-column border shadow-light" onClick={()=>props.floorplan.deleteWall()}>
                <i className="fas fa-trash color-black font-small"></i>
            </div>

            {/* <div id="updateWallAnnotation" className="icon-container center-aligned-column border shadow-light" onClick={toggleWallDimensions}>
                <i className="fas fa-edit color-black font-small"></i>
            </div> */}

           

            

        </div>
    </div> 

      <div className="display-none full-height-width center-aligned-column" id="wallDimensions">
      <div className="display-flex-row white-card border shadow- padding10" style={{width:"max-content"}}>
        <div className="display-flex-row no-wrap">
          <select name="" id="wallDimFeetValue" className="input padding0 text-center">
            {breadthFeetDropDownValues.map((value:number,index:number)=>{
              return(
                <option key={index} value={value}>{value}</option>
              )
            })}
          </select>
          <div className="center-aligned-column dimension-label label" >
            {" "}
            Ft.
          </div>
        </div>
        <div className="display-flex-row no-wrap">
          <select name="" id="wallDimInchValue" className="input padding0 text-center">
            {breadthInchDropDownValues.map((value:number,index:number)=>{
              return(
                <option key={index} value={value}>{value}</option>
              )
            })}
          </select>
          {/* <input type="number" id="wallDimInchValue" defaultValue={0} className="input padding0 text-center" style={{width:"40px"}} /> */}
          <div className="center-aligned-column dimension-label label" >
            {" "}
            In.
          </div>
        </div>

        <div className="center-aligned-column">
          <div className="display-flex-row">
            <Button2
              configObj={{id:""}}
              action={updateDimHandle}
              label={""}
              fontAwesomeIcon={"fas fa-check font-small color-white"}
              iconPos="left"
              classNames={"shadow-light bg-color-primary button-small"}
            />
          </div>
          
        </div>

        

      </div>
      </div>
      </>
    );
  };
  
  export default Annotations;
  