import { IonIcon, IonModal } from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { getAllProjectAreas, getMoodboardList, getProjectAreaCategories, getProjects, saveMoodboard, saveProjectAreaCategories, saveProjectAreas, updateProjectMaterialRelationship } from "../../../../../services/api";
import AwsConfig from "../../../../../utils/aws-sdk/AwsConfig";
import { displayUploadedImage } from "../../../../../utils/inventory/inventory";
import { applicationConfig, getFilteredArray, getObjectByParameter, logger, stringWithoutQuotes } from "../../../../../utils/methods";
import { addNewBadgeClassToElement } from "../../../../../utils/UI_methods/global";
import Modal from "../../../../common/shared-components/Modals/Modal";
import Share from "../../../../common/shared-components/Share/Share"
import AreaProductDetails from "./AreaProductDetails"
import { ProjectForm } from "../../../../../utils/projects/projectForm";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import MaterialsForm from "../materials-form/MaterialForm";
import FormStatus from "../FormStatus";
import MaterialList from "./MaterialList";

const Summary: React.FC<{
    closeModal: (value: boolean) => void;
    updateCurrentState:(state:string)=>void
    projectForm:ProjectForm
    selectedCategories:any
    selectedAreas:any
    refreshProjects:(projects:Array<any>,allAreas:Array<any>)=>void
    projectId:number
    allCategories:any
    configObj:any
    mode:string
}> = (props) => {

  const [imageSrc,setImageSrc] = useState(`https://opusassets.s3.ap-south-1.amazonaws.com/uploads/floorplan/${applicationConfig?.clientName}/${props.projectForm.projectName}_${props.projectForm.projectId}/flooplan.png`);

  // const[isShowPrice,setIsShowPrice] = useState(false)
  const[showFinalSummary,setShowFinalSummary] = useState(false)
  const[isOpenMaterial,setIsOpenMaterial] = useState(false)

// useEffect(()=>{
//   displayUploadedImage(props.projectDetails.SELECTED_FILE,"uploadedImage")
// },[])



const ProjectDetails = () => {
    return(
        <div className="white-card no-shadow marginBottom10 padding10">
          <div className="heading2 color-danger">Project Details</div>
          <div className="display-flex-row no-wrap position-relative  marginTop10 ">
            <div className="image-flooplan">
              <img src={imageSrc} alt="" style={{maxHeight:"200px"}} />
            </div>
            <div className="details-container fill-available-width" style={{padding:"0 10px"}}>

              <div className="display-flex-row space-between">
                <div className="width50" style={{padding:"0 10px"}}>
                  <div className="display-flex-row space-between">
                    <span className="heading4 font-md-small">Project Name </span>
                    <span className="heading1 font-md-small">{props.projectForm.projectName}</span>
                  </div>
                </div>
                <div className="width50" style={{padding:"0 10px"}}>
                  <div className="display-flex-row space-between">
                    <span className="heading4 font-md-small">Developer</span>
                    <span className="heading1 font-md-small">{props.projectForm.developer}</span>
                  </div>
                </div>
              </div>


              <div className="display-flex-row space-between marginTop10">
                <div className="width50" style={{padding:"0 10px"}}>
                  <div className="display-flex-row space-between">
                    <span className="heading4 font-md-small">Location</span>
                    <span className="heading1 font-md-small">{props.projectForm.projectLocation}</span>
                  </div>
                </div>
                <div className="width50" style={{padding:"0 10px"}}>
                  <div className="display-flex-row space-between">
                    <span className="heading4 font-md-small">Mobile</span>
                    <span className="heading1 font-md-small">{props.projectForm.clientMobile}</span>
                  </div>
                </div>
              </div>

              <div className="display-flex-row space-between marginTop10">
                <div className="width50" style={{padding:"0 10px"}}>
                  <div className="display-flex-row space-between">
                    <span className="heading4 font-md-small">apartmentType</span>
                    <span className="heading1 font-md-small">{props.projectForm.apartmentType}</span>
                  </div>
                </div>
                <div className="width50" style={{padding:"0 10px"}}>
                  <div className="display-flex-row space-between">
                    <span className="heading4 font-md-small">Client Name</span>
                    <span className="heading1 font-md-small">{props.projectForm.clientName}</span>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
    )
}
 

function confirmCreateProject(){

    let projectId:any = props.projectId 

    let moodboardId = Number(new Date())
    let sagment = "Interior"

    //Assign moodboard id for existing 
    if(props.mode==="update"){
      //get MoodboardID
      let moodboard = getFilteredArray(props.configObj?.data.moodboardList,"project_id",projectId)
      if(moodboard.length){
        moodboardId = moodboard[0].moodboard_id
      }
    }



    // create FloorPlan upload key
    let albumName = "uploads"
    var albumPhotosKey = encodeURIComponent(albumName) + "/" + "floorPlans" + "/" + props.configObj?.clientName + "/"
    let fileName = "floorplan_" + projectId 
    let promiseUploadFloorPlan = null

    let promiseSaveAreas = saveProjectAreas(projectId,props.selectedAreas)
    let promiseSaveAreaCategories = saveProjectAreaCategories(projectId,props.selectedCategories)
    let promiseSaveMoodboard = saveMoodboard(applicationConfig.clientId,moodboardId,projectId,props.selectedAreas,"StyleBoard",sagment)
    let promiseUpdateMaterials = updateProjectMaterialRelationship(projectId,props.projectForm.projectMaterials.list)
    
    
    // if(props.projectDetails.SELECTED_FILE){
    //   promiseUploadFloorPlan = awsConfig.uploadImageToS3(albumPhotosKey,props.projectDetails.SELECTED_FILE,fileName)
    // }


    Promise.all([promiseSaveAreas,promiseSaveAreaCategories,promiseSaveMoodboard,promiseUploadFloorPlan,promiseUpdateMaterials])
    .then((response: any) => {
        let promiseProjects = getProjects();
        let promiseAllAreas = getAllProjectAreas();
        let promiseProjectAreaCategories = getProjectAreaCategories()
        let promiseMoodbboardList = getMoodboardList()
        Promise.all([promiseProjects,promiseAllAreas,promiseProjectAreaCategories,promiseMoodbboardList])
          .then((data) => {
            let configData = props.configObj.getData()
            configData['projects'] = data[0]
            configData['projectAreasList'] = data[1]
            configData['projectAreaCategories'] = data[2]
            configData['moodboardList'] = data[3]
            props.configObj.setData(configData)
            
            // props.refreshProjects(data[0],data[1])

            props.updateCurrentState("Options")
            if(props.mode==="create"){
              addNewBadgeClassToElement("projectCard_"+projectId)
            }

            logger?.info("projects","createproject",`New project created: ${projectId}` )

          })
          .catch((error) => {
            console.log(error);
            logger.error("projects","createproject",`Error in creating project: ${stringWithoutQuotes(error)}`)
          });
    
    })
    .catch((error: any) => {
          logger.error("projects","createproject",`Error in creating project`)
          console.log(error);
    });

}
 

function FooterForMaterialModal() {
  return(
    <Button2
      configObj={applicationConfig}
      action={()=>setIsOpenMaterial(false)}
      label={"Confirm"}
      fontAwesomeIcon={"fas fa-times color-danger"}
      iconPos=""
      classNames={"shadow-light bg-color-primary"}
    />
  )
}
 
 
  return (
    <React.Fragment>
        <IonModal
          id=""
          cssClass="share-modal"
          onDidDismiss={()=>setShowFinalSummary(false)}
          isOpen={showFinalSummary}
          >
           <Share
            configuration={{
              selectedAreas:props.selectedAreas,
              selectedCategories:props.selectedCategories,
              allCategories:props.allCategories
            }}
            areas={props.selectedAreas}
            configObj={props.configObj}
            moduleName="CreateProjectSummary"
            closeModal={setShowFinalSummary}
          />
        </IonModal>

        
        {/* <Modal
            classNames="project-action-modal"
            onDismiss={setIsOpenMaterial}
            isOpen={isOpenMaterial}
            heading="Select Materials"
            id=""
            FooterContent={FooterForMaterialModal}
            Content={()=>(
              <MaterialsForm
                projectForm={props.projectForm}
              />
            )}
          >
       
        </Modal> */}

    <div className="modal-header heading1">
    <IonIcon
      icon={close}
      ios={close}
      className="modal-close-icon"
      onClick={() => props.closeModal(false)}></IonIcon>
    Summary 
  </div>
  <div className="modal-body light-background">
          <FormStatus
            count={5}
          />
      <div className="project-details-summary-container overflow-y-scroll full-height-width" style={{padding:"5px 5px 50px 5px"}}>
        <ProjectDetails/>
        <AreaProductDetails
          configObj={props.configObj}
          areaList={props.selectedAreas}
          selectedCategories={props.selectedCategories}
          allCategories={props.allCategories}
          isShowPrice={false}
        />
        <MaterialList
          projectForm={props.projectForm}
        />
        
      </div>
  </div>
  <div className="modal-footer" style={{justifyContent:"space-between"}}>
   

    <button className="op-btn op-btn-light" onClick={()=>props.updateCurrentState("Step4")}>
    <i className="fas fa-arrow-left color-black"></i> Back
    </button>

    <button className="op-btn op-btn-primary"  onClick={confirmCreateProject} >
    <i className="fas fa-save color-white"></i> Confirm
    </button>
    
  </div>
  </React.Fragment>
        
  );
};

export default Summary;
