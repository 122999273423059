import { IonIcon } from "@ionic/react";
import { trash } from "ionicons/icons";
import React, { useState } from "react";
import { removeProduct } from "../../../../../../utils/moodboard/moodboard";
import { deleteText, updateFontStyle, updateTextColor, updateTextSize } from "../../../../../../utils/moodboard/text";
import DropDown from "../../../../../common/ui-components/filters/DropDown";

const TextAraeActions: React.FC<{
  configObj: any;
}> = (props) => {




  const[currSelectedSize,setCurrSelectedSize] = useState("11")

  function colorChangeHandle(event:any) {
    updateTextColor(event.target.value)
  }

  function fontSizeChangeHandle(value:any) {
    updateTextSize(value)
    setCurrSelectedSize(value)
  }

  

  return (
    <React.Fragment>
       {/* <div className="top-left">
        <div>Add Area</div>
      </div> */}

    <div className="addons-container zindex30" id="textAreaActions" style={{position:"absolute",top:"-100%",left:"-100%"}}>
        <div className="display-flex-row space-between pointer">
          <div className="text-edit border text-center full-height" style={{width:"20px",borderColor:"gray",borderRadius:"2px"}} onClick={updateFontStyle}>
            B
            {/* <IonIcon className="font-small color-black click-effect white-card shadow-light padding5" icon={trash} onClick={removeProduct}/> */}
          </div>

          <div className="marginleft10">
            <input type="color" id="favcolor" onChange={colorChangeHandle} name="favcolor" value="#ff0000" style={{height:"22px"}}/>
          </div>

          <div className="marginleft5">
            <DropDown
              classNames="marginleft5"
              columns={1}
              values={["9","10","11","12","13","14","15","16","17","18"]}
              currSelectedValue={currSelectedSize}
              action={fontSizeChangeHandle}
              label=""
            />
          </div>

          <div className="text-actions" onClick={deleteText}>
            <IonIcon  className="font-small marginleft5 color-black click-effect white-card shadow-light padding5" icon={trash}/>
          </div>
        </div>
      </div>

    
    </React.Fragment>
  );
};

export default TextAraeActions;
