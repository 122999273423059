import $ from "jquery";
import React, { useEffect, useState } from "react";
import {
  getParentContainer,
  showComponentLoader
} from "../../../../utils/UI_methods/global";
import AwsConfig from "../../../../utils/aws-sdk/AwsConfig";
import {
  checkFromCache,
  untarAndSaveToCache
} from "../../../../utils/cache/cache";
import {
  addItemToLocalStorage,
  updateModuleFilters,
  updateRecentActivities
} from "../../../../utils/cache/localstorage";
import { productViewerInit } from "../../../../utils/customizein3d/productViewer/productViewer";
import { applicationConfig, getUserType, logger, waitFor } from "../../../../utils/methods";
import { getProductImageUrl } from "../../../../utils/moodboard/helper";
import ProductViewer360 from "../../../admin/products/ProductViewer360/ProductViewer360";
import Home from "../../../admin/products/productActions/Home";
import ProductViewer from "../../../admin/products/productviewer/ProductViewer";
import Modal from "../../shared-components/Modals/Modal";
import Loader from "../../shared-components/main-ui/Loader";
import Button2 from "../buttons/Button2";
import ProductImage from "../elements/ProductImage";
import { IonModal } from "@ionic/react";

const ProductCards: React.FC<{
  configObj: any;
  productsList: any;
  setCurrProductDetails: (prodId: number) => void;
  isSearchMode:boolean
  currSelectedSubCategory:string
}> = (props) => {
  //Function to remove project after deleting
  const [openProjectAction, setOpenProjectAction] = useState(false);
  const [isOpenViewin360, setIsOpenViewin360] = useState(false);
  const [currProductId, setCurrProductId] = useState(0);
  const [currProduct, setCurrProduct] = useState(null);
  const [loadLimit, setLoadLimit] = useState(20);
  const [filteredProducts, setFilteredProducts] = useState<any>([]);
  let awsConfig = new AwsConfig();
  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);

  const[productsList,setProductsList] = useState([])

  const [productLoaderClass] = useState<string>("product-loader");


  useEffect(()=>{
    let products = props.productsList
    if(getUserType().toLowerCase() !== "customer" && applicationConfig?.filters?.products?.recentConfigured && !props.isSearchMode && (props.currSelectedSubCategory === applicationConfig?.filters?.products?.recentConfigured?.sub_category_name)){
      products = products.filter(currProduct=>currProduct.product_name!==applicationConfig?.filters?.products?.recentConfigured.product_name)
      products.splice(0,0,applicationConfig?.filters?.products?.recentConfigured)
      setProductsList(products)
    }else{
      setProductsList(props.productsList)
    }
  },[props.productsList,props.isSearchMode])



  useEffect(() => {
    if (productsList?.length) {
      updateModuleFilters("products", "productsLoadLimit", loadLimit);
      
      setFilteredProducts((prevProducts) => {
        return prevProducts.concat(
          productsList?.slice(loadLimit - 10, loadLimit)
        );
      });
    }
  }, [productsList, loadLimit]);


  function nextPage() {
    setLoadLimit((prevValue) => {
      if (prevValue >= productsList?.length) {
        return prevValue;
      }
      return prevValue + 10;
    });
  }

  function cardClickHandle(product: any) {
    let productId = product.product_id;
    updateRecentActivities("products", product);
    setOpenProjectAction(true);
    setCurrProductId(productId);
    setCurrProduct(product)

    //Create logs
    // logger?.info("products","productActions","Viewed product ")
  }


  async function gotoCustomizeIn3D(product:any){
    setOpenProjectAction(false)
    addItemToLocalStorage("currProduct",product)
    // props.setCurrProductDetails(product.product_id)
    // setCustomizein3dConfiguration(product,false)
    // history.push("/product-3dcustomization");
    applicationConfig?.filters?.products?.setRecentConfigured(product)
    window.location.href = "/product-3dcustomization"
  }



  async function viewProduct360Action(product:any) {
    setOpenProjectAction(false)
    setIsOpenViewin360(true)
    props.setCurrProductDetails(product.product_id)
    setCurrProduct(product)
    await waitFor(500)
    // productViewerInit(product,"productViewerWrapper")
  }

  function gotoProductDetails(product:any) {
    applicationConfig.functions.products.setCurrProduct(product)
    applicationConfig.functions.products.setIsShowProductDetails(true)
  }

  async function viewProductin3DAction(product:any) {
    setIsOpenViewProduct(true)
    setOpenProjectAction(false)
    await waitFor(500)
    productViewerInit(product,"productViewerWrapper")
  }

  function goToProduct() {
    window.location.href = `/store/product-details/${currProduct.product_name}`
  }

  return (
    <>
      <div className="products-wrapper display-flex-row full-width"
        id="productsWrapper">

          <Modal
            isOpen = {isOpenViewProduct}
            Content = {()=>(
              <ProductViewer
                id="productViewerWrapper"
              />
            )}
            onDismiss = {()=>setIsOpenViewProduct(false)}
            heading={currProduct?.product_name}
            classNames="vertical-modal"
            id=""
            FooterContent={()=>(<></>)}
        />

        <IonModal
            isOpen = {openProjectAction}
            onDidDismiss = {()=>setOpenProjectAction(false)}
            cssClass="vertical-modal"
            id=""
        >
          <Home
            configObj={props.configObj}
            currProductId={currProductId}
            setOpenProjectAction={setOpenProjectAction}
            currProduct={currProduct}
            viewIn360Action={viewProduct360Action}
            quickCustomizeAction={goToProduct}
            gotoCustomizeIn3D={gotoCustomizeIn3D}
          /> 
        </IonModal>

      

        <Modal
            isOpen = {isOpenViewin360}
            Content = {()=>(
              <ProductViewer360
                id="productViewer360"
                product={currProduct}
              />
            )}
            onDismiss = {()=>setIsOpenViewin360(false)}
            heading={"Product Viewer 360"}
            classNames="form-action-modal"
            id=""
            FooterContent={()=>(<></>)}
        />
 

        {productsList?.map((product: any, index: number) => {
          let productImageUrl = getProductImageUrl(
            props.configObj?.BASE_URL_ASSETS,
            props.configObj?.clientName,
            product.product_name,
            product.category_name,
            product.sub_category_name
          );


          return (
            <React.Fragment key={index}>
              {Number(index) < loadLimit ? (
                <div className={`project-container product-image-container product-container shadow-light white-card`}
                  data-product-id={product.product_id}
                  data-product-name={product.product_name}
                  data-category-name={product.category_name}
                  data-subcategory-name={product.sub_category_name}>
                  <div className="project-detials-container">
                    <div className="display-flex-row bg-color-white space-between padding10 no-wrap">

                      <div className="display-flex-row" style={{marginTop:"3px"}}>
                          {product.is_modular?
                          <div className="center-aligned-column">
                            <img src="assets/icon/modular.png" width={20} alt="" />
                          </div>
                          :null}
                          <span className="heading3 center-aligned-column font-normal color-black" >
                            {product.product_name}  <i onClick={()=>gotoProductDetails(product)} className="fas fa-info-circle display-none font-small color-primary"></i>
                          </span>

                      </div>
                      
                     
                      {getUserType().toLowerCase() !== "customer"?
                        <div className="center-aligned-column">
                          <Button2
                            configObj={{}}
                            action={()=>{}}
                            label={""}
                            fontAwesomeIcon={"fas fa-share-alt color-primary"}
                            iconPos="right"
                            classNames={"no-shadow pointer-none button-small bg-color-light"}
                          />
                        </div>
                      :null}
                      
                    </div>
                    
                    <div className="image position-relative" onClick={() => cardClickHandle(product)}>
                      {/* <img alt="" className="product-image" id={productImageUrl} /> */}
                      <ProductImage product={product}/>
                      <Loader
                        className={`${productLoaderClass} --is-active fade-background-loader`}
                        id={product.product_name}
                        data={{ isAnimated: true }}
                        loaderType="dots"
                      />
                    </div>

                   

                    {/* {!applicationConfig.isShareMode?
                    <div className="top-right zindex100 padding10" style={{opacity:0}}>
                      <ShareLink
                        configObj={getFilteredArray(applicationConfig?.data?.productModules,"product_id",product.product_id).length?new Configuration(product.product_id):new Configuration(product.product_id)}
                        shareItemId={product.product_id}
                        loaderId = {"loaderId"}
                        moduleName="products"
                        textClass="color-black"
                        isShareButton={false}
                      />
                    </div>
                    :null} */}

                    

                    {applicationConfig?.filters?.products?.recentConfigured?.product_name === product.product_name?

                      <div className="top-left padding0 full-height-width pointer-none" style={{border:"2px solid var(--op-color-primary)"}}>
                        <div className="color-primary heading2 font-ex-small" style={{padding:"2px 5px"}}>Recently configured</div>
                      </div>

                    :null}

                    <div className="other-details no-wrap padding5 marginBottom5 overflow-hidden display-flex-column">
                      <div className="position-relative">
                      </div>
                      <div className="position-relative  display-flex-row  no-wrap" style={{justifyContent:"space-evenly"}}>
                          <Button2
                            configObj={{}}
                            action={()=>{viewProductin3DAction(product)}}
                            label={"3D"}
                            fontAwesomeIcon={"fas fa-cube color-danger"}
                            iconPos="left"
                            classNames={"no-shadow button-md-small margin0 bg-color-light"}
                          />
                          <Button2
                            configObj={{}}
                            action={()=>{gotoCustomizeIn3D(product)}}
                            label={"Customize"}
                            fontAwesomeIcon={"fas fa-cog color-danger"}
                            iconPos="left"
                            classNames={"no-shadow button-md-small margin0 bg-color-primary"}
                          />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            
            </React.Fragment>
          );
        })}

          <div className="project-container product-image-container product-container" style={{height:"0.1px",visibility:"hidden"}}>
          </div>
          <div className="project-container product-image-container product-container" style={{height:"0.1px",visibility:"hidden"}}>
          </div>
          <div className="project-container product-image-container product-container" style={{height:"0.1px",visibility:"hidden"}}>
          </div>
          <div className="project-container product-image-container product-container" style={{height:"0.1px",visibility:"hidden"}}>
          </div>
          <div className="project-container product-image-container product-container" style={{height:"0.1px",visibility:"hidden"}}>
          </div>
         
        {loadLimit < productsList?.length ? (
          <div className="center-aligned-row full-width">
            <div className="display-flex-row pointer border padding10" onClick={nextPage}>
              <div className="font-small center-aligned-column products-toggle marginleft5 heading3 pointer-none"  style={{padding:"10 12px"}}>Load more</div>
            </div>
          </div>
        ) : null}

        
        
      </div>
    </>
  );
};

export default ProductCards;
