import React, { useEffect, useState } from "react";
import { tokenVerification } from "../../services/api";
import { ModelViewer } from "../../utils/customizein3d/productViewer/productViewer";
import ProductViewer from "../admin/products/productviewer/ProductViewer";
import { getMobileOperatingSystem, showGlobalToast } from "../../utils/UI_methods/global";
 
const SharedProductViewer: React.FC<{
  configObj: any;
  // setLoginTrue:()=>void
}> = (props) => {

    // const [url, setUrl] = useState("");
    const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);

    useEffect(()=>{
        let queryString = window.location.search
        let urlParams = new URLSearchParams(queryString);
        let token = urlParams.get('token')

        tokenVerification(token).then(data=>{
            setTimeout(() => {
              setIsOpenViewProduct(true)
              let modelviewer = new ModelViewer()
              let url = data.collectionNames.url
              if(getMobileOperatingSystem() === "ios"){
                  url = url.replace(".glb","_iOS.glb")
              }
              modelviewer.loadWithUrl("productViewerWrapper",url)
            }, 500);
        }).catch(err=>{
            alert("Link expired")
            console.log(err)
        })
    },[])
 
  return (
       <div className="full-height-width center-aligned-row padding10" id="productViewerWrapper">
          <ProductViewer
            id="productViewerWrapper"
          />
        </div>
  );
};

export default SharedProductViewer;
