import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify";
import { STORE, SofaConfiguration } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";

 

const PriceBreakup: React.FC<{
}> = (props) => {

  const[isOpenMeasurement,setIsOpenMeasurements]:any =  useState(false)
  const[configuration,setconfig] = useState(null)

  useEffect(()=>{
    if(isOpenMeasurement){
      setconfig(STORE.productConfiguration)
    }
  },[isOpenMeasurement])



  function Pricing() {
    return(
      <div className="full-height-width bg-color-">
        <div className="text-center heading2 store-bg-color-primary padding10 color-white font-medium">Pricing</div>
        <div className="ion-padding">
          <table className="table table store-quotation-table border-top">
            <tbody>
              <tr>
                <td>Base Price </td>
                <td>{getFormattedPrice(configuration?.getBasePrice())}</td>
              </tr>

              <tr>
                <td>Price Upgrade ({configuration?.quality})</td>
                <td>{getFormattedPrice(configuration?.getQualityPrice(configuration?.quality))}</td>
              </tr>


              {configuration?.addon?
              configuration?.addon.map((currAddon:any,i:number)=>{
                return(
                  <tr>
                    <td>Addon - {currAddon.name}</td>
                    <td>{getFormattedPrice(currAddon.price)}</td>
                  </tr>
                )   
              })
              :null}

              {configuration?.cushionFeel?
                  <tr>
                    <td>Cushion Feel - {configuration?.cushionFeel}</td>
                    <td>{getFormattedPrice((STORE.pricing.sofa.cushion || 1200) * configuration?.variant.noOfSeats)}</td>
                  </tr>
              :null}

            {configuration?.instructions?
              configuration?.instructions.map((instruction:any,i:number)=>{
                return(
                  <tr>
                    <td>{instruction.instruction}</td>
                    <td>{getFormattedPrice(instruction.price)}</td>
                  </tr>
                )
              })
              :null}

              <tr className="border">
                <td className="" style={{fontWeight:600}}>Total</td>
                <td style={{fontWeight:600}}>{getFormattedPrice(configuration?.price)}</td>
              </tr>
              
            </tbody>
          </table>
        </div>

        <div className="center-aligned-column padding10 bottom-middle">
          <div className="">
            <Button2
              configObj={{id:""}}
              action={()=>{setIsOpenMeasurements(false)}}
              label={"Ok"}
              fontAwesomeIcon={"fas fa-cart-plus color-white"}
              iconPos=""
              classNames={"no-shadow margin0 bg-color-warning"}
            />
          </div>
        </div>
        
      </div>
    )
  }

  

  return (
    <>
     <IonModal
      isOpen={isOpenMeasurement}
      onDidDismiss={()=>setIsOpenMeasurements(false)}
      cssClass={"small-y-modal"}
    >
      <Pricing/>
    </IonModal>

    <span onClick={()=>setIsOpenMeasurements(true)} className="center-aligned-column marginleft5"><i className="fas fa-question-circle color-warning"></i></span>
    </>
   
  );
};

export default PriceBreakup;
