import {
  IonPage
} from "@ionic/react";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SessionExpired from "../../../../common/ui-components/alerts/SessionExpired";
import SubscriptionAlert from "../../../../common/ui-components/alerts/SubscriptionAlert";
import "../../../../style/store/cart.css";
import "../../../../style/store/categories.css";
import "../../../../style/store/products.css";
import MainHeader from "../common/MainHeader";
import Categories from "./StoreCategories";
 

const StoreHomepage: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {



  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white">
        <SubscriptionAlert/>
        <MainHeader/>
        <div className="main-content-wrapper  position-relative hide-scrollbar light-background padding10">
          <div className="display-flex-column no-wrap overflow-y-scroll full-height shadow-light white-card">
                <Categories configObj={props.configObj} />
          </div>
        </div>
      </div>
      :
      <SessionExpired/>
      }
    </IonPage>
  );
};

export default StoreHomepage;
