import React from "react";
const MaterialDetails: React.FC<{
  configObj: any;
  finishDetails:any
}> = (props) => {
 
  return (
    <React.Fragment>
           <div className="display-flex-row no-wrap shadow-light padding10">

<div className="display-flex-row">
    <div className="label">
      Company Name : 
    </div>
    <div className="heading color-primary marginleft5"> {props.finishDetails.companyName}</div>
</div>

<div className="display-flex-row ion-margin-start">
    <div className="label">
      Collection Name : 
    </div>
    <div className="heading color-primary marginleft5"> {props.finishDetails.collectionName}</div>
</div>

<div className="display-flex-row ion-margin-start">
    <div className="label">
      Material Type : 
    </div>
    <div className="heading color-primary marginleft5"> {props.finishDetails.materialType}</div>
</div>
</div>
    </React.Fragment>
  );
};

export default MaterialDetails;
