import React from 'react';

// import './Home.css';

const Header: React.FC<{
    configObj:any
    heading:string
}> = (props) => {


    return(
        <div className="heading2 color-black bg-color-active position-relative hide-in-mobile zindex1 padding1rem action-tabs-header text-center shadow-light border-bottom">
        {props.heading}
        </div>
        
    );
};
 
export default Header;
