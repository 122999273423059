import React, { useState } from "react";
import { Customer } from "../../../../../../../../utils/store/storeConfiguration";
import StartActivity from "../activities/StartActivity";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import { getFormatedDate, touchDeviceActions } from "../../../../../../../../utils/store/crm/crm";
import $ from "jquery"
import MarkAsLostOption from "./MarkAsLostOption";

 
const LeadCard: React.FC<{
  lead:any
  index:number
  refreshLeads:()=>void
  setCurrLead:any
  activeCustomer:Customer
  openLeadDetailsHandle:(lead:any,view:string)=>void
  setActiveCustomer:(customer:Customer)=>void
}> = (props) => {


  const[isOpenStartActivity,setIsOpenStartActivity] = useState(false)
  const[currActivity,setCurrActivity] = useState("")

  
  function startActivityHandle(activityName:string) {
    touchDeviceActions(activityName,props.lead.mobile)
    setCurrActivity(activityName)
    CRM.crmApi.leads.updateLead(props.lead.lead_id,{isViewed:1})
    setIsOpenStartActivity(true)
  }


  function mouseOverHandle() {
    $(".leads-cards-view-container").css("overflow-x","visible")
  }

  function mouseLeaveHandle() {
    // $(".leads-cards-view-container").css("overflow-y","scroll")
  }
 
  return (
    <div key={props.index} className="white-card pointer position-relative shadow-medium padding10 marginBottom10 border">
      <div className="display-flex-row no-wrap">
        <div className="name-badge-container" style={{marginLeft:"0"}}>
          <div className="capitalize name-badge center-aligned-column white-card no-shadow" style={{width:"1.8rem",height:"1.8rem",fontSize:"0.85rem"}} >
            {props.lead.customer_name.substring(0,2)}
          </div>
        </div>
        <div className="heading3 font-md-small center-aligned-column  marginleft5 color-black">{props.lead.customer_name}</div>
      </div>

      
      <StartActivity currActivity={currActivity} setCurrTab={(ta:string)=>{}} refreshLeads={props.refreshLeads} isOpen={isOpenStartActivity} setIsOpen={setIsOpenStartActivity} lead={props.lead} />
      
      
      <div className="display-flex-row marginTop5 marginleft5">
        <span className="center-aligned-column"><i className="far fa-address-card font-small color-dark"></i></span>
        <span className="center-aligned-column heading4 marginleft5">{props.lead.mobile}</span>
      </div>


      <div className="display-flex-row marginTop5 marginleft5">
        <span className="center-aligned-column"><i className="fas fa-history font-small color-dark"></i></span>
        <span className="center-aligned-column ">
          <span className="display-flex-row">
            <span className="heading3 font-small marginleft5 color-warning">{props.lead.end_date?getFormatedDate(props.lead.end_date):"Not called yet"}</span>
          </span>
        </span>
      </div>

      {props.lead.comments?.length?
      <div onMouseOver={mouseOverHandle} onMouseLeave={mouseLeaveHandle} className="marginleft5 pointer display-flex-row marginTop5 custom-tooltip  position-relative">
        <span className="center-aligned-column"><i className="far fa-comments font-small color-dark"></i></span>
        <span className="heading4 text marginleft5 text-ellipsis" style={{maxWidth:"70px"}}>{props.lead.comments}</span>
        <div className="custom-tooltip-text">{props.lead.comments}</div>
      </div>
      :null}



      <div className="bottom-right padding10">
        {props.lead.is_assigned?
        <div className="display-flex-row no-wrap">
          
            <div onClick={()=>{startActivityHandle("call")}} className="bg-color-white click-effect center-aligned-column white-card shadow-light border" style={{width:"24px",height:"24px"}}>
              <i className="fas fa-phone color-dark font-small pointer-none"></i>
            </div>

            <div onClick={()=>{startActivityHandle("whatsapp")}} className="bg-color-white click-effect center-aligned-column marginleft5 white-card shadow-light border" style={{width:"24px",height:"24px"}}>
              <i className="fab fa-whatsapp color-dark font-small pointer-none"></i>
            </div>
           
        </div>
        :null}
      </div>

      <div className={`top-right`} style={{padding:"5px 10px"}}>
        {/* <div className="bg-color-primary heading4 font-ex-small white-card shadow-light color-white" style={{padding:"2px 5px"}}>
          New
        </div> */}
        <MarkAsLostOption Content={()=>(
          <i className="fas fa-ellipsis-v color-dark font-small pointer-none"></i>
        )} 
        lead={props.lead} refreshLeads={props.refreshLeads}/>
      </div>
      

    </div>
  );
};

export default LeadCard;
