import $ from "jquery";
import React, { useEffect, useState } from "react";
import { updateOrderStatus } from "../../../../../services/storeApis";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getFilteredArray, getObjectArrayOfDistictValues, getObjectByParameter, stringWithoutWhiteSpace } from "../../../../../utils/methods";
import { getFormatedDate } from "../../../../../utils/store/crm/crm";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ControlsTab from "../../../../common/ui-components/tabs/ControlsTab";
 
 
 

const OrdersList: React.FC<{
    
}> = (props) => {



  const[allOrdersList,setAllOrdersList] = useState([])
  const[customersList,setCustomersList] = useState([])
  const[ordersList,setOrdersList] = useState([])
  const[orderItemsList,setOrderItemsList] = useState([])
  const[filteresOrders,setFilteredOrders] = useState([])

  const[isOpenSummary,setIsOpenSummary] = useState(false)

  const [currTab, setCurrTab] = useState<string>("Pending");


  var tabItems = [
    {tabName:"Pending",
    label:"Not Started",
    icon:"fas fa-menu color-dark",
    count:getFilteredArray(ordersList,"status","Pending").length
    },
    {tabName:"MaterialPrerequirement",
    label:"Material Pre...",
    ionIcon:"",
    count:getFilteredArray(ordersList,"status","MaterialPrerequirement").length
    },
    {tabName:"InProduction",
      label:"In Production",
      ionIcon:"",
      count:getFilteredArray(ordersList,"status","InProduction").length
    },
    {tabName:"QualtiyCheck",
      label:"Qualtiy Check",
      ionIcon:"",
      count:getFilteredArray(ordersList,"status","QualtiyCheck").length
    },
    {tabName:"ReadyForDispatch",
      label:"Ready For Dispatch",
      ionIcon:"",
      count:getFilteredArray(ordersList,"status","ReadyForDispatch").length
    },
    {tabName:"Returned",
        label:"Returned",
        ionIcon:"",
        count:getFilteredArray(ordersList,"status","Returned").length
    },
  ]

  useEffect(()=>{
    fetchOrders()
  },[])

  useEffect(()=>{
    if(ordersList && currTab){
      let result = getFilteredArray(ordersList,"status",stringWithoutWhiteSpace(currTab))
      setFilteredOrders(result)
    }
  },[currTab,ordersList])

  // function fetchOrders() {
  //   STORE.storeApi.orders.getOrders(applicationConfig.clientId).then((data:any)=>{
  //     if(!data.error){
  //       if(data.data?.data?.length){
  //         const orderItems = data.data.data 
  //         console.log(orderItems)
  //         setOrdersList(orderItems)
  //         // const orders = getObjectArrayOfDistictValues(orderItems,"order_id")
  //         // setOrdersList(orders)
  //       }
  //     }
  //   })
  // }
  
  function fetchOrders() {
    let promises = [STORE.storeApi.orders.getOrders(applicationConfig.clientId),STORE.storeApi.getCustomersList()] 
    Promise.all(promises).then(data=>{
        let orderItems = data[0].data.data
        let customers = data[1].data.data
        orderItems = orderItems.filter(currOrder => currOrder.client_id === applicationConfig.clientId)
        const orders = getObjectArrayOfDistictValues(orderItems,"order_id")
        setOrdersList(orders)
        setAllOrdersList(orderItems)
        setCustomersList(customers)
    }).catch(err=>{
        
    })
  
  }

  function updateTabHandle(tabName: string) {
    setCurrTab(tabName)
  }

  function viewHandle(order:any){

    if(order.status === "Pending"){
      const result = getFilteredArray(allOrdersList,"order_id",order.order_id)
      setOrderItemsList(result)
      setIsOpenSummary(true)
    }else{
      confirmHandle(order)
    }
    
  }

  function confirmHandle(order:any){
    let status:any = $(`#orderStatus_${order.order_id}`).val()
    if(!status){
      showGlobalToast("Invalid status")
      return 
    }
    updateOrderStatus("NA",order.order_id,status).then(data=>{
      fetchOrders()
      showGlobalToast("Success",2000)
    }).catch(err=>{
      console.log(err)
      showGlobalToast("Something went wrong",2000,"error")
    })
  }


  return (
    <div className="full-height-width overflow-y-scroll">

    

    <div className="orders-type-tabs" style={{width:"100%",maxWidth:"70rem"}}>
        <ControlsTab
          configObj={{}}
          tabItems={tabItems}
          currSelectedValue={currTab}
          action={updateTabHandle}
          theme="black"
        />
    </div>

    <div className="padding10 full-width">
          <table className="table full-width orders-list-table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Order ID</th>
                    <th>Order Date</th>
                    <th>Product</th>
                    {/* <th>Material</th> */}
                    <th>Customer Name</th>
                    {/* <th>Payment Status</th> */}
                    <th>Actions</th>
                </tr>
            </thead>
            {filteresOrders?filteresOrders.map((order:any,index:any)=>{
                const item = order
                const product = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",item.product_id)
                const customer = getObjectByParameter(customersList,"id",item.customer_id)
                  return (
                        <tbody key={index}>
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td className="heading2" >{`#${order.order_id}`}</td>
                            <td>{getFormatedDate(order.order_date)}</td>
                            <td className="color-primary heading2">{product?.product_name}</td>
                            {/* <td>{material?.material_code}</td> */}
                            <td>{customer?.customer_name || ""}</td>
                            {/* <td className="color-danger">unpaid</td> */}
                            <td className="center-aligned-row" style={{margin:"auto"}}>
                              <div className="display-flex-row no-wrap">

                                <div className="input-container" style={{maxWidth:"11rem"}}>
                                  <select id={`orderStatus_${order.order_id}`} defaultValue={order.status} className={"input"} name="">
                                      {tabItems.map((currTab:any,index:number)=>{
                                        let tabName = currTab.tabName === "Pending"?"Not Started":currTab.label
                                          return(
                                              <option value={currTab.tabName} key={index}>{tabName}</option>
                                          )
                                      })}
                                  </select>
                                </div>

                                <Button2
                                  configObj={{id:""}}
                                  action={()=>{confirmHandle(order)}}
                                  label={"Update"}
                                  fontAwesomeIcon={"fas fa-share color-white"}
                                  iconPos=""
                                  classNames={"no-shadow bg-color-primary button-small "}
                                />
                              </div>
                           
                            </td>
                          </tr>
                        </tbody>
                  )
              }):null}

          </table>
      </div>
  
  </div>
  );
};

export default OrdersList;
