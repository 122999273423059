import React, { useState } from "react";
import InnerPageHeader from "../../../../../common/shared-components/main-ui/InnerPageHeader";
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import Summary from "../controls/Summary";
import HeaderMiddle from "./HeaderMiddle";
import $ from "jquery"



const MoodboardHeader: React.FC<{
  configObj:any
  currentConfigObject:any
  updateCurrArea: (areaName: string, areaId: number) => void;
  areasList: Array<any>;
  categoriesStatus: any;
  updateProjectAreaCategories: () => void;
  currSelectedArea:string
  }> = (props) => {

    const [isOpenSummary, setIsOpenSummary] = useState(false);
  


  function goBack() {
    window.history.back()
  }

  function shareClickHandle() {
    $("#summaryButtonDesktop").click()
  }

  function Right() {
    return(
      <div className="display-flex-row">
        {/* <div className="display-flex-row hide-in-mobile white-card shadow-light  bg-color-primary" onClick={shareClickHandle} style={{padding:"5px 10px"}}>
            <span className="heading2 click-effect color-white center-aligned-column">Share</span>
            <span className="center-aligned-column marginleft5"><i className="fas fa-share-alt color-white"></i></span> 
        </div> */}
        {/* <Button2
          configObj={{}}
          action={shareClickHandle}
          label={"Share"}
          fontAwesomeIcon={"fas fa-share-alt color-primary"}
          iconPos="right"
          classNames={"shadow-light margin0 hide-in-mobile bg-color-primar"}
        /> */}

        <div className="hide-in-desktop">
          <Button2
            configObj={{}}
            action={shareClickHandle}
            label={""}
            fontAwesomeIcon={"fas fa-share-alt color-primary"}
            iconPos="left"
            classNames={"no-shadow margin0 bg-color-"}
          />
        </div>


        
      </div>
    )
  }
  

   
  return (
    <>

          <Loader
            id="moodboardSaveLoader"
            data={{message:"Saving.."}}
            loaderType="dotsText"
            className="fade-background-loader"
          />

        <InnerPageHeader 
          configObj={props.configObj}
          componentRight={Right}
          componentMiddle={HeaderMiddle}
          goBack={()=>goBack()}
          moduleName="moodboard"
        />
 
      <Summary
        configObj={props.configObj}
        categoriesStatus={props.categoriesStatus}
        areasList={props.areasList}
        updateProjectAreaCategories={props.updateProjectAreaCategories}
        isOpenSummary={isOpenSummary}
        setIsOpenSummary={setIsOpenSummary}
        callBack={()=>setIsOpenSummary(false)}
      />
      
</>
  );
};

export default MoodboardHeader;
