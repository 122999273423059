import React from 'react';
 

const HomeButton: React.FC<{
}> = (props) => {

 function handleClick() {
     window.history.back()
 }


  return (
    <div
    className="top-left zindex100 click-effect white-card shadow-light center-aligned-column home-button-mobile"
    style={{ width: "30px", height: "30px" ,margin:"4px 5px"}} onClick={handleClick}>
    <i className="fas fa-chevron-left color-dark font-normal"></i>
  </div>
       
  );
};

export default HomeButton;
