import { Layout } from "./layout"

export class Layout4 extends Layout{


    name:string = "layout5a"

    constructor(width:number,height:number){
        super(width,height)
    }

    setPadding(padding:number){
        this.padding = padding
    }

    getLayoutsList(){
        return ["a","b"]
    }

    getBlockParameters(layoutName:string,index:number){
        switch (layoutName.toLocaleLowerCase()) {
            case "a":
                return this.getBlockParametersLayoutA(index)
            
            case "b":
                return this.getBlockParametersLayoutB(index)
            default:
                break;
        }
    }

    getBlockParametersLayoutA(index:number){

        switch(index){
            case 1:
                this.block1 = {
                    width: this.stageWidth * 0.5 - this.padding,
                    height: this.stageHeight * 0.7 - this.padding,
                    x: 0 + this.padding,
                    y: 0 + this.padding * 3
                }
                return this.block1
            case 2:
                this.block2 = {
                    width: this.stageWidth * 0.25 - this.padding,
                    height: this.stageWidth * 0.25 - this.padding,
                    x: this.block1.x + this.block1.width + this.padding * 3,
                    y: this.padding
                }
                return this.block2

            case 3:
                this.block3 = {
                    width: this.block2.width,
                    height: this.block2.height,
                    x: this.block2.x,
                    y: this.block2.y + this.block2.height + this.padding
                }
                return this.block3

            case 4:
                this.block4 = {
                    width: this.block2.width,
                    height: this.block2.height,
                    x: this.block3.x ,
                    y: this.block3.y + this.block3.height + this.padding
                }
                return this.block4
            
        }

    }

    getBlockParametersLayoutB(index:number){

        switch(index){
            case 1:
                this.block1 = {
                    width: this.stageWidth * 0.40 - this.padding,
                    height: this.stageHeight * 0.48 - this.padding,
                    x: 0 + this.padding,
                    y: 0 + this.padding
                }
                return this.block1
            case 2:
                this.block2 = {
                    width: this.block1.width / 2,
                    height: this.block1.height / 2,
                    x: this.block1.x + this.padding * 2,
                    y: this.block1.y + this.block1.height + this.padding * 2
                }
                return this.block2
         

            case 3:
                this.block3 = {
                    width: this.block2.width,
                    height: this.block2.height,
                    x: this.block1.x + this.block1.width + this.padding * 5,
                    y: this.block1.y + this.padding * 2
                }
                return this.block3
            
            case 4:
                this.block4 = {
                    width: this.block1.width,
                    height: this.block1.height,
                    x: this.block3.x - this.padding,
                    y: this.block3.y + this.block1.height - this.padding * 2
                }
                return this.block4
            
        }

    }
}
