import React, { useEffect, useState } from "react";
import Modal from "../../../../common/shared-components/Modals/Modal";
import Canvas from "./Canvas";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import { applicationConfig, getRandomString, waitFor } from "../../../../../utils/methods";
import $ from "jquery"
import { IonModal } from "@ionic/react";
 

const VariantCreater: React.FC<{
    product:any
    confirmHandle:()=>void
}> = (props) => {
 
  

    const[isOpen,setIsOpen] = useState(false)

    useEffect(()=>{
        if(isOpen){
            if(props.product){
                STORE.storeApi.pricing.getModulePrice(props.product?.product_name).then(data=>{
                  const result = data.data.data
                  STORE.data.modulesPrice = result
                }).catch(err=>{
                  console.log(err)
                })
            }
        }
    },[isOpen])

    function Footer() {
        return(
          <div className="">
              <Button2
                  configObj={{id:""}}
                  action={confirmAction}
                  label={`Confirm`}
                  fontAwesomeIcon={"fas fa-arrow-right color-primary"}
                  iconPos=""
                  classNames={"no-shadow button-large store-bg-color-primary"}
              />
          </div>
        )
    }


    async function confirmAction() {
        STORE.productConfiguration.isCustomVariant = true
        STORE.productConfiguration.updatePrice()
        let varianName = getRandomString(8)
        STORE.productConfiguration.variant.variantName = varianName
        STORE.productConfiguration.setCustomModulesList()

        const canvas = $("#mainMoodboardCanvasContainer").find("canvas")
        canvas[0].toBlob(async (blob) => {
            const file = new File([blob], 'myImage.png', { type: 'image/png' });
            let fileName = `${varianName}.png`
            await uploadVariantImage(file,fileName)
            props.confirmHandle()
            setIsOpen(false)
        }, 'image/png');
    }

    async function uploadVariantImage(file:any,fileName:string) {
        let albumName = "public"
        var albumPhotosKey = encodeURIComponent(albumName) + "/estre/custom-variants/" + STORE.currProduct.category_name + "/" + STORE.currProduct.product_name + "/"   
        await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,file,fileName,true)
    }

  
    return (
    <>
        <IonModal
            id=""
            cssClass="large-x-modal"
            onDidDismiss={()=>setIsOpen(false)}
            isOpen={isOpen}
            >
            <div className="full-height-width bg-color-ex-light" >
                <div className="header-content center-aligned-column bg-color-dark color-white heading2">
                    Create Variant
                </div>
                <div className="body-content">
                    {isOpen?
                        <Canvas product={props.product} isOpen={isOpen}/>
                    :null}
                </div>
                <div className="footer-content bg-color-white border-top center-aligned-column">
                    <Footer/>
                </div>
            
            </div>
        </IonModal>  

        {/* <div id="variantCreatorWrapper" className="display-none top-left full-height-width bg-color-white" style={{position:"fixed",zIndex:1001}}>
            <div className="modal-wrapper full-height-width">
                <div className="modal-header"></div>
                <div className="modal-body">
                    {isOpen?
                        <Canvas product={props.product} isOpen={isOpen}/>
                    :null}
                </div>
                <div className="modal-footer"></div>
            </div>
        </div> */}
        
        <div onClick={()=>setIsOpen(true)} className={`center-aligned-column bg-color-light item position-relative  white-card shadow-light`}>
                <div className="pointer-none midde full-width">
                <div className="padding5 center-aligned-row"><i className={`icon color-warning fas fa-plus-square font-large`}/></div>
                <div className="heading2  font-md-small pc-color-primary text text-center text-ellipsis text-ellipsis-mobile">Make Your Own</div>
                </div>
        </div>
    </>
    );
};

export default VariantCreater;
