import { InventoryApi } from "../../services/InventoryApis"

export var EstreInventory:InventoryConfiguration = null


export class InventoryConfiguration{

    InventoryApi:InventoryApi = new InventoryApi()

    data = new Data()

    constructor(){
        EstreInventory = this
    }
  
    
}


class Data{

    materialsList = []
 

    constructor(){
        
    }


    async fetch(){
        const promises = [EstreInventory.InventoryApi.stock.get()]
        return await Promise.all(promises).then(data=>{
            this.materialsList = data[0].data.data || []
        }).catch(err=>{
            console.log(err)
        })
    }
}