import $ from "jquery";
import React, { useRef, useState } from "react";
import { getForegroundSavedConfig } from "../../../../../services/api";
import { addItemToLocalStorage } from "../../../../../utils/cache/localstorage";
import { applicationConfig } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import { showComponentLoader } from "../../../../../utils/UI_methods/global";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ProductImages from "../../../projects/projectActions/moodboard/controls/ProductImages";
import Home from "../../productActions/Home";
 
const ChangeProduct: React.FC<{
    changeProduct:(val:any)=>void
}> = (props) => {

    const[isOpenProduct,setisOpenProduct] = useState(false)
    const [isOpenOptions, setIsOpenOptions] = useState(false);
    const [currProduct,setCurrProduct] = useState<any>(null);

    const ref = useRef(null)
    const elementRef = useRef(null)

    
    async function  changeProduct(product:any) {
        addItemToLocalStorage("currProduct",product)
        STORE.currProduct = product
        setCurrProduct(product)
        addItemToLocalStorage("sharedItem",null)
        // setIsOpenOptions(true)
        $(ref.current).fadeIn()
    }

    function close() {
        $(ref.current).fadeOut()
    }

    async function loadApiData(product:any) {
        let promiseArray = [getForegroundSavedConfig(0,product.product_id)]
        Promise.all(promiseArray)
        .then(function (apidata) {
            applicationConfig.data.customizerSavedConfigs = apidata[0]
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function customizein3dAction() {
        showComponentLoader("mainLoaderSpinner")
        setIsOpenOptions(false)
        window.location.href = "/customizein3d"
    }

 

    function closePanel(){
        $("#changeProductWindow").fadeOut()
    }
 
    return (
        <>

        


        <div id={"changeProductWindow"} className="full-height-width vertical-modal padding0 position-fixed top-left center-aligned-column" style={{display:"none",zIndex:1001,background:"rgba(0,0,0,0.5)"}}>
        <div className="customizein3d-change-product padding10 moodboard-wrapper bg-color-white white-card shadow-medium" style={{width:"100%",height:"97%",maxWidth:"55rem"}}>
            <div className="full-height-width position-relative">
                <div className="header-content position-relative border-bottom">
                
                    <div className="right-middle">
                        <Button2
                            configObj={{}}
                            action={()=>{closePanel()}}
                            label={""}
                            fontAwesomeIcon={"fas fa-times color-danger"}
                            iconPos="left"
                            classNames={"shadow-light"}
                        />
                    </div>

                    <div className="marginleft10 left-middle heading1 center-aligned-column">Select Product</div>

                </div>
                <div className="body-content-no-footer position-relative">
                    <ProductImages
                        setProductModelSrc={()=>{}}
                        configObj={applicationConfig}
                        currArea={""}
                        action={(product)=>{changeProduct(product)}}
                        moduleName="customizein3d"
                        dragStart={()=>{}}
                        parentContainerId="changeConfiguratorProduct"
                    />
                </div>

                <div ref={ref} className="full-height-width padding0 top-left zindex100" style={{display:"none"}}>
                {currProduct?
                        <Home
                            configObj={applicationConfig}
                            currProductId={currProduct?.product_id}
                            setOpenProjectAction={()=>close()}
                            currProduct={currProduct}
                            viewIn360Action={customizein3dAction}
                            gotoCustomizeIn3D={customizein3dAction}
                            quickCustomizeAction={()=>{}}
                        /> 
                :null}
                
                </div>

            </div>

    </div>
    </div>
        </>
       
    );
};

export default ChangeProduct;
