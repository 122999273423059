import React, { useEffect, useState } from "react";
import { STORE } from "../../../../../../utils/store/storeConfiguration";
import { IonModal } from "@ionic/react";
import CreateNewAddress from "./CreateNewAddress";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import $ from "jquery"

 

const SavedAddress: React.FC<{
  selectAddress:(address)=>void
}> = (props) => {

  const[savedAddress,setSavedAddress] = useState([])
  const[isOpenAddress,setIsOpenAddress] = useState(false)

  useEffect(()=>{
    if(STORE.activeCustomer){
      fetchAddress()
    }
  },[])

  function fetchAddress() {
    STORE.storeApi.getCustomerAddress(STORE.activeCustomer.id).then((data:any)=>{
      if(!data.error){
        if(data.data?.data?.length){
          setSavedAddress(data.data.data)
        }
      }
    })
  }


  function selectAddressHandle(event:any) {
    $(".confirm-address-button").addClass("display-none")
    $(event.target).parent().parent().find(".confirm-address-button").removeClass("display-none")
  }

  function postAddressSaveAction() {
    setIsOpenAddress(false)
    fetchAddress()
  }

 
  return (
    <div className="full-width padding5">
    {/* <div className="heading3 bg-color-light padding10">Saved Addresses</div> */}

      <IonModal
        id=""
        cssClass="vertical-modal"
        onDidDismiss={()=>setIsOpenAddress(false)}
        isOpen={isOpenAddress}
        >
        <CreateNewAddress callBack={postAddressSaveAction}/>
      </IonModal>

      <div className="address-list ">
        {savedAddress.map((address:any,index:number)=>{
          return(
            <div className="full-width border padding10 marginTop5" key={index}>
              <div className="display-flex-row no-wrap">
                {/* <div className="heading2 center-aligned-column font-md-small color-primary">Address {index+1}</div> */}
                <div className="center-aligned-column"><input type="radio" checked={index === 0 ? true : false}   onChange={selectAddressHandle} className="input" name="address" /></div>

                <div className="">
                  <div className="heading3  marginleft10">
                    {STORE.activeCustomer.customerName}

                    <div className="heading3 marginTop5 color-dark font-md-small">
                      {address.address_line_1},&nbsp;{address.city},&nbsp;
                      {address.state},&nbsp;
                      {address.country},&nbsp;
                      {address.postal_code}
                    </div>

                    <div className="marginTop10 confirm-address-button">
                      <Button2
                        configObj={{id:""}}
                        action={()=>{props.selectAddress(address)}}
                        label={"Deliver here"}
                        fontAwesomeIcon={"fas fa-plus color-white"}
                        iconPos=""
                        classNames={`no-shadow button-small cart-button cart-button-warning bg-color-warning margin0 bg-color-`}
                      />
                    </div>

                  </div>
                </div>
              </div>

              

            </div>
           
          )
        })}
        <div className="color-primary border click-effect marginTop5" onClick={()=>setIsOpenAddress(true)} style={{padding:"10px 15px"}}>
          <span><i className="fas fa-plus color-primary"></i></span>
          <span className="marginleft10 heading3 font-md-small color-primary">Add new address</span>
        </div>
      </div>
  </div>
  );
};

export default SavedAddress;
