import { IonModal } from '@ionic/react';
import $ from "jquery";
import React, { useEffect } from 'react';
const Modal: React.FC<{
    isOpen:boolean
    onDismiss:(value:boolean)=>void
    classNames:string
    id:string
    Content:any
}> = (props) => {

  useEffect(()=>{
    setTimeout(() => {
      updateIconPosition()
      window.addEventListener("resize",updateIconPosition)

      return ()=>{
        window.removeEventListener("resize",updateIconPosition)
      }
    }, 200);
  },[props.isOpen])

  function updateIconPosition() {
    $("#iconContainerModel2").height($(".modal-header").height())
  }

 

  return (
    <IonModal  onDidDismiss={() => props.onDismiss(false)} isOpen={props.isOpen}
      id={props.id}
      mode="ios"
      cssClass={props.classNames}>
        <>
        {/* <div className="top-right zindex30 center-aligned-column" id="iconContainerModel2" style={{padding:"0 0.8rem"}} >
          <Button2
            configObj={{id:""}}
            action={()=>props.onDismiss(false)}
            label={""}
            fontAwesomeIcon={"fas fa-times color-black"}
            iconPos="left"
            classNames={"no-shadow bg-color-light"}
          />
        </div> */}
            <props.Content/>
            </>
      </IonModal>
  );
};

export default Modal;
