import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { showComponentLoader, showGlobalToast, showToast, updateLoaderProgress, updateLoaderProgressWithInterval } from "../../UI_methods/global"
import { checkFromCache, untarAndSaveToCache } from "../../cache/cache"
import { applicationConfig, categoryFilteredTextures, getApplicationFiltersMaterials, getSubDomainName, stringWithoutWhiteSpace } from "../../methods"
import { getOriginalPartName, getProductNameWithoutVariant } from "../area3dmodel/helper"
import { addModuleToConfiguration, downloadTexturesTarFiles, loadAndApplyTexture, updateGroupCenter } from "../common"
import { MODULE_NAME, disableLoading, group, loadModuleToTheScene, configuration as modulesConfiguration, modulesLoader, postAddingActions } from "../modules/customizein3d"
import { getAddedProductFromScene, getAllUrls } from "../modules/helper"
import { enableModelViewer } from "../webxr"
import { getTextureInfo } from "../UImethods"
import { STORE } from "../../store/storeConfiguration"
import { downloadBlobUrl } from '../../webxr/webxr'
import $ from "jquery"


export var dracoLoader = new DRACOLoader()
export var productLoader = null
export var fontLoader = new FontLoader()


export const noCustomizableObjects = ["leaf","wire","carpet","wood","chrome","cgaxis","plastic","ficus","cramic","soil"]
export const extensions = [".png",".webp",".jpg",".jpeg"]


export async function productViewerInit(product:any,containerId:string,url:any = null) {
 
  if(!url){
    let productId = product.product_id || product.productId
    let clientName = applicationConfig?.clientName
    if(product){
      clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
    }
    let savedConfig = applicationConfig?.data?.savedProductConfiguration?.find(currConfig=> currConfig.product_id === productId && Number(currConfig.is_default) === 1)
    url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/models/${clientName}/${encodeURIComponent(product.category_name || product.categoryName)}/${encodeURIComponent(product.sub_category_name || product.subCategoryName)}/${encodeURIComponent(product.product_name || product.productName)}.glb`
  }
  enableModelViewer(url,containerId)
}



export class ModelViewer{
  MODEL_VIEWER: any
  product:any
  OBJECT_MATERIALS:any= []
  
  constructor(){

  }

  init(product:any,containerId:string,variantName:string = ""){
    this.product = product
    let url = ""
    if(variantName){
      url = this.getVariantUrl(variantName)
    }else{
      url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/models/${applicationConfig?.clientName}/${product.category_name || product.categoryName}/${product.sub_category_name || product.subCategoryName}/${product.product_name || product.productName}.glb`
    }
    this.loadWithUrl(containerId,url)
  }

  loadWithUrl(containerId:string,url:string,isUpdateFinish:boolean = false){
    this.MODEL_VIEWER = enableModelViewer(url,containerId)
    this.MODEL_VIEWER.addEventListener("load", (ev) => {
      this.OBJECT_MATERIALS = this.MODEL_VIEWER?.model?.materials;
      if(isUpdateFinish){
        this.applyPrevTexture()
      }
    })
  }

  updateSrc(url:string){
    try {
      this.MODEL_VIEWER.src = url;
    } catch (error) {
      showGlobalToast("Something went wrong",2000,"error")
    }
  }

  updateSrcWithVariantName(variantName:string) {
    try {
      let url = this.getVariantUrl(variantName)
      this.MODEL_VIEWER.src = url;
    } catch (error) {
      showGlobalToast("Something went wrong",2000,"error")
    }
  }

  getVariantUrl(variantName:string){
    let product = this.product
    let productName = product.product_name ||  product.productName
    return `https://opusassets.s3.ap-south-1.amazonaws.com/public/models/${applicationConfig?.clientName}/${product.category_name || product.categoryName}/${product.sub_category_name || product.subCategoryName}/${productName}/variants/${productName}_${encodeURIComponent(variantName)}.glb`
  }

  async updateFinish(event:any,textureInfo:any = null){
    try {
      if(!textureInfo){
        textureInfo = getTextureInfo(event)
      }
      let urls = getAllUrls(textureInfo)
      if(getSubDomainName() === "elevate"){
        urls.textureUrl = urls.textureUrl.replace(".png",".webp")
      }
      let url = urls.textureUrl
      const lastDotIndex = url.lastIndexOf('.');
      const defaultExtension = url.substring(lastDotIndex);

      
      downloadTexturesTarFiles([urls.textureUrl],[textureInfo]).then(async (result)=>{

        for (let i = 0; i < extensions.length; i++) {
          let extension = extensions[i];
          let otherUrl = url?.replace(defaultExtension,extension);
          if(!otherUrl){
            return; // Exiting the loop if URL is invalid
          }
          try {
              await checkFromCache(otherUrl)?.then((url: any) => {
                for (const currMaterial of this.OBJECT_MATERIALS) {
                  let partName = currMaterial.name.toLowerCase()
                  if(!noCustomizableObjects.find(currPart => partName.includes(currPart))){
                    this.createAndApplyTexture(currMaterial,'baseColorTexture', url)
                  }
                }
                return
              })
              .catch((err) => {
              });
          } catch (error) {
            console.error(`File not found`);
          }
        }
      })
    } catch (error) {
      showGlobalToast("Something went wrong",2000,"error")
    }
    
  }

  applyPrevTexture(){
    if(STORE.productConfiguration.texture){
      STORE.modelViewer.updateFinish(null,STORE.productConfiguration.texture)
    }
  }

  applyPBRTexture = (material, channel, textureValue) => {
    const texture = material.pbrMetallicRoughness.baseColorTexture.texture;
    texture.source.setURI(textureValue)
}

  createAndApplyTexture = async (material:any,channel:string,url:any) => {
    const texture = await this.MODEL_VIEWER.createTexture(url);
    if (channel.includes('base') || channel.includes('metallic')) {
      material.pbrMetallicRoughness[channel].setTexture(texture);
    } else {
      material[channel].setTexture(texture);
    }
  }


  captureViewerScreenshot(){
    const screenshotURL = this.MODEL_VIEWER.toDataURL();
    // const screenshotURL = URL.createObjectURL(blob);
    let fileName = String(Number(new Date())) + ".png"
    downloadBlobUrl(screenshotURL,fileName)
 
  }
 
  appendFinishUI(){

  }

}
  

export async function loadProductModelToScene(product: { productName: string, categoryName: string, subCategoryName: string, configuration: any },
    groupToAttach: any,isViewProductMode = false,isAddToConfig:boolean = true): Promise<any> {
    showComponentLoader("canvasLoader");
    updateLoaderProgress("canvasLoader", 0, 1, "Please wait");
  
    const { productName, categoryName, subCategoryName, configuration } = product;
    const productNameWithoutVariant = getProductNameWithoutVariant(productName);
    // let tarfilekey = applicationConfig.awsConfig.getTarFileKey("productModels",{clientName:applicationConfig?.clientName,productName:stringWithoutWhiteSpace(productNameWithoutVariant),categoryName:categoryName,subCategoryName:product.sub_category_name})
    const tarfilekey = applicationConfig.awsConfig.getTarFileKey("productModels", { clientName: applicationConfig?.clientName,productName:stringWithoutWhiteSpace(productNameWithoutVariant), categoryName, subCategoryName });
    const urlPrefix = `productModels/${applicationConfig?.clientName}/${categoryName}/${subCategoryName}`;
    const key = `${urlPrefix}/${stringWithoutWhiteSpace(productNameWithoutVariant)}.glb`;
  
    try {
      await untarAndSaveToCache(applicationConfig.awsConfig, urlPrefix, tarfilekey.replace(/\s/g, ""));
    } catch (error) {
        showToast("Not found",2000,"error")
        disableLoading()
        throw error;
    }
  
    updateLoaderProgressWithInterval("canvasLoader", "Loading model");
    try {
      const url:any = await checkFromCache(key);
      const modelObject:any = await loadModuleToTheScene(productLoader || modulesLoader, url);
  
      group.attach(modelObject);
      updateGroupCenter(group);
      modelObject.userData.configuration = product;
      disableLoading()

      product.configuration = []
  
      if (!isViewProductMode) {
        postAddingActions(modelObject);
        updateMaterials();
      }
      if(isAddToConfig){
        addModuleToConfiguration(product,modelObject,modulesConfiguration)
      }else{
        if(modulesConfiguration.modules[0]){
          modulesConfiguration.modules[0].moduleObjectId = modelObject.uuid
        }
      }
      return modelObject;
    } catch (error) {
      disableLoading();
      throw error;
    }
  }
  

export function getProductUrlFromCache(product:any) {
    return new Promise(async (resolve,reject)=>{
        let productName = product.productName
        let categoryName = product.categoryName
        let subCategoryName = product.subCategoryName
        
        let productNameWithoutVarient = stringWithoutWhiteSpace(getProductNameWithoutVariant(productName)) 
        let key:string = `productModels/${applicationConfig?.clientName}/${categoryName}/${subCategoryName}/${productNameWithoutVarient}.glb`
        await checkFromCache(key)?.then((url:any)=>{
            resolve(url)
        }).catch(err=>{
            reject(err)
        })  
    })
   
}


export function updateProductFinish(object:any,textureInfo:any){
    if(!object){
        disableLoading()  
        return
    }
    // object.traverse(mesh=>{
        loadAndApplyTexture(object,textureInfo,object.userData.configuration.productName,MODULE_NAME,object.userData.configuration.categoryName,object.userData.configuration).then(data=>{
        // if(Number(index)===modulesList.length-1){
        //     updateScreenshot()
        // }
        disableLoading()
        }).catch(err=>{
        console.log(err)
        })
    // })
}


export function updateMaterials() {
  return
    let materials = getApplicationFiltersMaterials("Customizer",applicationConfig?.data.materials,applicationConfig?.data.applicationMaterials)
    let objectMaterialTypes = applicationConfig?.data.objectMaterialTypes
    let productsMeshNames = getAllMeshNames()
    objectMaterialTypes = objectMaterialTypes.filter(currObject => productsMeshNames.includes(currObject.object_name))
    materials = categoryFilteredTextures(materials,objectMaterialTypes,modulesConfiguration.product?.categoryName)
    applicationConfig.functions?.customizein3d?.setTextures(materials)
}

export function getAllMeshNames() {
    let names = []
    let object = getAddedProductFromScene()
    object.traverse(mesh=>{
        if(mesh.isMesh){
            names.push(getOriginalPartName(modulesConfiguration.product.categoryName,mesh.name))
        }
    }) 
    return names
}

