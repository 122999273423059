import React, { useState } from "react";
import { Customer, STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import StartCustomerSession from "../customer/StartCustomerSession";

 
const SelectCustomer: React.FC<{
  confirmHandle:()=>void
}> = (props) => {


  const[customer,setCustomer] = useState(STORE.activeCustomer)
  const[isOpenStartSession,setIsOpenStartSession] = useState(false)

  function createCustomerCallback(customer:Customer) {
    setCustomer(customer) 
  }

  function ActiveCustomer() {
    return(
      <div className="display-flex-row space-between full-width">
        <div className="display-flex-row">
        <div className="marginleft10">
          <div className="heading2 letter-spacing font-large marginTop5 color-black">
            {customer?.customerName}
          </div>

          <div className="heading4 font-md-small">
            +91 {customer?.mobile}
          </div>
        </div>
        </div>

        <div className="marginTop10 display-flex-row no-wrap">
        <Button2
          configObj={{id:""}}
          action={()=>{props.confirmHandle()}}
          label={"Confirm"}
          fontAwesomeIcon={"fas fa-times color-danger"}
          iconPos=""
          classNames={"no-shadow cart-button width-auto text-center cart-button-warning"}
        />

        <Button2
          configObj={{id:""}}
          action={()=>{setIsOpenStartSession(true)}}
          label={"Change"}
          fontAwesomeIcon={"fas fa-times color-danger"}
          iconPos=""
          classNames={"no-shadow cart-button cart-button-warning-outline"}
        />
        
        </div>

      </div>
    )
  }


  return (
    <div className="ion-padding">
    <StartCustomerSession isOpen={isOpenStartSession} setIsOpen={setIsOpenStartSession} createCustomerCallback={createCustomerCallback}/>

      {customer?
        <ActiveCustomer/>
      :
      <div className="marginTop10 center-aligned-row full-width no-wrap">
         <Button2
              configObj={{id:""}}
              action={()=>{setIsOpenStartSession(true)}}
              label={"Select customer"}
              fontAwesomeIcon={"fas fa-times color-danger"}
              iconPos=""
              classNames={"no-shadow cart-button cart-button-warning-outline"}
          />
      </div>
      }
      
     

      





     
    </div>     
  );
};

export default SelectCustomer;
