import React, { useEffect, useState } from "react";
import { deleteUser, getUsersList, updateUser } from "../../../../../services/api";
import { showGlobalToast, showToast } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getFilteredArray, getObjectArrayOfDistictValues, getObjectByParameter, openNativeShareOptions, stringWithoutWhiteSpace } from "../../../../../utils/methods";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import { getFormattedPrice, getRandomNumber } from "../../../../../utils/products/productviewer_shopify";
import { getFormatedDate } from "../../../../../utils/store/crm/crm";
import { archiveOutline } from "ionicons/icons";
import ControlsTab from "../../../../common/ui-components/tabs/ControlsTab";
import { IonModal } from "@ionic/react";
import CartProductSummary from "../../../products/store/cart/CartProductSummary";
import MaterialImage from "../../../../common/ui-components/elements/MaterialImage";
import { updateOrderStatus } from "../../../../../services/storeApis";
 
 
 

const OrdersList: React.FC<{
    
}> = (props) => {



  const[allOrdersList,setAllOrdersList] = useState([])
  const[ordersList,setOrdersList] = useState([])
  const[orderItemsList,setOrderItemsList] = useState([])
  const[filteresOrders,setFilteredOrders] = useState([])

  const[isOpenSummary,setIsOpenSummary] = useState(false)

 


  const [currTab, setCurrTab] = useState<string>("Pending");


  var tabItems = [
    {tabName:"Pending",
    label:"Open Orders",
    icon:"fas fa-menu color-dark",
    count:getFilteredArray(ordersList,"status","Pending").length
    },
    {tabName:"QuotationShared",
    label:"Quotation Shared",
    ionIcon:"",
    count:getFilteredArray(ordersList,"status","QuotationShared").length
    },
    {tabName:"Paid",
      label:"Paid",
      ionIcon:"",
      count:getFilteredArray(ordersList,"status","Paid").length
    },
    {tabName:"TobeFulfilled",
      label:"TobeFulfilled",
      ionIcon:"",
      count:getFilteredArray(ordersList,"status","TobeFulfilled").length
    },
    {tabName:"Closed",
      label:"Closed",
      ionIcon:"",
      count:getFilteredArray(ordersList,"status","Closed").length
    },
  ]

  useEffect(()=>{
    fetchOrders()
  },[])

  useEffect(()=>{
    if(ordersList && currTab){
      let result = getFilteredArray(ordersList,"status",stringWithoutWhiteSpace(currTab))
      setFilteredOrders(result)
    }
  },[currTab,ordersList])

  // function fetchOrders() {
  //   STORE.storeApi.orders.getOrders(applicationConfig.clientId).then((data:any)=>{
  //     if(!data.error){
  //       if(data.data?.data?.length){
  //         const orderItems = data.data.data 
  //         console.log(orderItems)
  //         setOrdersList(orderItems)
  //         // const orders = getObjectArrayOfDistictValues(orderItems,"order_id")
  //         // setOrdersList(orders)
  //       }
  //     }
  //   })
  // }
  
  function fetchOrders() {
    STORE.storeApi.orders.getOrders(applicationConfig.clientId).then((data:any)=>{
      if(!data.error){
        if(data.data?.data?.length){
          let orderItems = data.data.data 
          orderItems = orderItems.filter(currOrder => currOrder.client_id === applicationConfig.clientId)
          const orders = getObjectArrayOfDistictValues(orderItems,"order_id")
          setOrdersList(orders)
          setAllOrdersList(orderItems)
        }
      }
    })
  }

  function updateTabHandle(tabName: string) {
    setCurrTab(tabName)
  }

  function viewHandle(order:any){

    if(order.status === "Pending"){
      const result = getFilteredArray(allOrdersList,"order_id",order.order_id)
      setOrderItemsList(result)
      setIsOpenSummary(true)
    }else{
      confirmHandle(order)
    }
    
  }

  function confirmHandle(order:any){
    setIsOpenSummary(false)
    let status = getStatus(order.status)
    if(!status){
      showGlobalToast("Order has beed placed")
      return 
    }
    updateOrderStatus("NA",order.order_id,status).then(data=>{
      console.log(data)
      fetchOrders()
      showGlobalToast("Success",2000)
    }).catch(err=>{
      console.log(err)
      showGlobalToast("Something went wrong",2000,"error")
    })
  }

  function getStatus(currStatus:string){
    if(currStatus === "Pending"){
      return "QuotationShared"
    }
    if(currStatus === "QuotationShared"){
      return "Paid"
    }
    if(currStatus === "Paid"){
      return "TobeFulfilled"
    }
    if(currStatus === "TobeFulfilled"){
      return "Closed"
    }
    return ""
  }

  

  function ItemsList() {
    return(
      <div className="full-width">
      {orderItemsList.map((item:any,index:number)=>{
      const material = getObjectByParameter(applicationConfig?.data?.materials,"id",item.material_id)
      return(
        <div className="display-flex-row position-relative white-card no-shadow border cart-item padding5 space-between border-bottom" key={index}>
          {material?
           <div className="product-details-cart display-flex-row no-wrap">
            <div className="">
              <div className="image">
                <MaterialImage material={material}/>
              </div>
            </div>
            
            <div className="other-details ion-margin-start">

            <div className="config-details">

                <div className="heading1 font-medium color-black">
                    {material.material_code}
                </div>
                <div className="heading3 marginTop5 color-primary" >{"Standard"}</div>

                <div className="price-details">
                  <div className="display-flex-row space-between item">
                    <span className="heading3">MRP</span>
                    <span className="rupee-sign heading3 center-aligned-column">{0}</span>
                  </div>
                  <div className="display-flex-row space-between item">
                    <span className="heading3 color-success">Discount</span>
                    <div className="heading3 font-md-small rupee-sign"><input type="number" className="input" defaultValue={0} style={{borderColor:"#eee",width:"7rem"}} /></div>
                  </div>
                  <div className="display-flex-row space-between item no-border">
                    <span className="heading3">ITEM PRICE</span>
                    <span className="rupee-sign heading3 center-aligned-column">{getFormattedPrice(0)}</span>
                  </div>
                </div>
                </div>
            </div>

          </div>
          
          :null}
         
        </div>
      )
      })}
      </div>
    )
    
  }

  return (
    <div className="full-height-width overflow-y-scroll">

    <IonModal
      id=""
      cssClass="vertical-modal"
      onDidDismiss={()=>setIsOpenSummary(false)}
      isOpen={isOpenSummary}
      >
        <>
        <div className="header-content bg-color-dark position-relative">
          <div className="middle heading2 color-white">Share Quotation</div>
          <div onClick={()=>{setIsOpenSummary(false)}} className="right-middle"><i className="fas fa-times color-white font-medium"></i></div>
        </div>
        <div className="body-content padding10">
          {orderItemsList.length?
            <ItemsList  />
          :null}
        </div>
        <div className="footer-content center-aligned-column">
        <Button2
            configObj={{id:""}}
            action={()=>{confirmHandle(orderItemsList[0])}}
            label={"Confirm"}
            fontAwesomeIcon={"fas fa-share color-success"}
            iconPos=""
            classNames={"no-shadow bg-color-success button-medium"}
          />
        </div>
       
        </>
    </IonModal>

    <div className="orders-type-tabs" style={{width:"100%",maxWidth:"70rem"}}>
        <ControlsTab
          configObj={{}}
          tabItems={tabItems}
          currSelectedValue={currTab}
          action={updateTabHandle}
          theme="black"
        />
    </div>

    <div className="padding10 full-width">
          <table className="table full-width store-quotation-table orders-list-table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Order ID</th>
                    <th>Order Date</th>
                    <th>Collection</th>
                    {/* <th>Material</th> */}
                    <th>Customer Name</th>
                    {/* <th>Payment Status</th> */}
                    <th>Actions</th>
                </tr>
            </thead>
            {filteresOrders?filteresOrders.map((order:any,index:any)=>{
                const item = order
                const material = getObjectByParameter(applicationConfig?.data?.materials,"id",item.material_id)
                const user = getObjectByParameter(applicationConfig?.data?.usersList,"id",item.user_id)
                  return (
                        <tbody key={index}>
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td className="heading2" >{`#${order.order_id}`}</td>
                            <td>{getFormatedDate(order.order_date)}</td>
                            <td className="color-primary heading2">{material?.collection_name}</td>
                            {/* <td>{material?.material_code}</td> */}
                            <td>{user?.business_name || user?.username}</td>
                            {/* <td className="color-danger">unpaid</td> */}
                            <td style={{margin:"auto"}}>
                            <Button2
                              configObj={{id:""}}
                              action={()=>{viewHandle(item)}}
                              label={currTab === "Pending"?"Share Quotation":currTab==="QuotationShared"?"Set As Payment Received":currTab === "Paid"?"Set As Order Dispatched":currTab === "TobeFulfilled"?"Set As Order Received":"Order Confirmed"}
                              fontAwesomeIcon={"fas fa-share color-white"}
                              iconPos=""
                              classNames={"no-shadow margin0 bg-color-primary button-small "}
                            />
                            </td>
                          </tr>
                        </tbody>
                  )
              }):null}

          </table>
      </div>
  
  </div>
  );
};

export default OrdersList;
