import React, { useState } from "react";
 

const Footer: React.FC<{
}> = (props) => {
 
 
  return (

    <div className="full-width bg-color-dark center-aligned-column" style={{height:"7rem"}}>
         <p className="color-white heading4">&copy; 2024 My Store. All rights reserved.</p>
    </div>
    
  );
};

export default Footer;
