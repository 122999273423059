import $ from "jquery"
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter"
import { DRACOExporter } from "three/examples/jsm/exporters/DRACOExporter"
import { DracoEncoderModule } from "three/examples/jsm/libs/draco/draco_encoder"
import { hideComponentLoader, showComponentLoader } from "../UI_methods/global"
import { configuration, group } from "../customizein3d/modules/customizein3d"
import { applicationConfig, stringWithoutWhiteSpace } from "../methods"


export var gl = null
export function activeXRtest() {
    document.getElementById("sceneViewertest").click()
}

export function getBlobUrlFromExporter() {
    showComponentLoader("mainLoaderSpinner")
    try {
        return new Promise((resolve,reject)=>{
            var exporter = new GLTFExporter();
            exporter.parse(group, function (gltf:any) {
                const blob = new Blob([JSON.stringify(gltf)], { type: 'application/json' });
                console.log("Blob size:", blob.size, "bytes");
                const blobURL = URL.createObjectURL(blob);
                resolve(blobURL)
            }, { binary: true });
           
        })
    } catch (error) {
        console.log(error) 
    } 
}

export function getBlobUrlFromExporterCompressed() {
    showComponentLoader("mainLoaderSpinner")
    try {
        return new Promise((resolve,reject)=>{
            var exporter = new GLTFExporter();
            const dracoExporter = new DRACOExporter();
            exporter.parse(group, function (gltf:any) {
                var script = document.createElement('script');
                script.src = 'https://cdn.jsdelivr.net/npm/three@0.160.0/examples/jsm/libs/draco/draco_encoder.js';
                script.onload = () => {
                    var compressedGltf = dracoExporter.parse(gltf, {});
                    const json = JSON.stringify(compressedGltf);
                    const blob = new Blob([json], { type: 'application/json' });
                    console.log("Blob size:", blob.size, "bytes");
                    const blobURL = URL.createObjectURL(blob);
                    resolve(blobURL);
                };
                document.body.appendChild(script);
            }, { binary: true });
           
        })
       
    } catch (error) {       
        console.log(error) 
    } 
}

export function downloadBlobUrl(blobURL:any,fileName:string) {
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = fileName; // Set default filename
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();  
}

export async function activateXR(scene,camera,renderer) {
    try {
        var exporter = new GLTFExporter();
        exporter.parse(group, function (gltf) {
            var Url = saveArrayBuffer(gltf, 'scene.glb');
            let modelUrl = `intent://arvr.google.com/scene-viewer/1.0?file=${Url}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`
            $("#sceneViewer").attr("href", modelUrl);
            setTimeout(() => { document.getElementById("sceneViewer").click() }, 3000);
        }, { binary: true });
    } catch (error) {
        console.log(error) 
    }
  }

  function saveArrayBuffer(buffer, filename) {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    // const blob = new Blob([buffer], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    // saveAs(blob, filename);
    // storeInCache(blob);
    return url;
}

function saveAs(blob, filename) {
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link); // Firefox workaround, see #6594
    let href = URL.createObjectURL(blob);
    let modelUrl = `intent://arvr.google.com/scene-viewer/1.0?file=${href}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`
    link.href = modelUrl
    link.download = filename;
    link.click();
    // URL.revokeObjectURL( url ); breaks Firefox...
  }

  async function saveAndDisplayAr(blob) {
    let fileName = stringWithoutWhiteSpace(configuration.product.productName) + "_" + Number(new Date()) + ".glb"
    let sceneFile:any = new File([blob], fileName);
    await applicationConfig.awsConfig.uploadFile(blob,"customizein3d",fileName,sceneFile).then(data=>{
        let url = "https://opusassets.s3.ap-south-1.amazonaws.com/uploads/share/customizein3d/" + fileName
        let modelUrl = `intent://arvr.google.com/scene-viewer/1.0?file=${url}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`
        $("#sceneViewer").attr("href", modelUrl);
        console.log(fileName,modelUrl)
        setTimeout(() => { 
            hideComponentLoader("mainLoaderSpinner")
            document.getElementById("sceneViewer").click() 
        }, 3000);
    })
  }

