import { IonIcon, IonModal } from "@ionic/react";
import {
  close
} from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { checkFromCache, displayImageFromCache, getThumbnailsUrlKey } from "../../../../utils/cache/cache";
import { applicationConfig, getFilteredArray } from "../../../../utils/methods";
import { hideComponentLoader, showComponentLoader } from "../../../../utils/UI_methods/global";
import Loader from "../../../common/shared-components/main-ui/Loader";
import Inventory from "../../inventory/Inventory";
import MaterialDetails from "./MaterialDetails";
import MaterialList from "./MaterialList";
const Home: React.FC<{
  configObj: any;
  currCollection: string;
  setopenFinishAction: (value: boolean) => void;
  materials:Array<any>
}> = (props) => {
  const [isOpenInventory, setIsOpenInventory] = useState<boolean>(false);
  const [materials, setMaterials] = useState<any>([]);

  const[currMaterial,setCurrMaterial]:any = useState({})

  const[finishImageLoader] = useState("FinishImageLoader")

  
  useEffect(()=>{
    showComponentLoader(finishImageLoader)
    setTimeout(() => {
      displayCurrThumbnailImage()
    }, 200);
  },[currMaterial,isOpenInventory])


  function displayCurrThumbnailImage() {
    let key = getThumbnailsUrlKey(currMaterial.companyName,currMaterial.collectionName,currMaterial.materialCode)
    let image = $("#thumbnailDetailsImage")
    displayImageFromCache(image,key).then(data=>{
      hideComponentLoader(finishImageLoader)
    })
  }

  useEffect(()=>{
    let filteredMaterials = getFilteredArray(props.materials,"collection_name",props.currCollection)
    setMaterials(filteredMaterials)
    setCurrMaterial({
      materialId:filteredMaterials[0]?.id,
      companyName:filteredMaterials[0]?.company_name,
      collectionName:filteredMaterials[0]?.collection_name,
      materialCode:filteredMaterials[0]?.material_code,
    })
  },[props.currCollection,props.materials])

  useEffect(()=>{
    showComponentLoader(finishImageLoader)
    setTimeout(() => {
      displayThumbnails()
      // addEventListnerOnImages()
    }, 300);
  },[materials,isOpenInventory])
  
  async function displayThumbnails() {
    let thumbnails = document.querySelectorAll(".slider-image");
    for (let i = 0; i < thumbnails.length; i++) {
      let companyName = thumbnails[i].getAttribute("data-company-name")
      let collectionName = thumbnails[i].getAttribute("data-collection-name")
      let materialCode = thumbnails[i].getAttribute("data-material-code")
      let key = `thumbnails/OVL/${companyName}/${collectionName}/${materialCode}.png`
      let image = $(thumbnails[i])
      await checkFromCache(key)?.then((url:any)=>{
        if(i===thumbnails.length-1){
          hideComponentLoader(finishImageLoader)
        }
        image.attr("src",url)
      }).catch(err=>{
        console.log(err)
        if(i===thumbnails.length-1){
          hideComponentLoader(finishImageLoader)
        }
      })
    }
  }
 

function Header() {
  return(
    <div className="modal-header">
      <div className="heading3 ">Materials List</div>
      
      <IonIcon
          className="modal-close-icon"
          icon={close}
          ios={close}
          onClick={() => props.setopenFinishAction(false)}></IonIcon>
    </div>
  )

}

function Footer() {
  return(
    <div className="modal-footer">

    </div>
  )
}

  return (
    <React.Fragment>
        <IonModal
          onDidDismiss={()=>setIsOpenInventory(false)} 
          isOpen={isOpenInventory}
          id="inventoryModal"
          cssClass='form-action-modal'
        >
          <Inventory
          configObj={props.configObj}
          closeModal={setIsOpenInventory}
          currState="Finish"
          product={null}
          finishId={currMaterial.materialId}
          />
        </IonModal>
        <Loader
            id={finishImageLoader}
            data={{}}
            loaderType="spinner"
            className="--is-active fade-background-loader"
          />
        {/* <Header/> */}
        {/* <div className="modal-body">
     
        <div className="background-white full-height-width no-wrap">

        <div className="position-relative full-height"> */}
          <MaterialList
            configObj={props.configObj}
            materials={materials}
          />
        {/* </div>
        </div>
    </div>
        <Footer/> */}

      
    </React.Fragment>
  );
};

export default Home;
