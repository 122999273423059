import $ from "jquery";
import React from "react";
import { enableFloorplanMode, sceneBackgrounds } from "../../../../../utils/customizein3d/modules/customizein3d";
import { setCurrConfigAsDefault } from "../../../../../utils/customizein3d/modules/helper";
import { redoHandle, undoHandle } from "../../../../../utils/customizein3d/modules/undo";
import { applicationConfig, getUser } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../ui-components/buttons/Button2";
import ARLinks from "./ARLinks";
import { fullScreenToggleConfigurator } from "../../../../../utils/customizein3d/modules/UI_methods";
import SaveConfigForm from "../common/SaveConfigForm";
import { getWindowWidth } from "../../../../../utils/UI_methods/global";
  
  
  const TopRightActions: React.FC<{
    modulesConfigurtion:any
    isSpacePlannerMode:boolean
    isFloorplanMode:boolean
    isViewProductMode:boolean
    isElevateMode?:boolean
  }> = (props) => {


    const username = applicationConfig?.user?.username



    async function updateVariantImage() {
      if(STORE.productConfiguration && STORE.currProduct ){
        let variantName = STORE.productConfiguration.variant.variantName
        const canvas = $("#mainCustomizein3DWrapper").find("canvas")
        canvas[0].toBlob(async (blob) => {
            const file = new File([blob], 'myImage.png', { type: 'image/png' });
            let fileName = `${variantName}.png`
            await uploadVariantImage(file,fileName)
        }, 'image/png');
      }
    }
  
    async function uploadVariantImage(file:any,fileName:string) {
        let albumName = "public"
        var albumPhotosKey = encodeURIComponent(albumName) + "/estre/custom-variants/" + STORE.currProduct.category_name + "/" + STORE.currProduct.product_name + "/"   
        await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,file,fileName,true)
        let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/custom-variants/Sofa/${STORE.currProduct.product_name}/${STORE.productConfiguration.variant.variantName}.png`
        var link = url
        window.open(link, '_blank');
    }
 

 
    return (
      <React.Fragment>

      
         
        <div className="top-right hide-on-select hide-on-sprites customizein3d-top-right hide-in-take-render full-screen-toggle-wrapper" id="">
          <div className="display-flex-row no-wrap hide-button-text-in-mobile">

          {/* <div className={`center-aligned-column marginRight5 action-link-container`} id="pageTourLink">
            <PageTourLink/>
          </div> */}


          {props.isSpacePlannerMode?
          <Button2
            configObj={{id:""}}
            action={updateVariantImage}
            label={""}
            fontAwesomeIcon={"fas fa-camera color-primary"}
            iconPos="left"
            classNames={"shadow-medium no-border button-small"}
          />
          :null}

            

        {!props.isViewProductMode && !props.isElevateMode?
        <>
          <Button2
            configObj={{id:""}}
            action={undoHandle}
            label={"Undo"}
            fontAwesomeIcon={"fas fa-undo color-danger font-small"}
            iconPos="left"
            classNames={"shadow-medium no-border button-small"}
          />
          <Button2
            configObj={{id:""}}
            action={redoHandle}
            label={"Redo"}
            fontAwesomeIcon={"fas fa-undo color-dark redo-icon font-small"}
            iconPos="left"
            classNames={"shadow-medium no-border button-small"}
          />
        </>
        :null}

          {!props.isFloorplanMode && props.isSpacePlannerMode?
            <Button2
              configObj={{id:""}}
              action={enableFloorplanMode}
              label={"Floorplan"}
              fontAwesomeIcon={"fas fa-clipboard color-danger"}
              iconPos="left"
              classNames={"shadow-medium no-border button-small"}
            />
          :null}
        
          
 

          {props.modulesConfigurtion.allowAr && !props.isSpacePlannerMode?
            <ARLinks
              configObj={props.modulesConfigurtion}
              isViewProductMode={props.modulesConfigurtion.isViewProductMode}
            />
          :null}

          {!getUser()?.isShareMode && applicationConfig?.isAdmin()?
            <SaveConfigForm modulesConfigurtion={props.modulesConfigurtion}/>
          :null}

          


          {(username?.toLowerCase().includes("shreedhar") || username?.toLowerCase().includes("kartik")) && getWindowWidth()>480?
            <Button2
              configObj={{id:""}}
              action={setCurrConfigAsDefault}
              label={"Set as default"}
              fontAwesomeIcon={"fas fa-save color-black redo-icon"}
              iconPos="left"
              classNames={"shadow-light"}
            />
          :null}

          <Button2
            configObj={{id:""}}
            action={fullScreenToggleConfigurator}
            label={""}
            fontAwesomeIcon={"fas fa-expand color-primary redo-icon"}
            iconPos="left"
            classNames={"shadow-medium no-border button-small"}
          />
{/* 
            <Button2
              configObj={{id:""}}
              action={enableTakeRenderMode}
              label={"Take render"}
              fontAwesomeIcon={"fas fa-camera color-black redo-icon"}
              iconPos="left"
              classNames={"shadow-light"}
            /> */}

          

          {/* <div className={`center-aligned-column action-link-container`} id="pageTourLink">
            <PageTourLink/>
          </div> */}

          
          </div>

        

    

        </div>
      </React.Fragment>
    );
  };
  
  export default TopRightActions;
  