import React from "react";
import { deselectProduct } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import Button from "../../../../../common/ui-components/buttons/Button";
import Button2 from "../../../../../common/ui-components/buttons/Button2";



const TopMiddle: React.FC<{
  configObj:any
  currentConfigObject:any
  showDeselect:boolean
  deselectCanvas:()=>void
  }> = (props) => {

 
   
  return (
    <>
     
        {props.showDeselect ? (
        
          <div className="bottom-middle center-aligned-column" style={{zIndex:1002,opacity:1,bottom:"1rem"}}>
             <Button2
                configObj={props.configObj}
                action={props.deselectCanvas}
                label={"Clear Selection"}
                fontAwesomeIcon={"fas fa-times"}
                iconPos="right"
                classNames={"button-light button-large"}
            />

          
      
          </div>
        ) : 
        // <div className="heading bottom-middle zindex30 canvas-icon-bar hide-in-desktop" style={{padding:"1areaSelectorMobilerem 1.5rem"}}>
        //   <span className="color-black font-normal font-outfit letter-spacing">Customizing {props.currentConfigObject?.areaName}</span>
        // </div>
        null
     
        }
    </>
 
  );
};

export default TopMiddle;
