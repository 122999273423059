import React from "react";

import MainLayout from "../../templates/layouts/page/MainLayout";
import CategoryLayout from "./CategoryLayout";
import HeaderLeftContent from "./HeaderLeftContent";
import { useHistory } from "react-router";
import { applicationConfig } from "../../../utils/methods";



const ProductCategories: React.FC<{
  applicationConfig:any
}> = (props) => {

  var history = useHistory()

  // useEffect(()=>{
  //   if (props.applicationConfig) {
  //     let category = null
  //     category = getSavedFilterValue("products","currSelectedCategory")
  //     if(category){
  //       setCurrSelectedCategory(category)
  //       return
  //     }
  //   }
  // },[props.applicationConfig])
 
  function updateCategory(category:string) {
    applicationConfig.filters.products.setCategoryName(category)
    applicationConfig.filters.products.setSubCategoryName("All")
    history.push("/products/"+category)
    // setCurrSelectedCategory(category)
  }
 
  return (
    <React.Fragment>
        <MainLayout 
            configObj={props.applicationConfig}  
            CardsContainer={()=>(
              <CategoryLayout
                configObj={props.applicationConfig}
                updateCategory={updateCategory}
              />
            )}
            HeaderLeftContent={null}
            HeaderMiddleContent={()=>(<div className="heading font-medium">Categories</div>)}
            contentWrapperId="mainProductsCategoryContentWrapper"
        />
    </React.Fragment>
  );
};

export default ProductCategories;
