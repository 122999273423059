import React, { useEffect, useState } from "react";
import Button2 from "../common/ui-components/buttons/Button2";
import Modal from "../common/shared-components/Modals/Modal";
import { Floorplan } from "../../utils/customizein3d/floorplanner/floorplan";

const FloorplanShapes: React.FC<{
  floorplan:Floorplan
  moduleName:string
  currFloorplanOption:string
}> = (props) => {
 
  const[showFinalSummary,setShowFinalSummary]:any = useState(false)
 
  useEffect(()=>{
    if(props.moduleName === "customizein3d"){
      setShowFinalSummary(false)
    }else{
      setShowFinalSummary((!props.floorplan.isRoomDefined && props.moduleName !== "customizer") || props.currFloorplanOption === "shape")
    }
  },[props.currFloorplanOption,props.moduleName])


  function SummaryFooter() {
    return(
      <>
      </>
    )
  }

  function updateRoomShape(type:string){
    setShowFinalSummary(false)
    props.floorplan.updateFloorplanShape(type)
  }

  function RoomShapes() {
    return(
      <>
      {/* <div className="heading2">Please select room shape from given options</div> */}
      <div className="display-flex-row full-height-width ion-padding wrap space-around overflow-y-scroll">
          <label className="room-shape-svg-container" onClick={()=>updateRoomShape("rect")} >
            <svg className="room-shape-svg" viewBox="0 0 160 160">
                <rect x="1" y="1" width="158" height="158" strokeWidth="2" fill-rule="evenodd" strokeLinecap="round" strokeLinejoin="round"></rect>
            </svg>
            <div className="text-center heading3 pointer-none">Shape1</div>
          </label>
          <label className="room-shape-svg-container" onClick={()=>updateRoomShape("lshape")} >
            <svg className="room-shape-svg" viewBox="0 0 160 160">
                <polygon points="1 1 119 1 119 39 159 39 159 159 1 159" strokeWidth="2" fill-rule="evenodd" strokeLinecap="round" strokeLinejoin="round"></polygon>
            </svg>
            <div className="text-center heading3 pointer-none">Shape2</div>
          </label>
          <label className="room-shape-svg-container" onClick={()=>updateRoomShape("pentagon")} >
            <svg className="room-shape-svg" viewBox="0 0 160 160">
                <polygon points="1 1 119 1 159 39 159 159 1 159" strokeWidth="2" fill-rule="evenodd" strokeLinecap="round" strokeLinejoin="round"></polygon>
            </svg>
            <div className="text-center heading3 pointer-none">Shape3</div>
          </label>
          <label className="room-shape-svg-container" onClick={()=>updateRoomShape("shape4")} >
            <svg viewBox="0 0 160 160"  className="room-shape-svg">
                <polygon points="1,1 159,1 159,59 99,59 99,159 1,159" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></polygon>
                {/* <path d="M5,59 L99,59" strokeLinecap="square" stroke-dasharray="8,8" strokeWidth="2"></path> */}
            </svg>
            <div className="text-center heading3 pointer-none">Shape4</div>
          </label>

          <label className="room-shape-svg-container" >
          </label>
          <label className="room-shape-svg-container" >
          </label>
          {/* <label onClick={()=>updateRoomShape("shape5")} >
            <img src="assets/images/floorplan/shape5.png" alt="" />
          </label> */}
      </div>
      </>
    )
  }
  return ( 
    <>
    {props.floorplan.isRoomDefined || props.moduleName !== "customizer"?
      <div id="roomShapesButton" className="hide-in-draw-walls">
        <div className="hide-button-text-in-mobile marginTop5">
          <Button2
            configObj={{id:""}}
            action={()=>{setShowFinalSummary(true)}}
            label={"Room Shapes"}
            fontAwesomeIcon={"fas fa-object-group color-danger"}
            iconPos="left"
            classNames={"shadow-medium  no-border button-small"}
          />
          <Button2
            configObj={{id:""}}
            action={()=>{props.floorplan.reset()}}
            label={"Reset"}
            fontAwesomeIcon={"fas fa-undo color-danger"}
            iconPos="left"
            classNames={"shadow-medium display-none no-border button-small"}
          />
        </div>
      
      </div>
    :null}
    
      
      
      <Modal
          classNames="project-action-modal"
          onDismiss={setShowFinalSummary}
          isOpen={showFinalSummary}
          heading="Select room shape"
          id=""
          FooterContent={SummaryFooter}
          Content={() => (
             <RoomShapes/>
      )}></Modal>
    </>
  );
};

export default FloorplanShapes;
