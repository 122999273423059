import { applicationConfig, stringWithoutWhiteSpace } from "../methods"
import { updateProductTarFile } from "./createproduct"

 
  export class createProductForm{
  
    sku:string = ""
    productName:string = ""
    description:string = ""
    price:number = 0
    categoryName:string = ""
    subCategory:string = ""
    productId:number = 0
    categoryId:number = 0
    subCategoryId:number = 0
    length:number = 0
    breadth:number = 0
    height:number = 0
    clientId:number = applicationConfig.clientId
    
    productImageFile:any = null
    productModuleFiles:Array<any> = null
    moduleIds:Array<number> = []
    product3dModel:any

    galleryImages:any = []
    tilingScale3d:number = 36

    isTemplate:number = 0
    isLeatherAvailable:number = 0
    canHaveCupHolder:number = 0

    isModelAvailable:number = 1
    productType:string = "product"

    storeFrontId:number = 0

    defaultConfiguration:DefaultConfig = new DefaultConfig()

    productTags = []

    isUpdateProductModulesMapping = false
  
    constructor(product:any = null){
      if(product){
        this.sku = product.sku
        this.productName = product.product_name
        this.description = product.description
        this.categoryName = product.category_name
        this.subCategory = product.sub_category_name
        this.productId = product.product_id
        this.categoryId = product.category_id
        this.subCategoryId = product.sub_category_id
        this.isTemplate = product.is_template
        this.storeFrontId = product.storefront_id
        if(product.default_configuration){
          this.defaultConfiguration = JSON.parse(product.default_configuration)
        }
        if(product.product_tags){
          this.productTags = JSON.parse(product.product_tags)
        }
      }
       
    }
 
    setProductImage(file:any){
      this.productImageFile = file
    }
  }
  

  class DefaultConfig{

    variant:string = ""
    
    constructor(){

    }


  }

