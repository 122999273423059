import React, { useEffect, useState } from 'react';
import { showAreasOptions } from '../../../../../../utils/customizein3d/area3dmodel/UI_methods';
import { enableFloorplanMode, enableKitchenPlannerMode, projectConfiguration, updateAreaCustomizer } from '../../../../../../utils/customizein3d/area3dmodel/area3dModel';
import { applicationConfig } from '../../../../../../utils/methods';
import HorizontalScroll from '../../../../../common/ui-components/scrollbar/HorizontalScroll';
import { setCurrAreaFloorplan } from '../../../../../../utils/customizein3d/area3dmodel/floorplan/floorplanUI';
import ProjectDetailsForm from '../../../createproject/ProjectDetailsForm';

const Areas: React.FC<{
  currentConfigObject:any
  }> = (props) => {

    const[areaList,setAreaList] = useState([])
    const[currArea,setCurrArea] = useState("NewDemo")
    const[progress,setProgress] = useState(0)

    useEffect(()=>{
      if(props.currentConfigObject){
        let areaList = projectConfiguration?.areasList.filter(area=>area.area_name!=="Other")
        areaList.sort((a,b)=>a.area_name<b.area_name?-1:1)
        setAreaList([{area_name:applicationConfig.projectName},...areaList])

        applicationConfig.setFunctionRef("customizer",{updateAreaLoaderProgress:setProgress})
      }
    },[props.currentConfigObject])



    function editWallsHandle(areaName:string) {
      enableFloorplanMode()
      setCurrAreaFloorplan(areaName)
      setTimeout(() => {
        applicationConfig.functions.floorplan.updateWallHandle(areaName)
      }, 2000);
    }
 

    function Component() {
      return(
        <>
        {/* <div className="configuration-area-container customizer-areas-container hide-in-mobile" id="customizerAreaWrapper"> */}
      
          {areaList.length?
            areaList.map(
                (area: any, index: number) => {
                  let isSavedConfig = false;
                  let classNames = [];
                  classNames.push("configuration-area white-card shadow-light");
                  if (area.area_name === currArea) {
                    classNames.push("--is-active");
                  }
                  if (area.area_name === "Other" || area.area_name === projectConfiguration.projectName) {
                    classNames.push("display-none");
                  }
                  return (
                    <div
                      key={index}
                      className={classNames.join(" ")}
                      style={{ margin: "0",minWidth:"100px" }}
                      onClick={() =>{
                        setCurrArea(area.area_name)
                        updateAreaCustomizer(area.area_name)
                      }}>
                      <div>
                        {area.area_name}
                      </div>
                    </div>
                  );
                }
              )
            : null}
       
        {/* </div> */}
        </>
    
      )
      
    }
  return (

    <>
      {areaList.map((area:any,index:number)=>{
        return(
          <div key={index} className={`top-left pointer-none hide-on-measurement hide-in-elevation-view area-label hide-in-isolation-view padding0 zindex100 ${area.area_name === projectConfiguration.projectName || area.area_name === "Other display-none" ? " display-none":""}`} style={{visibility:"hidden"}} id={`areaLabel${area.area_name}`}>
            <div className='display-flex-row text shadow-light position-relative  white-card padding5 no-wrap'>
              <span className='font-ex-small center-aligned-column marginleft5 heading4 zindex100 color-black'>{area.area_name}</span>
              <div className='area-label-loader  bottom-left area-products-loader'></div>
            </div>
            <div onClick={enableKitchenPlannerMode} className={area.area_name.includes("Kitchen")?"right-middle display-none pointer padding0 marginleft10":"display-none"} style={{right:"-25px",pointerEvents:"all"}}>
              <img src="assets/icon/modular.png" width={25} alt="" />
            </div>

            <div  className={"right-middle pointer padding0 marginleft10 "} style={{right:"-15px",pointerEvents:"all"}}>
              <div className='padding5 user-options-link' onClick={showAreasOptions}>
                <i className='fas fa-ellipsis-v pointer-none color-dark ' ></i>
              </div>

              <div className='header-user-options area-options white-card shadow-medium border' >
                   
                  <div  onClick={()=>updateAreaCustomizer(area.area_name)} className='option hide-in-elevation-view border-top display-flex-row'>
                    <div><i className="fas fa-cog  color-black font-small"></i></div>
                    <div className='marginleft10 heading3 font-small center-aligned-column'>Load room</div>
                  </div>

                  <div  onClick={()=>editWallsHandle(area.area_name)} className='option hide-in-elevation-view border-top display-flex-row'>
                    <div><i className="fas fa-edit  color-black font-small"></i></div>
                    <div className='marginleft10 heading3 font-small center-aligned-column'>Edit walls</div>
                  </div>

              </div>

            </div>
          </div>
        )
      })}
      
      <div className='padding0 display-none canvas-icon-bar' style={{zIndex:1001,top:"5px",width:"320px",position:"fixed",left:"50%",transform:"translate(-50%,0)"}}>
        <HorizontalScroll
          itemsClass="configuration-area-container"
          Component={Component}   
          itemsList={areaList}    
          id="areaScrollBar" 
        />
      </div>
    </>
  );
};

export default Areas;
