import React, { useEffect } from "react";
import "../../../style/admin/error.css";
import { IonPage } from "@ionic/react";
import Button from "../../ui-components/buttons/Button2";
import { logoutHandle } from "../../../../utils/methods";
    // import Controls from "./controls/Controls"
  
     
    
const AccessDenied: React.FC<{
}> = (props) => {
    

    return (
        <IonPage>
            <div className="full-height-width center-aligned-column bg-color-ternary">
                <div className="ion-padding">
                    <div className="font-ex-large color-primary">Access denied !!!</div>
                    <div className="font-normal marginTop5">We're sorry, but it seems there's an issue preventing access to this page. Please try again later.</div>
                    <div className="display-flex-row ion-margin-top">
                        <Button
                            configObj={{}}
                            action={()=>{logoutHandle()}}
                            label={"Login"}
                            fontAwesomeIcon={"fas fa-home color-primary"}
                            iconPos=""
                            classNames={"shadow-light bg-color-primary margin0"}   
                        />
                    </div>
                </div>
            </div>
        </IonPage>
    );
};

export default AccessDenied;
    