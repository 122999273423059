import React from "react";

 

const Breadcrumb: React.FC<{
  product:any
}> = (props) => {
 


 
 
  return (
    <div className="breadcrumb">
      <span className="inactive color-black heading3">Home <span className="padding10 color-dark">/</span> </span>
      <span className="inactive color-black heading3"> Furnitures <span className="padding10 color-dark">/</span></span>
      <span className="inactive color-black heading3"> Sofas <span className="padding10 color-dark">/</span></span>
      <span className="inactive color-black heading3"> SetSofas <span className="padding10 color-dark">/</span></span>
      <span className="active color-black heading3">{props.product?.product_name}</span>
    </div> 
  );
};

export default Breadcrumb;
