import React, { useEffect, useState } from 'react';
import { hideComponentLoader, showComponentLoader, showGlobalToast } from '../../../../../utils/UI_methods/global';
import { configuration, loadDefaultModuleToScene, loadModulesFromSavedConfiguration, resetConfiguration } from '../../../../../utils/customizein3d/modules/customizein3d';
import { getSavedConfigList } from '../../../../../utils/customizein3d/modules/helper';
import { applicationConfig, getObjectByParameter, getUser, waitFor } from '../../../../../utils/methods';
import { getImageFromModules, STORE } from '../../../../../utils/store/storeConfiguration';
import Button2 from '../../../ui-components/buttons/Button2';
import ShareLink from '../../../ui-components/links/sharelink/ShareLink';
import $ from "jquery"
import { getForegroundSavedConfig, updateVisualizerConfig } from '../../../../../services/api';

// import './Home.css';

const SavedConfigsList: React.FC<{
  closeHandle:()=>void
  isOpen:boolean
}> = (props) => {
 
    const[rendersList,setRendersList] = useState([])
    const[refresh,setRefresh] = useState(Number(new Date()))
    const[product] = useState(STORE.currProduct)

    const[isSavedDefaultConfg,setIsSavedDefaultConfig] = useState(false)

    useEffect(()=>{
      if(props.isOpen){
        let renders = getSavedConfigList()
        let defaultConfig = getObjectByParameter(renders,"title","Default")
        if(defaultConfig){
          let currConfiguration = JSON.parse(defaultConfig.configuration)
          if(currConfiguration.modules.length){
            setIsSavedDefaultConfig(true)
          }

        }
        if(renders.length){
          setRendersList(renders)
        }
      }
    },[props.isOpen,refresh])

    async function loadConfigurationFromScratch() {
      props.closeHandle()
      showComponentLoader("canvasLoader")
      // props.setIsRendersOpen(false)
      resetConfiguration()
      await waitFor(500)
      let defaultConfig = getObjectByParameter(rendersList,"title","Default")
      if(defaultConfig){
        loadDefaultModuleToScene()
        // await loadModulesFromSavedConfiguration(currConfiguration)
        configuration.updateConfigId(defaultConfig.id)
        // hideComponentLoader("canvasLoader")
      }
    }

    async function loadConfiguration(config:any) {
      // props.closeHandle()
      showComponentLoader("canvasLoader")
      resetConfiguration()
      await waitFor(500)
      await loadModulesFromSavedConfiguration(JSON.parse(config.configuration))
      configuration.updateConfigId(config.id)
      hideComponentLoader("canvasLoader")
    }


    function showActions(event:any) {
      $(event.target).find(".config-action").fadeIn(100)
      $(event.target).find(".config-action").css("top","0")
    }

    function hideActions(event:any) {
      $(".config-action").fadeOut(0)
      $(".config-action").css("top","-100%")
    }

    function touchStartAction(event:any) {
      hideActions(event)
      showActions(event)
    }

    function deleteHandle(id:number) {
      let data = {
        isDelete:true,
        configId:id
      }
      updateVisualizerConfig(data).then(data=>{
        if(!data.data?.error){
          getForegroundSavedConfig(0,product.product_id).then(data=>{
            applicationConfig.data.customizerSavedConfigs = data
            setRefresh(Number(new Date()))
            showGlobalToast("Deleted",2000,)
          }).catch(err=>{
          })
        }else{
          showGlobalToast("Error in deleting",2000,"error")
        }
      }).catch(err=>{
        showGlobalToast("Error in deleting",2000,"error")
      })
    }



    function List() {
      return(
        <div className="renders-list bg-color-white display-flex-row full-height-
        width" style={{flexWrap:"wrap"}}>
          {isSavedDefaultConfg?
            <div onClick={()=>{loadConfigurationFromScratch()}} className="padding5 display-none card position-relative" style={{width:"25%",maxWidth:"18rem",aspectRatio:"1",height:"fit-content"}}>
               <div className="center-aligned-column overflow-hidden white-card shadow-medium border full-height position-relative"> 
              <div className="heading4 font-normal color-dark pointer-none padding10 text-center center-aligned-column">Start From Scratch</div>
            </div>
            </div>
          :null}

            {rendersList?.map((render:any,index:number)=>{
              let url = getImageFromModules(JSON.parse(render.configuration),STORE.currProduct)
              // let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/savedConfigs/${applicationConfig?.clientName}/${product.category_name}/${encodeURIComponent(product.product_name)}/${render.id}.png`
            return(
            <div key={index} onTouchStart={touchStartAction} onMouseLeave={hideActions} onMouseMove={showActions} className="padding5 card position-relative" style={{width:"90%",maxWidth:"100%",margin:"auto",aspectRatio:"1",height:"fit-content"}}>
               <div className="overflow-hidden white-card shadow-medium border full-height position-relative"> 
                

                <div className='display-flex-row space-between bg-color-light padding5'>

                  <div className='display-flex-row'>

                    {configuration.configId === render.id?<span className='bg-color-success white-card' style={{width:"10px",height:"10px"}}></span>:null}

                    <div className="center-aligned-column">
                      <div className="display-flex-row custom-tooltip item position-relative">
                        <span className="text  marginleft5 font-small text-ellipsis">{render.title}</span>
                        {/* <div className="custom-tooltip-text">{render.title}</div> */}
                      </div>
                    </div>


                  </div>

                  <div className='display-flex-row'>

                    {render.title !== "Default"?
                    <div className='' onClick={()=>{deleteHandle(render.id)}}>
                      <i className='fas fa-trash-alt font-small color-danger'></i>
                    </div>
                    :null}

                  {!getUser()?.isShareMode?
                    <ShareLink
                      shareItemId={STORE.currProduct.product_id}
                      loaderId = {""}
                      moduleName="products"
                      textClass="color-black"
                      isShareButton={true}
                      defaultConfiguration={JSON.parse(render.configuration)}
                      Content={()=>(
                        <div className='marginleft10'>
                          <i className='fas fa-share-alt font-small color-primary'></i>
                        </div>
                      )}
                    />
                    :null}

                  </div>

                </div>

                <div className='center-aligned-column'>
                  <img className="ion-padding pointer-none"
                    alt="" 
                    src={url}
                    style={{transform:"scale(1)",objectFit:"contain",height:"8rem",width:"8rem"}}
                    onError={(e:any) => { e.target.onerror = null; e.target.src = 'assets/images/gallery_placeholder.png'; }}
                  />
                </div>

                {/* {render.title === "Default"?
                  <div className="heading4 font-normal color-dark pointer-none padding10 text-center center-aligned-column">Start From Scratch</div>
                : */}
                  
                {/* } */}

                <div className="bottom-left center-aligned-column padding5 border-top bg-color-white full-width" style={{background:"rgba(0,0,0,0.2)"}}>
                  <div className='display-flex-row no-wrap'>
                    <Button2
                      configObj={{id:""}}
                      action={()=>loadConfiguration(render)}
                      label={"Load"}
                      fontAwesomeIcon={"fas fa-arrow-right color-primary"}
                      iconPos="right"
                      classNames={"no-shadow button-ex-small width-auto"}
                    />
                  </div>
                </div>  

                

                {/* <div className='top-middle padding5 heading4 color-black'>{render.title}</div> */}

                
                </div>

                

                
            </div>
            )
            })}
        <div className="center-aligned-column full-width display-none">
          <div className="heading4 ion-padding text-center full-width" style={{border:"1px dashed lightgray",borderRadius:"10px"}}>No saved Configs</div>
        </div>
  </div>
      )
    }
 
  return (
    <div className="full-height-width overflow-y-scroll">
      <div className="header-content white-card shadow-medium center-aligned-column heading1 font-medium bg-color-light header-heading store-color-primary">
        Layouts
      </div>
      <div className="body-content">
        <List/>
      </div>
      <div className="footer-content center-aligned-column">
        {/* <div className='display-flex-row no-wrap'>
          <span className='center-aligned-column'>
            <i className='fas fa-times color-danger font-medium'></i>
          </span>
          <span className='center-aligned-column heading2 marginleft5'>Close</span>
        </div> */}

        <div onClick={props.closeHandle} className='bg-color-warning color-white white-card heading4 font-md-small shadow-light ion-margin' style={{padding:"0.8rem 1.2rem"}}>Close</div>
          {/* <Button2
            configObj={{id:""}}
            action={props.closeHandle}
            label={"Close"}
            fontAwesomeIcon={"fas fa-arrow-left color-primary"}
            iconPos=""
            classNames={"no-shadow button-small store-bg-color-primary"}
          /> */}
      </div>
    </div>
  );
};

export default SavedConfigsList;