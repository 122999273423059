import React from "react";
import { createBounceEffectEvents } from "../../../../utils/UI_methods/ui_effects";

const CardsSideBarLayout: React.FC<{
  MainContent: any;
}> = (props) => {
  

  return (
      <div className="main-inner-content-wrapper overflow-y-scroll  display-flex-row ion-padding"
        style={{ paddingTop: "0" }} onLoad={(e)=>createBounceEffectEvents(e)}>
        <props.MainContent />
    </div>
  )
}

export default CardsSideBarLayout;
