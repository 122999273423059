import { applicationConfig } from "../../methods";
import { hideProductSelectionBox, updateAnchorsPositions } from "../helper";
import { configuration, moodboardConfig } from "../moodboard";
import { hideAllProductAnnotations } from "../UI_methods";
import { showPriorityToggleButton } from "./UI_methods";

export class Layout{

    padding: number = 10;
    stageWidth:number;
    stageHeight:number;

    block1: { width: number; height: number; x: number; y: number; };
    block2: { width: number; height: number; x: number; y: number; };
    block3: { width: number; height: number; x: number; y: number; };
    block4: { width: number; height: number; x: number; y: number; };
    block5: { width: number; height: number; x: number; y: number; };
    block6: { width: number; height: number; x: number; y: number; };
    block7: { width: number; height: number; x: number; y: number; };
    block8: { width: number; height: number; x: number; y: number; };
    block9: { width: number; height: number; x: number; y: number; };
    block10: { width: number; height: number; x: number; y: number; };


    constructor(width:number,height:number){
        this.stageHeight = height
        this.stageWidth = width
    }

    setPadding(padding:number){
        this.padding = padding
    }

    setWidthHeight(width:number,height:number){
        this.stageWidth = width
        this.stageHeight = height
    }

}


export function applyLayout(layout:any,layoutName:string) {

    let canvasContainerId = "mainMoodboardCanvasContainer"
  
    let allGroups = moodboardConfig.getImagesList(canvasContainerId)

    let stage = moodboardConfig.getStage(canvasContainerId)
    let layer = moodboardConfig.getLayer(canvasContainerId)
  
    if(allGroups.length < 2){
      return
    }

    layout.setPadding(30)
    layout.setWidthHeight(stage.attrs.width,stage.attrs.height)
    
  
    hideAllProductAnnotations()
    hideProductSelectionBox(null,canvasContainerId)
   
    let currGroup = null
    let currImage = null


    updateGroupsSequence(allGroups)
  
  

    for (let i = 0; i < allGroups.length; i++) {

      let index = i+1

      let data = layout.getBlockParameters(layoutName,index)

      currGroup = allGroups[i]
      currImage = currGroup.find(".canvasItem")[0]

      if(!currImage){
        continue
      }

      
      //Update position
      currGroup.absolutePosition({
        x: data.x,
        y: data.y
      });

      let aspectRatio =  currImage.attrs.width / currImage.attrs.height
      currImage.setAttrs({"width":data.width,"height":data.width / aspectRatio})

      
      updateAnchorsPositions(currGroup)

      layer.draw()
  }

  setTimeout(() => {
    updateConfiguration(allGroups,stage)
  }, 500);

 
}


function updateConfiguration(allGroups:Array<any>,stage:any) {
  let currGroup = null
  let currImage = null
  for (let i = 0; i < allGroups.length; i++) {

    currGroup = allGroups[i]
    currImage = currGroup.attrs.productImage

    let transform = currGroup.attrs.image.transform
    let dimensions = currGroup.attrs.image.dimensions

    transform.posX = currGroup.attrs.x
    transform.posY = currGroup.attrs.y
    dimensions.width = currImage?.width()
    dimensions.height = currImage?.height()
  }
  configuration.updateLocalStorage()
}


function updateGroupsSequence(allGroups:any) {
  let isUpdate = false
  let index = 0
  let item = null

  for (let i = 0; i < allGroups.length; i++) {
    let currGroup = allGroups[i]
    
    if(currGroup.attrs.priority === 1){
      isUpdate = true
      item = currGroup
      index = i
    }
  }

  if(isUpdate){
    allGroups.splice(index,1)
    allGroups.splice(0,0,item)
  }

}

export function updateLayouts() {
    applicationConfig?.functions?.moodboard.setRefreshLayouts(!applicationConfig?.functions?.moodboard.refreshLayouts)
    showPriorityToggleButton()
}