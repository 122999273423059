import $ from "jquery"
import { MathUtils, Quaternion, Vector3 } from "three"
import { CURR_SELECTED_MODULE, removeBoxHelper } from "./raycasting"

var prevRotationValue = 0
const snapDelta = 35
var rotationSnaps = [0,90,180,-90,-180]
var prevRotation = new Quaternion()
var axis = new Vector3(0,1,0)
export var isPointerDownRotationHandler = false
export function startRotation(event:any) {
    event.stopPropagation()
    if(!CURR_SELECTED_MODULE){
        return
    }
    prevRotationValue = 0
    isPointerDownRotationHandler = true
    CURR_SELECTED_MODULE.getWorldQuaternion(prevRotation)
    removeBoxHelper()
}

export function endRotation(event:any) {
    isPointerDownRotationHandler = false
    if(!CURR_SELECTED_MODULE){
        return
    }
    let rotation = Number(event.target.value)
    for (const snap of rotationSnaps) {
        let diff = snap - rotation
        if(diff > - snapDelta && diff < snapDelta){
        CURR_SELECTED_MODULE.rotation.set(0,MathUtils.degToRad(snap),0)
        $("#rangeSlider").val(snap)
        setTimeout(() => {
            $("#rangeSliderValue").text(String(snap))
        }, 200);
        }
    }
    // snapRotation(rotation)
    // addBoxHelper(CURR_SELECTED_MODULE)
 
}


export function rotationRangeSliderHandle(event:any) {
    event.stopPropagation()
    if(CURR_SELECTED_MODULE && isPointerDownRotationHandler){
        let model = CURR_SELECTED_MODULE
        let rotation = Number(event.target.value)
        model.rotation.set(0,MathUtils.degToRad(rotation),0)
        $("#rangeSliderValue").text(String(event.target.value))
    }
}

function snapRotation(rotation:number) {
    for (const snap of rotationSnaps) {
      let diff = snap - rotation
      if(diff > -snapDelta && diff < snapDelta){
        CURR_SELECTED_MODULE.rotateOnWorldAxis(axis,Number(diff) * (Math.PI / 180))
      }
    }
}

export function showRotationSlider() {
    $("#productRotationRangeSlider").addClass("--is-active")
}

export function hideRotationSlider() {
    $("#productRotationRangeSlider").removeClass("--is-active")
}


export function resetRotationSlider() {
    prevRotationValue = 0
}