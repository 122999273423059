import React, { useEffect, useState } from "react";
import { getFilteredArray, getObjectArrayOfDistictValues, getObjectByParameter } from "../../../../utils/methods";
import $, { trim } from "jquery"
const Collections: React.FC<{
  configObj: any;
  // setFinishDetails:(value:any)=>void
  finishDetails:any
}> = (props) => {


  const[companyList,setCompanyList] = useState([])
  const[filteredCompanyList,setFilteredCompanyList] = useState([])

  useEffect(()=>{
    for (var key in props.finishDetails) delete props.finishDetails[key];
    let companies =  getObjectArrayOfDistictValues(props.configObj?.data?.materialCompaniesList,"company_name")
    setCompanyList(companies)
  },[props.configObj])


  const[materialTypeList,setMaterialTypeList] = useState([])

  useEffect(()=>{
    let materialTypes =  getObjectArrayOfDistictValues(props.configObj?.data.allMaterialTypes,"material_type")
    setMaterialTypeList(materialTypes)

    props.finishDetails["materialTypeId"] = materialTypes[0]?.id   
    props.finishDetails["materialType"] = materialTypes[0]?.material_type
  },[props.configObj])

  
  function handleMaterialTypeChange(event) {
    let text = $.trim($(event.target).children("option:selected").text())
    props.finishDetails["materialTypeId"] = event.target.value
    props.finishDetails["materialType"] = text
  }

  function handleCompanyChange(event) {
    props.finishDetails["companyName"] = event.target.value
    props.finishDetails["companyId"] = null
    let searchString = event.target.value

    if(!searchString.length){
      $("#searchResultCompany").fadeOut()
      return
    }

    let container:any = document.querySelectorAll(".company-name-container")!;
 
    for (let i = 0; i < container.length; i++) {
      let companyName = container[i].getAttribute("data-company-name")!
      if (companyName.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ) {
        $("#searchResultCompany").fadeIn()
        container[i].style.display = "";
      } else {
        container[i].style.display = "none";
      }
  }
  }
 
  function handleCollectionChange(event) {
    props.finishDetails["collectionName"] = event.target.value
  }

  function handleSearchCompany(event:any) {
    let companyName = trim($(event.target).text())
    let companyId = $(event.target).attr("data-company-id")
    $("#companyName").val(companyName)
    $("#searchResultCompany").fadeOut()
    props.finishDetails["companyName"] = companyName
    props.finishDetails["companyId"] = companyId
  }
 
  return (
    <React.Fragment>
      <div className="center-aligned-column full-height-width">
        
        <div className="finish-details-container">

          <div className="error-container" >
            <ul id="successContainer">
            </ul>
            <ul id="errorContainer">
            </ul>
          </div>

          <div className="heading2 font-normal color-black text-center">Select Company name, Collection Name & Material Type</div>
          <div className="display-flex-row padding10">

      



          <div className="form-label-input-container">
                <div className="label-container">
                  <div className="label">
                    Company Name <span className="mandatory">*</span>
                  </div>
                </div>
                <div className="input-container position-relative">
                <input
                    className="input input-mandatory"
                    type="text"
                    id="companyName"
                    required
                    onChange={handleCompanyChange}
                  />
                  <div className="suggesion-wrapper full-width border " id="searchResultCompany" style={{position:"absolute",top:"100%",left:0,display:"none"}}>
                    {companyList.map((company:any,index:number)=>{
                      return(
                        <div key={index} className="label company-name-container" data-company-name={company.company_name} data-company-id={company.id} style={{padding:"5px"}} onClick={handleSearchCompany}>{company.company_name}</div>
                      )
                    })}
                  </div>
                </div>
              </div>


              <div className="form-label-input-container">
                <div className="label-container">
                  <div className="label">
                    Collection Name <span className="mandatory">*</span>
                  </div>
                </div>
                <div className="input-container">
                    <input
                    className="input input-mandatory"
                    type="text"
                    required
                    onChange={handleCollectionChange}
                  />
                </div>
              </div>


          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">
                Material Type<span className="mandatory">*</span>
              </div>
              <div className="hint"></div>
            </div>
            <div className="input-container">
            <select
                className="input"
                name=""
                id="materialType"
                onChange={handleMaterialTypeChange}
                >
                {materialTypeList?.map(
                  (materialType: any, index: number) => {
                    return (
                      <option value={materialType.id} key={index}>
                        {materialType.material_type}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          </div>
          
             
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Collections;
