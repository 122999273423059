import React from 'react';
// import './Home.css';
import {
  IonIcon,
 
} from "@ionic/react";
import {  search } from "ionicons/icons";
const Search: React.FC<{
  action:(value:any)=>void
  placeholder:string
  classNames:string
}> = (props) => {
  return (
    <div className={`search-container ${props.classNames}`}>
    <input placeholder={props.placeholder} id="textureSearch" className="search-input" onChange={props.action}>
    </input>
    <IonIcon
      className="icon center-aligned-column color-dark font-normal left-middle"
      icon={search}></IonIcon>
    </div>
  );
};

export default Search;
