import Konva from "konva";
import { getCanvasHeight, getCanvasWidth, moodboardConfig, removeProductFromCurrentConfig, updateCanvasScreenshot } from "./moodboard";
import $ from "jquery"
import { hideAllProductAnnotations } from "./UI_methods";
import { applicationConfig, getCurrentConfigurationFromLocalStorage, logger, setCurrentConfigurationInLocalStorage } from "../methods";
import { getMoodBoardConfigKey } from "./helper";
import { captureAndSaveScreenshot } from "../cache/indexdb";

export var currSelectedText = null


export function addText(textData:any = null) {

  let actionContainer = $(textData.target).parents(".moodboard-canvas-actions-container")
  let canvasContainerId = actionContainer.siblings(".canvas-container").attr("id") || "mainMoodboardCanvasContainer"
  
    let layer = moodboardConfig.getLayer(canvasContainerId)


    let textGroup = new Konva.Group({
      x: textData?.posX || 50,
      y: textData?.posY || 80,
      imageId:textData.imageId || null,
      draggable: true,
    })


    var textNode = new Konva.Text({
        text: textData?.text || 'Double click to change',
        fontSize: 11,
        padding: 2,
        y:2,
        name:"text"
      });

      
    let textRect = new Konva.Rect({
      shadowBlur: 0,
      cornerRadius: 0,
      strokeWidth:0,
      stroke: 'gray',
      backgroud:"transparent",
      name:"rect"
  });

      textNode.on('dblclick dbltap', () => {
        
        var textPosition = textNode.getAbsolutePosition();

        // find position of stage container on the page:

        // position of textarea will be the sum of positions above:
        var areaPosition = {
          x: textPosition.x,
          y: textPosition.y,
        };

        // create textarea and style it
        var textarea:any = createTextArea()

        prepareTextArea(textarea,textNode,areaPosition)

        // textNode.opacity(0)


        textarea.addEventListener('keydown', function (e) {
          // hide on enter
          if (e.keyCode === 13) {
            updateText(layer,textNode,textarea)
          }
        });
      });

      textGroup.on("mousedown touchstart",function() {
        currSelectedText = textGroup
        currSelectedText.attrs.canvasContainerId = canvasContainerId
        updateActionsPosition()
      })

      textGroup.on("dragmove",function() {
        updateActionsPosition()
      })

      textGroup.on("dragend",function() {
        let textData={
          text:textNode.text(),
          x:currSelectedText.x(),
          y:currSelectedText.y(),
          canvasContainerId:canvasContainerId,
          imageId:currSelectedText.attrs.id
        }
        updateTextConfiguration(layer.parent,textData)
      })
      

      textRect.width(textNode.width())
      textRect.height(textNode.height() + 5)
      textGroup.add(textRect)
      textGroup.add(textNode)
      layer.add(textGroup);
      layer.draw()

      logger?.info("editcollection","additem",`Added new text`)

}


export function updateText(layer:any,textNode:any,textarea:any) {
  textNode.text(textarea.value);
  textNode.opacity(1)
  // $(textarea).removeClass("--is-active")
  $(textarea).remove()
  updateRect()
  layer.draw()

  let id = currSelectedText.attrs.id

  if(!id){
    id = String(Number(new Date()))
    currSelectedText.attrs.id  = id
  }

  let textData={
    text:textarea.value,
    x:currSelectedText.x(),
    y:currSelectedText.y(),
    canvasContainerId:currSelectedText.attrs.canvasContainerId,
    imageId:id
  }
  updateTextConfiguration(layer.parent,textData)

  logger?.info("editcollection","updatetext",`Updated text value to ${textarea.value}`)

}


export function updateActionsPosition() {
  let canvasContainerId = currSelectedText.attrs.canvasContainerId
  let parentContainer = $("#"+canvasContainerId)
  let selectionBox = $("#textAreaActions")

  //Append text action to canvas
  selectionBox.detach()
  parentContainer.append(selectionBox)

  let x = currSelectedText.absolutePosition().x
  let y = currSelectedText.absolutePosition().y - selectionBox.height() - 5

  // let stage = moodboardConfig.getStage(canvasContainerId)
  // x = x + $("#"+canvasContainerId).offset().left
  // y = y + $("#"+canvasContainerId).offset().top

  selectionBox.css({"top":y,"left":x})
  selectionBox.addClass("--is-active")
}


function createTextArea() {
  let canvasContainer = $("#"+currSelectedText.attrs.canvasContainerId).parent()
  let textArea = document.createElement('textarea')
  textArea.classList.add("moodboard-text-area")
  textArea.classList.add("top-left")
  canvasContainer.append(textArea)
  return textArea
}

function updateRect(){
  let textNode = currSelectedText.find(".text")[0]
  let textRect = currSelectedText.find(".rect")[0]
  textRect.width(textNode.width())
  textRect.height(textNode.height() + 5)
}

function prepareTextArea(textarea:any,textNode:any,areaPosition) {
    $(textarea).addClass("--is-active")

    textarea.value = textNode.text();
    textarea.style.top = areaPosition.y + 'px';
    textarea.style.left = areaPosition.x + 'px';
    textarea.style.width = textNode.width();

    textarea.style.fontSize = textNode.fontSize() + 'px';
    textarea.style.border = 'none';
    textarea.style.padding = '0px';
    textarea.style.margin = '0px';
    textarea.style.overflow = 'hidden';
    // textarea.style.background = 'none';
    textarea.style.outline = 'none';
    textarea.style.resize = 'none';
    textarea.style.lineHeight = textNode.lineHeight();
    textarea.style.fontFamily = textNode.fontFamily();
    textarea.style.transformOrigin = 'left top';
    textarea.style.textAlign = textNode.align();
    textarea.style.color = textNode.fill();
    textarea.style.background = "#ffffff";
    // rotation = textNode.rotation();

    textarea.focus();
}

export function deleteText() {
  removeTextFromCurrentConfig()
  let layer = currSelectedText.parent
  currSelectedText.remove()
  layer.draw()
  hideAllProductAnnotations()
  updateCanvasScreenshot()
}

export function removeTextFromCurrentConfig() {
  let key = getMoodBoardConfigKey(
    applicationConfig?.getMoodboardId(),
    applicationConfig?.getCurrArea()
  )
  let configuration = getCurrentConfigurationFromLocalStorage(key)
  // console.log(currSelectedText)
  let configKey = "text"+"_"+currSelectedText.attrs.imageId
  delete configuration[configKey]
  setCurrentConfigurationInLocalStorage(key, configuration)
}


export function updateTextColor(value:string) {
  let text = currSelectedText.find(".text")[0]
  text.fill(value)
  updateLayer()
}


export function updateFontStyle(event:any) {
  let text = currSelectedText.find(".text")[0]
  if(text.fontStyle() === "bold"){
    text.fontStyle("normal")
    updateRect()
    updateLayer()
    $(event.target).css("font-weight","normal")
    return
  }
  text.fontStyle("bold")
  updateRect()
  updateLayer()
  $(event.target).css("font-weight","bold")
}

export function updateTextSize(value:number) {
  let text = currSelectedText.find(".text")[0]
  text.fontSize(value)
  updateRect()
  updateLayer()
}

export function updateTextConfiguration(stage:any,textData:any) {
  
  let key = getMoodBoardConfigKey(applicationConfig?.getMoodboardId(),applicationConfig?.getCurrArea())
  let configuration = getCurrentConfigurationFromLocalStorage(key) || {};

  if(!stage){
    stage = moodboardConfig.getStage(currSelectedText.attrs.canvasContainerId)
  }

  // let id = textData.imageId

  // if(!id){
  //   id = String(Number(new Date()))
  // }


  let configKey = "text"+"_"+textData.imageId

  configuration[configKey] = {
    productId: 0,
    imageId:textData.imageId,
    productName:textData.text,
    productCategory:"Default",
    productSubCategory: "Default",
    imageType:"text",
    width:0,
    height:0,
    posX:textData.x,
    posY:textData.y,
    canvasWidth:getCanvasWidth(),
    canvasHeight:getCanvasHeight(),
    canvasContainerId:textData.canvasContainerId,
    addonsList:[],
    rotation:0,
    text:textData.text
  };
  updateCanvasScreenshot()
  setCurrentConfigurationInLocalStorage(key, configuration)
}

function updateLayer() {
  let layer = currSelectedText.parent
  layer.draw()
}