import {
  IonPage
} from "@ionic/react";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SessionExpired from "../../../common/ui-components/alerts/SessionExpired";
import SubscriptionAlert from "../../../common/ui-components/alerts/SubscriptionAlert";
import MainHeader from "../../products/store/common/MainHeader";
import { Carousel } from "react-responsive-carousel";
import Button2 from "../../../common/ui-components/buttons/Button2";
import TopCollections from "./TopCollections";
import { applicationConfig } from "../../../../utils/methods";
import KanvasCollections from "./KanvasCollections";
import KairaHome from "./KairaHome";
 

const KanvasHome: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = (props) => {

 

  return (
    <>
      {props.configObj && props.isLogin?
       <>
       {applicationConfig?.clientName === "KairaFabrics"?
       <KairaHome
        configObj = {props.configObj}
        setIsLoadedPageConfiguration={props.setIsLoadedPageConfiguration}
        isLoadedPageConfiguration={props.isLoadedPageConfiguration}
        isLogin={props.isLogin}
       />
       :
       <KanvasCollections
        configObj = {props.configObj}
        setIsLoadedPageConfiguration={props.setIsLoadedPageConfiguration}
        isLoadedPageConfiguration={props.isLoadedPageConfiguration}
        isLogin={props.isLogin}
       />
       }
       </>
      :
      <SessionExpired/>
      }
    </>
  );
};

export default KanvasHome;
