import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { closeFiltersPanelMobile } from "../../../utils/UI_methods/global";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray } from "../../../utils/methods";
import SubCategories from "../../common/shared-components/products/SubCategories";
import Button2 from "../../common/ui-components/buttons/Button2";
import "../../style/admin/project.css";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../../../utils/cache/localstorage";

const ProductFilters: React.FC<{
  setCurrSelectedSubCategory:(val:string)=>void
  setProductsList:(data:Array<any>)=>void
  currSelectedCategory:string
  currSelectedSubCategory:string
  productsList:any
}> = (props) => {

  var history = useHistory()


  const[subCategories,setSubCategories] = useState([])
  const[isSearchMode,setIsSearchMode] = useState(false)

  useEffect(()=>{
    applicationConfig.setFunctionRef("products",{filterProductWithCategoryAndSubCategory:filterProductWithCategoryAndSubCategory})
  },[])


  useEffect(()=>{
    let currSubCategories = getFilteredArray(props.productsList,"category_name",props.currSelectedCategory);
    setSubCategories(currSubCategories)

    let currSubCategory = getItemFromLocalStorage("recentActivities")?.currSubCategory
    let currCategory = getItemFromLocalStorage("recentActivities")?.currCategory
    //If categories are same then only select sub category 
    if(currSubCategory && currCategory === props.currSelectedCategory){
      props.setCurrSelectedSubCategory(currSubCategory);
    }else{
      props.setCurrSelectedSubCategory(getDefaultSubCategory());
    }

  },[props.currSelectedCategory,props.productsList])
 

  
  function getDefaultSubCategory() {
    let result = "All"
    if(applicationConfig.clientName === "DashSquare" && props.currSelectedCategory === "Sofa"){
      result = "LShape"
    }
    return result
  }


  function filterProductWithCategoryAndSubCategory(category:string,subCategory:string) {
    closeFiltersPanelMobile()
    props.setCurrSelectedSubCategory(subCategory);

    if(category && subCategory){
      setIsSearchMode(false)
      if(subCategory.toLowerCase() === "all"){
        let filteredProducts = props.productsList?.filter(product=>product.category_name===category)
        props.setProductsList(filteredProducts);
        return
      }
      
      let filteredProducts = props.productsList?.filter(product=>product.category_name===category&&product.sub_category_name===subCategory)
      props.setProductsList(filteredProducts);

      let recentActivities =  getItemFromLocalStorage("recentActivities")
      if(!recentActivities){
        recentActivities = {}
      }
      recentActivities.currCategory = category
      recentActivities.currSubCategory = subCategory
      addItemToLocalStorage("recentActivities",recentActivities)

    }



  }
   
  return (
    <React.Fragment>

       

     

      <div className="sub-categories marginTop5 ">
        <SubCategories
          updateProducts={filterProductWithCategoryAndSubCategory}
          subCategories={[...getArrayOfDistinctValues(subCategories,"sub_category_name")]}
          currSelectedCategory={props.currSelectedCategory}
          currSelectedSubCategory={props.currSelectedSubCategory}
          productsList={props.productsList}
        />
      </div>

          
    </React.Fragment>
  );
};

export default ProductFilters;
