import $ from "jquery";
import React, { useState } from "react";
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../../../../utils/UI_methods/global";
import { ActivityEvent } from "../../../../../../../utils/store/crm/calendar";
import { getFormatedDate } from "../../../../../../../utils/store/crm/crm";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
import Modal from "../../../../../../common/shared-components/Modals/Modal";
import Calendar from "../calender/Calendar";
 
const ScheduleAppointmentForm: React.FC<{
    ticket:any
    refreshLeads:()=>void
    currActivity:string
    setIsOpen:(val)=>void
    isOpen:boolean
    allCalendarEvents:Array<any>
}> = (props) => {

    // const[isOpenCalender,setIsOpenCalender] = useState(false)

    const[nextActivity,setNextActivitiy]:any = useState(null)
    const[nextActivityEvent] = useState(new ActivityEvent(null,"")) 

   
 
    function selectDateHandle() {
        const nextActivityDetails = {
          resolutionDate:nextActivityEvent.start?.getTime(),
          remarks:$("#closeActivityRemarks").val()
        }
  
        if(nextActivityDetails.resolutionDate && props.ticket){
          showComponentLoader("storeLoader")
          CRM.crmApi.ticket.update(props.ticket?.ticket_id,nextActivityDetails).then(async (data:any)=>{
            if(!data.data.error){
                props.setIsOpen(false)
                // await waitFor(500)
                props.refreshLeads()
                showGlobalToast("Scheduled at " + getFormatedDate(nextActivityEvent.start?.getTime()) ,3000)
                hideComponentLoader("storeLoader")
            }else{
              showGlobalToast("Internal server error",2000,"error")
              hideComponentLoader("storeLoader")
            }
          })
        }else{
          showGlobalToast("Please enter mandatory fields",2000,"error")
          hideComponentLoader("storeLoader")
        }
  
        props.setIsOpen(false)
    }

      

      function Footer() {
        return(
            <div className="middle">
                {/* <Button2
                    configObj={{id:""}}
                    action={closeActivityHandle}
                    label={"Confirm"}
                    fontAwesomeIcon={"fas fa-arrow-right color-dark"}
                    iconPos=""
                    classNames={"no-shadow butt color-white bg-color-primary"}
                /> */}
            </div>
        )
    }
   


 
 
  return (
    <Modal
        isOpen = {props.isOpen}
        Content = {()=>(
            <Calendar isShowRemarks={false} eventsList={props.allCalendarEvents} callbackAfterDateSelect={selectDateHandle} lead={null} isSetEventMode={true} activityEvent={nextActivityEvent}/>
        )}
        onDismiss = {()=>props.setIsOpen(false)}
        heading={"Select date for : " + nextActivity?.activity_type}
        FooterContent={Footer}
        classNames="large-x-modal"
        id=""
    />
  );
};

export default ScheduleAppointmentForm;
