import React, { useEffect, useState } from "react";
import { confirmAccordionHandle, confirmProccessAccordion, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { SofaVariantsConfiguration } from "../../../../../utils/store/customization-options-data/sofa";
import { SofaConfiguration } from "../../../../../utils/store/storeConfiguration";
import Modal2 from "../../../../common/shared-components/Modals/Modal2";
import AccordionForProccessCss from "../../../../common/ui-components/accordion/AccordionForProccessCss";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import MaterialsOptions from "../product-customizer/Material-Selector/MaterialsOptions";
import QualityIcon from "../product-customizer/common/QualityIcon";
import SelectQuality from "../product-customizer/common/SelectQuality";
import VariantIcon from "../product-customizer/common/VariantIcon";
import VariantSelector from "../product-customizer/common/VariantSelector";
import $ from "jquery"

 

const SofaCofigOptions: React.FC<{
  product:any
  productConfiguration:SofaConfiguration
  updatePrice:()=>void
  setIsOpenAdvancedConfig:(val:boolean)=>void
}> = (props) => {

  const[currCategory] = useState(props.product.category_name)
  const[currVariant,setCurrVariant] = useState(props.productConfiguration.variant)
  const[currQuality,setCurrQuality] = useState(props.productConfiguration.quality)
  const[currFabric,setCurrFabric] = useState(props.productConfiguration.fabric)

  const[isOpenFabricSelection,setisOpenFabricSelection] = useState(false)
  const[isOpenMeasurement,setIsOpenMeasurement] = useState(false)
 
  const[isConfirmVariant,setisConfirmVariant] = useState(false)
  const[isConfirmQuality,setisConfirmQuality] = useState(false)
  const[isConfirmFabric,setisConfirmFabric] = useState(true)

  useEffect(()=>{
    props.updatePrice()
  },[currVariant,currQuality,isConfirmQuality,isConfirmVariant,props.product])
   

  function CurrentSelection(props:any) {
    const heading = props.heading
    const value = props.value
    return(
      <div className="center-aligned-column">
          <div className="heading2 font-md-small marginRight10 color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">{value} {props.heading === "Variant" ? `(${props.variant.type})`:""}</div>
      </div>
    )
  }

  function CurrentVariant() {
    let variantName = currVariant.variantName
    return(
      <div className="center-aligned-column">
        <div className="display-flex-row no-wrap">
          <div className="padding5 center-aligned-column" style={{width:"5rem"}}>
            <VariantIcon variant={currVariant} currCategory={currCategory}/>
          </div>
          
          <div className="heading2 center-aligned-column hide-in-mobile font-md-small marginRight10  color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">
            {props.productConfiguration.isCustomVariant?
            "Custom Variant"
            :
            `${variantName} ${variantName.includes("Lounger") || variantName.includes("Corner")? `${currVariant.type} (${currVariant.size})`:`(${currVariant.size})`}`
            }
          </div>
        </div>
      </div>
    )
  }
  
  function CurrentQuality(props:any) {
    const quality = props.quality
    return(
      <div className="center-aligned-column">
        <div className="display-flex-row no-wrap">
          <div className="padding5 center-aligned-column" style={{width:"5rem"}}><QualityIcon quality={quality} currCategory={currCategory}/></div>
          <div className="heading2 center-aligned-column hide-in-mobile font-md-small marginRight10  color-danger text-no-wrap text-ellipsis-mobile text-ellipsis-md">{quality}</div>
        </div>
      </div>
    )
  }


  function Footer(props:any) {
    const confirmHandle = props.confirmHandle
    return(
      <div className="center-aligned-column padding5 full-width bg-color-light">
        <div className="">
          <Button2
            configObj={{id:""}}
            action={()=>{confirmHandle()}}
            label={"Confirm Selection"}
            fontAwesomeIcon={"fas fa-cart-plus color-white"}
            iconPos=""
            classNames={"no-shadow margin0 bg-color-warning"}
          />
        </div>
      </div>
    )
  }


  function confirmMaterialHandle() {
      setisOpenFabricSelection(false)
      setisConfirmFabric(true)
      showGlobalToast("Saved")
      confirmAccordionHandle(2)
  }

  function confirmMeaHandle() {
    setIsOpenMeasurement(false)
    showGlobalToast("Updated")
  }


  function confirmVariantHandle(index:number) {
    confirmAccordionHandle(index)
    setCurrVariant({...props.productConfiguration.variant})
    props.productConfiguration.updatePrice()
  }


  return (
    <div className='product-details product-customizer-wrapper'>


      <Modal2
        isOpen = {isOpenFabricSelection}
        Content = {()=>(
          <MaterialsOptions product={props.product} confirmMaterialHandle={()=>{confirmMaterialHandle()}} productConfiguration={props.productConfiguration} onChange={setCurrFabric}/>
        )}
        onDismiss = {()=>setisOpenFabricSelection(false)}
        classNames="medium-x-modal"
        id=""
      />


      <div className="">

        <AccordionForProccessCss
            label="Choose your size"
            SubHeader={()=>(
              <CurrentVariant/>
            )}
            Content={()=>(
              <>
                <VariantSelector confirmHandle={()=>{confirmVariantHandle(1)}} variant={props.productConfiguration.variant} product={props.product} configuration={SofaVariantsConfiguration} onChange={setCurrVariant} isShowMakeYourOwn={true}/>
              </>
            )}
            index={1}
            Footer={()=>(
              <Footer confirmHandle={()=>{confirmVariantHandle(1)}} />
            )}
        />

        {/* <AccordionForProccessCss
            label="Choose Your Quality"
            SubHeader={()=>(
              <CurrentQuality quality={props.productConfiguration.quality}/>
            )}
            Content={()=>(
              <SelectQuality productConfiguration={props.productConfiguration} currCategory={props.product.category_name}  currQuality={currQuality} onChange={setCurrQuality}/>
            )}
            index={2}
            Footer={()=>(
              <Footer confirmHandle={()=>{
                setisConfirmQuality(true)
                setCurrQuality(props.productConfiguration.quality)
                confirmProccessAccordion(2)
                props.productConfiguration.updatePrice()
              }} />
            )}
        /> */}

          <AccordionForProccessCss
            label="Select Your Fabric"
            SubHeader={()=>(
              <CurrentSelection heading="Fabric" value={props.productConfiguration.materials.length?props.productConfiguration.materials[0]?.material?.materialCode:"No Selection"}/>
            )}
            Content={()=>(
                <></>
            )}
            index={2}
            onClick={()=>setisOpenFabricSelection(true)}
          />

          

      </div>
        </div>
  );
};

export default SofaCofigOptions;
