import React, { useEffect, useState } from 'react';
import { applicationConfig } from '../../../../utils/methods';
import { removeActiveClass, updateNotificationsCounter } from '../../../../utils/UI_methods/global';
import $ from "jquery"
 
const NotificationsList: React.FC<{
  }> = (props) => {

  const[notificatiions,setNotifications] = useState([])
  const[updateNotifications,setUpdateNotifications] = useState(true)

  useEffect(()=>{
    applicationConfig?.setFunctionRef("notifications",{setUpdateNotifications:setUpdateNotifications})
  },[])

  useEffect(()=>{
    setTimeout(() => {
      let notifications = JSON.parse(localStorage.getItem("notifications")) || []
      setNotifications(notifications)
      applicationConfig.setState("notifications",{updateNotifications:updateNotifications})
      updateNotificationsCounter()
    }, 200);
  },[updateNotifications])

  function gotoProduct(productData:any,index:number) {

    applicationConfig.functions.products.setCurrSelectedCategory("")
    applicationConfig.functions.products.setCurrSelectedCategory(productData.categoryName)
    setTimeout(() => {
      applicationConfig.functions.products.setProductsList([{product_name:productData.productName,category_name:productData.categoryName,sub_category_name:productData.subCategory}])
    }, 500);

    removeActiveClass("#notificationList")
  }


  function removeItem(event:any,index:number) {
    $(event?.target).parents(".notification").fadeOut()

    setNotifications((prevValues) => {
      prevValues.splice(index, 1);
      localStorage.setItem("notifications",JSON.stringify(prevValues))
      updateNotificationsCounter()
      console.log(prevValues)
      return prevValues
    });
  }

  return (
  <div className='top-right ion-margin-top notification-list-wrapper' id="notificationList" style={{top:"2rem"}}>
    <div className='wrapper border padding10 white-card shadow-light  background-white' style={{width:"300px",height:"400px"}}>
      <div className='heading3'>Notification</div>

      <div className='fill-available-height overflow-y-scroll'>
        {notificatiions?.map((notification:any,index:number)=>{
          return(
            <div key={notification.id}  className='notification position-relative border-bottom pointer marginTop10 padding10 full-width'>
              <div className='display-flex-row no-wrap space-between' >
                <div className=''> <i className='fas fa-mail color-dark'></i> </div>
                <div className='details' style={{padding:"0 10px 0 0"}} onClick={()=>gotoProduct(notification.productData,index)}>
                  <div className='heading4 color-black'>{notification.message}</div>
                  <div className='heading4   bottom-right font-ex-small marginBottom5 marginTop5 date' style={{marginLeft:"10px"}}>{new Date(Number(notification.id)).getMinutes()} mins ago</div>
                </div>
              
              </div>
              <div className='top-right padding5' onClick={(event:any)=>{removeItem(event,index)}}>
                  <i className='fas fa-times color-black font-small'></i>
                </div>
            </div>
          )
        })}
      </div>

     
      
     

    </div>
  </div>
  );
};

export default NotificationsList;
