  import React from "react";
  import "../../../style/common/themesetting.css"
import $ from "jquery";

  const MainSideBar: React.FC<{
     close:()=>void
  }> = (props) => {


    function changeFontSize(event:any,fontSize:string) {
      $(event.target).siblings("button").removeClass("op-btn-primary")
      $(event.target).siblings("button").addClass("op-btn-light")
      $(event.target).removeClass("op-btn-light")
      $(event.target).addClass("op-btn-primary")

      updateFontSize(fontSize)

    }

    function updateFontSize(fontSize:string) {
      document.documentElement.style.setProperty("--op-font-size",fontSize)
    }

    function resetFont() {
      updateFontSize("13px")
      $(".font-setting").find("button").removeClass("op-btn-primary")
      $(".font-setting").find("button").addClass("op-btn-light")
      $("#defaultFontButton").removeClass("op-btn-light")
      $("#defaultFontButton").addClass("op-btn-primary")
      
    }

  
    return (
      <div className="theme-setting-wrapper full-height-width">
        <div className="theme-setting-content">
          <div className="display-flex-column ">
            <div className="ion-padding bg-color-light">Theme Options</div>
            <div className="ion-margin ion-padding border">
                <div className="ion-margin-bottom">Font Size</div>
                <div className="display-flex-row font-setting">
                  <button className="op-btn op-btn-light" onClick={(event)=>changeFontSize(event,"12px")}>Small</button>
                  <button id="defaultFontButton" className="op-btn op-btn-primary ion-margin-start" onClick={(event)=>changeFontSize(event,"13px")}>Normal</button>
                  <button className="op-btn op-btn-light ion-margin-start" onClick={(event)=>changeFontSize(event,"14px")}>Medium</button>
                  <button className="op-btn op-btn-light ion-margin-start" onClick={(event)=>changeFontSize(event,"15px")}>Large</button>
                </div>
            </div>
            <div className="ion-padding-start">
              <button className="op-btn op-btn-light" onClick={resetFont}>Reset Default</button>
            </div>
          </div>
        </div>

        {/* <div className="toggle-button center-aligned-column hide-in-mobile" 
          onClick={()=>  $(".theme-setting-wrapper").toggleClass("--is-active")}
          >
          <div> <i className="fa fa-cog font-medium"></i></div>
            
           </div> */}
           
 <div className="ion-padding bottom-middle border-top bg-color-ternary full-width text-center color-primary"
        onClick={props.close}
        >
        <div className="center-aligned-row">
          <div className="heading color-black uppercase">
            <i className="fas fa-chevron-left heading2"></i> Go Back
          </div>
        </div>
      </div>

        {/* <div className="bottom-middle padding10">
          <button className="op-btn op-btn-light"
          onClick={()=>  $(".theme-setting-wrapper").removeClass("--is-active")}
          >
            Close
          </button>
        </div> */}

      </div>
    );
  };
  
  export default MainSideBar;
  