import $ from "jquery";
import React, { useEffect, useState } from "react";
import { showComponentLoader } from "../../../../../../../utils/UI_methods/global";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../../../../../../../utils/cache/localstorage";
import { updateAspectFrame } from "../../../../../../../utils/cropper/cropper";
import { toggleSaveRenderOptions } from "../../../../../../../utils/customizein3d/area3dmodel/UI_methods";
import { controls, customizerConfig, disableFinalizeMode, projectConfiguration } from "../../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { ProductRenderViews } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/DefaultRoomsBackground";
import { cameraSettingViewData } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/createRender";
import { getAnchorElement, hideAllAreaAnchors, removeAllAnchors, showAllAreaAnchors, updateAnchorPosition } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/floorplanUI";
import { addAnchorFor2dRender, cameraFor2dRender, createAnchorElement, getCameraObjectFromCamera, isViewRenderMode, loadSavedCamera, rotateCameraIconFromCameraControls, saveAsNewView, setCameraFor2dRender, updateView } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/render2d";
 
import { applicationConfig, waitFor } from "../../../../../../../utils/methods";
import { getPageTourElements } from "../../../../../../../utils/siteTour/elements";
import { startPageTour } from "../../../../../../../utils/siteTour/tour";
import Cropper from "../../../../../../common/shared-components/Helpers/Cropper";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import SiteTour from "../../../../../../common/ui-components/siteTour/SiteTour";
import CameraSettings from "./CameraSettings";

const CreateRender2D: React.FC<{
}> = (props) => {

  //whether selecting walls or anchr..based on call the submit function

  const[defaultView,setDefaultView] = useState({})
  
  const[isFullScreenMode,setIsFullScreenMode] = useState(customizerConfig.currentRenderingType === "3d" ? true : false)
 
  const[currselectedArea,setCurrAreaFloorplan] = useState(null)
  
  const[tourElementsList] = useState(getPageTourElements("CreateRender2d"))
  
  const[allViews,setAllViews] = useState([])

 
  
  useEffect(()=>{
    setTimeout(() => {
      removeAllAnchors()
      addAnchorFor2dRender("2d",applicationConfig.projectName)
      updateAspectFrame(4/3)
      startPageTour()
      updateAnchorRotation()
      // hideComponentLoader("canvasLoader")
      let currRoom = projectConfiguration.productRenderModeConfig.currScene
      let otherViews = ProductRenderViews[currRoom] || []
      let camera = otherViews?otherViews[0]:null

      if(camera){
        setDefaultView(camera)
        // let key = `ProductRenderSavedViews_${projectConfiguration.productRenderModeConfig.currScene}`
        // let savedViews = getItemFromLocalStorage(key) || []
        // setAllViews([camera,...savedViews])
        setAllViews([camera,...otherViews])
        
      }

    }, 500);
    applicationConfig.setFunctionRef("customizer",{setCurrAreaFloorplan:setCurrAreaFloorplan})
    applicationConfig.setFunctionRef("customizer",{enableFullScreenMode:enableFullScreenMode})
    applicationConfig.setFunctionRef("customizer",{disableFullScreenMode:disableFullScreenMode})
  },[])


  function updateAnchorRotation() {
    let anchor = getAnchorElement(cameraFor2dRender?.cameraId)
    rotateCameraIconFromCameraControls(anchor,controls?.azimuthAngle) 
  }

  
  async function closeCreateRenderHandle() {
    showComponentLoader("canvasLoader")
    await waitFor(100)
    disableFinalizeMode()
  }


  useEffect(()=>{
    if(isFullScreenMode){
      hideAllAreaAnchors()
      // updateSceneCameraAspectRatio(perspectiveCamera,window.innerWidth,window.innerHeight,1)
      $("#cameraPositionFooter").removeClass("--is-active")
      $("#cameraViewFrame").removeClass("--is-active")
      $(".crop-box-wrapper").css({"width":`100%`,"left":`0`})
      $("#cameraSettingsContainer").css({"width":`100%`,"padding-left":`0`})
    }else{
      showAllAreaAnchors()
      // updateSceneCameraAspectRatio(perspectiveCamera,window.innerWidth * cameraSettingViewData.width,window.innerHeight * cameraSettingViewData.width , 1)
      $("#cameraPositionFooter").addClass("--is-active")
      $("#cameraViewFrame").addClass("--is-active")
      $(".crop-box-wrapper").css({"width":`${(1-cameraSettingViewData.width)*100}%`,"left":`${(cameraSettingViewData.width)*100}%`})
      $("#cameraSettingsContainer").css({"width":`100%`,"padding-left":`${(cameraSettingViewData.width)*100}%`})
    }
  },[isFullScreenMode])
  
  function toggleFullScreen() {
    if(isFullScreenMode){
      disableFullScreenMode()
    }else{
      enableFullScreenMode()
    }
  }

  function enableFullScreenMode() {
    setIsFullScreenMode(true)
    customizerConfig.setIsFullScreenMode(true)
  }

  function disableFullScreenMode() {
    setIsFullScreenMode(false)
    customizerConfig.setIsFullScreenMode(false)
  }

  function saveHandle() {
    if(isViewRenderMode){
      toggleSaveRenderOptions()
    }else{
      updateView()
    }
  }


  function saveCurrentView() {
    let key = `ProductRenderSavedViews_${projectConfiguration.productRenderModeConfig.currScene}`
    let savedViews = getItemFromLocalStorage(key) || []
    if(savedViews){
      // if(savedViews.find(currView => currView.cameraId !== cameraFor2dRender.cameraId)){
      //   savedViews.push(cameraFor2dRender)
      // }else{
      //   showGlobalToast("Already exists",2000,"error")
      // }
      let newCamera = {...cameraFor2dRender}
      newCamera.cameraId = Number(new Date)
      savedViews.push(newCamera)
    }
    addItemToLocalStorage(key,[...savedViews])
    setAllViews([...savedViews])
  }

  function deleteSavedView(view:any){
    let key = `ProductRenderSavedViews_${projectConfiguration.productRenderModeConfig.currScene}`
    let savedViews = getItemFromLocalStorage(key) || []
    if(savedViews){
      savedViews = savedViews.filter(currView => currView.cameraId !== view.cameraId)
    }
    addItemToLocalStorage(key,savedViews)
    setAllViews([...savedViews])
  }

  function loadCamera(camera:any){
    if(camera){
      let cameraClass = getCameraObjectFromCamera(camera) 
      removeAllAnchors()
      createAnchorElement(cameraClass)
      loadSavedCamera(cameraClass)
      

      $(".anchor-icon-container").attr("id",`areaAnchor${cameraClass.cameraId}`)
      setCameraFor2dRender(cameraClass)
      // setTimeout(() => {
        updateAnchorPosition(cameraClass.cameraId,cameraClass.transform.position)
        updateAnchorRotation()
      // }, 500);
    }
  }

  

 

  return (
    <>

      {!projectConfiguration?.isProductRenderMode?
        <SiteTour
          tourElementsList={tourElementsList}
          moduleName={"CreateRender2d"}
        />
      :null}

      

      <div id="cameraViewFrame" className="camera-view-frame display-toggle-wrapper pointer-none full-height padding0 top-left" style={{width:`${cameraSettingViewData.width*100}%`}}>
      </div>
      <div className={`customize-camera-settings-wrapper bottom-left  display-flex-row no-wrap`} id="cameraSettingsContainer" style={{background:"transparent"}}>
        <CameraSettings
          isFullScreenMode={isFullScreenMode}
        />
      </div>

    {!isFullScreenMode?
      <>
   
      
      <div className="top-left padding0 bg-color-light border" style={{width:`${cameraSettingViewData.width*100}%`,marginTop:`${projectConfiguration.isProductRenderMode?"3.5rem":"0"}`}}>
          {projectConfiguration.isProductRenderMode?
          null
          :
          <div className="center-aligned-column bg-color-white position-relative" style={{padding:"25px 0"}}>
            <div className="heading2 middle">Set camera position</div>
          </div>

          }
          {/* <div className="position-relative border right-middle click-effect white-card no-shadow center-aligned-column" style={{width:"28px",height:"28px"}} onClick={toggleFullScreen}>
                <IonIcon className="color-danger font-medium" icon={close} />
          </div> */}
      </div>

      <div className="bottom-left padding0 overflow-y-scroll" style={{width:`${cameraSettingViewData.width*100}%`,zIndex:1001,bottom:"0",height:"15rem"}}>
        
      </div>

       

      <div  className="bottom-left border-top padding0 " style={{width:`${cameraSettingViewData.width*100}%`,zIndex:1001}}>
        
        <div className="position-relative padding10  white-card shadow-light">
            <Button2
              configObj={applicationConfig}
              action={saveCurrentView}
              label={"Save View"}
              fontAwesomeIcon={"fas fa-plus color-danger"}
              iconPos="left"
              classNames={"bg-color-white button-ex-small"}
            />
            <div className="display-flex-row marginTop5 overflow-y-scroll" style={{maxHeight:"7rem"}}>
              {allViews?.map((currView:any,index:any)=>{
                return(
                  <div key={index} className="white-card padding5 marginTop5  marginRight5 border-primary position-relative shadow-light center-aligned-column" style={{width:"22%",maxWidth:"6rem",height:"6rem"}}>
                    {cameraFor2dRender.cameraId == currView.cameraId?
                      <span className="padding5 top-left" >
                        <span className="padding0 bg-color-success" style={{width:"7px",height:"7px",borderRadius:"5px"}}></span>
                      </span>
                    :null}
                    <span  style={{top:"0"}} className="top-right padding5 padding0" onClick={()=>{deleteSavedView(currView)}}>
                      <i className="fas fa-trash color-danger font-ex-small"></i>
                    </span>
                    <span className="middle heading3 font-ex-small">{index===0?"Defualt":`View${index+1}`}</span>

                    <div className="bottom-middle border-top" onClick={()=>loadCamera(currView)}>
                      <div className="display-flex-row no-wrap pointer-none padding5">
                        <span className="heading3 font-ex-small center-aligned-column">Load</span>
                        <span className="center-aligned-column marginleft5"> <i className="fas fa-arrow-right color-primary font-ex-small"></i> </span>
                      </div>
                    </div>

                  </div>
                )
              })}
            </div>
        </div>
        
        <div onClick={toggleFullScreen} className="position-relative display-none  border-top pointer" style={{padding:"24px 0",background:"rgba(255,255,255,1)"}}>
            <div className="middle display-flex-row">
                <i className="fas fa-eye-slash color-danger center-aligned-column"></i>
                <div className="heading3 font-md-small marginleft5">Hide view</div>
            </div>
        </div>
      </div>

      </>
    :
      <div className="top-left" style={{zIndex:1003}}>
         <Button2
            configObj={applicationConfig}
            action={toggleFullScreen}
            label={"Set Position"}
            fontAwesomeIcon={"fas fa-cog color-danger"}
            iconPos="left"
            classNames={"bg-color-white pulse-box"}
          />
      </div>
    }

    <>
    {/* <div className="bottom-left center-aligned-row crop-box-wrapper" style={{bottom:"8rem",pointerEvents:"all",zIndex:1001}}>
        <MovementControls/>
    </div> */}
    {/* <div className="right-middle" style={{right:"-115px",zIndex:1001}}>
      <div className="position-relative" style={{width:"300px"}}>
        <input type="range" onPointerDown={pointerDownHeightSlider} className="range-slider camera-height-slider bg-range-slider" onChange={updateHeightUsingTruck} id="rangeSliderHeight" style={{transform:"rotate(-90deg)"}}/>
         
        <div className="heading3 bottom-left font-small color-white text-center" style={{bottom:"-200px",left:"42%"}}>(Height)</div>
        <div className="heading3 bottom-left " style={{bottom:"-176px",left:"47%"}}> <i className="fas fa-camera color-white font-large color-"></i> 
        </div>
      </div>
    </div> */}
    <Cropper/>
    
    
   

    {!projectConfiguration?.isProductRenderMode?
    <>
     <div className="top-right padding10 hide-in-product-render-mode" style={{zIndex:1003}}>
      <Button2
        configObj={applicationConfig}
        action={closeCreateRenderHandle}
        label={"Close"}
        fontAwesomeIcon={"fas fa-times color-danger"}
        iconPos="left"
        classNames={"button- button- bg-color-black"}
        />
    </div>
      <div className="top-right padding10 hide-in-product-render-mode" style={{zIndex:1003,right:"8rem"}}>
        <Button2
          configObj={applicationConfig}
          action={updateView}
          label={"Save view"}
          fontAwesomeIcon={"fas fa-camera color-danger"}
          iconPos="left"
          classNames={`${cameraFor2dRender?.isRendered?"display-none":""}`}
          />
      </div>
      </>
    :null}

    

    <div className='header-user-options customizer-save-render-options white-card shadow-medium border' >
        <div className='option border-top display-flex-row' onClick={updateView}>
          <div><i className="fas fa-camera  color-dark font-normal"></i></div>
          <div className='marginleft10 heading1 font-small center-aligned-column'>Save</div>
        </div>


        <div className='option display-flex-row'   onClick={saveAsNewView}>
          <div><i className="fas fa-camera  color-dark font-normal"></i></div>
          <div className='marginleft10 heading1 font-small center-aligned-column'>Save as new</div>
        </div>
        </div>

    </>
  
 </>
  );
};

export default CreateRender2D;
 