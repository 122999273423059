import $ from "jquery";
import React, { useRef } from 'react';
import { toggleSaveOptions } from "../../../../../utils/customizein3d/area3dmodel/UI_methods";
import { saveConfiguration } from '../../../../../utils/customizein3d/area3dmodel/helper';
import { saveScreenShotToS3 } from "../../../../../utils/customizein3d/modules/helper";
import { getUser } from "../../../../../utils/methods";
import Button2 from "../../../ui-components/buttons/Button2";
import { hideComponentLoader, showComponentLoader, stopPropagation } from "../../../../../utils/UI_methods/global";

// import './Home.css';

const SaveConfigForm: React.FC<{
  modulesConfigurtion:any
}> = (props) => {


  const formRef = useRef(null)
 
  
  async function saveAsNew(val:any) {
    if(!String($("#configTitle").val()).length){
      alert("Please enter config name")
      return
    }
    showComponentLoader("mainLoaderSpinner")
    saveConfiguration(false,props.modulesConfigurtion).then(async (response:any)=>{
      props.modulesConfigurtion.updateConfigId(response.data.data.insertId)
      await saveScreenShotToS3()
      hideComponentLoader("mainLoaderSpinner")
    }).catch(err=>{
      console.log(err)
      hideComponentLoader("mainLoaderSpinner")
    })
    hide()
  }

 
 

  function Form() {
    return(
      <div ref={formRef} onPointerDown={stopPropagation} className="full-height-width op-modal-wrapper position-fixed top-left center-aligned-column" style={{display:"none",background:"rgba(0,0,0,0.5)",zIndex:1001}}>

        <div className="form-container" onPointerDown={stopPropagation} style={{width:"80%",maxWidth:"28rem"}}>
          <div className="form-label-input-container">
            <div className="label-container">
              <div className="label">Config Name</div>
            </div>
            <div className="input-container">
              <input
                className="input"
                type="text"
                name="title"
                id="configTitle"
                required
              />
            </div>
          </div>

          <div className="form-label-input-container ion-margin-top">
            <div className="label-container">
              <div className="label">Description</div>
            </div>
            <div className="input-container">
              <textarea
                className="input"
                name="description"
                id="configDescription"
                required
                cols={50}
                style={{height:"80px"}}
              />
            </div>
          </div>

          <div className="center-aligned-column  full-width">
            <div className="ion-margin">
            <button className="op-btn op-btn-primary" onClick={saveAsNew}>Save</button>
            <button className="op-btn op-btn-light marginleft10" onClick={hide}>Cancle</button>
            </div>
          </div>

        </div>
      </div>
    )
  }


  
  async function updateConfiguration() {
    
    saveConfiguration(true,props.modulesConfigurtion)
    hide()
  }

  function show() {
    $(formRef.current).fadeIn()
  }


  
  function hide() {
    $(formRef.current).fadeOut()
  }


  return(
    <>
      <Form/>
      {getUser()?.username === "DemoMaster" || true?
      <div className="position-relative">
        <Button2
          configObj={{}}
          action={toggleSaveOptions}
          label={"Save"}
          fontAwesomeIcon={"fas fa-chevron-down color-primary"}
          iconPos="left"
          classNames={"shadow-medium full-height no-border button-small user-options-link"}
        />
      <div className='header-user-options customizer-save-options white-card shadow-medium border' style={{position:"absolute",top:"101%",left:"0",zIndex:1001}} >
        <div className='option border-top display-flex-row' onClick={updateConfiguration}>
          <div><i className="fas fa-save  color-dark font-normal"></i></div>
          <div className='marginleft10 heading1 font-small center-aligned-column'>Save</div>
        </div>


        <div className='option display-flex-row'   onClick={()=>show()}>
          <div><i className="fas fa-save  color-primary font-normal"></i></div>
          <div className='marginleft10 heading1 font-small center-aligned-column'>Save as new</div>
        </div>
      </div>

      </div>
      :null}

      

      
    </>
      
  );
};
 
export default SaveConfigForm;
