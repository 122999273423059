import React, { useState } from "react";
import { createProductForm } from "../../../../../utils/products/createProductForm";
import AddProductForm from "./AddProductForm";
import Review from "./Review";

 

const CreateCustomOrder: React.FC<{
    onSuccess:()=>void
}> = (props) => {

    const [productDetails] = useState(new createProductForm());
    const [currProduct,setCurrProduct] = useState(null);


    return (
        <div className='overflow-y-scroll full-height-width'>
            {/* <CustomOrdersList/> */}
            <AddProductForm setCurrProduct={setCurrProduct} currProduct={currProduct}  productDetails={productDetails} onSuccess={props.onSuccess}/>
            <Review currProduct={currProduct} onSuccess={props.onSuccess} productDetails={productDetails}/>
        </div>
    );
};

export default CreateCustomOrder;
