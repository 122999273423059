import React, { useEffect, useState } from "react";
import { toggleDropdownMenu } from "../../../../../../utils/UI_methods/global";
import { getArrayOfDistinctValues } from "../../../../../../utils/methods";
import { updateAddonFill } from "../../../../../../utils/moodboard/addons";
import ColorSwatch from "../../../../../common/shared-components/Textures/ColorSwatch";
import DropDown from "../../../../../common/ui-components/filters/DropDown";

const ColorOptions: React.FC<{
  configObj:any
  action:(data:any)=>void
}> = (props) => {


  const [allColorsCollections, setAllColorsCollections] = useState<any>([])
 
  const [currColorValue,setCurrColorValue] = useState('Blues')
  const [colorOptions, setColorOptions] = useState<any>([])

  useEffect(()=>{
    if(props.configObj){
      setColorOptions(props.configObj.data.colorOptions)
      let collections = getArrayOfDistinctValues(props.configObj.data.colorOptions,"color_group")
      setAllColorsCollections(collections)
    }
  },[props.configObj])

   
  function ColorsGroupDropDown() {
    return (
      <div className="collection-dropdown-container">

        <div className="dropdown-container">
        <div className="dropdown-select border text-start dropdown-toggle full-width" onClick={(event)=>toggleDropdownMenu(event)}>{currColorValue}</div>
          <div
            role="menu"
            aria-hidden="true"
            className="hide-scrollbar dropdown-menu-rounded dropdown-menu"
           >
            {allColorsCollections?.map((collection: any) => {
            return (
                <button key={collection} type="button" className="dropdown-item"  onClick={() =>
                  selectColorGroup(collection)
                  }>
                  {collection}
               </button>
            )
          })}
          </div>
        </div>
      </div>
    )
  }
  function selectColorGroup(colorGroup: string) {
    setCurrColorValue(colorGroup)
    if (colorGroup.toLowerCase() === "all") {
      setColorOptions(props.configObj?.data.colorOptions)
    } else {
      let filteredColorOptions = props.configObj?.data.colorOptions.filter((color: any) => {
        return color.color_group === colorGroup
      })
      setColorOptions(filteredColorOptions)
    }
  }

  function MaterialDropDown(){
    return (
      <DropDown
        classNames=""
        columns={1}
        values={["Default"]}
        currSelectedValue={"WallPaint"}
        action={()=>{}}
        label="FinishType"
        />
    );
  }

  
  function CompanyDropDown(){
    return (
      <DropDown
        classNames=""
        columns={1}
        values={["Default"]}
        currSelectedValue={"Default"}
        action={()=>{}}
        label="CompanyName"
        />
    );
  }

  function CollectionsDropDown(){
    return (
      <DropDown
        classNames=""
        columns={1}
        values={allColorsCollections}
        currSelectedValue={currColorValue}
        action={selectColorGroup}
        label="ColorGroup"
        />
    );
  }


 
  return (
    <React.Fragment>
      {/* <div className="full-width-drop-down" style={{marginBottom:"5px"}}>
        <MaterialDropDown/>
      </div> */}
      <div className="full-width display-flex-row no-wrap">
        {/* <div className="width50"><CompanyDropDown/></div> */}
        <div className="full-width marginBottom5"><CollectionsDropDown/></div>
        </div>

        {/* <ColorsGroupDropDown /> */}
          <ColorSwatch
            configObj={props.configObj}
            colorOptions={colorOptions}
            action={props.action}
          />

    </React.Fragment>
  );
};

export default ColorOptions;
