import React, { useState } from "react";
import { applicationConfig, getFilteredArray } from "../../../utils/methods";
import Button2 from "../../common/ui-components/buttons/Button2";
import CreateUser from "./CreateUser/CreateUser";
import Modal from "../../common/shared-components/Modals/Modal";
import Toast from "../../common/ui-components/notification/Toast";
import CreateClient from "./CreateUser/CreateClient";
import { IonAlert } from "@ionic/react";
import { deleteClient, getClientsList } from "../../../services/api";
import { showToast } from "../../../utils/UI_methods/global";
import { getFormatedDate } from "../../../utils/store/crm/crm";


const ClientsList: React.FC<{
  }> = (props) => {

    const[isOpenModal,setIsOpenModal] = useState(false)
    const[usersList,setUsersList] = useState(applicationConfig?.data?.clientsList)
    const[showAlert,setShowAlert] = useState(false)
    const[currUser,setCurrUSer] = useState(null)
  

    function ModalFooter() {
      return(
        <Button2
            configObj={applicationConfig}
            action={()=>{setIsOpenModal(false)}}
            label={"Close"}
            fontAwesomeIcon={"fas fa-user color-primary"}
            iconPos=""         
            classNames={"button-light margin0 bg-color-light shadow-light"}   
        />
      )
    }

    function deleteAction() {
      deleteClient({id:currUser.id}).then((response:any)=>{
          if(response.data.error){
              alert(response.data.message)
          }else{
            getClientsList().then(data=>{
                applicationConfig.updateData("clientsList",data)
                setCurrUSer(null)
                setUsersList(data)
            })
            showToast("Client deleted successfully",2000)
          }
      }).catch(err=>{
          console.log(err)
      })
    }
    

    function closeModal(){
      setIsOpenModal(false)
      setUsersList(applicationConfig?.data?.clientsList)
    }

    function deleteHanlde(user:any) {
      setCurrUSer(user)
      setShowAlert(true)
    }

    function updateHanlde(user:any) {
      setCurrUSer(user)
      setIsOpenModal(true)
    }

    function createHanlde() {
      setCurrUSer(null)
      setIsOpenModal(true)
    }
    
     
    return (
      <div className="full-height-width display-flex-column no-wrap  white-card" style={{margin:"10px"}}>

          <Modal
            isOpen = {isOpenModal}
            Content = {()=>(
              <CreateClient
                callBack={closeModal}
                savedUser={currUser}
              />
            )}
            onDismiss = {()=>setIsOpenModal(false)}
            heading="Create client"
            classNames="vertical-modal"
            id=""
            FooterContent={ModalFooter}
          />

      <IonAlert
        isOpen={showAlert}
        mode="ios"
        onDidDismiss={() => setShowAlert(false)}
        cssClass=""
        header={"Confirm!"}
        message={"Delete user !!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Configuration not finalized");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              deleteAction()
            },
          },
        ]}
      />

        <Toast/>

        <div className="border-bottom padding10 display-flex-row no-wrap space-between">
          <div className="header center-aligned-column  heading2"> Clients List</div>
          <Button2
            configObj={applicationConfig}
            action={()=>createHanlde()}
            label={"Create client"}
            fontAwesomeIcon={"fas fa-plus color-primary"}
            iconPos="left"         
            classNames={"button-light margin0 bg-color-primary no-shadow"}   
          />
        </div>

        <div className="overflow-y-scroll ion-margin-bottom fill-available-height">
          <table className="users-table table">
              <thead>
              <tr className="display-noe">
                  <th>#</th>
                  <th>Client Name</th>
                  {/* <th>Email</th> */}
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Status</th>
                  <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {usersList?usersList.map((user:any,index:any)=>{
                  return (
                  <tr className="" key={index}>
                      <td>{index+1}</td>
                      <td>{user.client_name}</td>
                      {/* <td>{user.email}</td> */}
                      <td>{getFormatedDate(user.subscription_start_date)}</td>
                      <td>{getFormatedDate(user.subscription_end_date)}</td>
                      <td className="capitalize">{user.status}</td>
                      <td className="capitalize">{user.status === "inactive" || user.status === "suspended"?<span className="color-danger heading3 font-small">{user.status}</span>:<span className="color-success heading3 font-small">{user.status}</span>}</td>
                      <td>
                        <div className="display-flex-row no-wrap">
                          <i className="fas fa-edit color-black pointer" onClick={()=>updateHanlde(user)}></i>
                          <i className="fas fa-trash marginleft10 color-black pointer" onClick={()=>deleteHanlde(user)}></i>
                        </div>
                      </td>
                  </tr>
                  )
              }):null}
            </tbody>
          </table>
        </div>
      </div>
    )
       
  };
  
export default ClientsList;
  