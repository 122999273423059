import $ from "jquery"
import { DoubleSide, Euler, MathUtils, Mesh, MeshBasicMaterial, PlaneGeometry, Vector3 } from "three"
import { getWallBoundingBoxWithoutChildren, setPlaneNormalVector } from "../area3dmodel/walls"
import { getObjectDimensionPosition } from "../modules/helper"
import { Floorplan } from "./floorplan"

var FacePoints1_0,FacePoints1_1,FacePoints1_2,FacePoints1_3
var FacePoints2_0,FacePoints2_1,FacePoints2_2,FacePoints2_3

export const pixelsPerUnit = 1


var floorplanner = null

export function heperInit(floorplanObject:Floorplan) {
    floorplanner = floorplanObject
}


const materialForPlane = new MeshBasicMaterial({
    opacity: 1,
    // visible:false,
    transparent:true,
    side: DoubleSide,
    color:"black"
});

export function createFrontAndBackPlane(wall:Mesh){
    let center = getObjectDimensionPosition(null,floorplanner.group).center
	let boundingbox = getWallBoundingBoxWithoutChildren(wall)
	
	// let boundingbox = getObjectDimensionPosition(null,mesh).positions
    let width = boundingbox.max.x-boundingbox.min.x
    let depth = boundingbox.max.z-boundingbox.min.z


	let tempVector = new Vector3(0,0,0)
    
    if(width>depth)
    {
		tempVector.set(0,0,depth)
        // plane1
        FacePoints1_0 = boundingbox.min
        FacePoints1_1 = new Vector3(boundingbox.max.x,boundingbox.min.y,boundingbox.min.z)
        FacePoints1_2 = new Vector3(boundingbox.min.x,boundingbox.max.y,boundingbox.min.z)
        FacePoints1_3 = new Vector3(boundingbox.max.x,boundingbox.max.y,boundingbox.min.z)
        // plane2
        FacePoints2_0 = FacePoints1_0.clone().add(tempVector)
        FacePoints2_1 = FacePoints1_1.clone().add(tempVector)
        FacePoints2_2 = FacePoints1_2.clone().add(tempVector)
        FacePoints2_3 = FacePoints1_3.clone().add(tempVector)
    }
    else
    {
		tempVector.set(width,0,0)
        // plane1
        FacePoints1_0 = boundingbox.min
        FacePoints1_1 = new Vector3(boundingbox.min.x,boundingbox.min.y,boundingbox.max.z)
        FacePoints1_2 = new Vector3(boundingbox.min.x,boundingbox.max.y,boundingbox.min.z)
        FacePoints1_3 = new Vector3(boundingbox.min.x,boundingbox.max.y,boundingbox.max.z)
        // plane2
		
        FacePoints2_0 = FacePoints1_0.clone().add(tempVector) 
        FacePoints2_1 = FacePoints1_1.clone().add(tempVector)
        FacePoints2_2 = FacePoints1_2.clone().add(tempVector)
        FacePoints2_3 = FacePoints1_3.clone().add(tempVector)
    }

	var pointsForPlane1 = [FacePoints1_0, FacePoints1_1, FacePoints1_2, FacePoints1_3];
	var pointsForPlane2 = [FacePoints2_0, FacePoints2_1, FacePoints2_2, FacePoints2_3];
			 

    let plane1 = createVirtualPlane(pointsForPlane1)
    let plane2 = createVirtualPlane(pointsForPlane2)



    let FrontPlane = getObjectDimensionPosition(null,plane1).center.distanceTo(center) <  getObjectDimensionPosition(null,plane2).center.distanceTo(center) ? plane1 : plane2
    let BackPlane = getObjectDimensionPosition(null,plane2).center.distanceTo(center) >  getObjectDimensionPosition(null,plane1).center.distanceTo(center) ? plane2 : plane1
	
	FrontPlane.userData.wall = wall
	BackPlane.userData.wall = wall

	FrontPlane.visible = false
	BackPlane.visible = false

	setPlaneNormalVector(FrontPlane,pointsForPlane1,center)
	setPlaneNormalVector(BackPlane,pointsForPlane2,center)


	let normal = FrontPlane.userData.normal
	FrontPlane.translateOnAxis(normal,0.05)

    // floorplanner.group.add(FrontPlane)
	// floorplanner.group.add(BackPlane)

	// isCreatedVirtualPlane = true
    wall.userData.normal = normal

    return normal

}

export function createVirtualPlane(points:any) {
	
	var geometryForPlane2 = new PlaneGeometry().setFromPoints(points)
	return new Mesh(geometryForPlane2,materialForPlane);
}

export function convert3dPointsToFeet(vector1:Vector3,vector2:Vector3,scale:number = 1) {

    const distance = vector1.distanceTo(vector2) * scale
    
    let distanceInFeet = distance / pixelsPerUnit;
    distanceInFeet = distanceInFeet * 100
    distanceInFeet = distanceInFeet/30.48 
    
    const wholeFeet = Math.floor(distanceInFeet);
    
    const remainingInches = Math.round((distanceInFeet - wholeFeet) * 12)

    if(remainingInches === 12){
        return {
            feet:wholeFeet + 1,
            inch:0,
        }  
    }

    return {
        feet:wholeFeet,
        inch:remainingInches,
    }
}

export function convert3DPointsToInches(vector1:Vector3,vector2:Vector3) {
    const distance = vector1.distanceTo(vector2);

    const inches = (distance / pixelsPerUnit) * 100 / 2.54;

    return inches;
}

export function convert3DPointsToCentimeters(vector1, vector2) {
    const distance = vector1.distanceTo(vector2);

    const centimeters = (distance / pixelsPerUnit) * 100;

    return centimeters;
}


export function openDimensionsOptions() {
    $(".dimensions-options").addClass("--is-active")
  }

  export function closeDimensionsOptions() {
    $(".dimensions-options").removeClass("--is-active")
  }

  export function toggleDimensionsOptions() {
    $(".dimensions-options").toggleClass("--is-active")
  }


export function quaternionToEulerAngle(quaternion) {
    var euler = new Euler();
    euler.setFromQuaternion(quaternion, 'XYZ');
    return euler.toVector3().multiplyScalar(MathUtils.RAD2DEG);
}
