import {
  IonIcon
} from "@ionic/react";
import {
  close, expandOutline,
  settingsOutline
} from "ionicons/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { deleteProject, getAllProjectAreas, getProjects } from "../../../../services/api";
import { showToast } from "../../../../utils/UI_methods/global";
import { projectConfiguration, setProjectConfigObject } from "../../../../utils/customizein3d/area3dmodel/area3dModel";
import { saveConfiguration } from "../../../../utils/customizein3d/area3dmodel/helper";
import { getProjectState } from "../../../../utils/projects/projects";
import Loader from "../../../common/shared-components/main-ui/Loader";
import DeleteLink from "../../../common/ui-components/links/DeleteLink";
import EditLink from "../../../common/ui-components/links/EditLink";
import Toast from "../../../common/ui-components/notification/Toast";
import CreateProject from "../createproject/CreateProject";
import ProjectProgress from "./ProjectProgress";
import { applicationConfig } from "../../../../utils/methods";

const Home: React.FC<{
  currProjectId: number;
  setCurrState: (state: string) => void;
  setOpenProjectAction: (value: boolean) => void;
  allProjects:any
  moodBoardSavedconfigs:any
  allAreas:any
  currProjectName:string
  setCurrProjectDetails:(id:number,name:string)=>void
  CLIENT_ID:number
  applicationConfigObj:any
  setCurrMoodboardId:(id:string)=>void
  editProject:(id:number)=>void
  refreshProjects:(projects:Array<any>,allAreas:Array<any>)=>void
  configObj:any
  allCategories:any
}> = (props) => {
  const history = useHistory();

  const[projectStatus,setProjectStatus] = useState<number>(1)
  const [createNewProject, setCreateNewProject] = useState(false);
  const [loaderId] = useState<any>("sharelinkLoader");

  // const[isCollectionConfirmed,setIsCollectionConfirmed] = useState(false)

  const [projectActions,setProjectActions] = useState([
    // {
    //   name: "moodboard",
    //   label: "Create DesignBoard",
    //   status: 1,
    //   icon:colorPaletteOutline,
    //   iconClassNames:["color-danger"],
    //   action: gotoMoodBoard,
    // },

    {
      name: "customizeIn3D",
      label: "Configure in 3D",
      status: 3,
      icon:expandOutline,
      iconClassNames:["color-info"],
      action: gotoCustomizeIn3D,
    },
    {
      name: "customizeFinish",
      label: "Customize Finish",
      status: 2,
      iconClassNames:["color-dark"],
      icon:settingsOutline,
      action: updateSelection,
    },
  ]);



  function postDeleteActions() {
    let promiseProjects = getProjects();
    let promiseAllAreas = getAllProjectAreas();
    Promise.all([promiseProjects,promiseAllAreas])
      .then((data) => {
        let configData = props.configObj.getData()
        configData['projects'] = data[0]
        configData['projectAreasList'] = data[1]
        props.configObj.setData(configData)
        props.refreshProjects(data[0],data[1])
        props.setOpenProjectAction(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  // function gotoMoodBoard(){
  //   //Check if moodboard if exist
  //   // let projectId:any = props.currProjectId
  //   let moodboardId = null
  //   let moodboardList = getFilteredArray(props.applicationConfigObj?.data.moodboardList,"project_id",props.currProjectId.toString())
  //   if(moodboardList){
  //     moodboardId = moodboardList[0]?.moodboard_id
  //   }
  
  //   props.setCurrProjectDetails(props.currProjectId,props.currProjectName)
  //   props.setCurrMoodboardId(moodboardId)
  //   console.log(isCollectionConfirmed)
  //   if(getUserType()?.toLowerCase()!="admin" || isCollectionConfirmed){
  //     setMoodboardId(moodboardId)
  //     setShowFinalSummary(true)
  //   }else{
  //     props.setOpenProjectAction(false)
  //     history.push('/edit-moodboard')
  //   }
  // }

  function gotoCustomizeIn3D() {
    props.setCurrProjectDetails(props.currProjectId,props.currProjectName)
    props.setOpenProjectAction(false)
    projectConfiguration.updateLocalStorage()
    saveConfiguration(false,projectConfiguration,projectConfiguration.configName).then(data=>{
      console.log(data)
      history.push('/3dcustomization')
      setProjectConfigObject(projectConfiguration)
    }).catch(err=>{
      showToast("error in saving config",2000,"error")
    })
    
  }

    
 


 
  function updateSelection() {
    props.setCurrProjectDetails(props.currProjectId,props.currProjectName)
    props.setCurrState("Minimap");
  }



 
  function EditItemComponent() {
    return(
      <CreateProject
      closeModal={setCreateNewProject}
      projectId={props.currProjectId}
      CLIENT_ID={props.configObj?.clientId}
      refreshProjects={props.refreshProjects}
      allCategories={props.allCategories}
      configObj={props.configObj}
    />
    )
  }

  function removeProject() {
    deleteProject(props.currProjectId).then(data=>{
      postDeleteActions()
      console.log(data)
    }).catch(err=>{
      console.log(err)
    })
}
 

  return (
    <React.Fragment>
        <Toast/>

      

      <div className="project-action-container">
    
        <IonIcon
          className="modal-close-icon"
          icon={close}
          ios={close}
          onClick={() => props.setOpenProjectAction(false)}>
        </IonIcon>

        <Loader
            id={loaderId}
            loaderType="spinner"
            data={{}}
            className="fade-background-loader"
          />

        <div className="background-white full-height-width">
        <div className="project-details center-aligned-column full-width position-relative" style={{height:"100%"}}>

        <div className="top-left zindex30 padding10  display-flex-row" >
          {applicationConfig?.isMasterLogin()?
          <EditLink
            configObj={props.configObj}
            editItemName={props.currProjectName}
            editItemId={props.currProjectId}
            EditItemComponent={EditItemComponent}
          />
          :null}
          

        {/* <div className="top-right zindex30">
          <div className="display-flex-row">
              <div className="display-flex-row fade-background-loader white-card card-padding">
                <div className="center-aligned-column"> <i className="fas fa-location-arrow color-danger"></i> </div>
                <div className="center-aligned-column marginleft5">Edit Collection</div>
              </div>
          </div>
        </div> */}

      
        </div>
       
        <div className="top-right zindex30 padding10">
            <DeleteLink
            configObj={props.configObj}
            action={removeProject}
            />
            </div>

          {/* <ShareLink
              configObj={props.configObj}
              shareItemId={props.currProjectId}
              loaderId = {loaderId}
              moduleName="projects"
              isShareButton={false}
              textClass="color-white"
            /> */}


       
          <div className="floor-plan-image-container position-relative" >
            <img src="/assets/images/3dwalkthrough.jpg" alt=""/>

            <ProjectProgress
               configObj={props.configObj}
               currProjectId={props.currProjectId}
               currProjectName={props.currProjectName}
               setCurrProjectDetails={props.setCurrProjectDetails}
               setOpenProjectAction={props.setOpenProjectAction}
               setCurrMoodboardId={props.setCurrMoodboardId}
            />

          </div>
          
        </div>


        <div className="position-relative center-aligned-column" style={{height:"0",display:"none"}}>
            {/* <Actions
              configObj={props.configObj}
              actionList={projectActions}
            /> */}
              <div className="project-actions no-wrap position-relative display-flex-row">
                {projectActions?.map((action:any,index:number)=>{
                  let state = getProjectState(props.currProjectId,props.configObj)
                  let classNames = ["white-card" ,"shadow-medium","project-action"]
                  // if(state<action.status){
                  //   classNames.push("disable")
                  // }
                  if(action.name.includes("customizeIn3D")){
                    classNames.push("coming-soon")
                  }
                  let classNameIcon = ["icon"]
                  classNameIcon.push(action.iconClassNames.join(" "))
                  return(
                    <div className={classNames.join(" ")} key={index} onClick={action.action}>
                      <IonIcon
                        className={classNameIcon.join(" ")}
                        icon={action.icon}
                        ios={action.icon}></IonIcon>
                      <div className="text">{action.label}</div>
                    </div>
                  )
                })}
              </div>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
