import React, { useEffect, useRef, useState } from "react";
import { appendModel, sprites } from "../../../../../utils/customizein3d/modules/customizein3d";
import { applicationConfig, getSubDomainName } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Finish from "../../../../common/modules/customizein3d/controls/Finish";
import ProductModules from "../../../../common/shared-components/product-modules/ProductModules";
import AccordionForProccessCss from "../../../../common/ui-components/accordion/AccordionForProccessCss";
import ProductImage from "../../../../common/ui-components/elements/ProductImage";
import { getWindowWidth } from "../../../../../utils/UI_methods/global";
import SpacePlanner from "../../../../common/modules/customizein3d/controls/SpacePlanner";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import $ from "jquery"
import Modal2 from "../../../../common/shared-components/Modals/Modal";
import SceneBackgrounds from "./SceneBackgrounds";
import SpritesMaterials from "../../../../common/modules/customizein3d/controls/SpritesMaterials";
import { isPotraitMode } from "../../../../../utils/customizein3d/common";
import ControlsTab from "../../../../common/ui-components/tabs/ControlsTab";
import ProductImages from "../../../projects/projectActions/moodboard/controls/ProductImages";
import { addItemToLocalStorage } from "../../../../../utils/cache/localstorage";
import SummaryFooter from "./SummaryFooter";

const ElevateControls: React.FC<{
  modulesConfigurtion:any
  product:any
}> = (props) => {
   
  const[currProduct] = useState(props.modulesConfigurtion.product)
  const[productsList] = useState(applicationConfig?.data?.productsList)
  const[windowWidth] = useState(getWindowWidth())
  const[isElevateMode,setIsElevateMode] = useState(getSubDomainName() === "elevate")
  const[isPotrait,setIsPotrait] = useState(isPotraitMode())
  const [currTab, setCurrTab] = useState<string>(isElevateMode?"SelectProduct":"Fabric");

  const productRef = useRef(null)
  const[tabItems,setTabItems] = useState(getTabItmes())

  function getTabItmes() {
    if(STORE.isKanvasMode){
      return [
        {tabName:"Fabric",label:"Fabric",ionIcon:null},
      ]
    }else{
      if(isElevateMode){
        return [
          {tabName:"SelectProduct",label:"Select Product",ionIcon:null},
          {tabName:"AddModules",label:"Add Modules",ionIcon:null},
          {tabName:"Fabric",label:"Choose Finish",ionIcon:null},
        ]
      }else{
        return [
          {tabName:"SelectProduct",label:"Select Product",ionIcon:null},
          {tabName:"AddModules",label:"Add Modules Product",ionIcon:null},
          {tabName:"Fabric",label:"Fabric",ionIcon:null},
        ]
      }
      
    }
    
  }

 
  function loadProduct(product:any) {
    window.location.href = "/customizein3d/"+product.product_name
  }

  function ProductsList() {
    return(
      <div className="display-flex-row padding10">
        {productsList.map((product:any,index:number)=>{
          return(
            <div onClick={()=>{loadProduct(product)}} style={{width:"33%"}} className={`padding5 pointer ${currProduct.productName === product.product_name?" border border-color-primary bg-color-light":" "}`} key={index}>
              <ProductImage product={product}/>
              <div className="text-center marginTop5 heading4 color-black">{product.product_name}</div>
            </div>
          )
        })}
      </div>
    )
  }
  function closeRoomLayout() {
    $("#roomLayoutContainer").addClass("display-none")
  }



  function show() {
    $(productRef.current).removeClass("display-none")
  }

  function hide() {
    $(productRef.current).addClass("display-none")
  }

  function changeTabOption(tabName: string) {
    setCurrTab(tabName);
    updateTabValue(tabName)
  }
  function updateTabValue(val:string){

    $("#elevateControlsWrapper").find(".tab-content").removeClass("--is-active")
    $("#"+val).addClass("--is-active")
  }
  function showMobileInputWindow() {
    $("#mobileInputWindow").removeClass("display-none")
  }

  async function  changeProduct(product:any) {
    addItemToLocalStorage("currProduct",product)
    STORE.currProduct = product
    // setCurrProduct(product)
    addItemToLocalStorage("sharedItem",null)
    window.location.href = "/customizein3d"
    // setIsOpenOptions(true)
    // $(ref.current).fadeIn()
  }


  return (
      <>
       {!isPotrait?
        <div id="elevateControlsWrapper" className="accordion-wrapper full-height elevate-controls-wrapper padding5">


      {!STORE.isKanvasMode?
        <AccordionForProccessCss
            label="Select Product"
            SubHeader={()=>(
              <div id="currProduct" className="heading2 font-md-small marginRight10 center-aligned-column color-black"></div>
            )}
            Content={()=>(
              <ProductImages
                  setProductModelSrc={()=>{}}
                  configObj={applicationConfig}
                  currArea={""}
                  action={(product)=>{changeProduct(product)}}
                  moduleName="customizein3d"
                  dragStart={()=>{}}
                  parentContainerId="changeConfiguratorProduct"
              />
            )}
            index={isElevateMode?1:2}
            Footer={()=>(
              <></>
            )}
        />
        :null}

        



        {!STORE.isKanvasMode?
          <AccordionForProccessCss
            label="Add Modules"
            SubHeader={()=>(
              <div id="currVariant" className="heading2 font-md-small marginRight10 center-aligned-column color-black">{}</div>
            )}
            Content={()=>(
              <ProductModules
                productId={props.modulesConfigurtion.product.productId}
                moduleRef={{}}
                action={(module: any) => appendModel(module, false)}
                moduleName="customizein3d"
                dragStart={()=>{}}
                parentContainerId={"addModulesContainer"}
              />
            )}
            index={2}
            Footer={()=>(
              <></>
            )}
          />
        :null}

          <AccordionForProccessCss
            label={isElevateMode?"Choose Finish":"Fabric"}
            SubHeader={()=>(
              <div id="currMaterial" className="heading2 font-md-small marginRight10 center-aligned-column color-black"></div>
            )}
            Content={()=>(
              <Finish
                configObj={applicationConfig}
              />
            )}
            index={isElevateMode?3:1}
            Footer={()=>(
              <></>
            )}
          />

       

  
          
  
          
  
        </div>
        :
        <div id="elevateControlsWrapper" className="controls-options full-height-width padding5">

         

          <div className="full-width tabs">
            <ControlsTab
              configObj={applicationConfig}
              tabItems={tabItems}
              currSelectedValue={currTab}
              action={changeTabOption}
              theme="black"
            />
          </div>

          <div id="SelectProduct" className={`tab-content ${isElevateMode?" --is-active":""}`}>
            <ProductsList/>
          </div>

          <div id="AddModules" className="tab-content ">
              <ProductModules
                productId={props.modulesConfigurtion.product.productId}
                moduleRef={{}}
                action={(module: any) => appendModel(module, false)}
                moduleName="customizein3d"
                dragStart={()=>{}}
                parentContainerId={"addModulesContainer"}
              />
          </div>

          <div id="Fabric" className={`tab-content ${isElevateMode?"":" --is-active"}`}>
            <Finish
                configObj={applicationConfig}
              />
          </div>

      </div>
        }
        <div id="roomLayoutContainer" className="full-height-width top-left bg-color-white padding0 display-none" style={{zIndex:1001}}>
          <div className="heading2 color-black bg-color-active position-relative zindex1 padding1rem text-center shadow-light border-bottom">
          Room Layout
          </div>
          <SpacePlanner
            configObj={applicationConfig}
          />
          <div className="bottom-middle padding10">
            <Button2
              configObj={{id:""}}
              action={closeRoomLayout}
              label={"Close"}
              fontAwesomeIcon={"fas fa-arrow-left color-danger"}
              iconPos=""
              classNames={"store-bg-color-primary"}
            />
          </div>
        </div>
        <SceneBackgrounds/>


        {STORE.isKanvasMode?
        <div className="bottom-left full-width padding0">
          <SummaryFooter
              product={props.product}
              productConfiguration={props.modulesConfigurtion}
              isElevateMode={false}
          />
        </div>
        
        :
        <div className="bottom-middle">
          <Button2
            configObj={{id:""}}
            action={()=>{showMobileInputWindow()}}
            label={"Get Quotation"}
            fontAwesomeIcon={"fas fa-arrow-right color-white"}
            iconPos="right"
            classNames={"shadow-medium no-shadow button-large elevate-bg-color-warning"}
          />
        </div>
        }

        

        
        

        {/* <div className="hide-in-desktop bottom-right padding10">
          <Button2
              configObj={{id:""}}
              action={()=>{show()}}
              label={""}
              fontAwesomeIcon={"fas fa-plus color-white"}
              iconPos="left"
              classNames={"shadow-medium bg-color-primary"}
          />
        </div> */}
      </>
    );
};

export default ElevateControls;
