import $ from "jquery";
import React, { useEffect, useState } from "react";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray } from "../../../utils/methods";
import { closeFiltersPanelMobile } from "../../../utils/UI_methods/global";
import SubCategories from "../../common/shared-components/products/SubCategories";
import Button2 from "../../common/ui-components/buttons/Button2";


const Filters: React.FC<{
}> = (props) => {

  
  const[materialTypesMaterials,setMaterialTypesMaterials]:any = useState([])
  const[currSelectedCompany,setCurrSelectedCompany] = useState<string>("")

  
  //Untar file for all collections
  useEffect(()=>{
    //Filter materials based on material type
    let filteredMaterials = getFilteredArray(applicationConfig?.data.materials,"material_type",applicationConfig?.states.finish.currSelectedMaterialType)
    setMaterialTypesMaterials(filteredMaterials)

    // props.setMaterials(filteredMaterials)
    applicationConfig?.functions?.finish?.setMaterials(filteredMaterials)
    //Get all companies 
    // setCurrSelectedCompany(companyNames[0])
    setCurrSelectedCompany("All")
  
  },[applicationConfig?.states.finish.currSelectedMaterialType])

  useEffect(()=>{
    if(currSelectedCompany.length && materialTypesMaterials.length){
      let filteredArray:any = getFilteredArray(materialTypesMaterials,"company_name",currSelectedCompany)
      if(currSelectedCompany?.toLowerCase() === "all"){
        filteredArray = materialTypesMaterials
      }
      let allCollections:any = getArrayOfDistinctValues(filteredArray,"collection_name")
      // props.setCollectionList(allCollections)
      applicationConfig?.functions?.finish?.setCollectionList(allCollections)

    }
  },[currSelectedCompany,materialTypesMaterials])

  useEffect(()=>{
    applicationConfig.setState("finish",{currSelectedCompany:currSelectedCompany})
  },[currSelectedCompany])


  function FilterMaterials(materialType:string,companyName:string) {
    // applicationConfig?.states.finish.currSelectedMaterialType(materialType)
    setCurrSelectedCompany(companyName);
    closeFiltersPanelMobile()
  }


  return (
  <div className="fill-available-height position-relative ">

        {/* <div className="padding10 heading3 bg-color-ternary marginBottom5">{applicationConfig?.states.finish.currSelectedMaterialType} / {currSelectedCompany}</div> */}

        {/* <Accordian
          itemsList={applicationConfig?.data.materials}
          action={FilterMaterials}
          parentItemKey={"material_type"}
          childItemKey={"company_name"}
          currParentValue={applicationConfig?.states.finish.currSelectedMaterialType}
          currChildValue={currSelectedCompany}
          setInitialChildValue={setCurrSelectedCompany}
          label="MaterialType / Company"
          moduleName="finish"
        /> */}

      <div className="hide-in-mobile">
        <Button2
          configObj={{}}
          action={()=>applicationConfig?.functions.finish.setCurrSelectedMaterialType("")}
          label={"Material types"}
          fontAwesomeIcon={"fas fa-chevron-left color-primary"}
          iconPos="left"
          classNames={"shadow-light margin0 font-medium full-width bg-color-black"}
        />

        <div className="bg-color-light heading3 padding10 marginTop10">
          {applicationConfig?.states.finish.currSelectedMaterialType}
        </div>
       
      </div>

      


    

        <SubCategories
          updateProducts={FilterMaterials}
          subCategories={getArrayOfDistinctValues(materialTypesMaterials,"company_name")}
          currSelectedCategory={applicationConfig?.states.finish.currSelectedMaterialType}
          currSelectedSubCategory={applicationConfig?.states.finish.currSelectedMaterialType}
          productsList={applicationConfig?.data?.productsList}
        />
      
  </div>
  );
};

export default Filters;
