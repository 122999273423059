import React, { useEffect, useRef, useState } from "react";
import { getRandomString, stringWithoutWhiteSpace } from "../../../../utils/methods";
import { loadAndDisplayModuleImage } from "../../../../utils/store/UI_methods";

const ProductImage: React.FC<{
  module:any
}> = (props) => {

  const[imageId]  = useState(`${stringWithoutWhiteSpace(props.module?.module_name)}_${getRandomString(10)}`)

  const[module] = useState(props.module)


  const imageRef = useRef(null)

  useEffect(()=>{
    setTimeout(() => {
      if(imageRef){
        loadAndDisplayModuleImage(imageRef.current)
      }
    }, 500);
  },[props.module,imageRef])


  return (
    <>
    {props.module?
    <img 
        data-product-name={module.module_name}
        data-product-category={module.category_name}
        data-product-subcategory={module.sub_category_name || "Default"}
        data-sub-module-type={module.sub_module_type || "Default"}
        data-product-price={module.price || 0}
        data-module-type={module.module_type} 
        data-product-id={module.module_id}
        data-image-type="module"
        className="module-image zindex1"
        alt="" style={{height:"6rem",objectFit:"contain"}} 
        ref={imageRef}
    />
    :null}
    </>
    
  );
};

export default ProductImage;
