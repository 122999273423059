import React, { useEffect, useState } from "react";
import { hideComponentLoader, showComponentLoader, showToast, updateLoaderProgress } from "../../../../../../../utils/UI_methods/global";
import { customizerConfig, disableFloorplanMode, projectConfiguration, undo } from "../../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { addAnchor, addPulseAnimation, confirmWalls, disableSelectWalls, enableSelectWalls, hideWallSelectionHint, highlightSelectedAreaWalls, highlightSelectedWalls, isAllAreasCompleted, pulsateNextArea, removeAreaAnchor, removeAreaHighlight, setCurrAreaName, showAllAreaAnchors, showWallSelectionHint, updateAllAreaAnchorsPosition } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/floorplanUI";
import { getNextArea, getPrevArea } from "../../../../../../../utils/customizer/helper";
import { applicationConfig, waitFor } from "../../../../../../../utils/methods";
import Modal from "../../../../../../common/shared-components/Modals/Modal";
import Loader from "../../../../../../common/shared-components/main-ui/Loader";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import DebugOptions from "../../common/DebugOptions";
import EditFloorplan from "./EditFloorplan";
import SelectWallsHint from "./SelectWallsHint";
import ProcessHeader from "../../../../../../common/ui-components/header/ProcessHeader";
const AreaDetailsInterface: React.FC<{
  isFinalizeMode:boolean
  isFloorPlanMode:boolean
}> = (props) => {

  const[areasList,setAreasList] = useState([])
  const[currselectedArea,setCurrAreaFloorplan] = useState(null)
  // const[currAreaWalls,setCurrAreaWalls] = useState([])
  const[isUpdateMode,setIsUpdateMode] = useState(false)
  const[areaDetailsMapping,setAreaDetailsMapping] = useState(null)
  //whether selecting walls or anchr..based on call the submit function
  const[showRenderModal,setShowRenderModal] = useState(false)
  const[isWallSelection,setIsWallSelection] = useState(false)
 
  const[completeCount,setCompleteCount] = useState(0)
  const[hintText,setHintText] = useState(projectConfiguration.projectName)

  
  
  const[guideBoxData,setGuideBoxData] = useState({
    guideMessage:"",
    confirmButtonLabel:"",
    confirmButtonAction:()=>{},
  })


  useEffect(()=>{
    if(areaDetailsMapping){
      let count = 0
      for (const areaKey in areaDetailsMapping) {
        if(areaDetailsMapping[areaKey].walls){
          count++
        }
      }
      setCompleteCount(count)
      applicationConfig.setState("customizer",{areaDetailsMapping:areaDetailsMapping})
      pulsateNextArea(areaDetailsMapping,setCurrAreaFloorplan)
    }
    setTimeout(() => {
      hideComponentLoader("saveWallsLoader")
    }, 2000);
  },[areaDetailsMapping])

 

  useEffect(()=>{
    if(customizerConfig){
      highlightSelectedWalls()
      applicationConfig.setFunctionRef("customizer",{setCurrAreaFloorplan:setCurrAreaFloorplan})
      applicationConfig.setFunctionRef("customizer",{setAreaDetailsMapping:setAreaDetailsMapping})
      applicationConfig.setFunctionRef("customizer",{setIsWallSelection:setIsWallSelection})
      applicationConfig.setFunctionRef("customizer",{setShowRenderModal:setShowRenderModal})
      setAreasList(projectConfiguration.areasList.filter(area=>area.area_name!=="Other"))
      updateAreaDetailsMapping()
      
      applicationConfig.setFunctionRef("floorplan",{updateWallHandle:updateWallHandle})
    }
    
  },[customizerConfig])

 
  useEffect(()=>{
    if(currselectedArea){
      setCurrAreaName(currselectedArea)
      addPulseAnimation(currselectedArea)
      enableSelectWalls(currselectedArea)
      // setCurrAreaWalls(projectConfiguration.projectConfiguration[currselectedArea].walls)
    }
  },[currselectedArea])

  useEffect(()=>{
    if(areasList.length){
      setCurrAreaFloorplan(areasList[0].area_name)
      updateAllAreaAnchorsPosition()
    }
  },[areasList])

 

  function updateAreaDetailsMapping() {
    var anchorsMapping = {}
      let configuration = projectConfiguration.projectConfiguration
      for (const areaKey in configuration) {
        anchorsMapping[areaKey] = {
          anchor:false,
          walls:false
        }
      }
      //Add saved area anchors
      setTimeout(() => {
        for (const areaKey in configuration) {
          let area = configuration[areaKey]
          if(area.walls.length){
            addAnchor(areaKey,area.anchor)
            anchorsMapping[areaKey].walls = true
          }
        }
        setAreaDetailsMapping(anchorsMapping)
        showAllAreaAnchors()
        highlightSelectedWalls()
      }, 1500);
  }
  
  

 

 
  function ConfirmationModalFooter() {
    return(
      <div className="">
      <button className="op-btn op-btn-light" onClick={() => setShowRenderModal(false)}>Close</button>
      </div>
    )
  }

  async function addAnchorHandle(areaName:string) {
    if(isUpdateMode){
      let configuration = projectConfiguration.projectConfiguration
      let area = configuration[areaName]
      removeAreaAnchor(areaName)
      undo.add("update",{areaName:areaName,walls:area.walls,position:area.anchor})
    }else{
      undo.add("add",{areaName:areaName})
    }
    showComponentLoader("saveWallsLoader")
    updateLoaderProgress("saveWallsLoader",1,1,"Saving...")
    await waitFor(1000)
    confirmWalls(areaName)
    hideComponentLoader("saveWallsLoader")
    setIsUpdateMode(false)
  }

  function goToNextArea() {
    removeAreaHighlight()
    let nextArea = getNextArea(areasList,currselectedArea,"")
    const areaName = nextArea.area_name
    // let walls = projectConfiguration.projectConfiguration[areaName].walls
    // if(!walls.length){
    //   showToast("Please select walls",2000,"error")
    //   return
    // }
    // pulsateNextArea(areaDetailsMapping,setCurrAreaFloorplan)
    // $(`#areaDetailsWrapper${nextArea.area_name}`).addClass("pulse-box")
    setCurrAreaFloorplan(areaName)
    updateWallHandle(areaName)
    // showWallSelectionHint()
  }

  function gotoPrevArea() {
    removeAreaHighlight()
    let nextArea = getPrevArea(areasList,currselectedArea,"")
    const areaName = nextArea.area_name
  
    setCurrAreaFloorplan(areaName)
    updateWallHandle(areaName)
    // showWallSelectionHint()
  }

  function updateWallHandle(areaName:string) {
    setIsUpdateMode(true)
    showWallSelectionHint()
    enableSelectWalls(areaName)
    setTimeout(() => {
      highlightSelectedAreaWalls(areaName)
    }, 600);
  }
 
 
  return (
    <>

    {isAllAreasCompleted(areaDetailsMapping)?
      <EditFloorplan
        areaDetailsMapping={areaDetailsMapping}
        isFinalizeMode={props.isFinalizeMode}
        areasList={areasList}
        updateWallHandle={updateWallHandle}
        completeCount={completeCount}
        addAnchorHandle={addAnchorHandle}
        setIsUpdateMode={setIsUpdateMode}
        isUpdateMode={isUpdateMode}
      />
    :
    <>

    

    <ProcessHeader
     prevButtonAction={gotoPrevArea}
     nextButtonAction={()=>addAnchorHandle(currselectedArea)}
     prevButtonLabel={"Prev area"}
     nextButtonLabel={"Save & Next"}
     heading={"Selecting walls for -"}
     HighlightComponent={()=>(
      <div className="pulse-anchor">
        <Button2
          configObj={applicationConfig}
          action={()=>{}}
          label={currselectedArea}
          fontAwesomeIcon={"fas fa-map-marker-alt color-danger"}
          iconPos="left"
          classNames={"bg-color-light uppercase margin0 heading2 button button-small"}
        />
      </div>
     )}
    />


    <div className="bottom-middle zindex100">
        <div className="font-small center-aligned-column marginRight5">
          <div className="display-flex-row ion-padding no-wrap"><span className="heading4 marginleft5 color-black">Note: Click on the wall to select</span></div>
        </div>
    </div>
     
    <SelectWallsHint
      currSelectedArea={currselectedArea}
    />
    
    </>
    
    }
      

        <Loader
          id="saveWallsLoader"
          className="fade-background-loader --is-active"
          loaderType="dotsText"
          data={{message:"Please wait..."}}
        />
    

    <div className="top-middle user-guide-box --is-active display-none">
      <div className="display-flex-row no-wrap background-white glow-effect">
        <div className="header-heading center-aligned-column marginRight5">
          {props.isFinalizeMode?"Review Anchors position":guideBoxData.guideMessage}
        </div>
      </div>
    </div>


    <div onClick={()=>{hideWallSelectionHint()}} className="center-aligned-column wall-selection-hint " >
      <div className="padding5">
        
        <div className="display-">
          <div  className="padding5 center-aligned-column width-auto pointer" style={{width:"fit-content"}}>
            <div className="marginleft5 heading1 uppercase text-center color-black">
              {hintText}
            </div>
          </div>
        </div>
      </div>
    </div>

   


    <Modal
      isOpen = {showRenderModal}
      Content = {()=>(
        <DebugOptions
          isOpenDebugOptions={showRenderModal}
          setIsOpenDebugOptions={setShowRenderModal}
        />
      )}
      onDismiss = {()=>setShowRenderModal(false)}
      heading="Render options"
      classNames="medium-x-modal"
      id=""
      FooterContent={ConfirmationModalFooter}
    />
 </>
  );
};

export default AreaDetailsInterface;
 