import React, { useState } from "react";
import { getArrayOfDistinctValues, getObjectByParameter } from "../../../../../../utils/methods";
import { MaterialAndPart, SofaConfiguration } from "../../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import Select from "../../../../../common/ui-components/input/Select";
import MaterialImage from "../../../../../common/ui-components/elements/MaterialImage";
 

const Options: React.FC<{
  SofaParts:any
  materailAndParts:MaterialAndPart
  confirmHandle:(values:any)=>void
  setSelectedOptionsIds:(values:any)=>void
  selectedOptionIds:any
}> = (props) => {



  const[isAllSelected,setIsAllSelected] = useState(false)

  function toggleSelection(currPart:any) {
    const id = currPart.id
    if(currPart.partName === "All"){
      props.setSelectedOptionsIds(getArrayOfDistinctValues(props.SofaParts,"id"))
      return
    }
    props.setSelectedOptionsIds(prevValue=>{
      if(prevValue.includes(id)){
        return prevValue.filter(currValue => currValue !== id)
      }else{
        return [...prevValue,id]
      }
    })

    if(props.materailAndParts.partNames.includes(currPart.partName)){
      props.materailAndParts.partNames = props.materailAndParts.partNames.filter(partName => partName !== currPart.partName)
    }else{
      props.materailAndParts.addPart(currPart.partName)
    }
  }

  function confirmHandle() {
    props.confirmHandle(props.selectedOptionIds)
  }

  function Footer(props:any) {
    return(
      <div className="display-flex-row bottom-middle no-wrap padding10">
          <Button2
              configObj={{id:""}}
              action={confirmHandle}
              label={`Confirm`}
              fontAwesomeIcon={"fas fa-arrow-right color-primary"}
              iconPos=""
              classNames={"no-shadow button-large button-fixed-width text-center pc-bg-color-primary"}
          />
      </div>
    )
  }

  function allSelectHandle() {
    // if(isAllSelected){
    //   props.setSelectedOptionsIds([])
    //   props.materailAndParts.partNames = []
    // }else{
    //   props.setSelectedOptionsIds(getArrayOfDistinctValues(props.SofaParts,"id"))
    //   let array:any = getArrayOfDistinctValues(props.SofaParts,"partName")
    //   props.materailAndParts.partNames = array
    // }

    props.materailAndParts.partNames = []
    let array:any = getArrayOfDistinctValues(props.SofaParts,"partName")
    props.materailAndParts.partNames = array
    props.setSelectedOptionsIds(getArrayOfDistinctValues(props.SofaParts,"id"))

    setIsAllSelected(!isAllSelected)
  }

  function isAllSelectedForMaterial() {
    return 
  }
 

  return (
    <div className="product-customizer-wrapper display-flex-row full-height-width no-wrap">

      <div className="part-codes-list full-width">
          {/* {props.SofaParts.map((currPart:any,index:number)=>{
            return(
              <div key={index} className={`position-relative`}>
                <div className="pointer-none midde full-width">
                  <div className="heading3 font- padding5 pc-color-primary">{currPart.partName} ({currPart.partCode})</div>
                  <div className="heading4 font-md-small pc-color-primary text text-center">({currPart.partCode})</div>
                </div>
              </div>
            )
          })} */}

      <table className="table store-quotation-table material-map-table full-width">
        <thead>
          <th>Material</th>
          <th>Material Details</th>
          <th>Part Selection</th>
        </thead>
        <tbody>
        <tr>
          <td className="material-td" style={{maxWidth:"5rem"}}><MaterialImage material={props.materailAndParts?.material}/></td>
          <td>
            <div className="display-flex-column">
              <span className="font-md-small">Material Code: {props.materailAndParts?.material?.materialCode}</span>
              <span className="font-md-small marginTop5">Material Type: {props.materailAndParts?.material?.materialType}</span>
              <span className="font-md-small marginTop5">Company Name: {props.materailAndParts?.material?.companyName}</span>
              <span className="font-md-small marginTop5">Collection Name{props.materailAndParts?.material?.collectionName}</span>
            </div>
          </td>
          <td>
              <div onClick={()=>allSelectHandle()} className="display-flex-row padding5 border marginTop5 no-wrap white-card no-shadow">
                <span className="">
                  {props.materailAndParts.partNames.length === props.SofaParts.length?
                  <i className="far fa-check-circle store-color-primary select-icon font-medium"></i>
                  :
                  <i className="far fa-circle select-icon font-medium"></i>
                  }
                </span>
                <span className="heading3 center-aligned-column marginleft5 font-small">All Parts</span>
              </div>
              {props.SofaParts.map((currPart:any,index:number)=>{
                return(
                  <div onClick={()=>toggleSelection(currPart)} className="display-flex-row pointer padding5 border marginTop5 no-wrap white-card no-shadow" key={index}>
                    <span className="">
                        {props.materailAndParts.partNames.includes(currPart.partName)?
                          <i className="far fa-check-circle store-color-primary select-icon font-medium"></i>
                          :
                          <i className="far fa-circle select-icon font-medium"></i>
                        }
                    </span>
                    <span className="heading3 center-aligned-column marginleft5 font-small">{currPart.partName} ({currPart.partCode})</span>
                  </div>
                )
              })}
          </td>
        </tr>
            
        </tbody>
        </table>
      </div>
      </div>
  );
};

export default Options;
