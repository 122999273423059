import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { applicationConfig } from "../../../../utils/methods";
import { getTodayTimestampOnlyDate } from "../../../../utils/store/crm/calendar";

const SubscriptionAlert: React.FC<{
}> = (props) => {

  const ref = useRef(null)
  const[dayDiff,setDayDiff] = useState(getRemainingDays())
  const[subText,setsubText] = useState("")



  useEffect(()=>{
    if(dayDiff < 5){
      show()
      let text = `Your subscription will end in ${dayDiff} days. Kindly renew to avoid any disruption.`
      if(dayDiff === 0){
        text = `Your subscription will be ending today. Kindly renew to avoid any disruption.`
      }
      if(dayDiff < 0){
        text = `Your subscription has been ended. Kindly renew to avoid any disruption.`
      }
      setsubText(text)
    }
  },[dayDiff])


  function getRemainingDays() {
    // Timestamps in milliseconds
    const startDate = getTodayTimestampOnlyDate();
    const endDate = applicationConfig?.user?.subscriptionEndDate;

    if(startDate && endDate){
      // Create Date objects from timestamps
      const date1:any = new Date(startDate);
      const date2:any = new Date(endDate);

      // Calculate the difference in time (in milliseconds)
      const timeDifference:any = date2 - date1;

      // Convert time difference from milliseconds to days
      const millisecondsInADay = 1000 * 60 * 60 * 24;
      const dayDifference = timeDifference / millisecondsInADay;

      return Math.round(dayDifference); 
    }
    return 99
  }

  function hide() {
    $(ref.current).fadeOut() 
  }
  function show() {
    $(ref.current).fadeIn() 
  }

  return (
    <React.Fragment>
      <div ref={ref} className={`heading3 padding5 position-relative white-card no-border-radius full-width`} style={{background:"#d1ecf1",display:"none"}}>
        <div className="heading4  full-width text-center font-small" style={{color:dayDiff<0?"#0c5460":"#0c5460"}}>
          {subText}
        </div>
        <div className="right-middle marginRight10">
          <i className="fas fa-times icon color-black"  onClick={hide}></i>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubscriptionAlert;
