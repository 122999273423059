import $ from "jquery";
import React, { useState } from "react";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import { IonModal } from "@ionic/react";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ProductViewer from "../../productviewer/ProductViewer";
import Modal from "../../../../common/shared-components/Modals/Modal";
import { hideComponentLoader } from "../../../../../utils/UI_methods/global";
import { productViewerInit } from "../../../../../utils/customizein3d/productViewer/productViewer";
 
const WardrobeDoorAndHandles: React.FC<{
}> = (props) => {

  const[tabItems] = useState([
    {tabName:"Doors",label:"Doors",ionIcon:null},
    {tabName:"Handles",label:"Handles",ionIcon:null},
    ])


  const [currTab, setCurrTab] = useState<string>("Handles");
  const [currSelectedHandle, setCurrSelectedHandle] = useState<string>("Handles");
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);
  
  const handles = [
    {
      handle_name:"Handle1",
      imageSrc:"https://img.fruugo.com/product/5/14/282990145_max.jpg"
    },
    {
      handle_name:"Handle2",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtirPvhm-Np96ht5KYkjeK5ENw6HCmqNVxwcHalgZNtBBzr0TifiVrXJgCLXjz4tMB48g&usqp=CAU"
    },
    {
      handle_name:"Handle3",
      imageSrc:"https://image.made-in-china.com/202f0j00ZDHqTblBrdoc/Modern-96mm-Zinc-Alloy-Drawer-Square-Hardware-Wardrobe-Handles.webp"
    },
    {
      handle_name:"Handle4",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS09aRD3y1fhsCOpvmrSmvqS1y4u-BxpFwtuAkleuEjSL9Y3m81xjMfFuIojU8Iutp1bY&usqp=CAU"
    },
    {
      handle_name:"Handle5",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTuvQEpcM5zBF4dbUA48S8jN3wo_F_Ehx9wys_k7SoOVU4oP_lSkL_jasB9gJ3jxOseRA&usqp=CAU"
    },
    {
      handle_name:"Handle7",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTuvQEpcM5zBF4dbUA48S8jN3wo_F_Ehx9wys_k7SoOVU4oP_lSkL_jasB9gJ3jxOseRA&usqp=CAU"
    },
    {
      handle_name:"Handle8",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTuvQEpcM5zBF4dbUA48S8jN3wo_F_Ehx9wys_k7SoOVU4oP_lSkL_jasB9gJ3jxOseRA&usqp=CAU"
    },
    {
      handle_name:"Handle10",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTuvQEpcM5zBF4dbUA48S8jN3wo_F_Ehx9wys_k7SoOVU4oP_lSkL_jasB9gJ3jxOseRA&usqp=CAU"
    },
    {
      handle_name:"Handle12",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTuvQEpcM5zBF4dbUA48S8jN3wo_F_Ehx9wys_k7SoOVU4oP_lSkL_jasB9gJ3jxOseRA&usqp=CAU"
    },
    {
      handle_name:"Handle13",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTuvQEpcM5zBF4dbUA48S8jN3wo_F_Ehx9wys_k7SoOVU4oP_lSkL_jasB9gJ3jxOseRA&usqp=CAU"
    }

  ]

  function changeTabOption(tabName: string) {
    setCurrTab(tabName);
    updateTabValue(tabName)
  }
  function updateTabValue(val:string){
    $("#wardrobeDoorsAndHandlePanel").find(".tab-content").removeClass("--is-active")
    $("#"+val).addClass("--is-active")
  }

  function Handles(){
    return(
      <div className="full-width display-flex-row padding10">
        {handles.map((handle:any,index:number)=>{
          return(
            <div key={index}  className="position-relative" style={{width:"33%",padding:"5px",height:"7rem"}}>
              <div onClick={()=>{openWindow(handle.handle_name)}} id={`${handle.handle_name}_option`} className="white-card handle-card-option border pointer position-relative full-height-width shadow-light ">
                <div className="bottom-middle font-ex-small color-black zindex30 full-width text-center bg-color-white heading4">{handle.handle_name}</div>
                <img className="top-left padding0 full-height-width cover" src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Wardrobe/Handles/${handle.handle_name}.png`} alt="" />
              </div>

              <i onClick={()=>{
                setCurrSelectedHandle(handle.handle_name)
                setIsOpenDetails(true)
              }} className="fas fa-info-circle top-right pointer color-dark font-small padding10"></i>
            </div>
          )
        })}
      </div>
    )
  }

  

  function Options() {
    return(
        <>
          {tabItems.map((tab:any,index:number)=>{
            return(
              <div onClick={()=>setCurrTab(tab.tabName)} className={tab.tabName===currTab?"marginleft10 marginTop5 bg-color-warning color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"7px 15px",borderRadius:"10px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-md-small text-center'>{tab.label}</div>
              </div>
            )
          })}
        </>
    )
  }

  function openWindow(handle:string){
    STORE.wardrobe.currSelectedHandle = handle
    $("#handleConfirmWindow").fadeIn()
    setCurrSelectedHandle(handle)
    $("#confirmWindowHandleName").text(handle)
  }



  function HandleDetails(){
    return(
      <div className="full-height-width padding10">

        <div className="display-flex-row full-height-width no-wrap">
          <div className="width50 full-height">
            <img  className="padding0 full-height-width cover" src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Wardrobe/Handles/${currSelectedHandle}.png`} alt="" />
          </div>
          <div className="width50 padding10">
            <div className="heading1">{currSelectedHandle}</div>
            <div className="heading4 marginTop5">Chrome</div>
            <div className="heading4 marginTop5">3 Inch</div>
            <Button2
              configObj={{id:""}}
              action={()=>{productViewerHandle()}}
              label={"View in 3D"}
              fontAwesomeIcon={"fas fa-arrow-right color-primary"}
              iconPos=""
              classNames={"no-shadow bg-color-primary marginTop10"}
            />
          
          </div>

        </div>
      </div>
    )
  }


  function closeProductViewer() {
    setIsOpenViewProduct(false)
    hideComponentLoader("canvasLoader")
  }

  function productViewerHandle(){
    setIsOpenViewProduct(true)
    let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/models/WardrobeHandles/${currSelectedHandle}.glb`
    setTimeout(() => {
      productViewerInit(null,"productViewerWardrobeHandleView",url)
    }, 500);
  }
  
  return (
    <>
      <IonModal
        id=""
        cssClass="project-steps-modal"
        onDidDismiss={() => setIsOpenDetails(false)}
        isOpen={isOpenDetails}>
          <>
          <HandleDetails/>
          </>
        
      </IonModal>

      <Modal
          isOpen = {isOpenViewProduct}
          Content = {()=>(
            <ProductViewer
              id="productViewerWardrobeHandleView"
            />
          )}
          onDismiss = {closeProductViewer}
          heading={"Product viewer"}
          classNames="form-action-modal"
          id=""
          FooterContent={()=>(<></>)}
        />
      <div id="wardrobeDoorsAndHandlePanel" className="full-height-width overflow-y-scroll">
      {/* <div className="full-width pointer center-aligned-row marginTop10">
        <Options/>
      </div> */}
      {/* <div id="WardrobeDoors" className="tab-content padding10">
        <></>
      </div> */}
      <Handles/>
    </div>
    </>
   
  );
};

export default WardrobeDoorAndHandles;
