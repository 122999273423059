import React, { useEffect, useState } from 'react';
import { checkmark, checkmarkDone, eyeOffOutline, eyeOutline, time, timeOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import '../../../../../style/style.css';
import "../../../../../style/common/summary.css"
import { IonIcon } from '@ionic/react';
import { getMoodBoardConfigKey, getPriceFromConfiguration } from '../../../../../../utils/moodboard/helper';
import { getCurrentConfigurationFromLocalStorage } from '../../../../../../utils/methods';
import { setElementInnerContent } from '../../../../../../utils/UI_methods/global';

const SummaryMoodboard: React.FC<{
    configObj:any   
    areasList:any
}> = (props) => {

  const[areaViewConfigStatusMappning,setAreaViewConfigStatusMappning] = useState(null)

  const history = useHistory()

  var totalPrice = 0

  function getPrice(areaName:string) {
    
    let key = getMoodBoardConfigKey(props.configObj?.getMoodboardId(),areaName);
    let configuration = getCurrentConfigurationFromLocalStorage(key);
    let price = 0
    if(configuration){
      price = getPriceFromConfiguration(configuration,props.configObj)
    }
    return price
  }

  return (
     <div className="summary-container ion-padding white-card shadow-light">
    <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Area</th>
                        <th>Price</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.areasList.map((area:any,index:any)=>{
                        // let isSavedConfig = getAreaConfigStatus(areaViewConfigStatusMappning,area.area_name)
                        let isSavedConfig = false
                        let price = getPrice(area.area_name)
                        totalPrice += Number(price)
                        setTimeout(() => {
                          setElementInnerContent("₹ "+String(totalPrice)+".00","moodboardTotalPrice")
                        }, 200);
                        return (
                        <tr className="" key={index}>
                            <td>{index+1}</td>
                            <td>{area.area_name}</td>
                            <td>{price}.00 INR</td>
                            <td>{isSavedConfig?(
                              <div className="icon-text-container center-aligned-row">
                                <div className="icon"><IonIcon icon={checkmark} ios={checkmark} style={{color:"green"}}></IonIcon> </div>
                                <div className="text ion-padding-start"></div>
                              </div>
                            ):(
                              <div className="icon-text-container center-aligned-row">
                                <div className="icon"><IonIcon icon={timeOutline} ios={timeOutline} style={{color:"#FFC409"}}></IonIcon> </div>
                                <div className="ion-padding-start text"></div>
                              </div>
                            )}</td>
                        </tr>
                        )
                    })}
                  </tbody>
                </table>
     </div>
  );
};

export default SummaryMoodboard;
