import React, { useEffect, useState } from "react";
import { getFilteredArray, getObjectByParameter } from "../../../../../../../../utils/methods";
import { getFilteredLeadsWithOutcome, getFormatedDate, getLeadsTabItems, getOtherHeadingPrefix, getWeekHeadingPrefix, outcomesList } from "../../../../../../../../utils/store/crm/crm";
import { Customer } from "../../../../../../../../utils/store/storeConfiguration";
import LeadCard from "./LeadCard";
import { CRM } from "../../../../../../../../utils/store/crm/crmConfiguration";
import $ from "jquery"
import { getWindowHeight } from "../../../../../../../../utils/UI_methods/global";
import { getTodayTimestampOnlyDate, getTodayTimestampWithDayDifference } from "../../../../../../../../utils/store/crm/calendar";
import EditLead from "./EditLead";
import Modal2 from "../../../../../../../common/shared-components/Modals/Modal2";
 

const LeadsCardView: React.FC<{
  allLeads: Array<any>;
  setCurrLead:(lead:any)=>void
  refreshLeads:()=>void
  activeCustomer:any
  setActiveCustomer:(customer:Customer)=>void
}> = (props) => {


  const[additionalTab] = useState("Visited")
  const[tabsList,setTabList] = useState(getLeadsTabItems([]))
  const[isEditLeadOpen,setIsEditLeadOpen] = useState(false)
  const[currSelectedLead,setCurrSelectedLead] = useState(null)
  const[editLeadView,setEditLeadView] = useState("calendar")

  
  useEffect(()=>{
    setTimeout(() => {
      setCardViewHeight()
    }, 500);
  },[])

 

  function getTimestampList(currTab:string) {
    let timestampsList = []
    try {
      for (let i = 0; i < 7; i++) {
        let delta = +i
        if(currTab.includes("New") || currTab.includes("Visited")){
          delta = -i 
        }
        let date = getTodayTimestampWithDayDifference(delta)
        timestampsList.push(date)     
      }
      timestampsList.push("other") 
    } catch (error) {
      console.log(error)
    }
  
    return timestampsList
  }


  function getLeadsList(currTab:string,) {
    let leads = []
    if(currTab === "Visited"){
      let assignedLeads = getFilteredArray(leads,"sales_stage","Visited")
      leads = [...CRM.data?.unAssignedleadsList,...assignedLeads]
    }else if(currTab === "Placed order"){
      leads = getFilteredArray(props.allLeads,"sales_stage","Win")
    }else{
      let filtered = props.allLeads.filter(currLead => currLead.sales_stage !== "Win" && currLead.sales_stage !== "Visited")
      leads = getFilteredLeadsWithOutcome(filtered,currTab)
    }
    return leads
  }
 

  function setCardViewHeight() {
    const delta = 50
    let heights = $(".main-header-wrapper").height() +  $(".main-sub-header-wrapper").height() + $(".leads-header").height() + $(".leads-card-view-heading").height() + delta
    $(".leads-cards-view-container").height(getWindowHeight()-heights+"px")
    $(".vertical-tabs-wrapper").height(getWindowHeight()-heights+delta+"px")
  }

  function openLeadDetailsHandle(lead:any,view:string) {
    setCurrSelectedLead(lead)
    setIsEditLeadOpen(true)
    setEditLeadView(view)
  }

  return (
    <div className="vertical-tabs-wrapper full-width overflow-x-auto no-wrap" style={{overflowX:"auto",overflowY:"hidden"}}>
      <div className="tabs-container bg-color-light space-between full-height-width display-flex-row no-wrap hide-scrollbar" style={{width:"100%"}}>

        <Modal2
          isOpen = {isEditLeadOpen}
          Content = {()=>(
              <EditLead view={editLeadView} lead={currSelectedLead} refreshLeads={props.refreshLeads} setIsEditLeadOpen={setIsEditLeadOpen}/>
          )}
          onDismiss = {()=>setIsEditLeadOpen(false)}
          classNames="large-x-modal"
          id=""
        />

        {tabsList?.map((currTab:any,index:number)=>{
          const filteredLeads = getLeadsList(currTab.tabName)
          let allTimestamps = getTimestampList(currTab.tabName)

          return(
            <div className={`white-card no-shadow marginRight5 tab-container position-relative pointer tab`} key={index} style={{width:"calc(100%/6)",background:"#EBEBEE",height:"max-content",minWidth:"18rem"}}>
              <div className="display-flex-row space-between no-wrap leads-card-view-heading" style={{padding:"0.7rem 0.2rem"}}>
                <div className="center-aligned-column hide-in-mobile position-relative heading3 color-black  marginleft5">{currTab.label}
                </div>
                <span className="bg-color-dark heading4 marginRight5 white-card no-shadow border center-aligned-column padding0 font-ex-small color-white" style={{width:"24px",height:"24px"}}> {filteredLeads?.length || 0} </span>
              </div>

              <div className="leads-list-container padding5 leads-cards-view-container hide-scrollbar">
                {filteredLeads?.length?

                  <div className="container ">
                        {/* {filteredLeads?.map((lead:any,index:number)=>{
                        return(
                          <div className="full-width" key={index}>
                            <LeadCard
                              lead={lead}
                              index={index}
                              setCurrLead={props.setCurrLead}
                              refreshLeads={props.refreshLeads}
                              activeCustomer={props.activeCustomer}
                              setActiveCustomer={props.setActiveCustomer}
                            />
                            </div>
                          )
                        })} */}

                        {allTimestamps.map((currTimestamp:any,index:number)=>{
                                  // let activities = CRM.data.activitiesList.filter(currActivity => currActivity.start_date === currTimestamp && currActivity.status === 1)
                                  // let leadIdList = getArrayOfDistinctValues(activities,"lead_id")
                                  // let leadsListResult = props.filteredLeads.filter(currLead => leadIdList.includes(currLead.lead_id))
                                  let leadsListResult = filteredLeads.filter(currLead => {
                                    let startDate = getObjectByParameter(CRM.data.activitiesList,"activity_id",currLead.current_activity_id)?.start_date
                                    if(currTimestamp === "other"){
                                      return !allTimestamps.includes(startDate)
                                    }
                                    return String(startDate) === String(currTimestamp)
                                  })
                                  if(currTab.tabName.includes("Visited") || currTab.tabName.includes("Placed")){
                                    if(currTimestamp === "other"){
                                      leadsListResult = filteredLeads.filter(currLead => !allTimestamps.includes(currLead.date_of_creation))
                                    }else{
                                      leadsListResult = filteredLeads.filter(currLead => String(currLead.date_of_creation) === String(currTimestamp))
                                    }
                                  }
                                  return(
                                    <div className={`border marginBottom5 ${leadsListResult.length?"":" display-none"}`} key={index}>
                                      {currTimestamp === "other"?
                                        <div className="heading3 date-header font-small padding10 color-dark">{`${getOtherHeadingPrefix(currTab.tabName)} other dates`}</div>
                                      :
                                        <div className="heading3 date-header font-small padding10 color-dark">{getWeekHeadingPrefix(currTab.tabName) + " " +getFormatedDate(currTimestamp)} {currTimestamp === getTodayTimestampOnlyDate()?" (Today)":""}</div>
                                      }
                                      {leadsListResult?.map((lead:any,index:number)=>{
                                      return(
                                        <div className="full-width padding5" key={index}>
                                          <LeadCard
                                            lead={lead}
                                            index={index}
                                            setCurrLead={props.setCurrLead}
                                            refreshLeads={props.refreshLeads}
                                            activeCustomer={props.activeCustomer}
                                            setActiveCustomer={props.setActiveCustomer}
                                            openLeadDetailsHandle={openLeadDetailsHandle}
                                          />
                                          </div>
                                        )
                                      })}
                                    </div>
                                  )
                                })}
                    
                  </div>
                :
                <div className="" >
                  <div className="heading4 font-md-small white-card no-shadow ion-margin-top" style={{border:"1px dashed var(--op-color-dark)",padding:"15px 15px"}}>Leads not available</div>
                </div>
                }
              </div>
            </div>
          )
        })}


        {/* <div className={`white-card no-shadow marginRight5 tab-container position-relative pointer tab`} style={{width:"calc(100%/6)",background:"#EBEBEE",height:"max-content"}}>
          <div className="display-flex-row space-between no-wrap">
            <div className="center-aligned-column hide-in-mobile position-relative heading4 font-normal color-black  marginleft5">Visited
            </div>
            <span className="bg-color-dark heading4 marginRight5 white-card no-shadow border center-aligned-column padding0 font-ex-small color-white" style={{width:"24px",height:"24px"}}>{CRM.data?.unAssignedleadsList?.length || 0}</span>
          </div>

          <div className="leads-list-container padding5 leads-cards-view-container hide-scrollbar">
            {CRM.data.unAssignedleadsList?.length?
              <div className="container">
                    {CRM.data.unAssignedleadsList?.map((lead:any,index:number)=>{
                    return(
                      <div className="full-width" key={index}>
                        <LeadCard
                          lead={lead}
                          index={index}
                          setCurrLead={props.setCurrLead}
                          refreshLeads={props.refreshLeads}
                          activeCustomer={props.activeCustomer}
                          setActiveCustomer={props.setActiveCustomer}
                        />
                        </div>
                      )
                    })}
                
              </div>
            :
            <div className="" >
              <div className="heading4 font-md-small white-card no-shadow ion-margin-top" style={{border:"1px dashed var(--op-color-dark)",padding:"15px 15px"}}>Leads not available</div>
            </div>
            }
          </div>
        </div> */}

      </div>

      {/* <div className="content-container position-relative full-height overflow-y-scroll hide-scrollbar bg-color-white" style={{width:"-webkit-fill-available"}}>
        <props.Content/>
      </div> */}


  </div>
  );
};

export default LeadsCardView;
