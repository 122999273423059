import React, { useEffect } from "react";
import { fullScreenToggleConfigurator } from "../../../../../utils/customizein3d/modules/UI_methods";
import { sceneBackgrounds } from "../../../../../utils/customizein3d/modules/customizein3d";
import Button2 from "../../../ui-components/buttons/Button2";
import { downloadCanvasAsPng, shareCanvasAsPng } from "../../../../../utils/customizein3d/modules/sceneBackground";

const SceneBackgroundCanvasUI: React.FC<{
}> = (props) => {

 

  useEffect(()=>{
  },[])

 


  return (
    <React.Fragment>

<div className="top-right">
          <div className="show-on-background display-none">
            <div className="display-flex-row no-wrap">
              <Button2
                  configObj={{id:""}}
                  action={downloadCanvasAsPng}
                  label={""}
                  fontAwesomeIcon={"fas fa-download color-primary"}
                  iconPos="right"
                  classNames={"shadow-medium no-border button-small"}
              />
              <Button2
                  configObj={{id:""}}
                  action={shareCanvasAsPng}
                  label={"Share"}
                  fontAwesomeIcon={"fas fa-share color-success "}
                  iconPos="right"
                  classNames={"shadow-medium no-border button-small"}
              />
              <Button2
                configObj={{id:""}}
                action={fullScreenToggleConfigurator}
                label={""}
                fontAwesomeIcon={"fas fa-expand color-primary redo-icon"}
                iconPos="left"
                classNames={"shadow-medium no-border button-small"}
              />
            </div>
              
            </div>
        </div>

        <div className="top-left">
          <div className="show-on-background display-none">
              <Button2
                  configObj={{id:""}}
                  action={()=>{sceneBackgrounds.disable()}}
                  label={"Exit mode"}
                  fontAwesomeIcon={"fas fa-chevron-left color-danger"}
                  iconPos="left"
                  classNames={"shadow-medium bg-color-black no-border button-small"}
              />
            </div>
        </div>

        {/* <div className="bottom-right">
          <div className="show-on-background padding10 display-none">
              <Button2
                  configObj={{id:""}}
                  action={()=>{sceneBackgrounds.enable()}}
                  label={"Backgrounds"}
                  fontAwesomeIcon={"fas fa-camera color-danger"}
                  iconPos="left"
                  classNames={"shadow-medium no-border button-small"}
              />
            </div>
        </div> */}
    </React.Fragment>
  );
};

export default SceneBackgroundCanvasUI;
