import $ from "jquery"
import { BoxGeometry, BufferGeometry, Color, Group, Line, Mesh, MeshBasicMaterial, OrthographicCamera, PerspectiveCamera, Quaternion, Scene, ShaderMaterial, Vector3, WebGLRenderer } from "three"
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial"
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import "../../components/style/customizein3d/dimensions.css"
import { getWindowWidth, hideComponentLoader, showComponentLoader, showGlobalToast, showToast } from "../UI_methods/global"
import { applicationConfig, waitFor } from "../methods"
import { detachModule } from "./common"
import { convert3DPointsToCentimeters, convert3DPointsToInches, convert3dPointsToFeet } from "./floorplanner/helper"
import { getObjectDimensionPosition } from "./modules/helper"
import { Line2 } from "three/examples/jsm/lines/Line2"
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry"
import { STORE } from "../store/storeConfiguration"

const boxThickness = 0.03
const boxMaterial = new MeshBasicMaterial({ color: 0x000000 })
const vector = new Vector3()
const center = new Vector3()
const normal = new Vector3()
const v = new Vector3();
// Create a custom shader material for the dashed line
const lineMaterial = new ShaderMaterial({
  uniforms: {
    color: { value: new Color(0x000000) }, // Replace with your desired line color
    dashSize: { value: 0.03 },
    gapSize: { value: 0.03 },
    linewidth: { value: 5 },
  },
  vertexShader: `
    attribute float lineDistance;
    varying float vLineDistance;

    void main() {
      vLineDistance = lineDistance;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform vec3 color;
    uniform float dashSize;
    uniform float gapSize;
    varying float vLineDistance;

    void main() {
      float modLineDistance = mod(vLineDistance, (dashSize + gapSize));
      if (modLineDistance > dashSize) {
        discard;
      }
      gl_FragColor = vec4(color, 1.0);
    }
  `,
});

export class Dimensions{
    dimensionsGroup = new Group()
    material = new MeshBasicMaterial( { color: 0x000000 } )
    scale = 100
    fontSize = 6
    fontLoader = new FontLoader()
    isDimensionsVisible:boolean = false
    unit:string = (applicationConfig.clientName === "DashSquare") ? "cm" : "inch";
    currObject:Group = null

    // camera:any
    perspectiveCamera:any
    orthoCamera:any
    
    scene:Scene = null
    renderer:WebGLRenderer = null
    isSpacePlannerMode:boolean = false

    points:Array<Vector3> = []
    lines = []
    annotations:Array<Annotation> = []

    constructor(scene:Scene,renderer:any,perspectiveCamera:PerspectiveCamera,orthoCamera:OrthographicCamera,currSelectedObject:Group,isSpacePlannerMode:boolean,scale:number){
        this.scene = scene
        this.renderer = renderer
        this.perspectiveCamera = perspectiveCamera
        this.orthoCamera = orthoCamera
        this.scale = scale

        this.currObject = currSelectedObject

        this.fontSize = 0.2 * this.scale
        if(getWindowWidth() <=481 && this.isSpacePlannerMode){
          this.fontSize = this.fontSize * 2 * this.scale
        }

    }

    setCurrSelectedObject(object:Group){
      this.currObject = object
    }

    setIsSpacePlannerMode(val:boolean){
      this.isSpacePlannerMode = val
    }

    updateCurrObject(group:Group){
 
        this.currObject = group
    }

    updateUnit(unit:string){
      this.unit = unit
      if(this.isDimensionsVisible){
        this.hideDimensions()
        this.showDimensions()
      }else{
        this.hideDimensions()
      }
      
    }

    updateLabelsVisibility(){
      if(this.isDimensionsVisible){
        let camera = this.perspectiveCamera
        if(this.isSpacePlannerMode){
          $(".room-dimension-annotation").css("visibility","visible")
          return
        }
        for (const annotation of this.annotations) {
          const center = annotation.center
          const normal = annotation.normal
          const element = annotation.css2dObject.element
          if ( v.subVectors( camera.position,center ).dot( normal ) < -2 ) {
            $(element).css("visibility","hidden")
          }else{
            $(element).css("visibility","visible")
          }
        }
        // this.updateAnnotationsPosition()
      }
      
      
    }

    createDimensions(object = this.currObject) {
        let category = STORE.currProduct?.category_name
        if(!object){
            showToast("No object selected",2000,"error")
            return
        }
        let data = getObjectDimensionPosition(null,object)
        const posY = data.positions.min.y
        const delta = 0.01

        let point1 =  new Vector3( data.positions.min.x - delta, posY, data.positions.min.z - delta)
        let point2 = new Vector3( data.positions.max.x + delta, posY, data.positions.min.z - delta)
        let point3 = new Vector3( data.positions.max.x + delta, posY, data.positions.max.z + delta )
        let point4 = new Vector3( data.positions.min.x - delta, posY, data.positions.max.z + delta) 
        let point5 = new Vector3( data.positions.min.x - delta, posY, data.positions.min.z - delta )


        
        let op1 =  new Vector3( data.positions.min.x, posY, data.positions.min.z)
        let op2 = new Vector3( data.positions.max.x, posY, data.positions.min.z)
        let op3 = new Vector3( data.positions.max.x, posY, data.positions.max.z )
        let op4 = new Vector3( data.positions.min.x, posY, data.positions.max.z) 
        let op5 = new Vector3( data.positions.min.x, posY, data.positions.min.z )


        this.points = [point1,point2,point3,point4,point5]

        if(category === "Wardrobe" || category === "Dresser" || category === "Cot"){
          let point6 = new Vector3( data.positions.min.x - delta, data.positions.max.y, data.positions.min.z - delta )
          this.points = [point1,point2,point3,point4,point5,point6]
        }

        // this.createBoxFromPoints([point1,point2,point3,point4,point5])
        // this.createLineFromPoints([point1,point2,point3,point4,point5])
        this.createWebGlLinesFromPoint(this.points)
        // this.updateSvgLinesNormal()
        // this.updateSvgLinesPosition()
        
        this.annotations.push(new Annotation(op1,op2,this.dimensionsGroup,object,this.unit,"x"))
        this.annotations.push(new Annotation(op2,op3,this.dimensionsGroup,object,this.unit,"z"))
        this.annotations.push(new Annotation(op3,op4,this.dimensionsGroup,object,this.unit,"x"))
        this.annotations.push(new Annotation(op4,op5,this.dimensionsGroup,object,this.unit,"z"))

        if(category === "Wardrobe" || category === "Dresser" || category === "Cot"){
          let point6 = new Vector3( data.positions.min.x - delta, data.positions.max.y, data.positions.min.z - delta )
          this.annotations.push(new Annotation(point5,point6,this.dimensionsGroup,object,this.unit,"y"))
        }

        
        this.scene.add(this.dimensionsGroup)
      }


      async convertDimensions(unit:string) {
        showComponentLoader("changeFinishLoader")
        this.unit = unit
        this.removeChidlren()

        await waitFor(1000)
        this.createDimensions()
        hideComponentLoader("changeFinishLoader")
      }

      
      removeChidlren(){
        this.lines.forEach(currObject=>{
            detachModule(currObject)
        })
        this.annotations.forEach(currObject=>{
            const element = currObject.css2dObject.element
            $(element).remove()
            currObject.css2dObject.parent?.remove(currObject.css2dObject)
            // detachModule(currObject.css2dObject)
        })
      }
    

      toggleDimension() {
        if(!this.isDimensionsVisible){
          this.showDimensions()
          $("#dimensionToggle").find(".state-text").text("on")
          showGlobalToast("Showing dimensions",2000)
          return false
        }
        this.hideDimensions()
        $("#dimensionToggle").find(".state-text").text("off")
        return true
      }
    
      showDimensions() {
        this.annotations = []
        $("#dimensionToggle").addClass("--is-active")
        $("#dimLines").fadeIn()
        $(".dim-options").toggleClass("--is-active")
        $(".unit-selector-wrapper").removeClass("display-none")
        this.isDimensionsVisible = true
        this.createDimensions()
      }

      hideDimensions() {
        if(this.isDimensionsVisible){
          this.isDimensionsVisible = false
          $(".dimension-icon").removeClass("--is-active")
          $("#dimLines").fadeOut()
          $(".dim-options").removeClass("--is-active")
          $("#dimensionToggle").removeClass("--is-active")
          this.removeChidlren()
          $(".unit-selector-wrapper").addClass("display-none")
          $(".room-dimension-annotation").remove()
          this.scene.remove(this.dimensionsGroup)
          this.lines = []
          return false
        }
      
      }


      updateDimensions(){
        this.hideDimensions()
        setTimeout(() => {
          this.showDimensions()
        }, 200);
      }

      createLineFromPoints(points:Array<Vector3>){
        const lineGeometryForTop = new BufferGeometry().setFromPoints( points );
        const line = new Line( lineGeometryForTop, lineMaterial );
        line.computeLineDistances();
        this.lines.push(line);
        this.dimensionsGroup.add(line);
      }


       createWebGlLinesFromPoint(points:Array<Vector3>){
        const geometry = new LineGeometry();
        let positions = []
        for (const point of points) {
          positions.push( point.x, point.y, point.z );
        }
        // const geometry = new BufferGeometry().setFromPoints( points );
				geometry.setPositions(positions);
				const matLine = new LineMaterial( {
					color: 0xff0000,
					linewidth: 2,  
					vertexColors: true,
					dashed: false,
					// alphaToCoverage: true,
          // dashScale: 5,
					// gapSize: 1
				} );
        // matLine.envMap = generatedEnvironmentMap.texture
        const canvas = this.renderer?.domElement
        matLine.resolution.set(canvas.width,canvas.height);
        // const geometry = new BufferGeometry().setFromPoints( points );
				const line = new Line2( geometry, matLine );
				line.computeLineDistances();
				line.scale.set( 1, 1, 1 );
        this.lines.push(line);
				this.scene.add( line );
      }

      createBoxFromPoints(points:Array<Vector3>) {

        for (let i = 0; i < points.length - 1; i++) {
            const startPoint = points[i];
            const endPoint = points[i + 1];
            const distance = startPoint.distanceTo(endPoint);
          
            const boxGeometry = new BoxGeometry(distance, boxThickness, boxThickness);
            const box = new Mesh(boxGeometry, boxMaterial);
          
            box.position.copy(startPoint.clone().add(endPoint).multiplyScalar(0.5));
          
            // Calculate the direction vector from the start point to the end point
            const direction = endPoint.clone().sub(startPoint).normalize();
            // Calculate the quaternion to represent the rotation from (1, 0, 0) to the direction vector
            const quaternion = new Quaternion().setFromUnitVectors(new Vector3(1, 0, 0), direction);
            box.quaternion.copy(quaternion);

            this.lines.push(box);
          }
    
    
        for (const box of this.lines) {
            this.dimensionsGroup.add(box);
        }
    
    }



    updateSvgLinesPosition() {
      return
      if(this.isDimensionsVisible){
        const dimLines = document.querySelectorAll('line');
        for (let i = 0; i < this.points.length - 1; i++) {
            const startPoint = this.points[i];
            const endPoint = this.points[i + 1];
  
            // let pos1 = this.queryHotspot(startPoint)
            // let pos2 = this.queryHotspot(endPoint)
  
            // let svg = dimLines[i]
            // this.drawLine(svg,pos1,pos2)
            // this.updateSvgLinesVisibility(svg)
        }
      }
      this.updateLabelsVisibility()
  }

  updateSvgLinesNormal(){
    const dimLines = document.querySelectorAll('line');
    for (let i = 0; i < this.points.length - 1; i++) {
        const startPoint = this.points[i];
        const endPoint = this.points[i + 1];

        const center = new Vector3()
        center.addVectors(startPoint, endPoint).multiplyScalar(0.5);
        const normal = center.clone().sub(getObjectDimensionPosition(null,this.currObject).center).normalize()
        const svg = dimLines[i]
        svg.setAttribute("data-normal-x",String(normal.x))
        svg.setAttribute("data-normal-y",String(normal.y))
        svg.setAttribute("data-normal-z",String(normal.z))

        svg.setAttribute("data-center-x",String(center.x))
        svg.setAttribute("data-center-y",String(center.y))
        svg.setAttribute("data-center-z",String(center.z))

      }
  }
  


  updateSvgLinesVisibility(svg:any){
    let camera = this.perspectiveCamera
    if(this.isSpacePlannerMode){
      camera = this.orthoCamera
    }
    normal.x = Number(svg.getAttribute("data-normal-x")) 
    normal.y = Number(svg.getAttribute("data-normal-y")) 
    normal.z = Number(svg.getAttribute("data-normal-z")) 

    center.x = Number(svg.getAttribute("data-center-x")) 
    center.y = Number(svg.getAttribute("data-center-y")) 
    center.z = Number(svg.getAttribute("data-center-z")) 


    if ( v.subVectors( camera.position,center ).dot( normal ) < 0) {
      if(this.isSpacePlannerMode){
        $(svg).css("opacity","1")
      }else{
        $(svg).css("opacity","0.07")
        // $(svg).fadeOut(0)
      }
    }else{
        $(svg).css("opacity","1")
        // $(svg).fadeIn(0)
    }
  }


    drawLine(svgLine, dotHotspot1, dotHotspot2) {
      if (dotHotspot1 && dotHotspot2) {
        svgLine.setAttribute('x1', dotHotspot1.x);
        svgLine.setAttribute('y1', dotHotspot1.y);
        svgLine.setAttribute('x2', dotHotspot2.x);
        svgLine.setAttribute('y2', dotHotspot2.y);
        svgLine.classList.remove('hide');
  
        // if (dimensionHotspot && !dimensionHotspot.facingCamera) {
        //   svgLine.classList.add('hide');
        // }
        // else {
        //   svgLine.classList.remove('hide');
        // }
      }
    }

    // updateAnnotationsPosition(){
    //   for (const annotation of this.annotations) {
    //     annotation.center.addVectors(annotation.point1,annotation.point2).multiplyScalar(0.5);
    //     annotation.css2dObject.position.copy(annotation.center);
    //   }
    // }
     
  }

 

class Annotation{
  distance:number = 0
  center:Vector3 = new Vector3()
  element:any = null
  mesh:Mesh = null
  normal:Vector3
  css2dObject:CSS2DObject
  point1:Vector3
  point2:Vector3

  dimensionInCm:number = 0
  axis:string = ""

  constructor(point1:Vector3,point2:Vector3,scene:any,object:any,unit:string,axis:string = ""){
      this.point1 = point1
      this.point2 = point2
      this.distance = point1.distanceTo(point2);
      this.center.addVectors(point1, point2).multiplyScalar(0.5);

      let dim:any = unit === "feet" ? convert3dPointsToFeet(point1,point2): 
      unit === "inch" ? convert3DPointsToInches(point1,point2) : 
      unit === "cm" ? convert3DPointsToCentimeters(point1,point2) : 
      convert3dPointsToFeet(point1,point2)

      let finalDimString = unit === "feet" ? `${dim.feet}' ${dim.inch}"` : 
      unit === "inch" ? String(Math.floor(dim)) + '"' : 
      unit === "cm" ? String(Math.floor(dim)) + " cm" :
      ""

      this.dimensionInCm = convert3DPointsToCentimeters(point1,point2)
      this.axis = axis

      this.normal = this.center.clone().sub(getObjectDimensionPosition(null,object).center).normalize()

      
      this.element = this.createAnnotationElement(String(Number(new Date())))
      this.element.textContent = finalDimString;
      this.css2dObject = new CSS2DObject( this.element );
      this.css2dObject.position.copy(this.center);
      scene.add( this.css2dObject );
  }

  createAnnotationElementJquery(id:string) {
 
      let parentContainer = document.getElementById("area3DSceneWrapper")
  
      let element = $("<div></div>").addClass("white-card room-dimension-annotation heading3 font-normal top-middle").
      attr("id",`areaAnchor${id}`)
      .appendTo($(parentContainer))
  
      return element
  }

  createAnnotationElement(id:string) {
 
    const element = document.createElement( 'div' );
    element.className = 'white-card room-dimension-annotation heading3 font-normal';

    return element
  }
}
