import { checkmark, listOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { touchDeviceActions } from "../../../../../../../utils/store/crm/crm";
import DropDown from "../../../../../../common/ui-components/filters/DropDown2";
import ControlsTab from "../../../../../../common/ui-components/tabs/ControlsTab";
import ScheduleAppointmentForm from "./ScheduleAppointmentForm";
import TicketCard from "./TicketCard";
import Modal2 from "../../../../../../common/shared-components/Modals/Modal";
import EditLead from "../leads/leadslist/EditLead";
import TicketDetails from "./TicketDetails";
import CreateTicket from "../tickers/createticket/CreateTicket";
import { showGlobalToast } from "../../../../../../../utils/UI_methods/global";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
import { Customer } from "../../../../../../../utils/store/storeConfiguration";
 

 
const TicketListContainer: React.FC<{
  allTickets:any
  currSelectedTime:string
  refreshList:()=>void
}> = (props) => {




  const [currTab, setCurrTab] = useState<string>("NewTickets");
  const[currTicket,setCurrTicket] = useState(null)

  const[currActivity,setCurrActivity] = useState("")
  const[isOpenStartActivity,setIsOpenStartActivity] = useState(false)

  const[isEditLeadOpen,setIsEditLeadOpen] = useState(false)
  const[editLeadView,setEditLeadView] = useState("calendar")
  const[currSelectedTicket,setCurrSelectedTicket] = useState(null)
  const[filteredTickets,setFilteredTickets] = useState([])

  const[customer,setCustomer] = useState(null)
  const[isOpenConvertTicket,setIsOpenConvertTicket] = useState(null)


  var tabItems = [
    {tabName:"NewTickets",
    label:"All Tickets",
    ionIcon:listOutline,
    count:props.allTickets.length
    },
    // {tabName:"ConvertedTickets",
    // label:"Converted Tickets",
    // ionIcon:listOutline,
    // count:props.allTickets.filter(currTicket => currTicket.is_converted_to_ticket === 1).length
    // },
  ]

  useEffect(()=>{
    filterTickets(currTab)
  },[currTab,props.allTickets])


  function filterTickets(currTab:string) {
    let result = []
    if(currTab === "NewTickets"){
      result = props.allTickets.filter(currTicket => currTicket.is_converted_to_ticket === 0)
    }
    // if(currTab === "ConvertedTickets"){
    //   result = props.allTickets.filter(currTicket => currTicket.is_converted_to_ticket === 1)
    // }
    setFilteredTickets(result)
  }

 

  function updateTabHandle(tabName: string) {
    setCurrTab(tabName)
  }


  function openCalendarHandle(ticket:any) {
      setCurrTicket(ticket)
      setIsOpenStartActivity(true)
  }

 

  function startActivityHandle(activityName,ticket:any) {
    setCurrTicket(ticket)
    touchDeviceActions(activityName,ticket.mobile)
    setCurrActivity(activityName)
  }

  function openLeadDetailsHandle(lead:any,view:string) {
    setCurrSelectedTicket(lead)
    setIsEditLeadOpen(true)
    setEditLeadView(view)
  }

  function openConvertTicketHandle(ticket:any) {
    const mobile = ticket.mobile
    const cusDetails = CRM.data.customersList.find(currCustomer => currCustomer.mobile.includes(mobile) || mobile.includes(currCustomer.mobile))
    if(!cusDetails){
      showGlobalToast("Customer not found",2000,"error")
      return
    }
    let cus = new Customer(cusDetails)
    setCustomer(cus)
    setCurrSelectedTicket(ticket)
    setIsOpenConvertTicket(true)
  }

  function updateTicketStatus() {
    const details = {
      isConvertedToTicket:1
    }
    CRM.crmApi.customerCreatedTicket.update(currSelectedTicket?.id,details).then(async (data:any)=>{
      console.log(data)
      if(!data.data.error){
          props.refreshList()
      }else{
        showGlobalToast("Internal server error",2000,"error")
      }
      setIsOpenConvertTicket(false)
    })
  }

  return (
    <div className="full-height-width display-flex-column no-wrap">

      <CreateTicket ticket={currSelectedTicket} createTicketCallback={updateTicketStatus} isOpen={isOpenConvertTicket} setIsOpen={setIsOpenConvertTicket} customer={customer}/>

      <div className="" style={{maxWidth:"27rem",width:"100%"}}>
        <ControlsTab
          configObj={{}}
          tabItems={tabItems}
          currSelectedValue={currTab}
          action={updateTabHandle}
          theme="black"
        />
      </div>

      <Modal2
          isOpen = {isEditLeadOpen}
          Content = {()=>(
              <TicketDetails ticket={currSelectedTicket}/>
          )}
          onDismiss = {()=>setIsEditLeadOpen(false)}
          FooterContent={()=>(<></>)}
          heading="Ticket details"
          classNames="medium-y-modal"
          id=""
      />


      <div className="leads-list-container display-flex-column no-wrap full-height-width position-relative">
          {/* <div className="header-container display-flex-row space-between">
            <div className="date-filter padding5">
              <DropDown
                classNames={""}
                values={["Last created"]}
                currSelectedValue={"Last created"}
                action={()=>{}}
                label="Sort by"
              />
            </div>
          </div> */}

        {props.allTickets?.length?
        
          <div className="container overflow-y-scroll"  style={{paddingBottom:"180px"}}>
            <div className="border marginBottom5">
              {/* <div className="heading3 font-md-small bg-color-ternary padding10"><span className="marginRight5 display-none"><i className="fas fa-check-circle color-primary"></i></span>Scheduled</div> */}
              {props.allTickets?.map((ticket:any,index:number)=>{
                return(
                  <div className="full-width" key={index}>
                    <TicketCard
                      ticket={ticket}
                      index={index}
                      openCalendarHandle={openCalendarHandle}
                      refreshLeads={props.refreshList}
                      startActivityHandle={startActivityHandle}
                      openLeadDetailsHandle={openLeadDetailsHandle}
                      openConvertTicketHandle={openConvertTicketHandle}
                    />
                    </div>
                  )
              })}
            </div>

        
            

          </div>
        :
        <div className="middle" >
          <div className="heading4 font-normal white-card no-shadow" style={{border:"1px dashed var(--op-color-dark)",padding:"25px 35px"}}>No tickets available</div>
        </div>
        }

        

      </div>
      
      
    </div>
  );
};

export default TicketListContainer;
