import React from "react";
import Search from "./Search";
 
const Header: React.FC<{
  refreshList:()=>void
  setSearchedCustomerList:(values:any)=>void
  allCalendarEvents:Array<any>
}> = (props) => {



  return (
    <div className="full-width position-relative display-flex-row space-between border-bottom bg-color-light calendar-header" style={{padding:"10px 0",height:"fit-content"}}>
 
        {/* <LeftContent/> */}
        <div className="middle">
          {/* <ToggleView setIsShowCalendar={props.setIsShowCalendar}/> */}
        </div>

        <div className="display-flex-row hide-in-mobile">
          <Search setSearchedCustomerList={props.setSearchedCustomerList}/>
        </div>

      </div>  
  );
};

export default Header;
