import React, { useEffect, useState } from "react";
import { removeActiveClass } from "../../../utils/UI_methods/global";
import ProjectCards from "../../common/ui-components/cards/ProjectCards";
import CardsLayout from "../../templates/layouts/page/CardsLayout";
import CreateProject from "./createproject/CreateProject";
import Filters from "./Filters";
import ProjectHeader from "./ProjectHeader";

const ProjectsContainer: React.FC<{
  projects: Array<any>;
  configObj: any;
  gotoCustomizer: (
    projectId: number,
    areaId: number,
    areaName: string,
    projectName: string,
    viewName: string
  ) => void;
  allViews:any
  allAreas:any
  allCategories:any
  moodBoardSavedconfigs:any
  setCurrProjectDetails: (id: number, name: string) => void;
  setCurrMoodboardId: (id: string) => void;
  refreshProjects:(projects: Array<any>, allProjectAreas: Array<any>)=>void
}> = (props) => {
  // const [showModal, setShowModal] = useState(false);

  const [createNewProject, setCreateNewProject] = useState(false);

  const [currProjectId, setCurrProjectId]: any = useState(0);
  const [projects, setProjects]: Array<any> = useState(props.projects);



  useEffect(()=>{
    props.configObj.setFunctionRef("projects",{setProjects:setProjects})
  },[])



  function AddCardForm() {
    return(
       <CreateProject
          closeModal={setCreateNewProject}
          projectId={currProjectId}
          CLIENT_ID={props.configObj?.clientId}
          refreshProjects={props.refreshProjects}
          allCategories={props.allCategories}
          configObj={props.configObj}
        />
    )
  }


  return (
    <React.Fragment>
    

      <ProjectHeader
           configObj={props.configObj}
      />

      <CardsLayout
        MainContent={()=>(
          <ProjectCards
          projects={projects}
          configObj={props.configObj}
          gotoCustomizer={props.gotoCustomizer}
          allViews={props.allViews}
          allAreas={props.allAreas}
          allCategories={props.allCategories}
          moodBoardSavedconfigs={props.moodBoardSavedconfigs}
          setCurrProjectDetails={props.setCurrProjectDetails}
          setCurrMoodboardId={props.setCurrMoodboardId}
          refreshProjects={props.refreshProjects}
        />
        )}
        AddCardForm={AddCardForm}
        configObj={props.configObj}
      />

          {/* <div id="projectFiltersMobileWrapper" className='subcategories-wrapper-mobile hide-in-desktop white-card'>
            <div className='center-aligned-row full-width ion-padding'>
              <div className='subcategories-close' onClick={()=>removeActiveClass("#projectFiltersMobileWrapper")}></div>
            </div>
            <div className="padding10 ion-margin-start heading1">Project filters</div>
            <div className="sub-categories ion-padding" style={{paddingTop:"0"}}>
              <Filters/>
            </div>
          </div> */}
    
     
    </React.Fragment>
  );
};

export default ProjectsContainer;
