import $ from "jquery";
import React, { useEffect, useState } from "react";
import { STORE, SofaConfiguration } from "../../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import MaterialSelector from "./MaterialSelector";
import MaterialsPartMapping from "./MaterialsPartMapping";
import { getObjectArrayOfDistictValues } from "../../../../../../utils/methods";
import { showGlobalToast } from "../../../../../../utils/UI_methods/global";
import SelectedMaterialsList from "./SelectedMaterialsList";

 

const MaterialsOptions: React.FC<{
  onChange?:(val:any)=>void
  productConfiguration:any
  confirmMaterialHandle:()=>void
  product:any
}> = (props) => {

  const[materials,setMaterials] = useState([])

  const[selectedMaterials,setSelectedMaterials] = useState(props.productConfiguration.materials)
  // const[isMaterialsSelected,setIsMaterialSelected] = useState(false)
  const[selectedOptionIds,setSelectedOptionsIds] = useState([])


  useEffect(()=>{
      fetchData()
  },[])

 
  const fetchData = () => {
    
    let promises = [STORE.storeApi.product.getProductMaterials()]
    Promise.all(promises).then(async (data)=>{
      const materials = data[0]
      if(!materials?.data?.error){
        setMaterials(materials || [])
      }
      STORE.data.materialList = materials
    }).catch(err=>{
      console.log(err)
    })
  }


  function onChange(event:any) {
    let value = $(event.target).attr("data-material-code")
    if(props.onChange){
      props.onChange(value)
    }
  }


  function nextHandle() {
    if(selectedMaterials.length){
      props.confirmMaterialHandle()
      return
    }
    let material = STORE.data.materialList.filter(currMaterial => selectedOptionIds.includes(currMaterial.id))
    material = getObjectArrayOfDistictValues(material,"id")
    if(material.length > 3 ){
      showGlobalToast("Maximun 3 Fabric",2000,"error")
      return 
    }
    props.productConfiguration.addMaterials(material)
    setSelectedMaterials(props.productConfiguration.materials)
  }


  function Footer(props:any) {
    return(
      <div className="display-flex-row ion-padding">
          <Button2
              configObj={{id:""}}
              action={nextHandle}
              label={`${selectedMaterials.length?"Confirm":"Next"}`}
              fontAwesomeIcon={"fas fa-arrow-right color-primary"}
              iconPos=""
              classNames={"no-shadow button-large button-fixed-width text-center pc-bg-color-primary"}
          />
      </div>
    )
  }

  function resetHandle() {
    props.productConfiguration.materials = []
    setSelectedMaterials([])
    setMaterials(STORE.data.materialList)
    setSelectedOptionsIds([])
  }


  return (
    <div className="full-height-width product-customizer-wrapper material-options-container  bg-color-light padding10">
      <div className="header-content ion-padding position-relative padding-container full-width display-flex-row space-between">

        {selectedMaterials.length?
          <div className="center-aligned-column">
            <Button2
              configObj={{id:""}}
              action={resetHandle}
              label={`Select Materials`}
              fontAwesomeIcon={"fas fa-arrow-left color-warning"}
              iconPos="left"
              classNames={"no-shadow text-center hide-button-text bg-color-warning margin0"}
            />
          </div>
          :null
        }
        

        <div className="heading1 middle pc-color-primary font-large capitalize text-center ">
          {!selectedMaterials.length?
          "Select Your Materials":
          "Select Fabric Part Configuration"
          }
        </div>
        
        <div></div>
      </div>
        
          <div className="display-flex-column position-relative body-content customization-options no-wrap configuration-selector padding-container">
          
            {selectedMaterials.length?
              <MaterialsPartMapping
                productConfiguration = {props.productConfiguration}
                resetHandle={resetHandle}
                product={props.product}
              />
            :
              <MaterialSelector
                materials={materials}
                action={onChange}
                selectedOptionIds={selectedOptionIds}
                setSelectedOptionsIds={setSelectedOptionsIds}
              />
            }
            
        </div>
        <div className="padding10 footer-content center-aligned-column position-relative">
          <div className="middle">
            {!selectedMaterials.length?
            <SelectedMaterialsList selectedOptionIds={selectedOptionIds}/>
            :null}
            <Footer />
          </div>
        </div>
    </div>
    
        
  );
};

export default MaterialsOptions;
