import { IonIcon } from '@ionic/react';
import {
  close
} from "ionicons/icons";
import React from 'react';
 
const GlobalToast: React.FC<{
  }> = (props) => {


 
  return (

    <React.Fragment>
        <div id="globalToast" className="center-aligned-row toast global-toast pointer-none white-card shadow-light">
          <div className="display-flex-row center-aligned-column no-wrap">
            <div className="icon">
              <i className="" id="icon"></i>
            </div>
            <div className="text">Some Text</div>
            <div className="close center-aligned-column">
              <IonIcon icon={close} className="color-white font-medium"></IonIcon>
            </div>
          </div>
        </div>
    </React.Fragment>
  );
};

export default GlobalToast;
