export var ushapeConfiguration ={
    "productName": "LShape1",
    "categoryName": "Kitchen",
    "subCategoryName": "LShapeKitchen",
    "enabled": true,
    "isAddedAsNew": true,
    "transform": {
        "position": {
            "x": 0,
            "y": 0,
            "z": 0
        },
        "rotation": {
            "_x": 0,
            "_y": 0,
            "_z": 0,
            "_w": 1
        },
        "scale": {
            "x": 1,
            "y": 1,
            "z": 1
        }
    },
    "productId": "",
    "categoryId": "",
    "originalProductName": "LShape1",
    "productInstanceName": "LShape1",
    "isAttachedToWall": false,
    "isWallMounted": false,
    "normal": {
        "x": 0,
        "y": 0,
        "z": 1
    },
    "configuration": {
        "BaseShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter3": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter4": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter5": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter6": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter7": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter8": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseShutter9": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter3": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter4": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter5": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter6": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftShutter7": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter3": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter4": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter5": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter6": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter7": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Shutter8": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "LoftBase": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "UpperLoft_Base": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "Skirting": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "Handle": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "CounterTop": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BackSplash": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseDrawer1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "BaseDrawer2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "TallUnitShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "WallUnitShutter1": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "WallUnitShutter2": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "WallUnitBase": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        },
        "OpenShelf": {
            "companyName": "Default",
            "collectionName": "Default",
            "materialCode": "Default",
            "materialType": "Default",
            "materialId": 0,
            "uvScale": 0,
            "roughness": 0,
            "metalness": 0,
            "specular": 0,
            "clearcoat": 0,
            "sheen": 0,
            "transmission": 0,
            "normal_strength": 1,
            "normalStrength": 1,
            "partName": ""
        }
    },
    "moduleConfiguration": {
        "modules": [
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "Corner1Kitchen",
                "moduleType": "Base",
                "subModuleType": "Corner",
                "moduleObjectId": "A2858495-1746-4ADD-BA7A-CA35E8EF8A1C",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -571.0195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 2,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "EEB29DED-E362-4D77-9A18-3FD7AB1827F6",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -571.5195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "E8D4276E-730F-4ECC-BAAF-F67F0CCE36B5",
                "transform": {
                    "position": {
                        "x": 265.51534569404845,
                        "y": 4176.518847584724,
                        "z": -570.0295546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 1,
                        "_z": 0,
                        "_w": 6.123233995736766e-17
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "B0B52FE4-710A-4B87-A711-43F79C12E33E",
                "transform": {
                    "position": {
                        "x": 264.9347723672981,
                        "y": 4176.518847584724,
                        "z": -570.0210360604258
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 1,
                        "_z": 0,
                        "_w": 6.123233995736766e-17
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "5D1CE67F-7C50-4DF5-8B9E-EDAC22C6EE48",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -572.0195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "TallUnit1Kitchen",
                "moduleType": "Tall",
                "subModuleType": "For fridge and freezer",
                "moduleObjectId": "9036E030-2BDF-4123-AB18-22064D1CA103",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -573.0605631961012
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 2,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "5AA59248-4CA1-4122-8DAE-DC0C2931C4BA",
                "transform": {
                    "position": {
                        "x": 263.8279129781941,
                        "y": 4176.518847584724,
                        "z": -570.9672658408451
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 0.7071067811865475,
                        "_z": 0,
                        "_w": 0.7071067811865476
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "CB87EE3E-3B9D-440E-B15E-E908C2B07A67",
                "transform": {
                    "position": {
                        "x": 266.13646093750003,
                        "y": 4176.518847584724,
                        "z": -572.5195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": -0.7071067811865476,
                        "_z": 0,
                        "_w": 0.7071067811865475
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": 1
                },
                "moduleName": "Corner1Kitchen",
                "moduleType": "Base",
                "subModuleType": "Corner",
                "moduleObjectId": "0011C900-244A-45CB-AD53-5A82ECFC9B7B",
                "transform": {
                    "position": {
                        "x": 263.81846459960934,
                        "y": 4176.518847584724,
                        "z": -570.0195546875
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 0.7071067811865475,
                        "_z": 0,
                        "_w": 0.7071067811865476
                    },
                    "scale": {
                        "x": 2,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": 1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "8362F8D6-C305-4CBC-A799-3C9E274F28B1",
                "transform": {
                    "position": {
                        "x": 263.82739198414936,
                        "y": 4176.518847584724,
                        "z": -571.4672658408451
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 0.7071067811865475,
                        "_z": 0,
                        "_w": 0.7071067811865476
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": 1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "8185543D-4099-46E6-9A76-2E96BC18842E",
                "transform": {
                    "position": {
                        "x": 263.81846459960934,
                        "y": 4176.518847584724,
                        "z": -571.9672658408451
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 0.7071067811865475,
                        "_z": 0,
                        "_w": 0.7071067811865476
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            },
            {
                "normal": {
                    "x": 0,
                    "y": 0,
                    "z": 1
                },
                "moduleName": "BaseUnit1Kitchen",
                "moduleType": "Base",
                "subModuleType": "With drawer",
                "moduleObjectId": "7BECC20B-AA0F-40E1-AFBF-DF7D5EE55633",
                "transform": {
                    "position": {
                        "x": 263.81846459960934,
                        "y": 4176.518847584724,
                        "z": -572.4672658408451
                    },
                    "rotation": {
                        "_x": 0,
                        "_y": 0.7071067811865475,
                        "_z": 0,
                        "_w": 0.7071067811865476
                    },
                    "scale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "configuration": [],
                "isAddedAsAddon": false,
                "isWallMounted": false,
                "isAttachedToWall": false
            }
        ],
        "addons": [],
        "isGroupRotationVertical": false,
        "groupRotation": 0,
        "isViewProductMode": false,
        "allowSpacePlanner": true,
        "allowFinish": true,
        "allowAr": true,
        "allowAddons": true
    }
}