import { applicationConfig, getFilteredArray, getUser } from "../methods"

export class createClientForm{
    
    id:number = 0
    clientName:string = ""
    location:string = "Delhi"
    email:string = "example@gmail.com"
    subscriptionType:string = "Premium"
    installationType:string = "Service"
    subscriptionStartDate:any = null
    subscriptionEndDate:any = null
    status:string = ""


    constructor(savedUser:any){
        if(savedUser){
            this.clientName = savedUser.client_name
            this.email = savedUser.email
            this.location = savedUser.client_location
            this.subscriptionStartDate = savedUser.subscription_start_date
            this.subscriptionEndDate = savedUser.subscription_end_date
            this.status = savedUser.status
            this.id = savedUser.id
        }
      
    }

    setClientName(clientName:string){
        this.clientName = clientName
    }

    setEmail(email:string){
        this.email = email
    }

    setStatus(status:string){
        this.status = status
    }

    setLocation(location:string){
        this.location = location
    }

    setStartDate(subscription_start_date:string){
        this.subscriptionStartDate = subscription_start_date
    }

    setEndDate(subscription_end_date:string){
        this.subscriptionEndDate = subscription_end_date
    }
   
}