import React, { useState } from "react";
 

const OtherDetails: React.FC<{
  product:any
}> = (props) => {

  const[detailsLeft] = useState([
    {
      name:"Material",
      value:"Terry Cotton Fabric"
    },
    {
      name:"Three Seater Dimensions	",
      value:"85 L X 38 W x 37 H inches"
    },
    {
      name:"Seating Foam",
      value:"4 Inch 32 Density, 1 Inch Recron Poly Sheet 250 GSM"
    },
    {
      name:"Back Foam",
      value:" Recron Poly Sheet 150 GSM"
    },
    {
      name:"Spring",
      value:"3 Inch Elastic Belt"
    },
    {
      name:"Leg",
      value:"Acacia Wood"
    },
    {
      name:"Wood Material",
      value:"Neem Wood"
    },
    {
      name:"Foam Density",
      value:"32 Density"
    },
  ])

  const[detailsRight] = useState([
    {
      name:"Armrest",
      value:"Yes"
    },
    {
      name:"Color",
      value:"Blue"
    },
    {
      name:"Customization",
      value:"Available"
    },
    {
      name:"Estimate Delivery",
      value:"20 Days"
    },
    {
      name:"Warranty",
      value:"2yrs of Manufacturing Warranty"
    },
    {
      name:"SKU",
      value:"Apollo Sofa"
    }
  ])

 
  return (
    <div className="full-width  padding-container" style={{marginTop:"3rem"}}>
      <div className="center-aligned-row heading2 font-medium padding10 border-top border-bottom">Product Details</div>
      <div className="display-flex-row marginTop10">
        <div className="width50 padding-container">
          {detailsLeft.map((detail:any,index:number)=>{
            return(
            <div key={index} className="display-flex-row marginTop10 space-between">
              <span className="heading3 font-normal">{detail.name}</span>
              {/* <span className="heading3 font-normal">:</span> */}
              <span className="heading4 font-md-small">{detail.value}</span>
            </div>
            )
          })}
          
        </div>
        <div className="width50 padding-container">

          {detailsRight.map((detail:any,index:number)=>{
            return(
            <div key={index} className="display-flex-row marginTop10 space-between">
              <span className="heading3 font-normal">{detail.name}</span>
              {/* <span className="heading3 font-normal">:</span> */}
              <span className="heading4 font-md-small">{detail.value}</span>
            </div>
            )
          })}

        </div>
      </div>
      
    
    </div>
  );
};

export default OtherDetails;
