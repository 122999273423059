import React, { useEffect, useState } from "react";
import { showToast } from "../../../../../utils/UI_methods/global";
import { getArrayOfDistinctValuesFromObject } from "../../../../../utils/methods";

const LinkDetails: React.FC<{
  shareData:any
  moduleName:string
  shareItemDetails:any
  kanvasModeConfig:any
}> = (props) => {


  const[collections,setCollections] = useState([])

  useEffect(()=>{
    let collectionList = props.shareData?.collectionList
    if(collectionList?.length){
      if(typeof collectionList[0] != "string"){
        collectionList = getArrayOfDistinctValuesFromObject(collectionList,"collection_name")
        setCollections(collectionList)
      }else{
        setCollections(collectionList)
      }
    }
  },[props.shareData])

 
    
  function handleCopy() {
    let link:any = document.getElementById("shareLinkAddress")
    link.select()
    document.execCommand("copy");
    link.setSelectionRange(0, 99999)
    showToast("Link copied",2000)
  }


  return (
    <div className="flex1 background-white share-link-details-container" id="shareLinkContainer">
    
    <div className="full-width position-relative">
      {/* <div className="heading1 uppercase color-primary">Share link details</div> */}

      <div className="select-validity-container marginBottom5">
        <div className="heading2 color-danger">Token Validity</div>
      <button className="op-btn op-btn-primary-inverse pointer-none --is-active">{props.shareData.tokenValidity * 24} Hours</button>
      </div>


      {props.moduleName==="products" && !props.shareItemDetails && !props.kanvasModeConfig?
      <>


      <div className="heading2 color-danger marginBottom5">Shared  collections</div>
      <div className="create-project-areas-container marginBottom5 position-relative display-flex-row" style={{padding:0}}>

      {collections?.length?collections.map((collection:string,index:number)=>{
        return(
          <div className="area-item " key={index}>
          <div className="area-image white-card border shadow-light">
            <div className="top-left check-container">
              <div className="check"></div>
            </div>
            <div>
            <div className="title color-black">{collection}</div>
            </div>
          </div>
        </div>
        )
      }):
      <div className="center-aligned-row full-width border-dashed ion-padding ion-margin-top ion-margin-bottom">
        <div className="label">No Collections Available</div>
      </div>
      }
      </div>
      </>
      :null}
  

      <div className="share-link-container position-relative">
        <div className="heading2 color-danger">Share link</div>
        <textarea name="Text1" readOnly value={props.shareData.link} id="shareLinkAddress" className="background-white share-link-input full-width">
            {props.shareData.link}
        </textarea>
        <div className="center-aligned top-right">
          <i className="fas fa-copy color-dark font-medium" onClick={handleCopy}></i>
          {/* <button className="op-btn-primary-inverse-active op-btn-lg op-btn" >Copy link</button> */}
        </div>
      </div>

    </div>


  
</div>
  );
};

export default LinkDetails;
