import React, { useEffect, useState } from "react";
import { getFormatedDate } from "../../../../../../../utils/store/crm/crm";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import MarkAsClosedOption from "./MarkAsClosedOption";
import { getTodayTimestampOnlyDate } from "../../../../../../../utils/store/crm/calendar";
 

 
const TicketCard: React.FC<{
  ticket:any
  index:number
  openCalendarHandle:(val:any)=>void
  refreshLeads:()=>void
  startActivityHandle:(name:string,ticket:any)=>void
  openLeadDetailsHandle:(lead:any,view:string)=>void
}> = (props) => {

  
  const[timeDifference] = useState((getTodayTimestampOnlyDate() - props.ticket.created_at) / (1000 * 60 * 60 * 24))
  const[timeDifferenceClosedDate,setClosedDate] = useState(null)
  
  useEffect(()=>{
    if(props.ticket.closed_at){
      setClosedDate((getTodayTimestampOnlyDate() - props.ticket.closed_at) / (1000 * 60 * 60 * 24))
    }
  },[props.ticket])
    
  function Options() {
    return(
      <div className="display-flex-row no-wrap">

          <Button2
            configObj={{id:""}}
            action={()=>{props.startActivityHandle("call",props.ticket)}}
            label={""}
            fontAwesomeIcon={"fas fa-phone-alt color-primary"}
            iconPos="left"
            classNames={"no-shadow button-small color-white bg-color-"}
          />

          <Button2
            configObj={{id:""}}
            action={()=>{props.startActivityHandle("whatsapp",props.ticket)}}
            label={""}
            fontAwesomeIcon={"fab fa-whatsapp font-medium color-success"}
            iconPos="left"
            classNames={"no-shadow button-small color-white bg-color-"}
          />

          <Button2
            configObj={{id:""}}
            action={()=>{{props.openLeadDetailsHandle(props.ticket,"activity")}}}
            label={""}
            fontAwesomeIcon={"far fa-comments font-small color-warning"}
            iconPos="left"
            classNames={"no-shadow button-small color-white"}
          />

        {props.ticket.resolution_date === null?

          <Button2
            configObj={{id:""}}
            action={()=>{props.openCalendarHandle(props.ticket)}}
            label={"Schedule"}
            fontAwesomeIcon={"fas fa-calendar-alt color-warning"}
            iconPos="left"
            classNames={"no-shadow button-small color-white bg-color-warning-outline"}
          />
        :
          <Button2
            configObj={{id:""}}
            action={()=>{props.openCalendarHandle(props.ticket)}}
            label={"Reschedule"}
            fontAwesomeIcon={"fas fa-calendar-alt color-warning"}
            iconPos="left"
            classNames={"no-shadow button-small color-white bg-color-warning-outline"}
          />
        }

          <MarkAsClosedOption ticket={props.ticket} refreshLeads={props.refreshLeads}/>

      </div>
    )
  }


  function getTextFromDay(date:number) {
    if(date){
      let diff = (getTodayTimestampOnlyDate() - date) / (1000 * 60 * 60 * 24)

      if(diff === 0){
        return "Today"
      }
      if(diff === 1){
        return "1 Day ago"
      }
      if(diff > 1){
        return `${diff} Days ago`
      }
      return `${diff} Days ago`
    }
    return 0
    
  }

  return (
    <div className="full-width padding5 lead-card-container" key={props.index}>

      <div className={`white-card pointer position-relative no-shadow border ${props.ticket.is_viewed === 0 ? " bg-color-light" : ""}`}>

        
        <div className="display-flex-row"  onClick={()=>{props.openLeadDetailsHandle(props.ticket,"activity")}}>

          <div className="padding5 name-badge-container">
            <div className="capitalize name-badge center-aligned-column white-card no-shadow">
              {props.ticket.customer_name.substring(0,2)}
            </div>
          </div>

          <div className="customer-details center-aligned-column padding5">

            <div className="contact-details ">

              <div className="display-flex-row item">
                <span className="hide-in-desktop"><i className="far fa-id-card icon color-dark"></i></span>
                <span className="heading2 capitalize name color-black text text-ellipsis marginleft5">{props.ticket.customer_name}</span>
              </div>

              <div className="display-flex-row custom-tooltip item ion-margin-start position-relative">
                <span className="center-aligned-column"><i className="fas fa-info-circle icon color-dark"></i></span>
                <span className="text  marginleft5 text-ellipsis" style={{marginTop:"2px",maxWidth:"5rem"}} >{props.ticket.issue_type}</span>
                <div className="custom-tooltip-text">{props.ticket.issue_type}</div>
              </div>

              {props.ticket.description?
              <div className="display-flex-row custom-tooltip item ion-margin-start position-relative">
                <span className="center-aligned-column"><i className="far fa-comments icon color-dark"></i></span>
                <span className="text  marginleft5 text-ellipsis" style={{marginTop:"2px",maxWidth:"4rem"}} >{props.ticket.description}</span>
                <div className="custom-tooltip-text">{props.ticket.description}</div>
              </div>
              :null}

              
            {props.ticket.resolution_date?
              <div className="display-flex-row item ion-margin-start">
                <span className="center-aligned-column"><i className="far fa-calendar-alt icon color-warning"></i></span>
                <span className="text marginleft5 color-warning">Scheduled: {getFormatedDate(props.ticket.resolution_date)} </span>
              </div>
            :null}

              {/* {props.ticket.remarks?
              <div className="display-flex-row custom-tooltip item ion-margin-start position-relative">
                <span className="center-aligned-column"><i className="far fa-comment-dots icon color-dark"></i></span>
                <span className="text  marginleft5 text-ellipsis" style={{marginTop:"2px"}} >{props.ticket.remarks}</span>
                <div className="custom-tooltip-text">{props.ticket.remarks}</div>
              </div>
              :null} */}

              <div className="display-flex-row custom-tooltip item ion-margin-start position-relative">
                <span className="center-aligned-column"><i className="far fa-clock icon color-dark"></i></span>
                <span className="text" style={{marginTop:"1px"}} > Created : <span className={`text ${timeDifference>1?"crm-color-danger":"color-success"}`}>{getTextFromDay(props.ticket.created_at)}</span></span>
              </div>

              {props.ticket.closed_at?
              <div className="display-flex-row custom-tooltip item ion-margin-start position-relative">
                <span className="center-aligned-column"><i className="far fa-clock icon color-dark"></i></span>
                <span className="text" style={{marginTop:"1px"}} > Closed on: <span className={`text`}>{getFormatedDate(props.ticket.closed_at)}</span></span>
              </div>
              :null}

              

            </div>
          </div>

        </div>
        
    

        <div className={`top-right options-container center-aligned-column full-height`}>
          {props.ticket.status === 1?
          <Options/>
          :
          null
          }
        </div>
      </div>
    </div>
    
  );
};

export default TicketCard;
