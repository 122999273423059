import React, { useEffect, useState } from "react";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import TicketCard from "./TicketCard";
import TicketListContainer from "./TicketListContainer";
import Header from "./header/Header";
import { CRM } from "../../../../../../../utils/store/crm/crmConfiguration";
import { ActivityEvent } from "../../../../../../../utils/store/crm/calendar";
 

 
const TicketsList: React.FC<{
}> = (props) => {

  const[ticketsList,setTicketsList] = useState([])
  
  const[searchedCustomerList,setSearchedCustomerList] = useState([])

  const [currSelectedTime,setCurrSeletedTime] = useState("Today")

  const[refresh,setIsReferesh] = useState(Number(new Date()))

  useEffect(()=>{
    CRM.crmApi.customerCreatedTicket.getTickets().then(data=>{
      if(!data.data.error){
        setTicketsList(data.data.data)
        // setCurrSeletedTime("Today")
        CRM.data.ticketsList = data.data.data
      }
    }).catch(err=>{
      console.log(err)
    })
  },[refresh])
  



  function refreshList() {
    setIsReferesh(Number(new Date()))
  }

  function SearchResult() {
    return(
      <div className="full-width  full-height bg-color-light hide-scrollbar calendar-header padding10">
        <div className="display-flex-row no-wrap paddingTop5">
          <div className="">
              <Button2
                  configObj={{id:""}}
                  action={()=>{setSearchedCustomerList([])}}
                  label={"All customers"}
                  fontAwesomeIcon={"fas fa-arrow-left color-dark"}
                  iconPos="left"
                  classNames={"no-shadow marginleft5"}
              />
            </div>
            {/* <div className="heading3 padding5 text-center full-width center-aligned-column">Showing {searchedLeadsList.length} results</div> */}
          </div>
          {/* <div className="marginTop10">
            {searchedCustomerList?.map((customer:any,index:number)=>{
              return(
                <TicketCard
                  ticket={customer}
                  index={index}
                  openCalendarHandle={()=>{}}
                  refreshLeads={refreshList}
                  startActivityHandle={()=>{}}
                />
              )
            })}
        </div> */}
      </div>
    )
  }
 

  function getEventsList(allTickets:Array<any>) {
    let events = []
    for (const ticket of allTickets) {
      events.push(new ActivityEvent(new Date(ticket.resolution_date),`${ticket.customer_name} - ${ticket.issue_type}`))
    }
    return events
  }  
 
  return (
    <div className="full-height-width">
 
      <Header allCalendarEvents={[]} refreshList={refreshList} setSearchedCustomerList={setSearchedCustomerList}/>

      <div className="display-flex-row no-wrap full-height">
        <div className="border full-height white-card shadow-light display-flex-column" style={{width:"100%"}}>
          <div className="full-width  full-height bg-color-light hide-scrollbar">
            {searchedCustomerList.length?
              <SearchResult/>            
            :
            <TicketListContainer refreshList={refreshList} allTickets={ticketsList} currSelectedTime={currSelectedTime}/>  
            }
          </div>
        </div>
      </div>


    </div>
  );
};

export default TicketsList;
