import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { ifEmptyFields, showToast } from "../../../../utils/UI_methods/global";
import { createMaterialUploadImage, saveFinishDetails } from "../../../../utils/inventory/inventory";
import { createFinishForm } from "../../../../utils/products/createFinishForm";
import Loader from "../../../common/shared-components/main-ui/Loader";
import Toast from "../../../common/ui-components/notification/Toast";
import ExistingCollectionsForm from "./ExistingCollectionForm";
import MaterialForm from "./MaterialForm2";
import NewCollectionsForm from "./NewCollectionForm";
import Options from "./Options";
import ReviewFinish from "./ReviewFinish";

const FinishForm: React.FC<{
  configObj: any;
  setCurrState: (value: string) => void;
  closeModal: (value: boolean) => void;
  currState:string
}> = (props) => {

    const[currForm,setCurrForm] = useState("options")
    const[addFinishLoader] = useState("addFinishLoader")
    const [finishDetails, setFinishDetails]:any = useState(new createFinishForm());


    useEffect(()=>{
      // props.setFinishDetails({})
      // setTimeout(() => {
      //   fillFormData()
      // }, 200);
      //   let imageUrlKey = getThumbnailsUrlKey(finishDetails?.companyName,finishDetails?.collectionName,finishDetails?.materialCode)
      //   setUploadedImage(imageUrlKey)
    },[])

    function fillFormData() {
      $("#materialName").val(finishDetails?.materialName)
      $("#companyName").val(finishDetails?.companyName)
      $("#collectionName").val(finishDetails?.collectionName)
      $("#materialCode").val(finishDetails?.materialCode)
      $("#category").val(finishDetails?.categoryId)
    }




  

    function reviewFinishDetails(){
        // createTarFile(SELECTED_FILE)
        if(!finishDetails?.materialCodes?.length){
          showToast("Please add materials",2000,"error")
          return
        }
        setCurrForm("review")
    }

    function goToMaterialsForm() {
      if(ifEmptyFields()){
        return
      }
      setCurrForm("material")
    }

    


 

  function Header() {
    return (
      <div className="modal-header">
        <div className="center-aligned-row">
          <strong>Add Material</strong>
        </div>
        <span
          className="right-middle"
          onClick={() => {
            props.closeModal(false);
          }}>
          <IonIcon icon={close} ios={close} className="font-large"></IonIcon>
        </span>
      </div>
    );
  }

  function Footer() {
    let nextLink = ""
    let prevLink = ""
    let nextButtonLabel = ""
    let nextAction:any
    switch (currForm) {
      case "newCollection":
        nextLink = "material"
        prevLink = "options"
        nextButtonLabel = "Add Materials"
        nextAction = goToMaterialsForm
      break;

      case "existingCollection":
        nextLink = "material"
        prevLink = "options"
        nextButtonLabel = "Add materials"
        nextAction = setCurrForm
      break;

      case "material":
        nextLink = "review"
        prevLink = "options"
        nextButtonLabel = "Next"
        nextAction = setCurrForm

      break;

      case "review":
        nextLink = "review"
        prevLink = "material"
        nextButtonLabel = "Confirm and Save"
        nextAction = setCurrForm
      break;

      default:
        nextLink="options"
        prevLink="options"
        nextButtonLabel = "Next"
        nextAction = setCurrForm
      break;
    }

    return (
      <div className="modal-footer">
        <div className="full-width display-flex-row space-between">
            <div><button className="op-btn op-btn-light" onClick={()=>setCurrForm(prevLink)}><i className="fas fa-chevron-left color-black"></i> Prev  </button></div>
            {currForm==="material"?
            <div><button className="op-btn op-btn-primary" onClick={reviewFinishDetails}>Review & Next <i className="fas fa-chevron-right color-white"></i></button></div>
            :
            currForm==="newCollection"?
            <div><button className="op-btn op-btn-primary" onClick={()=>saveFinishDetails(finishDetails,props.closeModal)}>Confirm and Save <i className="fas fa-save color-white"></i></button></div>
            :
            currForm==="review"?
            <div><button className="op-btn op-btn-primary" onClick={()=>createMaterialUploadImage(finishDetails,props.closeModal)}>Confirm and Save <i className="fas fa-save color-white"></i></button></div>
            :
            <div><button className="op-btn op-btn-primary" onClick={()=>nextAction(nextLink)}>{nextButtonLabel } <i className="fas fa-chevron-right color-white"></i> </button></div>
            }
          </div>
      </div>
    );
  }

  return (
    <React.Fragment>
          <Loader
            id={addFinishLoader}
            data={{}}
            className="fade-background-loader"
            loaderType={"spinner"}
          />
          <Toast/>
          <Header/>
        
          {currForm==="material"?
            <div className="modal-body">
            <MaterialForm 
              configObj={props.configObj}
              finishDetails={finishDetails}
              addFinishLoader={addFinishLoader}
            />
            </div>
          :currForm==="options"?
          <div className="modal-body">
          <Options
            configObj={props.configObj}
            setCurrForm={setCurrForm}
          />
          </div>
          :currForm==="newCollection"?
            <div className="modal-body">
          <NewCollectionsForm
            configObj={props.configObj}
            finishDetails={finishDetails}
          />
          </div>
          :currForm==="existingCollection"?
          <div className="modal-body">
        <ExistingCollectionsForm
          configObj={props.configObj}
          finishDetails={finishDetails}
        />
        </div>
          :
          currForm==="review"?
          <div className="modal-body">
            <ReviewFinish
              configObj={props.configObj}
              setCurrState={props.setCurrState}
              closeModal={props.closeModal}
              finishDetails={finishDetails}
              setFinishDetails={setFinishDetails}
              addFinishLoader={addFinishLoader}
            />
          </div>
          :
          <div>GO BACK</div>
          }

      <Footer/>

    </React.Fragment>
  );
};

export default FinishForm;
