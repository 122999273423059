import {Raycaster,Vector3} from 'three';
import { getCurrentConfigurationFromLocalStorage, getCurrentConfigurationKey, getPartName } from '../methods';
import { getModelViewerElement, getProductModelContainerElement } from '../UI_methods/modelviewer';

    export var selectTexture = async function(MODEL_VIEWER:any,companyName:string,collectionName:string,materialCode:string,selectedObject:string){
        //Start loader
        // const selectedValue = $('#js-texture-slide').attr('data-current-material')
        
        // let texture = generateTextureURL(BASE_PATH,e.target.dataset.name,e.target.dataset.companyName,e.target.dataset.collectionName)
        let texture =  `https://elevateardemo.s3.ap-south-1.amazonaws.com/Elevate/assets/thumbnails/${companyName}/${collectionName}/${materialCode}.png` 
        // let roughnessTextures = generateRoughnessMapURL(BASE_PATH,e.target.dataset.roughness,e.target.dataset.companyName,e.target.dataset.collectionName);
        // let normalMapTexture = generateNormalMapURL(BASE_PATH,e.target.dataset.normalmap,e.target.dataset.companyName,e.target.dataset.collectionName)
        const OBJECT_MATERIALS = MODEL_VIEWER?.model?.materials;
        
        //For checking if material type can be applied on part 
        // let materialType = e.target.dataset.materialType
        if (selectedObject == 'all') {
            for (let [i, material] of OBJECT_MATERIALS.entries()) {
                // if((CUSTOMIZABLE_MATERIAL.some(v => material.name.toLowerCase().includes(v))))
                // {
                    // let flag = checkIfMaterialAvailable(materialType,material.name,PARTS_MATERIALS)
                    if(true){
                         // Get material Type from material name (materialName,PARTS_MATERIALS)
                        await applyPBRTexture(OBJECT_MATERIALS,material.name, 'baseColorTexture', texture);                    
                        // await applyPBRTexture(OBJECT_MATERIALS,material.name, 'metallicRoughnessTexture', roughnessTextures);
                        // await applyNormalTexture(OBJECT_MATERIALS,material.name, 'normalTexture', normalMapTexture);
                    }else{
                        // console.log(materialType + ' Can not be applied on '+ material.name)
                        // $('#toast').addClass('show')
                        // $('#toast').text('Option Not Available')
                        // setTimeout(() => {
                        //     $('#toast').removeClass('show')
                        // }, 2500);
                    }
                // }else{
                //     console.log(material.name + ' Is not customizable')
                // }
            }
        } else {
            await applyPBRTexture(OBJECT_MATERIALS,selectedObject, 'baseColorTexture', texture);                
            // await applyPBRTexture(OBJECT_MATERIALS,materialName, 'metallicRoughnessTexture', roughnessTextures);
            // await applyNormalTexture(OBJECT_MATERIALS,materialName, 'normalTexture', normalMapTexture);

        }
        //End loader
    }

    export function updateProductModelSrc(src:string,iosSrc:string){
        const MODEL_VIEWER:any = getModelViewerElement()
        MODEL_VIEWER.src = src;
        MODEL_VIEWER.iosSrc = src;
    }

    export async function applyCurrentConfiguration(currentConfigObject:any,productName:string,MODEL_VIEWER:any){
        //apply configuration
        // applyCurrentConfiguration(props.currentConfigObject)
        const OBJECT_MATERIALS = getModelObjects(MODEL_VIEWER)
        let key = getCurrentConfigurationKey(currentConfigObject.projectName,currentConfigObject.areaName)
        let config = getCurrentConfigurationFromLocalStorage(key)
        let productConfig:any = {}
        for(let key in config){
            if(key.includes(productName)){
                productConfig[key] = config[key]
            }
        }

        for (const key in productConfig) {
            let config = productConfig[key]
            let companyName = config.companyName
            let collectionName = config.collectionName
            let materialCode = config.materialCode
            let objectName = getPartName(key)
            let texture =  `https://elevateardemo.s3.ap-south-1.amazonaws.com/Elevate/assets/thumbnails/${companyName}/${collectionName}/${materialCode}.png` 
            await applyPBRTexture(OBJECT_MATERIALS,objectName,'baseColorTexture', texture);                    
        }
    }


    export function addEventListeners(MODEL_VIEWER:any,setSelectedObject:any){
        MODEL_VIEWER.addEventListener('click', (event:any) => {
            var x:number = event.layerX 
            var y:number = event.layerY
            selectModelObject(MODEL_VIEWER,x,y,setSelectedObject)
            // applyColorOnModel(OBJECT_MATERIALS,obj.material.name,colorString)
            event.stopPropagation();
          }, true);
    }


    export function selectModelObject(MODEL_VIEWER:any,x:number,y:number,setSelectedObject:any){
      
        // x = x + $("#productModal").offset()!.left;
        // y = y + $("#productModal").offset()!.top;
        const scene = getModelViewerScene(MODEL_VIEWER);
        // raycaster.setFromCamera({x:x,y:y}, scene.getCamera());

        // const hits = raycaster.intersectObject(scene, true);
        const intersects = getIntersects(x, y,scene);
        var res = intersects.filter(function(res) {
            return res && res.object;
            // let obj:any = res.object
        })[0];
        if(!res){
            return
        }
        let obj:any = res.object
        let colorString = "0,1,1,0"
        // alert(obj.material.name)
        setSelectedObject(obj.material.name)
        // applyColorOnModel(OBJECT_MATERIALS,obj.material.name,colorString)
    }

    function getIntersects(x:number, y:number,scene:any) {
        const raycaster = new Raycaster();
        var mouseVector = new Vector3();
        let container =getProductModelContainerElement()
        x = (x / container.innerWidth()!) * 2 - 1;
        y = -(y / container.innerHeight()!) * 2 + 1;
        mouseVector.set(x, y, 0.5);
        raycaster.setFromCamera(mouseVector, scene.getCamera());
        return raycaster.intersectObject(scene, true);
    }

    
    var applyPBRTexture = async function(OBJECT_MATERIALS:any,materialName:string, channel:string, textureValue:string) {
        try{
            let material = getMaterial(OBJECT_MATERIALS,materialName)
            if(material.length===0){
                return
            }
            if (material[0].pbrMetallicRoughness[channel] != null) {
                await material[0].pbrMetallicRoughness[channel].texture.source.setURI(textureValue)
            } 
            else {
                console.log('Texture not available');
            }
        }catch(err){
            console.log('Error ' + err)
        }   
    }

    export var applyColorOnModel = async function(OBJECT_MATERIALS:any,materialName:string,colorString:string) {
        try{
            let material = getMaterial(OBJECT_MATERIALS,materialName)
            const color = colorString.split(',')
            .map(numberString => parseFloat(numberString));
            // if (material[0].pbrMetallicRoughness[channel] != null) {
            material[0].pbrMetallicRoughness.setBaseColorFactor(color);
            // } 
            // else {
            //     console.log('Texture not available');
            // }
        }catch(err){
            console.log('Error ' + err)
        }   
    }

    export function getModelViewerScene(MODEL_VIEWER:any){
        const $scene = Object.getOwnPropertySymbols(MODEL_VIEWER)
        .filter(symbol => symbol.description === 'scene')[0];
        return MODEL_VIEWER[$scene];
    }

    export function getModelObjects(MODEL_VIEWER:any){
        return MODEL_VIEWER?.model?.materials;
    }




    var applyNormalTexture =  async function(OBJECT_MATERIALS:any,materialName:string, channel:string, textureValue:string) {
        try {
            let material = OBJECT_MATERIALS.filter(function (material:any) {
                return material.name === materialName;
            })
            let targetMaterial =material[0];
            if (targetMaterial[channel]!= null) 
                await targetMaterial[channel].texture.source.setURI(textureValue);
        } catch (error) {
            console.log('Error ' + error)
        }
       
    }

    function getMaterial(OBJECT_MATERIALS:any,materialName:string){
        let material = OBJECT_MATERIALS.filter(function (material:any) {
            return material.name === materialName;
        })
        return material
    }

    function checkIfMaterialAvailable(materialType:string,objectName:string,PARTS_MATERIALS:any){
        objectName = convertObjectString(objectName)
        if(PARTS_MATERIALS[objectName.toLowerCase()].includes(materialType)){
            return true
        }else{
            return false
        }
    }   
    function generateRoughnessMapURL(BASE_PATH:string,roughness:string,companyName:string,collectionName:string){
        let url = BASE_PATH + 'assets/textures/' +  companyName + '/' + collectionName + '/' + roughness + '_Roughness.jpg'
        return url;   
    }

    function generateNormalMapURL(BASE_PATH:string,normalmap:string,companyName:string,collectionName:string){
        let url = BASE_PATH + 'assets/textures/' +  companyName + '/' + collectionName + '/' + normalmap + '_Normal.jpg'
        return url;
    }

    function generateTextureURL(BASE_PATH:string,materialCode:string,companyName:string,collectionName:string){
        let url = BASE_PATH + 'assets/textures/' +  companyName + '/' + collectionName + '/' + materialCode + '.jpg'
        return url;
    }

    function convertObjectString(objectName:string){
        //Get index of first .
        let index = objectName.indexOf('.')
        //Make a substring 
        if (index != -1) {
            objectName = objectName.substr(0,index)
            return objectName
        }else{
            return objectName
        }
    }
