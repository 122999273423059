import $ from "jquery"
import { addActiveClass, hideComponentLoader, showComponentLoader, showToast } from "../../UI_methods/global"
import AwsConfig from "../../aws-sdk/AwsConfig"
import { checkFromCache, untarAndSaveToCache } from "../../cache/cache"
import { getUrlPrefix } from "../../customizer/customizer"
import { setInitialRotationSliderValues } from "../../form/circlerangeslider"
import { applicationConfig, waitFor } from "../../methods"
import { closeCategories, getProductImageUrl, openCategories } from "../../moodboard/helper"
import { expandControlsPanel, hideControlsPanel, isOpenControlsPanel } from "../common"
import { CURR_SELECTED_PRODUCT } from "../raycasting"
import { CURR_AREA_NAME, IS_CUSTOMIZABLE_MODE, IS_SPACE_PLANNER_MODE, composer, controls, customizerConfig, deselectProduct, disableFinalizeMode, disableSpacePlanner, enableCustomzationMode, enableFinalizeMode, enableSpacePlanner, groupForRotatingProduct, hideAllWalls, isKitchenPlannerMode, kitchenPlanner, labelRenderer, projectConfiguration, renderer, setCustomizableMeshesAndTexturesList, showAreaWalls, undo } from "./area3dModel"
import { updateCameraSettingViewData } from "./floorplan/createRender"
import { getOriginalCategoryName, getOriginalProductName, getOriginalSubCategoryName, isWallMountedProduct, openMaterialsWindowFromCurrentProduct } from "./helper"
import { disableInteriorVirtualization, enableInteriorVirtualization, isInteriorVisualization } from "./interior_visualization"
import { CURR_DRAGGING_PRODUCT } from "./dragging"


var isLoadedCategory = false
var renderingTime = 0


export function gotoProductActions() {
    $(".tab-content").removeClass("--is-active")
    $("#Actions").addClass("--is-active")
}


export function openFinishPanel(event:any) {
    event?.preventDefault()
    hideColorSwatch()
    $("#Finish").addClass("--is-active")
    customizerConfig.functions.setCurrTab("Finish")
}


export function gotoAddModules() {
    $(".tab-content").removeClass("--is-active")
    $("#Modify").addClass("--is-active")
}

export function closeProductInfo(event:any) {
    $("#customizerProductInfo").removeClass("--is-active")
    $("#productInfoAnnotation").removeClass("--is-active")
    if(!IS_CUSTOMIZABLE_MODE){
        hideControlsPanel(function() {
            composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)          
        })
    }
}

export function openFinalize() {
    hideColorSwatch()
    hideControlsPanel(function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)       
    })
    addActiveClass("#customizeReviewWrapper")
}


export function displayClickHint() {
    $("#clickHintWrapper").addClass("--is-active")
}

export function hideClickHint() {
    $("#clickHintWrapper").removeClass("--is-active")
}

export function addActiveClassOnAnnotation(event:any) {
    removeActiveClassFromAnnotations()
    $(event?.target).addClass("--is-active")
}

export function removeActiveClassFromAnnotations() {
    $(".icon-container").removeClass("--is-active")
}

export function showProductOptionsPanel(event:any) {
    addActiveClassOnAnnotation(event)
    // $(".customization-cancel-button").addClass("--is-active")
    // $(".customize-product-options").addClass("--is-active")
    $("#customizerProductInfo").removeClass("--is-active")
    $(".product-customize-button").addClass("display-none")
    $(".tab-content").removeClass("--is-active")
    hideAddProducts()
    hideColorSwatch()
    hideCreateRender()
    expandControlsPanel(function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
        // applicationConfig.functions.customizer.updateCategory(CURR_SELECTED_PRODUCT.userData.categoryName)
    })
    if(CURR_SELECTED_PRODUCT){
        openMaterialsWindowFromCurrentProduct()
        //No ui event when selecting a mesh after product is clicked
        if(event){
            setCustomizableMeshesAndTexturesList()
        }
        enableCustomzationMode()
        let config = CURR_SELECTED_PRODUCT.userData.configuration || CURR_DRAGGING_PRODUCT
        applicationConfig.functions.customizer.updateCategory(config.categoryName)
    }
}

export function openReplaceProductPanel(event:any) {
    kitchenPlanner?.setIsReplaceMode(true)
    addActiveClassOnAnnotation(event)
    hideAddProducts()
    hideColorSwatch()
    // applicationConfig.functions.customizer.setisOpenAddProduct(false)
    applicationConfig.functions.products.filterProductsWithSubCategory(CURR_SELECTED_PRODUCT.userData.categoryName,CURR_SELECTED_PRODUCT.userData.subCategoryName)
    expandControlsPanel(function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)

      

      setTimeout(() => {
        $(".tab-content").removeClass("--is-active")
        $("#Replace").addClass("--is-active")
        customizerConfig.functions.setCurrTab("Replace")
        closeCategories()
      }, 200);

    })

    setTimeout(() => {
        $(".tab-content").removeClass("--is-active")
        $("#Replace").addClass("--is-active")
        customizerConfig.functions.setCurrTab("Replace")
        closeCategories()
    }, 200);
  }

  export function openProduOrGroupInfo() {
    
  }


  export function openProductInfoPanel(event:any) {
    if(CURR_SELECTED_PRODUCT.userData.isModulesGroup){
        openGroupsListPanel(event)
        return
    }
    addActiveClassOnAnnotation(event)
    hideAddProducts()
    hideColorSwatch()
    expandControlsPanel(function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    })
    setTimeout(() => {
        $(".tab-content").removeClass("--is-active")
        $("#productInfoWrapper").addClass("--is-active")
      }, 200);
  }

  export function openGroupsListPanel(event:any) {
    hideAddProducts()
    hideColorSwatch()
    expandControlsPanel(function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    })
    setTimeout(() => {
        $(".tab-content").removeClass("--is-active")
        $("#kitchenPlannerGroupsWrapper").addClass("--is-active")
      }, 200);
  }


export function showAddProducts() {
    // deselectProduct()
    hideColorSwatch()
    if(isOpenControlsPanel){
        hideCreateRender()
        $(".customizer-add-products").addClass("--is-active")
        if(!isLoadedCategory){
            openCategories()
            isLoadedCategory = true
        }
        return  
    }
    $(".add-products-button").fadeOut(0)
    expandControlsPanel(function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
        $(".customizer-add-products").addClass("--is-active")
        if(!isLoadedCategory){
            openCategories()
            isLoadedCategory = true
        }
    })
}


export function closeControlsPanelAndHideProducts() {
    hideControlsPanel(function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
        hideAddProducts()
        hideCreateRender()
        applicationConfig.functions.customizer.setisOpenReplaceProduct(false)
    })
    $(".product-customize-button").removeClass("display-none")
}
export function hideAddProducts() {
    $(".customizer-add-products").removeClass("--is-active")
    $(".add-products-button").fadeIn(0)
}

export function showCreateRender() {
    if(isOpenControlsPanel){
        hideAddProducts()
        $(".customizer-create-render").addClass("--is-active")
        return  
    }
    hideColorSwatch()
    // deselectProduct()
    // $(".create-render-button").fadeOut(0)
    expandControlsPanel(function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
        $(".customizer-create-render").addClass("--is-active")
    })
}

export function showColorSwatch() {
    expandControlsPanel(async function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
        await waitFor(200)
        $(".tab-content").removeClass("--is-active")
        $(".customizer-create-render").removeClass("--is-active")
        $(".customize-product-options").removeClass("--is-active")
        $("#colorSwatchPanel").removeClass("display-none")
    })
}

export function hideColorSwatch() {
    $("#colorSwatchPanel").addClass("display-none")
}

export function closeControlsPanelAndHideCreateRender() {
    hideControlsPanel(function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
        hideCreateRender()
    })
}

export function hideCreateRender() {
    $(".customizer-create-render").removeClass("--is-active")
    $(".create-render-button").fadeIn(0)
}



export async function gotoProductInfo(event:any) {
    event?.preventDefault()
    event?.stopPropagation()
    hideAddProducts()

    addActiveClassOnAnnotation(event)
    expandControlsPanel(function() {
        composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    })

    await waitFor(200)

    // $(".tab-content").removeClass("--is-active")
    $("#customizerProductInfo").addClass("--is-active")
    $("#customizerProductInfo").find(".product-name").text(CURR_SELECTED_PRODUCT.userData.productName)
    let categoryName = getOriginalCategoryName(customizerConfig,CURR_SELECTED_PRODUCT.userData.productName)
    $("#customizerProductInfo").find(".category-name").text(categoryName)

    let imageUrlKey = getProductImageUrl(null,
    applicationConfig?.clientName,
    getOriginalProductName(customizerConfig,CURR_SELECTED_PRODUCT.userData.productName),
    categoryName,
    getOriginalSubCategoryName(customizerConfig,CURR_SELECTED_PRODUCT.userData.productName)
    );
    let urlPrefix = getUrlPrefix(imageUrlKey);
    let tarFileKey = applicationConfig.awsConfig.getTarFileKey("products",
    {clientName:applicationConfig?.clientName,
    category:getOriginalCategoryName(customizerConfig,CURR_SELECTED_PRODUCT.userData.productName),
    subCategory:getOriginalSubCategoryName(customizerConfig,CURR_SELECTED_PRODUCT.userData.productName),
    productName:getOriginalProductName(customizerConfig,CURR_SELECTED_PRODUCT.userData.productName)
    })


    await untarAndSaveToCache(applicationConfig.awsConfig, urlPrefix, tarFileKey)?.then((data) => {
        displayImages(imageUrlKey,"customizeProductInfoImage")
    }).catch(err=>{
        displayImages(imageUrlKey,"customizeProductInfoImage")
    })
}



export function setProductModules(product:any) {
    customizerConfig.functions.setCurrProductId(product.productId)
}

export function hideAnnotations() {
    $(".product-annotations-wrapper").addClass("--is-hide")   
}


export async function displayAnnotations() {
  
  
    let categoryName =   CURR_SELECTED_PRODUCT?.userData?.configuration?.categoryName || CURR_SELECTED_PRODUCT?.userData?.configuration?.moduleType 
    let productName =   CURR_SELECTED_PRODUCT?.userData?.configuration?.productName || CURR_SELECTED_PRODUCT?.userData?.configuration?.moduleName 
    let subCategoryName =   CURR_SELECTED_PRODUCT?.userData?.configuration?.subCategoryName || CURR_SELECTED_PRODUCT?.userData?.configuration?.subModuleType  

    if(CURR_SELECTED_PRODUCT.userData.isModulesGroup){
        categoryName = CURR_SELECTED_PRODUCT.name
        productName = CURR_SELECTED_PRODUCT.name
    }
    $(".product-annotations-wrapper").removeClass("--is-hide")
    $("#productHeading").text(productName)
    $("#categoryHeading").text(categoryName)
    if(isKitchenPlannerMode){
        $("#categoryHeading").text(productName)
    }

    // $("#productInfoButton").fadeOut(0) 

    let isShowCustomize = true


    // CURR_SELECTED_PRODUCT?.traverse(mesh=>{
    //     if(mesh.isMesh){
    //         let partName = getOriginalPartName(categoryName,mesh.name)
    //         if(isObjectCustomizable(applicationConfig,customizerConfig.materials,categoryName,partName)){
    //             isShowCustomize = true
    //             return true
    //         }
    //     }
    // })

    if(IS_SPACE_PLANNER_MODE){
        // if(!isWallProduct(CURR_SELECTED_PRODUCT)){
        $("#productVerticalDrag").fadeOut(0)
    }else{
        $("#productVerticalDrag").fadeIn(0)
    }
    if(!isShowCustomize || IS_SPACE_PLANNER_MODE){
        $("#customizeProductIcon").fadeOut(0)
        // hideControlsPanel(function() {
        //     fitRendererIntoElement(IS_SPACE_PLANNER_MODE,renderer,camera,1)
        // })
    }else{
        $("#customizeProductIcon").fadeIn(0)
    }

    if(isWallMountedProduct(productName,categoryName) || (subCategoryName === "Vase" || subCategoryName === "Planters")){
    
    }else{
        $("#productWallAttach").fadeOut(0)
      
    }

 

    if(CURR_SELECTED_PRODUCT.userData.isProductModular){
        $("#annotaionLabel").fadeOut(0)
        $("#annotationDragIcon").fadeOut(0)
    }else{
        $("#annotaionLabel").fadeIn(0)
        $("#annotationDragIcon").fadeIn(0)   
    }
    
  

    if(isKitchenPlannerMode){
        $("#annotaionLabel").fadeIn(0)
        $("#annotationDragIcon").fadeIn(0)
        $("#productWallAttach").fadeOut(0)
        // $("#productInfoButton").fadeIn(0) 
        if(CURR_SELECTED_PRODUCT.userData.isWallMounted){
            $("#elevationView").fadeIn(0)
        }

        if(CURR_SELECTED_PRODUCT.userData.isModulesGroup){
            $("#productReplaceIcon").fadeOut(0) 
            $("#duplicateProduct").fadeOut(0) 
        }else{
            $("#productReplaceIcon").fadeIn(0) 
            $("#duplicateProduct").fadeIn(0) 
        }
    }

    if(isWallMountedProduct(productName,categoryName)){
        $("#productWallAttach").fadeIn(0)
        $("#productRotateAnnotation").fadeOut(0)
    }else{
        $("#productWallAttach").fadeOut(0)
        $("#productRotateAnnotation").fadeIn(0)
    }

    updateAttachIconVisibility()
    resetAnnotationWrapperPosition()
    
    
}

export function resetAnnotationWrapperPosition() {
    $("#productAnnotationWrapper").css("margin-left",0)
    $("#productAnnotationWrapper").css("margin-top",0)
}

export function updateAttachIconVisibility(){
    if(CURR_SELECTED_PRODUCT.userData.isAttachedToWall){
        $("#productWallAttach").fadeIn(0)
        $("#productWallElevation").fadeIn(0)
    }else{
        $("#productWallAttach").fadeOut(0)
        $("#productWallElevation").fadeOut(0)
    }
}

export function hideModularProductActions() {
    $(".modular-product-actions").addClass("display-none")
}
export function showProductActions() {
    // $("#productActionsWrapper").addClass("--is-active")
}

export function hideProductActions() {
    $("#productActionsWrapper").removeClass("--is-active")
}

export function hideProductsAnnotationsButton() {
    $(".product-annotation-button").css("display","none")
}

export function displayProductsAnnotationsButton() {
    $(".product-annotation-button").css("display","block")
}


export async function displayProductImage(product:any,elementId:string) {
    let productName = getOriginalProductName(applicationConfig,product.productName)
    let imageUrlKey = getProductImageUrl('',applicationConfig?.clientName,
    productName,
      product.categoryName,
      product.subCategoryName
    )
    let urlPrefix = getUrlPrefix(imageUrlKey);
    let tarFileKey = applicationConfig.awsConfig.getTarFileKey("products",
    {clientName:applicationConfig?.clientName,
      category: product.categoryName,
      subCategory: product.subCategoryName,
      productName: productName,
    });
    await untarAndSaveToCache(applicationConfig.awsConfig, urlPrefix, tarFileKey)?.then((data) => {
      displayImages(imageUrlKey,elementId);
    }).catch(err=>{
      displayImages(imageUrlKey,elementId);
    })
}

export async function displayImages(imageUrlKey:string,elementId:string) {

    let image = document.getElementById(elementId)
    await checkFromCache(imageUrlKey)?.then((url: any) => {
        image?.setAttribute("src", url);
      })
      .catch((err) => {
        if(image){
          image.style.visibility = "hidden"
        }
      });
  // }
}


export async function updateUndoVisibility(){
    await waitFor(100)
    if(!undo?.itemActionsStack.length){
        $(".undo-product").css("opacity",0.5)
    }else{
        $(".undo-product").css("opacity",1)
    }
  }

  export function dragEndCustomizer(event:any) {
    try {
        console.log($(event.target))
    } catch (error) {
        
    }
  }


  export function showRotationSlider() {
    $(".product-rotation-slider").addClass("--is-active")
  }

  export function hideRotationSlider() {
    $(".product-rotation-slider").removeClass("--is-active")
  }


  export async function changeViewMode(event:any,mode:string,isFromControls:boolean = false) {

    showComponentLoader("changeFinishLoader")
    applicationConfig.functions.customizer.setIsInteriorVisualization(false)
    
    switch (mode) {
        case "2d":
            if(isInteriorVisualization){
                disableInteriorVirtualization()
            }
            if(!IS_SPACE_PLANNER_MODE){
                enableSpacePlanner()
            }
        break;

        case "3d":
            if(IS_SPACE_PLANNER_MODE){
                disableSpacePlanner()
            }
            if(isInteriorVisualization){
                disableInteriorVirtualization()
            }
        break;

        case "walkthrough":
            updateCameraSettingViewData("walkthrough")
            if(IS_SPACE_PLANNER_MODE){
                disableSpacePlanner()
            }
            if(!isInteriorVisualization){
                await enableInteriorVirtualization(isFromControls)
                setTimeout(() => {
                    hideComponentLoader("changeFinishLoader")
                }, 3000);
            }
        break;
    
        default:
            break;
    }
    deselectProduct()
    updateActiveClassModeSelector(mode)
    await waitFor(200)
    composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    hideComponentLoader("changeFinishLoader")
  }

  export function updateActiveClassModeSelector(mode:string) {
    $(".mode").removeClass("--is-active")
    $("#mode"+mode).addClass("--is-active")
  }

  export function hideAllLoaders() {
    hideComponentLoader("changeFinishLoader")
    hideComponentLoader("addProductLoader")
    // hideComponentLoader("texturesLoader")
    hideComponentLoader("canvasLoader")
    hideComponentLoader("loadShellProductsLoader")
  }


  export function hideFloorplanModeElements() {
    $(".hide-in-floorplan-mode").css("visibility","hidden")
  }

  export function showFloorplanModeElements() {
    $(".hide-in-floorplan-mode").css("visibility","visible")
  }

  
  export function updateRenderTime() {
    let parentEle = window.parent.document.getElementById('productRenderingTime')
    if(parentEle){
        $(parentEle).text(" " + String(renderingTime++) + "sec")
    }else{
        $(".rendering-time").text(" " + String(renderingTime++) + "sec")
    }
  }


  export function resetRenderTime() {
    renderingTime = 0
  }

  export function resetUIMethods() {
    isLoadedCategory = false
    renderingTime = 0
  }


  export function toggleSnapping() {
    if(customizerConfig.isSnap){
        disableSnapping()
    }else{
        enableSnapping()
    }
  }

  export function enableSnapping() {
        customizerConfig.setIsSnap(true)
        showToast("Snapping on",2000)
        $("#toggleSnapping").find(".state-text").text("on")
        $("#toggleSnapping").addClass("--is-active")
  }

    export function disableSnapping() {
        customizerConfig.setIsSnap(false)
        showToast("Snapping off",2000)
        $("#toggleSnapping").find(".state-text").text("off")
        $("#toggleSnapping").removeClass("--is-active")
  }

  


  export function toggleHideWalls() {
    if(customizerConfig.isHideWalls){
        showWalls()
    }else{
        hideWalls()
    }
  }

  export function toggleAutoRotation() {
    if(customizerConfig.isAutoRotation){
        disableAutoRotation()
    }else{
        enableAutoRotation()
    }
  }

  export function enableAutoRotation() {
        customizerConfig.setIsAutoRotation(true)
        showToast("Auto rotation on",2000)
        $("#toggleAutoRotation").find(".state-text").text("on")
        $("#toggleAutoRotation").addClass("--is-active")
  }

    export function disableAutoRotation() {
        customizerConfig.setIsAutoRotation(false)
        showToast("Auto rotation off",2000)
        $("#toggleAutoRotation").find(".state-text").text("off")
        $("#toggleAutoRotation").removeClass("--is-active")
  }

export function showWalls() {
    customizerConfig.setIsHideWalls(false)
    showAreaWalls(CURR_AREA_NAME)
    $("#toggleHideWalls").find(".state-text").text("Hide Walls")
    $("#toggleHideWalls").addClass("--is-active")
}

export function hideWalls() {
    customizerConfig.setIsHideWalls(true)
    hideAllWalls()
    $("#toggleHideWalls").find(".state-text").text("Show Walls")
    $("#toggleHideWalls").removeClass("--is-active")
}



  export function openSaveOptions() {
    $(".customizer-save-options").addClass("--is-active")
  }

  export function closeSaveOptions() {
    $(".customizer-save-options").removeClass("--is-active")
  }

  export function toggleSaveOptions() {
    $(".customizer-save-options").toggleClass("--is-active")
  }

    export function toggleFileOptions() {
    $(".customizer-file-options").toggleClass("--is-active")
  }

  export function toggleSaveRenderOptions() {
    $(".customizer-save-render-options").toggleClass("--is-active")
  }


  export function toggleRenderOptions() {
    $(".customizer-render-options").toggleClass("--is-active")
  }

  export function toggleXrayOptions() {
    $(".customizer-xray-options").toggleClass("--is-active")
  }

  export function toggleMoreOptions() {
    $(".more-options").toggleClass("--is-active")
  }

  export function showAreasOptions(event:any) {
    $(".area-options").removeClass("--is-active")
    $(event.target).siblings(".area-options").addClass("--is-active")
  }


  export function showFloorplanOptionWindow() {
    $("#floorplanOptionWindow").addClass("--is-active")
  }

  export function hideFloorplanOptionWindow() {
    $("#floorplanOptionWindow").removeClass("--is-active")
  }
 
 
  export function enableRotationMode() {
    $(".hide-on-rotation").css({"pointer-events":"none","opacity":0})
    $(".product-rotation-slider").removeClass("display-none")
    // const  worldQuaternion = new Quaternion()
    // const eular = new Euler();
    // CURR_SELECTED_PRODUCT.getWorldQuaternion(worldQuaternion);
    // const worldRotation = eular.setFromQuaternion(worldQuaternion);
    // let objectRotationValueGroup = Math.floor(MathUtils.radToDeg(worldRotation.y))

    setInitialRotationSliderValues(groupForRotatingProduct)
  }

  export function disableRotationMode() {
    $(".hide-on-rotation").css({"pointer-events":"all","opacity":1})
    $(".product-rotation-slider").addClass("display-none")
}
 
 
 
export function hideLoading() {
  hideComponentLoader("changeFinishLoader")
  hideComponentLoader("canvasLoader")
  hideComponentLoader("addProductLoader")
}