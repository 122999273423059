import React, { useEffect } from "react";
import { createOrder } from "../../../../../utils/store/product-cart";
import Button2 from "../../../../common/ui-components/buttons/Button2";

 

const UpiPayment: React.FC<{
  callback:()=>void
  price:number
}> = (props) => {

  useEffect(()=>{
    // if(props.isOpen){
      setTimeout(() => {
        openQrCode()
      }, 200);
    // }
  },[])
 
 
  async function openQrCode() {
    const windowObject:any = window
    // Replace with your payee's UPI ID and amount
    // var upiId = "kartikkatahre@ibl";
    var upiId = "Vyapar.169048628957@hdfcbank";
    var amount = "19";
    // Construct the UPI URL
    var upiUrl = "upi://pay?pa=" + upiId + "&pn=KartikKatahre&am=" + amount + "&cu=INR";
    // Generate the QR code
    var qrcode = new windowObject.QRCode(document.getElementById("qrcode"), {
      text: upiUrl,
      width: 180,
      height: 180
    });
  }

 
  return (
    <div className="full-height-width">
        <div className="center-aligned-column full-height-width">
          <div className="padding10">
            <div id="qrcode"></div>
            <div className="marginTop10 heading1 font-large text-center rupee-sign">Pay ₹{String(props.price)}</div>
            <div className="marginTop10 heading3 text-center rupee-sign">Company Pvt. Ltd.</div>
          </div>
        </div>

        <div className="bottom-middle ion-padding">
          <Button2
            configObj={{id:""}}
            action={()=>{createOrder(props.callback)}}
            label={"Confirm order"}
            fontAwesomeIcon={"fas fa-times color-danger"}
            iconPos=""
            classNames={"no-shadow margin0 button-large button-warning bg-color-success"}
          />
        </div>
    </div>
  );
};

export default UpiPayment;
