import React from "react";
import { gotoProductInfo } from "../../../../../../utils/customizein3d/area3dmodel/UI_methods";
import { duplicateProduct } from "../../../../../../utils/customizein3d/area3dmodel/actions";
// import { addArea3dModelToScene, area3dModelInit, loadModuleToTheScene } from "../../../../../../utils/customizein3d/area3dModel";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import "../../../../../style/admin/customizein3d.css";

const ProductActions: React.FC<{
  configObj: any;
}> = (props) => {
  

  return (
    <React.Fragment>
      
    <div className="bottom-middle zindex30 marginleft10 product-actions-wrapper" id="productActionsWrapper">

      <div className="display-flex-row border hide-in-mobile no-shadow white-card no-wrap position-relative" style={{padding:"5px 10px"}}>
        

        <Button2
          configObj={props.configObj}
          action={duplicateProduct}
          label={"Diplicate"}
          fontAwesomeIcon={"fas fa-copy color-danger"}
          iconPos="left"
          classNames={"no-shadow margin0 button-small bg-color-white "}
        />

        <Button2
          configObj={props.configObj}
          action={()=>gotoProductInfo(null)}
          label={"Info"}
          fontAwesomeIcon={"fas fa-info color-dark"}
          iconPos="left"
          classNames={"no-shadow margin0 button-small bg-color-white "}
        />
      

      </div>

    </div>
  </React.Fragment>
  );
};

export default ProductActions;
