import $ from "jquery";
import React, { useEffect, useState } from "react";
import { hideComponentLoader, showToast } from "../../utils/UI_methods/global";
import { projectConfiguration, traceFloorplan } from "../../utils/customizein3d/area3dmodel/area3dModel";
import { deleteLastWall } from "../../utils/customizein3d/floorplanner/drawwalls";
import { Floorplan } from "../../utils/customizein3d/floorplanner/floorplan";
import { updateImageViewerFrame } from "../../utils/image-viewer/imageviewer";
import { displayUploadedImage } from "../../utils/inventory/inventory";
import { applicationConfig, waitFor, waitForDummyLoader } from "../../utils/methods";
import Hint from "../admin/projects/createproject/Hint";
import Loader from "../common/shared-components/main-ui/Loader";
import Button2 from "../common/ui-components/buttons/Button2";
import ProcessHeader from "../common/ui-components/header/ProcessHeader";
import ImageViewer from "./ImageViewer";

const TraceFloorplan: React.FC<{
  floorplan:Floorplan
  setIsTracingFloorplan:(val:boolean)=>void
  moduleName:string
}> = (props) => {

  const[imageSrc,setImageSrc] = useState("")
  const[file,setFile] = useState(null)
  const[isTracing,setIsTracing] = useState(props.moduleName === "customizer" ? true : false)
  const[isConfirmedScale,setIsConfirmedScale] = useState(false)
  const[isReviewFloorplanMode,setIsReviewFloorplanMode] = useState(false)

  //for hint
  const[isShowSetScaleHint,setIsShowSetScaleHint] = useState(true)


  useEffect(()=>{
    if(props.moduleName === "customizer"){
      const src = `https://opusassets.s3.ap-south-1.amazonaws.com/uploads/floorplan/${applicationConfig?.clientName}/${projectConfiguration.projectName}/flooplan.png`
      setImageSrc(src)
      props.floorplan.enableTraceMode()
      props.setIsTracingFloorplan(true)
      setFile({})
      startTracing()
    }
    hideComponentLoader("floorplanLoader")
  },[props.moduleName])


  async function selectFile(event:any){
    let currfile = event.target.files[0] 
    setFile(currfile)
    props.floorplan.enableTraceMode()
    props.setIsTracingFloorplan(true)
    await waitFor(400)
    await displayUploadedImage(currfile,"traceFloorplanImage")
    updateImageViewerFrame()
  }

  function cancle() {
    setFile(null)
    props.setIsTracingFloorplan(false)
    props.floorplan.disableTraceMode()
    setIsTracing(false)
  }

  function startTracing() {
    props.floorplan.enableDrawWalls()
    setIsTracing(true)
    props.floorplan.isSetScaleMode = true
  }

  async function confirmTracing() {
    await waitForDummyLoader("floorplanLoader","Creating floorplan..",2000)
    cancle()
    props.floorplan.confirmTracing()
    setIsReviewFloorplanMode(true)
    if(props.moduleName === "customizer"){
      traceFloorplan.confirmTracing()
    }
  }

  async function confirmScale() {
    // $("#setScaleWrapper").addClass("display-none")
    props.floorplan.setTraceScale()
    props.floorplan.isSetScaleMode = false
    setIsConfirmedScale(true)
    props.floorplan.enableDrawWalls()
    props.floorplan.removeWallForSetScale()
  }

  function cancleSetScale() {
    $("#setScaleWrapper").addClass("display-none")
  }

  function undoHandle() {
    props.floorplan.deleteLastWall()
    deleteLastWall()
  }

  async function showScaleConfirmationHandle() {
    $("#setScaleWrapper").addClass("display-none")
    await waitForDummyLoader("floorplanLoader","Updating Scale")
    hideComponentLoader("")
    showToast("Dimensions updated successfully, Start drawing",4000)
    confirmScale()
  }

  function SetScaleForm() {
    return(
      <div id="setScaleWrapper" className="top-left full-height-width display-none" style={{position:"fixed",background:"rgba(0,0,0,0.5)",zIndex:10002}}>
      {/* {!isConfirmedScale?
        <div className="center-aligned-column middle zindex100">
          <div className="heading3 font-medium text-center color-black ion-padding">Please draw a wall over the image to set the scale</div>
        </div>
      :null} */}
      <div className="border white-card middle" style={{padding:"30px 40px"}}>
        <div className="">
          <div className="">
            <div className="heading3">Wall dimension</div>
            <div className="display-flex-row no-wrap padding10">
              <div className="width50 space-around display-flex-row">
                <div className="heading4 center-aligned-column">Ft</div>
                <input type="number" id="wallDimensionFeet" className="input border disable" style={{width:"50px"}}/>
              </div>
              <div className="width50 space-around display-flex-row">
                <div className="heading4 center-aligned-column">Inch</div>
                <input type="number" id="wallDimensionInch" className="input border disable" style={{width:"50px"}}/>
              </div>
            </div>
          </div>
          <div className="">
            <div className="heading3">Actual values</div>
              <div className="display-flex-row no-wrap padding10">
                <div className="width50 space-around display-flex-row">
                  <div className="heading4 center-aligned-column">Ft</div>
                  <input id="actualWallDimensionFeet" type="number" className="input" style={{width:"50px"}}/>
                </div>
                <div className="width50 space-around display-flex-row">
                  <div className="heading4 center-aligned-column">Inch</div>
                  <input id="actualWallDimensionInch" type="number" className="input"  style={{width:"50px"}}/>
                </div>
              </div>
          </div>
        </div>
        <div className="center-aligned-column ion-margin-top position-relative">
          <Button2
            configObj={{id:""}}
            action={showScaleConfirmationHandle}
            label={"Submit & start tracing"}
            fontAwesomeIcon={"fas fa-check color-black"}
            iconPos="left"
            classNames={"shadow-light button-small bg-color-primary"}
          />
          <Button2
            configObj={{id:""}}
            action={cancleSetScale}
            label={"Cancle & try again"}
            fontAwesomeIcon={"fas fa-times color-danger"}
            iconPos="left"
            classNames={"shadow-light marginTop10 button-small"}
          />
        </div>
      </div>
    </div>
    )
  }
 
 
  return (
    <>


  <Loader
      className="fade-background-loader --is-active"
      id="floorplanLoader"
      data={{message:"Please wait..."}}
      loaderType="dotsText"
    />
    <Hint
      id="drawWall"
      heading="Floorplan image loaded"
      subHeading="Please draw a wall over the image to set the scale"
      buttonText="Set scale"
      closeAction={()=>{setIsShowSetScaleHint(false)}}
      isShow={isShowSetScaleHint}
    />

    <Hint
      id="confirmWall"
      heading="Scale set successfully"
      subHeading="Start drawing walls. Window / door can be added later "
      buttonText="Start drawing"
      closeAction={()=>{}}
      isShow={isConfirmedScale}
    />

    {file?

    <>
    <div className="display-flex-row top-left full-height-width" style={{background:"rgba(0,0,0,0.7)"}}>
        <ImageViewer
          imageId="traceFloorplanImage"
          src={imageSrc}
        />
    </div>
        

        {!isConfirmedScale && isTracing?
            <ProcessHeader
              prevButtonAction={null}
              nextButtonAction={null}
              prevButtonLabel={"Go back"}
              nextButtonLabel={""}
              heading={"Set floorplan scale"}
              HighlightComponent={null}
            />
        :null}

          <SetScaleForm/>
     
              
     

        <div className="top-right zindex100">
        <div className="display-flex-row no-wrap zindex100">
          {!isTracing && props.moduleName !== "customizer"?
            <>
              <Button2
                configObj={{id:""}}
                action={startTracing}
                label={"Start tracing"}
                fontAwesomeIcon={"fas fa-edit color-black"}
                iconPos="left"
                classNames={"shadow-light bg-color-primary"}
              />

              <Button2
                configObj={{id:""}}
                action={cancle}
                label={"Cancel"}
                fontAwesomeIcon={"fas fa-times color-danger"}
                iconPos="left"
                classNames={"shadow-light  bg-color"}
              />
            </>
          :
            isConfirmedScale?
            props.moduleName !== "customizer"?
            <>
              <Button2
                configObj={{id:""}}
                action={undoHandle}
                label={"Undo"}
                fontAwesomeIcon={"fas fa-undo color-danger"}
                iconPos="left"
                classNames={"shadow-light"}
              />
              <Button2
                configObj={{id:""}}
                action={confirmTracing}
                label={"Confirm"}
                fontAwesomeIcon={"fas fa-check color-black"}
                iconPos="left"
                classNames={"shadow-light zindex100 bg-color-primary"}
              />
            </>
            :null
            :
            <>
            </>
          }
          
        </div>
      </div>
      </>
    :
    props.moduleName !== "customizer"?
    <div className='bottom-middle zindex30 ion-padding'>
        <div className="white-card  shadow-medium">
        </div>
        <label className={`position-relative bg-color-primary border op-btn ripple zindex30 button2 no-wrap display-flex-row`} style={{padding:"0.7rem"}}>
          <i className={`fas fa-camera color-white icon pointer-none center-aligned-column`}></i>
          <input type="file" id="uploadImage"  onChange={selectFile} />
          <span className={`"center-aligned-column marginleft5 heading4 hide-in-mobile label color-white state-text" : ""}`} >Trace Floorplan</span> 
        </label> 
      </div>
    :null
      
    }


    {props.moduleName === "customizer" && isConfirmedScale?
    <>
    {!isReviewFloorplanMode?
       <ProcessHeader
        prevButtonAction={null}
        nextButtonAction={confirmTracing}
        prevButtonLabel={"Go back"}
        nextButtonLabel={"Confirm Tracing"}
        heading={"Tracing floorplan"}
        HighlightComponent={null}
        />
    :
    <ProcessHeader
        prevButtonAction={null}
        nextButtonAction={()=>traceFloorplan.disableTraceFloorplan()}
        prevButtonLabel={"Go back"}
        nextButtonLabel={"Confirm & Save floorplan"}
        heading={"Reviewing floorplan"}
        HighlightComponent={null}
        />
    }
    
    </>
    
    
    :
    null
    }

    

    

   

    </>
  );
};

export default TraceFloorplan;