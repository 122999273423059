import { IonButton, IonIcon } from "@ionic/react";
import { arrowBack, arrowForward, arrowUndoCircleOutline, close, cloudUpload, colorPalette, colorPaletteOutline, constructOutline, home, homeOutline, save, settingsOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { applicationConfig, getFilteredArray, logger } from "../../../../utils/methods";
import { useHistory } from "react-router";
import Actions from "../../../common/ui-components/tabs/Actions";
import { ProjectForm } from "../../../../utils/projects/projectForm";
import { addItemToLocalStorage } from "../../../../utils/cache/localstorage";

 

const Options: React.FC<{
    closeModal: (value: boolean) => void;
    updateCurrentState:(state:string)=>void
    configObj:any
    projectId:number
    refreshProjects:(projects:Array<any>,allAreas:Array<any>)=>void
    projectForm:ProjectForm
    mode:string
}> = (props) => {


  let history = useHistory()

  const [actions] = useState([
    {
      name: "goBakc",
      label: "Back to projects",
      iconClassNames: ["color-dark"],
      icon: arrowUndoCircleOutline,
      action: backtohome,
    },

    {
      name: "ContinueDesigning",
      label: "Create Moodboard",
      iconClassNames: ["color-danger"],
      icon: colorPaletteOutline,
      action: gotoMoodboard,
    },

    // {
    //   name: "ContinueCustomizing",
    //   label: "Start customizing",
    //   iconClassNames: ["color-primary"],
    //   icon: settingsOutline,
    //   action: gotoCustomizer,
    // },
  ]);



  function gotoMoodboard() {
    let moodboardId = null
    let moodboardList = getFilteredArray(props.configObj?.data.moodboardList,"project_id",props.projectId.toString())
    if(moodboardList){
      moodboardId = moodboardList[0]?.moodboard_id
    }

    props.closeModal(false)
    props.configObj?.setMoodboardId(moodboardId)
    props.configObj?.setProjectId(props.projectId)
    props.configObj?.setProjectName(props.projectForm?.projectName)

    logger?.info("projects","createproject",`Create collection selected: ${props.projectId}`)

    history.push('/edit-moodboard')
  }

  function backtohome() {
    props.closeModal(false)
    props.refreshProjects(props.configObj.data.projects,props.configObj.data.projects.projectAreasList)
    logger?.info("projects","createproject",`Go back selected`)
  }
 

  
  function gotoCustomizer() {
    console.log(props.projectForm)
    let project = {
      client_id:applicationConfig.clientId,
      project_id:props.projectForm.projectId,
      project_name:props.projectForm.projectName
    }
    addItemToLocalStorage("currProject",project)
    window.location.href = "/customize"
  }

 
  return (
    <React.Fragment>
    <div className="modal-header capitalize">Choose option</div>
    <div className="modal-body">
      <div className="create-project-areas-container" style={{ padding: "0 0", overflowY: "hidden" }}>
        <div className="product-actions full-height-width center-aligned-column">
          <div className=""><i className="fas fa-check-circle font-ex-large color-success"></i></div>
          <div className="heading1 color-black letter-spacing">Your project has been {props.mode}d successfully</div>
          <div className="ion-margin-bottom marginTop5 heading2 color-dark letter-spacing">Choose any option to continue</div>
          <Actions configObj={props.configObj} actionList={actions} />
        </div>
      </div>
    </div>
    <div className="modal-footer space-between">
      
    </div>
  </React.Fragment>
        
  );
};

export default Options;
