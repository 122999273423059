import React, { useState } from "react";
import Alert from "../../../../common/ui-components/alerts/Alert";
import Input from "../../../../common/ui-components/input/Input";
import Select from "../../../../common/ui-components/input/Select";
import MultipleFileUpload from "../../../../common/ui-components/forms/MultipleFileUpload";
import "../../../../style/inventory/form.css"
 
 


 
const Form: React.FC<{
    alertId:string
    isUpdate:boolean
}> = (props) => {


  return (

    <div className={`create-stock-form bg-color-light full-width overflow-y-scroll ion-padding-top ion-padding-bottom full-height ${!props.isUpdate?" padding-container ":""}`}>
        <Alert id={props.alertId}/>

        <div className={`display-flex-row form-row`}>
            <div className="row">
                <Input
                    type="text"
                    id="material_name"
                    label="Material Name"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={``}
                    className=""
                />
            </div>
            <div className="row">
                <Select
                    id="material_type"
                    label="Material Type"
                    isMandatory={false}
                    onChange={()=>{}}
                    options={["Engineering Wood"]}
                    className=""
                    value={""}
                />
            </div>
            <div className="row">
                <MultipleFileUpload 
                    heading="Upload image"
                    setFiles={()=>{}}
                    acceptExtensions = ".png,.jpeg,.webp"
                    multiple = {false}
                    isMandatory={true}
                    isInputView={true}
                />
            </div>
        </div>

        

        <div className={`display-flex-row form-row`}>
            <div className="row">
                <Select
                    id="quality_variant"
                    label="Quality Variant"
                    isMandatory={true}
                    onChange={()=>{}}
                    options={["A Grade"]}
                    className=""
                    value={""}
                />
            </div>
            <div className="row">
                <Input
                    type="number"
                    id="quantity_in_stock"
                    label="Quantity In Stock"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={1}
                    className=""
                />
            </div>
            <div className="row">
                <Select
                    id="storage_recommandation"
                    label="Storage Recommandation"
                    isMandatory={true}
                    onChange={()=>{}}
                    options={["A cool, dry, dark environment."]}
                    className=""
                    value={""}
                />
            </div>
        </div>

        <div className={`display-flex-row form-row`}>
            <div className="row">
                <Input
                    type="number"
                    id="reorder_level"
                    label="Reorder Level"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={1}
                    className=""
                />
            </div>
            <div className="row">
                <Input
                    type="number"
                    id="reorder_quantity"
                    label="Reorder Quantity"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={5}
                    className=""
                />
            </div>
            <div className="row">
                <Input
                    type="number"
                    id="lead_time"
                    label="Lead Time"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={2}
                    className=""
                />
            </div>
        </div>

        <div className={`display-flex-row form-row`}>
            <div className="row">
                <Input
                    type="number"
                    id="minimum_order_quantity"
                    label="Minimum Order Quantity"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={1}
                    className=""
                />
            </div>
            <div className="row">
                <Input
                    type="number"
                    id="maximum_order_quantity"
                    label="Maximum Order Quantity"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={100}
                    className=""
                />
            </div>
            <div className="row">
                <Input
                    type="number"
                    id="safety_stock"
                    label="Safety Stock"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={10}
                    className=""
                />
            </div>
            
        </div>

        <div className={`display-flex-row form-row`}>
            <div className="row">
                <Select
                    id="unit_of_measure"
                    label="Unit of Measure"
                    isMandatory={true}
                    onChange={()=>{}}
                    options={["CM","Feet","Inches"]}
                    className=""
                    value={""}
                />
            </div>
            <div className="row">
                <Select
                    id="alternative_unit_of_measure"
                    label="Alternative Unit of Measure"
                    isMandatory={true}
                    onChange={()=>{}}
                    options={["CM","Feet","Inches"]}
                    className=""
                    value={""}
                />
            </div>
            <div className="row">
                <Input
                    type="number"
                    id="unit_weight"
                    label="Unit Weight"
                    isMandatory={true}
                    onChange={()=>{}}
                    value={1}
                    className=""
                />
            </div>
            
        </div>

        <div className="form-label-input-container">
            <div className="label-container ">
                <div className="label">
                    Descirption
                </div>
            </div>
            <div className="input-container">
                <textarea className={`input`} id="description" style={{height:"7rem"}} />
            </div>
        </div>
       
       

    
      
       

     
      
    </div>
  );
};

export default Form;
