import { IonIcon } from '@ionic/react';
import { colorPaletteOutline } from 'ionicons/icons';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
// import './Home.css';
import { handleRightWrapperToggle } from "../../../utils/UI_methods/global";

const ToggleControlsWrapper: React.FC<{
  position:string
}> = (props) => {

  const[classNames,setClassNames]:Array<any> = useState([])

  useEffect(()=>{

    let classes = ["toggle-controls-wrapper zindex30  center-aligned-column"]

    if(props.position==="left"){
        // classes.push("left-middle")
    }else{
        // classes.push("right-middle")
    }
    setClassNames(classes)
  },[props.position])


  return (
    <div className={classNames.join(" ")} onClick={()=>handleRightWrapperToggle(props.position)}>
      <div className="white-card heading shadow-medium padding10" style={{border:"1px solid var(--op-color-danger)"}}> 
            <i className="fas fa-cog color-danger"></i> Customize
    </div>
    </div>

  );
};

export default ToggleControlsWrapper;
